import moment from 'moment'
import { StopStatus } from 'constants/constants'

export interface IDelayResult {
  value: string
  color: IColors
}
export interface IColors {
  background: string
  fontColor: string
}

const ON_TIME = { background: 'MediumSeaGreen', fontColor: 'green' }
const NOT_DELIVERED = { background: 'white', fontColor: 'black' }
const ACCEPTABLE = { background: 'orange', fontColor: 'orange' }
const UNACCEPTABLE = { background: 'red', fontColor: 'red' }
const DROPPED = { background: 'black', fontColor: 'white' }

function delayCalculation(
  deliveryDate: Date | undefined,
  deliveryDateMinimum: Date,
  deliveryDateMaximum: Date,
  toleratedAdvance: number,
  toleratedDelay: number,
): IDelayResult {
  if (!deliveryDate) return { value: '', color: NOT_DELIVERED }

  const momentDeliveryDate = moment(deliveryDate)
  const momentDeliveryDateMinimum = moment(deliveryDateMinimum)
  const momentDeliveryDateMaximum = moment(deliveryDateMaximum)

  if (momentDeliveryDate.isBefore(momentDeliveryDateMinimum)) {
    const advance = momentDeliveryDate.diff(momentDeliveryDateMinimum, 'minutes', true)
    const value = formatDelayValue(advance)
    return Math.abs(advance) > toleratedAdvance
      ? { value, color: UNACCEPTABLE }
      : { value, color: ACCEPTABLE }
  }

  if (momentDeliveryDate.isAfter(momentDeliveryDateMaximum)) {
    const delay = momentDeliveryDate.diff(momentDeliveryDateMaximum, 'minutes', true)
    const value = formatDelayValue(delay)
    return Math.abs(delay) > toleratedDelay
      ? { value, color: UNACCEPTABLE }
      : { value, color: ACCEPTABLE }
  }

  return { value: 'OK', color: ON_TIME }
}

function stopDelayCalculation(
  deliveryDate: Date,
  planifiedDate: Date,
  toleratedDelay: number,
): IDelayResult {
  const deliveryDateMoment = moment(deliveryDate)
  const planifiedDateMoment = moment(planifiedDate)

  if (deliveryDateMoment.isBefore(planifiedDateMoment)) {
    const advance = deliveryDateMoment.diff(planifiedDateMoment, 'minutes', true)
    const value = formatDelayValue(advance)

    return { value, color: ON_TIME }
  }

  if (deliveryDateMoment.isAfter(planifiedDateMoment)) {
    const delay = deliveryDateMoment.diff(planifiedDateMoment, 'minutes', true)
    const value = formatDelayValue(delay)
    return Math.abs(delay) > toleratedDelay
      ? { value, color: UNACCEPTABLE }
      : { value, color: ACCEPTABLE }
  }

  return { value: 'OK', color: ON_TIME }
}

function formatDelayValue(delayInMinutes: number): string {
  const delayPrefix = `${delayInMinutes < 0 ? '- ' : ''}`
  const hours = Math.floor(Math.abs(delayInMinutes) / 60)
  const minutes = Math.floor(Math.abs(delayInMinutes) % 60)
  let delay = ''
  if (hours === 0) delay = `${minutes}m`
  else {
    const formattedHours = hours.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
    })
    const formattedMinutes = minutes.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
    })
    delay = `${formattedHours}h${formattedMinutes}`
  }
  return `${delayPrefix}${delay}`
}

function getDelaysColors(
  deliveryDate: Date,
  deliveryDateMinimum: Date,
  deliveryDateMaximum: Date,
  toleratedAdvance: number,
  toleratedDelay: number,
): IColors {
  return delayCalculation(
    deliveryDate,
    deliveryDateMinimum,
    deliveryDateMaximum,
    toleratedAdvance,
    toleratedDelay,
  ).color
}

function getStopMarkerDelaysColors(
  deliveryDateMinimum: Date,
  deliveryDateMaximum: Date,
  toleratedAdvance: number,
  toleratedDelay: number,
  realDeliveryDate?: Date,
  status?: string | number,
): IColors {
  if (status === StopStatus.Dropped || status === StopStatus.Canceled) {
    return DROPPED
  }

  if (
    !realDeliveryDate
    || deliveryDateMinimum === null
    || deliveryDateMinimum === undefined
    || deliveryDateMaximum === null
    || deliveryDateMaximum === undefined
  ) {
    return NOT_DELIVERED
  }
  return getDelaysColors(
    realDeliveryDate,
    deliveryDateMinimum,
    deliveryDateMaximum,
    toleratedAdvance,
    toleratedDelay,
  )
}

export {
  getStopMarkerDelaysColors,
  getDelaysColors,
  delayCalculation,
  stopDelayCalculation,
  formatDelayValue,
}
