import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import DateRangePicker from 'components/Inputs/DateRangePicker'
import { DateType } from 'constants/constants'
import useStyles from './styles'

interface Props {
  open: boolean
  onClose: (endDate: string) => void
  close: () => void
  isTour: boolean
}

function FinishStopTourDialog({ open, onClose, close, isTour }: Props): JSX.Element {
  const { t } = useTranslation()
  const [tourEndDate, setTourEndDate] = useState<string>(new Date().toString())
  const classes = useStyles()

  const renderDateRangePicker = () => (
    <div className={classes.dateRangePicker}>
      <DateRangePicker
        customClass={classes.dateRangePicker}
        dateType={DateType.DateTime}
        startLabel={t('finishToursDialog.tourEndDate')}
        startDate={tourEndDate}
        hideEndPicker
        hideShiftPicker
        onChangeStart={(value) => {
          if (value) setTourEndDate(value.toString())
        }}
      />
    </div>
  )

  return (
    <Dialog
      onClick={(e) => e.stopPropagation()}
      disableBackdropClick
      fullWidth
      disableEscapeKeyDown
      aria-labelledby="finish-tour-dialog"
      open={open}
    >
      <DialogTitle>
        {isTour ? t('finishToursDialog.tourTitle') : t('finishToursDialog.stopTitle')}
      </DialogTitle>
      <DialogContent>
        {isTour ? (
          <div className={classes.finishTourContainer}>
            <span>{t('finishToursDialog.info')}</span>
            {renderDateRangePicker()}
          </div>
        ) : (
          renderDateRangePicker()
        )}
      </DialogContent>
      <DialogActions>
        <div className={classes.finishTourActionsContainer}>
          <div>
            <Button data-cy="cancelButton" onClick={close} color="primary">
              {t('form.cancel')}
            </Button>
          </div>
          <div>
            <Button data-cy="eraseButton" onClick={() => setTourEndDate('')} color="primary">
              {t('finishToursDialog.eraseButton')}
            </Button>
            <Button
              data-cy="okButton"
              onClick={() => {
                onClose(tourEndDate)
              }}
              color="primary"
            >
              {t('finishToursDialog.validateButton')}
            </Button>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default FinishStopTourDialog
