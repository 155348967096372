import Api from 'services/api'
import { IError } from 'api/types'
import { ICancellationReason } from 'interfaces/ICancellationReason'

const mapCancellationReasons = (
  cancellationReasons: ICancellationReason[],
): ICancellationReason[] =>
  cancellationReasons.map((cancellationReason) => ({
    id: Math.random(),
    errorCode: false,
    errorLabel: false,
    isNew: false,
    ...cancellationReason,
  }))

const getCancellationReasons = async (): Promise<ICancellationReason[] | IError> => {
  try {
    const response = await Api.get('tenants/cancellation-reasons')
    return mapCancellationReasons(response)
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const updateCancellationReasons = async (
  cancellationReasons: ICancellationReason[],
): Promise<ICancellationReason[] | IError> => {
  try {
    const response = await Api.patch('tenants/cancellation-reasons', {
      cancellationReasons: cancellationReasons.map((cancellationReason) => ({
        ...cancellationReason,
        id: undefined,
        errorCode: undefined,
        errorLabel: undefined,
        isNew: undefined,
      })),
    })
    return mapCancellationReasons(response)
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

export default {
  getCancellationReasons,
  updateCancellationReasons,
}
