import React, { ReactNode } from 'react'
import { SnackbarContent, Snackbar, IconButton } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CloseIcon from '@material-ui/icons/Close'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import WarningIcon from '@material-ui/icons/Warning'
import styled from 'styled-components'

const SnackContent = styled(SnackbarContent)`
  color: white;
`

const Span = styled.span`
  display: flex;
  align-items: center;
`

interface Icons {
  [key: string]: ReactNode
}

const variantIcon: Icons = {
  success: <CheckCircleIcon />,
  warning: <WarningIcon />,
  error: <ErrorIcon />,
  info: <InfoIcon />,
}

interface IColors {
  [key: string]: string
}

const variantColor: IColors = {
  success: 'green',
  warning: 'orange',
  error: 'red',
  info: 'grey',
}

interface ICustomSnackbar {
  variant: string
  open: boolean
  handleClose(event: React.SyntheticEvent | React.MouseEvent, reason?: string): void
  message: string
}

const CustomSnackbar = ({ variant, open, handleClose, message }: ICustomSnackbar): JSX.Element => {
  const Icon = variantIcon[variant]

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={10000}
      onClose={handleClose}
      data-cy="snackbar"
    >
      <SnackContent
        message={(
          <Span id="client-snackbar" style={{ whiteSpace: 'pre-line' }}>
            {Icon}
            <span style={{ marginLeft: '14px' }} data-cy="messageSnackbar">
              {message}
            </span>
          </Span>
        )}
        style={{ backgroundColor: variantColor[variant] }}
        action={(
          <IconButton
            data-cy="closeSnackBarButton"
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      />
    </Snackbar>
  )
}

export default CustomSnackbar
