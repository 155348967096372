import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import { useTranslation } from 'react-i18next'
import { IPlanStop } from 'interfaces/IPlan'
import { IStop } from 'interfaces/Itours'
import { ListSelectStopStatus } from 'components/Inputs/ListSelect'
import { STOP_STATUS_MAP } from 'constants/constants'
import { dateDefaultFormat } from '../../utils/dateFormat'
import StopDelay from './StopDelay'

interface IDetailsProps {
  stop: IStop | IPlanStop
  toleratedAdvance: number
  toleratedDelay: number
  isTourDropped: boolean
}

interface IProps {
  readonly isForPlanning?: boolean
  readonly canEditStatus?: boolean
}

function Columns({ isForPlanning }: IProps): JSX.Element {
  const { t } = useTranslation()

  return (
    <>
      {!isForPlanning && (
        <>
          <TableCell align="right">{t('tablesEntries.details.eta')}</TableCell>
          <TableCell align="right">{t('tablesEntries.details.deliverySchedule')}</TableCell>
          <TableCell align="right">{t('tablesEntries.details.respectedClientWindow')}</TableCell>
          <TableCell align="left">{t('tablesEntries.details.status')}</TableCell>
        </>
      )}
    </>
  )
}

function Data({
  stop,
  isForPlanning,
  toleratedAdvance,
  toleratedDelay,
  canEditStatus,
  isTourDropped,
}: IDetailsProps & IProps): JSX.Element {
  return (
    <>
      {!isForPlanning && (
        <>
          <TableCell align="right">
            {(stop as IStop).estimatedArrival
              ? dateDefaultFormat((stop as IStop).estimatedArrival)
              : ''}
          </TableCell>
          <TableCell align="right">
            {dateDefaultFormat((stop as IStop).realArrival || (stop as IStop).cancellation?.date)}
          </TableCell>
          <TableCell align="right">
            <StopDelay
              stop={stop as IStop}
              toleratedAdvance={toleratedAdvance}
              toleratedDelay={toleratedDelay}
              isStopClientPromiseDelay
            />
          </TableCell>
          <TableCell align="left">
            {canEditStatus ? (
              <ListSelectStopStatus
                status={(stop as IStop).status}
                stopId={(stop as IStop).stopId}
                isTourDropped={isTourDropped}
              />
            ) : (
              STOP_STATUS_MAP.get(Number(stop.status))
            )}
          </TableCell>
        </>
      )}
      {/* <TableCell align="right">Signature</TableCell> */}
      {/* <TableCell align="right">Photo</TableCell> */}
    </>
  )
}

export default {
  Columns,
  Data,
}
