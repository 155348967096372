import React from 'react'

import { IVisit } from 'interfaces/IOrders'
import Address from './Address'
import useStyles from './styles'
import TrackingCard from './TrackingCard'
import Visit from './Visit'

type VisitTrackingCardProps = {
  visits: IVisit[]
  orderNumber: string
}
export default ({ visits, orderNumber }: VisitTrackingCardProps): JSX.Element => {
  const classes = useStyles()

  return (
    <TrackingCard orderNumber={orderNumber}>
      <div className={classes.visitList}>
        <Address label={visits[0].originAddress?.label || ''} />
        {visits.map((visit) => (
          <Visit
            key={visit.visitId}
            address={visit.destinationAddress?.label as string}
            status={visit.stopStatus}
          />
        ))}
      </div>
    </TrackingCard>
  )
}
