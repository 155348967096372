import React from 'react'
import { useTranslation } from 'react-i18next'
import DeleteIcon from '@material-ui/icons/Delete'
import { FormControl, Button, TextField } from '@material-ui/core'
import moment from 'moment'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

import useStyles from 'constants/cruStyles'
import Card from 'components/Card/Card'
import DateRangePicker from 'components/Inputs/DateRangePicker'
import { DateType } from 'constants/constants'
import { IWarehouseTimeSlotForm } from 'interfaces/IWarehouseTimeSlot'
import { ITextChange } from 'interfaces/interfaces'

interface IProps {
  day: string
  warehouseTimeSlot: IWarehouseTimeSlotForm
  onWarehouseTimeSlotChange: (warehouseTimeSlot: IWarehouseTimeSlotForm) => void
}

const WarehouseTimeSlotDayScreen = ({
  day,
  warehouseTimeSlot,
  onWarehouseTimeSlotChange,
}: IProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleAddClick = (): void => {
    warehouseTimeSlot.timeSlot[day].push({
      id: Math.random(),
      availability: undefined,
      beginTime: moment().hours(12).minutes(0).seconds(0)
        .milliseconds(0)
        .toISOString(),
      endTime: moment().hours(14).minutes(0).seconds(0)
        .milliseconds(0)
        .toISOString(),
    })
    onWarehouseTimeSlotChange({ ...warehouseTimeSlot })
  }

  const handleTimeSlotDelete = (timeSlotId: number | undefined): void => {
    const data = { ...warehouseTimeSlot }
    const timeSlotDay = data.timeSlot[day].filter((timeSlot) => timeSlot.id !== timeSlotId)
    data.timeSlot[day] = timeSlotDay
    onWarehouseTimeSlotChange({ ...data })
  }

  const handleTimeChange = (
    time: MaterialUiPickersDate,
    timeSlotId: number | undefined,
    name: string,
  ): void => {
    if (moment(time).isValid()) {
      const data = { ...warehouseTimeSlot }
      const timeSlotDay = data.timeSlot[day].map((timeSlot) => {
        if (timeSlot.id === timeSlotId) {
          return {
            ...timeSlot,
            [name]: moment(time).toISOString(),
          }
        }
        return timeSlot
      })
      data.timeSlot[day] = timeSlotDay
      onWarehouseTimeSlotChange({ ...data })
    }
  }

  const handleInputChange = (event: ITextChange, timeSlotId: number | undefined): void => {
    const { target } = event
    const { name, value } = target
    const data = { ...warehouseTimeSlot }
    const timeSlotDay = data.timeSlot[day].map((timeSlot) => {
      if (timeSlot.id === timeSlotId) {
        return {
          ...timeSlot,
          [name]: value ? +value : undefined,
        }
      }
      return timeSlot
    })
    data.timeSlot[day] = timeSlotDay
    onWarehouseTimeSlotChange({ ...data })
  }

  return (
    <Card
      dataCy="timeSlotDayCard"
      title={t(`scheduler.${day}`).toUpperCase()}
      contentClassName={classes.contentRef}
    >
      <>
        <div className={classes.btnSelect}>
          <FormControl variant="outlined">
            <Button data-cy="addButton" className={classes.modifyButton} onClick={handleAddClick}>
              {t('ConfigurationsScreen.add').toUpperCase()}
            </Button>
          </FormControl>
        </div>
        <div className={classes.fieldsContainer}>
          {warehouseTimeSlot.timeSlot[day]?.map((timeSlot) => (
            <div data-cy="timeSlotsItems" className={classes.configurationRow} key={timeSlot.id}>
              <div className={classes.configurationItemsContainer}>
                <DateRangePicker
                  startDate={timeSlot.beginTime}
                  endDate={timeSlot.endTime}
                  onChangeStart={(time: MaterialUiPickersDate): void => {
                    handleTimeChange(time, timeSlot.id, 'beginTime')
                  }}
                  onChangeEnd={(time: MaterialUiPickersDate): void => {
                    handleTimeChange(time, timeSlot.id, 'endTime')
                  }}
                  hideShiftPicker
                  dateType={DateType.Time}
                />
                <TextField
                  data-cy="availability"
                  className={classes.sideTextField}
                  error={
                    timeSlot.availability !== undefined
                    && timeSlot.availability.toString().length === 0
                  }
                  label={t('SiteTimeSlotsScreen.maxOrder').toUpperCase()}
                  onChange={(event) => handleInputChange(event, timeSlot.id)}
                  type="number"
                  name="availability"
                  required
                  value={timeSlot.availability !== undefined ? timeSlot.availability : ''}
                />
              </div>
              <Button
                data-cy="deleteButton"
                onClick={(): void => {
                  handleTimeSlotDelete(timeSlot.id)
                }}
              >
                <DeleteIcon />
              </Button>
            </div>
          ))}
        </div>
      </>
    </Card>
  )
}

export default WarehouseTimeSlotDayScreen
