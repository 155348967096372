import React, { useState, useEffect, useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import TenantsProvider, { TenantsConsumer } from 'store/TenantsContext'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import FormAction from 'components/Button/FormAction'
import { ITenant } from 'interfaces/ITenant'
import { IIdParam } from 'interfaces/IIdParam'
import { AuthContext } from 'store/AuthContext'
import { FeedbackContext } from 'store/FeedbackContext'
import TenantInfoCard from './TenantInfoCard'

interface IProps {
  createTenant?: Function
  createSuccess?: boolean
  updateSuccess?: boolean
  getDetails?: Function
  updateTenant?: Function
  tenantDetails?: ITenant
}

const TenantFormScreen = ({
  createTenant,
  updateSuccess,
  updateTenant,
  createSuccess,
  getDetails,
  tenantDetails,
}: IProps): JSX.Element => {
  const { t } = useTranslation()
  const [tenant, setTenant] = useState<ITenant>({})
  const { getUserData } = useContext(AuthContext)
  const { openErrorSnack } = useContext(FeedbackContext)
  const history = useHistory()
  const { id } = useParams<IIdParam>()

  const handleTenantChange = (tenantInfo: ITenant): void => {
    setTenant((prevTenant) => ({
      ...prevTenant,
      name: tenantInfo.name,
      language: tenantInfo.language,
      carrierCanChangeDriver: tenantInfo.carrierCanChangeDriver,
      deliveryDuration: tenantInfo.deliveryDuration
        ? Number(tenantInfo.deliveryDuration)
        : undefined,
      klareoProducts: tenantInfo.klareoProducts,
      planningPinsZoom: tenantInfo.planningPinsZoom ? Number(tenantInfo.planningPinsZoom) : null,
      sessionTokenExpiryDelay: tenantInfo.sessionTokenExpiryDelay,
      passwordRules: tenantInfo.passwordRules,
      setManualPassword: tenantInfo.setManualPassword,
    }))
  }

  const handleSaveClick = async (): Promise<void> => {
    if (tenant.passwordRules) {
      if (
        !tenant.passwordRules.charactersMinimum
        || tenant.passwordRules.charactersMinimum < 1
        || tenant.passwordRules.charactersMinimum % 1 !== 0
      ) {
        openErrorSnack(t('TenantsScreen.messages.charactersMinimumValidation'))
        return
      }

      if (
        tenant.passwordRules.upperCaseMinimum !== undefined
        && (tenant.passwordRules.upperCaseMinimum < 0
          || tenant.passwordRules.upperCaseMinimum % 1 !== 0)
      ) {
        openErrorSnack(t('TenantsScreen.messages.upperCaseMinimumValidation'))
        return
      }

      if (
        tenant.passwordRules.lowerCaseMinimum !== undefined
        && (tenant.passwordRules.lowerCaseMinimum < 0
          || tenant.passwordRules.lowerCaseMinimum % 1 !== 0)
      ) {
        openErrorSnack(t('TenantsScreen.messages.lowerCaseMinimumValidation'))
        return
      }

      if (
        tenant.passwordRules.digitsMinimum !== undefined
        && (tenant.passwordRules.digitsMinimum < 0 || tenant.passwordRules.digitsMinimum % 1 !== 0)
      ) {
        openErrorSnack(t('TenantsScreen.messages.digitsMinimumValidation'))
        return
      }

      if (
        tenant.passwordRules.symbolsMinimum !== undefined
        && (tenant.passwordRules.symbolsMinimum < 0 || tenant.passwordRules.symbolsMinimum % 1 !== 0)
      ) {
        openErrorSnack(t('TenantsScreen.messages.symbolsMinimumValidation'))
        return
      }
    }

    if (id) {
      if (updateTenant) {
        await updateTenant(id, tenant)
      }
    } else {
      if (createTenant) {
        await createTenant(tenant)
      }
    }
  }

  useEffect(() => {
    if (createSuccess || updateSuccess) {
      getUserData(true)
      history.push(ROUTES_PATH.tenantsList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createSuccess, updateSuccess])

  useEffect(() => {
    if (id && getDetails) {
      getDetails(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    setTenant({
      id: tenantDetails?.id,
      name: tenantDetails?.name,
      language: tenantDetails?.language,
      carrierCanChangeDriver: tenantDetails?.carrierCanChangeDriver,
      deliveryDuration: tenantDetails?.deliveryDuration,
      klareoProducts: tenantDetails?.klareoProducts,
      planningPinsZoom: tenantDetails?.planningPinsZoom,
      sessionTokenExpiryDelay: tenantDetails?.sessionTokenExpiryDelay,
      passwordRules: tenantDetails?.passwordRules,
      setManualPassword: tenantDetails?.setManualPassword,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantDetails])

  const handleCancelClick = (): void => {
    history.push(ROUTES_PATH.tenantsList)
  }

  return (
    <>
      {id && <h3 data-cy="tenantDetailDescription">{`${tenant.name || ''}`}</h3>}
      <TenantInfoCard key={tenant.id} tenant={tenant} onTenantChange={handleTenantChange} />
      <FormAction onSaveClick={handleSaveClick} onCancelClick={handleCancelClick} />
    </>
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <TenantsProvider>
    <TenantsConsumer>
      {(tenantCtx): JSX.Element => (
        <TenantFormScreen
          updateSuccess={tenantCtx.updateSuccess}
          updateTenant={tenantCtx.updateTenant}
          getDetails={tenantCtx.getDetails}
          tenantDetails={tenantCtx.tenantDetails}
          createTenant={tenantCtx.createTenant}
          createSuccess={tenantCtx.createSuccess}
          {...props}
        />
      )}
    </TenantsConsumer>
  </TenantsProvider>
)
