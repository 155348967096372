import {
  MENU_CATEGORIES,
  MENU_SUBCATEGORIES,
  USER_ROLES,
  USER_ROLES_WITH_ORDER_MODULE,
} from 'constants/constants'
import OrdersScreen from 'screens/OrderCategory/List/OrdersScreen'
import OrderDetailsScreen from 'screens/OrderCategory/Details/OrderDetailsScreen'
import ImmportJobsListScreen from 'screens/OrderCategory/ImportJob/ImportJobHistoryScreen'
import OrdersFormScreen from 'screens/OrderCategory/Form/OrdersFormScreen'
import OrderBookingScreen from 'screens/OrderCategory/Booking/OrderBookingScreen'
import CartDropoffScreen from 'screens/OrderCategory/Form/CartDropoffScreen'
import ReloadCartDropoffScreen from 'screens/OrderCategory/Form/ReloadCartDropoffScreen'
import { ROUTES_PATH } from './RoutesPath'

const ORDER_CATEGORY = [
  {
    title: MENU_SUBCATEGORIES.list,
    path: ROUTES_PATH.ordersList,
    component: OrdersScreen,
    isPrivate: true,
    isInMenu: true,
    category: MENU_CATEGORIES.order,
    roles: USER_ROLES_WITH_ORDER_MODULE,
  },
  {
    title: MENU_SUBCATEGORIES.createOrderFromMenu,
    path: ROUTES_PATH.createOrderFromMenu,
    component: OrdersFormScreen,
    isPrivate: true,
    isInMenu: true,
    category: MENU_CATEGORIES.order,
    roles: USER_ROLES_WITH_ORDER_MODULE,
    description: "Ajout d'une commande depuis la page principale",
    isBetaMode: true,
  },
  // Item of ReloadCartDropoffScreen should be before the one of CartDropoffScreen
  {
    title: MENU_SUBCATEGORIES.cartDropoff,
    path: ROUTES_PATH.reloadCartDropoff,
    component: ReloadCartDropoffScreen,
    isPrivate: true,
    isInMenu: false,
    category: MENU_CATEGORIES.order,
    roles: USER_ROLES_WITH_ORDER_MODULE,
  },
  {
    title: MENU_SUBCATEGORIES.cartDropoff,
    path: ROUTES_PATH.cartDropoff,
    component: CartDropoffScreen,
    isPrivate: true,
    isInMenu: true,
    category: MENU_CATEGORIES.order,
    roles: USER_ROLES_WITH_ORDER_MODULE,
    description: "Accès à l'écran lâcher caddie",
    isBetaMode: true,
  },
  {
    title: MENU_SUBCATEGORIES.orders,
    path: ROUTES_PATH.createOrder,
    component: OrdersFormScreen,
    isPrivate: true,
    isInMenu: false,
    category: MENU_CATEGORIES.order,
    roles: USER_ROLES_WITH_ORDER_MODULE,
  },
  {
    title: MENU_SUBCATEGORIES.orderBooking,
    path: `${ROUTES_PATH.orderDetails}:id/booking`,
    component: OrderBookingScreen,
    isPrivate: true,
    isInMenu: false,
    category: MENU_CATEGORIES.order,
    roles: USER_ROLES_WITH_ORDER_MODULE,
  },
  {
    title: MENU_SUBCATEGORIES.orderDetails,
    path: `${ROUTES_PATH.orderDetailsByOrderNumber}/:orderNumber`,
    component: OrderDetailsScreen,
    isPrivate: true,
    isInMenu: false,
    category: MENU_CATEGORIES.order,
    roles: USER_ROLES_WITH_ORDER_MODULE,
  },
  {
    title: MENU_SUBCATEGORIES.orderDetails,
    path: `${ROUTES_PATH.orderDetails}:id`,
    component: OrderDetailsScreen,
    isPrivate: true,
    isInMenu: false,
    category: MENU_CATEGORIES.order,
    roles: USER_ROLES_WITH_ORDER_MODULE,
  },
  {
    title: MENU_SUBCATEGORIES.importHistory,
    path: ROUTES_PATH.importHistory,
    component: ImmportJobsListScreen,
    isPrivate: true,
    isInMenu: false,
    category: MENU_CATEGORIES.order,
    roles: [...USER_ROLES_WITH_ORDER_MODULE, USER_ROLES.udcAdmin, USER_ROLES.udcExploitant],
  },
]

export default ORDER_CATEGORY
