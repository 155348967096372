import moment from 'moment'

import Api from 'services/api'
import { IError } from 'api/types'
import { IPlanTemplate } from 'interfaces/IPlanTemplate'
import { IPlanTemplatesFilters } from 'interfaces/interfaces'

const getList = async (
  filters?: IPlanTemplatesFilters,
  offset?: number,
  rowsPerPage?: number,
  sortBy?: string,
  sortDirection?: number,
): Promise<{ planTemplates: Array<IPlanTemplate>; count: number } | IError> => {
  try {
    const response = await Api.get('plan-templates', {
      siteIds: filters?.siteIds,
      offset,
      limit: rowsPerPage,
      sortBy: sortBy || 'label',
      sortDirection: sortDirection || 1,
    })
    return { planTemplates: response.items, count: response.count }
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const create = async (planTemplate: IPlanTemplate): Promise<IPlanTemplate | IError> => {
  try {
    const response = await Api.post('plan-templates', {
      ...planTemplate,
      warehouseId: planTemplate.warehouseIds && planTemplate.warehouseIds[0],
      dateOffset: +planTemplate.dateOffset || 0,
      warehouseIds: undefined,
      begin: planTemplate.begin || moment().hours(0).minutes(0).toISOString(),
      end: planTemplate.end || moment().hours(23).minutes(59).toISOString(),
    })
    return response
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

const get = async (id: string): Promise<IPlanTemplate | IError> => {
  try {
    const response = await Api.get(`plan-templates/details/${id}`)
    return {
      ...response,
      warehouseIds: [response.warehouseId],
      deliveryTypes: response.deliveryTypes.map(String),
    }
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const update = async (id: string, planTemplate: IPlanTemplate): Promise<IPlanTemplate | IError> => {
  try {
    const response = await Api.patch(`plan-templates/${id}`, {
      ...planTemplate,
      warehouseId: planTemplate.warehouseIds && planTemplate.warehouseIds[0],
      warehouseIds: undefined,
      dateOffset: +planTemplate.dateOffset || 0,
    })
    return response
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

const deletePlanTemplate = async (id: string): Promise<void | IError> => {
  try {
    const response = await Api.delete(`plan-templates/${id}`)
    return response
  } catch (error) {
    return { error: { message: error.message } }
  }
}

export default {
  getList,
  create,
  get,
  update,
  deletePlanTemplate,
}
