import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    detailsPanel: {
      width: `calc(100% - 60px - 10px)`,
      marginTop: 10,
      marginBottom: 10,
      marginRight: 10,
      marginLeft: 60,
      boxShadow: `inset 0px 0px 0px 2px ${theme.color.primary}`,
      backgroundColor: theme.color.backgroundBody,
    },
    detailsPanelContainer: {
      position: 'relative',
    },
    pinsContainer: {
      position: 'absolute',
      left: 0,
      top: 0,
      width: 60,
      height: '100%',
    },
    filterContainer: {
      display: 'flex',
      flexDirection: 'row',
      position: 'relative',
    },
    noFilter: {
      height: 68,
    },
    filterLeftContainer: {
      marginBottom: 12,
    },
    filterRightContainer: {
      position: 'absolute',
      right: 0,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    filterContent: {
      marginBottom: 12,
    },
    buttonContainer: {
      position: 'absolute',
      right: 0,
      display: 'flex',
      flexDirection: 'row',
    },
    newButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    newButton: {
      marginLeft: 10,
    },
    arrowIcon: {
      color: theme.color.secondary,
    },
    newButtonRightContainer: {
      textAlign: 'right',
    },
    extendedDateFilter: {
      marginBottom: '5px',
    },
    alignFlexStart: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '5px',
    },
    tagsContent: {
      maxWidth: 200,
      wordWrap: 'break-word',
    },
  }),
)
