import React from 'react'
import { useTranslation } from 'react-i18next'

import { FilterKey } from 'constants/filters'
import { CancellationReasonType } from 'constants/constants'
import BaseCancellationsIndicator from './BaseCancellationsIndicator'

const containerId = 'tour-cancellations'
const filterKey = FilterKey.tourCancellations

const TourCancellationsIndicator = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <BaseCancellationsIndicator
      containerId={containerId}
      filterKey={filterKey}
      apiName="getTourCancellations"
      cancellationReasonType={CancellationReasonType.Tour}
      legendLabel={t('CancellationsScreen.numberOfTourCancellationsOverThePeriod')}
      title="CancellationsScreen.tourTitle"
    />
  )
}

export default TourCancellationsIndicator
