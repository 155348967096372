import React, { useState, createContext, ReactNode, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { isIError } from 'api/types'
import { getErrorList } from 'utils/errorUtils'
import { FeedbackContext } from 'store/FeedbackContext'
import { IDispute, IDisputeFilters, IDisputeReasonCode } from 'interfaces/IDispute'
import DisputeApi from 'api/dispute'
import { COLUMNS_MODEL_DISPUTES_LIST } from 'constants/table'
import { AuthContext } from 'store/AuthContext'

interface IDisputeContext {
  disputes: IDispute[]
  disputesCount: number
  searchDisputes: (
    queryParams: IDisputeFilters,
    offset?: number,
    rowsPerPage?: number,
    sortField?: number,
    sortDirection?: string,
  ) => void
  disputeReasonCodes: IDisputeReasonCode[]
  getDisputeReasonCodes: () => void
  updateDisputeReasonCodes: (disputeReasonCodes: IDisputeReasonCode[]) => void
}

const DisputeContext = createContext<IDisputeContext>({} as IDisputeContext)
const { Provider, Consumer } = DisputeContext

interface IProps {
  children: ReactNode
}

const DisputeProvider = ({ children }: IProps): JSX.Element => {
  const { openErrorSnack, openSuccessSnack, toggleLoader } = useContext(FeedbackContext)
  const { getUserData } = useContext(AuthContext)
  const [disputeReasonCodes, setDisputeReasonCodes] = useState<IDisputeReasonCode[]>([])
  const [disputes, setDisputes] = useState<IDispute[]>([])
  const [disputesCount, setDisputesCount] = useState<number>(0)
  const { t } = useTranslation()

  const searchDisputes = async (
    filters: IDisputeFilters,
    offset?: number,
    rowsPerPage?: number,
    sortField?: number,
    sortDirection?: string,
  ): Promise<void> => {
    toggleLoader(true)
    const columnsToUse = COLUMNS_MODEL_DISPUTES_LIST

    const response = await DisputeApi.searchDisputes(
      filters,
      offset,
      rowsPerPage,
      columnsToUse.find((_column, index) => index === sortField)?.field,
      sortDirection === 'desc' ? -1 : 1,
    )
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      setDisputes(response.disputes.map((item) => ({ ...item, siteIds: [item.siteId] })))
      setDisputesCount(response.count)
    }
    toggleLoader(false)
  }

  const getDisputeReasonCodes = async (): Promise<void> => {
    toggleLoader(true)
    const response = await DisputeApi.getDisputeReasonCodes()
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      setDisputeReasonCodes(response)
    }
    toggleLoader(false)
  }

  const updateDisputeReasonCodes = async (types: IDisputeReasonCode[]): Promise<void> => {
    toggleLoader(true)
    const response = await DisputeApi.updateDisputeReasonCodes(types)
    if (isIError(response)) {
      response.error.errorList = getErrorList(response)
      if (response.error.fieldErrors) {
        openErrorSnack(response.error.errorList.join('\n'))
      } else {
        openErrorSnack(response.error.message)
      }
    } else {
      setDisputeReasonCodes(response)
      getUserData(true)
      openSuccessSnack(t('ConfigurationsScreen.successfullySaved'))
    }
    toggleLoader(false)
  }

  return (
    <Provider
      value={{
        disputes,
        disputesCount,
        searchDisputes,
        disputeReasonCodes,
        getDisputeReasonCodes,
        updateDisputeReasonCodes,
      }}
    >
      {children}
    </Provider>
  )
}

export default DisputeProvider

export { Consumer as DisputeConsumer, DisputeContext }
