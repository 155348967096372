import { getDistance } from 'geolib'

import OrdersApi from 'api/orders'
import PlansApi from 'api/plans'
import { IError, isIError } from 'api/types'
import { IWarehouse } from 'interfaces/interfaces'

interface IFilters {
  startDate?: string
  endDate?: string
  warehouseIds?: string[]
  deliveryTypes?: string[]
  transportTypes?: string[]
}

interface IDeletePlanFilters {
  startDate: string
  endDate: string
  deliveryTypes?: string[]
  transportTypes?: string[]
}

interface ICreateVisitsReturn {
  planTrCreated: number
  partiallyTreated: boolean
}

interface IDeletePlanVisitsResponse {
  deleted: number
  remaining: number
  partiallyTreated: boolean
}

interface ISorters {
  sortBy?: string
  destinationSite: IWarehouse
}

async function createPlanVisits({
  startDate,
  endDate,
  deliveryTypes,
  transportTypes,
  warehouseIds,
}: IFilters): Promise<IError | ICreateVisitsReturn> {
  const res = await PlansApi.createVisits({
    startDate,
    endDate,
    deliveryTypes,
    transportTypes,
    warehouseIds,
  })
  if (!isIError(res) && res.partiallyTreated) {
    const newRes = await createPlanVisits({
      startDate,
      endDate,
      deliveryTypes,
      transportTypes,
      warehouseIds,
    })
    return newRes
  }
  return res
}

const deletePlanVisits = async ({
  startDate,
  endDate,
  deliveryTypes,
  transportTypes,
}: IDeletePlanFilters): Promise<IDeletePlanVisitsResponse | IError> => {
  const response = await PlansApi.deletePlanVisits({
    startDate,
    endDate,
    deliveryTypes,
    transportTypes,
  })
  if (!isIError(response) && response.partiallyTreated) {
    const newResponse = await deletePlanVisits({
      startDate,
      endDate,
      deliveryTypes,
      transportTypes,
    })
    return newResponse
  }
  return response
}

async function createSortedPlanVisits({
  destinationSite,
  startDate,
  endDate,
  deliveryTypes,
  transportTypes,
  warehouseIds,
}: ISorters & IFilters): Promise<(IError | ICreateVisitsReturn)[]> {
  // Get all filtered orders
  let filteredOrders
  const ordersFilters = {
    startDate,
    endDate,
    deliveryTypes,
    transportTypes,
    siteIds: warehouseIds,
  }
  const resFilteredOrders = await OrdersApi.getAllOrders(ordersFilters)
  if (!isIError(resFilteredOrders)) {
    filteredOrders = resFilteredOrders.orders
  }

  // Target coordinates
  const targetLatitude = destinationSite.address.latitude
  const targetLongitude = destinationSite.address.longitude

  // Calculate distance between each order and target site coordinates
  const distances = filteredOrders.map((order) =>
    getDistance(
      { latitude: order.location.latitude, longitude: order.location.longitude },
      { latitude: targetLatitude, longitude: targetLongitude },
    ),
  )

  // Sort orders by distance
  const sortedOrders = filteredOrders.sort((a, b) => {
    const indexA = filteredOrders.indexOf(a)
    const indexB = filteredOrders.indexOf(b)
    return distances[indexA] - distances[indexB]
  })

  // Loop over filtered and sorted orders and create visits in batches of 5
  const batchSize = 5
  const results: ICreateVisitsReturn[] = []
  for (let i = 0; i < sortedOrders.length; i += batchSize) {
    const batch = sortedOrders.slice(i, i + batchSize)
    const orderIds = batch.map((order) => order.orderId)
    // eslint-disable-next-line no-await-in-loop
    const result = await PlansApi.createVisits({ orderIds })
    if (!isIError(result)) {
      results.push(result)
    }
  }

  return results
}

export { createPlanVisits, deletePlanVisits, createSortedPlanVisits }
