import React, { useEffect, useState, useMemo, useRef, useContext } from 'react'
import MaterialTable, { MTableHeader, Action } from 'material-table'
import { useHistory } from 'react-router-dom'
import ArrowForward from '@material-ui/icons/ArrowForward'
import RefreshIcon from '@material-ui/icons/Refresh'
import { IconButton } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@material-ui/core/styles'

import { ITableColumn, ISiteFilters, IWarehouse } from 'interfaces/interfaces'
import MUITableIcons from 'constants/MUITableIcons'
import {
  ActiveFilter,
  WarehouseTypeFilter,
  SectorFilter,
  TenantFilter,
  SiteFilter,
} from 'components/Inputs/ListFilter'
import SearchInput from 'components/Inputs/SearchInput'
import Button from 'components/Button/CustomButton'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import { SitesConsumer } from 'store/SitesContext'
import { scrollTop } from 'utils/functions'
import AppConfigProvider, { AppConfigContext } from 'store/AppConfigContext'
import { FiltersContext } from 'store/FiltersContext'
import Pagination from 'components/Table/Pagination'
import { getPageSize, savePageSize } from 'utils/localStorage'
import { Filter } from 'constants/filters'
import { getDisplayRowsCount } from 'utils/tableUtils'

import { AuthContext } from 'store/AuthContext'
import { ContentContext } from 'store/ContentContext'
import { USER_ROLES } from 'constants/constants'
import { FiltersContainer, SearchContainer, TopFiltersContainer } from 'components/Layout'
import useStyles from './styles'

interface ICustomTableProps {
  columns: ITableColumn[]
  getSitesList?: (
    filters?: ISiteFilters,
    offset?: number,
    rowsPerPage?: number,
    sortField?: number,
    sortDirection?: string,
    isForPlanning?: boolean,
    isForAdmin?: boolean,
  ) => void
  sites: IWarehouse[]
  count?: number
  columnConfigName: string
  filterKey: string
  isForPlanning?: boolean
  isForAdmin?: boolean
}

const CustomTable = ({
  columns,
  getSitesList,
  sites,
  count,
  columnConfigName,
  filterKey,
  isForPlanning,
  isForAdmin,
  ...rest
}: ICustomTableProps): JSX.Element => {
  const isInitialMount = useRef(true)

  const [paginationOffset, setPaginationOffset] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(getPageSize)
  const [availableSites, setAvailableSites] = useState<IWarehouse[]>([])
  const { setFilter, resetFilters, filters } = useContext(FiltersContext)
  const active = filters[filterKey][Filter.active] as string[]
  const type = filters[filterKey][Filter.types] as string[]
  const tenant = filters[filterKey][Filter.tenant] as string[]
  const searchText = filters[filterKey][Filter.searchText] as string
  const hasSector = filters[filterKey][Filter.hasSector] as string[]
  const siteIds = filters[filterKey][Filter.sites] as string[]
  const [isSearchReset, setIsSearchReset] = useState<boolean>(false)
  const [sortField, setSortField] = useState<number>(0)
  const [sortDirection, setSortDirection] = useState<string>('asc')
  const styles = useStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const { tablesConfig } = useContext(AppConfigContext)
  const { user } = useContext(AuthContext)
  const { sites: contentSites } = useContext(ContentContext)
  const theme = useTheme()

  useEffect(() => {
    if (rowsPerPage !== getPageSize()) {
      savePageSize(rowsPerPage)
      scrollTop()
    }
    if (getSitesList) {
      getSitesList(
        { active, type, searchText, hasSector, tenant, siteIds },
        0,
        rowsPerPage,
        undefined,
        undefined,
        isForPlanning,
        isForAdmin,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, siteIds, searchText, type, hasSector, rowsPerPage, tenant])

  useEffect(() => {
    if (getSitesList && !isInitialMount.current) {
      getSitesList(
        { active, searchText, type, hasSector, tenant, siteIds },
        paginationOffset,
        rowsPerPage,
        sortField,
        sortDirection,
        isForPlanning,
        isForAdmin,
      )
      scrollTop()
    } else {
      isInitialMount.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationOffset, sortField, sortDirection])

  useEffect(() => {
    if (!isForPlanning || type.length === 0) {
      return setAvailableSites(contentSites)
    }

    const filteredAvailableSites = contentSites.filter((site) =>
      type.includes(site.type?.toString() || ''),
    )
    if (siteIds.length > 0) {
      const filteredAvailableSitesIds = filteredAvailableSites.map((site) => site.id)
      const filteredSelectedSites = siteIds.filter((siteId) =>
        filteredAvailableSitesIds.includes(siteId),
      )
      setFilter(filterKey, Filter.sites, filteredSelectedSites)
    }
    return setAvailableSites(filteredAvailableSites)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, isForPlanning, contentSites])

  const handleNewSiteClick = (): void => {
    history.push(ROUTES_PATH.createSite)
  }

  const resetLocalFilters = (): void => {
    setIsSearchReset(true)
    resetFilters(filterKey)
  }

  const canCreateSite = user?.roles.some((role) =>
    [USER_ROLES.udcAdmin, USER_ROLES.pilot].includes(role),
  )

  const Filters = useMemo(
    (): JSX.Element => (
      <TopFiltersContainer>
        <FiltersContainer>
          <IconButton data-cy="initializeButton" onClick={resetLocalFilters}>
            <RefreshIcon />
          </IconButton>
          {!isForAdmin && (
            <ActiveFilter
              dataCy="activePicker"
              handleChange={(activeValues: string[]): void => {
                setFilter(filterKey, Filter.active, activeValues)
              }}
              ids={active}
            />
          )}
          {isForAdmin && (
            <TenantFilter
              dataCy="tenantPicker"
              handleChange={(tenantValues: string[]): void => {
                setFilter(filterKey, Filter.tenant, tenantValues)
              }}
              ids={tenant}
              placeholder={t('tablesEntries.tenant')}
            />
          )}
          <WarehouseTypeFilter
            dataCy="siteTypePicker"
            handleChange={(typeValues: string[]): void => {
              setFilter(filterKey, Filter.types, typeValues)
            }}
            ids={type}
          />
          {isForPlanning && (
            <SectorFilter
              dataCy="sectorPicker"
              handleChange={(sectorValues: string[]): void => {
                setFilter(filterKey, Filter.hasSector, sectorValues)
              }}
              ids={hasSector}
            />
          )}
          {isForPlanning && (
            <SiteFilter
              handleChange={(values: string[]): void => {
                setFilter(filterKey, Filter.sites, values)
              }}
              ids={siteIds}
              dataCy="sitePicker"
              isUnselectAllowed={false}
              data={availableSites}
              filterKey={filterKey}
            />
          )}
        </FiltersContainer>
        <SearchContainer>
          <SearchInput
            defaultValue={searchText}
            key={isSearchReset ? 'search-reset' : ''}
            onSearch={(text: string): void => {
              setIsSearchReset(false)
              setFilter(filterKey, Filter.searchText, text)
            }}
          />
          {!isForPlanning && !isForAdmin && canCreateSite && (
            <Button dataCy="newButton" className={styles.newButton} onPress={handleNewSiteClick}>
              {t('tablesEntries.new')}
            </Button>
          )}
        </SearchContainer>
      </TopFiltersContainer>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [active, siteIds, type, searchText, hasSector, tenant, styles, availableSites],
  )

  const handleDetailsClick = ({ id }: IWarehouse): void => {
    if (isForPlanning) {
      history.push(`${ROUTES_PATH.sectorDetails}${id}`)
    } else if (isForAdmin) {
      history.push(`${ROUTES_PATH.warehouseAdminDetails}${id}`)
    } else {
      history.push(`${ROUTES_PATH.siteDetails}${id}`)
    }
  }

  const handleChangeRowsPerPage = (pageSize: number): void => {
    setPaginationOffset(0)
    setRowsPerPage(pageSize)
    setPage(0)
  }

  const handleOrderChange = (columnIndex: number): void => {
    setPage(0)
    setPaginationOffset(0)
    setSortField(columnIndex)
    setSortDirection((prevSortDirection) => (prevSortDirection === 'asc' ? 'desc' : 'asc'))
  }

  const displayRowsCount = useMemo(() => getDisplayRowsCount(sites), [sites])

  return (
    <>
      {Filters}
      <MaterialTable
        key={displayRowsCount}
        data={sites}
        columns={columns.map(
          (col) =>
            ({
              ...col,
              hidden: tablesConfig[columnConfigName]?.includes(col.title),
            } as unknown as MaterialTable<object>),
        )}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        {...rest}
        options={{
          toolbar: false,
          pageSize: displayRowsCount,
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: theme.color.tableHeaderBackground,
            borderBottom: '1px solid #A8A8A8',
          },
        }}
        actions={[
          (data: IWarehouse): Action<IWarehouse> => ({
            icon: ArrowForward,
            tooltip: t('tablesEntries.seeDetails'),
            onClick: (): void => handleDetailsClick(data),
          }),
        ]}
        icons={MUITableIcons}
        components={{
          // eslint-disable-next-line react/prop-types, @typescript-eslint/no-unused-vars
          Pagination: ({ classes, ...props }): JSX.Element => (
            <Pagination
              {...props}
              columns={columns}
              columnConfigName={columnConfigName}
              page={page}
              rowsPerPage={rowsPerPage}
              setPage={setPage}
              setPaginationOffset={setPaginationOffset}
              count={count}
            />
          ),
          Header: (props): JSX.Element => (
            <MTableHeader
              {...props}
              onOrderChange={handleOrderChange}
              orderBy={sortField}
              orderDirection={sortDirection}
            />
          ),
        }}
      />
    </>
  )
}

export default (props: ICustomTableProps): JSX.Element => (
  <AppConfigProvider>
    <SitesConsumer>
      {(ctx): JSX.Element => (
        <CustomTable count={ctx.count} getSitesList={ctx.getSitesList} {...props} />
      )}
    </SitesConsumer>
  </AppConfigProvider>
)
