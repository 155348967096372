import React, { useContext, useMemo, useState } from 'react'
import { useTheme } from '@material-ui/core/styles'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { ContentContext } from 'store/ContentContext'
import { IVehicleColumn } from 'interfaces/map'
import { dateToTimeFormat } from 'utils/dateFormat'
import { calculateAmplitude } from 'utils/planningUtils'
import { Header } from '../BasicComponents'

const StyledHeader = styled(Header)`
  background-color: ${(props) => props.theme.color.backgroundNavBar};
`

const Summary = styled.div`
  font-style: italic;
`

interface IProps {
  vehicleColumn: IVehicleColumn
}

const VehicleHeader = ({ vehicleColumn }: IProps): JSX.Element => {
  const { vehicles } = useContext(ContentContext)
  const [mouseIsOverHeader, setMouseIsOverHeader] = useState<boolean>(false)
  const { t } = useTranslation()
  const theme = useTheme()

  const vehicleLabel = useMemo(
    (): string | undefined =>
      vehicles.find((vehicle) => vehicle.id === vehicleColumn.vehicleId)?.name,
    [vehicleColumn, vehicles],
  )

  const totalAmplitude = useMemo((): string => {
    const { planTours } = vehicleColumn
    return calculateAmplitude(
      planTours[0].beginDateTime,
      planTours[planTours.length - 1].endDateTime,
    )
  }, [vehicleColumn])

  const summaries = useMemo((): string[] => {
    const { planTours } = vehicleColumn
    const planToursSummaries: string[] = []
    planTours.forEach((planTour) => {
      const start = dateToTimeFormat(planTour.beginDateTime)
      const end = dateToTimeFormat(planTour.endDateTime)
      const nbOfStops = planTour.stops.length - 2
      const planTourSummary = `${start} ... ${nbOfStops} stops ... ${end}`
      planToursSummaries.push(planTourSummary)
    })
    return planToursSummaries
  }, [vehicleColumn])

  const toggleHover = (mouseIsOver: boolean) => {
    setMouseIsOverHeader(mouseIsOver)
  }

  return (
    <StyledHeader
      square
      elevation={3}
      theme={theme}
      onMouseEnter={() => toggleHover(true)}
      onMouseLeave={() => toggleHover(false)}
    >
      <div data-cy="vehicle-info">
        <b>
          {t('PlanningScreen.vehicle')} {vehicleLabel}
        </b>
        <div>
          {t('PlanningScreen.totalAmplitude')}: {totalAmplitude}
        </div>
      </div>
      {mouseIsOverHeader && (
        <div data-cy="vehicle-summary">
          {summaries.map((summary) => (
            <Summary key={summary}>{summary}</Summary>
          ))}
        </div>
      )}
    </StyledHeader>
  )
}

export default VehicleHeader
