import React, { useEffect, useState, useRef, useContext, useMemo } from 'react'
import MaterialTable, { MTableHeader, Action } from 'material-table'
import ArrowForward from '@material-ui/icons/ArrowForward'
import RefreshIcon from '@material-ui/icons/Refresh'
import { IconButton } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { IExtendedDateProps, ITableColumn } from 'interfaces/interfaces'
import MUITableIcons from 'constants/MUITableIcons'
import AppConfigProvider, { AppConfigContext } from 'store/AppConfigContext'
import { FiltersContext } from 'store/FiltersContext'
import SearchInput from 'components/Inputs/SearchInput'
import { FiltersContainer, SearchContainer, TopFiltersContainer } from 'components/Layout'
import Button from 'components/Button/CustomButton'
import { scrollTop } from 'utils/functions'
import { getPageSize, savePageSize } from 'utils/localStorage'
import Pagination from 'components/Table/Pagination'
import { FilterKey, Filter } from 'constants/filters'
import { getDisplayRowsCount } from 'utils/tableUtils'
import useStyles from './styles'
import ExtendedDateFilters from './ExtendedDateFilters'

interface ICustomTableProps {
  showExtendedDateFilter?: boolean
  extendedDateFilter?: IExtendedDateProps
  columns: ITableColumn[]
  renderDetailPanel?: (rowData?: object) => JSX.Element
  updateList?: (
    filters?: object,
    offset?: number,
    rowsPerPage?: number,
    sortField?: number,
    sortDirection?: string,
  ) => void
  listData: Array<object>
  handleDetailsClick?: (data: unknown) => void
  handleNewClick?: (() => void) | (() => Promise<void>)
  handleRowClick?: (
    event?: React.MouseEvent,
    rowData?: object,
    toggleDetailPanel?: (panelIndex?: number) => void,
  ) => void
  count?: number
  filterKey: FilterKey
  columnConfigName: string
  filterFieldsToDisplay: JSX.Element
  defaultSortDirection: string
  isSearchable?: boolean
}

const CustomTable = ({
  showExtendedDateFilter,
  extendedDateFilter,
  columns,
  renderDetailPanel,
  handleNewClick,
  handleDetailsClick,
  handleRowClick,
  updateList,
  listData,
  count,
  filterKey,
  columnConfigName,
  filterFieldsToDisplay,
  isSearchable,
  defaultSortDirection = 'asc',
  ...rest
}: ICustomTableProps): JSX.Element => {
  const isInitialMount = useRef(true)

  const [paginationOffset, setPaginationOffset] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [sortField, setSortField] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(getPageSize)
  const [sortDirection, setSortDirection] = useState<string>(defaultSortDirection)
  const styles = useStyles()
  const { tablesConfig } = useContext(AppConfigContext)
  const { setFilter, resetFilters, filters } = useContext(FiltersContext)
  const searchText = filters[filterKey][Filter.searchText] as string
  const [isSearchReset, setIsSearchReset] = useState<boolean>(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (rowsPerPage !== getPageSize()) {
      savePageSize(rowsPerPage)
      scrollTop()
    }
    if (updateList) {
      updateList({ searchText }, 0, rowsPerPage, sortField, sortDirection)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters[filterKey], searchText, rowsPerPage])

  useEffect(() => {
    if (updateList && !isInitialMount.current) {
      updateList({}, paginationOffset, rowsPerPage, sortField, sortDirection)
      scrollTop()
    } else {
      isInitialMount.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationOffset, sortField, sortDirection])

  const resetLocalFilters = (): void => {
    setIsSearchReset(true)
    resetFilters(filterKey)
  }

  const renderFilters = (): JSX.Element => (
    <TopFiltersContainer>
      <FiltersContainer>
        <IconButton data-cy="initializeButton" onClick={resetLocalFilters}>
          <RefreshIcon />
        </IconButton>
        {filterFieldsToDisplay}
      </FiltersContainer>
      <SearchContainer className={styles.filterRightContainer}>
        {isSearchable && (
          <SearchInput
            defaultValue={searchText}
            key={isSearchReset ? 'search-reset' : ''}
            onSearch={(text: string): void => {
              setIsSearchReset(false)
              setFilter(filterKey, Filter.searchText, text)
            }}
          />
        )}
        {handleNewClick && (
          <Button dataCy="newButton" className={styles.newButton} onPress={handleNewClick}>
            {t('tablesEntries.new')}
          </Button>
        )}
      </SearchContainer>
    </TopFiltersContainer>
  )

  const handleOrderChange = (columnIndex: number): void => {
    setPage(0)
    setPaginationOffset(0)
    setSortField(columnIndex)
    setSortDirection((prevSortDirection) => (prevSortDirection === 'asc' ? 'desc' : 'asc'))
  }

  const handleChangeRowsPerPage = (pageSize: number): void => {
    setPage(0)
    setPaginationOffset(0)
    setRowsPerPage(pageSize)
  }

  const displayRowsCount = useMemo(() => getDisplayRowsCount(listData), [listData])

  const setEndDate = (value: string): void => {
    setFilter(filterKey, Filter.endDate, value)
  }

  const setStartDate = (value: string): void => {
    setFilter(filterKey, Filter.startDate, value)
  }

  return (
    <>
      {renderFilters()}
      {showExtendedDateFilter && (
        <ExtendedDateFilters
          {...extendedDateFilter}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
        />
      )}
      <MaterialTable
        key={displayRowsCount}
        data={listData}
        columns={columns.map(
          (col) =>
            ({
              ...col,
              hidden: tablesConfig[columnConfigName]?.includes(col.title),
            } as unknown as MaterialTable<object>),
        )}
        {...rest}
        options={{
          toolbar: false,
          pageSize: displayRowsCount,
          actionsColumnIndex: -1,
        }}
        onRowClick={handleRowClick}
        actions={
          handleDetailsClick
            ? [
              (data: unknown): Action<object> => ({
                icon: ArrowForward,
                tooltip: t('tablesEntries.seeDetails'),
                onClick: (): void => handleDetailsClick(data),
              }),
            ]
            : undefined
        }
        detailPanel={renderDetailPanel}
        icons={MUITableIcons}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        components={{
          // eslint-disable-next-line react/prop-types, @typescript-eslint/no-unused-vars
          Pagination: ({ classes, ...props }): JSX.Element => (
            <Pagination
              {...props}
              columnConfigName={columnConfigName}
              columns={columns}
              count={count}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setPaginationOffset={setPaginationOffset}
            />
          ),
          Header: (props): JSX.Element => (
            <MTableHeader
              {...props}
              onOrderChange={handleOrderChange}
              orderBy={sortField}
              orderDirection={sortDirection}
            />
          ),
        }}
      />
    </>
  )
}

export default (props: ICustomTableProps): JSX.Element => (
  <AppConfigProvider>
    <CustomTable {...props} />
  </AppConfigProvider>
)
