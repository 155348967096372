import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import moment from 'moment'
import 'moment/locale/fr'
import useInterval from '@use-it/interval'
import MaterialTable, { Action, MTableHeader } from 'material-table'
import { useTranslation } from 'react-i18next'
import RefreshIcon from '@material-ui/icons/Refresh'
import { IconButton } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'

import DateRangePicker from 'components/Inputs/DateRangePicker'
import {
  CarrierFilter,
  StatusFilter,
  VehicleCatFilter,
  SiteFilter,
  DeliveryTypeFilter,
  PlanTourStatusFilter,
} from 'components/Inputs/ListFilter'
import MUITableIcons from 'constants/MUITableIcons'
import { AVAILABLE_FILTERS, AUTO_RELOAD_TIMER, TOUR_STATUS_ENUM } from 'constants/constants'
import AppConfigProvider, { AppConfigContext } from 'store/AppConfigContext'
import { FiltersContext } from 'store/FiltersContext'
import { IExtendedDateProps, ITableColumn, ITourFilters } from 'interfaces'
import { ITour, IUpdateTourVisits } from 'interfaces/Itours'
import { ToursContext } from 'store/ToursContext'
import SearchInput from 'components/Inputs/SearchInput'
import { FiltersContainer, SearchContainer, TopFiltersContainer } from 'components/Layout'
import { scrollTop } from 'utils/functions'
import { getPageSize, savePageSize } from 'utils/localStorage'
import Pagination from 'components/Table/Pagination'
import { IPlanTour, IPlanTourDeleteResponse } from 'interfaces/IPlan'
import { ITableSelectionAction } from 'interfaces/ITableSelectionAction'
import { PlansContext } from 'store/PlansContext'
import { FeedbackContext } from 'store/FeedbackContext'
import { isIError } from 'api/types'
import PlansApi from 'api/plans'
import ToursApi from 'api/tours'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog'
import { Filter } from 'constants/filters'
import useStyles from 'components/Table/styles'
import { ContentContext } from 'store/ContentContext'
import { useLocation } from 'react-router-dom'
import { getDisplayRowsCount, setColumnDisplay } from 'utils/tableUtils'
import SettingsIcon from '@material-ui/icons/Settings'
import EditVisitsDialog from 'components/Dialog/EditVisitsDialog'
import DetailsPanel, { ExtendedDetailsPanel, PlanningExtendedDetailsPanel } from './DetailsPanel'
import ExtendedDateFilters from './ExtendedDateFilters'

interface ICustomTableProps {
  showExtendedDateFilter?: boolean
  extendedDateFilter?: IExtendedDateProps
  columns: ITableColumn[]
  data: Array<ITour | IPlanTour>
  count?: number
  extendedDetails?: boolean
  hideFilters?: AVAILABLE_FILTERS[]
  updateTours?: (
    filters?: ITourFilters,
    offset?: number,
    rowsPerPage?: number,
    sortField?: number,
    sortDirection?: string,
  ) => void
  autoReload?: boolean
  name: string
  isForPlanning?: boolean
  filterKey: string
  detailsPanelColumnsToFilter?: ITableColumn[]
  hasEditVisitsDialog?: boolean
}

interface ILocation {
  startDateFilter?: Date | null
  endDateFilter?: Date | null
}

function CustomTable({
  showExtendedDateFilter,
  extendedDateFilter,
  columns,
  hideFilters,
  extendedDetails,
  autoReload,
  data,
  name,
  isForPlanning,
  filterKey,
  detailsPanelColumnsToFilter,
  hasEditVisitsDialog = false,
  ...rest
}: ICustomTableProps): JSX.Element {
  const isInitialMount = useRef(true)

  const { setFilter, resetFilters, filters } = useContext(FiltersContext)

  const startDate = filters[filterKey][Filter.startDate] as string
  const endDate = filters[filterKey][Filter.endDate] as string
  const shift = filters[filterKey][Filter.shift] as string
  const carriers = filters[filterKey][Filter.carriers] as string[]
  const sites = filters[filterKey][Filter.sites] as string[]
  const deliveryTypes = filters[filterKey][Filter.deliveryTypes] as string[]
  const vehicleTypeIds = filters[filterKey][Filter.vehicleTypes] as string[]
  const status = filters[filterKey][Filter.status] as string[]
  const searchText = filters[filterKey][Filter.searchText] as string

  const [rowsPerPage, setRowsPerPage] = useState<number>(getPageSize)
  const [paginationOffset, setPaginationOffset] = useState<number>(0)
  const [localData, setLocalData] = useState<Array<ITour | IPlanTour>>([])
  const [isSearchReset, setIsSearchReset] = useState<boolean>(false)
  const [sortField, setSortField] = useState<number>(0)
  const [sortDirection, setSortDirection] = useState<string>('asc')
  const [selectedPlanTourIds, setSelectedPlanTourIds] = useState<string[]>([])
  const [isInterfaceConfirmationModalOpen, setIsInterfaceConfirmationModalOpen] = useState<boolean>(false)
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState<boolean>(false)
  const [isDeleteTourFinishModalOpen, setIsDeleteTourFinishModalOpen] = useState<boolean>(false)
  const [deleteTourFinishMessage, setDeleteTourFinishMessage] = useState<string>('')
  const [page, setPage] = useState<number>(0)
  const [selectedTour, setSelectedTour] = useState<ITour>()
  const { count: tourCount, updateTours } = useContext(ToursContext)
  const { count: planTourCount, updatePlanTours } = useContext(PlansContext)
  const { openErrorSnack, toggleLoader, openSuccessSnack } = useContext(FeedbackContext)
  const { tablesConfig } = useContext(AppConfigContext)
  const contentContext = useContext(ContentContext)

  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const searchId = query.get('searchTerm')
  const { startDateFilter, endDateFilter } = (location.state as ILocation) || {
    startDateFilter: null,
    endDateFilter: null,
  }

  const { t } = useTranslation()
  const theme = useTheme()
  const styles = useStyles()

  const update: Function = isForPlanning ? updatePlanTours : updateTours

  const getList = (offset = paginationOffset): void => {
    update(
      {
        carriers,
        warehouses: sites,
        vehicleTypeIds,
        status,
        startDate,
        endDate,
        searchText,
        deliveryTypes,
      },
      offset,
      rowsPerPage,
      sortField,
      sortDirection,
    )
  }

  const wrapperUpdate = (): void => {
    if (update && autoReload) {
      getList()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  useInterval(wrapperUpdate, AUTO_RELOAD_TIMER)

  useEffect(() => {
    if (rowsPerPage !== getPageSize()) {
      savePageSize(rowsPerPage)
      scrollTop()
    }
    if (update) {
      getList(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    carriers,
    sites,
    vehicleTypeIds,
    status,
    startDate,
    endDate,
    searchText,
    deliveryTypes,
    rowsPerPage,
  ])

  useEffect(() => {
    if (update && !isInitialMount.current) {
      getList()
      scrollTop()
    } else {
      isInitialMount.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationOffset, sortField, sortDirection])

  const checkProcessingPlans = async (): Promise<boolean | null> => {
    let isStillProcessing = true
    while (isStillProcessing) {
      // eslint-disable-next-line no-await-in-loop
      const response = await PlansApi.getProcessingPlans({ planIds: selectedPlanTourIds })
      if (!isIError(response)) {
        isStillProcessing = Boolean(response.filter((plan) => plan.isProcessing).length)
      } else {
        openErrorSnack(response.error.message)
        return null
      }
    }
    return isStillProcessing
  }

  const resetLocalFilters = (): void => {
    setIsSearchReset(true)
    resetFilters(filterKey)
  }

  const getValidCarrierIdsList = (selectedSites: string[] = sites) => {
    const selectedSitesDetails = contentContext.sites.filter((x) => selectedSites.includes(x.id))
    let carrierIds: string[] = []
    if (selectedSitesDetails) {
      selectedSitesDetails.forEach((site) => {
        carrierIds = carrierIds.concat(site.carriers ? site.carriers.map((item) => item.id) : [])
      })
    }
    return Array.from(new Set(carrierIds))
  }

  useEffect(() => {
    if (searchId) {
      setIsSearchReset(true)
      setFilter(filterKey, Filter.searchText, searchId)
      if (startDateFilter) {
        const startDateFilterValue = moment(startDateFilter)
          .hours(0)
          .minutes(0)
          .seconds(0)
          .milliseconds(0)
          .toISOString()
        setFilter(filterKey, Filter.startDate, startDateFilterValue)
      }
      if (endDateFilter) {
        const endDateFilterValue = moment(endDateFilter)
          .hours(23)
          .minutes(59)
          .seconds(59)
          .milliseconds(999)
          .toISOString()
        setFilter(filterKey, Filter.endDate, endDateFilterValue)
      }
      return () => {
        if (searchId) {
          resetFilters(filterKey)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchId])

  const Filters = useMemo((): JSX.Element | null => {
    if (hideFilters && hideFilters?.length === Object.keys(AVAILABLE_FILTERS).length / 2) {
      return null
    }

    return (
      <TopFiltersContainer>
        <FiltersContainer>
          <IconButton data-cy="initializeButton" onClick={resetLocalFilters}>
            <RefreshIcon />
          </IconButton>
          {(!hideFilters || !hideFilters?.includes(AVAILABLE_FILTERS.DATE_RANGE)) && (
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              shift={shift}
              onChangeStart={(startValue): void => {
                setFilter(filterKey, Filter.startDate, moment(startValue).toISOString())
              }}
              onChangeEnd={(endValue): void => {
                setFilter(filterKey, Filter.endDate, moment(endValue).toISOString())
              }}
              onShiftChange={(shiftValue) => setFilter(filterKey, Filter.shift, shiftValue)}
            />
          )}
          {(!hideFilters || !hideFilters?.includes(AVAILABLE_FILTERS.SITES)) && (
            <SiteFilter
              handleChange={(values: string[]): void => {
                setFilter(filterKey, Filter.sites, values)
              }}
              ids={sites}
              dataCy="sitePicker"
              isUnselectAllowed={false}
            />
          )}
          {(!hideFilters || !hideFilters?.includes(AVAILABLE_FILTERS.VEHICLE_TYPES)) && (
            <VehicleCatFilter
              handleChange={(vehicleTypeValues: string[]): void => {
                setFilter(filterKey, Filter.vehicleTypes, vehicleTypeValues)
              }}
              ids={vehicleTypeIds}
              dataCy="vehicleTypePicker"
            />
          )}
          {(!hideFilters || !hideFilters?.includes(AVAILABLE_FILTERS.CARRIERS)) && (
            <CarrierFilter
              handleChange={(carrierValues: string[]): void => {
                setFilter(filterKey, Filter.carriers, carrierValues)
              }}
              ids={carriers}
              dataCy="carrierPicker"
              filterIds={getValidCarrierIdsList()}
              startDate={startDate}
              endDate={endDate}
              siteIds={sites}
            />
          )}
          {(!hideFilters || !hideFilters?.includes(AVAILABLE_FILTERS.DELIVERY_TYPES)) && (
            <DeliveryTypeFilter
              handleChange={(values: string[]): void => {
                setFilter(filterKey, Filter.deliveryTypes, values)
              }}
              ids={deliveryTypes}
              dataCy="deliveryPicker"
            />
          )}
          {(!hideFilters || !hideFilters?.includes(AVAILABLE_FILTERS.STATUS))
            && (isForPlanning ? (
              <PlanTourStatusFilter
                handleChange={(statusValues: string[]): void => {
                  setFilter(filterKey, Filter.status, statusValues)
                }}
                ids={status}
                dataCy="statusPicker"
              />
            ) : (
              <StatusFilter
                handleChange={(statusValues: string[]): void => {
                  setFilter(filterKey, Filter.status, statusValues)
                }}
                ids={status}
                dataCy="statusPicker"
              />
            ))}
        </FiltersContainer>
        <SearchContainer>
          <SearchInput
            defaultValue={searchText}
            key={isSearchReset ? 'search-reset' : ''}
            onSearch={(text: string): void => {
              setIsSearchReset(false)
              setFilter(filterKey, Filter.searchText, text)
            }}
          />
        </SearchContainer>
      </TopFiltersContainer>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    carriers,
    sites,
    vehicleTypeIds,
    status,
    startDate,
    endDate,
    searchText,
    deliveryTypes,
    styles,
  ])

  useEffect(() => {
    if (data) {
      setLocalData((prev) =>
        isForPlanning
          ? (data as unknown as IPlanTour[]).map((elem) => ({
            ...elem,
            tableData: (prev as unknown as IPlanTour[]).find(
              (e) => e.planToursId === elem.planToursId,
            )?.tableData,
          }))
          : (data as unknown as ITour[]).map((elem) => ({
            ...elem,
            tableData: (prev as unknown as ITour[]).find((e) => e.tourId === elem.tourId)
              ?.tableData,
          })),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data)])

  const expendRow = (index?: number): void => {
    if (index) {
      setLocalData((prevData) => {
        const newData = prevData.map((d) => ({ ...d }))
        if (newData[index]) {
          // @ts-ignore
          newData[index].tableData.detailPanel = extendedDetails
            ? ExtendedDetailsPanel
            : DetailsPanel
        }
        return newData
      })
    }
  }

  const handleChangeRowsPerPage = (pageSize: number): void => {
    setRowsPerPage(pageSize)
    setPaginationOffset(0)
    setPage(0)
  }

  const handleOrderChange = (columnIndex: number): void => {
    setPage(0)
    setPaginationOffset(0)
    setSortField(columnIndex)
    setSortDirection((prevSortDirection) => (prevSortDirection === 'asc' ? 'desc' : 'asc'))
  }

  const getDetailPanel = (rowData: ITour | IPlanTour): React.ReactNode => {
    if (isForPlanning) {
      return <PlanningExtendedDetailsPanel rowData={rowData as IPlanTour} />
    }
    return extendedDetails ? (
      <ExtendedDetailsPanel rowData={rowData as ITour} tableName={name} />
    ) : (
      <DetailsPanel rowData={rowData as ITour} tableName={name} />
    )
  }

  const validatePlanTours = async (): Promise<void> => {
    toggleLoader(true)
    const response = await PlansApi.validatePlanTours(selectedPlanTourIds)
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      openSuccessSnack(t('PlanToursScreen.validatedSuccessMessage'))
      getList()
    }
    toggleLoader(false)
  }

  const handleInterfaceConfirmation = async (validate: boolean): Promise<void> => {
    setIsInterfaceConfirmationModalOpen(false)
    if (validate) {
      toggleLoader(true)
      const response = await PlansApi.interfacePlanTours(selectedPlanTourIds)
      if (isIError(response)) {
        openErrorSnack(response.error.message)
      } else {
        openSuccessSnack(t('PlanToursScreen.interfacedSuccessMessage'))
        getList()
      }
      toggleLoader(false)
    }
  }

  const handleEditVisitsDialogSubmit = async (
    tourId: string,
    visits: IUpdateTourVisits[],
  ): Promise<void> => {
    if (!tourId || !visits || !visits.length) return setSelectedTour(undefined)

    toggleLoader(true)
    const response = await ToursApi.updateVisits(tourId, visits)
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      setSelectedTour(undefined)
      openSuccessSnack(t('editVisitsDialog.success'))
      getList()
    }
    toggleLoader(false)
  }

  const handleDeleteTourConfirmation = async (validate: boolean): Promise<void> => {
    if (!validate) {
      return setIsDeleteConfirmationModalOpen(false)
    }
    setIsDeleteConfirmationModalOpen(false)
    toggleLoader(true)
    const isStillProcessing = await checkProcessingPlans()
    if (isStillProcessing === false) {
      const response = await PlansApi.deletePlanTours(selectedPlanTourIds)
      if (isIError(response)) {
        openErrorSnack(response.error.message)
      } else {
        const { notDeletePlanTours } = response as unknown as IPlanTourDeleteResponse
        if (
          notDeletePlanTours
          && Array.isArray(notDeletePlanTours)
          && notDeletePlanTours.length > 0
        ) {
          setDeleteTourFinishMessage(
            t('PlanningScreen.notDeletedToursMessage', {
              stringifiedTrips: notDeletePlanTours.map((item) => item.planTourNumber).join(', '),
            }),
          )
          setIsDeleteTourFinishModalOpen(true)
        } else {
          openSuccessSnack(t('PlanToursScreen.deletedSuccessMessage'))
        }
        getList()
      }
      toggleLoader(false)
    }
  }

  const interfacePlanTours = (): void => {
    setIsInterfaceConfirmationModalOpen(true)
  }

  const deletePlanTours = (): void => {
    setIsDeleteConfirmationModalOpen(true)
  }

  const toggleEditVisitsDialog = (tour: ITour | undefined): void => {
    if (!tour) return setSelectedTour(undefined)
    const tourExists = localData.find((tourToFind) => tourToFind.tourNumber === tour.tourNumber)
    if (!tourExists) return setSelectedTour(undefined)
    return setSelectedTour(tour)
  }

  const planTourActions: ITableSelectionAction[] = [
    { label: t('PlanToursScreen.validate'), action: validatePlanTours },
    { label: t('PlanToursScreen.interface'), action: interfacePlanTours },
    { label: t('PlanToursScreen.delete'), action: deletePlanTours },
  ]

  const displayRowsCount = useMemo(() => getDisplayRowsCount(localData), [localData])
  const displayedColumns = columns.map((column) => setColumnDisplay(column, tablesConfig[name]))

  const setEndDate = (value: string): void => {
    setFilter(filterKey, Filter.endDate, value)
  }

  const setStartDate = (value: string): void => {
    setFilter(filterKey, Filter.startDate, value)
  }

  const getPaginationColumnList = (): ITableColumn[] => {
    if (detailsPanelColumnsToFilter) return [...columns, ...detailsPanelColumnsToFilter]
    return columns
  }

  return (
    <>
      {Filters}
      {showExtendedDateFilter && (
        <ExtendedDateFilters
          {...extendedDateFilter}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
        />
      )}
      <MaterialTable
        key={displayRowsCount}
        data={localData}
        actions={
          hasEditVisitsDialog
            ? [
              (col: ITour | IPlanTour): Action<ITour | IPlanTour> => ({
                hidden: col.status !== TOUR_STATUS_ENUM.REFUSED,
                icon: SettingsIcon,
                tooltip: t('tablesEntries.edit'),
                onClick: (): void => toggleEditVisitsDialog(col as ITour),
              }),
            ]
            : []
        }
        columns={displayedColumns}
        {...rest}
        options={{
          toolbar: false,
          pageSize: displayRowsCount,
          selection: isForPlanning && !!localData.length,
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: theme.color.tableHeaderBackground,
            borderBottom: '1px solid #A8A8A8',
          },
        }}
        onSelectionChange={(rows: Array<ITour | IPlanTour>): void =>
          setSelectedPlanTourIds((rows as IPlanTour[]).map((planTour) => planTour.planToursId))}
        icons={MUITableIcons}
        onRowClick={(event, rowData, togglePanel): void => {
          expendRow(rowData?.tableData?.id)
          if (togglePanel) {
            togglePanel()
          }
        }}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        detailPanel={getDetailPanel}
        components={{
          // eslint-disable-next-line react/prop-types, @typescript-eslint/no-unused-vars
          Pagination: ({ classes, ...props }): JSX.Element => (
            <Pagination
              {...props}
              columns={getPaginationColumnList()}
              page={page}
              setPage={setPage}
              count={isForPlanning ? planTourCount : tourCount}
              rowsPerPage={rowsPerPage}
              setPaginationOffset={setPaginationOffset}
              columnConfigName={name}
              selectionActions={planTourActions}
              showActionButton={selectedPlanTourIds.length > 0}
            />
          ),
          Header: (props): JSX.Element => (
            <MTableHeader
              {...props}
              onOrderChange={handleOrderChange}
              orderBy={sortField}
              orderDirection={sortDirection}
            />
          ),
        }}
      />
      {isInterfaceConfirmationModalOpen && (
        <ConfirmationDialog
          open={isInterfaceConfirmationModalOpen}
          onClose={handleInterfaceConfirmation}
          message={t('PlanToursScreen.interfacePlanTourConfirmationMessage')}
        />
      )}
      <EditVisitsDialog
        open={!!selectedTour?.tourNumber}
        onClose={handleEditVisitsDialogSubmit}
        tour={selectedTour}
      />
      {isDeleteConfirmationModalOpen && (
        <ConfirmationDialog
          open={isDeleteConfirmationModalOpen}
          onClose={handleDeleteTourConfirmation}
          message={t('PlanToursScreen.deletePlanTourConfirmationMessage')}
        />
      )}
      {isDeleteTourFinishModalOpen && (
        <ConfirmationDialog
          open={isDeleteTourFinishModalOpen}
          onClose={() => {
            setDeleteTourFinishMessage('')
            setIsDeleteTourFinishModalOpen(false)
          }}
          message={deleteTourFinishMessage}
          showCancelOption={false}
        />
      )}
    </>
  )
}

export default (props: ICustomTableProps): JSX.Element => (
  <AppConfigProvider>
    <CustomTable {...props} />
  </AppConfigProvider>
)
