import React, { useState, useEffect, useMemo, useContext } from 'react'
import moment from 'moment'
import { IconButton } from '@material-ui/core'
import {
  Chart,
  Legend,
  Title,
  ValueAxis,
  ArgumentAxis,
  BarSeries,
  Tooltip,
  PieSeries,
} from '@devexpress/dx-react-chart-material-ui'
import { Stack, Animation, EventTracker, SeriesRef, HoverState } from '@devexpress/dx-react-chart'
import { connectProps } from '@devexpress/dx-react-core'
import { Skeleton } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'
import RefreshIcon from '@material-ui/icons/Refresh'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

import ChartProgress from 'components/Chart/ChartProgress'
import DateRangePicker from 'components/Inputs/DateRangePicker'
import { CarrierFilter, DriverFilter, SiteFilter, UseFilter } from 'components/Inputs/ListFilter'
import { IDailyStats } from 'interfaces/IDailyStats'
import ToursApi from 'api/tours'
import { isIError } from 'api/types'
import Export from 'components/Chart/Export'
import { FiltersContext } from 'store/FiltersContext'
import { MOBILE_APP_INDICATOR_TOUR_STATUS, UseType } from 'constants/constants'
import { IChartData } from 'interfaces/IChartData'
import { FilterKey, Filter } from 'constants/filters'
import VerticalLabel from 'screens/ReportingCategory/components/VerticalLabel'
import StyledPaper from 'screens/ReportingCategory/components/StyledPaper'
import CustomLegend from 'screens/ReportingCategory/components/CustomLegend'
import { FiltersContainer, TopFiltersContainer } from 'components/Layout'
import ExtendedDateFilters from 'components/Table/ExtendedDateFilters'
import {
  getChartTitle,
  setDateFactory,
  setSelectedDateFilterShortcutFactory,
} from 'utils/extendedDateFilter'

const mobileAppMonitoringContainerId = 'mobile-app-monitoring'
const mobileAppSummaryContainerId = 'mobile-app-monitoring-summary'
const filterKey = FilterKey.mobileApp

type ITooltipProps = Tooltip.ContentProps & { data: IDailyStats[] }

const CustomTooltipContent = ({ targetItem, data }: ITooltipProps): JSX.Element => (
  <Tooltip.Content text={data[targetItem.series]} targetItem={targetItem} />
)

const MobileAppIndicator = (): JSX.Element => {
  const { setFilter, resetFilters, filters } = useContext(FiltersContext)

  const startDate = filters[filterKey][Filter.startDate] as string
  const endDate = filters[filterKey][Filter.endDate] as string
  const siteIds = filters[filterKey][Filter.sites] as string[]
  const carrierIds = filters[filterKey][Filter.carriers] as string[]
  const driverIds = filters[filterKey][Filter.drivers] as string[]
  const useIds = filters[filterKey][Filter.uses] as string[]
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [statData, setStatData] = useState<IDailyStats[]>([])
  const [filteredStats, setFilteredStats] = useState<IDailyStats[]>([])
  const ordinateIds: string[] = MOBILE_APP_INDICATOR_TOUR_STATUS.map((status) => status.id)
  const [target, setTarget] = useState<SeriesRef | undefined>()
  const [selectedDateFilterShortcut, setSelectedDateFilterShortcut] = useState('')
  const [summary, setSummary] = useState<IChartData[]>([])
  const { t } = useTranslation()

  const filterByUseType = (data: IDailyStats[]): void => {
    setFilteredStats(() =>
      useIds.length === 1
        ? data.map((stat) => ({
          ...stat,
          [useIds[0] === UseType.Unused ? UseType.Used : UseType.Unused]: 0,
        }))
        : data,
    )
  }

  const formatSummary = (data: IDailyStats[]): void => {
    let unusedCount = 0
    let usedCount = 0
    data.forEach((stat) => {
      Object.entries(stat).forEach(([key, value]) => {
        if (key === UseType.Unused) {
          unusedCount += +value
        } else if (key === UseType.Used) {
          usedCount += +value
        }
      })
    })
    const total = unusedCount + usedCount
    setSummary([
      {
        label: MOBILE_APP_INDICATOR_TOUR_STATUS[0].id,
        value: +((unusedCount / total) * 100).toFixed(2),
      },
      {
        label: MOBILE_APP_INDICATOR_TOUR_STATUS[1].id,
        value: +((usedCount / total) * 100).toFixed(2),
      },
    ])
  }

  const getStats = async (): Promise<void> => {
    setIsLoading(true)
    const response = await ToursApi.getMobileAppStats(
      startDate,
      endDate,
      carrierIds,
      driverIds,
      siteIds,
    )
    if (!isIError(response)) {
      setStatData(response)
      formatSummary(response)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    getStats()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, carrierIds, driverIds, siteIds])

  useEffect(() => {
    filterByUseType(statData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useIds, statData])

  useEffect(() => {
    formatSummary(filteredStats)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredStats])

  const getLabel = (id: string): string =>
    MOBILE_APP_INDICATOR_TOUR_STATUS.find((status) => status.id === id)?.name || ''

  const getTotalCount = (stats: IDailyStats[]): number => {
    let total = 0
    stats.forEach((stat) => {
      Object.entries(stat).forEach(([key, value]) => {
        if (key !== 'day') {
          total += +value
        }
      })
    })
    return total
  }

  const handleHoverChange = (series: SeriesRef | null): void =>
    setTarget(series ? ({ point: series?.point } as SeriesRef) : undefined)

  const memoizedTotal = useMemo(() => getTotalCount(filteredStats), [filteredStats])

  const EnhancedLegend = connectProps(CustomLegend, () => ({
    total: memoizedTotal,
    label: t('MobileAppIndicatorScreen.totalNumberOfTours'),
  }))

  const TooltipContent = connectProps(CustomTooltipContent, () => ({
    data: target ? filteredStats[target.point] : null,
  }))

  const LegendLabel = ({ text }: Legend.LabelProps): JSX.Element => (
    <Legend.Label text={getLabel(text as string)} />
  )

  const resetLocalFilters = (): void => resetFilters(filterKey)

  const { setEndDate, setStartDate } = setDateFactory(setFilter, filterKey)

  const { fnLast3months, fnLastMonth, fnLast7days, fnNext7days, fnThisMonth } = setSelectedDateFilterShortcutFactory(
    setSelectedDateFilterShortcut,
  )

  const chartTitleSummary = getChartTitle(
    'MobileAppIndicatorScreen.appMobileSummary',
    selectedDateFilterShortcut,
  )
  const chartTitleMonitoring = getChartTitle(
    'menu.subcategories.mobileAppMonitoring',
    selectedDateFilterShortcut,
  )

  return (
    <>
      <TopFiltersContainer>
        <FiltersContainer>
          <IconButton onClick={resetLocalFilters}>
            <RefreshIcon />
          </IconButton>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onChangeStart={(startValue: MaterialUiPickersDate): void => {
              setFilter(filterKey, Filter.startDate, moment(startValue).toISOString())
              setSelectedDateFilterShortcut('')
            }}
            onChangeEnd={(endValue: MaterialUiPickersDate): void => {
              setFilter(filterKey, Filter.endDate, moment(endValue).toISOString())
              setSelectedDateFilterShortcut('')
            }}
            hideShiftPicker
          />
          <SiteFilter
            handleChange={(siteValues: string[]): void => {
              setFilter(filterKey, Filter.sites, siteValues)
            }}
            ids={siteIds}
            placeholder={t('MobileAppIndicatorScreen.site')}
            dataCy="sitePicker"
            isUnselectAllowed={false}
          />
          <CarrierFilter
            handleChange={(carrierValues: string[]): void => {
              setFilter(filterKey, Filter.carriers, carrierValues)
            }}
            ids={carrierIds}
            siteIds={siteIds}
            filterKey={filterKey}
          />
          <DriverFilter
            handleChange={(driverValues: string[]): void => {
              setFilter(filterKey, Filter.drivers, driverValues)
            }}
            ids={driverIds}
            siteIds={siteIds}
            carrierIds={carrierIds}
            filterKey={filterKey}
          />
          <UseFilter
            handleChange={(useValues: string[]): void => {
              setFilter(filterKey, Filter.uses, useValues)
            }}
            ids={useIds}
            placeholder={t('MobileAppIndicatorScreen.use')}
          />
        </FiltersContainer>
      </TopFiltersContainer>
      <ExtendedDateFilters
        fnLast3months={fnLast3months}
        fnLastMonth={fnLastMonth}
        fnLast7days={fnLast7days}
        fnNext7days={fnNext7days}
        fnThisMonth={fnThisMonth}
        last3months
        lastMonth
        last7days
        next7days
        thisMonth
        setEndDate={setEndDate}
        setStartDate={setStartDate}
      />
      {isLoading && (
        <Skeleton
          animation="wave"
          variant="circle"
          height={400}
          width={400}
          style={{ margin: 'auto', marginTop: 60 }}
        />
      )}
      {summary && !isLoading && (
        <StyledPaper id={mobileAppSummaryContainerId}>
          <Chart data={summary}>
            <PieSeries valueField="value" argumentField="label" />
            <Title text={chartTitleSummary} />
            <Legend position="bottom" rootComponent={EnhancedLegend} labelComponent={LegendLabel} />
            <EventTracker />
            <Tooltip />
            {/* TODO: use another fileName */}
            <Export
              containerId={mobileAppSummaryContainerId}
              fileName={mobileAppSummaryContainerId}
            />
          </Chart>
        </StyledPaper>
      )}
      {isLoading && <ChartProgress count={24} />}
      {filteredStats && !isLoading && (
        <StyledPaper id={mobileAppMonitoringContainerId}>
          <Chart data={filteredStats}>
            <Title text={chartTitleMonitoring} />
            <ArgumentAxis labelComponent={VerticalLabel} />
            <ValueAxis showGrid showLabels showLine showTicks />

            {ordinateIds.map((id) => (
              <BarSeries key={id} name={id} valueField={id} argumentField="day" />
            ))}

            <Animation />
            <Stack stacks={[{ series: [...ordinateIds] }]} />
            <EventTracker />
            <HoverState hover={target} onHoverChange={handleHoverChange} />
            <Tooltip contentComponent={TooltipContent} />
            <Export
              containerId={mobileAppMonitoringContainerId}
              fileName={mobileAppMonitoringContainerId}
            />
          </Chart>
        </StyledPaper>
      )}
    </>
  )
}

export default MobileAppIndicator
