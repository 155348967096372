import React, { useState, ReactNode, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { IUserFilters } from 'interfaces'
import { isIError } from 'api/types'
import UsersApi from 'api/user'
import { IUser } from 'interfaces/IUser'
import { IUserInfos } from 'interfaces/IUserInfos'
import { getErrorList } from 'utils/errorUtils'
import { FeedbackContext } from 'store/FeedbackContext'
import { COLUMNS_MODEL_USERS_LIST } from 'constants/table'
import { AuthContext } from 'store/AuthContext'

interface IUsersContext {
  users: IUser[]
  count: number
  updateUsers: (
    filters?: IUserFilters,
    offset?: number,
    rowsPerPage?: number,
    sortField?: number,
    sortDirection?: string,
    isAdmin?: boolean,
  ) => void
  createUser: (user: IUser) => void
  getDetails: (id: string, isAdmin?: boolean) => void
  userDetails: IUserInfos
  updateUser: (id: string, user: IUser) => void
  createSuccess?: boolean
  updateSuccess?: boolean
}

const UsersContext = React.createContext<IUsersContext>({} as IUsersContext)
const { Provider, Consumer } = UsersContext

interface IProps {
  children: ReactNode
}

const UsersProvider = ({ children }: IProps): JSX.Element => {
  const [users, setUsers] = useState<IUser[]>([])
  const [count, setCount] = useState<number>(0)
  const [updateSuccess, setUpdateSuccess] = useState<boolean | undefined>()
  const [createSuccess, setCreateSuccess] = useState<boolean | undefined>()
  const [userDetails, setUserDetails] = useState<IUserInfos>({} as IUserInfos)
  const { openErrorSnack, openSuccessSnack, toggleLoader } = useContext(FeedbackContext)
  const { user: currentUser, setUser: setCurrentUser } = useContext(AuthContext)
  const { t } = useTranslation()

  const updateUsers = async (
    filters?: IUserFilters,
    offset?: number,
    rowsPerPage?: number,
    sortField?: number,
    sortDirection?: string,
    isAdmin = false,
  ): Promise<void> => {
    toggleLoader(true)
    const response = await UsersApi.getUsers(
      filters,
      offset,
      rowsPerPage,
      COLUMNS_MODEL_USERS_LIST.find((_column, index) => index === sortField)?.field,
      sortDirection === 'desc' ? -1 : 1,
      isAdmin,
    )
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      setUsers(response.users)
      setCount(response.count)
    }
    toggleLoader(false)
  }

  const createUser = async (user: IUser): Promise<void> => {
    toggleLoader(true)
    const response = await UsersApi.create(user)
    if (isIError(response)) {
      response.error.errorList = getErrorList(response)

      if (response.error.fieldErrors) {
        openErrorSnack(response.error.errorList.join('\n'))
      } else {
        openErrorSnack(response.error.message)
      }
    } else {
      setCreateSuccess(true)
      openSuccessSnack(t('UsersScreen.createdUser'))
    }
    toggleLoader(false)
  }

  const getDetails = async (id: string, isAdmin = false): Promise<void> => {
    toggleLoader(true)
    const response = await UsersApi.get(id, isAdmin)
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      setUserDetails(response)
    }
    toggleLoader(false)
  }

  const updateUser = async (id: string, user: IUser): Promise<void> => {
    toggleLoader(true)
    const response = await UsersApi.update(id, user)
    if (isIError(response)) {
      response.error.errorList = getErrorList(response)

      if (response.error.fieldErrors) {
        openErrorSnack(response.error.errorList.join('\n'))
      } else {
        openErrorSnack(response.error.message)
      }
    } else {
      setUpdateSuccess(true)
      if (currentUser?.id === id) {
        setCurrentUser({
          ...currentUser,
          firstName: user.firstName,
          lastName: user.lastName,
          login: user.email as string,
          roles: user.roles,
          sites: user.sites,
        })
      }
      openSuccessSnack(t('UsersScreen.updatedUser'))
    }
    toggleLoader(false)
  }

  return (
    <Provider
      value={{
        users,
        count,
        updateUsers,
        createUser,
        getDetails,
        userDetails,
        updateUser,
        updateSuccess,
        createSuccess,
      }}
    >
      {children}
    </Provider>
  )
}

export default UsersProvider

export { Consumer as UsersConsumer, UsersContext }
