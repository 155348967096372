import React, { useEffect, useState } from 'react'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Typography from '@material-ui/core/Typography'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { convertToCamelCase } from 'utils/functions'

const PathEntry = styled(Typography)`
  ::first-letter {
    text-transform: capitalize;
  }
`

interface IBreadcrumbs {
  path: string
}

function CustomBreadcrumbs({ path }: IBreadcrumbs): JSX.Element {
  const [data, setData] = useState<string[]>([])
  const { t } = useTranslation()

  useEffect(() => {
    if (path) {
      const arrayPath = path.split('/')
      if (arrayPath.length > 0) {
        const final = arrayPath.filter((elem) => elem !== '')
        setData(final)
      }
    }
  }, [path])

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" style={{ color: '#FFF' }} />}
      aria-label="breadcrumb"
    >
      {data
        .filter((_, itemIndex) => itemIndex <= 1)
        .map((elem, index) => (
          <PathEntry
            variant="h6"
            key={index.toString()}
            data-cy="breadcrumb"
            style={{ color: '#FFF' }}
          >
            {index === 0 && t(`menu.categories.${convertToCamelCase(elem)}`)}
            {index === 1 && t(`menu.subcategories.${convertToCamelCase(elem)}`)}
          </PathEntry>
        ))}
    </Breadcrumbs>
  )
}

export default CustomBreadcrumbs
