import React from 'react'
import { Tooltip } from '@devexpress/dx-react-chart-material-ui'

const CustomTooltipContent = ({
  text,
  total,
  firstLabel,
  secondLabel,
  ...props
}: Tooltip.ContentProps & {
  total: number
  firstLabel: string
  secondLabel: string
}): JSX.Element => (
  <Tooltip.Content
    {...props}
    text={`${firstLabel}: ${text} 
        - ${secondLabel}: ${((+text / total) * 100).toFixed(2)}%`}
  />
)

export default CustomTooltipContent
