import { makeStyles, createStyles } from '@material-ui/core/styles'

export default makeStyles(() =>
  createStyles({
    container: {
      position: 'relative',
      marginRight: 25,
    },
    datePicker: {
      marginLeft: 8,
      width: 170,
    },
  }),
)
