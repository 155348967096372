import React, { ReactNode, useContext, useEffect, useState } from 'react'

import { IUserPreferences, ITableConfig } from 'interfaces/IUserPreferences'
import UserApi from 'api/user'
import { isIError } from 'api/types'

import { AuthContext } from 'store/AuthContext'
import { FeedbackContext } from './FeedbackContext'

interface IAuthContext {
  tablesConfig: ITableConfig
  updateTableConfig(configName: string, value: string[]): void
  updateToolTipConfig(configName: string, value: string[]): void
  getTableHiddenCols(name: string): string[]
  isConfigLoading: boolean
  toolTipsConfig: ITableConfig
  mapConfig: string[]
  updateMapConfig(viewName: string, add: boolean): void
}

const AppConfigContext = React.createContext<IAuthContext>({} as IAuthContext)
const { Provider, Consumer } = AppConfigContext

interface IProps {
  children: ReactNode
}

function AppConfigProvider({ children }: IProps): JSX.Element {
  const { user } = useContext(AuthContext)
  const [isConfigLoading, setIsLoading] = useState(true)
  const { openErrorSnack } = useContext(FeedbackContext)
  const [configId, setConfigId] = useState<string | undefined>()
  const [userConfig, setUserConfig] = useState<Partial<IUserPreferences>>()
  const [firstTimeConfig, setFirstTimeConfig] = useState<boolean>(true)
  const [tablesConfig, setTablesConfig] = useState<ITableConfig>({})
  const [toolTipsConfig, setToolTipsConfig] = useState<ITableConfig>({})
  const [mapConfig, setMapConfig] = useState<string[]>([])

  async function getUserPreferences() {
    if (user) {
      const res = await UserApi.getUserConfig({ userId: user.id })
      if (!isIError(res)) {
        const { id, preferences } = res
        setConfigId(id)
        setUserConfig(preferences)
        if (preferences) {
          setFirstTimeConfig(!(Object.keys(preferences).length > 0))
          if (preferences) {
            setTablesConfig(preferences.tablesConfig || {})
            if (preferences.toolTipsConfig) {
              setToolTipsConfig(preferences.toolTipsConfig || {})
            }
            if (preferences.mapConfig) {
              setMapConfig(preferences.mapConfig || [])
            }
          }
        }
      }
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getUserPreferences()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function updateMapConfig(viewName: string, add: boolean) {
    setMapConfig((prev) => {
      const arr = [...prev]
      if (add) {
        arr.push(viewName)
      } else {
        const idx = mapConfig.findIndex((config) => config === viewName)
        if (idx > -1) {
          arr.splice(idx, 1)
        }
      }
      updateUserPreferences({ ...userConfig, mapConfig: arr })
      return arr
    })
  }

  function updateTableConfig(configName: string, value: string[]) {
    setTablesConfig((prev) => {
      const config = { ...prev }
      config[configName] = value
      updateUserPreferences({ ...userConfig, tablesConfig: config })
      return config
    })
  }

  function updateToolTipConfig(configName: string, value: string[]) {
    setToolTipsConfig((prev) => {
      const config = { ...prev }
      config[configName] = value
      updateUserPreferences({ ...userConfig, toolTipsConfig: config })
      return config
    })
  }

  async function updateUserPreferences(config: Partial<IUserPreferences>) {
    if (!user) {
      return
    }
    if (firstTimeConfig || !configId) {
      const res = await UserApi.createUserConfig({ config, userId: user.id })
      if (!isIError(res)) {
        setConfigId(res.id)
      }
    } else {
      const res = await UserApi.updateUserConfig({ config, configId, userId: user.id })
      if (isIError(res)) {
        openErrorSnack(res.error.message)
        // TODO manage error
      }
    }
    setUserConfig(config)
  }

  function getTableHiddenCols(name: string): string[] {
    return tablesConfig[name] || []
  }

  return (
    <Provider
      value={{
        tablesConfig,
        updateTableConfig,
        updateToolTipConfig,
        getTableHiddenCols,
        isConfigLoading,
        toolTipsConfig,
        mapConfig,
        updateMapConfig,
      }}
    >
      {children}
    </Provider>
  )
}

export { AppConfigContext }
export default AppConfigProvider
export { Consumer as AppConfigConsumer }
