import { Dispatch, SetStateAction, useEffect, useState } from 'react'

type ReturnType<T> = [T, Dispatch<SetStateAction<T>>]

export function useExpirableState<T>(key: string, initialValue: T, dateKey: string): ReturnType<T> {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      if (!isPlanIdExpired()) {
        const item = localStorage.getItem(key)
        return item ? JSON.parse(item) : initialValue
      }
      return initialValue
    } catch (err) {
      return initialValue
    }
  })

  function isPlanIdExpired(): boolean {
    const expirationDate = localStorage.getItem(dateKey)
    if (expirationDate) {
      const parsedDate = JSON.parse(expirationDate)
      if (new Date(parsedDate) > new Date()) {
        return false
      }
    }
    localStorage.setItem(key, '')
    return true
  }

  useEffect(() => {
    if (storedValue) {
      localStorage.setItem(key, JSON.stringify(storedValue))
      const expiration = new Date()
      expiration.setDate(expiration.getDate() + 1)
      expiration.setHours(0)
      expiration.setMinutes(0)
      localStorage.setItem(dateKey, JSON.stringify(expiration))
    } else {
      localStorage.removeItem(key)
      localStorage.removeItem(dateKey)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(storedValue)])

  return [storedValue, setStoredValue]
}

export default useExpirableState
