import React, { useState, ReactNode, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import TenantsApi from 'api/tenants'
import { isIError } from 'api/types'
import { ITenantFilters } from 'interfaces/interfaces'
import { ITenant } from 'interfaces/ITenant'
import { COLUMS_MODEL_TENANTS_LIST } from 'constants/table'
import { getErrorList } from 'utils/errorUtils'
import { FeedbackContext } from 'store/FeedbackContext'

interface ITenantsContext {
  getTenantsList: (
    filters?: ITenantFilters,
    offset?: number,
    sortField?: number,
    sortDirection?: string,
  ) => void
  count: number
  tenants: ITenant[]
  getDetails: (id: string) => void
  tenantDetails: ITenant
  createSuccess?: boolean
  updateSuccess?: boolean
  createTenant: (tenant: ITenant) => void
  updateTenant: (id: string, tenant: ITenant) => void
}

interface IProps {
  children: ReactNode
}

const TenantsContext = React.createContext<ITenantsContext>({} as ITenantsContext)
const { Provider, Consumer } = TenantsContext

const TenantsProvider = ({ children }: IProps): JSX.Element => {
  const [createSuccess, setCreateSuccess] = useState<boolean | undefined>()
  const [updateSuccess, setUpdateSuccess] = useState<boolean | undefined>()
  const [count, setCount] = useState<number>(0)
  const [tenants, setTenants] = useState<ITenant[]>([])
  const [tenantDetails, setTenantDetails] = useState<ITenant>({})
  const { t } = useTranslation()
  const { openErrorSnack, openSuccessSnack, toggleLoader } = useContext(FeedbackContext)

  const getTenantsList = async (
    filters?: ITenantFilters,
    offset?: number,
    sortField?: number,
    sortDirection?: string,
  ): Promise<void> => {
    toggleLoader(true)
    const response = await TenantsApi.getList(
      filters,
      offset,
      COLUMS_MODEL_TENANTS_LIST.find((_column, index) => index === sortField)?.field,
      sortDirection === 'desc' ? -1 : 1,
    )
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      setTenants(response.tenants)
      setCount(response.count)
    }
    toggleLoader(false)
  }

  const getDetails = async (id: string): Promise<void> => {
    toggleLoader(true)
    const response = await TenantsApi.get(id)
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      setTenantDetails(response)
    }
    toggleLoader(false)
  }

  const createTenant = async (tenant: ITenant): Promise<void> => {
    toggleLoader(true)
    const response = await TenantsApi.create(tenant)
    if (isIError(response)) {
      response.error.errorList = getErrorList(response)

      if (response.error.fieldErrors) {
        openErrorSnack(response.error.errorList.join('\n'))
      } else {
        openErrorSnack(response.error.message)
      }
    } else {
      setCreateSuccess(true)
      openSuccessSnack(t('TenantsScreen.createdTenant'))
    }
    toggleLoader(false)
  }

  const updateTenant = async (id: string, tenant: ITenant): Promise<void> => {
    toggleLoader(true)
    const response = await TenantsApi.update(id, tenant)
    if (isIError(response)) {
      response.error.errorList = getErrorList(response)

      if (response.error.fieldErrors) {
        openErrorSnack(response.error.errorList.join('\n'))
      } else {
        openErrorSnack(response.error.message)
      }
    } else {
      setUpdateSuccess(true)
      openSuccessSnack(t('TenantsScreen.updatedTenant'))
    }
    toggleLoader(false)
  }

  return (
    <Provider
      value={{
        count,
        getTenantsList,
        tenants,
        getDetails,
        tenantDetails,
        createTenant,
        updateTenant,
        createSuccess,
        updateSuccess,
      }}
    >
      {children}
    </Provider>
  )
}

export default TenantsProvider

export { Consumer as TenantsConsumer }
