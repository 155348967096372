import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormControlLabel, Switch } from '@material-ui/core'

import useStyles from 'constants/cruStyles'
import Card from 'components/Card/Card'
import { IAppointmentEndCustomerPageConfig, IToggleChange } from 'interfaces/interfaces'

interface IProps {
  appointmentEndCustomerPage?: IAppointmentEndCustomerPageConfig
  onChange: (configs: IAppointmentEndCustomerPageConfig) => void
}

const WarehouseAppointmentEndCustomerPageCard = ({
  appointmentEndCustomerPage,
  onChange,
}: IProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [config, setConfig] = useState<IAppointmentEndCustomerPageConfig>({
    showUnavailableSlots: appointmentEndCustomerPage?.showUnavailableSlots ?? true,
    canPilotReserveTimeSlots: appointmentEndCustomerPage?.canPilotReserveTimeSlots ?? false,
  })

  const handleChange = (event: IToggleChange): void => {
    const { target } = event
    const { name } = target
    const value = target.checked

    onChange({ ...config, [name]: value } as IAppointmentEndCustomerPageConfig)
    setConfig({ ...config, [name]: value })
  }

  return (
    <Card title={t('SitesScreen.appointmentEndCustomerPage').toUpperCase()}>
      <div className={classes.fieldsContainer}>
        <div>
          <FormControlLabel
            className={classes.switchContainer}
            control={(
              <Switch
                color="primary"
                checked={config.showUnavailableSlots}
                onChange={handleChange}
                name="showUnavailableSlots"
              />
            )}
            labelPlacement="start"
            label={t(
              'SitesScreen.appointmentEndCustomerPageConfigs.showUnavailableSlots',
            ).toUpperCase()}
          />
        </div>
        <div>
          <FormControlLabel
            className={classes.switchContainer}
            control={(
              <Switch
                color="primary"
                checked={config.canPilotReserveTimeSlots}
                onChange={handleChange}
                name="canPilotReserveTimeSlots"
              />
            )}
            labelPlacement="start"
            label={t(
              'SitesScreen.appointmentEndCustomerPageConfigs.canPilotReserveTimeSlots',
            ).toUpperCase()}
          />
        </div>
      </div>
    </Card>
  )
}

export default WarehouseAppointmentEndCustomerPageCard
