import React, { useContext } from 'react'
import L from 'leaflet'
import { Marker } from 'react-leaflet'
import ReactDOMServer from 'react-dom/server'
import { IVisit } from 'interfaces/IOrders'
import { ExecutionContext } from 'store/ExecutionContext'
import PinIcon from './PinIcon'
import MarkerPopup from './MarkerPopup'

interface IProps {
  visits: IVisit[]
}

const generateIcon = (visit: IVisit, shouldDisplayStroke?: boolean) =>
  L.divIcon({
    className: 'custom icon',
    html: ReactDOMServer.renderToString(
      <PinIcon pinColor="#000" circleColor="#FFF" shouldDisplayStroke={shouldDisplayStroke}>
        <text
          x="50%"
          y="44%"
          textAnchor="middle"
          fontSize="155"
          fill="#000"
          style={{ fontWeight: 'bold' }}
        >
          {visit.destinationAddress.label?.trim()?.slice(0, 2).toUpperCase() || '-'}
        </text>
      </PinIcon>,
    ),
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
  })

const UnassignedVisitsMarker = ({ visits }: IProps): JSX.Element => {
  const { highlightedElement, setHighlightedElement } = useContext(ExecutionContext)
  return (
    <>
      {visits.map((visit: IVisit) => {
        const { latitude: lat, longitude: lng } = visit.destinationAddress
        if (!lat || !lng) return null
        return (
          <Marker
            key={visit.visitId}
            position={{ lat, lng }}
            icon={generateIcon(visit, visit.visitId === highlightedElement?.id)}
            eventHandlers={{
              click: (e) => {
                e.originalEvent.stopPropagation()
                setHighlightedElement({
                  id: visit.visitId,
                  latitude: lat,
                  longitude: lng,
                })
              },
            }}
          >
            <MarkerPopup visit={visit} />
          </Marker>
        )
      })}
    </>
  )
}

export default UnassignedVisitsMarker
