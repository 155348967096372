import {
  MENU_CATEGORIES,
  MENU_SUBCATEGORIES,
  USER_ROLES,
  PILOT_AND_STORE_USER_ROLES,
} from 'constants/constants'

import CarrierDetailsScreen from 'screens/NetworkCategory/Carriers/CarrierDetailsScreen'
import CarriersScreen from 'screens/NetworkCategory/Carriers'
import CarrierFormScreen from 'screens/NetworkCategory/Carriers/CarrierFormScreen'
import SitesDetailsScreen from 'screens/NetworkCategory/Sites/SitesDetailsScreen'
import SitesFormScreen from 'screens/NetworkCategory/Sites/SitesFormScreen'
import SitesListScreen from 'screens/NetworkCategory/Sites/SitesListScreen'

import { ROUTES_PATH } from './RoutesPath'

/**
 * NETWORK_CATEGORY configuration
 * Please refer to 'navigation/NavigationConfig' before changing this.
 */

const NETWORK_CATEGORY = [
  {
    title: MENU_SUBCATEGORIES.site,
    path: ROUTES_PATH.sitesList,
    component: SitesListScreen,
    isPrivate: true,
    isInMenu: true,
    category: MENU_CATEGORIES.network,
    roles: [USER_ROLES.udcAdmin, ...PILOT_AND_STORE_USER_ROLES],
  },
  {
    title: MENU_SUBCATEGORIES.site,
    path: `${ROUTES_PATH.siteDetails}:id`,
    component: SitesDetailsScreen,
    isPrivate: true,
    isInMenu: false,
    category: MENU_CATEGORIES.network,
    roles: [USER_ROLES.udcAdmin, ...PILOT_AND_STORE_USER_ROLES],
  },
  {
    title: MENU_SUBCATEGORIES.site,
    path: `${ROUTES_PATH.editSite}:id`,
    component: SitesFormScreen,
    isPrivate: true,
    isInMenu: false,
    category: MENU_CATEGORIES.network,
    roles: [USER_ROLES.udcAdmin, ...PILOT_AND_STORE_USER_ROLES],
  },
  {
    title: MENU_SUBCATEGORIES.site,
    path: ROUTES_PATH.createSite,
    component: SitesFormScreen,
    isPrivate: true,
    isInMenu: false,
    category: MENU_CATEGORIES.network,
    roles: [USER_ROLES.udcAdmin, USER_ROLES.pilot],
  },
  {
    title: MENU_SUBCATEGORIES.carriers,
    path: ROUTES_PATH.carriersList,
    component: CarriersScreen,
    isPrivate: true,
    isInMenu: true,
    category: MENU_CATEGORIES.network,
    roles: [USER_ROLES.udcAdmin, USER_ROLES.pilot],
  },
  {
    title: MENU_SUBCATEGORIES.carrierDetail,
    path: `${ROUTES_PATH.carrierDetails}:id`,
    component: CarrierDetailsScreen,
    isPrivate: true,
    isInMenu: false,
    category: MENU_CATEGORIES.network,
    roles: [USER_ROLES.udcAdmin, USER_ROLES.pilot],
  },
  {
    title: MENU_SUBCATEGORIES.carrierEdition,
    path: `${ROUTES_PATH.editCarrier}:id`,
    component: CarrierFormScreen,
    isPrivate: true,
    isInMenu: false,
    category: MENU_CATEGORIES.network,
    roles: [USER_ROLES.udcAdmin, USER_ROLES.pilot],
  },
  {
    title: MENU_SUBCATEGORIES.carrierEdition,
    path: ROUTES_PATH.createCarrier,
    component: CarrierFormScreen,
    isPrivate: true,
    isInMenu: false,
    category: MENU_CATEGORIES.network,
    roles: [USER_ROLES.udcAdmin, USER_ROLES.pilot],
  },
]

export default NETWORK_CATEGORY
