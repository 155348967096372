import React, { useState, createContext, ReactNode, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { isIError, IError } from 'api/types'
import NotifierConfigurationsApi from 'api/notifierConfigurations'
import { getErrorList } from 'utils/errorUtils'
import { FeedbackContext } from 'store/FeedbackContext'
import { INotifierConfiguration } from 'interfaces/INotifierConfiguration'
import { IOrderSmsDetails } from 'interfaces/IOrders'

interface INotifierConfigurationsContext {
  getDetails: (id: string) => Promise<void>
  notifierConfigurations: INotifierConfiguration[]
  count: number
  error?: IError
  getList: (
    filters?: { tenant?: string[] },
    paginationOffset?: number,
    rowsPerPage?: number,
  ) => void
  updateSuccess?: boolean
  createSuccess?: boolean
  notifierConfigurationsDetails: Partial<INotifierConfiguration>
  createNotifierConfiguration: (notifierConfiguration: INotifierConfiguration) => Promise<void>
  updateNotifierConfiguration: (id: string, notifierConfigurations: INotifierConfiguration) => void
  sendBookingSMS: (orderSmsDTO: IOrderSmsDetails) => Promise<void>
}

const NotifierConfigurationsContext = createContext<INotifierConfigurationsContext>(
  {} as INotifierConfigurationsContext,
)

const { Provider } = NotifierConfigurationsContext

interface IProps {
  children: ReactNode
}

const NotifierConfigurationsProvider = ({ children }: IProps): JSX.Element => {
  const { openErrorSnack, openSuccessSnack, toggleLoader } = useContext(FeedbackContext)
  const [notifierConfigurations, setNotifierConfigurations] = useState<INotifierConfiguration[]>([])
  const [count, setCount] = useState<number>(0)
  const [notifierConfigurationsDetails, setNotifierConfigurationsDetails] = useState<
    Partial<INotifierConfiguration>
  >({})
  const [createSuccess, setCreateSuccess] = useState<boolean | undefined>()
  const [updateSuccess, setUpdateSuccess] = useState<boolean | undefined>()
  const { t } = useTranslation()

  const getList = async (
    filters?: { tenant?: string[] },
    offset?: number,
    rowsPerPage?: number,
  ): Promise<void> => {
    toggleLoader(true)
    const response = await NotifierConfigurationsApi.getList(filters, offset, rowsPerPage)
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      setNotifierConfigurations(response.notifierConfigurations)
      setCount(response.count)
    }
    toggleLoader(false)
  }

  const getDetails = async (id: string): Promise<void> => {
    toggleLoader(true)
    const response = await NotifierConfigurationsApi.get(id)
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      setNotifierConfigurationsDetails(response)
    }
    toggleLoader(false)
  }

  const createNotifierConfiguration = async (
    notifierConfiguration: INotifierConfiguration,
  ): Promise<void> => {
    toggleLoader(true)
    const response = await NotifierConfigurationsApi.create({
      ...notifierConfiguration,
      deliveryTypes: notifierConfiguration.deliveryTypes?.map(Number),
      notificationParam: {
        ...notifierConfiguration.notificationParam,
        sendSms:
          notifierConfiguration.notificationParam?.sendSms === undefined
            ? false
            : notifierConfiguration.notificationParam?.sendSms,
        ...(notifierConfiguration.notificationParam?.specific
          ? { specific: JSON.parse(notifierConfiguration.notificationParam?.specific as string) }
          : undefined),
      },
    })
    if (isIError(response)) {
      response.error.errorList = getErrorList(response)
      if (response.error.fieldErrors) {
        openErrorSnack(response.error.errorList.join('\n'))
      } else {
        openErrorSnack(response.error.message)
      }
    } else {
      setCreateSuccess(true)
      openSuccessSnack(t('NotifierConfigurationsScreen.configurationSuccessfullyCreated'))
    }
    toggleLoader(false)
  }

  const updateNotifierConfiguration = async (
    id: string,
    notifierConfiguration: INotifierConfiguration,
  ): Promise<void> => {
    toggleLoader(true)
    const response = await NotifierConfigurationsApi.update(id, {
      ...notifierConfiguration,
      deliveryTypes: notifierConfiguration.deliveryTypes?.map(Number),
      notificationParam: {
        ...notifierConfiguration.notificationParam,
        ...(notifierConfiguration.notificationParam?.specific
          ? { specific: JSON.parse(notifierConfiguration.notificationParam?.specific as string) }
          : undefined),
      },
    })
    if (isIError(response)) {
      response.error.errorList = getErrorList(response)
      if (response.error.fieldErrors) {
        openErrorSnack(response.error.errorList.join('\n'))
      } else {
        openErrorSnack(response.error.message)
      }
    } else {
      setUpdateSuccess(true)
      openSuccessSnack(t('NotifierConfigurationsScreen.successfullySaved'))
    }
    toggleLoader(false)
  }

  const sendBookingSMS = async (orderSmsDTO: IOrderSmsDetails): Promise<void> => {
    toggleLoader(true)
    const response = await NotifierConfigurationsApi.sendBookingSMS(orderSmsDTO)
    if (isIError(response)) {
      response.error.errorList = getErrorList(response)
      if (response.error.fieldErrors) {
        openErrorSnack(response.error.errorList.join('\n'))
      } else {
        openErrorSnack(response.error.message)
      }
    } else {
      if (response) {
        openSuccessSnack(t('NotifierConfigurationsScreen.sendingSms'))
      } else {
        openErrorSnack(t('NotifierConfigurationsScreen.SmsNotSent'))
      }
    }
    toggleLoader(false)
  }

  return (
    <Provider
      value={{
        getDetails,
        notifierConfigurations,
        count,
        getList,
        updateNotifierConfiguration,
        createSuccess,
        updateSuccess,
        notifierConfigurationsDetails,
        createNotifierConfiguration,
        sendBookingSMS,
      }}
    >
      {children}
    </Provider>
  )
}
export { NotifierConfigurationsContext }

export default NotifierConfigurationsProvider
