import React, { useState, useEffect, useRef, useContext, useMemo } from 'react'
import MaterialTable, { Action, MTableHeader } from 'material-table'
import { useHistory } from 'react-router-dom'
import ArrowForward from '@material-ui/icons/ArrowForward'
import RefreshIcon from '@material-ui/icons/Refresh'
import { useTranslation } from 'react-i18next'

import { PlanTemplatesConsumer } from 'store/PlanTemplatesContext'
import MUITableIcons from 'constants/MUITableIcons'
import { IPlanTemplatesFilters, ITableColumn } from 'interfaces/interfaces'
import { IPlanTemplate } from 'interfaces/IPlanTemplate'
import Button from 'components/Button/CustomButton'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import AppConfigProvider, { AppConfigContext } from 'store/AppConfigContext'
import { scrollTop } from 'utils/functions'
import { getPageSize, savePageSize } from 'utils/localStorage'
import Pagination from 'components/Table/Pagination'
import { Filter } from 'constants/filters'
import { FiltersContext } from 'store/FiltersContext'
import { IconButton } from '@material-ui/core'
import { SiteFilter } from 'components/Inputs/ListFilter'
import { getDisplayRowsCount } from 'utils/tableUtils'
import { FiltersContainer, SearchContainer, TopFiltersContainer } from 'components/Layout'
import useStyles from './styles'

interface IProps {
  columns: ITableColumn[]
  planTemplates: IPlanTemplate[]
  count?: number
  filterKey: string
  getList?: (
    filters?: IPlanTemplatesFilters,
    offset?: number,
    rowsPerPage?: number,
    sortField?: number,
    sortDirection?: string,
  ) => void
}

const columnConfigName = 'planTemplateList'

const PlanTemplateTable = ({
  columns,
  planTemplates,
  count,
  getList,
  filterKey,
  ...rest
}: IProps): JSX.Element => {
  const isInitialMount = useRef(true)
  const { setFilter, resetFilters, filters } = useContext(FiltersContext)

  const [paginationOffset, setPaginationOffset] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [sortDirection, setSortDirection] = useState<string>('asc')
  const [rowsPerPage, setRowsPerPage] = useState<number>(getPageSize)
  const [sortField, setSortField] = useState<number>(0)
  const styles = useStyles()
  const history = useHistory()
  const { tablesConfig } = useContext(AppConfigContext)
  const siteIds = filters[filterKey][Filter.sites] as string[]
  const { t } = useTranslation()

  useEffect(() => {
    if (rowsPerPage !== getPageSize()) {
      savePageSize(rowsPerPage)
      scrollTop()
    }
    if (getList) {
      getList({ siteIds }, 0, rowsPerPage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteIds, rowsPerPage])

  useEffect(() => {
    if (getList && !isInitialMount.current) {
      getList({ siteIds }, paginationOffset, rowsPerPage, sortField, sortDirection)
      scrollTop()
    } else {
      isInitialMount.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationOffset, sortDirection, sortField])

  const resetLocalFilters = (): void => resetFilters(filterKey)

  const handleNewPlanTemplateClick = (): void => {
    history.push(ROUTES_PATH.createPlanTemplate)
  }

  const Filters = useMemo(
    (): JSX.Element => (
      <TopFiltersContainer>
        <FiltersContainer>
          <IconButton data-cy="initializeButton" onClick={resetLocalFilters}>
            <RefreshIcon />
          </IconButton>
          <SiteFilter
            dataCy="sitePicker"
            handleChange={(siteValues: string[]): void => {
              setFilter(filterKey, Filter.sites, siteValues)
            }}
            ids={siteIds}
            isUnselectAllowed={false}
          />
        </FiltersContainer>
        <SearchContainer>
          <Button data-cy="newButton" onPress={handleNewPlanTemplateClick}>
            {t('tablesEntries.new')}
          </Button>
        </SearchContainer>
      </TopFiltersContainer>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [siteIds, styles],
  )

  const handleDetailsClick = ({ id }: IPlanTemplate): void => {
    history.push(`${ROUTES_PATH.planTemplateDetails}${id}`)
  }

  const handleChangeRowsPerPage = (pageSize: number): void => {
    setPage(0)
    setRowsPerPage(pageSize)
    setPaginationOffset(0)
  }

  const handleOrderChange = (columnIndex: number): void => {
    setPaginationOffset(0)
    setPage(0)
    setSortDirection((prevSortDirection) => (prevSortDirection === 'asc' ? 'desc' : 'asc'))
    setSortField(columnIndex)
  }

  const displayRowsCount = useMemo(() => getDisplayRowsCount(planTemplates), [planTemplates])

  return (
    <>
      {Filters}
      <MaterialTable
        key={displayRowsCount}
        data={planTemplates}
        columns={columns.map(
          (col) =>
            ({
              ...col,
              hidden: tablesConfig[columnConfigName]?.includes(col.title),
            } as unknown as MaterialTable<object>),
        )}
        {...rest}
        options={{
          toolbar: false,
          pageSize: displayRowsCount,
          actionsColumnIndex: -1,
        }}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        actions={[
          (data: IPlanTemplate): Action<IPlanTemplate> => ({
            icon: ArrowForward,
            onClick: (): void => handleDetailsClick(data),
            tooltip: t('tablesEntries.seeDetails'),
          }),
        ]}
        icons={MUITableIcons}
        components={{
          Header: (props): JSX.Element => (
            <MTableHeader
              {...props}
              onOrderChange={handleOrderChange}
              orderBy={sortField}
              orderDirection={sortDirection}
            />
          ),
          // eslint-disable-next-line react/prop-types, @typescript-eslint/no-unused-vars
          Pagination: ({ classes, ...props }): JSX.Element => (
            <Pagination
              {...props}
              page={page}
              columns={columns}
              columnConfigName={columnConfigName}
              count={count}
              rowsPerPage={rowsPerPage}
              setPaginationOffset={setPaginationOffset}
              setPage={setPage}
            />
          ),
        }}
      />
    </>
  )
}

export default (props: IProps): JSX.Element => (
  <AppConfigProvider>
    <PlanTemplatesConsumer>
      {(ctx): JSX.Element => (
        <PlanTemplateTable count={ctx.count} getList={ctx.getPlanTemplates} {...props} />
      )}
    </PlanTemplatesConsumer>
  </AppConfigProvider>
)
