export default [
  '#b2b2f0',
  '#B7B28A',
  '#ffd700',
  '#00ffff',
  '#00bfff',
  '#ff00ff',
  '#dda0dd',
  '#7b68ee',
  '#a0522d',
  '#4b0082',
  '#ffff00',
  '#e9967a',
  '#008080',
  '#f0e68c',
  '#ffe5b4',
]
