import React, { useContext } from 'react'

import { COLUMNS_MODEL_ADMIN_WAREHOUSES } from 'constants/table'
import SitesProvider, { SitesContext } from 'store/SitesContext'
import SitesTable from 'components/Table/SitesTable'
import { FilterKey } from 'constants/filters'

const WarehousesAdminListScreen = (): JSX.Element => {
  const { sites } = useContext(SitesContext)
  return (
    <SitesTable
      sites={sites}
      columns={COLUMNS_MODEL_ADMIN_WAREHOUSES}
      columnConfigName="adminWarehouseList"
      filterKey={FilterKey.adminWarehouses}
      isForAdmin
    />
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <SitesProvider>
    <WarehousesAdminListScreen {...props} />
  </SitesProvider>
)
