import React, { useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import {
  ArgumentAxis,
  ValueAxis,
  BarSeries,
  Chart,
  Tooltip,
  Title,
  Legend,
} from '@devexpress/dx-react-chart-material-ui'
import { Animation, EventTracker, ValueScale, DomainItems } from '@devexpress/dx-react-chart'
import { connectProps } from '@devexpress/dx-react-core'

import { OrdersConsumer } from 'store/OrdersContext'
import { IOrderStats } from 'interfaces/IOrders'
import Export from 'components/Chart/Export'
import ChartProgress from 'components/Chart/ChartProgress'
import { FilterKey } from 'constants/filters'
import TooltipArrow from 'screens/ReportingCategory/components/TooltipArrow'
import TooltipSheet from 'screens/ReportingCategory/components/TooltipSheet'
import OrdersFilters from 'screens/ReportingCategory/components/OrdersFilters'
import StyledPaper from 'screens/ReportingCategory/components/StyledPaper'
import { getChartTitle } from 'utils/extendedDateFilter'

const useStyles = makeStyles(() =>
  createStyles({
    legendContainer: {
      textAlign: 'center',
      margin: 'auto',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      flexFlow: 'column',
      width: '100%',
      alignContent: 'center',
    },
    totalLabel: {
      display: 'flex',
      width: '100%',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      marginTop: '25px',
    },
  }),
)

interface IOrdersStatsProps {
  ordersStats: IOrderStats[]
  updateStats: (
    deliveryType?: string[],
    transportTypes?: string[],
    warehouseIds?: string[],
    planStatuses?: string[],
    deliveryWarehouseIds?: string[],
    zipCodes?: string[],
    minDay?: string,
    maxDay?: string,
  ) => void
}

const CustomLegend = ({ total }: Legend.RootProps & { total: number }): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.legendContainer}>
      <Legend.Label
        className={classes.totalLabel}
        text={`${t('reporting.orders.total')}: ${total}`}
      />
    </div>
  )
}

const containerId = 'order-reporting'
const filterKey = FilterKey.orderHistory

const OrderHistoryIndicator = ({ ordersStats, updateStats }: IOrdersStatsProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [selectedDateFilterShortcut, setSelectedDateFilterShortcut] = useState('')
  const theme = useTheme()

  useEffect(() => {
    setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersStats])

  const getStats = (
    deliveryTypes?: string[],
    transportTypes?: string[],
    warehouseIds?: string[],
    planStatuses?: string[],
    deliveryWarehouseIds?: string[],
    zipCodes?: string[],
    minDay?: string,
    maxDay?: string,
  ) => {
    setIsLoading(true)
    updateStats(
      deliveryTypes,
      transportTypes,
      warehouseIds,
      planStatuses,
      deliveryWarehouseIds,
      zipCodes,
      minDay,
      maxDay,
    )
  }

  const getTotalCount = (stats: IOrderStats[]): number =>
    stats.reduce((total, current) => total + current.orderCount, 0)

  const memoizedTotal = useMemo(() => getTotalCount(ordersStats), [ordersStats])

  const EnhancedLegend = connectProps(CustomLegend, () => ({ total: memoizedTotal }))

  const orderChartTitle = getChartTitle('reporting.orders.title', selectedDateFilterShortcut)

  return (
    <>
      <OrdersFilters
        filterKey={filterKey}
        getStats={getStats}
        selectedDateFilterShortcut={selectedDateFilterShortcut}
        setSelectedDateFilterShortcut={setSelectedDateFilterShortcut}
      />
      {ordersStats?.length !== undefined && !isLoading ? (
        <StyledPaper id={containerId}>
          <Chart
            data-cy="orderHistoryChart"
            data={ordersStats.map((stat) => ({
              orderCount: stat.orderCount,
              day: stat.day.toString().substr(0, 5),
            }))}
          >
            <Title text={orderChartTitle} />
            <Animation />
            <ValueScale
              name="orders"
              modifyDomain={(domain: DomainItems): DomainItems => [0, domain[1] * 1.2]}
            />
            <ArgumentAxis />
            <ValueAxis scaleName="orders" showGrid showLine showTicks />
            <BarSeries
              name="orders"
              valueField="orderCount"
              argumentField="day"
              scaleName="orders"
              color={theme.color.primary}
            />
            <EventTracker />
            <Legend position="bottom" rootComponent={EnhancedLegend} />
            <Tooltip sheetComponent={TooltipSheet} arrowComponent={TooltipArrow} />
            {/* TODO: maybe use another fileName later instead of the containerId */}
            <Export dataCy="exportButton" containerId={containerId} fileName={containerId} />
          </Chart>
        </StyledPaper>
      ) : (
        <ChartProgress count={24} />
      )}
    </>
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <OrdersConsumer>
    {(ctx): JSX.Element => (
      <OrderHistoryIndicator
        ordersStats={ctx.ordersStats}
        updateStats={ctx.updateStats}
        {...props}
      />
    )}
  </OrdersConsumer>
)
