import React, { useMemo, useContext } from 'react'
import styled from 'styled-components'
import { useTheme } from '@material-ui/core/styles'
import { Draggable, Droppable } from 'react-beautiful-dnd'

import { IStopMarker, ITourMapItem, ITourStopsNumber } from 'interfaces/map'
import { StopStatus, VehicleFillingRateCalculation } from 'constants/constants'
import { ContentContext } from 'store/ContentContext'
import { getFinalStop } from 'utils/stopsUtils'
import useStyles from '../styles'
import Header from './Header'
import TourSummary from './TourSummary'
import StopsListItem from './StopsListItem'
import StyledPaper from './StyledPaper'

interface ITourColumn {
  tour: ITourMapItem
  color: string
  numberOfStops: ITourStopsNumber
}

const StyledContainer = styled.div`
  overflow: auto;
`

const TourColumn = ({ tour, color, numberOfStops }: ITourColumn): JSX.Element => {
  const classes = useStyles()
  const theme = useTheme()
  const { sites } = useContext(ContentContext)

  const { tourNumber, driver, toleratedAdvance, toleratedDelay } = tour
  const { stopsNumber, realizedStopsNumber, filteredStops } = numberOfStops

  const lastStop = useMemo(() => getFinalStop(tour.stops) as IStopMarker, [tour])

  const vehicleAllocationCapacityCalculation = useMemo(
    (): VehicleFillingRateCalculation =>
      sites?.find((site) => tour?.warehouseId === site.id)?.executionScreen
        ?.vehicleFillingRateCalculation || VehicleFillingRateCalculation.Quantity,
    [sites, tour],
  )

  const vehicleAllocatedCapacityPercentage: number | null = useMemo(() => {
    if (!tour.vehicleCapacity) {
      return null
    }

    if (tour.stops.length === 0) {
      return 0
    }

    let quantity = 0
    if (vehicleAllocationCapacityCalculation === VehicleFillingRateCalculation.Quantity) {
      quantity = tour.stops.reduce((total, current) => total + Math.abs(current?.quantity || 0), 0)
    } else {
      quantity = tour.stops.reduce(
        (total, current) =>
          total
          + current.visitsToDeliver?.reduce(
            (parcelCount, visit) => parcelCount + (visit.supports?.length || 0),
            0,
          ),
        0,
      )
    }

    const percentage = Math.round((quantity / tour.vehicleCapacity) * 100)

    return percentage
  }, [tour, vehicleAllocationCapacityCalculation])

  return (
    <Droppable droppableId={`TourDroppable-${tour.tourId}`}>
      {(provided): JSX.Element => (
        <StyledPaper square elevation={3} ref={provided.innerRef} data-cy="tourColumn">
          <Header theme={theme}>
            <TourSummary
              color={color}
              tourNumber={tourNumber}
              driverName={driver?.name}
              toleratedAdvance={toleratedAdvance}
              toleratedDelay={toleratedDelay}
              stopsNumber={stopsNumber}
              realizedStopsNumber={realizedStopsNumber}
              vehicleAllocatedCapacityPercentage={vehicleAllocatedCapacityPercentage}
              lastStop={lastStop}
            />
          </Header>
          <StyledContainer className={classes.scrollable}>
            {filteredStops.map((stop: IStopMarker, index: number) => (
              <Draggable
                key={stop.stopId}
                index={index}
                draggableId={stop.stopId}
                isDragDisabled={
                  stop.status === StopStatus.Delivered || stop.status === StopStatus.Dropped
                }
              >
                {(providedStop, snapshotStop): JSX.Element => (
                  <StopsListItem
                    index={index}
                    stop={stop}
                    providedMarker={providedStop}
                    snapshotMarker={snapshotStop}
                    toleratedAdvance={tour.toleratedAdvance}
                    toleratedDelay={tour.toleratedDelay}
                  />
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </StyledContainer>
        </StyledPaper>
      )}
    </Droppable>
  )
}

export default TourColumn
