import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormControlLabel, Switch } from '@material-ui/core'
import useStyles from 'constants/cruStyles'
import Card from 'components/Card/Card'
import { IOrderScreenConfig, IToggleChange } from 'interfaces/interfaces'

interface IProps {
  orderScreen?: IOrderScreenConfig
  onChange: (orderScreenInfo: IOrderScreenConfig) => void
}

const WarehouseOrderScreenCard = ({ orderScreen, onChange }: IProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [config, setConfig] = useState<Partial<IOrderScreenConfig>>({
    editServiceTime: orderScreen?.editServiceTime ?? false,
    editDeliveryTime: orderScreen?.editDeliveryTime ?? false,
  })

  const handleChange = (event: IToggleChange): void => {
    const { target } = event
    const { name } = target
    const value = target.checked || false

    onChange({ ...config, [name]: value } as IOrderScreenConfig)
    setConfig({ ...config, [name]: value })
  }

  return (
    <Card title={t('SitesScreen.orderScreen').toUpperCase()}>
      <div className={classes.fieldsContainer}>
        <div>
          <FormControlLabel
            className={classes.switchContainer}
            control={(
              <Switch
                color="primary"
                checked={config.editServiceTime}
                onChange={handleChange}
                name="editServiceTime"
              />
            )}
            labelPlacement="start"
            label={t('SitesScreen.orderScreenConfig.editServiceTime').toUpperCase()}
          />
        </div>
        <div>
          <FormControlLabel
            className={classes.switchContainer}
            control={(
              <Switch
                color="primary"
                checked={config.editDeliveryTime}
                onChange={handleChange}
                name="editDeliveryTime"
              />
            )}
            labelPlacement="start"
            label={t('SitesScreen.orderScreenConfig.editDeliveryTime').toUpperCase()}
          />
        </div>
      </div>
    </Card>
  )
}

export default WarehouseOrderScreenCard
