import React, { useState, useEffect } from 'react'
import { IDriver } from 'interfaces/IDriver'
import DriversProvider, { DriversConsumer } from 'store/DriversContext'
import { useHistory, useParams } from 'react-router-dom'
import { ContentConsumer } from 'store/ContentContext'
import { ICarrier } from 'interfaces/ICarrier'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import FormAction from 'components/Button/FormAction'
import useStyles from 'constants/cruStyles'
import { IEvent } from 'interfaces/IEvent'
import { IIdParam } from 'interfaces/IIdParam'
import DriverInfoCard from './DriverInfoCard'
import DriverAvailabilityCard from './DriverAvailabilityCard'

interface IDriversProps {
  createDriver?: Function
  createSuccess?: boolean
  updateSuccess?: boolean
  carriers: ICarrier[]
  getDetails?: Function
  driverDetails?: IDriver
  updateDriver?: Function
  refreshDrivers?: Function
}

const DriversFormScreen = ({
  updateSuccess,
  createDriver,
  createSuccess,
  carriers,
  getDetails,
  updateDriver,
  driverDetails,
  refreshDrivers,
}: IDriversProps): JSX.Element => {
  const [driver, setDriver] = useState<IDriver>({ active: true, driverAvailabilityControl: false })
  const history = useHistory()
  const { id } = useParams<IIdParam>()
  const classes = useStyles()

  useEffect(() => {
    if (id && getDetails) {
      getDetails(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (driverDetails && id) {
      setDriver({
        id: driverDetails?.id,
        firstName: driverDetails?.firstName,
        lastName: driverDetails?.lastName,
        phoneNumber: driverDetails?.phoneNumber,
        login: driverDetails?.login,
        email: driverDetails?.login,
        active: driverDetails?.active,
        events: driverDetails?.events,
        carrier: driverDetails?.carrierId,
        companyId:
          carriers && carriers.find((carrier) => carrier.id === driverDetails.carrierId)?.companyId,
        siteId: driverDetails?.siteId,
        driverAvailabilityControl: driverDetails?.driverAvailabilityControl,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverDetails])

  useEffect(() => {
    if ((createSuccess || updateSuccess) && refreshDrivers) {
      history.push(ROUTES_PATH.driversList)
      refreshDrivers()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createSuccess, updateSuccess])

  const handleDriverChange = (driverInfo: IDriver): void => {
    setDriver((prevDriver) => ({
      ...prevDriver,
      firstName: driverInfo.firstName,
      lastName: driverInfo.lastName,
      phoneNumber: driverInfo.phoneNumber,
      email: driverInfo.login,
      password: driverInfo.password,
      active: driverInfo.active,
      carrier: driverInfo.carrier,
      companyId:
        carriers && carriers.find((carrier) => carrier.id === driverInfo.carrier)?.companyId,
      siteId: driverInfo.siteId,
    }))
  }

  const handleSaveClick = async (): Promise<void> => {
    if (id) {
      if (updateDriver) {
        await updateDriver(id, driver)
      }
    } else {
      if (createDriver) {
        await createDriver(driver)
      }
    }
  }

  const handleCancelClick = (): void => {
    history.push(ROUTES_PATH.driversList)
  }

  const handleAvailabilityChange = (availability: IEvent[]): void => {
    setDriver((prevDrvier) => ({
      ...prevDrvier,
      events: availability,
    }))
  }

  const handleAvailabilityControlChange = (checked: boolean): void => {
    setDriver((prevDriver) => ({
      ...prevDriver,
      driverAvailabilityControl: checked,
    }))
  }

  return (
    <>
      {id && (
        <h3 data-cy="driverDetailDescription">
          {`${driver.firstName || ''} ${driver.lastName || ''}`}
        </h3>
      )}
      <DriverInfoCard
        key={`${driver.id}-info`}
        driver={driver}
        onDriverChange={handleDriverChange}
      />
      <div className={classes.infoContainer}>
        <DriverAvailabilityCard
          key={`${driver.id}-availability`}
          events={driver.events as IEvent[]}
          shouldShowDriverPlanning={driver.driverAvailabilityControl}
          onAvailabilityChange={handleAvailabilityChange}
          onAvailabilityControlChange={handleAvailabilityControlChange}
        />
      </div>
      <FormAction onSaveClick={handleSaveClick} onCancelClick={handleCancelClick} />
    </>
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <DriversProvider>
    <ContentConsumer>
      {(contentCtx): JSX.Element => (
        <DriversConsumer>
          {(driversCtx): JSX.Element => (
            <DriversFormScreen
              updateSuccess={driversCtx.updateSuccess}
              updateDriver={driversCtx.updateDriver}
              getDetails={driversCtx.getDetails}
              driverDetails={driversCtx.driverDetails}
              carriers={contentCtx.carriers}
              createSuccess={driversCtx.createSuccess}
              createDriver={driversCtx.createDriver}
              refreshDrivers={contentCtx.refreshDrivers}
              {...props}
            />
          )}
        </DriversConsumer>
      )}
    </ContentConsumer>
  </DriversProvider>
)
