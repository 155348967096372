import {
  IAtoptimaParams,
  OptimizationEngine,
  OptimizationItineraryCalculatorType,
} from './interfaces'
import { IOptimizationRouterOptions } from './IOptimizationRouterOptions'

export interface IOptimizationParam {
  isOptimizable?: boolean
  daysBeforePlansExpiration?: number
  maximumOptimizationTime?: number
  speedMultiplier?: number
  setupDuration?: number
  visitDuration?: number
  maxDestinations?: number
  optimizationEngine?: OptimizationEngine
  optimizationMaxSplitSize?: number
  optimizationStopSoftUpperBound?: number
  optimizationVehicleSoftUpperBound?: number
  optimizationCostWaitingTime?: number
  maxClientsPerTour?: number
  optimizationItineraryCalculator?: OptimizationItineraryCalculatorType
  routerOptions?: IOptimizationRouterOptions
  multiTourPermission?: boolean
  nbToursPerVehicle?: number
  delayBetweenTours?: number
  isMultisite?: boolean
  loadingTimePerOrderMultisite?: number
  toleratedAdvance?: number
  toleratedDelay?: number
  atoptimaParams?: IAtoptimaParams
}

export const VALUE_TYPE = {
  maximumOptimizationTime: 'int',
  speedMultiplier: 'float',
  setupDuration: 'int',
  visitDuration: 'int',
  optimizationMaxSplitSize: 'int',
  optimizationStopSoftUpperBound: 'float',
  optimizationVehicleSoftUpperBound: 'float',
  optimizationCostWaitingTime: 'int',
  maxClientsPerTour: 'int',
}
