import React from 'react'
import styled from 'styled-components'
import { useTheme } from '@material-ui/core/styles'

import Address from '../../../OrderCategory/Details/components/Address'

const StatusLine = styled.div(({ color, theme }) => ({
  height: '5px',
  width: '100%',
  flex: '1',
  background: color,
  margin: '0 10px',
  minWidth: '115px',
  border: `1px solid ${theme.color.statusLineBorder}`,
}))

interface IProps {
  address: string
}

export default ({ address }: IProps): JSX.Element => {
  const theme = useTheme()

  return (
    <>
      <StatusLine color="white" theme={theme} />
      <Address label={address} />
    </>
  )
}
