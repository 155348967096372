import React, { useContext, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'

import { IIdParam } from 'interfaces/IIdParam'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import CustomCard from 'components/CustomCard/CustomCard'
import Button from 'components/Button/CustomButton'
import useStyles from 'constants/cruStyles'
import CostConfigurationsProvider, {
  CostConfigurationsContext,
} from 'store/CostConfigurationsContext'
import { getCarrierNamesFromIds, getTenantNameFromId } from 'utils/tableUtils'
import { ICostConfiguration } from 'interfaces/ICostConfiguration'

const CostConfigDetailsScreen = (): JSX.Element => {
  const { id } = useParams<IIdParam>()
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const { getDetails, costConfigurationsDetails } = useContext(CostConfigurationsContext)

  useEffect(() => {
    if (id && getDetails) {
      getDetails(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const handleEditClick = (): void => {
    history.push(`${ROUTES_PATH.editCostConfig}${id}`)
  }

  return (
    <>
      <div className={classes.detailHeaderContainer}>
        <h3 data-cy="costConfigurationsDetails">
          {getTenantNameFromId(costConfigurationsDetails as ICostConfiguration)}
        </h3>
        <Button data-cy="updateButton" onPress={handleEditClick}>
          {t('CostConfigurationsScreen.edit')}
        </Button>
      </div>
      <div className={classes.detailsContent}>
        <div className={classes.detailsInnerContent}>
          <Grid item xs={10} className={classes.infoContainer}>
            <CustomCard
              dataCy="configurationsCard"
              cardTitle={t('CostConfigurationsScreen.configurations')}
              linePairs={[
                {
                  label: t('CostConfigurationsScreen.label'),
                  value: costConfigurationsDetails.label,
                },
                {
                  label: t('CostConfigurationsScreen.carriers'),
                  value: getCarrierNamesFromIds(costConfigurationsDetails as ICostConfiguration),
                },
                {
                  label: t('CostConfigurationsScreen.startDate'),
                  value: costConfigurationsDetails.startDate ?? '',
                },
                {
                  label: t('CostConfigurationsScreen.endDate'),
                  value: costConfigurationsDetails.endDate ?? '',
                },
                {
                  label: t('CostConfigurationsScreen.costPerTour'),
                  value: costConfigurationsDetails.costPerTour?.toString(),
                },
                {
                  label: t('CostConfigurationsScreen.costPerStop'),
                  value: costConfigurationsDetails.costPerStop?.toString(),
                },
                {
                  label: t('CostConfigurationsScreen.costPerKm'),
                  value: costConfigurationsDetails.costPerKm?.toString(),
                },
              ]}
            />
          </Grid>
        </div>
      </div>
    </>
  )
}
export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <CostConfigurationsProvider>
    <CostConfigDetailsScreen {...props} />
  </CostConfigurationsProvider>
)
