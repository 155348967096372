import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IEvent } from 'interfaces/IEvent'
import Switch from 'components/Inputs/ToggleSwitch'
import Scheduler from 'components/Scheduler/EventScheduler'
import Card from 'components/Card/Card'

interface IProps {
  events: IEvent[]
  shouldShowDriverPlanning?: boolean
  onAvailabilityChange?: Function
  isReadOnly?: boolean
  onAvailabilityControlChange?: (checked: boolean) => void
}

const DriverAvailabilityCard = ({
  events,
  shouldShowDriverPlanning,
  onAvailabilityChange,
  isReadOnly,
  onAvailabilityControlChange,
}: IProps): JSX.Element => {
  const { t } = useTranslation()
  const [displayDriverPlanning, setDisplayDriverPlanning] = useState<boolean | undefined>(
    shouldShowDriverPlanning,
  )

  const handleAvailabilityControlChange = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ): void => {
    setDisplayDriverPlanning(checked)
    if (onAvailabilityControlChange) {
      onAvailabilityControlChange(checked)
    }
  }

  return (
    <Card dataCy="availabilityCard" title={t('DriversScreen.availability').toUpperCase()}>
      <Switch
        dataCy="planningManagement"
        name="driverPlanningControl"
        checked={displayDriverPlanning}
        label={t('DriversScreen.planningManagement').toUpperCase()}
        onChange={handleAvailabilityControlChange}
        disabled={isReadOnly}
        justifyLeft
      />
      {displayDriverPlanning && (
        <Scheduler events={events} onEventChange={onAvailabilityChange} isReadOnly={isReadOnly} />
      )}
    </Card>
  )
}

export default DriverAvailabilityCard
