import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField } from '@material-ui/core'

import { IVehicle } from 'interfaces/IVehicle'
import { ITrailer } from 'interfaces/ITrailer'
import { ICarrier, IVehicleType, IWarehouse } from 'interfaces/interfaces'
import { ContentConsumer } from 'store/ContentContext'
import Switch from 'components/Inputs/ToggleSwitch'
import SearchableSelect from 'components/Inputs/SearchableSelect'
import useStyles from 'constants/cruStyles'
import Card from './Card'

interface ITextChange {
  target: {
    value: string
    name: string
    type?: string
    checked?: boolean
  }
}

type IVehicleBase = IVehicle | ITrailer

interface IProps {
  onVehicleChange: Function
  vehicle?: IVehicleBase
  carrierOptions?: ICarrier[]
  vehicleTypeOptions?: IVehicleType[]
  hideLastKnownKilometer?: boolean
  hideSite?: boolean
  siteOptions?: IWarehouse[]
}

const VehicleCard = ({
  onVehicleChange,
  vehicle,
  carrierOptions,
  vehicleTypeOptions,
  hideLastKnownKilometer,
  siteOptions,
  hideSite,
}: IProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [state, setState] = useState<IVehicleBase>({
    id: vehicle?.id,
    name: vehicle?.name,
    licencePlate: vehicle?.licencePlate,
    lastKnownKilometer: (vehicle as IVehicle)?.lastKnownKilometer
      ? (vehicle as IVehicle)?.lastKnownKilometer
      : undefined,
    carrier: vehicle?.carrierId,
    active: vehicle?.active,
    vehicleType: vehicleTypeOptions?.find(
      (vehicleType) => vehicleType.code === vehicle?.vehicleType,
    )?.id,
    siteId: (vehicle as IVehicle)?.siteId ? (vehicle as IVehicle)?.siteId : undefined,
  })

  const handleInputChange = (event: ITextChange): void => {
    const { target } = event
    const { name, type } = target
    const value = type === 'checkbox' ? target.checked : target.value
    const nameValue = !state.id && name === 'licencePlate' ? value : name === 'name' ? value : state.name

    onVehicleChange({ ...state, [name]: value, name: nameValue })
    setState({ ...state, [name]: value, name: nameValue as string })
  }

  const handleSelectChange = (
    event: React.ChangeEvent<{ value: unknown; name?: string }>,
  ): void => {
    const { target } = event
    const name = target.name as string
    const value = target.value as string

    onVehicleChange({ ...state, [name]: value })
    setState({ ...state, [name]: value })
  }

  return (
    <Card dataCy="infoCard" title={t('VehicleScreen.profile').toUpperCase()}>
      <>
        <div className={classes.fieldsContainer}>
          <TextField
            data-cy="licencePlate"
            error={state.licencePlate === ''}
            label={t('VehicleScreen.licencePlate').toUpperCase()}
            onChange={handleInputChange}
            type="text"
            required
            name="licencePlate"
            value={state.licencePlate || ''}
          />
          <TextField
            data-cy="lastName"
            error={state.name === ''}
            label={t('VehicleScreen.name').toUpperCase()}
            onChange={handleInputChange}
            type="text"
            required
            name="name"
            value={state.name || ''}
          />
          {!hideSite && (
            <SearchableSelect
              dataCy="siteSelect"
              label={t('VehicleScreen.site')}
              name="siteId"
              selectedValue={(state as IVehicle).siteId || ''}
              options={siteOptions?.map((carrier) => ({ id: carrier.id, name: carrier.name }))}
              onChange={handleSelectChange}
              required
            />
          )}
          {!hideLastKnownKilometer && (
            <TextField
              data-cy="lastKnownKilometer"
              label={t('VehicleScreen.lastKnownKilometer').toUpperCase()}
              onChange={handleInputChange}
              type="number"
              name="lastKnownKilometer"
              value={(state as IVehicle).lastKnownKilometer}
            />
          )}
          <SearchableSelect
            dataCy="carrierSelect"
            label={t('VehicleScreen.carrier')}
            name="carrier"
            selectedValue={state.carrier || ''}
            options={carrierOptions?.map((carrier) => ({ id: carrier.id, name: carrier.name }))}
            onChange={handleSelectChange}
            required
          />
          <SearchableSelect
            dataCy="catVehicleSelect"
            label={t('VehicleScreen.vehicleType')}
            name="vehicleType"
            selectedValue={state.vehicleType || ''}
            options={vehicleTypeOptions?.map((vehicleType) => ({
              id: vehicleType.id,
              name: vehicleType.name,
            }))}
            onChange={handleSelectChange}
            required
          />
        </div>
        <Switch
          dataCy="active"
          onChange={handleInputChange}
          name="active"
          checked={state.active}
          label={t('VehicleScreen.active').toUpperCase()}
        />
      </>
    </Card>
  )
}

export default (props: JSX.IntrinsicAttributes & IProps): JSX.Element => (
  <ContentConsumer>
    {(ctx): JSX.Element => (
      <VehicleCard
        onVehicleChange={props.onVehicleChange}
        carrierOptions={ctx.carriers}
        vehicleTypeOptions={ctx.vehicleTypes}
        vehicle={props.vehicle}
        hideLastKnownKilometer={props.hideLastKnownKilometer}
        siteOptions={ctx.userSites}
      />
    )}
  </ContentConsumer>
)
