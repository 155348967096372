import React, { ReactNode, useContext, useState } from 'react'

import { IVehicleType } from 'interfaces/IVehicleType'
import { IVehicleTypeFilters } from 'interfaces/interfaces'
import VehicleTypesApi from 'api/vehicleTypes'
import { isIError } from 'api/types'
import { useTranslation } from 'react-i18next'
import { COLUMNS_MODEL_VEHICLE_TYPES_LIST } from 'constants/table'
import { getErrorList } from 'utils/errorUtils'
import { DEFAULT_VEHICLE_TYPE_MAX_CLIENTS_COUNT } from 'constants/constants'
import { FeedbackContext } from './FeedbackContext'

interface IVehicleTypesContext {
  vehicleTypes: IVehicleType[]
  count: number
  getList: (
    filters?: IVehicleTypeFilters,
    offset?: number,
    rowsPerPage?: number,
    sortBy?: number,
    sortDirection?: string,
  ) => void
  getDetails: (id: string) => void
  vehicleTypeDetails: IVehicleType
  createSuccess?: boolean
  updateSuccess?: boolean
  createVehicleType: (vehicleType: IVehicleType) => void
  updateVehicleType: (id: string, vehicleType: IVehicleType) => void
}

interface IProps {
  children: ReactNode
}

const { Provider, Consumer } = React.createContext<IVehicleTypesContext>({} as IVehicleTypesContext)

function VehicleTypesProvider({ children }: IProps): JSX.Element {
  const [vehicleTypes, setVehicleTypes] = useState<IVehicleType[]>([])
  const [vehicleTypeDetails, setVehicleTypeDetails] = useState<IVehicleType>({})
  const [count, setCount] = useState<number>(0)
  const [createSuccess, setCreateSuccess] = useState<boolean | undefined>()
  const [updateSuccess, setUpdateSuccess] = useState<boolean | undefined>()
  const { t } = useTranslation()
  const { openErrorSnack, openSuccessSnack, toggleLoader } = useContext(FeedbackContext)

  const getList = async (
    filters?: IVehicleTypeFilters,
    offset?: number,
    rowsPerPage?: number,
    sortField?: number,
    sortDirection?: string,
  ): Promise<void> => {
    toggleLoader(true)
    const response = await VehicleTypesApi.getList(
      filters,
      offset,
      rowsPerPage,
      COLUMNS_MODEL_VEHICLE_TYPES_LIST.find((column, index) => index === sortField)?.field,
      sortDirection === 'desc' ? -1 : 1,
    )
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      setVehicleTypes(response.vehicleTypes)
      setCount(response.count)
    }
    toggleLoader(false)
  }

  const getDetails = async (id: string): Promise<void> => {
    toggleLoader(true)
    const response = await VehicleTypesApi.get(id)
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      setVehicleTypeDetails(response)
    }
    toggleLoader(false)
  }

  const createVehicleType = async (vehicleType: IVehicleType): Promise<void> => {
    toggleLoader(true)
    const response = await VehicleTypesApi.create({
      ...vehicleType,
      maxClientsPerTour: vehicleType.maxClientsPerTour || DEFAULT_VEHICLE_TYPE_MAX_CLIENTS_COUNT,
    })
    if (isIError(response)) {
      response.error.errorList = getErrorList(response)
      if (response.error.fieldErrors) {
        openErrorSnack(response.error.errorList.join('\n'))
      } else {
        openErrorSnack(response.error.message)
      }
    } else {
      setCreateSuccess(true)
      openSuccessSnack(t('VehicleTypesScreen.createdVehicleType'))
    }
    toggleLoader(false)
  }

  const updateVehicleType = async (id: string, vehicleType: IVehicleType): Promise<void> => {
    toggleLoader(true)
    const response = await VehicleTypesApi.update(id, vehicleType)
    if (isIError(response)) {
      response.error.errorList = getErrorList(response)
      if (response.error.fieldErrors) {
        openErrorSnack(response.error.errorList.join('\n'))
      } else {
        openErrorSnack(response.error.message)
      }
    } else {
      setUpdateSuccess(true)
      openSuccessSnack(t('VehicleTypesScreen.updatedVehicleType'))
    }
    toggleLoader(false)
  }

  return (
    <Provider
      value={{
        count,
        vehicleTypes,
        getList,
        getDetails,
        vehicleTypeDetails,
        createSuccess,
        updateSuccess,
        createVehicleType,
        updateVehicleType,
      }}
    >
      {children}
    </Provider>
  )
}

export default VehicleTypesProvider

export { Consumer as VehicleTypesConsumer }
