import React, { useEffect, useState, useContext } from 'react'
import * as Sentry from '@sentry/react'
import { Severity } from '@sentry/react'

import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

import { AuthConsumer } from 'store/AuthContext'
import LoginBg from 'components/Background'
import UserApi from 'api/user'
import { IUserData } from 'interfaces/interfaces'

import { FeedbackContext } from 'store/FeedbackContext'
import iconDarkMode from 'assets/logoWithSloganDarkMode.png'
// import iconLightMode from 'assets/logoWithSloganLightMode.png'
// import { ThemeContext } from 'store/ThemeContext'
import { SentryEvents } from 'constants/constants'
import Paper from './components/Paper'
import Input from './components/Input'
import Logo from './components/Logo'

import useStyle from './style'

interface IProps {
  user?: IUserData
  isAuthLoading: boolean
}

interface ILocation {
  from: {
    pathname: string
  }
}

function LoginScreen({ user, isAuthLoading }: IProps): JSX.Element {
  const history = useHistory()
  const location = useLocation()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [error, setError] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()
  const classes = useStyle()
  const { openErrorSnack } = useContext(FeedbackContext)
  // const { darkMode } = useContext(ThemeContext)

  const { from } = (location.state as ILocation) || { from: { pathname: '/' } }
  useEffect(() => {
    if (user) {
      history.replace(from)
    }
  }, [user, history, from])

  async function onPressSubmit(): Promise<void> {
    setIsLoading(true)
    const res = await UserApi.loginUser({ email, password })
    if (res && res.error) {
      openErrorSnack(res.error.message)
      setError(res.error.message)
    }
    Sentry.captureEvent({
      message: SentryEvents.LocalLogin,
      level: res?.error ? Severity.Error : Severity.Info,
      tags: {
        login: email,
        errorMessage: res?.error?.message,
      },
    })
    setIsLoading(false)
  }

  useEffect(() => {
    if (error.length > 0) {
      setError('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, password])

  function onChangeEmail(event: React.ChangeEvent<HTMLInputElement>): void {
    setEmail(event?.target?.value)
  }

  function onChangePassword(event: React.ChangeEvent<HTMLInputElement>): void {
    setPassword(event?.target?.value)
  }

  return (
    <>
      <Logo data-cy="logo">
        <img className={classes.img} src={iconDarkMode} alt="logo" />
      </Logo>
      <LoginBg>
        {!isAuthLoading ? (
          <Paper elevation={4} className={classes.paper}>
            <Input
              error={!!error}
              value={email}
              onChange={onChangeEmail}
              data-cy="email"
              label={t('loginScreen.email')}
              type="text"
              required
            />
            <Input
              error={!!error}
              value={password}
              onChange={onChangePassword}
              data-cy="password"
              label={t('loginScreen.password')}
              type="password"
              required
            />
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Button data-cy="submit" type="submit" onClick={onPressSubmit} variant="contained">
                {t('loginScreen.login')}
              </Button>
            )}
          </Paper>
        ) : (
          <Paper className={classes.paper}>
            <CircularProgress />
          </Paper>
        )}
      </LoginBg>
    </>
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <AuthConsumer>
    {(ctx): JSX.Element => (
      <LoginScreen user={ctx.user} isAuthLoading={ctx.isAuthLoading} {...props} />
    )}
  </AuthConsumer>
)
