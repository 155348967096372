import React from 'react'
import styled from 'styled-components'

import { DEFAULT_ACCEPTABLE_DELAY, DEFAULT_ACCEPTABLE_ADVANCE } from 'constants/constants'
import { IStop } from 'interfaces/Itours'
import { calculateStopDelay, calculateStopClientPromiseDelay } from 'utils/stopsUtils'
import { IStopMarker } from 'interfaces/map'

interface IProps {
  colors: IColors
}

interface IColors {
  background: string
  fontColor: string
}

const Container = styled.div`
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  background-color: ${(props: IProps): string => props.colors.background};
  color: ${(props: IProps): string => props.colors.fontColor};
`

interface IDelayColumn {
  stop: IStop | IStopMarker
  toleratedAdvance?: number
  toleratedDelay?: number
  isStopClientPromiseDelay?: boolean
}

const StopDelay = ({
  stop,
  toleratedAdvance = DEFAULT_ACCEPTABLE_ADVANCE,
  toleratedDelay = DEFAULT_ACCEPTABLE_DELAY,
  isStopClientPromiseDelay = false,
}: IDelayColumn): JSX.Element | null => {
  if (!stop) {
    return null
  }

  if (isStopClientPromiseDelay) {
    // Calculate the stop delay compared to the client's time window
    if (stop.realArrival || stop.estimatedArrival || stop.cancellation?.date) {
      const delay = calculateStopClientPromiseDelay(stop, toleratedAdvance, toleratedDelay)
      return <Container colors={{ ...delay.color, fontColor: 'white' }}>{delay.value}</Container>
    }
  }

  const hasMissingInfoForStopDelayCalculation = !stop.estimatedArrival && !stop.realArrival

  if (hasMissingInfoForStopDelayCalculation) {
    return null
  }
  const delay = calculateStopDelay(stop, toleratedDelay)
  return <Container colors={{ ...delay.color, fontColor: 'white' }}>{delay.value}</Container>
}

export default StopDelay
