import { IProduct, ISupport } from 'interfaces/IOrders'
import React, { useContext, useEffect } from 'react'
import Paper from '@material-ui/core/Paper'
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { useTranslation } from 'react-i18next'
import { AppConfigContext } from 'store/AppConfigContext'
import { IDispute } from 'interfaces/IDispute'
import { renderMediaLinks } from 'utils/tableUtils'
import { DisputeContext } from 'store/DisputeContext'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      backgroundColor: theme.color.backgroundStrongGrey,
      borderLeft: `5px solid ${theme.color.backgroundStrongGrey}`,
      borderRight: `5px solid ${theme.color.backgroundStrongGrey}`,
    },
    tableContainer: {
      minHeight: 376,
      backgroundColor: theme.color.backgroundStrongGrey,
    },
    productTableContainer: {
      minHeight: 328,
      backgroundColor: theme.color.backgroundStrongGrey,
    },
    tableHead: {
      backgroundColor: theme.color.subTableHeaderBackground,
      border: 'none',
      borderBottom: '1px solid #A8A8A8',
    },
    tableSettings: {
      width: '2px',
      padding: '0px',
    },
  }),
)

export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '& div.left': {
        marginLeft: 6,
      },
      '&:nth-of-type(odd)': {
        padding: 0,
        backgroundColor: theme.color.tableRowOdd,
        '& td': {
          padding: 0,
        },
        '& div': {
          marginTop: '6px',
          marginBottom: '6px',
          padding: '14px',
          '&.right': {
            marginRight: '6px',
          },
        },
      },
      '& td:first-child': {
        borderTopLeftRadius: '4px',
        borderBottomLeftRadius: '4px',
      },
      '& td:last-child': {
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
      },
      borderRadius: '4px',
      border: 'none',
    },
  }),
)(TableRow)

export const StyledTableCell = withStyles(() =>
  createStyles({
    body: {
      border: 'none',
    },
  }),
)(TableCell)

export default ({
  products,
  supports,
  disputes,
  dataCy,
}: {
  products?: Array<IProduct>
  supports?: Array<ISupport>
  disputes?: Array<IDispute>
  dataCy?: string
}): JSX.Element => {
  const { t } = useTranslation()
  const { tablesConfig } = useContext(AppConfigContext)
  const { disputeReasonCodes, getDisputeReasonCodes } = useContext(DisputeContext)

  const classes = useStyles()

  useEffect(() => {
    if (!disputeReasonCodes || disputeReasonCodes.length === 0) {
      getDisputeReasonCodes()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isColumnHidden = (columnName): boolean => tablesConfig.productDetails?.includes(columnName)

  const renderDisputeEanList = (item): JSX.Element =>
    item.eanList.map((eanListItem) => (
      <div>
        {eanListItem.ean} - {eanListItem.quantity}
      </div>
    ))

  return (
    <TableContainer
      component={Paper}
      className={products ? classes.productTableContainer : classes.tableContainer}
    >
      <Table
        data-cy={dataCy}
        stickyHeader
        className={classes.table}
        aria-label={`${products ? 'Products' : 'Supports'} table`}
      >
        <TableHead>
          <TableRow>
            {products && !isColumnHidden(t('tablesEntries.quantity')) && (
              <TableCell className={classes.tableHead} align="center">
                {t('tablesEntries.quantity')}
              </TableCell>
            )}
            {products && !isColumnHidden(t('OrdersScreen.label')) && (
              <TableCell className={classes.tableHead} align="center">
                {t('OrdersScreen.label')}
              </TableCell>
            )}
            {((products && !isColumnHidden(t('OrdersScreen.ean'))) || supports) && (
              <TableCell className={classes.tableHead} align="center">
                {t('OrdersScreen.ean')}
              </TableCell>
            )}
            {products && !isColumnHidden(t('OrdersScreen.service')) && (
              <TableCell className={classes.tableHead} align="center">
                {t('OrdersScreen.service')}
              </TableCell>
            )}
            {supports && (
              <TableCell className={classes.tableHead} align="center">
                {t('OrdersScreen.type')}
              </TableCell>
            )}
            {((products && !isColumnHidden(t('OrdersScreen.declaredMissing'))) || supports) && (
              <TableCell className={classes.tableHead} align="center">
                {t('OrdersScreen.declaredMissing')}
              </TableCell>
            )}
            {disputes && (
              <TableCell className={classes.tableHead} align="center">
                {t('OrdersScreen.eanList')}
              </TableCell>
            )}
            {disputes && (
              <TableCell className={classes.tableHead} align="center">
                {t('OrdersScreen.disputeReasonCode')}
              </TableCell>
            )}
            {disputes && (
              <TableCell className={classes.tableHead} align="center">
                {t('OrdersScreen.comment')}
              </TableCell>
            )}
            {disputes && (
              <TableCell className={classes.tableHead} align="center">
                {t('tablesEntries.images')}
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {(products || supports || disputes)?.map((value) => (
            <StyledTableRow key={value.ean}>
              {products && !isColumnHidden(t('tablesEntries.quantity')) && (
                <StyledTableCell align="center">
                  <div className="left">{(value as IProduct).quantity}</div>
                </StyledTableCell>
              )}
              {products && !isColumnHidden(t('OrdersScreen.label')) && (
                <StyledTableCell align="center">
                  <div>{(value as IProduct).label}</div>
                </StyledTableCell>
              )}
              {((products && !isColumnHidden(t('OrdersScreen.ean'))) || supports) && (
                <StyledTableCell align="center">
                  <div className={supports && !products ? 'left' : ''}>{value.ean}</div>
                </StyledTableCell>
              )}
              {products && !isColumnHidden(t('OrdersScreen.service')) && (
                <StyledTableCell align="center" width="25%">
                  <div>{(value as IProduct).serviceInstructions}</div>
                </StyledTableCell>
              )}
              {supports && (
                <StyledTableCell align="center">
                  <div className="right">{value.temperature}</div>
                </StyledTableCell>
              )}
              {((products && !isColumnHidden(t('OrdersScreen.declaredMissing'))) || supports) && (
                <StyledTableCell align="center">
                  <div className="right">{value.missing ? 'OUI' : ''}</div>
                </StyledTableCell>
              )}
              {disputes && (
                <StyledTableCell align="center">
                  <div className="right">{renderDisputeEanList(value)}</div>
                </StyledTableCell>
              )}
              {disputes && (
                <StyledTableCell align="center">
                  <div className="right">
                    {`${value.reasonCode} - 
                    ${
                      disputeReasonCodes?.find((item) => item.code === value.reasonCode)?.label
                      || 'N/A'
                    }`}
                  </div>
                </StyledTableCell>
              )}
              {disputes && (
                <StyledTableCell align="center">
                  <div className="right">{value.comment}</div>
                </StyledTableCell>
              )}
              {disputes && (
                <StyledTableCell align="center">
                  <div className="right">{renderMediaLinks(value)}</div>
                </StyledTableCell>
              )}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
