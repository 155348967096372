import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField } from '@material-ui/core'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import moment from 'moment'
import clsx from 'clsx'

import Card from 'components/Card/Card'
import { IOrderForm } from 'interfaces/IOrders'
import Button from 'components/Button/CustomButton'
import { getValidCarriers, shouldDisplayOrderFormField } from 'utils/functions'
import DateRangePicker from 'components/Inputs/DateRangePicker'
import { DateType } from 'constants/constants'
import { TagsContext } from 'store/TagsContext'
import { AuthContext } from 'store/AuthContext'
import { ITag } from 'interfaces/ITag'
import { ContentContext } from 'store/ContentContext'
import { IOrderTimeSlot, ISelectedTimeSlot } from 'interfaces/IWarehouseTimeSlot'
import { IWarehouseCartDropoffConfig } from 'interfaces/IWarehouseCartDropoffConfig'
import { ICarrier } from 'interfaces/interfaces'
import { FeedbackContext } from 'store/FeedbackContext'
import useStyles from './styles'
import TimeSlots from '../Booking/components/TimeSlots'

interface Props {
  order?: Partial<IOrderForm>
  onChange: (key: keyof IOrderForm, value: unknown) => void
  isCartDropoff?: boolean
  config?: IWarehouseCartDropoffConfig | null
  orderTimeSlots?: IOrderTimeSlot[]
  selectedTimeSlot?: ISelectedTimeSlot
  setSelectedTimeSlot?: React.Dispatch<React.SetStateAction<ISelectedTimeSlot | undefined>>
  isBookingEnabled?: boolean
  openOrderBookingScreen?: () => void
}

const DeliveryInfoCard = ({
  order,
  onChange,
  isCartDropoff = false,
  config,
  orderTimeSlots,
  selectedTimeSlot,
  setSelectedTimeSlot,
  isBookingEnabled,
  openOrderBookingScreen,
}: Props): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { tags, getTags } = useContext(TagsContext)
  const { user } = useContext(AuthContext)
  const { carriers } = useContext(ContentContext)
  const { isToggleLoading } = useContext(FeedbackContext)
  const [selectedTags, setSelectedTags] = useState<string[]>(order?.tags || [])
  const [selectedCarrier, setSelectedCarrier] = useState<string | undefined>(order?.carrierId)
  const configTagCodes = config?.tags?.allowedValues
  const configCarrierIds = config?.requestedCarriers?.allowedValues

  const sortedTags = useMemo(() => {
    let allTags = [...(tags || [])]
    const allowedTagCodes = configTagCodes || []
    if (allowedTagCodes.length > 0) {
      allTags = allTags.filter((tag) => allowedTagCodes.includes(tag.code || ''))
    }
    return allTags.sort((a, b) => (a.label || '').localeCompare(b.label || ''))
  }, [tags, configTagCodes])

  const filteredCarriers = useMemo(() => {
    let validCarriers = getValidCarriers(carriers, order?.warehouseId)
    const allowedCarrierIds = configCarrierIds || []
    if (allowedCarrierIds.length > 0) {
      validCarriers = validCarriers.filter((carrier) => allowedCarrierIds.includes(carrier.id))
    }
    if (selectedTags.length > 0) {
      validCarriers = validCarriers.filter((carrier) =>
        carrier.tags?.some((tag) => selectedTags.includes(tag.code || '')),
      )
    }
    return validCarriers
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carriers, order?.warehouseId, configCarrierIds, selectedTags])

  useEffect(() => {
    if (user?.tenantId) {
      getTags(user?.tenantId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    setSelectedCarrier((prev) => {
      if (filteredCarriers.map((carrier) => carrier.id).includes(prev || '')) {
        return prev
      }
      return undefined
    })
  }, [filteredCarriers])

  useEffect(() => {
    setSelectedTags(order?.tags || [])
  }, [order?.tags])

  const handleTagClick = (tag: ITag) => {
    const newTags = [...selectedTags]
    const index = newTags.findIndex((e) => e === tag.code)
    if (index === -1) newTags.push(tag.code || '')
    else newTags.splice(index, 1)
    onChange('tags', newTags)
  }

  useEffect(() => {
    setSelectedCarrier(order?.carrierId)
  }, [order?.carrierId])

  const handleCarrierClick = (carrier: ICarrier) => {
    const newCarrier = selectedCarrier === carrier.id ? null : carrier.id
    onChange('carrierId', newCarrier)
  }

  const isCarrierSelected = (carrier: ICarrier) => carrier.id === selectedCarrier

  const isTagSelected = (tag: ITag) => selectedTags.findIndex((e) => e === tag.code) !== -1

  const handleInputChange = (event: React.ChangeEvent<{ value: unknown; name?: string }>): void => {
    const { target } = event
    const { name, value } = target
    onChange(name as keyof IOrderForm, value)
  }

  const handleDateChange = (date: MaterialUiPickersDate, name: string): void => {
    onChange(name as keyof IOrderForm, moment(date).isValid() ? date?.seconds(0).milliseconds(0) : null)
  }

  const onWheel = (e: React.WheelEvent<HTMLDivElement>): void => {
    (e.target as HTMLInputElement).blur()
  }

  return (
    <Card title={t('OrdersScreen.deliveryInfo').toUpperCase()} dataCy="deliveryInformationCard">
      <div className={classes.container}>
        {shouldDisplayOrderFormField(isCartDropoff, config?.orderInstructions) && (
          <TextField
            data-cy="orderInstructions"
            type="text"
            label={t('OrdersScreen.orderInstructions').toUpperCase()}
            value={order?.orderInstructions || ''}
            name="orderInstructions"
            onChange={handleInputChange}
          />
        )}
        {isCartDropoff && (
          <TimeSlots
            orderTimeSlots={orderTimeSlots}
            selectedTimeSlot={selectedTimeSlot}
            setSelectedTimeSlot={setSelectedTimeSlot}
            customClass={classes.timeSlotsContainer}
            customListClass={classes.timeSlotsList}
            customListItemClass={classes.timeSlotsListItem}
            areDropdownsInitiallyOpen
          />
        )}
        {isBookingEnabled && openOrderBookingScreen && (
          <div>
            <Button
              dataCy="reservationButton"
              onPress={openOrderBookingScreen}
              disabled={isToggleLoading}
            >
              {t('OrdersScreen.reserveTimeslot')}
            </Button>
          </div>
        )}
        <div className={classes.dateRangePickerContainer}>
          <DateRangePicker
            startDate={order?.deliveryDateTimeMinimum}
            endDate={order?.deliveryDateTimeMaximum}
            dateType={DateType.DateTime}
            onChangeStart={(date: MaterialUiPickersDate): void => {
              handleDateChange(date, 'deliveryDateTimeMinimum')
            }}
            onChangeEnd={(date: MaterialUiPickersDate): void => {
              handleDateChange(date, 'deliveryDateTimeMaximum')
            }}
            hideShiftPicker
            isFullWidth
            startLabel={t('OrdersScreen.deliveryDateTimeMinimum')}
            endLabel={t('OrdersScreen.deliveryDateTimeMaximum')}
            startDataCy="deliveryDateTimeMinimum"
            endDataCy="deliveryDateTimeMaximum"
            isStartRequired
            isEndRequired
            customClass={classes.dateRangePicker}
          />
        </div>
        {shouldDisplayOrderFormField(isCartDropoff, config?.estimatedServiceTime) && (
          <TextField
            data-cy="estimatedServiceTime"
            type="number"
            label={t('OrdersScreen.estimatedServiceTime').toUpperCase()}
            value={order?.estimatedServiceTime ?? ''}
            name="estimatedServiceTime"
            onChange={handleInputChange}
            onWheel={onWheel}
          />
        )}
        {shouldDisplayOrderFormField(isCartDropoff, config?.tags) && (
          <div className={classes.tagsContainer}>
            <span>{t('OrdersScreen.tags').toUpperCase()}</span>
            <div className={classes.tagsList}>
              {sortedTags?.map((tag) => (
                <Button
                  key={tag.code}
                  onPress={() => handleTagClick(tag)}
                  className={clsx(classes.tag, isTagSelected(tag) && classes.selectedTag)}
                >
                  {tag.label || ''}
                </Button>
              ))}
            </div>
          </div>
        )}
        {isCartDropoff
          && shouldDisplayOrderFormField(isCartDropoff, config?.requestedCarriers)
          && filteredCarriers.length > 0 && (
            <div className={classes.tagsContainer}>
              <span>{t('OrdersScreen.carrier').toUpperCase()}</span>
              <div className={classes.tagsList}>
                {filteredCarriers?.map((carrier) => (
                  <Button
                    key={carrier.id}
                    onPress={() => handleCarrierClick(carrier)}
                    className={clsx(classes.tag, isCarrierSelected(carrier) && classes.selectedTag)}
                  >
                    {carrier.name || ''}
                  </Button>
                ))}
              </div>
            </div>
        )}
      </div>
    </Card>
  )
}

export default DeliveryInfoCard
