import { DateType } from 'constants/constants'
import moment from 'moment'

export function dateDefaultFormat(date?: Date | null | string): string {
  if (!date) {
    return ''
  }

  return moment(date).format('DD/MM/YYYY HH:mm')
}

export function dateToTimeFormat(date?: Date | string): string {
  if (!date) {
    return ''
  }

  return moment(date).format('HH:mm')
}

export function dateDefaultFormatWithouTime(date?: Date | null | string): string {
  return date ? moment(date).format('DD/MM/YYYY') : ''
}

export function isDateEqual(dateTime1?: Date, dateTime2?: Date): boolean {
  if (dateTime1 && dateTime2) {
    const time1 = new Date(dateTime1)
    const time2 = new Date(dateTime2)
    time1.setUTCHours(0, 0, 0, 0)
    time2.setUTCHours(0, 0, 0, 0)
    return time1.getTime() === time2.getTime()
  }
  return false
}

export const getTimeFromDateString = (isoDate: string): string => moment(isoDate).format('HH:mm')

export const getDateStringFromTime = (time: string): string => {
  const splitTime = time.split(':')
  return moment()
    .hours(+splitTime[0])
    .minutes(+splitTime[1])
    .toISOString()
}

export const getFormat = (type?: DateType): string => {
  switch (type) {
    case DateType.Time:
      return 'HH:mm'
    case DateType.Date:
      return 'DD/MM/YYYY'
    case DateType.DateTime:
      return 'DD/MM/YYYY HH:mm'
    default:
      return 'DD/MM/YYYY HH:mm'
  }
}

export const getMask = (type?: DateType): string => {
  switch (type) {
    case DateType.Time:
      return '__:__'
    case DateType.Date:
      return '__/__/____'
    case DateType.DateTime:
      return '__/__/____ __:__'
    default:
      return '__/__/____ __:__'
  }
}

export const getFormattedDeliveryTime = (
  firstDate: Date | undefined,
  secondDate: Date | undefined,
): string => {
  if (firstDate && secondDate) {
    return `${dateDefaultFormat(firstDate)} - ${
      isDateEqual(firstDate, secondDate)
        ? dateToTimeFormat(secondDate)
        : dateDefaultFormat(secondDate)
    }`
  }
  return ``
}

export const getDayMonthFormat = (date: Date): string => moment(date).format('DD/MM')

export const addDays = (date: Date, numberOfDays: number): Date => {
  date.setDate(date.getDate() + numberOfDays)
  return date
}
