import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ICarrier } from 'interfaces/ICarrier'
import CarriersProvider, { CarriersConsumer } from 'store/CarriersContext'
import { ContentConsumer } from 'store/ContentContext'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import FormAction from 'components/Button/FormAction'
import SiteCarriersValidityCard from 'components/Card/SiteCarriersValidityCard'
import { IIdParam } from 'interfaces/IIdParam'
import { TagsConsumer } from 'store/TagsContext'
import { ITag } from 'interfaces/ITag'
import { isDefined } from 'utils/functions'
import { IWarehouse } from 'interfaces/interfaces'
import ProfileCard from './ProfileCard'

interface IProps {
  getDetails?: Function
  carrierDetails: ICarrier
  warehouses: IWarehouse[]
  createCarrier: (carrier: ICarrier) => Promise<void>
  updateCarrier: (id: string, carrier: ICarrier) => Promise<void>
  updateSuccess?: boolean
  createSuccess?: boolean
  refreshCarriers?: Function
  tags: ITag[] | null
  getTags: Function
  userTenantId: string
}

const CarrierFormScreen = ({
  getDetails,
  carrierDetails,
  warehouses,
  createCarrier,
  updateCarrier,
  updateSuccess,
  createSuccess,
  refreshCarriers,
  tags,
  getTags,
  userTenantId,
}: IProps): JSX.Element => {
  const { id } = useParams<IIdParam>()
  const [warehouseOptions, setWarehouseOptions] = useState<IWarehouse[]>(warehouses)
  const [carrier, setCarrier] = useState<ICarrier>({ active: true })
  const history = useHistory()
  const { t } = useTranslation()

  useEffect(() => {
    if (id && getDetails) {
      getDetails(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    setCarrier({
      id: carrierDetails.id,
      companyId: carrierDetails.companyId,
      name: carrierDetails.name,
      code: carrierDetails.code,
      active: carrierDetails.active,
      warehouses: carrierDetails.warehouses,
      tags: carrierDetails.tags,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carrierDetails])

  useEffect(() => {
    if (!isDefined(tags) && userTenantId && getTags) {
      getTags(userTenantId)
    }
  }, [tags, userTenantId, getTags])

  useEffect(() => {
    setWarehouseOptions(warehouses)
    setCarrier((prevCarrier) => ({
      ...prevCarrier,
      warehouseIds: warehouses
        .filter((warehouse) => warehouse.active)
        .map((warehouse) => warehouse.id as string),
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouses])

  const handleProfileChange = (carrierProfile: ICarrier): void => {
    setCarrier((prevCarrier) => ({
      ...prevCarrier,
      name: carrierProfile.name,
      code: carrierProfile.code,
      active: carrierProfile.active,
      companyName: carrierProfile.name,
      tags: carrierProfile.tags,
    }))
  }

  const handleWarehouseChange = (carrierWarehouses: IWarehouse[]): void => {
    setCarrier((prevCarrier) => ({
      ...prevCarrier,
      warehouses: carrierWarehouses,
    }))
  }

  useEffect(() => {
    if ((updateSuccess || createSuccess) && refreshCarriers) {
      history.push(ROUTES_PATH.carriersList)
      refreshCarriers()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSuccess, createSuccess])

  const handleSaveClick = async (): Promise<void> => {
    if (id) {
      if (updateCarrier) {
        await updateCarrier(id, carrier)
      }
    } else {
      if (createCarrier) {
        await createCarrier(carrier)
      }
    }
  }

  const handleCancelClick = (): void => {
    history.push(ROUTES_PATH.carriersList)
  }

  return (
    <>
      {id && <h3 data-cy="carrierDetailDescription">{`${carrier.name || ''}`}</h3>}
      <ProfileCard
        key={`${carrier?.id}-infos`}
        carrier={carrier}
        onProfileChange={handleProfileChange}
        tags={tags}
      />
      <SiteCarriersValidityCard
        type="site"
        key={`${carrier?.id}-warehouses-${warehouseOptions.length}`}
        validities={carrier.warehouses || []}
        options={warehouseOptions}
        onValidityChange={handleWarehouseChange}
        cardTitle={t('CarrierScreen.reference').toUpperCase()}
      />
      <FormAction onSaveClick={handleSaveClick} onCancelClick={handleCancelClick} />
    </>
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <CarriersProvider>
    <ContentConsumer>
      {(contentCtx): JSX.Element => (
        <CarriersConsumer>
          {(carriersCtx): JSX.Element => (
            <TagsConsumer>
              {(tagsCtx): JSX.Element => (
                <CarrierFormScreen
                  createCarrier={carriersCtx.createCarrier}
                  updateCarrier={carriersCtx.updateCarrier}
                  updateSuccess={carriersCtx.updateSuccess}
                  createSuccess={carriersCtx.createSuccess}
                  warehouses={contentCtx.userSites}
                  carrierDetails={carriersCtx.carrierDetails}
                  getDetails={carriersCtx.getDetails}
                  refreshCarriers={contentCtx.refreshCarriers}
                  tags={tagsCtx.tags}
                  getTags={tagsCtx.getTags}
                  userTenantId={contentCtx.userTenantId}
                  {...props}
                />
              )}
            </TagsConsumer>
          )}
        </CarriersConsumer>
      )}
    </ContentConsumer>
  </CarriersProvider>
)
