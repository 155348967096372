import React, { useContext, useState, useEffect, useMemo } from 'react'
import { Grid, TableCell, TableRow } from '@material-ui/core'
import MaterialTable from 'material-table'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import MUITableIcons from 'constants/MUITableIcons'
import { FeedbackContext } from 'store/FeedbackContext'
import WarehouseTimeSlotsApi from 'api/warehouseTimeSlots'
import { isIError } from 'api/types'
import {
  IWarehouseTimeSlotReporting,
  IWarehouseTimeSlotReportingConfiguration,
  IWarehouseTimeSlotStatus,
} from 'interfaces/IWarehouseTimeSlot'
import { dateToTimeFormat } from 'utils/dateFormat'
import { COLUMNS_SLOTS_REPORTING } from 'constants/table'
import moment from 'moment'
import { getDisplayRowsCount } from 'utils/tableUtils'
import useStyles from './styles'
import ConfigurationSelect from './components/ConfigurationSelect'

const slotsFilling = 'slots-filling'

const SlotsReportingScreen = (): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { openErrorSnack, toggleLoader } = useContext(FeedbackContext)
  const [selectedConfiguration, setSelectedConfiguration] = useState<string>('')
  const [configurations, setConfigurations] = useState<IWarehouseTimeSlotReportingConfiguration[]>(
    [],
  )
  const [data, setData] = useState<IWarehouseTimeSlotReporting[]>([])

  const getConfigurations = async (): Promise<void> => {
    toggleLoader(true)
    const res = await WarehouseTimeSlotsApi.getReportingConfigurations()
    if (isIError(res)) {
      openErrorSnack(res.error.message)
    } else {
      setConfigurations(res)
    }
    toggleLoader(false)
  }

  useEffect(() => {
    getConfigurations()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const constructTableData = (
    warehouseTimeSlotStatus: IWarehouseTimeSlotStatus,
  ): IWarehouseTimeSlotReporting[] => {
    const result: IWarehouseTimeSlotReporting[] = []
    warehouseTimeSlotStatus.timeSlots.forEach((timeSlot, timeSlotIndex) => {
      timeSlot.timeSlotDetails.forEach((timeSlotDetail, timeSlotDetailIndex) => {
        const startTimeSlot = new Date(timeSlotDetail.beginTime)
        const endTimeSlot = new Date(timeSlotDetail.endTime)
        const row: IWarehouseTimeSlotReporting = {
          day: moment(timeSlot.day).format('DD/MM/YYYY'),
          slot: `${dateToTimeFormat(startTimeSlot)} - ${dateToTimeFormat(endTimeSlot)}`,
          availability:
            timeSlotDetail.maxOrder - timeSlotDetail.orderCount > 0
              ? timeSlotDetail.maxOrder - timeSlotDetail.orderCount
              : 0,
          nbOfSlots: timeSlotDetailIndex === 0 ? timeSlot.timeSlotDetails.length : undefined,
          lastSlotInDay:
            timeSlotIndex !== warehouseTimeSlotStatus.timeSlots.length - 1
            && timeSlotDetailIndex === timeSlot.timeSlotDetails.length - 1,
        }
        result.push(row)
      })
    })
    return result
  }

  const getReporting = async (warehouseId: string, sectorId?: string): Promise<void> => {
    toggleLoader(true)
    const res = await WarehouseTimeSlotsApi.getReporting(warehouseId, sectorId)
    if (isIError(res)) {
      openErrorSnack(res.error.message)
    } else {
      const tableData = constructTableData(res)
      setData(tableData)
    }
    toggleLoader(false)
  }

  const handleConfigurationSelect = (configuration: string): void => {
    setSelectedConfiguration(configuration)
    const [warehouseId, sectorId] = configuration.split(' - ')
    getReporting(warehouseId, sectorId)
  }

  const displayRowsCount = useMemo(() => getDisplayRowsCount(data), [data])

  return (
    <Grid item xs={12} className={classes.container}>
      <ConfigurationSelect
        configurations={configurations}
        selectedConfiguration={selectedConfiguration}
        onConfigurationSelect={handleConfigurationSelect}
      />
      <MaterialTable
        key={displayRowsCount}
        columns={COLUMNS_SLOTS_REPORTING.map(
          (col) =>
            ({
              ...col,
            } as unknown as MaterialTable<object>),
        )}
        data={data}
        options={{
          paging: false,
          exportButton: true,
          exportFileName: slotsFilling,
          showTitle: false,
          search: false,
          pageSize: displayRowsCount,
        }}
        localization={{
          toolbar: {
            // @ts-ignore
            exportCSVName: t('tablesEntries.exportCsv'),
            exportPDFName: t('tablesEntries.exportPdf'),
          },
        }}
        icons={MUITableIcons}
        components={{
          Row: (props) => {
            const {
              data: { day, slot, availability, nbOfSlots, lastSlotInDay },
            } = props
            return (
              <TableRow className={clsx(lastSlotInDay && classes.border)}>
                {nbOfSlots && (
                  <TableCell rowSpan={nbOfSlots} className={classes.dayTableCell}>
                    {day}
                  </TableCell>
                )}
                <TableCell>{slot}</TableCell>
                <TableCell>{availability}</TableCell>
              </TableRow>
            )
          },
        }}
      />
    </Grid>
  )
}

export default SlotsReportingScreen
