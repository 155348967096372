import React, { useState, useEffect, useContext, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import FormAction from 'components/Button/FormAction'
import useStyles from 'constants/cruStyles'
import { IIdParam } from 'interfaces/IIdParam'
import { IUser } from 'interfaces/IUser'
import { IApikey } from 'interfaces/IApikey'
import ApikeysProvider, { ApikeysConsumer } from 'store/ApikeysContext'
import { AuthContext } from 'store/AuthContext'
import { USER_ROLES } from 'constants/constants'
import {
  Button,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { ITextChange } from 'interfaces/interfaces'
import SearchableSelect from 'components/Inputs/SearchableSelect'
import { FeedbackContext } from 'store/FeedbackContext'
import { ContentConsumer } from 'store/ContentContext'

interface IApikeysFormProps {
  createApikey?: Function
  // createSuccess?: boolean;
  updateSuccess?: boolean
  users?: IUser[]
  getDetails?: Function
  apikeyDetails?: IApikey
  updateApikey?: Function
  refreshApikeys?: Function
}

const ApikeysFormScreen = ({
  updateSuccess,
  createApikey,
  // createSuccess,
  users,
  getDetails,
  updateApikey,
  apikeyDetails,
  refreshApikeys,
}: IApikeysFormProps): JSX.Element => {
  const [apikey, setApikey] = useState<IApikey>()
  const [usersList, setUsersList] = useState<IUser[]>()
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [selectedUserId, setSelectedUserId] = useState<string>('')
  const [generatedApikey, setGeneratedApikey] = useState<string | undefined>()
  const history = useHistory()
  const { id } = useParams<IIdParam>()
  const { user: loggedUser } = useContext(AuthContext)
  const classes = useStyles()
  const { t } = useTranslation()
  const { openErrorSnack } = useContext(FeedbackContext)

  const isAdminUser = useMemo(() => loggedUser?.roles.includes(USER_ROLES.superAdmin), [loggedUser])

  useEffect(() => {
    if (id && getDetails) {
      getDetails(id)
      setIsEditMode(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (apikeyDetails && id) {
      setApikey({
        id: apikeyDetails?.id,
        comment: apikeyDetails.comment,
        whitelistedIPs: apikeyDetails.whitelistedIPs,
        userId: apikeyDetails.userId,
        tenantId: apikeyDetails.tenantId,
      })
      setSelectedUserId(apikeyDetails?.userId || '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apikeyDetails])

  useEffect(() => {
    if (updateSuccess && refreshApikeys) {
      history.push(ROUTES_PATH.apiKeysList)
      refreshApikeys()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSuccess])

  useEffect(() => {
    if (loggedUser) {
      // get the list of users available for the tenant
      if (isAdminUser) {
        if (users && users.length > 0 && isAdminUser) {
          setUsersList(users)
        }
      } else if (loggedUser) {
        setUsersList([{ ...loggedUser, active: true } as IUser])
        setSelectedUserId(loggedUser.id)
        setApikey({ ...apikey, userId: loggedUser.id })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser, users])

  const handleSaveClick = async (): Promise<void> => {
    if (apikey && selectedUserId && apikey?.comment?.trim()) {
      if (id) {
        if (updateApikey) {
          await updateApikey(id, apikey)
        }
      } else {
        if (createApikey) {
          const createdApikey = await createApikey(apikey)
          if (createdApikey) {
            setGeneratedApikey(createdApikey.apiKey)
          }
        }
      }
    } else {
      openErrorSnack('ApikeyScreen.missingInfo')
    }
  }

  const handleCancelClick = (): void => {
    history.push(ROUTES_PATH.apiKeysList)
  }

  const handleInputChange = (event: ITextChange): void => {
    const { target } = event
    const { name } = target

    if (name === 'comment') {
      setApikey({ ...apikey, [name]: target.value })
    }
    if (name === 'whitelistedIPs') {
      if (target.value?.length > 0) {
        const values = target.value.split(',')
        setApikey({ ...apikey, whitelistedIPs: values })
      } else setApikey({ ...apikey, whitelistedIPs: undefined })
    }
  }

  const handleSelectChange = (
    event: React.ChangeEvent<{ value: unknown; name?: string }>,
  ): void => {
    const { target } = event
    const name = target.name as string
    const value = target.value as string

    if (name === 'userId') setSelectedUserId(value)

    setApikey({ ...apikey, [name]: value })
  }

  const closeNewApikeyDialog = () => {
    setGeneratedApikey(undefined)
    history.push(ROUTES_PATH.apiKeysList)
    if (refreshApikeys) refreshApikeys()
  }

  const handleCopyToClipboard = () => {
    if (generatedApikey) {
      navigator.clipboard.writeText(generatedApikey)
    }
  }

  return (
    <>
      {id && apikey && <h3 data-cy="apikeyName">{`${apikey.comment}`}</h3>}
      <Grid item xs={10} className={classes.infoContainer}>
        <CardHeader
          title={t('ApikeyScreen.title').toUpperCase()}
          className={classes.header}
          titleTypographyProps={{
            variant: 'subtitle1',
            style: {
              fontWeight: 'bolder',
            },
          }}
        />
        <CardContent className={classes.content}>
          <div className={classes.fieldsContainer}>
            {!isEditMode && (
              <>
                <SearchableSelect
                  dataCy="userSelect"
                  label={t('ApikeyScreen.user')}
                  name="userId"
                  required
                  selectedValue={selectedUserId}
                  options={usersList?.map((user) => ({
                    id: user.id,
                    name: `${user.firstName} ${user.lastName}`,
                  }))}
                  onChange={handleSelectChange}
                  disabled={!isAdminUser}
                />
              </>
            )}
            <TextField
              data-cy="comment"
              name="comment"
              value={apikey?.comment || ''}
              required
              error={apikey?.comment === ''}
              label={t('ApikeyScreen.comment').toUpperCase()}
              onChange={handleInputChange}
              type="text"
            />
            <TextField
              data-cy="whitelistedIPs"
              name="whitelistedIPs"
              value={apikey?.whitelistedIPs || ''}
              label={t('ApikeyScreen.allowedips').toUpperCase()}
              onChange={handleInputChange}
              type="text"
            />
          </div>
        </CardContent>
      </Grid>
      <FormAction onSaveClick={handleSaveClick} onCancelClick={handleCancelClick} />
      {generatedApikey && (
        <Dialog
          aria-describedby="simple-modal-description"
          onClose={closeNewApikeyDialog}
          aria-labelledby="date-dialog"
          open={!!generatedApikey}
        >
          <DialogTitle data-cy="modal-description" id="date-dialog">
            {t('ApikeyScreen.newKey')}
          </DialogTitle>
          <DialogContent>
            <span>{t('ApikeyScreen.newKeyMessage', { generatedApikey })}</span>
          </DialogContent>
          <DialogActions>
            <Button data-cy="copyToClipboardButton" onClick={handleCopyToClipboard} color="primary">
              {t('ApikeyScreen.copy')}
            </Button>
            <Button data-cy="closeButton" onClick={closeNewApikeyDialog} color="primary">
              {t('ApikeyScreen.close')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export default (props: IApikeysFormProps): JSX.Element => (
  <ApikeysProvider>
    <ContentConsumer>
      {(contentCtx): JSX.Element => (
        <ApikeysConsumer>
          {(apikeysCtx): JSX.Element => (
            <ApikeysFormScreen
              updateSuccess={apikeysCtx.updateSuccess}
              updateApikey={apikeysCtx.updateApikey}
              getDetails={apikeysCtx.getDetails}
              apikeyDetails={apikeysCtx.apikeyDetails}
              users={contentCtx.users}
              createApikey={apikeysCtx.createApikey}
              refreshApikeys={apikeysCtx.updateApikeys}
              {...props}
            />
          )}
        </ApikeysConsumer>
      )}
    </ContentConsumer>
  </ApikeysProvider>
)
