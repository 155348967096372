import React, { CSSProperties, useCallback, useContext, useEffect, useState } from 'react'
import 'leaflet-geometryutil'
import 'leaflet-snap'
import { LatLngBounds, latLngBounds } from 'leaflet'
import { Visibility, VisibilityOff, ExpandMore, ExpandLess } from '@material-ui/icons'
import { Button, Paper } from '@material-ui/core'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useTheme, Theme } from '@material-ui/core/styles'
import clsx from 'clsx'
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd'

import MapProvider from 'store/MapContext'
import { IMarker, ITourMapItem, ITourStopsNumber, IStopMarker } from 'interfaces/map'
import { StyledMapContainer } from 'screens/PlanningCategory/PlanningScreen/components/BasicComponents'
import { StopStatus, TOUR_STATUS_ENUM } from 'constants/constants'
import { AuthContext } from 'store/AuthContext'
import { FeedbackContext } from 'store/FeedbackContext'
import { isIError } from 'api/types'
import ToursApi from 'api/tours'
import useStyles from 'components/Map/styles'
import { IVisit } from 'interfaces/IOrders'
import { ExecutionContext } from 'store/ExecutionContext'
import Polyline from '../Polyline'
import DriverMarker from '../DriverMarker'
import COLORS from '../LinesColors'
import Map from '../Map'
import PopupControl from './PopupControl'
import FabMenu, { ScreenMode } from './FabMenu'
import TourItem from './TourItem'
import TourColumn from './TourColumn'
import Header from './Header'
import UnassignedVisits from './UnassignedVisits'
import StyledPaper, { WIDTH, WIDTH_VALUE } from './StyledPaper'
import UnassignedVisitsMarkers from '../UnassignedVisitsMarkers'
import ColorsLegend from './ColorsLegend'
import ExecutionStopsMarkers from '../ExecutionStopsMarkers'
import ChangeMapView from '../ChangeMapView'

interface IMapProps {
  mapName: string
  drivers: IMarker[]
  draggable?: boolean
  filteredTours: ITourMapItem[]
  style?: CSSProperties
  displayMode: ScreenMode
  toggleDisplayMode: () => void
  unassignedVisits?: IVisit[]
  reloadData?: () => void
}

const ToursColumnsContainer = styled(Paper)<{ theme: Theme }>`
  display: flex;
  overflow: auto;
  background-color: ${(props) => props.theme.color.headerPaper};
`

const UNASSIGNED_VISITS_DROPPABLE_ID = 'UNASSIGNED_VISITS_DROPPABLE_ID'

const ToursMap = ({
  mapName,
  drivers = [],
  filteredTours = [],
  style,
  displayMode,
  toggleDisplayMode,
  unassignedVisits = [],
  reloadData,
}: IMapProps): JSX.Element => {
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false)
  const [isPopupControlClicked, setPopupControlClicked] = useState<boolean>(false)
  const [isShowHideDetailsButtonClicked, setIsShowHideDetailsButtonClicked] = useState<boolean>(false)
  const [areDetailsShown, setAreDetailsShown] = useState<boolean>(false)
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const [mapBounds, setMapBounds] = useState<LatLngBounds>(latLngBounds([]))
  const [tours, setTours] = useState<ITourMapItem[]>(filteredTours)
  const { openErrorSnack, toggleLoader } = useContext(FeedbackContext)
  const { shouldDisplayVisitsWithoutTour } = useContext(AuthContext)
  const { highlightedElement } = useContext(ExecutionContext)

  useEffect(() => {
    setTours(filteredTours)
  }, [filteredTours])

  useEffect(() => {
    const markers = L.featureGroup()
    tours.forEach((tour: ITourMapItem) => {
      tour.stops.forEach((stop) => {
        if (stop.address.latitude && stop.address.longitude) {
          L.marker([stop.address.latitude, stop.address.longitude]).addTo(markers)
        }
      })
    })
    setMapBounds(markers.getBounds())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tours.length])

  const handleShowDriversInfoClick = (): void => {
    setIsPopupOpen(true)
    setPopupControlClicked((prevState) => !prevState)
  }

  const handleHideDriversInfoClick = (): void => {
    setIsPopupOpen(false)
    setPopupControlClicked((prevState) => !prevState)
  }

  const handleShowDetailsClick = (): void => {
    setAreDetailsShown(true)
    setIsShowHideDetailsButtonClicked((prevState) => !prevState)
  }

  const handleHideDetailsClick = (): void => {
    setAreDetailsShown(false)
    setIsShowHideDetailsButtonClicked((prevState) => !prevState)
  }

  const getMapWidth = useCallback(
    () =>
      displayMode === ScreenMode.MapAndListSummary
        ? shouldDisplayVisitsWithoutTour
          ? `calc(100% - ${2 * WIDTH_VALUE}px)`
          : `calc(100% - ${WIDTH})`
        : displayMode === ScreenMode.MapAndList
          ? '50%'
          : displayMode === ScreenMode.List
            ? '0%'
            : '100%',
    [displayMode, shouldDisplayVisitsWithoutTour],
  )

  const getNumberOfStops = (tour: ITourMapItem): ITourStopsNumber => {
    const { stops } = tour
    const filteredStops = [...stops]
    filteredStops.shift()
    filteredStops.pop()
    const stopsNumber = filteredStops.length
    const realizedStopsNumber = filteredStops.filter(
      (stop) =>
        (stop.status === StopStatus.Delivered && stop.realArrival)
        || stop.status === StopStatus.Dropped,
    ).length
    return { stopsNumber, realizedStopsNumber, filteredStops }
  }

  async function reorderStopsInTour(tourId: string, stops: string[]): Promise<void> {
    toggleLoader(true)
    const res = await ToursApi.reorderStops(tourId, { stops })
    if (isIError(res)) {
      openErrorSnack(res.error.message)
    }
    toggleLoader(false)
  }

  async function handleAddVisitToTour(
    tourId: string,
    visitId: string,
    index: number,
  ): Promise<void> {
    toggleLoader(true)
    const res = await ToursApi.addVisitToTour(tourId, {
      visitId,
      index,
    })
    if (isIError(res)) {
      openErrorSnack(res.error.message)
    }
    toggleLoader(false)
    if (reloadData) reloadData()
  }

  async function handleRemoveStopFromTour(tourId: string, stopId?: string): Promise<void> {
    toggleLoader(true)
    const res = await ToursApi.removeStopFromTour(tourId, stopId)
    if (isIError(res)) {
      openErrorSnack(res.error.message)
    }
    toggleLoader(false)
    if (reloadData) reloadData()
  }

  const getTourById = (tourId: string): ITourMapItem | undefined =>
    tours.find((tour) => tour.tourId === tourId)

  const getStopByIndexAndTourId = (tourId: string, stopIndex: number): IStopMarker | undefined => {
    const tour = getTourById(tourId)
    return tour?.stops[stopIndex]
  }

  const validateTourStatus = (tourId: string): boolean => {
    const tourToValidate = getTourById(tourId)
    const tourStatus = tourToValidate?.status
    return (
      tourStatus !== undefined
      && tourStatus !== TOUR_STATUS_ENUM.FINISHED
      && tourStatus !== TOUR_STATUS_ENUM.DROPPED
    )
  }

  const validateTourGoingFirstStop = (tourId: string, stopIndex: number): boolean => {
    if (stopIndex !== 0) {
      return false
    }

    const tourToValidate = getTourById(tourId)
    const tourStatus = tourToValidate?.status

    return tourStatus === TOUR_STATUS_ENUM.GOING
  }

  const validateStopStatus = (tourId: string, stopIndex: number): boolean => {
    const stop = getStopByIndexAndTourId(tourId, stopIndex)
    const stopStatus = stop?.status

    return (
      stopStatus !== undefined
      && stopStatus !== StopStatus.Delivered
      && stopStatus !== StopStatus.Dropped
    )
  }

  const validateAddStopToTourAtPosition = (
    destinationTourId: string,
    destinationIndex: number,
  ): boolean => {
    // tour should not be finished nor dropped
    const isDestinationTourStatusValid = validateTourStatus(destinationTourId)
    if (!isDestinationTourStatusValid) {
      openErrorSnack(t('MapScreen.messages.invalidTour'))
      return false
    }

    // the next stop should not be delivered nor dropped
    const isPostDestinationStopStatusValid = validateStopStatus(
      destinationTourId,
      destinationIndex + 1,
    )
    if (!isPostDestinationStopStatusValid) {
      const nextStop = getStopByIndexAndTourId(destinationTourId, destinationIndex + 1)
      const nextStopLabel = nextStop?.address.label
      openErrorSnack(t('MapScreen.messages.invalidNextStop', { nextStopLabel }))
      return false
    }

    // the previous stop should not be delivered nor dropped
    const isPreDestinationStopStatusValid = validateStopStatus(destinationTourId, destinationIndex)
    if (!isPreDestinationStopStatusValid) {
      const destinationStop = getStopByIndexAndTourId(destinationTourId, destinationIndex + 1)
      const destinationStopLabel = destinationStop?.address.label
      openErrorSnack(t('MapScreen.messages.invalidPreviousStop', { destinationStopLabel }))
      return false
    }

    // cannot replace first stop if tour is on its way
    const isTourGoingAndFirstStop = validateTourGoingFirstStop(destinationTourId, destinationIndex)
    if (isTourGoingAndFirstStop) {
      const destinationStop = getStopByIndexAndTourId(destinationTourId, 1)
      const destinationStopLabel = destinationStop?.address.label
      openErrorSnack(t('MapScreen.messages.invalidPreviousStop', { destinationStopLabel }))
      return false
    }

    return true
  }

  const validateRemoveStopFromTourAtPosition = (
    sourceTourId: string,
    sourceIndex: number,
  ): boolean => {
    // source tour should not be finished nor dropped
    const isSourceTourStatusValid = validateTourStatus(sourceTourId)
    if (!isSourceTourStatusValid) {
      openErrorSnack(t('MapScreen.messages.invalidTour'))
      return false
    }

    // the source stop should not be delivered nor dropped
    const isSourceStopStatusValid = validateStopStatus(sourceTourId, sourceIndex + 1)
    if (!isSourceStopStatusValid) {
      openErrorSnack(t('MapScreen.messages.invalidStop'))
      return false
    }

    // the stop before the source stop should not be delivered nor dropped
    const isPreSourceStopStatusValid = validateStopStatus(sourceTourId, sourceIndex)
    if (!isPreSourceStopStatusValid) {
      const destinationStop = getStopByIndexAndTourId(sourceTourId, sourceIndex + 1)
      const destinationStopLabel = destinationStop?.address.label
      openErrorSnack(t('MapScreen.messages.invalidPreviousStop', { destinationStopLabel }))
      return false
    }

    // cannot displace first stop if tour is on its way
    const isTourGoingAndFirstStop = validateTourGoingFirstStop(sourceTourId, sourceIndex)
    if (isTourGoingAndFirstStop) {
      const destinationStop = getStopByIndexAndTourId(sourceTourId, 1)
      const destinationStopLabel = destinationStop?.address.label
      openErrorSnack(t('MapScreen.messages.invalidPreviousStop', { destinationStopLabel }))
      return false
    }

    return true
  }

  const onDragEnd = (result: DropResult): void => {
    const { source, destination } = result

    if (!destination) return

    if (source.droppableId === destination.droppableId && source.index === destination.index) return

    if (source.droppableId === UNASSIGNED_VISITS_DROPPABLE_ID) {
      if (destination.droppableId === UNASSIGNED_VISITS_DROPPABLE_ID) return

      const destinationTourId = destination.droppableId.split('-')[1]

      const isAddVisitAtNewPositionValid = validateAddStopToTourAtPosition(
        destinationTourId,
        destination.index,
      )
      if (!isAddVisitAtNewPositionValid) {
        return
      }

      const { visitId } = unassignedVisits[source.index]

      handleAddVisitToTour(destinationTourId, visitId, destination.index + 1)
    } else if (destination.droppableId === UNASSIGNED_VISITS_DROPPABLE_ID) {
      const sourceTourId = source.droppableId.split('-')[1]

      const isRemoveStopFromPositionValid = validateRemoveStopFromTourAtPosition(
        sourceTourId,
        source.index,
      )
      if (!isRemoveStopFromPositionValid) {
        return
      }

      const stopToRemove = getStopByIndexAndTourId(sourceTourId, source.index + 1)
      handleRemoveStopFromTour(sourceTourId, stopToRemove?.stopId)
    } else {
      const destinationTourId = destination.droppableId.split('-')[1]
      const sourceTourId = source.droppableId.split('-')[1]

      if (destinationTourId !== sourceTourId) {
        openErrorSnack(t('MapScreen.messages.cannotMoveStopDifferentTour'))
        return
      }

      const isRemoveStopFromPositionValid = validateRemoveStopFromTourAtPosition(
        sourceTourId,
        source.index,
      )
      if (!isRemoveStopFromPositionValid) {
        return
      }

      const isAddStopAtNewPositionValid = validateAddStopToTourAtPosition(
        destinationTourId,
        destination.index,
      )
      if (!isAddStopAtNewPositionValid) {
        return
      }

      // Reorder stops in tour or move stop from tour (source) to tour (destination)
      setTours((prevTours) => {
        const sourceTours = [...prevTours]
        const sourceTourIndex = sourceTours.findIndex((tour) => tour.tourId === sourceTourId)
        const destinationTourIndex = sourceTours.findIndex(
          (tour) => tour.tourId === destinationTourId,
        )
        const { stops: sourceStops } = sourceTours[sourceTourIndex]
        const { stops: destinationStops } = sourceTours[destinationTourIndex]
        // +1 because we shouldn't take into consideration the first stop wich represents the warehouse
        const elementToMove = sourceStops.splice(source.index + 1, 1)
        // +1 because we shouldn't take into consideration the first stop wich represents the warehouse
        destinationStops.splice(destination.index + 1, 0, elementToMove[0])
        // do not sent the delivered or dropped stops nor the first and last which represent the warehouse
        reorderStopsInTour(
          sourceTourId,
          sourceStops
            .filter(
              (stop) => stop.status !== StopStatus.Delivered && stop.status !== StopStatus.Dropped,
            )
            .map((stop) => stop.stopId)
            .slice(1, -1),
        )
        // when implementing D&D from tour to tour, we should also reorder stops of destination tour
        return sourceTours
      })
    }
  }

  return (
    <>
      <StyledMapContainer mapWidth={getMapWidth()}>
        <Map
          mapName={mapName}
          bounds={mapBounds.isValid() ? mapBounds : undefined}
          className={classes.toursMap}
          width={getMapWidth()}
          style={style}
        >
          <PopupControl
            dataCy="showDriversInfo"
            titleKey="showDriversInfo"
            className={classes.showDriverPopup}
            onPress={handleShowDriversInfoClick}
          >
            <Visibility />
          </PopupControl>
          <PopupControl
            titleKey="hideDriversInfo"
            dataCy="hideDriversInfo"
            containerClassName={classes.hideDriverPopupContainer}
            className={classes.hideDriverPopup}
            onPress={handleHideDriversInfoClick}
          >
            <VisibilityOff />
          </PopupControl>
          <ColorsLegend />
          <ChangeMapView highlightedElement={highlightedElement} />
          {tours.map((tour: ITourMapItem, index: number) => (
            <React.Fragment key={tour.tourId}>
              <ExecutionStopsMarkers
                tour={tour}
                color={COLORS[index % COLORS.length]}
                tourNumber={tour.tourNumber}
                driver={tour.driver}
              />
              <Polyline
                tour={tour}
                color={COLORS[index % COLORS.length]}
                allowResumeAbandonnedTours
              />
              {tour.status === TOUR_STATUS_ENUM.GOING && (
                <DriverMarker
                  data={drivers.filter((driver) => driver.driverId === tour.driver?.id)[0]}
                  color={COLORS[index % COLORS.length]}
                  isPopupOpen={isPopupOpen}
                  isPopupControlClicked={isPopupControlClicked}
                  numberOfStops={getNumberOfStops(tour)}
                />
              )}
            </React.Fragment>
          ))}
          {shouldDisplayVisitsWithoutTour && <UnassignedVisitsMarkers visits={unassignedVisits} />}
        </Map>
      </StyledMapContainer>
      {displayMode !== ScreenMode.MapAndList && displayMode !== ScreenMode.List && (
        <>
          {shouldDisplayVisitsWithoutTour && (
            <UnassignedVisits displayMode={displayMode} visits={unassignedVisits} />
          )}
          <StyledPaper
            className={clsx(
              classes.scrollable,
              classes.toursList,
              displayMode === ScreenMode.Map && classes.hidden,
            )}
            square
            data-cy="tourContainer"
          >
            <Header theme={theme}>
              <b data-cy="title">{`${t('MapScreen.tours')} (${tours.length})`}</b>
              <div>
                <Button
                  className={classes.showDetailsButton}
                  title={t('MapScreen.showDetails')}
                  onClick={handleShowDetailsClick}
                  data-cy="showDetailsButton"
                >
                  <ExpandMore />
                </Button>
                <Button
                  className={classes.showDetailsButton}
                  title={t('MapScreen.hideDetails')}
                  onClick={handleHideDetailsClick}
                  data-cy="hideDetailsButton"
                >
                  <ExpandLess />
                </Button>
              </div>
            </Header>
            {tours.map((tour: ITourMapItem, index: number) => (
              <TourItem
                key={tour.tourId}
                tour={tour}
                areDetailsShown={areDetailsShown}
                isShowHideDetailsButtonClicked={isShowHideDetailsButtonClicked}
                color={COLORS[index % COLORS.length]}
                numberOfStops={getNumberOfStops(tour)}
              />
            ))}
          </StyledPaper>
        </>
      )}
      {(displayMode === ScreenMode.MapAndList || displayMode === ScreenMode.List) && (
        <>
          <DragDropContext onDragEnd={onDragEnd}>
            {shouldDisplayVisitsWithoutTour && (
              <Droppable droppableId={UNASSIGNED_VISITS_DROPPABLE_ID}>
                {(provided): JSX.Element => (
                  <UnassignedVisits
                    displayMode={displayMode}
                    visits={unassignedVisits}
                    provided={provided}
                  />
                )}
              </Droppable>
            )}
            <ToursColumnsContainer
              elevation={10}
              square
              className={classes.scrollable}
              data-cy="tourColumnsContainer"
              theme={theme}
            >
              {tours.map((tour: ITourMapItem, index: number) => (
                <TourColumn
                  key={tour.tourId}
                  tour={tour}
                  color={COLORS[index % COLORS.length]}
                  numberOfStops={getNumberOfStops(tour)}
                />
              ))}
            </ToursColumnsContainer>
          </DragDropContext>
        </>
      )}
      <FabMenu displayMode={displayMode} toggleDisplayMode={toggleDisplayMode} />
    </>
  )
}

export default (props: JSX.IntrinsicAttributes & IMapProps): JSX.Element => (
  <MapProvider>
    <ToursMap {...props} />
  </MapProvider>
)
