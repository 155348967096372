import { createStyles, makeStyles } from '@material-ui/core/styles'

export default makeStyles(() =>
  createStyles({
    homeContainer: {
      display: 'flex',
      height: '85vh',
    },
    img: {
      margin: 'auto',
      height: '30vh',
    },
  }),
)
