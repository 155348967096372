import React, { useContext, useEffect, useState } from 'react'
import Drawer from '@material-ui/core/Drawer'
import clsx from 'clsx'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { useTheme } from '@material-ui/core/styles'
import { CardMedia } from '@material-ui/core'
import { useLocation } from 'react-router-dom'

import { IMenuEntries } from 'interfaces'
import { Images } from 'constants/images'
import { KlareoProduct, Role } from 'constants/constants'
import { AuthContext } from 'store/AuthContext'
import { ThemeContext } from 'store/ThemeContext'
import ListCategory from './ListCategory'
import useStyles from './styles'

interface ILink {
  label: string
  shortLabel: string
  url?: string
  package?: KlareoProduct
  roles: Role[]
}

interface IDrawerProps {
  entries?: IMenuEntries[]
  opened: boolean
  toggleDrawer: () => void
}

const CURRENT_APP = { label: 'Planning', shortLabel: 'PLAN.' }

const CustomDrawer = ({ entries, opened, toggleDrawer }: IDrawerProps): JSX.Element => {
  const classes = useStyles()
  const theme = useTheme()
  const { pathname } = useLocation()
  const { user } = useContext(AuthContext)
  const klareoProducts = user?.tenantConfig.klareoProducts || []
  const currentUserRoles = user?.roles || []
  const { darkMode } = useContext(ThemeContext)

  const klareoLogo = darkMode ? Images.LOGO_FULL_DARK_MODE : Images.LOGO_FULL_LIGHT_MODE

  const OTHER_APPS: ILink[] = [
    {
      label: 'Relay',
      shortLabel: 'REL.',
      url: process.env.REACT_APP_KLAREO_UDC_URL,
      package: KlareoProduct.UdcPackage,
      roles: [Role.UdcAdmin, Role.UdcExploitant],
    },
    {
      label: 'Config',
      shortLabel: 'CONF.',
      url: process.env.REACT_APP_KLAREO_MDM_URL,
      roles: [Role.Pilot, Role.UdcAdmin],
    },
  ]

  const [menuEntries, setMenuEntries] = useState<IMenuEntries[]>(entries as IMenuEntries[])

  const shouldDisplayLink = (link: ILink): boolean => {
    const hasPackage = link.package ? klareoProducts.includes(link.package) : true
    const hasRoleAccess = link.roles
      ? currentUserRoles.some((role) => link.roles?.includes(role as Role))
      : true
    return hasPackage && hasRoleAccess
  }

  useEffect(() => {
    if (entries) {
      setMenuEntries(entries)
    }
  }, [entries])

  const handleMenuClick = (title: string, isMenuOpen: boolean): void => {
    setMenuEntries((prevMenuEntries) =>
      prevMenuEntries.map((menu) => ({
        ...menu,
        isMenuOpen: menu.title === title ? isMenuOpen : false,
      })),
    )
  }

  return (
    <Drawer
      data-cy="menuCategories"
      variant="permanent"
      className={clsx(classes.drawer, opened ? classes.drawerOpen : classes.drawerClose)}
      classes={{
        paper: clsx(opened ? classes.drawerOpen : classes.drawerClose),
      }}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={toggleDrawer}>
          {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </div>
      <div className={classes.currentApp}>
        {opened ? CURRENT_APP.label : CURRENT_APP.shortLabel}
      </div>
      {menuEntries?.map((elem) => (
        <ListCategory
          drawerOpen={opened}
          currentPath={pathname}
          key={elem.title}
          category={elem}
          isMenuOpen={elem.isMenuOpen}
          onMenuClick={handleMenuClick}
        />
      ))}
      {OTHER_APPS.filter((app) => shouldDisplayLink(app)).map(({ url, label, shortLabel }) => (
        <a
          data-cy={`menu-${label}`}
          key={url}
          className={classes.externalLink}
          href={url}
          aria-label={url}
          target="_blank"
          rel="noreferrer"
        >
          <span>{opened ? label : shortLabel}</span>
          {opened && <OpenInNewIcon className={classes.externalLinkIcon} />}
        </a>
      ))}
      <CardMedia
        className={opened ? classes.logoFull : classes.logo}
        component="img"
        image={opened ? klareoLogo : Images.LOGO}
      />
    </Drawer>
  )
}

export default CustomDrawer
