import React, { ChangeEvent, useEffect, useState } from 'react'
import Add from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import Card from 'components/Card/Card'
import DateRangePicker from 'components/Inputs/DateRangePicker'
import { DateType } from 'constants/constants'
import EventBusyIcon from '@material-ui/icons/EventBusy'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { ICarrierWarehouse, ICarrierWarehouseValidity } from 'interfaces/interfaces'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import useStyles from 'constants/cruStyles'
import { useTranslation } from 'react-i18next'
import SearchableSelect from 'components/Inputs/SearchableSelect'
import { ICarrier } from 'interfaces/ICarrier'
import { IWarehouse } from 'interfaces/IWarehouse'

type Validity = IWarehouse | ICarrierWarehouse
type Options = IWarehouse | ICarrier

interface ISiteCarriersValidityCardProps {
  cardTitle: string
  options: Options[]
  onValidityChange: Function
  type: string
  validities: Validity[]
}

const SiteCarriersValidityCard = ({
  cardTitle,
  options,
  onValidityChange,
  type,
  validities,
}: ISiteCarriersValidityCardProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()

  const activeOptions = options.filter((option) => option.active)
  const activeOptionsIds = activeOptions.map((activeOption) => activeOption.id)

  const initialValues = validities
    ?.filter((validity) => activeOptionsIds.includes(validity.id))
    .map((validity) => ({
      id: validity.id || '',
      validityStartDate: validity.validityStartDate,
      validityEndDate: validity.validityEndDate,
      name: validity.name,
    })) || []

  const [selectedValidities, setSelectedValidities] = useState<ICarrierWarehouseValidity[]>(initialValues)

  useEffect(() => {
    onValidityChange(selectedValidities)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValidities])

  const handleValidityChange = (id: string, key: string, value: string | undefined): void => {
    let newSelectedValidities: Validity[]
    const validityToUpdate = validities.find(
      (validity: IWarehouse | ICarrierWarehouse) => validity.id === id,
    )
    const validityToUpdateIndex = validities.findIndex((validity) => validity.id === id)
    if (validityToUpdate && validityToUpdateIndex !== undefined && validities) {
      newSelectedValidities = [...validities]
      if (value) {
        newSelectedValidities[validityToUpdateIndex] = { ...validityToUpdate, [key]: value }
      } else {
        delete validityToUpdate[key]
        newSelectedValidities[validityToUpdateIndex] = { ...validityToUpdate }
      }
      setSelectedValidities(newSelectedValidities)
    }
  }

  const handleAddValidity = () => {
    const newSelectedValidities = [...selectedValidities]
    newSelectedValidities.push({
      id: '',
      name: '',
      validityEndDate: '',
      validityStartDate: '',
      isNew: true,
    })
    setSelectedValidities(newSelectedValidities)
  }

  const handleDeleteValidity = (event: React.MouseEvent<HTMLElement>) => {
    const { id } = event.currentTarget
    const index = selectedValidities?.findIndex((site) => site.id === id)
    if (index > -1) {
      const newSelectedValidities = [...selectedValidities]
      newSelectedValidities.splice(index, 1)
      setSelectedValidities(newSelectedValidities)
    }
  }

  const handleReferenceChange = (event: ChangeEvent<{ value: unknown; name?: string }>) => {
    const newSelectedValidities = [...selectedValidities]
    const index = selectedValidities.findIndex(
      (selectedSite) => selectedSite.id === event.target.name,
    )
    if (index !== -1) {
      newSelectedValidities[index] = {
        id: event.target.value as string,
        name: activeOptions.find((option) => option.id === event.target.value)?.name || '',
        isNew: newSelectedValidities[index].isNew,
      }
      setSelectedValidities(newSelectedValidities)
    }
  }

  const handleCloseValidity = (event: React.MouseEvent<HTMLElement>) => {
    let endDate: Date
    const now = new Date()
    const { id } = event.currentTarget
    const validityToUpdate = selectedValidities?.find((site) => site.id === id)
    const validityToUpdateIndex = selectedValidities?.findIndex((site) => site.id === id)
    if (validityToUpdate && validityToUpdateIndex > -1) {
      if (validityToUpdate && validityToUpdate.validityStartDate) {
        endDate = validityToUpdate.validityStartDate < now.toISOString()
          ? now
          : new Date(validityToUpdate.validityStartDate)
      } else {
        endDate = now
      }
      const newSelectedValidities = [...selectedValidities]
      newSelectedValidities[validityToUpdateIndex] = {
        ...validityToUpdate,
        validityEndDate: endDate.toISOString(),
      }
      setSelectedValidities(newSelectedValidities)
    }
  }

  const handleValidityEndDateChange = (date: MaterialUiPickersDate, id?: string) => {
    if (id) {
      handleValidityChange(id, 'validityEndDate', date?.toISOString())
    }
  }

  const handleValidityStartDateChange = (date: MaterialUiPickersDate, id?: string) => {
    if (id) {
      handleValidityChange(id, 'validityStartDate', date?.toISOString())
    }
  }

  const cannotAdd = Boolean(selectedValidities.find((cosr) => cosr.id === ''))
    || selectedValidities.length === options.length

  return (
    <Card
      dataCy="siteCarrierReferenceValidityCard"
      title={cardTitle.toUpperCase()}
      contentClassName={classes.contentRef}
    >
      <Grid container>
        <Grid container justify="flex-end">
          <Grid item>
            <Button
              data-cy="addButton"
              onClick={handleAddValidity}
              startIcon={<Add />}
              variant="outlined"
              disabled={cannotAdd}
            >
              {t('form.add')}
            </Button>
          </Grid>
        </Grid>
        <Grid container className={classes.rawRowContainer}>
          {selectedValidities?.map((validity) => (
            <Grid
              container
              item
              data-cy={`validity-container-${validity.id}`}
              key={validity.id}
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs>
                <SearchableSelect
                  isStandard
                  forceLabel
                  dataCy={`${type}Select-${validity.id}`}
                  label={
                    type === 'carrier' ? t('WarehouseScreen.carrier') : t('CarrierScreen.site')
                  }
                  name={validity.id}
                  required
                  selectedValue={validity.id}
                  options={(options as any) // eslint-disable-line @typescript-eslint/no-explicit-any
                    .filter(
                      (d) =>
                        d.id === validity.id
                        || !selectedValidities.find((cosr) => cosr.id === d.id),
                    )
                    .map((d) => ({ id: d.id, name: d.name }))}
                  onChange={handleReferenceChange}
                />
              </Grid>
              <Grid item>
                <DateRangePicker
                  id={validity.id}
                  onChangeStart={handleValidityStartDateChange}
                  onChangeEnd={handleValidityEndDateChange}
                  startDate={validity.validityStartDate}
                  endDate={validity.validityEndDate}
                  hideShiftPicker
                  dateType={DateType.DateTime}
                  isFullWidth
                  startDataCy={`cwvStartDate-${validity.id}`}
                  endDataCy={`cwvEndDate-${validity.id}`}
                  isStartClearable
                  isEndClearable
                />
              </Grid>
              <Grid container item xs justify="flex-end">
                {validity.isNew && (
                  <Button
                    id={validity.id}
                    data-cy={`delete-${validity.id}`}
                    onClick={handleDeleteValidity}
                  >
                    {t('form.delete')}
                  </Button>
                )}
                {!validity.isNew && (
                  <IconButton
                    id={validity.id}
                    data-cy={`close-${validity.id}`}
                    onClick={handleCloseValidity}
                  >
                    <EventBusyIcon />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Card>
  )
}

export default SiteCarriersValidityCard
