import React, { useContext, useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import clsx from 'clsx'

import { ITab } from 'interfaces/ITab'
import { AuthContext } from 'store/AuthContext'
import { checkUserRole } from 'utils/functions'
import TabPanel from './TabPanel'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  tab: {
    marginRight: 5,
  },
  selectedTab: {
    color: theme.color.primary,
  },
  reportingContainer: {
    marginLeft: 100,
    width: '100%',
  },
  configurationContainer: {
    width: '100%',
  },
}))

interface IVerticalTabProps {
  tabs: ITab[]
  isForConfiguration?: boolean
}

const VerticalTab = ({ tabs, isForConfiguration = false }: IVerticalTabProps): JSX.Element => {
  const classes = useStyles()
  const [currentMenuIndex, setCurrentMenuIndex] = useState<number>(0)
  const { user } = useContext(AuthContext)

  const filteredTabs = tabs.filter((tab) => checkUserRole(user, tab.roles))

  const handleChange = (_event: React.ChangeEvent<{}>, newMenuIndex: number): void => {
    setCurrentMenuIndex(newMenuIndex)
  }

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        value={currentMenuIndex}
        onChange={handleChange}
        aria-label="Vertical tabs"
        className={classes.tabs}
      >
        {filteredTabs.map((menu, index) => (
          <Tab
            className={clsx(classes.tab, currentMenuIndex === index && classes.selectedTab)}
            label={menu.label}
            key={`vertical-tab-${menu.label}`}
            aria-controls={`vertical-tabpanel-${index}`}
            data-cy={menu.label}
          />
        ))}
      </Tabs>
      {filteredTabs.map((menu, index) => (
        <TabPanel
          key={`vertical-tab-panel-${menu.label}`}
          value={currentMenuIndex}
          index={index}
          className={
            isForConfiguration ? classes.configurationContainer : classes.reportingContainer
          }
        >
          {menu.component}
        </TabPanel>
      ))}
    </div>
  )
}

export default VerticalTab
