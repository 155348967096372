import React, { useEffect, useState, useContext } from 'react'
import { Grid } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { IIdParam } from 'interfaces/IIdParam'
import { IOrderPlanVisit, IOrderDetails } from 'interfaces/IOrders'
import OrderCard from 'screens/OrderCategory/Details/components/OrderCard'
import { OrdersContext } from 'store/OrdersContext'
import PlanVisitDetailsCard from './components/PlanVisitDetailsCard'
import PlanVisitsCard from './components/PlanVisitsCard'
import PlanVisitTrackingCard from './components/PlanVisitTrackingCard'

const sortPlanVisitByDate = (a: IOrderPlanVisit, b: IOrderPlanVisit): number => {
  if (!a.plannedDate || !b.plannedDate) return 0
  return a.plannedDate.getTime() - b.plannedDate.getTime()
}

export default (): JSX.Element => {
  const { id } = useParams<IIdParam>()
  const { t } = useTranslation()
  const { fetchOrderDetails } = useContext(OrdersContext)
  const [orderDetails, setOrderDetails] = useState<IOrderDetails>()
  const [selectedPlanVisit, setSelectedPlanVisit] = useState<IOrderPlanVisit>()

  useEffect(() => {
    fetchOrderDetails(
      id,
      (response) => {
        setOrderDetails(response as IOrderDetails)
        setSelectedPlanVisit(
          [...(response as IOrderDetails).planVisits].sort(sortPlanVisitByDate)[0],
        )
      },
      true,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <>
      <h3 data-cy="orderDetailDescription">
        {`${t('OrdersScreen.order')} ${orderDetails?.orderNumber || ''}`}
      </h3>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Grid container direction="column" spacing={4}>
            <Grid item xs>
              <OrderCard orderDetails={orderDetails} setOrderDetails={setOrderDetails} />
            </Grid>
            <Grid item xs>
              {orderDetails && orderDetails.planVisits.length > 0 && (
                <PlanVisitsCard
                  planVisits={orderDetails.planVisits.sort(sortPlanVisitByDate)}
                  selectedPlanVisit={selectedPlanVisit}
                  setSelectedPlanVisit={setSelectedPlanVisit}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Grid container direction="column" spacing={4}>
            <Grid item xs>
              {orderDetails && orderDetails.planVisits.length > 0 && (
                <PlanVisitTrackingCard
                  orderNumber={orderDetails.orderNumber || ''}
                  planVisits={orderDetails.planVisits.sort(sortPlanVisitByDate)}
                />
              )}
            </Grid>
            <Grid item xs>
              {orderDetails && orderDetails.planVisits.length > 0 && (
                <PlanVisitDetailsCard selectedPlanVisit={selectedPlanVisit} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
