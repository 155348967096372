import React from 'react'
import styled from 'styled-components'

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const PercentageCard = styled.div<{ isOverLoaded: boolean }>`
  background-color: #fff;
  color: ${(props) => (props.isOverLoaded ? 'red' : 'green')};
  padding: 0 2px;
  border-radius: 4px;
`

interface IWrapper {
  title?: string
  children: JSX.Element
}

interface IVehicleAllocatedCapacityPercentage {
  percentage: number | null
  title?: string
}

const Wrapper = ({ title, children }: IWrapper): JSX.Element => {
  if (!title) {
    return children
  }

  return (
    <TitleContainer>
      <b data-cy="tour-title">{title}</b>
      {children}
    </TitleContainer>
  )
}

const VehicleAllocatedCapacityPercentage = ({
  percentage,
  title,
}: IVehicleAllocatedCapacityPercentage): JSX.Element => (
  <Wrapper title={title}>
    <PercentageCard isOverLoaded={!!percentage && percentage > 100}>
      <b>{`${percentage ?? ''}%`}</b>
    </PercentageCard>
  </Wrapper>
)

export default VehicleAllocatedCapacityPercentage
