import React, { useState } from 'react'
import { FormControlLabel, Switch, Typography } from '@material-ui/core'
import Card from 'components/Card/Card'
import useStyles from 'constants/cruStyles'
import { IPlanningMobile } from 'interfaces/interfaces'
import { useTranslation } from 'react-i18next'

interface ITextChange {
  target: {
    value: string
    name: string
    type?: string
    checked?: boolean
  }
}

interface IProps {
  planningMobile?: Partial<IPlanningMobile>
  onChange: (optimParam: IPlanningMobile) => void
}

const WarehousePlanningMobileCard = ({ planningMobile, onChange }: IProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [state, setState] = useState<Partial<IPlanningMobile>>({
    dispute: planningMobile?.dispute,
    vehicleNote: planningMobile?.vehicleNote,
    shift: planningMobile?.shift,
    tourControl: planningMobile?.tourControl,
    signature: planningMobile?.signature,
    evaluation: planningMobile?.evaluation,
    unloadingPackage: planningMobile?.unloadingPackage,
    parcelLoadingControl: planningMobile?.parcelLoadingControl,
    services: planningMobile?.services,
    clientCode: planningMobile?.clientCode,
    scanUnknownParcelsToPickup: planningMobile?.scanUnknownParcelsToPickup,
    arrivalReport: planningMobile?.arrivalReport,
    returnables: planningMobile?.returnables,
  })

  const handleChange = (event: ITextChange): void => {
    const { target } = event
    const { name } = target
    const value = target.type === 'checkbox' ? target.checked : target.value

    onChange({ ...state, [name]: value } as IPlanningMobile)
    setState({ ...state, [name]: value })
  }

  return (
    <Card title={t('SitesScreen.planningMobile').toUpperCase()}>
      <div className={classes.fieldsContainer}>
        <div className={classes.subCard}>
          <Typography className={classes.subCardTitle}>
            {t('SitesScreen.tour').toUpperCase()}
          </Typography>
          <div className={classes.subCardList}>
            <FormControlLabel
              className={classes.switchContainer}
              control={(
                <Switch
                  color="primary"
                  checked={state?.tourControl || false}
                  onChange={handleChange}
                  name="tourControl"
                />
              )}
              labelPlacement="start"
              label={t('SitesScreen.tourNumberControl').toUpperCase()}
            />
          </div>
        </div>

        <div className={classes.subCard}>
          <div>
            <FormControlLabel
              className={classes.switchContainer}
              control={(
                <Switch
                  color="primary"
                  checked={state?.shift || false}
                  onChange={handleChange}
                  name="shift"
                />
              )}
              labelPlacement="start"
              label={(
                <Typography className={classes.boldLabel}>
                  {t('SitesScreen.shift').toUpperCase()}
                </Typography>
              )}
            />
          </div>
          {state.shift && (
            <div className={classes.subCardList}>
              <FormControlLabel
                className={classes.switchContainer}
                control={(
                  <Switch
                    color="primary"
                    checked={state?.vehicleNote || false}
                    onChange={handleChange}
                    name="vehicleNote"
                  />
                )}
                labelPlacement="start"
                label={t('SitesScreen.vehicleNote').toUpperCase()}
              />
            </div>
          )}
        </div>

        <div className={classes.subCard}>
          <div>
            <FormControlLabel
              className={classes.switchContainer}
              control={(
                <Switch
                  color="primary"
                  checked={state.parcelLoadingControl || false}
                  onChange={handleChange}
                  name="parcelLoadingControl"
                />
              )}
              labelPlacement="start"
              label={(
                <Typography className={classes.boldLabel}>
                  {t('SitesScreen.parcelLoadingControl').toUpperCase()}
                </Typography>
              )}
            />
          </div>
        </div>

        <div className={classes.subCard}>
          <Typography className={classes.subCardTitle}>
            {t('SitesScreen.passagePoint').toUpperCase()}
          </Typography>
          <div className={classes.subCardList}>
            <FormControlLabel
              className={classes.switchContainer}
              control={(
                <Switch
                  color="primary"
                  checked={state.unloadingPackage || false}
                  onChange={handleChange}
                  name="unloadingPackage"
                />
              )}
              labelPlacement="start"
              label={t('SitesScreen.scanning').toUpperCase()}
            />
          </div>
        </div>

        <div className={classes.subCard}>
          <div>
            <FormControlLabel
              className={classes.switchContainer}
              control={(
                <Switch
                  color="primary"
                  checked={state.scanUnknownParcelsToPickup || false}
                  onChange={handleChange}
                  name="scanUnknownParcelsToPickup"
                />
              )}
              labelPlacement="start"
              label={(
                <Typography className={classes.boldLabel}>
                  {t('SitesScreen.scanUnknownParcelsToPickup').toUpperCase()}
                </Typography>
              )}
            />
          </div>
        </div>

        <div className={classes.subCard}>
          <div>
            <FormControlLabel
              className={classes.switchContainer}
              control={(
                <Switch
                  color="primary"
                  checked={state.services || false}
                  onChange={handleChange}
                  name="services"
                />
              )}
              labelPlacement="start"
              label={(
                <Typography className={classes.boldLabel}>
                  {t('SitesScreen.services').toUpperCase()}
                </Typography>
              )}
            />
          </div>
        </div>

        <div className={classes.subCard}>
          <Typography className={classes.subCardTitle}>
            {t('SitesScreen.proofOfDelivery').toUpperCase()}
          </Typography>
          <div className={classes.subCardList}>
            <FormControlLabel
              className={classes.switchContainer}
              control={(
                <Switch
                  color="primary"
                  checked={state.returnables || false}
                  onChange={handleChange}
                  name="returnables"
                />
              )}
              labelPlacement="start"
              label={t('SitesScreen.returnables').toUpperCase()}
            />
          </div>
          <div className={classes.subCardList}>
            <FormControlLabel
              className={classes.switchContainer}
              control={(
                <Switch
                  color="primary"
                  checked={state?.evaluation || false}
                  onChange={handleChange}
                  name="evaluation"
                />
              )}
              labelPlacement="start"
              label={t('SitesScreen.evaluation').toUpperCase()}
            />
          </div>
          <div className={classes.subCardList}>
            <FormControlLabel
              className={classes.switchContainer}
              control={(
                <Switch
                  color="primary"
                  checked={state?.signature || false}
                  onChange={handleChange}
                  name="signature"
                />
              )}
              labelPlacement="start"
              label={t('SitesScreen.signature').toUpperCase()}
            />
          </div>
          <div className={classes.subCardList}>
            <FormControlLabel
              className={classes.switchContainer}
              control={(
                <Switch
                  color="primary"
                  checked={state.clientCode || false}
                  onChange={handleChange}
                  name="clientCode"
                />
              )}
              labelPlacement="start"
              label={t('SitesScreen.clientCode').toUpperCase()}
            />
          </div>
        </div>

        <div className={classes.subCard}>
          <div>
            <FormControlLabel
              className={classes.switchContainer}
              control={(
                <Switch
                  color="primary"
                  checked={state.arrivalReport || false}
                  onChange={handleChange}
                  name="arrivalReport"
                />
              )}
              labelPlacement="start"
              label={(
                <Typography className={classes.boldLabel}>
                  {t('SitesScreen.arrivalReport').toUpperCase()}
                </Typography>
              )}
            />
          </div>
        </div>

        <div className={classes.subCard}>
          <Typography className={classes.subCardTitle}>
            {t('SitesScreen.additionalFeatures').toUpperCase()}
          </Typography>
          <div className={classes.subCardList}>
            <FormControlLabel
              className={classes.switchContainer}
              control={(
                <Switch
                  color="primary"
                  checked={state.dispute || false}
                  onChange={handleChange}
                  name="dispute"
                />
              )}
              labelPlacement="start"
              label={t('SitesScreen.dispute').toUpperCase()}
            />
          </div>
        </div>
      </div>
    </Card>
  )
}

export default WarehousePlanningMobileCard
