import React, { CSSProperties, ReactNode } from 'react'
import i18n from 'i18next'
import FlashOnIcon from '@material-ui/icons/FlashOn'
import styled from 'styled-components'
import { Button, Grid } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'

const StyledButtonsRow = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

interface IButtonsRow {
  isLoading?: boolean
  isDisabled?: boolean
  toggleModal?(): void
  onClickCTA?(): void
  error?: string | null
  isOptimizePurpose?: boolean
  confirmMessage?: string
  style?: CSSProperties
  children?: ReactNode
}

const ButtonsRow = ({
  isLoading,
  isDisabled = false,
  toggleModal,
  onClickCTA,
  error,
  isOptimizePurpose,
  confirmMessage,
  style,
  children,
}: IButtonsRow): JSX.Element => (
  <StyledButtonsRow style={style} item xs={12}>
    <Button
      disabled={isLoading}
      variant="contained"
      color="secondary"
      onClick={toggleModal}
      style={{ color: 'white', marginRight: 16 }}
      data-cy="cancelButton"
    >
      {i18n.t('PlanningScreen.cancel')}
    </Button>
    <div>
      {error}
      {children}
      <Button
        disabled={isLoading || isDisabled}
        variant="contained"
        color="primary"
        onClick={onClickCTA}
        style={{ color: 'white', marginLeft: 16 }}
        data-cy="saveButton"
      >
        {!isOptimizePurpose ? (
          <>{confirmMessage || i18n.t('PlanningScreen.save')}</>
        ) : (
          <FlashOnIcon fontSize="large" />
        )}
        {isLoading && <CircularProgress color="secondary" size={24} style={{ marginLeft: 8 }} />}
      </Button>
    </div>
  </StyledButtonsRow>
)

export default ButtonsRow
