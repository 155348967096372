import { makeStyles, createStyles } from '@material-ui/core/styles'

export default makeStyles(() =>
  createStyles({
    legend: {
      display: 'grid',
      gridTemplateColumns: 'auto auto auto',
      margin: 'auto',
    },
    container: {
      position: 'relative',
    },
    textLegend: {
      fontSize: '1.2rem',
      padding: '0 0 10px 18px',
    },
  }),
)
