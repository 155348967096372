import React from 'react'
import clsx from 'clsx'
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import useStyles from 'components/NavigationLayout/styles'
import { ILogisticsShift } from 'interfaces/interfaces'
import { AuthConsumer } from 'store/AuthContext'

interface ILogisticsShiftPickerProps {
  logisticsShift?: ILogisticsShift[]
  onShiftSelect?: (shift: string) => void
  selectedShift?: string | number
  isDisabled?: boolean
  shifts?: ILogisticsShift[]
}

const LogisticsShiftPicker = ({
  logisticsShift,
  onShiftSelect,
  selectedShift,
  isDisabled,
}: ILogisticsShiftPickerProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()

  const placeholder = t('executionScreen.post')

  const handleShiftChange = (shiftAsString: string): void => {
    if (onShiftSelect) onShiftSelect(shiftAsString)
  }

  return (
    <FormControl variant="outlined" data-cy="shiftPicker">
      <InputLabel className={classes.filtersMargin}>{placeholder}</InputLabel>
      <Select
        disabled={isDisabled}
        label={placeholder}
        value={selectedShift}
        className={clsx(classes.filtersMargin, classes.shift)}
        onChange={(event): void => handleShiftChange(event.target.value as string)}
      >
        <MenuItem value={-1} disabled>
          {placeholder}
        </MenuItem>
        {logisticsShift?.map((shift: ILogisticsShift) => {
          const value = `${shift.begin} - ${shift.end}`
          return (
            <MenuItem
              key={value}
              value={value}
              style={{ justifyContent: 'space-between', flexDirection: 'row' }}
            >
              {value}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default ({
  onShiftSelect,
  selectedShift,
  shifts,
  ...props
}: JSX.IntrinsicAttributes & ILogisticsShiftPickerProps): JSX.Element => (
  <AuthConsumer>
    {(authCtx): JSX.Element => (
      <LogisticsShiftPicker
        logisticsShift={authCtx?.user?.tenantConfig.logisticsShift || shifts}
        onShiftSelect={onShiftSelect}
        selectedShift={selectedShift}
        {...props}
      />
    )}
  </AuthConsumer>
)
