/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useContext, useState } from 'react'
import { Button, FormControl, MenuItem, ClickAwayListener } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import DeleteIcon from '@material-ui/icons/Delete'

import { IRole } from 'interfaces'
import { IUserInfos } from 'interfaces/IUserInfos'
import { KlareoProduct, DELIVERY_ROLES, UDC_ROLES, ROLES_USER_OPTIONS } from 'constants/constants'
import { AuthContext } from 'store/AuthContext'
import Card from 'components/Card/Card'
import useStyles from 'constants/cruStyles'
import { getPossibleRolesToAdd } from 'utils/roleUtils'
import rolesRules from './rolesRules.json'

interface IProps {
  infos?: IUserInfos
  onRolesChange: Function
}

export default function RolesCard({ infos, onRolesChange }: IProps): JSX.Element {
  const classes = useStyles()
  const { t } = useTranslation()
  const { user } = useContext(AuthContext)
  const klareoProducts = user?.tenantConfig.klareoProducts
  const ROLES_USER = [
    ...(klareoProducts?.includes(KlareoProduct.DeliveryPackage) ? DELIVERY_ROLES : []),
    ...(klareoProducts?.includes(KlareoProduct.UdcPackage) ? UDC_ROLES : []),
  ]
  const [showList, setShowList] = useState<boolean>(false)
  const [selectedRoles, setSelectedRoles] = useState<IRole[]>(
    infos?.roles ? (infos?.roles?.map((role) => ({ id: role, name: role })) as IRole[]) : [],
  )

  const getRolesList = (userRoles?: IRole[]): IRole[] => {
    if (!user?.roles) {
      return []
    }
    const userRolesBeingEdited = userRoles?.map((role) => role.id) ?? infos?.roles ?? []
    const rolesToAdd = getPossibleRolesToAdd(user.roles, userRolesBeingEdited, rolesRules)

    return ROLES_USER.filter((role) => rolesToAdd.includes(role.id))
  }

  const [roles, setRoles] = useState<IRole[]>(getRolesList())

  const handleRoleDelete = (selectedRole: IRole): void => {
    const newRoles = selectedRoles.filter((role) => role.id !== selectedRole.id)
    setSelectedRoles(newRoles)
    onRolesChange({
      roles: newRoles.map((role) => role.id),
    })
    setRoles(getRolesList(newRoles))
    setShowList(false)
  }

  const DisplaySelectedRoles = (): JSX.Element => (
    <div className={classes.fieldsContainer}>
      {selectedRoles.map((role) => (
        <div
          data-cy={role.id !== 'DRIVER' ? `option-role-${role.id}` : null}
          className={classes.fieldsWarehouse}
          key={role.id}
        >
          <span key={role.id} className={classes.warehouse}>
            {ROLES_USER_OPTIONS.find((roleOption) => roleOption.id === role.id)?.name}
          </span>
          {role.id !== 'DRIVER' && (
            <Button
              data-cy="deleteButton"
              onClick={(): void => {
                handleRoleDelete(role)
              }}
            >
              <DeleteIcon />
            </Button>
          )}
        </div>
      ))}
    </div>
  )

  const showDropdownList = (): void => {
    setShowList(true)
  }

  const handleRoleSelect = (selectedRole: IRole): void => {
    const newRoles = [...selectedRoles, selectedRole]
    setSelectedRoles(newRoles)
    setRoles(getRolesList(newRoles))
    onRolesChange({
      roles: newRoles.map((role) => role.id),
    })
    setShowList(false)
  }

  const handleRolesClickAway = (): void => {
    setShowList(false)
  }

  return (
    <Card
      title={t('UsersScreen.roles').toUpperCase()}
      dataCy="roleCard"
      contentClassName={classes.contentRef}
    >
      <ClickAwayListener onClickAway={handleRolesClickAway}>
        <div className={classes.btnSelect}>
          <FormControl variant="outlined">
            <Button data-cy="addButton" className={classes.modifyButton} onClick={showDropdownList}>
              {t('UsersScreen.add').toUpperCase()}
            </Button>
            {showList && (
              <div className={classes.DropdownList}>
                {roles.map((role) => (
                  <MenuItem
                    data-cy={
                      ROLES_USER_OPTIONS.find((roleOption) => roleOption.id === role.id)?.name
                    }
                    onClick={(): void => {
                      handleRoleSelect(role)
                    }}
                    key={role.id}
                  >
                    {ROLES_USER_OPTIONS.find((roleOption) => roleOption.id === role.id)?.name}
                  </MenuItem>
                ))}
              </div>
            )}
          </FormControl>
        </div>
      </ClickAwayListener>
      <DisplaySelectedRoles />
    </Card>
  )
}
