import { createStyles, makeStyles } from '@material-ui/core/styles'

export default makeStyles(() =>
  createStyles({
    text: {
      width: '100%',
    },
    statusText: {
      width: '100%',
      textAlign: 'right',
    },
    greyText: {
      color: 'grey',
    },
  }),
)
