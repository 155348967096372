import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'

const Input = styled(TextField)({
  width: '100%',
  marginTop: 16,
  marginBottom: 16,
  '& .MuiInput-root': {
    color: '#FFF',
  },
  '& .MuiFormLabel-root': {
    color: '#FFF',
  },
})

export default Input
