import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      marginRight: 25,
    },
    warehouseTableCell: {
      backgroundColor: theme.color.backgroundBody,
    },
    border: {
      borderBottom: `1px solid ${theme.color.fieldBorder}`,
    },
  }),
)
