import React from 'react'
import { Legend } from '@devexpress/dx-react-chart-material-ui'

import useStyles from '../styles'

const CustomPieChartLegend = ({ ...props }: Legend.RootProps): JSX.Element => {
  const classes = useStyles()

  return (
    <div className={classes.legendContainer}>
      <Legend.Root {...props} className={classes.legend} />
    </div>
  )
}

export default CustomPieChartLegend
