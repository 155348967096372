import React, { useContext } from 'react'

import { CONFIGURATIONS_MENU } from 'constants/constants'
import VerticalTab from 'components/Tab/VerticalTab'
import { shouldShowTab } from 'utils/tabFeatureUtils'
import { AuthContext } from 'store/AuthContext'

const ConfigurationsScreen = (): JSX.Element => {
  const { features } = useContext(AuthContext)
  return (
    <VerticalTab
      isForConfiguration
      tabs={CONFIGURATIONS_MENU.filter((orderTab) => shouldShowTab(orderTab, features))}
    />
  )
}

export default ConfigurationsScreen
