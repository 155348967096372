import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import VehiclesProvider, { VehiclesConsumer } from 'store/VehiclesContext'
import { IIdParam } from 'interfaces/IIdParam'
import { IVehicle } from 'interfaces/IVehicle'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import FormAction from 'components/Button/FormAction'
import { ContentConsumer } from 'store/ContentContext'
import { IVehicleType } from 'interfaces/interfaces'
import VehicleCard from 'components/Card/VehicleCard'
import { IEvent } from 'interfaces/IEvent'
import useStyles from 'constants/cruStyles'
import { VehicleAvailabilityCard } from './VehicleAvailabilityCard'

interface IProps {
  createVehicle?: Function
  createSuccess?: boolean
  updateSuccess?: boolean
  getDetails?: Function
  updateVehicle?: Function
  vehicleDetails?: IVehicle
  vehicleTypes?: IVehicleType[]
  shouldShowVehiclePlanning?: boolean
  refreshVehicles?: () => void
}

const VehicleFormScreen = ({
  createVehicle,
  updateSuccess,
  updateVehicle,
  createSuccess,
  getDetails,
  vehicleDetails,
  vehicleTypes,
  refreshVehicles,
}: IProps): JSX.Element => {
  const [vehicle, setVehicle] = useState<IVehicle>({
    active: true,
    vehicleAvailabilityControl: false,
  })
  const history = useHistory()
  const { id } = useParams<IIdParam>()
  const classes = useStyles()

  const handleVehicleChange = (vehicleInfo: IVehicle): void => {
    setVehicle((prevVehicle) => ({
      ...prevVehicle,
      name: vehicleInfo.name,
      licencePlate: vehicleInfo.licencePlate,
      lastKnownKilometer: vehicleInfo.lastKnownKilometer && +vehicleInfo.lastKnownKilometer,
      carrier: vehicleInfo.carrier,
      vehicleTypeId: vehicleTypes?.find((vehicleType) => vehicleType.id === vehicleInfo.vehicleType)
        ?.id,
      active: vehicleInfo.active,
      siteId: vehicleInfo.siteId,
    }))
  }

  const handleAvailabilityChange = (availability: IEvent[]): void => {
    setVehicle((prevVehicle) => ({
      ...prevVehicle,
      events: availability,
    }))
  }

  const handleAvailabilityControlChange = (checked: boolean): void => {
    setVehicle((prevVehicle) => ({
      ...prevVehicle,
      vehicleAvailabilityControl: checked,
    }))
  }

  const handleSaveClick = async (): Promise<void> => {
    if (id) {
      if (updateVehicle) {
        await updateVehicle(id, vehicle)
      }
    } else {
      if (createVehicle) {
        await createVehicle(vehicle)
      }
    }
  }

  useEffect(() => {
    if ((createSuccess || updateSuccess) && refreshVehicles) {
      history.push(ROUTES_PATH.vehiclesList)
      refreshVehicles()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createSuccess, updateSuccess])

  useEffect(() => {
    if (id && getDetails) {
      getDetails(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (vehicleDetails && id) {
      setVehicle({
        id: vehicleDetails?.id,
        name: vehicleDetails?.name,
        licencePlate: vehicleDetails?.licencePlate,
        lastKnownKilometer: vehicleDetails?.lastKnownKilometer,
        active: vehicleDetails?.active,
        carrierId: vehicleDetails?.carrierId,
        vehicleType: vehicleDetails?.vehicleType,
        events: vehicleDetails?.events,
        siteId: vehicleDetails?.siteId,
        vehicleAvailabilityControl: vehicleDetails?.vehicleAvailabilityControl,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleDetails])

  const handleCancelClick = (): void => {
    history.push(ROUTES_PATH.vehiclesList)
  }

  return (
    <>
      {id && <h3 data-cy="vehicleDetailDescription">{`${vehicle.name || ''}`}</h3>}
      <VehicleCard
        key={`${vehicle.id}-info`}
        vehicle={vehicle}
        onVehicleChange={handleVehicleChange}
      />
      <div className={classes.infoContainer}>
        <VehicleAvailabilityCard
          key={`${vehicle.id}-availability`}
          events={vehicle.events as IEvent[]}
          shouldShowVehiclePlanning={vehicle.vehicleAvailabilityControl}
          onAvailabilityChange={handleAvailabilityChange}
          onAvailabilityControlChange={handleAvailabilityControlChange}
        />
      </div>
      <FormAction onSaveClick={handleSaveClick} onCancelClick={handleCancelClick} />
    </>
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <VehiclesProvider>
    <ContentConsumer>
      {(contentCtx): JSX.Element => (
        <VehiclesConsumer>
          {(vehicleCtx): JSX.Element => (
            <VehicleFormScreen
              updateSuccess={vehicleCtx.updateSuccess}
              updateVehicle={vehicleCtx.updateVehicle}
              getDetails={vehicleCtx.getDetails}
              vehicleDetails={vehicleCtx.vehicleDetails}
              createVehicle={vehicleCtx.createVehicle}
              createSuccess={vehicleCtx.createSuccess}
              vehicleTypes={contentCtx.vehicleTypes}
              refreshVehicles={contentCtx.refreshVehicles}
              {...props}
            />
          )}
        </VehiclesConsumer>
      )}
    </ContentConsumer>
  </VehiclesProvider>
)
