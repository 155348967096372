import type { INavElem } from 'navigation/NavigationConfig'
import {
  DestinationType,
  KlareoProduct,
  SessionTokenExpiryDelay,
  SetManualPassword,
  TransportType,
  VehicleFillingRateCalculation,
} from 'constants/constants'
import { IAddress } from './Itours'
import { ITrailer } from './ITrailer'
import { IOptimizationParam } from './IOptimizationParam'
import { IPlanTemplate } from './IPlanTemplate'
import { ISector } from './ISector'
import { ISite } from './ISite'
import { IDeliveryType } from './IDeliveryType'
import { ICancellationReason } from './ICancellationReason'
import { ITag } from './ITag'
import { ICustomerAddress } from './ICustomer'

export interface ILogisticsShift {
  begin: string
  end: string
}

export interface IDataTransformer {
  name: string
  url: string
}

export interface IFileTemplates {
  orderFileTemplateURL: string
}

export interface IPasswordRules {
  charactersMinimum: number
  upperCaseMinimum?: number
  lowerCaseMinimum?: number
  digitsMinimum?: number
  symbolsMinimum?: number
}

export interface IUserDataObj {
  id: string
  firstName: string
  lastName: string
  companyId: string
  login: string
  roles: string[]
  tenant: string
  tenantId: string
  tenantConfig: {
    carrierCanChangeDriver: boolean
    carrierCanChangeVehicle: boolean
    logisticsShift: ILogisticsShift[]
    planningParam: {
      vehiclePlanningControl: boolean
      driverPlanningControl: boolean
    }
    language: string
    dataTransformers: IDataTransformer[]
    klareoProducts: KlareoProduct[]
    planningPinsZoom: number
    deliveryTypes: IDeliveryType[]
    templates: IFileTemplates
    cancellationReasons?: ICancellationReason[]
    tags?: ITag[]
    sessionTokenExpiryDelay: SessionTokenExpiryDelay
    passwordRules?: IPasswordRules
    setManualPassword?: SetManualPassword
  }
  sites: ISite[]
}

export type IUserData = IUserDataObj | null

export interface IMenuEntries {
  id: number
  title: string
  data: INavElem[]
  isMenuOpen?: boolean
}

export interface IVehicle {
  id: string
  name: string
  licencePlate: string
  lastKnownKilometer: number
  carrierId: string
  siteId: string
  active: boolean
}

export interface ICompany {
  name: string
  id: string
  tenantId?: string
}

export interface IVehicleProfile {
  id: string
  name: string
  label?: string
}

export interface IDriver {
  id: string
  name: string
  carrierId: string
  active: boolean
  firstName: string | null
  lastName: string | null
  siteId: string
}

export interface ICarrier {
  id: string
  name: string
  code: string
  tenantId: string
  warehouses: ICarrierWarehouse[]
  active: boolean
  tags?: ITag[]
}

export interface ICarrierWarehouse {
  id: string
  name: string
  code: string
  validityStartDate?: string
  validityEndDate?: string
}

export interface ICarrierWarehouseValidity {
  id: string
  name?: string
  validityStartDate?: string
  validityEndDate?: string
  isNew?: boolean
}

export enum TypeOfSite {
  Warehouse,
  Hubspot,
  Store,
  Drive,
}

export enum TypeOfTour {
  Global = 'Global',
  Sector = 'Sector',
}

export interface IMapotempoConfig {
  clientId: string
  apiKey: string
  url: string
  parcelDeliverableUnitId: string
  clientDeliverableUnitId: string
}

export enum OptimizationItineraryCalculatorType {
  WORLD = 'World',
  EUROPE = 'Europe',
}

export enum OptimizationEngine {
  Atoptima = 'atoptima',
  Kvrp = 'kvrp',
}

export interface IPlanningEndCustomerPageConfig {
  driverInformation: boolean
  driverPosition: boolean
  driverPhoneNumber: boolean
  customerCare: boolean
  customerEvaluation: boolean
  customerComments: boolean
  clientCode: boolean
}

export interface IAppointmentEndCustomerPageConfig {
  showUnavailableSlots: boolean
  canPilotReserveTimeSlots: boolean
}

export interface IOrderScreenConfig {
  editServiceTime: boolean
  editDeliveryTime: boolean
  defaultDestinationType: DestinationType
  defaultDeliveryType: number
  defaultTransportType: TransportType
}

export interface IExecutionScreenConfig {
  vehicleFillingRateCalculation: VehicleFillingRateCalculation
}

export interface IDeliveryInformationEndCustomerPageConfig {
  deliverySlotAmplitude?: number
  message?: string
  showElevator: boolean
  showFloor: boolean
  showInstructions: boolean
}

export interface IExecutionTrackingScreenConfig {
  delayAlertOnTourBeforeDeparture?: number
}

export interface IPlanningMobile {
  dispute: boolean
  shift: boolean
  vehicleNote: boolean
  tourControl: boolean
  signature: boolean
  evaluation: boolean
  unloadingPackage: boolean
  parcelLoadingControl: boolean
  services: boolean
  clientCode: boolean
  scanUnknownParcelsToPickup: boolean
  arrivalReport: boolean
  returnables: boolean
}

export interface IApplicationVersion {
  name: string
  version: string
}

export interface IAtoptimaParams {
  appClassic?: IApplicationVersion
  appMultisites?: IApplicationVersion
  appMultitours?: IApplicationVersion
  hourlyPrecedence?: boolean
  uncoveredOperationsViolationPerUnit?: number
  directPrecedenceViolationPerUnit?: number
  weightViolationPerUnit?: number
  volumeViolationPerUnit?: number
  timeViolationPerSecond?: number
}

export interface IWarehouse {
  id: string
  name: string
  address: IAddress
  accountId: string
  code: string
  type: TypeOfSite
  tourType: TypeOfTour
  active: boolean
  carriers: ICarrierWarehouse[]
  carriersIds?: string[]
  locationRelevance?: number
  phone?: string
  customerServicePhone?: string
  accessInstructions?: string
  optimParams?: IOptimizationParam
  sectors?: ISector[]
  tenantId?: string
  tenantName?: string
  averageLoadingTime?: number
  kVrpConfig?: IMapotempoConfig
  planningEndCustomerPage?: IPlanningEndCustomerPageConfig
  planningMobile?: IPlanningMobile
  autoCreatePlanVisits: boolean
  appointmentEndCustomerPage?: IAppointmentEndCustomerPageConfig
  deliveryInformationEndCustomerPage?: IDeliveryInformationEndCustomerPageConfig
  orderScreen?: IOrderScreenConfig
  executionScreen?: IExecutionScreenConfig
  executionTrackingScreen?: IExecutionTrackingScreenConfig
}

export interface ICustomer {
  email: string
  firstName: string
  isNewCustomer: boolean | null
  lastName: string
  orderNumber: number | null
  phoneNumber: string
  addresses: ICustomerAddress[]
  areTermsOfSaleAccepted?: boolean
  _id: string
}

export interface ITableColumn {
  title: string
  field: string
  sortField?: string
  render?: Function
  disableClick?: boolean
  hidden?: boolean
  searchable?: boolean
  cellStyle?: object
  headerStyle?: object
  sorting?: boolean
  align?: 'center' | 'inherit' | 'justify' | 'left' | 'right'
  export?: boolean
  defaultHidden?: boolean
}

export interface ITourFilters {
  carriers?: string[]
  warehouses?: string[]
  vehicleTypeIds?: string[]
  status?: string[]
  startDate?: string
  endDate?: string
  searchText?: string | null
  deliveryTypes?: string[]
}

export type IPlanTourFilters = ITourFilters

export interface IOrderFilters {
  startDate?: string
  endDate?: string
  search?: string
  deliveryTypes?: string[]
  transportTypes?: string[]
  relevance?: number
  siteIds?: string[]
  deliverySiteIds?: string[]
  planStatuses?: string[]
  zipCodes?: string[]
}

export interface ICarrierFilters {
  search?: string
  active?: string[]
  limit?: number
  searchText?: string | null
  warehouses?: string[]
}

export interface A11yPropsType {
  id: string
  'aria-controls': string
}

export interface IDriverFilters {
  active?: string[]
  carriers?: string[]
  siteIds?: string[]
  searchText?: string | null
}

export interface IRoutingRulesFilters {
  searchText?: string | null
  active?: string[]
  siteIds?: string[]
}

export interface IUserFilters {
  search?: string
  active?: string[]
  limit?: number
  searchText?: string | null
  roles?: string[]
  tenants?: string[]
  company?: string[]
  siteIds?: string[]
}

export interface IRole {
  name?: string
  id: string
}

export interface IUserFilter {
  roles?: string[]
  company?: string[]
  active?: string[]
  tenants?: string[]
  searchText?: string | null
  siteIds?: string[]
}

export interface IVehicleFilters {
  active?: string[]
  vehicleTypeIds?: string[]
  carriers?: string[]
  siteIds?: string[]
  searchText?: string | null
  siteId?: string
}

export interface ITrailerFilters {
  active?: string[]
  vehicleTypeIds?: string[]
  carriers?: string[]
  searchText?: string | null
}

export interface IVehicleTypeFilters {
  active?: string[]
  vehicleProfileIds?: string[]
  searchText?: string | null
}

export interface ITenantFilters {
  searchText?: string | null
}

export interface IVehicleType {
  id: string
  name: string
  label?: string
  code?: string
  active?: boolean
}

export interface ISearchableSelectOption {
  id: string | number
  name: string
  disabled?: boolean
  hidden?: boolean
}

export interface ISiteFilters {
  type?: string[]
  active?: string[]
  searchText?: string
  hasSector?: string[]
  tenant?: string[]
  siteIds?: string[]
}

export interface IPlanTemplatesFilters {
  siteIds?: string[]
}

export interface IApikeyFilters {
  tenantIds?: string[]
}

type IName = {
  name: string
}

export type IContent =
  | IWarehouse
  | ICarrier
  | IVehicleType
  | IVehicleProfile
  | IDriver
  | IVehicle
  | ICompany
  | (IPlanTemplate & IName)

export type IVehicleBase = IVehicle | ITrailer

export interface ITextChange {
  target: {
    value: string
    name: string
  }
}

export interface IToggleChange {
  target: {
    name: string
    checked?: boolean
  }
}

export interface IFieldChange {
  target: {
    value: unknown
    name?: string | undefined
    type?: string
    checked?: boolean
  }
}

export interface INamedFieldChange {
  target: {
    value: unknown
    name: string
    type?: string
    checked?: boolean
  }
}

export interface IToursNumberOfOrdersByDeliveryTypes {
  deliveryType: number
  toursNumberOfOrders: number
}

export interface IChartStat {
  toursNumberOfOrdersByDeliveryTypes: IToursNumberOfOrdersByDeliveryTypes[]
}

export interface IWarehouseTimeSlotFilters {
  active?: string[]
  searchText?: string
  warehouseIds?: string[]
}

export interface IWarehouseMapping {
  warehouseId: string
  dealersCode: string
}

export interface IDisplayConfig {
  dealerLogo: string
  backgroundColor: string
  headerColor: string
  primaryColor: string
  darkPrimaryColor: string
  textColor: string
}

export interface IDealer {
  id: string
  tenantId: string
  label: string
  code: string
  dealersAccount: string
  warehouseMapping: IWarehouseMapping[]
  phone: string
  mail: string
  displayConfig: IDisplayConfig
}

export interface IAlertsFilters {
  startDate?: string
  endDate?: string
  read?: string[]
  concernedRoles?: string[]
  concernedWarehouseIds?: string[]
  dealerIds?: string[]
}

export interface ISortingPrams {
  sortBy: string
  destinationSiteId: string
}

export interface IExtendedDateProps {
  last3months?: boolean
  last7days?: boolean
  lastMonth?: boolean
  lastWeek?: boolean
  next7days?: boolean
  thisMonth?: boolean
  thisWeek?: boolean
  today?: boolean
  tomorrow?: boolean
  yesterday?: boolean
}
