import React, { useContext, useEffect, useState } from 'react'
import { IconButton } from '@material-ui/core'
import { Chart, PieSeries, Legend, Title, Tooltip } from '@devexpress/dx-react-chart-material-ui'
import { EventTracker } from '@devexpress/dx-react-chart'
import { useTranslation } from 'react-i18next'
import { Skeleton } from '@material-ui/lab'
import moment from 'moment'
import RefreshIcon from '@material-ui/icons/Refresh'

import DateRangePicker from 'components/Inputs/DateRangePicker'
import { DeliveryTypeFilter, SiteFilter } from 'components/Inputs/ListFilter'
import { DateType } from 'constants/constants'
import ToursApi from 'api/tours'
import { isIError } from 'api/types'
import { IChartData } from 'interfaces/IChartData'
import { FiltersContext } from 'store/FiltersContext'
import Export from 'components/Chart/Export'
import { connectProps } from '@devexpress/dx-react-core'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { FilterKey, Filter } from 'constants/filters'
import StyledPaper from 'screens/ReportingCategory/components/StyledPaper'
import CustomTooltipContent from 'screens/ReportingCategory/components/CustomTooltipContent'
import CustomLegend from 'screens/ReportingCategory/components/CustomLegend'
import ExtendedDateFilters from 'components/Table/ExtendedDateFilters'
import { FiltersContainer, TopFiltersContainer } from 'components/Layout'
import {
  getChartTitle,
  setDateFactory,
  setSelectedDateFilterShortcutFactory,
} from 'utils/extendedDateFilter'

const containerId = 'market-share'
const filterKey = FilterKey.marketShare

const MarketShareIndicator = (): JSX.Element => {
  const { setFilter, resetFilters, filters } = useContext(FiltersContext)
  const deliveryTypes = filters[filterKey][Filter.deliveryTypes] as string[]
  const startDate = filters[filterKey][Filter.startDate] as string
  const endDate = filters[filterKey][Filter.endDate] as string
  const siteIds = filters[filterKey][Filter.sites] as string[]
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [selectedDateFilterShortcut, setSelectedDateFilterShortcut] = useState('')
  const [shares, setShares] = useState<IChartData[]>()
  const [total, setTotal] = useState<number>(0)
  const { t } = useTranslation()

  const getShares = async (): Promise<void> => {
    setIsLoading(true)
    const response = await ToursApi.getMarketShare(startDate, endDate, deliveryTypes, siteIds)
    if (!isIError(response)) {
      setShares(response.shares.map((share) => ({ label: share.label, value: share.share })))
      setTotal(response.total)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    getShares()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryTypes, startDate, endDate, siteIds])

  const TooltipContent = connectProps(CustomTooltipContent, () => ({
    total,
    firstLabel: t('MarketShareScreen.share'),
    secondLabel: t('MarketShareScreen.percentage'),
  }))

  const EnhancedLegend = connectProps(CustomLegend, () => ({
    total,
    label: t('MarketShareScreen.numberOfToursOverThePeriod'),
  }))

  const resetLocalFilters = (): void => resetFilters(filterKey)

  const { setEndDate, setStartDate } = setDateFactory(setFilter, filterKey)

  const { fnLast3months, fnLastMonth, fnLast7days, fnNext7days, fnThisMonth } = setSelectedDateFilterShortcutFactory(
    setSelectedDateFilterShortcut,
  )

  const chartTitle = getChartTitle('MarketShareScreen.marketShare', selectedDateFilterShortcut)

  return (
    <>
      <TopFiltersContainer>
        <FiltersContainer>
          <IconButton onClick={resetLocalFilters}>
            <RefreshIcon />
          </IconButton>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onChangeStart={(startValue: MaterialUiPickersDate): void => {
              setFilter(filterKey, Filter.startDate, moment(startValue).toISOString())
              setSelectedDateFilterShortcut('')
            }}
            onChangeEnd={(endValue: MaterialUiPickersDate): void => {
              setFilter(filterKey, Filter.endDate, moment(endValue).toISOString())
              setSelectedDateFilterShortcut('')
            }}
            hideShiftPicker
            dateType={DateType.Date}
          />
          <SiteFilter
            handleChange={(siteValues: string[]): void => {
              setFilter(filterKey, Filter.sites, siteValues)
            }}
            ids={siteIds}
            placeholder={t('MarketShareScreen.site')}
            dataCy="sitePicker"
            isUnselectAllowed={false}
          />
          <DeliveryTypeFilter
            handleChange={(deliveryTypeValues: string[]): void => {
              setFilter(filterKey, Filter.deliveryTypes, deliveryTypeValues)
            }}
            ids={deliveryTypes}
          />
        </FiltersContainer>
      </TopFiltersContainer>
      <ExtendedDateFilters
        fnLast3months={fnLast3months}
        fnLastMonth={fnLastMonth}
        fnLast7days={fnLast7days}
        fnNext7days={fnNext7days}
        fnThisMonth={fnThisMonth}
        last3months
        lastMonth
        last7days
        next7days
        thisMonth
        setEndDate={setEndDate}
        setStartDate={setStartDate}
      />
      {isLoading && (
        <Skeleton
          variant="circle"
          animation="wave"
          width={400}
          height={400}
          style={{ margin: 'auto', marginTop: 60 }}
        />
      )}
      {shares && !isLoading && (
        <StyledPaper id={containerId}>
          <Chart data={shares}>
            <PieSeries valueField="value" argumentField="label" />
            <Title text={chartTitle} />
            <Legend position="bottom" rootComponent={EnhancedLegend} />
            <EventTracker />
            <Tooltip contentComponent={TooltipContent} />
            {/* TODO: use another fileName */}
            <Export containerId={containerId} fileName={containerId} />
          </Chart>
        </StyledPaper>
      )}
    </>
  )
}

export default MarketShareIndicator
