import React, { useContext, useEffect, useRef, useState, useMemo } from 'react'
import MaterialTable, { MTableHeader } from 'material-table'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { useTranslation } from 'react-i18next'
import { Grid, IconButton } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import moment from 'moment'

import { COLUMNS_SITE_REPORTING } from 'constants/table'
import { Filter, FilterKey } from 'constants/filters'
import { FiltersContext } from 'store/FiltersContext'
import { SiteFilter } from 'components/Inputs/ListFilter'
import SitesProvider, { SitesConsumer } from 'store/SitesContext'
import { ISiteReporting } from 'interfaces/ISiteReporting'
import MUITableIcons from 'constants/MUITableIcons'
import { getSitesLabel, getDisplayRowsCount } from 'utils/tableUtils'
import { ContentContext } from 'store/ContentContext'
import Pagination from 'components/Table/Pagination'
import { getPageSize, savePageSize } from 'utils/localStorage'
import { scrollTop } from 'utils/functions'
import AppConfigProvider, { AppConfigContext } from 'store/AppConfigContext'
import useStyles from './styles'

const columnConfigName = 'siteReportingList'

interface IProps {
  getSiteReporting: (
    date: string,
    warehouseIds?: string[],
    offset?: number,
    rowsPerPage?: number,
  ) => Promise<void>
  siteReporting: ISiteReporting[]
  reportingCount: number
}

const ordersAndDeliveryBySite = 'orders-and-delivery-by-site'

const filterKey = FilterKey.siteReporting

const SiteReportingScreen = ({
  getSiteReporting,
  reportingCount,
  siteReporting,
}: IProps): JSX.Element => {
  const isInitialMount = useRef(true)
  const classes = useStyles()
  const { t } = useTranslation()
  const { setFilter, resetFilters, filters } = useContext(FiltersContext)
  const { allSites, sites } = useContext(ContentContext)
  const date = filters[filterKey][Filter.date] as string
  const siteIds = filters[filterKey][Filter.sites] as string[]
  const [rowsPerPage, setRowsPerPage] = useState<number>(getPageSize)
  const [paginationOffset, setPaginationOffset] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const { tablesConfig } = useContext(AppConfigContext)

  useEffect(() => {
    if (rowsPerPage !== getPageSize()) {
      savePageSize(rowsPerPage)
      scrollTop()
    }

    setPaginationOffset(0)
    setPage(0)
    getSiteReporting(date, siteIds, 0, rowsPerPage)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, siteIds, rowsPerPage])

  useEffect(() => {
    if (!isInitialMount.current) {
      getSiteReporting(date, siteIds, paginationOffset, rowsPerPage)
      scrollTop()
    } else {
      isInitialMount.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationOffset])

  const resetLocalFilters = (): void => resetFilters(filterKey)

  const handleDateChange = (selectedDate: MaterialUiPickersDate): void => {
    if (moment(selectedDate).isValid()) {
      const newDate = moment(selectedDate).toISOString()
      setFilter(filterKey, Filter.date, newDate)
    }
  }

  const handleChangeRowsPerPage = (pageSize: number): void => {
    setPage(0)
    setRowsPerPage(pageSize)
    setPaginationOffset(0)
  }

  const displayRowsCount = useMemo(() => getDisplayRowsCount(siteReporting), [siteReporting])

  return (
    <Grid item xs={12} className={classes.container}>
      <IconButton data-cy="initializeButton" onClick={resetLocalFilters}>
        <RefreshIcon />
      </IconButton>
      <KeyboardDatePicker
        label={t('SiteReportingScreen.month')}
        inputVariant="outlined"
        format="MM/yyyy"
        value={date}
        onChange={handleDateChange}
        data-cy="monthPicker"
        className={classes.datePicker}
        views={['year', 'month']}
        openTo="month"
      />
      <SiteFilter
        handleChange={(siteValues: string[]): void => {
          setFilter(filterKey, Filter.sites, siteValues)
        }}
        ids={siteIds}
        placeholder={t('SiteReportingScreen.site')}
        dataCy="sitePicker"
        isUnselectAllowed={false}
      />
      <MaterialTable
        key={displayRowsCount}
        data={siteReporting
          .map((item) => ({
            ...item,
            siteName: getSitesLabel([item.siteId], allSites, sites),
          }))
          .sort((a, b) => a.siteName.localeCompare(b.siteName))}
        columns={COLUMNS_SITE_REPORTING.map(
          (col) =>
            ({
              ...col,
              hidden: col.hidden || tablesConfig[columnConfigName]?.includes(col.title),
            } as unknown as MaterialTable<object>),
        )}
        options={{
          pageSize: displayRowsCount,
          exportButton: true,
          exportFileName: ordersAndDeliveryBySite,
          showTitle: false,
          search: false,
        }}
        localization={{
          toolbar: {
            // @ts-ignore
            exportCSVName: t('tablesEntries.exportCsv'),
            exportPDFName: t('tablesEntries.exportPdf'),
          },
        }}
        icons={MUITableIcons}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        components={{
          Header: (props): JSX.Element => <MTableHeader {...props} />,
          // eslint-disable-next-line react/prop-types, @typescript-eslint/no-unused-vars
          Pagination: ({ ...props }): JSX.Element => (
            <Pagination
              {...props}
              page={page}
              setPage={setPage}
              columns={COLUMNS_SITE_REPORTING.filter((col) => !col.hidden)}
              columnConfigName={columnConfigName}
              count={reportingCount}
              rowsPerPage={rowsPerPage}
              setPaginationOffset={setPaginationOffset}
            />
          ),
        }}
      />
    </Grid>
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <AppConfigProvider>
    <SitesProvider>
      <SitesConsumer>
        {(sitesCtx): JSX.Element => (
          <SiteReportingScreen
            getSiteReporting={sitesCtx.getSiteReporting}
            reportingCount={sitesCtx.reportingCount}
            siteReporting={sitesCtx.siteReporting}
            {...props}
          />
        )}
      </SitesConsumer>
    </SitesProvider>
  </AppConfigProvider>
)
