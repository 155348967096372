import React, { useState, createContext, ReactNode, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { isIError, IError } from 'api/types'
import WarehouseTimeSlotsApi from 'api/warehouseTimeSlots'
import { getErrorList } from 'utils/errorUtils'
import { FeedbackContext } from 'store/FeedbackContext'
import { IWarehouseTimeSlot, IWarehouseTimeSlotForm } from 'interfaces/IWarehouseTimeSlot'
import { IWarehouseTimeSlotFilters } from 'interfaces/interfaces'

interface IWarehouseTimeSlotContext {
  getDetails: (id: string) => Promise<void>
  warehouseTimeSlots: IWarehouseTimeSlot[]
  count: number
  error?: IError
  getList: (
    filters?: IWarehouseTimeSlotFilters,
    paginationOffset?: number,
    rowsPerPage?: number,
  ) => void
  updateSuccess?: boolean
  createSuccess?: boolean
  warehouseTimeSlotDetails: Partial<IWarehouseTimeSlot>
  createWarehouseTimeSlot: (warehouseTimeSlot: IWarehouseTimeSlotForm) => Promise<void>
  updateWarehouseTimeSlot: (id: string, warehouseTimeSlot: IWarehouseTimeSlotForm) => void
}

const WarehouseTimeSlotsContext = createContext<IWarehouseTimeSlotContext>(
  {} as IWarehouseTimeSlotContext,
)

const { Provider } = WarehouseTimeSlotsContext

interface IProps {
  children: ReactNode
}

const WarehouseTimeSlotsProvider = ({ children }: IProps): JSX.Element => {
  const { openErrorSnack, openSuccessSnack, toggleLoader } = useContext(FeedbackContext)
  const [warehouseTimeSlots, setWarehouseTimeSlots] = useState<IWarehouseTimeSlot[]>([])
  const [count, setCount] = useState<number>(0)
  const [warehouseTimeSlotDetails, setWarehouseTimeSlotDetails] = useState<
    Partial<IWarehouseTimeSlot>
  >({})
  const [createSuccess, setCreateSuccess] = useState<boolean | undefined>()
  const [updateSuccess, setUpdateSuccess] = useState<boolean | undefined>()
  const { t } = useTranslation()

  const getList = async (
    filters?: IWarehouseTimeSlotFilters,
    paginationOffset?: number,
    rowsPerPage?: number,
  ): Promise<void> => {
    toggleLoader(true)
    const response = await WarehouseTimeSlotsApi.getList(filters, paginationOffset, rowsPerPage)
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      setWarehouseTimeSlots(response.warehouseTimeSlots)
      setCount(response.count)
    }
    toggleLoader(false)
  }

  const getDetails = async (id: string): Promise<void> => {
    toggleLoader(true)
    const response = await WarehouseTimeSlotsApi.get(id)
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      setWarehouseTimeSlotDetails(response)
    }
    toggleLoader(false)
  }

  const createWarehouseTimeSlot = async (
    warehouseTimeSlot: IWarehouseTimeSlotForm,
  ): Promise<void> => {
    toggleLoader(true)
    const response = await WarehouseTimeSlotsApi.create(warehouseTimeSlot)
    if (isIError(response)) {
      response.error.errorList = getErrorList(response)
      if (response.error.fieldErrors) {
        openErrorSnack(response.error.errorList.join('\n'))
      } else {
        openErrorSnack(response.error.message)
      }
    } else {
      setCreateSuccess(true)
      openSuccessSnack(t('SiteTimeSlotsScreen.warehouseTimeSlotsSuccessfullyCreated'))
    }
    toggleLoader(false)
  }

  const updateWarehouseTimeSlot = async (
    id: string,
    warehouseTimeSlot: IWarehouseTimeSlotForm,
  ): Promise<void> => {
    toggleLoader(true)
    const response = await WarehouseTimeSlotsApi.update(id, warehouseTimeSlot)
    if (isIError(response)) {
      response.error.errorList = getErrorList(response)
      if (response.error.fieldErrors) {
        openErrorSnack(response.error.errorList.join('\n'))
      } else {
        openErrorSnack(response.error.message)
      }
    } else {
      setUpdateSuccess(true)
      openSuccessSnack(t('SiteTimeSlotsScreen.successfullySaved'))
    }
    toggleLoader(false)
  }

  return (
    <Provider
      value={{
        getDetails,
        warehouseTimeSlots,
        count,
        getList,
        updateWarehouseTimeSlot,
        createSuccess,
        updateSuccess,
        warehouseTimeSlotDetails,
        createWarehouseTimeSlot,
      }}
    >
      {children}
    </Provider>
  )
}
export { WarehouseTimeSlotsContext }

export default WarehouseTimeSlotsProvider
