import React, { Dispatch, SetStateAction } from 'react'
import { ITourMapItem, IStopMarker, IMarker } from 'interfaces/map'
import { Marker, Popup } from 'react-leaflet'
import ReactDOMServer from 'react-dom/server'
import L from 'leaflet'
import i18n from 'i18next'

import { getStopMarkerDelaysColors } from 'utils/delaysColors'
import { dateDefaultFormat, dateToTimeFormat } from 'utils/dateFormat'
import WarehouseIcon from 'assets/icons/warehouse'
import { DEFAULT_ACCEPTABLE_DELAY, DEFAULT_ACCEPTABLE_ADVANCE } from 'constants/constants'
import { IHighlightedElement } from 'interfaces/IHighlightedElement'
import PinIcon from './PinIcon'

export interface IStopsMarker {
  tour: ITourMapItem
  color: string
  tourNumber: number
  driver: IMarker | null
  highlightedElement?: IHighlightedElement
  setHighlightedElement?: Dispatch<SetStateAction<IHighlightedElement | undefined>>
  autoPan?: boolean
}

const ICON_WIDTH = 50
const ICON_HEIGHT = 50

interface IIconProps {
  order?: number
  color: string
  circleColor?: string
  shouldDisplayStroke?: boolean
}

interface IMarkerPopup {
  stop: IStopMarker
  tourNumber: number
  driver: IMarker | null
  autoPan?: boolean
}

const StopIcon = ({ order, color, circleColor, shouldDisplayStroke }: IIconProps): JSX.Element => (
  <PinIcon pinColor={color} circleColor={circleColor} shouldDisplayStroke={shouldDisplayStroke}>
    <text
      x="50%"
      y="44%"
      textAnchor="middle"
      fontSize="155"
      fill={circleColor === 'white' ? 'black' : 'white'}
      style={{ fontWeight: 'bold' }}
    >
      {order}
    </text>
  </PinIcon>
)

const WarehouseIconWrapper = ({ color }: IIconProps): JSX.Element => (
  <PinIcon pinColor={color} circleColor={color}>
    <WarehouseIcon />
  </PinIcon>
)

const generateIcon = (
  order: number,
  color: string,
  stop: IStopMarker,
  lenght: number,
  toleratedAdvance?: number,
  toleratedDelay?: number,
  shouldDisplayStroke?: boolean,
): L.DivIcon => {
  const { realArrival, deliveryDateTimeMinimum, deliveryDateTimeMaximum } = stop

  const { background } = getStopMarkerDelaysColors(
    deliveryDateTimeMinimum,
    deliveryDateTimeMaximum,
    toleratedAdvance ?? DEFAULT_ACCEPTABLE_ADVANCE,
    toleratedDelay ?? DEFAULT_ACCEPTABLE_DELAY,
    realArrival,
    stop.status,
  )
  return L.divIcon({
    className: 'custom icon',
    html: ReactDOMServer.renderToString(
      order === 0 || order === lenght - 1 ? (
        <WarehouseIconWrapper order={order} color={color} />
      ) : (
        <StopIcon
          order={order}
          color={color}
          circleColor={background}
          shouldDisplayStroke={shouldDisplayStroke}
        />
      ),
    ),
    iconAnchor: [ICON_WIDTH / 2, ICON_HEIGHT],
    popupAnchor: [0, -ICON_HEIGHT],
  })
}

const MarkerPopup = ({ stop, tourNumber, driver, autoPan = true }: IMarkerPopup): JSX.Element => {
  const {
    address,
    estimatedArrival,
    realArrival,
    deliveryDateTimeMinimum,
    deliveryDateTimeMaximum,
  } = stop
  let timeSlot = ''
  if (deliveryDateTimeMinimum && deliveryDateTimeMaximum) {
    timeSlot = `${dateToTimeFormat(deliveryDateTimeMinimum)}-${dateToTimeFormat(
      deliveryDateTimeMaximum,
    )}`
  }
  const { full, label } = address
  return (
    <Popup autoPan={autoPan}>
      <div>{label}</div>
      <div>{full}</div>
      <div>{`${i18n.t('MapScreen.tour')}: ${tourNumber}`}</div>
      <div>{`${i18n.t('MapScreen.driver')}: ${driver?.name || ''}`}</div>
      <div>{`${i18n.t('MapScreen.timeSlot')}: ${timeSlot}`}</div>
      <div>
        {realArrival
          ? `${i18n.t('MapScreen.realArrival')}: ${dateDefaultFormat(realArrival)}`
          : `${i18n.t('MapScreen.estimatedArrival')}: ${dateDefaultFormat(estimatedArrival)}`}
      </div>
    </Popup>
  )
}

const StopsMarker = ({
  tour,
  color,
  tourNumber,
  driver,
  highlightedElement,
  setHighlightedElement,
  autoPan,
}: IStopsMarker): JSX.Element => (
  <>
    {tour.stops.map((stop: IStopMarker, index) => {
      const { stopId, address } = stop
      const { latitude: lat, longitude: lng } = address
      if (!lat || !lng) return null
      return (
        <Marker
          key={stopId}
          position={{ lat, lng }}
          icon={generateIcon(
            index,
            color,
            stop,
            tour.stops.length,
            tour.toleratedAdvance,
            tour.toleratedDelay,
            stopId === highlightedElement?.id,
          )}
          eventHandlers={
            setHighlightedElement
              ? {
                click: (e) => {
                  e.originalEvent.stopPropagation()
                  setHighlightedElement({
                    id: stopId,
                    latitude: lat,
                    longitude: lng,
                  })
                },
              }
              : undefined
          }
        >
          <MarkerPopup stop={stop} tourNumber={tourNumber} driver={driver} autoPan={autoPan} />
        </Marker>
      )
    })}
  </>
)

export default StopsMarker
