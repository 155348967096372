import React from 'react'
import { Button, ButtonProps } from '@material-ui/core'
import clsx from 'clsx'
import useStyles from './styles'

interface CustomButtonProps {
  onPress: (() => void) | (() => Promise<void>)
  children: JSX.Element | string
  dataCy?: string
  customStyle?: string
}

type IButton = CustomButtonProps & ButtonProps

const CustomButton = ({
  onPress,
  children,
  dataCy,
  customStyle,
  ...rest
}: IButton): JSX.Element => {
  const classes = useStyles()

  return (
    <Button
      data-cy={dataCy}
      className={customStyle ? clsx(classes.styledButton, customStyle) : classes.styledButton}
      variant="contained"
      color="primary"
      onClick={onPress}
      {...rest}
    >
      {children}
    </Button>
  )
}

export default CustomButton
