import React, { useState, ReactNode, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ICarrierFilters } from 'interfaces'
import { isIError } from 'api/types'
import CarriersApi from 'api/carriers'
import { ICarrier } from 'interfaces/ICarrier'
import { COLUMNS_MODEL_CARRIERS_LIST } from 'constants/table'
import { getErrorList } from 'utils/errorUtils'
import { FeedbackContext } from 'store/FeedbackContext'

interface ICarrierContext {
  carriers: ICarrier[]
  count: number
  updateCarriers: (
    filters?: ICarrierFilters,
    offset?: number,
    rowsPerPage?: number,
    sortField?: number,
    sortDirection?: string,
  ) => Promise<void>
  getDetails: (id: string) => Promise<void>
  carrierDetails: ICarrier
  createCarrier: (carrier: ICarrier) => Promise<void>
  updateCarrier: (id: string, carrier: ICarrier) => Promise<void>
  updateSuccess?: boolean
  createSuccess?: boolean
}

const CarriersContext = React.createContext<ICarrierContext>({} as ICarrierContext)
const { Provider, Consumer } = CarriersContext

interface IProps {
  children: ReactNode
}

const CarriersProvider = ({ children }: IProps): JSX.Element => {
  const [carriers, setCarriers] = useState<ICarrier[]>([])
  const [count, setCount] = useState<number>(0)
  const [carrierDetails, setCarrierDetails] = useState<ICarrier>({})
  const [updateSuccess, setUpdateSuccess] = useState<boolean | undefined>()
  const [createSuccess, setCreateSuccess] = useState<boolean | undefined>()
  const { t } = useTranslation()
  const { openErrorSnack, openSuccessSnack, toggleLoader } = useContext(FeedbackContext)

  const updateCarriers = async (
    filters?: ICarrierFilters,
    offset?: number,
    rowsPerPage?: number,
    sortField?: number,
    sortDirection?: string,
  ): Promise<void> => {
    toggleLoader(true)
    const res = await CarriersApi.getCarriers(
      filters,
      offset,
      rowsPerPage,
      COLUMNS_MODEL_CARRIERS_LIST.find((column, index) => index === sortField)?.field,
      sortDirection === 'desc' ? -1 : 1,
    )
    if (isIError(res)) {
      openErrorSnack(res.error.message)
    } else {
      setCarriers(res.carriers)
      setCount(res.count)
    }
    toggleLoader(false)
  }

  const getDetails = async (id: string): Promise<void> => {
    toggleLoader(true)
    const response = await CarriersApi.getCarrierDetails(id)
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      setCarrierDetails(response.carrier as ICarrier)
    }
    toggleLoader(false)
  }

  const createCarrier = async (carrier: ICarrier): Promise<void> => {
    toggleLoader(true)
    const response = await CarriersApi.createCarrier(carrier)
    if (isIError(response)) {
      response.error.errorList = getErrorList(response)
      if (response.error.fieldErrors) {
        openErrorSnack(response.error.errorList.join('\n'))
      } else {
        openErrorSnack(response.error.message)
      }
    } else {
      setCreateSuccess(true)
      openSuccessSnack(t('CarrierScreen.savedCarrier'))
    }
    toggleLoader(false)
  }

  const updateCarrier = async (id: string, carrier: ICarrier): Promise<void> => {
    toggleLoader(true)
    const response = await CarriersApi.updateCarrier(id, carrier)
    if (isIError(response)) {
      response.error.errorList = getErrorList(response)
      if (response.error.fieldErrors) {
        openErrorSnack(response.error.errorList.join('\n'))
      } else {
        openErrorSnack(response.error.message)
      }
    } else {
      setUpdateSuccess(true)
      openSuccessSnack(t('CarrierScreen.updatedCarrier'))
    }
    toggleLoader(false)
  }

  return (
    <Provider
      value={{
        carriers,
        count,
        updateCarriers,
        carrierDetails,
        getDetails,
        createCarrier,
        updateCarrier,
        updateSuccess,
        createSuccess,
      }}
    >
      {children}
    </Provider>
  )
}

export default CarriersProvider

export { Consumer as CarriersConsumer, CarriersContext }
