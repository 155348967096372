import React, { Dispatch, SetStateAction, useContext } from 'react'
import { Menu, IconButton, MenuItem, Checkbox } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { PIN_TOOLTIP_CONFIG } from 'constants/constants'
import { AppConfigContext } from 'store/AppConfigContext'

const toolTipConfigName = 'planningPinTooltip'

interface IProps {
  anchorEl: null | HTMLElement
  setAnchorEl: Dispatch<SetStateAction<null | HTMLElement>>
}

export default ({ anchorEl, setAnchorEl }: IProps): JSX.Element => {
  const { updateToolTipConfig, toolTipsConfig } = useContext(AppConfigContext)

  const toggleEntry = (entry: string) => {
    const entries = [...(toolTipsConfig?.planningPinTooltip || [])]
    if (entries?.includes(entry)) {
      const index = entries.findIndex((string) => string === entry)
      entries.splice(index, 1)
    } else {
      entries.push(entry)
    }
    if (updateToolTipConfig) {
      updateToolTipConfig(toolTipConfigName, entries)
    }
    return entries
  }
  const handleClose = () => setAnchorEl(null)

  return (
    <Menu
      id="simple-menu-settings"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <div style={{ float: 'right' }}>
        <IconButton onClick={handleClose} size="small">
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>
      {PIN_TOOLTIP_CONFIG.map((entry) => (
        <MenuItem key={entry.id} onClick={() => toggleEntry(entry.id)}>
          <Checkbox checked={!toolTipsConfig?.planningPinTooltip?.includes(entry.id)} />
          {entry.name}
        </MenuItem>
      ))}
    </Menu>
  )
}
