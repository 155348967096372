import React, { useContext, useState } from 'react'
import { DeleteOutline, LocalShipping } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { IconButton, Paper } from '@material-ui/core'
import styled from 'styled-components'

import ConfirmationDialog from 'components/Dialog/ConfirmationDialog'
import { ICluster } from 'interfaces/ICluster'
import { TransportType } from 'constants/constants'
import { calculateMainQuantity } from 'utils/planningUtils'
import ClusterStatusComponent from './ClusterStatusComponent'
import { PlanificationContext } from '../../PlanningStore'
import VehiculeCapacityHeader from '../VehicleCapacityHeader'

const Header = styled(Paper)<{ $bgColor: string }>`
  padding: 4px 8px;
  background-color: ${(props) => props.$bgColor};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: max-height 0.25s ease-in;
  cursor: default;
`
export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

interface IClusterHeader {
  cluster: ICluster
  onClusterLock: () => void
  onClusterDelete: () => void
  onVehicleChange: () => void
  clusterCapacity: () => number
  isManualTour?: boolean
}

const ClusterHeader = ({
  cluster,
  onClusterLock,
  onClusterDelete,
  onVehicleChange,
  clusterCapacity,
  isManualTour = false,
}: IClusterHeader): JSX.Element => {
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState<boolean>(false)

  const { t } = useTranslation()
  const { isMapotempoActionInProgress, vehiclesList } = useContext(PlanificationContext)

  const ordersCount = cluster.visits.reduce(
    (total, current) => total + (current.ordersCount || 0),
    0,
  )
  const totalMainQuantityToDeliver = calculateMainQuantity(cluster.visits, TransportType.Delivery)
  const totalMainQuantityToLoad = calculateMainQuantity(cluster.visits, TransportType.Pickup)
  const hasVehicles = !!cluster.vehicleIds.length

  const handleDeleteConfirmation = (validate: boolean): void => {
    setIsDeleteConfirmationModalOpen(false)
    if (validate) {
      onClusterDelete()
    }
  }

  const handleDelete = () => setIsDeleteConfirmationModalOpen(true)

  const title = isManualTour
    ? `${t('PlanningScreen.manualTour')} ${cluster.clusterId}`
    : `${t('PlanningScreen.cluster')} ${cluster.clusterId}`
  return (
    <Header square elevation={3} $bgColor={cluster.color}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          color: '#FFF',
        }}
      >
        <div>
          <TitleContainer>
            <b data-cy="tour-title">{title}</b>
          </TitleContainer>
          <div style={{ display: 'flex', gap: '12px' }}>
            <div>{`${t('PlanningScreen.visitsCountShortForm')}: ${cluster.visits.length}`}</div>
            <div>
              {`${t(
                'PlanningScreen.quantityShortForm',
              )}: ${totalMainQuantityToDeliver}-${totalMainQuantityToLoad} / ${clusterCapacity()}`}
            </div>
            <div>{`${t('PlanningScreen.ordersCountShortForm')}: ${ordersCount}`}</div>
          </div>
          <VehiculeCapacityHeader cluster={cluster} vehiclesList={vehiclesList} />
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-endcenter',
            flexDirection: 'column',
            minWidth: 56,
            marginLeft: 4,
          }}
        >
          <div>
            <IconButton
              disabled={isMapotempoActionInProgress}
              onClick={onVehicleChange}
              style={{ padding: 1, color: hasVehicles ? 'white' : 'orange' }}
            >
              <LocalShipping />
            </IconButton>
            <IconButton
              disabled={isMapotempoActionInProgress}
              onClick={onClusterLock}
              style={{ padding: 1, color: '#FFF' }}
            >
              <ClusterStatusComponent status={cluster.status} isManualTour={isManualTour} />
            </IconButton>
          </div>
          <IconButton
            disabled={isMapotempoActionInProgress}
            onClick={handleDelete}
            style={{ padding: 1, color: '#FFF' }}
          >
            <DeleteOutline />
          </IconButton>
        </div>
      </div>
      {isDeleteConfirmationModalOpen && (
        <ConfirmationDialog
          open={isDeleteConfirmationModalOpen}
          onClose={handleDeleteConfirmation}
          message={
            isManualTour
              ? t('PlanningScreen.deleteManualTourConfirmationMessage')
              : t('PlanningScreen.deleteClusterConfirmationMessage')
          }
        />
      )}
    </Header>
  )
}

export default ClusterHeader
