import React, { useContext } from 'react'

import { COLUMNS_MODEL_WAREHOUSE_TIME_SLOTS } from 'constants/table'
import WarehouseTimeSlotsProvider, {
  WarehouseTimeSlotsContext,
} from 'store/WarehouseTimeSlotsContext'
import WarehouseTimeSlotsTable from 'components/Table/WarehouseTimeSlotsTable'

const WarehouseTimeSlotListScreen = (): JSX.Element => {
  const { warehouseTimeSlots, count } = useContext(WarehouseTimeSlotsContext)
  return (
    <WarehouseTimeSlotsTable
      warehouseTimeSlots={warehouseTimeSlots}
      count={count}
      columns={COLUMNS_MODEL_WAREHOUSE_TIME_SLOTS}
    />
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <WarehouseTimeSlotsProvider>
    <WarehouseTimeSlotListScreen {...props} />
  </WarehouseTimeSlotsProvider>
)
