import React, { useContext, useState } from 'react'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import styled from 'styled-components'
import { Typography, CircularProgress, Paper } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useTheme, Theme } from '@material-ui/core/styles'
import { ContentContext } from 'store/ContentContext'

import { ITourMapItem } from 'interfaces/map'
import { AuthContext } from 'store/AuthContext'
import ListItem from '../ListItem'
import { StyledLoaderContainer, AutomaticDroppableContainer } from '../BasicComponents'
import CustomHeader from './CustomHeader'
import { PlanificationContext } from '../../PlanningStore'

interface ITourCol {
  tour: ITourMapItem
  isLoading?: boolean
}

const StyledContainer = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  overflow-y: overlay;
  height: 100%;
  max-height: calc(100% - 68px);
`

const ListContainer = styled(Paper)<{ theme: Theme }>`
  min-width: 260px;
  flex-direction: column;
  display: flex;
  background-color: ${(props) => props.theme.color.container};
  position: relative;
`

const TourColumn = ({ tour, isLoading }: ITourCol): JSX.Element => {
  const [isTourLoading, setIsTourLoading] = useState<boolean>(false)
  const { user } = useContext(AuthContext)
  const { isMapotempoActionInProgress, selectedPlan } = useContext(PlanificationContext)
  const { t } = useTranslation()
  const theme = useTheme()

  const { sites, tenants } = useContext(ContentContext)
  const warehouse = sites?.find((site) => site.id === tour.departureWarehouse)
  const userTenant = tenants.find((tenant) => tenant.id === user?.tenantId)

  const tagsMap = new Map(userTenant?.tags?.map((tag) => [tag.code as string, tag.label as string]))

  return (
    <Droppable
      droppableId={`TourDroppable-${tour.tourId}`}
      isDropDisabled={isMapotempoActionInProgress || selectedPlan?.deletedOnThirdParty}
    >
      {(provided): JSX.Element => (
        <ListContainer
          data-cy="tour-column"
          ref={provided.innerRef}
          elevation={1}
          square
          theme={theme}
        >
          {(isLoading || isTourLoading) && (
            <StyledLoaderContainer>
              <Typography variant="h5" component="h2" align="center" style={{ marginBottom: 16 }}>
                {t('PlanningScreen.tourLoading')}
              </Typography>
              <CircularProgress color="secondary" />
            </StyledLoaderContainer>
          )}
          <CustomHeader
            tour={tour}
            setIsTourLoading={setIsTourLoading}
            isTourLoading={isTourLoading}
          />
          <Droppable
            droppableId={`TourDroppableAuto-${tour.tourId}`}
            isDropDisabled={isMapotempoActionInProgress || selectedPlan?.deletedOnThirdParty}
          >
            {(_provided): JSX.Element => <AutomaticDroppableContainer provided={_provided} />}
          </Droppable>
          <StyledContainer>
            {tour.stops.map((stop, index) => (
              <Draggable
                isDragDisabled={
                  index === 0 || index === tour.stops.length - 1 || isMapotempoActionInProgress
                }
                key={stop.stopId}
                index={index}
                draggableId={stop.stopId}
              >
                {(providedStop, snapshotStop): JSX.Element => (
                  <ListItem
                    tagsMap={tagsMap}
                    color={tour.color}
                    marker={stop}
                    providedMarker={providedStop}
                    snapshotMarker={snapshotStop}
                    stopIndex={index}
                    toleratedAdvance={warehouse?.optimParams?.toleratedAdvance}
                    toleratedDelay={warehouse?.optimParams?.toleratedDelay}
                  />
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </StyledContainer>
        </ListContainer>
      )}
    </Droppable>
  )
}

export default TourColumn
