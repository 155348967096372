import Api from 'services/api'
import { IError } from 'api/types'
import { ITag } from 'interfaces/ITag'

const mapTags = (tags: ITag[]): ITag[] =>
  tags?.map((tag) => ({
    ...tag,
    id: Math.random(),
    errorCode: false,
  }))

const getTags = async (tenantId: string): Promise<ITag[] | IError> => {
  try {
    const response = await Api.get(`tenants/${tenantId}/tags`)
    return mapTags(response.tags)
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const updateTags = async (tenantId: string, newTags: ITag[]): Promise<ITag[] | IError> => {
  try {
    const tags = newTags
      .filter((tag) => !tag.global)
      .map(({ code, label }: { code?: string; label?: string }) => ({ code, label }))
    const response = await Api.patch(`tenants/${tenantId}/tags`, { tags })
    return mapTags(response.tags)
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

export default {
  getTags,
  updateTags,
}
