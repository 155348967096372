import React from 'react'
import { useTranslation } from 'react-i18next'
import { IWarehouseCartDropoffConfig } from 'interfaces/IWarehouseCartDropoffConfig'
import { SiteFilter } from 'components/Inputs/ListFilter'
import { isDefined } from 'utils/functions'
import { ConfigCard, FieldDetails } from './ConfigCard'
import { IProps } from './OrderInfoConfig'
import useStyles from './styles'

export const CustomerInfoConfig = ({ data, onChange }: IProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()

  const handleSelectChange = (values: string[], name: string): void => {
    onChange(name as keyof IWarehouseCartDropoffConfig, values[0] || undefined)
  }

  const customerInfoFields: FieldDetails[] = [
    {
      name: 'originSiteId',
      component: (
        <SiteFilter
          dataCy="originSiteId"
          placeholder={t('CartDropoffConfigurationScreen.originSiteId').toUpperCase()}
          handleChange={(values: string[]): void => handleSelectChange(values, 'originSiteId')}
          ids={
            isDefined(data.originSiteId?.defaultValue)
              ? [data.originSiteId?.defaultValue as string]
              : []
          }
          onlyOneValue
          shouldFillWidth
          customFormClass={classes.fullWidth}
          removeMargin
          isUnselectAllowed={false}
        />
      ),
    },
    {
      name: 'termsOfSale',
      component: (
        <div className={classes.labelDiv}>
          {t('CartDropoffConfigurationScreen.termsOfSale').toUpperCase()}
        </div>
      ),
    },
  ]

  return (
    <ConfigCard
      data={data}
      onChange={onChange}
      fields={customerInfoFields}
      sectionName={t('CartDropoffConfigurationScreen.cards.customerInfo')}
    />
  )
}
