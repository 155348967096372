import React, { useContext } from 'react'
import { FormControl, InputLabel, Select as Dropdown, MenuItem } from '@material-ui/core'

import useStyles from 'constants/cruStyles'
import { RoutingRulesContext } from './RoutingStore'

interface IProps {
  value?: number | string
  label: string
  name?: string
  isRequired?: boolean
  dataCy?: string
  options: { value: number | string; label: string; disabled?: boolean }[]
  onChange: (value: number | string | null, name: string) => void
}

const Select = ({
  value,
  onChange,
  label,
  options,
  name,
  isRequired,
  dataCy,
}: IProps): JSX.Element => {
  const classes = useStyles()
  const { editMode } = useContext(RoutingRulesContext)

  const handleSelectChange = (
    event: React.ChangeEvent<{ value: unknown; name?: string }>,
  ): void => {
    const { target } = event
    const fieldName = target.name as string
    const newValue = target.value as number

    onChange(newValue === undefined ? null : newValue, fieldName)
  }

  return (
    <FormControl
      variant={!editMode ? undefined : 'outlined'}
      disabled={!editMode}
      required={isRequired}
    >
      <InputLabel>{label}</InputLabel>
      <Dropdown
        value={value === undefined ? '' : value}
        onChange={handleSelectChange}
        className={classes.routingRuleSelect}
        label={label}
        name={name}
        IconComponent={!editMode ? () => <></> : undefined}
        data-cy={dataCy}
      >
        <MenuItem>
          <em>{label}</em>
        </MenuItem>
        {options.map((option) => (
          <MenuItem
            data-cy={option.label}
            disabled={option.disabled}
            key={option.value}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Dropdown>
    </FormControl>
  )
}

export default Select
