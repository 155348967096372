import React from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '@material-ui/core'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { ITour } from 'interfaces/Itours'
import { GetCarrierNameFromIdString } from 'utils/tableUtils'
import useStyles from './styles'

type TourRefusedAlertProps = {
  tour: ITour
}

const TourRefusedAlert = ({ tour }: TourRefusedAlertProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <div>
      <Tooltip
        title={(
          <div>
            <pre className={classes.message}>
              {t('tablesEntries.tourRefusedAlertMessage', {
                tourNumber: tour.tourNumber,
                carrierName: GetCarrierNameFromIdString({ id: tour.carrierId }),
                refusalReason: tour.refusal?.reason,
              })}
            </pre>
          </div>
        )}
      >
        <ErrorOutlineIcon />
      </Tooltip>
    </div>
  )
}

export default TourRefusedAlert
