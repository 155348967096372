import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IEvent } from 'interfaces/IEvent'
import Switch from 'components/Inputs/ToggleSwitch'
import Scheduler from 'components/Scheduler/EventScheduler'
import Card from 'components/Card/Card'

interface IProps {
  events: IEvent[]
  shouldShowVehiclePlanning?: boolean
  onAvailabilityChange?: Function
  isReadOnly?: boolean
  onAvailabilityControlChange?: (checked: boolean) => void
}

export const VehicleAvailabilityCard = ({
  events,
  shouldShowVehiclePlanning,
  onAvailabilityChange,
  isReadOnly,
  onAvailabilityControlChange,
}: IProps): JSX.Element => {
  const { t } = useTranslation()
  const [displayVehiclePlanning, setDisplayVehiclePlanning] = useState<boolean | undefined>(
    shouldShowVehiclePlanning,
  )

  const handleAvailabilityControlChange = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ): void => {
    setDisplayVehiclePlanning(checked)
    if (onAvailabilityControlChange) {
      onAvailabilityControlChange(checked)
    }
  }

  return (
    <Card title={t('VehicleScreen.availability').toUpperCase()} dataCy="availabilityCard">
      <Switch
        dataCy="planningManagement"
        checked={displayVehiclePlanning}
        name="vehiclePlanningControl"
        label={t('VehicleScreen.planningManagement').toUpperCase()}
        justifyLeft
        onChange={handleAvailabilityControlChange}
        disabled={isReadOnly}
      />
      {displayVehiclePlanning && (
        <Scheduler events={events} onEventChange={onAvailabilityChange} isReadOnly={isReadOnly} />
      )}
    </Card>
  )
}

export default VehicleAvailabilityCard
