import React, { useContext } from 'react'
import { IMenuEntries } from 'interfaces'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'

import {
  EXECUTION_REPORTING_MENU,
  SITE_CONFIGURATIONS_MENU,
  getCategoryIcon,
  getSubCategoryIcon,
} from 'constants/constants'
import { useTheme } from '@material-ui/core'
import { DefaultSiteContext } from 'store/DefaultSiteContext'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import { INavElem } from 'navigation/NavigationConfig'
import { ITab } from 'interfaces/ITab'
import { AuthContext } from 'store/AuthContext'
import { shouldShowTab } from 'utils/tabFeatureUtils'
import useStyles from './styles'

interface IListProps {
  currentPath: string
  drawerOpen: boolean
  category: IMenuEntries
  isMenuOpen?: boolean
  onMenuClick: (title: string, isMenuOpen: boolean) => void
}

interface ListItemLinkProps {
  icon?: React.ReactElement
  primary: string
  to: string
  selected: boolean
  drawerOpen: boolean
}

const ListItemLink = (props: ListItemLinkProps): JSX.Element => {
  const classes = useStyles()
  const { icon, primary, to, selected, drawerOpen } = props

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<never, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to],
  )

  return (
    <ListItem
      data-cy={primary}
      button
      className={selected ? classes.boxListSubItemSelected : classes.boxListSubItem}
      component={renderLink}
    >
      {icon && (
        <ListItemIcon
          className={drawerOpen ? classes.listSubItemIconOpen : classes.listSubItemIconClose}
        >
          {icon}
        </ListItemIcon>
      )}
      <ListItemText primary={primary} className={classes.listSubItem} />
    </ListItem>
  )
}

const ListCategory = ({
  currentPath,
  drawerOpen,
  category,
  isMenuOpen,
  onMenuClick,
}: IListProps): JSX.Element => {
  const theme = useTheme()
  const classes = useStyles()
  const { defaultSite } = useContext(DefaultSiteContext)
  const { features } = useContext(AuthContext)
  const title = category.title.charAt(0).toUpperCase() + category.title.slice(1)

  const handleClick = (): void => {
    onMenuClick(category.title, !isMenuOpen)
  }

  const areAllTabsHidden = (entry: INavElem) => {
    // check if elem has tabs and at least one tab is available to display
    const subCategoryWithTabs: { path: string; tabs: ITab[] }[] = [
      {
        path: ROUTES_PATH.siteConfigurations,
        tabs: SITE_CONFIGURATIONS_MENU,
      },
      {
        path: ROUTES_PATH.reportingExecution,
        tabs: EXECUTION_REPORTING_MENU,
      },
    ]

    const entryConfig = subCategoryWithTabs.find((elem) => elem.path === entry.path)
    if (entryConfig) {
      return entryConfig.tabs.find((tab) => shouldShowTab(tab, features)) === undefined
    }
    return false
  }

  const isSubCategoryDisabled = (entry: INavElem): boolean =>
    (defaultSite?.optimParams?.isMultisite && entry.path === ROUTES_PATH.planning)
    || (!defaultSite?.optimParams?.isMultisite && entry.path === ROUTES_PATH.multisitePlanning)
    || areAllTabsHidden(entry)

  return (
    <List style={{ width: drawerOpen ? '100%' : theme.spacing(9) + 1 }} data-cy={title}>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>{getCategoryIcon(category.title, isMenuOpen || false)}</ListItemIcon>
        <ListItemText
          primary={title}
          className={isMenuOpen ? classes.listItemSelected : classes.listItem}
        />
        {isMenuOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse
        data-cy="collapse"
        in={isMenuOpen}
        timeout="auto"
        unmountOnExit
        className={classes.collapse}
      >
        {category.data.map((e) => {
          if (isSubCategoryDisabled(e)) {
            return null
          }
          return (
            <ListItemLink
              to={e.path}
              key={e.path}
              primary={e.title}
              selected={e.path === currentPath}
              icon={getSubCategoryIcon(e.title, e.path === currentPath)}
              drawerOpen={drawerOpen}
            />
          )
        })}
      </Collapse>
    </List>
  )
}

export default ListCategory
