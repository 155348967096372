import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FormAction from 'components/Button/FormAction'
import SitesProvider, { SitesConsumer } from 'store/SitesContext'
import {
  CartDropoffConfigField,
  IWarehouseCartDropoffConfig,
} from 'interfaces/IWarehouseCartDropoffConfig'
import { DefaultSiteContext } from 'store/DefaultSiteContext'
import { FeedbackContext } from 'store/FeedbackContext'
import { convertToMinutes } from 'utils/libs/time-conversion'
import { isDefined } from 'utils/functions'
import { OrderInfoConfig } from './OrderInfoConfig'
import { AddressInfoConfig } from './AddressInfoConfig'
import { CustomerInfoConfig } from './CustomerInfoConfig'
import { DeliveryInfoConfig } from './DeliveryInfoConfig'

interface IProps {
  config: IWarehouseCartDropoffConfig | null
  getCartDropoffConfig: (warehouseId: string) => void
  updateCartDropoffConfig: (warehouseId: string, config: IWarehouseCartDropoffConfig) => void
}

const CartDropoffConfigScreen = ({
  config,
  getCartDropoffConfig,
  updateCartDropoffConfig,
}: IProps): JSX.Element => {
  const [cartDropoffConfig, setCartDropoffConfig] = useState<IWarehouseCartDropoffConfig>({})
  const { defaultSiteId } = useContext(DefaultSiteContext)
  const { openErrorSnack } = useContext(FeedbackContext)
  const { t } = useTranslation()

  const initializeConfig = (conf: IWarehouseCartDropoffConfig): void => {
    setCartDropoffConfig({
      country: { active: false, ...conf.country },
      deliveryInstructions: { active: false, ...conf.deliveryInstructions },
      orderInstructions: { active: false, ...conf.orderInstructions },
      deliveryType: { active: false, ...conf.deliveryType },
      destinationType: { active: false, ...conf.destinationType },
      digicode: { active: false, ...conf.digicode },
      enterprise: { active: false, ...conf.enterprise },
      estimatedServiceTime: {
        active: false,
        ...conf.estimatedServiceTime,
        defaultValue: isDefined(conf.estimatedServiceTime?.defaultValue)
          ? convertToMinutes(conf.estimatedServiceTime?.defaultValue as number)
          : undefined,
      },
      estimatedWeight: { active: false, ...conf.estimatedWeight },
      floor: { active: false, ...conf.floor },
      geocode: { active: false, ...conf.geocode },
      hasElevator: { active: false, ...conf.hasElevator },
      numberOfArticles: { active: false, ...conf.numberOfArticles },
      orderNumber: { active: false, ...conf.orderNumber },
      originSiteId: { active: false, ...conf.originSiteId },
      parcelCodes: { active: false, ...conf.parcelCodes },
      requestedCarriers: { active: false, ...conf.requestedCarriers },
      scanTicket: { active: false, ...conf.scanTicket },
      tags: { active: false, ...conf.tags },
      termsOfSale: { active: false, ...conf.termsOfSale },
      transportType: { active: false, ...conf.transportType },
      value: { active: false, ...conf.value },
    })
  }

  useEffect(() => {
    if (config) initializeConfig(config)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config])

  useEffect(() => {
    if (defaultSiteId && getCartDropoffConfig) getCartDropoffConfig(defaultSiteId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSiteId])

  const handleCancelClick = (): void => {
    initializeConfig(config || {})
  }

  const validateForm = (): string[] => {
    const errors: string[] = []

    const estimatedServiceTime = cartDropoffConfig.estimatedServiceTime?.defaultValue as number
    if (estimatedServiceTime && (estimatedServiceTime < 0 || estimatedServiceTime % 1 !== 0)) {
      errors.push(t('CartDropoffConfigurationScreen.messages.estimatedServiceTimeValidation'))
    }
    const floor = cartDropoffConfig.floor?.defaultValue as number
    if (floor && floor % 1 !== 0) {
      errors.push(t('CartDropoffConfigurationScreen.messages.floorValidation'))
    }
    const value = cartDropoffConfig.value?.defaultValue as number
    if (value && value < 0) {
      errors.push(t('CartDropoffConfigurationScreen.messages.valueValidation'))
    }
    const numberOfArticles = cartDropoffConfig.numberOfArticles?.defaultValue as number
    if (numberOfArticles && numberOfArticles < 0) {
      errors.push(t('CartDropoffConfigurationScreen.messages.numberOfArticlesValidation'))
    }
    const estimatedWeight = cartDropoffConfig.estimatedWeight?.defaultValue as number
    if (estimatedWeight && estimatedWeight < 0) {
      errors.push(t('CartDropoffConfigurationScreen.messages.estimatedWeightValidation'))
    }
    return errors
  }

  const handleSaveClick = (): void => {
    const validationErrors = validateForm()
    if (validationErrors.length > 0) {
      openErrorSnack(validationErrors.join('\n'))
      return
    }

    if (updateCartDropoffConfig && defaultSiteId) {
      updateCartDropoffConfig(defaultSiteId, {
        ...cartDropoffConfig,
        deliveryType: {
          ...cartDropoffConfig.deliveryType,
          defaultValue: isDefined(cartDropoffConfig.deliveryType?.defaultValue)
            ? Number(cartDropoffConfig.deliveryType?.defaultValue)
            : undefined,
        } as CartDropoffConfigField,
        estimatedServiceTime: {
          ...cartDropoffConfig.estimatedServiceTime,
          defaultValue: cartDropoffConfig.estimatedServiceTime?.defaultValue
            ? ((cartDropoffConfig.estimatedServiceTime?.defaultValue as number) || 0) * 60
            : undefined,
        } as CartDropoffConfigField,
        floor: {
          ...cartDropoffConfig.floor,
          defaultValue: isDefined(cartDropoffConfig.floor?.defaultValue)
            ? Number(cartDropoffConfig.floor?.defaultValue)
            : undefined,
        } as CartDropoffConfigField,
        estimatedWeight: {
          ...cartDropoffConfig.estimatedWeight,
          defaultValue: isDefined(cartDropoffConfig.estimatedWeight?.defaultValue)
            ? Number(cartDropoffConfig.estimatedWeight?.defaultValue)
            : undefined,
        } as CartDropoffConfigField,
        numberOfArticles: {
          ...cartDropoffConfig.numberOfArticles,
          defaultValue: isDefined(cartDropoffConfig.numberOfArticles?.defaultValue)
            ? Number(cartDropoffConfig.numberOfArticles?.defaultValue)
            : undefined,
        } as CartDropoffConfigField,
      })
    }
  }

  const handleConfigChange = (
    fieldName: string,
    value: unknown,
    propertyName = 'defaultValue',
  ): void => {
    if (
      fieldName === 'originSiteId'
      && propertyName === 'defaultValue'
      && cartDropoffConfig.originSiteId?.defaultValue !== value
    ) {
      // we changed the default site, we need to clear the selections in requested Carriers
      handleConfigChange('requestedCarriers', [], 'allowedValues')
    }
    setCartDropoffConfig((prev) => {
      const conf = { ...prev }
      const fieldConf = conf[fieldName] || {}
      fieldConf[propertyName] = value

      return conf
    })
  }

  return (
    <>
      <CustomerInfoConfig data={cartDropoffConfig} onChange={handleConfigChange} />
      <AddressInfoConfig data={cartDropoffConfig} onChange={handleConfigChange} />
      <DeliveryInfoConfig data={cartDropoffConfig} onChange={handleConfigChange} />
      <OrderInfoConfig data={cartDropoffConfig} onChange={handleConfigChange} />
      <FormAction onSaveClick={handleSaveClick} onCancelClick={handleCancelClick} />
    </>
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <SitesProvider>
    <SitesConsumer>
      {(ctx): JSX.Element => (
        <CartDropoffConfigScreen
          config={ctx.cartDropoffConfig}
          getCartDropoffConfig={ctx.getCartDropoffConfig}
          updateCartDropoffConfig={ctx.updateCartDropoffConfig}
          {...props}
        />
      )}
    </SitesConsumer>
  </SitesProvider>
)
