import React, { useContext, useEffect, useState } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { Skeleton } from '@material-ui/lab'
import { IVehicle } from 'interfaces/IVehicle'
import { IVehicleAvailability } from 'interfaces/IVehicleAvailability'
import { useTranslation } from 'react-i18next'
import { Paper, Typography } from '@material-ui/core'
import Modal from '@material-ui/core/Modal'
import ButtonsRow from 'screens/PlanningCategory/PlanningScreen/components/ButtonsRow'
import ListItem from 'screens/PlanningCategory/PlanningScreen/components/OptimizeModal/ListItem'
import styled from 'styled-components'
import { removePastDatesFromVehiclesAvailabilities } from 'utils/planningUtils'
import { PlanificationContext } from '../../PlanningStore'

interface ICreateClusterModal {
  selectedPlanId?: string
  isVisible: boolean
  toggleModal(): void
  changeVehicles: (vehicleIds: string[]) => void
  vehiclesList: IVehicleAvailability[]
  selectedVehicleIds: string[]
  isManualTour?: boolean
}

const CustomPaper = styled(Paper)`
  position: absolute;
  min-width: 60%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 500;
  padding: 20px 50px;
`

const VehiclesListContainer = styled(Paper)`
  height: 500px;
  overflow: auto;
  background-color: ${(props) => props.theme.color.backgroundNavBar};
`

export default ({
  isVisible,
  toggleModal,
  changeVehicles,
  vehiclesList,
  selectedVehicleIds,
  isManualTour = false,
}: ICreateClusterModal): JSX.Element => {
  const { t } = useTranslation()
  const [selectedVehIds, setSelectedVehIds] = useState<Set<string>>(new Set(selectedVehicleIds))
  const { setVehiclesList, selectedPlan } = useContext(PlanificationContext)
  const theme = useTheme()

  useEffect(() => {
    setVehiclesList(prev => removePastDatesFromVehiclesAvailabilities(prev, selectedPlan))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setSelectedVehIds(new Set(selectedVehicleIds))
  }, [selectedVehicleIds])

  const onVehicleClick = ({ id = '' }: IVehicle): void => {
    if (selectedVehIds.has(id)) {
      selectedVehIds.delete(id)
      setSelectedVehIds(new Set(selectedVehIds))
    } else {
      selectedVehIds.add(id)
      setSelectedVehIds(new Set(selectedVehIds))
    }
  }

  const handleCreate = () => {
    changeVehicles(Array.from(selectedVehIds))
    toggleModal()
  }

  return (
    <Modal open={isVisible} onClose={toggleModal} aria-label="cluster-creation-modal">
      <CustomPaper>
        <Typography variant="h4" style={{ marginBottom: 32 }}>
          {isManualTour
            ? t('PlanningScreen.manualTourCreation')
            : t('PlanningScreen.clusterCreation')}
        </Typography>
        <Typography style={{ marginBottom: 6 }}>
          {t('PlanningScreen.vehicleSelection')} :
        </Typography>
        <VehiclesListContainer theme={theme}>
          {!vehiclesList ? (
            <>
              {[...Array(16)].map((n, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Skeleton height={38} style={{ marginBottom: 6 }} key={`skeleton_${i}`} />
              ))}
            </>
          ) : !vehiclesList.length ? (
            <Typography>{t('PlanningScreen.optimizeModal.noVehicle')}</Typography>
          ) : (
            vehiclesList.map((veh) => (
              <ListItem
                key={veh.id}
                label={veh.name}
                selected={selectedVehIds.has(veh.id)}
                startDate={veh.startDate}
                endDate={veh.endDate}
                onClick={(): void => onVehicleClick(veh)}
                siteId={veh.siteId}
                capacity={veh.capacity}
                id={veh.id}
              />
            ))
          )}
        </VehiclesListContainer>
        <ButtonsRow
          toggleModal={toggleModal}
          onClickCTA={handleCreate}
          confirmMessage={t('PlanningScreen.validate')}
          style={{ marginTop: 42 }}
          isDisabled={vehiclesList.findIndex(v => new Date(v.startDate) > new Date(v.endDate)) !== -1}
        />
      </CustomPaper>
    </Modal>
  )
}
