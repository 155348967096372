import React, { useEffect, useRef, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { TablePagination } from '@material-ui/core'
import { ArrowForward } from '@material-ui/icons'
import MaterialTable, { Action } from 'material-table'
import { useHistory } from 'react-router-dom'

import { getPageSize, savePageSize } from 'utils/localStorage'
import { IWebhook } from 'interfaces/IWebhook'
import { isIError } from 'api/types'
import { scrollTop } from 'utils/functions'
import { COLUMNS_MODEL_WEBHOOKS } from 'constants/table'
import Button from 'components/Button/CustomButton'
import { ROWS_PER_PAGE_OPTIONS } from 'constants/constants'
import MUITableIcons from 'constants/MUITableIcons'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import WebhooksApi from 'api/webhooks'
import useStyles from 'components/Table/styles'
import { getDisplayRowsCount } from 'utils/tableUtils'

const WebhooksTable = (): JSX.Element => {
  const isInitialMount = useRef(true)
  const styles = useStyles()

  const [paginationOffset, setPaginationOffset] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(getPageSize)
  const [webhooks, setWebhooks] = useState<IWebhook[]>([])
  const [count, setCount] = useState<number>(0)
  const history = useHistory()
  const { t } = useTranslation()

  const getInitialList = async () => {
    const response = await WebhooksApi.getList(0, rowsPerPage)
    if (!isIError(response)) {
      setWebhooks(response.webhooks)
      setCount(response.count)
    }
  }

  const getList = async () => {
    const response = await WebhooksApi.getList(paginationOffset, rowsPerPage)
    if (!isIError(response)) {
      setWebhooks(response.webhooks)
    }
  }

  useEffect(() => {
    if (rowsPerPage !== getPageSize()) {
      savePageSize(rowsPerPage)
      scrollTop()
    }
    getInitialList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage])

  useEffect(() => {
    if (!isInitialMount.current) {
      getList()
      scrollTop()
    } else {
      isInitialMount.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationOffset])

  const handleChangeRowsPerPage = (pageSize: number): void => {
    setPage(0)
    setPaginationOffset(0)
    setRowsPerPage(pageSize)
  }

  const handleNewWebhookClick = () => {
    history.push(ROUTES_PATH.createWebhook)
  }

  const handleDetailsClick = ({ id }: IWebhook) => {
    history.push(`${ROUTES_PATH.editWebhook}${id}`)
  }

  const displayRowsCount = useMemo(() => getDisplayRowsCount(webhooks), [webhooks])

  return (
    <div>
      <div className={styles.newButtonContainer}>
        <Button className={styles.newButton} onPress={handleNewWebhookClick}>
          {t('tablesEntries.new')}
        </Button>
      </div>
      <div>
        <MaterialTable
          key={displayRowsCount}
          data={webhooks}
          columns={COLUMNS_MODEL_WEBHOOKS as unknown as MaterialTable<object>[]}
          options={{
            toolbar: false,
            pageSize: displayRowsCount,
            actionsColumnIndex: -1,
          }}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          icons={MUITableIcons}
          actions={[
            (data: IWebhook): Action<IWebhook> => ({
              onClick: (): void => handleDetailsClick(data),
              icon: ArrowForward,
              tooltip: t('tablesEntries.seeDetails'),
            }),
          ]}
          components={{
            // eslint-disable-next-line react/prop-types, @typescript-eslint/no-unused-vars
            Pagination: ({ classes, ...otherProps }): JSX.Element => (
              <TablePagination
                {...otherProps}
                page={page}
                rowsPerPage={rowsPerPage}
                count={count}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                onChangePage={(_event: Event, nextPage: number): void => {
                  setPage(nextPage)
                  setPaginationOffset(nextPage * rowsPerPage)
                }}
              />
            ),
          }}
        />
      </div>
    </div>
  )
}

export default WebhooksTable
