import { ROUTES_PATH } from 'navigation/RoutesPath'
import React, { ReactNode, useState } from 'react'
import { useLocation } from 'react-router-dom'

interface IRoutingRulesContext {
  editMode: boolean
}

const RoutingRulesContext = React.createContext<IRoutingRulesContext>({} as IRoutingRulesContext)
const { Provider, Consumer } = RoutingRulesContext

interface IProps {
  children: ReactNode
}

function RoutingRulesProvider({ children }: IProps): JSX.Element {
  const location = useLocation()
  const [editMode] = useState<boolean>(
    location.pathname.includes(ROUTES_PATH.editRoutingRules)
      || location.pathname.includes(ROUTES_PATH.createRoutingRules),
  )

  return (
    <Provider
      value={{
        editMode,
      }}
    >
      {children}
    </Provider>
  )
}

export { RoutingRulesContext }
export default RoutingRulesProvider
export { Consumer as RoutingRulesConsumer }
