import React from 'react'
import { useTranslation } from 'react-i18next'
import { FormControl, Button, TextField } from '@material-ui/core'

import useStyles from 'constants/cruStyles'
import Card from 'components/Card/Card'
import { ITextChange } from 'interfaces/interfaces'
import FieldError from 'components/FieldError/FieldError'
import { ITag } from 'interfaces/ITag'
import clsx from 'clsx'

interface IProps {
  tags: ITag[]
  onTagsChange: (tags: ITag[]) => void
}

const TagsCard = ({ tags, onTagsChange }: IProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleAdd = (): void => {
    const newId = Math.random()
    const errorCode = codeAlreadyExists(newId, undefined)
    onTagsChange([
      ...tags,
      {
        id: newId, // this id is used only by the front to handle the list of tags correctly when changing the values
        code: undefined,
        errorCode,
        isNew: true,
        global: false,
      },
    ])
  }

  const handleInputChange = (event: ITextChange, tagId: number | undefined): void => {
    const { target } = event
    const { name, value } = target
    const formattedValue = name === 'code' ? value.toUpperCase().trim().split(' ').join('') : value

    onTagsChange(
      tags.map((tag) => {
        if (tag.id === tagId) {
          return {
            ...tag,
            [name]: value.length > 0 ? formattedValue : undefined,
            errorCode: name === 'code' ? codeAlreadyExists(tagId, formattedValue) : tag.errorCode,
          }
        }
        return tag
      }),
    )
  }

  const codeAlreadyExists = (tagId: number | undefined, value?: string): boolean =>
    tags.findIndex((tag) => tag.code === value && tag.id !== tagId) !== -1

  return (
    <Card
      dataCy="tagsCard"
      title={t('ConfigurationsScreen.tags').toUpperCase()}
      contentClassName={classes.contentRef}
    >
      <>
        <div className={classes.btnSelect}>
          <FormControl variant="outlined">
            <Button data-cy="addButton" className={classes.modifyButton} onClick={handleAdd}>
              {t('ConfigurationsScreen.add').toUpperCase()}
            </Button>
          </FormControl>
        </div>
        <div className={classes.fieldsContainer}>
          {tags.map((tag) => (
            <div data-cy="tagItems" className={classes.configurationRow} key={tag.id}>
              <div className={classes.configurationItemsContainer}>
                <div className={classes.configurationTextField}>
                  <TextField
                    data-cy="code"
                    error={tag.code === undefined || tag.errorCode}
                    label={t('ConfigurationsScreen.code').toUpperCase()}
                    onChange={(event) => handleInputChange(event, tag.id)}
                    name="code"
                    required
                    value={tag.code || ''}
                    type="text"
                    disabled={!tag.isNew || tag.global}
                  />
                  {tag.errorCode && (
                    <FieldError>{t('ConfigurationsScreen.codeAlreadyExists')}</FieldError>
                  )}
                </div>
                <div className={clsx(classes.sideTextField, classes.configurationTextField)}>
                  <TextField
                    data-cy="label"
                    error={tag.label === undefined}
                    label={t('ConfigurationsScreen.label').toUpperCase()}
                    onChange={(event) => handleInputChange(event, tag.id)}
                    name="label"
                    required
                    value={tag.label || ''}
                    type="text"
                    disabled={tag.global}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    </Card>
  )
}

export default TagsCard
