import Api from 'services/api'
import { IError } from 'api/types'
import {
  ITour,
  IAddress,
  IStop,
  IVisitData,
  IUpdateTourVisits,
  IUpdateTourStatus,
  IUpdateTour,
} from 'interfaces/Itours'
import { ITourFilters } from 'interfaces'
import moment from 'moment'
import { IDailyStats } from 'interfaces/IDailyStats'
import { IMarketShare } from 'interfaces/IMarketShare'
import { ITourReplay } from 'interfaces/map'
import { IMinimalTour } from 'interfaces/IMinimalTour'

/*
 * This function will parse the tours get from api
 * We need to do this to make remote data easely usable
 * Some of the output data will not come directly from api
 * but is computed from the data source
 */

interface IReturnStopsData {
  stopsNumber: number
  quantity: number
  totalFloor: number
  postalCodes: string[]
  totalKilometers: number
  departureDate?: Date | null
  specifics: string[]
  amplitude?: number
  warehouseLabel: string
  estimatedArrival?: Date | null
}

function parsePostalCode(postalCodes: string[], address: IAddress): void {
  if (address && address.zipCode) {
    if (!postalCodes.includes(address.zipCode)) {
      postalCodes.push(address.zipCode)
    }
  }
}

function getQuantity(stop: IStop): number {
  let quantity = 0
  const getVisitsQuantity = (acc: number, visit: IVisitData): number => acc + visit.quantity

  if (stop.visitsToLoad && stop.visitsToLoad.length > 0) {
    quantity += stop.visitsToLoad.reduce(getVisitsQuantity, 0)
  }
  if (stop.visitsToDeliver && stop.visitsToDeliver.length > 0) {
    // eslint-disable-next-line no-param-reassign
    stop.quantity += stop.visitsToDeliver.reduce(getVisitsQuantity, 0)
  }
  return quantity
}

function getVisitSpecifics(specifics: string[], arraySpecifics: string[], visit: IVisitData): void {
  if (visit.specifics && visit.specifics.length > 0) {
    visit.specifics.forEach((elem) => {
      if (elem && !specifics.includes(elem)) {
        specifics.push(elem)
      }
      if (elem) {
        arraySpecifics.push(elem)
      }
    })
  }
}

function getSpecifics(specifics: string[], stop: IStop): void {
  // This array will be used to export stop specifics in order to easely use them later.
  // This should probably be done in the backend
  const arraySpecifics: string[] = []
  if (stop.visitsToLoad && stop.visitsToLoad.length > 0) {
    stop.visitsToLoad.forEach((visit) => getVisitSpecifics(specifics, arraySpecifics, visit))
  }
  if (stop.visitsToDeliver && stop.visitsToDeliver.length > 0) {
    stop.visitsToDeliver.forEach((visit) => getVisitSpecifics(specifics, arraySpecifics, visit))
  }
  // eslint-disable-next-line no-param-reassign
  stop.specifics = arraySpecifics
}

interface ICustomDates {
  startDate: Date | null
  endDate: Date | null
  amplitude: number
}

function getTime(date: Date): moment.Moment {
  return moment(date)
}

function getAmplitude(dates: ICustomDates, stop: IStop): ICustomDates {
  const tempDates: ICustomDates = { ...dates }
  if (!tempDates.startDate || tempDates.startDate > stop.plannedArrival) {
    tempDates.startDate = stop.plannedArrival
  }
  if (!tempDates.endDate || tempDates.endDate < stop.plannedArrival) {
    tempDates.endDate = stop.plannedArrival
  }
  if (tempDates.startDate && tempDates.endDate) {
    const start = getTime(tempDates.startDate)
    const end = getTime(tempDates.endDate)
    const diff = end.diff(start)
    tempDates.amplitude = diff
  }
  return tempDates
}

const extractPhoneNumber = (visits: IVisitData[]): string => {
  let phoneNumber = ''
  visits.forEach((visit) => {
    if (visit.phoneNumber && visit.phoneNumber.length > 0) {
      phoneNumber = visit.phoneNumber
    }
  })
  return phoneNumber
}

const getPhoneNumber = (stop: IStop): void => {
  let phoneNumber = ''
  if (stop.visitsToLoad && stop.visitsToLoad.length > 0) {
    phoneNumber = extractPhoneNumber(stop.visitsToLoad)
  }
  if (stop.visitsToDeliver && stop.visitsToDeliver.length > 0) {
    phoneNumber = extractPhoneNumber(stop.visitsToDeliver)
  }
  // eslint-disable-next-line no-param-reassign
  stop.phoneNumber = phoneNumber
}

function getStopsData(stops: IStop[], departureAddress: IAddress): IReturnStopsData {
  let stopsNumber = stops.length - 2
  if (stopsNumber < 0) stopsNumber = 0
  let quantity = 0
  let totalFloor = 0
  const postalCodes: string[] = []
  const specifics: string[] = []
  let totalKilometers = 0
  let departureDate: Date | null = null
  let dates: ICustomDates = {
    startDate: null,
    endDate: null,
    amplitude: 0,
  }
  let warehouseLabel = ''
  if (stops && stops.length > 0) {
    stops.forEach((elem: IStop) => {
      quantity += getQuantity(elem)
      const { address } = elem
      if (departureAddress.full !== address.full) {
        parsePostalCode(postalCodes, address)
      }
      if (address && address.label && warehouseLabel === '') {
        warehouseLabel = address.label
      }
      totalFloor += address.floor
      if (elem.plannedArrival && !departureDate) {
        departureDate = elem.plannedArrival
      }
      totalKilometers += elem.kilometers
      getSpecifics(specifics, elem)
      dates = getAmplitude(dates, elem)
      getPhoneNumber(elem)
    })
  }
  return {
    stopsNumber,
    quantity,
    totalFloor,
    postalCodes,
    totalKilometers,
    departureDate,
    specifics,
    amplitude: dates.amplitude,
    warehouseLabel,
  }
}

const parseSingleStop = (stop: IStop): IStop => ({
  ...stop,
  plannedArrival: stop.planedArrival,
  status: Number(stop.status),
  label: stop.visitsToDeliver && stop.visitsToDeliver[0] && stop.visitsToDeliver[0].label,
})

const parseStops = (stops: IStop[]): Array<IStop> => stops.map((elem) => parseSingleStop(elem))

function parseSingleTour(tour: ITour): ITour {
  return {
    tourId: tour.tourId,
    tourNumber: tour.tourNumber,
    carrierId: tour.carrierId,
    vehicleId: tour.vehicleId,
    driverId: tour.driverId,
    driver: tour.driver,
    deliveryType: tour.deliveryType,
    departureAddress: tour.departureAddress,
    pickupTime: tour.pickupTime,
    planningStatus: tour.planningStatus,
    executionStatus: tour.executionStatus,
    affectationStatus: tour.affectationStatus,
    abandonedStopCount: tour.abandonedStopCount,
    toleratedAdvance: tour.toleratedAdvance,
    toleratedDelay: tour.toleratedDelay,
    estimatedDelayCount: tour.estimatedDelayCount,
    locked: tour.locked,
    realDelay: tour.realDelay,
    estimatedDelay: tour.estimatedDelay,
    postponedStopCount: tour.postponedStopCount,
    status: Number(tour.status),
    deckCode: tour.deckCode,
    ...getStopsData(tour.stops, tour.departureAddress),
    stops: parseStops(tour.stops),
    departureDateTime: tour.departureDateTime,
    arrivalDateTime: tour.arrivalDateTime,
    warehouseId: tour.warehouseId,
    cancellation: tour.cancellation,
    refusal: tour.refusal,
  }
}

const parseTours = (tours: ITour[]): Array<ITour> => tours.map((elem) => parseSingleTour(elem))

const getTours = async (
  filters?: ITourFilters,
  offset?: number,
  rowsPerPage?: number,
  sortBy?: string,
  sortDirection?: number,
): Promise<{ tours: Array<ITour>; count: number } | IError> => {
  try {
    const filtersToApply = {
      ...filters,
      searchText: undefined,
      ...((filters?.searchText?.length || 0) >= 3 && { search: filters?.searchText }),
    }
    const res = await Api.get('tours', {
      ...filtersToApply,
      offset,
      limit: rowsPerPage,
      sortBy: sortBy || 'tourNumber',
      sortDirection: sortDirection || 1,
    })
    return { tours: parseTours(res.items), count: res.count }
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const updateTour = async ({ tourId, status, ...rest }: IUpdateTour): Promise<ITour | IError> => {
  try {
    const res = await Api.put(`tours/${tourId}`, {
      status: status ? Number(status) : undefined,
      ...rest,
    })
    return parseSingleTour(res)
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const updateTourStatus = async (
  tourId: string,
  status: Number,
): Promise<IUpdateTourStatus | IError> => {
  try {
    const res = await Api.patch(`tours/${tourId}/status`, { status })
    return res
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getMobileAppStats = async (
  startDate: string,
  endDate: string,
  carrierIds?: string[],
  driverIds?: string[],
  siteIds?: string[],
): Promise<IDailyStats[] | IError> => {
  try {
    const response = await Api.get('stats/get-tracking-mobile-usage-per-status-in-date', {
      startDate: new Date(startDate).toLocaleDateString('fr'),
      endDate: new Date(endDate).toLocaleDateString('fr'),
      carrierIds,
      driverIds,
      jetLag: -new Date().getTimezoneOffset() / 60,
      siteIds,
    })
    return response
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getMarketShare = async (
  startDate: string,
  endDate: string,
  deliveryTypes?: string[],
  siteIds?: string[],
): Promise<IMarketShare | IError> => {
  try {
    const response = await Api.get('/stats/get-market-share-carriers-tours-per-types-by-date', {
      startDate: new Date(startDate).toLocaleDateString('fr'),
      endDate: new Date(endDate).toLocaleDateString('fr'),
      deliveryType: deliveryTypes,
      jetLag: -new Date().getTimezoneOffset() / 60,
      siteIds,
    })
    return response
  } catch (error) {
    return { error: { message: error.message } }
  }
}

interface IStopOrderRequest {
  stops: string[]
}

async function reorderStops(tourId: string, newOrder: IStopOrderRequest): Promise<void | IError> {
  try {
    await Api.patch(`tours/${tourId}/stop/order`, newOrder)
  } catch (error) {
    return { error: { message: error.message } }
  }
}

async function addVisitToTour(
  tourId: string,
  visit: { visitId: string; index: number },
): Promise<void | IError> {
  try {
    await Api.patch(`tours/${tourId}/visit`, visit)
  } catch (error) {
    return { error: { message: error.message } }
  }
}

async function removeStopFromTour(tourId: string, stopId?: string): Promise<void | IError> {
  try {
    await Api.delete(`tours/${tourId}/stop/${stopId}`)
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getMinimalTours = async (
  startDate: string,
  endDate: string,
  siteIds: string[],
): Promise<Array<IMinimalTour> | IError> => {
  try {
    const res = await Api.get('tours/minimal', { startDate, endDate, warehouseIds: siteIds })
    return res
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getReplay = async (tourId: string): Promise<ITourReplay | IError> => {
  try {
    const res = await Api.get(`tours/${tourId}/replay`)
    return res
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const updateStopStatus = async (
  stopId: string,
  status: number,
  date?: Date,
): Promise<ITour | IError> => {
  try {
    const res = await Api.patch(`tours/stop/${stopId}/status`, { status, date })
    return parseSingleTour(res)
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const updateVisits = async (
  tourId: string,
  visits: IUpdateTourVisits[],
): Promise<ITour | IError> => {
  try {
    const res = await Api.post(`tours/${tourId}/update-visits`, { visits })
    return parseSingleTour(res)
  } catch (error) {
    return { error: { message: error.message } }
  }
}

export default {
  getTours,
  updateTour,
  getMobileAppStats,
  getMarketShare,
  reorderStops,
  addVisitToTour,
  removeStopFromTour,
  updateVisits,
  getReplay,
  getMinimalTours,
  updateStopStatus,
  updateTourStatus,
}
