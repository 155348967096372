import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd'
import styled from 'styled-components'
import { useTheme } from '@material-ui/core/styles'
import clsx from 'clsx'
import Collapse from '@material-ui/core/Collapse'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { useTranslation } from 'react-i18next'
import { AppConfigContext } from 'store/AppConfigContext'
import { AuthConsumer } from 'store/AuthContext'
import FullCircle from '@material-ui/icons/FiberManualRecord'

import { ISelectableMarker, IStopMarker } from 'interfaces/map'
import { getDelaysColors } from 'utils/delaysColors'
import {
  getItemStyle,
  Indicator,
  ContentContainer,
  ContentHeader,
  TextName,
  Status,
  Address,
  Label,
  CardFooter,
  Bold,
} from 'components/Map/ToursMap/VisitsListItem'

import { DEFAULT_ACCEPTABLE_DELAY, DEFAULT_ACCEPTABLE_ADVANCE } from 'constants/constants'
import { dateToTimeFormat } from 'utils/dateFormat'
import useStyles from 'components/Map/styles'
import { PlanificationContext } from '../PlanningStore'
import { CARD_SETTINGS_VALUES } from './CardSettingsMenu'

const DelayIndicator = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`

const StyleDeleter = styled.div`
  opacity: 0;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 6px 12px 6px;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  display: flex;
  font-weight: bold;
  color: orange;
  font-size: large;
  text-align: center;
  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`

const UnderlinedLink = styled.span`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

interface IListItem {
  showMoreForAll?: boolean
  marker: ISelectableMarker | IStopMarker
  providedMarker: DraggableProvided
  snapshotMarker: DraggableStateSnapshot
  stopIndex?: number
  color?: string
  isClusterMode?: boolean
  onDelete?: () => void
  isClusterEditable?: boolean
  isManualTour?: boolean
  toleratedAdvance?: number
  toleratedDelay?: number
  isOutsourceMode?: boolean
  tagsMap: Map<string, string>
}

const ListItem = ({
  showMoreForAll = false,
  marker,
  providedMarker,
  snapshotMarker,
  stopIndex,
  color,
  isClusterMode = false,
  onDelete,
  isClusterEditable = false,
  isManualTour = false,
  toleratedAdvance = DEFAULT_ACCEPTABLE_ADVANCE,
  toleratedDelay = DEFAULT_ACCEPTABLE_DELAY,
  isOutsourceMode = false,
  tagsMap,
}: IListItem): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [collapsed, setCollapsed] = useState<boolean>(showMoreForAll)
  const { toolTipsConfig } = useContext(AppConfigContext)
  const { highlightedElement, setHighlightedElement } = useContext(PlanificationContext)
  const label = marker.address.label || ''
  const isPlanTourColumn = stopIndex !== undefined

  const { fontColor: delayColor } = getDelaysColors(
    marker.plannedArrival,
    marker.deliveryDateTimeMinimum,
    marker.deliveryDateTimeMaximum,
    toleratedAdvance,
    toleratedDelay,
  )

  let timeSlot = ''
  if (marker.deliveryDateTimeMinimum && marker.deliveryDateTimeMaximum) {
    timeSlot = `${dateToTimeFormat(marker.deliveryDateTimeMinimum)}-${dateToTimeFormat(
      marker.deliveryDateTimeMaximum,
    )}`
  }

  const plannedDeparture = marker.plannedDeparture && moment(marker.plannedDeparture).format('HH:mm')
  const theme = useTheme()

  useEffect(() => {
    setCollapsed(showMoreForAll)
  }, [showMoreForAll])

  const handleMoreInfoClicked = () => {
    setCollapsed(!collapsed)
  }

  const generateExtraData = (prop) => {
    const setting = CARD_SETTINGS_VALUES.find((set) => set.propName === prop)
    if (setting) {
      const path = setting.propPath.split('.')
      const rawValue = path.reduce(
        (obj: object | string, currentValue) => obj[currentValue],
        marker,
      )
      const formattedValue = t(setting.formatter(rawValue as never, tagsMap))
      return (
        <Grid
          key={`more-data-${setting.propName}-${marker.id}`}
          item
          data-cy={`more-data-${setting.propName}-${marker.id}`}
        >
          {t(setting.label)} : {formattedValue}
        </Grid>
      )
    }
  }

  const addressLines = marker.address.full?.split(',') || []
  const addressLine1 = marker.address.road
    ? `${marker.address.roadNumber} ${marker.address.road}`
    : addressLines[0]?.trim() || ''
  const addressLine2 = marker.address.road
    ? `${marker.address.zipCode} ${marker.address.city} ${marker.address.country || ''}`
    : addressLines[1]?.trim() || ''

  return (
    <Paper
      elevation={8}
      ref={providedMarker.innerRef}
      {...providedMarker.draggableProps}
      {...providedMarker.dragHandleProps}
      style={getItemStyle(snapshotMarker.isDragging, providedMarker.draggableProps.style)}
      data-cy="tour"
      className={clsx(
        classes.clickable,
        highlightedElement?.id === marker.stopId && classes.highlightedCard,
      )}
      onClick={(e) => {
        if (!((isClusterMode && isClusterEditable) || isOutsourceMode)) {
          e.stopPropagation()
          setHighlightedElement({
            id: marker.stopId,
            latitude: marker.address.latitude,
            longitude: marker.address.longitude,
          })
        }
      }}
      id={marker.stopId}
    >
      <div style={{ display: 'flex', position: 'relative' }}>
        <Indicator color={color} defaultColor={theme.color.secondaryHover as string} />
        <ContentContainer>
          <ContentHeader>
            <Label>
              {(isPlanTourColumn ? stopIndex : label?.trim()?.slice(0, 2).toUpperCase()) || '-'}
            </Label>
            <TextName>{label}</TextName>
            <Status>
              {`${t('PlanningScreen.quantityShortForm')}: ${marker.mainQuantity || 0}`}
            </Status>
          </ContentHeader>
          <Address>{addressLine1}</Address>
          <Address>{addressLine2}</Address>
          <CardFooter>
            <DelayIndicator>
              {isPlanTourColumn
                && marker.deliveryDateTimeMaximum
                && marker.deliveryDateTimeMinimum && <FullCircle style={{ color: delayColor }} />}
              <Bold data-cy="time">{plannedDeparture}</Bold>
            </DelayIndicator>
            {timeSlot && (
              <div>
                <Bold>[{timeSlot}]</Bold>
              </div>
            )}
            <Bold>{`${t('PlanningScreen.ordersCountShortForm')}: ${marker.ordersCount || 0}`}</Bold>
          </CardFooter>
          <CardFooter>
            <Grid container direction="column">
              <Collapse
                key={`more-data-container-${marker.id}`}
                in={collapsed}
                timeout="auto"
                data-cy={`more-data-container-${marker.id}`}
              >
                {toolTipsConfig.cardSettings?.map((setting) => generateExtraData(setting))}
              </Collapse>
              {toolTipsConfig.cardSettings?.length > 0 && !isOutsourceMode && (
                <Grid
                  container
                  data-cy={`collapse-button-${marker.id}`}
                  item
                  justify="flex-end"
                  onClick={handleMoreInfoClicked}
                  style={{ cursor: 'pointer' }}
                >
                  <Grid item>
                    <UnderlinedLink>
                      {collapsed ? '-' : '+'} {t('CardSettingsMenu.info')}
                    </UnderlinedLink>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </CardFooter>
        </ContentContainer>
        {((isClusterMode && isClusterEditable) || isOutsourceMode) && (
          <StyleDeleter onClick={onDelete}>
            {isOutsourceMode
              ? t('PlanningScreen.deleteVisitFromOutsourced')
              : isManualTour
                ? t('PlanningScreen.deleteVisitFromManualTour')
                : t('PlanningScreen.deleteVisitFromCluster')}
          </StyleDeleter>
        )}
      </div>
    </Paper>
  )
}

export default (props: JSX.IntrinsicAttributes & IListItem): JSX.Element => (
  <AuthConsumer>{(): JSX.Element => <ListItem {...props} />}</AuthConsumer>
)
