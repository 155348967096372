import React from 'react'
import CardLine, { CardLineProps } from 'components/CardLine/CardLine'
import CustomCard from 'components/CustomCard/CustomCard'
import { useTranslation } from 'react-i18next'
import { dateDefaultFormat } from 'utils/dateFormat'
import { GetCarrierNameFromDirectId } from 'utils/tableUtils'
import { IOrderPlanVisit } from 'interfaces/IOrders'

type PlanVisitDetailsCardProps = {
  selectedPlanVisit?: IOrderPlanVisit
}

export default ({ selectedPlanVisit }: PlanVisitDetailsCardProps): JSX.Element => {
  const { t } = useTranslation()

  const infoLines: CardLineProps[] = [
    {
      label: t('OrdersScreen.lastStatus'),
      value: t(`OrdersScreen.planVisitStatus.${selectedPlanVisit?.status}`),
    },
    {
      label: t('OrdersScreen.deliveryAddressLabel'),
      value: selectedPlanVisit?.deliveryAddressLabel,
    },
    {
      label: t('OrdersScreen.deliveryAddressFull'),
      value: selectedPlanVisit?.deliveryAddressFull,
    },
    {
      label: t('OrdersScreen.planedDate'),
      value: dateDefaultFormat(selectedPlanVisit?.plannedDate),
    },
    {
      label: t('tablesEntries.carrier'),
      value: GetCarrierNameFromDirectId({ id: selectedPlanVisit?.carrierId, displayPurpose: true }),
    },
    { label: t('OrdersScreen.tourNumber'), value: selectedPlanVisit?.tourNumber },
  ]

  return (
    <CustomCard
      dataCy="visitDetailsCard"
      cardTitle={t('OrdersScreen.detailsOnPlanVisitNumber', {
        planVisitNumber: selectedPlanVisit?.planVisitNumber,
      })}
    >
      {infoLines.map(({ label, value }) => (
        <CardLine key={label} label={label} value={value} />
      ))}
    </CustomCard>
  )
}
