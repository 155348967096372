import { IOrderHistory } from 'interfaces/IOrders'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { formatDeliveryTypes } from 'utils/functions'
import { getDeliveryTypeLabel } from 'utils/tableUtils'
import { ORDER_HISTORY_ROWS, TRANSPORT_TYPE_OPTIONS } from 'constants/constants'
import { dateDefaultFormat } from 'utils/dateFormat'
import clsx from 'clsx'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { formatDateInterval } from 'utils/libs/date-range'
import { AuthContext } from 'store/AuthContext'
import { ContentContext } from 'store/ContentContext'
import useStyles from './styles'

interface Props {
  orderUpdates: IOrderHistory[]
  open?: boolean
  close?: () => void
}

function OrderHistorySidebar({ orderUpdates, open = false, close }: Props): JSX.Element {
  const classes = useStyles()
  const { t } = useTranslation()
  const { user } = useContext(AuthContext)
  const userDeliveryTypes = formatDeliveryTypes(user?.tenantConfig?.deliveryTypes)
  const { carriers } = useContext(ContentContext)
  const checkIfUndefined = (value: string | number | undefined) => typeof value === 'undefined'

  const removeSameValueUpdates = (modifiedFields: IOrderHistory[], field: string) => {
    for (let i = 0; i < modifiedFields.length - 1; i += 1) {
      if (modifiedFields[i][field] === modifiedFields[i + 1][field]) {
        modifiedFields.splice(i, 1)
        i -= 1
      }
    }
    return modifiedFields
  }
  const getUpdatesByField = (field: string) =>
    orderUpdates.filter((e) => !checkIfUndefined(e[field]))

  const renderDeliveryType = (value: number) =>
    getDeliveryTypeLabel(userDeliveryTypes, value as number)

  const renderTransportType = (value: string) =>
    TRANSPORT_TYPE_OPTIONS.find((e) => e.id.toString() === value)?.name

  const renderTags = (value: string[]) => value?.join(' - ') || '-'

  const formatRender = (field: string, value: string | number | boolean | string[]) => {
    if (field === ORDER_HISTORY_ROWS.DELIVERY_TYPE) return renderDeliveryType(value as number)
    if (field === ORDER_HISTORY_ROWS.TRANSPORT_TYPE) return renderTransportType(value as string)
    if (field === ORDER_HISTORY_ROWS.TAGS) return renderTags(value as string[])
    if (field === ORDER_HISTORY_ROWS.MAIN_QUANTITY) return Math.abs(value as number)
    if (field === ORDER_HISTORY_ROWS.ESTIMATED_SERVICE_TIME) return Math.abs(value as number) / 60
    if (field === ORDER_HISTORY_ROWS.REQUESTED_CARRIER) return carriers.find((carrier) => carrier.id === value)?.name || ''
    if (typeof value === 'boolean') {
      return !value ? t('OrdersScreen.no') : t('OrdersScreen.yes')
    }
    return typeof value !== 'string' && typeof value !== 'number' ? '-' : value
  }

  const getTitle = (field: string): string => {
    if (field === ORDER_HISTORY_ROWS.DELIVERY_TIME_MIN) return t('OrdersScreen.deliveryTimeSlot')
    return t(`OrdersScreen.${field}`)
  }

  const adjustIndexAndFilter = (historyArray: IOrderHistory[], field: string) => {
    const reversedArray = [...historyArray].reverse()

    let lastIndex = -1
    if (field === ORDER_HISTORY_ROWS.TAGS) {
      lastIndex = reversedArray.findIndex((e) => Array.isArray(e[field]) && !!e[field].length)
    } else {
      lastIndex = reversedArray.findIndex((e) => e[field] !== null && e[field] !== undefined)
    }

    const adjustedIndex = lastIndex !== -1 ? reversedArray.length - lastIndex - 1 : -1
    if (adjustedIndex > -1) {
      return [...historyArray.filter((e, i) => i <= adjustedIndex)]
    }
    return []
  }

  const getUpdatesAfterFirstValue = (filtered: IOrderHistory[], field: string): IOrderHistory[] => [
    ...adjustIndexAndFilter([...filtered], field),
  ]

  const validateUpdates = (modifiedFields: IOrderHistory[], field: string, isNullable: boolean) => {
    let filtered: IOrderHistory[] = removeSameValueUpdates([...modifiedFields], field)
    if (field === ORDER_HISTORY_ROWS.DELIVERY_TIME_MIN) {
      filtered = modifiedFields
    }
    const values: IOrderHistory[] = getUpdatesAfterFirstValue(filtered, field)
    if (isNullable && values.length < 1) {
      return []
    }
    if (!isNullable && values.length < 2) return []
    return values
  }

  const handleRender = (field: string) => {
    const modifiedFields = getUpdatesByField(field)
    const isNullable = [
      ORDER_HISTORY_ROWS.REQUESTED_CARRIER,
      ORDER_HISTORY_ROWS.ENTREPRISE,
      ORDER_HISTORY_ROWS.ORDER_AMOUNT,
      ORDER_HISTORY_ROWS.ESTIMATED_WEIGHT,
      ORDER_HISTORY_ROWS.ORDER_INSTRUCTIONS,
      ORDER_HISTORY_ROWS.TAGS,
      ORDER_HISTORY_ROWS.DOOR_CODE,
      ORDER_HISTORY_ROWS.ADDITIONAL_ADDRESS_INFORMATION,
    ].includes(field)
    const validatedUpdates = validateUpdates(modifiedFields, field, isNullable)
    if (!validatedUpdates.length) return <></>
    return (
      <div className={classes.orderHistoryCard}>
        <h3>{getTitle(field)}</h3>
        {validatedUpdates.map((entry, index: number) => {
          if (
            !isNullable
            && field !== ORDER_HISTORY_ROWS.TAGS
            && index === validatedUpdates.length - 1
          ) return <></>
          return (
            <div className={classes.orderHistorySubCard}>
              <span>
                {t('OrdersScreen.ordersHistory.value')}:{' '}
                {field === ORDER_HISTORY_ROWS.DELIVERY_TIME_MIN
                  ? formatDateInterval(
                    entry.deliveryDateTimeMinimum,
                    entry.deliveryDateTimeMaximum,
                    true,
                  )
                  : formatRender(field, entry[field])}
              </span>
              <span>
                {t('OrdersScreen.ordersHistory.previousValue')}:{' '}
                {index !== validatedUpdates.length - 1 ? (
                  <>
                    {field === ORDER_HISTORY_ROWS.DELIVERY_TIME_MIN
                      ? formatDateInterval(
                        validatedUpdates[index + 1].deliveryDateTimeMinimum,
                        validatedUpdates[index + 1].deliveryDateTimeMaximum,
                        true,
                      )
                      : formatRender(field, validatedUpdates[index + 1][field])}
                  </>
                ) : (
                  ' - '
                )}
              </span>
              <span>
                {t('OrdersScreen.ordersHistory.updatedBy')}: {entry.user}
              </span>
              <span>
                {t('OrdersScreen.ordersHistory.date')}: {dateDefaultFormat(entry.creationDate)}
              </span>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div className={clsx(classes.orderHistoryContainer, open && classes.orderHistoryContainerOpen)}>
      <div className={classes.spaceBetween}>
        <h2>{t('OrdersScreen.ordersHistory.title')}</h2>
        <IconButton onClick={close}>
          <CloseIcon />
        </IconButton>
      </div>
      {handleRender(ORDER_HISTORY_ROWS.DELIVERY_TYPE)}
      {handleRender(ORDER_HISTORY_ROWS.TRANSPORT_TYPE)}
      {handleRender(ORDER_HISTORY_ROWS.TAGS)}
      {handleRender(ORDER_HISTORY_ROWS.ESTIMATED_SERVICE_TIME)}
      {handleRender(ORDER_HISTORY_ROWS.DELIVERY_ADDRESS)}
      {handleRender(ORDER_HISTORY_ROWS.FLOOR)}
      {handleRender(ORDER_HISTORY_ROWS.ELEVATOR)}
      {handleRender(ORDER_HISTORY_ROWS.DOOR_CODE)}
      {handleRender(ORDER_HISTORY_ROWS.ADDITIONAL_ADDRESS_INFORMATION)}
      {handleRender(ORDER_HISTORY_ROWS.ORDER_INSTRUCTIONS)}
      {handleRender(ORDER_HISTORY_ROWS.DELIVERY_TIME_MIN)}
      {handleRender(ORDER_HISTORY_ROWS.REQUESTED_CARRIER)}
      {handleRender(ORDER_HISTORY_ROWS.ESTIMATED_WEIGHT)}
      {handleRender(ORDER_HISTORY_ROWS.MAIN_QUANTITY)}
    </div>
  )
}

export default OrderHistorySidebar
