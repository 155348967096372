import React from 'react'
import { COLUMNS_MODEL_VEHICLE_TYPES_LIST } from 'constants/table'
import { IVehicleType } from 'interfaces/IVehicleType'
import VehicleTypesTable from 'components/Table/VehicleTypesTable'
import VehicleTypeProvider, { VehicleTypesConsumer } from 'store/VehicleTypesContext'

interface IProps {
  vehicleTypes: IVehicleType[]
}

const VehicleTypeListScreen = ({ vehicleTypes }: IProps): JSX.Element => (
  <VehicleTypesTable vehicleTypes={vehicleTypes} columns={COLUMNS_MODEL_VEHICLE_TYPES_LIST} />
)

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <VehicleTypeProvider>
    <VehicleTypesConsumer>
      {(ctx): JSX.Element => <VehicleTypeListScreen vehicleTypes={ctx.vehicleTypes} {...props} />}
    </VehicleTypesConsumer>
  </VehicleTypeProvider>
)
