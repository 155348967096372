import React, { useState, useContext } from 'react'
import { Tabs, Tab, IconButton } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import { useTranslation } from 'react-i18next'

import TabPanel from 'components/Tab/TabPanel'
import useStyles from 'constants/cruStyles'
import { IRoutingSegment } from 'interfaces/IRoutingRule'
import RoutingSegmentForm from './RoutingSegmentForm'
import { RoutingRulesContext } from './RoutingStore'

interface IProps {
  routingSegments?: IRoutingSegment[]
  onRoutingSegmentItemChange: (routingSegments: IRoutingSegment[]) => void
}

const RoutingSegmentsTab = ({
  routingSegments,
  onRoutingSegmentItemChange,
}: IProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0)
  const { editMode } = useContext(RoutingRulesContext)

  const handleTabChange = (_event: React.ChangeEvent<{}>, newTabIndex: number): void => {
    setCurrentTabIndex(newTabIndex)
  }

  const handleRoutingSegmentTabAdd = (): void => {
    setCurrentTabIndex(routingSegments?.length || 0)
    const newRoutingSegments = [...(routingSegments || []), { toOptimize: true } as IRoutingSegment]
    onRoutingSegmentItemChange(newRoutingSegments)
  }

  const handleRoutingSegmentTabDelete = (): void => {
    const newRoutingSegments = [...(routingSegments || [])]
    newRoutingSegments.splice(currentTabIndex, 1)
    onRoutingSegmentItemChange(newRoutingSegments)
    setCurrentTabIndex(currentTabIndex - 1)
  }

  const handleRoutingSegmentChange = (
    changedRoutingSegment: IRoutingSegment,
    index: number,
  ): void => {
    const newRoutingSegments = [...(routingSegments || [])]
    newRoutingSegments[index] = changedRoutingSegment
    onRoutingSegmentItemChange(newRoutingSegments)
  }

  return (
    <>
      <div className={classes.ruleTabsContainer}>
        <Tabs
          aria-label="rules tabs"
          value={currentTabIndex}
          variant="scrollable"
          scrollButtons="auto"
          onChange={handleTabChange}
          className={classes.ruleTab}
        >
          {routingSegments
            && routingSegments.length > 0
            && routingSegments?.map((_routingSegment, index) => (
              <Tab
                // eslint-disable-next-line react/no-array-index-key
                key={`tab-${index}`}
                label={`${t('RoutingRulesScreen.segment')} 
              ${index + 1}`}
                data-cy={`${t('RoutingRulesScreen.segment')} ${index + 1}`}
              />
            ))}
        </Tabs>
        {editMode && (
          <div className={classes.tabActions}>
            <IconButton data-cy="addButton" onClick={handleRoutingSegmentTabAdd}>
              <AddIcon />
            </IconButton>
            <IconButton data-cy="deleteButton" onClick={handleRoutingSegmentTabDelete}>
              <DeleteIcon />
            </IconButton>
          </div>
        )}
      </div>
      {routingSegments
        && routingSegments?.length > 0
        && routingSegments?.map((routingSegment, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <TabPanel key={`tabpanel-${index}`} value={currentTabIndex} index={index}>
            <RoutingSegmentForm
              routingSegment={routingSegment}
              onRoutingSegmentItemChange={(newRoutingSegment) =>
                handleRoutingSegmentChange(newRoutingSegment, index)}
            />
          </TabPanel>
        ))}
    </>
  )
}

export default RoutingSegmentsTab
