import Api from 'services/api'
import { IError } from 'api/types'
import { IDispute, IDisputeFilters, IDisputeReasonCode } from 'interfaces/IDispute'

const getDisputeReasonCodes = async (): Promise<IDisputeReasonCode[] | IError> => {
  try {
    const response = await Api.get(`tenants/disputeReasonCodes`)
    return response
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const searchDisputes = async (
  queryParams: IDisputeFilters,
  offset?: number,
  rowsPerPage?: number,
  sortBy?: string,
  sortDirection?: number,
): Promise<{ disputes: Array<IDispute>; count: number } | IError> => {
  try {
    const response = await Api.get(`disputes/`, {
      ...queryParams,
      offset,
      limit: rowsPerPage,
      sortBy,
      sortDirection,
    })
    return {
      disputes: response.items,
      count: response.count,
    }
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

const updateDisputeReasonCodes = async (
  disputeReasonCodes: IDisputeReasonCode[],
): Promise<IDisputeReasonCode[] | IError> => {
  try {
    const response = await Api.patch(`tenants/disputeReasonCodes`, {
      disputeReasonCodes,
    })
    return response
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

export default {
  searchDisputes,
  getDisputeReasonCodes,
  updateDisputeReasonCodes,
}
