import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core'
import styled from 'styled-components'

import { ISortingPrams } from 'interfaces/interfaces'
import { ContentContext } from 'store/ContentContext'

interface IProps {
  sortingPrams: ISortingPrams
  onChange: (name: string, value: string) => void
}

const Container = styled.div`
  margin-top: 32px;
`

const StyledInputLabel = styled(InputLabel)`
  margin-left: 8px;
`

const StyledSelect = styled(Select)`
  margin-left: 8px;
  min-width: 140px;
`

const SortingSelectors = ({ sortingPrams, onChange }: IProps): JSX.Element => {
  const { t } = useTranslation()

  const { sites } = useContext(ContentContext)

  return (
    <Container>
      <FormControl variant="outlined">
        <StyledInputLabel>{t('PlanningScreen.sortBy')}</StyledInputLabel>
        <StyledSelect
          label={t('PlanningScreen.sortBy')}
          value={sortingPrams.sortBy}
          onChange={(event): void => onChange('sortBy', event.target.value as string)}
        >
          <MenuItem value="">{t('PlanningScreen.none')}</MenuItem>
          <MenuItem value="distance">{t('PlanningScreen.distance')}</MenuItem>
        </StyledSelect>
      </FormControl>
      {sortingPrams.sortBy && (
        <FormControl variant="outlined" required>
          <StyledInputLabel>{t('PlanningScreen.destination')}</StyledInputLabel>
          <StyledSelect
            label={t('PlanningScreen.destination')}
            value={sortingPrams.destinationSiteId}
            onChange={(event): void => onChange('destinationSiteId', event.target.value as string)}
          >
            {sites?.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </StyledSelect>
        </FormControl>
      )}
    </Container>
  )
}

export default SortingSelectors
