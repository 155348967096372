import React from 'react'
import { IWarehouseCartDropoffConfig } from 'interfaces/IWarehouseCartDropoffConfig'
import Card from 'components/Card/Card'
import { FieldConfig } from './FieldConfig'
import useStyles from './styles'

interface IProps {
  data: IWarehouseCartDropoffConfig
  onChange: (fieldName: string, value: unknown, propertyName?: string) => void
  fields: FieldDetails[]
  sectionName: string
}

export interface FieldDetails {
  component?: JSX.Element
  name: string
  label?: string
  subSection?: FieldDetails[]
  disabled?: boolean
}

export const ConfigCard = ({ data, onChange, fields, sectionName }: IProps): JSX.Element => {
  const classes = useStyles()

  return (
    <Card title={sectionName.toUpperCase()} dataCy="orderCard">
      <div className={classes.container}>
        {fields.map((elem) => (
          <div key={elem.name}>
            {!elem.subSection && (
              <FieldConfig
                active={data[elem.name]?.active || false}
                onChange={onChange}
                name={elem.name}
                disabled={elem.disabled}
              >
                {elem.component}
              </FieldConfig>
            )}
            {elem.subSection && (
              <div className={classes.subSection}>
                <div className={classes.sectionName}>{elem.label?.toUpperCase() || ''}</div>
                <div className={classes.subContainer}>
                  {elem.subSection.map((item) => (
                    <FieldConfig
                      active={data[item.name]?.active || false}
                      onChange={onChange}
                      name={item.name}
                      key={item.name}
                      disabled={item.disabled}
                    >
                      {item.component}
                    </FieldConfig>
                  ))}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </Card>
  )
}
