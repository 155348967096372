import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import OrdersApi from 'api/orders'
import { isIError } from 'api/types'
import { CardLineSaveObject } from 'components/CardLine/CardLine'
import CustomCard from 'components/CustomCard/CustomCard'
import {
  OrderPlanStatus,
  PILOT_AND_STORE_USER_ROLES,
  TRANSPORT_TYPE_OPTIONS,
  TransportType,
  USER_ROLES_WITH_ORDER_MODULE,
} from 'constants/constants'
import { ICarrier } from 'interfaces/ICarrier'
import { IOrderDetails } from 'interfaces/IOrders'
import { AuthContext } from 'store/AuthContext'
import { ContentContext } from 'store/ContentContext'
import { FeedbackContext } from 'store/FeedbackContext'
import { isDateEqual } from 'utils/dateFormat'
import { formatDeliveryTypes, isDefined } from 'utils/functions'
import { getDeliveryTypeLabel, getTransportTypeName } from 'utils/tableUtils'
import { formatDateInterval } from 'utils/libs/date-range'
import { convertToMinutes } from 'utils/libs/time-conversion'

import { TagsContext } from 'store/TagsContext'
import { ITag } from 'interfaces/ITag'
import AddressApi from 'api/address'
import { useDebouncedCallback } from 'use-debounce'
import { IAutocompleteResult } from 'interfaces/IAutocompleteResult'
import { IAddress } from 'interfaces/Itours'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import { Tooltip } from '@material-ui/core'
import { ICustomerUpdate } from 'interfaces/ICustomer'

type OrderCardProps = {
  orderDetails?: IOrderDetails
  setOrderDetails?: (oderDetails: IOrderDetails) => void
  openHistory?: () => void
  isOrderHistoryHidden?: boolean
}

export default ({
  orderDetails,
  setOrderDetails,
  openHistory,
  isOrderHistoryHidden = true,
}: OrderCardProps): JSX.Element => {
  const { t } = useTranslation()
  const { tags, getTags } = useContext(TagsContext)
  const [details, setDetails] = useState<IOrderDetails>()
  useEffect(() => {
    if (!tags && user) getTags(user.tenantId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const { user } = useContext(AuthContext)
  const { sites, carriers } = useContext(ContentContext)
  const { openSuccessSnack, openErrorSnack, toggleLoader } = useContext(FeedbackContext)

  const [addressChoices, setAddressChoices] = useState<IAutocompleteResult[]>([])
  const [requestedCarrierName, setRequestedCarrierName] = useState<string | undefined>(undefined)
  const userDeliveryTypes = formatDeliveryTypes(user?.tenantConfig?.deliveryTypes)
  const [deliveryType, setDeliveryType] = useState<number>(details?.deliveryType || 0)
  const getTransportTypeIndex = (typeToFind: TransportType | string | undefined) => {
    const type = TRANSPORT_TYPE_OPTIONS.findIndex((e) => e.id === typeToFind)
    return type > -1 ? type : 0
  }
  const [transportType, setTransportType] = useState<number>(
    getTransportTypeIndex(details?.transportType),
  )

  useEffect(() => {
    setDeliveryType(orderDetails?.deliveryType || 0)
    setTransportType(getTransportTypeIndex(orderDetails?.transportType))
    setDetails(orderDetails)
  }, [orderDetails])

  const userDeliveryTypesSelect = userDeliveryTypes.map((type) => ({
    id: type.code,
    name: type.label,
  })) || []
  const tagsSelectOptions = tags?.map((tag) => ({
    id: tag.code || '',
    name: tag.label || '',
  })) || []

  const isEstimatedServiceTimeEditable = useMemo((): boolean => {
    // eslint-disable-next-line max-len
    const isEditServiceTimeEnabled = sites?.find((site) => details?.warehouseId === site.id)?.orderScreen?.editServiceTime || false

    const isPilotOrStore = user?.roles?.some((userRole) =>
      PILOT_AND_STORE_USER_ROLES.some((role) => role === userRole),
    )

    return !!(isEditServiceTimeEnabled && isPilotOrStore)
  }, [sites, user, details])

  useEffect(() => {
    if (carriers && carriers.length > 0 && orderDetails?.carrierId) {
      const requestedCarrier: ICarrier | undefined = carriers.find(
        (e) => e.id === orderDetails?.carrierId,
      )
      setRequestedCarrierName(requestedCarrier?.name)
    }
  }, [carriers, orderDetails])

  const isEditDisabled = useMemo(
    (): boolean =>
      details?.planStatus === undefined
      || ![
        OrderPlanStatus.ToBook,
        OrderPlanStatus.Unplanned,
        OrderPlanStatus.PartiallyPlanned,
        OrderPlanStatus.Draft,
      ].includes(details?.planStatus),
    [details],
  )

  const disabledOrderInfoEditMessage = useMemo(
    (): string =>
      details?.planStatus === OrderPlanStatus.Canceled
        ? t('OrdersScreen.messages.disabledEditOrderInfoMessageCancelled')
        : t('OrdersScreen.messages.disabledEditOrderInfoMessagePlanned'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [details],
  )

  const handleSaveEstimatedServiceTime = async (estimatedServiceTime?: string) => {
    if (orderDetails?.orderId) {
      const estimatedServiceTimeToNumber = estimatedServiceTime
        ? Number(estimatedServiceTime)
        : null

      if (
        estimatedServiceTimeToNumber
        && (estimatedServiceTimeToNumber < 0 || estimatedServiceTimeToNumber % 1 !== 0)
      ) {
        openErrorSnack(t('OrdersScreen.messages.estimatedServiceTimeValidation'))
      } else {
        const res = await OrdersApi.update(orderDetails?.orderId, {
          estimatedServiceTime:
            estimatedServiceTimeToNumber !== null ? estimatedServiceTimeToNumber * 60 : null,
        })
        if (isIError(res)) {
          openErrorSnack(res.error.message)
        } else if (res) {
          if (setOrderDetails) {
            setOrderDetails({
              ...orderDetails,
              estimatedServiceTime: res.estimatedServiceTime,
            })
          }
          openSuccessSnack(t('OrdersScreen.messages.estimatedTimeUpdateSuccess'))
        }
      }
    }
  }

  const isDeliveryTimeEditable = useMemo((): boolean => {
    const isEditDeliveryTimeEnabled = sites?.find((site) => details?.warehouseId === site.id)?.orderScreen?.editDeliveryTime
      || false

    const hasAccessToEditOrder = user?.roles?.some((role) =>
      USER_ROLES_WITH_ORDER_MODULE.includes(role),
    )

    return !!(isEditDeliveryTimeEnabled && hasAccessToEditOrder)
  }, [sites, user, details])

  const handleSaveDeliveryTime = async (data: CardLineSaveObject) => {
    if (orderDetails?.orderId) {
      const res = await OrdersApi.update(orderDetails?.orderId, {
        deliveryDateTimeMinimum: data.dateTimeMinimum,
        deliveryDateTimeMaximum: data.dateTimeMaximum,
      })
      if (isIError(res)) {
        openErrorSnack(res.error.message)
      } else if (res) {
        if (setOrderDetails) {
          setOrderDetails({
            ...orderDetails,
            deliveryDateTimeMinimum: res.deliveryDateTimeMinimum,
            deliveryDateTimeMaximum: res.deliveryDateTimeMaximum,
          })
        }
        openSuccessSnack(t('OrdersScreen.messages.deliveryTimeUpdateSuccess'))
      }
    }
  }

  const handleSaveDeliveryInstructions = async (deliveryInstructions?: string) => {
    if (orderDetails?.orderId) {
      const res = await OrdersApi.update(orderDetails?.orderId, {
        instructions: deliveryInstructions,
      })
      if (isIError(res)) {
        openErrorSnack(res.error.message)
      } else if (res) {
        if (setOrderDetails) {
          setOrderDetails({
            ...orderDetails,
            deliveryInstructions: res.deliveryInstructions,
          })
        }
        openSuccessSnack(t('OrdersScreen.messages.deliveryInstructionsUpdateSuccess'))
      }
    }
  }

  const handleSaveFloor = async (floor?: string) => {
    if (orderDetails?.orderId) {
      const floorToNumber = floor ? Number(floor) : undefined

      if (floorToNumber !== undefined && floorToNumber % 1 !== 0) {
        openErrorSnack(t('OrdersScreen.messages.orderDeliveryFloorValidation'))
      } else {
        const res = await OrdersApi.update(orderDetails?.orderId, {
          deliveryAddress: {
            floor: floorToNumber,
          },
        })
        if (isIError(res)) {
          openErrorSnack(res.error.message)
        } else if (res) {
          if (setOrderDetails) {
            setOrderDetails({
              ...orderDetails,
              deliveryFloor: res.deliveryFloor,
            })
          }
          openSuccessSnack(t('OrdersScreen.messages.deliveryFloorUpdateSuccess'))
        }
      }
    }
  }

  const handleSaveElevator = async (hasElevator?: boolean) => {
    if (orderDetails?.orderId) {
      const res = await OrdersApi.update(orderDetails?.orderId, {
        deliveryAddress: {
          hasElevator,
        },
      })
      if (isIError(res)) {
        openErrorSnack(res.error.message)
      } else if (res) {
        if (setOrderDetails) {
          setOrderDetails({
            ...orderDetails,
            deliveryIsElevatorPresent: res.deliveryIsElevatorPresent,
          })
        }
        openSuccessSnack(t('OrdersScreen.messages.deliveryIsElevatorPresentUpdateSuccess'))
      }
    }
  }

  const handleSaveDoorCode = async (doorCode?: string) => {
    if (orderDetails?.orderId) {
      const res = await OrdersApi.update(orderDetails?.orderId, {
        deliveryAddress: {
          doorCode,
        },
      })
      if (isIError(res)) {
        openErrorSnack(res.error.message)
      } else if (res) {
        if (setOrderDetails) {
          setOrderDetails({
            ...orderDetails,
            deliveryDoorCode: res.deliveryDoorCode,
          })
        }
        openSuccessSnack(t('OrdersScreen.messages.deliveryDoorCodeUpdateSuccess'))
      }
    }
  }

  const handleSavePhoneNumber = async (customerPhoneNumber?: string) => {
    if (orderDetails?.orderId && customerPhoneNumber) {
      const customer: ICustomerUpdate = {
        firstName: orderDetails?.customerFirstName,
        lastName: orderDetails?.customerLastName,
        email: orderDetails?.customerEmail,
        phoneNumber: customerPhoneNumber,
      }
      const res = await OrdersApi.update(orderDetails?.orderId, { customer })
      if (isIError(res)) {
        openErrorSnack(res.error.message)
      } else if (res) {
        if (setOrderDetails) {
          setOrderDetails({
            ...orderDetails,
            customerPhoneNumber: customerPhoneNumber || '',
          })
        }
        openSuccessSnack(t('OrdersScreen.messages.phoneNumberUpdateSuccess'))
      }
    }
  }

  const handleSaveOrderAmount = async (orderAmount?: string) => {
    if (orderDetails?.orderId) {
      const res = await OrdersApi.update(orderDetails?.orderId, {
        orderAmount,
      })
      if (isIError(res)) {
        openErrorSnack(res.error.message)
      } else if (res) {
        if (setOrderDetails) {
          setOrderDetails({
            ...orderDetails,
            orderAmount: res.orderAmount,
          })
        }
        openSuccessSnack(t('OrdersScreen.messages.orderAmountUpdateSuccess'))
      }
    }
  }

  const handleSaveEstimatedWeight = async (estimatedWeight?: string) => {
    if (orderDetails?.orderId) {
      const estimatedWeightToNumber = estimatedWeight ? Number(estimatedWeight) : null

      const res = await OrdersApi.update(orderDetails?.orderId, {
        estimatedWeight: estimatedWeightToNumber,
      })
      if (isIError(res)) {
        if (res.error.code === 400) {
          openErrorSnack(t('OrdersScreen.messages.estimatedWeightValidation'))
        } else {
          openErrorSnack(res.error.message)
        }
      } else if (res) {
        if (setOrderDetails) {
          setOrderDetails({
            ...orderDetails,
            estimatedWeight: res.estimatedWeight,
          })
        }
        openSuccessSnack(t('OrdersScreen.messages.estimatedWeightUpdateSuccess'))
      }
    }
  }

  const handleSaveMainQuantity = async (mainQuantity?: string) => {
    if (orderDetails?.orderId && typeof mainQuantity !== 'undefined') {
      const mainQuantityUpdate = handleMainQuantity(orderDetails.transportType, mainQuantity)
      const res = await OrdersApi.update(orderDetails?.orderId, {
        mainQuantity: mainQuantityUpdate,
      })
      if (isIError(res)) {
        openErrorSnack(res.error.message)
      } else if (res) {
        if (setOrderDetails) {
          setOrderDetails({
            ...orderDetails,
            mainQuantity: res.mainQuantity,
          })
        }
        openSuccessSnack(t('OrdersScreen.messages.mainQuantityUpdateSuccess'))
      }
    }
  }

  const handleSaveOrderInstructions = async (orderInstructions?: string) => {
    if (orderDetails?.orderId) {
      const res = await OrdersApi.update(orderDetails?.orderId, {
        orderInstructions,
      })
      if (isIError(res)) {
        openErrorSnack(res.error.message)
      } else if (res) {
        if (setOrderDetails) {
          setOrderDetails({
            ...orderDetails,
            orderInstructions: res.orderInstructions,
          })
        }
        openSuccessSnack(t('OrdersScreen.messages.orderInstructionsUpdateSuccess'))
      }
    }
  }

  const handleMainQuantity = (transportTypeAsString: string, mainQuantityValue: string): number => {
    let value: number = parseInt(mainQuantityValue, 10)
    if (transportTypeAsString === TransportType.Pickup) {
      if (value > 0) {
        value *= -1
      }
    } else {
      if (value < 0) {
        value *= -1
      }
    }
    return value
  }

  const handleSaveTransportType = async () => {
    if (orderDetails?.orderId) {
      const mainQuantityValue: number = handleMainQuantity(
        TRANSPORT_TYPE_OPTIONS[transportType].id,
        orderDetails.mainQuantity,
      )
      const res = await OrdersApi.update(orderDetails?.orderId, {
        transportType: TRANSPORT_TYPE_OPTIONS[transportType].id,
        mainQuantity: mainQuantityValue,
      })
      if (isIError(res)) {
        openErrorSnack(res.error.message)
      } else if (res) {
        if (setOrderDetails) {
          setOrderDetails({
            ...orderDetails,
            transportType: TRANSPORT_TYPE_OPTIONS[transportType].id,
          })
        }
        openSuccessSnack(t('OrdersScreen.messages.transportTypeUpdateSuccess'))
      }
    }
  }

  const handleSaveDeliveryType = async (value: number) => {
    if (orderDetails?.orderId) {
      const res = await OrdersApi.update(orderDetails?.orderId, {
        deliveryType: value,
      })
      if (isIError(res)) {
        openErrorSnack(res.error.message)
      } else if (res) {
        if (setOrderDetails) {
          setOrderDetails({ ...orderDetails, deliveryType: value })
        }
        openSuccessSnack(t('OrdersScreen.messages.deliveryTypeUpdateSuccess'))
      }
    }
  }

  const handleSaveTags = async (name: string, value: string[]) => {
    if (orderDetails?.orderId) {
      const res = await OrdersApi.update(orderDetails?.orderId, {
        [name]: value,
      })
      if (isIError(res)) {
        openErrorSnack(res.error.message)
      } else if (res) {
        if (setOrderDetails) {
          setOrderDetails({ ...orderDetails, tags: getAsTags(value) })
        }
        openSuccessSnack(t('OrdersScreen.messages.tagsUpdateSuccess'))
      }
    }
  }
  const handleSaveCarrierId = async (name: string, value: string) => {
    if (orderDetails?.orderId) {
      const res = await OrdersApi.update(orderDetails?.orderId, {
        [name]: value,
      })
      if (isIError(res)) {
        openErrorSnack(res.error.message)
      } else if (res) {
        if (setOrderDetails) {
          setOrderDetails({ ...orderDetails, carrierId: value })
        }
        openSuccessSnack(t('OrdersScreen.messages.carrierUpdateSuccess'))
      }
    }
  }
  const handleSaveEnterpriseLabel = async (name: string, value: string) => {
    if (orderDetails?.orderId) {
      const res = await OrdersApi.update(orderDetails?.orderId, {
        [name]: value,
      })
      if (isIError(res)) {
        openErrorSnack(res.error.message)
      } else if (res) {
        if (setOrderDetails) {
          setOrderDetails({ ...orderDetails, enterpriseLabel: value })
        }
        openSuccessSnack(t('OrdersScreen.messages.enterpriseLabelUpdateSuccess'))
      }
    }
  }

  const getAsTags = (tagsArray: string[]): ITag[] =>
    tags?.filter((tag) => tagsArray.includes(tag.code || '')) || []

  const handleChangeTags = (value: string[]) => {
    if (details?.orderId) {
      const data: ITag[] = getAsTags(value)
      setDetails({
        ...details,
        tags: data,
      })
    }
  }

  const handleChange = (name: string, value: string | number) => {
    if (details?.orderId) {
      setDetails({
        ...details,
        [name]: value,
      })
    }
  }

  const getTagsIds = (): string[] =>
    details?.tags
      ?.filter((tag) => tag.code)
      .map((tag) => tag.code || '')
      .filter((val) => val) || []

  const handleAddressChange = (value: string): void => {
    if (details?.orderNumber) {
      handleChange('deliveryAddress', value)
      if (value?.length < 7) {
        return setAddressChoices([])
      }
      handleAutocomplete()
    }
  }

  const debouncedAddressAutocomplete = useDebouncedCallback(async () => {
    if (!details) return
    if (details?.deliveryAddress?.length > 7) {
      const res = await AddressApi.autocompleteAddress(
        details.deliveryAddress,
        details.warehouseId,
        user?.tenantConfig.language,
      )
      if (isIError(res)) {
        openErrorSnack(res.error.message)
      } else {
        setAddressChoices(res)
      }
      toggleLoader(false)
    }
  }, 1000)

  const handleAutocomplete = async (): Promise<void> => {
    debouncedAddressAutocomplete.callback()
  }

  const makeAChoice = (addressChoice: IAutocompleteResult): void => {
    confirmAddressCompletion(addressChoice)
    setAddressChoices([])
  }

  const confirmAddressCompletion = (addressChoice: IAutocompleteResult): void => {
    let formattedAddressLabel = addressChoice.address.label
    const addressLabelParts = formattedAddressLabel?.split(',')
    if (addressLabelParts) {
      const lastPart = addressLabelParts[addressLabelParts.length - 1]
      if (lastPart.trim() === addressChoice.address.country) {
        addressLabelParts.pop()
        formattedAddressLabel = addressLabelParts.join()
      }
    }
    if (details?.orderNumber) {
      setDetails({
        ...details,
        deliveryAddress: formattedAddressLabel || '',
        deliveryAddressNumber: addressChoice.address.roadNumber || '',
        deliveryAddressRoad: addressChoice.address.road || '',
        deliveryAddressZipCode: addressChoice.address.zipCode || '',
        deliveryAddressCity: addressChoice.address.city || '',
        deliveryAddressCountry: addressChoice.address.country || '',
      })
    }
  }

  const handleAddressSave = async (): Promise<void> => {
    if (details?.orderId) {
      const updatedAddress: Partial<IAddress> = {
        full: details.deliveryAddress,
        roadNumber: details.deliveryAddressNumber,
        road: details.deliveryAddressRoad,
        zipCode: details.deliveryAddressZipCode,
        city: details.deliveryAddressCity,
        country: details.deliveryAddressCountry,
      }
      const res = await OrdersApi.update(details?.orderId, {
        deliveryAddress: updatedAddress,
      })
      if (isIError(res)) {
        openErrorSnack(res.error.message)
      } else if (res) {
        if (setOrderDetails && orderDetails?.orderId) {
          setOrderDetails({
            ...orderDetails,
            deliveryAddress: res?.fullAddress || '',
            deliveryAddressNumber: res.deliveryAddressNumber,
            deliveryAddressRoad: res.deliveryAddressRoad,
            deliveryAddressZipCode: res.deliveryAddressZipCode,
            deliveryAddressCity: res.deliveryAddressCity,
            deliveryAddressCountry: res.deliveryAddressCountry,
          })
        }
        openSuccessSnack(t('OrdersScreen.messages.deliveryAddressUpdateSuccess'))
      }
    }
  }

  return (
    <CustomCard
      dataCy="orderCard"
      cardTitle={t('OrdersScreen.orderInformation')}
      icon={
        !isOrderHistoryHidden ? (
          <Tooltip title={t('OrdersScreen.ordersHistory.tooltip') || ''}>
            <AccessTimeIcon />
          </Tooltip>
        ) : undefined
      }
      onIconClick={() => openHistory?.()}
      linePairs={[
        { label: t('tablesEntries.warehouses'), value: orderDetails?.warehouseName },
        {
          label: t('tablesEntries.customer'),
          value: `${orderDetails?.customerFirstName} ${orderDetails?.customerLastName}`,
        },
        {
          label: t('tablesEntries.phoneNumber'),
          value: orderDetails?.customerPhoneNumber,
          isEditable: true,
          onSave: handleSavePhoneNumber,
        },
        {
          label: t('OrdersScreen.deliveryType'),
          value: getDeliveryTypeLabel(userDeliveryTypes, orderDetails?.deliveryType),
          isEditable: true,
          type: 'select',
          selectOptions: userDeliveryTypesSelect as { id: number; name: string }[],
          selectValue: deliveryType.toString() || '',
          onSelectChange: (e) => setDeliveryType(e.target.value as number),
          onSave: async () => {
            await handleSaveDeliveryType(deliveryType)
          },
        },
        {
          label: t('OrdersScreen.transportType'),
          value: getTransportTypeName(orderDetails?.transportType),
          isEditable: true,
          type: 'select',
          selectOptions: TRANSPORT_TYPE_OPTIONS,
          selectValue: TRANSPORT_TYPE_OPTIONS[transportType].id || '',
          onSelectChange: (e) => setTransportType(getTransportTypeIndex(e.target.value as string)),
          onSave: async () => {
            await handleSaveTransportType()
          },
        },
        {
          label: t('OrdersScreen.tags'),
          isEditable: true,
          type: 'select',
          value: orderDetails?.tags?.map((tag) => tag.label)?.join(', ') || '',
          selectValues: details?.tags?.map((tag) => tag.code || ''),
          selectOptions: tagsSelectOptions,
          isMultiSelect: true,
          onSelectChange: (e) => handleChangeTags(e.target.value as string[]),
          onSave: () => handleSaveTags('tags', getTagsIds()),
        },
        {
          label: t('OrdersScreen.estimatedServiceTime'),
          value: convertToMinutes(orderDetails?.estimatedServiceTime)?.toString() || '',
          isEditable: isEstimatedServiceTimeEditable,
          isEditDisabled,
          disabledMessage: disabledOrderInfoEditMessage,
          type: 'number',
          onSave: handleSaveEstimatedServiceTime,
        },
        {
          label: t('OrdersScreen.deliveryAddress'),
          value: orderDetails?.deliveryAddress,
          isEditable: true,
          type: 'autocomplete',
          onChange: handleAddressChange,
          autocompleteValue: details?.deliveryAddress,
          autoCompleteChoices: addressChoices,
          autoCompleteChoiceClick: (e) => makeAChoice(e),
          onSave: handleAddressSave,
        },
        {
          label: t('OrdersScreen.deliveryFloor'),
          type: 'number',
          value: isDefined(orderDetails?.deliveryFloor)
            ? orderDetails?.deliveryFloor?.toString()
            : '',
          isEditable: true,
          onSave: handleSaveFloor,
        },
        {
          label: t('OrdersScreen.deliveryIsElevatorPresent'),
          type: 'boolean',
          checked: !!orderDetails?.deliveryIsElevatorPresent,
          isEditable: true,
          onSaveSwitch: handleSaveElevator,
        },
        {
          label: t('OrdersScreen.deliveryDoorCode'),
          value: orderDetails?.deliveryDoorCode || '',
          isEditable: true,
          onSave: handleSaveDoorCode,
        },
        {
          label: t('OrdersScreen.deliveryInstructions'),
          value: orderDetails?.deliveryInstructions || '',
          isEditable: true,
          onSave: handleSaveDeliveryInstructions,
        },
        {
          label: t('OrdersScreen.orderInstructions'),
          value: orderDetails?.orderInstructions || '',
          isEditable: true,
          onSave: handleSaveOrderInstructions,
        },
        {
          label: t('OrdersScreen.initialDeliveryTimeSlot'),
          value: formatDateInterval(
            orderDetails?.initialDeliveryDateTimeMinimum,
            orderDetails?.initialDeliveryDateTimeMaximum,
            true,
          ),
          hidden:
            !orderDetails?.initialDeliveryDateTimeMinimum
            || !orderDetails?.initialDeliveryDateTimeMaximum
            || (isDateEqual(
              orderDetails?.initialDeliveryDateTimeMinimum,
              orderDetails?.deliveryDateTimeMinimum,
            )
              && isDateEqual(
                orderDetails?.initialDeliveryDateTimeMaximum,
                orderDetails?.deliveryDateTimeMaximum,
              )),
        },
        {
          label: t('OrdersScreen.deliveryTimeSlot'),
          value: formatDateInterval(
            orderDetails?.deliveryDateTimeMinimum,
            orderDetails?.deliveryDateTimeMaximum,
            true,
          ),
          isEditable: isDeliveryTimeEditable,
          isEditDisabled,
          disabledMessage: disabledOrderInfoEditMessage,
          type: 'DateTimeMinMax',
          metaData: {
            dateTimeMinimum: orderDetails?.deliveryDateTimeMinimum,
            dateTimeMaximum: orderDetails?.deliveryDateTimeMaximum,
          },
          onSaveObject: handleSaveDeliveryTime,
          dateTimeStartLabel: t('OrdersScreen.deliveryDateTimeMinimum'),
          dateTimeEndLabel: t('OrdersScreen.deliveryDateTimeMaximum'),
          dateTimeMinErrorMessage: t('OrdersScreen.messages.deliveryTimeMinimumValidationFailed'),
          dateTimeMaxErrorMessage: t('OrdersScreen.messages.deliveryTimeMaximumValidationFailed'),
        },
        {
          label: t('OrdersScreen.requestedCarrier'),
          isEditable: true,
          type: 'select',
          value: requestedCarrierName || '',
          selectValue: carriers.find((e) => e.id === details?.carrierId)?.id,
          selectOptions: carriers.filter((e) => e.active),
          onSelectChange: (e) => handleChange('carrierId', e.target.value as string),
          onSave: () => handleSaveCarrierId('carrierId', details?.carrierId || ''),
        },
        {
          label: t('OrdersScreen.enterprise'),
          value: orderDetails?.enterpriseLabel || '',
          isEditable: true,
          type: 'string',
          onSave: (value) => handleSaveEnterpriseLabel('enterpriseLabel', value || ''),
        },
        {
          label: t('OrdersScreen.orderAmount'),
          type: 'number',
          value: orderDetails?.orderAmount ?? '',
          isEditable: true,
          isEditDisabled,
          isAbsoluteNumber: true,
          disabledMessage: disabledOrderInfoEditMessage,
          onSave: handleSaveOrderAmount,
        },
        {
          label: t('OrdersScreen.estimatedWeightWithoutUnit'),
          type: 'number',
          value: isDefined(orderDetails?.estimatedWeight)
            ? orderDetails?.estimatedWeight?.toString()
            : '',
          isEditable: true,
          isEditDisabled,
          disabledMessage: disabledOrderInfoEditMessage,
          onSave: handleSaveEstimatedWeight,
        },
        {
          label: t('OrdersScreen.mainQuantity'),
          type: 'number',
          value:
            isDefined(orderDetails?.mainQuantity)
            && orderDetails?.mainQuantity
            && !Number.isNaN(parseInt(orderDetails?.mainQuantity, 10))
              ? Math.abs(parseInt(orderDetails?.mainQuantity, 10)).toString()
              : '',
          isEditable: true,
          isAbsoluteNumber: true,
          isEditDisabled,
          onSave: handleSaveMainQuantity,
        },
      ]}
    />
  )
}
