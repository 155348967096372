import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    cardLine: {
      padding: theme.spacing(5),
      backgroundColor: theme.color.backgroundStrongGrey,
      marginBottom: 2,
    },
    cardLineLabel: {
      fontWeight: 'bold',
      paddingRight: theme.spacing(2),
    },
    cardDoubleLine: {
      marginTop: theme.spacing(1),
      fontSize: 12,
    },
    selected: {
      boxShadow: `inset -5px 0px 0px 0px ${theme.color.primary}`,
    },
    icon: {
      textAlign: 'center',
    },
  }),
)
