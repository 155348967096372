import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Tooltip } from '@material-ui/core'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { ContentContext } from 'store/ContentContext'
import { ToursContext } from 'store/ToursContext'
import {
  isDateEqual,
  dateToTimeFormat,
  dateDefaultFormat,
  dateDefaultFormatWithouTime,
} from 'utils/dateFormat'
import { TOUR_STATUS_ENUM } from 'constants/constants'
import { ITour } from 'interfaces/Itours'
import useStyles from './styles'

type TourNumberWithAlertProps = {
  tour: ITour
}

const TourNumberWithAlert = ({ tour }: TourNumberWithAlertProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { sites } = useContext(ContentContext)
  const { tours } = useContext(ToursContext)
  const warehouse = useMemo(
    () => sites?.find((site) => site.id === tour?.warehouseId),
    [tour, sites],
  )

  const getFormattedDeliveryTime = (
    minDate: Date | undefined,
    maxDate: Date | undefined,
  ): string => {
    if (minDate && maxDate) {
      return `${
        isDateEqual(minDate, maxDate)
          ? `${dateDefaultFormatWithouTime(minDate)} [${dateToTimeFormat(
            minDate,
          )}-${dateToTimeFormat(maxDate)}]`
          : `${dateDefaultFormat(minDate)} - ${dateDefaultFormat(maxDate)}`
      }`
    }
    return ''
  }

  const firstStop = useMemo(() => {
    if (tour.stops.length >= 3) {
      return tour.stops[1]
    }

    return null
  }, [tour])

  const shouldDisplayAlert = useMemo(() => {
    const isStatusValid = [
      TOUR_STATUS_ENUM.PLANIFIED,
      TOUR_STATUS_ENUM.PREPARING,
      TOUR_STATUS_ENUM.PREPARED,
      TOUR_STATUS_ENUM.LOADING,
      TOUR_STATUS_ENUM.LOADED,
    ].includes(tour?.status as number)

    const delayAlertOnTourBeforeDeparture = warehouse?.executionTrackingScreen?.delayAlertOnTourBeforeDeparture

    let isLate = false
    if (
      delayAlertOnTourBeforeDeparture !== undefined
      && firstStop?.visitsToDeliver[0].arrivalMaxDate
    ) {
      const allowedUtcDate = moment.utc().add(delayAlertOnTourBeforeDeparture, 'minutes')
      const arrivalMaxUtcDate = moment.utc(firstStop.visitsToDeliver[0].arrivalMaxDate)
      isLate = allowedUtcDate.isSameOrAfter(arrivalMaxUtcDate)
    }

    return isStatusValid && isLate
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tour, firstStop, warehouse, tours])

  if (!shouldDisplayAlert) {
    return <>{tour.tourNumber}</>
  }

  return (
    <div>
      <Tooltip
        title={(
          <div>
            <p>{t('tablesEntries.tourNumberAlertMessage')}</p>
            <p>
              {getFormattedDeliveryTime(
                firstStop?.visitsToDeliver[0].arrivalMinDate,
                firstStop?.visitsToDeliver[0].arrivalMaxDate,
              )}
            </p>
          </div>
        )}
      >
        <div className={classes.tourNumberAndIconWrapper}>
          <div className={classes.tourNumberWrapper}>{tour.tourNumber}</div>
          <ErrorOutlineIcon />
        </div>
      </Tooltip>
    </div>
  )
}

export default TourNumberWithAlert
