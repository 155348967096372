import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { IPlanTemplate } from 'interfaces/IPlanTemplate'
import { IIdParam } from 'interfaces/IIdParam'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import useStyles from 'constants/cruStyles'
import PlanTemplatesProvider, { PlanTemplatesConsumer } from 'store/PlanTemplatesContext'
import Button from 'components/Button/CustomButton'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog'
import { ContentContext } from 'store/ContentContext'
import PlanTemplateInfoCard from './PlanTemplateInfoCard'
import PlanTemplateActivityCard from './PlanTemplateActivityCard'
import PlanTemplateVehicleCard from './PlanTemplateVehicleCard'

interface IProps {
  planTemplateDetails?: IPlanTemplate
  getPlanTemplateDetails?: Function
  deletePlanTemplate?: Function
  isSuccessFullyDeleted?: boolean
}

const PlanTemplateDetailsScreen = ({
  getPlanTemplateDetails,
  planTemplateDetails,
  deletePlanTemplate,
  isSuccessFullyDeleted,
}: IProps): JSX.Element => {
  const history = useHistory()
  const { id } = useParams<IIdParam>()
  const { t } = useTranslation()
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState<boolean>(false)
  const classes = useStyles()
  const { refreshPlanTemplates } = useContext(ContentContext)

  useEffect(() => {
    if (id && getPlanTemplateDetails) {
      getPlanTemplateDetails(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (isSuccessFullyDeleted) {
      history.push(ROUTES_PATH.planTemplateList)
      refreshPlanTemplates()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessFullyDeleted])

  const handleEditClick = (): void => {
    history.push(`${ROUTES_PATH.editPlanTemplate}${id}`)
  }

  const handleDeleteConfirmation = (validate: boolean): void => {
    setIsDeleteConfirmationModalOpen(false)
    if (deletePlanTemplate && validate) {
      deletePlanTemplate(id)
    }
  }

  const handleDeleteClick = () => {
    setIsDeleteConfirmationModalOpen(true)
  }

  return (
    <>
      <div className={classes.detailActionButtonContainer}>
        <Button dataCy="deleteButton" variant="outlined" onPress={handleDeleteClick}>
          {t('PlanTemplatesScreen.delete')}
        </Button>
        <Button dataCy="updateButton" onPress={handleEditClick}>
          {t('PlanTemplatesScreen.edit')}
        </Button>
      </div>
      <div style={{ marginTop: '90px' }}>
        <PlanTemplateInfoCard
          key={`${planTemplateDetails && planTemplateDetails.id}-info-details`}
          planTemplate={planTemplateDetails}
          isReadOnly
        />
        <PlanTemplateActivityCard
          key={`${planTemplateDetails && planTemplateDetails.id}-activity-details`}
          planTemplate={planTemplateDetails}
          isReadOnly
        />
        <PlanTemplateVehicleCard
          planTemplate={planTemplateDetails}
          key={`${planTemplateDetails && planTemplateDetails.id}-vehicle-details`}
          isReadOnly
        />
      </div>
      <ConfirmationDialog
        open={isDeleteConfirmationModalOpen}
        onClose={handleDeleteConfirmation}
        message={t('PlanTemplatesScreen.deletePlanTemplateConfirmationMessage')}
      />
    </>
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <PlanTemplatesProvider>
    <PlanTemplatesConsumer>
      {(planTemplatesCtx): JSX.Element => (
        <PlanTemplateDetailsScreen
          getPlanTemplateDetails={planTemplatesCtx.getPlanTemplateDetails}
          planTemplateDetails={planTemplatesCtx.planTemplateDetails}
          deletePlanTemplate={planTemplatesCtx.deletePlanTemplate}
          isSuccessFullyDeleted={planTemplatesCtx.isSuccessFullyDeleted}
          {...props}
        />
      )}
    </PlanTemplatesConsumer>
  </PlanTemplatesProvider>
)
