import React from 'react'
import { useTranslation } from 'react-i18next'
import { ListItem, ListItemIcon, Checkbox, ListItemText } from '@material-ui/core'

interface ISelectData {
  id: string
  name?: string
}

interface IProps {
  isAllSelected: boolean
  options: ISelectData[]
  selectedOptions: string[]
  onSelectAllClick: () => void
  onItemClick: (id: string) => void
}

const SelectCard = ({
  isAllSelected,
  onSelectAllClick,
  options,
  selectedOptions,
  onItemClick,
}: IProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <div style={{ maxHeight: 500, overflow: 'scroll' }}>
      <ListItem role={undefined} dense button onClick={onSelectAllClick} divider>
        <ListItemIcon>
          <Checkbox
            checked={isAllSelected}
            edge="start"
            disableRipple
            tabIndex={-1}
            inputProps={{ 'aria-labelledby': t('CarrierScreen.selectAll') }}
          />
        </ListItemIcon>
        <ListItemText id="select-all" primary={t('CarrierScreen.selectAll')} />
      </ListItem>
      {options.map((option) => (
        <ListItem
          data-cy={option.name}
          key={option.id}
          role={undefined}
          dense
          button
          onClick={(): void => {
            onItemClick(option.id as string)
          }}
        >
          <ListItemIcon>
            <Checkbox
              checked={selectedOptions.findIndex((optionId) => optionId === option.id) !== -1}
              edge="start"
              disableRipple
              tabIndex={-1}
              inputProps={{ 'aria-labelledby': option.name }}
            />
          </ListItemIcon>
          <ListItemText id={`${option.id}-list-item`} primary={option.name} />
        </ListItem>
      ))}
    </div>
  )
}

export default SelectCard
