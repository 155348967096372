import i18n from 'i18next'
import JsPDF from 'jspdf'
import { Images } from 'constants/images'
import { CreatedOrder } from 'interfaces/IOrders'
import { IWarehouseCartDropoffConfig } from 'interfaces/IWarehouseCartDropoffConfig'
import { DestinationType } from 'constants/constants'
import { ITag } from 'interfaces/ITag'
import { IParcel } from 'interfaces/IParcel'
import { IDeliveryType } from 'interfaces/IDeliveryType'

interface pdfBlockContent {
  label: string
  value: string
}

const PDF_DATE_FORMAT: Intl.DateTimeFormatOptions = { dateStyle: 'short', timeStyle: 'short' }

const LEFT_MARGIN = 10
const TOP_MARGIN = 0
const LOGO_WIDTH = 75
const LOGO_HEIGHT = 25
const START_Y = LOGO_HEIGHT + 5
const COLUMN_WIDTH = 90
const TITLE_HEIGHT = 15
const TITLE_MARGIN_BOTTOM = 5

const TEXT_FONT_SIZE = 12
const TITLE_FONT_SIZE = 16

export class OrderPdfGenerator {
  private pdf: JsPDF

  constructor() {
    this.pdf = new JsPDF()
  }

  get blob(): Blob {
    return this.pdf.output('blob')
  }

  get documentURL(): string {
    return URL.createObjectURL(this.blob)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  static generateOrderPDF(
    warehouseName: string,
    newOrder: CreatedOrder,
    carrierName?: string,
    destinationType?: DestinationType,
    isCartDropoff = false,
    config?: IWarehouseCartDropoffConfig,
  ): OrderPdfGenerator {
    const estSvcTime = newOrder.estimatedServiceTime !== undefined ? String(newOrder.estimatedServiceTime / 60) : ''
    const deliveryTypeLabel = newOrder.deliveryTypeLabel || i18n.t(`tablesEntries.deliveryType.${newOrder.deliveryType}`)

    const customerInfoContent = isCartDropoff
      ? generateCustomerInfoContent(warehouseName, newOrder, config as IWarehouseCartDropoffConfig)
      : [
        { label: 'OrdersScreen.email', value: newOrder.customer.email },
        { label: 'OrdersScreen.warehouse', value: warehouseName },
        { label: 'OrdersScreen.customerLastName', value: newOrder.customer.lastName },
        { label: 'OrdersScreen.customerFirstName', value: newOrder.customer.firstName },
        { label: 'OrdersScreen.phoneNumber', value: newOrder.customer.phoneNumber },
      ]

    const addressInfoContent = isCartDropoff
      ? generateAddressInfoContent(
          destinationType as DestinationType,
          newOrder,
          config as IWarehouseCartDropoffConfig,
      )
      : [
        {
          label: 'OrdersScreen.deliveryAddressType.title',
          value: i18n.t('OrdersScreen.deliveryAddressType.address'),
        },
        { label: 'OrdersScreen.address.roadNumber', value: newOrder.deliveryAddressNumber },
        { label: 'OrdersScreen.address.road', value: newOrder.deliveryAddressRoad },
        { label: 'OrdersScreen.address.zipCode', value: newOrder.deliveryAddressZipCode },
        { label: 'OrdersScreen.address.city', value: newOrder.deliveryAddressCity },
        { label: 'OrdersScreen.address.country', value: newOrder.deliveryAddressCountry },
        { label: 'OrdersScreen.enterprise', value: newOrder.enterprise || '' },
        {
          label: 'OrdersScreen.deliveryInstructions',
          value: newOrder.deliveryInstructions || '',
        },
      ]

    const deliveryInfoContent = isCartDropoff
      ? generateDeliveryInfoContent(
        estSvcTime,
          carrierName as string,
          newOrder,
          config as IWarehouseCartDropoffConfig,
      )
      : [
        {
          label: 'OrdersScreen.deliveryDateTimeMinimum',
          value: formatDateForPdf(newOrder.deliveryDateTimeMinimum),
        },
        {
          label: 'OrdersScreen.deliveryDateTimeMaximum',
          value: formatDateForPdf(newOrder.deliveryDateTimeMaximum),
        },
        { label: 'OrdersScreen.estimatedServiceTime', value: estSvcTime },
      ]

    const orderInfoContent = isCartDropoff
      ? generateOrderInfoContent(newOrder, config as IWarehouseCartDropoffConfig, deliveryTypeLabel)
      : [
        { label: 'OrdersScreen.orderNumber', value: newOrder.orderNumber },
        { label: 'OrdersScreen.orderAmount', value: newOrder.orderAmount || '' },
        {
          label: 'OrdersScreen.deliveryType',
          value: deliveryTypeLabel || '',
        },
        {
          label: 'OrdersScreen.transportType',
          value: i18n.t(`OrdersScreen.transportTypes.${newOrder.transportType}`),
        },
        { label: 'OrdersScreen.mainQuantity', value: String(newOrder.mainQuantity) },
      ]

    const pdf: OrderPdfGenerator = new OrderPdfGenerator()
    const customerInfoBlockHeight = pdf.getBlockHeight(customerInfoContent)
    const deliveryInfoBlockHeight = pdf.getBlockHeight(deliveryInfoContent)
    pdf
      .setTitle()
      .addLogo()
      .drawPdfBlock('OrdersScreen.customerInfo', customerInfoContent, LEFT_MARGIN, START_Y)
      .drawPdfBlock(
        'OrdersScreen.addressInfo',
        addressInfoContent,
        COLUMN_WIDTH + 2 * LEFT_MARGIN,
        START_Y,
      )
      .drawPdfBlock(
        'OrdersScreen.deliveryInfo',
        deliveryInfoContent,
        LEFT_MARGIN,
        START_Y + customerInfoBlockHeight,
      )
      .drawPdfBlock(
        'OrdersScreen.orderInfo',
        orderInfoContent,
        LEFT_MARGIN,
        START_Y + customerInfoBlockHeight + deliveryInfoBlockHeight,
      )
    return pdf
  }

  addLogo(): this {
    this.pdf.addImage(
      Images.LOGO_FULL_LIGHT_MODE,
      'png',
      LEFT_MARGIN,
      TOP_MARGIN,
      LOGO_WIDTH,
      LOGO_HEIGHT,
    )
    return this
  }

  setTitle(): this {
    this.pdf.setProperties({ title: formatDateForPdf(new Date().toISOString()) })
    return this
  }

  drawPdfBlock(title: string, content: pdfBlockContent[], x: number, y: number): this {
    this.drawBoxTitle(title, x, y)

    let currentY = y + TITLE_HEIGHT + TITLE_MARGIN_BOTTOM

    this.pdf.setFontSize(TEXT_FONT_SIZE)

    const pageHeight = this.pdf.internal.pageSize.getHeight()

    // eslint-disable-next-line no-restricted-syntax
    for (const contentLine of content) {
      const line = `${i18n.t(contentLine.label)}: ${contentLine.value || ''}`
      const multiLineText = this.pdf.splitTextToSize(line, COLUMN_WIDTH)
      this.pdf.text('•', x, currentY)
      this.pdf.text(multiLineText, x + 3, currentY)

      currentY += TEXT_FONT_SIZE * multiLineText.length

      if (currentY > pageHeight) {
        this.pdf.addPage()
        this.addLogo()
        currentY = START_Y
      }
    }

    return this
  }

  drawBoxTitle(text: string, x: number, y: number): this {
    const titleWidth = COLUMN_WIDTH
    const titleHeight = TITLE_HEIGHT

    this.pdf.setFontSize(TITLE_FONT_SIZE)
    this.pdf.setFillColor(200, 200, 200)
    this.pdf.rect(x, y, titleWidth, titleHeight, 'F')

    const textStart = x + titleWidth / 2
    this.pdf.setTextColor(0, 0, 0)
    this.pdf.text(i18n.t(text), textStart, y + 10, { align: 'center' })

    return this
  }

  getBlockHeight(content: pdfBlockContent[]): number {
    this.pdf.setFontSize(TEXT_FONT_SIZE)
    let height = TITLE_HEIGHT + TITLE_MARGIN_BOTTOM
    content.forEach((contentLine) => {
      const line = `${i18n.t(contentLine.label)}: ${contentLine.value || ''}`
      const multiLineText = this.pdf.splitTextToSize(line, COLUMN_WIDTH)
      height += TEXT_FONT_SIZE * multiLineText.length
    })
    return height
  }
}

function generateCustomerInfoContent(
  warehouseName: string,
  newOrder: CreatedOrder,
  config: IWarehouseCartDropoffConfig,
): pdfBlockContent[] {
  const customerInfoContent: pdfBlockContent[] = []
  if (config.originSiteId?.active) {
    customerInfoContent.push({ label: 'OrdersScreen.warehouse', value: warehouseName })
  }
  customerInfoContent.push({ label: 'OrdersScreen.email', value: newOrder.customer.email })
  customerInfoContent.push({
    label: 'OrdersScreen.customerLastName',
    value: newOrder.customer.lastName,
  })
  customerInfoContent.push({
    label: 'OrdersScreen.customerFirstName',
    value: newOrder.customer.firstName,
  })
  customerInfoContent.push({
    label: 'OrdersScreen.phoneNumber',
    value: newOrder.customer.phoneNumber,
  })
  if (config.termsOfSale?.active) {
    customerInfoContent.push({
      label: 'OrdersScreen.termsOfSaleAcceptance',
      value: formatBoolean(newOrder.areTermsOfSaleAccepted),
    })
  }
  return customerInfoContent
}

function generateAddressInfoContent(
  destinationType: DestinationType,
  newOrder: CreatedOrder,
  config: IWarehouseCartDropoffConfig,
): pdfBlockContent[] {
  const addressInfoContent: pdfBlockContent[] = []
  if (config.destinationType?.active) {
    addressInfoContent.push({
      label: 'OrdersScreen.deliveryAddressType.title',
      value: i18n.t(`OrdersScreen.deliveryAddressType.${destinationType}`),
    })
  }
  if (destinationType === DestinationType.Address) {
    addressInfoContent.push({
      label: 'OrdersScreen.address.road',
      value: newOrder.deliveryAddressRoad,
    })
    addressInfoContent.push({
      label: 'OrdersScreen.address.roadNumber',
      value: newOrder.deliveryAddressNumber,
    })
    addressInfoContent.push({
      label: 'OrdersScreen.address.zipCode',
      value: newOrder.deliveryAddressZipCode,
    })
    addressInfoContent.push({
      label: 'OrdersScreen.address.city',
      value: newOrder.deliveryAddressCity,
    })
    if (config.country?.active) {
      addressInfoContent.push({
        label: 'OrdersScreen.address.country',
        value: newOrder.deliveryAddressCountry,
      })
    }
    if (config.floor?.active) {
      addressInfoContent.push({
        label: 'OrdersScreen.deliveryFloor',
        value: newOrder.deliveryFloor?.toString() || '',
      })
    }
    if (config.hasElevator?.active) {
      addressInfoContent.push({
        label: 'OrdersScreen.deliveryIsElevatorPresent',
        value: formatBoolean(newOrder.deliveryIsElevatorPresent),
      })
    }
    if (config.digicode?.active) {
      addressInfoContent.push({
        label: 'OrdersScreen.deliveryDoorCode',
        value: newOrder.deliveryDoorCode || '',
      })
    }
    if (config.deliveryInstructions?.active) {
      addressInfoContent.push({
        label: 'OrdersScreen.deliveryInstructions',
        value: newOrder.deliveryInstructions || '',
      })
    }
  }
  if (destinationType === DestinationType.Warehouse) {
    addressInfoContent.push({
      label: 'OrdersScreen.deliverySite',
      value: newOrder.deliverySiteId?.name || '',
    })
    if (config.deliveryInstructions?.active) {
      addressInfoContent.push({
        label: 'OrdersScreen.deliveryInstructions',
        value: newOrder.deliveryInstructions || '',
      })
    }
  }
  if (config.enterprise?.active) {
    addressInfoContent.push({ label: 'OrdersScreen.enterprise', value: newOrder.enterprise || '' })
  }
  return addressInfoContent
}

function generateDeliveryInfoContent(
  estimatedServiceTime: string,
  carrierName: string,
  newOrder: CreatedOrder,
  config: IWarehouseCartDropoffConfig,
): pdfBlockContent[] {
  const deliveryInfoContent: pdfBlockContent[] = []
  if (config.orderInstructions?.active) {
    deliveryInfoContent.push({
      label: 'OrdersScreen.orderInstructions',
      value: newOrder.orderInstructions || '',
    })
  }
  deliveryInfoContent.push({
    label: 'OrdersScreen.deliveryDateTimeMinimum',
    value: formatDateForPdf(newOrder.deliveryDateTimeMinimum),
  })
  deliveryInfoContent.push({
    label: 'OrdersScreen.deliveryDateTimeMaximum',
    value: formatDateForPdf(newOrder.deliveryDateTimeMaximum),
  })
  if (config.estimatedServiceTime?.active) {
    deliveryInfoContent.push({
      label: 'OrdersScreen.estimatedServiceTime',
      value: estimatedServiceTime,
    })
  }
  if (config.tags?.active) {
    deliveryInfoContent.push({ label: 'OrdersScreen.tags', value: formatTags(newOrder.tags) })
  }
  if (config.requestedCarriers?.active) {
    deliveryInfoContent.push({ label: 'OrdersScreen.carrier', value: carrierName })
  }
  return deliveryInfoContent
}

function generateOrderInfoContent(
  newOrder: CreatedOrder,
  config: IWarehouseCartDropoffConfig,
  deliveryTypeLabel?: string,
): pdfBlockContent[] {
  const orderInfoContent: pdfBlockContent[] = []
  if (config.orderNumber?.active) {
    orderInfoContent.push({ label: 'OrdersScreen.orderNumber', value: newOrder.orderNumber })
  }
  if (config.value?.active) {
    orderInfoContent.push({ label: 'OrdersScreen.orderAmount', value: newOrder.orderAmount || '' })
  }
  if (config.deliveryType?.active) {
    orderInfoContent.push({
      label: 'OrdersScreen.deliveryType',
      value: deliveryTypeLabel || '',
    })
  }
  if (config.transportType?.active) {
    orderInfoContent.push({
      label: 'OrdersScreen.transportType',
      value: i18n.t(`OrdersScreen.transportTypes.${newOrder.transportType}`),
    })
  }
  if (config.scanTicket?.active) {
    orderInfoContent.push({ label: 'OrdersScreen.scanTicket', value: newOrder.scanTicket || '' })
  }
  orderInfoContent.push({
    label: 'OrdersScreen.mainQuantity',
    value: String(newOrder.mainQuantity),
  })
  if (config.numberOfArticles?.active) {
    orderInfoContent.push({
      label: 'OrdersScreen.numberOfArticles',
      value: newOrder.numberOfArticles?.toString() || '',
    })
  }
  if (config.estimatedWeight?.active) {
    orderInfoContent.push({
      label: 'OrdersScreen.estimatedWeight',
      value: newOrder.estimatedWeight?.toString() || '',
    })
  }
  if (config.parcelCodes?.active) {
    orderInfoContent.push({
      label: 'OrdersScreen.parcelCodes',
      value: formatParcels(newOrder.parcels),
    })
  }
  return orderInfoContent
}

function formatBoolean(value?: boolean): string {
  return value ? i18n.t('OrdersScreen.yes') : i18n.t('OrdersScreen.no')
}

function formatTags(tags?: ITag[]): string {
  const tagLabels = tags?.map((tag) => tag.label)
  return tagLabels?.join(', ') || ''
}

function formatParcels(parcels?: IParcel[]): string {
  const parcelCodes = parcels?.map((parcel) => parcel.ean)
  return parcelCodes?.join(', ') || ''
}

export function formatDateForPdf(date: string): string {
  const d = new Date(date)
  return new Intl.DateTimeFormat(i18n.language, PDF_DATE_FORMAT).format(d)
}

export function getDeliveryTypeLabelByCode(code: number, tenantDeliveryTypes?: IDeliveryType[]): string {
  const deliveryTypesI18nPrefix = 'tablesEntries.deliveryType.'
  const translated = i18n.t(deliveryTypesI18nPrefix + code)

  if (!translated.startsWith(deliveryTypesI18nPrefix)) {
    return translated
  }

  if (!tenantDeliveryTypes) {
    return String(code)
  }

  const deliveryType = tenantDeliveryTypes.find((_deliveryType) => _deliveryType.code === code)
  return deliveryType ? deliveryType.label : String(code)
}
