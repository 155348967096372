import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

const STEP_TITLE_HEIGHT = 20
const STEP_STATUS_HEIGHT = 30
const STEP_GAP = 5
const STEP_PADDING = 8
export const STEP_HEIGHT = STEP_TITLE_HEIGHT + STEP_STATUS_HEIGHT + STEP_GAP + 2 * STEP_PADDING

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      flex: 1,
      gap: STEP_GAP,
      cursor: 'pointer',
      borderRadius: '4px 4px 0 0',
      padding: STEP_PADDING,
      height: STEP_HEIGHT,
    },
    active: {
      border: `1px solid ${theme.color.primary}`,
      color: 'white',
      background: theme.color.primary,
    },
    completed: {
      border: `1px solid ${theme.color.lightPrimary}`,
      color: 'white',
      background: theme.color.lightPrimary,
    },
    notCompleted: {
      border: '1px solid grey',
      color: 'grey',
      background: theme.color.backgroundBody,
    },
    title: {
      height: STEP_TITLE_HEIGHT,
    },
    statusContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: STEP_STATUS_HEIGHT,
    },
    status: {
      borderRadius: '4px 4px 0 0',
      padding: '5px',
    },
    activeStatus: {
      background: 'white',
      color: theme.color.primary,
    },
    completedStatus: {
      background: theme.color.primary,
      color: 'white',
    },
    notCompletedStatus: {
      background: 'grey',
      color: 'white',
    },
    icon: {
      borderRadius: '100%',
      padding: '5px',
      fontSize: '30px',
    },
  }),
)
