import { createStyles, makeStyles } from '@material-ui/core/styles'

export default makeStyles(() =>
  createStyles({
    filterContainer: {
      display: 'flex',
      flexDirection: 'row',
      position: 'relative',
    },
    filterLeftContainer: {
      marginBottom: 12,
    },
    mapContainer: {
      display: 'flex',
      height: 'calc(100vh - 166px)',
      marginTop: '5px',
    },
    map: {
      flex: 1,
      zIndex: 1,
    },
  }),
)
