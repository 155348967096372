export enum WeekDayName {
  Sunday = 'sunday',
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
}

export interface IWarehouseTimeSlot {
  id: string
  tenantId: string
  label: string
  timeSlot: ITimeSlot
  active: boolean
  maxDelayInHour: number
  minDelayInHour: number
  warehouseIds: string[]
  sectorIds: string[]
}

export interface ITimeSlotConfiguration {
  availability: number
  beginTime: Date
  endTime: Date
}

export interface ITimeSlot {
  friday: ITimeSlotConfiguration[]
  monday: ITimeSlotConfiguration[]
  saturday: ITimeSlotConfiguration[]
  sunday: ITimeSlotConfiguration[]
  thursday: ITimeSlotConfiguration[]
  tuesday: ITimeSlotConfiguration[]
  wednesday: ITimeSlotConfiguration[]
}

export interface IWarehouseTimeSlotForm {
  label: string
  timeSlot: ITimeSlotCreate
  active: boolean
  maxDelayInHour: number
  minDelayInHour: number
  warehouseIds: string[]
  sectorIds: string[]
}

interface ITimeSlotCreate {
  friday?: ITimeSlotConfiguration[]
  monday?: ITimeSlotConfiguration[]
  saturday?: ITimeSlotConfiguration[]
  sunday?: ITimeSlotConfiguration[]
  thursday?: ITimeSlotConfiguration[]
  tuesday?: ITimeSlotConfiguration[]
  wednesday?: ITimeSlotConfiguration[]
}

export interface IWarehouseTimeSlotReportingConfiguration {
  label: string
  warehouseId: string
  warehouseName: string
  sectorId?: string
  sectorLabel?: string
}

interface ITimeSlotDetail {
  maxOrder: number
  orderCount: number
  beginTime: Date
  endTime: Date
}

interface ITimeSlotDay {
  day: string
  timeSlotDetails: ITimeSlotDetail[]
}
export interface IWarehouseTimeSlotStatus {
  timeSlots: ITimeSlotDay[]
}

export interface IWarehouseTimeSlotReporting {
  day: string
  slot: string
  availability: number
  nbOfSlots?: number
  lastSlotInDay: boolean
}

export interface IOrderWarehouseTimeSlotStatus {
  id: string
  active: boolean
  timeSlots: IOrderTimeSlot[]
}

export interface IOrderTimeSlot {
  day: string
  timeSlotDetails: IOrderTimeSlotDetails[]
}

export interface IOrderTimeSlotDetails {
  active: boolean
  beginTime: Date
  endTime: Date
  maxOrder: number
  orderCount: number
  isAvailable: boolean
}

export interface ISelectedTimeSlot {
  beginTime: Date
  endTime: Date
}
