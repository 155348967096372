import React from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Paper,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { ICancellation } from 'interfaces/ICancellation'
import { STOP_STATUS_MAP } from 'constants/constants'
import useStyles from './styles'

interface IStop {
  address: {
    full: string
  }
  label: string
  status: number | string | undefined
  cancellation?: ICancellation
}

interface IProps {
  expanded?: boolean
  onChange: () => void
  summary: string
  stops: IStop[]
  isGreyedOut?: boolean
}

const StopsAccordion = ({
  expanded,
  onChange,
  summary,
  stops,
  isGreyedOut,
}: IProps): JSX.Element => {
  const classes = useStyles()

  return (
    <Accordion expanded={expanded} onChange={onChange}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">{summary}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Paper elevation={0}>
          {stops.map((stop) => (
            <ListItem dense divider className={isGreyedOut ? classes.greyText : ''}>
              <ListItemText primary={stop.label} className={classes.text} />
              <ListItemText primary={stop.address.full} className={classes.text} />
              <ListItemText
                primary={STOP_STATUS_MAP.get(Number(stop.status))}
                className={classes.statusText}
              />
            </ListItem>
          ))}
        </Paper>
      </AccordionDetails>
    </Accordion>
  )
}

export default StopsAccordion
