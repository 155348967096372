import React from 'react'
import { useTranslation } from 'react-i18next'
import { Legend } from '@devexpress/dx-react-chart-material-ui'
import clsx from 'clsx'

import useStyles from '../styles'

interface IProps {
  totalAvg: number
  totalNumber: number
}

const CustomBarChartLegend = ({ totalAvg, totalNumber }: IProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.legendContainer}>
      <Legend.Label
        className={classes.totalLabel}
        text={`${t('CustomerRatingReportingScreen.averageOverPeriod')}: ${
          Math.round(totalAvg * 100) / 100 || ''
        }`}
      />
      <Legend.Label
        className={clsx(classes.totalLabel, classes.totalNumberLabel)}
        text={`${t('CustomerRatingReportingScreen.numberOfRatings')}: ${totalNumber}`}
      />
    </div>
  )
}

export default CustomBarChartLegend
