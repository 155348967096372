import React, { useState, ReactNode, useContext } from 'react'
import { isIError } from 'api/types'
import { getErrorList } from 'utils/errorUtils'
import { FeedbackContext } from 'store/FeedbackContext'
import { useTranslation } from 'react-i18next'
import { IApikey } from 'interfaces/IApikey'
import ApikeysApi from 'api/apikeys'
import { COLUMNS_MODEL_APIKEYS_LIST } from 'constants/table'
import { IApikeyFilters } from 'interfaces/interfaces'

interface IApikeysContext {
  apikeys: IApikey[]
  count: number
  updateApikeys: (
    filters?: IApikeyFilters,
    offset?: number,
    rowsPerPage?: number,
    sortField?: number,
    sortDirection?: string,
  ) => void
  createApikey: (apiKey: IApikey) => void
  updateApikey: (id: string, apiKey: IApikey) => void
  getDetails: (id: string) => void
  createSuccess?: boolean
  updateSuccess?: boolean
  apikeyDetails: IApikey
  revokeApikey: (id: string) => void
}

interface IProps {
  children: ReactNode
}

const { Provider, Consumer } = React.createContext<IApikeysContext>({} as IApikeysContext)

function ApikeysProvider({ children }: IProps): JSX.Element {
  const [apikeys, setApikeys] = useState<IApikey[]>([])
  const [count, setCount] = useState<number>(0)
  const [createSuccess, setCreateSuccess] = useState<boolean | undefined>()
  const [updateSuccess, setUpdateSuccess] = useState<boolean | undefined>()
  const [apikeyDetails, setApikeyDetails] = useState<IApikey>({})
  const { openErrorSnack, openSuccessSnack, toggleLoader } = useContext(FeedbackContext)
  const { t } = useTranslation()

  const updateApikeys = async (
    filters?: IApikeyFilters,
    offset?: number,
    rowsPerPage?: number,
    sortField?: number,
    sortDirection?: string,
  ): Promise<void> => {
    toggleLoader(true)
    const response = await ApikeysApi.getList(
      filters,
      offset,
      rowsPerPage,
      COLUMNS_MODEL_APIKEYS_LIST.find((_column, index) => index === sortField)?.field,
      sortDirection === 'desc' ? -1 : 1,
    )
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      setApikeys(response.apikeys)
      setCount(response.count)
    }
    toggleLoader(false)
  }

  const createApikey = async (apikey: IApikey): Promise<IApikey | void> => {
    toggleLoader(true)
    const response = await ApikeysApi.create(apikey)
    if (isIError(response)) {
      response.error.errorList = getErrorList(response)
      if (response.error.fieldErrors) {
        openErrorSnack(response.error.errorList.join('\n'))
      } else {
        openErrorSnack(response.error.message)
      }
    } else {
      setCreateSuccess(true)
      openSuccessSnack(t('ApikeyScreen.apikeyCreated'))
      return response
    }
    toggleLoader(false)
  }

  const updateApikey = async (id: string, apikey: IApikey): Promise<void> => {
    toggleLoader(true)
    const response = await ApikeysApi.update(id, apikey)
    if (isIError(response)) {
      response.error.errorList = getErrorList(response)
      if (response.error.fieldErrors) {
        openErrorSnack(response.error.errorList.join('\n'))
      } else {
        openErrorSnack(response.error.message)
      }
    } else {
      setUpdateSuccess(true)
      openSuccessSnack(t('ApikeyScreen.apikeyUpdated'))
    }
    toggleLoader(false)
  }

  const getDetails = async (id: string): Promise<void> => {
    toggleLoader(true)
    const response = await ApikeysApi.get(id)
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      setApikeyDetails(response)
    }
    toggleLoader(false)
  }

  const revokeApikey = async (id: string): Promise<void> => {
    toggleLoader(true)
    const response = await ApikeysApi.revoke(id)
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    }
    toggleLoader(false)
  }

  return (
    <Provider
      value={{
        apikeys,
        count,
        updateApikeys,
        createApikey,
        createSuccess,
        updateApikey,
        updateSuccess,
        apikeyDetails,
        getDetails,
        revokeApikey,
      }}
    >
      {children}
    </Provider>
  )
}

export default ApikeysProvider

export { Consumer as ApikeysConsumer }
