import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { CardContent, TextField } from '@material-ui/core'
import clsx from 'clsx'

import useStyles from 'constants/cruStyles'
import { ISector } from 'interfaces/ISector'
import SearchableSelect from 'components/Inputs/SearchableSelect'
import { IDriver, IVehicle } from 'interfaces/interfaces'
import Button from 'components/Button/CustomButton'
import { SitesConsumer } from 'store/SitesContext'
import { DefaultSiteContext } from 'store/DefaultSiteContext'

interface IProps {
  sector: ISector
  sectors?: ISector[]
  vehicles?: IVehicle[]
  drivers?: IDriver[]
  onClose: () => void
  updateSector?: (sectorId: string, sector: ISector) => Promise<void>
}

interface ITextChange {
  target: {
    value: string
    name: string
  }
}

const UpdateSector = ({
  sector,
  sectors,
  vehicles,
  drivers,
  onClose,
  updateSector,
}: IProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [state, setState] = useState<ISector>(sector)
  const [availableDrivers, setAvailableDrivers] = useState<IDriver[]>([])
  const { defaultSiteId } = useContext(DefaultSiteContext)

  useEffect(() => {
    if (defaultSiteId && drivers) {
      const filteredAvailableDrivers = drivers
        ?.filter((driver) => driver.active)
        ?.filter((driver) => driver.siteId === defaultSiteId)
      if (state.driverId) {
        const filteredAvailableDriverIds = filteredAvailableDrivers.map((driver) => driver.id)
        if (!filteredAvailableDriverIds.includes(state.driverId)) {
          setState({ ...state, driverId: '' })
        }
      }
      return setAvailableDrivers(filteredAvailableDrivers)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSiteId, drivers])

  const handleSelectChange = (
    event: React.ChangeEvent<{ value: unknown; name?: string }>,
  ): void => {
    const { target } = event
    const name = target.name as string
    const value = target.value as string

    setState({ ...state, [name]: value })
  }

  const handleSelectAll = (vIds: string[]): void => {
    setState({ ...state, vehicleIds: vIds })
  }

  const handleInputChange = (event: ITextChange): void => {
    const { target } = event
    const { name, value } = target
    setState({ ...state, [name]: value })
  }

  const handleSaveClick = async (): Promise<void> => {
    if (updateSector) {
      await updateSector(state.id, state)
      onClose()
    }
  }

  /**
   * Unavailable vehicle ids for the current sector
   */
  const unavailableVehicleIds = (() => {
    const allOtherSectors = sectors ? sectors.filter((sec) => sec.id !== state.id) : []

    return allOtherSectors.map((sec) => (sec.vehicleIds ? sec.vehicleIds : [])).flat()
  })()

  return (
    <CardContent data-cy="update-container">
      <div className={clsx(classes.fieldsContainer, classes.routingRuleCard)}>
        <TextField
          data-cy="label"
          label={t('SectorsScreen.label').toUpperCase()}
          onChange={handleInputChange}
          type="text"
          name="label"
          value={state.label || ''}
          error={state.label?.trim() === ''}
          required
          className={classes.textFieldItem}
        />
        <SearchableSelect
          dataCy="vehicleSelect"
          label={t('SectorsScreen.vehicles').toUpperCase()}
          name="vehicleIds"
          selectedValues={state.vehicleIds || []}
          options={vehicles
            ?.filter((vehicle) => vehicle.active)
            .filter((vehicle) => unavailableVehicleIds.every((vId) => vId !== vehicle.id))
            .map((vehicle) => ({ id: vehicle?.id, name: vehicle?.name }))}
          onChange={handleSelectChange}
          handleAll={handleSelectAll}
          isMultiSelect
        />
        <SearchableSelect
          dataCy="driverSelect"
          label={t('SectorsScreen.driver').toUpperCase()}
          name="driverId"
          selectedValue={state.driverId || ''}
          options={availableDrivers.map((driver) => ({ id: driver.id, name: driver.name }))}
          onChange={handleSelectChange}
        />
        <TextField
          data-cy="deckCode"
          label={t('SectorsScreen.deckCode').toUpperCase()}
          onChange={handleInputChange}
          type="text"
          name="deckCode"
          value={state.deckCode || ''}
        />
      </div>
      <div className={classes.rowFieldsContainer}>
        <div className={classes.detailActionButtonsContainer}>
          <Button dataCy="cancelButton" variant="outlined" onPress={onClose}>
            {t('form.cancel')}
          </Button>
          <Button dataCy="saveButton" onPress={handleSaveClick}>
            {t('form.save')}
          </Button>
        </div>
      </div>
    </CardContent>
  )
}

export default ({
  sector,
  vehicles,
  drivers,
  onClose,
}: JSX.IntrinsicAttributes & IProps): JSX.Element => (
  <SitesConsumer>
    {(sitesCtx): JSX.Element => (
      <UpdateSector
        updateSector={sitesCtx.updateSector}
        sectors={sitesCtx.sectors}
        sector={sector}
        vehicles={vehicles}
        drivers={drivers}
        onClose={onClose}
      />
    )}
  </SitesConsumer>
)
