import React, { useEffect, useState } from 'react'
import ReactDOMServer from 'react-dom/server'
import { Button } from '@material-ui/core'

import COLORS from 'components/Map/LinesColors'
import CLUSTER_COLORS from 'screens/PlanningCategory/PlanningScreen/components/ClusterColors'
import { Marker, SiteMarker, WarehouseMarker } from './markers'

const PrettyPrintJson = ({ data }): JSX.Element => (
  <div>
    <pre>{JSON.stringify(data, null, 2)}</pre>
  </div>
)

function MarkersGenerationScreen(): JSX.Element {
  const [imagesList, setImagesList] = useState({})

  function getEncodedIcon(svg: JSX.Element): string {
    const decoded = unescape(encodeURIComponent(ReactDOMServer.renderToString(svg)))
    const base64 = btoa(decoded)
    return `data:image/svg+xml;base64,${base64}`
  }

  function generateMarkers(): void {
    const alphabet = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase().split('')
    const list = {}

    alphabet.forEach((firstLetter: string) => {
      alphabet.forEach((secondLetter: string) => {
        const normal = getEncodedIcon(<Marker text={`${firstLetter}${secondLetter}`} />)
        list[`${firstLetter}${secondLetter}_normal`] = normal
        CLUSTER_COLORS.forEach((color: string) => {
          const coloredLetters = getEncodedIcon(
            <Marker text={`${firstLetter}${secondLetter}`} color={color} />,
          )
          list[`${color}_${firstLetter}${secondLetter}_colored`] = coloredLetters
        })
      })
    })

    COLORS.forEach((color: string) => {
      const times = 50
      for (let i = 0; i < times; i += 1) {
        list[`${color}_${i}_colored`] = getEncodedIcon(<Marker text={`${i}`} color={color} />)
      }
      list[`${color}_warehouse`] = getEncodedIcon(<WarehouseMarker color={color} />)
      list[`${color}_warehouse_inverted`] = getEncodedIcon(<WarehouseMarker circleColor={color} />)
      list[`stop_site_${color}`] = getEncodedIcon(<SiteMarker color={color} />)
    })
    CLUSTER_COLORS.forEach((color: string) => {
      list[`visit_site_${color}`] = getEncodedIcon(<SiteMarker color={color} />)
    })
    // eslint-disable-next-line dot-notation
    list['visit_site_normal'] = getEncodedIcon(<SiteMarker />)
    setImagesList(list)
  }

  function onClickText(): void {
    navigator.clipboard.writeText(
      `const markers = '${JSON.stringify(imagesList)}' \n\nexport default markers\n`,
    )
  }

  useEffect(() => {
    generateMarkers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {Object.entries(imagesList).length}
      <Button variant="contained" color="primary" onClick={onClickText}>
        Copy data
      </Button>
      <PrettyPrintJson data={imagesList} />
    </>
  )
}

export default MarkersGenerationScreen
