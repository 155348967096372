import { PILOT_AND_STORE_USER_ROLES, AUTHORIZED_ROLES } from 'constants/constants'

import LoginScreen from 'screens/AuthStack/LoginScreen'
import HomeScreen from 'screens/HomeScreen/HomeScreen'
import ForbiddenScreen from 'screens/ForbiddenScreen'
import MarkersGenerationScreen from 'screens/SettingsCategory/MarkersGenerationScreen'

import { AlertsListScreen } from 'screens/AlertsScreen/AlertsScreen'
import EXECUTION_CATEGORY from './ExecutionConfig'
import RESSOURCES_CATEGORY from './RessourcesConfig'
import NETWORK_CATEGORY from './NetworkConfig'
import ORDER_CATEGORY from './OrderConfig'
import PLANNING_CATEGORY from './PlanningConfig'
import REPORTING_CATEGORY from './ReportingConfig'
import { ROUTES_PATH } from './RoutesPath'
import SETTING_CATEGORY from './SettingConfig'
import COST_CATEGORY from './CostConfig'
import APIKEYS from './ApikeysConfig'

/**
 * Here's an array with all routes.
 * To add a new route, add an object defined like:
 * {
 *   title: PAGE_TITLE,
 *   path: PATH,               The path displayed in url
 *   component: LoginScreen,   The component of the screen
 *   isPrivate: false,         Set to true if the user needs to be logged in to see this screen
 *   isInMenu: false,          Set to true if you want this page to have a shortcut in menu
 *   roles: [],                Array of strings | Roles allowed to see the screen
 *                             If array is empty or not set, anyone will have access to the screen
 *   category: string,         Define in which category should the screen appear in the menu
 *                             If a category is set, you must also set at least one role.
 *   isBetaMode: boolean,      Set this to true if the page is not ready for production yet.
 *   description: string,      A string explaning what is the screen is about. (only used with isBetaMode)
 *                             This string doesn't need to be translated yet, it's only for dev / PO.
 *   exact: boolean,           Set to true if path must match exactly the current url
 *}
 */

export interface INavElem {
  title: string
  path: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: any
  category?: string
  isPrivate?: boolean
  isInMenu?: boolean
  roles?: string[]
  isBetaMode?: boolean
  hideInFeaturesList?: boolean
  description?: string
  exact?: boolean
  featureFlagName?: string
}

export const NAVIGATION_CONFIG: INavElem[] = [
  ...ORDER_CATEGORY,
  ...RESSOURCES_CATEGORY,
  ...NETWORK_CATEGORY,
  ...PLANNING_CATEGORY,
  ...EXECUTION_CATEGORY,
  ...COST_CATEGORY,
  ...REPORTING_CATEGORY,
  ...SETTING_CATEGORY,
  ...APIKEYS,
  {
    title: 'LoginPage',
    path: ROUTES_PATH.localLogin,
    component: LoginScreen,
    isPrivate: false,
    isInMenu: false,
  },
  {
    title: 'ForbiddenScreen',
    path: ROUTES_PATH.forbidden,
    component: ForbiddenScreen,
    isPrivate: false,
    isInMenu: false,
  },
  {
    title: 'Alerts',
    path: ROUTES_PATH.alerts,
    component: AlertsListScreen,
    isPrivate: true,
    roles: PILOT_AND_STORE_USER_ROLES,
    isInMenu: false,
  },
  ...(process.env.NODE_ENV === 'development'
    ? [
      {
        title: 'MarkerGenerationScreen',
        path: ROUTES_PATH.markersGeneration,
        component: MarkersGenerationScreen,
        isPrivate: true,
        isInMenu: false,
      },
    ]
    : []),
  /* THIS ENTRY MUST BE AT THE BOTTOM OF THE ARRAY, PUT NEW ROUTES ABOVE */
  {
    title: 'HomePage',
    path: ROUTES_PATH.home,
    component: HomeScreen,
    isPrivate: true,
    isInMenu: true,
    roles: AUTHORIZED_ROLES.map((role) => role.toString()),
  },
]

export const NO_LAYOUT_SCREEN = [ROUTES_PATH.localLogin]
