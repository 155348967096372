import React from 'react'

import OrdersTable from 'components/Table/OrdersTable'
import { COLUMNS_PLANNING_ORDERS } from 'constants/table'
import { IOrder } from 'interfaces/IOrders'
import { OrdersConsumer } from 'store/OrdersContext'
import { FilterKey } from 'constants/filters'
import { ROUTES_PATH } from 'navigation/RoutesPath'

interface IOrdersProps {
  orders: IOrder[]
}

const OrdersListScreen = ({ orders }: IOrdersProps): JSX.Element => (
  <>
    <OrdersTable
      columns={COLUMNS_PLANNING_ORDERS}
      data={orders}
      columnConfigName="planningOrdersList"
      filterKey={FilterKey.planningOrders}
      detailsUrl={ROUTES_PATH.planningOrderDetails}
    />
  </>
)

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <OrdersConsumer>
    {(ctx): JSX.Element => <OrdersListScreen orders={ctx.orders} {...props} />}
  </OrdersConsumer>
)
