import React, { useEffect, useContext } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { IIdParam } from 'interfaces/IIdParam'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import SitesProvider, { SitesContext } from 'store/SitesContext'
import CustomCard from 'components/CustomCard/CustomCard'
import Button from 'components/Button/CustomButton'
import {
  DESTINATION_TYPE_OPTIONS,
  VEHICLE_FILLING_RATE_CALCULATION_OPTIONS,
  TRANSPORT_TYPE_OPTIONS,
} from 'constants/constants'
import { AuthContext } from 'store/AuthContext'
import { formatDeliveryTypes, getApplicationVersionValue } from 'utils/functions'
import MultiLevelCard from 'components/MultiLevelCard/MultiLevelCard'
import { IApplicationVersion, OptimizationEngine } from 'interfaces/interfaces'
import useCardStyles from '../../../components/CustomCard/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerDetails: {
      fontWeight: 'bold',
      backgroundColor: theme.color.backgroundPaperTitle,
      color: theme.color.secondary,
    },
    infoContainer: {
      margin: '0 auto',
      position: 'relative',
      display: 'flex',
    },
    contentDetails: {
      overflow: 'auto',
      padding: 0,
      maxHeight: '512px',
      backgroundColor: theme.palette.background.default,
      paddingBottom: '0 !important',
    },
    detailsContainer: {
      display: 'flex',
      marginBottom: '20px',
      justifyContent: 'space-between',
    },
    kvrpContainer: {
      marginLeft: '50px',
    },
    appointmentContainer: {
      width: '100%',
    },
  }),
)

const WarehouseAdminDetailsScreen = (): JSX.Element => {
  const { id } = useParams<IIdParam>()
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()
  const cardClasses = useCardStyles()
  const { getDetails, siteDetails } = useContext(SitesContext)
  const { user } = useContext(AuthContext)
  const userDeliveryTypes = formatDeliveryTypes(user?.tenantConfig?.deliveryTypes)

  useEffect(() => {
    if (id) getDetails(id, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const handleEditClick = (): void => history.push(`${ROUTES_PATH.editWarehouseAdmin}${id}`)

  const displayBoolean = (value: boolean | undefined): string =>
    value !== undefined ? (value ? t('SitesScreen.yes') : t('SitesScreen.no')) : ''

  return (
    <>
      <div className={classes.detailsContainer}>
        <h3 data-cy="siteDetailDescription">{`${siteDetails ? siteDetails.name : ''}`}</h3>
        <Button dataCy="updateButton" onPress={handleEditClick}>
          {t('SitesScreen.edit')}
        </Button>
      </div>
      <Grid xs={10} className={classes.infoContainer}>
        <Grid item xs={6}>
          <CustomCard
            cardTitle={t('SitesScreen.optimParams')}
            linePairs={[
              {
                label: t('SitesScreen.optimParam.isOptimizable'),
                value: displayBoolean(siteDetails.optimParams?.isOptimizable),
              },
              {
                label: t('SitesScreen.optimParam.isMultisite'),
                value: displayBoolean(siteDetails.optimParams?.isMultisite),
              },
              {
                label: t('SitesScreen.optimParam.multiTourPermission'),
                value: displayBoolean(siteDetails.optimParams?.multiTourPermission),
              },
              {
                label: t('SitesScreen.optimParam.nbToursPerVehicle'),
                value: siteDetails.optimParams?.nbToursPerVehicle?.toString(),
              },
              {
                label: t('SitesScreen.optimParam.delayBetweenTours'),
                value: siteDetails.optimParams?.delayBetweenTours?.toString(),
              },
              {
                label: t('SitesScreen.optimParam.daysBeforePlansExpiration'),
                value: siteDetails.optimParams?.daysBeforePlansExpiration?.toString(),
              },
              {
                label: t('SitesScreen.optimParam.optimizationEngine'),
                value: siteDetails.optimParams?.optimizationEngine,
              },
              {
                label: t('SitesScreen.optimParam.optimizationItineraryCalculator'),
                value: siteDetails.optimParams?.optimizationItineraryCalculator,
              },
              {
                label: t('SitesScreen.optimParam.maximumOptimizationTime'),
                value: siteDetails.optimParams?.maximumOptimizationTime?.toString(),
              },
              {
                label: t('SitesScreen.optimParam.speedMultiplier'),
                value: siteDetails.optimParams?.speedMultiplier?.toString(),
              },
              {
                label: t('SitesScreen.optimParam.setupDuration'),
                value: siteDetails.optimParams?.setupDuration?.toString(),
              },
              {
                label: t('SitesScreen.optimParam.visitDuration'),
                value: siteDetails.optimParams?.visitDuration?.toString(),
              },
              {
                label: t('SitesScreen.optimParam.loadingTimePerOrderMultisite'),
                value: siteDetails.optimParams?.loadingTimePerOrderMultisite?.toString(),
              },
              {
                label: t('SitesScreen.optimParam.maxDestinations'),
                value: siteDetails.optimParams?.maxDestinations?.toString(),
              },
              {
                label: t('SitesScreen.optimParam.optimizationMaxSplitSize'),
                value: siteDetails.optimParams?.optimizationMaxSplitSize?.toString(),
              },
              {
                label: t('SitesScreen.optimParam.optimizationStopSoftUpperBound'),
                value: siteDetails.optimParams?.optimizationStopSoftUpperBound?.toString(),
              },
              {
                label: t('SitesScreen.optimParam.optimizationVehicleSoftUpperBound'),
                value: siteDetails.optimParams?.optimizationVehicleSoftUpperBound?.toString(),
              },
              {
                label: t('SitesScreen.optimParam.optimizationCostWaitingTime'),
                value: siteDetails.optimParams?.optimizationCostWaitingTime?.toString(),
              },
              {
                label: t('SitesScreen.optimParam.routerOptions.traffic'),
                value: displayBoolean(siteDetails.optimParams?.routerOptions?.traffic),
              },
              {
                label: t('SitesScreen.optimParam.routerOptions.motorway'),
                value: displayBoolean(siteDetails.optimParams?.routerOptions?.motorway),
              },
              {
                label: t('SitesScreen.optimParam.routerOptions.toll'),
                value: displayBoolean(siteDetails.optimParams?.routerOptions?.toll),
              },
              {
                label: t('SitesScreen.optimParam.routerOptions.maxWalkDistance'),
                value: siteDetails.optimParams?.routerOptions?.maxWalkDistance?.toString(),
              },
              {
                label: t('SitesScreen.optimParam.routerOptions.strictRestriction'),
                value: displayBoolean(siteDetails.optimParams?.routerOptions?.strictRestriction),
              },
              {
                label: t('SitesScreen.averageLoadingTime'),
                value: siteDetails.averageLoadingTime?.toString(),
              },
              {
                label: t('SitesScreen.optimParam.toleratedAdvance'),
                value: siteDetails.optimParams?.toleratedAdvance?.toString(),
              },
              {
                label: t('SitesScreen.optimParam.toleratedDelay'),
                value: siteDetails.optimParams?.toleratedDelay?.toString(),
              },
            ]}
          />
        </Grid>
        <Grid item xs={6} className={classes.kvrpContainer}>
          <Grid container direction="column" spacing={4}>
            {siteDetails.optimParams?.optimizationEngine === OptimizationEngine.Atoptima ? (
              <Grid item xs>
                <CustomCard cardTitle={t('SitesScreen.atoptimaParams')}>
                  <MultiLevelCard
                    items={[
                      {
                        title: t('SitesScreen.atoptimaParam.app').toUpperCase(),
                        linePairs: [
                          {
                            label: t('SitesScreen.atoptimaParam.appClassic').toUpperCase(),
                            value: getApplicationVersionString(
                              siteDetails.optimParams.atoptimaParams?.appClassic,
                            ),
                          },
                          {
                            label: t('SitesScreen.atoptimaParam.appMultisites').toUpperCase(),
                            hidden: !siteDetails.optimParams?.isMultisite,
                            value: getApplicationVersionString(
                              siteDetails.optimParams.atoptimaParams?.appMultisites,
                            ),
                          },
                          {
                            label: t('SitesScreen.atoptimaParam.appMultitours').toUpperCase(),
                            hidden: !siteDetails.optimParams?.multiTourPermission,
                            value: getApplicationVersionString(
                              siteDetails.optimParams.atoptimaParams?.appMultitours,
                            ),
                          },
                        ],
                      },
                      {
                        title: t('SitesScreen.atoptimaParam.hourlyPrecedence').toUpperCase(),
                        notWithinSection: true,
                        titleValue: siteDetails.optimParams?.atoptimaParams?.hourlyPrecedence
                          ? t('SitesScreen.yes')
                          : t('SitesScreen.no'),
                      },
                      {
                        title: t(
                          'SitesScreen.atoptimaParam.uncoveredOperationsViolationPerUnit',
                        ).toUpperCase(),
                        notWithinSection: true,
                        titleValue:
                          siteDetails.optimParams?.atoptimaParams?.uncoveredOperationsViolationPerUnit?.toString(),
                      },
                      {
                        title: t(
                          'SitesScreen.atoptimaParam.directPrecedenceViolationPerUnit',
                        ).toUpperCase(),
                        notWithinSection: true,
                        titleValue:
                          siteDetails.optimParams?.atoptimaParams?.directPrecedenceViolationPerUnit?.toString(),
                      },
                      {
                        title: t('SitesScreen.atoptimaParam.weightViolationPerUnit').toUpperCase(),
                        notWithinSection: true,
                        titleValue:
                          siteDetails.optimParams?.atoptimaParams?.weightViolationPerUnit?.toString(),
                      },
                      {
                        title: t('SitesScreen.atoptimaParam.volumeViolationPerUnit').toUpperCase(),
                        notWithinSection: true,
                        titleValue:
                          siteDetails.optimParams?.atoptimaParams?.volumeViolationPerUnit?.toString(),
                      },
                      {
                        title: t('SitesScreen.atoptimaParam.timeViolationPerSecond').toUpperCase(),
                        notWithinSection: true,
                        titleValue:
                          siteDetails.optimParams?.atoptimaParams?.timeViolationPerSecond?.toString(),
                      },
                    ]}
                  />
                </CustomCard>
              </Grid>
            ) : siteDetails.optimParams?.optimizationEngine === OptimizationEngine.Kvrp ? (
              <Grid item xs>
                <CustomCard
                  cardTitle={t('SitesScreen.mapotempoConfigs')}
                  linePairs={[
                    {
                      label: t('SitesScreen.mapotempoConfig.clientId'),
                      value: siteDetails.kVrpConfig?.clientId,
                    },
                    {
                      label: t('SitesScreen.mapotempoConfig.url'),
                      value: siteDetails.kVrpConfig?.url,
                    },
                    {
                      label: t('SitesScreen.mapotempoConfig.parcelDeliverableUnitId'),
                      value: siteDetails.kVrpConfig?.parcelDeliverableUnitId,
                    },
                    {
                      label: t('SitesScreen.mapotempoConfig.clientDeliverableUnitId'),
                      value: siteDetails.kVrpConfig?.clientDeliverableUnitId,
                    },
                  ]}
                />
              </Grid>
            ) : null}
            <Grid item xs>
              <CustomCard
                cardTitle={t('SitesScreen.orderScreen')}
                linePairs={[
                  {
                    label: t('SitesScreen.orderScreenConfig.editServiceTime'),
                    value:
                      siteDetails.orderScreen?.editServiceTime !== undefined
                        ? siteDetails.orderScreen?.editServiceTime
                          ? t('SitesScreen.yes')
                          : t('SitesScreen.no')
                        : '',
                  },
                  {
                    label: t('SitesScreen.orderScreenConfig.editDeliveryTime'),
                    value:
                      siteDetails.orderScreen?.editDeliveryTime !== undefined
                        ? siteDetails.orderScreen?.editDeliveryTime
                          ? t('SitesScreen.yes')
                          : t('SitesScreen.no')
                        : '',
                  },
                ]}
              />
            </Grid>
            <Grid item xs>
              <CustomCard
                cardTitle={t('SitesScreen.orderScreenCreation')}
                linePairs={[
                  {
                    label: t('SitesScreen.orderScreenConfig.defaultDestinationType'),
                    value: DESTINATION_TYPE_OPTIONS.find(
                      (option) => option.id === siteDetails.orderScreen?.defaultDestinationType,
                    )?.name,
                  },
                  {
                    label: t('SitesScreen.orderScreenConfig.defaultDeliveryType'),
                    value: userDeliveryTypes.find(
                      (deliveryType) =>
                        deliveryType.code === siteDetails.orderScreen?.defaultDeliveryType,
                    )?.label,
                  },
                  {
                    label: t('SitesScreen.orderScreenConfig.defaultTransportType'),
                    value: TRANSPORT_TYPE_OPTIONS.find(
                      (option) => option.id === siteDetails.orderScreen?.defaultTransportType,
                    )?.name,
                  },
                ]}
              />
            </Grid>
            <Grid item xs>
              <CustomCard
                cardTitle={t('SitesScreen.executionScreen')}
                linePairs={[
                  {
                    label: t('SitesScreen.executionScreenConfig.vehicleFillingRateCalculation'),
                    value: VEHICLE_FILLING_RATE_CALCULATION_OPTIONS.find(
                      (option) =>
                        option.id === siteDetails.executionScreen?.vehicleFillingRateCalculation,
                    )?.name,
                  },
                ]}
              />
            </Grid>
            <Grid item xs>
              <CustomCard
                cardTitle={t('SitesScreen.executionTrackingScreen')}
                linePairs={[
                  {
                    label: t(
                      'SitesScreen.executionTrackingScreenConfigs.delayAlertOnTourBeforeDeparture',
                    ),
                    value:
                      siteDetails.executionTrackingScreen?.delayAlertOnTourBeforeDeparture?.toString(),
                  },
                ]}
              />
            </Grid>
            <Grid item xs>
              <CustomCard
                cardTitle={t('SitesScreen.planningEndCustomerPage')}
                linePairs={[
                  {
                    label: t('SitesScreen.planningEndCustomerPageConfigs.driverInformation'),
                    value: displayBoolean(siteDetails.planningEndCustomerPage?.driverInformation),
                  },
                  {
                    label: t('SitesScreen.planningEndCustomerPageConfigs.driverPosition'),
                    value: displayBoolean(siteDetails.planningEndCustomerPage?.driverPosition),
                  },
                  {
                    label: t('SitesScreen.planningEndCustomerPageConfigs.driverPhoneNumber'),
                    value: displayBoolean(siteDetails.planningEndCustomerPage?.driverPhoneNumber),
                  },
                  {
                    label: t('SitesScreen.planningEndCustomerPageConfigs.customerCare'),
                    value: displayBoolean(siteDetails.planningEndCustomerPage?.customerCare),
                  },
                  {
                    label: t('SitesScreen.planningEndCustomerPageConfigs.customerEvaluation'),
                    value: displayBoolean(siteDetails.planningEndCustomerPage?.customerEvaluation),
                  },
                  {
                    label: t('SitesScreen.planningEndCustomerPageConfigs.customerComments'),
                    value: displayBoolean(siteDetails.planningEndCustomerPage?.customerComments),
                  },
                  {
                    label: t('SitesScreen.planningEndCustomerPageConfigs.clientCode'),
                    value: displayBoolean(siteDetails.planningEndCustomerPage?.clientCode),
                  },
                ]}
              />
            </Grid>
            <Grid item xs className={classes.appointmentContainer}>
              <CustomCard
                cardTitle={t('SitesScreen.appointmentEndCustomerPage')}
                linePairs={[
                  {
                    label: t('SitesScreen.appointmentEndCustomerPageConfigs.showUnavailableSlots'),
                    value: displayBoolean(
                      siteDetails.appointmentEndCustomerPage?.showUnavailableSlots,
                    ),
                  },
                  {
                    label: t(
                      'SitesScreen.appointmentEndCustomerPageConfigs.canPilotReserveTimeSlots',
                    ),
                    value: displayBoolean(
                      siteDetails.appointmentEndCustomerPage?.canPilotReserveTimeSlots,
                    ),
                  },
                ]}
              />
            </Grid>
            <Grid item xs>
              <CustomCard
                cardTitle={t('SitesScreen.deliveryInformationEndCustomerPage')}
                linePairs={[
                  {
                    label: t(
                      'SitesScreen.deliveryInformationEndCustomerPageConfigs.deliverySlotAmplitude',
                    ),
                    value:
                      siteDetails.deliveryInformationEndCustomerPage?.deliverySlotAmplitude?.toString(),
                  },
                  {
                    label: t('SitesScreen.deliveryInformationEndCustomerPageConfigs.message'),
                    value: siteDetails.deliveryInformationEndCustomerPage?.message,
                  },
                  {
                    label: t(
                      'SitesScreen.deliveryInformationEndCustomerPageConfigs.showInstructions',
                    ),
                    value: displayBoolean(
                      siteDetails.deliveryInformationEndCustomerPage?.showInstructions,
                    ),
                  },
                  {
                    label: t('SitesScreen.deliveryInformationEndCustomerPageConfigs.showFloor'),
                    value: displayBoolean(
                      siteDetails.deliveryInformationEndCustomerPage?.showFloor,
                    ),
                  },
                  {
                    label: t('SitesScreen.deliveryInformationEndCustomerPageConfigs.showElevator'),
                    value: displayBoolean(
                      siteDetails.deliveryInformationEndCustomerPage?.showElevator,
                    ),
                  },
                ]}
              />
            </Grid>
            <Grid item xs>
              <CustomCard
                cardTitle={t('SitesScreen.planningMobile')}
                contentClassName={cardClasses.bg}
              >
                <MultiLevelCard
                  items={[
                    {
                      title: t('SitesScreen.tour').toUpperCase(),
                      linePairs: [
                        {
                          label: t('SitesScreen.tourNumberControl').toUpperCase(),
                          value:
                            siteDetails.planningMobile?.tourControl !== undefined
                              ? siteDetails.planningMobile.tourControl
                                ? t('SitesScreen.yes')
                                : t('SitesScreen.no')
                              : '',
                        },
                      ],
                    },
                    {
                      title: t('SitesScreen.shift').toUpperCase(),
                      titleValue:
                        siteDetails.planningMobile?.shift !== undefined
                          ? siteDetails.planningMobile.shift
                            ? t('SitesScreen.yes')
                            : t('SitesScreen.no')
                          : '',
                      linePairs: siteDetails?.planningMobile?.shift
                        ? [
                          {
                            label: t('SitesScreen.vehicleNote').toUpperCase(),
                            value:
                                siteDetails.planningMobile?.vehicleNote !== undefined
                                  ? siteDetails.planningMobile.vehicleNote
                                    ? t('SitesScreen.yes')
                                    : t('SitesScreen.no')
                                  : '',
                          },
                        ]
                        : [],
                    },
                    {
                      title: t('SitesScreen.parcelLoadingControl').toUpperCase(),
                      titleValue:
                        siteDetails.planningMobile?.parcelLoadingControl !== undefined
                          ? siteDetails.planningMobile.parcelLoadingControl
                            ? t('SitesScreen.yes')
                            : t('SitesScreen.no')
                          : '',
                    },
                    {
                      title: t('SitesScreen.passagePoint').toUpperCase(),
                      linePairs: [
                        {
                          label: t('SitesScreen.scanning').toUpperCase(),
                          value:
                            siteDetails.planningMobile?.unloadingPackage !== undefined
                              ? siteDetails.planningMobile.unloadingPackage
                                ? t('SitesScreen.yes')
                                : t('SitesScreen.no')
                              : '',
                        },
                      ],
                    },
                    {
                      title: t('SitesScreen.scanUnknownParcelsToPickup').toUpperCase(),
                      titleValue:
                        siteDetails.planningMobile?.scanUnknownParcelsToPickup !== undefined
                          ? siteDetails.planningMobile.scanUnknownParcelsToPickup
                            ? t('SitesScreen.yes')
                            : t('SitesScreen.no')
                          : '',
                    },
                    {
                      title: t('SitesScreen.services').toUpperCase(),
                      titleValue:
                        siteDetails.planningMobile?.services !== undefined
                          ? siteDetails.planningMobile.services
                            ? t('SitesScreen.yes')
                            : t('SitesScreen.no')
                          : '',
                    },
                    {
                      title: t('SitesScreen.proofOfDelivery').toUpperCase(),
                      linePairs: [
                        {
                          label: t('SitesScreen.returnables').toUpperCase(),
                          value:
                            siteDetails.planningMobile?.returnables !== undefined
                              ? siteDetails.planningMobile.returnables
                                ? t('SitesScreen.yes')
                                : t('SitesScreen.no')
                              : '',
                        },
                        {
                          label: t('SitesScreen.evaluation').toUpperCase(),
                          value:
                            siteDetails.planningMobile?.evaluation !== undefined
                              ? siteDetails.planningMobile.evaluation
                                ? t('SitesScreen.yes')
                                : t('SitesScreen.no')
                              : '',
                        },
                        {
                          label: t('SitesScreen.signature').toUpperCase(),
                          value:
                            siteDetails.planningMobile?.signature !== undefined
                              ? siteDetails.planningMobile.signature
                                ? t('SitesScreen.yes')
                                : t('SitesScreen.no')
                              : '',
                        },
                        {
                          label: t('SitesScreen.clientCode').toUpperCase(),
                          value:
                            siteDetails.planningMobile?.clientCode !== undefined
                              ? siteDetails.planningMobile.clientCode
                                ? t('SitesScreen.yes')
                                : t('SitesScreen.no')
                              : '',
                        },
                      ],
                    },
                    {
                      title: t('SitesScreen.arrivalReport').toUpperCase(),
                      titleValue:
                        siteDetails.planningMobile?.arrivalReport !== undefined
                          ? siteDetails.planningMobile.arrivalReport
                            ? t('SitesScreen.yes')
                            : t('SitesScreen.no')
                          : '',
                    },
                    {
                      title: t('SitesScreen.additionalFeatures').toUpperCase(),
                      linePairs: [
                        {
                          label: t('SitesScreen.dispute').toUpperCase(),
                          value:
                            siteDetails.planningMobile?.dispute !== undefined
                              ? siteDetails.planningMobile.dispute
                                ? t('SitesScreen.yes')
                                : t('SitesScreen.no')
                              : '',
                        },
                      ],
                    },
                  ]}
                />
              </CustomCard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

function getApplicationVersionString(appVersion?: IApplicationVersion): string {
  return appVersion ? getApplicationVersionValue(appVersion) : ''
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <SitesProvider>
    <WarehouseAdminDetailsScreen {...props} />
  </SitesProvider>
)
