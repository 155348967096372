import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Analytics from 'react-router-ga'

import NavigationLayout from 'components/NavigationLayout'

import PrivateRoute from './PrivateRoute'
import { NAVIGATION_CONFIG } from './NavigationConfig'

const RootNavigator = (): JSX.Element => (
  <Router basename="/planning">
    <Analytics id={process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID} basename="/planning">
      <NavigationLayout>
        <Switch>
          {NAVIGATION_CONFIG.map((route) => {
            if (route.isPrivate) {
              return (
                <PrivateRoute
                  path={route.path}
                  roles={route.roles}
                  isBetaMode={route.isBetaMode}
                  key={route.path}
                  exact={route.exact}
                  featureFlagName={route.featureFlagName}
                >
                  <route.component />
                </PrivateRoute>
              )
            }
            return (
              <Route path={route.path} key={route.path}>
                <route.component />
              </Route>
            )
          })}
        </Switch>
      </NavigationLayout>
    </Analytics>
  </Router>
)

export default RootNavigator
