import React, { useState, useEffect, useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import styled from 'styled-components'

import {
  ICondition,
  IRoutingRule,
  IRoutingSegment,
  IRuleInformation,
} from 'interfaces/IRoutingRule'
import { IIdParam } from 'interfaces/IIdParam'
import FormAction from 'components/Button/FormAction'
import Button from 'components/Button/CustomButton'
import useStyles from 'constants/cruStyles'
import RoutingRulesApi from 'api/routingRules'
import { isIError } from 'api/types'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import { FeedbackContext } from 'store/FeedbackContext'
import { getErrorList } from 'utils/errorUtils'
import { isDefined } from 'utils/functions'
import { RuleType } from 'constants/constants'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog'
import ConditionCard from './ConditionCard'
import RoutingSegmentsCard from './RoutingSegmentsCard'
import InformationCard from './InformationCard'
import RoutingRulesProvider, { RoutingRulesContext } from './RoutingStore'

const HeaderContainer = styled(Grid)`
  padding-top: 16px;
  padding-bottom: 16px;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  align-items: center;
`

const RoutingRulesForm = (): JSX.Element => {
  const { editMode } = useContext(RoutingRulesContext)
  const [routingRules, setRoutingRules] = useState<Partial<IRoutingRule>>({} as IRoutingRule)
  const [ruleInformation, setRuleInformation] = useState<IRuleInformation>({
    active: true,
  } as IRuleInformation)
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState<boolean>(false)
  const { id } = useParams<IIdParam>()
  const history = useHistory()
  const { openErrorSnack, toggleLoader, openSuccessSnack } = useContext(FeedbackContext)
  const classes = useStyles()
  const { t } = useTranslation()

  const getDetails = async () => {
    toggleLoader(true)
    const response = await RoutingRulesApi.get(id)
    if (!isIError(response)) {
      setRoutingRules(response)
      setRuleInformation({
        label: response.label,
        priority: response.priority,
        active: response.active,
      })
    } else {
      openErrorSnack(response.error.message)
    }
    toggleLoader(false)
  }

  useEffect(() => {
    if (id) {
      getDetails()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const isDestinationValid = (data: IRoutingRule): boolean => {
    const isValid = !(
      data.condition?.destination?.type === RuleType.Site && !data.condition?.destination.siteId
    )
    return (
      isValid
      && (data.routingSegments?.length
        ? !data.routingSegments.find(
          (routingSegment) =>
            routingSegment.destination?.type === RuleType.Site
              && !routingSegment.destination.siteId,
        )
        : true)
    )
  }

  const isDestinationSiteAreaValid = (data: IRoutingRule): boolean =>
    !(
      data.condition?.destination?.type === RuleType.SiteArea
      && (!data.condition?.destination.perimeter || !data.condition?.destination?.perimeterMode)
    )

  const isDestinationZipCodeValid = (data: IRoutingRule): boolean =>
    !(
      data.condition?.destination?.type === RuleType.ZipCode
      && (data.condition?.destination.zipCodes || []).length === 0
    )

  const isDestinationSectorValid = (data: IRoutingRule): boolean =>
    !(
      data.condition?.destination?.type === RuleType.Sector
      && (data.condition?.destination.sectorIds || []).length === 0
    )

  const handleSaveClick = async (): Promise<void> => {
    if (
      !isDefined(routingRules?.condition?.origin?.type)
      || !isDefined(routingRules?.condition?.origin?.siteId)
    ) {
      openErrorSnack(t('RoutingRulesScreen.emptyOriginSiteErrorMessage'))
      return
    }

    if (routingRules?.condition?.origin?.type === RuleType.SiteArea) {
      if (!isDefined(routingRules?.condition?.origin?.perimeter)) {
        openErrorSnack(t('RoutingRulesScreen.emptyOrignPerimeterErrorMessage'))
        return
      }
      if (!isDefined(routingRules?.condition?.origin?.perimeterMode)) {
        openErrorSnack(t('RoutingRulesScreen.emptyOrignPerimeterModeErrorMessage'))
        return
      }
    }

    if (
      routingRules?.condition?.minWeight
      && routingRules?.condition?.maxWeight
      && routingRules?.condition?.minWeight >= routingRules?.condition?.maxWeight
    ) {
      openErrorSnack(t('RoutingRulesScreen.weightValidationFailed'))
      return
    }

    const data = {
      ...routingRules,
      label: ruleInformation?.label,
      priority: ruleInformation?.priority,
      active: ruleInformation.active,
      routingSegments:
        routingRules.routingSegments
        && routingRules.routingSegments.map((routingSegment) => ({
          ...routingSegment,
        })),
    }
    if (!isDestinationValid(data as IRoutingRule)) {
      openErrorSnack(t('RoutingRulesScreen.emptyDestinationErrorMessage'))
      return
    }
    if (!isDestinationSiteAreaValid(data as IRoutingRule)) {
      openErrorSnack(t('RoutingRulesScreen.emptyDestinationPerimeterErrorMessage'))
      return
    }
    if (!isDestinationZipCodeValid(data as IRoutingRule)) {
      openErrorSnack(t('RoutingRulesScreen.emptyDestinationZipCodeErrorMessage'))
      return
    }
    if (!isDestinationSectorValid(data as IRoutingRule)) {
      openErrorSnack(t('RoutingRulesScreen.emptyDestinationSectorErrorMessage'))
      return
    }
    toggleLoader(true)
    const response = id
      ? await RoutingRulesApi.update(id, data as IRoutingRule)
      : await RoutingRulesApi.create(data as IRoutingRule)
    if (!isIError(response)) {
      openSuccessSnack(
        id
          ? t('RoutingRulesScreen.successfullyUpdated')
          : t('RoutingRulesScreen.successfullyCreated'),
      )
      history.push(ROUTES_PATH.routingRulesList)
    } else {
      response.error.errorList = getErrorList(response)
      openErrorSnack(
        response.error.fieldErrors ? response.error.errorList.join('\n') : response.error.message,
      )
    }
    toggleLoader(false)
  }

  const handleCancelClick = async (): Promise<void> => {
    history.push(ROUTES_PATH.routingRulesList)
  }

  const handleEditClick = () => {
    history.push(`${ROUTES_PATH.editRoutingRules}${id}`)
  }

  const handleConditionChange = (condition: Partial<ICondition>): void => {
    setRoutingRules({ ...routingRules, condition })
  }

  const handleRulesChange = (routingSegments: IRoutingSegment[]): void => {
    setRoutingRules({ ...routingRules, routingSegments })
  }

  const handleDeleteConfirmation = async (validate: boolean): Promise<void> => {
    setIsDeleteConfirmationModalOpen(false)
    if (validate) {
      const response = await RoutingRulesApi.deleteRule(id)
      if (!isIError(response)) {
        openSuccessSnack(t('RoutingRulesScreen.successfullyDeleted'))
        history.push(ROUTES_PATH.routingRulesList)
      } else {
        openErrorSnack(response.error.message)
      }
    }
  }

  const handleDeleteClick = () => {
    setIsDeleteConfirmationModalOpen(true)
  }

  return (
    <Grid container>
      {!editMode && (
        <HeaderContainer className={clsx(classes.infoContainer)} item xs={11}>
          <div>{ruleInformation?.label}</div>
          <div className={classes.detailActionButtonsContainer}>
            <Button dataCy="deleteButton" variant="outlined" onPress={handleDeleteClick}>
              {t('RoutingRulesScreen.delete')}
            </Button>
            <Button dataCy="updateButton" onPress={handleEditClick}>
              {t('RoutingRulesScreen.edit')}
            </Button>
          </div>
        </HeaderContainer>
      )}
      <InformationCard ruleInformation={ruleInformation} onInfoChange={setRuleInformation} />
      <ConditionCard
        condition={routingRules?.condition}
        onConditionChange={handleConditionChange}
      />
      <RoutingSegmentsCard
        routingSegments={routingRules?.routingSegments}
        onRulesChange={handleRulesChange}
      />
      {editMode && (
        <FormAction gridSize={11} onSaveClick={handleSaveClick} onCancelClick={handleCancelClick} />
      )}
      {!editMode && (
        <ConfirmationDialog
          open={isDeleteConfirmationModalOpen}
          onClose={handleDeleteConfirmation}
          message={t('RoutingRulesScreen.deleteRuleConfirmationMessage')}
        />
      )}
    </Grid>
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <RoutingRulesProvider>
    <RoutingRulesForm {...props} />
  </RoutingRulesProvider>
)
