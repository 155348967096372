import React, { useState, ReactNode, useContext } from 'react'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import useConstant from 'use-constant'

import { IPlanTourFilters } from 'interfaces'
import { COLUMNS_MODEL_PLAN_LIST } from 'constants/table'
import PlansApi from 'api/plans'
import { isIError } from 'api/types'
import { FeedbackContext } from 'store/FeedbackContext'
import { IPlanTour } from 'interfaces/IPlan'
import { useTranslation } from 'react-i18next'

interface IPlanToursContext {
  planTours: IPlanTour[]
  count: number
  updatePlanTours: (
    filters?: IPlanTourFilters,
    offset?: number,
    rowsPerPage?: number,
    sortField?: number,
    sortDirection?: string,
  ) => void
  updatePlanTourStatus: (planTour: { planTourId: string; status: string | null }) => Promise<void>
}

const PlansContext = React.createContext<IPlanToursContext>({} as IPlanToursContext)
const { Provider, Consumer } = PlansContext

interface IProps {
  children: ReactNode
}

const PlansProvider = ({ children }: IProps): JSX.Element => {
  const [planTours, setPlanTours] = useState<IPlanTour[]>([])
  const [count, setCount] = useState<number>(0)
  const { openErrorSnack, openSuccessSnack, toggleLoader } = useContext(FeedbackContext)
  const { t } = useTranslation()

  const getPlanTours = useConstant(() => AwesomeDebouncePromise(PlansApi.getPlanToursList, 500))

  const updatePlanTours = async (
    filters?: IPlanTourFilters,
    offset?: number,
    rowsPerPage?: number,
    sortField?: number,
    sortDirection?: string,
  ): Promise<void> => {
    toggleLoader(true)
    const res = await getPlanTours(
      filters,
      offset,
      rowsPerPage,
      COLUMNS_MODEL_PLAN_LIST.find((_column, index) => index === sortField)?.field,
      sortDirection === 'desc' ? -1 : 1,
    )
    if (isIError(res)) {
      openErrorSnack(res.error.message)
    } else {
      setPlanTours(res.planTours)
      setCount(res.count)
    }
    toggleLoader(false)
  }

  function updateSinglePlanTourStatus(planTour: IPlanTour): void {
    setPlanTours((prev): IPlanTour[] => {
      const planToursArray = [...prev]
      const index = planToursArray.findIndex((elem) => elem.planToursId === planTour.planToursId)
      if (index !== -1) {
        planToursArray[index].status = planTour.status
      }
      return planToursArray
    })
  }

  async function updatePlanTourStatus({
    planTourId,
    status,
  }: {
    planTourId: string
    status: string | null
  }): Promise<void> {
    toggleLoader(true)
    const res = await PlansApi.updatePlanTourStatus({ planTourId, status })
    if (isIError(res)) {
      openErrorSnack(res.error.message)
    } else {
      updateSinglePlanTourStatus(res)
      openSuccessSnack(t('PlanToursScreen.changeSaved'))
    }
    toggleLoader(false)
  }

  return (
    <Provider
      value={{
        planTours,
        count,
        updatePlanTours,
        updatePlanTourStatus,
      }}
    >
      {children}
    </Provider>
  )
}

export default PlansProvider

export { PlansContext, Consumer as PlansConsumer }
