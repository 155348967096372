import { Theme, Tooltip, createStyles, makeStyles } from '@material-ui/core'
import React, { useContext } from 'react'
import i18n from 'i18next'
import moment from 'moment'
import { ITour, IStop, IVisitData } from 'interfaces/Itours'
import { dateDefaultFormat, dateToTimeFormat, getTimeFromDateString } from 'utils/dateFormat'
import { ContentContext } from 'store/ContentContext'
import { AuthContext } from 'store/AuthContext'
import { IOrder, IVisit } from 'interfaces/IOrders'
import { IUser } from 'interfaces/IUser'
import { ICarrier } from 'interfaces/ICarrier'
import { IDriver } from 'interfaces/IDriver'
import { IVehicle } from 'interfaces/IVehicle'
import { ListSelectDriver, ListSelectVehicle } from 'components/Inputs/ListSelect'
import { IDealer, ITableColumn, IWarehouse } from 'interfaces/interfaces'
import { IVehicleType } from 'interfaces/IVehicleType'
import { IPlanTemplate } from 'interfaces/IPlanTemplate'
import { IWebhook } from 'interfaces/IWebhook'
import {
  ORDER_PLAN_STATUSES,
  StopStatus,
  PLAN_TOUR_STATUS,
  ROLES_USER_OPTIONS,
  CUSTOMER_NOTIFICATION_TYPE,
  TRANSPORT_TYPE_OPTIONS,
  TransportType,
  TOUR_STATUS_MAP,
  TOUR_STATUS_ENUM,
  CancellationReasonType,
  TOUR_STATUS,
} from 'constants/constants'
import { IPlanTour } from 'interfaces/IPlan'
import { IUserInfos } from 'interfaces/IUserInfos'
import { IImportJob } from 'interfaces/IImportJob'
import { IRoutingRule } from 'interfaces/IRoutingRule'
import { IDeliveryType } from 'interfaces/IDeliveryType'
import { ISector } from 'interfaces/ISector'
import { INotifierConfiguration } from 'interfaces/INotifierConfiguration'
import { IAlert } from 'interfaces/IAlert'
import ImageLine from 'components/ImageLine/ImageLine'
import { DisputeContext } from 'store/DisputeContext'
import { IDispute } from 'interfaces/IDispute'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import TourNumberWithAlert from 'components/TourNumberWithAlert/TourNumberWithAlert'
import LocationRelevanceWithAlert from 'components/LocationRelevanceWithAlert/LocationRelevanceWithAlert'
import { ICancellationReason } from 'interfaces/ICancellationReason'
import { IApikey } from 'interfaces/IApikey'
import StopDelay from 'components/Table/StopDelay'
import TourRefusedAlert from 'components/TourRefusedAlert/TourRefusedAlert'
import DeliveryTimeSlotModifiedAlert from 'components/Table/DeliveryTimeSlotModifiedAlert/DeliveryTimeSlotModifiedAlert'
import { ITag } from 'interfaces/ITag'
import MaterialTable from 'material-table'
import { formatDeliveryTypes, isDefined } from './functions'
import {
  calculateStopClientPromiseDelay,
  getFinalStop,
  removeFirstAndLastStops,
} from './stopsUtils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
    },
    link: {
      color: theme.color.secondary,
      '&:hover': {
        color: theme.color.primary,
      },
    },
    tourStatusAndIconWrapper: {
      minWidth: '100px',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: '4px',
    },
  }),
)

function formatAmplitude(rowData: ITour): string {
  if (rowData.amplitude && rowData.amplitude > 0) {
    return moment.utc(rowData.amplitude).format('HH:mm')
  }
  return '-'
}

function formatPostalCodes(rowData: ITour): JSX.Element | undefined {
  if (!rowData.postalCodes) {
    return
  }
  if (rowData.postalCodes.length <= 3) {
    // eslint-disable-next-line consistent-return
    return <>{rowData.postalCodes?.join(' ')}</>
  }
  // eslint-disable-next-line consistent-return
  return (
    <Tooltip title={rowData.postalCodes?.join(' ')}>
      <span>{`${rowData.postalCodes?.slice(0, 3).join(' ')}...`}</span>
    </Tooltip>
  )
}

function formatSpecifics(rowData: ITour | IStop): string | undefined {
  return rowData.specifics?.join(' ')
}

const formatCustomerName = (rowData: IOrder): string =>
  `${rowData?.customer?.firstName} ${rowData?.customer?.lastName}`

function formatStartDate(rowData: ITour): string {
  if (rowData.departureDateTime) {
    return dateDefaultFormat(rowData.departureDateTime)
  }
  return ''
}

function formatEndDate(rowData: ITour): string {
  if (rowData.arrivalDateTime) {
    return dateDefaultFormat(rowData.arrivalDateTime)
  }
  return ''
}

function formatVisitFloors(rowData: IStop): string | undefined {
  return Array.from(
    new Set(rowData.visitsToDeliver.map((visit) => visit.destinationAddress.floor)),
  ).join('; ')
}

function formatVisitHasElevator(rowData: IStop): string | undefined {
  return Array.from(
    new Set(
      rowData.visitsToDeliver.map((visit) =>
        i18n.t(`tablesEntries.${visit.destinationAddress.hasElevator ? 'yes' : 'no'}`),
      ),
    ),
  ).join('; ')
}

function formatVisitTags(rowData: IStop, tags: ITag[] | null): string {
  const visitsTagCodes = rowData.visitsToDeliver.reduce((acc: string[], visit: IVisitData) => {
    acc.push(...visit.tags)
    return acc
  }, [])
  return Array.from(new Set(visitsTagCodes))
    .map((value) => tags?.find((tag) => tag.code === value)?.label || value)
    .join(', ')
}

interface ISearchable {
  id: string
  name: string
}

function getName(array: ISearchable[], id?: string, displayPurpose?: boolean): JSX.Element {
  const data = array.find((elem: ISearchable) => elem.id === id)
  const name = data?.name || (displayPurpose ? '' : '-')
  return <>{name}</>
}

function getNameString(array: ISearchable[], id?: string, displayPurpose?: boolean): string {
  const data = array.find((elem: ISearchable) => elem.id === id)
  const name = data?.name || (displayPurpose ? '' : '-')
  return name
}

const getCarrierStatus = (carrier: ICarrier): JSX.Element =>
  carrier.active ? (
    <>{i18n.t('tablesEntries.status.active')}</>
  ) : (
    <>{i18n.t('tablesEntries.status.notActive')}</>
  )

const getDriverStatus = (driver: IDriver): JSX.Element =>
  driver.active ? (
    <>{i18n.t('tablesEntries.status.active')}</>
  ) : (
    <>{i18n.t('tablesEntries.status.notActive')}</>
  )

const getToOptimizeLabel = (planTour: IPlanTour): string =>
  planTour.toOptimize !== undefined
    ? planTour.toOptimize
      ? i18n.t('tablesEntries.yes')
      : i18n.t('tablesEntries.no')
    : ''

const getCarrierWarehouses = (carrier: ICarrier): JSX.Element => (
  <>
    {((carrier.warehouses && carrier.warehouses.map((warehouse) => warehouse.name)) || []).join(
      ', ',
    )}
  </>
)

const getEventsLabel = (webhook: IWebhook): string => (webhook.events || []).join(', ')

const getSitesLabel = (ids: string[], allSites: IWarehouse[], sites: IWarehouse[]): string => {
  // Function used when we need to get the sites label as a string and not as a JSX element
  const usedSites = allSites?.length ? allSites : sites
  return (
    ids?.map((id) => usedSites.find((site) => site.id === id)?.name).filter((name) => name) || []
  ).join(', ')
}

const GetSitesLabelById = ({ ids }: { ids: string[] }): JSX.Element => {
  const { allSites, sites } = useContext(ContentContext)
  return <>{getSitesLabel(ids, allSites, sites)}</>
}

const GetSectorsLabelById = ({ ids }: { ids: string[] }): JSX.Element => {
  const { allSites, sites } = useContext(ContentContext)
  const usedSites = allSites?.length ? allSites : sites
  const allSectors: ISector[] = []
  usedSites?.forEach((site) => {
    if (site?.sectors?.length) {
      allSectors.push(...site.sectors)
    }
  })
  return (
    <>{(ids?.map((id) => allSectors.find((sector) => sector.id === id)?.label) || []).join(', ')}</>
  )
}

const GetTenantNameById = ({ id }: { id: string }): JSX.Element => {
  const { tenants } = useContext(ContentContext)
  return <>{tenants.find((tenant) => tenant.id === id)?.name || []}</>
}

const WrapperGetSitesLabelById = (item: { siteIds: string[] }): JSX.Element => (
  <GetSitesLabelById ids={item.siteIds} />
)

const WrapperGetSiteLabelById = (item: { siteId: string }): JSX.Element => (
  <GetSitesLabelById ids={[item.siteId]} />
)

const WrapperGetWarehousesLabelById = (item: { warehouseIds: string[] }): JSX.Element => (
  <GetSitesLabelById ids={item.warehouseIds} />
)

const WrapperGetConcernedWarehousesLabelById = (item: {
  concernedWarehouseIds: string[]
}): JSX.Element => <GetSitesLabelById ids={item.concernedWarehouseIds} />

const WrapperGetSectorsLabelById = (item: { sectorIds: string[] }): JSX.Element => (
  <GetSectorsLabelById ids={item.sectorIds} />
)

const WrapperGetTenantNameFromId = (item: { tenantId: string }): JSX.Element => (
  <GetTenantNameById id={item.tenantId} />
)

const WrapperGetUserSites = (user: IUserInfos): JSX.Element => (
  <GetSitesLabelById ids={user.sites?.map(({ siteId }) => siteId) || []} />
)

const getVehicleStatus = (vehicle: IVehicle): JSX.Element =>
  vehicle.active ? (
    <>{i18n.t('tablesEntries.status.active')}</>
  ) : (
    <>{i18n.t('tablesEntries.status.notActive')}</>
  )

const getThirdPartyActivation = (vehicle: IVehicle): JSX.Element =>
  vehicle.thirdPartyActivation ? (
    <>{i18n.t('tablesEntries.yes')}</>
  ) : (
    <>{i18n.t('tablesEntries.no')}</>
  )

const getSiteStatus = (site: IWarehouse): JSX.Element =>
  site.active ? (
    <>{i18n.t('tablesEntries.status.active')}</>
  ) : (
    <>{i18n.t('tablesEntries.status.notActive')}</>
  )

const getSiteSector = (site: IWarehouse): JSX.Element =>
  site.sectors && site.sectors.length > 0 ? (
    <>{i18n.t('tablesEntries.status.hasSector')}</>
  ) : (
    <>{i18n.t('tablesEntries.status.hasNoSector')}</>
  )

const getVehicleTypeStatus = (vehicleType: IVehicleType): JSX.Element =>
  vehicleType.active ? (
    <>{i18n.t('tablesEntries.status.active')}</>
  ) : (
    <>{i18n.t('tablesEntries.status.notActive')}</>
  )

const getFullAddress = (site: IWarehouse): JSX.Element => <>{site.address.full}</>

const getSiteType = (site: IWarehouse): JSX.Element => (
  <>{i18n.t(`SitesScreen.type.${site.type}`)}</>
)

const getSiteTourType = (site: IWarehouse): JSX.Element => (
  <>{i18n.t(`SitesScreen.tourType.${site.tourType}`)}</>
)

function GetCarrierNameFromId({
  id,
  displayPurpose,
}: {
  id?: string
  displayPurpose?: boolean
}): JSX.Element {
  const { carriers } = useContext(ContentContext)
  return getName(carriers, id, displayPurpose)
}

// This is bullshit, but we can't use hooks as a direct child of render in material-table
function WrapperGetCarrierNameFromId(rowData: ITour): JSX.Element {
  return <GetCarrierNameFromId id={rowData.carrierId} />
}

function GetDriverNameFromId({
  id,
  displayPurpose,
}: {
  id?: string
  displayPurpose?: boolean
}): JSX.Element {
  const { drivers } = useContext(ContentContext)
  return getName(drivers, id, displayPurpose)
}

function GetCarrierNameFromIdString({
  id,
  displayPurpose,
}: {
  id?: string
  displayPurpose?: boolean
}): string {
  const { carriers } = useContext(ContentContext)
  return getNameString(carriers, id, displayPurpose)
}

function GetDriverNameFromIdString({
  id,
  displayPurpose,
}: {
  id?: string
  displayPurpose?: boolean
}): string {
  const { drivers } = useContext(ContentContext)
  return getNameString(drivers, id, displayPurpose)
}

// This is bullshit, but we can't use hooks as a direct child of render in material-table
function WrapperGetDriverNameFromId(rowData: ITour): JSX.Element {
  return <GetDriverNameFromId id={rowData.driverId} />
}

function GetVehicleNameFromId({
  id,
  displayPurpose,
}: {
  id?: string
  displayPurpose?: boolean
}): JSX.Element {
  const { vehicles } = useContext(ContentContext)
  return getName(vehicles, id, displayPurpose)
}

function WrapperGetVehicleNameFromId(rowData: ITour): JSX.Element {
  return <GetVehicleNameFromId id={rowData.vehicleId} />
}

const getDeliveryTypeLabel = (
  deliveryTypes: IDeliveryType[],
  deliveryTypeCode?: number,
): string => {
  let result = ''
  if (deliveryTypeCode !== undefined) {
    const foundType = deliveryTypes.find((type) => type.code === deliveryTypeCode)
    if (foundType) result = foundType.label
  }
  return result
}

const getDeliveryTypesLabel = (
  deliveryTypeCodes: string[],
  deliveryTypes: IDeliveryType[],
): string =>
  deliveryTypeCodes
    .map((code) => deliveryTypes.find((type) => type.code === +code)?.label || '')
    .join(',')

const GetDeliveryTypeLabelFromCode = ({
  deliveryTypeCode,
}: {
  deliveryTypeCode: number
}): JSX.Element => {
  const { user } = useContext(AuthContext)
  const userDeliveryTypes = formatDeliveryTypes(user?.tenantConfig?.deliveryTypes)
  return <>{getDeliveryTypeLabel(userDeliveryTypes, deliveryTypeCode)}</>
}

const GetDeliveryTypeLabelWrapped = (item: { deliveryType: number }): JSX.Element => (
  <GetDeliveryTypeLabelFromCode deliveryTypeCode={item.deliveryType} />
)

const getCancellationReasonLabelFromCode = (
  cancellationReasonType?: CancellationReasonType,
  cancellationReasons?: ICancellationReason[],
  cancellationReasonCode?: string,
): string => {
  if (cancellationReasons && cancellationReasonCode) {
    return (
      cancellationReasons.find(
        (reason) =>
          reason.type === cancellationReasonType && reason.code === cancellationReasonCode,
      )?.label || ''
    )
  }
  return ''
}

const GetTimeSlotFromStop = (element: IStop): JSX.Element => {
  let visit: IVisitData | undefined
  if (element.visitsToDeliver?.length && element.visitsToDeliver[0]) {
    [visit] = element.visitsToDeliver
  } else if (element.visitsToLoad?.length && element.visitsToLoad[0]) {
    [visit] = element.visitsToLoad
  }
  return GetTimeSlotFromVisit(visit)
}

const GetTimeSlotFromVisit = (visit: IVisitData | IVisit | undefined): JSX.Element => {
  let timeSlot = ''
  if (visit?.departureMinDate && visit?.departureMaxDate) {
    timeSlot = `${dateToTimeFormat(visit.departureMinDate)}-${dateToTimeFormat(
      visit.departureMaxDate,
    )}`
  }
  if (!timeSlot) return <></>
  let isVisitTimeSlotChanged = false
  if (
    visit?.updatedByCarrierDepartureMaxDate
    && visit.updatedByCarrierDepartureMaxDate.toString() !== visit.departureMaxDate?.toString()
  ) {
    isVisitTimeSlotChanged = true
  } else if (
    visit?.updatedByCarrierDepartureMinDate
    && visit.updatedByCarrierDepartureMinDate.toString() !== visit.departureMinDate?.toString()
  ) {
    isVisitTimeSlotChanged = true
  }
  return (
    <>
      {timeSlot}
      {isVisitTimeSlotChanged
        && visit?.updatedByCarrierDepartureMaxDate
        && visit?.updatedByCarrierDepartureMinDate && (
          <DeliveryTimeSlotModifiedAlert
            startDate={dateToTimeFormat(visit.updatedByCarrierDepartureMinDate)}
            endDate={dateToTimeFormat(visit.updatedByCarrierDepartureMaxDate)}
          />
      )}
    </>
  )
}

const GetOrderStatus = ({
  status,
  cancellationReasonCode,
  cancellationSource,
}: {
  status?: StopStatus
  cancellationReasonCode?: string
  cancellationSource?: CancellationReasonType
}): JSX.Element => {
  const { user } = useContext(AuthContext)
  const userCancellationReasons = user?.tenantConfig?.cancellationReasons
  let cancellationReason = ''
  if (status === StopStatus.Dropped) {
    cancellationReason = getCancellationReasonLabelFromCode(
      cancellationSource,
      userCancellationReasons,
      cancellationReasonCode,
    )
  }
  return (
    <>
      {isDefined(status)
        ? `${i18n.t(`OrdersScreen.orderStatus.${status}`)}${
          cancellationReason && ` - ${cancellationReason}`
        }`
        : '-'}
    </>
  )
}

const GetTourStatus = ({ tour }: { tour: ITour }): JSX.Element => {
  const { user } = useContext(AuthContext)
  const classes = useStyles()

  const userCancellationReasons = user?.tenantConfig?.cancellationReasons
  let cancellationReason = ''
  const status = getTourStatusLabel(tour)

  if (status === TOUR_STATUS_MAP.get(TOUR_STATUS_ENUM.DROPPED)) {
    cancellationReason = getCancellationReasonLabelFromCode(
      tour.cancellation?.source,
      userCancellationReasons,
      tour.cancellation?.reasonCode,
    )
  }
  return (
    <div className={classes.tourStatusAndIconWrapper}>
      {status}
      {cancellationReason && ` - ${cancellationReason}`}
      {tour.status === TOUR_STATUS_ENUM.REFUSED && <TourRefusedAlert tour={tour} />}
    </div>
  )
}

function WrapperGetTourStatus(rowData: ITour): JSX.Element {
  return <GetTourStatus tour={rowData} />
}

const formatLocationRelevance = (rowData: IOrder): string =>
  rowData?.locationRelevance !== null && rowData?.locationRelevance !== undefined
    ? Math.round(rowData?.locationRelevance * 100).toString()
    : '-'

const getSiteLocationRelevance = (site: IWarehouse): string =>
  site.address.relevance !== null && site.address.relevance !== undefined
    ? Math.round(site.address.relevance * 100).toString()
    : '-'

const getUserStatus = (user: IUser): JSX.Element =>
  user.active ? (
    <>{i18n.t('tablesEntries.status.active')}</>
  ) : (
    <>{i18n.t('tablesEntries.status.notActive')}</>
  )

const getTranslatedRolesList = (roles: string[]): string =>
  roles
    .map((role) => ROLES_USER_OPTIONS.find((roleOption) => roleOption.id === role)?.name)
    .join(', ')

const getUserRoles = (user: IUser): JSX.Element => <>{getTranslatedRolesList(user.roles)}</>

function GetUserCompanyName({
  id,
  displayPurpose,
}: {
  id?: string
  displayPurpose?: boolean
}): JSX.Element {
  const { companies } = useContext(ContentContext)
  return getName(companies, id, displayPurpose)
}

function WrapperGetUserCompanyName(user: IUser): JSX.Element {
  return <GetUserCompanyName id={user.companyId} />
}

function GetVehicleTypeLabel({ code }: { code?: string }): JSX.Element {
  const { vehicleTypes } = useContext(ContentContext)
  const label = vehicleTypes.find((vehicleType) => vehicleType.code === code)?.label
  return <>{label}</>
}

function WrapperGetVehicleTypeLabel(vehicleType: IVehicle): JSX.Element {
  return <GetVehicleTypeLabel code={vehicleType.vehicleType} />
}

function GetDisputeStatus({ status }: { status: string }): JSX.Element {
  const { t } = useTranslation()
  return <>{t(`DisputesListScreen.status.${status}`)}</>
}

function WrapperGetDisputeStatus(dispute: IDispute): JSX.Element {
  return <GetDisputeStatus status={dispute.status} />
}

function WrapperGetDisputeReasonLabelFromCode(rowData: IDispute): JSX.Element {
  return <GetDisputeReasonLabelFromCode code={rowData.reasonCode} />
}

function GetDisputeReasonLabelFromCode({ code }: { code: string }): JSX.Element {
  const { disputeReasonCodes } = useContext(DisputeContext)
  return (
    <>{`${code} - ${disputeReasonCodes?.find((item) => item.code === code)?.label || 'N/A'}`}</>
  )
}

function DisplayVehicleImages(item: { images: string[] }): JSX.Element {
  return (
    <div style={{ display: 'flex' }}>
      {item.images.map((imageLink) => (
        <ImageLine link={imageLink} alt="driver-captured-pic" noTopMargin />
      ))}
    </div>
  )
}

function DisplayDriverField({ tour }: { tour: ITour }): JSX.Element {
  const { user } = useContext(AuthContext)
  return user?.tenantConfig.carrierCanChangeDriver ? (
    <ListSelectDriver {...tour} />
  ) : (
    <WrapperGetDriverNameFromId {...tour} />
  )
}

function WrapperDisplayDriverField(tour: ITour): JSX.Element {
  return <DisplayDriverField tour={tour} />
}

function DisplayVehicleField({ tour }: { tour: ITour }): JSX.Element {
  const { user } = useContext(AuthContext)
  return user?.tenantConfig.carrierCanChangeVehicle ? (
    <ListSelectVehicle {...tour} />
  ) : (
    <WrapperGetVehicleNameFromId {...tour} />
  )
}

function WrapperDisplayVehicleField(tour: ITour): JSX.Element {
  return <DisplayVehicleField tour={tour} />
}

const GetPlanTemplateWarehouseFromId = ({
  id,
  displayPurpose,
}: {
  id?: string
  displayPurpose?: boolean
}): JSX.Element => {
  const { sites } = useContext(ContentContext)
  return getName(sites, id, displayPurpose)
}

const GetSiteNameFromId = ({
  id,
  displayPurpose,
}: {
  id?: string
  displayPurpose?: boolean
}): JSX.Element => {
  const { sites } = useContext(ContentContext)
  return getName(sites, id, displayPurpose)
}

const WrapperGetPlanTemplateWarehouseFromId = (planTemplate: IPlanTemplate): JSX.Element => (
  <GetPlanTemplateWarehouseFromId id={planTemplate.warehouseId} />
)

const WrapperGetSiteNameFromId = (vehicle: IVehicle): JSX.Element => (
  <GetSiteNameFromId id={(vehicle as IVehicle).siteId} />
)

const WrapperGetRoutingRuleSiteNameFromId = (routingRule: IRoutingRule): JSX.Element => (
  <GetSiteNameFromId id={(routingRule as IRoutingRule)?.condition?.origin?.siteId} />
)

const getVehicleProfileLabel = (vehicleType: IVehicleType): JSX.Element => (
  <>{vehicleType.vehicleProfile?.label}</>
)

const getDriverPhoneNumber = (tour: ITour): JSX.Element =>
  tour.driver ? <>{tour.driver.phoneNumber}</> : <></>

const getDriverName = (planTour: IPlanTour): JSX.Element =>
  planTour.driver ? <>{planTour.driver.name}</> : <></>

const formatBeginTime = (planTemplate: IPlanTemplate): JSX.Element => (
  <>{getTimeFromDateString(planTemplate.begin as string)}</>
)

const formatEndTime = (planTemplate: IPlanTemplate): JSX.Element => (
  <>{getTimeFromDateString(planTemplate.end as string)}</>
)

const GetSiteLabel = ({ id }: { id?: string }): JSX.Element => {
  const { sites } = useContext(ContentContext)
  return getName(sites, id)
}

const WrapperGetSiteInOrderLabel = (order: IOrder): JSX.Element => (
  <GetSiteLabel id={order.deliverySiteId?.id} />
)

const WrapperGetSiteInPlanTourLabel = (planTour: IPlanTour): JSX.Element => (
  <GetSiteLabel id={planTour.departureWarehouse} />
)

const WrapperGetSiteLabelInTour = (tour: ITour): JSX.Element => (
  <GetSiteLabel id={tour.warehouseId} />
)

const getPlanStatusLabel = (order: IOrder): string =>
  ORDER_PLAN_STATUSES.find((status) => +status.id === order.planStatus)?.name || ''

const getPlanTourStatusLabel = (planTour: IPlanTour): string =>
  PLAN_TOUR_STATUS.find((status) => status.id === planTour.status)?.name || ''

const getTourStatusLabel = (tour: ITour): string =>
  TOUR_STATUS.find((status) => +status.id === tour.status)?.name || ''

const getUserDefaultSite = (user: IUserInfos): JSX.Element => (
  <GetSitesLabelById ids={[user.sites?.find((site) => site.default)?.siteId || '']} />
)

const getImportJobStatusLabel = (importJob: IImportJob): string =>
  i18n.t(`jobs.status.${importJob.status}`)

const getImportJobDataTypeLabel = (importJob: IImportJob): string =>
  i18n.t(`jobs.types.${importJob.dataType}`)

const formatDate = (importJob: IImportJob): string => dateDefaultFormat(importJob.createdDate)

const getEstimatedWeightLabel = (item: { estimatedWeight: number }): string =>
  isDefined(item.estimatedWeight) ? item.estimatedWeight.toString() : '-'

const getGeocodingSourceLabel = (item: { geocodingSource: string }): string =>
  item.geocodingSource ? i18n.t(`OrdersScreen.geocodingSource.${item.geocodingSource}`) : ''

const getNotificationTypeLabel = (notifierConfiguration: INotifierConfiguration): string =>
  CUSTOMER_NOTIFICATION_TYPE.find((type) => type.id === notifierConfiguration.notificationType)
    ?.name || ''

const getDealersLabel = (ids: string[], dealers: IDealer[]): string =>
  (ids?.map((id) => dealers.find((dealer) => dealer.id === id)?.label) || []).join(', ')

const GetDealersLabelById = ({ ids }: { ids: string[] }): JSX.Element => {
  const { dealers } = useContext(ContentContext)
  return <>{getDealersLabel(ids, dealers)}</>
}

const WrapperGetDealersLabelById = (item: { dealerIds: string[] }): JSX.Element => (
  <GetDealersLabelById ids={item.dealerIds} />
)

const WrapperGetDealerLabelById = (item: { dealerId: string }): JSX.Element => (
  <GetDealersLabelById ids={[item.dealerId]} />
)

const getCarrierNames = (carrierIds: string[], carriers: ICarrier[]): string =>
  (carrierIds?.map((id) => carriers.find((carrier) => carrier.id === id)?.name) || []).join(', ')

const GetCarrierNamesFromIds = ({ ids }: { ids: string[] }): JSX.Element => {
  const { carriers } = useContext(ContentContext)
  return <>{getCarrierNames(ids, carriers)}</>
}

const WrapperGetCarrierNamesFromIds = (item: { carrierIds: string[] }): JSX.Element => (
  <GetCarrierNamesFromIds ids={item.carrierIds} />
)

const getTransportTypeName = (id?: TransportType, displayPurpose?: boolean): JSX.Element =>
  getName(TRANSPORT_TYPE_OPTIONS, id, displayPurpose)

const GetTransportType = ({
  id,
  displayPurpose,
}: {
  id?: TransportType
  displayPurpose?: boolean
}): JSX.Element => getTransportTypeName(id, displayPurpose)

const WrapperGetTransportType = (rowData: IOrder): JSX.Element => (
  <GetTransportType id={rowData.transportType} />
)

const getTransportTypesName = (ids: TransportType[]): string =>
  ids.map((id) => TRANSPORT_TYPE_OPTIONS.find((type) => type.id === id)?.name || '').join(', ')

const formatMainQuantityToLoad = (rowData: IPlanTour): JSX.Element => (
  <>{rowData.mainQuantityToLoad ? `-${rowData.mainQuantityToLoad}` : rowData.mainQuantityToLoad}</>
)

function formatAlertCellStyle(rows: IAlert[], row: IAlert): React.CSSProperties | undefined {
  return row.read ? undefined : { fontWeight: 'bold' }
}

const formatReportDate = (item: { createdOn: string }): string => dateDefaultFormat(item.createdOn)

const formatVehicleReportRating = (item: { rating: string }): string =>
  isDefined(item.rating) ? `${item.rating} /5` : ''

const getDisplayRowsCount = (data: unknown[]): number => {
  const count = data.length

  return Math.max(count, 4)
}

const formatCreationDate = (dispute: IDispute): string => dateDefaultFormat(dispute.creationDate)

function WrapperRenderMediaLinks(dispute: IDispute): JSX.Element {
  return <RenderMediaLinks links={dispute.mediaList} />
}

function RenderMediaLinks({ links = [] }: { links: string[] }): JSX.Element {
  return (
    <div style={{ display: 'flex' }}>
      {links.map((imageLink) => (
        <ImageLine link={imageLink} alt="driver-captured-pic" noTopMargin />
      ))}
    </div>
  )
}

const GetOrderLink = ({ dispute }: { dispute: IDispute }): JSX.Element => {
  const classes = useStyles()
  return (
    <Link
      className={classes.link}
      to={{
        pathname: `${ROUTES_PATH.orderDetails}${dispute.orderId}`,
      }}
    >
      {dispute.orderNumber}
    </Link>
  )
}

const getFirstDeliveredOrderDate = (rowData: ITour): string => {
  const stops = removeFirstAndLastStops(rowData.stops) as IStop[]
  const date = stops.find((stop) => stop.status === StopStatus.Delivered)?.realArrival
  return date ? dateDefaultFormat(date) : ''
}

const getLastDeliveredOrderDate = (rowData: ITour): string => {
  const lastStop = getFinalStop(rowData.stops) as IStop
  if (!lastStop) return ''
  const lastStopDeliverDate = lastStop?.realArrival || lastStop?.estimatedArrival
  return lastStop ? dateDefaultFormat(lastStopDeliverDate) : ''
}

const getFirstPlanifiedOrderDate = (rowData: ITour): string => {
  const date = rowData.stops[1]?.planedArrival || rowData.stops[1]?.visitsToDeliver[0].departureMinDate
  return date ? dateDefaultFormat(date) : ''
}

const getLastPlanifiedOrderDate = (rowData: ITour): string => {
  const lastStop = getFinalStop(rowData.stops) as IStop
  const date = lastStop?.planedArrival || lastStop?.visitsToDeliver[0].departureMaxDate
  return date ? dateDefaultFormat(date) : ''
}

const getLastStopDelay = (rowData: ITour): JSX.Element | undefined => {
  const lastStop = getFinalStop(rowData.stops) as IStop
  if (!lastStop) return
  return <StopDelay stop={lastStop} toleratedDelay={rowData.toleratedDelay} />
}

const getNumberOfMissedClientPromises = (rowData: ITour): number => {
  const stops = removeFirstAndLastStops(rowData.stops) as IStop[]
  const stopDelays = stops.map((stop) =>
    calculateStopClientPromiseDelay(stop, rowData.toleratedAdvance, rowData.toleratedDelay),
  )
  return stopDelays.filter((item) => item.color.background === 'red').length
}

function WrapperGetOrderLink(dispute: IDispute): JSX.Element {
  if (dispute.orderId) {
    return <GetOrderLink dispute={dispute} />
  }
  return <>{dispute.orderNumber}</>
}

function DisplayPhotosFromURLs({ photoURLs }: { photoURLs: string[] }): JSX.Element {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      {photoURLs.map((photoURl, index) => (
        <ImageLine
          link={photoURl}
          label={`image${index + 1}`}
          alt="cancellation photo"
          noTopMargin
        />
      ))}
    </div>
  )
}

function formatAllowedIPs(rowData: IApikey): JSX.Element | undefined {
  if (!rowData.whitelistedIPs) {
    return
  }
  return <>{rowData.whitelistedIPs?.join(' , ')}</>
}

function formatApikeyUserName(rowData: IApikey): JSX.Element | undefined {
  if (!rowData.userName) {
    return <>{rowData.userId}</>
  }
  return <>{rowData.userName}</>
}

function addAlertToTourNumber(rowData: ITour): JSX.Element {
  return <TourNumberWithAlert tour={rowData} />
}

function addAlertToGeocoding(rowData: IOrder): JSX.Element {
  const locationRelevance = formatLocationRelevance(rowData)
  return <LocationRelevanceWithAlert locationRelevance={locationRelevance} />
}

function setColumnDisplay(col: ITableColumn, columnPreferences: string[]): MaterialTable<object> {
  // if the column is hidden by default and we find it in the user preferences, then it should be displayed
  // if the column is shown by default and we find it in the user preferences, then it should be hidden
  let hidden = false
  if (col.defaultHidden) hidden = !columnPreferences?.includes(col.title)
  else hidden = columnPreferences?.includes(col.title)
  return { ...col, hidden } as unknown as MaterialTable<object>
}

export {
  formatAmplitude,
  getUserDefaultSite,
  formatPostalCodes,
  formatSpecifics,
  formatStartDate,
  formatEndDate,
  formatCustomerName,
  formatLocationRelevance,
  formatVisitFloors,
  formatVisitHasElevator,
  formatVisitTags,
  GetCarrierNameFromId as GetCarrierNameFromDirectId,
  GetDriverNameFromId as GetDriverNameFromDirectId,
  GetCarrierNameFromIdString,
  GetDriverNameFromIdString,
  WrapperGetCarrierNameFromId as GetCarrierNameFromId,
  WrapperGetDriverNameFromId as GetDriverNameFromId,
  getDeliveryTypeLabel,
  getDeliveryTypesLabel,
  GetDeliveryTypeLabelWrapped,
  GetOrderStatus,
  GetTimeSlotFromStop as getTimeSlotFromStop,
  GetTimeSlotFromVisit as getTimeSlotFromVisit,
  getCarrierStatus,
  getCarrierWarehouses,
  getDriverStatus,
  getUserStatus,
  getUserRoles,
  WrapperGetUserCompanyName as getUserCompanyName,
  getVehicleStatus,
  WrapperDisplayDriverField as DisplayDriverField,
  WrapperDisplayVehicleField as DisplayVehicleField,
  getSiteStatus,
  getSiteSector,
  getFullAddress,
  getSiteType,
  getSiteTourType,
  getSiteLocationRelevance,
  getVehicleTypeStatus,
  getVehicleProfileLabel,
  WrapperGetVehicleTypeLabel as getVehicleTypeLabel,
  WrapperGetDisputeStatus as getDisputeStatus,
  WrapperGetDisputeReasonLabelFromCode as getDisputeReasonLabelFromCode,
  getDriverPhoneNumber,
  WrapperGetPlanTemplateWarehouseFromId as getPlanTemplateWarehouseFromId,
  WrapperGetSiteNameFromId as getSiteNameFromId,
  WrapperGetRoutingRuleSiteNameFromId as getRoutingRuleSiteNameFromId,
  formatBeginTime,
  formatEndTime,
  getThirdPartyActivation,
  getEventsLabel,
  WrapperGetSitesLabelById as getSitesLabelById,
  WrapperGetSiteLabelById as getSiteLabelById,
  WrapperGetSectorsLabelById as getSectorsLabelById,
  getTranslatedRolesList,
  WrapperGetSiteInOrderLabel as getSiteInOrderLabel,
  WrapperGetSiteInPlanTourLabel as getSiteInPlanTourLabel,
  getPlanStatusLabel,
  getPlanTourStatusLabel,
  getDriverName,
  getToOptimizeLabel,
  WrapperGetUserSites as getUserSites,
  getImportJobStatusLabel as getJobStatusLabel,
  getImportJobDataTypeLabel as getJobDataTypeLabel,
  formatDate,
  WrapperGetTenantNameFromId as getTenantNameFromId,
  WrapperGetWarehousesLabelById as getWarehousesLabelById,
  getSitesLabel,
  getEstimatedWeightLabel,
  getGeocodingSourceLabel,
  getNotificationTypeLabel,
  WrapperGetDealersLabelById as getDealersLabelById,
  WrapperGetCarrierNamesFromIds as getCarrierNamesFromIds,
  getCarrierNames,
  WrapperGetTransportType as getTransportType,
  getTransportTypeName,
  getTransportTypesName,
  formatMainQuantityToLoad,
  WrapperGetSiteLabelInTour as getSiteLabelInTour,
  WrapperGetConcernedWarehousesLabelById as getConcernedWarehousesLabelById,
  WrapperGetDealerLabelById as getDealerLabelById,
  formatAlertCellStyle,
  DisplayVehicleImages,
  formatReportDate,
  formatVehicleReportRating,
  getDisplayRowsCount,
  WrapperRenderMediaLinks as renderMediaLinks,
  formatCreationDate,
  WrapperGetOrderLink as renderOrderLink,
  WrapperGetTourStatus as getTourStatus,
  DisplayPhotosFromURLs,
  getCancellationReasonLabelFromCode,
  formatAllowedIPs,
  formatApikeyUserName,
  getFirstDeliveredOrderDate,
  getFirstPlanifiedOrderDate,
  getLastPlanifiedOrderDate,
  getLastStopDelay,
  getNumberOfMissedClientPromises,
  addAlertToTourNumber,
  addAlertToGeocoding,
  getLastDeliveredOrderDate,
  getTourStatusLabel,
  setColumnDisplay,
}
