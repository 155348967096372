import React, { useState, ReactNode, useEffect } from 'react'
import L, { Map, PM } from 'leaflet'

import COLORS from 'screens/PlanningCategory/PlanningScreen/components/ClusterColors'
import { ICoordinates, ISector } from 'interfaces/ISector'
import { useTranslation } from 'react-i18next'

export interface IMapContext {
  map?: Map | null
  setMap: (map: Map) => void
  addLayer: (layer: L.Layer[]) => void
  layerGroup: L.Layer[]
  markersLayer: L.Marker[]
  addMarkerToLayer({ lat, lng }: { lat: number; lng: number }): void
  canvas: L.Canvas
  cleanMarkersLayer(): void
}

const MapContext = React.createContext<IMapContext>({} as IMapContext)
const { Consumer } = MapContext

interface IProps {
  children: ReactNode
  onSectorChange?(
    layer: L.Polygon,
    remove?: boolean,
    sectorId?: string,
    layerIndex?: number,
  ): Promise<string>
  useToolbar?: boolean
  existingSectors?: ISector[]
}

/**
 * This context will manage the data wich is not made to change often
 */

function MapProvider({
  children,
  useToolbar,
  onSectorChange,
  existingSectors,
}: IProps): JSX.Element {
  const [map, setMap] = useState<Map | null>(null)
  const [layerGroup, setLayerGroup] = useState<L.Layer[]>([])
  const [markersLayer, setMarkersLayer] = useState<L.Marker[]>([])
  const [canvas] = useState<L.Canvas>(L.canvas({ padding: 0.5 }))
  const { t } = useTranslation()

  useEffect(() => {
    if (map && existingSectors) {
      addExistingShapes()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, existingSectors?.length])

  function addLayer(layer: L.Layer[]): void {
    setLayerGroup((prev) => [...prev, ...layer])
  }

  function getNumberOfPolygons(): number {
    if (map) {
      let totalLayer = -1
      map.eachLayer((layer) => {
        // @ts-ignore
        if (layer.pm?._shape === 'Polygon') {
          totalLayer += 1
        }
      })
      return totalLayer
    }
    return 0
  }

  function addControls() {
    if (map) {
      const customTranslation: PM.Translations = {
        buttonTitles: {
          drawPolyButton: t('SectorsScreen.drawSectors'),
          editButton: t('SectorsScreen.editSectors'),
          dragButton: t('SectorsScreen.dragSectors'),
          deleteButton: t('SectorsScreen.deleteSectors'),
        },
      }

      const browserLanguage = navigator.language.includes('fr') ? 'fr' : 'en'

      map.pm.setLang(browserLanguage, customTranslation, browserLanguage)

      // enable the toolbar to create shapes
      map.pm.addControls({
        position: 'topleft',
        drawCircle: false,
        drawCircleMarker: false,
        drawMarker: false,
        drawPolyline: false,
        drawRectangle: false,
        oneBlock: true,
        cutPolygon: false,
      })
    }
  }

  function attachEvents(id: string, layer: L.Polygon, layerIndex?: number) {
    if (onSectorChange) {
      layer.on('pm:edit', ({ layer: editedLayer }: { layer: L.Polygon }) => {
        onSectorChange(editedLayer, false, id, layerIndex)
      })
      layer.on('pm:remove', ({ layer: removedLayer }: { layer: L.Polygon }) => {
        onSectorChange(removedLayer, true, id)
      })
    }
  }

  function addExistingShapes() {
    if (map) {
      map.eachLayer((layer) => {
        // @ts-ignore
        if (layer.pm?._shape === 'Polygon') {
          map.removeLayer(layer)
        }
      })
      if (existingSectors && existingSectors.length > 0) {
        existingSectors.forEach((sector: ISector) => {
          sector.sectorCoordinates.forEach((sectorCoordinate: ICoordinates, index: number) => {
            const layer = L.polygon(sectorCoordinate.coordinates, { color: sector.color }).addTo(
              map,
            )
            attachEvents(sector.id, layer, index)
          })
        })
      }
    }
  }

  useEffect(() => {
    if (useToolbar && map) {
      addControls()
      map.on('pm:create', async ({ layer }: { layer: L.Polygon }) => {
        const numPolygons = getNumberOfPolygons()
        layer.setStyle({ color: COLORS[numPolygons % COLORS.length] })
        if (onSectorChange) {
          const backendId = await onSectorChange(layer)
          attachEvents(backendId, layer)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, useToolbar])

  function addMarkerToLayer({ lat, lng }): void {
    if (lat && lng) {
      setMarkersLayer((prev) => {
        const layer = [...prev]
        layer.push(L.marker([lat, lng]))
        return layer
      })
    }
  }

  function cleanMarkersLayer(): void {
    setMarkersLayer([])
  }

  function fitBounds(): void {
    if (markersLayer?.length > 0 && map) {
      const guideLayer = new L.FeatureGroup(markersLayer)
      map.fitBounds(guideLayer.getBounds(), { padding: [50, 50], animate: false })
    }
  }

  useEffect(() => {
    fitBounds()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markersLayer && markersLayer.length])

  return (
    <MapContext.Provider
      value={{
        map,
        setMap,
        addLayer,
        layerGroup,
        addMarkerToLayer,
        markersLayer,
        canvas,
        cleanMarkersLayer,
      }}
    >
      {children}
    </MapContext.Provider>
  )
}

export default MapProvider
export { MapContext }
export { Consumer as MapConsumer }
