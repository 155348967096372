import { ITab } from 'interfaces/ITab'
import { IFeature } from 'interfaces/IFeature'
import { Dispatch, SetStateAction } from 'react'

const shouldShowTab = (tab: ITab, features: IFeature[]): boolean => {
  if (tab.isBetaMode && features) {
    return features.findIndex((feature) => tab.featureFlag === feature.name) !== -1
  }
  return true
}

const handleTabChange = (setter: Dispatch<SetStateAction<number>>, newValue: number): void =>
  setter(newValue)

export { shouldShowTab, handleTabChange }
