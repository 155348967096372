import React from 'react'
import { Grid, CardHeader, CardContent, Paper } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import useStyles from 'constants/cruStyles'
import { IRoutingSegment } from 'interfaces/IRoutingRule'
import RoutingSegmentsTab from './RoutingSegmentsTab'

interface IProps {
  routingSegments?: IRoutingSegment[]
  onRulesChange: (routingSegments: IRoutingSegment[]) => void
}

const RoutingSegmentsCard = ({ routingSegments, onRulesChange }: IProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid
      data-cy="segmentCard"
      item
      xs={5}
      className={clsx(classes.infoContainer, classes.routingRuleCard)}
    >
      <Paper elevation={10}>
        <CardHeader
          title={t('RoutingRulesScreen.segments')}
          titleTypographyProps={{
            variant: 'subtitle1',
            style: {
              fontWeight: 'bolder',
            },
          }}
          className={classes.header}
        />
        <CardContent className={classes.routingRulesCard}>
          <RoutingSegmentsTab
            routingSegments={routingSegments}
            onRoutingSegmentItemChange={onRulesChange}
          />
        </CardContent>
      </Paper>
    </Grid>
  )
}

export default RoutingSegmentsCard
