import React from 'react'
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import { IWarehouseTimeSlotReportingConfiguration } from 'interfaces/IWarehouseTimeSlot'
import useStyles from '../styles'

interface IConfigurationSelectProps {
  configurations: IWarehouseTimeSlotReportingConfiguration[]
  selectedConfiguration: string
  onConfigurationSelect: (configuration: string) => void
}

const ConfigurationSelect = ({
  configurations,
  selectedConfiguration,
  onConfigurationSelect,
}: IConfigurationSelectProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()

  const placeholder = t('SlotsReportingScreen.configuration')

  const handleConfigurationChange = (configuration: string): void => {
    onConfigurationSelect(configuration)
  }

  return (
    <div className={classes.configurationSelectContainer}>
      <FormControl variant="outlined" data-cy="configurationSelect">
        <InputLabel>{placeholder}</InputLabel>
        <Select
          label={placeholder}
          className={clsx(selectedConfiguration === '' && classes.configurationSelect)}
          value={selectedConfiguration}
          onChange={(event): void => handleConfigurationChange(event.target.value as string)}
        >
          <MenuItem value={-1} disabled>
            {placeholder}
          </MenuItem>
          {configurations.map((config: IWarehouseTimeSlotReportingConfiguration) => {
            let label = `${config.label} - ${config.warehouseName}`
            if (config.sectorLabel) label += ` - ${config.sectorLabel}`
            let value = config.warehouseId
            if (config.sectorId) value += ` - ${config.sectorId}`
            return (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </div>
  )
}

export default ConfigurationSelect
