import { createStyles, makeStyles } from '@material-ui/core/styles'

export default makeStyles(() =>
  createStyles({
    menuItemContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    menuItemArrow: {
      fontSize: '12px',
      marginLeft: '5px',
    },
  }),
)
