import i18n from 'i18next'

const dateStyle = 'short'
const timeStyle = 'short'

export function formatDateInterval(
  from: string | Date | undefined,
  to: string | Date | undefined,
  displayOnlyDates = false,
): string {
  if (from && to && isSameDay(from, to)) {
    const day = Intl.DateTimeFormat(i18n.language, { dateStyle }).format(new Date(from))
    const hoursFormatter = Intl.DateTimeFormat(i18n.language, { timeStyle })

    const hours = [hoursFormatter.format(new Date(from)), hoursFormatter.format(new Date(to))]
    return `${day} [${hours.join('-')}]`
  }

  const dateFormatter = Intl.DateTimeFormat(i18n.language, { dateStyle, timeStyle })
  const strFrom = from ? dateFormatter.format(new Date(from)) : null
  const strTo = to ? dateFormatter.format(new Date(to)) : null

  if (from && to) {
    return `${strFrom} - ${strTo}`
  }

  if (from) {
    return displayOnlyDates ? `${strFrom}` : `${i18n.t('CarrierScreen.dateFrom')} ${strFrom}`
  }

  if (to) {
    return displayOnlyDates ? `${strTo}` : `${i18n.t('CarrierScreen.dateTo')} ${strTo}`
  }

  return displayOnlyDates ? '' : 'Permanent'
}

function isSameDay(date1: string | Date, date2: string | Date): boolean {
  const d1 = new Date(date1).setHours(0, 0, 0, 0)
  const d2 = new Date(date2).setHours(0, 0, 0, 0)
  return d1 === d2
}

export function intervalContainsDate(
  date: Date,
  from: string | undefined,
  to: string | undefined,
): boolean {
  const dateFromTs = from ? new Date(from).setHours(0, 0, 0, 0) : null
  const dateToTs = to ? new Date(to).setHours(0, 0, 0, 0) : null
  const dateTs = date.getTime()

  if (dateFromTs && dateToTs) {
    return dateFromTs < dateTs && dateToTs > dateTs
  }

  if (dateFromTs) {
    return dateFromTs < dateTs
  }

  if (dateToTs) {
    return dateToTs > dateTs
  }

  return true
}
