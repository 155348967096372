import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'

import SitesProvider, { SitesContext } from 'store/SitesContext'
import { ContentContext } from 'store/ContentContext'
import {
  IAppointmentEndCustomerPageConfig,
  IMapotempoConfig,
  IPlanningEndCustomerPageConfig,
  IPlanningMobile,
  IWarehouse,
  IDeliveryInformationEndCustomerPageConfig,
  IOrderScreenConfig,
  IExecutionScreenConfig,
  IExecutionTrackingScreenConfig,
  IAtoptimaParams,
  OptimizationEngine,
} from 'interfaces/interfaces'
import { useParams, useHistory } from 'react-router-dom'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import { IIdParam } from 'interfaces/IIdParam'
import FormAction from 'components/Button/FormAction'
import { IOptimizationParam } from 'interfaces/IOptimizationParam'
import { FeedbackContext } from 'store/FeedbackContext'
import { isDefined, isDefinedAndNotEmpty } from 'utils/functions'
import WarehouseOptimParamCard from './WarehouseOptimParamCard'
import WarehouseMapotempoCard from './WarehouseMapotempoCard'
import WarehousePlanningEndCustomerPageCard from './WarehousePlanningEndCustomerPageCard'
import WarehousePlanningMobileCard from './WarehousePlanningMobileCard'
import WarehouseAppointmentEndCustomerPageCard from './WarehouseAppointmentEndCustomerPageCard'
import WarehouseDeliveryInformationEndCustomerPageCard from './WarehouseDeliveryInformationEndCustomerPageCard'
import WarehouseOrderScreenCard from './WarehouseOrderScreenCard'
import WarehouseOrderScreenCreationCard from './WarehouseOrderScreenCreationCard'
import WarehouseExecutionScreenCard from './WarehouseExecutionScreenCard'
import WarehouseExecutionTrackingScreenCard from './WarehouseExecutionTrackingScreenCard'
import WarehouseAtoptimaParamCard from './WarehouseAtoptimaParamCard'

const WarehouseAdminFormScreen = (): JSX.Element => {
  const { id } = useParams<IIdParam>()
  const [warehouse, setWarehouse] = useState<Partial<IWarehouse>>({})
  const { getDetails, siteDetails, updateSite, updateSuccess, getOptimizerApps, optimizerApps } = useContext(SitesContext)
  const { refreshSites } = useContext(ContentContext)
  const { openErrorSnack } = useContext(FeedbackContext)
  const history = useHistory()
  const { t } = useTranslation()

  useEffect(() => {
    if (id) getDetails(id, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (siteDetails) setWarehouse(siteDetails)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteDetails])

  useEffect(() => {
    if (warehouse.optimParams?.optimizationEngine === OptimizationEngine.Atoptima) {
      if (!optimizerApps || optimizerApps.length < 1) {
        getOptimizerApps()
      }
    } else {
      setWarehouse({
        ...warehouse,
        optimParams: {
          ...warehouse.optimParams,
          atoptimaParams: undefined,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouse.optimParams?.optimizationEngine])

  const handleOptimParamsChange = (optimParams: IOptimizationParam): void => {
    const optimParamsToSend: IOptimizationParam = {
      ...optimParams,
      daysBeforePlansExpiration: isDefined(optimParams?.daysBeforePlansExpiration)
        ? Number(optimParams.daysBeforePlansExpiration)
        : undefined,
      maximumOptimizationTime: isDefined(optimParams?.maximumOptimizationTime)
        ? Number(optimParams?.maximumOptimizationTime)
        : undefined,
      speedMultiplier: isDefined(optimParams?.speedMultiplier)
        ? Number(optimParams?.speedMultiplier)
        : undefined,
      setupDuration: isDefined(optimParams?.setupDuration)
        ? Number(optimParams?.setupDuration)
        : undefined,
      visitDuration: isDefined(optimParams?.visitDuration)
        ? Number(optimParams?.visitDuration)
        : undefined,
      maxDestinations: isDefined(optimParams?.maxDestinations)
        ? Number(optimParams?.maxDestinations)
        : undefined,
      multiTourPermission: isDefined(optimParams?.multiTourPermission)
        ? !!optimParams?.multiTourPermission
        : undefined,
      nbToursPerVehicle: isDefined(optimParams?.nbToursPerVehicle)
        ? Number(optimParams?.nbToursPerVehicle)
        : undefined,
      delayBetweenTours: isDefined(optimParams?.delayBetweenTours)
        ? Number(optimParams?.delayBetweenTours)
        : undefined,
      optimizationMaxSplitSize: isDefined(optimParams?.optimizationMaxSplitSize)
        ? Number(optimParams?.optimizationMaxSplitSize)
        : undefined,
      optimizationStopSoftUpperBound: isDefined(optimParams?.optimizationStopSoftUpperBound)
        ? Number(optimParams?.optimizationStopSoftUpperBound)
        : undefined,
      optimizationVehicleSoftUpperBound: isDefined(optimParams?.optimizationVehicleSoftUpperBound)
        ? Number(optimParams?.optimizationVehicleSoftUpperBound)
        : undefined,
      optimizationCostWaitingTime: isDefined(optimParams?.optimizationCostWaitingTime)
        ? Number(optimParams?.optimizationCostWaitingTime)
        : undefined,
      routerOptions: {
        ...optimParams.routerOptions,
        maxWalkDistance: isDefined(optimParams.routerOptions?.maxWalkDistance)
          ? Number(optimParams.routerOptions?.maxWalkDistance)
          : undefined,
      },
      isMultisite: isDefined(optimParams?.isMultisite) ? optimParams.isMultisite : undefined,
      loadingTimePerOrderMultisite: isDefined(optimParams?.loadingTimePerOrderMultisite)
        ? Number(optimParams.loadingTimePerOrderMultisite)
        : undefined,
      toleratedAdvance: isDefined(optimParams?.toleratedAdvance)
        ? Number(optimParams?.toleratedAdvance)
        : undefined,
      toleratedDelay: isDefined(optimParams?.toleratedDelay)
        ? Number(optimParams?.toleratedDelay)
        : undefined,
    }

    setWarehouse({ ...warehouse, optimParams: optimParamsToSend })
  }

  const handleAverageLoadingTimeChange = (averageLoadingTime?: number): void => {
    const averageLoadingTimeToSend = isDefined(averageLoadingTime)
      ? Number(averageLoadingTime)
      : undefined
    setWarehouse({ ...warehouse, averageLoadingTime: averageLoadingTimeToSend })
  }

  const handlePlanningMobileChange = (planningMobile: IPlanningMobile): void => {
    setWarehouse({ ...warehouse, planningMobile })
  }

  const handleAtoptimaParamChange = (atoptimaParams: IAtoptimaParams): void => {
    setWarehouse({
      ...warehouse,
      optimParams: {
        ...warehouse.optimParams,
        atoptimaParams: {
          ...atoptimaParams,
          hourlyPrecedence: !!atoptimaParams?.hourlyPrecedence,
          uncoveredOperationsViolationPerUnit: isDefinedAndNotEmpty(
            atoptimaParams?.uncoveredOperationsViolationPerUnit,
          )
            ? Number(atoptimaParams?.uncoveredOperationsViolationPerUnit)
            : undefined,
          directPrecedenceViolationPerUnit: isDefinedAndNotEmpty(
            atoptimaParams?.directPrecedenceViolationPerUnit,
          )
            ? Number(atoptimaParams?.directPrecedenceViolationPerUnit)
            : undefined,
          weightViolationPerUnit: isDefinedAndNotEmpty(atoptimaParams?.weightViolationPerUnit)
            ? Number(atoptimaParams?.weightViolationPerUnit)
            : undefined,
          volumeViolationPerUnit: isDefinedAndNotEmpty(atoptimaParams?.volumeViolationPerUnit)
            ? Number(atoptimaParams?.volumeViolationPerUnit)
            : undefined,
          timeViolationPerSecond: isDefinedAndNotEmpty(atoptimaParams?.timeViolationPerSecond)
            ? Number(atoptimaParams?.timeViolationPerSecond)
            : undefined,
        },
      },
    })
  }

  const handleMapotempoChange = (mapotempoConfig: IMapotempoConfig): void => {
    setWarehouse({ ...warehouse, kVrpConfig: mapotempoConfig })
  }

  const handlePlanningEndCustomerPageChange = (
    planningEndCustomerPage: IPlanningEndCustomerPageConfig,
  ): void => {
    setWarehouse({ ...warehouse, planningEndCustomerPage })
  }

  const handleAppointmentEndCustomerPageChange = (
    appointmentEndCustomerPage: IAppointmentEndCustomerPageConfig,
  ): void => {
    setWarehouse({ ...warehouse, appointmentEndCustomerPage })
  }

  const handleDeliveryInformationEndCustomerPageChange = (
    deliveryInformationEndCustomerPage: IDeliveryInformationEndCustomerPageConfig,
  ): void => {
    setWarehouse({ ...warehouse, deliveryInformationEndCustomerPage })
  }

  const handleExecutionTrackingScreenChange = (
    executionTrackingScreen: IExecutionTrackingScreenConfig,
  ): void => {
    setWarehouse({ ...warehouse, executionTrackingScreen })
  }

  const handleSaveClick = (): void => {
    if (
      warehouse?.executionTrackingScreen?.delayAlertOnTourBeforeDeparture !== undefined
      && (!Number.isInteger(warehouse?.executionTrackingScreen?.delayAlertOnTourBeforeDeparture)
        || warehouse?.executionTrackingScreen?.delayAlertOnTourBeforeDeparture < 0
        || warehouse?.executionTrackingScreen?.delayAlertOnTourBeforeDeparture % 5 !== 0
        || (warehouse?.executionTrackingScreen?.delayAlertOnTourBeforeDeparture < 15
          && warehouse?.executionTrackingScreen?.delayAlertOnTourBeforeDeparture !== 0))
    ) {
      openErrorSnack(t('SitesScreen.messages.delayAlertOnTourBeforeDepartureValidation'))
    } else {
      updateSite(id, warehouse as IWarehouse, true)
    }
  }

  const handleOrderScreenChange = (orderScreen: IOrderScreenConfig): void => {
    setWarehouse({ ...warehouse, orderScreen: { ...warehouse.orderScreen, ...orderScreen } })
  }

  const handleExecutionScreenChange = (executionScreen: IExecutionScreenConfig): void => {
    setWarehouse({
      ...warehouse,
      executionScreen: { ...warehouse.executionScreen, ...executionScreen },
    })
  }

  useEffect(() => {
    if (updateSuccess) {
      history.push(ROUTES_PATH.warehouseAdminList)
      refreshSites()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSuccess])

  const handleCancelClick = (): void => {
    history.push(ROUTES_PATH.warehouseAdminList)
  }

  return (
    <Grid container justify="center">
      {id && (
        <Grid item xs={10}>
          <h3>{`${warehouse.name || ''}`}</h3>
        </Grid>
      )}
      <WarehouseOptimParamCard
        key={`${siteDetails.id}-optim`}
        optimParam={siteDetails.optimParams}
        averageLoadingTime={siteDetails.averageLoadingTime}
        onChange={handleOptimParamsChange}
        onAverageLoadingTimeChange={handleAverageLoadingTimeChange}
      />
      {warehouse.optimParams?.optimizationEngine === OptimizationEngine.Atoptima ? (
        <WarehouseAtoptimaParamCard
          key={`${siteDetails.id}-atoptimaParams`}
          atoptimaParams={siteDetails.optimParams?.atoptimaParams}
          onChange={handleAtoptimaParamChange}
          optimizerApps={optimizerApps}
          isMultisite={warehouse.optimParams?.isMultisite}
          isMultitour={warehouse.optimParams?.multiTourPermission}
        />
      ) : warehouse.optimParams?.optimizationEngine === OptimizationEngine.Kvrp ? (
        <WarehouseMapotempoCard
          key={`${siteDetails.id}-kvrp`}
          mapotempoConfig={siteDetails.kVrpConfig}
          onChange={handleMapotempoChange}
        />
      ) : null}

      <WarehouseOrderScreenCard
        key={`${siteDetails.id}-orderScreen`}
        orderScreen={siteDetails.orderScreen}
        onChange={handleOrderScreenChange}
      />
      <WarehouseOrderScreenCreationCard
        key={`${siteDetails.id}-orderScreenCreation`}
        orderScreen={siteDetails.orderScreen}
        onChange={handleOrderScreenChange}
      />
      <WarehouseExecutionScreenCard
        key={`${siteDetails.id}-executionScreen`}
        executionScreen={siteDetails.executionScreen}
        onChange={handleExecutionScreenChange}
      />
      <WarehouseExecutionTrackingScreenCard
        key={`${siteDetails.id}-executionTrackingScreen`}
        executionTrackingScreen={siteDetails.executionTrackingScreen}
        onChange={handleExecutionTrackingScreenChange}
      />
      <WarehousePlanningEndCustomerPageCard
        key={`${siteDetails.id}-planningEndCustomerPage`}
        planningEndCustomerPage={siteDetails.planningEndCustomerPage}
        onChange={handlePlanningEndCustomerPageChange}
      />
      <WarehouseAppointmentEndCustomerPageCard
        key={`${siteDetails.id}-appointmentEndCustomerPage`}
        appointmentEndCustomerPage={siteDetails.appointmentEndCustomerPage}
        onChange={handleAppointmentEndCustomerPageChange}
      />
      <WarehouseDeliveryInformationEndCustomerPageCard
        key={`${siteDetails.id}-deliveryInformationEndCustomerPage`}
        deliveryInformationEndCustomerPage={siteDetails.deliveryInformationEndCustomerPage}
        onChange={handleDeliveryInformationEndCustomerPageChange}
      />
      <WarehousePlanningMobileCard
        key={`${siteDetails.id}-planningMobile`}
        planningMobile={siteDetails.planningMobile}
        onChange={handlePlanningMobileChange}
      />
      <FormAction onSaveClick={handleSaveClick} onCancelClick={handleCancelClick} />
    </Grid>
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <SitesProvider>
    <WarehouseAdminFormScreen {...props} />
  </SitesProvider>
)
