import React from 'react'
import { Animation, EventTracker } from '@devexpress/dx-react-chart'
import {
  ArgumentAxis,
  BarSeries,
  Chart,
  Title,
  Tooltip,
  ValueAxis,
} from '@devexpress/dx-react-chart-material-ui'
import Export from 'components/Chart/Export'
import { IDailyAvgStats } from 'interfaces/IAvgStatsTours'
import StyledPaper from 'screens/ReportingCategory/components/StyledPaper'
import VerticalLabel from './VerticalLabel'
import TooltipArrow from './TooltipArrow'
import TooltipSheet from './TooltipSheet'
import { TooltipRoundedContent } from './TooltipRoundedContent'

interface IIndicatorProps {
  data: IDailyAvgStats[]
  title: string
  containerId: string
  exportName: string
  yKey: string
  dataCy?: string
  exportCy?: string
  caption?: string
  secondaryCaption?: string
  classCaption?: string
}

export const BarChart = ({
  data,
  title,
  containerId,
  exportName,
  yKey,
  dataCy,
  exportCy,
  caption,
  secondaryCaption,
  classCaption,
}: IIndicatorProps): JSX.Element => (
  <StyledPaper id={containerId}>
    <Chart data-cy={dataCy} data={data}>
      {data?.length >= 10 ? <ArgumentAxis labelComponent={VerticalLabel} /> : <ArgumentAxis />}
      <ValueAxis />

      <BarSeries argumentField="day" valueField={yKey} />
      <Title text={title} />
      <Animation />
      <EventTracker />

      <Tooltip
        sheetComponent={TooltipSheet}
        arrowComponent={TooltipArrow}
        contentComponent={TooltipRoundedContent}
      />
      <Export dataCy={exportCy} containerId={containerId} fileName={exportName} />
    </Chart>
    {caption && <div className={classCaption}>{caption}</div>}
    {secondaryCaption && <div className={classCaption}>{secondaryCaption}</div>}
  </StyledPaper>
)
