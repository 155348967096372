import React from 'react'
import { Tooltip } from '@devexpress/dx-react-chart-material-ui'
import { useTranslation } from 'react-i18next'

interface IProps {
  total: number
}

const CustomPieChartTooltipContent = ({
  text,
  total,
  ...props
}: Tooltip.ContentProps & IProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Tooltip.Content
      {...props}
      text={`${t('CustomerRatingReportingScreen.number')}: ${text} 
          - ${t('CustomerRatingReportingScreen.percentage')}: ${((+text / total) * 100).toFixed(
        2,
      )}%`}
    />
  )
}

export default CustomPieChartTooltipContent
