import React from 'react'

import ToggleSwitch from './ToggleSwitch'

// This default export determines where your story goes in the story list
export default {
  title: 'ToggleSwitch',
  component: ToggleSwitch,
}

const Template = ({ onChange, label = 'Switch', name = 'switch', ...props }) => (
  <ToggleSwitch name={name} label={label} onChange={onChange} {...props} />
)

export const Switch = Template.bind({})
