import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    cardLine: {
      padding: theme.spacing(5),
      backgroundColor: theme.color.backgroundStrongGrey,
      marginBottom: 2,
      display: 'flex',
      alignItems: 'center',
    },
    cardLineLabel: {
      fontWeight: 'bold',
      paddingRight: theme.spacing(2),
    },
    addressResultsContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      paddingLeft: '20px',
      borderLeft: '1px solid grey',
      marginLeft: '20px',
      maxHeight: '300px',
      overflow: 'auto',
    },
    autocompleteAddress: {
      width: 'auto',
      wordBreak: 'break-word',
    },
    addressLine: {
      cursor: 'pointer',
      border: '1px solid grey',
      borderRadius: '8px',
      boxShadow: `2px 2px 2px 0px`,
      width: '100%',
      padding: '6px',
      display: 'flex',
      justifyContent: 'space-between',
      gap: '6px',
    },
    disabledLine: {
      opacity: '0.5',
    },
    miniPadding: {
      padding: theme.spacing(2),
    },
    value: {
      paddingLeft: 30,
    },
    withBackground: {
      backgroundColor: theme.color.backgroundStrongGrey,
      display: 'flex',
      width: '100%',
      paddingLeft: '27px',
      marginBottom: '0px',
      zIndex: 2,
      fontSize: '1.1rem',
      fontWeight: 'bold',
      paddingTop: '15px',
    },
    fullWidth: {
      width: '100%',
    },
    noPadding: {
      padding: 0,
    },
    withMinHeight: {
      minHeight: '80px !important',
      paddingTop: '2%',
      paddingLeft: '3%',
      backgroundColor: theme.color.backgroundStrongGrey,
    },
    transparentBg: {
      backgroundColor: 'transparent !important',
      marginLeft: '-20px',
    },
    withMarginLeft: {
      marginLeft: '10px',
    },
    withoutBorder: {
      paddingLeft: theme.spacing(5),
      backgroundColor: theme.color.backgroundStrongGrey,
      marginBottom: 0,
      display: 'flex',
      alignItems: 'center',
      height: '30px',
    },
    innerDiv: {
      width: '95%',
      paddingBottom: '20px',
    },
    withinSection: {
      border: '1px solid #D5D5D5',
    },
    notWithinSection: {
      marginBottom: '2px',
    },
  }),
)
