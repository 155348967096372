import { createStyles, makeStyles } from '@material-ui/core/styles'

export default makeStyles(() =>
  createStyles({
    message: {
      fontFamily: 'inherit',
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word',
    },
  }),
)
