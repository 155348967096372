import React, { useMemo } from 'react'
import countries from 'i18n-iso-countries'
import enLocale from 'i18n-iso-countries/langs/en.json'
import frLocale from 'i18n-iso-countries/langs/fr.json'
import i18n from 'i18next'
import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

export interface ICountryValue {
  code: string
  label: string
}

interface ICountryAutocompleteProps {
  value: string
  onChange: (event: object, value: ICountryValue | null) => void
  dataCy?: string
  required?: boolean
  disableClearable?: boolean
}

const CountryAutocomplete = ({
  value,
  onChange,
  dataCy,
  required = false,
  disableClearable = false,
}: ICountryAutocompleteProps): JSX.Element => {
  countries.registerLocale(enLocale)
  countries.registerLocale(frLocale)
  const { t } = useTranslation()

  const getCountriesList = (): ICountryValue[] => {
    const countryObj = countries.getNames(i18n.language.slice(0, 2), { select: 'official' })

    return Object.entries(countryObj).map(([key, val]) => ({
      label: val,
      code: key,
    }))
  }

  const countriesList = useMemo((): ICountryValue[] => {
    const list = getCountriesList()
    return list.sort(
      (c1, c2) =>
        c1.label?.localeCompare(c2.label || '') || -1,
    )
  }, [])

  return (
    <Autocomplete
      id="country"
      data-cy={dataCy}
      autoComplete
      options={countriesList}
      getOptionLabel={(option) => option.label || ''}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label={t('CartDropoffConfigurationScreen.country').toUpperCase()}
          required={required}
        />
      )}
      onChange={onChange}
      value={countriesList.find((country) => country.label === value) || { code: '', label: '' }}
      getOptionSelected={(option, val) => option.label === val.label}
      fullWidth
      disableClearable={disableClearable}
    />
  )
}

export default CountryAutocomplete
