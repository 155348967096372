import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    subCard: {
      border: '0.5px solid #D5D5D5',
      borderRadius: '2px',
      padding: '15px',
    },
    tooltipedContainers: {
      height: '56px',
    },
    subCardTitle: {
      fontSize: '1.2rem',
      fontWeight: 'bold',
    },
    boldLabel: {
      fontWeight: 'bold',
    },
    subCardList: {
      marginLeft: '20px',
      marginTop: '10px',
    },
    subCardSelectList: {
      display: 'grid',
    },
    carrierDisabledValue: {
      height: '50px',
    },
    inlineWithMargin: {
      display: 'inline-flex',
      marginLeft: '30px',
      marginRight: '8px',
    },
    flex: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    flexRow: {
      flexDirection: 'row !important' as 'row',
    },
    customLabelPosition: {
      marginTop: '-6px',
      marginLeft: '14px',
    },
    labelInputWidth: {
      width: '140px',
      marginRight: '30px',
    },
    withMinimalWidth: {
      width: '90px',
      marginRight: '25px',
    },
    switchInput: {
      marginTop: '13px',
    },
    labelSpan: {
      fontSize: '1rem',
      fontFamily: 'Nunito',
      fontWeight: 400,
      lineHeight: 1.5,
      position: 'absolute',
      width: '150px',
      top: '-5px',
      left: '-3.5vw',
    },
    likeLabel: {
      boxSizing: 'inherit',
      color: theme.color.label,
      padding: 0,
      fontSize: '1rem',
      fontFamily: 'Nunito',
      fontWeight: 400,
      transform: 'translate(0, 1.5px) scale(0.75)',
      transformOrigin: 'top left',
      lineHeight: 1,
      display: 'block',
    },
    withMinWidth: {
      margin: '0px',
      minWidth: '100px',
    },
    header: {
      backgroundColor: theme.color.backgroundPaperTitle,
      color: theme.color.secondary,
      fontWeight: 'bold',
      borderRadius: '4px 4px 0 0',
    },
    infoContainer: {
      position: 'relative',
      margin: '0 auto',
    },
    orderList: {
      minHeight: '35px',
    },
    orderNumberInput: {
      width: '100%',
    },
    availabilityDetails: {
      position: 'relative',
      paddingTop: '20px',
    },
    content: {
      paddingLeft: '10px',
      paddingBottom: '5px',
      backgroundColor: theme.palette.background.default,
      marginBottom: '20px',
      display: 'flex',
      position: 'relative',
    },
    mapContent: {
      padding: 0,
      paddingBottom: '0!important',
      backgroundColor: theme.palette.background.default,
      marginBottom: '20px',
      display: 'flex',
      position: 'relative',
      height: 600,
    },
    multiContent: {
      paddingLeft: '10px',
      paddingBottom: '5px',
      backgroundColor: theme.palette.background.default,
      marginBottom: '20px',
      position: 'relative',
    },
    contentRef: {
      paddingLeft: '10px',
      paddingBottom: '5px',
      backgroundColor: theme.palette.background.default,
      marginBottom: '20px',
      display: 'flex',
      position: 'relative',
      flexDirection: 'column',
      alignItems: 'stretch',
    },
    fieldsContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      paddingLeft: '20px',
      paddingRight: '20px',
      gap: '15px',
    },
    ruleDeliverySelect: {
      marginBottom: 20,
    },
    rowFieldsContainer: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      paddingRight: '20px',
      paddingLeft: '20px',
    },
    fieldsWarehouse: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: '1px solid',
    },
    DropdownList: {
      display: 'block',
      backgroundColor: theme.palette.background.paper,
      position: 'absolute',
      marginTop: '35px',
      zIndex: 999,
    },
    modifyButton: {
      marginBottom: '10px',
    },
    warehouse: {
      fontSize: '18px',
    },
    btnSelect: {
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'flex-end',
    },
    filtersMargin: {
      marginTop: 22,
    },
    datePickerWidth: {
      width: 160,
    },
    orderConditionField: {
      marginTop: 20,
    },
    filtersWidth: {
      width: 140,
    },
    errorContainer: {
      marginTop: '75px',
    },
    select: {
      width: '100%',
    },
    detailsContainer: {
      position: 'relative',
      display: 'flex',
      marginBottom: '20px',
    },
    detailsContent: {
      marginTop: '20px',
      display: 'flex',
      flexDirection: 'column',
    },
    detailsInnerContent: {
      display: 'flex',
    },
    carriersRefList: {
      padding: '20px!important',
      justifyContent: 'space-around',
      backgroundColor: theme.color.backgroundStrongGrey,
    },
    controlLabel: {
      marginRight: 0,
      overflowX: 'auto',
    },
    headerDetails: {
      backgroundColor: theme.color.backgroundPaperTitle,
      color: theme.color.secondary,
      fontWeight: 'bolder',
    },
    contentDetails: {
      display: 'flex',
      padding: 0,
      paddingBottom: '0 !important',
      backgroundColor: theme.palette.background.default,
      maxHeight: '512px',
      overflow: 'auto',
    },
    carrierDetailsContainer: {
      position: 'relative',
      display: 'flex',
      marginBottom: '20px',
    },
    switchContainer: {
      margin: 'auto',
    },
    timeInput: {
      marginTop: '10px',
    },
    filters: {
      marginTop: '10px',
      display: 'flex',
      flexDirection: 'column',
    },
    configurationRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    configurationItemsContainer: {
      display: 'flex',
      marginBottom: 10,
    },
    geocodingFilter: {
      marginLeft: 8,
    },
    fullWidthSelect: {
      width: '100%',
    },
    tableSelect: {
      width: 150,
    },
    ruleInfoField: {
      width: 243,
      paddingTop: 8,
    },
    ruleInfoSwitch: {
      width: 'unset',
    },
    singleField: {
      width: 243,
    },
    fieldGroupContainer: {
      marginBottom: 30,
      paddingLeft: 20,
      paddingRight: 20,
    },
    applicationDaysContainer: {
      marginBottom: 20,
    },
    ruleTabsContainer: {
      display: 'flex',
      flexDirection: 'row',
      width: '107%',
    },
    ruleTab: {
      width: '80%',
      marginBottom: '10px',
    },
    tabActions: {
      width: '20%',
    },
    routingRuleSelect: {
      width: 243,
    },
    routingRuleSection: {
      marginTop: 30,
    },
    routingRuleSectionLabel: {
      marginBottom: '10px !important', // adding !important to override margin: 0 from Typography (which was happening on DEV)
    },
    siteSelect: {
      marginTop: 20,
    },
    ruleTextField: {
      marginBottom: 20,
      marginTop: 20,
    },
    ruleOptimizeSwitch: {
      marginBottom: 10,
      display: 'flex',
    },
    routingRuleCard: {
      marginBottom: 30,
    },
    routingRulesCard: {
      backgroundColor: theme.palette.background.default,
    },
    detailActionButtonsContainer: {
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      gap: '20px',
    },
    detailActionButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& > *': {
        marginLeft: '10px',
      },
    },
    detailHeaderContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    orderDetailHeaderContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: '16px',
    },
    condtionOrders: {
      maxHeight: '120px',
      overflow: 'auto',
    },
    conditionOrderContainer: {
      marginTop: '20px',
    },
    zipCode: {
      width: 180,
      display: 'inline-flex',
      verticalAlign: 'top',
      marginLeft: 8,
    },
    routingRuleZipCode: {
      width: 243,
      display: 'inline-flex',
      verticalAlign: 'top',
    },
    sectorInfoField: {
      flex: 1,
    },
    textFieldItem: {
      marginTop: '22px',
      marginLeft: '8px',
    },
    tenantDetailsContainer: {
      position: 'relative',
      margin: '0 auto',
      marginBottom: '20px',
    },
    detailsActionButtonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 4,
      width: 220,
    },
    newButton: {
      marginLeft: 10,
      color: '#FFF',
      fontWeight: 'bolder',
    },
    sendSmsButton: {
      marginLeft: 10,
    },
    sideTextField: {
      marginLeft: 10,
    },
    configurationTextField: {
      display: 'flex',
      flexDirection: 'column',
    },
    routingRuleFieldsContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      gap: '15px',
    },
    sectorInfoInput: {
      textOverflow: 'ellipsis',
    },
    validityRowLabel: {
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    rawRowContainer: {
      gap: '15px',
      paddingLeft: '20px',
      paddingRight: '20px',
    },
  }),
)
