import DateRangePicker from 'components/Inputs/DateRangePicker'
import moment from 'moment'
import React, { useEffect, useState, useMemo, useRef, useContext } from 'react'
import useInterval from '@use-it/interval'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { Fab, IconButton } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import clsx from 'clsx'

import MapInfosApi from 'api/mapInfos'
import Map from 'components/Map/ToursMap'
import { IMarker, ITourMapItem } from 'interfaces/map'
import { isIError } from 'api/types'
import { AUTO_RELOAD_TIMER } from 'constants/constants'
import {
  SiteFilter,
  CarrierFilter,
  DeliveryTypeFilter,
  StatusFilter,
} from 'components/Inputs/ListFilter'
import SearchInput from 'components/Inputs/SearchInput'
import { FeedbackContext } from 'store/FeedbackContext'
import { FiltersContext } from 'store/FiltersContext'
import { FilterKey, Filter } from 'constants/filters'
import { ScreenMode } from 'components/Map/ToursMap/FabMenu'
import { IVisit } from 'interfaces/IOrders'
import { AuthContext } from 'store/AuthContext'
import AppConfigProvider from 'store/AppConfigContext'
import ExecutionProvider, { ExecutionContext } from 'store/ExecutionContext'
import { FiltersContainer, SearchContainer, TopFiltersContainer } from 'components/Layout'
import ExtendedDateFilters from 'components/Table/ExtendedDateFilters'
import useStyles from './styles'

const filterKey = FilterKey.mapMonitoring

function MapMonitoringScreen(): JSX.Element {
  const isInitialMount = useRef(true)

  const { setFilter, resetFilters, filters } = useContext(FiltersContext)
  const { shouldDisplayVisitsWithoutTour } = useContext(AuthContext)

  const styles = useStyles()
  const [filteredTours, setFilteredTours] = useState<ITourMapItem[]>([])
  const [tours, setTours] = useState<ITourMapItem[]>([])
  const [drivers, setDrivers] = useState<IMarker[]>([])
  const carriers = filters[filterKey][Filter.carriers] as string[]
  const sites = filters[filterKey][Filter.sites] as string[]
  const deliveryTypes = filters[filterKey][Filter.deliveryTypes] as string[]
  const startDate = filters[filterKey][Filter.startDate] as string
  const endDate = filters[filterKey][Filter.endDate] as string
  const searchText = filters[filterKey][Filter.searchText] as string
  const status = filters[filterKey][Filter.status] as string[]
  const [isSearchReset, setIsSearchReset] = useState<boolean>(false)
  const [showFilters, setShowFilters] = useState<boolean>(false)
  const { openErrorSnack, toggleLoader } = useContext(FeedbackContext)
  const [displayMode, setDisplayMode] = useState<ScreenMode>(ScreenMode.MapAndListSummary)
  const [unassignedVisits, setUnassignedVisits] = useState<IVisit[]>([])
  const { highlightedElement, setHighlightedElement } = useContext(ExecutionContext)

  useEffect(() => {
    if (highlightedElement) {
      const element = document.getElementById(highlightedElement.id)
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        })
      }
    }
  }, [highlightedElement])

  const toggleDisplayMode = (): void =>
    setDisplayMode((prev) => (prev === ScreenMode.__LENGTH - 1 ? 0 : prev + 1))

  const search = (tour: ITourMapItem): boolean => {
    const seachByTourNumber = tour.tourNumber.toString().includes(searchText)
    const searchByDriverName = tour.driver?.name
      .toLocaleLowerCase()
      .includes(searchText?.toLocaleLowerCase())
    let searchByZipCode = false
    tour.zipCodes.forEach((zipCode) => {
      if (zipCode?.includes(searchText)) {
        searchByZipCode = true
      }
    })

    return seachByTourNumber || searchByDriverName || searchByZipCode
  }

  const filter = (tour: ITourMapItem): boolean => {
    const filterBySite = sites.length > 0 ? sites.includes(tour.warehouseId) : true
    const filterByCarriers = carriers.length > 0 && tour.driver ? carriers.includes(tour.driver.carrierId) : true
    const filterByDeliveryType = deliveryTypes.length > 0 ? deliveryTypes.includes(`${tour.deliveryType}`) : true
    const filterBySearch = searchText && searchText?.length > 0 ? search(tour) : true

    return filterBySite && filterByCarriers && filterByDeliveryType && filterBySearch
  }

  async function getDeliveringDrivers(): Promise<void> {
    toggleLoader(true)
    const res = await MapInfosApi.getDeliveringDrivers(startDate, endDate)
    if (!isIError(res)) {
      setDrivers(res)
    } else {
      openErrorSnack(res.error.message)
    }
    toggleLoader(false)
  }

  async function getSegments(): Promise<void> {
    toggleLoader(true)
    const res = await MapInfosApi.getSegments(startDate, endDate, status)
    if (!isIError(res)) {
      const filtered = res.filter((tour) => filter(tour))
      setFilteredTours(filtered)
      setTours(res)
    } else {
      openErrorSnack(res.error.message)
    }
    toggleLoader(false)
  }

  async function getUnassignedVisits(): Promise<void> {
    if (startDate && endDate && sites && sites.length > 0) {
      toggleLoader(true)
      const res = await MapInfosApi.getUnassignedVisits(startDate, endDate, sites, deliveryTypes)
      if (!isIError(res)) {
        setUnassignedVisits(res)
      } else {
        openErrorSnack(res.error.message)
      }
      toggleLoader(false)
    }
  }

  useInterval(getDeliveringDrivers, AUTO_RELOAD_TIMER)
  useInterval(getSegments, AUTO_RELOAD_TIMER)
  useInterval(getUnassignedVisits, shouldDisplayVisitsWithoutTour ? AUTO_RELOAD_TIMER : null)

  useEffect(() => {
    if (sites?.length) {
      getDeliveringDrivers()
      getSegments()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, status, sites])

  useEffect(() => {
    if (shouldDisplayVisitsWithoutTour) {
      getUnassignedVisits()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldDisplayVisitsWithoutTour, startDate, endDate, sites, deliveryTypes])

  useEffect(() => {
    if (!isInitialMount.current) {
      const filtered = tours.filter((tour) => filter(tour))
      setFilteredTours(filtered)
    } else {
      isInitialMount.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sites, carriers, deliveryTypes, searchText])

  const handleShowFilterClick = (): void => {
    setShowFilters((prev) => !prev)
  }

  const resetLocalFilters = (): void => {
    setIsSearchReset(true)
    resetFilters(filterKey)
  }

  const reloadData = () => {
    getDeliveringDrivers()
    getSegments()
    if (shouldDisplayVisitsWithoutTour) {
      getUnassignedVisits()
    }
  }

  const Filters = useMemo(
    (): JSX.Element => (
      <TopFiltersContainer>
        <FiltersContainer>
          <IconButton data-cy="hideFilterButton" onClick={handleShowFilterClick} color="primary">
            <KeyboardArrowUpIcon />
          </IconButton>
          <IconButton data-cy="initializeButton" onClick={resetLocalFilters}>
            <RefreshIcon />
          </IconButton>
          <DateRangePicker
            startDate={startDate}
            onChangeStart={(startValue): void => {
              setFilter(filterKey, Filter.startDate, moment(startValue).toISOString())
            }}
            endDate={endDate}
            onChangeEnd={(endValue): void => {
              setFilter(filterKey, Filter.endDate, moment(endValue).toISOString())
            }}
            hideShiftPicker
          />
          <SiteFilter
            handleChange={(values: string[]): void => {
              setFilter(filterKey, Filter.sites, values)
            }}
            ids={sites}
            dataCy="sitePicker"
            isUnselectAllowed={false}
          />
          <CarrierFilter
            handleChange={(values: string[]): void => {
              setFilter(filterKey, Filter.carriers, values)
            }}
            ids={carriers}
            dataCy="carrierPicker"
            siteIds={sites}
            filterKey={filterKey}
          />
          <DeliveryTypeFilter
            handleChange={(values: string[]): void => {
              setFilter(filterKey, Filter.deliveryTypes, values)
            }}
            ids={deliveryTypes}
            dataCy="deliveryPicker"
          />
          <StatusFilter
            handleChange={(statusValues: string[]): void => {
              setFilter(filterKey, Filter.status, statusValues)
            }}
            ids={status}
            dataCy="statusPicker"
          />
        </FiltersContainer>
        <SearchContainer>
          <SearchInput
            defaultValue={searchText}
            key={isSearchReset ? 'search-reset' : ''}
            onSearch={(text: string): void => {
              setIsSearchReset(false)
              setFilter(filterKey, Filter.searchText, text)
            }}
          />
        </SearchContainer>
      </TopFiltersContainer>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sites, carriers, deliveryTypes, searchText, status, styles, startDate, endDate],
  )

  const handleClick = () => {
    setHighlightedElement(undefined)
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleClick()
    }
  }

  const setEndDate = (value: string): void => {
    setFilter(filterKey, Filter.endDate, value)
  }

  const setStartDate = (value: string): void => {
    setFilter(filterKey, Filter.startDate, value)
  }

  return (
    <>
      {showFilters && (
        <>
          {Filters}
          <ExtendedDateFilters
            today
            tomorrow
            thisWeek
            next7days
            setEndDate={setEndDate}
            setStartDate={setStartDate}
          />
        </>
      )}
      <div
        data-cy="map"
        className={`${styles.toursMapContainer} ${
          showFilters && styles.toursMapContainerWithFilters
        }`}
        role="button"
        tabIndex={0}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
      >
        <Map
          mapName="mapMonitoring"
          displayMode={displayMode}
          toggleDisplayMode={toggleDisplayMode}
          filteredTours={filteredTours}
          drivers={drivers}
          unassignedVisits={unassignedVisits}
          reloadData={reloadData}
        />
      </div>
      {!showFilters && (
        <Fab
          data-cy="showFilterButton"
          color="primary"
          className={clsx(
            styles.fabFilterButton,
            displayMode === ScreenMode.List && styles.fabFilterButtonWithoutMap,
          )}
          onClick={handleShowFilterClick}
        >
          <KeyboardArrowDownIcon />
        </Fab>
      )}
    </>
  )
}

export default (): JSX.Element => (
  <AppConfigProvider>
    <ExecutionProvider>
      <MapMonitoringScreen />
    </ExecutionProvider>
  </AppConfigProvider>
)
