import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { useTranslation } from 'react-i18next'
import CircularProgress from '@material-ui/core/CircularProgress'
import useStyles from './styles'

interface IProps {
  message: string
  open: boolean
  onClose: (validate: boolean) => void
  confirmMessage?: string
  denyMessage?: string
  isConfirmDisabled?: boolean
  showCancelOption?: boolean
  showConfirmOption?: boolean
}

const ConfirmationDialog = ({
  open,
  onClose,
  message,
  confirmMessage,
  isConfirmDisabled,
  denyMessage,
  showCancelOption = true,
  showConfirmOption = true,
}: IProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="confirmation-dialog"
      open={open}
    >
      <DialogContent>
        <pre className={classes.dialogMessage}>{message}</pre>
      </DialogContent>
      <DialogActions>
        {showCancelOption && (
          <Button
            data-cy="cancelButton"
            disabled={isConfirmDisabled}
            onClick={() => {
              onClose(false)
            }}
            color="primary"
          >
            {denyMessage || t('confirmation.cancel')}
          </Button>
        )}
        {showConfirmOption && (
          <Button
            data-cy="okButton"
            disabled={isConfirmDisabled}
            onClick={() => {
              onClose(true)
            }}
            color="primary"
          >
            {confirmMessage || t('confirmation.ok')}
            {isConfirmDisabled && (
              <CircularProgress color="secondary" size={24} style={{ marginLeft: 8 }} />
            )}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
