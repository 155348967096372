import React, { Dispatch, SetStateAction, useContext, useState, KeyboardEvent } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import printJS from 'print-js'

import { ICustomerAddress } from 'interfaces/ICustomer'
import { CreatedOrder, IOrderCreate, IOrderForm, IOrderRequiredField } from 'interfaces/IOrders'
import { FeedbackContext } from 'store/FeedbackContext'
import AddressApi from 'api/address'
import CustomersApi from 'api/customer/customer'
import OrdersApi from 'api/orders'
import { IError, isIError } from 'api/types'
import { ICustomer } from 'interfaces/interfaces'
import { AuthContext } from 'store/AuthContext'
import { IAutocompleteResult } from 'interfaces/IAutocompleteResult'
import {
  CustomerSearchField,
  DestinationType,
  ORDER_REQUIRED_FIELDS,
  OrderStepKey,
  TransportType,
} from 'constants/constants'
import { isDefined } from 'utils/functions'
import { ContentContext } from 'store/ContentContext'
import { getErrorList } from 'utils/errorUtils'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import { OrderPdfGenerator, getDeliveryTypeLabelByCode } from 'utils/libs/pdf-generation'
import { IWarehouseCartDropoffConfig } from 'interfaces/IWarehouseCartDropoffConfig'
import { IOrderTimeSlot, ISelectedTimeSlot } from 'interfaces/IWarehouseTimeSlot'
import CartDropoffApi from 'api/cartDropoff/cartDropoff'
import { ICartDropoffDraft } from 'interfaces/ICartDropoffDraft'
import { convertToMinutes } from 'utils/libs/time-conversion'
import AddressDialog from './AddressDialog'
import EmailForceUpdate from './EmailForceUpdateDialog'
import OriginCard from './OriginCard'
import DestinationCard from './DestinationCard'
import OrderCard from './OrderCard'
import OrderFormAction from './OrderFormAction'
import DeliveryInfoCard from './DeliveryInfoCard'
import ExistingDraftDialog from './ExistingDraftDialog'
import useStyles from './styles'

interface IOrderFormProps {
  isCartDropoff?: boolean
  order: Partial<IOrderForm>
  setOrder: Dispatch<SetStateAction<Partial<IOrderForm>>>
  handleChange: (key: keyof IOrderForm, value: unknown) => void
  resetDestinationCardData: () => void
  deliveryInfoCardRef?: React.RefObject<HTMLDivElement>
  isOrderNumberValid: boolean
  isBookingEnabled?: boolean
  openOrderBookingScreen?: () => Promise<void>
  destinationType: DestinationType
  setDestinationType: Dispatch<SetStateAction<DestinationType>>
  config?: IWarehouseCartDropoffConfig | null
  orderTimeSlots?: IOrderTimeSlot[]
  selectedTimeSlot?: ISelectedTimeSlot
  setSelectedTimeSlot?: React.Dispatch<React.SetStateAction<ISelectedTimeSlot | undefined>>
  onClick?: (key: OrderStepKey) => void
  resetOriginCardData: () => void
  existingDraft?: ICartDropoffDraft
  setExistingDraft?: React.Dispatch<React.SetStateAction<ICartDropoffDraft | undefined>>
}

const OrderForm = ({
  isCartDropoff = false,
  order,
  setOrder,
  handleChange,
  resetDestinationCardData,
  deliveryInfoCardRef,
  isOrderNumberValid,
  isBookingEnabled,
  openOrderBookingScreen,
  destinationType,
  setDestinationType,
  config,
  orderTimeSlots,
  selectedTimeSlot,
  setSelectedTimeSlot,
  onClick,
  resetOriginCardData,
  existingDraft,
  setExistingDraft,
}: IOrderFormProps): JSX.Element => {
  let forceUpdate = false
  let printAndSaveEnabled = false
  const { t } = useTranslation()
  const classes = useStyles()
  const { push: navigateTo } = useHistory()
  const { toggleLoader, openErrorSnack, openSuccessSnack } = useContext(FeedbackContext)
  const { user } = useContext(AuthContext)
  const { sites, carriers } = useContext(ContentContext)
  const [addressDialogOpen, setAddressDialogOpen] = useState<boolean>(false)
  const [dialogAddresses, setDialogAddresses] = useState<ICustomerAddress[]>([])
  const [customerChoice, setCustomerChoice] = useState<Partial<ICustomer>>()
  const [toggleEmailForceUpdatePopup, setToggleEmailForceUpdatePopup] = useState<boolean>(false)
  const [customerChoices, setCustomerChoices] = useState<ICustomer[]>([])
  const [addressChoices, setAddressChoices] = useState<IAutocompleteResult[]>([])
  const [isExistingDraftDialogOpen, setIsExistingDraftDialogOpen] = useState<boolean>(false)

  async function getExistingDraft(
    email: string,
    deliveryType?: number,
  ): Promise<ICartDropoffDraft | null> {
    toggleLoader(true)
    const response = await CartDropoffApi.getExistingDraft(email, deliveryType)
    toggleLoader(false)
    if (isIError(response)) {
      openErrorSnack(response.error.message)
      return null
    }
    return response
  }

  const mapToOrderAddress = (customerAddress: ICustomerAddress): Partial<IOrderForm> => ({
    deliveryAddressFull: customerAddress.deliveryAddress,
    deliveryAddressRoadNumber: customerAddress.deliveryAddressNumber,
    deliveryAddressRoad: customerAddress.deliveryAddressRoad,
    deliveryAddressZipCode: customerAddress.deliveryAddressZipCode,
    deliveryAddressCity: customerAddress.deliveryAddressCity,
    deliveryAddressCountry: customerAddress.deliveryAddressCountry,
    deliveryInstructions: customerAddress.deliveryInstructions,
    deliveryFloor: customerAddress.deliveryFloor,
    deliveryIsElevatorPresent: customerAddress.deliveryIsElevatorPresent,
    deliveryDoorCode: customerAddress.deliveryDoorCode,
  })

  const handleDialogSelectAddress = (address: ICustomerAddress): void => {
    setOrder({ ...order, ...mapToOrderAddress(address) })
  }

  const handleForceEmailUpdateDialogClose = (): void => {
    setToggleEmailForceUpdatePopup(false)
    toggleLoader(false)
  }

  const handleForceEmailUpdate = (): void => {
    forceUpdate = true
    setToggleEmailForceUpdatePopup(false)
    performSave()
  }

  const handleAutocompleteResponse = (res: ICustomer[] | IError): void => {
    if (isIError(res)) {
      openErrorSnack(res.error.message)
    } else {
      setCustomerChoices(res)
    }
  }

  const debouncedCustomerEmailAutocomplete = useDebouncedCallback(async () => {
    if (order.customerEmail && order.customerEmail.length >= 3) {
      const res = await CustomersApi.searchCustomers({
        search: order.customerEmail,
        searchField: CustomerSearchField.Email,
      })
      handleAutocompleteResponse(res)
    }
  }, 1000)

  const debouncedCustomerLastNameAutocomplete = useDebouncedCallback(async () => {
    if (order.customerLastName && order.customerLastName.length >= 3) {
      const res = await CustomersApi.searchCustomers({
        search: order.customerLastName,
        searchField: CustomerSearchField.LastName,
      })
      handleAutocompleteResponse(res)
    }
  }, 1000)

  const debouncedCustomerPhoneNumberAutocomplete = useDebouncedCallback(async () => {
    if (order.customerPhoneNumber && order.customerPhoneNumber.length >= 3) {
      const res = await CustomersApi.searchCustomers({
        search: order.customerPhoneNumber,
        searchField: CustomerSearchField.PhoneNumber,
      })
      handleAutocompleteResponse(res)
    }
  }, 1000)

  const handleCustomerAutocomplete = async (field: CustomerSearchField): Promise<void> => {
    if (field === CustomerSearchField.Email) {
      debouncedCustomerEmailAutocomplete.callback()
    }
    if (field === CustomerSearchField.LastName) {
      debouncedCustomerLastNameAutocomplete.callback()
    }
    if (field === CustomerSearchField.PhoneNumber) {
      debouncedCustomerPhoneNumberAutocomplete.callback()
    }
  }

  const resetCustomerChoices = (): void => {
    setCustomerChoices([])
  }

  const processCustomerChoice = (customer: Partial<ICustomer>): void => {
    const mapped = {
      customerFirstName: customer.firstName,
      customerLastName: customer.lastName,
      customerEmail: customer.email,
      customerPhoneNumber: customer.phoneNumber,
      areTermsOfSaleAccepted: customer.areTermsOfSaleAccepted,
    }
    if (Array.isArray(customer.addresses) && customer.addresses.length === 1) {
      setOrder({ ...order, ...mapToOrderAddress(customer.addresses[0]), ...mapped })
    } else if (Array.isArray(customer.addresses) && customer.addresses.length > 1) {
      setOrder({ ...order, ...mapped })
      setAddressDialogOpen(true)
      setDialogAddresses(customer.addresses)
    } else {
      setOrder({ ...order, ...mapped })
    }
  }

  const handleConfirmCustomerChoice = async (customer: Partial<ICustomer>): Promise<void> => {
    setCustomerChoice(customer)
    if (!isCartDropoff) {
      processCustomerChoice(customer)
    } else if (customer.email) {
      const res = await getExistingDraft(customer.email, order.deliveryType)
      if (res) {
        if (setExistingDraft) {
          setExistingDraft(res)
        }
        setIsExistingDraftDialogOpen(true)
      } else {
        processCustomerChoice(customer)
      }
    }
  }

  const handleDeleteAddress = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    address: ICustomerAddress,
  ): Promise<void> => {
    if (customerChoice?._id) {
      toggleLoader(true)
      const res = await CustomersApi.deleteAddress(customerChoice._id, address)
      if (isIError(res)) {
        openErrorSnack(res.error.message)
      } else {
        setDialogAddresses(res.addresses)
      }
      toggleLoader(false)
    }
  }

  const debouncedAddressAutocomplete = useDebouncedCallback(async () => {
    if (order.deliveryAddressRoad && order.deliveryAddressRoad?.length > 7) {
      const res = await AddressApi.autocompleteAddress(
        order.deliveryAddressRoad,
        order.warehouseId,
        user?.tenantConfig.language,
      )
      if (isIError(res)) {
        openErrorSnack(res.error.message)
      } else {
        setAddressChoices(res)
      }
      toggleLoader(false)
    }
  }, 1000)

  const handleAutocomplete = async (): Promise<void> => {
    debouncedAddressAutocomplete.callback()
  }

  const resetAddressChoices = (): void => {
    setAddressChoices([])
  }

  const handleConfirmAddressChoice = (newAddress: Partial<IOrderForm>): void => {
    setOrder({ ...order, ...newAddress })
  }

  const buildDeliveryFullAddress = () => {
    if (
      !order.deliveryAddressRoadNumber
      && !order.deliveryAddressRoad
      && !order.deliveryAddressZipCode
      && !order.deliveryAddressCity
    ) {
      return undefined
    }
    const fullAddress = `${order.deliveryAddressRoadNumber || '1'} ${
      order.deliveryAddressRoad || ''
    }, ${order.deliveryAddressZipCode || ''} ${order.deliveryAddressCity || ''}`
    return fullAddress.trim()
  }

  const validateFieldsFormat = (
    deliveryFloor: number | null,
    estimatedServiceTime: number | null,
    estimatedWeight: number | null,
    numberOfArticles: number | null,
    mainQuantity: number | null,
  ): string[] => {
    const errors: string[] = []
    if (
      order.customerEmail
      && !order.customerEmail.match(/^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/)
    ) {
      errors.push(t('OrdersScreen.messages.emailFormatValidation'))
    }
    if (order.customerPhoneNumber?.includes(' ')) {
      errors.push(t('OrdersScreen.messages.phoneNumberValidation'))
    }
    if (deliveryFloor && deliveryFloor % 1 !== 0) {
      errors.push(t('OrdersScreen.messages.orderDeliveryFloorValidation'))
    }
    if (order.deliveryDateTimeMinimum && order.deliveryDateTimeMaximum
      && new Date(order.deliveryDateTimeMinimum).getTime() > new Date(order.deliveryDateTimeMaximum).getTime()) {
      errors.push(t('OrdersScreen.messages.deliveryDateTimeValidation'))
    }
    if (estimatedServiceTime && (estimatedServiceTime < 0 || estimatedServiceTime % 1 !== 0)) {
      errors.push(t('OrdersScreen.messages.estimatedServiceTimeValidation'))
    }
    if (mainQuantity !== null && mainQuantity <= 0) {
      errors.push(t('OrdersScreen.messages.mainQuantityValidation'))
    }
    if (numberOfArticles && numberOfArticles < 0) {
      errors.push(t('OrdersScreen.messages.numberOfArticlesValidation'))
    }
    if (
      estimatedWeight
      && (estimatedWeight < 0
        || (estimatedWeight.toString().split('.').length === 2
          && estimatedWeight.toString().split('.')[1].length > 3))
    ) {
      errors.push(t('OrdersScreen.messages.estimatedWeightValidation'))
    }
    return errors
  }

  const validateForm = async (
    deliveryFloor: number | null,
    estimatedServiceTime: number | null,
    estimatedWeight: number | null,
    numberOfArticles: number | null,
    mainQuantity: number | null,
  ): Promise<string[]> => {
    const requiredFieldsNotFilled: string[] = []
    const requiredFields: IOrderRequiredField[] = []
    Object.entries(ORDER_REQUIRED_FIELDS).forEach(([key, value]) => {
      const fields = key === OrderStepKey.Destination
        ? value.filter(
          (elem) => !elem.destinationType || elem.destinationType === destinationType,
        )
        : value
      requiredFields.push(...fields)
    })
    requiredFields.forEach((requiredField) => {
      if (requiredField.isNumber) {
        const value = order[requiredField.name]
        const convertedValue = isDefined(value) && (value as unknown) !== '' ? Number(value) : null
        if (convertedValue === null) {
          requiredFieldsNotFilled.push(t(requiredField.translationKey))
        }
      } else if (!order[requiredField.name]) {
        requiredFieldsNotFilled.push(t(requiredField.translationKey))
      }
    })
    if (!destinationType) {
      requiredFieldsNotFilled.push(t('OrdersScreen.deliveryAddressType.title'))
    }
    const errors: string[] = requiredFieldsNotFilled.map((requiredField) =>
      t('form.fieldIsRequired', { field: requiredField.toLowerCase() }),
    ) || []

    if (isCartDropoff && order.orderNumber) {
      const response = await OrdersApi.checkOrderNumber(order.orderNumber)
      if (!isIError(response) && response) {
        errors.push(t('OrdersScreen.orderNumberAlreadyExisting'))
      }
    }

    const formatErrors = validateFieldsFormat(
      deliveryFloor,
      estimatedServiceTime,
      estimatedWeight,
      numberOfArticles,
      mainQuantity,
    )
    errors.push(...formatErrors)
    return errors
  }

  const validateDraft = (
    deliveryFloor: number | null,
    estimatedServiceTime: number | null,
    estimatedWeight: number | null,
    numberOfArticles: number | null,
    mainQuantity: number | null,
  ): string[] => {
    const requiredFieldsNotFilled: string[] = []
    const requiredFields = ORDER_REQUIRED_FIELDS[OrderStepKey.Origin].filter(
      (field) =>
        field.name === 'customerEmail'
        || field.name === 'customerLastName'
        || field.name === 'customerFirstName',
    )
    requiredFields.forEach((requiredField) => {
      if (!order[requiredField.name]) {
        requiredFieldsNotFilled.push(t(requiredField.translationKey))
      }
    })
    const errors: string[] = requiredFieldsNotFilled.map((requiredField) =>
      t('form.fieldIsRequired', { field: requiredField.toLowerCase() }),
    ) || []

    const formatErrors = validateFieldsFormat(
      deliveryFloor,
      estimatedServiceTime,
      estimatedWeight,
      numberOfArticles,
      mainQuantity,
    )
    errors.push(...formatErrors)
    return errors
  }

  const convertNumbers = (): {
    deliveryFloor: number | null,
    estimatedServiceTime: number | null,
    estimatedWeight: number | null,
    numberOfArticles: number | null,
    mainQuantity: number | null,
  } => {
    const deliveryFloor = isDefined(order.deliveryFloor) && (order.deliveryFloor as unknown) !== ''
      ? Number(order.deliveryFloor)
      : null

    const estimatedServiceTime = isDefined(order.estimatedServiceTime) && (order.estimatedServiceTime as unknown) !== ''
      ? Number(order.estimatedServiceTime)
      : null

    const estimatedWeight = isDefined(order.estimatedWeight) && (order.estimatedWeight as unknown) !== ''
      ? Number(order.estimatedWeight)
      : null

    const numberOfArticles = isDefined(order.numberOfArticles) && (order.numberOfArticles as unknown) !== ''
      ? Number(order.numberOfArticles)
      : null

    const mainQuantity = isDefined(order.mainQuantity) && (order.mainQuantity as unknown) !== ''
      ? Number(order.mainQuantity)
      : null

    return { deliveryFloor, estimatedServiceTime, estimatedWeight, numberOfArticles, mainQuantity }
  }

  const getMainQuantityValue = (mainQuantity: number | null): number | null => {
    if (mainQuantity) {
      return (order.transportType === TransportType.Pickup && mainQuantity > 0) ? mainQuantity * -1 : mainQuantity
    }
    return mainQuantity
  }

  const performSaveDraft = async (): Promise<void> => {
    toggleLoader(true)

    const { deliveryFloor, estimatedServiceTime, estimatedWeight, numberOfArticles, mainQuantity } = convertNumbers()

    const validationErrors = validateDraft(deliveryFloor, estimatedServiceTime, estimatedWeight, numberOfArticles, mainQuantity)

    if (validationErrors.length > 0) {
      openErrorSnack(validationErrors.join('\n'))
      toggleLoader(false)
      return
    }

    const mainQuantityValue = getMainQuantityValue(mainQuantity)

    const draft = {
      carrierId: order.carrierId,
      deliveryAddressCity: order.deliveryAddressCity,
      deliveryAddressCountry: order.deliveryAddressCountry,
      deliveryAddressNumber: order.deliveryAddressRoadNumber,
      deliveryAddressRoad: order.deliveryAddressRoad,
      deliveryAddressZipCode: order.deliveryAddressZipCode,
      deliveryDateTimeMaximum: order.deliveryDateTimeMaximum,
      deliveryDateTimeMinimum: order.deliveryDateTimeMinimum,
      deliveryDoorCode: order.deliveryDoorCode,
      deliveryFloor,
      deliveryInstructions: order.deliveryInstructions,
      deliveryIsElevatorPresent: order.deliveryIsElevatorPresent,
      deliveryType: order.deliveryType,
      departureSite: order.warehouseId,
      destinationType,
      email: order.customerEmail,
      enterpriseLabel: order.enterpriseLabel,
      estimatedServiceTime: estimatedServiceTime !== null ? estimatedServiceTime * 60 : estimatedServiceTime,
      estimatedWeight,
      firstName: order.customerFirstName,
      lastName: order.customerLastName,
      mainQuantity: mainQuantityValue,
      numberOfArticles,
      orderAmount: order.orderAmount,
      orderInstructions: order.orderInstructions,
      orderNumber: order.orderNumber,
      parcelCodes: order.parcelCodes,
      phoneNumber: order.customerPhoneNumber,
      scanTicket: order.scanTicket,
      tags: order.tags,
      areTermsOfSaleAccepted: order.areTermsOfSaleAccepted,
      transportType: order.transportType,
      deliverySiteId: order.deliverySiteId,
    } as ICartDropoffDraft

    const response = existingDraft?.id
      ? await CartDropoffApi.updateDraft(existingDraft.id, draft)
      : await CartDropoffApi.saveDraft(draft)

    if (isIError(response)) {
      response.error.errorList = getErrorList(response)
      if (response.error.fieldErrors) {
        openErrorSnack(response.error.errorList.join('\n'))
      } else {
        openErrorSnack(response.error.message)
      }

      toggleLoader(false)
      return
    }
    openSuccessSnack(t('OrdersScreen.draftSavedSuccessfully'))
    toggleLoader(false)

    navigateTo(ROUTES_PATH.reloadCartDropoff)
  }

  const performSave = async (): Promise<CreatedOrder | void> => {
    toggleLoader(true)

    const { deliveryFloor, estimatedServiceTime, estimatedWeight, numberOfArticles, mainQuantity } = convertNumbers()

    const validationErrors = await validateForm(
      deliveryFloor,
      estimatedServiceTime,
      estimatedWeight,
      numberOfArticles,
      mainQuantity,
    )

    if (validationErrors.length > 0) {
      openErrorSnack(validationErrors.join('\n'))
      toggleLoader(false)
      return
    }
    if (
      customerChoice
      && (order.customerFirstName !== customerChoice?.firstName
        || order.customerLastName !== customerChoice?.lastName
        || order.customerEmail !== customerChoice?.email)
      && !forceUpdate
    ) {
      setToggleEmailForceUpdatePopup(true)
      return
    }

    const mainQuantityValue = getMainQuantityValue(mainQuantity)

    const response = await OrdersApi.create({
      orderNumber: order.orderNumber,
      deliveryType: order.deliveryType,
      transportType: order.transportType,
      mainQuantity: mainQuantityValue,
      deliveryDateTimeMinimum: order.deliveryDateTimeMinimum,
      deliveryDateTimeMaximum: order.deliveryDateTimeMaximum,
      estimatedWeight,
      warehouseCode: sites.find((site) => site.id === order.warehouseId)?.code,
      deliverySiteCode:
        destinationType === DestinationType.Warehouse
          ? sites.find((site) => site.id === order.deliverySiteId)?.code
          : undefined,
      instructions: order.deliveryInstructions,
      enterpriseLabel: order.enterpriseLabel,
      customer: {
        firstName: order.customerFirstName,
        lastName: order.customerLastName,
        email: order.customerEmail,
        phoneNumber: order.customerPhoneNumber,
      },
      deliveryAddress:
        destinationType === DestinationType.Address
          ? {
            full: buildDeliveryFullAddress(),
            roadNumber: order.deliveryAddressRoadNumber || '1',
            road: order.deliveryAddressRoad,
            zipCode: order.deliveryAddressZipCode,
            city: order.deliveryAddressCity,
            country: order.deliveryAddressCountry,
            floor: deliveryFloor,
            hasElevator: order.deliveryIsElevatorPresent,
            doorCode: order.deliveryDoorCode,
          }
          : undefined,
      estimatedServiceTime: estimatedServiceTime !== null ? estimatedServiceTime * 60 : estimatedServiceTime,
      generateVisit: order.generateVisit,
      clientCode: order.clientCode,
      orderAmount: order.orderAmount,
      requestSource: 'MANUAL',
      orderInstructions: order.orderInstructions,
      tags: order.tags,
      scanTicket: order.scanTicket,
      numberOfArticles,
      parcels: order.parcelCodes?.map((parcelCode) => ({ ean: parcelCode })),
      areTermsOfSaleAccepted: order.areTermsOfSaleAccepted,
      carrierCode: carriers.find((carrier) => carrier.id === order.carrierId)?.code,
    } as IOrderCreate)

    if (isIError(response)) {
      response.error.errorList = getErrorList(response)
      if (response.error.fieldErrors) {
        openErrorSnack(response.error.errorList.join('\n'))
      } else {
        openErrorSnack(response.error.message)
      }

      toggleLoader(false)
      return
    }
    openSuccessSnack(t('OrdersScreen.successfullyCreated'))
    toggleLoader(false)

    if (!printAndSaveEnabled) {
      navigateTo(isCartDropoff ? ROUTES_PATH.reloadCartDropoff : ROUTES_PATH.ordersList)
      return
    }

    const newOrder = response[0]
    if (!newOrder) {
      return
    }

    const warehouseName = sites.find((site) => site.id === newOrder.warehouseId)?.name || ''
    const carrierName = carriers.find(carrier => carrier.id === newOrder.carrierId)?.name || ''
    newOrder.deliveryTypeLabel = getDeliveryTypeLabelByCode(newOrder.deliveryType, user?.tenantConfig?.deliveryTypes)
    newOrder.enterprise = newOrder.enterprise?.label || ''
    try {
      const docUrl = OrderPdfGenerator.generateOrderPDF(
        warehouseName,
        newOrder,
        carrierName,
        destinationType,
        isCartDropoff,
        config || {},
      ).documentURL
      printJS({ printable: docUrl })
      navigateTo(isCartDropoff ? ROUTES_PATH.reloadCartDropoff : ROUTES_PATH.ordersList)
    } catch (err) {
      openErrorSnack(t('OrdersScreen.errorGeneratingPdf'))
    }
  }

  const handleSaveDraftClick = async (): Promise<void> => {
    await performSaveDraft()
  }

  const handleSaveClick = async (): Promise<CreatedOrder | void> => {
    printAndSaveEnabled = false
    await performSave()
  }

  const handleSaveAndPrintClick = async (): Promise<void> => {
    printAndSaveEnabled = true
    await performSave()
  }

  const mapDraftToOrder = (draft: ICartDropoffDraft): Partial<IOrderForm> => ({
    orderNumber: draft.orderNumber,
    deliveryType: draft.deliveryType,
    mainQuantity: draft.mainQuantity,
    deliveryDateTimeMinimum: draft.deliveryDateTimeMinimum,
    deliveryDateTimeMaximum: draft.deliveryDateTimeMaximum,
    warehouseId: draft.departureSite,
    customerFirstName: draft.firstName,
    customerLastName: draft.lastName,
    customerPhoneNumber: draft.phoneNumber,
    customerEmail: draft.email,
    deliveryAddressRoadNumber: draft.deliveryAddressNumber,
    deliveryAddressRoad: draft.deliveryAddressRoad,
    deliveryAddressZipCode: draft.deliveryAddressZipCode,
    deliveryAddressCity: draft.deliveryAddressCity,
    deliveryAddressCountry: draft.deliveryAddressCountry,
    enterpriseLabel: draft.enterpriseLabel,
    deliveryInstructions: draft.deliveryInstructions,
    transportType: draft.transportType,
    estimatedServiceTime: convertToMinutes(draft.estimatedServiceTime),
    orderAmount: draft.orderAmount,
    estimatedWeight: draft.estimatedWeight,
    orderInstructions: draft.orderInstructions,
    deliveryFloor: draft.deliveryFloor,
    deliveryIsElevatorPresent: draft.deliveryIsElevatorPresent,
    deliveryDoorCode: draft.deliveryDoorCode,
    tags: draft.tags,
    scanTicket: draft.scanTicket,
    numberOfArticles: draft.numberOfArticles,
    parcelCodes: draft.parcelCodes,
    areTermsOfSaleAccepted: draft.areTermsOfSaleAccepted,
    carrierId: draft.carrierId,
    deliverySiteId: draft.deliverySiteId,
  })

  const handleExistingDraftDialogClose = (): void => {
    setIsExistingDraftDialogOpen(false)
    if (setExistingDraft) {
      setExistingDraft(undefined)
    }
    if (customerChoice) processCustomerChoice(customerChoice)
  }

  const handleExistingDraftValidate = (): void => {
    if (existingDraft) {
      setOrder(mapDraftToOrder(existingDraft))
      setDestinationType(existingDraft.destinationType as DestinationType)
      if (setSelectedTimeSlot) {
        setSelectedTimeSlot(undefined)
      }
    }
    setIsExistingDraftDialogOpen(false)
  }

  const handleClick = (stepKey: OrderStepKey) => {
    if (customerChoices.length > 0) {
      resetCustomerChoices()
    }
    if (onClick) {
      onClick(stepKey)
    }
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>, stepKey: OrderStepKey) => {
    if (e.keyCode === 13) {
      handleClick(stepKey)
    }
  }

  return (
    <>
      <div className={isCartDropoff ? classes.orderForm : ''}>
        <AddressDialog
          open={addressDialogOpen}
          addresses={dialogAddresses}
          onSelectAddress={handleDialogSelectAddress}
          setDialogOpen={setAddressDialogOpen}
          onDeleteAddress={handleDeleteAddress}
        />
        <EmailForceUpdate
          firstName={customerChoice?.firstName || ''}
          handleClose={handleForceEmailUpdateDialogClose}
          lastName={customerChoice?.lastName || ''}
          open={toggleEmailForceUpdatePopup}
          onValidateForUpdate={handleForceEmailUpdate}
        />
        <ExistingDraftDialog
          handleClose={handleExistingDraftDialogClose}
          open={isExistingDraftDialogOpen}
          onValidate={handleExistingDraftValidate}
        />
        <div
          id={OrderStepKey.Origin}
          tabIndex={0}
          role="button"
          onClick={() => { handleClick(OrderStepKey.Origin) }}
          onKeyDown={(e) => { handleKeyDown(e, OrderStepKey.Origin) }}
        >
          <OriginCard
            order={order}
            onChange={handleChange}
            onCustomerAutocomplete={handleCustomerAutocomplete}
            customerChoices={customerChoices}
            onResetCustomerChoices={resetCustomerChoices}
            onConfirmCustomerChoice={handleConfirmCustomerChoice}
            isCartDropoff={isCartDropoff}
            config={config}
            resetOriginCardData={resetOriginCardData}
          />
        </div>
        <div
          id={OrderStepKey.Destination}
          tabIndex={0}
          role="button"
          onClick={() => { handleClick(OrderStepKey.Destination) }}
          onKeyDown={(e) => { handleKeyDown(e, OrderStepKey.Destination) }}
        >
          <DestinationCard
            order={order}
            onChange={handleChange}
            destinationType={destinationType}
            onDestinationTypeChange={setDestinationType}
            onAddressAutocomplete={handleAutocomplete}
            addressChoices={addressChoices}
            onResetAddressChoices={resetAddressChoices}
            onConfirmAddressChoice={handleConfirmAddressChoice}
            resetDestinationCardData={resetDestinationCardData}
            config={config}
            isCartDropoff={isCartDropoff}
          />
        </div>
        <div
          id={OrderStepKey.Delivery}
          ref={deliveryInfoCardRef}
          tabIndex={0}
          role="button"
          onClick={() => { handleClick(OrderStepKey.Delivery) }}
          onKeyDown={(e) => { handleKeyDown(e, OrderStepKey.Delivery) }}
        >
          <DeliveryInfoCard
            order={order}
            onChange={handleChange}
            isCartDropoff={isCartDropoff}
            config={config}
            orderTimeSlots={orderTimeSlots}
            selectedTimeSlot={selectedTimeSlot}
            setSelectedTimeSlot={setSelectedTimeSlot}
            isBookingEnabled={isBookingEnabled}
            openOrderBookingScreen={openOrderBookingScreen}
          />
        </div>
        <div
          id={OrderStepKey.Order}
          tabIndex={0}
          role="button"
          onClick={() => { handleClick(OrderStepKey.Order) }}
          onKeyDown={(e) => { handleKeyDown(e, OrderStepKey.Order) }}
        >
          <OrderCard
            order={order}
            onChange={handleChange}
            isOrderNumberValid={isOrderNumberValid}
            isCartDropoff={isCartDropoff}
            config={config}
          />
        </div>
      </div>
      <OrderFormAction
        isCartDropoff={isCartDropoff}
        isSaveDisabled={!isOrderNumberValid}
        handleSaveAndPrintClick={handleSaveAndPrintClick}
        handleSaveClick={handleSaveClick}
        onSaveDraftClick={handleSaveDraftClick}
      />
    </>
  )
}

export default OrderForm
