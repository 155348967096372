import { IAddress } from 'interfaces/Itours'
import React, { useContext, useEffect, useState } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { Fab } from '@material-ui/core'
import Modal from '@material-ui/core/Modal'
import SaveIcon from '@material-ui/icons/Save'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import { LatLng } from 'leaflet'
import { isIError } from 'api/types'

import { OrdersContext } from 'store/OrdersContext'
import { FeedbackContext } from 'store/FeedbackContext'
import MapWithAddressForm from 'components/Map/MapWithAddressForm'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog'
import { OrderPlanStatus } from 'constants/constants'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      display: 'flex',
      width: '60%',
      height: '70%',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #000',
      boxShadow: theme.shadows[5],
      outline: 'none',
      top: '50%',
      left: '50%',
      transform: `translate(-50%, -50%)`,
    },
    fab: {
      position: 'absolute',
      bottom: '5%',
      right: '5%',
      zIndex: 1000,
    },
    errorMessage: {
      position: 'absolute',
      bottom: '5%',
      left: '5%',
      zIndex: 1000,
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
  }),
)

interface IMapModal {
  visible: boolean
  onClose: () => void
  deliveryAddresLabel?: string
  currentLocation: LatLng
  orderId: string | null
  fullAddress: string
  isCoordinateReadOnly?: boolean
  warehouseId: string | undefined
  mapName: string
}

function MapModal({
  visible,
  onClose,
  currentLocation,
  orderId,
  fullAddress,
  deliveryAddresLabel,
  isCoordinateReadOnly = false,
  warehouseId,
  mapName,
}: IMapModal): JSX.Element {
  const { t } = useTranslation()
  const { patchOrderPosition } = useContext(OrdersContext)
  const classes = useStyles()
  const [markerLocation, setMarkerLocation] = useState<Partial<LatLng>>(currentLocation)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLocationChanged, setIsLocationChanged] = useState<boolean>(false)
  const [shouldCacheAddressLabelModalOpen, setShouldCacheAddressLabelModalOpen] = useState<boolean>(false)
  const [orderNotUpdatedMessageModal, setOrderNotUpdatedMessageModal] = useState<
    string | undefined
  >()
  const [address, setAddress] = useState<IAddress | undefined>(undefined)
  const { openErrorSnack } = useContext(FeedbackContext)

  const onAddressChange = (newAddress: IAddress): void => {
    if (newAddress.latitude && newAddress.longitude) {
      setIsLocationChanged(true)
    }
    setMarkerLocation({ lat: newAddress.latitude, lng: newAddress.longitude })
    setAddress(newAddress)
  }

  useEffect(() => {
    setMarkerLocation(currentLocation)
  }, [currentLocation])

  async function onClickSave(): Promise<void> {
    setIsLoading(true)
    if (address?.label) {
      setShouldCacheAddressLabelModalOpen(true)
    } else {
      onConfirmPatchOption(false)
    }
  }

  async function onConfirmPatchOption(shouldCacheAddressLabel: boolean): Promise<void> {
    setShouldCacheAddressLabelModalOpen(false)
    if (orderId) {
      const res = await patchOrderPosition(
        orderId,
        isLocationChanged
          ? {
            ...address,
            label: deliveryAddresLabel,
            full: address?.label,
            relevance: 1,
          }
          : {
            latitude: currentLocation.lat,
            longitude: currentLocation.lng,
            relevance: 1,
          },
        shouldCacheAddressLabel,
      )
      if (!isIError(res)) {
        if (res?.planStatus === OrderPlanStatus.Interfaced) {
          setOrderNotUpdatedMessageModal(t('OrdersScreen.interfacedOrderNotUpdated'))
        }
        if (res?.planStatus === OrderPlanStatus.Canceled) {
          setOrderNotUpdatedMessageModal(t('OrdersScreen.canceledOrderNotUpdated'))
        }
        handleClose()
      } else {
        openErrorSnack(res.error.message)
      }
      setIsLoading(false)
    }
  }

  const handleClose = (): void => {
    setAddress(undefined)
    setIsLocationChanged(false)
    onClose()
  }

  return (
    <>
      <Modal
        open={visible}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableAutoFocus
        data-cy="mapModal"
      >
        <div className={classes.paper}>
          <Fab
            data-cy="saveLocationButton"
            variant="extended"
            className={classes.fab}
            onClick={onClickSave}
            disabled={isLoading || !address}
          >
            {isLoading ? (
              <CircularProgress className={classes.extendedIcon} size={25} />
            ) : (
              <SaveIcon className={classes.extendedIcon} />
            )}
            {isLocationChanged
              ? i18n.t('OrdersScreen.saveNewLocation')
              : i18n.t('OrdersScreen.saveCurrentLocation')}
          </Fab>
          <MapWithAddressForm
            mapName={mapName}
            center={markerLocation}
            isCreateMode={false}
            onAddressChange={onAddressChange}
            fullAddress={fullAddress}
            isCoordinateReadOnly={isCoordinateReadOnly}
            warehouseId={warehouseId}
          />
        </div>
      </Modal>
      <ConfirmationDialog
        open={shouldCacheAddressLabelModalOpen}
        onClose={onConfirmPatchOption}
        message={t('OrdersScreen.shouldReplaceInitialAddress')}
        confirmMessage={t('OrdersScreen.yes')}
        denyMessage={t('OrdersScreen.no')}
      />
      <ConfirmationDialog
        open={!!orderNotUpdatedMessageModal}
        onClose={() => {
          setOrderNotUpdatedMessageModal(undefined)
        }}
        message={orderNotUpdatedMessageModal as string}
        showCancelOption={false}
      />
    </>
  )
}

export default MapModal
