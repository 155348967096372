export const ROUTES_PATH = {
  ordersList: '/order/list',
  orderDetails: '/order/details/',
  orderDetailsByOrderNumber: '/order/details/orderNumber',
  createOrder: '/order/create',
  createOrderFromMenu: '/order/create/from-menu',
  cartDropoff: '/order/cart-dropoff',
  reloadCartDropoff: '/order/cart-dropoff/reload',
  disputesList: '/disputes/list',
  driversList: '/drivers/list',
  createDriver: '/drivers/create',
  driverDetails: '/drivers/details/',
  editDriver: '/drivers/edit/',
  vehiclesList: '/vehicles/list',
  createVehicle: '/vehicles/create',
  vehicleDetails: '/vehicles/details/',
  editVehicle: '/vehicles/edit/',
  vehicleReports: '/vehicles/reports/',
  sitesList: '/sites/list',
  siteDetails: '/sites/details/',
  editSite: '/sites/edit/',
  createSite: '/sites/create',
  carriersList: '/carriers/list',
  carrierDetails: '/carriers/details/',
  editCarrier: '/carriers/edit/',
  createCarrier: '/carriers/create',
  planning: '/planning',
  multisitePlanning: '/multisite',
  planningList: '/planning/list',
  reporting: '/reporting',
  reportingOrders: '/reporting/orders',
  reportingExecution: '/reporting/execution',
  reportingPlanning: '/reporting/planning',
  reportingSites: '/reporting/sites',
  reportingSlots: '/reporting/slots',
  reportingCustomerRating: '/reporting/customer-rating',
  reportingCustomerComments: '/reporting/customer-comments',
  usersList: '/users/list',
  editUser: '/users/edit/',
  userDetails: '/users/details/',
  createUser: '/users/create',
  localLogin: '/login',
  login: process.env.REACT_APP_KLAREO_HOME_URL || '/login',
  forbidden: '/403',
  markersGeneration: '/markers-generation',
  home: '/',
  vehicleTypesList: '/vehicle-types/list',
  vehicleTypeDetails: '/vehicle-types/details/',
  createVehicleType: '/vehicle-types/create',
  editVehicleType: '/vehicle-types/edit/',
  tenantsList: '/tenants/list',
  createTenant: '/tenants/create',
  editTenant: '/tenants/edit/',
  tenantDetails: '/tenants/details/',
  trailersList: '/trailers/list',
  createTrailer: '/trailers/create',
  editTrailer: '/trailers/edit/',
  trailerDetails: '/trailers/details/',
  executionAffectation: '/execution/affectation',
  executionCarrierAffectation: '/execution/carrier/affectation',
  executionTours: '/execution/tours',
  executionMap: '/execution/map',
  executionReplay: '/execution/replay',
  manageFeature: '/manage-feature',
  planTemplateList: '/plan-templates/list',
  createPlanTemplate: '/plan-templates/create',
  editPlanTemplate: '/plan-templates/edit/',
  planTemplateDetails: '/plan-templates/details/',
  configurations: '/settings/configurations',
  siteConfigurations: '/settings/site-configurations',
  planningOrders: '/planning/planning-orders',
  geocodingOrders: '/planning/geocoding-orders',
  webhooksList: '/webhooks/list',
  createWebhook: '/webhooks/create',
  editWebhook: '/webhooks/edit/',
  routingRulesList: '/routing-rules/list',
  createRoutingRules: '/routing-rules/create',
  detailRoutingRules: '/routing-rules/details/',
  editRoutingRules: '/routing-rules/edit/',
  importHistory: '/order/import-history',
  sectorsList: '/sectors/list',
  sectorDetails: '/sectors/details/',
  warehouseAdminList: '/warehouse-admin/list',
  warehouseAdminDetails: '/warehouse-admin/details/',
  editWarehouseAdmin: '/warehouse-admin/edit/',
  notifierConfigList: '/notifier-config/list',
  notifierConfigDetails: '/notifier-config/details/',
  createNotifierConfig: '/notifier-config/create',
  editNotifierConfig: '/notifier-config/edit/',
  warehouseTimeSlotList: '/warehouse-time-slot/list',
  warehouseTimeSlotDetails: '/warehouse-time-slot/details/',
  createWarehouseTimeSlot: '/warehouse-time-slot/create',
  editWarehouseTimeSlot: '/warehouse-time-slot/edit/',
  planningOrdersList: '/planning/orders',
  planningOrderDetails: '/planning-orders/details/',
  reportingCosts: '/cost/reporting',
  costConfigList: '/cost/list',
  costConfigDetails: '/cost/details/',
  editCostConfig: '/cost/edit/',
  createCostConfig: '/cost/create',
  alerts: '/alerts',
  apiKeysList: '/apikeys/list/',
  editApiKey: '/apikeys/edit/',
  createApiKey: '/apikeys/create',
}
