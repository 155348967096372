import React, { useEffect, useContext } from 'react'
import 'moment/locale/fr'
import Table from 'components/Table'
import { COLUMNS_MODEL, EXECUTION_STOP_DETAILS_COLUMNS_MODEL } from 'constants/table'
import { ITour } from 'interfaces/Itours'
import { ToursConsumer } from 'store/ToursContext'
import { AVAILABLE_FILTERS } from 'constants/constants'
import { FilterKey } from 'constants/filters'
import { AuthContext } from 'store/AuthContext'
import { TagsContext } from 'store/TagsContext'

interface IExecutionProps {
  tours: ITour[]
}

const ExecutionScreen = ({ tours }: IExecutionProps): JSX.Element => {
  const { user } = useContext(AuthContext)
  const { tags, getTags } = useContext(TagsContext)

  useEffect(() => {
    if (!tags && user) getTags(user.tenantId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Table
      showExtendedDateFilter
      extendedDateFilter={{
        today: true,
        tomorrow: true,
        thisWeek: true,
        next7days: true,
      }}
      columns={COLUMNS_MODEL}
      data={tours}
      hideFilters={[AVAILABLE_FILTERS.DELIVERY_TYPES]}
      name="execution"
      filterKey={FilterKey.execution}
      detailsPanelColumnsToFilter={EXECUTION_STOP_DETAILS_COLUMNS_MODEL}
    />
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <ToursConsumer>
    {(ctx): JSX.Element => <ExecutionScreen tours={ctx.tours} {...props} />}
  </ToursConsumer>
)
