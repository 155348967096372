import React, { useContext, useState, useEffect } from 'react'
import { Button, Menu, MenuItem } from '@material-ui/core/'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles'

import PlansApi from 'api/plans'
import { isIError } from 'api/types'
import { FeedbackContext } from 'store/FeedbackContext'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog'
import { AppInsightEvents, PLAN_TOUR_STATUS_ENUM as PlanTourStatus } from 'constants/constants'
import appInsight from 'services/appInsight'
import { addDays } from 'utils/dateFormat'
import { IPlanTemplate } from 'interfaces/IPlanTemplate'
import { ContentContext } from 'store/ContentContext'
import { DefaultSiteContext } from 'store/DefaultSiteContext'
import { IPlan, IPlanTourDeleteResponseItem } from 'interfaces/IPlan'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import { PlanificationContext } from '../../PlanningStore'
import DeletePlanModal from '../DeletePlanModal'
import DeletePlanWarningModal from '../DeletePlanWarningModal'
import NestedMenuItem from './NestedMenuItem'

interface IPlanMenu {
  toggleCreationModal(isSpecific: boolean): void
  toggleFiltersModal(): void
}

function PlanMenu({ toggleCreationModal, toggleFiltersModal }: IPlanMenu): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const {
    selectedPlan,
    setSelectedPlan,
    validateAllTours,
    invalidateAllTours,
    tours,
    interfaceAllTours,
    isPlanValidateDisabled,
    isPlanInvalidateDisabled,
    isPlanInterfaceDisabled,
    isMapotempoActionInProgress,
    deletePlanTours,
    isPlanTourDeleteDisabled,
    isPlanTourStatusChangeDone,
    setIsPlanTourStatusChangeDone,
    isMultisite,
  } = useContext(PlanificationContext)
  const [planIdToDelete, setPlanIdToDelete] = useState<string>()
  const [isDeletionWarningModalOpen, setIsDeletionWarningModalOpen] = useState<boolean>(false)
  const [isInterfaceConfirmationModalOpen, setIsInterfaceConfirmationModalOpen] = useState<boolean>(false)
  const [isDeletePlanToursConfirmationModalOpen, setIsDeletePlanToursConfirmationModalOpen] = useState<boolean>(false)
  const [isInvalidateConfirmationOpen, setIsInvalidateConfirmationOpen] = useState<boolean>(false)
  const [shouldShowDeletePlanToursButton, setShouldShowDeletePlanToursButton] = useState<boolean>(false)
  const [availablePlanTemplates, setAvailablePlanTemplates] = useState<IPlanTemplate[]>([])
  const [isDeleteTourFinishModalOpen, setIsDeleteTourFinishModalOpen] = useState<boolean>(false)
  const [deleteTourFinishMessage, setDeleteTourFinishMessage] = useState<string>('')
  const { openErrorSnack, toggleLoader } = useContext(FeedbackContext)
  const { planTemplates } = useContext(ContentContext)
  const { defaultSiteId } = useContext(DefaultSiteContext)
  const { t } = useTranslation()
  const history = useHistory()
  const theme = useTheme()

  const getPlanTours = async (): Promise<void> => {
    toggleLoader(true)
    const res = await PlansApi.getPlanTours({
      planId: selectedPlan?.id,
      status: [
        PlanTourStatus.PLANNED,
        PlanTourStatus.VALIDATED,
        PlanTourStatus.LOCKED,
        PlanTourStatus.INTERFACED,
      ].map(String),
    })
    toggleLoader(false)
    if (isIError(res)) {
      openErrorSnack(res.error.message)
      setSelectedPlan(null)
    } else {
      setShouldShowDeletePlanToursButton(
        res.some((planTour) => planTour.status !== PlanTourStatus.INTERFACED),
      )
    }
    setIsPlanTourStatusChangeDone(false)
  }

  useEffect(() => {
    if (isPlanTourStatusChangeDone && selectedPlan) {
      getPlanTours()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlanTourStatusChangeDone, selectedPlan])

  useEffect(() => {
    setAvailablePlanTemplates(
      planTemplates.filter((planTemplate) => planTemplate.warehouseId === defaultSiteId),
    )
  }, [defaultSiteId, planTemplates])

  useEffect(() => {
    const path = isMultisite ? ROUTES_PATH.multisitePlanning : ROUTES_PATH.planning
    history.push(`${path}?id=${selectedPlan?.id}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlan])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => setAnchorEl(null)

  const _toggleCreationModal = (isSpecific: boolean): void => {
    toggleCreationModal(isSpecific)
    handleClose()
  }

  function _toggleFiltersModal(): void {
    toggleFiltersModal()
    handleClose()
  }

  const shouldDisplayDeletePlanConfirmModal = async (): Promise<boolean> => {
    if (selectedPlan) {
      if (!moment(selectedPlan.endDate).isBefore(moment().subtract(1, 'days'))) {
        toggleLoader(true)
        const res = await PlansApi.getPlanTours({
          planId: selectedPlan.id,
          status: ['3'],
        })
        toggleLoader(false)
        if (isIError(res)) {
          openErrorSnack(res.error.message)
          setSelectedPlan(null)
        } else {
          return !res.length
        }
      }
      return true
    }
    return false
  }

  const handlePlanDelete = async (): Promise<void> => {
    const showDeleteConfirmModal = await shouldDisplayDeletePlanConfirmModal()
    if (showDeleteConfirmModal) {
      setPlanIdToDelete(selectedPlan?.id)
    } else {
      setIsDeletionWarningModalOpen(true)
    }
    handleClose()
  }

  function onPlanDelete(): void {
    appInsight.trackEvent({ name: AppInsightEvents.DeletePlan })
    setSelectedPlan(null)
  }

  function validatePlan(): void {
    handleClose()
    validateAllTours()
  }

  function invalidatePlan(): void {
    handleClose()
    setIsInvalidateConfirmationOpen(true)
  }

  function interfacePlan(): void {
    setIsInterfaceConfirmationModalOpen(true)
    handleClose()
  }

  const createPlan = async (planTemplate: IPlanTemplate, date: Date): Promise<void> => {
    if (defaultSiteId) {
      const begin = new Date(planTemplate.begin)
      const end = new Date(planTemplate.end)
      const planLabel = `${planTemplate.label} ${new Date(date).toLocaleDateString('fr')}`
      const baseDate = moment(date).seconds(0).milliseconds(0)
      const startDate = baseDate.hours(begin.getHours()).minutes(begin.getMinutes()).toISOString()
      const endDate = baseDate
        .clone()
        .add(planTemplate.dateOffset, 'days')
        .hours(end.getHours())
        .minutes(end.getMinutes())
        .toISOString()
      let departureMinToSet
      if (planTemplate.vehicleDepartureMin) {
        const departureMin = new Date(planTemplate.vehicleDepartureMin)
        departureMinToSet = baseDate
          .clone()
          .hours(departureMin.getHours())
          .minutes(departureMin.getMinutes())
          .toISOString()
      } else {
        departureMinToSet = startDate
      }
      let returnMaxToSet
      if (planTemplate.vehicleReturnMax) {
        const returnMax = new Date(planTemplate.vehicleReturnMax)
        returnMaxToSet = baseDate
          .clone()
          .add(planTemplate.dateOffset, 'days')
          .hours(returnMax.getHours())
          .minutes(returnMax.getMinutes())
          .toISOString()
      } else {
        returnMaxToSet = endDate
      }
      let ordersBeginToSet
      if (planTemplate.ordersBegin) {
        const ordersBegin = new Date(planTemplate.ordersBegin)
        ordersBeginToSet = baseDate
          .clone()
          .hours(ordersBegin.getHours())
          .minutes(ordersBegin.getMinutes())
          .toISOString()
      } else {
        ordersBeginToSet = startDate
      }
      let ordersEndToSet
      if (planTemplate.ordersEnd) {
        const ordersEnd = new Date(planTemplate.ordersEnd)
        ordersEndToSet = baseDate
          .clone()
          .add(planTemplate.dateOffset, 'days')
          .hours(ordersEnd.getHours())
          .minutes(ordersEnd.getMinutes())
          .toISOString()
      } else {
        ordersEndToSet = endDate
      }

      const res = await PlansApi.savePlan({
        label: planLabel,
        warehouseId: defaultSiteId,
        secondaryWarehouseIds: planTemplate.secondaryWarehouseIds || [],
        begin: startDate,
        end: endDate,
        deliveryTypes: planTemplate.deliveryTypes,
        transportTypes: planTemplate.transportTypes,
        carrierIds: planTemplate.carrierIds,
        vehicleTypesIds: planTemplate.vehicleTypesIds,
        vehicleDepartureMin: departureMinToSet,
        vehicleReturnMax: returnMaxToSet,
        ordersBegin: ordersBeginToSet,
        ordersEnd: ordersEndToSet,
      })
      if (!isIError(res)) {
        setSelectedPlan(res)
      } else {
        openErrorSnack(res.error.message)
      }
    }
  }

  const getPlan = (plan: IPlan): void => {
    if (selectedPlan?.id === plan.id) {
      return
    }
    setSelectedPlan(plan)
    setIsPlanTourStatusChangeDone(true)
  }

  const handleSelectOrCreatePlan = async (
    planTemplate: IPlanTemplate,
    date: Date,
  ): Promise<void> => {
    const planLabel = `${planTemplate.label} ${new Date(date).toLocaleDateString('fr')}`
    toggleLoader(true)
    const res = await PlansApi.getPlans({
      search: planLabel,
      warehouseId: defaultSiteId || undefined,
    })
    if (!isIError(res)) {
      if (res.length === 0) {
        createPlan(planTemplate, date)
      } else {
        getPlan(res[0])
      }
    } else {
      openErrorSnack(res.error.message)
    }
    toggleLoader(false)
    handleClose()
  }

  const shouldDisplayValidateButton = () =>
    tours.filter((tour) => tour.status === PlanTourStatus.PLANNED).length > 0

  const shouldDisplayInterfaceButton = () =>
    tours.filter((tour) => tour.status === PlanTourStatus.VALIDATED).length > 0

  const shoudlDisplayInvalidateButton = () =>
    selectedPlan && tours.filter((tour) => tour.status === PlanTourStatus.VALIDATED).length > 0

  const handleInterfaceConfirmation = (validate: boolean): void => {
    setIsInterfaceConfirmationModalOpen(false)
    if (validate) {
      interfaceAllTours()
    }
  }

  const handleDeletePlanToursConfirmation = async (validate: boolean): Promise<void> => {
    setIsDeletePlanToursConfirmationModalOpen(false)
    if (validate) {
      appInsight.trackEvent({ name: AppInsightEvents.DeletePlanTours })
      const response = await deletePlanTours(validate)
      if (response?.notDeletePlanTours && response.notDeletePlanTours.length) {
        setNotDeletedModalOpen(response.notDeletePlanTours)
      }
    }
  }

  const setNotDeletedModalOpen = (notDeletePlanTours: IPlanTourDeleteResponseItem[]) => {
    setDeleteTourFinishMessage(
      t('PlanningScreen.notDeletedToursMessage', {
        stringifiedTrips: notDeletePlanTours.map((item) => item.planTourNumber).join(', '),
      }),
    )
    setIsDeleteTourFinishModalOpen(true)
  }

  const handlePlanToursDelete = (): void => {
    setIsDeletePlanToursConfirmationModalOpen(true)
    handleClose()
  }

  const handleInvalidateConfirmation = (validate: boolean): void => {
    setIsInvalidateConfirmationOpen(false)
    if (validate) {
      invalidateAllTours()
    }
  }

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        variant="contained"
        color="secondary"
        onClick={handleClick}
        style={{
          color: theme.color.colorPlan,
          backgroundColor: theme.color.backgroundColorPlan,
        }}
        data-cy="planButton"
      >
        {selectedPlan ? selectedPlan.label : t('PlanningScreen.newPlan')}
      </Button>
      <Menu
        data-cy="planButton-menu"
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          data-cy="item-option-create"
          disabled={isMapotempoActionInProgress}
          onClick={(): void => {
            _toggleCreationModal(false)
          }}
        >
          {t('PlanningScreen.createPlan')}
        </MenuItem>
        {/*
          // TODO modal is not yet up-to-date
          <MenuItem onClick={(): void => { _toggleCreationModal(true) }}>{t('PlanningScreen.createSpecificPlan')}</MenuItem>
        */}
        <MenuItem
          data-cy="item-option-select"
          disabled={isMapotempoActionInProgress}
          onClick={_toggleFiltersModal}
        >
          {t('PlanningScreen.selectPlan')}
        </MenuItem>
        {shouldDisplayValidateButton() && (
          <MenuItem
            data-cy="item-option-validate"
            disabled={isPlanValidateDisabled || isMapotempoActionInProgress}
            onClick={validatePlan}
          >
            {t('PlanningScreen.validatePlan')}
          </MenuItem>
        )}
        {shoudlDisplayInvalidateButton() && (
          <MenuItem
            data-cy="item-option-invalidate"
            disabled={isPlanInvalidateDisabled || isMapotempoActionInProgress}
            onClick={invalidatePlan}
          >
            {t('PlanningScreen.invalidateTours')}
          </MenuItem>
        )}
        {shouldDisplayInterfaceButton() && (
          <MenuItem
            data-cy="item-option-interface"
            disabled={isPlanValidateDisabled || isMapotempoActionInProgress}
            onClick={interfacePlan}
          >
            {t('PlanningScreen.interfacePlan')}
          </MenuItem>
        )}
        {selectedPlan && (
          <MenuItem
            data-cy="item-option-deletePlan"
            disabled={isMapotempoActionInProgress}
            onClick={handlePlanDelete}
          >
            {t('PlanningScreen.deletePlan')}
          </MenuItem>
        )}
        {shouldShowDeletePlanToursButton && selectedPlan && tours.length > 0 && (
          <MenuItem
            data-cy="item-option-deleteTours"
            disabled={isMapotempoActionInProgress || isPlanTourDeleteDisabled}
            onClick={handlePlanToursDelete}
          >
            {t('PlanningScreen.deleteTours')}
          </MenuItem>
        )}
        <hr />
        <NestedMenuItem
          disabled={isMapotempoActionInProgress || availablePlanTemplates.length === 0}
          date={new Date()}
          dataCy="shortcut-today"
          items={availablePlanTemplates}
          onClick={handleSelectOrCreatePlan}
        />
        <NestedMenuItem
          disabled={isMapotempoActionInProgress || availablePlanTemplates.length === 0}
          date={addDays(new Date(), 1)}
          dataCy="shortcut-tomorrow"
          items={availablePlanTemplates}
          onClick={handleSelectOrCreatePlan}
        />
      </Menu>
      <DeletePlanModal
        planIdToDelete={planIdToDelete}
        setPlanIdToDelete={setPlanIdToDelete}
        onEndDelete={onPlanDelete}
        setNotDeletedModalOpen={setNotDeletedModalOpen}
      />
      <DeletePlanWarningModal
        open={isDeletionWarningModalOpen}
        onCancel={() => setIsDeletionWarningModalOpen(false)}
      />
      {/* TODO: maybe use only one confirmation dialog later */}
      <ConfirmationDialog
        open={isInterfaceConfirmationModalOpen}
        onClose={handleInterfaceConfirmation}
        message={t('PlanningScreen.interfacePlanConfirmationMessage')}
        isConfirmDisabled={isPlanInterfaceDisabled}
      />
      <ConfirmationDialog
        open={isDeletePlanToursConfirmationModalOpen}
        onClose={handleDeletePlanToursConfirmation}
        message={t('PlanningScreen.planToursDeleteConfirmationMessage')}
      />
      <ConfirmationDialog
        open={isInvalidateConfirmationOpen}
        onClose={handleInvalidateConfirmation}
        message={t('PlanningScreen.invalidatePlanConfirmationMessage')}
      />
      {isDeleteTourFinishModalOpen && (
        <ConfirmationDialog
          open={isDeleteTourFinishModalOpen}
          onClose={() => {
            setDeleteTourFinishMessage('')
            setIsDeleteTourFinishModalOpen(false)
          }}
          message={deleteTourFinishMessage}
          showCancelOption={false}
        />
      )}
    </>
  )
}

export default PlanMenu
