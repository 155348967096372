import React, { useContext } from 'react'
import { Backdrop, CircularProgress, Paper, Typography } from '@material-ui/core'
import styled from 'styled-components'

import { PlanificationContext } from '../PlanningStore'
import LinearWithValueLabel from './LinearProgress'
import VisitsGenerationProgress from './VisitsGenerationProgress'

const StyledPaper = styled(Paper)`
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

interface IProps {
  maxOptimizationTime: number
}

function BackdropLoader({ maxOptimizationTime }: IProps): JSX.Element {
  const { isActionLoading, backdropMessage, isOptimizingByPilot, visitsGenerationProgress } = useContext(PlanificationContext)

  return (
    <Backdrop
      open={isActionLoading || backdropMessage.length > 0}
      style={{ zIndex: 600 }}
      data-cy="loader"
    >
      <StyledPaper data-cy="loaderContainer" elevation={3} color="warning.main">
        {backdropMessage.length > 0 && (
          <Typography variant="h5" component="h2" style={{ marginBottom: 16 }}>
            {backdropMessage}
          </Typography>
        )}
        <CircularProgress />
        {visitsGenerationProgress && (
          <VisitsGenerationProgress progress={visitsGenerationProgress} />
        )}
        {isActionLoading && isOptimizingByPilot && (
          <LinearWithValueLabel averageTimeInSeconds={maxOptimizationTime} />
        )}
      </StyledPaper>
    </Backdrop>
  )
}

export default BackdropLoader
