import React from 'react'
import { COLUMNS_MODEL_DRIVERS_LIST } from 'constants/table'
import { IDriver } from 'interfaces/IDriver'
import { IError } from 'api/types'
import DriversProvider, { DriversConsumer } from 'store/DriversContext'
import DriversTable from 'components/Table/DriversTable'

interface IDriversProps {
  drivers: IDriver[]
  error?: IError
}

const DriversScreen = ({ drivers }: IDriversProps): JSX.Element => (
  <DriversTable drivers={drivers} columns={COLUMNS_MODEL_DRIVERS_LIST} />
)

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <DriversProvider>
    <DriversConsumer>
      {(ctx): JSX.Element => <DriversScreen drivers={ctx.drivers} {...props} />}
    </DriversConsumer>
  </DriversProvider>
)
