import React from 'react'
import {
  Chart,
  Title,
  ValueAxis,
  ArgumentAxis,
  BarSeries,
  Tooltip,
} from '@devexpress/dx-react-chart-material-ui'
import { Stack, Animation, EventTracker, Palette } from '@devexpress/dx-react-chart'
import { connectProps } from '@devexpress/dx-react-core'

import { IDailyStats } from 'interfaces/IDailyStats'
import { TOUR_STATUS_MAP, TOUR_STATUS_CHART_COLORS } from 'constants/constants'
import Export from 'components/Chart/Export'
import VerticalLabel from 'screens/ReportingCategory/components/VerticalLabel'
import StyledPaper from 'screens/ReportingCategory/components/StyledPaper'

interface IProps {
  data: IDailyStats[]
  title: string
  statusIds: string[]
}

interface IDailyTotal {
  day: string
  sum: number
}

const containerId = 'daily-tour-status'

interface IBarWithTotalProps {
  dailyTotals: IDailyTotal[]
}

const BarWithTotal = ({
  arg,
  val,
  value,
  dailyTotals,
  ...props
}: BarSeries.PointProps & IBarWithTotalProps): JSX.Element => {
  const shouldShowTotal = value > 0 && value === dailyTotals.find((dailyTotal) => props.argument === dailyTotal.day)?.sum

  return (
    <>
      <BarSeries.Point arg={arg} val={val} value={value} {...props} />
      {shouldShowTotal && (
        <Chart.Label x={arg} y={val - 10} dominantBaseline="middle" textAnchor="middle">
          {value}
        </Chart.Label>
      )}
    </>
  )
}

export const DailyTourStatusChart = ({ data, title, statusIds }: IProps): JSX.Element => {
  const getStatusLabel = (statusId: number): string => TOUR_STATUS_MAP.get(statusId) as string

  const EnhancedBarWithTotal = connectProps(BarWithTotal, () => ({
    dailyTotals: data.map((dailyTour) => {
      const { day, ...tours } = dailyTour
      return {
        day,
        sum: Object.values(tours).reduce((a, b) => Number(a) + Number(b)),
      }
    }),
  }))

  return (
    <StyledPaper id={containerId}>
      <Chart data-cy="dailyTourStatusChart" data={data}>
        <Palette scheme={TOUR_STATUS_CHART_COLORS} />
        <ArgumentAxis labelComponent={VerticalLabel} showTicks={false} />
        <ValueAxis />

        {statusIds.map((statusId) => (
          <BarSeries
            key={statusId}
            name={`${getStatusLabel(+statusId)}`}
            valueField={statusId}
            argumentField="day"
            pointComponent={EnhancedBarWithTotal}
          />
        ))}

        <Animation />
        <Title text={title} />
        <Stack stacks={[{ series: [...statusIds.map((statusId) => getStatusLabel(+statusId))] }]} />
        <EventTracker />
        <Tooltip />
        {/* TODO: use another fileName */}
        <Export
          dataCy="exportDailyTourStatusButton"
          containerId={containerId}
          fileName={containerId}
        />
      </Chart>
    </StyledPaper>
  )
}

export default DailyTourStatusChart
