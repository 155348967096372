import React, { useMemo } from 'react'
import { Marker } from 'react-leaflet'
import L, { LatLngLiteral } from 'leaflet'
import ReactDOMServer from 'react-dom/server'

import PinIcon from 'components/Map/PinIcon'
import WarehouseIcon from 'assets/icons/warehouse'

interface IWarehouseMarker {
  position: LatLngLiteral
  color: string
}

const ICON_WIDTH = 40
const ICON_HEIGHT = 40

const Icon = ({ color }: { color: string }): JSX.Element => (
  <PinIcon pinColor={color} circleSize={1.1}>
    <WarehouseIcon color={color} />
  </PinIcon>
)

const WarehouseMarker = ({ position, color }: IWarehouseMarker): JSX.Element => {
  const generateIcon = useMemo(
    () =>
      L.divIcon({
        className: 'custom icon',
        html: ReactDOMServer.renderToString(<Icon color={color} />),
        iconAnchor: [ICON_WIDTH / 2, ICON_HEIGHT],
        popupAnchor: [0, -ICON_HEIGHT],
      }),
    [color],
  )

  return (
    <>
      {position && position.lat && position.lng && (
        <Marker position={position} icon={generateIcon} interactive={false} />
      )}
    </>
  )
}

export default WarehouseMarker
