import React, { ReactNode } from 'react'
import { Card, CardContent, CardHeader, Grid, IconButton, SvgIconProps } from '@material-ui/core'
import clsx from 'clsx'

import CardLine, { CardLineMetaData, CardLineSaveObject } from 'components/CardLine/CardLine'
import { ISearchableSelectOption } from 'interfaces/interfaces'
import { IAutocompleteResult } from 'interfaces/IAutocompleteResult'
import useStyles from './styles'

type CustomCardProps = {
  cardTitle: string
  icon?: React.ReactElement<SvgIconProps>
  linePairs?: Array<{
    label: string
    value?: string | JSX.Element
    hidden?: boolean
    isEditable?: boolean
    type?: string
    isEditDisabled?: boolean
    disabledMessage?: string
    onSave?: (value?: string) => Promise<void>
    metaData?: CardLineMetaData
    onSaveObject?: (data: CardLineSaveObject) => void
    dateTimeStartLabel?: string
    dateTimeEndLabel?: string
    dateTimeMinErrorMessage?: string
    dateTimeMaxErrorMessage?: string
    checked?: boolean
    isAbsoluteNumber?: boolean
    onSaveSwitch?: (checked: boolean) => Promise<void>
    isMultiSelect?: boolean
    selectValue?: string
    selectValues?: string[]
    selectOptions?: ISearchableSelectOption[]
    onSelectChange?: (
      e: React.ChangeEvent<{
        value: unknown
        name?: string | undefined
      }>,
    ) => void
    autoCompleteChoices?: IAutocompleteResult[]
    autoCompleteChoiceClick?: (value: IAutocompleteResult) => void
    onChange?: (e: string) => void
    autocompleteValue?: string
  }>
  children?: ReactNode
  isMiniPadding?: boolean
  dataCy?: string
  contentClassName?: string
  onIconClick?: () => void
}

export default ({
  cardTitle,
  icon,
  linePairs,
  children,
  isMiniPadding = false,
  dataCy,
  contentClassName,
  onIconClick,
}: CustomCardProps): JSX.Element => {
  const classes = useStyles()
  return (
    <Card className={classes.root} data-cy={dataCy}>
      <CardHeader
        title={(
          <div className={classes.spaceBetween}>
            {cardTitle.toUpperCase()}
            {icon && (
              <IconButton className={classes.cardIcon} onClick={onIconClick}>
                {icon}
              </IconButton>
            )}
          </div>
        )}
        className={classes.header}
        titleTypographyProps={{
          variant: 'subtitle1',
          style: {
            fontWeight: 'bolder',
          },
        }}
      />
      <CardContent className={clsx(classes.content, contentClassName)}>
        <Grid container>
          {linePairs
            && linePairs.map(
              ({
                label,
                value,
                hidden,
                isEditable,
                type,
                isEditDisabled,
                disabledMessage,
                onSave,
                metaData,
                onSaveObject,
                dateTimeStartLabel,
                dateTimeEndLabel,
                dateTimeMinErrorMessage,
                dateTimeMaxErrorMessage,
                checked,
                isAbsoluteNumber,
                onSaveSwitch,
                selectOptions,
                selectValue,
                selectValues,
                onSelectChange,
                isMultiSelect,
                autoCompleteChoices,
                autoCompleteChoiceClick,
                onChange,
                autocompleteValue,
              }) =>
                !hidden && (
                  <CardLine
                    key={label}
                    label={label}
                    value={value}
                    isMiniPadding={isMiniPadding}
                    isEditable={isEditable}
                    type={type}
                    isEditDisabled={isEditDisabled}
                    disabledMessage={disabledMessage}
                    onSave={onSave}
                    metaData={metaData}
                    onSaveObject={onSaveObject}
                    dateTimeStartLabel={dateTimeStartLabel}
                    dateTimeEndLabel={dateTimeEndLabel}
                    dateTimeMinErrorMessage={dateTimeMinErrorMessage}
                    dateTimeMaxErrorMessage={dateTimeMaxErrorMessage}
                    checked={checked}
                    isAbsoluteNumber={isAbsoluteNumber}
                    onSaveSwitch={onSaveSwitch}
                    selectOptions={selectOptions}
                    selectValue={selectValue}
                    selectValues={selectValues}
                    onSelectChange={onSelectChange}
                    isMultiSelect={isMultiSelect}
                    autoCompleteChoices={autoCompleteChoices}
                    autoCompleteChoiceClick={autoCompleteChoiceClick}
                    onChange={onChange}
                    autocompleteValue={autocompleteValue}
                  />
                ),
            )}
          {children}
        </Grid>
      </CardContent>
    </Card>
  )
}
