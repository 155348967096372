import { Button } from '@material-ui/core'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import CustomCard from 'components/CustomCard/CustomCard'
import { ISector } from 'interfaces/ISector'
import DisplaySector from './DisplaySector'
import useStyles from './styles'

interface IProps {
  sectors: ISector[]
}

const SectorColor = styled.div`
  width: 100%;
  height: 6px;
  background-color: ${(props) => props.color};
`

const SectorsList = ({ sectors }: IProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [currentSectorId, setcurrentSectorId] = useState<string | null>(null)

  useEffect(() => {
    if (sectors && sectors.length > 0) {
      setcurrentSectorId(sectors[0].id)
    }
  }, [sectors])

  const showSector = (sector: ISector) => {
    setcurrentSectorId(sector.id)
  }

  const currentSector = currentSectorId && sectors ? sectors.find((s) => s.id === currentSectorId) || null : null

  return (
    <CustomCard cardTitle={t('SectorsScreen.detail')} dataCy="detailsCard">
      {sectors && sectors.length > 0 ? (
        <div className={classes.flex}>
          <div className={classes.border}>
            <div className={classes.list} data-cy="detail-button-container">
              {sectors.map((sector) => {
                const isActive = !!currentSectorId && currentSectorId === sector.id
                return (
                  <Button
                    key={sector.id}
                    className={clsx(classes.listItem, isActive && classes.selectedListItem)}
                    onClick={() => showSector(sector)}
                    data-cy={`${sector.color?.toLowerCase()}Button`}
                  >
                    <SectorColor color={sector.color} />
                  </Button>
                )
              })}
            </div>
          </div>
          <div className={classes.flexGrow}>
            {currentSector && <DisplaySector key={currentSector.id} sector={currentSector} />}
          </div>
        </div>
      ) : (
        <div className={classes.flexGrow}>
          <div className={classes.noSectors}>{t('SectorsScreen.noSectors')}</div>
        </div>
      )}
    </CustomCard>
  )
}

export default SectorsList
