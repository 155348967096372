import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import moment from 'moment'
import { useDebouncedCallback } from 'use-debounce'

import AddressApi from 'api/address'
import OrdersApi from 'api/orders'
import { isIError } from 'api/types'
import { DestinationType, TransportType } from 'constants/constants'
import { IAddressCoordinates, IOrderForm } from 'interfaces/IOrders'
import { ISelectedTimeSlot } from 'interfaces/IWarehouseTimeSlot'
import OrderBookingScreen from 'screens/OrderCategory/Booking/OrderBookingScreen'
import { ContentContext } from 'store/ContentContext'
import { DefaultSiteContext } from 'store/DefaultSiteContext'
import { FeedbackContext } from 'store/FeedbackContext'
import { DefaultDeliveryTypeCode } from 'interfaces/IDeliveryType'
import { AuthContext } from 'store/AuthContext'
import { IWarehouse } from 'interfaces/interfaces'
import { convertToMinutes } from 'utils/libs/time-conversion'
import OrderForm from './OrderForm'

const OrdersFormScreen = (): JSX.Element => {
  const { user } = useContext(AuthContext)
  const { defaultSiteId } = useContext(DefaultSiteContext)
  const { sites } = useContext(ContentContext)
  const { openErrorSnack, toggleLoader } = useContext(FeedbackContext)
  const orderEstimatedServiceTime = useMemo((): number | undefined => {
    const visitDuration = sites.find((item) => item.id === defaultSiteId)?.optimParams
      ?.visitDuration

    return convertToMinutes(visitDuration)
  }, [sites, defaultSiteId])
  const [destinationType, setDestinationType] = useState<DestinationType>(DestinationType.Address)
  const [order, setOrder] = useState<Partial<IOrderForm>>({
    deliveryType: DefaultDeliveryTypeCode.CustomerSite,
    warehouseId: defaultSiteId || '',
    transportType: TransportType.Delivery,
    estimatedServiceTime: orderEstimatedServiceTime,
    deliveryAddressCountry: sites.find((item) => item.id === defaultSiteId)?.address.country,
    mainQuantity: 1,
  })
  const [isOrderNumberValid, setIsOrderNumberValid] = useState<boolean>(true)
  const [isOrderBookingScreenOpen, setIsOrderBookingScreenOpen] = useState<boolean>(false)
  const [coordinates, setCoordinates] = useState<IAddressCoordinates>()
  const [shouldScrollToDeliveryInfoCard, setShouldScrollToDeliveryInfoCard] = useState<boolean>(false)
  const deliveryInfoCardRef = useRef<HTMLDivElement>(null)

  const handleDestinationTypeDefaultValue = (orderSite: IWarehouse) => {
    if (orderSite?.orderScreen?.defaultDestinationType) {
      const defaultDestinationValue = orderSite.orderScreen.defaultDestinationType
      setDestinationType(defaultDestinationValue)
    }
  }

  const handleDeliveryTypeDefaultValue = (orderSite: IWarehouse) => {
    if (orderSite?.orderScreen?.defaultDeliveryType) {
      const defaultDeliveryType = orderSite.orderScreen?.defaultDeliveryType
      setOrder((prevState) => ({ ...prevState, deliveryType: defaultDeliveryType }))
    }
  }

  const handleTransportTypeDefaultValue = (orderSite: IWarehouse) => {
    if (orderSite?.orderScreen?.defaultTransportType) {
      const defaultTransportType = orderSite.orderScreen?.defaultTransportType
      setOrder((prevState) => ({ ...prevState, transportType: defaultTransportType }))
    }
  }

  useEffect(() => {
    if (order && order.warehouseId && sites) {
      const orderSite = sites.find((site) => site.id === order.warehouseId)
      if (orderSite) {
        handleDestinationTypeDefaultValue(orderSite)
        handleDeliveryTypeDefaultValue(orderSite)
        handleTransportTypeDefaultValue(orderSite)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sites, order.warehouseId])

  useEffect(() => {
    if (!order.warehouseId && defaultSiteId) {
      setOrder({ ...order, warehouseId: defaultSiteId })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSiteId])

  useEffect(() => {
    if (shouldScrollToDeliveryInfoCard && deliveryInfoCardRef?.current) {
      deliveryInfoCardRef.current.scrollIntoView()
      setShouldScrollToDeliveryInfoCard(false)
    }
  }, [shouldScrollToDeliveryInfoCard, deliveryInfoCardRef])

  const debouncedOrderInputChange = useDebouncedCallback(async (orderNumber: string) => {
    const response = await OrdersApi.checkOrderNumber(orderNumber)
    if (!isIError(response)) {
      setIsOrderNumberValid(!response)
    }
  }, 600)

  const handleChange = (key: keyof IOrderForm, value: unknown): void => {
    if (key === 'orderNumber') {
      if (value === '') {
        setIsOrderNumberValid(true)
      } else {
        debouncedOrderInputChange.callback(value as string)
      }
    }
    setOrder({ ...order, [key]: value })
  }

  const isBookingEnabled = useMemo(() => {
    if (order && order.warehouseId && sites) {
      // check warehouse configuration
      const orderSite = sites.find((site) => site.id === order.warehouseId)
      return !!orderSite?.appointmentEndCustomerPage?.canPilotReserveTimeSlots
    }
    return false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sites, order.warehouseId])

  const openOrderBookingScreen = async () => {
    if (destinationType === DestinationType.Address) {
      setCoordinates(undefined)
      if (order.deliveryAddressRoad && order.deliveryAddressZipCode && order.deliveryAddressCity) {
        toggleLoader(true)
        const res = await AddressApi.geocode(
          order.deliveryAddressRoadNumber,
          order.deliveryAddressRoad,
          order.deliveryAddressZipCode,
          order.deliveryAddressCity,
          order.deliveryAddressCountry,
          user?.tenantConfig.language,
          order.warehouseId,
        )
        if (isIError(res)) {
          openErrorSnack(res.error.message)
          setCoordinates(undefined)
        } else if (res.length > 0) {
          setCoordinates({
            longitude: res[0].position.longitude,
            latitude: res[0].position.latitude,
          })
        }
        toggleLoader(false)
      }
    } else {
      const deliverySite = sites.find((site) => site.id === order.deliverySiteId)
      if (deliverySite) {
        setCoordinates({
          longitude: deliverySite.address.longitude,
          latitude: deliverySite.address.latitude,
        })
      }
    }

    setIsOrderBookingScreenOpen(true)
  }

  const closeOrderBookingScreen = () => {
    setIsOrderBookingScreenOpen(false)
    setShouldScrollToDeliveryInfoCard(true)
  }

  const handleSaveBooking = (selectedTimeSlot: ISelectedTimeSlot) => {
    if (
      moment(selectedTimeSlot.beginTime).isValid()
      && moment(selectedTimeSlot.endTime).isValid()
    ) {
      setOrder({
        ...order,
        deliveryDateTimeMinimum: moment(selectedTimeSlot.beginTime).toISOString(),
        deliveryDateTimeMaximum: moment(selectedTimeSlot.endTime).toISOString(),
      })
      closeOrderBookingScreen()
    }
  }

  const resetDestinationCardData = () => {
    if (order?.warehouseId && sites) {
      const orderSite = sites.find((site) => site.id === order.warehouseId)
      if (orderSite) {
        handleDestinationTypeDefaultValue(orderSite)
      } else setDestinationType(DestinationType.Address)
    } else setDestinationType(DestinationType.Address)
    setOrder({
      ...order,
      deliveryAddressFull: undefined,
      deliveryAddressRoadNumber: undefined,
      deliveryAddressRoad: undefined,
      deliveryAddressZipCode: undefined,
      deliveryAddressCity: undefined,
      deliveryAddressCountry: undefined,
      enterpriseLabel: undefined,
      deliveryInstructions: undefined,
      deliverySiteId: undefined,
      orderInstructions: undefined,
      deliveryDoorCode: undefined,
      deliveryIsElevatorPresent: undefined,
      deliveryFloor: undefined,
    })
  }

  const resetOriginCardData = () => {
    setOrder({
      ...order,
      warehouseId: defaultSiteId || '',
      customerEmail: undefined,
      customerFirstName: undefined,
      customerLastName: undefined,
      customerPhoneNumber: undefined,
    })
  }

  return (
    <>
      {isOrderBookingScreenOpen ? (
        <OrderBookingScreen
          isCreateOrderMode
          warehouseId={order.warehouseId}
          coordinates={coordinates}
          handleSave={handleSaveBooking}
          handleClose={closeOrderBookingScreen}
        />
      ) : (
        <OrderForm
          order={order}
          setOrder={setOrder}
          handleChange={handleChange}
          resetDestinationCardData={resetDestinationCardData}
          isOrderNumberValid={isOrderNumberValid}
          destinationType={destinationType}
          setDestinationType={setDestinationType}
          isBookingEnabled={isBookingEnabled}
          openOrderBookingScreen={openOrderBookingScreen}
          deliveryInfoCardRef={deliveryInfoCardRef}
          resetOriginCardData={resetOriginCardData}
        />
      )}
    </>
  )
}

export default OrdersFormScreen
