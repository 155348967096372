import MapWithAddressForm from 'components/Map/MapWithAddressForm'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IAddress } from 'interfaces/Itours'
import useStyles from 'constants/cruStyles'
import Card from 'components/Card/Card'

interface IProps {
  address?: IAddress
  onAddressChange: Function
  isCreateMode: boolean
  warehouseId?: string
  mapName: string
}

const SiteAddressCard = ({
  address,
  onAddressChange,
  isCreateMode,
  warehouseId,
  mapName,
}: IProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Card
      dataCy="addressCard"
      title={t('SitesScreen.address').toUpperCase()}
      contentClassName={classes.mapContent}
    >
      <MapWithAddressForm
        mapName={mapName}
        address={address}
        onAddressChange={onAddressChange}
        isCreateMode={isCreateMode}
        warehouseId={warehouseId}
      />
    </Card>
  )
}

export default SiteAddressCard
