import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Fab, Tooltip } from '@material-ui/core'
import UndoIcon from '@material-ui/icons/Undo'

import { PlanificationContext } from '../PlanningStore'

const DeselectAllButton = styled(Fab)`
  position: absolute;
  bottom: 10px;
  left: 80px;
  z-index: 500;
  background-color: white;
`

function DeselectButton(): JSX.Element {
  const { deselectAll, isMapotempoActionInProgress } = useContext(PlanificationContext)
  const { t } = useTranslation()
  return (
    <Tooltip
      data-cy="resetSelectionButton"
      title={t('PlanningScreen.resetSelection') || ''}
      aria-label={t('PlanningScreen.resetSelection')}
    >
      <DeselectAllButton disabled={isMapotempoActionInProgress} onClick={deselectAll}>
        <UndoIcon color="primary" fontSize="large" />
      </DeselectAllButton>
    </Tooltip>
  )
}

export default DeselectButton
