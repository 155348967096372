import { IError } from 'api/types'
import { IWebhook } from 'interfaces/IWebhook'
import Api from 'services/api'

const getList = async (
  offset?: number,
  rowsPerPage?: number,
): Promise<{ webhooks: Array<IWebhook>; count: number } | IError> => {
  try {
    const response = await Api.get('webhooks', {
      offset,
      limit: rowsPerPage,
    })
    return {
      webhooks: response.items.map((webhook) => ({
        ...webhook,
        siteIds: webhook.warehouseIds,
      })),
      count: response.count,
    }
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const create = async (webhook: IWebhook): Promise<IWebhook | IError> => {
  try {
    const response = await Api.post('webhooks', {
      ...webhook,
      warehouseIds: webhook.siteIds,
      siteIds: undefined,
      extra: webhook?.extra ? JSON.parse(webhook?.extra as string) : undefined,
    })
    return response
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const update = async (id: string, webhook: IWebhook): Promise<IWebhook | IError> => {
  try {
    const response = await Api.patch(`webhooks/${id}`, {
      ...webhook,
      carrierId: webhook.carrierId || null,
      warehouseIds: webhook.siteIds,
      siteIds: undefined,
      dealerId: webhook.dealerId || null,
      extra: webhook?.extra ? JSON.parse(webhook?.extra as string) : null,
    })
    return response
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const get = async (id: string): Promise<IWebhook | IError> => {
  try {
    const response = await Api.get(`webhooks/details/${id}`)
    return {
      ...response,
      siteIds: response.warehouseIds,
    }
  } catch (error) {
    return { error: { message: error.message } }
  }
}

export default {
  getList,
  create,
  update,
  get,
}
