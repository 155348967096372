import React, { useContext, useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { IUserInfos } from 'interfaces/IUserInfos'
import UsersProvider, { UsersConsumer } from 'store/UsersContext'
import { FeedbackContext } from 'store/FeedbackContext'
import { IIdParam } from 'interfaces/IIdParam'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import FormAction from 'components/Button/FormAction'
import { checkRoleWithSite } from 'utils/functions'
import InfosCard from './InfosCard'
import RolesCard from './RolesCard'
import WarehouseCard from './WarehouseCard'

interface IUsersProps {
  createUser?: Function
  getDetails?: Function
  userDetails: IUserInfos
  updateUser?: Function
  updateSuccess?: boolean
  createSuccess?: boolean
}

const UserFormScreen = ({
  createUser,
  getDetails,
  userDetails,
  updateUser,
  updateSuccess,
  createSuccess,
}: IUsersProps): JSX.Element => {
  const { id } = useParams<IIdParam>()
  const [infos, setInfos] = useState<IUserInfos>({ active: true } as IUserInfos)
  const [shouldShowSiteCard, setShouldShowSiteCard] = useState<boolean>(false)
  const { openErrorSnack } = useContext(FeedbackContext)
  const history = useHistory()
  const { t } = useTranslation()

  useEffect(() => {
    if (id && getDetails) {
      getDetails(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (updateSuccess || createSuccess) {
      history.push(ROUTES_PATH.usersList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSuccess, createSuccess])

  useEffect(() => {
    if (userDetails?.roles) {
      setShouldShowSiteCard(checkRoleWithSite(userDetails.roles))
    }
    setInfos({
      id: userDetails.id,
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      email: userDetails.email,
      active: userDetails.active,
      roles: userDetails.roles,
      companyId: userDetails.companyId,
      sites: userDetails.sites,
    })
  }, [userDetails])

  const handleInfosChange = (userInfos: IUserInfos): void => {
    setInfos((prevInfos) => ({
      ...prevInfos,
      firstName: userInfos.firstName,
      lastName: userInfos.lastName,
      email: userInfos.email,
      active: userInfos.active,
      companyId: userInfos.companyId,
      password: userInfos.password,
    }))
  }

  const handleRolesChange = (userInfos: IUserInfos): void => {
    setShouldShowSiteCard(checkRoleWithSite(userInfos.roles))
    setInfos((prevInfos) => ({
      ...prevInfos,
      roles: userInfos.roles,
    }))
  }

  const handleDefaultSiteChange = (defaultSiteId: string): void => {
    setInfos((prev) => ({
      ...prev,
      sites: prev.sites?.map((site) => ({
        siteId: site.siteId,
        default: site.siteId === defaultSiteId,
      })),
    }))
  }

  const handleSitesChange = (siteIds: string[]): void => {
    setInfos((prev) => ({
      ...prev,
      sites: siteIds.map((siteId) => ({
        siteId,
        default: false,
      })),
    }))
  }

  const checkUserSite = checkRoleWithSite(infos.roles) && !infos.sites?.some((site) => site.default)

  const handleSaveClick = async (): Promise<void> => {
    if (!infos.roles?.length) {
      openErrorSnack(t('UsersScreen.userMustHaveARole'))
      return
    }
    if (checkUserSite) {
      openErrorSnack(t('UsersScreen.userMustHaveSite'))
      return
    }
    if (id) {
      if (updateUser) {
        await updateUser(id, infos)
      }
    } else {
      if (createUser) {
        await createUser(infos)
      }
    }
  }

  const handleCancelClick = (): void => {
    history.push(ROUTES_PATH.usersList)
  }

  return (
    <>
      {id && (
        <h3 data-cy="userDetailDescription">
          {`${infos.firstName || ''} ${infos.lastName || ''}`}
        </h3>
      )}
      <InfosCard key={`${infos.id}-infos`} infos={infos} onInfosChange={handleInfosChange} />
      <RolesCard key={`${infos.id}-roles`} infos={infos} onRolesChange={handleRolesChange} />
      {shouldShowSiteCard && (
        <WarehouseCard
          key={`${infos.id}-sites`}
          onSitesChange={handleSitesChange}
          onDefaultSiteChange={handleDefaultSiteChange}
          sites={infos.sites?.map(({ siteId }) => siteId)}
          defaultSite={infos.sites?.find((site) => site.default)?.siteId}
        />
      )}
      <FormAction onSaveClick={handleSaveClick} onCancelClick={handleCancelClick} />
    </>
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <UsersProvider>
    <UsersConsumer>
      {(ctx): JSX.Element => (
        <UserFormScreen
          updateSuccess={ctx.updateSuccess}
          createSuccess={ctx.createSuccess}
          createUser={ctx.createUser}
          updateUser={ctx.updateUser}
          getDetails={ctx.getDetails}
          userDetails={ctx.userDetails}
          {...props}
        />
      )}
    </UsersConsumer>
  </UsersProvider>
)
