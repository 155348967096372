import React, { useState, ReactNode, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { isIError } from 'api/types'
import { getErrorList } from 'utils/errorUtils'
import PlanTemplatesApi from 'api/planTemplates'
import { COLUMNS_MODEL_PLAN_TEMPLATE } from 'constants/table'
import { IPlanTemplate } from 'interfaces/IPlanTemplate'
import { IPlanTemplatesFilters } from 'interfaces/interfaces'
import { FeedbackContext } from './FeedbackContext'

interface IPlanTemplatesContext {
  planTemplates: IPlanTemplate[]
  count: number
  createSuccess?: boolean
  updateSuccess?: boolean
  isSuccessFullyDeleted?: boolean
  planTemplateDetails: IPlanTemplate
  getPlanTemplates: (
    filters?: IPlanTemplatesFilters,
    offset?: number,
    rowsPerPage?: number,
    sortField?: number,
    sortDirection?: string,
  ) => void
  createPlanTemplate: (planTemplate: IPlanTemplate) => void
  updatePlanTemplate: (id: string, planTemplate: IPlanTemplate) => void
  getPlanTemplateDetails: (id: string) => void
  deletePlanTemplate: (id: string) => void
}

interface IProps {
  children: ReactNode
}

const { Provider, Consumer } = React.createContext<IPlanTemplatesContext>(
  {} as IPlanTemplatesContext,
)

const PlanTemplatesProvider = ({ children }: IProps): JSX.Element => {
  const [planTemplates, setPlanTemplates] = useState<IPlanTemplate[]>([])
  const [count, setCount] = useState<number>(0)
  const { t } = useTranslation()
  const [updateSuccess, setUpdateSuccess] = useState<boolean | undefined>()
  const [createSuccess, setCreateSuccess] = useState<boolean | undefined>()
  const [isSuccessFullyDeleted, setIsSuccessFullyDeleted] = useState<boolean | undefined>()
  const [planTemplateDetails, setPlanTemplateDetails] = useState<IPlanTemplate>({} as IPlanTemplate)
  const { openErrorSnack, openSuccessSnack, toggleLoader } = useContext(FeedbackContext)

  const getPlanTemplates = async (
    filters?: IPlanTemplatesFilters,
    offset?: number,
    rowsPerPage?: number,
    sortField?: number,
    sortDirection?: string,
  ): Promise<void> => {
    toggleLoader(true)
    const response = await PlanTemplatesApi.getList(
      filters,
      offset,
      rowsPerPage,
      COLUMNS_MODEL_PLAN_TEMPLATE.find((_column, index) => index === sortField)?.field,
      sortDirection === 'desc' ? -1 : 1,
    )
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      setPlanTemplates(response.planTemplates)
      setCount(response.count)
    }
    toggleLoader(false)
  }

  const getPlanTemplateDetails = async (id: string): Promise<void> => {
    toggleLoader(true)
    const response = await PlanTemplatesApi.get(id)
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      setPlanTemplateDetails(response)
    }
    toggleLoader(false)
  }

  const createPlanTemplate = async (planTemplate: IPlanTemplate): Promise<void> => {
    toggleLoader(true)
    const response = await PlanTemplatesApi.create(planTemplate)
    if (isIError(response)) {
      response.error.errorList = getErrorList(response)
      if (response.error.fieldErrors) {
        openErrorSnack(response.error.errorList.join('\n'))
      } else {
        openErrorSnack(response.error.message)
      }
    } else {
      setCreateSuccess(true)
      openSuccessSnack(t('PlanTemplatesScreen.createdPlanTemplate'))
    }
    toggleLoader(false)
  }

  const updatePlanTemplate = async (id: string, planTemplate: IPlanTemplate): Promise<void> => {
    toggleLoader(true)
    const response = await PlanTemplatesApi.update(id, planTemplate)
    if (isIError(response)) {
      response.error.errorList = getErrorList(response)
      if (response.error.fieldErrors) {
        openErrorSnack(response.error.errorList.join('\n'))
      } else {
        openErrorSnack(response.error.message)
      }
    } else {
      setUpdateSuccess(true)
      openSuccessSnack(t('PlanTemplatesScreen.updatedPlanTemplate'))
    }
    toggleLoader(false)
  }

  const deletePlanTemplate = async (id: string): Promise<void> => {
    toggleLoader(true)
    const response = await PlanTemplatesApi.deletePlanTemplate(id)
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      setIsSuccessFullyDeleted(true)
      openSuccessSnack(t('PlanTemplatesScreen.deletedPlanTemplate'))
    }
    toggleLoader(false)
  }

  return (
    <Provider
      value={{
        planTemplateDetails,
        updatePlanTemplate,
        updateSuccess,
        createPlanTemplate,
        createSuccess,
        planTemplates,
        count,
        getPlanTemplates,
        getPlanTemplateDetails,
        deletePlanTemplate,
        isSuccessFullyDeleted,
      }}
    >
      {children}
    </Provider>
  )
}

export default PlanTemplatesProvider

export { Consumer as PlanTemplatesConsumer }
