import React, { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import IconButton from '@material-ui/core/IconButton'
import { ArrowForward } from '@material-ui/icons'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import { useTheme } from '@material-ui/core/styles'

import { ITour, IStop } from 'interfaces/Itours'
import { dateDefaultFormat, dateToTimeFormat } from 'utils/dateFormat'
import {
  formatVisitHasElevator,
  formatSpecifics,
  formatVisitFloors,
  getTimeSlotFromStop,
  formatVisitTags,
} from 'utils/tableUtils'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import { IPlanStop, IPlanTour } from 'interfaces/IPlan'

import { STOP_STATUS_ENUM, TOUR_STATUS_ENUM } from 'constants/constants'
import { removeFirstAndLastStops } from 'utils/stopsUtils'
import { TagsContext } from 'store/TagsContext'
import { AppConfigContext } from 'store/AppConfigContext'
import { EXECUTION_STOP_DETAILS_COLUMNS_MODEL } from 'constants/table'
import Extended from './ExtendedDetailsPanel'
import useStyles from './styles'

interface IProps {
  readonly rowData: ITour | IPlanTour
  readonly extended?: boolean
  readonly isForPlanning?: boolean
  readonly tableName?: string
}

/* This is mandatory since material table direct childrens can't use hooks (weird, I know) */
const DetailsPanelWrapper = (props: IProps): JSX.Element => <DetailsPanel {...props} />

const ExtendedDetailsPanel = (props: IProps): JSX.Element => <DetailsPanel extended {...props} />

const PlanningExtendedDetailsPanel = (props: IProps): JSX.Element => (
  <DetailsPanel extended isForPlanning {...props} />
)

function DetailsPanel({ rowData, extended, isForPlanning, tableName }: IProps): JSX.Element {
  const { stops, status } = rowData
  const { t } = useTranslation()
  const theme = useTheme()
  const classes = useStyles()
  const { tags } = useContext(TagsContext)
  const { tablesConfig } = useContext(AppConfigContext)

  const [showFloor, setShowFloor] = useState<boolean>(false)
  const [showElevator, setShowElevator] = useState<boolean>(false)
  const [showTags, setShowTags] = useState<boolean>(false)

  useEffect(() => {
    if (tablesConfig && tableName) {
      if (tableName === 'execution') {
        EXECUTION_STOP_DETAILS_COLUMNS_MODEL.forEach((item) => {
          const columnInPreferences = tablesConfig[tableName]?.includes(item.title)
          const showColumn = item.defaultHidden ? columnInPreferences : !columnInPreferences
          switch (item.field) {
            case 'floor':
              setShowFloor(showColumn)
              break
            case 'hasElevator':
              setShowElevator(showColumn)
              break
            case 'tags':
              setShowTags(showColumn)
              break
            default:
              break
          }
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tablesConfig, tableName])

  /* Make sure stops exists and return an empty view if not */
  if (!stops || stops.length === 0) {
    return <></>
  }
  const stopsArray = removeFirstAndLastStops(stops) as IStop[] | IPlanStop[]

  const getPlanVisitId = (element: IPlanStop): string | undefined => {
    if (element.planVisitsToDeliver?.length && element.planVisitsToDeliver[0]) return element.planVisitsToDeliver[0].planVisitsId
    if (element.planVisitsToLoad?.length && element.planVisitsToLoad[0]) return element.planVisitsToLoad[0].planVisitsId
  }

  const getVisitId = (element: IStop): string | undefined => {
    if (element.visitsToDeliver[0]) return element.visitsToDeliver[0].visitId
    if (element.visitsToLoad[0]) return element.visitsToLoad[0].visitId
  }

  const getTimeSlotFromPlanStop = (element: IPlanStop): string => {
    if (element.planVisitsToDeliver?.length && element.planVisitsToDeliver[0]) {
      const planVisitToDeliver = element.planVisitsToDeliver[0]
      let timeSlot = ''
      if (
        planVisitToDeliver.deliveryDateTimeMinimum
        && planVisitToDeliver.deliveryDateTimeMaximum
      ) {
        timeSlot = `${dateToTimeFormat(
          planVisitToDeliver.deliveryDateTimeMinimum,
        )}-${dateToTimeFormat(planVisitToDeliver.deliveryDateTimeMaximum)}`
      }
      return timeSlot
    }
    if (element.planVisitsToLoad?.length && element.planVisitsToLoad[0]) {
      const planVsitToLoad = element.planVisitsToLoad[0]
      let timeSlot = ''
      if (planVsitToLoad.deliveryDateTimeMinimum && planVsitToLoad.deliveryDateTimeMaximum) {
        timeSlot = `${dateToTimeFormat(planVsitToLoad.deliveryDateTimeMinimum)}-${dateToTimeFormat(
          planVsitToLoad.deliveryDateTimeMaximum,
        )}`
      }
      return timeSlot
    }
    return ''
  }

  return (
    <Table
      size="small"
      aria-label="a dense table"
      style={{ width: 'auto', margin: 4, boxShadow: '0 0 6px 2px grey' }}
    >
      <TableHead
        style={{
          border: 'none',
          borderBottom: '1px solid #A8A8A8',
        }}
      >
        <TableRow>
          <TableCell align="left">{t('tablesEntries.details.label')}</TableCell>
          <TableCell align="left">{t('tablesEntries.details.location')}</TableCell>
          <TableCell align="left">{t('tablesEntries.details.phoneNumber')}</TableCell>
          <TableCell align="left">{t('tablesEntries.details.address')}</TableCell>
          {!extended && (
            <>
              {showFloor && <TableCell align="left">{t('tablesEntries.details.floor')}</TableCell>}
              {showElevator && (
                <TableCell align="left">{t('tablesEntries.details.elevator')}</TableCell>
              )}
              {showTags && <TableCell align="left">{t('tablesEntries.details.tags')}</TableCell>}
              <TableCell align="right">{t('tablesEntries.details.specifics')}</TableCell>
            </>
          )}
          <>
            <TableCell align="right">{t('tablesEntries.details.bracket')}</TableCell>
            <TableCell align="right">{t('tablesEntries.details.plannedArrival')}</TableCell>
          </>
          {extended && <Extended.Columns isForPlanning={isForPlanning} />}
          <TableCell align="right" />
        </TableRow>
      </TableHead>
      <TableBody>
        {stopsArray
          && stopsArray.map((row: IStop | IPlanStop, index: number) => (
            <TableRow
              key={row.order}
              style={{
                backgroundColor:
                  index % 2 === 0 ? theme.color.tableRowEven : theme.color.subTableRowOdd,
              }}
            >
              <TableCell align="left">{row.label}</TableCell>
              <TableCell align="left">{row.address?.label}</TableCell>
              <TableCell align="left">{row.phoneNumber}</TableCell>
              <TableCell align="left">{row.address?.full}</TableCell>
              {!extended && (
                <>
                  {showFloor && (
                    <TableCell align="left">{formatVisitFloors(row as IStop)}</TableCell>
                  )}
                  {showElevator && (
                    <TableCell align="left">{formatVisitHasElevator(row as IStop)}</TableCell>
                  )}
                  {showTags && (
                    <TableCell align="left">
                      <div className={classes.tagsContent}>
                        {formatVisitTags(row as IStop, tags)}
                      </div>
                    </TableCell>
                  )}
                  <TableCell align="right">{formatSpecifics(row as IStop)}</TableCell>
                </>
              )}
              <>
                {isForPlanning ? (
                  <TableCell align="right">{getTimeSlotFromPlanStop(row as IPlanStop)}</TableCell>
                ) : (
                  <TableCell align="right">
                    <div className={classes.alignFlexStart}>
                      {getTimeSlotFromStop(row as IStop)}
                    </div>
                  </TableCell>
                )}
                <TableCell align="right">{dateDefaultFormat(row.plannedArrival)}</TableCell>
              </>
              {extended && (
                <Extended.Data
                  stop={row}
                  isForPlanning={isForPlanning}
                  // eslint-disable-next-line dot-notation
                  toleratedAdvance={rowData['toleratedAdvance']}
                  // eslint-disable-next-line dot-notation
                  toleratedDelay={rowData['toleratedDelay']}
                  canEditStatus={
                    (status === TOUR_STATUS_ENUM.GOING
                      && row.status !== STOP_STATUS_ENUM.Delivered
                      && row.status !== STOP_STATUS_ENUM.Loaded
                      && row.status !== STOP_STATUS_ENUM.OnSite)
                    || (status === TOUR_STATUS_ENUM.DROPPED
                      && row.status === STOP_STATUS_ENUM.Abandoned)
                  }
                  isTourDropped={status === TOUR_STATUS_ENUM.DROPPED}
                />
              )}
              <TableCell align="right">
                <Link
                  to={{
                    pathname: `${ROUTES_PATH.ordersList}`,
                    search: isForPlanning
                      ? `?planVisitId=${getPlanVisitId(row as IPlanStop)}`
                      : `?visitId=${getVisitId(row as IStop)}`,
                    state: {
                      startDateFilter: rowData.departureDateTime,
                      endDateFilter: rowData.arrivalDateTime,
                    },
                  }}
                >
                  <IconButton>
                    <ArrowForward style={{ color: theme.color.secondary }} />
                  </IconButton>
                </Link>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  )
}

export { ExtendedDetailsPanel, PlanningExtendedDetailsPanel }
export default DetailsPanelWrapper
