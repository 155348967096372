import { IError } from 'api/types'
import { ISector, ITargetZoneSector } from 'interfaces/ISector'
import Api from 'services/api'

const create = async (newSector: ISector): Promise<ISector | IError> => {
  try {
    const response = await Api.post('sectors', newSector)
    return response
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

const deleteSector = async (id: string): Promise<void | IError> => {
  try {
    const response = await Api.delete(`sectors/${id}`)
    return response
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const update = async (id: string, sector: ISector): Promise<ISector | IError> => {
  try {
    const response = await Api.patch(`sectors/${id}`, sector)
    return response
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

const createTargetZoneSector = async (
  targetZoneSector: ITargetZoneSector,
): Promise<ISector | IError> => {
  try {
    const response = await Api.post('sectors/coordinates', targetZoneSector)
    return response
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

export default {
  create,
  deleteSector,
  update,
  createTargetZoneSector,
}
