import React, { Dispatch, SetStateAction } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useTranslation } from 'react-i18next'
import { Button } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'

import { isDefined } from 'utils/functions'
import { ICustomerAddress } from 'interfaces/ICustomer'
import useStyles from './styles'

export interface IProps {
  addresses: ICustomerAddress[]
  open: boolean
  onSelectAddress: (address: ICustomerAddress) => void
  setDialogOpen: Dispatch<SetStateAction<boolean>>
  onDeleteAddress: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    address: ICustomerAddress,
  ) => Promise<void>
}

export default function AddressDialog({
  open,
  addresses,
  onSelectAddress,
  setDialogOpen,
  onDeleteAddress,
}: IProps): JSX.Element {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleClick = (address: ICustomerAddress): void => {
    onSelectAddress(address)
    setDialogOpen(false)
  }

  const constructComplementString = (addressChoice: ICustomerAddress): string => {
    const parts: string[] = []
    if (addressChoice.deliveryIsElevatorPresent) {
      parts.push(t('OrdersScreen.deliveryIsElevatorPresent'))
    }
    if (isDefined(addressChoice.deliveryFloor)) {
      parts.push(`${t('OrdersScreen.deliveryFloor')}: ${addressChoice.deliveryFloor}`)
    }
    if (addressChoice.deliveryDoorCode) {
      parts.push(`${t('OrdersScreen.deliveryDoorCode')}: ${addressChoice.deliveryDoorCode}`)
    }
    return parts.join(' - ')
  }

  const handleDeleteAddress = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    address: ICustomerAddress,
  ): void => {
    e.stopPropagation()
    e.preventDefault()
    onDeleteAddress(e, address)
  }

  return (
    <Dialog onClose={() => setDialogOpen(false)} open={open}>
      <div className={classes.dialog}>
        <DialogTitle>{t('OrdersScreen.dialog.title')}</DialogTitle>
        <div className={classes.dialogAddressesContainer}>
          {addresses.map((addressChoice, index) => (
            <div
              key={`${addressChoice.deliveryAddress}`}
              role="button"
              tabIndex={index}
              className={classes.addressLineWithMargin}
              onClick={() => handleClick(addressChoice)}
              onKeyPress={(e): false | void => e.keyCode === 13 && handleClick(addressChoice)}
            >
              <div>
                <div>{addressChoice.deliveryAddress}</div>
                {constructComplementString(addressChoice) && (
                  <div>{constructComplementString(addressChoice)}</div>
                )}
                {addressChoice.deliveryInstructions && (
                  <div>{addressChoice.deliveryInstructions}</div>
                )}
                {isDefined(addressChoice.relevance) && (
                  <div>{`${t('OrdersScreen.accuracy')} = ${addressChoice.relevance}%`}</div>
                )}
              </div>
              <Button
                onClick={(e) => {
                  handleDeleteAddress(e, addressChoice)
                }}
              >
                <DeleteIcon />
              </Button>
            </div>
          ))}
          <div
            role="button"
            className={classes.addressLineWithMargin}
            onClick={() => setDialogOpen(false)}
            tabIndex={addresses.length}
            onKeyPress={(e): false | void => e.keyCode === 13 && setDialogOpen(false)}
          >
            <div>{t('OrdersScreen.dialog.newAddress')}</div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
