import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { useTheme } from '@material-ui/core/styles'
import styled from 'styled-components'
import { IVisit } from 'interfaces/IOrders'
import { Draggable, DroppableProvided } from 'react-beautiful-dnd'
import VisitsListItem from './VisitsListItem'

import StyledPaper from './StyledPaper'
import { ScreenMode } from './FabMenu'
import Header from './Header'

import useStyles from '../styles'

interface IUnassignedVisits {
  displayMode: ScreenMode
  visits: IVisit[]
  provided?: DroppableProvided
}

const StyledContainer = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  overflow-y: overlay;
  height: 100%;
  max-height: calc(100% - 40px);
`

const UnassignedVisits = ({ displayMode, visits, provided }: IUnassignedVisits): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()

  const sortedVisits = useMemo(
    () =>
      visits.sort(
        (v1, v2) =>
          v1.destinationAddress.label?.localeCompare(v2.destinationAddress.label || '') || -1,
      ),
    [visits],
  )

  const renderItems = () => {
    if (!provided) {
      return sortedVisits.map((visit) => <VisitsListItem key={visit.visitId} visit={visit} />)
    }

    return sortedVisits.map((visit, index) => (
      <Draggable key={visit.visitId} index={index} draggableId={visit.visitId}>
        {(providedStop, snapshotStop): JSX.Element => (
          <VisitsListItem
            visit={visit}
            providedMarker={providedStop}
            snapshotMarker={snapshotStop}
          />
        )}
      </Draggable>
    ))
  }

  return (
    <StyledPaper
      className={clsx(
        classes.scrollable,
        classes.toursList,
        displayMode === ScreenMode.Map && classes.hidden,
        provided && displayMode === ScreenMode.List && classes.visitsColumnsContainer,
      )}
      square
      ref={provided?.innerRef}
    >
      <Header theme={theme}>
        <b>{`${t('MapScreen.toAssign')}: ${sortedVisits.length}`}</b>
      </Header>
      <StyledContainer>{renderItems()}</StyledContainer>
    </StyledPaper>
  )
}

export default UnassignedVisits
