export enum NotificationType {
  Tracking = 'tracking',
  Booking = 'booking',
  DeliveryPlanningValidation = 'deliveryPlanningValidation',
  StopDelivered = 'stopDelivered',
  StopAbandoned = 'stopAbandoned',
  StopCanceled = 'stopCanceled',
  StopPostponed = 'stopPostponed',
  TourAbandoned = 'tourAbandoned',
}

export enum SmsCommunicationService {
  Twilio = 'twilio',
  Octopush = 'octopush',
}

export interface INotifierConfiguration {
  id: string
  tenantId: string
  warehouseIds: string[]
  dealerIds: string[]
  carrierIds: string[]
  notificationType: NotificationType
  smsCommunicationService?: SmsCommunicationService
  deliveryTypes: number[]
  notificationParam: Partial<INotificationParam>
}

interface INotificationParam {
  sendSms: boolean
  message?: string
  specific?: Record<string, unknown> | string
  sender?: string
}
