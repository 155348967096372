import {
  MENU_SUBCATEGORIES,
  MENU_CATEGORIES,
  USER_ROLES,
  PILOT_AND_STORE_USER_ROLES,
} from 'constants/constants'
import PlanningScreen from 'screens/PlanningCategory/PlanningScreen'
import PlanTemplatesListScreen from 'screens/PlanningCategory/PlanTemplate/PlanTemplatesListScreen'
import PlanTemplateFormScreen from 'screens/PlanningCategory/PlanTemplate/PlanTemplateFormScreen'
import PlanTemplateDetailsScreen from 'screens/PlanningCategory/PlanTemplate/PlanTemplateDetailsScreen'
import GeocodingOrdersScreen from 'screens/PlanningCategory/GeocodingOrders/GeocodingOrdersScreen'
import RoutingRulesTable from 'screens/PlanningCategory/RoutingRules/RoutingRulesTable'
import RoutingRulesForm from 'screens/PlanningCategory/RoutingRules/RoutingRulesForm'
import PlanTourList from 'screens/PlanningCategory/PlanTour/PlanTourList'

import SectorsListScreen from 'screens/PlanningCategory/Sectors/SectorsListScreen'
import SectorDetailsScreen from 'screens/PlanningCategory/Sectors/SectorDetailsScreen'
import OrdersListScreen from 'screens/PlanningCategory/Orders/OrdersListScreen'
import PlanningOrderDetailsScreen from 'screens/PlanningCategory/Orders/PlanningOrderDetailsScreen'
import MultisitePlanningScreen from 'screens/PlanningCategory/MultisitePlanning/MultisitePlanningScreen'
import { ROUTES_PATH } from './RoutesPath'

const PLANNING_CATEGORY = [
  {
    title: MENU_SUBCATEGORIES.planification,
    path: ROUTES_PATH.planning,
    component: PlanningScreen,
    isPrivate: true,
    isInMenu: true,
    category: MENU_CATEGORIES.planning,
    roles: PILOT_AND_STORE_USER_ROLES,
    isBetaMode: true,
    description: 'Ecran principal de planification (Menu Planification/planification)',
    exact: true,
  },
  {
    title: MENU_SUBCATEGORIES.multisitePlanning,
    path: ROUTES_PATH.multisitePlanning,
    component: MultisitePlanningScreen,
    isPrivate: true,
    isInMenu: true,
    category: MENU_CATEGORIES.planning,
    roles: PILOT_AND_STORE_USER_ROLES,
    isBetaMode: true,
    description:
      'Ecran principal de planification multisite (Menu Planification/planification multi)',
    exact: true,
  },
  {
    title: MENU_SUBCATEGORIES.tourList,
    path: ROUTES_PATH.planningList,
    component: PlanTourList,
    isPrivate: true,
    isInMenu: true,
    category: MENU_CATEGORIES.planning,
    roles: PILOT_AND_STORE_USER_ROLES,
  },
  {
    title: MENU_SUBCATEGORIES.geocodingOrders,
    path: ROUTES_PATH.geocodingOrders,
    component: GeocodingOrdersScreen,
    isPrivate: true,
    isInMenu: true,
    category: MENU_CATEGORIES.planning,
    roles: PILOT_AND_STORE_USER_ROLES,
  },
  {
    title: MENU_SUBCATEGORIES.planTemplates,
    path: ROUTES_PATH.planTemplateList,
    component: PlanTemplatesListScreen,
    isPrivate: true,
    isInMenu: true,
    category: MENU_CATEGORIES.planning,
    roles: PILOT_AND_STORE_USER_ROLES,
  },
  {
    title: MENU_SUBCATEGORIES.planTemplates,
    path: ROUTES_PATH.createPlanTemplate,
    component: PlanTemplateFormScreen,
    isPrivate: true,
    isInMenu: false,
    category: MENU_CATEGORIES.planning,
    roles: PILOT_AND_STORE_USER_ROLES,
  },
  {
    title: MENU_SUBCATEGORIES.planTemplates,
    path: `${ROUTES_PATH.editPlanTemplate}:id`,
    component: PlanTemplateFormScreen,
    isPrivate: true,
    isInMenu: false,
    category: MENU_CATEGORIES.planning,
    roles: PILOT_AND_STORE_USER_ROLES,
  },
  {
    title: MENU_SUBCATEGORIES.planTemplates,
    path: `${ROUTES_PATH.planTemplateDetails}:id`,
    component: PlanTemplateDetailsScreen,
    isPrivate: true,
    isInMenu: false,
    category: MENU_CATEGORIES.planning,
    roles: PILOT_AND_STORE_USER_ROLES,
  },
  {
    title: MENU_SUBCATEGORIES.routingRules,
    path: ROUTES_PATH.routingRulesList,
    component: RoutingRulesTable,
    isPrivate: true,
    isInMenu: true,
    category: MENU_CATEGORIES.planning,
    roles: PILOT_AND_STORE_USER_ROLES,
  },
  {
    title: MENU_SUBCATEGORIES.routingRules,
    path: ROUTES_PATH.createRoutingRules,
    component: RoutingRulesForm,
    isPrivate: true,
    isInMenu: false,
    category: MENU_CATEGORIES.planning,
    roles: PILOT_AND_STORE_USER_ROLES,
  },
  {
    title: MENU_SUBCATEGORIES.routingRules,
    path: `${ROUTES_PATH.detailRoutingRules}:id`,
    component: RoutingRulesForm,
    isPrivate: true,
    isInMenu: false,
    category: MENU_CATEGORIES.planning,
    roles: PILOT_AND_STORE_USER_ROLES,
  },
  {
    title: MENU_SUBCATEGORIES.routingRules,
    path: `${ROUTES_PATH.editRoutingRules}:id`,
    component: RoutingRulesForm,
    isPrivate: true,
    isInMenu: false,
    category: MENU_CATEGORIES.planning,
    roles: PILOT_AND_STORE_USER_ROLES,
  },
  {
    title: MENU_SUBCATEGORIES.sectors,
    path: ROUTES_PATH.sectorsList,
    component: SectorsListScreen,
    isPrivate: true,
    isInMenu: true,
    category: MENU_CATEGORIES.planning,
    roles: PILOT_AND_STORE_USER_ROLES,
  },
  {
    title: MENU_SUBCATEGORIES.sectors,
    path: `${ROUTES_PATH.sectorDetails}:id`,
    component: SectorDetailsScreen,
    isPrivate: true,
    isInMenu: false,
    category: MENU_CATEGORIES.planning,
    roles: PILOT_AND_STORE_USER_ROLES,
  },
  {
    title: MENU_SUBCATEGORIES.orders,
    path: ROUTES_PATH.planningOrdersList,
    component: OrdersListScreen,
    isPrivate: true,
    isInMenu: true,
    category: MENU_CATEGORIES.planning,
    roles: [...PILOT_AND_STORE_USER_ROLES, USER_ROLES.superAdmin],
  },
  {
    title: MENU_SUBCATEGORIES.orderDetails,
    path: `${ROUTES_PATH.planningOrderDetails}:id`,
    component: PlanningOrderDetailsScreen,
    isPrivate: true,
    isInMenu: false,
    category: MENU_CATEGORIES.planning,
    roles: [...PILOT_AND_STORE_USER_ROLES, USER_ROLES.superAdmin],
  },
]

export default PLANNING_CATEGORY
