import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import CustomCard from 'components/CustomCard/CustomCard'
import AppConfigProvider from 'store/AppConfigContext'
import { ContentConsumer } from 'store/ContentContext'
import { IIdParam } from 'interfaces/IIdParam'
import SitesProvider, { SitesConsumer } from 'store/SitesContext'
import SectorMap from 'components/Map/SectorMap/SectorMap'
import { IWarehouse } from 'interfaces/interfaces'
import { ISector, ITargetZoneSector } from 'interfaces/ISector'
import SectorsList from './components/SectorsList/SectorsList'
import useStyles from './styles'

interface IProps {
  getDetails?: Function
  siteDetails: IWarehouse
  sectors: ISector[]
  createSector?: Function
  deleteSector?: Function
  updateSector?: (sectorId: string, sector: ISector) => Promise<void>
  createTargetZoneSector: (targetZoneSector: ITargetZoneSector) => Promise<void>
  refreshSites?: () => void
}

const SectorDetailsScreen = ({
  getDetails,
  siteDetails,
  sectors,
  createSector,
  deleteSector,
  updateSector,
  createTargetZoneSector,
  refreshSites,
}: IProps): JSX.Element => {
  const { id } = useParams<IIdParam>()
  const { t } = useTranslation()
  const classes = useStyles()

  useEffect(() => {
    if (id && getDetails) {
      getDetails(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <>
      <h3 data-cy="sectorDetailDescription">{`${siteDetails.name || ''}`}</h3>
      <CustomCard cardTitle={t('SectorsScreen.cartographie')} dataCy="cartographyCard">
        <SectorMap
          mapName="sector"
          warehouseId={siteDetails?.id}
          warehousePosition={{
            lat: siteDetails?.address?.latitude,
            lng: siteDetails?.address?.longitude,
          }}
          sectors={sectors}
          createSector={createSector}
          deleteSector={deleteSector}
          updateSector={updateSector}
          createTargetZoneSector={createTargetZoneSector}
          refreshSites={refreshSites}
        />
      </CustomCard>
      <div className={classes.listCard}>
        <SectorsList sectors={sectors} />
      </div>
    </>
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <AppConfigProvider>
    <SitesProvider>
      <ContentConsumer>
        {(contentCtx): JSX.Element => (
          <SitesConsumer>
            {(sitesCtx): JSX.Element => (
              <SectorDetailsScreen
                getDetails={sitesCtx.getDetails}
                siteDetails={sitesCtx.siteDetails}
                sectors={sitesCtx.sectors}
                createSector={sitesCtx.createSector}
                deleteSector={sitesCtx.deleteSector}
                updateSector={sitesCtx.updateSector}
                createTargetZoneSector={sitesCtx.createTargetZoneSector}
                refreshSites={contentCtx.refreshSites}
                {...props}
              />
            )}
          </SitesConsumer>
        )}
      </ContentConsumer>
    </SitesProvider>
  </AppConfigProvider>
)
