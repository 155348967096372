import React, { Dispatch, SetStateAction, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Menu, IconButton, MenuItem, Checkbox } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { AppConfigContext } from 'store/AppConfigContext'

interface IProps {
  anchorEl: null | HTMLElement
  setAnchorEl: Dispatch<SetStateAction<null | HTMLElement>>
}

export const CARD_SETTINGS_VALUES = [
  {
    label: 'CardSettingsMenu.hasElevator',
    propName: 'elevator',
    propPath: 'address.hasElevator',
    formatter: (value: boolean): string => (value ? 'CardSettingsMenu.yes' : 'CardSettingsMenu.no'),
  },
  {
    label: 'CardSettingsMenu.floor',
    propName: 'floor',
    propPath: 'address.floor',
    formatter: (value: string): string => value,
  },
  {
    label: 'CardSettingsMenu.tags',
    propName: 'tags',
    propPath: 'tags',
    formatter: (values: string[] = [], tagsMap: Map<string, string>): string =>
      values
        .map((code) => tagsMap.get(code))
        .sort((a, b) => (a as string)?.localeCompare(b || ''))
        .join(', '),
  },
]

export const CardSettingsMenu = ({ anchorEl, setAnchorEl }: IProps): JSX.Element => {
  const { t } = useTranslation()

  const { toolTipsConfig, updateToolTipConfig } = useContext(AppConfigContext)

  const toggleEntry = (propName: string) => {
    const initialCardSettings = toolTipsConfig.cardSettings ? toolTipsConfig.cardSettings : []
    const filteredCardSettings = initialCardSettings.includes(propName)
      ? initialCardSettings.filter((setting) => setting !== propName)
      : [...initialCardSettings, propName]
    updateToolTipConfig(
      'cardSettings',
      filteredCardSettings.sort((a, b) => a.localeCompare(b)),
    )
  }
  const handleClose = () => setAnchorEl(null)

  return (
    <Menu
      id="card-settings"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <div style={{ float: 'right' }}>
        <IconButton onClick={handleClose} size="small">
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>
      {CARD_SETTINGS_VALUES.map((setting) => (
        <MenuItem key={setting.propName} onClick={() => toggleEntry(setting.propName)}>
          <Checkbox checked={toolTipsConfig.cardSettings?.includes(setting.propName)} />
          {t(setting.label)}
        </MenuItem>
      ))}
    </Menu>
  )
}
