/* eslint-disable  @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import clsx from 'clsx'
import Card from 'components/Card/Card'
import useStyles from 'constants/cruStyles'
import {
  DISPUTE_REASON_CODES_RELATES_TO,
  DISPUTE_REASON_CODES_TYPES,
  IDisputeReasonCode,
} from 'interfaces/IDispute'
import { INamedFieldChange } from 'interfaces/interfaces'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Switch from 'components/Inputs/ToggleSwitch'

interface IProps {
  disputeReasonCodes: IDisputeReasonCode[]
  onDisputeReasonCodesChange: (disputeReasonCodes: IDisputeReasonCode[]) => void
}

// Custom styles for the label
const LabelWrapper = styled(Box)`
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '5px',
`
const StyledSelect = styled(Select)`
  width: '100px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
`

const DisputeReasonsCard = ({
  disputeReasonCodes,
  onDisputeReasonCodesChange,
}: IProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleAdd = (): void => {
    onDisputeReasonCodesChange([
      ...disputeReasonCodes,
      {
        code: '',
        isEanRequired: false,
        isMediaRequired: false,
        label: '',
        relatesTo: [],
        types: [],
      },
    ])
  }

  const handleDelete = (index: number): void => {
    const updatedItems = [...disputeReasonCodes]
    updatedItems.splice(index, 1)
    onDisputeReasonCodesChange(updatedItems)
  }

  const handleInputChange = (event: INamedFieldChange, index: number): void => {
    const { target } = event
    const { name, value, type } = target
    onDisputeReasonCodesChange(
      disputeReasonCodes.map((reason, itemIndex) => {
        if (itemIndex === index) {
          return {
            ...reason,
            [name]: type === 'checkbox' ? target.checked : value,
          }
        }
        return reason
      }),
    )
  }
  const handleSelectChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    name: string,
    index: number,
  ): void => {
    const { target } = event
    const { value } = target
    onDisputeReasonCodesChange(
      disputeReasonCodes.map((reason, itemIndex) => {
        if (itemIndex === index) {
          return {
            ...reason,
            [name]: value,
          }
        }
        return reason
      }),
    )
  }

  const handleRelatesToRender = (selectedItemsArray: string[]) => {
    let string = ''
    selectedItemsArray.forEach((item: string, index: number) => {
      let concat = ''
      const reasonCodeRelatesTo = DISPUTE_REASON_CODES_RELATES_TO.find((e) => e.value === item)
      if (reasonCodeRelatesTo?.label) {
        const { label } = reasonCodeRelatesTo
        concat += label
        if (index !== selectedItemsArray.length - 1) {
          concat += ', '
        }
        string += concat
      }
    })
    return string
  }

  const handleTypesRender = (selectedItemsArray: string[]) => {
    let string = ''
    selectedItemsArray.forEach((item: string, index: number) => {
      let concat = ''
      const reasonCodeTypes = DISPUTE_REASON_CODES_TYPES.find((e) => e.value === item)
      if (reasonCodeTypes?.label) {
        const { label } = reasonCodeTypes
        concat += label
        if (index !== selectedItemsArray.length - 1) {
          concat += ', '
        }
        string += concat
      }
    })
    return string
  }

  return (
    <Card
      dataCy="disputeReasonsCard"
      title={t('ConfigurationsScreen.disputeReasonCodes').toUpperCase()}
      contentClassName={classes.contentRef}
    >
      <>
        <div className={classes.btnSelect}>
          <FormControl variant="outlined">
            <Button data-cy="addButton" className={classes.modifyButton} onClick={handleAdd}>
              {t('ConfigurationsScreen.add').toUpperCase()}
            </Button>
          </FormControl>
        </div>
        <div className={classes.fieldsContainer}>
          {disputeReasonCodes?.map((reasonCode, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div data-cy="deliveryTypeItems" className={classes.configurationRow} key={index}>
              <div className={classes.configurationItemsContainer}>
                <div className={clsx(classes.configurationTextField, classes.withMinimalWidth)}>
                  <TextField
                    data-cy="code"
                    error={reasonCode.code === undefined}
                    InputLabelProps={{ shrink: true }}
                    label={t('ConfigurationsScreen.code').toUpperCase()}
                    onChange={(event) => handleInputChange(event, index)}
                    name="code"
                    required
                    value={reasonCode.code}
                  />
                </div>
                <div
                  className={clsx(
                    classes.sideTextField,
                    classes.configurationTextField,
                    classes.labelInputWidth,
                  )}
                >
                  <TextField
                    data-cy="label"
                    InputLabelProps={{ shrink: true }}
                    label={t('ConfigurationsScreen.label').toUpperCase()}
                    onChange={(event) => handleInputChange(event, index)}
                    name="label"
                    required
                    value={reasonCode.label}
                  />
                </div>
                <div className={clsx(classes.sideTextField, classes.configurationTextField)}>
                  <Box style={{ display: 'flex', flexDirection: 'column' }}>
                    <InputLabel htmlFor="relatesTo" className={clsx(classes.likeLabel)}>
                      <span>{t('ConfigurationsScreen.relatesTo').toUpperCase()}</span>
                    </InputLabel>
                    <StyledSelect
                      id="relatesTo"
                      multiple
                      value={reasonCode.relatesTo}
                      onChange={(event) => handleSelectChange(event, 'relatesTo', index)}
                      renderValue={(selected: any) => handleRelatesToRender(selected)}
                      label={t('ConfigurationsScreen.relatesTo').toUpperCase()}
                      labelId="relatesTo-label"
                    >
                      {DISPUTE_REASON_CODES_RELATES_TO.map((item) => (
                        <MenuItem value={item.value} key={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  </Box>
                </div>
                <div className={clsx(classes.sideTextField, classes.configurationTextField)}>
                  <Box style={{ display: 'flex', flexDirection: 'column', width: '100px' }}>
                    <InputLabel htmlFor="my-select" className={clsx(classes.likeLabel)}>
                      <span>{t('ConfigurationsScreen.types').toUpperCase()}</span>
                    </InputLabel>
                    <StyledSelect
                      id="my-select"
                      multiple
                      value={reasonCode.types}
                      onChange={(event) => handleSelectChange(event, 'types', index)}
                      renderValue={(selected: any) => handleTypesRender(selected)}
                      label={t('ConfigurationsScreen.types').toUpperCase()}
                      labelId="my-select-label"
                    >
                      {DISPUTE_REASON_CODES_TYPES.map((item) => (
                        <MenuItem value={item.value} key={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  </Box>
                </div>
                <div
                  className={clsx(
                    classes.sideTextField,
                    classes.configurationTextField,
                    classes.withMinWidth,
                  )}
                >
                  <Switch
                    className={clsx(classes.switchInput)}
                    onChange={(event) => handleInputChange(event, index)}
                    name="isEanRequired"
                    checked={reasonCode.isEanRequired}
                    label={(
                      <LabelWrapper className={clsx(classes.likeLabel)}>
                        <span className={clsx(classes.labelSpan)}>
                          {t('ConfigurationsScreen.isEanRequired').toUpperCase()}
                        </span>
                      </LabelWrapper>
                    )}
                    labelPlacement="top"
                  />
                </div>
                <div
                  className={clsx(
                    classes.sideTextField,
                    classes.configurationTextField,
                    classes.withMinWidth,
                  )}
                >
                  <Switch
                    className={clsx(classes.switchInput)}
                    onChange={(event) => handleInputChange(event, index)}
                    name="isMediaRequired"
                    checked={reasonCode.isMediaRequired}
                    label={(
                      <LabelWrapper className={clsx(classes.likeLabel)}>
                        <span className={clsx(classes.labelSpan)}>
                          {t('ConfigurationsScreen.isMediaRequired').toUpperCase()}
                        </span>
                      </LabelWrapper>
                    )}
                    labelPlacement="top"
                  />
                </div>
              </div>
              <Button
                data-cy="deleteButton"
                onClick={(): void => {
                  handleDelete(index)
                }}
              >
                <DeleteIcon />
              </Button>
            </div>
          ))}
        </div>
      </>
    </Card>
  )
}

export default DisputeReasonsCard
