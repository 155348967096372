import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@material-ui/core/styles'
import RefreshIcon from '@material-ui/icons/Refresh'
import { IconButton } from '@material-ui/core'
import {
  ArgumentAxis,
  ValueAxis,
  BarSeries,
  Chart,
  Tooltip,
  Title,
  Legend,
  PieSeries,
} from '@devexpress/dx-react-chart-material-ui'
import { Animation, EventTracker, ValueScale, DomainItems } from '@devexpress/dx-react-chart'
import { connectProps } from '@devexpress/dx-react-core'
import moment from 'moment'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { Skeleton } from '@material-ui/lab'

import { FiltersContext } from 'store/FiltersContext'
import Export from 'components/Chart/Export'
import {
  CarrierFilter,
  DeliveryTypeFilter,
  DriverFilter,
  SiteFilter,
} from 'components/Inputs/ListFilter'
import ChartProgress from 'components/Chart/ChartProgress'
import { FilterKey, Filter } from 'constants/filters'
import DateRangePicker from 'components/Inputs/DateRangePicker'
import { FeedbackContext } from 'store/FeedbackContext'
import StatsApi from 'api/stats'
import { isIError } from 'api/types'
import { ICustomerRatingStats } from 'interfaces/ICustomerRatingStats'
import { IChartData } from 'interfaces/IChartData'
import { DateType } from 'constants/constants'
import StyledPaper from 'screens/ReportingCategory/components/StyledPaper'
import ExtendedDateFilters from 'components/Table/ExtendedDateFilters'
import { FiltersContainer, TopFiltersContainer } from 'components/Layout'
import { getChartTitle } from 'utils/extendedDateFilter'
import useStyles from './styles'
import TooltipArrow from '../components/TooltipArrow'
import TooltipSheet from '../components/TooltipSheet'
import VerticalLabel from '../components/VerticalLabel'
import CustomBarChartLegend from './components/CustomBarChartLegend'
import CustomPieChartLegend from './components/CustomPieChartLegend'
import CustomPieChartTooltipContent from './components/CustomPieChartTooltipContent'

const barContainerId = 'customer-rating-evolution-reporting'
const pieContainerId = 'customer-ratings-distribution-reporting'
const filterKey = FilterKey.customerRatingReporting

const CustomerRatingReportingScreen = (): JSX.Element => {
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  const { setFilter, resetFilters, filters } = useContext(FiltersContext)
  const { openErrorSnack, toggleLoader } = useContext(FeedbackContext)
  const startDateFilter = filters[filterKey][Filter.startDate] as string
  const endDateFilter = filters[filterKey][Filter.endDate] as string
  const deliveryTypesFilter = filters[filterKey][Filter.deliveryTypes] as string[]
  const sitesFilter = filters[filterKey][Filter.sites] as string[]
  const carriersFilter = filters[filterKey][Filter.carriers] as string[]
  const driversFilter = filters[filterKey][Filter.drivers] as string[]
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [stats, setStats] = useState<ICustomerRatingStats>()
  const [pieChartData, setPieChartData] = useState<IChartData[]>([])
  const [selectedDateFilterShortcut, setSelectedDateFilterShortcut] = useState('')

  const getStats = async (
    startDate?: string,
    endDate?: string,
    deliveryTypes?: string[],
    warehouseIds?: string[],
    carrierIds?: string[],
    driverIds?: string[],
  ): Promise<void> => {
    toggleLoader(true)
    setIsLoading(true)
    const res = await StatsApi.getCustomerRatingStats(
      startDate,
      endDate,
      deliveryTypes,
      warehouseIds,
      carrierIds,
      driverIds,
    )
    if (isIError(res)) {
      openErrorSnack(res.error.message)
    } else {
      setStats(res)
      const { ratingsCount } = res
      const data: IChartData[] = []
      Object.keys(ratingsCount).forEach((key) => {
        data.push({
          label: key,
          value: ratingsCount[key],
        })
      })
      setPieChartData(data)
    }
    setIsLoading(false)
    toggleLoader(false)
  }

  useEffect(() => {
    getStats(
      startDateFilter,
      endDateFilter,
      deliveryTypesFilter,
      sitesFilter,
      carriersFilter,
      driversFilter,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    startDateFilter,
    endDateFilter,
    deliveryTypesFilter,
    sitesFilter,
    carriersFilter,
    driversFilter,
  ])

  const EnhancedBarChartLegend = connectProps(CustomBarChartLegend, () => ({
    totalAvg: stats?.totalAverage,
    totalNumber: stats?.numberOfRatings,
  }))
  const EnhancedPieChartTooltipContent = connectProps(CustomPieChartTooltipContent, () => ({
    total: stats?.numberOfRatings,
  }))

  function resetIsLastThirty() {
    setSelectedDateFilterShortcut('')
  }

  const resetLocalFilters = (): void => resetFilters(filterKey)

  const handleStartChange = (startValue: MaterialUiPickersDate): void => {
    setFilter(filterKey, Filter.startDate, moment(startValue).toISOString())
    resetIsLastThirty()
  }

  const handleEndChange = (endValue: MaterialUiPickersDate): void => {
    setFilter(filterKey, Filter.endDate, moment(endValue).toISOString())
    resetIsLastThirty()
  }

  const setEndDate = (value: string): void => {
    setFilter(filterKey, Filter.endDate, value)
  }

  const setStartDate = (value: string): void => {
    setFilter(filterKey, Filter.startDate, value)
  }

  const fnLast30weeks = () => {
    setSelectedDateFilterShortcut('last30weeks')
  }

  const fnLast30days = () => {
    setSelectedDateFilterShortcut('last30days')
  }

  const chartTitleDistribution = getChartTitle(
    'CustomerRatingReportingScreen.ratingsDistribution',
    selectedDateFilterShortcut,
  )
  const chartTitleEvolution = getChartTitle(
    'CustomerRatingReportingScreen.ratingEvolution',
    selectedDateFilterShortcut,
  )

  return (
    <>
      <TopFiltersContainer className={classes.filterContainer}>
        <FiltersContainer>
          <IconButton data-cy="initializeButton" onClick={resetLocalFilters}>
            <RefreshIcon />
          </IconButton>
          <DateRangePicker
            startDate={startDateFilter}
            endDate={endDateFilter}
            onChangeStart={handleStartChange}
            onChangeEnd={handleEndChange}
            hideShiftPicker
            dateType={DateType.Date}
            maxStartDate={moment().toISOString()}
            maxEndDate={moment().toISOString()}
          />
          <DeliveryTypeFilter
            handleChange={(values: string[]): void => {
              setFilter(filterKey, Filter.deliveryTypes, values)
            }}
            ids={deliveryTypesFilter}
            dataCy="deliveryPicker"
          />
          <SiteFilter
            handleChange={(siteValues: string[]): void => {
              setFilter(filterKey, Filter.sites, siteValues)
            }}
            ids={sitesFilter}
            placeholder={t('CustomerRatingReportingScreen.site')}
            dataCy="sitePicker"
            isUnselectAllowed={false}
          />
          <CarrierFilter
            handleChange={(carrierValues: string[]): void => {
              setFilter(filterKey, Filter.carriers, carrierValues)
              setFilter(filterKey, Filter.drivers, [])
            }}
            ids={carriersFilter}
            dataCy="carrierPicker"
            siteIds={sitesFilter}
            filterKey={filterKey}
          />
          <DriverFilter
            handleChange={(driverValues: string[]): void => {
              setFilter(filterKey, Filter.drivers, driverValues)
            }}
            siteIds={sitesFilter}
            ids={driversFilter}
            carrierIds={carriersFilter}
            filterKey={filterKey}
          />
        </FiltersContainer>
      </TopFiltersContainer>
      <ExtendedDateFilters
        fnLast30days={fnLast30days}
        fnLast30weeks={fnLast30weeks}
        last30weeks
        last30days
        setEndDate={setEndDate}
        setStartDate={setStartDate}
      />
      {stats && !isLoading ? (
        <>
          <StyledPaper id={barContainerId}>
            <Chart
              data-cy="customerRatingEvolutionChart"
              data={stats.weeklyRatingsAverages.map((weeklyRatingsAverage) => ({
                ratingsAvg: Math.round(weeklyRatingsAverage.average * 100) / 100,
                week: `${weeklyRatingsAverage.startDate.substr(
                  0,
                  5,
                )}-${weeklyRatingsAverage.endDate.substr(0, 5)}`,
              }))}
            >
              <Title text={chartTitleEvolution} />
              <Animation />
              <ValueScale name="ratingAverages" modifyDomain={(): DomainItems => [0, 5]} />
              <ArgumentAxis labelComponent={VerticalLabel} showTicks={false} showLine={false} />
              <ValueAxis scaleName="ratingAverages" showLine showTicks />
              <BarSeries
                name="ratingAverages"
                valueField="ratingsAvg"
                argumentField="week"
                scaleName="ratingAverages"
                color={theme.color.primary}
              />
              <EventTracker />
              <Legend position="bottom" rootComponent={EnhancedBarChartLegend} />
              <Tooltip sheetComponent={TooltipSheet} arrowComponent={TooltipArrow} />
              <Export
                dataCy="exportRatingEvolutionButton"
                containerId={barContainerId}
                fileName={barContainerId}
              />
            </Chart>
          </StyledPaper>
          <StyledPaper id={pieContainerId}>
            <Chart data-cy="ratingsCountChart" data={pieChartData}>
              <PieSeries valueField="value" argumentField="label" />
              <Title text={chartTitleDistribution} />
              <Legend position="bottom" rootComponent={CustomPieChartLegend} />
              <EventTracker />
              <Tooltip contentComponent={EnhancedPieChartTooltipContent} />
              <Export
                dataCy="exportRatingsCountButton"
                containerId={pieContainerId}
                fileName={pieContainerId}
              />
            </Chart>
          </StyledPaper>
        </>
      ) : (
        <>
          <ChartProgress count={24} />
          <Skeleton variant="circle" className={classes.pieSkeleton} />
        </>
      )}
    </>
  )
}

export default CustomerRatingReportingScreen
