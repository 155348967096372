import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { IPlanTemplate } from 'interfaces/IPlanTemplate'
import { IIdParam } from 'interfaces/IIdParam'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import PlanTemplatesProvider, { PlanTemplatesConsumer } from 'store/PlanTemplatesContext'
import FormAction from 'components/Button/FormAction'
import { ContentConsumer } from 'store/ContentContext'
import PlanTemplateInfoCard from './PlanTemplateInfoCard'
import PlanTemplateActivityCard from './PlanTemplateActivityCard'
import PlanTemplateVehicleCard from './PlanTemplateVehicleCard'

interface IProps {
  planTemplateDetails?: IPlanTemplate
  createSuccess?: boolean
  updateSuccess?: boolean
  createPlanTemplate?: Function
  getPlanTemplateDetails?: Function
  updatePlanTemplate?: Function
  refreshPlanTemplates?: () => void
}

const PlanTemplateFormScreen = ({
  updateSuccess,
  createSuccess,
  createPlanTemplate,
  getPlanTemplateDetails,
  updatePlanTemplate,
  planTemplateDetails,
  refreshPlanTemplates,
}: IProps): JSX.Element => {
  const [planTemplate, setPlanTemplate] = useState<Partial<IPlanTemplate>>()
  const history = useHistory()
  const { id } = useParams<IIdParam>()

  useEffect(() => {
    if (id && getPlanTemplateDetails) {
      getPlanTemplateDetails(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    setPlanTemplate({ ...planTemplateDetails })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planTemplateDetails])

  useEffect(() => {
    if ((createSuccess || updateSuccess) && refreshPlanTemplates) {
      history.push(ROUTES_PATH.planTemplateList)
      refreshPlanTemplates()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createSuccess, updateSuccess])

  const handlePlanTemplateChange = (planTemplateInfo: IPlanTemplate): void => {
    setPlanTemplate((prevPlanTemplate) => ({
      ...prevPlanTemplate,
      ...planTemplateInfo,
    }))
  }

  const handleSaveClick = async (): Promise<void> => {
    if (id) {
      if (updatePlanTemplate) {
        await updatePlanTemplate(id, planTemplate)
      }
    } else {
      if (createPlanTemplate) {
        await createPlanTemplate(planTemplate)
      }
    }
  }

  const handleCancelClick = (): void => {
    history.push(ROUTES_PATH.planTemplateList)
  }

  return (
    <>
      <PlanTemplateInfoCard
        key={`${planTemplate && planTemplate.id}-info`}
        planTemplate={planTemplate}
        onPlanTemplateChange={handlePlanTemplateChange}
      />
      <PlanTemplateActivityCard
        key={`${planTemplate && planTemplate.id}-activity`}
        onPlanTemplateChange={handlePlanTemplateChange}
        planTemplate={planTemplate}
      />
      <PlanTemplateVehicleCard
        planTemplate={planTemplate}
        key={`${planTemplate && planTemplate.id}-vehicle`}
        onPlanTemplateChange={handlePlanTemplateChange}
      />
      <FormAction onSaveClick={handleSaveClick} onCancelClick={handleCancelClick} />
    </>
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <PlanTemplatesProvider>
    <ContentConsumer>
      {(contentCtx): JSX.Element => (
        <PlanTemplatesConsumer>
          {(planTemplatesCtx): JSX.Element => (
            <PlanTemplateFormScreen
              updateSuccess={planTemplatesCtx.updateSuccess}
              createSuccess={planTemplatesCtx.createSuccess}
              updatePlanTemplate={planTemplatesCtx.updatePlanTemplate}
              getPlanTemplateDetails={planTemplatesCtx.getPlanTemplateDetails}
              planTemplateDetails={planTemplatesCtx.planTemplateDetails}
              createPlanTemplate={planTemplatesCtx.createPlanTemplate}
              refreshPlanTemplates={contentCtx.refreshPlanTemplates}
              {...props}
            />
          )}
        </PlanTemplatesConsumer>
      )}
    </ContentConsumer>
  </PlanTemplatesProvider>
)
