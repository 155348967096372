import React from 'react'

import { ITenant } from 'interfaces/ITenant'
import TenantsTable from 'components/Table/TenantsTable'
import { COLUMS_MODEL_TENANTS_LIST } from 'constants/table'
import { IError } from 'api/types'
import TenantsProvider, { TenantsConsumer } from 'store/TenantsContext'

interface IProps {
  tenants: ITenant[]
  error?: IError
}

const TenantsListScreen = ({ tenants }: IProps): JSX.Element => (
  <TenantsTable tenants={tenants} columns={COLUMS_MODEL_TENANTS_LIST} />
)

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <TenantsProvider>
    <TenantsConsumer>
      {(ctx): JSX.Element => <TenantsListScreen tenants={ctx.tenants} {...props} />}
    </TenantsConsumer>
  </TenantsProvider>
)
