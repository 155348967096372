import { useState, useCallback } from 'react'

type ReturnType = [boolean, () => void]

export const useToggle = (defaultValue = false): ReturnType => {
  const [value, setValue] = useState<boolean>(defaultValue)

  const toggle = useCallback(() => {
    setValue(!value)
  }, [value])

  return [value, toggle]
}

export default useToggle
