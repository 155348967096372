import React, { useState } from 'react'
import { MenuItem, Menu } from '@material-ui/core/'
import { useTranslation } from 'react-i18next'
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos'
import styled from 'styled-components'

import { getDayMonthFormat } from 'utils/dateFormat'
import { IPlanTemplate } from 'interfaces/IPlanTemplate'
import useStyles from './styles'

interface IProps {
  disabled: boolean
  date: Date
  dataCy: string
  onClick: (planTemplate: IPlanTemplate, date: Date) => void
  items: IPlanTemplate[]
}

const StyledMenuItem = styled(MenuItem)<{ $isSubMenuOpen: boolean }>`
  background-color: ${(props) => (props.$isSubMenuOpen ? 'rgba(255,255,255,0.08)' : 'transparent')};
`

function NestedMenuItem({ disabled, date, dataCy, onClick, items }: IProps): JSX.Element {
  const classes = useStyles()
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>): void =>
    setAnchorEl(event.currentTarget)

  const handleClose = (): void => setAnchorEl(null)

  return (
    <StyledMenuItem
      data-cy={dataCy}
      disabled={disabled}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleClose}
      $isSubMenuOpen={Boolean(anchorEl)}
    >
      <div className={classes.menuItemContainer}>
        <span>{t('PlanningScreen.plansOf', { date: getDayMonthFormat(date) })}</span>
        <ArrowForwardIos className={classes.menuItemArrow} />
      </div>
      <Menu
        id="sub-menu"
        style={{ pointerEvents: 'none' }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div style={{ pointerEvents: 'auto' }}>
          {items.map((item) => (
            <MenuItem key={item.label} onClick={() => onClick(item, date)}>
              {item.label}
            </MenuItem>
          ))}
        </div>
      </Menu>
    </StyledMenuItem>
  )
}

export default NestedMenuItem
