import React, { useContext } from 'react'

import { ExecutionContext } from 'store/ExecutionContext'
import StopsMarker, { IStopsMarker } from './StopsMarkers'

const ExecutionStopsMarkers = ({ tour, color, tourNumber, driver }: IStopsMarker): JSX.Element => {
  const { highlightedElement, setHighlightedElement } = useContext(ExecutionContext)

  return (
    <StopsMarker
      tour={tour}
      color={color}
      tourNumber={tourNumber}
      driver={driver}
      highlightedElement={highlightedElement}
      setHighlightedElement={setHighlightedElement}
      autoPan={false}
    />
  )
}

export default ExecutionStopsMarkers
