import React, { useMemo, useState, useEffect, ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, IconButton, TextField, Tooltip } from '@material-ui/core'
import { Edit, Clear, Check } from '@material-ui/icons'
import clsx from 'clsx'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

import { isDefined } from 'utils/functions'
import DateRangePicker from 'components/Inputs/DateRangePicker'
import Switch from 'components/Inputs/ToggleSwitch'
import { DateType } from 'constants/constants'
import moment from 'moment'
import SearchableSelect from 'components/Inputs/SearchableSelect'
import { ISearchableSelectOption } from 'interfaces/interfaces'
import { IAutocompleteResult } from 'interfaces/IAutocompleteResult'
import useStyles from './styles'

export type CardLineMetaData = {
  dateTimeMinimum?: Date
  dateTimeMaximum?: Date
}

export type CardLineSaveObject = {
  dateTimeMinimum?: string
  dateTimeMaximum?: string
}

export type CardLineProps = {
  label?: string
  value?: string | JSX.Element
  isMiniPadding?: boolean
  hidden?: boolean
  isEditable?: boolean
  type?: string
  isDisabled?: boolean
  isEditDisabled?: boolean
  withBorder?: boolean
  disabledMessage?: string
  onSave?: (value?: string) => Promise<void>
  metaData?: CardLineMetaData
  onSaveObject?: (data: CardLineSaveObject) => void
  dateTimeStartLabel?: string
  dateTimeEndLabel?: string
  dateTimeMinErrorMessage?: string
  dateTimeMaxErrorMessage?: string
  checked?: boolean
  isAbsoluteNumber?: boolean
  onSaveSwitch?: (checked: boolean) => Promise<void>
  isMultiSelect?: boolean
  selectValue?: string
  selectValues?: string[]
  selectOptions?: ISearchableSelectOption[]
  onSelectChange?: (
    e: React.ChangeEvent<{
      value: unknown
      name?: string | undefined
    }>,
  ) => void
  autocompleteValue?: string
  autoCompleteChoices?: IAutocompleteResult[]
  autoCompleteChoiceClick?: (value: IAutocompleteResult) => void
  onChange?: (e: string) => void
}

export default ({
  label,
  value,
  isMiniPadding = false,
  hidden = false,
  isEditable = false,
  type = 'text',
  isDisabled = false,
  isEditDisabled = false,
  disabledMessage = '',
  onSave,
  metaData,
  onSaveObject,
  dateTimeStartLabel,
  dateTimeEndLabel,
  dateTimeMinErrorMessage,
  dateTimeMaxErrorMessage,
  withBorder = true,
  checked,
  isAbsoluteNumber,
  onSaveSwitch,
  selectValue,
  selectValues,
  selectOptions,
  onSelectChange,
  isMultiSelect = false,
  autoCompleteChoices,
  autoCompleteChoiceClick,
  autocompleteValue,
  onChange,
}: CardLineProps): JSX.Element | null => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [newValue, setNewValue] = useState<string>()
  const [newChecked, setNewChecked] = useState<boolean>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [newDateTimeMin, setDateTimeMin] = useState<string>()
  const [newDateTimeMax, setDateTimeMax] = useState<string>()
  const [isError, setIsError] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>()

  const isDateTimeField = useMemo(
    (): boolean => type === 'DateTimeMinMax' && isDefined(metaData),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [type, metaData],
  )

  useEffect(() => {
    if (metaData?.dateTimeMaximum) {
      setDateTimeMax(metaData.dateTimeMaximum.toString())
    }
    if (metaData?.dateTimeMinimum) {
      setDateTimeMin(metaData.dateTimeMinimum.toString())
    }
  }, [metaData])

  const handleEditClick = () => {
    if (type === 'boolean') {
      setIsEditMode(true)
      clearError()
      setNewChecked(checked)
    } else if (typeof value === 'string') {
      setIsEditMode(true)
      clearError()
      setNewValue(value)
      if (type === 'DateTimeMinMax') {
        if (metaData?.dateTimeMaximum && metaData.dateTimeMinimum) {
          setDateTimeMin(metaData.dateTimeMinimum?.toString())
          setDateTimeMax(metaData.dateTimeMaximum?.toString())
        }
      }
    } else if (['autocomplete', 'select'].includes(type)) {
      setIsEditMode(true)
      clearError()
    }
  }

  const onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) return onChange(event.target.value)
    if (isAbsoluteNumber) {
      return setNewValue(Number(event.target.value).toString())
    }
    setNewValue(event.target.value)
  }

  const onSwitchChange = (_event: React.ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
    setNewChecked(isChecked)
  }

  const handleClear = () => {
    setNewValue('')
    setDateTimeMin('')
    setDateTimeMax('')
    clearError()
    setIsEditMode(false)
  }

  const handleSave = async () => {
    setIsLoading(true)
    if (type === 'boolean' && onSaveSwitch) {
      await onSaveSwitch(!!newChecked)
    } else if (isDateTimeField && onSaveObject) {
      await onSaveObject({
        dateTimeMinimum: newDateTimeMin,
        dateTimeMaximum: newDateTimeMax,
      } as CardLineSaveObject)
    } else if (onSave) {
      await onSave(newValue)
    }
    setIsLoading(false)
    setNewValue('')
    setDateTimeMax('')
    setDateTimeMin('')
    setIsEditMode(false)
  }

  const setError = (message: string) => {
    setIsError(true)
    setErrorMessage(message)
  }

  const clearError = () => {
    setIsError(false)
    setErrorMessage('')
  }

  const setDateTimeMinimum = (selectedDate: MaterialUiPickersDate) => {
    setDateTimeMin(selectedDate?.toISOString())
    if (newDateTimeMax) {
      if (moment(newDateTimeMax).isBefore(selectedDate)) {
        if (dateTimeMinErrorMessage) setError(dateTimeMinErrorMessage)
      } else {
        setDateTimeMin(selectedDate?.toISOString())
        if (isError) clearError()
      }
    }
  }

  const setDateTimeMaximum = (selectedDate: MaterialUiPickersDate) => {
    setDateTimeMax(selectedDate?.toISOString())
    if (newDateTimeMin) {
      if (moment(newDateTimeMin).isAfter(selectedDate)) {
        if (dateTimeMaxErrorMessage) setError(dateTimeMaxErrorMessage)
      } else {
        setDateTimeMax(selectedDate?.toISOString())
        if (isError) clearError()
      }
    }
  }

  const AddressChoices = (): JSX.Element => (
    <div className={classes.addressResultsContainer}>
      {autoCompleteChoices?.map((addressChoice, index) => (
        <div
          key={`${addressChoice.title}`}
          role="button"
          tabIndex={index}
          className={classes.addressLine}
          onClick={(): void => autoCompleteChoiceClick?.(addressChoice)}
          onKeyPress={(e): false | void =>
            e.keyCode === 13 && autoCompleteChoiceClick?.(addressChoice)}
        >
          <div className={classes.autocompleteAddress}>{addressChoice.address.label}</div>
        </div>
      ))}
    </div>
  )

  const renderItems = () => {
    if (isEditMode) {
      return (
        <>
          <Grid item xs>
            {isDateTimeField ? (
              <DateRangePicker
                startDate={newDateTimeMin}
                endDate={newDateTimeMax}
                dateType={DateType.DateTime}
                onChangeStart={(date: MaterialUiPickersDate): void => {
                  if (moment(date).isValid()) {
                    setDateTimeMinimum(date)
                  }
                }}
                onChangeEnd={(date: MaterialUiPickersDate): void => {
                  if (moment(date).isValid()) {
                    setDateTimeMaximum(date)
                  }
                }}
                hideShiftPicker
                isFullWidth
                startLabel={dateTimeStartLabel}
                endLabel={dateTimeEndLabel}
                startDataCy="dateTimeMinimum"
                endDataCy="dateTimeMaximum"
                isStartRequired
                isEndRequired
                isEndClearable={false}
              />
            ) : type === 'boolean' ? (
              <Switch
                checked={newChecked}
                label={label}
                justifyLeft
                onChange={onSwitchChange}
                disabled={isLoading}
              />
            ) : type === 'select' ? (
              <SearchableSelect
                label={label || ''}
                name="deliveryType"
                isMultiSelect={isMultiSelect}
                selectedValue={selectValue}
                selectedValues={selectValues}
                disabled={isLoading}
                customStyle={{ width: '15vw' }}
                options={selectOptions}
                onChange={(e) => {
                  if (onSelectChange) onSelectChange(e)
                }}
                required
              />
            ) : type === 'autocomplete' ? (
              <>
                <TextField
                  type={type}
                  label={label}
                  value={autocompleteValue}
                  onChange={onValueChange}
                  fullWidth
                  disabled={isLoading}
                />
                {autoCompleteChoices && !!autoCompleteChoices.length && <AddressChoices />}
              </>
            ) : (
              <TextField
                type={type}
                label={label}
                value={newValue}
                onChange={onValueChange}
                fullWidth
                disabled={isLoading}
              />
            )}
            {isError && <div style={{ color: 'red' }}>{errorMessage}</div>}
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={handleClear} disabled={isLoading}>
              <Clear />
            </IconButton>
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={handleSave} disabled={isLoading || isError}>
              <Check />
            </IconButton>
          </Grid>
        </>
      )
    }

    const renderValue = () => {
      if (type === 'boolean') {
        return checked ? t('OrdersScreen.yes') : t('OrdersScreen.no')
      }

      return value || '-'
    }

    return (
      <>
        {label && (
          <Grid item xs={6} className={classes.cardLineLabel}>
            {label.toUpperCase()}
          </Grid>
        )}
        <Grid item xs className={label ? '' : classes.cardLineLabel}>
          {renderValue()}
        </Grid>
        {isEditable && (
          <Grid item xs={1}>
            {isEditDisabled ? (
              <Tooltip title={disabledMessage}>
                <div>
                  <IconButton onClick={handleEditClick} disabled>
                    <Edit />
                  </IconButton>
                </div>
              </Tooltip>
            ) : (
              <IconButton onClick={handleEditClick}>
                <Edit />
              </IconButton>
            )}
          </Grid>
        )}
      </>
    )
  }

  if (hidden) return null
  return (
    <Grid
      container
      className={clsx(
        withBorder ? classes.cardLine : classes.withoutBorder,
        isMiniPadding && classes.miniPadding,
        isDisabled && classes.disabledLine,
      )}
      data-cy="row"
    >
      {renderItems()}
    </Grid>
  )
}
