import React from 'react'
import { Player } from '@lottiefiles/react-lottie-player'
import styled from 'styled-components'

import LoaderJson from 'assets/loader.json'

const LoaderContainer = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0px;
  animation: fadeIn 1s;
  z-index: 100;
`

const Loader = (): JSX.Element => (
  <LoaderContainer>
    <Player autoplay loop src={LoaderJson} style={{ height: '300px', width: '300px' }} />
  </LoaderContainer>
)

export default Loader
