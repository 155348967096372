import React from 'react'
import { useTranslation } from 'react-i18next'
import DeleteIcon from '@material-ui/icons/Delete'
import { FormControl, Button, TextField } from '@material-ui/core'
import clsx from 'clsx'

import useStyles from 'constants/cruStyles'
import Card from 'components/Card/Card'
import { IDeliveryType } from 'interfaces/IDeliveryType'
import { ITextChange } from 'interfaces/interfaces'
import FieldError from 'components/FieldError/FieldError'

interface IProps {
  deliveryTypes: IDeliveryType[]
  onDeliveryTypesChange: (deliveryTypes: IDeliveryType[]) => void
}

const DeliveryTypesCard = ({ deliveryTypes, onDeliveryTypesChange }: IProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleAdd = (): void => {
    const newId = Math.random()
    const errorCode = codeAlreadyExists(newId, undefined)
    const errorLabel = labelAlreadyExists(newId, '')
    onDeliveryTypesChange([
      ...deliveryTypes,
      {
        id: newId,
        code: undefined,
        label: '',
        active: true,
        errorCode,
        errorLabel,
        isNew: true,
      },
    ])
  }

  const handleDelete = (deliveryTypeId: number | undefined, isNew?: boolean): void => {
    onDeliveryTypesChange(
      isNew
        ? deliveryTypes.filter((deliverType) => deliverType.id !== deliveryTypeId)
        : deliveryTypes.map((deliveryType) => {
          if (deliveryType.id === deliveryTypeId) {
            return {
              ...deliveryType,
              active: false,
            }
          }
          return deliveryType
        }),
    )
  }

  const handleInputChange = (event: ITextChange, deliveryTypeId: number | undefined): void => {
    const { target } = event
    const { name, value } = target
    const parsedValue = value ? parseInt(value, 10) : undefined
    onDeliveryTypesChange(
      deliveryTypes.map((deliveryType) => {
        if (deliveryType.id === deliveryTypeId) {
          return {
            ...deliveryType,
            [name]: name === 'code' ? parsedValue : value,
            errorCode:
              name === 'code'
                ? codeAlreadyExists(deliveryTypeId, parsedValue)
                : deliveryType.errorCode,
            errorLabel:
              name === 'label'
                ? labelAlreadyExists(deliveryTypeId, value)
                : deliveryType.errorLabel,
          }
        }
        return deliveryType
      }),
    )
  }

  const codeAlreadyExists = (deliveryTypeId: number | undefined, value?: number): boolean =>
    deliveryTypes
      .filter((type) => type.active)
      .findIndex((type) => type.code === value && type.id !== deliveryTypeId) !== -1

  const labelAlreadyExists = (deliveryTypeId: number | undefined, value: string): boolean =>
    deliveryTypes
      .filter((type) => type.active)
      .findIndex(
        (type) => type.label.toLowerCase() === value.toLowerCase() && type.id !== deliveryTypeId,
      ) !== -1

  return (
    <Card
      dataCy="deliveryTypesCard"
      title={t('ConfigurationsScreen.deliveryTypes').toUpperCase()}
      contentClassName={classes.contentRef}
    >
      <>
        <div className={classes.btnSelect}>
          <FormControl variant="outlined">
            <Button data-cy="addButton" className={classes.modifyButton} onClick={handleAdd}>
              {t('ConfigurationsScreen.add').toUpperCase()}
            </Button>
          </FormControl>
        </div>
        <div className={classes.fieldsContainer}>
          {deliveryTypes
            .filter((deliveryType) => deliveryType.active)
            .map((deliveryType) => (
              <div
                data-cy="deliveryTypeItems"
                className={classes.configurationRow}
                key={deliveryType.id}
              >
                <div className={classes.configurationItemsContainer}>
                  <div className={classes.configurationTextField}>
                    <TextField
                      data-cy="code"
                      error={deliveryType.code === undefined || deliveryType.errorCode}
                      label={t('ConfigurationsScreen.code').toUpperCase()}
                      onChange={(event) => handleInputChange(event, deliveryType.id)}
                      name="code"
                      required
                      value={deliveryType.code}
                      type="number"
                      inputProps={{ min: 0 }}
                      disabled={deliveryType.isPredefined}
                    />
                    {deliveryType.errorCode && (
                      <FieldError>{t('ConfigurationsScreen.codeAlreadyExists')}</FieldError>
                    )}
                  </div>
                  <div className={clsx(classes.sideTextField, classes.configurationTextField)}>
                    <TextField
                      data-cy="label"
                      error={deliveryType.label.length === 0 || deliveryType.errorLabel}
                      label={t('ConfigurationsScreen.label').toUpperCase()}
                      onChange={(event) => handleInputChange(event, deliveryType.id)}
                      name="label"
                      required
                      value={
                        deliveryType.isPredefined
                          ? t(`tablesEntries.deliveryType.${deliveryType.code}`)
                          : deliveryType.label
                      }
                      disabled={deliveryType.isPredefined}
                    />
                    {deliveryType.errorLabel && (
                      <FieldError>{t('ConfigurationsScreen.labelAlreadyExists')}</FieldError>
                    )}
                  </div>
                </div>
                <Button
                  data-cy="deleteButton"
                  onClick={(): void => {
                    handleDelete(deliveryType.id, deliveryType.isNew)
                  }}
                  disabled={deliveryType.isPredefined}
                >
                  <DeleteIcon />
                </Button>
              </div>
            ))}
        </div>
      </>
    </Card>
  )
}

export default DeliveryTypesCard
