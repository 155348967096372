import React, { useContext, useState } from 'react'
import { Typography } from '@material-ui/core'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import CreatableSelect from 'react-select/creatable'
import { useTheme } from '@material-ui/core/styles'
import { OptionTypeBase } from 'react-select'

import useStyles from 'constants/cruStyles'
import { ROUTING_RULE_TYPES, RuleType, PERIMETER_MODES } from 'constants/constants'
import { IPlace } from 'interfaces/IRoutingRule'
import { ContentContext } from 'store/ContentContext'
import { IInputChange } from 'interfaces/IInputChange'
import { alphabeticSort } from 'utils/functions'
import { ControlComponent, getCreatableSelectStyle } from 'components/Inputs/CreatableSelect'
import { SectorSiteFilter } from 'components/Inputs/ListFilter'
import { ISector } from 'interfaces/ISector'
import { IWarehouse } from 'interfaces/interfaces'
import Select from './Select'
import { TextInput } from './Inputs'
import { RoutingRulesContext } from './RoutingStore'

interface IProps {
  title: string
  place?: IPlace
  onChange: (place: IPlace) => void
  isForSegment?: boolean
  isRequired?: boolean
  dataCy?: string
  dataCyType?: string
  isOrigin?: boolean
}

const RulePlaceForm = ({
  title,
  place,
  onChange,
  isForSegment,
  isRequired,
  dataCy,
  dataCyType,
  isOrigin,
}: IProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()

  const { sites, userSites } = useContext(ContentContext)

  let displayedSites: IWarehouse[]
  if (isOrigin && !isForSegment) {
    displayedSites = userSites
  } else {
    displayedSites = sites
  }

  const { editMode } = useContext(RoutingRulesContext)
  const siteOptions = place?.type
    ? displayedSites.map((site) => ({ value: site.id, label: site.name }))
    : []
  const sectorOptions = displayedSites
    .reduce((acc: ISector[], site: IWarehouse) => [...acc, ...(site.sectors || [])], [])
    .map((sector) => {
      const warehouseName = displayedSites.find((site) => site.id === sector.warehouseId)?.name || ''
      return { id: sector.id, label: `${warehouseName} - ${sector.label}` }
    })
  const [zipCodeInputText, setZipCodeInputText] = useState<string>('')

  const handlePlaceChange = (value: number | string | string[] | null, name: string): void => {
    onChange({
      ...place,
      [name]: value,
    })
  }

  const handleZipCodeKeyDown = (event: React.KeyboardEvent<HTMLElement>): void => {
    if (!zipCodeInputText) return
    const currentZipCodes = place?.zipCodes || []
    const newZipCodes = [...currentZipCodes, zipCodeInputText]
    const zipCodesSet = new Set(newZipCodes)
    const uniqueZipCodes = Array.from(zipCodesSet)
    // eslint-disable-next-line default-case
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        handlePlaceChange(uniqueZipCodes, 'zipCodes')
        setZipCodeInputText('')
        event.preventDefault()
    }
  }

  const handleZipCodeChange = (zipCodeValues: OptionTypeBase): void => {
    const currentZipCodes = place?.zipCodes || []
    if (currentZipCodes.length > 0) {
      handlePlaceChange(
        (zipCodeValues as OptionTypeBase[]).map((zipCodeValue) => zipCodeValue.value),
        'zipCodes',
      )
    }
  }

  return (
    <div data-cy={dataCy} className={classes.fieldGroupContainer}>
      <Typography className={classes.routingRuleSectionLabel}>{title}</Typography>
      <div className={classes.routingRuleFieldsContainer}>
        <Select
          value={place?.type}
          onChange={(value: number | string | null, name: string) => {
            handlePlaceChange(value, name)
          }}
          options={
            isForSegment
              ? [ROUTING_RULE_TYPES[0]]
              : isOrigin
                ? ROUTING_RULE_TYPES.filter(
                  (ruleType) =>
                    ruleType.value !== RuleType.ZipCode && ruleType.value !== RuleType.Sector,
                )
                  .map((type) => ({ ...type, name: type.label }))
                  .sort(alphabeticSort)
                : ROUTING_RULE_TYPES.map((type) => ({ ...type, name: type.label })).sort(
                  alphabeticSort,
                )
          }
          label={t('RoutingRulesScreen.type')}
          name="type"
          dataCy={dataCyType}
          isRequired={isOrigin}
        />
      </div>
      {place?.type && (
        <div className={clsx(classes.routingRuleFieldsContainer, classes.siteSelect)}>
          {place?.type === RuleType.ZipCode ? (
            <CreatableSelect
              styles={getCreatableSelectStyle(
                theme.palette.background.default,
                theme.color.label,
                theme.color.secondaryFieldBorder,
                theme.color.hover,
              )}
              className={classes.routingRuleZipCode}
              components={{ DropdownIndicator: null, Control: ControlComponent }}
              inputValue={zipCodeInputText}
              onInputChange={setZipCodeInputText}
              isClearable
              isMulti
              menuIsOpen={false}
              onChange={handleZipCodeChange}
              onKeyDown={handleZipCodeKeyDown}
              placeholder={t('PlanningScreen.zipCode')}
              value={place?.zipCodes?.map((zipCode) => ({ label: zipCode, value: zipCode })) || []}
              backgroundColor={theme.palette.background.default}
              color={theme.color.label}
              data-cy="zipCode"
              isDisabled={!editMode}
            />
          ) : place?.type === RuleType.Sector ? (
            <SectorSiteFilter
              dataCy="sectorPicker"
              placeholder={t('tablesEntries.sectors')}
              handleChange={(sectorValues: string[]): void => {
                handlePlaceChange(sectorValues, 'sectorIds')
              }}
              ids={place?.sectorIds || []}
              data={sectorOptions}
              customClass={classes.routingRuleSelect}
              isUnselectAllowed={false}
              required
              disabled={!editMode}
            />
          ) : (
            <Select
              value={place?.siteId}
              name="siteId"
              isRequired={
                isRequired && (place?.type === RuleType.Site || place?.type === RuleType.SiteArea)
              }
              onChange={(value: number | string | null, name: string) => {
                handlePlaceChange(value, name)
              }}
              label={title}
              options={siteOptions}
              dataCy="sitePicker"
            />
          )}
        </div>
      )}
      {place?.type === RuleType.SiteArea && !isForSegment && (
        <div className={clsx(classes.configurationRow, classes.siteSelect)}>
          <TextInput
            className={classes.singleField}
            label={t('RoutingRulesScreen.perimeter')}
            name="perimeter"
            type="number"
            variant="outlined"
            value={place?.perimeter || ''}
            onChange={({ target: { name, value } }: IInputChange) =>
              handlePlaceChange(+value, name)}
            required={isRequired}
            data-cy="perimeter"
          />
          <Select
            value={place?.perimeterMode}
            onChange={(value: number | string | null, name: string) => {
              handlePlaceChange(value, name)
            }}
            options={PERIMETER_MODES.map((mode) => ({ ...mode, name: mode.label })).sort(
              alphabeticSort,
            )}
            label={t('RoutingRulesScreen.mode')}
            name="perimeterMode"
            isRequired={isRequired}
            dataCy="perimeterModePicker"
          />
        </div>
      )}
    </div>
  )
}

export default RulePlaceForm
