import { createStyles, makeStyles } from '@material-ui/core/styles'

export default makeStyles(() =>
  createStyles({
    locationRelevanceAndIconWrapper: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: '4px',
    },
    locationRelevanceWrapper: {
      paddingTop: '2px',
    },
  }),
)
