import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField, FormControlLabel, Switch, Typography } from '@material-ui/core'

import { ITenant } from 'interfaces/ITenant'
import useStyles from 'constants/cruStyles'
import Card from 'components/Card/Card'
import {
  KlareoProduct,
  SESSION_TOKEN_EXPIRACY_DELAY,
  SET_MANUAL_PASSWORD,
  TENANT_LANGUAGE_OPTIONS,
} from 'constants/constants'
import SearchableSelect from 'components/Inputs/SearchableSelect'
import TenantProductsCard from './TenantProductsCard'

interface ITextChange {
  target: {
    value: string
    name: string
    type?: string
    checked?: boolean
  }
}

interface IProps {
  onTenantChange: Function
  tenant?: ITenant
}

const DEFAULT_PASSWORD_RULES = {
  charactersMinimum: 8,
  upperCaseMinimum: 1,
  lowerCaseMinimum: 1,
  digitsMinimum: 1,
  symbolsMinimum: 1,
}

const TenantInfoCard = ({ onTenantChange, tenant }: IProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [state, setState] = useState<ITenant>({
    id: tenant?.id,
    name: tenant?.name,
    language: tenant?.language,
    carrierCanChangeDriver: tenant?.carrierCanChangeDriver,
    deliveryDuration: tenant?.deliveryDuration,
    klareoProducts: tenant?.klareoProducts,
    planningPinsZoom: tenant?.planningPinsZoom,
    sessionTokenExpiryDelay: tenant?.sessionTokenExpiryDelay,
    passwordRules: tenant?.passwordRules ?? DEFAULT_PASSWORD_RULES,
    setManualPassword: tenant?.setManualPassword,
  })

  const handleInputChange = (event: ITextChange): void => {
    const { target } = event
    const { name, type } = target
    const value = type === 'checkbox' ? target.checked : target.value

    onTenantChange({ ...state, [name]: value })
    setState({ ...state, [name]: value })
  }

  const handleProductsChange = (productIds: KlareoProduct[]): void => {
    onTenantChange({ ...state, klareoProducts: productIds })
    setState({ ...state, klareoProducts: productIds })
  }

  const handleSelectChange = (
    event: React.ChangeEvent<{ value: unknown; name?: string }>,
  ): void => {
    const { target } = event
    const name = target.name as string
    const value = target.value as string

    onTenantChange({ ...state, [name]: value })
    setState({ ...state, [name]: value })
  }

  const handlePasswordRulesChange = (event: ITextChange): void => {
    const { target } = event
    const { name } = target
    const { value } = target
    const updatedPasswordRules = {
      ...state.passwordRules,
    }
    updatedPasswordRules[name] = value ? Number(value) : undefined
    onTenantChange({ ...state, passwordRules: updatedPasswordRules })
    setState({ ...state, passwordRules: updatedPasswordRules })
  }

  return (
    <>
      <Card dataCy="generalCard" title={t('TenantsScreen.general').toUpperCase()}>
        <div className={classes.fieldsContainer}>
          <TextField
            value={state.name || ''}
            onChange={handleInputChange}
            label={t('TenantsScreen.name').toUpperCase()}
            type="text"
            error={state.name === ''}
            name="name"
            required
            data-cy="lastName"
          />
          <SearchableSelect
            label={t('TenantsScreen.language').toUpperCase()}
            name="language"
            selectedValue={state.language || ''}
            options={TENANT_LANGUAGE_OPTIONS}
            onChange={handleSelectChange}
            dataCy="languageSelect"
          />
        </div>
      </Card>
      <Card dataCy="authenticationCard" title={t('TenantsScreen.authentication').toUpperCase()}>
        <div className={classes.fieldsContainer}>
          <div className={classes.subCard}>
            <Typography className={classes.subCardTitle}>
              {t('TenantsScreen.passwordRules').toUpperCase()}
            </Typography>
            <div className={classes.subCardList}>
              <TextField
                label={t('TenantsScreen.upperCaseMinimum').toUpperCase()}
                onChange={handlePasswordRulesChange}
                value={state.passwordRules?.upperCaseMinimum ?? ''}
                type="number"
                name="upperCaseMinimum"
                fullWidth
                InputProps={{
                  inputProps: { min: 0 },
                }}
                data-cy="upperCaseMinimum"
              />
            </div>
            <div className={classes.subCardList}>
              <TextField
                label={t('TenantsScreen.lowerCaseMinimum').toUpperCase()}
                onChange={handlePasswordRulesChange}
                value={state.passwordRules?.lowerCaseMinimum ?? ''}
                type="number"
                name="lowerCaseMinimum"
                fullWidth
                InputProps={{
                  inputProps: { min: 0 },
                }}
                data-cy="lowerCaseMinimum"
              />
            </div>
            <div className={classes.subCardList}>
              <TextField
                label={t('TenantsScreen.digitsMinimum').toUpperCase()}
                onChange={handlePasswordRulesChange}
                value={state.passwordRules?.digitsMinimum ?? ''}
                type="number"
                name="digitsMinimum"
                fullWidth
                InputProps={{
                  inputProps: { min: 0 },
                }}
                data-cy="digitsMinimum"
              />
            </div>
            <div className={classes.subCardList}>
              <TextField
                label={t('TenantsScreen.symbolsMinimum').toUpperCase()}
                onChange={handlePasswordRulesChange}
                value={state.passwordRules?.symbolsMinimum ?? ''}
                type="number"
                name="symbolsMinimum"
                fullWidth
                InputProps={{
                  inputProps: { min: 0 },
                }}
                data-cy="symbolsMinimum"
              />
            </div>
            <div className={classes.subCardList}>
              <TextField
                label={t('TenantsScreen.charactersMinimum').toUpperCase()}
                onChange={handlePasswordRulesChange}
                value={state.passwordRules?.charactersMinimum ?? ''}
                type="number"
                name="charactersMinimum"
                fullWidth
                required
                InputProps={{
                  inputProps: { min: 1 },
                }}
                data-cy="charactersMinimum"
              />
            </div>
          </div>
          <SearchableSelect
            label={t('TenantsScreen.setManualPassword.title').toUpperCase()}
            name="setManualPassword"
            selectedValue={state.setManualPassword || ''}
            options={SET_MANUAL_PASSWORD}
            onChange={handleSelectChange}
            dataCy="setManualPassword"
          />
          <SearchableSelect
            label={t('TenantsScreen.sessionTokenExpiryDelay').toUpperCase()}
            name="sessionTokenExpiryDelay"
            selectedValue={state.sessionTokenExpiryDelay || ''}
            options={SESSION_TOKEN_EXPIRACY_DELAY}
            onChange={handleSelectChange}
            dataCy="sessionTokenExpiryDelay"
          />
        </div>
      </Card>
      <TenantProductsCard
        products={state.klareoProducts || []}
        onProductsChange={handleProductsChange}
      />
      <Card dataCy="planningCard" title={t('TenantsScreen.planning').toUpperCase()}>
        <div className={classes.fieldsContainer}>
          <div data-cy="carrierCanChangeDriver">
            <FormControlLabel
              className={classes.switchContainer}
              control={(
                <Switch
                  color="primary"
                  checked={state.carrierCanChangeDriver ?? false}
                  onChange={handleInputChange}
                  name="carrierCanChangeDriver"
                />
              )}
              labelPlacement="start"
              label={t('TenantsScreen.carrierCanChangeDriver').toUpperCase()}
            />
          </div>
          <TextField
            label={t('TenantsScreen.deliveryDuration').toUpperCase()}
            onChange={handleInputChange}
            type="number"
            name="deliveryDuration"
            value={state.deliveryDuration ?? ''}
            error={
              state.deliveryDuration !== undefined && state.deliveryDuration.toString().length === 0
            }
            required
            data-cy="deliveryDuration"
          />
          <TextField
            label={t('TenantsScreen.planningPinsZoom').toUpperCase()}
            onChange={handleInputChange}
            value={state.planningPinsZoom ?? ''}
            type="number"
            name="planningPinsZoom"
            data-cy="planningPinsZoom"
          />
        </div>
      </Card>
      <Card dataCy="relayCard" title={t('TenantsScreen.relay').toUpperCase()}>
        <div className={classes.fieldsContainer} />
      </Card>
      <Card dataCy="relayMobileCard" title={t('TenantsScreen.relayMobile').toUpperCase()}>
        <div className={classes.fieldsContainer} />
      </Card>
    </>
  )
}

export default TenantInfoCard
