import React, { useEffect, useState, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import LinearProgressWithLabel from './LinearProgressWithLabel'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
})

interface IProps {
  averageTimeInSeconds?: number
}

function LinearWithValueLabel({ averageTimeInSeconds }: IProps): JSX.Element {
  const classes = useStyles()
  const { t } = useTranslation()

  const [progress, setProgress] = useState(0)
  const [elapsedTime, setElapsedTime] = useState(0)

  const averageTimeInMinutes = useMemo(
    () => 2 + (averageTimeInSeconds ? Math.ceil(averageTimeInSeconds / 60) : 0),
    [averageTimeInSeconds],
  )
  const averageTimeInSecondsWithBuffer = useMemo(
    () => 120 + (averageTimeInSeconds ?? 0),
    [averageTimeInSeconds],
  )

  // the interval value is proportional to the average time and should be in ms
  // ex: average time is 3 sec = (3 * 1000) ms
  // so (3 * 1000) is equivalent to 100% => 1% is equal to (3 * 1000) / 100 = 3 * 10
  // ex: average time is 3 min = (3 * 60 * 1000) ms
  // so (3 * 60 * 1000) is equivalent to 100% => 1% is equal to (3 * 60 * 1000) / 100 = 3 * 600
  const progressTimerInterval = useMemo(
    () =>
      averageTimeInSecondsWithBuffer < 60
        ? averageTimeInSecondsWithBuffer * 10
        : averageTimeInMinutes * 600,
    [averageTimeInSecondsWithBuffer, averageTimeInMinutes],
  )

  useEffect(() => {
    const progressTimer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 99 ? prevProgress : prevProgress + 1))
    }, progressTimerInterval)
    const elapsedTimer = setInterval(() => {
      setElapsedTime((prevTime) => prevTime + 1)
    }, 1000)
    return () => {
      clearInterval(progressTimer)
      clearInterval(elapsedTimer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const formatElapsedTime = (): string => {
    const minutes = Math.floor(elapsedTime / 60)
    const seconds = elapsedTime - minutes * 60

    return `${minutes} min ${seconds} sec`
  }

  return (
    <div className={classes.root}>
      <LinearProgressWithLabel value={progress} />
      {averageTimeInSecondsWithBuffer < 60 ? (
        <div>
          {t('PlanningScreen.optimizationAverageDurationSeconds', {
            time: averageTimeInSecondsWithBuffer,
          })}
        </div>
      ) : (
        <div>{t('PlanningScreen.optimizationAverageDuration', { time: averageTimeInMinutes })}</div>
      )}
      <div>
        {t('PlanningScreen.optimizationElapsedTime')}
        {formatElapsedTime()}
      </div>
    </div>
  )
}

export default LinearWithValueLabel
