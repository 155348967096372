import React from 'react'
import { IVisit } from 'interfaces/IOrders'
import { Popup } from 'react-leaflet'
import { dateToTimeFormat } from 'utils/dateFormat'

interface IProps {
  visit: IVisit
}

const MarkerPopup = ({ visit }: IProps): JSX.Element => {
  let timeSlot = ''
  if (visit.departureMinDate && visit.departureMaxDate) {
    timeSlot = `${dateToTimeFormat(visit.departureMinDate)}-${dateToTimeFormat(
      visit.departureMaxDate,
    )}`
  }
  return (
    <Popup autoPan={false}>
      <div>{visit.destinationAddress.label}</div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {timeSlot && (
          <div>
            <span style={{ fontWeight: 'bold' }}>[{timeSlot}]</span>
          </div>
        )}
      </div>
    </Popup>
  )
}

export default MarkerPopup
