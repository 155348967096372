import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import RefreshIcon from '@material-ui/icons/Refresh'
import { IconButton } from '@material-ui/core'
import { LatLngBounds, latLngBounds } from 'leaflet'
import { FeatureGroup, Tooltip, Marker } from 'react-leaflet'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { Filter, FilterKey } from 'constants/filters'
import AppConfigProvider from 'store/AppConfigContext'
import { FiltersContext } from 'store/FiltersContext'
import { FeedbackContext } from 'store/FeedbackContext'
import { isIError } from 'api/types'
import { ITourReplay } from 'interfaces/map'
import { FiltersContainer, TopFiltersContainer } from 'components/Layout'
import Map from 'components/Map'
import StopsMarkers from 'components/Map/StopsMarkers'
import COLORS from 'components/Map/LinesColors'
import Polyline from 'components/Map/Polyline'
import ToursApi from 'api/tours'
import { dateDefaultFormat } from 'utils/dateFormat'
import DateRangePicker from 'components/Inputs/DateRangePicker'
import { IDictEntry, SiteFilter, TourNumberFilter } from 'components/Inputs/ListFilter'
import { IDriverEventLocation } from 'interfaces/IDriverEventLocation'
import DriverEventMarker from 'components/Map/DriverEventMarker'
import ExtendedDateFilters from 'components/Table/ExtendedDateFilters'
import useStyles from './styles'
import FabMenu, { ReplayScreenMode } from './components/FabMenu'

const filterKey = FilterKey.replay

function ReplayScreen(): JSX.Element {
  const { setFilter, resetFilters, filters } = useContext(FiltersContext)
  const startDate = filters[filterKey][Filter.startDate] as string
  const endDate = filters[filterKey][Filter.endDate] as string
  const siteIds = filters[filterKey][Filter.sites] as string[]
  const tourNumbers = filters[filterKey][Filter.tourNumbers] as string[]
  const { openErrorSnack, toggleLoader } = useContext(FeedbackContext)
  const [tourNumbersDropdownData, setTourNumbersDropdownData] = useState<IDictEntry[]>([])
  const [tourReplay, setTourReplay] = useState<ITourReplay | null>()
  const [mapBounds, setMapBounds] = useState<LatLngBounds>(latLngBounds([]))
  const [displayMode, setDisplayMode] = useState<ReplayScreenMode>(
    ReplayScreenMode.DriverAndEventsLocations,
  )
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const featureGroupRef = useRef<any>(null)
  const isInitialMount = useRef(true)
  const styles = useStyles()
  const { t } = useTranslation()

  useEffect(() => {
    if (featureGroupRef && featureGroupRef.current) {
      setMapBounds(featureGroupRef.current.getBounds())
    }
  }, [tourReplay, displayMode])

  useEffect(() => {
    if (!isInitialMount.current) {
      setFilter(filterKey, Filter.tourNumbers, [])
    } else {
      isInitialMount.current = false
    }
    getTourNumbersForDropdown()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, siteIds])

  useEffect(() => {
    if (tourNumbers.length > 0) {
      getTourReplay(tourNumbers[0])
    } else {
      setTourReplay(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourNumbers])

  const toggleDisplayMode = (): void =>
    setDisplayMode((prev) => (prev === ReplayScreenMode.__LENGTH - 1 ? 0 : prev + 1))

  const driverEventLocations = useMemo((): IDriverEventLocation[] => {
    let result: IDriverEventLocation[] = []
    if (tourReplay?.tour) {
      tourReplay.tour.stops.forEach((stop) => {
        result = result.concat(stop.driverEventLocations || [])
      })
      result = result.concat(tourReplay.tour.driverEventLocations || [])
    }
    return result
  }, [tourReplay])

  async function getTourNumbersForDropdown(): Promise<void> {
    toggleLoader(true)
    const res = await ToursApi.getMinimalTours(startDate, endDate, siteIds)
    if (!isIError(res)) {
      setTourNumbersDropdownData(
        res.map((elem) => ({
          id: elem.tourId,
          name: elem.driver
            ? `${elem.tourNumber?.toString()} - ${elem.driver.name}`
            : elem.tourNumber?.toString(),
        })),
      )
    } else {
      openErrorSnack(res.error.message)
    }
    toggleLoader(false)
  }

  async function getTourReplay(tourId: string): Promise<void> {
    toggleLoader(true)
    const res = await ToursApi.getReplay(tourId)
    if (!isIError(res)) {
      setTourReplay(res)
    } else {
      openErrorSnack(res.error.message)
    }
    toggleLoader(false)
  }

  const resetLocalFilters = (): void => {
    resetFilters(filterKey)
  }

  const Filters = useMemo(
    (): JSX.Element => (
      <TopFiltersContainer>
        <FiltersContainer>
          <IconButton data-cy="initializeButton" onClick={resetLocalFilters}>
            <RefreshIcon />
          </IconButton>
          <DateRangePicker
            startDate={startDate}
            onChangeStart={(startValue): void => {
              setFilter(filterKey, Filter.startDate, moment(startValue).toISOString())
            }}
            endDate={endDate}
            onChangeEnd={(endValue): void => {
              setFilter(filterKey, Filter.endDate, moment(endValue).toISOString())
            }}
            hideShiftPicker
          />
          <SiteFilter
            dataCy="sitePicker"
            handleChange={(values: string[]): void => {
              setFilter(filterKey, Filter.sites, values)
            }}
            ids={siteIds}
            onlyOneValue
            isUnselectAllowed={false}
          />
          <TourNumberFilter
            handleChange={(values: string[]): void => {
              setFilter(filterKey, Filter.tourNumbers, values)
            }}
            ids={tourNumbers}
            dataCy="tourNumberPicker"
            onlyOneValue
            data={tourNumbersDropdownData}
            placeholder={t('ReplayScreen.tour')}
          />
        </FiltersContainer>
      </TopFiltersContainer>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [startDate, endDate, siteIds, tourNumbers, tourNumbersDropdownData, styles],
  )

  const setEndDate = (value: string): void => {
    setFilter(filterKey, Filter.endDate, value)
  }

  const setStartDate = (value: string): void => {
    setFilter(filterKey, Filter.startDate, value)
  }

  return (
    <>
      {Filters}
      <ExtendedDateFilters
        last7days
        yesterday
        today
        setEndDate={setEndDate}
        setStartDate={setStartDate}
      />
      <div data-cy="map" className={styles.mapContainer}>
        <Map
          mapName="replay"
          className={styles.map}
          width="100%"
          bounds={mapBounds.isValid() ? mapBounds : undefined}
        >
          {tourReplay?.tour && (
            <FeatureGroup ref={featureGroupRef}>
              <StopsMarkers
                tour={tourReplay.tour}
                color={COLORS[0]}
                tourNumber={tourReplay.tour.tourNumber}
                driver={tourReplay.tour.driver}
              />
              <Polyline tour={tourReplay.tour} color={COLORS[0]} />
              {(displayMode === ReplayScreenMode.DriverAndEventsLocations
                || displayMode === ReplayScreenMode.DriverLocations)
                && tourReplay.driverLocations.map((elem, index) => (
                  <Marker
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    position={{ lat: elem.latitude, lng: elem.longitude }}
                  >
                    <Tooltip>{dateDefaultFormat(elem.date)}</Tooltip>
                  </Marker>
                ))}
              {(displayMode === ReplayScreenMode.DriverAndEventsLocations
                || displayMode === ReplayScreenMode.EventsLocations)
                && driverEventLocations.map((driverEventLocation, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <DriverEventMarker key={index} driverEventLocation={driverEventLocation} />
                ))}
            </FeatureGroup>
          )}
        </Map>
      </div>
      <FabMenu displayMode={displayMode} toggleDisplayMode={toggleDisplayMode} />
    </>
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <AppConfigProvider>
    <ReplayScreen {...props} />
  </AppConfigProvider>
)
