import React from 'react'
import styled from 'styled-components'
import { ControlProps, OptionTypeBase, components, StylesConfig, GroupTypeBase } from 'react-select'

const FullDiv = styled.div({
  width: '100%',
})

export const getCreatableSelectStyle = (
  background?: string,
  inputColor?: string,
  borderColor?: string,
  borderColorHover?: string,
): StylesConfig<OptionTypeBase[], true, GroupTypeBase<OptionTypeBase[]>> => ({
  control: (base) => ({
    ...base,
    background,
    height: 56,
    boxShadow: 'none',
    borderRadius: '4px',
    cursor: 'text',
    border: `1px solid ${borderColor}`,
    '&:hover': {
      border: `1px solid ${borderColorHover}`,
    },
  }),
  placeholder: (base, state) => ({
    ...base,
    color: state.isFocused ? 'transparent' : inputColor,
    fontSize: 15,
  }),
  valueContainer: (base) => ({
    ...base,
    overflow: 'auto',
    height: 56,
  }),
  input: (base) => ({
    ...base,
    color: inputColor,
  }),
  clearIndicator: (base) => ({
    ...base,
    color: inputColor,
    cursor: 'pointer',
    '&:hover': {
      color: borderColorHover,
    },
  }),
  multiValue: (base) => ({
    ...base,
    background: 'none',
    border: `1px solid ${borderColor}`,
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: inputColor,
  }),
  multiValueRemove: (base) => ({
    ...base,
    background: 'none',
    color: inputColor,
    cursor: 'pointer',
    '&:hover': {
      background: 'none',
      color: borderColorHover,
    },
  }),
})
interface ILabelProps {
  color?: string
  backgroundColor?: string
}
const Label = styled.p<ILabelProps>(({ backgroundColor, color }) => ({
  fontSize: 12,
  position: 'absolute',
  top: '-19px',
  left: '10px',
  zIndex: 500,
  backgroundColor,
  color,
  paddingLeft: 4,
  paddingRight: 4,
}))
export const ControlComponent = ({
  isFocused,
  hasValue,
  selectProps,
  innerProps,
  ...props
}: ControlProps<OptionTypeBase[], true>): JSX.Element => (
  <FullDiv>
    {(isFocused || hasValue) && (
      <Label backgroundColor={selectProps.backgroundColor} color={selectProps.color}>
        {selectProps.placeholder}
      </Label>
    )}
    <components.Control
      isFocused={isFocused}
      hasValue={hasValue}
      selectProps={selectProps}
      {...props}
      // @ts-ignore
      innerProps={{ ...innerProps, 'data-cy': 'zipCode' }}
    />
  </FullDiv>
)
