import React, { useContext, useEffect, useState } from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import TabPanel from 'components/Tab/TabPanel'
import { IWarehouseTimeSlotForm } from 'interfaces/IWarehouseTimeSlot'
import WarehouseTimeSlotsProvider, {
  WarehouseTimeSlotsContext,
} from 'store/WarehouseTimeSlotsContext'
import { useHistory, useParams } from 'react-router-dom'
import { IIdParam } from 'interfaces/IIdParam'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import i18n from 'i18next'
import { ITab } from 'interfaces/ITab'
import FormAction from 'components/Button/FormAction'
import { getSortedDays } from 'utils/functions'
import WarehouseTimeSlotConfigurationScreen from './WarehouseTimeSlotForm/WarehouseTimeSlotConfigurationScreen'
import WarehouseTimeSlotDayScreen from './WarehouseTimeSlotForm/WarehouseTimeSlotDayScreen'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    width: '185px',
  },
  selectedTab: {
    color: theme.color.primary,
  },
  planningContainer: {
    width: '80%',
  },
}))

const generateWarehouseTimeSlotComponent = (
  warehouseTimeSlot: IWarehouseTimeSlotForm,
  handleWarehouseTimeSlotChange: (changedWarehouseTimeSlot: IWarehouseTimeSlotForm) => void,
): ITab[] => {
  const res = [
    {
      label: i18n.t('menu.subcategories.configurations'),
      component: (
        <WarehouseTimeSlotConfigurationScreen
          warehouseTimeSlot={warehouseTimeSlot}
          onWarehouseTimeSlotChange={handleWarehouseTimeSlotChange}
        />
      ),
    },
  ]
  getSortedDays().forEach((day) => {
    res.push({
      label: i18n.t(`scheduler.${day}`),
      component: (
        <WarehouseTimeSlotDayScreen
          day={day}
          warehouseTimeSlot={warehouseTimeSlot}
          onWarehouseTimeSlotChange={handleWarehouseTimeSlotChange}
        />
      ),
    })
  })
  return res
}

const WarehouseTimeSlotScreen = (): JSX.Element => {
  const classes = useStyles()
  const history = useHistory()
  const { id } = useParams<IIdParam>()

  const [currentMenuIndex, setCurrentMenuIndex] = useState<number>(0)
  const [warehouseTimeSlot, setWarehouseTimeSlot] = useState<Partial<IWarehouseTimeSlotForm>>({
    active: true,
    timeSlot: {
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
    },
  })

  const {
    createWarehouseTimeSlot,
    updateWarehouseTimeSlot,
    getDetails,
    updateSuccess,
    createSuccess,
    warehouseTimeSlotDetails,
  } = useContext(WarehouseTimeSlotsContext)

  useEffect(() => {
    if (createSuccess || updateSuccess) {
      history.push(ROUTES_PATH.warehouseTimeSlotList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createSuccess, updateSuccess])

  useEffect(() => {
    if (id && getDetails) {
      getDetails(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (warehouseTimeSlotDetails && id) {
      let timeSlot
      if (warehouseTimeSlotDetails?.timeSlot) {
        timeSlot = {}
        const days = Object.keys(warehouseTimeSlotDetails.timeSlot as Object)
        for (let index = 0; index < days.length; index += 1) {
          const day = days[index]
          timeSlot[day] = warehouseTimeSlotDetails.timeSlot[day].map((item) => ({
            ...item,
            id: Math.random(),
          }))
        }
      }
      setWarehouseTimeSlot({
        ...warehouseTimeSlotDetails,
        timeSlot,
        warehouseIds: warehouseTimeSlotDetails.warehouseIds
          ? warehouseTimeSlotDetails.warehouseIds
          : [],
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouseTimeSlotDetails])

  const handleChange = (_event: React.ChangeEvent<{}>, newMenuIndex: number): void => {
    setCurrentMenuIndex(newMenuIndex)
  }

  const handleWarehouseTimeSlotChange = (
    changedWarehouseTimeSlot: Partial<IWarehouseTimeSlotForm>,
  ): void => {
    setWarehouseTimeSlot(changedWarehouseTimeSlot)
  }

  const handleSaveClick = async (): Promise<void> => {
    if (id) {
      if (updateWarehouseTimeSlot) {
        await updateWarehouseTimeSlot(id, warehouseTimeSlot as IWarehouseTimeSlotForm)
      }
    } else {
      if (createWarehouseTimeSlot) {
        await createWarehouseTimeSlot(warehouseTimeSlot as IWarehouseTimeSlotForm)
      }
    }
  }

  const handleCancelClick = (): void => {
    history.push(ROUTES_PATH.warehouseTimeSlotList)
  }

  return (
    <>
      <div className={classes.root}>
        <Tabs
          orientation="vertical"
          value={currentMenuIndex}
          onChange={handleChange}
          aria-label="Vertical tabs"
          className={classes.tabs}
        >
          {generateWarehouseTimeSlotComponent(
            warehouseTimeSlot as IWarehouseTimeSlotForm,
            handleWarehouseTimeSlotChange,
          ).map((menu, index) => (
            <Tab
              className={currentMenuIndex === index ? classes.selectedTab : ''}
              label={menu.label}
              key={`vertical-tab-${menu.label}`}
              aria-controls={`vertical-tabpanel-${index}`}
              data-cy={menu.label}
            />
          ))}
        </Tabs>
        {generateWarehouseTimeSlotComponent(
          warehouseTimeSlot as IWarehouseTimeSlotForm,
          handleWarehouseTimeSlotChange,
        ).map((menu, index) => (
          <TabPanel
            key={`vertical-tab-panel-${menu.label}`}
            value={currentMenuIndex}
            index={index}
            className={classes.planningContainer}
          >
            {menu.component}
          </TabPanel>
        ))}
      </div>
      <FormAction onSaveClick={handleSaveClick} onCancelClick={handleCancelClick} />
    </>
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <WarehouseTimeSlotsProvider>
    <WarehouseTimeSlotScreen {...props} />
  </WarehouseTimeSlotsProvider>
)
