import React, { useContext, useEffect, useRef, useState, useMemo } from 'react'
import { Paper } from '@material-ui/core'
import styled from 'styled-components'
import Modal from '@material-ui/core/Modal'
import MaterialTable, { MTableHeader } from 'material-table'

import JobsApi from 'api/jobs'
import MUITableIcons from 'constants/MUITableIcons'
import { IJobImportError } from 'interfaces/IImportJob'
import { isIError } from 'api/types'
import { FeedbackContext } from 'store/FeedbackContext'
import Pagination from 'components/Table/Pagination'
import { COLUMNS_MODEL_JOB_IMPORT_ERRORS } from 'constants/table'
import AppConfigProvider, { AppConfigContext } from 'store/AppConfigContext'
import { getPageSize, savePageSize } from 'utils/localStorage'
import { scrollTop } from 'utils/functions'
import { getDisplayRowsCount } from 'utils/tableUtils'

const columnConfigName = 'jobImportErrorList'

const CustomPaper = styled(Paper)`
  position: absolute;
  min-width: 70%;
  max-height: 80%;
  overflow: scroll;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 500;
  padding: 20px 50px;
`

interface IProps {
  id: string
  onClose: () => void
  isOpen: boolean
}

const ErrorModal = ({ onClose, isOpen, id }: IProps): JSX.Element => {
  const isInitialMount = useRef(true)
  const [errors, setErrors] = useState<IJobImportError[]>([])
  const [count, setCount] = useState<number>(0)
  const [paginationOffset, setPaginationOffset] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(getPageSize)
  const { openErrorSnack } = useContext(FeedbackContext)
  const { tablesConfig } = useContext(AppConfigContext)

  const getErrors = async (offset = paginationOffset): Promise<void> => {
    const response = await JobsApi.getErrors(id, { offset, rowsPerPage })
    if (!isIError(response)) {
      setErrors(response.errors)
      setCount(response.count)
    } else {
      openErrorSnack(response.error.message)
    }
  }

  useEffect(() => {
    if (id) {
      getErrors()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (rowsPerPage !== getPageSize()) {
      savePageSize(rowsPerPage)
      scrollTop()
    }
    if (id && !isInitialMount.current) {
      getErrors(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage])

  useEffect(() => {
    if (!isInitialMount.current) {
      getErrors()
      scrollTop()
    } else {
      isInitialMount.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationOffset])

  const handleChangeRowsPerPage = (pageSize: number): void => {
    setPage(0)
    setRowsPerPage(pageSize)
    setPaginationOffset(0)
  }

  const displayRowsCount = useMemo(() => getDisplayRowsCount(errors), [errors])

  return (
    <Modal open={isOpen} onClose={onClose} disableAutoFocus>
      <CustomPaper>
        <MaterialTable
          key={displayRowsCount}
          data={errors}
          columns={COLUMNS_MODEL_JOB_IMPORT_ERRORS.map(
            (col) =>
              ({
                ...col,
                hidden: tablesConfig[columnConfigName]?.includes(col.title),
              } as unknown as MaterialTable<object>),
          )}
          options={{
            toolbar: false,
            pageSize: displayRowsCount,
            actionsColumnIndex: -1,
            headerStyle: {
              textTransform: 'none',
            },
          }}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          icons={MUITableIcons}
          components={{
            Header: (props): JSX.Element => <MTableHeader {...props} />,
            // eslint-disable-next-line react/prop-types, @typescript-eslint/no-unused-vars
            Pagination: ({ classes, ...props }): JSX.Element => (
              <Pagination
                {...props}
                page={page}
                setPage={setPage}
                columns={COLUMNS_MODEL_JOB_IMPORT_ERRORS}
                columnConfigName={columnConfigName}
                count={count}
                rowsPerPage={rowsPerPage}
                setPaginationOffset={setPaginationOffset}
              />
            ),
          }}
        />
      </CustomPaper>
    </Modal>
  )
}

export default (props: IProps): JSX.Element => (
  <AppConfigProvider>
    <ErrorModal {...props} />
  </AppConfigProvider>
)
