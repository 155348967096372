import React, { useState } from 'react'
import { FormControlLabel, Switch, TextField, Typography } from '@material-ui/core'
import Card from 'components/Card/Card'
import useStyles from 'constants/cruStyles'
import { IAtoptimaParams } from 'interfaces/interfaces'
import { useTranslation } from 'react-i18next'
import SearchableSelect from 'components/Inputs/SearchableSelect'
import { IOptimizerApp } from 'interfaces/IOptimizerApp'
import { OptimizerAppType } from 'constants/enum'
import { getApplicationVersionValue } from 'utils/functions'

interface ITextChange {
  target: {
    value: string
    name: string
    type?: string
    checked?: boolean
  }
}

interface IProps {
  atoptimaParams?: Partial<IAtoptimaParams>
  onChange: (optimParam: IAtoptimaParams) => void
  optimizerApps: IOptimizerApp[]
  isMultisite?: boolean
  isMultitour?: boolean
}

const WarehouseAtoptimaParamCard = ({
  atoptimaParams,
  onChange,
  optimizerApps,
  isMultisite,
  isMultitour,
}: IProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [param, setParam] = useState<Partial<IAtoptimaParams>>({
    appClassic: atoptimaParams?.appClassic,
    appMultisites: atoptimaParams?.appMultisites,
    appMultitours: atoptimaParams?.appMultitours,
    hourlyPrecedence: atoptimaParams?.hourlyPrecedence,
    uncoveredOperationsViolationPerUnit: atoptimaParams?.uncoveredOperationsViolationPerUnit,
    directPrecedenceViolationPerUnit: atoptimaParams?.directPrecedenceViolationPerUnit,
    weightViolationPerUnit: atoptimaParams?.weightViolationPerUnit,
    volumeViolationPerUnit: atoptimaParams?.volumeViolationPerUnit,
    timeViolationPerSecond: atoptimaParams?.timeViolationPerSecond,
  })

  const handleSelectAppVersionChange = (
    event: React.ChangeEvent<{ value: unknown; name?: string }>,
  ): void => {
    const { target } = event
    const name = target.name as string
    const value = target.value as string
    const realValue = value
      ? { name: value.split(' | ')[0], version: value.split(' | ')[1] }
      : undefined
    onChange({ ...param, [name]: realValue } as IAtoptimaParams)
    setParam({ ...param, [name]: realValue })
  }

  const handleAtoptimaParamInputChange = (event: ITextChange): void => {
    const { target } = event
    const { name } = target
    const value = target.type === 'checkbox' ? target.checked : target.value

    onChange({ ...param, [name]: value } as IAtoptimaParams)
    setParam({ ...param, [name]: value })
  }

  return (
    <Card title={t('SitesScreen.atoptimaParams').toUpperCase()}>
      <div className={classes.fieldsContainer}>
        <div className={classes.subCard}>
          <Typography className={classes.subCardTitle}>
            {t('SitesScreen.atoptimaParam.app').toUpperCase()}
          </Typography>
          <div className={classes.subCardSelectList}>
            <SearchableSelect
              dataCy="appClassic"
              label={t('SitesScreen.atoptimaParam.appClassic')}
              name="appClassic"
              selectedValue={
                param?.appClassic ? getApplicationVersionValue(param.appClassic) : undefined
              }
              options={
                optimizerApps
                  ? optimizerApps
                    .filter((app) => app.type === OptimizerAppType.Classic)
                    .map((app) => ({
                      id: getApplicationVersionValue(app),
                      name: getApplicationVersionValue(app),
                    }))
                  : []
              }
              onChange={handleSelectAppVersionChange}
            />
          </div>
          {isMultisite ? (
            <div className={classes.subCardSelectList}>
              <SearchableSelect
                dataCy="appMultisites"
                label={t('SitesScreen.atoptimaParam.appMultisites')}
                name="appMultisites"
                selectedValue={
                  param?.appMultisites ? getApplicationVersionValue(param.appMultisites) : undefined
                }
                options={
                  optimizerApps
                    ? optimizerApps
                      .filter((app) => app.type === OptimizerAppType.Multisites)
                      .map((app) => ({
                        id: getApplicationVersionValue(app),
                        name: getApplicationVersionValue(app),
                      }))
                    : []
                }
                onChange={handleSelectAppVersionChange}
              />
            </div>
          ) : null}
          {isMultitour ? (
            <div className={classes.subCardSelectList}>
              <SearchableSelect
                dataCy="appMultitours"
                label={t('SitesScreen.atoptimaParam.appMultitours')}
                name="appMultitours"
                selectedValue={
                  param?.appMultitours ? getApplicationVersionValue(param.appMultitours) : undefined
                }
                options={
                  optimizerApps
                    ? optimizerApps
                      .filter((app) => app.type === OptimizerAppType.Multitours)
                      .map((app) => ({
                        id: getApplicationVersionValue(app),
                        name: getApplicationVersionValue(app),
                      }))
                    : []
                }
                onChange={handleSelectAppVersionChange}
              />
            </div>
          ) : null}
        </div>
        <div>
          <FormControlLabel
            className={classes.switchContainer}
            control={(
              <Switch
                color="primary"
                checked={param.hourlyPrecedence}
                onChange={handleAtoptimaParamInputChange}
                name="hourlyPrecedence"
              />
            )}
            labelPlacement="start"
            label={t('SitesScreen.atoptimaParam.hourlyPrecedence').toUpperCase()}
          />
        </div>
        <TextField
          label={t('SitesScreen.atoptimaParam.uncoveredOperationsViolationPerUnit').toUpperCase()}
          type="number"
          onChange={handleAtoptimaParamInputChange}
          value={param.uncoveredOperationsViolationPerUnit || ''}
          name="uncoveredOperationsViolationPerUnit"
        />
        <TextField
          label={t('SitesScreen.atoptimaParam.directPrecedenceViolationPerUnit').toUpperCase()}
          type="number"
          onChange={handleAtoptimaParamInputChange}
          value={param.directPrecedenceViolationPerUnit || ''}
          name="directPrecedenceViolationPerUnit"
        />
        <TextField
          label={t('SitesScreen.atoptimaParam.weightViolationPerUnit').toUpperCase()}
          type="number"
          onChange={handleAtoptimaParamInputChange}
          value={param.weightViolationPerUnit || ''}
          name="weightViolationPerUnit"
        />
        <TextField
          label={t('SitesScreen.atoptimaParam.volumeViolationPerUnit').toUpperCase()}
          type="number"
          onChange={handleAtoptimaParamInputChange}
          value={param.volumeViolationPerUnit || ''}
          name="volumeViolationPerUnit"
        />
        <TextField
          label={t('SitesScreen.atoptimaParam.timeViolationPerSecond').toUpperCase()}
          type="number"
          onChange={handleAtoptimaParamInputChange}
          value={param.timeViolationPerSecond || ''}
          name="timeViolationPerSecond"
        />
      </div>
    </Card>
  )
}

export default WarehouseAtoptimaParamCard
