import React, { useState, useEffect, useContext } from 'react'
import { IconButton } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import RefreshIcon from '@material-ui/icons/Refresh'

import DateRangePicker from 'components/Inputs/DateRangePicker'
import { FiltersContainer, TopFiltersContainer } from 'components/Layout'
import { ITransportVolumeStat } from 'interfaces/ITransportVolumeStat'
import { FiltersContext } from 'store/FiltersContext'
import { ContentConsumer } from 'store/ContentContext'
import { ICarrier } from 'interfaces/interfaces'
import { OrdersConsumer } from 'store/OrdersContext'
import { CarrierFilter, SiteFilter } from 'components/Inputs/ListFilter'
import ChartProgress from 'components/Chart/ChartProgress'
import { FilterKey, Filter } from 'constants/filters'
import { BarChart } from 'screens/ReportingCategory/components/BarChart'
import { IAvgStatsTours } from 'interfaces/IAvgStatsTours'
import { addMissingZeros, addFirstCarrierKey } from 'utils/functions'
import ExtendedDateFilters from 'components/Table/ExtendedDateFilters'
import {
  getChartTitle,
  setDateFactory,
  setSelectedDateFilterShortcutFactory,
} from 'utils/extendedDateFilter'
import { IndicatorChart } from './IndicatorChart'
import useStyles from './styles'

interface IProps {
  transportVolumeStat?: ITransportVolumeStat
  avgStatsOfTours?: IAvgStatsTours
  getTransportVolumeStats?: (
    startDate: string,
    endDate: string,
    carrierIds?: string[],
    siteIds?: string[],
  ) => void
  getAvgStatsOfTours?: (
    startDate: string,
    endDate: string,
    carrierIds?: string[],
    siteIds?: string[],
  ) => void
  carriersList?: ICarrier[]
}

const tourMonitoring = 'tour-monitoring'
const deliveryMonitoring = 'delivery-monitoring'
const avgNbVisits = 'avg-nb-visits'
const avgNbKms = 'avg-nb-kms'
const avgNbMin = 'avg-nb-min'
const filterKey = FilterKey.transportVolume

const VOLUME_TRANSPORT_INDICATOR_MAX_DATE_RANGE_IN_MONTH = 3

const TransportVolumeIndicator = ({
  transportVolumeStat,
  avgStatsOfTours,
  getTransportVolumeStats,
  getAvgStatsOfTours,
  carriersList,
}: IProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { setFilter, resetFilters, filters } = useContext(FiltersContext)
  const startDate = filters[filterKey][Filter.startDate] as string
  const endDate = filters[filterKey][Filter.endDate] as string
  const siteIds = filters[filterKey][Filter.sites] as string[]
  const carriers = filters[filterKey][Filter.carriers] as string[]
  const [maxEndDate, setMaxEndDate] = useState<string>(
    moment()
      .add(VOLUME_TRANSPORT_INDICATOR_MAX_DATE_RANGE_IN_MONTH, 'months')
      .hours(0)
      .minutes(0)
      .toISOString(),
  )
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [selectedDateFilterShortcut, setSelectedDateFilterShortcut] = useState('')

  useEffect(() => {
    if (
      getTransportVolumeStats
      && getAvgStatsOfTours
      && moment(endDate).diff(moment(startDate), 'month')
        <= VOLUME_TRANSPORT_INDICATOR_MAX_DATE_RANGE_IN_MONTH
    ) {
      setIsLoading(true)
      getTransportVolumeStats(startDate, endDate, carriers, siteIds)
      getAvgStatsOfTours(startDate, endDate, carriers, siteIds)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, carriers, siteIds])

  useEffect(() => {
    setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transportVolumeStat])

  const handleStartDateChange = (date: MaterialUiPickersDate): void => {
    const endDateUpperLimit = moment(date).add(
      VOLUME_TRANSPORT_INDICATOR_MAX_DATE_RANGE_IN_MONTH,
      'months',
    )
    setMaxEndDate(endDateUpperLimit.toISOString())
    setFilter(filterKey, Filter.startDate, moment(date).toISOString())
    if (moment(endDate).isAfter(endDateUpperLimit) || !endDate) {
      setFilter(filterKey, Filter.endDate, endDateUpperLimit.toISOString())
    }
    setSelectedDateFilterShortcut('')
  }

  const resetLocalFilters = (): void => {
    setMaxEndDate(
      moment()
        .add(VOLUME_TRANSPORT_INDICATOR_MAX_DATE_RANGE_IN_MONTH, 'months')
        .hours(0)
        .minutes(0)
        .toISOString(),
    )
    resetFilters(filterKey)
  }

  const calculateAvgClientsPerHour = (avgTimeByVisit?: number): string => {
    if (!avgTimeByVisit) {
      return String(0)
    }

    return (60 / avgTimeByVisit).toFixed(2)
  }

  const { setEndDate, setStartDate } = setDateFactory(setFilter, filterKey)

  // eslint-disable-next-line max-len
  const { fnLast3months, fnLastMonth, fnLast7days, fnNext7days, fnThisMonth } = setSelectedDateFilterShortcutFactory(setSelectedDateFilterShortcut)

  const chartTitleTourMonitoring = getChartTitle(
    'TransportVolumeScreen.tourMonitoring',
    selectedDateFilterShortcut,
  )
  const chartTitleDeliveryMonitoring = getChartTitle(
    'TransportVolumeScreen.deliveryMonitoring',
    selectedDateFilterShortcut,
  )
  const chartTitleAvgVisitsByTour = getChartTitle(
    'TransportVolumeScreen.avgVisitsByTourTitle',
    selectedDateFilterShortcut,
  )
  const chartTitleAvgKmByVisit = getChartTitle(
    'TransportVolumeScreen.avgKmByVisitTitle',
    selectedDateFilterShortcut,
  )
  const chartTitleAvgTimeByVisit = getChartTitle(
    'TransportVolumeScreen.avgTimeByVisitTitle',
    selectedDateFilterShortcut,
  )

  return (
    <>
      <TopFiltersContainer>
        <FiltersContainer>
          <IconButton data-cy="initializeButton" onClick={resetLocalFilters}>
            <RefreshIcon />
          </IconButton>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onChangeStart={handleStartDateChange}
            onChangeEnd={(endValue: MaterialUiPickersDate): void => {
              setFilter(filterKey, Filter.endDate, moment(endValue).toISOString())
              setSelectedDateFilterShortcut('')
            }}
            maxEndDate={maxEndDate}
            hideShiftPicker
          />
          <SiteFilter
            handleChange={(siteValues: string[]): void => {
              setFilter(filterKey, Filter.sites, siteValues)
            }}
            ids={siteIds}
            placeholder={t('TransportVolumeScreen.site')}
            dataCy="sitePicker"
            isUnselectAllowed={false}
          />
          <CarrierFilter
            dataCy="carrierPicker"
            handleChange={(carrierValues: string[]): void => {
              setFilter(filterKey, Filter.carriers, carrierValues)
            }}
            ids={carriers}
            siteIds={siteIds}
            filterKey={filterKey}
          />
        </FiltersContainer>
      </TopFiltersContainer>
      <ExtendedDateFilters
        fnLast3months={fnLast3months}
        fnLastMonth={fnLastMonth}
        fnLast7days={fnLast7days}
        fnNext7days={fnNext7days}
        fnThisMonth={fnThisMonth}
        last3months
        lastMonth
        last7days
        next7days
        thisMonth
        setEndDate={setEndDate}
        setStartDate={setStartDate}
      />
      {isLoading && <ChartProgress count={24} />}
      {transportVolumeStat && transportVolumeStat.tourNbStats && !isLoading && (
        <IndicatorChart
          exportCy="exportTourButton"
          dataCy="tourMonitoringChart"
          key={`tour-stat-${transportVolumeStat.carriersList.length}`}
          data={addFirstCarrierKey(
            transportVolumeStat.tourNbStats,
            transportVolumeStat.carriersList[0],
          )}
          title={chartTitleTourMonitoring}
          carrierIds={transportVolumeStat.carriersList}
          carriersList={carriersList}
          containerId={tourMonitoring}
          exportName={tourMonitoring}
        />
      )}

      {isLoading && <ChartProgress count={24} />}
      {transportVolumeStat && transportVolumeStat.stopNbStats && !isLoading && (
        <IndicatorChart
          exportCy="exportDeliveryButton"
          dataCy="deliveryMonitoringChart"
          key={`stop-stat-${transportVolumeStat.carriersList.length}`}
          title={chartTitleDeliveryMonitoring}
          data={addFirstCarrierKey(
            transportVolumeStat.stopNbStats,
            transportVolumeStat.carriersList[0],
          )}
          carriersList={carriersList}
          carrierIds={transportVolumeStat.carriersList}
          containerId={deliveryMonitoring}
          exportName={deliveryMonitoring}
        />
      )}

      {isLoading && <ChartProgress count={24} />}
      {isLoading && <ChartProgress count={24} />}
      {isLoading && <ChartProgress count={24} />}
      {avgStatsOfTours && avgStatsOfTours.avgStats && !isLoading && (
        <>
          <BarChart
            exportCy="exportAvgNbVisitsByTourButton"
            dataCy="avgNbVisitsByTour"
            title={chartTitleAvgVisitsByTour}
            data={addMissingZeros(avgStatsOfTours.avgStats)}
            containerId={avgNbVisits}
            exportName={avgNbVisits}
            yKey="avgNbVisitsByTour"
            caption={`${t('TransportVolumeScreen.avgPeriod')}: ${
              avgStatsOfTours.periodStats.avgNbVisitsByTour?.toFixed(2) || 0
            }`}
            classCaption={classes.textLegend}
          />
          <BarChart
            exportCy="exportAvgKmByVisitButton"
            dataCy="avgKmByVisit"
            title={t(chartTitleAvgKmByVisit)}
            data={addMissingZeros(avgStatsOfTours?.avgStats)}
            containerId={avgNbKms}
            exportName={avgNbKms}
            yKey="avgKmByVisit"
            caption={`${t('TransportVolumeScreen.avgPeriod')}: ${
              avgStatsOfTours.periodStats.avgKmByVisit?.toFixed(2) || 0
            }`}
            classCaption={classes.textLegend}
            secondaryCaption={`${t('TransportVolumeScreen.totalKmOverPeriod')}: ${
              avgStatsOfTours.periodStats.totalKmOverPeriod?.toFixed(2) || 0
            }`}
          />
          <BarChart
            exportCy="exportAvgTimeByVisitButton"
            dataCy="avgTimeByVisit"
            title={chartTitleAvgTimeByVisit}
            data={addMissingZeros(avgStatsOfTours?.avgStats)}
            containerId={avgNbMin}
            exportName={avgNbMin}
            yKey="avgTimeByVisit"
            caption={`${t('TransportVolumeScreen.avgPeriod')}: ${
              avgStatsOfTours.periodStats.avgTimeByVisit?.toFixed(2) || 0
            }`}
            classCaption={classes.textLegend}
            secondaryCaption={`${t(
              'TransportVolumeScreen.avgClientsPerHour',
            )}: ${calculateAvgClientsPerHour(avgStatsOfTours.periodStats.avgTimeByVisit)}`}
          />
        </>
      )}
    </>
  )
}

export default (props: JSX.IntrinsicAttributes & IProps): JSX.Element => (
  <ContentConsumer>
    {(contentContext): JSX.Element => (
      <OrdersConsumer>
        {(ordersContext): JSX.Element => (
          <TransportVolumeIndicator
            transportVolumeStat={ordersContext.transportVolumeStat}
            getTransportVolumeStats={ordersContext.getTransportVolumeStats}
            avgStatsOfTours={ordersContext.avgStatsOfTours}
            getAvgStatsOfTours={ordersContext.getAvgStatsOfTours}
            carriersList={contentContext.carriers}
            {...props}
          />
        )}
      </OrdersConsumer>
    )}
  </ContentConsumer>
)
