import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      width: '50%',
      margin: 'auto',
    },
    header: {
      backgroundColor: theme.color.backgroundPaperTitle,
      color: theme.color.secondary,
      fontWeight: 'bold',
    },
    content: {
      padding: 0,
      paddingBottom: '0 !important',
      backgroundColor: theme.palette.background.default,
    },
  }),
)
