import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/analytics'

import { FIREBASE_CONFIG } from 'constants/env'

firebase.initializeApp(FIREBASE_CONFIG)
firebase.analytics()

export const FirebaseAuth = firebase.auth
