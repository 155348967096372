import { Paper } from '@material-ui/core'
import styled from 'styled-components'

const Container = styled(Paper)`
  min-width: 300px;
  background-color: slategrey;
  position: relative;
`

export default Container
