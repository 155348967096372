import { createStyles, makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) =>
  createStyles({
    imageIcon: {
      color: '#5987D3',
      textDecorationColor: '#5987D3',
      cursor: 'pointer',
    },
    icon: {
      color: theme.color.secondary,
      fontSize: 24,
      marginRight: 10,
    },
    image: {
      backgroundColor: '#FFF',
      width: 400,
    },
    imageLine: {
      marginTop: 20,
    },
  }),
)
