import { IWarehouse } from 'interfaces/interfaces'
import React, { useState, ReactNode, useContext, Dispatch, SetStateAction, useEffect } from 'react'

import { AuthContext } from 'store/AuthContext'
import { ContentContext } from './ContentContext'
import { FiltersContext } from './FiltersContext'

interface IDefaultSiteContext {
  defaultSiteId: string | null
  defaultSite: IWarehouse | null
  setDefaultSiteId: Dispatch<SetStateAction<string | null>>
  setDefaultSiteAndFilters: (defaultSite: string | undefined) => void
}

interface IProps {
  children: ReactNode
}

const DefaultSiteContext = React.createContext<IDefaultSiteContext>({} as IDefaultSiteContext)
const { Provider, Consumer } = DefaultSiteContext

const DefaultSiteProvider = ({ children }: IProps): JSX.Element => {
  const { isAuthLoading, user } = useContext(AuthContext)

  const [defaultSiteId, setDefaultSiteId] = useState<string | null>(null)
  const [defaultSite, setDefaultSite] = useState<IWarehouse | null>(null)
  const { setDefaultSitesFilter } = useContext(FiltersContext)
  const { sites } = useContext(ContentContext)

  useEffect(() => {
    if (!isAuthLoading && user?.sites.length) {
      setDefaultSiteAndFilters(user?.sites.find((site) => site.default)?.siteId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isAuthLoading, sites])

  const setDefaultSiteAndFilters = (defaultSiteIdToSet: string | undefined) => {
    setDefaultSiteId(defaultSiteIdToSet || null)
    setDefaultSite(sites.find((site) => site.id === defaultSiteIdToSet) || null)
    setDefaultSitesFilter(defaultSiteIdToSet ? [defaultSiteIdToSet] : [])
  }

  return (
    <Provider
      value={{
        defaultSiteId,
        defaultSite,
        setDefaultSiteId,
        setDefaultSiteAndFilters,
      }}
    >
      {children}
    </Provider>
  )
}

export default DefaultSiteProvider

export { Consumer as DefaultSiteConsumer, DefaultSiteContext }
