/* eslint-disable react/jsx-wrap-multilines */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField } from '@material-ui/core'

import { ContentConsumer } from 'store/ContentContext'
import ActiveSwitch from 'components/Inputs/ToggleSwitch'
import { IVehicleType } from 'interfaces/IVehicleType'
import { IVehicleProfile } from 'interfaces/interfaces'
import { DEFAULT_VEHICLE_TYPE_MAX_CLIENTS_COUNT } from 'constants/constants'
import SearchableSelect from 'components/Inputs/SearchableSelect'
import useStyles from 'constants/cruStyles'
import Card from 'components/Card/Card'

interface ITextChange {
  target: {
    value: string
    name: string
    type?: string
    checked?: boolean
  }
}

interface IProps {
  onVehicleTypeChange: Function
  vehicleType?: IVehicleType
  vehicleProfileOptions?: IVehicleProfile[]
}

function VehicleTypeInfoCard({
  onVehicleTypeChange,
  vehicleType,
  vehicleProfileOptions,
}: IProps): JSX.Element {
  const { t } = useTranslation()
  const classes = useStyles()
  const [state, setState] = useState<IVehicleType>({
    id: vehicleType?.id,
    label: vehicleType?.label,
    code: vehicleType?.code,
    active: vehicleType?.active,
    vehicleProfileId: vehicleType?.vehicleProfileId,
    capacity: vehicleType?.capacity || undefined,
    maxClientsPerTour: vehicleType?.id
      ? vehicleType?.maxClientsPerTour
      : DEFAULT_VEHICLE_TYPE_MAX_CLIENTS_COUNT,
  })

  const handleInputChange = (event: ITextChange): void => {
    const { target } = event
    const { name, type } = target
    const value = type === 'checkbox' ? target.checked : target.value

    onVehicleTypeChange({ ...state, [name]: value })
    setState({ ...state, [name]: value })
  }

  const handleSelectChange = (
    event: React.ChangeEvent<{ value: unknown; name?: string }>,
  ): void => {
    const { target } = event
    const name = target.name as string
    const value = target.value as string

    onVehicleTypeChange({ ...state, [name]: value })
    setState({ ...state, [name]: value })
  }

  return (
    <Card dataCy="infoCard" title={t('VehicleTypesScreen.info').toUpperCase()}>
      <>
        <div className={classes.fieldsContainer}>
          <TextField
            data-cy="label"
            error={state.label === ''}
            label={t('VehicleTypesScreen.label').toUpperCase()}
            onChange={handleInputChange}
            type="text"
            required
            name="label"
            value={state.label || ''}
          />
          <TextField
            data-cy="code"
            error={state.code === ''}
            label={t('VehicleTypesScreen.code').toUpperCase()}
            onChange={handleInputChange}
            type="text"
            required
            name="code"
            value={state.code || ''}
          />
          <TextField
            data-cy="capacity"
            error={state.capacity !== undefined && state.capacity.toString().length === 0}
            label={t('VehicleTypesScreen.capacity').toUpperCase()}
            onChange={handleInputChange}
            type="number"
            name="capacity"
            required
            value={state.capacity || ''}
          />
          <TextField
            data-cy="maxClientsPerTour"
            label={t('VehicleTypesScreen.maxClientsPerTour').toUpperCase()}
            onChange={handleInputChange}
            type="number"
            name="maxClientsPerTour"
            value={state.maxClientsPerTour || ''}
          />
          <SearchableSelect
            dataCy="profileSelect"
            label={t('VehicleTypesScreen.profile')}
            name="vehicleProfileId"
            required
            selectedValue={state.vehicleProfileId || ''}
            options={vehicleProfileOptions?.map((vehicleProfile) => ({
              id: vehicleProfile.id,
              name: vehicleProfile.name,
            }))}
            onChange={handleSelectChange}
          />
        </div>
        <ActiveSwitch
          dataCy="active"
          onChange={handleInputChange}
          name="active"
          checked={state.active}
          label={t('VehicleTypesScreen.active').toUpperCase()}
        />
      </>
    </Card>
  )
}

export default (props: JSX.IntrinsicAttributes & IProps): JSX.Element => (
  <ContentConsumer>
    {(ctx): JSX.Element => (
      <VehicleTypeInfoCard
        vehicleProfileOptions={ctx.vehicleProfiles}
        onVehicleTypeChange={props.onVehicleTypeChange}
        vehicleType={props.vehicleType}
      />
    )}
  </ContentConsumer>
)
