import Api from 'services/customerNotifierApi'
import { INotifierConfiguration } from 'interfaces/INotifierConfiguration'
import { IError } from 'api/types'
import { IOrderSmsDetails } from 'interfaces/IOrders'
import { INumberOfSMSSentPerSite } from 'interfaces/ISiteReporting'

interface INotifierConfigurationResponse {
  notifierConfigurations: INotifierConfiguration[]
  count: number
}

const getList = async (
  filters?: { tenant?: string[] },
  offset?: number,
  rowsPerPage?: number,
): Promise<INotifierConfigurationResponse | IError> => {
  try {
    const response = await Api.get('configurations/admin', {
      tenantIds: filters?.tenant,
      offset,
      limit: rowsPerPage,
    })
    return { notifierConfigurations: response.items, count: response.count }
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const get = async (id: string): Promise<INotifierConfiguration | IError> => {
  try {
    const response = await Api.get(`configurations/details/${id}/admin`)
    return response
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const create = async (
  notifierConfiguration: INotifierConfiguration,
): Promise<INotifierConfiguration | IError> => {
  try {
    const response = await Api.post('configurations/admin', notifierConfiguration)
    return response
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

const update = async (
  id: string,
  notifierConfiguration: INotifierConfiguration,
): Promise<INotifierConfiguration | IError> => {
  try {
    const response = await Api.patch(`configurations/${id}/admin`, notifierConfiguration)
    return response
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

const sendBookingSMS = async (orderSmsDTO: IOrderSmsDetails): Promise<boolean | IError> => {
  try {
    const response = await Api.post('orders/send-sms', orderSmsDTO)
    return response
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

const getNumberOfSMSSentPerSite = async (
  startDate: string,
  endDate: string,
  siteIds?: string[],
): Promise<INumberOfSMSSentPerSite[] | IError> => {
  try {
    const response = await Api.post('customer-notifications/sent-sms-per-site', {
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      siteIds,
    })
    return response
  } catch (error) {
    return { error: { message: error.message } }
  }
}

export default {
  getList,
  get,
  create,
  update,
  sendBookingSMS,
  getNumberOfSMSSentPerSite,
}
