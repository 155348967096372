import React, { useState, useEffect, useContext } from 'react'
import { IconButton } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Chart, PieSeries, Legend, Title, Tooltip } from '@devexpress/dx-react-chart-material-ui'
import moment from 'moment'
import { EventTracker, Palette } from '@devexpress/dx-react-chart'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { Skeleton } from '@material-ui/lab'
import { connectProps } from '@devexpress/dx-react-core'
import RefreshIcon from '@material-ui/icons/Refresh'

import { IChartData } from 'interfaces/IChartData'
import { ITourIndicator } from 'interfaces/ITourIndicator'
import { TOUR_STATUS_MAP, TOUR_STATUS, TOUR_STATUS_CHART_COLORS } from 'constants/constants'
import { OrdersConsumer } from 'store/OrdersContext'
import DateRangePicker from 'components/Inputs/DateRangePicker'
import { CarrierFilter, SiteFilter } from 'components/Inputs/ListFilter'
import Export from 'components/Chart/Export'
import ChartProgress from 'components/Chart/ChartProgress'
import { FiltersContainer, TopFiltersContainer } from 'components/Layout'
import { FiltersContext } from 'store/FiltersContext'
import { FilterKey, Filter } from 'constants/filters'
import StyledPaper from 'screens/ReportingCategory/components/StyledPaper'
import CustomTooltipContent from 'screens/ReportingCategory/components/CustomTooltipContent'
import CustomLegend from 'screens/ReportingCategory/components/CustomLegend'
import ExtendedDateFilters from 'components/Table/ExtendedDateFilters'
import {
  getChartTitle,
  setDateFactory,
  setSelectedDateFilterShortcutFactory,
} from 'utils/extendedDateFilter'
import DailyTourStatusChart from './DailyTourStatusChart'

interface IProps {
  tourIndicator?: ITourIndicator
  getTourStats?: (
    startDate: string,
    endDate: string,
    carrierIds?: string[],
    siteIds?: string[],
  ) => void
}

const containerId = 'tour-count-status'
const filterKey = FilterKey.tourProgress

const TourProgressIndicator = ({ tourIndicator, getTourStats }: IProps): JSX.Element => {
  const { setFilter, resetFilters, filters } = useContext(FiltersContext)
  const startDate = filters[filterKey][Filter.startDate] as string
  const endDate = filters[filterKey][Filter.endDate] as string
  const siteIds = filters[filterKey][Filter.sites] as string[]
  const carriers = filters[filterKey][Filter.carriers] as string[]
  const [maxEndDate, setMaxEndDate] = useState<string>(
    moment().add(31, 'days').hours(0).minutes(0)
      .toISOString(),
  )
  const [tourStatus, setTourStatus] = useState<Array<IChartData>>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [selectedDateFilterShortcut, setSelectedDateFilterShortcut] = useState('')
  const [total, setTotal] = useState<number>(0)
  const { t } = useTranslation()

  useEffect(() => {
    if (getTourStats) {
      setIsLoading(true)
      getTourStats(startDate, endDate, carriers, siteIds)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, carriers, siteIds])

  useEffect(() => {
    if (tourIndicator && tourIndicator.statusStats) {
      setIsLoading(false)
      setTourStatus(
        tourIndicator.statusStats.map((status, index) => ({
          label: TOUR_STATUS_MAP.get(index) as string,
          value: status,
        })),
      )
      setTotal(tourIndicator.statusStats.reduce((accumulator, current) => accumulator + current, 0))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourIndicator])

  const handleStartDateChange = (date: MaterialUiPickersDate): void => {
    const endDateUpperLimit = moment(date).add(31, 'days')
    setMaxEndDate(endDateUpperLimit.toISOString())
    setFilter(filterKey, Filter.startDate, moment(date).toISOString())
    if (moment(endDate).isAfter(endDateUpperLimit) || !endDate) {
      setFilter(filterKey, Filter.endDate, endDateUpperLimit.toISOString())
    }
    setSelectedDateFilterShortcut('')
  }

  const EnhancedLegend = connectProps(CustomLegend, () => ({
    total,
    label: t('ReportingToursScreen.totalNumberOfTours'),
  }))

  const EnhancedTooltipContent = connectProps(CustomTooltipContent, () => ({
    total,
    firstLabel: t('ReportingToursScreen.count'),
    secondLabel: t('ReportingToursScreen.percentage'),
  }))

  const resetLocalFilters = (): void => {
    setMaxEndDate(moment().add(31, 'days').hours(0).minutes(0)
      .toISOString())
    resetFilters(filterKey)
  }

  const { setEndDate, setStartDate } = setDateFactory(setFilter, filterKey)

  const { fnLast3months, fnLastMonth, fnLast7days, fnNext7days, fnThisMonth } = setSelectedDateFilterShortcutFactory(
    setSelectedDateFilterShortcut,
  )

  const chartTitleToursCountByStatus = getChartTitle(
    'ReportingToursScreen.toursCountByStatus',
    selectedDateFilterShortcut,
  )
  const chartTitleToursCountByDay = getChartTitle(
    'ReportingToursScreen.toursCountByDay',
    selectedDateFilterShortcut,
  )

  return (
    <>
      <TopFiltersContainer>
        <FiltersContainer>
          <IconButton data-cy="initializeButton" onClick={resetLocalFilters}>
            <RefreshIcon />
          </IconButton>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onChangeStart={handleStartDateChange}
            maxEndDate={maxEndDate}
            onChangeEnd={(endValue: MaterialUiPickersDate): void => {
              setFilter(filterKey, Filter.endDate, moment(endValue).toISOString())
              setSelectedDateFilterShortcut('')
            }}
            hideShiftPicker
          />
          <SiteFilter
            handleChange={(siteValues: string[]): void => {
              setFilter(filterKey, Filter.sites, siteValues)
            }}
            ids={siteIds}
            placeholder={t('ReportingToursScreen.site')}
            dataCy="sitePicker"
            isUnselectAllowed={false}
          />
          <CarrierFilter
            dataCy="carrierPicker"
            handleChange={(carrierValues: string[]): void => {
              setFilter(filterKey, Filter.carriers, carrierValues)
            }}
            ids={carriers}
            siteIds={siteIds}
            filterKey={filterKey}
          />
        </FiltersContainer>
      </TopFiltersContainer>
      <ExtendedDateFilters
        fnLast3months={fnLast3months}
        fnLastMonth={fnLastMonth}
        fnLast7days={fnLast7days}
        fnNext7days={fnNext7days}
        fnThisMonth={fnThisMonth}
        last3months
        lastMonth
        last7days
        next7days
        thisMonth
        setEndDate={setEndDate}
        setStartDate={setStartDate}
      />
      {isLoading && (
        <Skeleton
          variant="circle"
          width={400}
          height={400}
          style={{ margin: 'auto', marginTop: 60 }}
        />
      )}
      {tourStatus && !isLoading && (
        <StyledPaper id={containerId}>
          <Chart data-cy="toursCountChart" data={tourStatus}>
            <Palette scheme={TOUR_STATUS_CHART_COLORS} />
            <PieSeries valueField="value" argumentField="label" />
            <Title text={chartTitleToursCountByStatus} />
            <Legend position="bottom" rootComponent={EnhancedLegend} />
            <EventTracker />
            <Tooltip contentComponent={EnhancedTooltipContent} />
            {/* TODO: use another fileName */}
            <Export
              dataCy="exportToursCountButton"
              containerId={containerId}
              fileName={containerId}
            />
          </Chart>
        </StyledPaper>
      )}
      {isLoading && <ChartProgress count={24} />}
      {tourIndicator && tourIndicator.statusByDayStats && !isLoading && (
        <DailyTourStatusChart
          title={chartTitleToursCountByDay}
          data={tourIndicator.statusByDayStats}
          statusIds={TOUR_STATUS.map((status) => status.id)}
        />
      )}
    </>
  )
}

export default (props: JSX.IntrinsicAttributes & IProps): JSX.Element => (
  <OrdersConsumer>
    {(ordersContext): JSX.Element => (
      <TourProgressIndicator
        tourIndicator={ordersContext.tourIndicator}
        getTourStats={ordersContext.getTourStats}
        {...props}
      />
    )}
  </OrdersConsumer>
)
