import React, { useContext, useState, useEffect } from 'react'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Chart, PieSeries, Legend, Title, Tooltip } from '@devexpress/dx-react-chart-material-ui'
import { Skeleton } from '@material-ui/lab'
import RefreshIcon from '@material-ui/icons/Refresh'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { EventTracker } from '@devexpress/dx-react-chart'
import { Grid, IconButton } from '@material-ui/core'

import { DeliveryTypeFilter, SiteFilter, TransportTypeFilter } from 'components/Inputs/ListFilter'
import { AuthContext } from 'store/AuthContext'
import { OrdersContext } from 'store/OrdersContext'
import Export from 'components/Chart/Export'
import { IChartData } from 'interfaces/IChartData'
import { FiltersContext } from 'store/FiltersContext'
import { FilterKey, Filter } from 'constants/filters'
import { formatDeliveryTypes } from 'utils/functions'
import StyledPaper from 'screens/ReportingCategory/components/StyledPaper'

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      position: 'relative',
      marginRight: '25px',
    },
    datePicker: {
      marginLeft: 8,
      width: 170,
    },
  }),
)

const containerId = 'planning-monitoring'
const filterKey = FilterKey.planningMonitoring

const PlanningMonitoringIndicator = (): JSX.Element => {
  const { user } = useContext(AuthContext)
  const { setFilter, resetFilters, filters } = useContext(FiltersContext)
  const deliveryTypes = filters[filterKey][Filter.deliveryTypes] as string[]
  const transportTypes = filters[filterKey][Filter.transportTypes] as string[]
  const date = filters[filterKey][Filter.date] as string
  const siteIds = filters[filterKey][Filter.sites] as string[]
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [chartData, setChartData] = useState<IChartData[]>([])
  const classes = useStyles()
  const { t } = useTranslation()
  const { planningOrderStats, getOrderPlanningStats } = useContext(OrdersContext)

  const deliveryTypesMap: Map<number, string> = new Map(
    formatDeliveryTypes(user?.tenantConfig?.deliveryTypes).map((deliveryType) => [
      deliveryType?.code || 0,
      deliveryType.label,
    ]),
  )

  useEffect(() => {
    setIsLoading(true)
    getOrderPlanningStats(date, deliveryTypes, transportTypes, siteIds)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, deliveryTypes, transportTypes, siteIds])

  useEffect(() => {
    setIsLoading(false)
    setChartData(
      planningOrderStats.toursNumberOfOrdersByDeliveryTypes.map((tn) => ({
        label: deliveryTypesMap.get(+tn.deliveryType) as string,
        value: +tn.toursNumberOfOrders,
      })),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planningOrderStats])

  const handleDateChange = (selectedDate: MaterialUiPickersDate): void => {
    if (moment(selectedDate).isValid()) {
      const newDate = moment(selectedDate).hours(0).minutes(0).toISOString()
      setFilter(filterKey, Filter.date, newDate)
    }
  }

  const resetLocalFilters = (): void => resetFilters(filterKey)

  return (
    <Grid xs={12} item className={classes.container}>
      <IconButton onClick={resetLocalFilters}>
        <RefreshIcon />
      </IconButton>
      <KeyboardDatePicker
        label={t('executionScreen.date')}
        inputVariant="outlined"
        format="DD/MM/YYYY"
        value={date}
        onChange={handleDateChange}
        className={classes.datePicker}
      />
      <SiteFilter
        handleChange={(siteValues: string[]): void => {
          setFilter(filterKey, Filter.sites, siteValues)
        }}
        ids={siteIds}
        placeholder={t('PlanningMonitoringScreen.site')}
        dataCy="sitePicker"
        isUnselectAllowed={false}
      />
      <DeliveryTypeFilter
        handleChange={(deliveryTypeValues: string[]): void => {
          setFilter(filterKey, Filter.deliveryTypes, deliveryTypeValues)
        }}
        ids={deliveryTypes}
      />
      <TransportTypeFilter
        handleChange={(transportTypeValues: string[]): void => {
          setFilter(filterKey, Filter.transportTypes, transportTypeValues)
        }}
        ids={transportTypes}
      />

      {isLoading && (
        <Skeleton
          variant="circle"
          animation="wave"
          width={400}
          height={400}
          style={{ margin: 'auto', marginTop: 60 }}
        />
      )}
      {chartData && !isLoading && (
        <StyledPaper id={containerId}>
          <Chart data={chartData}>
            <PieSeries valueField="value" argumentField="label" />
            <Title text={t('PlanningMonitoringScreen.orderCountByType')} />
            <Legend />
            <EventTracker />
            <Tooltip />
            {/* TODO: use another fileName */}
            <Export containerId={containerId} fileName={containerId} />
          </Chart>
        </StyledPaper>
      )}
    </Grid>
  )
}

export default PlanningMonitoringIndicator
