import React, { useState } from 'react'
import { TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { KeyboardTimePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import moment from 'moment'

import { IPlanTemplate } from 'interfaces/IPlanTemplate'
import useStyles from 'constants/cruStyles'
import Card from 'components/Card/Card'

interface ITextChange {
  target: {
    value: string
    name: string
    type?: string
    checked?: boolean
  }
}

interface IProps {
  planTemplate?: Partial<IPlanTemplate>
  onPlanTemplateChange?: Function
  isReadOnly?: boolean
}

const PlanTemplateInfoCard = ({
  planTemplate,
  onPlanTemplateChange,
  isReadOnly,
}: IProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [state, setState] = useState<Partial<IPlanTemplate>>({
    label: planTemplate?.label,
    begin: planTemplate?.begin,
    end: planTemplate?.end,
    dateOffset: planTemplate?.dateOffset,
  })

  const handleTimeChange = (time: MaterialUiPickersDate, name: string): void => {
    if (moment(time).isValid()) {
      setState({ ...state, [name]: moment(time).toISOString() })
      if (onPlanTemplateChange) {
        onPlanTemplateChange({ ...state, [name]: moment(time).toISOString() })
      }
    }
  }

  const handleInputChange = (event: ITextChange): void => {
    const { target } = event
    const { name } = target
    const value = target.type === 'checkbox' ? target.checked : target.value

    setState({ ...state, [name]: value })
    if (onPlanTemplateChange) {
      onPlanTemplateChange({ ...state, [name]: value })
    }
  }

  return (
    <Card dataCy="infoCard" title={t('PlanTemplatesScreen.info').toUpperCase()}>
      <div className={classes.fieldsContainer}>
        <TextField
          data-cy="lastName"
          disabled={isReadOnly}
          name="label"
          value={state.label || ''}
          required
          error={state.label === ''}
          label={t('PlanTemplatesScreen.name').toUpperCase()}
          onChange={handleInputChange}
          type="text"
        />
        <KeyboardTimePicker
          data-cy="startPicker"
          label={t('PlanTemplatesScreen.begin')}
          disabled={isReadOnly}
          value={state.begin || moment().hours(0).minutes(0).toISOString()}
          onChange={(time: MaterialUiPickersDate): void => {
            handleTimeChange(time, 'begin')
          }}
          inputVariant="outlined"
          className={classes.timeInput}
          ampm={false}
        />
        <KeyboardTimePicker
          data-cy="endPicker"
          label={t('PlanTemplatesScreen.end')}
          disabled={isReadOnly}
          value={state.end || moment().hours(23).minutes(59).toISOString()}
          onChange={(time: MaterialUiPickersDate): void => {
            handleTimeChange(time, 'end')
          }}
          inputVariant="outlined"
          className={classes.timeInput}
          ampm={false}
        />
        <TextField
          data-cy="jPlus"
          name="dateOffset"
          disabled={isReadOnly}
          value={state.dateOffset === undefined ? 0 : state.dateOffset}
          label={t('PlanTemplatesScreen.dateOffset').toUpperCase()}
          onChange={handleInputChange}
          type="number"
        />
      </div>
    </Card>
  )
}

export default PlanTemplateInfoCard
