import React from 'react'

import { IError } from 'api/types'
import { COLUMNS_MODEL_CARRIERS_LIST } from 'constants/table'
import { AVAILABLE_FILTERS } from 'constants/constants'
import { ICarrier } from 'interfaces/ICarrier'
import CarriersProvider, { CarriersConsumer } from 'store/CarriersContext'
import CarriersTable from 'components/Table/CarriersTable'

interface ICarriersProps {
  carriers: ICarrier[]
  error?: IError
}

const CarriersScreen = ({ carriers }: ICarriersProps): JSX.Element => (
  <CarriersTable
    carriers={carriers}
    columns={COLUMNS_MODEL_CARRIERS_LIST}
    hideFilters={[AVAILABLE_FILTERS.CARRIERS]}
  />
)

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <CarriersProvider>
    <CarriersConsumer>
      {(ctx): JSX.Element => <CarriersScreen carriers={ctx.carriers} {...props} />}
    </CarriersConsumer>
  </CarriersProvider>
)
