import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField } from '@material-ui/core'

import ActiveSwitch from 'components/Inputs/ToggleSwitch'
import { IWebhook } from 'interfaces/IWebhook'
import useStyles from 'constants/cruStyles'
import { AuthContext } from 'store/AuthContext'
import { CarrierFilter, DealerFilter } from 'components/Inputs/ListFilter'
import { USER_ROLES } from 'constants/constants'
import Card from 'components/Card/Card'

interface ITextChange {
  target: {
    value: string
    name: string
    type?: string
    checked?: boolean
  }
}

interface IProps {
  onChange: (webhook: IWebhook) => void
  webhook: IWebhook
}

const InformationCard = ({ onChange, webhook: webhookInfo }: IProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { user } = useContext(AuthContext)
  const shouldShowCarrierList = user?.roles.includes(USER_ROLES.pilot)
  const [webhook, setWebhook] = useState<IWebhook>(webhookInfo)

  const handleInputChange = (event: ITextChange): void => {
    const { target } = event
    const { name, type } = target
    const value = type === 'checkbox' ? target.checked : target.value

    setWebhook((prev) => ({ ...prev, [name]: value }))
    onChange({ ...webhook, [name]: value })
  }

  const handleCarrierSelect = (values: string[]): void => {
    setWebhook((prev) => ({ ...prev, carrierId: values[0] }))
    onChange({ ...webhook, carrierId: values[0] })
  }

  const handleDealerSelect = (values: string[]): void => {
    setWebhook((prev) => ({ ...prev, dealerId: values[0] }))
    onChange({ ...webhook, dealerId: values[0] })
  }

  const handleExtraChange = (extra: string): void => {
    setWebhook((prev) => ({ ...prev, extra }))
    onChange({ ...webhook, extra })
  }

  return (
    <Card title={t('WebhooksScreen.info').toUpperCase()}>
      <>
        <div className={classes.fieldsContainer}>
          <TextField
            label={t('WebhooksScreen.url').toUpperCase()}
            onChange={handleInputChange}
            type="text"
            name="url"
            value={webhook.url || ''}
          />
          <TextField
            label={t('WebhooksScreen.clientId').toUpperCase()}
            onChange={handleInputChange}
            type="text"
            name="clientId"
            value={webhook.clientId || ''}
          />
          <TextField
            label={t('WebhooksScreen.clientSecret').toUpperCase()}
            onChange={handleInputChange}
            type="text"
            name="clientSecret"
            value={webhook.clientSecret || ''}
          />
          <TextField
            label={t('WebhooksScreen.extra').toUpperCase()}
            onChange={(event) => handleExtraChange(event.target.value)}
            type="text"
            name="extra"
            value={webhook.extra || ''}
          />
          {shouldShowCarrierList && (
            <div className={classes.filters}>
              <CarrierFilter
                handleChange={handleCarrierSelect}
                ids={webhook.carrierId ? [webhook.carrierId] : []}
                onlyOneValue
                shouldFillWidth
              />
            </div>
          )}
          <div className={classes.filters}>
            <DealerFilter
              handleChange={handleDealerSelect}
              ids={webhook.dealerId ? [webhook.dealerId] : []}
              onlyOneValue
              shouldFillWidth
              placeholder={t('tablesEntries.dealers')}
              dataCy="dealerPicker"
            />
          </div>
        </div>
        <ActiveSwitch
          onChange={handleInputChange}
          name="active"
          checked={webhook.active}
          label={t('WebhooksScreen.active').toUpperCase()}
        />
      </>
    </Card>
  )
}

export default InformationCard
