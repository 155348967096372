import axios from 'axios'
import { FileWithPath } from 'react-dropzone'
import Api from 'services/api'

import { IError } from 'api/types'
import { ImportJobType } from 'interfaces/IImportJob'

const uploadFiles = async (
  files: FileWithPath[],
  dataType: ImportJobType,
): Promise<void | IError> => {
  try {
    const promises = await files.map(async (file) => {
      const res = await Api.post('/import-jobs/upload-url', {
        dataType,
        originFileName: file.name,
      })
      const cleanApi = axios.create()
      await cleanApi.put(res.uploadUrl, file, { headers: { 'x-ms-blob-type': 'BlockBlob' } })
    })
    await Promise.all(promises)
    return
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

export default {
  uploadFiles,
}
