import { createStyles, makeStyles } from '@material-ui/core/styles'

export default makeStyles(() =>
  createStyles({
    paper: {
      color: '#FFF',
      backgroundColor: '#0F0F10',
    },
    img: {
      margin: 'auto',
      maxWidth: '100%',
      height: '30vh',
      paddingTop: 100,
    },
  }),
)
