/* eslint-disable no-tabs */
import React, { useState, ReactNode, useEffect } from 'react'
// The next import is made to fix warnings from MUI
// We should try to remove it once fixes are OK on their side
// @ts-ignore
import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles'
import createTypography from '@material-ui/core/styles/createTypography'
import createPalette from '@material-ui/core/styles/createPalette'
import { saveIsDarkMode, getIsDarkMode } from 'utils/localStorage'

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    color: {
      secondaryHover: React.CSSProperties['color']
      green: React.CSSProperties['color']
      lightGreen: React.CSSProperties['color']
      red: React.CSSProperties['color']
      orange: React.CSSProperties['color']
      backgroundPaperTitle: React.CSSProperties['color']
      secondary: React.CSSProperties['color']
      tableRowOdd: React.CSSProperties['color']
      tableRowEven: React.CSSProperties['color']
      primary: React.CSSProperties['color']
      lightPrimary: React.CSSProperties['color']
      backgroundStrongGrey: React.CSSProperties['color']
      backgroundNavBar: React.CSSProperties['color']
      backgroundBody: React.CSSProperties['color']
      label: React.CSSProperties['color']
      fieldBorder: React.CSSProperties['color']
      secondaryFieldBorder: React.CSSProperties['color']
      hover: React.CSSProperties['color']
      scrollbarBorder: React.CSSProperties['color']
      scrollbarBackground: React.CSSProperties['color']
      semiTransparentBackground: React.CSSProperties['color']
      headerPaper: React.CSSProperties['color']
      container: React.CSSProperties['color']
      tableHeaderBackground: React.CSSProperties['color']
      statusLineBorder: React.CSSProperties['color']
      backgroundCustomToolbar: React.CSSProperties['color']
      colorPlan: React.CSSProperties['color']
      backgroundColorPlan: React.CSSProperties['color']
      backgroundNavBarShift: React.CSSProperties['color']
      actionsCustomToolbar: React.CSSProperties['color']
      actionsBackgroundCustomToolbar: React.CSSProperties['color']
      subTableHeaderBackground: React.CSSProperties['color']
      subTableRowOdd: React.CSSProperties['color']
    }
  }
  interface ThemeOptions {
    color?: {
      secondaryHover: React.CSSProperties['color']
      green: React.CSSProperties['color']
      lightGreen: React.CSSProperties['color']
      red: React.CSSProperties['color']
      orange: React.CSSProperties['color']
      backgroundPaperTitle: React.CSSProperties['color']
      tableRowOdd: React.CSSProperties['color']
      tableRowEven: React.CSSProperties['color']
      primary: React.CSSProperties['color']
      lightPrimary: React.CSSProperties['color']
      secondary: React.CSSProperties['color']
      backgroundStrongGrey: React.CSSProperties['color']
      backgroundNavBar: React.CSSProperties['color']
      backgroundBody: React.CSSProperties['color']
      label: React.CSSProperties['color']
      fieldBorder: React.CSSProperties['color']
      secondaryFieldBorder: React.CSSProperties['color']
      hover: React.CSSProperties['color']
      scrollbarBorder: React.CSSProperties['color']
      scrollbarBackground: React.CSSProperties['color']
      semiTransparentBackground: React.CSSProperties['color']
      headerPaper: React.CSSProperties['color']
      container: React.CSSProperties['color']
      tableHeaderBackground: React.CSSProperties['color']
      statusLineBorder: React.CSSProperties['color']
      backgroundCustomToolbar: React.CSSProperties['color']
      colorPlan: React.CSSProperties['color']
      backgroundColorPlan: React.CSSProperties['color']
      backgroundNavBarShift: React.CSSProperties['color']
      actionsCustomToolbar: React.CSSProperties['color']
      actionsBackgroundCustomToolbar: React.CSSProperties['color']
      subTableHeaderBackground: React.CSSProperties['color']
      subTableRowOdd: React.CSSProperties['color']
    }
  }
}

interface IThemeContext {
  toggleDarkMode: () => void
  darkMode: boolean
}

const ThemeContext = React.createContext<IThemeContext>({} as IThemeContext)
const { Provider, Consumer } = ThemeContext

interface IProps {
  children: ReactNode
}

/**
 *
 * ThemeContext will manage the app style and theme
 */

const CustomThemeProvider = ({ children }: IProps): JSX.Element => {
  const [darkMode, setDarkMode] = useState<boolean>(getIsDarkMode())

  function toggleDarkMode(): void {
    setDarkMode((prev) => !prev)
  }

  useEffect(() => {
    saveIsDarkMode(darkMode)
  }, [darkMode])

  const darkThemePalette = createPalette({
    type: 'dark',
    primary: {
      main: '#E68717',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#5E5F62',
      contrastText: '#FFF',
    },
    background: {
      paper: '#0F0F10',
    },
  })

  const darkTheme = createMuiTheme({
    color: {
      secondaryHover: '#DBDBDB',
      green: 'green',
      lightGreen: '#008831',
      red: 'red',
      orange: 'orange',
      backgroundPaperTitle: '#5E5F62',
      secondary: '#FFFFFF',
      tableRowOdd: '#0F0F10',
      tableRowEven: '#25262C',
      primary: '#E68717',
      lightPrimary: '#E68717B3',
      backgroundStrongGrey: '#25262C',
      backgroundNavBar: '#1B1C22',
      backgroundBody: '#0F0F10',
      label: 'rgba(255, 255, 255, 0.8)',
      fieldBorder: 'rgba(255, 255, 255, 0.3)',
      secondaryFieldBorder: 'rgba(255, 255, 255, 0.3)',
      hover: '#efefef',
      scrollbarBorder: 'rgba(0, 0, 0, 0.8)',
      scrollbarBackground: 'rgba(0, 0, 0, 0.3)',
      semiTransparentBackground: 'rgba(0,0,0,0.4)',
      headerPaper: 'slategrey',
      container: 'slategrey',
      tableHeaderBackground: '#0F0F10',
      statusLineBorder: '#25262C',
      backgroundCustomToolbar: '#E68717',
      colorPlan: '#FFF',
      backgroundColorPlan: '#5E5F62',
      backgroundNavBarShift: '#1B1C22',
      actionsCustomToolbar: '#FFF',
      actionsBackgroundCustomToolbar: '#E68717',
      subTableHeaderBackground: '#25262C',
      subTableRowOdd: '#1B1C22',
    },
    palette: darkThemePalette,
    typography: createTypography(darkThemePalette, {
      fontFamily: '"Nunito"',
    }),
    overrides: {
      MuiTableCell: {
        head: {
          color: '#919191',
          backgroundColor: '#1B1C22',
        },
        root: {
          borderBottom: 0,
        },
      },
      MuiInput: {
        underline: {
          '&:before': {
            borderBottom: 0,
          },
          '&:hover': {
            borderBottom: 0,
          },
        },
      },
      MuiTableRow: {
        root: {
          '&:nth-child(odd)': {
            backgroundColor: '#0F0F10',
          },
          '&:nth-child(even)': {
            backgroundColor: '#25262C',
          },
          '&:hover': {
            boxShadow: `inset 0px 0px 0px 2px #E68717`,
          },
          backgroundColor: '#0F0F10',
        },
        hover: {
          '&:nth-child(odd)': {
            backgroundColor: '#0F0F10',
          },
          '&:nth-child(even)': {
            backgroundColor: '#25262C',
          },
          '&:hover': {
            boxShadow: `inset 0px 0px 0px 2px #E68717`,
          },
        },
      },
      MuiDrawer: {
        root: {
          marginBottom: 100,
        },
      },
    },
  })

  const lightThemePalette = createPalette({
    type: 'light',
    primary: {
      main: '#EC6726',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#5E5F62',
      contrastText: '#0F0F10',
    },
    background: {
      paper: '#FFF',
      default: '#FAFAFA',
    },
  })

  const lightTheme = createMuiTheme({
    color: {
      secondaryHover: '#0F0F10',
      green: 'green',
      lightGreen: '#008831',
      red: 'red',
      orange: '#EC6726',
      backgroundPaperTitle: '#E5E4E3',
      secondary: '#0F0F10',
      tableRowOdd: '#FFF',
      tableRowEven: '#FFF',
      primary: '#EC6726',
      lightPrimary: '#EC6726B3',
      backgroundStrongGrey: '#FFF',
      backgroundNavBar: '#E5E4E3',
      backgroundBody: '#FFF',
      label: 'rgba(0, 0, 0, 0.8)',
      fieldBorder: '#b3b3b3',
      secondaryFieldBorder: 'rgba(0, 0, 0, 0.2)',
      hover: '#0F0F10',
      scrollbarBorder: 'rgba(255, 255, 255, 0.8)',
      scrollbarBackground: 'rgba(255, 255, 255, 0.3)',
      semiTransparentBackground: 'rgba(255,255,255,0.9)',
      headerPaper: '#E5E4E3',
      container: '#FFF',
      tableHeaderBackground: '#E5E4E3',
      statusLineBorder: '#000',
      backgroundCustomToolbar: '#FFF',
      colorPlan: '#25262C',
      backgroundColorPlan: '#E5E4E3',
      backgroundNavBarShift: '#EC6726',
      actionsCustomToolbar: '#EC6726',
      actionsBackgroundCustomToolbar: '#EC6726',
      subTableHeaderBackground: '#E5E4E3',
      subTableRowOdd: '#F2F2F2',
    },
    palette: lightThemePalette,
    typography: createTypography(lightThemePalette, {
      fontFamily: '"Nunito"',
    }),
    overrides: {
      MuiPaper: {
        elevation2: {
          boxShadow: 'none',
        },
      },
      MuiTableCell: {
        head: {
          color: '#25262C',
          backgroundColor: '#FFF',
        },
        root: {
          borderBottom: 0,
        },
      },
      MuiInput: {
        underline: {
          '&:before': {
            borderBottom: 0,
          },
          '&:hover': {
            borderBottom: 0,
          },
        },
      },
      MuiTableRow: {
        root: {
          '&:nth-child(odd)': {
            backgroundColor: '#FFF',
          },
          '&:nth-child(even)': {
            backgroundColor: '#F2F2F2',
          },
          '&:hover': {
            boxShadow: `inset 0px 0px 0px 2px #EC6726`,
          },
          backgroundColor: '#FFF',
        },
        hover: {
          '&:nth-child(odd)': {
            backgroundColor: '#FFF',
          },
          '&:nth-child(even)': {
            backgroundColor: '#F2F2F2',
          },
          '&:hover': {
            boxShadow: `inset 0px 0px 0px 2px #EC6726`,
          },
        },
      },
      MuiDrawer: {
        root: {
          marginBottom: 100,
        },
      },
    },
  })

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <Provider
        value={{
          toggleDarkMode,
          darkMode,
        }}
      >
        {children}
      </Provider>
    </ThemeProvider>
  )
}

export default CustomThemeProvider
export { Consumer as ThemeConsumer, ThemeContext }
