import Api from 'services/api'
import { IError } from 'api/types'
// eslint-disable-next-line max-len
import {
  IPlan,
  IPlanVisit,
  IPlanTour as IPlanTourData,
  IPlanStop,
  IPlanTourDeleteResponse,
  IPlanDeleteResponse,
} from 'interfaces/IPlan'
import { ICluster } from 'interfaces/ICluster'
import { IPlanTour, ITourMapItem } from 'interfaces/map'
import { IOptimizationParam } from 'interfaces/IOptimizationParam'
import { IAddress, IPlanVisitData, ITour } from 'interfaces/Itours'
import { IPlanTourFilters } from 'interfaces/interfaces'
import { PLAN_TOUR_STATUS_MAP, STOP_STATUS_MAP, TransportType } from 'constants/constants'
import { IProduct } from 'interfaces/IOrders'
import { IJob } from 'interfaces/IJob'
import { IModifiedVehicleAvailability } from 'interfaces/IModifiedVehicleAvailability'

interface IGetPlans {
  startDate?: string
  endDate?: string
  deliveryMaxEndDate?: string
  deliveryType?: string[]
  transportTypes?: string[]
  warehouseId?: string
  offset?: number
  zipCodes?: string[]
  toOptimize?: boolean
  search?: string
}

interface IGetPlanVisits extends IGetPlans {
  warehouseIds?: string[]
}

const ITEMS_BY_CALL = 50

interface IPlanVisitsRes {
  items: IPlanVisit[]
  count: number
}

const getPlansVisits = async ({
  startDate,
  endDate,
  deliveryMaxEndDate,
  deliveryType,
  transportTypes,
  warehouseIds,
  zipCodes,
  offset = 0,
  toOptimize,
}: IGetPlanVisits): Promise<IPlanVisit[] | IError> => {
  try {
    let items: IPlanVisit[] = []
    const res: IPlanVisitsRes = await Api.get('plan-visits', {
      startDate,
      endDate,
      deliveryMaxEndDate: deliveryMaxEndDate || undefined,
      deliveryTypes: deliveryType,
      transportTypes,
      loadingWarehouseIds: warehouseIds,
      status: 0,
      offset,
      zipCodes: zipCodes || undefined,
      toOptimize,
    })
    items = [...res.items]
    if (res.items.length >= ITEMS_BY_CALL) {
      const promises: Promise<IPlanVisitsRes>[] = []
      for (let index = 1; index < Math.ceil(res.count / ITEMS_BY_CALL); index += 1) {
        const tempRes: Promise<IPlanVisitsRes> = Api.get('plan-visits', {
          startDate,
          endDate,
          deliveryMaxEndDate: deliveryMaxEndDate || undefined,
          deliveryTypes: deliveryType,
          transportTypes,
          loadingWarehouseIds: warehouseIds,
          status: 0,
          zipCodes: zipCodes || undefined,
          offset: index * ITEMS_BY_CALL,
        })
        promises.push(tempRes)
      }
      const resArray = await Promise.all(promises)
      resArray.forEach((visitRes) => {
        items = [...items, ...visitRes.items]
      })
    }
    return items
  } catch (error) {
    return { error: { message: error.message } }
  }
}

interface ISavePlan {
  label: string
  warehouseId: string
  secondaryWarehouseIds: string[]
  begin: string
  end: string
  deliveryTypes: string[]
  transportTypes: TransportType[]
  carrierIds?: string[]
  vehicleTypesIds?: string[]
  vehicleDepartureMin: string
  vehicleReturnMax: string
  ordersBegin: string
  ordersEnd: string
}

const savePlan = async ({
  label,
  warehouseId,
  secondaryWarehouseIds,
  begin,
  end,
  deliveryTypes,
  transportTypes,
  carrierIds,
  vehicleTypesIds,
  vehicleDepartureMin,
  vehicleReturnMax,
  ordersBegin,
  ordersEnd,
}: ISavePlan): Promise<IPlan | IError> => {
  try {
    const res = await Api.post('plans', {
      label,
      warehouseId,
      secondaryWarehouseIds,
      begin,
      end,
      deliveryTypes,
      transportTypes,
      carrierIds,
      vehicleTypesIds,
      vehicleDepartureMin,
      vehicleReturnMax,
      ordersBegin,
      ordersEnd,
    })
    return res
  } catch (error) {
    return { error: { message: error.message, code: error.code } }
  }
}

const getPlans = async ({
  startDate,
  endDate,
  deliveryType,
  warehouseId,
  search,
}: IGetPlans): Promise<IPlan[] | IError> => {
  try {
    const res = await Api.get('plans', { startDate, endDate, deliveryType, warehouseId, search })
    return res.items
  } catch (error) {
    return { error: { message: error.message } }
  }
}

interface IGetPlanTours {
  planId?: string
  deliveryTypes?: string[]
  warehouseId?: string
  searchText?: string
  status: string[]
}

const getPlanTours = async ({
  planId,
  searchText,
  status,
  deliveryTypes,
}: IGetPlanTours): Promise<Array<ITourMapItem> | IError> => {
  try {
    const res = await Api.get(`plans/${planId}/plan-tours`, {
      deliveryTypes,
      search: searchText,
      status: status.map(Number),
    })
    return res.items.map((item: IPlanTour) => ({
      ...item,
      tourId: item.planToursId,
      tourNumber: item.tourNumber,
      mainQuantity: item.mainQuantity,
      mainQuantityToDeliver: item.mainQuantityToDeliver,
      mainQuantityToLoad: item.mainQuantityToLoad,
      loadingDate: item.loadingDate,
      roadpath: item.roadpath,
      stops: item.planStops.map((stop) => ({
        ...stop,
        stopId: stop.id,
      })),
      beginDateTime: item.beginDateTime,
      endDateTime: item.endDateTime,
      vehicleId: item.vehicleId,
    }))
  } catch (error) {
    return { error: { message: error.message } }
  }
}

export interface IOptimizePlan {
  planId?: string
  planVisitIds?: string[]
  optimParams: IOptimizationParam
  vehicleIds: string[]
  maxClientsPerTour?: number
  vehiclesAvailabilities?: IModifiedVehicleAvailability[]
}

interface IOptimizeClusters {
  planId?: string
  clusters: ICluster[]
  optimParams: IOptimizationParam
  maxClientsPerTour?: number
  vehiclesAvailabilities?: IModifiedVehicleAvailability[]
}

export interface IOptimizePlanWithManualTours extends IOptimizePlan {
  manualTours: ICluster[]
}

export interface ICustomTour {
  planVisitIds: string[]
  vehicleIds: string[]
  startHour: string
  tourNumber: string
}

export interface IReorganizePlanVisit {
  customTours: ICustomTour[]
}

const optimizePlan = async (
  {
    planId,
    planVisitIds,
    optimParams,
    vehicleIds,
    maxClientsPerTour,
    vehiclesAvailabilities,
  }: IOptimizePlan,
  useJob = false,
): Promise<IJob | IError> => {
  try {
    const data = {
      maxClientsPerTour,
      optimParams,
      planVisitIds,
      useJob,
      vehicleIds,
      vehiclesAvailabilities,
    }
    const res = await Api.post(`plans/${planId}/optimize`, data)
    return res
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const optimizeMultisitePlan = async (
  {
    planId,
    planVisitIds,
    optimParams,
    vehicleIds,
    maxClientsPerTour,
    vehiclesAvailabilities,
  }: IOptimizePlan,
  useJob = false,
): Promise<IJob | IError> => {
  try {
    const data = {
      maxClientsPerTour,
      optimParams,
      planVisitIds,
      useJob,
      vehicleIds,
      vehiclesAvailabilities,
    }
    const res = await Api.post(`plans/${planId}/optimize-multisites`, data)
    return res
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const optimizeClusters = async (
  { planId, clusters, optimParams, maxClientsPerTour, vehiclesAvailabilities }: IOptimizeClusters,
  useJob = false,
): Promise<IJob | IError> => {
  try {
    const data = {
      clusters: clusters.map((cluster) => ({
        planVisitIds: cluster.visits.map((visit) => visit.stopId),
        vehicleIds: cluster.vehicleIds,
        deckCode: cluster.deckCode,
        driverId: cluster.driverId,
      })),
      vehiclesAvailabilities,
      optimParams,
      maxClientsPerTour,
      useJob,
    }
    const res = await Api.post(`plans/${planId}/optimize-clusters`, data)
    return res
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const optimizePlanWithManualTours = async (
  {
    planId,
    manualTours,
    planVisitIds,
    vehicleIds,
    optimParams,
    maxClientsPerTour,
    vehiclesAvailabilities,
  }: IOptimizePlanWithManualTours,
  useJob = false,
): Promise<IJob | IError> => {
  try {
    const data = {
      manualTours: manualTours.map((manualTour) => ({
        planVisitIds: manualTour.visits.map((visit) => visit.stopId),
        vehicleIds: manualTour.vehicleIds,
      })),
      maxClientsPerTour,
      optimParams,
      planVisitIds,
      useJob,
      vehicleIds,
      vehiclesAvailabilities,
    }
    const res = await Api.post(`plans/${planId}/optimize-with-manual-tours`, data)
    return res
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const optimizeMultisitePlanWithManualTours = async (
  {
    planId,
    manualTours,
    planVisitIds,
    vehicleIds,
    optimParams,
    maxClientsPerTour,
    vehiclesAvailabilities,
  }: IOptimizePlanWithManualTours,
  useJob = false,
): Promise<IJob | IError> => {
  try {
    const data = {
      manualTours: manualTours.map((manualTour) => ({
        planVisitIds: manualTour.visits.map((visit) => visit.stopId),
        vehicleIds: manualTour.vehicleIds,
      })),
      maxClientsPerTour,
      optimParams,
      planVisitIds,
      useJob,
      vehicleIds,
      vehiclesAvailabilities,
    }
    const res = await Api.post(`plans/${planId}/optimize-multisites-manual-tours`, data)
    return res
  } catch (error) {
    return { error: { message: error.message } }
  }
}

export interface ICreateVisits {
  planId?: string
  startDate?: string
  endDate?: string
  deliveryTypes?: string[]
  transportTypes?: string[]
  warehouseIds?: string[]
  orderIds?: string[]
}

interface ICreateVisitsReturn {
  planTrCreated: number
  partiallyTreated: boolean
}

const createVisits = async ({
  startDate,
  endDate,
  deliveryTypes,
  transportTypes,
  warehouseIds,
  orderIds,
}: ICreateVisits): Promise<ICreateVisitsReturn | IError> => {
  try {
    const res = await Api.post('plan-trs', {
      startDate,
      endDate,
      deliveryTypes,
      transportTypes,
      warehouseIds,
      orderIds,
    })
    return res
  } catch (error) {
    return { error: { message: error.message } }
  }
}

export interface IDropVisitInPlan {
  planId: string
  visitId: string
  index: number
  automaticInsert?: boolean
}

const dropVisitInPlan = async ({
  planId,
  visitId,
  index,
  automaticInsert,
}: IDropVisitInPlan): Promise<void | IError> => {
  try {
    const res = await Api.put(`plan-tours/${planId}/visit`, {
      planVisitId: visitId,
      index,
      automaticInsert,
    })
    return res
  } catch (error) {
    return { error: { message: error.message } }
  }
}

export interface IDropVisitPlanStop {
  planVisitsToLoad: string[]
  planVisitsToDeliver: string[]
  transportType?: TransportType
}

export interface IDropVisitInPlanTourWithMultisites {
  planId: string
  planStops: IDropVisitPlanStop[]
  newOrUpdatedPlanStop: IDropVisitPlanStop
  automaticInsert: boolean
}

const dropVisitInPlanTourWithMultisites = async ({
  planId,
  planStops,
  newOrUpdatedPlanStop,
  automaticInsert,
}: IDropVisitInPlanTourWithMultisites): Promise<void | IError> => {
  try {
    const res = await Api.put(`plan-tours/${planId}/visit/multisites`, {
      planStops,
      newOrUpdatedPlanStop,
      automaticInsert,
    })
    return res
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const invalidatePlanTours = async (planTourIds: string[]): Promise<void | IError> => {
  try {
    const res = await Api.patch(`plan-tours/invalidate`, { planTourIds })
    return res
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const updatePlanTour = async (id: string, tour: ITour): Promise<void | IError> => {
  try {
    const res = await Api.patch(`plan-tours/${id}`, tour)
    return res
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const updatePlantTourStartHour = async (
  planTourId: string,
  startHour: string,
): Promise<void | IError> => {
  try {
    await Api.patch(`/plan-tours/${planTourId}/start-hour`, { startHour })
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const deletePlanTours = async (
  planTourIds: string[],
  shouldDeleteTour = true,
): Promise<IPlanTourDeleteResponse | IError> => {
  try {
    const res = await Api.delete('plan-tours', {
      planTourIds,
      confirmDeleteTour: shouldDeleteTour,
    })
    return res
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const validatePlanTours = async (planTourIds: string[]): Promise<void | IError> => {
  try {
    const res = await Api.patch(`plan-tours`, { planTourIds })
    return res
  } catch (error) {
    return { error: { message: error.message } }
  }
}

export interface IRemoveVisitsFromPlan {
  planId: string
  visitIds: string[]
}

const removeVisitsFromPlan = async ({
  planId,
  visitIds,
}: IRemoveVisitsFromPlan): Promise<void | IError> => {
  try {
    const res = await Api.delete(`plan-tours/${planId}/visits`, { planVisitIds: visitIds })
    return res
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const interfacePlanTour = async (id: string): Promise<void | IError> => {
  try {
    const res = await Api.post(`/plan-tours/${id}/interface`)
    return res
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const interfacePlanTours = async (planTourIds: string[]): Promise<void | IError> => {
  try {
    const res = await Api.post(`/plan-tours/interface`, { planTourIds })
    return res
  } catch (error) {
    return { error: { message: error.message } }
  }
}

interface IOptimizeReturn {
  isOptimizing: boolean
  planId: string
}

const isOptimizeRunning = async ({ planId }: { planId?: string }): Promise<boolean | IError> => {
  try {
    const res: IOptimizeReturn = await Api.get('plans/is-optimizing', { planId })
    return res.isOptimizing
  } catch (error) {
    return { error: { message: error.message, code: error.statusCode } }
  }
}

const deletePlan = async (planId: string): Promise<IPlanDeleteResponse[] | IError> => {
  try {
    const res = await Api.delete(`plans/${planId}`, { confirmDeleteTour: true })
    return res
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getPlan = async (planId: string): Promise<IPlan | IError> => {
  try {
    const res = await Api.get(`plans/${planId}/details`)
    return res
  } catch (error) {
    return { error: { message: error.message } }
  }
}

interface IReturnProcessingPlans {
  planToursId: string
  isProcessing: boolean
}

const getProcessingPlans = async ({
  planIds,
}: {
  planIds: string[]
}): Promise<IReturnProcessingPlans[] | IError> => {
  try {
    const res = await Api.get('plan-tours/is-processing', { planToursIds: [...planIds] })
    return res.planTours as IReturnProcessingPlans[]
  } catch (error) {
    return { error: { message: error.message } }
  }
}

async function switchVehicle({
  tourId,
  vehicleId,
  vehicleAvailability,
}: {
  tourId: string
  vehicleId: string
  vehicleAvailability?: IModifiedVehicleAvailability
}): Promise<{ status: boolean } | IError> {
  try {
    await Api.patch(`/plan-tours/${tourId}/switch-vehicle`, { vehicleId, vehicleAvailability })
    return { status: true }
  } catch (error) {
    return { error: { message: error.message } }
  }
}

interface IDeletePlanTrs {
  startDate: string
  endDate: string
  deliveryTypes?: string[]
  transportTypes?: string[]
}

interface IDeletePlanVisitsResponse {
  deleted: number
  remaining: number
  partiallyTreated: boolean
}

async function deletePlanVisits({
  startDate,
  endDate,
  deliveryTypes,
  transportTypes,
}: IDeletePlanTrs): Promise<IDeletePlanVisitsResponse | IError> {
  try {
    const response = await Api.delete(`/plan-visits`, {
      startDate,
      endDate,
      deliveryTypes,
      transportTypes,
    })
    return response
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const deletePlanToursByPlanId = async (
  planId: string,
  shouldDeleteTour: boolean,
): Promise<IPlanTourDeleteResponse | IError> => {
  try {
    const response = await Api.delete(`/plans/${planId}/plan-tours`, {
      confirmDeleteTour: shouldDeleteTour,
    })
    return response
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getOrderDetailsByPlanVisitId = async (planVisitId: string): Promise<IProduct[] | IError> => {
  try {
    const res = await Api.get(`/plan-visits/order-details/${planVisitId}`)
    return res
  } catch (error) {
    return { error: { message: error.message, code: error.statusCode } }
  }
}

const repairPlan = async (planId: string): Promise<void | IError> => {
  try {
    const response = await Api.post(`/plans/${planId}/repair`)
    return response
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const parseSinglePlanVisit = (planVisit: IPlanVisitData): IPlanVisitData =>
  ({
    planVisitsId: planVisit.planVisitsId,
    phoneNumber: planVisit.visitId?.phoneNumber,
    quantity: planVisit.visitId?.quantity as number,
    deliveryType: planVisit.deliveryType,
    deliveryDateTimeMinimum: planVisit.deliveryDateTimeMinimum,
    deliveryDateTimeMaximum: planVisit.deliveryDateTimeMaximum,
  } as IPlanVisitData)

const parsePlanVisits = (planVisits: IPlanVisitData[]): IPlanVisitData[] =>
  planVisits.map((planVisit) => parseSinglePlanVisit(planVisit))

const parseSinglePlanStop = (planStop: IPlanStop): IPlanStop => ({
  ...planStop,
  orderId: planStop.planVisitsToDeliver.length
    ? planStop.planVisitsToDeliver[0].visitId?.orderIds
      && planStop.planVisitsToDeliver[0].visitId?.orderIds[0]
    : undefined,
  planVisitsToDeliver: parsePlanVisits(planStop.planVisitsToDeliver),
  planVisitsToLoad: parsePlanVisits(planStop.planVisitsToLoad),
  status: STOP_STATUS_MAP.get(Number(planStop.status)),
})

const parsePlanStops = (stops: IPlanStop[]): IPlanStop[] =>
  stops.map((stop) => parseSinglePlanStop(stop))

interface IReturnStopsData {
  stopsNumber: number
  postalCodes: string[]
  departureDate?: Date | null
}

const parsePostalCode = (postalCodes: string[], address: IAddress): void => {
  if (address && address.zipCode) {
    if (!postalCodes.includes(address.zipCode)) {
      postalCodes.push(address.zipCode)
    }
  }
}

const extractPhoneNumber = (planVisits: IPlanVisitData[]): string => {
  let phoneNumber = ''
  planVisits.forEach((visit) => {
    if (visit.phoneNumber && visit.phoneNumber.length > 0) {
      phoneNumber = visit.phoneNumber
    }
  })
  return phoneNumber
}

const getPhoneNumber = (planStop: IPlanStop): void => {
  let phoneNumber = ''
  if (planStop.planVisitsToLoad && planStop.planVisitsToLoad.length > 0) {
    phoneNumber = extractPhoneNumber(planStop.planVisitsToLoad)
  }
  if (planStop.planVisitsToDeliver && planStop.planVisitsToDeliver.length > 0) {
    phoneNumber = extractPhoneNumber(planStop.planVisitsToDeliver)
  }
  // eslint-disable-next-line no-param-reassign
  planStop.phoneNumber = phoneNumber
}

const getStopsData = (stops: IPlanStop[], departureAddress: IAddress): IReturnStopsData => {
  let stopsNumber = stops.length - 2
  if (stopsNumber < 0) stopsNumber = 0
  const postalCodes: string[] = []
  let departureDate: Date | null = null
  if (stops && stops.length > 0) {
    stops.forEach((planStop: IPlanStop) => {
      if (departureAddress.full !== planStop.address.full) {
        parsePostalCode(postalCodes, planStop.address)
      }
      if (planStop.plannedArrival && !departureDate) {
        departureDate = planStop.plannedArrival
      }
      getPhoneNumber(planStop)
    })
  }
  return {
    stopsNumber,
    postalCodes,
    departureDate,
  }
}

const parseSinglePlanTour = (planTour: IPlanTourData): IPlanTourData => {
  const stops = parsePlanStops(planTour.planStops as IPlanStop[])
  return {
    planToursId: planTour.planToursId,
    tourNumber: planTour.tourNumber,
    carrierId: planTour.carrierId,
    vehicleId: planTour.vehicleId,
    driver: planTour.driverId,
    status: PLAN_TOUR_STATUS_MAP.get(Number(planTour.status)) || null,
    departureAddress: planTour.departureAddress,
    deliveryType:
      stops[0].planVisitsToLoad[0] !== undefined
        ? stops[0].planVisitsToLoad[0].deliveryType
        : undefined,
    departureDateTime: planTour.beginDateTime,
    arrivalDateTime: planTour.endDateTime,
    departureWarehouse: planTour.departureWarehouse,
    ...getStopsData(stops, planTour.departureAddress),
    quantity: planTour.mainQuantity,
    mainQuantityToDeliver: planTour.mainQuantityToDeliver,
    mainQuantityToLoad: planTour.mainQuantityToLoad,
    stops,
    toOptimize: planTour.toOptimize,
  }
}

const parsePlanTours = (planTours: IPlanTourData[]): IPlanTourData[] =>
  planTours.map((item) => parseSinglePlanTour(item))

const getPlanToursList = async (
  filters?: IPlanTourFilters,
  offset?: number,
  rowsPerPage?: number,
  sortBy?: string,
  sortDirection?: number,
): Promise<{ planTours: IPlanTourData[]; count: number } | IError> => {
  try {
    const filtersToApply = {
      ...filters,
      warehouses: undefined,
      warehouseIds: filters?.warehouses,
      carrierIds: filters?.carriers,
      carriers: undefined,
      searchText: undefined,
      ...((filters?.searchText?.length || 0) >= 3 && { search: filters?.searchText }),
    }
    const response = await Api.get('/plan-tours', {
      ...filtersToApply,
      offset,
      limit: rowsPerPage,
      sortBy: sortBy || 'tourNumber',
      sortDirection: sortDirection || 1,
    })
    return { planTours: parsePlanTours(response.items), count: response.count }
  } catch (error) {
    return { error: { message: error.message } }
  }
}

interface IPlanUpdateTour {
  planTourId: string
  status: string | null
}

const updatePlanTourStatus = async ({
  planTourId,
  status,
}: IPlanUpdateTour): Promise<IPlanTourData | IError> => {
  try {
    const res = await Api.patch(`plan-tours/${planTourId}/status`, {
      status: status ? Number(status) : undefined,
    })
    return parseSinglePlanTour(res)
  } catch (error) {
    return { error: { message: error.message } }
  }
}

export enum GroupAction {
  Add = 'add',
  Remove = 'remove',
}

export interface IUpdate {
  groupId?: string
  planVisitIds?: string[]
  action: GroupAction
  vehicleIds?: string[]
}
interface IUpdatePlanVisitGroup {
  updates: IUpdate[]
}

const updatePlanVisitsGroup = async (args: IUpdatePlanVisitGroup): Promise<void | IError> => {
  try {
    await Api.patch('plan-visits/group', args)
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

const getOngoingPlanJobs = async (planId: string): Promise<IJob[] | IError> => {
  try {
    const res = await Api.get(`plans/${planId}/jobs`)
    return res
  } catch (error) {
    return { error: { message: error.message } }
  }
}

interface IOutsourcePlanVisits {
  carrierId: string
  planVisitsIds: string[]
}

const outsourcePlanVisits = async (args: IOutsourcePlanVisits): Promise<void | IError> => {
  try {
    await Api.post('plan-tours/outsource', args)
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

const formatReorganizePlanVisitsPlanTours = (planTours: ITourMapItem[]) =>
  planTours.map((planTour) => {
    const planVisitIds = planTour.stops.flatMap((planStop) => planStop.planVisitsToDeliver)
    return {
      planVisitIds,
      vehicleIds: [planTour.vehicleId],
      startHour: planTour.beginDateTime,
      tourNumber: planTour.tourNumber,
    }
  })

const formatReorganizeMultisitesPlanVisitsPlanTours = (planTours: ITourMapItem[]) =>
  planTours.map((planTour) => {
    const planStops = planTour.stops.map((planStop) => ({
      planVisitsToDeliver: planStop.planVisitsToDeliver ?? [],
      planVisitsToLoad: planStop.planVisitsToLoad ?? [],
      transportType: planStop.transportType,
    }))
    return {
      planStops,
      vehicleIds: [planTour.vehicleId],
      startHour: planTour.beginDateTime,
      tourNumber: planTour.tourNumber,
    }
  })

const reorganizePlanVisitsMultisites = async (
  planId: string,
  planTours: ITourMapItem[],
): Promise<IJob | IError> => {
  try {
    const customTours = formatReorganizeMultisitesPlanVisitsPlanTours(planTours)
    const res = await Api.post(`plans/${planId}/reorganize-multisites`, {
      customTours,
    })
    return res
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const reorganizePlanVisits = async (
  planId: string,
  planTours: ITourMapItem[],
): Promise<IJob | IError> => {
  try {
    const customTours = formatReorganizePlanVisitsPlanTours(planTours)

    const res = await Api.post(`plans/${planId}/reorganize`, {
      customTours,
    })
    return res
  } catch (error) {
    return { error: { message: error.message } }
  }
}

export default {
  getPlansVisits,
  savePlan,
  getPlans,
  getPlanTours,
  optimizePlan,
  optimizeClusters,
  createVisits,
  dropVisitInPlan,
  isOptimizeRunning,
  deletePlan,
  getProcessingPlans,
  removeVisitsFromPlan,
  updatePlanTour,
  validatePlanTours,
  invalidatePlanTours,
  switchVehicle,
  interfacePlanTour,
  interfacePlanTours,
  deletePlanVisits,
  getPlanToursList,
  deletePlanTours,
  deletePlanToursByPlanId,
  updatePlantTourStartHour,
  getPlan,
  updatePlanTourStatus,
  optimizePlanWithManualTours,
  optimizeMultisitePlan,
  optimizeMultisitePlanWithManualTours,
  dropVisitInPlanTourWithMultisites,
  updatePlanVisitsGroup,
  getOrderDetailsByPlanVisitId,
  repairPlan,
  getOngoingPlanJobs,
  outsourcePlanVisits,
  reorganizePlanVisits,
  reorganizePlanVisitsMultisites,
}
