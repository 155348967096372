import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core/'
import { useTranslation } from 'react-i18next'

interface IProps {
  open: boolean
  onCancel(): void
}

const DeletePlanWarningModal = ({ onCancel, open }: IProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Dialog open={open} aria-describedby="modal-description">
      <DialogContent>
        <DialogContentText id="modal-description">
          {t('PlanningScreen.deleteWarningMessage')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          {t('PlanningScreen.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeletePlanWarningModal
