import React from 'react'

import { COLUMNS_MODEL_CARRIER } from 'constants/table'
import { AVAILABLE_FILTERS } from 'constants/constants'
import { ToursConsumer } from 'store/ToursContext'
import { ITour } from 'interfaces/Itours'
import Table from 'components/Table'
import { FilterKey } from 'constants/filters'

interface IExecutionProps {
  tours: ITour[]
}

const CarrierExecutionScreen = ({ tours }: IExecutionProps): JSX.Element => (
  <Table
    showExtendedDateFilter
    extendedDateFilter={{
      today: true,
      tomorrow: true,
      thisWeek: true,
      next7days: true,
    }}
    columns={COLUMNS_MODEL_CARRIER}
    data={tours}
    hideFilters={[AVAILABLE_FILTERS.CARRIERS, AVAILABLE_FILTERS.DELIVERY_TYPES]}
    name="carrierExecution"
    filterKey={FilterKey.carrierExecution}
  />
)

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <ToursConsumer>
    {(ctx): JSX.Element => <CarrierExecutionScreen tours={ctx.tours} {...props} />}
  </ToursConsumer>
)
