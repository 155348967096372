import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormControlLabel, Switch } from '@material-ui/core'

import useStyles from 'constants/cruStyles'
import Card from 'components/Card/Card'
import { IPlanningEndCustomerPageConfig, IToggleChange } from 'interfaces/interfaces'

interface IProps {
  planningEndCustomerPage?: Partial<IPlanningEndCustomerPageConfig>
  onChange: (configs: IPlanningEndCustomerPageConfig) => void
}

const WarehousePlanningEndCustomerPageCard = ({
  planningEndCustomerPage,
  onChange,
}: IProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [config, setConfig] = useState<Partial<IPlanningEndCustomerPageConfig>>({
    driverInformation: planningEndCustomerPage?.driverInformation || false,
    driverPosition: planningEndCustomerPage?.driverPosition || false,
    driverPhoneNumber: planningEndCustomerPage?.driverPhoneNumber || false,
    customerCare: planningEndCustomerPage?.customerCare || false,
    customerEvaluation: planningEndCustomerPage?.customerEvaluation || false,
    customerComments: planningEndCustomerPage?.customerComments || false,
    clientCode: planningEndCustomerPage?.clientCode || false,
  })

  const handleChange = (event: IToggleChange): void => {
    const { target } = event
    const { name } = target
    const value = target.checked

    onChange({ ...config, [name]: value } as IPlanningEndCustomerPageConfig)
    setConfig({ ...config, [name]: value })
  }

  return (
    <Card title={t('SitesScreen.planningEndCustomerPage').toUpperCase()}>
      <div className={classes.fieldsContainer}>
        <div>
          <FormControlLabel
            className={classes.switchContainer}
            control={(
              <Switch
                color="primary"
                checked={config.driverInformation}
                onChange={handleChange}
                name="driverInformation"
              />
            )}
            labelPlacement="start"
            label={t('SitesScreen.planningEndCustomerPageConfigs.driverInformation').toUpperCase()}
          />
        </div>
        <div>
          <FormControlLabel
            className={classes.switchContainer}
            control={(
              <Switch
                color="primary"
                checked={config.driverPosition}
                onChange={handleChange}
                name="driverPosition"
              />
            )}
            labelPlacement="start"
            label={t('SitesScreen.planningEndCustomerPageConfigs.driverPosition').toUpperCase()}
          />
        </div>
        <div>
          <FormControlLabel
            className={classes.switchContainer}
            control={(
              <Switch
                color="primary"
                checked={config.driverPhoneNumber}
                onChange={handleChange}
                name="driverPhoneNumber"
              />
            )}
            labelPlacement="start"
            label={t('SitesScreen.planningEndCustomerPageConfigs.driverPhoneNumber').toUpperCase()}
          />
        </div>
        <div>
          <FormControlLabel
            className={classes.switchContainer}
            control={(
              <Switch
                color="primary"
                checked={config.customerCare}
                onChange={handleChange}
                name="customerCare"
              />
            )}
            labelPlacement="start"
            label={t('SitesScreen.planningEndCustomerPageConfigs.customerCare').toUpperCase()}
          />
        </div>
        <div>
          <FormControlLabel
            className={classes.switchContainer}
            control={(
              <Switch
                color="primary"
                checked={config.customerEvaluation}
                onChange={handleChange}
                name="customerEvaluation"
              />
            )}
            labelPlacement="start"
            label={t('SitesScreen.planningEndCustomerPageConfigs.customerEvaluation').toUpperCase()}
          />
        </div>
        <div>
          <FormControlLabel
            className={classes.switchContainer}
            control={(
              <Switch
                color="primary"
                checked={config.customerComments}
                onChange={handleChange}
                name="customerComments"
              />
            )}
            labelPlacement="start"
            label={t('SitesScreen.planningEndCustomerPageConfigs.customerComments').toUpperCase()}
          />
        </div>
        <div>
          <FormControlLabel
            className={classes.switchContainer}
            control={(
              <Switch
                color="primary"
                checked={config.clientCode}
                onChange={handleChange}
                name="clientCode"
              />
            )}
            labelPlacement="start"
            label={t('SitesScreen.planningEndCustomerPageConfigs.clientCode').toUpperCase()}
          />
        </div>
      </div>
    </Card>
  )
}

export default WarehousePlanningEndCustomerPageCard
