import { GroupWork, PlaylistAdd, ExitToApp } from '@material-ui/icons'
import React, { useContext, useState } from 'react'
import { createStyles } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ExploreIcon from '@material-ui/icons/Explore'
import ExploreOffIcon from '@material-ui/icons/ExploreOff'
import MapIcon from '@material-ui/icons/Map'
import Subtitles from '@material-ui/icons/Subtitles'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import SettingsIcon from '@material-ui/icons/Settings'
import PlaceIcon from '@material-ui/icons/Place'

import { SpeedDialAction } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'
import { AuthContext } from 'store/AuthContext'
import { StyledSpeedDial } from './BasicComponents'
import { PlanificationContext } from '../PlanningStore'
import { screenMode } from './Planning'

interface IFabMenu {
  cardSettingsOpened: boolean
  displayMode: number
  toggleDisplayMode(): void
  toggleClusterMode?: () => void
  toggleToolTipConfigModal: (event: React.MouseEvent<HTMLDivElement>) => void
  toggleCardSettingsModal: (event: React.MouseEvent<HTMLDivElement>) => void
  toggleOutsourceMode?: () => void
}

const useStyles = makeStyles(() =>
  createStyles({
    noWrap: {
      whiteSpace: 'nowrap',
    },
  }),
)

function FabMenu({
  cardSettingsOpened,
  displayMode,
  toggleDisplayMode,
  toggleClusterMode,
  toggleToolTipConfigModal,
  toggleCardSettingsModal,
  toggleOutsourceMode,
}: IFabMenu): JSX.Element {
  const [open, setOpen] = useState<boolean>(false)
  const {
    hiddenTours,
    toggleAllToursVisibility,
    isClusterMode,
    areClustersHidden,
    setClustersVisibility,
    isMapotempoActionInProgress,
    isMultisite,
    isOutsourceMode,
    areOutsourcedHidden,
    setOutsourcedVisibility,
  } = useContext(PlanificationContext)
  const { t } = useTranslation()
  const classes = useStyles()
  const { shouldDisplayPinToolTipFields } = useContext(AuthContext)

  function handleOpen() {
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  function toggleDisplayElements() {
    if (isClusterMode) {
      setClustersVisibility(!areClustersHidden)
      return
    }

    if (isOutsourceMode) {
      setOutsourcedVisibility(!areOutsourcedHidden)
      return
    }

    toggleAllToursVisibility()
  }

  const getVisibilityActionName = () => {
    if (isClusterMode) {
      if (areClustersHidden) {
        return t('PlanningScreen.showClusters')
      }
      return t('PlanningScreen.hideClusters')
    }

    if (isOutsourceMode) {
      if (areOutsourcedHidden) {
        return t('PlanningScreen.showOutsourced')
      }
      return t('PlanningScreen.hideOutsourced')
    }

    if (hiddenTours.length > 0) {
      return t('PlanningScreen.showTours')
    }

    return t('PlanningScreen.hideTours')
  }

  const getVisibilityActionIcon = () => {
    if (
      (isClusterMode && areClustersHidden)
      || (isOutsourceMode && areOutsourcedHidden)
      || hiddenTours.length > 0
    ) {
      return <VisibilityIcon />
    }

    return <VisibilityOffIcon />
  }

  // The first entry is displayed at bottom
  const actions = [
    {
      name: getVisibilityActionName(),
      icon: getVisibilityActionIcon(),
      action: toggleDisplayElements,
    },
    {
      name: t(
        isOutsourceMode ? 'PlanningScreen.planifTourMode' : 'PlanningScreen.planifOutsourceMode',
      ),
      icon: isOutsourceMode ? <PlaylistAdd /> : <ExitToApp />,
      action: toggleOutsourceMode,
      hidden: isMultisite,
    },
    {
      name: t(isClusterMode ? 'PlanningScreen.planifTourMode' : 'PlanningScreen.planifClusterMode'),
      icon: isClusterMode ? <PlaylistAdd /> : <GroupWork />,
      action: toggleClusterMode,
      hidden: isMultisite,
    },
    {
      name: t('PlanningScreen.displayMode'),
      icon:
        (displayMode === screenMode.map && <MapIcon />)
        || (displayMode === screenMode.drawer && <ExploreOffIcon />)
        || (displayMode === screenMode.both && <ExploreIcon />),
      action: toggleDisplayMode,
    },
    {
      name: t('PlanningScreen.configurePinTooltip'),
      icon: <PlaceIcon />,
      action: toggleToolTipConfigModal,
      hidden: !shouldDisplayPinToolTipFields,
    },
    {
      name: t('PlanningScreen.cardSettings'),
      icon: <Subtitles />,
      action: toggleCardSettingsModal,
    },
  ]

  return (
    <StyledSpeedDial
      ariaLabel={t('PlanningScreen.displayOptionsButton')}
      icon={<SettingsIcon style={{ color: 'white' }} fontSize="large" />}
      onClose={handleClose}
      onOpen={handleOpen}
      open={(open && !isMapotempoActionInProgress) || cardSettingsOpened}
      data-cy="displayOptionsButton"
    >
      {actions
        .filter((action) => !action.hidden)
        .map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipPlacement="right"
            tooltipOpen
            onClick={action.action}
            classes={{ staticTooltip: classes.noWrap }}
            data-cy={action.name}
          />
        ))}
    </StyledSpeedDial>
  )
}

export default FabMenu
