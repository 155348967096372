import { ApplicationInsights } from '@microsoft/applicationinsights-web'

import { APP_INSIGHT_CONNECTION_STRING as connectionString } from 'constants/env'

const appInsights = new ApplicationInsights({
  config: {
    connectionString,
  },
})
appInsights.loadAppInsights()
appInsights.trackPageView()

export default appInsights
