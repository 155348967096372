import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import { IStopMarker } from 'interfaces/map'
import StopDelay from 'components/Table/StopDelay'
import VehicleAllocatedCapacityPercentage from './VehicleAllocatedCapacityPercentage'
import useStyles from '../styles'

const TourNumber = styled.b<{ color: string }>`
  color: ${(props) => props.color};
`

interface ITourSummary {
  color: string
  tourNumber: number
  driverName?: string
  toleratedAdvance?: number
  toleratedDelay?: number
  stopsNumber: number
  realizedStopsNumber: number
  vehicleAllocatedCapacityPercentage: number | null
  lastStop: IStopMarker
}

const TourSummary = ({
  color,
  tourNumber,
  driverName,
  stopsNumber,
  realizedStopsNumber,
  vehicleAllocatedCapacityPercentage,
  toleratedAdvance,
  toleratedDelay,
  lastStop,
}: ITourSummary): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <div
        className={clsx(classes.tourInfoContainer, classes.tourNumberContainer)}
        data-cy="tourNumberContainer"
      >
        <TourNumber color={color} className={classes.tourNumber} data-cy="tourNumber">
          {tourNumber}
        </TourNumber>
        <div data-cy="driverName">{driverName}</div>
      </div>
      <div data-cy="tourInfoContainer" className={classes.tourInfoContainer}>
        <div data-cy="stopNumber">{`${realizedStopsNumber}/${stopsNumber}`}</div>
        <div>{t('MapScreen.stops')}</div>
      </div>
      <div className={classes.rightContainer}>
        <VehicleAllocatedCapacityPercentage percentage={vehicleAllocatedCapacityPercentage} />
        <StopDelay
          toleratedAdvance={toleratedAdvance}
          toleratedDelay={toleratedDelay}
          stop={lastStop}
        />
      </div>
    </>
  )
}

export default TourSummary
