import React, { useContext } from 'react'
import styled from 'styled-components'
import { Paper } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { isFeatureAvailable } from 'utils/isFeatureAvailable'
import { NAVIGATION_CONFIG } from 'navigation/NavigationConfig'
import { AuthContext } from 'store/AuthContext'
import {
  ORDER_REPORTING_MENU,
  EXECUTION_REPORTING_MENU,
  PLANNING_REPORTING_MENU,
  CONFIGURATIONS_MENU,
} from 'constants/constants'

interface IRowProps {
  isOddRow: boolean
}

const Row = styled.div<IRowProps>`
  background-color: ${(props): string => (props.isOddRow ? 'transparent' : 'grey')};
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
`

const Cell = styled.p<{ bold?: boolean }>`
  font-weight: ${(props): string => (props.bold ? 'bold' : 'normal')};
  flex: 1;
`

function FeatureFlagsScreen(): JSX.Element {
  const { features: userFeatures } = useContext(AuthContext)
  const { t } = useTranslation()

  const reportingFlags = [
    ...ORDER_REPORTING_MENU,
    ...EXECUTION_REPORTING_MENU,
    ...PLANNING_REPORTING_MENU,
  ].map((reportingFeature) => ({
    path: reportingFeature.featureFlag,
    description: reportingFeature.label,
    isBetaMode: reportingFeature.isBetaMode,
    hideInFeaturesList: reportingFeature.hideInFeaturesList,
  }))

  const configFlags = [...CONFIGURATIONS_MENU].map((configFeature) => ({
    path: configFeature.featureFlag,
    description: configFeature.label,
    isBetaMode: configFeature.isBetaMode,
    hideInFeaturesList: configFeature.hideInFeaturesList,
  }))

  const featureFlags = [...NAVIGATION_CONFIG, ...reportingFlags, ...configFlags]

  return (
    <Paper elevation={4}>
      <Row isOddRow={false}>
        <Cell bold>{t('FeaturesScreen.path')}</Cell>
        <Cell bold>{t('FeaturesScreen.description')}</Cell>
        <Cell bold>{t('FeaturesScreen.activated')}</Cell>
      </Row>
      {featureFlags
        .filter((feature) => feature.isBetaMode && !feature.hideInFeaturesList)
        .map((feature, index) => (
          <Row isOddRow={index % 2 === 0} key={feature.path}>
            <Cell>{feature.path}</Cell>
            <Cell>{feature.description}</Cell>
            <Cell>
              {isFeatureAvailable(userFeatures, feature)
                ? t('FeaturesScreen.yes')
                : t('FeaturesScreen.no')}
            </Cell>
          </Row>
        ))}
    </Paper>
  )
}

export default FeatureFlagsScreen
