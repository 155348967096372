import React, { useState, useContext, useEffect } from 'react'

import { TypeOfTour } from 'interfaces/interfaces'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import { ISector } from 'interfaces/ISector'
import { FeedbackContext } from 'store/FeedbackContext'
import SitesApi from 'api/sites'
import { isIError } from 'api/types'
import PlanificationProvider, { PlanificationContext } from './PlanningStore'
import Planning from './components/Planning'

function PlanificationScreen(): JSX.Element {
  const [sectors, setSectors] = useState<ISector[]>([])
  const {
    selectedPlan,
    hideAllTours,
    isClusterMode,
    setClusterMode,
    isOutsourceMode,
    setIsOutsourceMode,
  } = useContext(PlanificationContext)
  const { openErrorSnack, toggleLoader } = useContext(FeedbackContext)

  async function fetchWarehouseDetails(id: string): Promise<void> {
    toggleLoader(true)
    const response = await SitesApi.get(id)
    if (isIError(response)) {
      setSectors([])
      setClusterMode(false)
      openErrorSnack(response.error.message)
    } else {
      setSectors(response.sectors || [])
      setClusterMode(selectedPlan?.tourType ? false : response.tourType === TypeOfTour.Sector)
    }
    toggleLoader(false)
  }

  useEffect(() => {
    setSectors([])
    setClusterMode(false)
    if (selectedPlan?.warehouseId) {
      fetchWarehouseDetails(selectedPlan.warehouseId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlan])

  function toggleClusterMode(): void {
    hideAllTours(!isClusterMode)
    setIsOutsourceMode(false)
    setClusterMode(!isClusterMode)
  }

  function toggleOutsourceMode(): void {
    hideAllTours(!isOutsourceMode)
    setClusterMode(false)
    setIsOutsourceMode(!isOutsourceMode)
  }

  return (
    <Planning
      sectors={sectors}
      path={ROUTES_PATH.planning}
      toggleClusterMode={toggleClusterMode}
      toggleOutsourceMode={toggleOutsourceMode}
    />
  )
}

export default (): JSX.Element => (
  <PlanificationProvider>
    <PlanificationScreen />
  </PlanificationProvider>
)
