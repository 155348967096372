import React from 'react'

import { ROUTES_PATH } from 'navigation/RoutesPath'
import PlanificationProvider from '../PlanningScreen/PlanningStore'
import Planning from '../PlanningScreen/components/Planning'

const MultisitePlanningScreen = (): JSX.Element => (
  <Planning sectors={[]} path={ROUTES_PATH.multisitePlanning} />
)

export default (): JSX.Element => (
  <PlanificationProvider isMultisite>
    <MultisitePlanningScreen />
  </PlanificationProvider>
)
