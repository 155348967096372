import React, { useEffect, useMemo, useContext, useState, useRef } from 'react'
import MaterialTable, { Action } from 'material-table'
import ArrowForward from '@material-ui/icons/ArrowForward'
import RefreshIcon from '@material-ui/icons/Refresh'

import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import { ITableColumn } from 'interfaces/interfaces'
import MUITableIcons from 'constants/MUITableIcons'
import Button from 'components/Button/CustomButton'
import AppConfigProvider, { AppConfigContext } from 'store/AppConfigContext'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import { WarehouseTimeSlotsContext } from 'store/WarehouseTimeSlotsContext'
import { getPageSize, savePageSize } from 'utils/localStorage'
import { scrollTop } from 'utils/functions'
import { IWarehouseTimeSlot } from 'interfaces/IWarehouseTimeSlot'
import { FiltersContext } from 'store/FiltersContext'
import { Filter, FilterKey } from 'constants/filters'
import { IconButton } from '@material-ui/core'
import { ActiveFilter, SiteFilter } from 'components/Inputs/ListFilter'
import SearchInput from 'components/Inputs/SearchInput'
import { getDisplayRowsCount } from 'utils/tableUtils'
import useStyles from './styles'
import Pagination from './Pagination/Pagination'

interface ICustomTableProps {
  columns: ITableColumn[]
  warehouseTimeSlots: IWarehouseTimeSlot[]
  count: number
}

const columnConfigName = 'warehouseTimeSlotsList'
const filterKey = FilterKey.warehouseTimeSlots

const CustomTable = ({
  columns,
  warehouseTimeSlots,
  count,
  ...rest
}: ICustomTableProps): JSX.Element => {
  const isInitialMount = useRef(true)
  const styles = useStyles()
  const history = useHistory()
  const { tablesConfig } = useContext(AppConfigContext)
  const { t } = useTranslation()
  const [paginationOffset, setPaginationOffset] = useState<number>(0)
  const { setFilter, resetFilters, filters } = useContext(FiltersContext)
  const active = filters[filterKey][Filter.active] as string[]
  const warehouseIds = filters[filterKey][Filter.sites] as string[]
  const searchText = filters[filterKey][Filter.searchText] as string
  const [isSearchReset, setIsSearchReset] = useState<boolean>(false)
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(getPageSize)
  const { getList } = useContext(WarehouseTimeSlotsContext)

  useEffect(() => {
    if (rowsPerPage !== getPageSize()) {
      savePageSize(rowsPerPage)
      scrollTop()
    }
    if (getList) {
      getList({ active, searchText, warehouseIds }, 0, rowsPerPage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, searchText, warehouseIds, rowsPerPage])

  useEffect(() => {
    if (getList && !isInitialMount.current) {
      getList({ active, warehouseIds }, paginationOffset, rowsPerPage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationOffset])

  const resetLocalFilters = (): void => {
    setIsSearchReset(true)
    resetFilters(filterKey)
  }

  const handleNewClick = (): void => {
    history.push(ROUTES_PATH.createWarehouseTimeSlot)
  }

  const handleDetailsClick = ({ id }: IWarehouseTimeSlot): void => {
    history.push(`${ROUTES_PATH.warehouseTimeSlotDetails}${id}`)
  }

  const Filters = useMemo(
    (): JSX.Element => (
      <div className={clsx(styles.filterContainer, styles.noFilter)}>
        <div className={styles.filterLeftContainer}>
          <IconButton onClick={resetLocalFilters}>
            <RefreshIcon />
          </IconButton>
          <ActiveFilter
            dataCy="activePicker"
            handleChange={(activeValues: string[]): void => {
              setFilter(filterKey, Filter.active, activeValues)
            }}
            ids={active}
          />
          <SiteFilter
            dataCy="sitePicker"
            handleChange={(siteValues: string[]): void => {
              setFilter(filterKey, Filter.sites, siteValues)
            }}
            ids={warehouseIds}
            isUnselectAllowed={false}
          />
        </div>
        <div className={styles.filterRightContainer}>
          <SearchInput
            defaultValue={searchText}
            key={isSearchReset ? 'search-reset' : ''}
            onSearch={(text: string): void => {
              setIsSearchReset(false)
              setFilter(filterKey, Filter.searchText, text)
            }}
          />
          <Button dataCy="newButton" className={styles.newButton} onPress={handleNewClick}>
            {t('tablesEntries.new')}
          </Button>
        </div>
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [active, warehouseIds, searchText, styles],
  )

  const handleChangeRowsPerPage = (pageSize: number): void => {
    setPage(0)
    setPaginationOffset(0)
    setRowsPerPage(pageSize)
  }

  const displayRowsCount = useMemo(
    () => getDisplayRowsCount(warehouseTimeSlots),
    [warehouseTimeSlots],
  )

  return (
    <>
      {Filters}
      <MaterialTable
        key={displayRowsCount}
        data={warehouseTimeSlots}
        columns={columns.map(
          (col) =>
            ({
              ...col,
              hidden: tablesConfig[columnConfigName]?.includes(col.title),
            } as unknown as MaterialTable<object>),
        )}
        {...rest}
        options={{
          toolbar: false,
          pageSize: displayRowsCount,
          actionsColumnIndex: -1,
        }}
        actions={[
          (data: IWarehouseTimeSlot): Action<IWarehouseTimeSlot> => ({
            icon: ArrowForward,
            tooltip: t('tablesEntries.seeDetails'),
            onClick: (): void => handleDetailsClick(data),
          }),
        ]}
        icons={MUITableIcons}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        components={{
          // eslint-disable-next-line react/prop-types, @typescript-eslint/no-unused-vars
          Pagination: ({ classes, ...props }): JSX.Element => (
            <Pagination
              {...props}
              columnConfigName={columnConfigName}
              columns={columns}
              count={count}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setPaginationOffset={setPaginationOffset}
            />
          ),
        }}
      />
    </>
  )
}

export default (props: ICustomTableProps): JSX.Element => (
  <AppConfigProvider>
    <CustomTable {...props} />
  </AppConfigProvider>
)
