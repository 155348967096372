import React, { useState, createContext, ReactNode, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { isIError, IError } from 'api/types'
import CostConfigurationsApi from 'api/costConfigurations/costConfigurations'
import { getErrorList } from 'utils/errorUtils'
import { FeedbackContext } from 'store/FeedbackContext'
import { ICostConfiguration, IWarehouseCostReporting } from 'interfaces/ICostConfiguration'

interface ICostConfigurationsContext {
  getDetails: (id: string) => Promise<void>
  costConfigurations: ICostConfiguration[]
  count: number
  error?: IError
  getList: (paginationOffset: number, rowsPerPage: number, carriers?: string[]) => void
  updateSuccess?: boolean
  createSuccess?: boolean
  costConfigurationsDetails: Partial<ICostConfiguration>
  createCostConfiguration: (costConfiguration: ICostConfiguration) => Promise<void>
  updateCostConfiguration: (id: string, costConfigurations: ICostConfiguration) => void
  getReporting: (startDate: string, endDate: string, carriersToFilter: string[]) => void
  costsReporting: IWarehouseCostReporting[]
}

const CostConfigurationsContext = createContext<ICostConfigurationsContext>(
  {} as ICostConfigurationsContext,
)

const { Provider } = CostConfigurationsContext

interface IProps {
  children: ReactNode
}

const CostConfigurationsProvider = ({ children }: IProps): JSX.Element => {
  const { openErrorSnack, openSuccessSnack, toggleLoader } = useContext(FeedbackContext)
  const [costConfigurations, setCostConfigurations] = useState<ICostConfiguration[]>([])
  const [costsReporting, setCostsReporting] = useState<IWarehouseCostReporting[]>([])
  const [count, setCount] = useState<number>(0)
  const [costConfigurationsDetails, setCostConfigurationsDetails] = useState<
    Partial<ICostConfiguration>
  >({})
  const [createSuccess, setCreateSuccess] = useState<boolean | undefined>()
  const [updateSuccess, setUpdateSuccess] = useState<boolean | undefined>()
  const { t } = useTranslation()

  const getList = async (
    offset?: number,
    rowsPerPage?: number,
    carriers?: string[],
  ): Promise<void> => {
    toggleLoader(true)
    const response = await CostConfigurationsApi.getList(offset, rowsPerPage, carriers)
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      setCostConfigurations(response.costConfigurations)
      setCount(response.count)
    }
    toggleLoader(false)
  }

  const getDetails = async (id: string): Promise<void> => {
    toggleLoader(true)
    const response = await CostConfigurationsApi.get(id)
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      setCostConfigurationsDetails(response)
    }
    toggleLoader(false)
  }

  const createCostConfiguration = async (costConfiguration: ICostConfiguration): Promise<void> => {
    toggleLoader(true)
    const response = await CostConfigurationsApi.create({
      ...costConfiguration,
      costPerStop: costConfiguration.costPerStop ?? 0,
      costPerTour: costConfiguration.costPerTour ?? 0,
      costPerKm: costConfiguration.costPerKm ?? 0,
    })
    if (isIError(response)) {
      response.error.errorList = getErrorList(response)
      if (response.error.fieldErrors) {
        openErrorSnack(response.error.errorList.join('\n'))
      } else {
        openErrorSnack(response.error.message)
      }
    } else {
      setCreateSuccess(true)
      openSuccessSnack(t('CostConfigurationsScreen.configurationSuccessfullyCreated'))
    }
    toggleLoader(false)
  }

  const updateCostConfiguration = async (
    id: string,
    costConfiguration: ICostConfiguration,
  ): Promise<void> => {
    toggleLoader(true)
    const response = await CostConfigurationsApi.update(id, {
      ...costConfiguration,
      costPerStop: costConfiguration.costPerStop ?? 0,
      costPerTour: costConfiguration.costPerTour ?? 0,
      costPerKm: costConfiguration.costPerKm ?? 0,
    })
    if (isIError(response)) {
      response.error.errorList = getErrorList(response)
      if (response.error.fieldErrors) {
        openErrorSnack(response.error.errorList.join('\n'))
      } else {
        openErrorSnack(response.error.message)
      }
    } else {
      setUpdateSuccess(true)
      openSuccessSnack(t('CostConfigurationsScreen.successfullySaved'))
    }
    toggleLoader(false)
  }

  const getReporting = async (
    startDate: string,
    endDate: string,
    carriersToFilter: string[],
  ): Promise<void> => {
    toggleLoader(true)
    const res = await CostConfigurationsApi.getReporting(startDate, endDate, carriersToFilter)
    if (isIError(res)) {
      openErrorSnack(res.error.message)
    } else {
      setCostsReporting(res)
    }
    toggleLoader(false)
  }

  return (
    <Provider
      value={{
        getDetails,
        costConfigurations,
        count,
        getList,
        updateCostConfiguration,
        createSuccess,
        updateSuccess,
        costConfigurationsDetails,
        createCostConfiguration,
        getReporting,
        costsReporting,
      }}
    >
      {children}
    </Provider>
  )
}
export { CostConfigurationsContext }

export default CostConfigurationsProvider
