import React from 'react'
import LockIcon from '@material-ui/icons/Lock'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import { Tooltip } from '@material-ui/core'
import i18n from 'i18next'

const STATUS_COLORS = ['orange', 'chartreuse', 'chartreuse', 'white']

const STATUS_TOOLTIP = [
  'tablesEntries.planTourStatus.planned',
  'tablesEntries.planTourStatus.validated',
  'tablesEntries.planTourStatus.validated',
  'tablesEntries.planTourStatus.interfaced',
]

interface IToursStatusProps {
  status: number
}

// Modulos are here to avoid crash if status > 2 || Make me feels smart
const TourStatus = ({ status }: IToursStatusProps): JSX.Element => (
  <Tooltip data-cy="lock" title={`${i18n.t(STATUS_TOOLTIP[status % STATUS_TOOLTIP.length])}`}>
    {status === 0 ? (
      <LockOpenIcon
        style={{ color: STATUS_COLORS[status % STATUS_COLORS.length], marginRight: 6 }}
      />
    ) : (
      <LockIcon style={{ color: STATUS_COLORS[status % STATUS_COLORS.length], marginRight: 6 }} />
    )}
  </Tooltip>
)

export default TourStatus
