import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import FormAction from 'components/Button/FormAction'
import { AuthContext } from 'store/AuthContext'
import { ICancellationReason } from 'interfaces/ICancellationReason'
import { FeedbackContext } from 'store/FeedbackContext'
import CancellationReasonsApi from 'api/cancellationReasons'
import { isIError } from 'api/types'
import { getErrorList } from 'utils/errorUtils'
import CancellationReasonsCard from './CancellationReasonsCard'

const CancellationReasonsScreen = (): JSX.Element => {
  const { t } = useTranslation()
  const { openErrorSnack, openSuccessSnack, toggleLoader } = useContext(FeedbackContext)
  const { getUserData } = useContext(AuthContext)
  const [savedCancellationReasons, setSavedCancellationReasons] = useState<ICancellationReason[]>(
    [],
  )
  const [cancellationReasons, setCancellationReasons] = useState<ICancellationReason[]>([])

  const getCancellationReasons = async (): Promise<void> => {
    toggleLoader(true)
    const response = await CancellationReasonsApi.getCancellationReasons()
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      setSavedCancellationReasons(response)
    }
    toggleLoader(false)
  }

  const updateCancellationReasons = async (reasons: ICancellationReason[]): Promise<void> => {
    toggleLoader(true)
    const response = await CancellationReasonsApi.updateCancellationReasons(reasons)
    if (isIError(response)) {
      response.error.errorList = getErrorList(response)
      if (response.error.fieldErrors) {
        openErrorSnack(response.error.errorList.join('\n'))
      } else {
        openErrorSnack(response.error.message)
      }
    } else {
      setSavedCancellationReasons(response)
      getUserData(true)
      openSuccessSnack(t('ConfigurationsScreen.successfullySaved'))
    }
    toggleLoader(false)
  }

  useEffect(() => {
    getCancellationReasons()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setCancellationReasons(savedCancellationReasons)
  }, [savedCancellationReasons])

  const handleCancelClick = (): void => {
    setCancellationReasons(savedCancellationReasons)
  }

  const handleSaveClick = (): void => {
    updateCancellationReasons(cancellationReasons)
  }

  const handleCancellationReasonsChange = (changedReasons: ICancellationReason[]): void => {
    setCancellationReasons(changedReasons)
  }

  return (
    <>
      <CancellationReasonsCard
        cancellationReasons={cancellationReasons}
        onCancellationReasonsChange={handleCancellationReasonsChange}
      />
      <FormAction onSaveClick={handleSaveClick} onCancelClick={handleCancelClick} />
    </>
  )
}

export default CancellationReasonsScreen
