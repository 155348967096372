import {
  MENU_CATEGORIES,
  MENU_SUBCATEGORIES,
  USER_ROLES,
  PILOT_AND_STORE_USER_ROLES,
} from 'constants/constants'

import DriversScreen from 'screens/ResourcesCategory/Drivers/DriversScreen'
import DriversFormScreen from 'screens/ResourcesCategory/Drivers/DriversFormScreen'
import DriversDetailsScreen from 'screens/ResourcesCategory/Drivers/DriversDetailsScreen'
import VehiclesListScreen from 'screens/ResourcesCategory/Vehicles/VehiclesListScreen'
import VehicleFormScreen from 'screens/ResourcesCategory/Vehicles/VehicleFormScreen'
import VehicleDetailsScreen from 'screens/ResourcesCategory/Vehicles/VehicleDetailsScreen'
import VehicleTypeListScreen from 'screens/ResourcesCategory/VehicleTypes/VehicleTypeListScreen'
import VehicleTypeDetailsScreen from 'screens/ResourcesCategory/VehicleTypes/VehicleTypeDetailsScreen'
import VehicleTypeFormScreen from 'screens/ResourcesCategory/VehicleTypes/VehicleTypeFormScreen'
import TrailersListScreen from 'screens/ResourcesCategory/Trailers/TrailersListScreen'
import TrailerDetailsScreen from 'screens/ResourcesCategory/Trailers/TrailerDetailsScreen'
import TrailerFormScreen from 'screens/ResourcesCategory/Trailers/TrailerFormScreen'
import VehicleReportsScreen from 'screens/ResourcesCategory/Vehicles/VehicleReportsScreen'
import { ROUTES_PATH } from './RoutesPath'

/**
 * RESSOURCES_CATEGORY configuration
 * Please refer to 'navigation/NavigationConfig' before changing this.
 */

const RESSOURCES_CATEGORY = [
  {
    title: MENU_SUBCATEGORIES.driver,
    path: ROUTES_PATH.driversList,
    component: DriversScreen,
    isPrivate: true,
    isInMenu: true,
    category: MENU_CATEGORIES.resources,
    roles: [USER_ROLES.udcAdmin, USER_ROLES.carrier, ...PILOT_AND_STORE_USER_ROLES],
  },
  {
    title: MENU_SUBCATEGORIES.driver,
    path: ROUTES_PATH.createDriver,
    component: DriversFormScreen,
    isPrivate: true,
    isInMenu: false,
    category: MENU_CATEGORIES.resources,
    roles: [USER_ROLES.udcAdmin, USER_ROLES.carrier, ...PILOT_AND_STORE_USER_ROLES],
  },
  {
    title: MENU_SUBCATEGORIES.driver,
    path: `${ROUTES_PATH.driverDetails}:id`,
    component: DriversDetailsScreen,
    isPrivate: true,
    isInMenu: false,
    category: MENU_CATEGORIES.resources,
    roles: [USER_ROLES.udcAdmin, USER_ROLES.carrier, ...PILOT_AND_STORE_USER_ROLES],
  },
  {
    title: MENU_SUBCATEGORIES.driver,
    path: `${ROUTES_PATH.editDriver}:id`,
    component: DriversFormScreen,
    isPrivate: true,
    isInMenu: false,
    category: MENU_CATEGORIES.resources,
    roles: [USER_ROLES.udcAdmin, USER_ROLES.carrier, ...PILOT_AND_STORE_USER_ROLES],
  },
  {
    title: MENU_SUBCATEGORIES.vehicle,
    path: ROUTES_PATH.vehiclesList,
    component: VehiclesListScreen,
    isPrivate: true,
    isInMenu: true,
    category: MENU_CATEGORIES.resources,
    roles: PILOT_AND_STORE_USER_ROLES,
  },
  {
    title: MENU_SUBCATEGORIES.vehicle,
    path: ROUTES_PATH.createVehicle,
    component: VehicleFormScreen,
    isPrivate: true,
    isInMenu: false,
    category: MENU_CATEGORIES.resources,
    roles: PILOT_AND_STORE_USER_ROLES,
  },
  {
    title: MENU_SUBCATEGORIES.vehicle,
    path: `${ROUTES_PATH.vehicleDetails}:id`,
    component: VehicleDetailsScreen,
    isPrivate: true,
    isInMenu: false,
    category: MENU_CATEGORIES.resources,
    roles: PILOT_AND_STORE_USER_ROLES,
  },
  {
    title: MENU_SUBCATEGORIES.vehicle,
    path: `${ROUTES_PATH.editVehicle}:id`,
    component: VehicleFormScreen,
    isPrivate: true,
    isInMenu: false,
    category: MENU_CATEGORIES.resources,
    roles: PILOT_AND_STORE_USER_ROLES,
  },
  {
    title: MENU_SUBCATEGORIES.vehicle,
    path: `${ROUTES_PATH.vehicleReports}:id`,
    component: VehicleReportsScreen,
    isPrivate: true,
    isInMenu: false,
    category: MENU_CATEGORIES.resources,
    roles: PILOT_AND_STORE_USER_ROLES,
  },
  {
    title: MENU_SUBCATEGORIES.trailers,
    path: ROUTES_PATH.trailersList,
    component: TrailersListScreen,
    isPrivate: true,
    isInMenu: true,
    isBetaMode: true,
    description: 'Accès aux écrans CRU Remorques (menu Ressources/ Remorques)',
    category: MENU_CATEGORIES.resources,
    roles: [...PILOT_AND_STORE_USER_ROLES, USER_ROLES.carrier],
  },
  {
    title: MENU_SUBCATEGORIES.trailers,
    path: ROUTES_PATH.createTrailer,
    component: TrailerFormScreen,
    isPrivate: true,
    isInMenu: false,
    isBetaMode: true,
    category: MENU_CATEGORIES.resources,
    roles: [...PILOT_AND_STORE_USER_ROLES, USER_ROLES.carrier],
  },
  {
    title: MENU_SUBCATEGORIES.trailers,
    path: `${ROUTES_PATH.editTrailer}:id`,
    component: TrailerFormScreen,
    isPrivate: true,
    isInMenu: false,
    isBetaMode: true,
    category: MENU_CATEGORIES.resources,
    roles: [...PILOT_AND_STORE_USER_ROLES, USER_ROLES.carrier],
  },
  {
    title: MENU_SUBCATEGORIES.trailers,
    path: `${ROUTES_PATH.trailerDetails}:id`,
    component: TrailerDetailsScreen,
    isPrivate: true,
    isInMenu: false,
    isBetaMode: true,
    category: MENU_CATEGORIES.resources,
    roles: [...PILOT_AND_STORE_USER_ROLES, USER_ROLES.carrier],
  },
  {
    title: MENU_SUBCATEGORIES.vehicleCategory,
    path: ROUTES_PATH.vehicleTypesList,
    component: VehicleTypeListScreen,
    isPrivate: true,
    isInMenu: true,
    category: MENU_CATEGORIES.resources,
    roles: [USER_ROLES.pilot],
  },
  {
    title: MENU_SUBCATEGORIES.vehicleCategory,
    path: `${ROUTES_PATH.vehicleTypeDetails}:id`,
    component: VehicleTypeDetailsScreen,
    isPrivate: true,
    isInMenu: false,
    category: MENU_CATEGORIES.resources,
    roles: [USER_ROLES.pilot],
  },
  {
    title: MENU_SUBCATEGORIES.vehicleCategory,
    path: ROUTES_PATH.createVehicleType,
    component: VehicleTypeFormScreen,
    isPrivate: true,
    isInMenu: false,
    category: MENU_CATEGORIES.resources,
    roles: [USER_ROLES.pilot],
  },
  {
    title: MENU_SUBCATEGORIES.vehicleCategory,
    path: `${ROUTES_PATH.editVehicleType}:id`,
    component: VehicleTypeFormScreen,
    isPrivate: true,
    isInMenu: false,
    category: MENU_CATEGORIES.resources,
    roles: [USER_ROLES.pilot],
  },
]

export default RESSOURCES_CATEGORY
