import { ClusterStatus, ICluster } from 'interfaces/ICluster'
import React, { useContext } from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import styled from 'styled-components'
import { sortPlanVisits } from 'utils/planningUtils'
import { AuthContext } from 'store/AuthContext'
import { ContentContext } from 'store/ContentContext'
import { PlanificationContext } from '../../PlanningStore'
import ListContainer from '../Container'
import ListItem from '../ListItem'
import CustomHeader from './CustomHeader'

interface IClusterCol {
  cluster: ICluster
  onClusterLock: () => void
  onClusterDelete: () => void
  onVisitDelete: (visitId: string, clusterId: number) => void
  onVehicleChange: () => void
  clusterCapacity: () => number
  isManualTour?: boolean
}

const StyledContainer = styled.div<{ bgColor: string }>`
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  overflow-y: overlay;
  height: 100%;
  border-radius: 4px;
  background-color: ${(props) => props.bgColor};
`

const ClusterColumn = ({
  cluster,
  onClusterLock,
  onClusterDelete,
  onVisitDelete,
  onVehicleChange,
  clusterCapacity,
  isManualTour = false,
}: IClusterCol): JSX.Element => {
  const { user } = useContext(AuthContext)
  const { tenants } = useContext(ContentContext)
  const { isMapotempoActionInProgress } = useContext(PlanificationContext)
  const userTenant = tenants.find((tenant) => tenant.id === user?.tenantId)

  const tagsMap = new Map(userTenant?.tags?.map((tag) => [tag.code as string, tag.label as string]))
  return (
    <Droppable
      // TourManualDroppable and not ManualTourDroppable because the latter includes TourDroppable which will cause problems
      droppableId={
        isManualTour
          ? `TourManualDroppable-${cluster.clusterId}`
          : `ClusterDroppable-${cluster.clusterId}`
      }
      isDropDisabled={isMapotempoActionInProgress}
    >
      {(provided): JSX.Element => (
        <ListContainer
          ref={provided.innerRef}
          elevation={3}
          square
          style={{ border: `3px solid ${cluster.color}`, backgroundColor: cluster.color }}
        >
          <CustomHeader
            cluster={cluster}
            onClusterDelete={onClusterDelete}
            onClusterLock={onClusterLock}
            onVehicleChange={() => cluster.status !== ClusterStatus.closed && onVehicleChange()}
            clusterCapacity={clusterCapacity}
            isManualTour={isManualTour}
          />
          <StyledContainer bgColor={cluster.color}>
            {sortPlanVisits(cluster.visits).map((visit, index) => (
              <Draggable
                key={visit.stopId}
                index={index}
                draggableId={visit.stopId}
                isDragDisabled={isMapotempoActionInProgress}
              >
                {(providedStop, snapshotStop): JSX.Element => (
                  <ListItem
                    tagsMap={tagsMap}
                    color={cluster.color}
                    marker={visit}
                    providedMarker={providedStop}
                    snapshotMarker={snapshotStop}
                    isClusterMode
                    onDelete={() => onVisitDelete(visit.stopId, cluster.clusterId)}
                    isClusterEditable={cluster.status !== ClusterStatus.closed}
                    isManualTour={isManualTour}
                  />
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </StyledContainer>
        </ListContainer>
      )}
    </Droppable>
  )
}

export default ClusterColumn
