import React, { useState, useEffect, useRef, useContext, useMemo } from 'react'
import MaterialTable, { Action, MTableHeader } from 'material-table'
import GetApp from '@material-ui/icons/GetApp'
import ErrorIcon from '@material-ui/icons/Error'
import { useTranslation } from 'react-i18next'
import useInterval from '@use-it/interval'

import MUITableIcons from 'constants/MUITableIcons'
import JobsApi from 'api/jobs'
import AppConfigProvider, { AppConfigContext } from 'store/AppConfigContext'
import { FeedbackContext } from 'store/FeedbackContext'
import { exportFile, scrollTop } from 'utils/functions'
import { getPageSize, savePageSize } from 'utils/localStorage'
import Pagination from 'components/Table/Pagination'
import { getDisplayRowsCount } from 'utils/tableUtils'
import { isIError } from 'api/types'

import { IImportJob, ImportJobStatus } from 'interfaces/IImportJob'
import { COLUMNS_MODEL_IMPORT_JOBS } from 'constants/table'

const columnConfigName = 'importJobList'

interface IProps {
  onErrorClick: (id: string) => void
}

const ImportJobsTable = ({ onErrorClick }: IProps): JSX.Element => {
  const { t } = useTranslation()
  const isInitialMount = useRef(true)

  const [paginationOffset, setPaginationOffset] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [isImportInProgress, setIsImportInProgress] = useState<boolean>(false)
  const [rowsPerPage, setRowsPerPage] = useState<number>(getPageSize)
  const [importJobs, setImportJobs] = useState<IImportJob[]>([])
  const [count, setCount] = useState<number>(0)
  const { openErrorSnack, toggleLoader } = useContext(FeedbackContext)
  const { tablesConfig } = useContext(AppConfigContext)

  const getList = async (offset = paginationOffset) => {
    toggleLoader(true)
    const response = await JobsApi.getOrdersJobImportHistory({ offset, rowsPerPage })
    if (!isIError(response)) {
      setIsImportInProgress(
        response.jobs.findIndex((job) => job.status === ImportJobStatus.Doing) !== -1,
      )
      setImportJobs(response.jobs)
      setCount(response.count)
    } else {
      openErrorSnack(response.error.message)
    }
    toggleLoader(false)
  }

  // TODO: define most suitable auto reload interval
  useInterval(
    isImportInProgress
      ? getList
      : () => {
        /* useless */
      },
    30000,
  )

  useEffect(() => {
    if (rowsPerPage !== getPageSize()) {
      savePageSize(rowsPerPage)
      scrollTop()
    }
    getList(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage])

  useEffect(() => {
    if (!isInitialMount.current) {
      getList()
      scrollTop()
    } else {
      isInitialMount.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationOffset])

  const handleChangeRowsPerPage = (pageSize: number): void => {
    setPage(0)
    setRowsPerPage(pageSize)
    setPaginationOffset(0)
  }

  const handleDownload = async (id: string, fileName: string): Promise<void> => {
    const response = await JobsApi.getDownloadUrl(id)
    if (!isIError(response)) {
      exportFile(response, fileName)
    } else {
      openErrorSnack(response.error.message)
    }
  }

  const handleErrorListClick = (id: string) => {
    onErrorClick(id)
  }

  const displayRowsCount = useMemo(() => getDisplayRowsCount(importJobs), [importJobs])

  return (
    <MaterialTable
      key={displayRowsCount}
      data={importJobs}
      columns={COLUMNS_MODEL_IMPORT_JOBS.map(
        (col) =>
          ({
            ...col,
            hidden: tablesConfig[columnConfigName]?.includes(col.title),
          } as unknown as MaterialTable<object>),
      )}
      options={{
        toolbar: false,
        pageSize: displayRowsCount,
        actionsColumnIndex: -1,
        headerStyle: {
          textTransform: 'none',
        },
      }}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      actions={[
        (data: IImportJob): Action<IImportJob> => ({
          icon: GetApp,
          tooltip: t('jobs.actions.download'),
          onClick: (): Promise<void> => handleDownload(data.id, data.originFileName),
        }),
        (data: IImportJob): Action<IImportJob> => ({
          icon: ErrorIcon,
          tooltip: t('jobs.actions.errorsList'),
          onClick: (): void => handleErrorListClick(data.id),
          disabled:
            data.nbOfFailedActions === 0
            || ![ImportJobStatus.HasErrors, ImportJobStatus.Failed].some(
              (status) => status === data.status,
            ),
        }),
      ]}
      icons={MUITableIcons}
      components={{
        Header: (props): JSX.Element => <MTableHeader {...props} />,
        // eslint-disable-next-line react/prop-types, @typescript-eslint/no-unused-vars
        Pagination: ({ classes, ...props }): JSX.Element => (
          <Pagination
            {...props}
            page={page}
            setPage={setPage}
            columns={COLUMNS_MODEL_IMPORT_JOBS}
            columnConfigName={columnConfigName}
            count={count}
            rowsPerPage={rowsPerPage}
            setPaginationOffset={setPaginationOffset}
          />
        ),
      }}
    />
  )
}

export default (props: IProps): JSX.Element => (
  <AppConfigProvider>
    <ImportJobsTable {...props} />
  </AppConfigProvider>
)
