import React, { useContext, useMemo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton, TextField, Theme, Typography, Button } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import styled from 'styled-components'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import CheckIcon from '@material-ui/icons/Check'
import { Clear } from '@material-ui/icons'

import Card from 'components/Card/Card'
import { IOrderForm } from 'interfaces/IOrders'
import SearchableSelect from 'components/Inputs/SearchableSelect'
import { TRANSPORT_TYPE_OPTIONS } from 'constants/constants'
import { AuthContext } from 'store/AuthContext'
import { formatDeliveryTypes, isDefined, shouldDisplayOrderFormField } from 'utils/functions'
import Switch from 'components/Inputs/ToggleSwitch'
import { IFieldChange } from 'interfaces/interfaces'
import { ContentContext } from 'store/ContentContext'
import { IInputChange } from 'interfaces/IInputChange'
import { IWarehouseCartDropoffConfig } from 'interfaces/IWarehouseCartDropoffConfig'
import useStyles from './styles'

const FieldError = styled.span<{ theme: Theme }>`
  color: ${(props) => props.theme.color.red};
`

interface IProps {
  order?: Partial<IOrderForm>
  onChange: (key: keyof IOrderForm, value: unknown) => void
  isOrderNumberValid: boolean
  isCartDropoff?: boolean
  config?: IWarehouseCartDropoffConfig | null
}

const OrderCard = ({
  order,
  onChange,
  isOrderNumberValid,
  isCartDropoff = false,
  config,
}: IProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { sites } = useContext(ContentContext)
  const theme = useTheme()
  const { user, shouldDisplayAutomaticVisitToggle } = useContext(AuthContext)
  const userDeliveryTypes = formatDeliveryTypes(user?.tenantConfig?.deliveryTypes).map(
    (deliveryType) => ({
      id: deliveryType.code,
      name: deliveryType.label,
    }),
  )
  const [currentParcelCode, setCurrentParcelCode] = useState<string>('')
  const [currentEditingParcelCodeIndex, setCurrentEditingParcelCodeIndex] = useState<number>()
  const [currentEditingParcelCode, setCurrentEditingParcelCode] = useState<string>('')
  const parcelCodesPrefix = config?.parcelCodes?.defaultValue as string || ''

  useEffect(() => {
    setCurrentParcelCode(parcelCodesPrefix)
  }, [parcelCodesPrefix])

  const isClientCodeEnabled = useMemo(() => {
    if (order && order.warehouseId && sites) {
      // check warehouse configuration
      const orderSite = sites.find((site) => site.id === order.warehouseId)
      return !!orderSite?.planningMobile?.clientCode || false
    }
    return false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sites, order?.warehouseId])

  const handleInputChange = (event: IFieldChange): void => {
    const { target } = event
    const { name } = target
    const value = target.type === 'checkbox' ? target.checked : target.value

    onChange(name as keyof IOrderForm, value)
  }

  const doesParcelCodeExist = (parcelCode: string, parcelCodeIndex?: number) => {
    const orderParcelCodes = order?.parcelCodes || []
    const index = orderParcelCodes.findIndex(code => code === parcelCode)
    return isDefined(parcelCodeIndex) ? (index !== -1 && index !== parcelCodeIndex) : index !== -1
  }

  const handleAddParcelCode = () => {
    if (currentParcelCode && !doesParcelCodeExist(currentParcelCode)) {
      onChange('parcelCodes', [...(order?.parcelCodes || []), currentParcelCode])
      setCurrentParcelCode(parcelCodesPrefix)
    }
  }

  const handleEditParcelCode = () => {
    if (
      currentEditingParcelCodeIndex !== undefined
      && !doesParcelCodeExist(currentEditingParcelCode, currentEditingParcelCodeIndex,
      )) {
      const orderParcelCodes = [...(order?.parcelCodes || [])]
      orderParcelCodes[currentEditingParcelCodeIndex] = currentEditingParcelCode
      onChange('parcelCodes', orderParcelCodes)
      setCurrentEditingParcelCodeIndex(undefined)
      setCurrentEditingParcelCode('')
    }
  }

  const handleParcelCodeEdit = (index: number, parcelCode: string) => {
    setCurrentEditingParcelCodeIndex(index)
    setCurrentEditingParcelCode(parcelCode)
  }

  const handleCancelEditParcelCode = () => {
    setCurrentEditingParcelCodeIndex(undefined)
    setCurrentEditingParcelCode('')
  }

  const handleDeleteParcelCode = (parcelCodeIndex: number) => {
    onChange(
      'parcelCodes',
      order?.parcelCodes?.filter((_code, index) => index !== parcelCodeIndex),
    )
  }

  const onWheel = (e: React.WheelEvent<HTMLDivElement>): void => {
    (e.target as HTMLInputElement).blur()
  }

  return (
    <Card
      title={t('OrdersScreen.orderInfo').toUpperCase()}
      dataCy="orderCard"
      contentClassName={isCartDropoff ? classes.orderCard : ''}
    >
      <div className={classes.container}>
        {shouldDisplayOrderFormField(isCartDropoff, config?.orderNumber) && (
          <TextField
            data-cy="orderNumber"
            error={!isOrderNumberValid}
            type="text"
            label={t('OrdersScreen.orderNumber').toUpperCase()}
            value={order?.orderNumber || ''}
            name="orderNumber"
            onChange={handleInputChange}
            autoComplete="off"
          />
        )}
        {!isOrderNumberValid && (
          <FieldError theme={theme}>{t('OrdersScreen.orderNumberAlreadyExisting')}</FieldError>
        )}
        {shouldDisplayOrderFormField(isCartDropoff, config?.value) && (
          <TextField
            type="number"
            label={t('OrdersScreen.orderAmount').toUpperCase()}
            value={order?.orderAmount || ''}
            name="orderAmount"
            onChange={handleInputChange}
            autoComplete="off"
            onWheel={onWheel}
          />
        )}
        {!isCartDropoff && isClientCodeEnabled && (
          <TextField
            data-cy="clientCode"
            type="text"
            label={t('OrdersScreen.clientCode').toUpperCase()}
            value={order?.clientCode}
            name="clientCode"
            onChange={handleInputChange}
            autoComplete="off"
          />
        )}
        {shouldDisplayOrderFormField(isCartDropoff, config?.deliveryType) && (
          <SearchableSelect
            dataCy="deliveryType"
            label={t('OrdersScreen.deliveryType')}
            name="deliveryType"
            selectedValue={order?.deliveryType ?? ''}
            options={userDeliveryTypes as { id: number; name: string }[]}
            onChange={handleInputChange}
            required
          />
        )}
        {shouldDisplayOrderFormField(isCartDropoff, config?.transportType) && (
          <SearchableSelect
            dataCy="transportType"
            label={t('OrdersScreen.transportType')}
            name="transportType"
            selectedValue={order?.transportType || ''}
            options={TRANSPORT_TYPE_OPTIONS}
            onChange={handleInputChange}
            required
          />
        )}
        {isCartDropoff && shouldDisplayOrderFormField(isCartDropoff, config?.scanTicket) && (
          <TextField
            data-cy="scanTicket"
            type="text"
            label={t('OrdersScreen.scanTicket').toUpperCase()}
            value={order?.scanTicket || ''}
            name="scanTicket"
            onChange={handleInputChange}
          />
        )}
        <TextField
          data-cy="mainQuantity"
          required
          type="number"
          label={t('OrdersScreen.mainQuantity').toUpperCase()}
          value={order?.mainQuantity ?? ''}
          name="mainQuantity"
          onChange={handleInputChange}
          onWheel={onWheel}
        />
        {isCartDropoff && shouldDisplayOrderFormField(isCartDropoff, config?.numberOfArticles) && (
          <TextField
            data-cy="numberOfArticles"
            type="number"
            label={t('OrdersScreen.numberOfArticles').toUpperCase()}
            value={order?.numberOfArticles ?? ''}
            name="numberOfArticles"
            onChange={handleInputChange}
            onWheel={onWheel}
          />
        )}
        {shouldDisplayOrderFormField(isCartDropoff, config?.estimatedWeight) && (
          <TextField
            data-cy="estimatedWeight"
            type="number"
            label={t('OrdersScreen.estimatedWeight').toUpperCase()}
            value={order?.estimatedWeight ?? ''}
            name="estimatedWeight"
            onChange={handleInputChange}
            onWheel={onWheel}
          />
        )}
        {isCartDropoff && shouldDisplayOrderFormField(isCartDropoff, config?.parcelCodes) && (
          <>
            <TextField
              type="text"
              variant="outlined"
              label={t('OrdersScreen.addParcelCode')}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={handleAddParcelCode}
                    disabled={!currentParcelCode || doesParcelCodeExist(currentParcelCode)}
                  >
                    <AddIcon />
                  </IconButton>
                ),
              }}
              value={currentParcelCode || ''}
              onChange={({ target: { value } }: IInputChange) => setCurrentParcelCode(value)}
              onKeyPress={(e): false | void => e.key === 'Enter' && handleAddParcelCode()}
            />
            {doesParcelCodeExist(currentParcelCode) && (
              <FieldError theme={theme}>{t('OrdersScreen.parcelCodeAlreadyExists')}</FieldError>
            )}
            {order?.parcelCodes && order.parcelCodes.length > 0 && (
              <div>
                <Typography>{t('OrdersScreen.parcelCodes')}</Typography>
                <div className={classes.parcelCodesContainer}>
                  {order.parcelCodes.map((parcelCode, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={index}>
                      {currentEditingParcelCodeIndex === index ? (
                        <>
                          <TextField
                            type="text"
                            autoFocus
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <>
                                  <IconButton onClick={handleCancelEditParcelCode}>
                                    <Clear />
                                  </IconButton>
                                  <IconButton
                                    onClick={handleEditParcelCode}
                                    disabled={!currentEditingParcelCode
                                      || doesParcelCodeExist(currentEditingParcelCode, currentEditingParcelCodeIndex)}
                                  >
                                    <CheckIcon />
                                  </IconButton>
                                </>
                              ),
                            }}
                            value={currentEditingParcelCode}
                            onChange={({ target: { value } }: IInputChange) => setCurrentEditingParcelCode(value)}
                            onKeyPress={(e): false | void => e.key === 'Enter' && handleEditParcelCode()}
                          />
                          {doesParcelCodeExist(currentEditingParcelCode, currentEditingParcelCodeIndex) && (
                            <FieldError theme={theme}>{t('OrdersScreen.parcelCodeAlreadyExists')}</FieldError>
                          )}
                        </>
                      ) : (
                        <div className={classes.parcelCode}>
                          <span>{parcelCode}</span>
                          <div>
                            <Button onClick={() => handleParcelCodeEdit(index, parcelCode)}>
                              <EditIcon />
                            </Button>
                            <Button onClick={() => handleDeleteParcelCode(index)}>
                              <DeleteIcon />
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </>
        )}
        {!isCartDropoff && shouldDisplayAutomaticVisitToggle && (
          <Switch
            justifyLeft
            dataCy="generateVisit"
            onChange={handleInputChange}
            checked={order?.generateVisit || false}
            name="generateVisit"
            label={t('OrdersScreen.generateVisit').toUpperCase()}
          />
        )}
      </div>
    </Card>
  )
}

export default OrderCard
