import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import moment from 'moment'
import MaterialTable, { MTableHeader, Action } from 'material-table'
import LocationOn from '@material-ui/icons/LocationOn'
import PublishIcon from '@material-ui/icons/Publish'
import ArrowForward from '@material-ui/icons/ArrowForward'
import { useHistory, useLocation } from 'react-router-dom'
import { TextField, IconButton, Tooltip } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import RefreshIcon from '@material-ui/icons/Refresh'
import { useTheme } from '@material-ui/core/styles'
import CreatableSelect from 'react-select/creatable'
import { OptionTypeBase } from 'react-select'

import DateRangePicker from 'components/Inputs/DateRangePicker'
import Button from 'components/Button/CustomButton'
import AppConfigProvider, { AppConfigContext } from 'store/AppConfigContext'
import SearchInput from 'components/Inputs/SearchInput'
import { ControlComponent, getCreatableSelectStyle } from 'components/Inputs/CreatableSelect'
import { FiltersContainer, SearchContainer, TopFiltersContainer } from 'components/Layout'
import MUITableIcons from 'constants/MUITableIcons'
import { IExtendedDateProps, IOrderFilters, ITableColumn, ITextChange } from 'interfaces'
import { IOrder } from 'interfaces/IOrders'
import { OrdersConsumer } from 'store/OrdersContext'
import { useDebouncedCallback } from 'use-debounce'
import { scrollTop } from 'utils/functions'
import { getPageSize, savePageSize } from 'utils/localStorage'
import {
  DeliveryTypeFilter,
  PlanStatusFilter,
  SiteFilter,
  TransportTypeFilter,
} from 'components/Inputs/ListFilter'
import useStyles from 'constants/cruStyles'
import Pagination from 'components/Table/Pagination'
import { FiltersContext } from 'store/FiltersContext'
import { AuthContext } from 'store/AuthContext'
import { Filter } from 'constants/filters'
import { Role, USER_ROLES_WITH_ORDER_MODULE } from 'constants/constants'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import { getDisplayRowsCount, setColumnDisplay } from 'utils/tableUtils'

import ExtendedDateFilters from './ExtendedDateFilters'

interface ICustomTableProps {
  showExtendedDateFilter?: boolean
  extendedDateFilter?: IExtendedDateProps
  detailsUrl?: string
  columns: ITableColumn[]
  data: IOrder[]
  count?: number
  updateOrders?: (
    filters?: IOrderFilters,
    offset?: number,
    rowsPerPage?: number,
    sortField?: number,
    sortDirection?: string,
    isForGeocoding?: boolean,
  ) => void
  onPressLocation?: (props: IOrder) => void
  isForGeocoding?: boolean
  columnConfigName: string
  filterKey: string
  onPressImport?: () => void
  canAddOrders?: boolean
}

interface ILocation {
  startDateFilter?: Date | null
  endDateFilter?: Date | null
  siteId?: string
}

const CustomTable = ({
  showExtendedDateFilter,
  extendedDateFilter,
  columns,
  updateOrders,
  onPressLocation,
  count,
  isForGeocoding,
  columnConfigName,
  filterKey,
  onPressImport,
  detailsUrl,
  canAddOrders,
  ...rest
}: ICustomTableProps): JSX.Element => {
  const isInitialMount = useRef(true)
  const query = new URLSearchParams(useLocation().search)
  const searchId = query.get('visitId') || query.get('planVisitId')
  const orderNumberSearch = query.get('orderNumber')
  const { user } = useContext(AuthContext)
  const canImport = user?.roles.some((role) =>
    [Role.Pilot, Role.UdcAdmin, Role.UdcExploitant].includes(role as Role),
  )
  const { setFilter, resetFilters, filters, resetAllFilters } = useContext(FiltersContext)

  const { t } = useTranslation()
  const startDate = filters[filterKey][Filter.startDate] as string
  const endDate = filters[filterKey][Filter.endDate] as string
  const shift = filters[filterKey][Filter.shift] as string
  const searchText = filters[filterKey][Filter.searchText] as string
  const relevance = filters[filterKey][Filter.relevance] as number
  const deliveryTypes = filters[filterKey][Filter.deliveryTypes] as string[]
  const transportTypes = filters[filterKey][Filter.transportTypes] as string[]
  const siteIds = filters[filterKey][Filter.sites] as string[]
  const deliverySiteIds = filters[filterKey][Filter.deliverySites] as string[]
  const planStatuses = filters[filterKey][Filter.planStatuses] as string[]
  const zipCodes = filters[filterKey][Filter.zipCodes] as OptionTypeBase[]
  const [isSearchReset, setIsSearchReset] = useState<boolean>(false)
  const [paginationOffset, setPaginationOffset] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(getPageSize)
  const styles = useStyles()
  const [sortDirection, setSortDirection] = useState<string>('asc')
  const [sortField, setSortField] = useState<number>(isForGeocoding ? 6 : 0)
  const [zipCodeInputText, setZipCodeInputText] = useState<string>('')
  const { tablesConfig } = useContext(AppConfigContext)
  const history = useHistory()
  const theme = useTheme()
  const location = useLocation()
  const { startDateFilter, endDateFilter, siteId } = (location.state as ILocation) || {
    startDateFilter: null,
    endDateFilter: null,
    siteId: null,
  }
  const hasCreatePermission = user?.roles.some((role) =>
    USER_ROLES_WITH_ORDER_MODULE.includes(role as Role),
  )

  useEffect(() => {
    if (searchId) {
      setIsSearchReset(true)
      setFilter(filterKey, Filter.searchText, searchId)
      if (startDateFilter) {
        const startDateFilterValue = moment(startDateFilter)
          .hours(0)
          .minutes(0)
          .seconds(0)
          .milliseconds(0)
          .toISOString()
        setFilter(filterKey, Filter.startDate, startDateFilterValue)
      }
      if (endDateFilter) {
        const endDateFilterValue = moment(endDateFilter)
          .hours(23)
          .minutes(59)
          .seconds(59)
          .milliseconds(999)
          .toISOString()
        setFilter(filterKey, Filter.endDate, endDateFilterValue)
      }
    }
    return () => {
      if (searchId) {
        resetFilters(filterKey)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchId])

  useEffect(() => {
    if (orderNumberSearch) {
      setIsSearchReset(true)
      resetAllFilters()
      if (startDateFilter) {
        const startDateFilterValue = moment(startDateFilter)
          .hours(0)
          .minutes(0)
          .seconds(0)
          .milliseconds(0)
          .toISOString()
        setFilter(filterKey, Filter.startDate, startDateFilterValue)
      }
      if (endDateFilter) {
        const endDateFilterValue = moment(endDateFilter)
          .hours(23)
          .minutes(59)
          .seconds(59)
          .milliseconds(999)
          .toISOString()
        setFilter(filterKey, Filter.endDate, endDateFilterValue)
      }
      if (siteId) {
        setFilter(filterKey, Filter.sites, [siteId])
      }
      setFilter(filterKey, Filter.relevance, 100)
      setFilter(filterKey, Filter.searchText, orderNumberSearch)
    }
    return () => {
      if (orderNumberSearch) {
        resetFilters(filterKey)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderNumberSearch])

  useEffect(() => {
    if (rowsPerPage !== getPageSize()) {
      savePageSize(rowsPerPage)
      scrollTop()
    }
    if (updateOrders) {
      updateOrders(
        {
          startDate,
          endDate,
          search: searchText,
          relevance: relevance && relevance / 100,
          deliveryTypes,
          transportTypes,
          siteIds,
          deliverySiteIds,
          planStatuses,
          zipCodes: zipCodes?.map(({ value }) => value),
        },
        0,
        rowsPerPage,
        isForGeocoding ? 6 : undefined,
        isForGeocoding ? 'asc' : undefined,
        isForGeocoding,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    startDate,
    endDate,
    searchText,
    rowsPerPage,
    deliveryTypes,
    transportTypes,
    siteIds,
    deliverySiteIds,
    planStatuses,
    zipCodes,
  ])

  useEffect(() => {
    debouncedUpdateOrders.callback()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relevance])

  useEffect(() => {
    if (updateOrders && !isInitialMount.current) {
      updateOrders(
        {
          startDate,
          endDate,
          search: searchText,
          relevance: relevance && relevance / 100,
          deliveryTypes,
          transportTypes,
          siteIds,
          deliverySiteIds,
          planStatuses,
        },
        paginationOffset,
        rowsPerPage,
        sortField,
        sortDirection,
        isForGeocoding,
      )
      scrollTop()
    } else {
      isInitialMount.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationOffset, sortField, sortDirection])

  const debouncedUpdateOrders = useDebouncedCallback(() => {
    if (updateOrders) {
      updateOrders(
        {
          startDate,
          endDate,
          search: searchText,
          relevance: relevance && relevance / 100,
          deliveryTypes,
          transportTypes,
          siteIds,
          deliverySiteIds,
          planStatuses,
        },
        0,
        rowsPerPage,
        isForGeocoding ? 6 : undefined,
        isForGeocoding ? 'asc' : undefined,
        isForGeocoding,
      )
    }
  }, 600)

  const onChangeRelevance = (event: ITextChange): void => {
    const wantedRelevance = +event.target.value
    const relevanceValue = wantedRelevance < 0 ? 0 : wantedRelevance > 100 ? 100 : wantedRelevance
    setFilter(filterKey, Filter.relevance, relevanceValue)
  }

  const handleZipCodeKeyDown = (event: React.KeyboardEvent<HTMLElement>): void => {
    if (!zipCodeInputText) return
    // eslint-disable-next-line default-case
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setZipCodeInputText('')
        setFilter(
          filterKey,
          Filter.zipCodes,
          zipCodes.find((zipCode) => zipCode.value === zipCodeInputText)
            ? zipCodes
            : [...zipCodes, { label: zipCodeInputText, value: zipCodeInputText }],
        )
        event.preventDefault()
    }
  }

  const handleZipCodeChange = (zipCodeValues: OptionTypeBase): void => {
    if (zipCodes.length > 0) {
      setFilter(filterKey, Filter.zipCodes, zipCodeValues as OptionTypeBase[])
    }
  }

  const resetLocalFilters = (): void => {
    setIsSearchReset(true)
    resetFilters(filterKey)
  }

  const Filters = useMemo(
    (): JSX.Element => (
      <TopFiltersContainer>
        <FiltersContainer>
          <IconButton data-cy="initializeButton" onClick={resetLocalFilters}>
            <RefreshIcon />
          </IconButton>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            shift={shift}
            onChangeStart={(startValue): void => {
              setFilter(filterKey, Filter.startDate, moment(startValue).toISOString())
            }}
            onChangeEnd={(endValue): void => {
              setFilter(filterKey, Filter.endDate, moment(endValue).toISOString())
            }}
            onShiftChange={(shiftValue) => setFilter(filterKey, Filter.shift, shiftValue)}
          />
          <SiteFilter
            dataCy="sitePicker"
            handleChange={(siteValues: string[]): void => {
              setFilter(filterKey, Filter.sites, siteValues)
            }}
            ids={siteIds}
            isUnselectAllowed={false}
          />
          <DeliveryTypeFilter
            dataCy="deliveryPicker"
            handleChange={(deliveryTypeValues: string[]): void => {
              setFilter(filterKey, Filter.deliveryTypes, deliveryTypeValues)
            }}
            ids={deliveryTypes}
          />
          {!isForGeocoding && (
            <TransportTypeFilter
              dataCy="transportPicker"
              handleChange={(transportTypeValues: string[]): void => {
                setFilter(filterKey, Filter.transportTypes, transportTypeValues)
              }}
              ids={transportTypes}
            />
          )}
          {!isForGeocoding && (
            <SiteFilter
              dataCy="deliverySitePicker"
              handleChange={(deliverySiteValues: string[]): void => {
                setFilter(filterKey, Filter.deliverySites, deliverySiteValues)
              }}
              ids={deliverySiteIds}
              shouldUseUserSites={false}
              placeholder={t('tablesEntries.deliverySite')}
            />
          )}
          {!isForGeocoding && (
            <PlanStatusFilter
              handleChange={(planStatusValues: string[]): void => {
                setFilter(filterKey, Filter.planStatuses, planStatusValues)
              }}
              ids={planStatuses}
              placeholder={t('tablesEntries.status')}
              dataCy="statusPicker"
            />
          )}
          {isForGeocoding && (
            <TextField
              label={t('OrdersScreen.geocoding')}
              onChange={onChangeRelevance}
              type="number"
              required
              name="geocoding"
              value={relevance}
              variant="outlined"
              className={styles.geocodingFilter}
              data-cy="geocoding"
            />
          )}
          {!isForGeocoding && (
            <CreatableSelect
              value={zipCodes}
              onChange={handleZipCodeChange}
              inputValue={zipCodeInputText}
              onInputChange={setZipCodeInputText}
              onKeyDown={handleZipCodeKeyDown}
              styles={getCreatableSelectStyle(
                'transparent',
                theme.color.label,
                theme.color.fieldBorder,
                theme.color.hover,
              )}
              className={styles.zipCode}
              backgroundColor={theme.color.backgroundBody}
              components={{ DropdownIndicator: null, Control: ControlComponent }}
              isClearable
              isMulti
              menuIsOpen={false}
              placeholder={t('OrdersScreen.zipCode')}
            />
          )}
        </FiltersContainer>
        <SearchContainer>
          <SearchInput
            defaultValue={searchText}
            key={isSearchReset ? 'search-reset' : ''}
            onSearch={(text: string): void => {
              setIsSearchReset(false)
              setFilter(filterKey, Filter.searchText, text)
            }}
          />
          {canAddOrders && hasCreatePermission && (
            <Button
              dataCy="newButton"
              className={styles.newButton}
              onPress={() => history.push(ROUTES_PATH.createOrder)}
            >
              {t('tablesEntries.new')}
            </Button>
          )}
          {canAddOrders && canImport && (
            <Tooltip title={`${t('OrdersScreen.importOrders')}`}>
              <IconButton data-cy="importButton" onClick={onPressImport}>
                <PublishIcon />
              </IconButton>
            </Tooltip>
          )}
        </SearchContainer>
      </TopFiltersContainer>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      startDate,
      endDate,
      searchText,
      relevance,
      deliveryTypes,
      transportTypes,
      siteIds,
      deliverySiteIds,
      planStatuses,
      zipCodes,
      zipCodeInputText,
      theme,
    ],
  )

  const handleRowClick = (rowData: IOrder): void => history.push(`${detailsUrl}${rowData.orderId}`)

  const handleChangeRowsPerPage = (pageSize: number): void => {
    setPaginationOffset(0)
    setPage(0)
    setRowsPerPage(pageSize)
  }

  const handleOrderChange = (columnIndex: number): void => {
    setPage(0)
    setPaginationOffset(0)
    setSortField(columnIndex)
    setSortDirection((prevSortDirection) => (prevSortDirection === 'asc' ? 'desc' : 'asc'))
  }

  const displayRowsCount = useMemo(() => getDisplayRowsCount(rest.data), [rest.data])

  const setEndDate = (value: string): void => {
    setFilter(filterKey, Filter.endDate, value)
  }

  const setStartDate = (value: string): void => {
    setFilter(filterKey, Filter.startDate, value)
  }
  return (
    <>
      {Filters}
      {showExtendedDateFilter && (
        <ExtendedDateFilters
          {...extendedDateFilter}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
        />
      )}
      <MaterialTable
        key={displayRowsCount}
        columns={columns.map((col) => setColumnDisplay(col, tablesConfig[columnConfigName]))}
        {...rest}
        options={{
          toolbar: false,
          pageSize: displayRowsCount,
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: theme.color.tableHeaderBackground,
            borderBottom: '1px solid #A8A8A8',
          },
        }}
        icons={MUITableIcons}
        actions={[
          (rowData: IOrder): Action<IOrder> => ({
            icon: LocationOn,
            tooltip: 'Geocode',
            hidden: !isForGeocoding,
            onClick: (): void => onPressLocation && onPressLocation(rowData),
          }),
          (rowData: IOrder): Action<IOrder> => ({
            icon: ArrowForward,
            tooltip: t('tablesEntries.seeDetails'),
            onClick: (): void => handleRowClick(rowData),
            hidden: isForGeocoding,
          }),
        ]}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        components={{
          // eslint-disable-next-line react/prop-types, @typescript-eslint/no-unused-vars
          Pagination: ({ classes, ...props }): JSX.Element => (
            <Pagination
              {...props}
              columns={columns}
              columnConfigName={columnConfigName}
              setPaginationOffset={setPaginationOffset}
              page={page}
              rowsPerPage={rowsPerPage}
              count={count}
              setPage={setPage}
            />
          ),
          Header: (props): JSX.Element => (
            <MTableHeader
              {...props}
              onOrderChange={handleOrderChange}
              orderDirection={sortDirection}
              orderBy={sortField}
            />
          ),
        }}
      />
    </>
  )
}

export default (props: ICustomTableProps): JSX.Element => (
  <AppConfigProvider>
    <OrdersConsumer>
      {(ctx): JSX.Element => (
        <CustomTable count={ctx.count} updateOrders={ctx.updateOrders} {...props} />
      )}
    </OrdersConsumer>
  </AppConfigProvider>
)
