import { createStyles, makeStyles } from '@material-ui/core/styles'

export default makeStyles(() =>
  createStyles({
    mapContainer: {
      height: '75vh',
      flex: 1,
      position: 'relative',
      marginTop: 10,
    },
    map: {
      height: '100%',
      zIndex: 1,
    },
    legendContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 15,
    },
    legend: {
      fontSize: 16,
    },
  }),
)
