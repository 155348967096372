import React, { useState } from 'react'
import {
  Chart,
  Legend,
  Title,
  ValueAxis,
  ArgumentAxis,
  BarSeries,
  Tooltip,
} from '@devexpress/dx-react-chart-material-ui'
import { Stack, Animation, EventTracker, HoverState, SeriesRef } from '@devexpress/dx-react-chart'
import { connectProps } from '@devexpress/dx-react-core'

import { IDailyCarrierCount } from 'interfaces/IDailyCarrierCount'
import { ICarrier } from 'interfaces/interfaces'
import Export from 'components/Chart/Export'
import TooltipSheet from 'screens/ReportingCategory/components/TooltipSheet'
import TooltipArrow from 'screens/ReportingCategory/components/TooltipArrow'
import VerticalLabel from 'screens/ReportingCategory/components/VerticalLabel'
import StyledPaper from 'screens/ReportingCategory/components/StyledPaper'
import useStyles from './styles'

interface IIndicatorProps {
  data: IDailyCarrierCount[]
  title: string
  carrierIds: string[]
  carriersList?: ICarrier[]
  containerId: string
  exportName: string
  hideLegend?: boolean
  caption?: string
  dataCy?: string
  exportCy?: string
}

interface ILengendProps {
  carriersList?: ICarrier[]
  text: string
}

const CustomLegend = (props: Legend.RootProps): JSX.Element => {
  const classes = useStyles()
  return <Legend.Root {...props} className={classes.legend} />
}

const CustomLengendLabel = ({ carriersList, text }: ILengendProps): JSX.Element => {
  const getCarrierNameById = (carrierId: string): string => {
    const foundCarrier = carriersList && carriersList.find((carrier) => carrier.id === carrierId)
    return foundCarrier?.name || ''
  }

  return <Legend.Label text={getCarrierNameById(text)} />
}

export const IndicatorChart = ({
  data,
  title,
  carrierIds,
  carriersList,
  containerId,
  exportName,
  hideLegend,
  caption,
  dataCy,
  exportCy,
}: IIndicatorProps): JSX.Element => {
  const classes = useStyles()
  const [target, setTarget] = useState<SeriesRef | undefined>()

  const LegendLabel = connectProps(CustomLengendLabel, () => ({ carriersList }))

  const handleHoverChange = (series: SeriesRef | null): void => {
    setTarget(series ? ({ point: series?.point } as SeriesRef) : undefined)
  }

  return (
    <StyledPaper id={containerId}>
      <Chart data-cy={dataCy} data={data} height={750}>
        {data?.length >= 10 ? <ArgumentAxis labelComponent={VerticalLabel} /> : <ArgumentAxis />}
        <ValueAxis />

        {/* order of items in legend comes from order of the BarSeries */}
        {carrierIds.length > 0
          && carrierIds.map((carrierId) => (
            <BarSeries
              key={carrierId}
              name={carrierId}
              valueField={carrierId}
              argumentField="day"
            />
          ))}

        <Animation />
        {!hideLegend && (
          <Legend position="bottom" rootComponent={CustomLegend} labelComponent={LegendLabel} />
        )}
        <Title text={title} />
        <Stack stacks={[{ series: [...carrierIds.map((carrierId) => carrierId)] }]} />
        <EventTracker />
        <HoverState hover={target} onHoverChange={handleHoverChange} />
        <Tooltip sheetComponent={TooltipSheet} arrowComponent={TooltipArrow} />
        <Export dataCy={exportCy} containerId={containerId} fileName={exportName} />
      </Chart>
      {caption && <div className={classes.textLegend}>{caption}</div>}
    </StyledPaper>
  )
}
