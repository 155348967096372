import React, { useContext } from 'react'

import { EXECUTION_REPORTING_MENU } from 'constants/constants'
import { AuthContext } from 'store/AuthContext'
import { shouldShowTab } from 'utils/tabFeatureUtils'
import VerticalTab from 'components/Tab/VerticalTab'

const ExecutionReportingScreen = (): JSX.Element => {
  const { features } = useContext(AuthContext)
  return (
    <VerticalTab
      tabs={EXECUTION_REPORTING_MENU.filter((orderTab) => shouldShowTab(orderTab, features))}
    />
  )
}

export default ExecutionReportingScreen
