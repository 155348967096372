import React, { Dispatch, SetStateAction, useContext, useMemo, useState } from 'react'
import { IconButton, Menu, MenuItem, Paper } from '@material-ui/core'
import { VisibilityOff } from '@material-ui/icons'
import Visibility from '@material-ui/icons/Visibility'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useTheme, Theme } from '@material-ui/core/styles'

import { FeedbackContext } from 'store/FeedbackContext'
import PlansApi from 'api/plans'
import { isIError } from 'api/types'
import useToggle from 'hooks/useToggle'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog'
import { ITourMapItem } from 'interfaces/map'
import { dateDefaultFormat } from 'utils/dateFormat'
import { AppInsightEvents, PLAN_TOUR_STATUS_ENUM } from 'constants/constants'
import appInsight from 'services/appInsight'
import { AuthContext } from 'store/AuthContext'
import VehicleAllocatedCapacityPercentage from 'components/Map/ToursMap/VehicleAllocatedCapacityPercentage'
import { IPlanTourDeleteResponseItem } from 'interfaces/IPlan'
import { calculateAmplitude } from 'utils/planningUtils'
import { PlanificationContext } from '../../PlanningStore'
import DateSelectModal from '../DateSelectModal'
import ChangeVehicleModal from './ChangeVehicleModal'
import TourStatus from './TourStatus'

interface ITourHeader {
  tour: ITourMapItem
  setIsTourLoading: Dispatch<SetStateAction<boolean>>
  isTourLoading: boolean
}

const Header = styled(Paper)<{ theme: Theme }>`
  padding: 10px;
  background-color: ${(props) => props.theme.color.headerPaper};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: max-height 0.25s ease-in;
`

function TourHeader({ tour, setIsTourLoading, isTourLoading }: ITourHeader): JSX.Element {
  const {
    toggleTourVisibility,
    hiddenTours,
    getPlanTours,
    setIsMapotempoActionInProgress,
    isMapotempoActionInProgress,
    getOptimizeState,
    setIsPlanTourStatusChangeDone,
    selectedPlan,
    deletePlanTour,
    isMultisite,
  } = useContext(PlanificationContext)

  const theme = useTheme()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [isDateModalOpen, setIsDateModalOpen] = useState<boolean>(false)
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState<boolean>(false)
  const [isInterfaceConfirmationModalOpen, setIsInterfaceConfirmationModalOpen] = useState<boolean>(false)
  const [mouseIsOverHeader, setMouseIsOverHeader] = useState<boolean>(false)
  const [isChangeVehicleOpen, toggleChangeVehicle] = useToggle(false)
  const [isValidateDisabled, setIsValidateDisabled] = useState<boolean>(false)
  const [isInvalidateDisabled, setIsInvalidateDisabled] = useState<boolean>(false)
  const [isDeleteTourFinishModalOpen, setIsDeleteTourFinishModalOpen] = useState<boolean>(false)
  const [deleteTourFinishMessage, setDeleteTourFinishMessage] = useState<string>('')

  const isButtonMenuOpen = Boolean(anchorEl)
  const isTourLocked = tour.locked === 2
  const { t } = useTranslation()
  const { openErrorSnack } = useContext(FeedbackContext)
  const { shouldDisplayTourEstimatedCost } = useContext(AuthContext)

  const ordersCount = tour.stops.reduce((total, current) => total + (current.ordersCount || 0), 0)

  const amplitude = useMemo(
    (): string => calculateAmplitude(tour.beginDateTime, tour.endDateTime),
    [tour],
  )

  const handleMenuClick = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    setAnchorEl(event.currentTarget)
  }

  function handleMenuClose(): void {
    setAnchorEl(null)
  }

  const handleEditDepartureDateClick = (): void => {
    setIsDateModalOpen(true)
    handleMenuClose()
  }

  const handleDepartureDateModalClose = (): void => {
    setIsDateModalOpen(false)
  }

  const handleDepartureDateSave = async (newDate: string): Promise<void> => {
    setIsDateModalOpen(false)
    setIsTourLoading(true)
    const isOptimizing = await getOptimizeState()
    if (isOptimizing) {
      setIsTourLoading(false)
      return
    }
    setIsMapotempoActionInProgress(true)
    const response = await PlansApi.updatePlantTourStartHour(tour.tourId, newDate)
    getPlanTours()
    setIsTourLoading(false)
    setIsMapotempoActionInProgress(false)
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    }
  }

  const handleDeleteTourClick = (): void => {
    setIsDeleteConfirmationModalOpen(true)
    handleMenuClose()
  }

  const handleValidateTourClick = async (): Promise<void> => {
    appInsight.trackEvent({ name: AppInsightEvents.ValidateOrInterfaceTour })
    setIsTourLoading(true)
    handleMenuClose()
    setIsValidateDisabled(true)
    const response = await PlansApi.validatePlanTours([tour.tourId])
    getPlanTours()
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    }
    setIsTourLoading(false)
    setIsValidateDisabled(false)
    setIsPlanTourStatusChangeDone(true)
  }

  const handleInvalidateTourClick = async (): Promise<void> => {
    setIsTourLoading(true)
    handleMenuClose()
    setIsInvalidateDisabled(true)
    const response = await PlansApi.invalidatePlanTours([tour.tourId])
    getPlanTours()
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    }
    setIsTourLoading(false)
    setIsInvalidateDisabled(false)
    setIsPlanTourStatusChangeDone(true)
  }

  const handleInterfaceClick = async (): Promise<void> => {
    setIsInterfaceConfirmationModalOpen(true)
    handleMenuClose()
  }

  const handleDeleteConfirmation = (validate: boolean): void => {
    handleMenuClose()
    setIsDeleteConfirmationModalOpen(false)
    if (validate) {
      handleDeleteTourConfirmation(true)
      appInsight.trackEvent({ name: AppInsightEvents.DeleteTour })
    }
  }

  const setNotDeletedModalOpen = (notDeletePlanTours: IPlanTourDeleteResponseItem[]) => {
    setDeleteTourFinishMessage(
      t('PlanningScreen.notDeletedToursMessage', {
        stringifiedTrips: notDeletePlanTours.map((item) => item.planTourNumber).join(', '),
      }),
    )
    setIsDeleteTourFinishModalOpen(true)
  }

  const handleDeleteTourConfirmation = async (validate: boolean): Promise<void> => {
    setIsTourLoading(true)
    const isOptimizing = await getOptimizeState()
    if (isOptimizing) {
      setIsTourLoading(false)
      return
    }
    setIsMapotempoActionInProgress(true)
    const response = await deletePlanTour(tour.tourId, validate)
    if (response?.notDeletePlanTours && response.notDeletePlanTours.length > 0) {
      setIsTourLoading(false)
      setNotDeletedModalOpen(response.notDeletePlanTours)
    }
  }

  function handleHide() {
    toggleTourVisibility(tour.tourId)
  }

  function toggleHover(mouseIsOver: boolean) {
    setMouseIsOverHeader(mouseIsOver)
  }

  function onClickVehicleChange(): void {
    handleMenuClose()
    toggleChangeVehicle()
  }

  function roundTo2Dec(nb) {
    return Math.round(nb * 100) / 100
  }

  const handleInterfaceConfirmation = async (validate: boolean): Promise<void> => {
    setIsInterfaceConfirmationModalOpen(false)
    if (validate) {
      appInsight.trackEvent({ name: AppInsightEvents.ValidateOrInterfaceTour })
      setIsTourLoading(true)
      const response = await PlansApi.interfacePlanTour(tour.tourId)
      getPlanTours()
      if (isIError(response)) {
        openErrorSnack(response.error.message)
      }
      setIsTourLoading(false)
      setIsPlanTourStatusChangeDone(true)
    }
  }

  const vehicleAllocatedCapacityPercentage: number | null = useMemo(() => {
    if (!tour.capacity) {
      return null
    }

    const percentage = Math.round(((tour.mainQuantity || 0) / tour.capacity) * 100)

    return percentage
  }, [tour.capacity, tour.mainQuantity])

  return (
    <Header
      square
      elevation={1}
      onMouseEnter={() => toggleHover(true)}
      onMouseLeave={() => toggleHover(false)}
      theme={theme}
    >
      <div
        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
        data-cy="tour-information"
      >
        <div>
          <VehicleAllocatedCapacityPercentage
            percentage={vehicleAllocatedCapacityPercentage}
            title={`${t('PlanningScreen.tour')} ${tour.tourNumber}`}
          />
          <div style={{ display: 'flex', gap: '18px' }}>
            {isMultisite ? (
              <div>{`${t('PlanningScreen.ordersCountShortForm')}: ${ordersCount / 2}`}</div>
            ) : (
              <div>{`${t('PlanningScreen.stops')}: ${tour.stops.length - 2}`}</div>
            )}
            <div>
              {`${t('PlanningScreen.quantityShortForm')}: ${roundTo2Dec(
                tour.mainQuantityToDeliver,
              )}-${roundTo2Dec(tour.mainQuantityToLoad)}`}
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <TourStatus status={tour.status} />
          <IconButton
            disabled={isTourLocked || isTourLoading || isMapotempoActionInProgress}
            onClick={handleHide}
            style={{ padding: 2 }}
          >
            {hiddenTours.includes(tour.tourId) ? <VisibilityOff /> : <Visibility />}
          </IconButton>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleMenuClick}
            disabled={isTourLoading}
            style={{ padding: 2 }}
            data-cy="moreButton"
          >
            <MoreVertIcon />
          </IconButton>
        </div>
      </div>
      {mouseIsOverHeader && (
        <div data-cy="tour-detail">
          {isMultisite ? (
            <div>{`${t('PlanningScreen.stops')}: ${tour.stops.length - 2}`}</div>
          ) : (
            <div>{`${t('PlanningScreen.ordersCountShortForm')}: ${ordersCount}`}</div>
          )}
          <div>
            {t('PlanningScreen.amplitude')}: {amplitude}
          </div>
          <div>{`${t('PlanningScreen.distance')}: ${roundTo2Dec(tour.plannedDistance)}km`}</div>
          {shouldDisplayTourEstimatedCost && tour.estimatedCost && (
            <div>
              {t('PlanningScreen.estimatedCost', {
                value: tour.estimatedCost.toLocaleString('fr-FR', {
                  style: 'currency',
                  currency: 'EUR',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                  compactDisplay: 'long',
                }),
              })}
            </div>
          )}
          <div>{`${t('PlanningScreen.waitTime')}: ${roundTo2Dec(tour.plannedWaitTime)}min`}</div>
          <div>
            {t('PlanningScreen.loading')}: {dateDefaultFormat(tour.loadingDate)}
          </div>
        </div>
      )}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={isButtonMenuOpen}
        onClose={handleMenuClose}
        id="tour-menu"
      >
        {tour.status !== PLAN_TOUR_STATUS_ENUM.INTERFACED && (
          <>
            <MenuItem
              data-cy="item-option-tour-column-editDepartureDate"
              disabled={isMapotempoActionInProgress || selectedPlan?.deletedOnThirdParty}
              onClick={handleEditDepartureDateClick}
            >
              {t('PlanningScreen.editDepartureDate')}
            </MenuItem>
            <MenuItem
              data-cy="item-option-tour-column-changeVehicle"
              disabled={isMapotempoActionInProgress}
              onClick={onClickVehicleChange}
            >
              {t('PlanningScreen.changeVehicle')}
            </MenuItem>
            <MenuItem
              data-cy="item-option-tour-column-delete"
              disabled={isMapotempoActionInProgress}
              onClick={handleDeleteTourClick}
            >
              {t('PlanningScreen.delete')}
            </MenuItem>
          </>
        )}
        {tour.status === PLAN_TOUR_STATUS_ENUM.PLANNED ? (
          <MenuItem
            data-cy="item-option-tour-column-validate"
            disabled={isValidateDisabled || isMapotempoActionInProgress}
            onClick={handleValidateTourClick}
          >
            {t('PlanningScreen.validate')}
          </MenuItem>
        ) : (
          <MenuItem
            data-cy="item-option-tour-column-interface"
            disabled={isMapotempoActionInProgress}
            onClick={handleInterfaceClick}
          >
            {t('PlanningScreen.interface')}
          </MenuItem>
        )}
        {(tour.status === PLAN_TOUR_STATUS_ENUM.VALIDATED
          || tour.status === PLAN_TOUR_STATUS_ENUM.INTERFACED) && (
          <MenuItem
            data-cy="item-option-tour-column-invalidate"
            disabled={isInvalidateDisabled || isMapotempoActionInProgress}
            onClick={handleInvalidateTourClick}
          >
            {t('PlanningScreen.invalidate')}
          </MenuItem>
        )}
      </Menu>
      {isDateModalOpen && (
        <DateSelectModal
          open={isDateModalOpen}
          onSave={handleDepartureDateSave}
          onClose={handleDepartureDateModalClose}
          defaultValue={tour.beginDateTime}
        />
      )}
      {isDeleteConfirmationModalOpen && (
        <ConfirmationDialog
          open={isDeleteConfirmationModalOpen}
          onClose={handleDeleteConfirmation}
          message={t('PlanningScreen.deletePlanTourConfirmationMessage')}
        />
      )}
      {isInterfaceConfirmationModalOpen && (
        <ConfirmationDialog
          open={isInterfaceConfirmationModalOpen}
          onClose={handleInterfaceConfirmation}
          message={t('PlanningScreen.interfacePlanTourConfirmationMessage')}
        />
      )}
      {isDeleteTourFinishModalOpen && (
        <ConfirmationDialog
          open={isDeleteTourFinishModalOpen}
          onClose={() => {
            setDeleteTourFinishMessage('')
            setIsDeleteTourFinishModalOpen(false)
          }}
          message={deleteTourFinishMessage}
          showCancelOption={false}
        />
      )}
      {isChangeVehicleOpen && <ChangeVehicleModal tour={tour} onClose={onClickVehicleChange} />}
    </Header>
  )
}

export default TourHeader
