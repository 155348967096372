import Api from 'services/api'
import { IError } from 'api/types'
import { IWarehouseCartDropoffConfig } from 'interfaces/IWarehouseCartDropoffConfig'
import { isDefined } from 'utils/functions'
import { ICartDropoffDraft } from 'interfaces/ICartDropoffDraft'

const getCartDropoffConfig = async (
  warehouseId: string,
): Promise<IWarehouseCartDropoffConfig | IError> => {
  try {
    const response = await Api.get(`warehouses/${warehouseId}/cart-dropoff-config`)
    return response
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const updateCartDropoffConfig = async (
  warehouseId: string,
  newConfig: IWarehouseCartDropoffConfig,
): Promise<IWarehouseCartDropoffConfig | IError> => {
  try {
    const response = await Api.patch(`warehouses/${warehouseId}/cart-dropoff-config`, newConfig)
    return response
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

const getExistingDraft = async (
  email: string,
  deliveryType?: number,
): Promise<ICartDropoffDraft | IError> => {
  try {
    const response = await Api.get('cart-dropoff-drafts/latest-for-email', {
      email,
      deliveryTypes: isDefined(deliveryType) ? [deliveryType] : undefined,
    })
    return response
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const saveDraft = async (draft: ICartDropoffDraft): Promise<ICartDropoffDraft | IError> => {
  try {
    const res = await Api.post('cart-dropoff-drafts', draft)
    return res
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

const updateDraft = async (
  id: string,
  draft: ICartDropoffDraft,
): Promise<ICartDropoffDraft | IError> => {
  try {
    const res = await Api.patch(`cart-dropoff-drafts/${id}`, draft)
    return res
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

export default {
  getCartDropoffConfig,
  updateCartDropoffConfig,
  getExistingDraft,
  saveDraft,
  updateDraft,
}
