import React, { useContext, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'

import { IIdParam } from 'interfaces/IIdParam'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import CustomCard from 'components/CustomCard/CustomCard'
import Button from 'components/Button/CustomButton'
import useStyles from 'constants/cruStyles'
import NotifierConfigurationsProvider, {
  NotifierConfigurationsContext,
} from 'store/NotifierConfigurationsContext'
import {
  getCarrierNamesFromIds,
  getDealersLabelById,
  getDeliveryTypesLabel,
  getTenantNameFromId,
  getWarehousesLabelById,
} from 'utils/tableUtils'
import { INotifierConfiguration } from 'interfaces/INotifierConfiguration'
import { AuthContext } from 'store/AuthContext'
import { formatDeliveryTypes } from 'utils/functions'

const NotifierConfigDetailsScreen = (): JSX.Element => {
  const { id } = useParams<IIdParam>()
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const { getDetails, notifierConfigurationsDetails } = useContext(NotifierConfigurationsContext)
  const { user } = useContext(AuthContext)
  const userDeliveryTypes = formatDeliveryTypes(user?.tenantConfig?.deliveryTypes)

  useEffect(() => {
    if (id && getDetails) {
      getDetails(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const handleEditClick = (): void => {
    history.push(`${ROUTES_PATH.editNotifierConfig}${id}`)
  }

  return (
    <>
      <div className={classes.detailHeaderContainer}>
        <h3 data-cy="notifierConfigurationsDetails">
          {getTenantNameFromId(notifierConfigurationsDetails as INotifierConfiguration)}
        </h3>
        <Button data-cy="updateButton" onPress={handleEditClick}>
          {t('NotifierConfigurationsScreen.edit')}
        </Button>
      </div>
      <div className={classes.detailsContent}>
        <div className={classes.detailsInnerContent}>
          <Grid item xs={10} className={classes.infoContainer}>
            <CustomCard
              dataCy="configurationsCard"
              cardTitle={t('NotifierConfigurationsScreen.configurations')}
              linePairs={[
                {
                  label: t('NotifierConfigurationsScreen.sites'),
                  value: getWarehousesLabelById(
                    notifierConfigurationsDetails as INotifierConfiguration,
                  ),
                },
                {
                  label: t('NotifierConfigurationsScreen.carriers'),
                  value: getCarrierNamesFromIds(
                    notifierConfigurationsDetails as INotifierConfiguration,
                  ),
                },
                {
                  label: t('NotifierConfigurationsScreen.dealers'),
                  value: getDealersLabelById(
                    notifierConfigurationsDetails as INotifierConfiguration,
                  ),
                },
                {
                  label: t('NotifierConfigurationsScreen.deliveryType'),
                  value: getDeliveryTypesLabel(
                    notifierConfigurationsDetails.deliveryTypes?.map(String) || [],
                    userDeliveryTypes,
                  ),
                },
                {
                  label: t('NotifierConfigurationsScreen.notificationType'),
                  value: notifierConfigurationsDetails.notificationType
                    ? t(
                      `NotifierConfigurationsScreen.${notifierConfigurationsDetails.notificationType}`,
                    )
                    : '-',
                },
                {
                  label: t('NotifierConfigurationsScreen.smsCommunicationService'),
                  value: notifierConfigurationsDetails.smsCommunicationService
                    ? t(
                      `NotifierConfigurationsScreen.${notifierConfigurationsDetails.smsCommunicationService}`,
                    )
                    : '-',
                },
                {
                  label: t('NotifierConfigurationsScreen.sendSms'),
                  value: notifierConfigurationsDetails.notificationParam?.sendSms
                    ? t('NotifierConfigurationsScreen.yes')
                    : t('NotifierConfigurationsScreen.no'),
                },
                {
                  label: t('NotifierConfigurationsScreen.sender'),
                  value: notifierConfigurationsDetails.notificationParam?.sender,
                },
                {
                  label: t('NotifierConfigurationsScreen.message'),
                  value: notifierConfigurationsDetails.notificationParam?.message,
                },
                {
                  label: t('NotifierConfigurationsScreen.specific'),
                  value: notifierConfigurationsDetails.notificationParam?.specific
                    ? JSON.stringify(notifierConfigurationsDetails.notificationParam?.specific)
                    : '',
                },
              ]}
            />
          </Grid>
        </div>
      </div>
    </>
  )
}
export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <NotifierConfigurationsProvider>
    <NotifierConfigDetailsScreen {...props} />
  </NotifierConfigurationsProvider>
)
