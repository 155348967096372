import React from 'react'
import { COLUMS_MODEL_SECTORS_LIST } from 'constants/table'
import { IWarehouse } from 'interfaces/interfaces'
import SitesProvider, { SitesConsumer } from 'store/SitesContext'
import SitesTable from 'components/Table/SitesTable'
import { FilterKey } from 'constants/filters'

interface IProps {
  sites: IWarehouse[]
}

const SectorsListScreen = ({ sites }: IProps): JSX.Element => (
  <SitesTable
    sites={sites}
    columns={COLUMS_MODEL_SECTORS_LIST}
    columnConfigName="sectorList"
    filterKey={FilterKey.sectors}
    isForPlanning
  />
)

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <SitesProvider>
    <SitesConsumer>
      {(ctx): JSX.Element => <SectorsListScreen sites={ctx.sites} {...props} />}
    </SitesConsumer>
  </SitesProvider>
)
