import { createStyles, makeStyles } from '@material-ui/core/styles'

export default makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      paddingLeft: '20px',
      paddingRight: '20px',
      gap: '15px',
    },
    fieldLine: {
      display: 'flex',
      flex: 1,
    },
    childrenContainer: {
      flex: 1,
      display: 'flex',
    },
    subContainer: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      paddingLeft: '20px',
      gap: '15px',
    },
    switchContainer: {
      marginLeft: '0',
    },
    labelDiv: {
      paddingTop: '12px',
      fontSize: '1rem',
    },
    sectionName: {
      fontSize: '1rem',
    },
    subSection: {
      border: '0.5px solid #D5D5D5',
      borderRadius: '2px',
      padding: '15px',
    },
    fullWidth: {
      width: '100%',
    },
    toggleContainer: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
    },
  }),
)
