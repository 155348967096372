import moment from 'moment'
import { IError } from 'api/types'
import Api from 'services/api'

import { IOrderFilters, ICustomer, IWarehouse, IChartStat } from 'interfaces/interfaces'
import { IAddress } from 'interfaces/Itours'
import {
  IOrder,
  IOrderDetails,
  IOrderStats,
  IOrderCreate,
  IOrderUnplan,
  IOrderUpdate,
  IAllOrders,
  IOrderDeliveryScheduleChangesStats,
  CreatedOrder,
  IOrderHistory,
} from 'interfaces/IOrders'
import { IStopStat } from 'interfaces/IStopStat'
import { ITourIndicator } from 'interfaces/ITourIndicator'
import { ITransportVolumeStat } from 'interfaces/ITransportVolumeStat'
import { IHourlyStats } from 'interfaces/IHourlyStats'
import { IAvgStatsTours } from 'interfaces/IAvgStatsTours'
import { formatDateInterval } from 'utils/libs/date-range'

const parseSingleOrder = (order: IOrder): IOrder => ({
  orderId: order.orderId,
  deliveryDateTimeMaximum: order.deliveryDateTimeMaximum,
  deliveryDateTimeMinimum: order.deliveryDateTimeMinimum,
  deliveryTime: formatDateInterval(
    order.deliveryDateTimeMinimum,
    order.deliveryDateTimeMaximum,
    true,
  ),
  deliveryAddress: order.fullAddress as string,
  deliveryType: order.deliveryType,
  transportType: order.transportType,
  customer: order.customerId as ICustomer,
  orderRequestType: 0,
  orderNumber: order.orderNumber,
  warehouse: order.warehouseId as IWarehouse,
  mainQuantity: order.mainQuantity,
  location: order.location,
  locationRelevance: order.locationRelevance,
  deliverySiteId: order.deliverySiteId,
  planStatus: order.planStatus,
  enterpriseLabel: order.enterpriseLabel,
  geocodingSource: order.geocodingSource,
  estimatedWeight: order.estimatedWeight,
})

const parseOrderDetails = (orderDetails: IOrderDetails): IOrderDetails => ({
  customerEmail: orderDetails.customerEmail,
  mainQuantity: orderDetails.mainQuantity,
  deliveryAddressNumber: orderDetails.deliveryAddressNumber,
  deliveryAddressRoad: orderDetails.deliveryAddressRoad,
  deliveryAddressCity: orderDetails.deliveryAddressCity,
  deliveryAddressCountry: orderDetails.deliveryAddressCountry,
  deliveryAddressZipCode: orderDetails.deliveryAddressZipCode,
  carrierId: orderDetails.carrierId,
  customerFirstName: orderDetails.customerFirstName,
  customerLastName: orderDetails.customerLastName,
  deliveryDateTimeMaximum: orderDetails.deliveryDateTimeMaximum,
  deliveryDateTimeMinimum: orderDetails.deliveryDateTimeMinimum,
  disputes: orderDetails.disputes,
  estimatedWeight: orderDetails.estimatedWeight,
  orderId: orderDetails.orderId,
  orderNumber: orderDetails.orderNumber,
  visits: (orderDetails.visits || []).map((visit) => ({
    ...visit,
    arrivalMaxDate: new Date(visit.arrivalMaxDate),
    arrivalMinDate: new Date(visit.arrivalMinDate),
    estimatedArrival: visit.estimatedArrival ? new Date(visit.estimatedArrival) : null,
    planedArrival: visit.planedArrival ? new Date(visit.planedArrival) : null,
    // plannedArrival: visit.plannedArrival ? new Date(visit.plannedArrival) : null,
    realArrival: visit.realArrival ? new Date(visit.realArrival) : null,
  })),
  planVisits: (orderDetails.planVisits || []).map((planVisit) => ({
    ...planVisit,
    plannedDate: planVisit.plannedDate ? new Date(planVisit.plannedDate) : null,
  })),
  location: orderDetails.location,
  customerPhoneNumber: orderDetails.customerPhoneNumber,
  deliveryAddress: orderDetails.deliveryAddress,
  warehouseName: orderDetails.warehouseName,
  deliveryType: orderDetails.deliveryType,
  transportType: orderDetails.transportType,
  orderDetails: orderDetails.orderDetails,
  parcels: orderDetails.parcels,
  enterpriseLabel: orderDetails.enterpriseLabel,
  warehouseId: orderDetails.warehouseId,
  planStatus: orderDetails.planStatus,
  dealerId: orderDetails.dealerId,
  tags: orderDetails.tags,
  tenantId: orderDetails.tenantId,
  estimatedServiceTime: orderDetails.estimatedServiceTime,
  deliveryInstructions: orderDetails.deliveryInstructions,
  orderAmount: orderDetails.orderAmount,
  initialDeliveryDateTimeMaximum: orderDetails.initialDeliveryDateTimeMaximum,
  initialDeliveryDateTimeMinimum: orderDetails.initialDeliveryDateTimeMinimum,
  orderInstructions: orderDetails.orderInstructions,
  deliveryFloor: orderDetails.deliveryFloor,
  deliveryIsElevatorPresent: orderDetails.deliveryIsElevatorPresent,
  deliveryDoorCode: orderDetails.deliveryDoorCode,
})

const parseOrders = (orders: Array<IOrder>): Array<IOrder> =>
  orders.map((elem) => parseSingleOrder(elem))

const getFiltersToApply = (filters?: IOrderFilters) => ({
  startDate: filters?.startDate || undefined,
  endDate: filters?.endDate || undefined,
  relevance: filters?.relevance,
  deliveryTypes: filters?.deliveryTypes,
  transportTypes: filters?.transportTypes,
  warehouseIds: filters?.siteIds,
  deliverySiteIds: filters?.deliverySiteIds,
  planStatuses: filters?.planStatuses?.map(Number),
  zipCodes: filters?.zipCodes,
  ...((filters?.search?.length || 0) >= 3 && { search: filters?.search }),
})

const getOrders = async (
  filters?: IOrderFilters,
  offset?: number,
  rowsPerPage?: number,
  sortBy?: string,
  sortDirection?: number,
): Promise<{ orders: Array<IOrder>; count: number } | IError> => {
  try {
    const filtersToApply = getFiltersToApply(filters)

    const res = await Api.get('orders', {
      ...filtersToApply,
      offset,
      limit: rowsPerPage,
      sortBy: sortBy || 'orderNumber',
      sortDirection: sortDirection || 1,
    })
    return { orders: parseOrders(res.items), count: res.count }
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getAllOrders = async (
  filters?: IOrderFilters,
): Promise<{ orders: Array<IAllOrders> } | IError> => {
  try {
    const filtersToApply = getFiltersToApply(filters)

    const res = await Api.get('orders/all', {
      ...filtersToApply,
    })
    return { orders: parseOrders(res) }
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getOrderHistory = async (orderId: string): Promise<IOrderHistory[] | IError> => {
  try {
    return await Api.get(`orders/history/${orderId}`)
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getOrderDetails = async (
  orderId: string,
  shouldGetPlanVisits = false,
): Promise<IOrderDetails | IError> => {
  try {
    const res = await Api.get(`orders/details/${orderId}`, {
      getPlanVisits: shouldGetPlanVisits,
    })
    return parseOrderDetails(res)
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getExtendedOrderDetailsByOrderNumber = async (
  orderNumber: string,
): Promise<IOrderDetails | IError> => {
  try {
    const res = await Api.get(`orders/details/extended/orderNumber/${orderNumber}`)
    return parseOrderDetails(res)
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getOrdersStats = async (
  deliveryTypes?: string[],
  transportTypes?: string[],
  warehouseIds?: string[],
  planStatuses?: string[],
  deliveryWarehouseIds?: string[],
  zipCodes?: string[],
  minDay?: string,
  maxDay?: string,
): Promise<IOrderStats[] | IError> => {
  try {
    const res = await Api.get('stats/count-orders-per-day', {
      minDay: minDay
        ? moment(minDay).format('DD/MM/YYYY')
        : moment().subtract(30, 'days').format('DD/MM/YYYY'),
      maxDay: maxDay ? moment(maxDay).format('DD/MM/YYYY') : undefined,
      deliveryTypes,
      transportTypes,
      zipCodes,
      warehouseIds,
      planStatuses,
      deliverySiteIds: deliveryWarehouseIds,
    })
    return res
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getOrdersStatsWhereDeliveryScheduleChanged = async (
  minDay?: string,
  maxDay?: string,
  warehouseIds?: string[],
  carrierIds?: string[],
): Promise<IOrderDeliveryScheduleChangesStats | IError> => {
  try {
    const res = await Api.get('stats/count-orders-where-delivery-schedule-changes', {
      minDay: minDay
        ? moment(minDay).format('DD/MM/YYYY')
        : moment().subtract(30, 'days').format('DD/MM/YYYY'),
      maxDay: maxDay ? moment(maxDay).format('DD/MM/YYYY') : undefined,
      warehouseIds,
      carrierIds,
    })
    return res
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const patchOrderPosition = async (
  orderId: string,
  address?: Partial<IAddress>,
  shouldCacheAddressLabel?: boolean,
): Promise<IOrder | IError> => {
  try {
    const res = await Api.patch(`orders/${orderId}/location`, {
      ...address,
      shouldCacheAddressLabel,
    })
    return parseSingleOrder(res)
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getStopStats = async (
  startDate: string,
  endDate: string,
  carrierIds?: string[],
  siteIds?: string[],
  toleratedAdvance?: number,
  toleratedDelay?: number,
): Promise<IStopStat | IError> => {
  try {
    const response = await Api.get('/stats/get-stops-delay-status-in-date-range', {
      minDay: new Date(startDate).toISOString(),
      maxDay: new Date(endDate).toISOString(),
      jetLag: -new Date().getTimezoneOffset() / 60,
      carrierIds,
      siteIds,
      toleratedAdvance,
      toleratedDelay,
    })
    return {
      totalCount: response.totalCount,
      lessThanAcceptableDelayCount: response.lessThanAcceptableDelayCount,
      betweenAcceptableDelayBoundsCount: response.betweenAcceptableDelayBoundsCount,
      greaterThanAcceptableDelayCount: response.greaterThanAcceptableDelayCount,
    } as IStopStat
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getTourStats = async (
  startDate: string,
  endDate: string,
  carrierIds?: string[],
  siteIds?: string[],
): Promise<ITourIndicator | IError> => {
  try {
    const response = await Api.get('/stats/get-tours-status-stats', {
      minDate: new Date(startDate).toISOString(),
      maxDate: new Date(endDate).toISOString(),
      carrierIds,
      siteIds,
    })
    return response
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getTransportVolumeStats = async (
  startDate: string,
  endDate: string,
  carrierIds?: string[],
  siteIds?: string[],
): Promise<ITransportVolumeStat | IError> => {
  try {
    const response = await Api.get('/stats/get-carriers-tours-stat-in-date-range', {
      minDay: new Date(startDate).toLocaleDateString('fr'),
      maxDay: new Date(endDate).toLocaleDateString('fr'),
      carrierIds,
      siteIds,
    })
    return {
      ...response,
      carriersList: Array.from(new Set<string>(response.carriersList)),
    }
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getAvgStatsOfTours = async (
  startDate: string,
  endDate: string,
  carrierIds?: string[],
  siteIds?: string[],
): Promise<IAvgStatsTours | IError> => {
  try {
    const response = await Api.get('/stats/avg-stats-tours-day', {
      minDay: new Date(startDate).toLocaleDateString('fr'),
      maxDay: new Date(endDate).toLocaleDateString('fr'),
      carrierIds,
      siteIds,
    })
    return response
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getOrderStats = async (
  date: string,
  deliveryType?: string[],
  transportTypes?: string[],
  deliveryWarehouseIds?: string[],
  warehouseIds?: string[],
  planStatuses?: string[],
  zipCodes?: string[],
  groupBy?: number,
): Promise<IHourlyStats[] | IError> => {
  try {
    const response = await Api.post('/stats/get-orders-per-day-per-type', {
      date: new Date(date).toLocaleDateString('fr'),
      deliveryType,
      transportTypes,
      jetLag: -new Date().getTimezoneOffset() / 60,
      deliveryWarehouseIds,
      warehouseIds,
      planStatuses,
      zipCodes,
      groupBy,
    })
    return response
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getOrderPlanningStats = async (
  date: string,
  deliveryTypes?: string[],
  transportTypes?: string[],
  siteIds?: string[],
): Promise<IChartStat | IError> => {
  try {
    const response = await Api.get('/stats/count-tours-orders-per-types-by-date', {
      date: new Date(date).toLocaleDateString('fr'),
      deliveryTypes,
      transportTypes,
      siteIds,
    })
    return response
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const create = async (order: IOrderCreate): Promise<CreatedOrder | IError> => {
  try {
    const res = await Api.post('orders', [order])
    return res
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

const checkOrderNumber = async (orderNumber: string): Promise<boolean | IError> => {
  try {
    const response = await Api.get(`orders/orderNumber/${orderNumber}/check`)
    return Boolean(response)
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

const unplan = async (order: IOrderUnplan): Promise<IOrder | IError> => {
  try {
    const res = await Api.post('orders/unplan', order)
    return res
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

const updateOrderAppointment = async (
  orderId: string,
  minDate: Date,
  maxDate: Date,
): Promise<void | IError> => {
  try {
    await Api.patch(`orders/appointment/${orderId}`, {
      deliveryDateTimeMinimum: minDate,
      deliveryDateTimeMaximum: maxDate,
    })
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const update = async (orderId: string, order: IOrderUpdate): Promise<IOrderDetails | IError> => {
  try {
    const response = await Api.patch(`orders/${orderId}`, order)
    return response
  } catch (error) {
    return {
      error: {
        message: error.message,
        fieldErrors: error.fieldErrors,
        code: error.statusCode,
      },
    }
  }
}

export default {
  getOrders,
  getOrderDetails,
  getOrderHistory,
  getExtendedOrderDetailsByOrderNumber,
  getOrdersStats,
  getOrdersStatsWhereDeliveryScheduleChanged,
  patchOrderPosition,
  getStopStats,
  getTourStats,
  getTransportVolumeStats,
  getAvgStatsOfTours,
  getOrderStats,
  getOrderPlanningStats,
  create,
  checkOrderNumber,
  unplan,
  updateOrderAppointment,
  update,
  getAllOrders,
}
