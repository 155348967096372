import {
  MENU_SUBCATEGORIES,
  MENU_CATEGORIES,
  USER_ROLES,
  PILOT_AND_STORE_USER_ROLES,
} from 'constants/constants'
import CostConfigListScreen from 'screens/SettingsCategory/Costs/CostConfigListScreen'
import CostConfigFormScreen from 'screens/SettingsCategory/Costs/CostConfigFormScreen'
import CostConfigDetailsScreen from 'screens/SettingsCategory/Costs/CostConfigDetailsScreen'
import CostsReportingScreen from 'screens/ReportingCategory/CostsReporting/CostsReportingScreen'
import { ROUTES_PATH } from './RoutesPath'

const COST_CATEGORY = [
  {
    title: MENU_SUBCATEGORIES.reporting,
    path: ROUTES_PATH.reportingCosts,
    component: CostsReportingScreen,
    isPrivate: true,
    isInMenu: true,
    isBetaMode: true,
    description: "Accès à l'écran reporting facturation",
    category: MENU_CATEGORIES.cost,
    roles: [USER_ROLES.superAdmin, ...PILOT_AND_STORE_USER_ROLES],
  },
  {
    title: MENU_SUBCATEGORIES.configurations,
    path: ROUTES_PATH.costConfigList,
    component: CostConfigListScreen,
    isPrivate: true,
    isInMenu: true,
    isBetaMode: true,
    description: 'Accès aux écrans CRU des configurations de facturation (Menu Paramètres/ Coûts)',
    category: MENU_CATEGORIES.cost,
    roles: [USER_ROLES.superAdmin, ...PILOT_AND_STORE_USER_ROLES],
  },
  {
    title: MENU_SUBCATEGORIES.configurations,
    path: `${ROUTES_PATH.costConfigDetails}:id`,
    component: CostConfigDetailsScreen,
    isPrivate: true,
    isInMenu: false,
    isBetaMode: true,
    description: 'Accès aux détails des configurations de facturation',
    category: MENU_CATEGORIES.cost,
    roles: [USER_ROLES.superAdmin, ...PILOT_AND_STORE_USER_ROLES],
  },
  {
    title: MENU_SUBCATEGORIES.configurations,
    path: `${ROUTES_PATH.editCostConfig}:id`,
    component: CostConfigFormScreen,
    isPrivate: true,
    isInMenu: false,
    isBetaMode: true,
    description: 'Accès à la modification de configurations de facturation',
    category: MENU_CATEGORIES.cost,
    roles: [USER_ROLES.superAdmin, ...PILOT_AND_STORE_USER_ROLES],
  },
  {
    title: MENU_SUBCATEGORIES.configurations,
    path: ROUTES_PATH.createCostConfig,
    component: CostConfigFormScreen,
    isPrivate: true,
    isInMenu: false,
    isBetaMode: true,
    description: 'Accès à la création de configurations de facturation',
    category: MENU_CATEGORIES.cost,
    roles: [USER_ROLES.superAdmin, ...PILOT_AND_STORE_USER_ROLES],
  },
]

export default COST_CATEGORY
