import i18n from 'i18next'

export enum DISPUTE_REASON_CODES_TYPES_CONFIG {
  Passage = 'passage',
  Loading = 'loading',
  Unloading = 'unloading',
}

export const DISPUTE_REASON_CODES_TYPES = [
  {
    value: DISPUTE_REASON_CODES_TYPES_CONFIG.Passage,
    label: i18n.t('ConfigurationsScreen.availableTypes.passage'),
  },
  {
    value: DISPUTE_REASON_CODES_TYPES_CONFIG.Loading,
    label: i18n.t('ConfigurationsScreen.availableTypes.loading'),
  },
  {
    value: DISPUTE_REASON_CODES_TYPES_CONFIG.Unloading,
    label: i18n.t('ConfigurationsScreen.availableTypes.unloading'),
  },
]

enum DISPUTE_REASON_CODES_RELATES_TO_CONFIG {
  Product = 'product',
  Support = 'support',
}

export const DISPUTE_REASON_CODES_RELATES_TO = [
  {
    value: DISPUTE_REASON_CODES_RELATES_TO_CONFIG.Product,
    label: i18n.t('ConfigurationsScreen.availableRelatesTo.product'),
  },
  {
    value: DISPUTE_REASON_CODES_RELATES_TO_CONFIG.Support,
    label: i18n.t('ConfigurationsScreen.availableRelatesTo.support'),
  },
]

export interface IDisputeReasonCode {
  code: string
  label: string
  types: string[]
  relatesTo: string[]
  isEanRequired: boolean
  isMediaRequired: boolean
}

export interface IEANListItem {
  EAN: string
  quantity: number
}

export interface IDispute {
  _id: string
  orderId: string
  orderNumber: string
  disputeNumber: string
  carrierId: string
  driverId: string
  comment: string
  creationDate: string
  eanList: IEANListItem[]
  mediaList: string[]
  reasonCode: string
  siteId: string
  sitesIds: string[]
  status: string
}

export interface IDisputeFilters {
  siteIds?: string[]
  disputeReasonCodes?: string[]
  startDate?: string
  endDate?: string
  carrierIds?: string[]
  driverIds?: string[]
}
