import { Paper, Menu, MenuItem, Button } from '@material-ui/core'
import { AddCircle, LocalShipping } from '@material-ui/icons'
import { useTheme, Theme } from '@material-ui/core/styles'
import useInterval from '@use-it/interval'
import PlanApi, {
  GroupAction,
  IDropVisitInPlan,
  IDropVisitInPlanTourWithMultisites,
  IDropVisitPlanStop,
  IRemoveVisitsFromPlan,
  IUpdate,
} from 'api/plans'
import { isIError, IError } from 'api/types'
import { AppInsightEvents, PLAN_TOUR_STATUS_ENUM, TransportType } from 'constants/constants'
import { ClusterStatus, ICluster } from 'interfaces/ICluster'
import { IVehicleColumn, ISelectableMarker } from 'interfaces/map'
import React, {
  useContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import ClusterColumn from 'screens/PlanningCategory/PlanningScreen/components/ClusterColumn/ClusterColumn'
import CreateClusterButton from 'screens/PlanningCategory/PlanningScreen/components/CreateClusterButton'
import { FeedbackContext } from 'store/FeedbackContext'
import { ContentContext } from 'store/ContentContext'
import { AuthContext } from 'store/AuthContext'
import styled from 'styled-components'
import { useDebouncedCallback } from 'use-debounce/lib'
import appInsight from 'services/appInsight'
import { computeAvailableVehicles } from 'utils/clusterUtils'
import { getErrorList } from 'utils/errorUtils'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog'
import { IJob } from 'interfaces/IJob'
import { sortPlanVisits } from 'utils/planningUtils'
import { PlanificationContext } from '../PlanningStore'
import ChangeClusterVehicleModal from './ChangeClusterVehicleModal'
import COLORS from './ClusterColors'
import SelectedColumn from './SelectedColumn'
import './styles.css'
import VehicleColumn from './VehicleColumn/VehicleColumn'
import SaveClustersButton from './SaveClustersButton'
import OutsourcedColumn from './OutsourcedColumn/OutsourcedColumn'

const CreationColumnContainer = styled.div`
  padding: 12px;
  display: flex;
  align-items: center;
`

const CreationContainer = styled.div`
  border: dashed 3px orange;
  border-radius: 12px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  cursor: pointer;
  justify-content: center;
  height 100%;
  width: 210px;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 165, 0, .1);
    transition: opacity .2s;
    opacity: 0;
    z-index: 2;
  }
  &:hover:before {
    opacity: 1;
  }
`

const ClusterButtonsContainer = styled.div`
  position: absolute;
  bottom: 0;
  margin: 8px;
  z-index: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledButton = styled(Button)<{ theme: Theme }>`
  position: absolute;
  bottom: 24px;
  right: 36px;
  background-color: ${(props) => props.theme.color.orange};
  color: white;
  &:hover {
    background-color: ${(props) => props.theme.color.orange};
    color: white;
  }
`

interface ICustomDrawer {
  setMaxOptimizationTime(value: number): void
}

const CREATE_CLUSTER_DROPPABLE_ID = 'create-cluster'

function CustomDrawer({ setMaxOptimizationTime }: ICustomDrawer): JSX.Element {
  const {
    markers,
    setMarkers,
    tours,
    setTours,
    getPlansVisits,
    getPlanTours,
    selectedPlan,
    clusters,
    setClusters,
    isClusterMode,
    isMapotempoActionInProgress,
    isProcessing,
    setIsProcessing,
    manualTours,
    setManualTours,
    isMultisite,
    hasRecoveredClusters,
    setHasRecoveredClusters,
    vehiclesList,
    isOutsourceMode,
    outsourced,
    setOutsourced,
    hideAllTours,
    setIsOutsourceMode,
    setJobIds,
    toggleBackdrop,
  } = useContext(PlanificationContext)
  const { shouldUseReorganizeUX } = useContext(AuthContext)
  const [loadingTours, setLoadingTours] = useState<string[]>([])
  const [createClusterModalOpen, setCreateClusterModalOpen] = useState<number | undefined>(
    undefined,
  )
  const [anchorElCarriersMenu, setAnchorElCarriersMenu] = useState<null | HTMLElement>(null)
  const [isSendOutsourcedDialogOpen, setIsSendOutsourcedDialogOpen] = useState<boolean>(false)
  const [isDeleteOutsourcedDialogOpen, setIsDeleteOutsourcedDialogOpen] = useState<boolean>(false)
  const [hasNonValidatedChanges, setHasNonValidatedChanges] = useState<boolean>(false)
  const [modifiedPlanToursIds, setModifiedPlanToursIds] = useState<Set<string>>(new Set())
  const { openErrorSnack, toggleLoader, openSuccessSnack } = useContext(FeedbackContext)
  const { carriers } = useContext(ContentContext)
  const clustersRef = useRef<HTMLElement>(null)

  const { t } = useTranslation()
  const theme = useTheme()

  const handleOpenCarriersMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!selectedPlan) {
      openErrorSnack(t('PlanningScreen.planNeeded'))
      return
    }
    if (activeBoundCarriers.length < 1) {
      openErrorSnack(t('PlanningScreen.noValidCarriersForPlan'))
      return
    }
    setAnchorElCarriersMenu(event.currentTarget)
  }

  const activeBoundCarriers = useMemo(() => {
    if (!selectedPlan) {
      return []
    }

    return carriers.filter((carrier) => {
      // The carrier is considered active for this plan if it is active,
      // includes the plan's main warehouse and its' validity overlaps with the plan's period

      let warehouseLinkedToCarrier

      if (
        !carrier.active
        || !carrier.warehouses?.length
        || !carrier.warehouses.some((warehouse) => {
          if (selectedPlan?.warehouseId === warehouse.id) {
            warehouseLinkedToCarrier = warehouse
            return true
          }
          return false
        })
      ) {
        return false
      }

      // Carrier is not valid for this plan if the end date of the plan is before the start of validity period
      if (
        warehouseLinkedToCarrier.validityStartDate
        && warehouseLinkedToCarrier.validityStartDate > selectedPlan?.endDate
      ) {
        return false
      }

      // Carrier is not valid for this plan if the start date of the plan is after the end of validity period
      if (
        warehouseLinkedToCarrier.validityEndDate
        && warehouseLinkedToCarrier.validityEndDate < selectedPlan?.startDate
      ) {
        return false
      }

      return true
    })
  }, [carriers, selectedPlan])

  const handleCloseCarriersMenu = () => setAnchorElCarriersMenu(null)

  const vehicleColumns = useMemo((): IVehicleColumn[] => {
    tours.sort((tour1, tour2) => Date.parse(tour1.beginDateTime) - Date.parse(tour2.beginDateTime))
    const planToursByVehicle = tours.reduce((accumulator, planTour) => {
      accumulator[planTour.vehicleId] = accumulator[planTour.vehicleId]
        || ({ vehicleId: planTour.vehicleId, planTours: [] } as IVehicleColumn)
      accumulator[planTour.vehicleId].planTours.push(planTour)
      return accumulator
    }, Object.create(null))
    return Object.values(planToursByVehicle)
  }, [tours])

  useEffect(() => {
    setClusters([])
    setManualTours([])
    setHasRecoveredClusters(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlan])

  useEffect(() => {
    if (isClusterMode && !hasRecoveredClusters && markers.length > 0) {
      recoverClusters()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClusterMode, markers.length])

  const recoverClusters = () => {
    const markersWithGroup = markers.filter((marker) => marker.group)
    if (markersWithGroup.length > 0) {
      const groupedMarkers = markersWithGroup.reduce((acc, marker) => {
        const groupId = marker.group?.groupId.split('-')[1] || ''
        acc[groupId] = acc[groupId] ?? []
        acc[groupId].push(marker)
        return acc
      }, {})

      const clustersToCreate: ICluster[] = []
      const groupIds = Object.keys(groupedMarkers)
      groupIds.forEach((groupId, index) => {
        // we need to create the the clusters
        const visits = groupedMarkers[groupId]
        clustersToCreate.push({
          vehicleIds: visits[0].group?.vehicleIds || [],
          visits,
          color: COLORS[(+groupId - 1) % COLORS.length],
          status: index === groupIds.length - 1 ? ClusterStatus.modification : ClusterStatus.new,
          clusterId: +groupId,
        })
      })
      setClusters(clustersToCreate)
      setMarkers((previousState) =>
        previousState.map((marker) => {
          if (marker.group) {
            const clusterId = +marker.group.groupId.split('-')[1]
            return { ...marker, clusterId, color: COLORS[(clusterId - 1) % COLORS.length] }
          }
          return marker
        }),
      )
    }
    setHasRecoveredClusters(true)
  }

  const computeClusterCapacity = (vehicleIds: string[]): number =>
    vehiclesList
      ?.filter((veh) => vehicleIds.includes(veh.id))
      .reduce((total, current) => total + current.capacity, 0) || 0

  async function checkProcessingPlans(): Promise<void> {
    toggleLoader(true)
    const res = await PlanApi.getProcessingPlans({ planIds: tours.map((tour) => tour.tourId) })
    if (!isIError(res) && res.length > 0) {
      const plansStillProcessing = res
        .map((plan) => (plan.isProcessing ? plan.planToursId : ''))
        .filter((plan) => plan && plan.length > 0)
      if (plansStillProcessing.length === 0 || !isProcessing) {
        getPlansVisits()
        getPlanTours()
        setIsProcessing(false)
        setLoadingTours([])
      } else {
        setIsProcessing(true)
        // let all the plan load if one of them is loading
        setLoadingTours(res.map((plan) => plan.planToursId))
      }
    } else if (isIError(res)) {
      openErrorSnack(res.error.message)
    }
    toggleLoader(false)
  }

  const callEnded = () => {
    getPlansVisits()
    getPlanTours()
    setIsProcessing(false)
    setLoadingTours([])
    setIsOutsourceMode(false)
    setOutsourced(null)
    toggleLoader(false)
  }

  async function _dropItemInPlanTour({
    planId,
    visitId,
    index,
    automaticInsert,
  }: IDropVisitInPlan): Promise<void> {
    setLoadingTours(tours.map((tour) => tour.tourId))
    toggleLoader(true)
    const res = await PlanApi.dropVisitInPlan({
      planId,
      visitId,
      index: index + 1,
      automaticInsert,
    })
    if (isIError(res)) {
      openErrorSnack(res.error.message)
    }
    callEnded()
  }

  async function _dropItemInPlanTourWithMultisites({
    planId,
    planStops,
    newOrUpdatedPlanStop,
    automaticInsert,
  }: IDropVisitInPlanTourWithMultisites): Promise<void> {
    setLoadingTours(tours.map((tour) => tour.tourId))
    toggleLoader(true)
    const res = await PlanApi.dropVisitInPlanTourWithMultisites({
      planId,
      planStops,
      newOrUpdatedPlanStop,
      automaticInsert,
    })
    if (isIError(res)) {
      openErrorSnack(res.error.message)
    }
    callEnded()
  }

  const dropItemInPlanTour = useDebouncedCallback(_dropItemInPlanTour, 800, {
    trailing: true,
  }).callback

  const dropItemInPlanTourWithMultisites = useDebouncedCallback(
    _dropItemInPlanTourWithMultisites,
    800,
    {
      trailing: true,
    },
  ).callback

  async function removeItemFromPlanTour({
    planId,
    visitIds,
  }: IRemoveVisitsFromPlan): Promise<void> {
    setLoadingTours(tours.map((tour) => tour.tourId))
    toggleLoader(true)
    const res = await PlanApi.removeVisitsFromPlan({ planId, visitIds })
    if (isIError(res)) {
      openErrorSnack(res.error.message)
    }
    callEnded()
  }

  const uselessFunc = (): void => {
    /* Do nothing here */
  }

  const scrollClustersLeft = () => clustersRef.current?.scrollTo(0, 0)

  const createClusterOrManualTour = (setElements: Dispatch<SetStateAction<ICluster[]>>) => {
    if (isMapotempoActionInProgress) {
      return
    }
    setElements((prevElems) => {
      let newElementId = 1
      if (prevElems.length > 0) {
        const currentElements = [...prevElems]
        currentElements.sort((e1, e2) => e1.clusterId - e2.clusterId)
        newElementId = currentElements[currentElements.length - 1].clusterId + 1
      }
      return [
        ...prevElems.map((element) =>
          element.status === ClusterStatus.modification
            ? { ...element, status: ClusterStatus.new }
            : element,
        ),
        {
          vehicleIds: [],
          visits: [],
          color: COLORS[(newElementId - 1) % COLORS.length],
          status: ClusterStatus.modification,
          clusterId: newElementId,
        },
      ]
    })
    scrollClustersLeft()
  }

  const createCluster = () => {
    createClusterOrManualTour(setClusters)
  }

  const createManualTour = () => {
    createClusterOrManualTour(setManualTours)
  }

  const onClusterVehicleChange = (clusterId: number, vehicleIds: string[]) =>
    setClusters(
      clusters.map((cluster) =>
        cluster.clusterId === clusterId ? { ...cluster, vehicleIds: [...vehicleIds] } : cluster,
      ),
    )

  const onManualTourVehicleChange = (manualTourId: number, vehicleIds: string[]) =>
    setManualTours(
      manualTours.map((manualTour) =>
        manualTour.clusterId === manualTourId
          ? { ...manualTour, vehicleIds: [...vehicleIds] }
          : manualTour,
      ),
    )

  const onClusterOrManualTourLock = (
    elements: ICluster[],
    setElements: Dispatch<SetStateAction<ICluster[]>>,
    elementId: number,
  ): void => {
    const modifiedElement = elements.find((element) => element.clusterId === elementId)
    if (modifiedElement?.status === ClusterStatus.modification) {
      // if we validate an element, we want to modify the next one not validated
      const firstElementToModify = elements.find((element) => element.status === ClusterStatus.new)
      setElements(
        elements.map((element) => {
          if (element.clusterId === elementId) return { ...element, status: ClusterStatus.closed }
          if (firstElementToModify && element.clusterId === firstElementToModify.clusterId) {
            return { ...element, status: ClusterStatus.modification }
          }
          return element
        }),
      )
    } else if (
      modifiedElement?.status === ClusterStatus.new
      || modifiedElement?.status === ClusterStatus.closed
    ) {
      // Only one element in modification state
      const firstElementInModification = elements.find(
        (element) => element.status === ClusterStatus.modification,
      )
      setElements(
        elements.map((element) => {
          if (element.clusterId === elementId) {
            return { ...element, status: ClusterStatus.modification }
          }
          if (
            firstElementInModification
            && element.clusterId === firstElementInModification.clusterId
          ) {
            return { ...element, status: ClusterStatus.new }
          }
          return element
        }),
      )
    }
    scrollClustersLeft()
  }

  const onClusterLock = (clusterId: number): void => {
    onClusterOrManualTourLock(clusters, setClusters, clusterId)
  }

  const onManualTourLock = (manualTourId: number): void => {
    onClusterOrManualTourLock(manualTours, setManualTours, manualTourId)
  }

  const onClusterOrManualTourDelete = (
    elements: ICluster[],
    setElements: Dispatch<SetStateAction<ICluster[]>>,
    isManualTour: boolean,
    elementId: number,
  ): void => {
    // if deleted element was in modification and there are still unvalidated elements,
    // put the first unvalidated in modification mode
    const elemetToDelete = elements.find((element) => element.clusterId === elementId)
    elemetToDelete?.visits.forEach((visit) =>
      isManualTour
        ? onManualTourVisitDelete(visit.stopId, elementId, true)
        : onClusterVisitDelete(visit.stopId, elementId, true),
    )
    const firstElementToModify = elements.find((element) => element.status === ClusterStatus.new)
    const newElements = elements.filter((element) => element.clusterId !== elementId)
    if (elemetToDelete?.status !== ClusterStatus.modification || !firstElementToModify) {
      return setElements(newElements)
    }
    return setElements(
      newElements.map((element) =>
        firstElementToModify.clusterId === element.clusterId
          ? { ...element, status: ClusterStatus.modification }
          : element,
      ),
    )
  }

  const onClusterDelete = (clusterId: number): void => {
    onClusterOrManualTourDelete(clusters, setClusters, false, clusterId)
    const updates: IUpdate[] = [
      {
        groupId: `${selectedPlan?.id}-${clusterId}`,
        action: GroupAction.Remove,
      },
    ]
    updatePlanVisitsGroup(updates, () => {
      openSuccessSnack(t('PlanningScreen.successfullyDeleted'))
    })
  }

  const onManualTourDelete = (manualTourId: number): void => {
    onClusterOrManualTourDelete(manualTours, setManualTours, true, manualTourId)
  }

  useInterval(isProcessing ? checkProcessingPlans : uselessFunc, 1000)

  const onVisitDelete = (
    setElements: Dispatch<SetStateAction<ICluster[]>>,
    isManualTour: boolean,
    visitId: string,
    elementId: number,
    avoidElementModif = false,
  ) => {
    if (!avoidElementModif) {
      setElements((prevElements) => {
        const sourceElements = [...prevElements]
        const elementIndex = sourceElements.findIndex((elem) => elem.clusterId === elementId)
        sourceElements[elementIndex].visits = sourceElements[elementIndex].visits.filter(
          (v) => v.stopId !== visitId,
        )
        return sourceElements
      })
    }
    setMarkers((prevMarkers) => {
      const sourceMarkers = [...prevMarkers]
      const originalIndex = sourceMarkers.findIndex((marker) => marker.stopId === visitId)
      if (sourceMarkers[originalIndex].isNotCompatibleWithFilters) {
        sourceMarkers.splice(originalIndex, 1)
        return sourceMarkers
      }
      sourceMarkers[originalIndex].selected = false
      if (isManualTour) {
        sourceMarkers[originalIndex].manualTourId = undefined
      } else {
        sourceMarkers[originalIndex].clusterId = undefined
      }
      // @ts-ignore
      sourceMarkers[originalIndex].color = undefined
      // @ts-ignore
      sourceMarkers[originalIndex].order = undefined
      return sourceMarkers
    })
  }

  const onClusterVisitDelete = (visitId, clusterId, avoidClusterModif = false) => {
    onVisitDelete(setClusters, false, visitId, clusterId, avoidClusterModif)
  }

  const onManualTourVisitDelete = (visitId, manualTourId, avoidManualTourModif = false) => {
    onVisitDelete(setManualTours, true, visitId, manualTourId, avoidManualTourModif)
  }

  const onClusterDrag = (source, destination): void => {
    const destClusterId = Number(destination.droppableId.split('-')[1])
    const destClusterStatus = clusters.find(
      (cluster) => cluster.clusterId === destClusterId,
    )?.status

    // TODO: could be improve and if status is new, pass the status to modification,
    // do the job, and pass the one on modification in new
    if (destClusterStatus === ClusterStatus.closed) return

    // const srcClusterId = source.droppableId.split('-')[1]
    if (
      source.droppableId.includes('ClusterDroppable')
      && destination.droppableId.includes('ClusterDroppable')
    ) {
      // Cluster visit to another cluster
      const sourceClusterId = Number(source.droppableId.split('-')[1])

      // Can't reorder visits inside same cluster
      if (destClusterId === sourceClusterId) return

      const sourceClusterStatus = clusters.find(
        (cluster) => cluster.clusterId === sourceClusterId,
      )?.status
      if (sourceClusterStatus === ClusterStatus.closed) return
      let markerColor = ''
      let sourceVisits: ISelectableMarker[] = []
      setClusters((prevClusters) => {
        const previousClusters = [...prevClusters]
        const sourceClusterIndex = previousClusters.findIndex(
          (cluster) => cluster.clusterId === sourceClusterId,
        )
        const { visits: sourceClusterVisits } = previousClusters[sourceClusterIndex]
        sourceVisits = [...sourceClusterVisits]
        // Add visit to destination cluster
        const destClusterIndex = previousClusters.findIndex(
          (cluster) => cluster.clusterId === destClusterId,
        )
        const { visits: destinationClusterVisits } = previousClusters[destClusterIndex]

        const sortedSourceVisits = sortPlanVisits(sourceClusterVisits)
        const sortedDestinationVisits = sortPlanVisits(destinationClusterVisits)
        sortedDestinationVisits.splice(destination.index, 0, sortedSourceVisits[source.index])
        markerColor = previousClusters[destClusterIndex].color
        previousClusters[destClusterIndex].visits = sortedDestinationVisits
        // Remove visit from source cluster
        sortedSourceVisits.splice(source.index, 1)
        previousClusters[sourceClusterIndex].visits = sortedSourceVisits
        return previousClusters
      })
      setMarkers((prevMarkers) => {
        const previousMarkers = [...prevMarkers]
        const originalIndex = previousMarkers.findIndex(
          (marker) => marker.stopId === sourceVisits[source.index].stopId,
        )
        previousMarkers[originalIndex].selected = false
        previousMarkers[originalIndex].clusterId = destClusterId
        previousMarkers[originalIndex].color = markerColor
        previousMarkers[originalIndex].order = destination.index
        return previousMarkers
      })
      return
    }
    const availableVisits: ISelectableMarker[] = sortPlanVisits(markers).filter(
      (marker) => !marker.tourId && marker.clusterId === undefined,
    )

    if (
      source.droppableId.includes('SelectedStops')
      && destination.droppableId.includes('ClusterDroppable')
    ) {
      // selected visit to a cluster
      let markerColor = ''
      // Keep availableVisits sorted as you would get surprises with index otherwise
      setClusters((prevClusters) => {
        const sourceClusters = [...prevClusters]
        const clusterIndex = sourceClusters.findIndex(
          (cluster) => cluster.clusterId === destClusterId,
        )
        const { visits } = sourceClusters[clusterIndex]
        visits.splice(destination.index, 0, availableVisits[source.index])
        markerColor = sourceClusters[clusterIndex].color
        sourceClusters[clusterIndex].visits = visits
        return sourceClusters
      })
      setMarkers((prevMarkers) => {
        const sourceMarkers = [...prevMarkers]
        const originalIndex = sourceMarkers.findIndex(
          (marker) => marker.stopId === availableVisits[source.index].stopId,
        )
        sourceMarkers[originalIndex].selected = false
        sourceMarkers[originalIndex].clusterId = destClusterId
        sourceMarkers[originalIndex].color = markerColor
        sourceMarkers[originalIndex].order = destination.index
        return sourceMarkers
      })
    }

    if (clusters.length === 0 && destination.droppableId === CREATE_CLUSTER_DROPPABLE_ID) {
      if (isMapotempoActionInProgress) {
        return
      }
      const color = COLORS[0]
      setClusters([
        {
          vehicleIds: [],
          visits: [availableVisits[source.index]],
          color,
          status: ClusterStatus.modification,
          clusterId: 1,
        },
      ])
      setMarkers((prevMarkers) => {
        const sourceMarkers = [...prevMarkers]
        const originalIndex = sourceMarkers.findIndex(
          (marker) => marker.stopId === availableVisits[source.index].stopId,
        )
        sourceMarkers[originalIndex].selected = false
        sourceMarkers[originalIndex].clusterId = 1
        sourceMarkers[originalIndex].color = color
        sourceMarkers[originalIndex].order = 0
        return sourceMarkers
      })
      scrollClustersLeft()
    }
  }

  const onOutsourceDrag = (source, destination): void => {
    if (
      source.droppableId.includes('SelectedStops')
      && destination.droppableId === 'outsourced-droppable'
      && outsourced
    ) {
      const availableVisits: ISelectableMarker[] = sortPlanVisits(markers).filter(
        (marker) => !marker.tourId && !marker.isOutsourced,
      )

      setOutsourced((prevOutsourced) => {
        const outsourcedUpdated = { ...prevOutsourced }
        const { visits } = outsourced
        visits?.splice(destination.index, 0, availableVisits[source.index])
        outsourced.visits = visits
        return outsourcedUpdated
      })
      setMarkers((prevMarkers) => {
        const colorIndex = 4
        const sourceMarkers = [...prevMarkers]
        const originalIndex = sourceMarkers.findIndex(
          (marker) => marker.stopId === availableVisits[source.index].stopId,
        )
        sourceMarkers[originalIndex].selected = false
        sourceMarkers[originalIndex].color = COLORS[colorIndex]
        sourceMarkers[originalIndex].order = destination.index
        sourceMarkers[originalIndex].isOutsourced = true
        return sourceMarkers
      })
    }
  }

  const handlePlanTourModification = (tourId: string) => {
    if (!modifiedPlanToursIds.has(tourId)) {
      modifiedPlanToursIds.add(tourId)
      setModifiedPlanToursIds(new Set(modifiedPlanToursIds))
    }
  }

  const onManualTourDrop = (source, destination): void => {
    // Can't drag and drop a visit from a tour to a manualTour
    if (
      source.droppableId.includes('TourDroppable')
      && destination.droppableId.includes('TourManualDroppable')
    ) {
      return
    }

    const destManualTourId = Number(destination.droppableId.split('-')[1])

    const destManualTourStatus = manualTours.find(
      (manualTour) => manualTour.clusterId === destManualTourId,
    )?.status

    // Can't drop if manual tour status is closed
    // TODO: could be improve and if status is new, pass the status to modification,
    // do the job, and pass the one in modification to new
    if (destManualTourStatus === ClusterStatus.closed) return

    if (
      source.droppableId.includes('SelectedStops')
      && destination.droppableId.includes('TourManualDroppable')
    ) {
      // from selected list to manual tour
      let markerColor = ''
      let availableVisits: ISelectableMarker[] = []
      if (markers.findIndex((e) => e.selected) === -1) {
        availableVisits = markers.filter(
          (marker) => !marker.tourId && marker.manualTourId === undefined,
        )
      } else {
        availableVisits = markers.filter((marker) => marker.selected)
      }
      availableVisits = sortPlanVisits(availableVisits)

      setManualTours((prevManualTours) => {
        const sourceManualTours = [...prevManualTours]
        const manualTourIndex = sourceManualTours.findIndex(
          (manualTour) => manualTour.clusterId === destManualTourId,
        )
        const { visits } = sourceManualTours[manualTourIndex]
        visits.splice(destination.index, 0, availableVisits[source.index])
        markerColor = sourceManualTours[manualTourIndex].color
        sourceManualTours[manualTourIndex].visits = visits
        return sourceManualTours
      })

      setMarkers((prevMarkers) => {
        const sourceMarkers = [...prevMarkers]
        const originalIndex = sourceMarkers.findIndex(
          (marker) => marker.stopId === availableVisits[source.index].stopId,
        )
        sourceMarkers[originalIndex].selected = false
        sourceMarkers[originalIndex].manualTourId = destManualTourId
        sourceMarkers[originalIndex].color = markerColor
        sourceMarkers[originalIndex].order = destination.index
        return sourceMarkers
      })
    } else if (
      source.droppableId.includes('TourManualDroppable')
      && destination.droppableId.includes('TourManualDroppable')
    ) {
      // from manualTour (source) to manualTour (destination)
      const sourceManualTourId = Number(source.droppableId.split('-')[1])

      // Can't reorder visits inside same manual tour
      if (destManualTourId === sourceManualTourId) return

      const sourceManualTourStatus = manualTours.find(
        (manualTour) => manualTour.clusterId === sourceManualTourId,
      )?.status
      if (sourceManualTourStatus === ClusterStatus.closed) return

      let markerColor = ''
      let sourceVisits: ISelectableMarker[] = []
      setManualTours((prevManualTours) => {
        const previousManualTours = [...prevManualTours]
        const sourceManualTourIndex = previousManualTours.findIndex(
          (manualTour) => manualTour.clusterId === sourceManualTourId,
        )
        const { visits: sourceManualTourVisits } = previousManualTours[sourceManualTourIndex]
        sourceVisits = [...sourceManualTourVisits]
        // Add visit to destination manualTour
        const destManualTourIndex = previousManualTours.findIndex(
          (manualTour) => manualTour.clusterId === destManualTourId,
        )
        const { visits: destinationManualTourVisits } = previousManualTours[destManualTourIndex]
        destinationManualTourVisits.splice(
          destination.index,
          0,
          sourceManualTourVisits[source.index],
        )
        markerColor = previousManualTours[destManualTourIndex].color
        previousManualTours[destManualTourIndex].visits = destinationManualTourVisits
        // Remove visit from source manualTour
        sourceManualTourVisits.splice(source.index, 1)
        previousManualTours[sourceManualTourIndex].visits = sourceManualTourVisits
        return previousManualTours
      })
      setMarkers((prevMarkers) => {
        const previousMarkers = [...prevMarkers]
        const originalIndex = previousMarkers.findIndex(
          (marker) => marker.stopId === sourceVisits[source.index].stopId,
        )
        previousMarkers[originalIndex].selected = false
        previousMarkers[originalIndex].manualTourId = destManualTourId
        previousMarkers[originalIndex].color = markerColor
        previousMarkers[originalIndex].order = destination.index
        return previousMarkers
      })
    }
  }

  // You probably shouldn't touch this function if you have no idea what it's supposed to do
  function onDragEnd(result): void {
    const { source, destination } = result

    // dropped outside the list
    if (!destination) {
      return
    }

    appInsight.trackEvent({ name: AppInsightEvents.DragAndDrop })

    if (isOutsourceMode) {
      return onOutsourceDrag(source, destination)
    }

    if (isClusterMode) {
      return onClusterDrag(source, destination)
    }

    // Can't drag and drop a visit from a manualTour to selected list
    if (
      source.droppableId.includes('TourManualDroppable')
      && destination.droppableId.includes('SelectedStops')
    ) {
      return
    }

    // Can't drag and drop a visit from a manualTour to a tour
    if (
      source.droppableId.includes('TourManualDroppable')
      && destination.droppableId.includes('TourDroppable')
    ) {
      return
    }

    if (destination.droppableId.includes('TourManualDroppable')) {
      return onManualTourDrop(source, destination)
    }

    const destTourId = destination.droppableId.split('-')[1]
    const destTourStatus = tours.find((tour) => tour.tourId === destTourId)?.status
    if (
      destTourStatus === PLAN_TOUR_STATUS_ENUM.VALIDATED
      || destTourStatus === PLAN_TOUR_STATUS_ENUM.INTERFACED
    ) {
      return
    }

    const srcTourId = source.droppableId.split('-')[1]
    if (srcTourId) {
      const srcTourStatus = tours.find((tour) => tour.tourId === srcTourId)?.status
      if (
        srcTourStatus === PLAN_TOUR_STATUS_ENUM.VALIDATED
        || srcTourStatus === PLAN_TOUR_STATUS_ENUM.INTERFACED
      ) {
        return
      }
    }

    if (
      source.droppableId === 'SelectedStops'
      && destination.droppableId.includes('TourDroppable')
    ) {
      // from selected list to stops in tour
      let markerColor = ''
      const tourId = destination.droppableId.split('-')[1]
      let availableVisits: ISelectableMarker[] = []
      if (markers.findIndex((e) => e.selected) === -1) {
        availableVisits = markers.filter(
          (marker) => !marker.tourId && marker.manualTourId === undefined,
        )
      } else {
        availableVisits = markers.filter((marker) => marker.selected)
      }
      availableVisits = sortPlanVisits(availableVisits)

      const planStops: IDropVisitPlanStop[] = []
      setTours((prevTours) => {
        const sourceTours = [...prevTours]
        const tourIndex = sourceTours.findIndex((tour) => tour.tourId === tourId)
        const { stops } = sourceTours[tourIndex]
        stops.splice(destination.index, 0, availableVisits[source.index])
        stops.forEach((stop) => {
          /*
          When dragging an item from SelectedColumn,
          it is not a planStop yet so it doesn't have planVisitsToDeliver defined nor planVisitsToLoad
          */
          planStops.push({
            planVisitsToDeliver: stop.planVisitsToDeliver ?? [availableVisits[source.index].stopId],
            planVisitsToLoad: stop.planVisitsToLoad ?? [],
          })
        })
        markerColor = sourceTours[tourIndex].color
        sourceTours[tourIndex].stops = stops
        return sourceTours
      })
      setMarkers((prevMarkers) => {
        const sourceMarkers = [...prevMarkers]
        const originalIndex = sourceMarkers.findIndex(
          (marker) => marker.stopId === availableVisits[source.index].stopId,
        )
        sourceMarkers[originalIndex].selected = false
        sourceMarkers[originalIndex].tourId = tourId
        sourceMarkers[originalIndex].color = markerColor
        sourceMarkers[originalIndex].order = destination.index
        if (!shouldUseReorganizeUX || destination.index === 0) {
          if (isMultisite) {
            dropItemInPlanTourWithMultisites({
              planId: tourId,
              planStops,
              newOrUpdatedPlanStop: {
                planVisitsToLoad: [],
                planVisitsToDeliver: [availableVisits[source.index].stopId],
                transportType: TransportType.Delivery,
              },
              automaticInsert: destination.droppableId.includes('TourDroppableAuto'),
            })
          } else {
            dropItemInPlanTour({
              planId: tourId,
              visitId: availableVisits[source.index].stopId,
              index: destination.index,
              automaticInsert: destination.droppableId.includes('TourDroppableAuto'),
            })
          }
        } else {
          setHasNonValidatedChanges(true)
          handlePlanTourModification(tourId)
        }
        return sourceMarkers
      })
    } else if (
      source.droppableId.includes('TourDroppable')
      && destination.droppableId.includes('TourDroppable')
    ) {
      // Reorder stops in tour or move stop from tour (source) to tour (destination)
      setTours((prevTours) => {
        const sourceTours = [...prevTours]

        const sourceTourId = source.droppableId.split('-')[1]
        const sourceTourIndex = sourceTours.findIndex((tour) => tour.tourId === sourceTourId)
        const destinationTourId = destination.droppableId.split('-')[1]
        const destinationTourIndex = sourceTours.findIndex(
          (tour) => tour.tourId === destinationTourId,
        )

        const { stops: sourceStops } = sourceTours[sourceTourIndex]
        const { stops: destinationStops } = sourceTours[destinationTourIndex]

        const elementToMove = sourceStops.splice(source.index, 1)
        destinationStops.splice(destination.index, 0, elementToMove[0])
        const planStops: IDropVisitPlanStop[] = []
        destinationStops.forEach((stop) => {
          planStops.push({
            planVisitsToDeliver: stop.planVisitsToDeliver,
            planVisitsToLoad: stop.planVisitsToLoad,
          })
        })
        if (!shouldUseReorganizeUX || destination.index === 0) {
          if (isMultisite) {
            dropItemInPlanTourWithMultisites({
              planId: destinationTourId,
              planStops,
              newOrUpdatedPlanStop: {
                planVisitsToLoad: elementToMove[0].planVisitsToLoad,
                planVisitsToDeliver: elementToMove[0].planVisitsToDeliver,
                transportType: elementToMove[0].transportType,
              },
              automaticInsert: destination.droppableId.includes('TourDroppableAuto'),
            })
          } else {
            dropItemInPlanTour({
              planId: destinationTourId,
              visitId: elementToMove[0].planVisitsToDeliver[0],
              index: destination.index,
              automaticInsert: destination.droppableId.includes('TourDroppableAuto'),
            })
          }
        } else {
          setHasNonValidatedChanges(true)
          handlePlanTourModification(sourceTourId)
          handlePlanTourModification(destinationTourId)
        }
        return sourceTours
      })
    } else if (
      destination.droppableId === source.droppableId
      && destination.droppableId.includes('SelectedStops')
    ) {
      // Reorder selected list
      setMarkers((prevMarkers) => {
        const sourceMarkers = [...prevMarkers]
        const elementToMove = sourceMarkers.splice(source.index, 1)
        sourceMarkers.splice(destination.index, 0, elementToMove[0])
        return sourceMarkers
      })
    } else if (
      source.droppableId.includes('TourDroppable')
      && destination.droppableId.includes('SelectedStops')
    ) {
      // From existing tour to selected list
      setTours((prevTours) => {
        const sourceTours = [...prevTours]
        const tourId = source.droppableId.split('-')[1]
        const tourIndex = sourceTours.findIndex((tour) => tour.tourId === tourId)
        const { stops } = sourceTours[tourIndex]
        const elementToMove = stops.splice(source.index, 1)
        sourceTours[tourIndex].stops = stops
        if (!shouldUseReorganizeUX) {
          if (elementToMove[0].transportType === TransportType.Delivery) {
            removeItemFromPlanTour({
              planId: tourId,
              visitIds: [elementToMove[0].planVisitsToDeliver[0]],
            })
          } else if (elementToMove[0].transportType === TransportType.Pickup) {
            removeItemFromPlanTour({
              planId: tourId,
              visitIds: elementToMove[0].planVisitsToLoad,
            })
          }
        } else {
          setHasNonValidatedChanges(true)
          handlePlanTourModification(tourId)
        }
        setMarkers((prevMarkers) => {
          const sourceMarkers = [...prevMarkers]
          const index = sourceMarkers.findIndex((stop) => stop.stopId === elementToMove[0].stopId)
          sourceMarkers.splice(destination.index, 0, sourceMarkers.splice(index, 1)[0])
          // This is made to check if some markers are already selected
          // This is made to not lose the list => Good UX
          sourceMarkers[destination.index].selected = markers.findIndex((marker) => marker.selected) !== -1
          sourceMarkers[destination.index].tourId = undefined
          return sourceMarkers
        })
        return sourceTours
      })
    }
  }

  const updatePlanVisitsGroup = async (
    updates: IUpdate[],
    callbackF: () => void,
  ): Promise<void> => {
    toggleLoader(true)
    const res = await PlanApi.updatePlanVisitsGroup({ updates })
    if (isIError(res)) {
      res.error.errorList = getErrorList(res)
      if (res.error.fieldErrors) {
        openErrorSnack(res.error.errorList.join('\n'))
      } else {
        openErrorSnack(res.error.message)
      }
    } else {
      callbackF()
    }
    toggleLoader(false)
  }

  const saveClusters = () => {
    const updates: IUpdate[] = []
    clusters.forEach((cluster) => {
      const planVisitIds: string[] = cluster.visits.map((visit) => visit.stopId)
      if (planVisitIds.length > 0) {
        updates.push({
          action: GroupAction.Add,
          planVisitIds,
          vehicleIds: cluster.vehicleIds,
          groupId: `${selectedPlan?.id}-${cluster.clusterId}`,
        })
      }
    })
    const orphanPlanVisitIds = markers
      .filter((marker) => !marker.tourId && !marker.clusterId)
      .map((marker) => marker.stopId)
    if (orphanPlanVisitIds.length > 0) {
      updates.push({
        action: GroupAction.Remove,
        planVisitIds: orphanPlanVisitIds,
      })
    }
    if (updates.length > 0) {
      updatePlanVisitsGroup(updates, () => {
        openSuccessSnack(t('PlanningScreen.successfullySaved'))
      })
    }
  }

  const handleSelectCarrier = (carrierId: string) => {
    setAnchorElCarriersMenu(null)
    setOutsourced({
      carrierId,
      visits: [],
    })
  }

  const handleOutsourcedVisitDelete = (visitId) => {
    setOutsourced((prevOutsourced) => {
      const outsourcedUpdated = { ...prevOutsourced }
      outsourcedUpdated.visits = outsourcedUpdated?.visits?.filter((v) => v.stopId !== visitId) || []
      return outsourcedUpdated
    })
    setMarkers((prevMarkers) => {
      const sourceMarkers = [...prevMarkers]
      const originalIndex = sourceMarkers.findIndex((marker) => marker.stopId === visitId)
      if (sourceMarkers[originalIndex].isNotCompatibleWithFilters) {
        sourceMarkers.splice(originalIndex, 1)
        return sourceMarkers
      }
      sourceMarkers[originalIndex].selected = false
      sourceMarkers[originalIndex].isOutsourced = undefined
      // @ts-ignore
      sourceMarkers[originalIndex].color = undefined
      // @ts-ignore
      sourceMarkers[originalIndex].order = undefined
      return sourceMarkers
    })
  }

  const closeSendOutsourcedDialogOpen = async (validate: boolean) => {
    setIsSendOutsourcedDialogOpen(false)
    if (validate && outsourced?.carrierId && outsourced?.visits) {
      toggleLoader(true)
      const res = await PlanApi.outsourcePlanVisits({
        carrierId: outsourced?.carrierId,
        planVisitsIds: outsourced?.visits.map((visit) => visit.stopId || ''),
      })
      if (isIError(res)) {
        res.error.errorList = getErrorList(res)
        if (res.error.fieldErrors) {
          openErrorSnack(res.error.errorList.join('\n'))
        } else {
          openErrorSnack(res.error.message)
        }
      } else {
        setIsOutsourceMode(false)
        hideAllTours(false)
        openSuccessSnack(t('PlanningScreen.sendVisitsToCarrierSuccessMessage'))
      }
      callEnded()
    }
  }

  const closeDeleteOutsourcedDialogOpen = (validate: boolean) => {
    setIsDeleteOutsourcedDialogOpen(false)
    if (validate) {
      outsourced?.visits?.forEach((visit) => handleOutsourcedVisitDelete(visit.stopId))
      setOutsourced(null)
    }
  }

  const shouldDisplayValidateChangesButton: boolean = useMemo(
    () =>
      shouldUseReorganizeUX //  tenant is using new d&d UX
      && tours?.length > 0 // plan tours already created
      && !isClusterMode // not in cluster mode
      && !isOutsourceMode // not in outsouced mode
      && hasNonValidatedChanges, // has some non-validated changes
    [shouldUseReorganizeUX, tours, isClusterMode, isOutsourceMode, hasNonValidatedChanges],
  )

  const handleValidateChanges = async () => {
    if (!selectedPlan) {
      return
    }

    setLoadingTours(Array.from(modifiedPlanToursIds))

    toggleLoader(true)
    const modifiedPlanTours = tours.filter((tour) => modifiedPlanToursIds.has(tour.tourId))
    const res: IJob | IError = isMultisite
      ? await PlanApi.reorganizePlanVisitsMultisites(selectedPlan.id, modifiedPlanTours)
      : await PlanApi.reorganizePlanVisits(selectedPlan.id, modifiedPlanTours)

    if (isIError(res)) {
      openErrorSnack(res.error.message)
    } else if (res) {
      setJobIds((prev) => [...prev, (res as IJob).jobId])
      // buffer dans LinearProgress est 2 minutes donc 120 secondes
      // la regle voulue pour maxOptimizationTime dans ce cas est
      // 5 * nombre de tournées modifiées + buffer de 40 secondes
      // => 5 * modifiedToursIds.size + 40 - 120
      // => 5 * modifiedToursIds.size - 80
      const maxOptimizationTime = 5 * modifiedPlanToursIds.size - 80
      setMaxOptimizationTime(maxOptimizationTime)
      toggleBackdrop(t(`PlanningScreen.optimizeRunning`), false, true)
    }
    setHasNonValidatedChanges(false)
    setModifiedPlanToursIds(new Set())
    setLoadingTours([])
    toggleLoader(false)
  }

  return (
    <Paper
      elevation={3}
      square
      style={{ display: 'flex', transitionDuration: '0.1s', overflow: 'hidden' }}
      className="tour-columns"
      data-cy="drawer"
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <SelectedColumn
          markers={markers}
          toursCount={tours.length}
          totalDistance={
            Math.round(
              tours.reduce((accumulator, tour) => accumulator + (tour.plannedDistance || 0), 0)
                * 100,
            ) / 100
          } // Rounding to the second decimal
          estimatedCost={
            Math.round(
              tours.reduce((accumulator, tour) => accumulator + (tour.estimatedCost || 0), 0) * 100,
            ) / 100
          }
          isClusterMode={isClusterMode}
          isOutsourceMode={isOutsourceMode}
          createManualTour={createManualTour}
          ref={clustersRef}
        />
        <Paper
          elevation={10}
          square
          style={{ display: 'flex', overflow: 'auto', backgroundColor: 'slategrey' }}
        >
          {isClusterMode ? (
            <>
              {clusters
                .sort((c1, c2) => (c1.clusterId < c2.clusterId ? 1 : -1))
                .sort((c1, c2) => (c2.status === ClusterStatus.modification ? 1 : -1))
                .map((cluster) => (
                  <React.Fragment key={cluster.clusterId}>
                    <ClusterColumn
                      key={cluster.clusterId}
                      cluster={cluster}
                      onClusterLock={() => onClusterLock(cluster.clusterId)}
                      onClusterDelete={() => onClusterDelete(cluster.clusterId)}
                      onVisitDelete={onClusterVisitDelete}
                      onVehicleChange={() => setCreateClusterModalOpen(cluster.clusterId)}
                      clusterCapacity={() => computeClusterCapacity(cluster.vehicleIds)}
                    />
                    {createClusterModalOpen === cluster.clusterId && (
                      <ChangeClusterVehicleModal
                        isVisible={createClusterModalOpen === cluster.clusterId}
                        toggleModal={() => setCreateClusterModalOpen(undefined)}
                        changeVehicles={(vehicleIds: string[]) =>
                          onClusterVehicleChange(cluster.clusterId, vehicleIds)}
                        selectedPlanId={selectedPlan?.id}
                        vehiclesList={computeAvailableVehicles(
                          vehiclesList,
                          clusters,
                          cluster.clusterId,
                        )}
                        selectedVehicleIds={cluster.vehicleIds}
                      />
                    )}
                  </React.Fragment>
                ))}
              {clusters.length ? (
                <ClusterButtonsContainer>
                  <CreateClusterButton
                    createCluster={createCluster}
                    isDisabled={isMapotempoActionInProgress}
                    title={t('PlanningScreen.createCluster')}
                  />
                  <SaveClustersButton
                    saveClusters={saveClusters}
                    isDisabled={isMapotempoActionInProgress}
                    title={t('PlanningScreen.saveClusters')}
                  />
                </ClusterButtonsContainer>
              ) : (
                <Droppable droppableId={CREATE_CLUSTER_DROPPABLE_ID}>
                  {(provided): JSX.Element => (
                    <CreationColumnContainer ref={provided.innerRef}>
                      <CreationContainer onClick={createCluster}>
                        <AddCircle fontSize="large" />
                        <b>{t('PlanningScreen.createCluster')}</b>
                      </CreationContainer>
                    </CreationColumnContainer>
                  )}
                </Droppable>
              )}
            </>
          ) : isOutsourceMode ? (
            outsourced ? (
              <>
                <OutsourcedColumn
                  outsourced={outsourced}
                  onDelete={() => setIsDeleteOutsourcedDialogOpen(true)}
                  onSend={() => setIsSendOutsourcedDialogOpen(true)}
                  onVisitDelete={handleOutsourcedVisitDelete}
                />
                <ConfirmationDialog
                  open={isSendOutsourcedDialogOpen}
                  onClose={closeSendOutsourcedDialogOpen}
                  message={t('PlanningScreen.sendOutsourcedConfirmationMessage')}
                />
                <ConfirmationDialog
                  open={isDeleteOutsourcedDialogOpen}
                  onClose={closeDeleteOutsourcedDialogOpen}
                  message={t('PlanningScreen.deleteOutsourcedConfirmationMessage')}
                />
              </>
            ) : (
              <>
                <CreationColumnContainer>
                  <CreationContainer onClick={handleOpenCarriersMenu}>
                    <LocalShipping fontSize="large" />
                    <b>{t('PlanningScreen.selectCarrier')}</b>
                  </CreationContainer>
                </CreationColumnContainer>
                {selectedPlan ? (
                  <Menu
                    id="carriers-menu"
                    anchorEl={anchorElCarriersMenu}
                    keepMounted
                    open={Boolean(anchorElCarriersMenu)}
                    onClose={handleCloseCarriersMenu}
                  >
                    {activeBoundCarriers.map((carrier) => (
                      <MenuItem key={carrier.id} onClick={() => handleSelectCarrier(carrier.id)}>
                        {carrier.name}
                      </MenuItem>
                    ))}
                  </Menu>
                ) : (
                  <></>
                )}
              </>
            )
          ) : (
            <>
              {manualTours.length > 0 && (
                <ClusterButtonsContainer>
                  <CreateClusterButton
                    createCluster={createManualTour}
                    isDisabled={isMapotempoActionInProgress}
                    title={t('PlanningScreen.createManualTour')}
                  />
                </ClusterButtonsContainer>
              )}
              {manualTours
                .sort((t1, t2) => (t1.clusterId < t2.clusterId ? 1 : -1))
                .sort((t1, t2) => (t2.status === ClusterStatus.modification ? 1 : -1))
                .map((manualTour) => (
                  <React.Fragment key={manualTour.clusterId}>
                    <ClusterColumn
                      cluster={manualTour}
                      onClusterLock={() => onManualTourLock(manualTour.clusterId)}
                      onClusterDelete={() => onManualTourDelete(manualTour.clusterId)}
                      onVisitDelete={onManualTourVisitDelete}
                      onVehicleChange={() => setCreateClusterModalOpen(manualTour.clusterId)}
                      clusterCapacity={() => computeClusterCapacity(manualTour.vehicleIds)}
                      isManualTour
                    />
                    {createClusterModalOpen === manualTour.clusterId && (
                      <ChangeClusterVehicleModal
                        isVisible={createClusterModalOpen === manualTour.clusterId}
                        toggleModal={() => setCreateClusterModalOpen(undefined)}
                        changeVehicles={(vehicleIds: string[]) =>
                          onManualTourVehicleChange(manualTour.clusterId, vehicleIds)}
                        selectedPlanId={selectedPlan?.id}
                        vehiclesList={computeAvailableVehicles(
                          vehiclesList,
                          manualTours,
                          manualTour.clusterId,
                        )}
                        selectedVehicleIds={manualTour.vehicleIds}
                        isManualTour
                      />
                    )}
                  </React.Fragment>
                ))}
              {vehicleColumns.map((vehicleColumn) => (
                <VehicleColumn
                  key={vehicleColumn.vehicleId}
                  vehicleColumn={vehicleColumn}
                  loadingTours={loadingTours}
                />
              ))}
            </>
          )}
        </Paper>
      </DragDropContext>
      {shouldDisplayValidateChangesButton && (
        <StyledButton theme={theme} onClick={handleValidateChanges}>
          {t('PlanningScreen.validateMyChanges')}
        </StyledButton>
      )}
    </Paper>
  )
}

export default CustomDrawer
