import React, { useState, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core'
import { ToursContext } from 'store/ToursContext'
import { ITour } from 'interfaces/Itours'
import { ITourMapItem } from 'interfaces/map'
import { CancellationReasonType, TOUR_STATUS_ENUM } from 'constants/constants'
import StopsAccordion from './StopsAccordion'

interface IProps {
  open: boolean
  onClose: (event: React.MouseEvent<HTMLButtonElement>) => void
  tour: ITour | ITourMapItem
}

const ResumeAbandonedTourModal = ({ open, onClose, tour }: IProps): JSX.Element => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isTourAbandonnedStopsAccordionExpanded, setIsTourAbandonnedStopsAccordionExpanded] = useState<boolean>(true)
  const [isUnchangedStopsAccordionExpanded, setIsUnchangedStopsAccordionExpanded] = useState<boolean>(false)
  const { updateTourStatus } = useContext(ToursContext)

  const onSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setIsLoading(true)
    await updateTourStatus(tour.tourId, TOUR_STATUS_ENUM.GOING)
    setIsLoading(false)
    onClose(event)
  }

  const changeTourAbandonnedStopsAccordion = () => {
    setIsTourAbandonnedStopsAccordionExpanded(!isTourAbandonnedStopsAccordionExpanded)
  }

  const changeUnchangedStopsAccordion = () => {
    setIsUnchangedStopsAccordionExpanded(!isUnchangedStopsAccordionExpanded)
  }

  const formattedStops = useMemo(
    () =>
      tour.stops.slice(1, -1).map((stop) => ({
        label: stop.label,
        address: stop.address,
        status: stop.status,
        cancellation: stop.cancellation,
      })),
    [tour],
  )

  const resumedStops = useMemo(
    () =>
      formattedStops.filter((stop) => stop.cancellation?.source === CancellationReasonType.Tour),
    [formattedStops],
  )

  const unchangedStops = useMemo(
    () =>
      formattedStops.filter((stop) => stop.cancellation?.source !== CancellationReasonType.Tour),
    [formattedStops],
  )

  return (
    <Dialog
      open={open}
      disableBackdropClick
      disableEscapeKeyDown
      onClose={onClose}
      onClick={(e) => e.stopPropagation()}
    >
      <DialogTitle>{t('executionScreen.resumeAbandonedTourModal.title')}</DialogTitle>
      <DialogContent>
        <div>
          <StopsAccordion
            expanded={isTourAbandonnedStopsAccordionExpanded}
            onChange={changeTourAbandonnedStopsAccordion}
            summary={t('executionScreen.resumeAbandonedTourModal.resumeStopsAccordionTitle', {
              number: resumedStops.length,
              total: formattedStops.length,
            })}
            stops={resumedStops}
          />
          <StopsAccordion
            expanded={isUnchangedStopsAccordionExpanded}
            onChange={changeUnchangedStopsAccordion}
            summary={t('executionScreen.resumeAbandonedTourModal.unchangedStopsAccordionTitle', {
              number: unchangedStops.length,
              total: formattedStops.length,
            })}
            stops={unchangedStops}
            isGreyedOut
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined" disabled={isLoading}>
          {t('confirmation.cancel')}
        </Button>
        <Button onClick={onSubmit} color="primary" variant="outlined" disabled={isLoading}>
          {t('executionScreen.resumeAbandonedTourModal.submitButton')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ResumeAbandonedTourModal
