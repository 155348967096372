import React from 'react'
import { FormControlLabel, Switch } from '@material-ui/core'
import useStyles from './styles'

interface IProps {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
  name?: string
  checked?: boolean
  label: React.ReactNode
  className?: string
  justifyLeft?: boolean
  disabled?: boolean
  dataCy?: string
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom'
}

function ToggleSwitch({
  onChange,
  name,
  checked,
  label,
  labelPlacement,
  className,
  justifyLeft,
  disabled,
  dataCy,
}: IProps): JSX.Element {
  const classes = useStyles()

  return (
    <div className={justifyLeft ? undefined : classes.switchBlock} data-cy={dataCy}>
      <FormControlLabel
        className={classes.switchContainer}
        control={(
          <Switch
            color="primary"
            checked={checked}
            name={name}
            onChange={onChange}
            className={className ?? classes.activitySwitch}
            disabled={disabled}
          />
        )}
        label={label}
        labelPlacement={labelPlacement ?? 'start'}
      />
    </div>
  )
}

export default ToggleSwitch
