import Api from 'services/api'
import { IError } from 'api/types'
import { DefaultDeliveryTypeCode, IDeliveryType } from 'interfaces/IDeliveryType'

const mapDeliveryTypes = (deliveryTypes: IDeliveryType[]): IDeliveryType[] =>
  deliveryTypes.map((deliveryType) => ({
    id: Math.random(),
    errorCode: false,
    errorLabel: false,
    isPredefined:
      deliveryType.code === DefaultDeliveryTypeCode.CustomerSite
      || deliveryType.code === DefaultDeliveryTypeCode.HubSpot
      || deliveryType.code === DefaultDeliveryTypeCode.Mixed,
    isNew: false,
    ...deliveryType,
  }))

const getDeliveryTypes = async (tenantId: string): Promise<IDeliveryType[] | IError> => {
  try {
    const response = await Api.get(`tenants/${tenantId}/deliveryTypes`)
    return mapDeliveryTypes(response)
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const updateDeliveryTypes = async (
  tenantId: string,
  deliveryTypes: IDeliveryType[],
): Promise<IDeliveryType[] | IError> => {
  try {
    const response = await Api.patch(`tenants/${tenantId}/deliveryTypes`, {
      deliveryTypes: deliveryTypes.map((deliveryType) => ({
        ...deliveryType,
        id: undefined,
        errorCode: undefined,
        errorLabel: undefined,
        isPredefined: undefined,
        isNew: undefined,
      })),
    })
    return mapDeliveryTypes(response)
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

export default {
  getDeliveryTypes,
  updateDeliveryTypes,
}
