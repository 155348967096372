import React, { useState, ReactNode, Dispatch, SetStateAction } from 'react'

import Loader from 'components/Feedback/Loader'
import Snackbar from 'components/Feedback/CustomSnackbar'

interface ISnackContext {
  openSuccessSnack(text: string): void
  openErrorSnack(text: string): void
  openWarningSnack(text: string): void
  openInfoSnack(text: string): void
  toggleLoader: Dispatch<SetStateAction<boolean>>
  isToggleLoading: boolean
}

const FeedbackContext = React.createContext<ISnackContext>({} as ISnackContext)

const { Provider, Consumer } = FeedbackContext

interface IProps {
  children: ReactNode
}

/**
 *
 * FeedbackContext will manage the snackbar to make it usable in the whole app.
 */

function FeedbackProvider({ children }: IProps): JSX.Element {
  const [open, setOpen] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [variant, setVariant] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)

  function openSuccessSnack(text: string): void {
    setMessage(text)
    setVariant('success')
    setOpen(true)
  }

  function openErrorSnack(text: string | number): void {
    setMessage(text as string)
    setVariant('error')
    setOpen(true)
  }

  function openWarningSnack(text: string): void {
    setMessage(text)
    setVariant('warning')
    setOpen(true)
  }

  function openInfoSnack(text: string): void {
    setMessage(text)
    setVariant('info')
    setOpen(true)
  }

  function handleClose(
    event: React.SyntheticEvent | React.MouseEvent | null,
    reason?: string,
  ): void {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
    setMessage('')
    setVariant('')
  }

  return (
    <Provider
      value={{
        openSuccessSnack,
        openErrorSnack,
        openWarningSnack,
        openInfoSnack,
        toggleLoader: setIsLoading,
        isToggleLoading: isLoading,
      }}
    >
      <Snackbar
        open={open}
        message={message}
        key={message}
        variant={variant}
        handleClose={handleClose}
      />
      {isLoading && <Loader />}
      {children}
    </Provider>
  )
}

export { FeedbackContext }
export default FeedbackProvider
export { Consumer as FeedbackConsumer }
