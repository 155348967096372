import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, CardHeader, CardContent, Paper } from '@material-ui/core'
import clsx from 'clsx'

import useStyles from 'constants/cruStyles'
import { IInputChange } from 'interfaces/IInputChange'
import { IRuleInformation } from 'interfaces/IRoutingRule'
import Switch from 'components/Inputs/ToggleSwitch'
import { TextInput } from './Inputs'
import { RoutingRulesContext } from './RoutingStore'

interface IProps {
  ruleInformation: IRuleInformation
  onInfoChange: (info: IRuleInformation) => void
}

const InformationCard = ({ ruleInformation, onInfoChange }: IProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { editMode } = useContext(RoutingRulesContext)

  return (
    <Grid
      data-cy="infoCard"
      item
      xs={11}
      className={clsx(classes.infoContainer, classes.routingRuleCard)}
    >
      <Paper elevation={10}>
        <CardHeader
          title={t('RoutingRulesScreen.informations')}
          titleTypographyProps={{
            variant: 'subtitle1',
            style: {
              fontWeight: 'bolder',
            },
          }}
          className={classes.header}
        />
        <CardContent className={classes.routingRulesCard}>
          <div className={clsx(classes.rowFieldsContainer, classes.configurationRow)}>
            <TextInput
              required
              error={ruleInformation?.label === ''}
              value={ruleInformation?.label || ''}
              className={classes.ruleInfoField}
              onChange={({ target: { value } }: IInputChange) =>
                onInfoChange({
                  ...ruleInformation,
                  label: value || undefined,
                })}
              label={t('RoutingRulesScreen.label')}
              name="label"
              data-cy="label"
            />
            <TextInput
              required
              value={ruleInformation?.priority || ''}
              type="number"
              className={classes.ruleInfoField}
              label={t('RoutingRulesScreen.priority')}
              name="priority"
              onChange={({ target: { value } }: IInputChange) =>
                onInfoChange({
                  ...ruleInformation,
                  priority: value ? Number(value) : undefined,
                })}
              data-cy="priority"
            />
            <div className={clsx(classes.ruleInfoField, classes.ruleInfoSwitch)}>
              <Switch
                label={t('RoutingRulesScreen.active')}
                onChange={({ target: { checked } }: IInputChange) =>
                  onInfoChange({
                    ...ruleInformation,
                    active: !!checked,
                  })}
                checked={ruleInformation.active}
                disabled={!editMode}
                dataCy="active"
              />
            </div>
          </div>
        </CardContent>
      </Paper>
    </Grid>
  )
}

export default InformationCard
