import React from 'react'
import styled from 'styled-components'
import { Fab, Tooltip } from '@material-ui/core'
import { AddCircle } from '@material-ui/icons'

interface CreateClusterBtnProps {
  createCluster: () => void
  isDisabled?: boolean
  title: string
}

const CreateButton = styled(Fab)`
  background-color: white;
`

export default ({ createCluster, isDisabled, title }: CreateClusterBtnProps): JSX.Element => (
  <Tooltip title={title} aria-label={title}>
    <CreateButton onClick={createCluster} disabled={isDisabled}>
      <AddCircle color="primary" fontSize="large" />
    </CreateButton>
  </Tooltip>
)
