import React from 'react'
import CardLine from 'components/CardLine/CardLine'
import { Grid } from '@material-ui/core'
import clsx from 'clsx'
import useStyles from '../CardLine/styles'

interface LinePair {
  label: string
  hidden?: boolean
  value?: string
}

interface MLCardItems {
  title: string
  titleValue?: string
  notWithinSection?: boolean
  linePairs?: LinePair[]
}

interface IProps {
  items: MLCardItems[]
}

export default function MultiLevelCard({ items }: IProps): JSX.Element {
  const classes = useStyles()
  return (
    <div className={clsx(classes.fullWidth)}>
      {items.map((item) => (
        <div className={clsx(classes.noPadding, classes.withMinHeight)}>
          <div
            className={
              item.notWithinSection
                ? clsx(classes.notWithinSection, classes.innerDiv)
                : clsx(classes.innerDiv, classes.withinSection)
            }
          >
            <div
              className={clsx(classes.withoutBorder, classes.withMinHeight, classes.transparentBg)}
            >
              <Grid item xs={6} className={classes.cardLineLabel}>
                {item.title.toUpperCase()}
              </Grid>
              <Grid
                item
                xs
                className={clsx(
                  item.titleValue ? '' : classes.cardLineLabel,
                  classes.withMarginLeft,
                )}
              >
                {item.titleValue ?? (item.linePairs && item.linePairs.length > 0 ? '' : '-')}
              </Grid>
            </div>
            {item.linePairs
              && item.linePairs.length > 0
              && item.linePairs.map((linePair) => (
                <CardLine
                  key={linePair.value}
                  hidden={linePair.hidden}
                  withBorder={false}
                  label={linePair.label}
                  value={linePair.value}
                />
              ))}
          </div>
        </div>
      ))}
    </div>
  )
}
