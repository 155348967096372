import React, { useContext } from 'react'

import { ThemeContext } from 'store/ThemeContext'
import iconLightMode from 'assets/logoWithSloganLightMode.png'
import iconDarkMode from 'assets/logoWithSloganDarkMode.png'
import useStyle from './style'

function HomeScreen(): JSX.Element {
  const classes = useStyle()
  const { darkMode } = useContext(ThemeContext)

  return (
    <>
      <div className={classes.homeContainer}>
        <img className={classes.img} src={darkMode ? iconDarkMode : iconLightMode} alt="logo" />
      </div>
    </>
  )
}

export default HomeScreen
