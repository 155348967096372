import { createStyles, makeStyles } from '@material-ui/core/styles'

export default makeStyles(() =>
  createStyles({
    validtionRulesWrapper: {
      display: 'flex',
      gap: '4px',
    },
    validText: {
      fontWeight: 'bold',
    },
    invalidText: {
      color: 'grey',
    },
  }),
)
