import React, { useState, createContext, ReactNode, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { isIError } from 'api/types'
import { getErrorList } from 'utils/errorUtils'
import { FeedbackContext } from 'store/FeedbackContext'
import TagsApi from 'api/tags'
import { ITag } from 'interfaces/ITag'
import { AuthContext } from './AuthContext'

interface ITagsContext {
  tags: ITag[] | null
  getTags: (tenantId: string) => void
  updateTags: (tenantId: string, tags: ITag[]) => void
}

const TagsContext = createContext<ITagsContext>({} as ITagsContext)
const { Provider, Consumer } = TagsContext

interface IProps {
  children: ReactNode
}

const TagsProvider = ({ children }: IProps): JSX.Element => {
  const { openErrorSnack, openSuccessSnack, toggleLoader } = useContext(FeedbackContext)
  const { getUserData } = useContext(AuthContext)
  const [tags, setTags] = useState<ITag[] | null>(null)
  const { t } = useTranslation()

  const getTags = async (tenantId: string): Promise<void> => {
    toggleLoader(true)
    const response = await TagsApi.getTags(tenantId)
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      setTags(response)
    }
    toggleLoader(false)
  }

  const updateTags = async (tenantId: string, updatedTags: ITag[]): Promise<void> => {
    if (updatedTags.filter((tag) => !tag.code).length > 0) {
      openErrorSnack(t('ConfigurationsScreen.tagCodeRequired'))
    } else if (updatedTags.filter((tag) => !tag.label).length > 0) {
      openErrorSnack(t('ConfigurationsScreen.tagLabelRequired'))
    } else {
      toggleLoader(true)
      const response = await TagsApi.updateTags(tenantId, updatedTags)
      if (isIError(response)) {
        response.error.errorList = getErrorList(response)
        if (response.error.fieldErrors) {
          openErrorSnack(response.error.errorList.join('\n'))
        } else {
          openErrorSnack(response.error.message)
        }
      } else {
        setTags(response)
        getUserData(true)
        openSuccessSnack(t('ConfigurationsScreen.successfullySaved'))
      }
      toggleLoader(false)
    }
  }

  return (
    <Provider
      value={{
        tags,
        getTags,
        updateTags,
      }}
    >
      {children}
    </Provider>
  )
}

export default TagsProvider

export { Consumer as TagsConsumer, TagsContext }
