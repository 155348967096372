import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { IOrderForm, IOrderRequiredField } from 'interfaces/IOrders'
import {
  DEFAULT_ORDER_STEPS,
  DestinationType,
  ORDER_REQUIRED_FIELDS,
  OrderStepKey,
} from 'constants/constants'
import { IStep } from 'interfaces/IStep'
import Step from 'components/Stepper/Step'
import { isDefined, shouldDisplayOrderFormField } from 'utils/functions'
import { IWarehouseCartDropoffConfig } from 'interfaces/IWarehouseCartDropoffConfig'
import useStyles from './styles'

interface IProps {
  order: Partial<IOrderForm>
  activeStep: OrderStepKey
  setActiveStep: Dispatch<SetStateAction<OrderStepKey>>
  config: IWarehouseCartDropoffConfig | null
  destinationType: DestinationType
}

const OrderStepper = ({
  order,
  activeStep,
  setActiveStep,
  config,
  destinationType,
}: IProps): JSX.Element => {
  const classes = useStyles()
  const [steps, setSteps] = useState<IStep[]>(DEFAULT_ORDER_STEPS)

  useEffect(() => {
    handleOrderChange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order])

  useEffect(() => {
    if (activeStep) {
      handleActiveStepChange(activeStep)
    }
  }, [activeStep])

  const handleActiveStepChange = (key: OrderStepKey) => {
    setSteps((prev) =>
      prev.map((step) => {
        if (step.key === key) {
          return { ...step, isActive: true }
        }
        return { ...step, isActive: false }
      }),
    )
  }

  const isFieldDisplayed = (field: IOrderRequiredField): boolean =>
    !field.nameInCartDropoffConfig
    || shouldDisplayOrderFormField(true, config?.[field.nameInCartDropoffConfig])

  const isStepCompleted = (key: OrderStepKey): boolean => {
    if (key === OrderStepKey.Destination && !destinationType) return false
    const requiredFields = key === OrderStepKey.Destination
      ? ORDER_REQUIRED_FIELDS[key].filter(
        (field) => !field.destinationType || field.destinationType === destinationType,
      )
      : ORDER_REQUIRED_FIELDS[key]
    const displayedRequiredFields = requiredFields.filter((field) => isFieldDisplayed(field))
    return (
      displayedRequiredFields.findIndex((field) =>
        field.isNumber
          ? !isDefined(order[field.name]) || order[field.name] === ''
          : !order[field.name],
      ) === -1
    )
  }

  const handleOrderChange = () => {
    setSteps((prevState) =>
      prevState.map((step) => ({
        ...step,
        isCompleted: isStepCompleted(step.key),
      })),
    )
  }

  const navigateToSection = (key: OrderStepKey): void => {
    const element = document.getElementById(key)
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      })
    }
    setActiveStep(key)
  }

  return (
    <div className={classes.stepsContainer}>
      {steps.map((step) => (
        <Step step={step} key={step.key} onClick={() => navigateToSection(step.key)} />
      ))}
    </div>
  )
}

export default OrderStepper
