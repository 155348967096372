import React, { ReactNode } from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import CustomCard from 'components/CustomCard/CustomCard'
import useStyles from './styles'

type TrackingCardProps = {
  children?: ReactNode
  orderNumber: string
}
export default ({ children, orderNumber }: TrackingCardProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <CustomCard
      dataCy="trackingCard"
      cardTitle={`${t('OrdersScreen.trackingOnOrder')} ${orderNumber}`}
    >
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.trackingContainer}
      >
        {children}
      </Grid>
    </CustomCard>
  )
}
