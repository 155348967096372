import { IconButton, Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import RefreshIcon from '@material-ui/icons/Refresh'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import DateRangePicker from 'components/Inputs/DateRangePicker'
import { CarrierFilter, SiteFilter } from 'components/Inputs/ListFilter'
import { DateType } from 'constants/constants'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FiltersContainer, TopFiltersContainer } from 'components/Layout'
import { Filter, FilterKey } from 'constants/filters'
import { IOrderDeliveryScheduleChangesStats } from 'interfaces/IOrders'
import { FiltersContext } from 'store/FiltersContext'
import { OrdersConsumer } from 'store/OrdersContext'
import { Tooltip, Title, Legend, Chart } from '@devexpress/dx-react-chart-material-ui'
import { EventTracker, PieSeries } from '@devexpress/dx-react-chart'
import { connectProps } from '@devexpress/dx-react-core'
import StyledPaper from 'screens/ReportingCategory/components/StyledPaper'
import { IChartData } from 'interfaces/IChartData'
import ExtendedDateFilters from 'components/Table/ExtendedDateFilters'
// eslint-disable-next-line max-len
import CustomPieChartTooltipContent from 'screens/ReportingCategory/CustomerRatingReporting/components/CustomPieChartTooltipContent'
import {
  getChartTitle,
  setDateFactory,
  setSelectedDateFilterShortcutFactory,
} from 'utils/extendedDateFilter'

const useStyles = makeStyles(() =>
  createStyles({
    legendContainer: {
      textAlign: 'center',
      margin: 'auto',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      flexFlow: 'column',
      width: '100%',
      alignContent: 'center',
    },
    totalLabel: {
      display: 'flex',
      width: '100%',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      marginTop: '25px',
    },
    legend: {
      gridTemplateColumns: 'auto auto',
      display: 'grid',
    },
    legendStats: {
      display: 'flex',
      alignSelf: 'flex-start',
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
  }),
)

interface IOrdersStatsProps {
  ordersStats: IOrderDeliveryScheduleChangesStats
  updateStats: (
    minDay?: string,
    maxDay?: string,
    warehouseIds?: string[],
    carrierIds?: string[],
  ) => void
}

const containerId = 'delivery-schedule-changes-reporting'
const filterKey = FilterKey.deliveryScheduleChanges

const DeliveryScheduleChangesIndicator = ({
  ordersStats,
  updateStats,
}: IOrdersStatsProps): JSX.Element => {
  const { setFilter, resetFilters, filters } = useContext(FiltersContext)
  const startDate = filters[filterKey][Filter.startDate] as string
  const endDate = filters[filterKey][Filter.endDate] as string
  const carriersToFilter = filters[filterKey][Filter.carriers] as string[]
  const siteIds = filters[filterKey][Filter.sites] as string[]
  const [pieChartData, setPieChartData] = useState<IChartData[]>([])
  const [ordersSum, setOrdersSum] = useState<number>(0)
  const [affectedOrdersSum, setAffectedOrdersSum] = useState<number>(0)
  const [selectedDateFilterShortcut, setSelectedDateFilterShortcut] = useState('')
  const { t } = useTranslation()

  const CustomLegend = ({ ...props }: Legend.RootProps): JSX.Element => {
    const classes = useStyles()
    return (
      <div className={classes.legendContainer}>
        <Legend.Root {...props} className={classes.legend} />
        <div className={classes.legendStats}>
          <Typography>
            {t('reporting.ordersChanged.totalOrdersLegend')} {ordersSum}
          </Typography>
          <Typography>
            {t('reporting.ordersChanged.totalAffectedOrdersLegend')} {affectedOrdersSum}
          </Typography>
          <Typography>
            {t('reporting.ordersChanged.totalUnaffectedOrdersLegend')}{' '}
            {ordersSum - affectedOrdersSum}
          </Typography>
        </div>
      </div>
    )
  }

  const mapToPieChartData = (orderData: IOrderDeliveryScheduleChangesStats): void => {
    const data: IChartData[] = []
    const totalOrders = orderData.orderCount
    const totalAffectedOrders = orderData.affectedOrders
    setOrdersSum(totalOrders)
    setAffectedOrdersSum(totalAffectedOrders)
    data.push({
      label: t('reporting.ordersChanged.unaffectedOrdersCountParam'),
      value: totalOrders - totalAffectedOrders,
    })
    data.push({
      label: t('reporting.ordersChanged.affectedOrdersCountParam'),
      value: totalAffectedOrders,
    })
    setPieChartData(data)
  }

  const getStats = (
    minDay?: string,
    maxDay?: string,
    warehouseIds?: string[],
    carrierIds?: string[],
  ) => {
    updateStats(minDay, maxDay, warehouseIds, carrierIds)
  }

  useEffect(() => {
    getStats(startDate, endDate, siteIds, carriersToFilter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, siteIds, carriersToFilter])

  useEffect(() => {
    mapToPieChartData(ordersStats)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersStats])

  const resetLocalFilters = (): void => {
    resetFilters(filterKey)
  }

  const renderFilters = () => (
    <TopFiltersContainer>
      <FiltersContainer>
        <IconButton data-cy="initializeButton" onClick={resetLocalFilters}>
          <RefreshIcon />
        </IconButton>
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          onChangeStart={(startValue: MaterialUiPickersDate): void => {
            setFilter(filterKey, Filter.startDate, moment(startValue).toISOString())
            setSelectedDateFilterShortcut('')
          }}
          onChangeEnd={(endValue: MaterialUiPickersDate): void => {
            setFilter(filterKey, Filter.endDate, moment(endValue).toISOString())
            setSelectedDateFilterShortcut('')
          }}
          hideShiftPicker
          dateType={DateType.DateTime}
        />
        <SiteFilter
          handleChange={(siteValues: string[]): void => {
            setFilter(filterKey, Filter.sites, siteValues)
          }}
          ids={siteIds || []}
          placeholder={t('SiteReportingScreen.site')}
          dataCy="sitePicker"
          isUnselectAllowed={false}
        />
        <CarrierFilter
          handleChange={(carrierValues: string[]): void => {
            setFilter(filterKey, Filter.carriers, carrierValues)
          }}
          ids={carriersToFilter}
          dataCy="carrierPicker"
          siteIds={siteIds}
          filterKey={filterKey}
        />
      </FiltersContainer>
    </TopFiltersContainer>
  )

  const EnhancedLegend = connectProps(CustomLegend, () => ({}))
  const EnhancedPieChartTooltipContent = connectProps(CustomPieChartTooltipContent, () => ({
    total: ordersSum,
  }))

  const { setEndDate, setStartDate } = setDateFactory(setFilter, filterKey)

  const { fnLast3months, fnLastMonth, fnLast7days, fnNext7days, fnThisMonth } = setSelectedDateFilterShortcutFactory(
    setSelectedDateFilterShortcut,
  )

  const orderChartTitle = getChartTitle(
    'reporting.ordersChanged.ordersCount',
    selectedDateFilterShortcut,
  )

  return (
    <>
      {renderFilters()}
      <ExtendedDateFilters
        fnLast3months={fnLast3months}
        fnLastMonth={fnLastMonth}
        fnLast7days={fnLast7days}
        fnNext7days={fnNext7days}
        fnThisMonth={fnThisMonth}
        last3months
        lastMonth
        last7days
        next7days
        thisMonth
        setEndDate={setEndDate}
        setStartDate={setStartDate}
      />
      <StyledPaper id={containerId}>
        <Chart data-cy="ratingsCountChart" data={pieChartData}>
          <PieSeries valueField="value" argumentField="label" />
          <Title text={orderChartTitle} />
          <EventTracker />
          <Tooltip contentComponent={EnhancedPieChartTooltipContent} />
          <Legend position="bottom" rootComponent={EnhancedLegend} />
        </Chart>
      </StyledPaper>
    </>
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <OrdersConsumer>
    {(ctx): JSX.Element => (
      <DeliveryScheduleChangesIndicator
        ordersStats={ctx.ordersDeliveryScheduleChangesStats}
        updateStats={ctx.updateDeliveryScheduleChangedStats}
        {...props}
      />
    )}
  </OrdersConsumer>
)
