import React, { useEffect, useRef, useState } from 'react'
import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Select,
  FormControlLabel,
} from '@material-ui/core'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import useStyles from 'constants/cruStyles'
import { ISearchableSelectOption } from 'interfaces/interfaces'

interface IInputChange {
  target: {
    value: string
    name: string
  }
}

interface IProps {
  forceLabel?: boolean
  label: string
  name: string
  required?: boolean
  disabled?: boolean
  selectedValue?: string | number
  selectedValues?: string[]
  options?: ISearchableSelectOption[]
  isStandard?: boolean
  onChange: (event: React.ChangeEvent<{ value: unknown; name?: string }>) => void
  handleAll?: (vIds: string[]) => void
  onOpen?: () => void
  onClose?: () => void
  isInTable?: boolean
  dataCy?: string
  isNullDisabled?: boolean
  isMultiSelect?: boolean
  defaultWhenCleared?: string
  isUnselectAllowed?: boolean
  isSearchDisabled?: boolean
  customStyle?: React.CSSProperties
}

const SearchableSelect = ({
  forceLabel,
  label,
  name,
  required,
  disabled,
  selectedValue,
  selectedValues,
  options,
  onChange,
  handleAll,
  isStandard,
  onOpen,
  onClose,
  isInTable,
  dataCy,
  isNullDisabled,
  isMultiSelect,
  defaultWhenCleared,
  isUnselectAllowed = true,
  isSearchDisabled,
  customStyle,
}: IProps): JSX.Element => {
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [filteredOptions, setFilteredOptions] = useState<ISearchableSelectOption[] | undefined>(
    options,
  )
  const inputRef = useRef<HTMLInputElement>(null)
  const { t } = useTranslation()
  const classes = useStyles()

  useEffect(() => {
    setFilteredOptions(options)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue])

  const stopImmediatePropagation = (e: {
    stopPropagation: () => void
    preventDefault: () => void
  }): void => {
    e.stopPropagation()
    e.preventDefault()
  }

  const handleClose = (): void => {
    setIsOpen(false)
    setSearchValue('')
    setFilteredOptions(options)
    if (onClose) {
      onClose()
    }
  }

  const handleInputChange = (event: IInputChange): void => {
    const { target } = event
    const { value } = target
    setSearchValue(value)
    setFilteredOptions(
      options
        && options.filter((option) => option.name.toLowerCase().includes(value.toLowerCase())),
    )
  }

  const handleOpen = () => {
    setIsOpen(true)
    if (onOpen) {
      onOpen()
    }
  }

  const handleSelectAll = (): void => {
    setIsAllSelected((prev) => {
      if (handleAll) {
        handleAll(
          !prev
            ? options?.map((option) => option.id.toString()) || []
            : isUnselectAllowed
              ? []
              : [defaultWhenCleared || (options && options[0].id.toString()) || ''],
        )
      }
      return !prev
    })
  }

  return (
    <FormControl variant={isStandard ? 'standard' : 'outlined'}>
      {(forceLabel || !isStandard) && (
        <InputLabel required={required} disabled>
          {label}
        </InputLabel>
      )}
      <Select
        name={name}
        value={isMultiSelect ? selectedValues : selectedValue}
        onChange={onChange}
        style={customStyle}
        className={clsx(
          classes.filtersWidth,
          isStandard ? classes.tableSelect : classes.fullWidthSelect,
        )}
        label={label}
        disabled={disabled}
        open={isOpen}
        onClose={handleClose}
        onOpen={handleOpen}
        onClick={(e: React.MouseEvent<HTMLElement>) => isInTable && e.stopPropagation()}
        data-cy={dataCy}
        multiple={isMultiSelect}
      >
        <MenuItem data-cy={label} value={-1} disabled={!isStandard || isNullDisabled || forceLabel}>
          {label}
        </MenuItem>
        {!isInTable && !isSearchDisabled && (
          <MenuItem
            divider
            onKeyDown={(e) => e.stopPropagation()}
            onClickCapture={stopImmediatePropagation}
          >
            <TextField
              inputRef={inputRef}
              placeholder={t('Select.search')}
              value={searchValue}
              onChange={handleInputChange}
            />
          </MenuItem>
        )}
        {isMultiSelect && (
          <MenuItem divider data-cy="filter-select-all">
            <FormControlLabel
              style={{ width: '100%', marginRight: 0 }}
              control={<Checkbox checked={isAllSelected} onChange={handleSelectAll} />}
              label={t('Filter.selectAll')}
            />
          </MenuItem>
        )}
        {filteredOptions
          && filteredOptions.map((option: ISearchableSelectOption) => (
            <MenuItem
              data-cy={option.name}
              key={option.id}
              value={option.id}
              disabled={option.disabled}
              style={{ display: option.hidden ? 'none' : 'block' }}
            >
              {option.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
}

export default SearchableSelect
