import React, { useState, useEffect, ChangeEvent, useCallback, memo } from 'react'
import Paper from '@material-ui/core/Paper'
import {
  ViewState,
  EditingState,
  AppointmentModel,
  ChangeSet,
} from '@devexpress/dx-react-scheduler'
import {
  Scheduler,
  MonthView,
  WeekView,
  Appointments,
  DateNavigator,
  Toolbar,
  AppointmentForm,
  AppointmentTooltip,
  EditRecurrenceMenu,
  ViewSwitcher,
  AllDayPanel,
} from '@devexpress/dx-react-scheduler-material-ui'
import { useTranslation } from 'react-i18next'
import { Close, Delete } from '@material-ui/icons'
import i18n from 'i18next'

import {
  convertDateToRecurrenceRuleDateFormat,
  addUntilDate,
  getDefaultRule,
  updateDaysOfWeek,
} from 'utils/recurrenceRuleUtils'
import { IEvent } from 'interfaces/IEvent'
import { IconButton, FormControlLabel, Checkbox } from '@material-ui/core'
import Button from 'components/Button/CustomButton'
import { DAYS_OF_WEEK } from 'constants/constants'
import useStyles from './styles'

interface IEventSchedulerProps {
  events: IEvent[]
  onEventChange?: Function
  isReadOnly?: boolean
}

interface IEventChange {
  [key: string]: object
}

const AppointmentFormMessageKeys = {
  detailsLabel: i18n.t('scheduler.title'),
  titleLabel: i18n.t('scheduler.title'),
  allDayLabel: i18n.t('scheduler.allDay'),
  repeatLabel: i18n.t('scheduler.repeat'),
  moreInformationLabel: i18n.t('scheduler.details'),
  notesLabel: i18n.t('scheduler.details'),
  commitCommand: i18n.t('scheduler.save'),
  daily: i18n.t('scheduler.daily'),
  weekly: i18n.t('scheduler.weekly'),
  monthly: i18n.t('scheduler.monthly'),
  yearly: i18n.t('scheduler.yearly'),
  repeatEveryLabel: i18n.t('scheduler.repeatEvery'),
  daysLabel: i18n.t('scheduler.days'),
  monthsLabel: i18n.t('scheduler.month'),
  endRepeatLabel: i18n.t('scheduler.endRepeat'),
  never: i18n.t('scheduler.never'),
  onLabel: i18n.t('scheduler.on'),
  afterLabel: i18n.t('scheduler.after'),
  occurrencesLabel: i18n.t('scheduler.occurences'),
  weeksOnLabel: i18n.t('scheduler.weeksOn'),
  ofEveryMonthLabel: i18n.t('scheduler.ofEveryMonth'),
  theLabel: i18n.t('scheduler.the'),
  firstLabel: i18n.t('scheduler.first'),
  secondLabel: i18n.t('scheduler.second'),
  thirdLabel: i18n.t('scheduler.third'),
  fourthLabel: i18n.t('scheduler.fourth'),
  lastLabel: i18n.t('scheduler.last'),
  yearsLabel: i18n.t('scheduler.years'),
  everyLabel: i18n.t('scheduler.every'),
  ofLabel: i18n.t('scheduler.of'),
}

const AppointmentRecurrenceMenuMessageKeys = {
  current: i18n.t('scheduler.currentAvailability'),
  currentAndFollowing: i18n.t('scheduler.currentAvailabilityAndFollowing'),
  all: i18n.t('scheduler.allAvailabilities'),
  menuEditingTitle: i18n.t('scheduler.editTitle'),
  menuDeletingTitle: i18n.t('scheduler.deleteTitle'),
  cancelButton: i18n.t('scheduler.cancel'),
  commitButton: i18n.t('scheduler.validate'),
}

const RadioGroup = (props: AppointmentForm.RadioGroupProps): JSX.Element => {
  const { t } = useTranslation()
  const [untilDate, setUntilDate] = useState<string | number>()
  const { type } = props

  const handleAfterValueChange = (date: Date): void => {
    setUntilDate(date.toISOString())
    const formattedDate = convertDateToRecurrenceRuleDateFormat(date)
    const newRecurrenceRule = addUntilDate(props.appointmentData.rRule as string, formattedDate)
    props.onFieldChange({ rRule: newRecurrenceRule })
  }

  if (type === 'endRepeat') {
    return (
      <div>
        <AppointmentForm.Label text={t('scheduler.after')} />
        <AppointmentForm.DateEditor onValueChange={handleAfterValueChange} value={untilDate} />
      </div>
    )
  }
  return <AppointmentForm.RadioGroup {...props} />
}

const CommandLayout = ({
  onCancelButtonClick,
  onCommitButtonClick,
  onDeleteButtonClick,
  disableSaveButton,
  hideDeleteButton,
}: AppointmentForm.CommandLayoutProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.commandContainer}>
      <IconButton onClick={onCancelButtonClick}>
        <Close color="action" />
      </IconButton>
      {!hideDeleteButton && (
        <IconButton onClick={onDeleteButtonClick}>
          <Delete color="action" />
        </IconButton>
      )}
      <Button onPress={onCommitButtonClick} disabled={disableSaveButton}>
        {t('scheduler.save')}
      </Button>
    </div>
  )
}

/** the expected props of this component has onFieldChange but when we use it their is an error so we use
 * onValueChange but when we create an interface with onValueChange, their is an error */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const WeeklyRecurrenceSelector = (props: any): JSX.Element => {
  const [dayChecks, setDayChecks] = useState<boolean[]>([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ])
  const { rRule } = props

  useEffect(() => {
    if (rRule.includes('BYDAY=')) {
      const checks = Array(7).fill(false)
      const days = rRule.split('BYDAY=')[1].split(';UNTIL=')[0]
      DAYS_OF_WEEK.forEach((day, index) => {
        if (days.includes(day.code)) {
          checks[index] = true
        }
      })
      setDayChecks(checks)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rRule])

  const handleChange = (checked: boolean, index: number): void => {
    const days = [...dayChecks]
    days[index] = checked
    setDayChecks(days)
    let dayCode = ''

    days.forEach((day, dayIndex) => {
      if (day) {
        dayCode = `${dayCode}${DAYS_OF_WEEK[dayIndex].code},`
      }
    })
    const newRule = updateDaysOfWeek(rRule, dayCode.replace(/,$/, ''))
    props.onValueChange({ rRule: newRule })
  }

  return (
    <>
      {DAYS_OF_WEEK.map((day, index) => (
        <FormControlLabel
          key={`fcl-${day.code}`}
          control={(
            <Checkbox
              key={day.code}
              checked={dayChecks[index]}
              onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                handleChange(event.target.checked, index)}
              name={day.code}
            />
          )}
          label={day.label}
        />
      ))}
    </>
  )
}

const Select = (props: AppointmentForm.SelectProps): JSX.Element => {
  useEffect(() => {
    props.onValueChange('weekly')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AppointmentForm.Select
      {...props}
      readOnly
      value="weekly"
      availableOptions={[{ text: i18n.t('scheduler.weekly'), id: 'weekly' }]}
    />
  )
}

const TextEditor = (props: AppointmentForm.TextEditorProps): JSX.Element | null => {
  const { type } = props
  if (type === 'numberEditor') {
    return null
  }
  return <AppointmentForm.TextEditor {...props} />
}

const Label = (props: AppointmentForm.LabelProps): JSX.Element | null => {
  const { text } = props
  if (text === i18n.t('scheduler.repeatEvery') || text === i18n.t('scheduler.weeksOn')) {
    return null
  }
  return <AppointmentForm.Label {...props} />
}

const EventScheduler = ({
  events,
  onEventChange,
  isReadOnly,
}: IEventSchedulerProps): JSX.Element => {
  const { t } = useTranslation()
  const [currentDate, setCurrentDate] = useState<Date>(new Date())
  const [schedulerData, setSchedulerData] = useState<IEvent[]>([])
  const [addedAppointment, setAddedAppointment] = useState<IEvent>()
  const [editingAppointment, setEditingAppointment] = useState<IEvent>()
  const [appointmentChanges, setAppointmentChanges] = useState<IEventChange>()

  useEffect(() => {
    if (events) {
      setSchedulerData(events)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events])

  const handleCurrentDateChange = (date: Date): void => {
    setCurrentDate(date)
  }

  const handleAppointmentChangesChange = (changes: IEventChange): void => {
    setAppointmentChanges(changes)
  }

  const handleSchedulerChange = ({ added, changed, deleted }: ChangeSet): void => {
    let data: IEvent[] = [...schedulerData]
    if (added) {
      /* TODO: could probably be reworked, maybe by using an id generator */
      const temporaryId = Math.random()
      data = [
        ...data,
        {
          id: temporaryId,
          ...added,
          rRule: added.rRule !== undefined ? getDefaultRule(added.rRule) : undefined,
        } as IEvent,
      ]
    }
    if (changed) {
      data = data.map((event) =>
        changed[event.id]
          ? {
            ...event,
            ...changed[event.id],
            rRule: changed[event.id].rRule
              ? !changed[event.id].rRule.includes('RRULE')
                ? `RRULE:${changed[event.id].rRule}`
                : changed[event.id].rRule
              : event.rRule,
          }
          : event,
      )
      setEditingAppointment(undefined)
    }
    if (deleted !== undefined) {
      data = data.filter((event) => event.id !== deleted)
      setEditingAppointment(undefined)
    }
    setSchedulerData(data)
    if (onEventChange) {
      onEventChange(data)
    }
  }

  /** no type matches this param */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleAddedAppointmentChange = (added: any): void => {
    const { title } = added
    setAddedAppointment({ ...added, title: !title ? t('scheduler.available') : title } as IEvent)
  }

  const handleEditingAppointmentChange = (changed: Partial<AppointmentModel>): void => {
    setEditingAppointment(changed as IEvent)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const MonthViewCell = useCallback(
    memo(({ onDoubleClick, ...props }: MonthView.TimeTableCellProps) => (
      <MonthView.TimeTableCell {...props} onDoubleClick={!isReadOnly ? onDoubleClick : undefined} />
    )),
    [isReadOnly],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const WeekViewCell = useCallback(
    memo(({ onDoubleClick, ...props }: WeekView.TimeTableCellProps) => (
      <WeekView.TimeTableCell {...props} onDoubleClick={!isReadOnly ? onDoubleClick : undefined} />
    )),
    [isReadOnly],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const WeekViewAllDayCell = useCallback(
    memo(({ onDoubleClick, ...props }: AllDayPanel.CellProps) => (
      <AllDayPanel.Cell {...props} onDoubleClick={!isReadOnly ? onDoubleClick : undefined} />
    )),
    [isReadOnly],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const AppointmentComponent = useCallback(
    memo(({ onDoubleClick, ...props }: Appointments.AppointmentProps) => (
      <Appointments.Appointment
        {...props}
        onDoubleClick={!isReadOnly ? onDoubleClick : undefined}
      />
    )),
    [isReadOnly],
  )

  return (
    <Paper>
      <Scheduler data={schedulerData} locale={t('scheduler.local')}>
        <ViewState currentDate={currentDate} onCurrentDateChange={handleCurrentDateChange} />
        <EditingState
          onCommitChanges={handleSchedulerChange}
          addedAppointment={addedAppointment}
          onAddedAppointmentChange={handleAddedAppointmentChange}
          editingAppointment={editingAppointment}
          onEditingAppointmentChange={handleEditingAppointmentChange}
          appointmentChanges={appointmentChanges}
          onAppointmentChangesChange={handleAppointmentChangesChange}
        />
        <Toolbar />
        <ViewSwitcher />
        <DateNavigator />
        <WeekView
          cellDuration={120}
          displayName={t('scheduler.week')}
          timeTableCellComponent={WeekViewCell}
        />
        <MonthView displayName={t('scheduler.month')} timeTableCellComponent={MonthViewCell} />
        <AllDayPanel
          messages={{ allDay: t('scheduler.allDay') }}
          cellComponent={WeekViewAllDayCell}
        />
        <EditRecurrenceMenu messages={AppointmentRecurrenceMenuMessageKeys} />
        <Appointments appointmentComponent={AppointmentComponent} />
        <AppointmentTooltip
          showOpenButton={!isReadOnly}
          showDeleteButton={!isReadOnly}
          showCloseButton
        />
        <AppointmentForm
          messages={AppointmentFormMessageKeys}
          commandLayoutComponent={CommandLayout}
          radioGroupComponent={RadioGroup}
          weeklyRecurrenceSelectorComponent={WeeklyRecurrenceSelector}
          selectComponent={Select}
          textEditorComponent={TextEditor}
          labelComponent={Label}
        />
      </Scheduler>
    </Paper>
  )
}

export default EventScheduler
