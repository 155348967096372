import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Card from 'components/Card/Card'
import SearchableSelect from 'components/Inputs/SearchableSelect'
import {
  DESTINATION_TYPE_OPTIONS,
  DestinationType,
  TRANSPORT_TYPE_OPTIONS,
  TransportType,
} from 'constants/constants'
import useStyles from 'constants/cruStyles'
import { DefaultDeliveryTypeCode, IDeliveryTypeItem } from 'interfaces/IDeliveryType'
import { IOrderScreenConfig } from 'interfaces/interfaces'
import { AuthContext } from 'store/AuthContext'
import { alphabeticSort, formatDeliveryTypes } from 'utils/functions'

interface IProps {
  orderScreen?: IOrderScreenConfig
  onChange: (orderScreenInfo: IOrderScreenConfig) => void
}

const WarehouseOrderScreenCreationCard = ({ orderScreen, onChange }: IProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [config, setConfig] = useState<Partial<IOrderScreenConfig>>({
    defaultDestinationType: orderScreen?.defaultDestinationType || DestinationType.Address,
    defaultDeliveryType: orderScreen?.defaultDeliveryType ?? DefaultDeliveryTypeCode.CustomerSite,
    defaultTransportType: orderScreen?.defaultTransportType || TransportType.Delivery,
  })
  const { user } = useContext(AuthContext)
  const userDeliveryTypes: IDeliveryTypeItem[] = formatDeliveryTypes(
    user?.tenantConfig?.deliveryTypes,
  ).map((deliveryType) => ({ id: deliveryType.code?.toString() || '', name: deliveryType.label }))

  const handleSearchableSelectChange = (
    event: React.ChangeEvent<{ value: unknown; name?: string }>,
  ): void => {
    const { target } = event
    const name = target.name as string
    const value = target.value as string
    onChange({
      ...config,
      [name]: name === 'defaultDeliveryType' ? parseInt(value, 10) : value,
    } as IOrderScreenConfig)
    setConfig({ ...config, [name]: name === 'defaultDeliveryType' ? parseInt(value, 10) : value })
  }

  return (
    <Card title={t('SitesScreen.orderScreenCreation').toUpperCase()}>
      <div className={classes.fieldsContainer}>
        <SearchableSelect
          label={t('SitesScreen.orderScreenConfig.defaultDestinationType')}
          name="defaultDestinationType"
          selectedValue={config.defaultDestinationType}
          options={DESTINATION_TYPE_OPTIONS}
          onChange={handleSearchableSelectChange}
        />
        <SearchableSelect
          label={t('SitesScreen.orderScreenConfig.defaultDeliveryType')}
          name="defaultDeliveryType"
          selectedValue={config.defaultDeliveryType}
          options={userDeliveryTypes.sort(alphabeticSort)}
          onChange={handleSearchableSelectChange}
        />
        <SearchableSelect
          label={t('SitesScreen.orderScreenConfig.defaultTransportType')}
          name="defaultTransportType"
          selectedValue={config.defaultTransportType}
          options={TRANSPORT_TYPE_OPTIONS}
          onChange={handleSearchableSelectChange}
        />
      </div>
    </Card>
  )
}

export default WarehouseOrderScreenCreationCard
