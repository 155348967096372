import { createStyles, makeStyles } from '@material-ui/core'

export default makeStyles(() =>
  createStyles({
    skeleton: {
      margin: 'auto',
      marginTop: 60,
    },
    cancellationReasonsFilter: {
      display: 'flex',
      flexDirection: 'column',
      width: 215,
    },
    filters: {
      display: 'flex',
      justifyContent: 'flex-start',
      rowGap: 8,
      flexWrap: 'wrap',
    },
  }),
)
