import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import useStyles from 'constants/cruStyles'
import SelectCard from 'components/CustomCard/SelectCard'
import { ContentContext } from 'store/ContentContext'
import { IOption } from 'interfaces/IOption'
import Card from 'components/Card/Card'

interface IProps {
  siteIds?: string[]
  onChange: (siteIds: string[]) => void
  isCreateMode: boolean
}

const SiteCard = ({ siteIds, onChange, isCreateMode }: IProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { sites: siteOptions } = useContext(ContentContext)
  const [sitesList, setSitesList] = useState<IOption[]>([])
  const [selectedSiteIds, setSelectedSiteIds] = useState<string[]>([])

  useEffect(() => {
    if (siteOptions) {
      const activeSites = siteOptions.filter((site) => site.active)
      setSitesList(
        activeSites.map((site) => ({
          name: site.name,
          id: site.id,
        })),
      )
      if (isCreateMode) {
        const initialSelectedSiteIds = activeSites.map((activeSite) => activeSite.id)
        setSelectedSiteIds(initialSelectedSiteIds)
        onChange(initialSelectedSiteIds)
      } else {
        setSelectedSiteIds(siteIds || [])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteOptions])

  const handleSiteClick = (siteId: string): void => {
    let newSelectSiteIds = [...selectedSiteIds]
    const index = selectedSiteIds.findIndex((selectedSiteId) => selectedSiteId === siteId)
    newSelectSiteIds = index !== -1 ? selectedSiteIds.filter((id) => id !== siteId) : [...selectedSiteIds, siteId]
    setSelectedSiteIds(newSelectSiteIds)
    onChange(newSelectSiteIds)
  }

  const handleSelectAllSitesClick = (): void => {
    const newSelectSiteIds = selectedSiteIds.length === sitesList.length ? [] : sitesList.map((site) => site.id)
    setSelectedSiteIds(newSelectSiteIds)
    onChange(newSelectSiteIds)
  }

  return (
    <Card title={t('WebhooksScreen.sites').toUpperCase()} contentClassName={classes.contentRef}>
      <SelectCard
        options={sitesList}
        selectedOptions={selectedSiteIds}
        isAllSelected={selectedSiteIds.length === sitesList.length}
        onSelectAllClick={handleSelectAllSitesClick}
        onItemClick={(id: string) => handleSiteClick(id)}
      />
    </Card>
  )
}

export default SiteCard
