import React, { ReactNode } from 'react'
import { Grid, CardHeader, CardContent } from '@material-ui/core'

import useStyles from 'constants/cruStyles'

interface IProps {
  dataCy?: string
  title: string
  children: ReactNode
  contentClassName?: string
}

const Card = ({ children, title, dataCy, contentClassName }: IProps): JSX.Element => {
  const classes = useStyles()

  return (
    <Grid data-cy={dataCy} item xs={10} className={classes.infoContainer}>
      <CardHeader
        title={title}
        className={classes.header}
        titleTypographyProps={{
          variant: 'subtitle1',
          style: {
            fontWeight: 'bolder',
          },
        }}
      />
      <CardContent className={contentClassName || classes.content}>{children}</CardContent>
    </Grid>
  )
}

export default Card
