import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'
import moment from 'moment'
import clsx from 'clsx'

import { IPlace, IRoutingSegment } from 'interfaces/IRoutingRule'
import { IInputChange } from 'interfaces/IInputChange'
import { dateToTimeFormat, getDateStringFromTime } from 'utils/dateFormat'
import useStyles from 'constants/cruStyles'
import { DateType } from 'constants/constants'
import { ContentContext } from 'store/ContentContext'
import DateRangePicker from 'components/Inputs/DateRangePicker'
import Switch from 'components/Inputs/ToggleSwitch'
import Select from './Select'
import DeliveryTypeSelect from './DeliveryTypeSelect'
import RulePlaceForm from './RulePlaceForm'
import { TextInput } from './Inputs'
import { RoutingRulesContext } from './RoutingStore'

interface IProps {
  routingSegment: IRoutingSegment
  onRoutingSegmentItemChange: (routingSegment: IRoutingSegment) => void
}

const RoutingSegmentForm = ({
  routingSegment,
  onRoutingSegmentItemChange,
}: IProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { carriers } = useContext(ContentContext)
  const { editMode } = useContext(RoutingRulesContext)
  const carrierOptions = carriers.map((carrier) => ({ value: carrier.id, label: carrier.name }))

  const handleOriginChange = (place: IPlace): void => {
    onRoutingSegmentItemChange({
      ...routingSegment,
      origin: place,
    })
  }

  const handleDestinationChange = (place: IPlace): void => {
    onRoutingSegmentItemChange({
      ...routingSegment,
      destination: place,
    })
  }

  const handleRuleChange = (
    value: number | string | boolean | null | undefined,
    name: string,
  ): void => {
    onRoutingSegmentItemChange({
      ...routingSegment,
      [name]: value,
    })
  }

  const handleIsOptimizeChange = (value: boolean): void => {
    onRoutingSegmentItemChange({
      ...routingSegment,
      toOptimize: value,
      ...(value && { carrier: undefined, toInterfaceAutomatically: false }),
    })
  }

  return (
    <>
      <RulePlaceForm
        place={routingSegment.origin}
        title={t('RoutingRulesScreen.origin')}
        onChange={handleOriginChange}
        isForSegment
        dataCy="originSection"
        dataCyType="originType"
      />
      <RulePlaceForm
        place={routingSegment.destination}
        title={t('RoutingRulesScreen.destination')}
        onChange={handleDestinationChange}
        isForSegment
        isRequired
        dataCy="destinationSection"
        dataCyType="destinationType"
      />
      <div data-cy="otherSection" className={classes.fieldGroupContainer}>
        <Typography className={classes.routingRuleSectionLabel}>
          {t('RoutingRulesScreen.other')}
        </Typography>
        <DeliveryTypeSelect
          isForRule
          value={routingSegment.deliveryType}
          onChange={(value: number | null, name: string) => {
            handleRuleChange(value, name)
          }}
          dataCy="deliveryPicker"
        />
        <div className={classes.configurationRow}>
          <DateRangePicker
            startDate={
              routingSegment.loadingDateTimeMinimum
                ? getDateStringFromTime(routingSegment.loadingDateTimeMinimum)
                : null
            }
            endDate={
              routingSegment.loadingDateTimeMaximum
                ? getDateStringFromTime(routingSegment.loadingDateTimeMaximum)
                : null
            }
            startLabel={t('RoutingRulesScreen.minHour')}
            endLabel={t('RoutingRulesScreen.maxHour')}
            onChangeStart={(time): void =>
              handleRuleChange(
                dateToTimeFormat(moment(time).toISOString()) || undefined,
                'loadingDateTimeMinimum',
              )}
            onChangeEnd={(time): void =>
              handleRuleChange(
                dateToTimeFormat(moment(time).toISOString() || undefined),
                'loadingDateTimeMaximum',
              )}
            hideShiftPicker
            dateType={DateType.Time}
            isStartDisabled={!editMode}
            isEndDisabled={!editMode}
            isStartClearable
            customClass={classes.singleField}
          />
        </div>
        <div className={clsx(classes.routingRuleFieldsContainer, classes.ruleTextField)}>
          <TextInput
            type="number"
            variant="outlined"
            className={classes.singleField}
            label={t('RoutingRulesScreen.maxAuthorizedQuantity')}
            value={routingSegment.maximumQuantity || ''}
            name="maximumQuantity"
            onChange={({ target: { name, value } }: IInputChange) => handleRuleChange(+value, name)}
            data-cy="maximumQuantity"
          />
        </div>
        <div data-cy="toOptimize" className={classes.ruleOptimizeSwitch}>
          <Switch
            label={t('RoutingRulesScreen.toOptimize')}
            onChange={({ target: { checked } }: IInputChange) => {
              handleIsOptimizeChange(checked || false)
            }}
            checked={routingSegment.toOptimize}
            disabled={!editMode}
            name="toOptimize"
          />
        </div>
        {!routingSegment.toOptimize && (
          <>
            <div className={clsx(classes.routingRuleFieldsContainer, classes.ruleDeliverySelect)}>
              <Select
                label={t('RoutingRulesScreen.carrier')}
                value={routingSegment.carrier}
                onChange={(value: number | string | null, name: string) => {
                  handleRuleChange(value, name)
                }}
                name="carrier"
                options={carrierOptions}
              />
            </div>
            <div data-cy="toInterfaceAutomatically" className={classes.ruleOptimizeSwitch}>
              <Switch
                label={t('RoutingRulesScreen.toInterfaceAutomatically')}
                onChange={({ target: { name, checked } }: IInputChange) => {
                  handleRuleChange(checked || false, name)
                }}
                checked={routingSegment.toInterfaceAutomatically}
                disabled={!editMode}
                name="toInterfaceAutomatically"
              />
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default RoutingSegmentForm
