import { ROWS_PER_PAGE_OPTIONS } from 'constants/constants'

export const getPageSize = (): number =>
  Number(localStorage.getItem('rowsPerPage')) || ROWS_PER_PAGE_OPTIONS[2]

export const savePageSize = (rowsPerPage: number): void =>
  localStorage.setItem('rowsPerPage', rowsPerPage.toString())

export const getIsDarkMode = (): boolean =>
  JSON.parse(localStorage.getItem('klareofront_isDarkMode') || 'true')

export const saveIsDarkMode = (isDarkMode: boolean): void =>
  localStorage.setItem('klareofront_isDarkMode', isDarkMode.toString())
