import React, { useState } from 'react'
import {
  ListItem,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      backgroundColor: theme.palette.background.default,
    },
    title: {
      backgroundColor: theme.color.backgroundPaperTitle,
      color: theme.color.secondary,
      fontWeight: 'bolder',
      // !important to prevent expanded behavior
      margin: '0!important',
      minHeight: '0!important',
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      padding: 0,
    },
  }),
)

interface IProps {
  children: JSX.Element | JSX.Element[]
  title: string
  isAlwaysExpanded?: boolean
  isOpenByDefault?: boolean
  isInner?: boolean
  dataCy?: string
}

const ExpansionCard = ({
  title,
  children,
  isAlwaysExpanded,
  isOpenByDefault,
  isInner,
  dataCy,
}: IProps): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState<boolean>(isOpenByDefault || false)
  const classes = useStyles()

  const handleClick = (): void => {
    setIsExpanded((prevState) => !prevState)
  }

  return (
    <Accordion
      data-cy={dataCy}
      expanded={isAlwaysExpanded || isExpanded}
      style={{ marginBottom: 16, borderRadius: 10 }}
    >
      <AccordionSummary
        {...(!isInner && { classes: { content: classes.title } })}
        className={isInner ? classes.content : classes.title}
      >
        <ListItem button onClick={handleClick}>
          <ListItemText primary={title} />
          {isAlwaysExpanded ? null : isExpanded ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      </AccordionSummary>
      <AccordionDetails className={classes.content}>{children}</AccordionDetails>
    </Accordion>
  )
}

export default ExpansionCard
