import { makeStyles, createStyles } from '@material-ui/core/styles'

export default makeStyles(() =>
  createStyles({
    legendContainer: {
      margin: 'auto',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      flexFlow: 'column',
      alignContent: 'center',
      width: '100%',
    },
    legend: {
      gridTemplateColumns: 'auto auto auto',
      display: 'grid',
      margin: 'auto',
    },
    container: {
      position: 'relative',
      marginRight: '25px',
    },
    datePicker: {
      marginLeft: 8,
      width: 170,
    },
    customSelect: {
      marginLeft: 8,
      width: 160,
    },
    totalLabel: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    zipCode: {
      display: 'inline-block',
      maxWidth: 180,
      width: '100%',
      marginLeft: 8,
      verticalAlign: 'top',
    },
  }),
)
