import { IRoutingRule } from 'interfaces/IRoutingRule'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { ArrowForward } from '@material-ui/icons'
import MaterialTable, { Action, MTableHeader } from 'material-table'
import { Link, useHistory } from 'react-router-dom'
import { IconButton } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import { useTranslation } from 'react-i18next'

import { getPageSize, savePageSize } from 'utils/localStorage'
import { FiltersContext } from 'store/FiltersContext'
import RoutingRulesApi from 'api/routingRules'
import { isIError } from 'api/types'
import { scrollTop } from 'utils/functions'
import { COLUMNS_MODEL_ROUTING_RULES } from 'constants/table'
import Button from 'components/Button/CustomButton'
import MUITableIcons from 'constants/MUITableIcons'
import SearchInput from 'components/Inputs/SearchInput'
import useStyles from 'components/Table/styles'
import AppConfigProvider, { AppConfigContext } from 'store/AppConfigContext'
import Pagination from 'components/Table/Pagination'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import { FeedbackContext } from 'store/FeedbackContext'
import { FilterKey, Filter } from 'constants/filters'
import { SiteFilter, ActiveFilter } from 'components/Inputs/ListFilter'
import { getDisplayRowsCount } from 'utils/tableUtils'
import { FiltersContainer, SearchContainer, TopFiltersContainer } from 'components/Layout'

const columnConfigName = 'routingRuleList'
const filterKey = FilterKey.routingRules

const RoutingRulesTable = (): JSX.Element => {
  const isInitialMount = useRef(true)
  const styles = useStyles()

  const [paginationOffset, setPaginationOffset] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const { setFilter, resetFilters, filters } = useContext(FiltersContext)
  const searchText = filters[filterKey][Filter.searchText] as string
  const [isSearchReset, setIsSearchReset] = useState<boolean>(false)
  const [sortField, setSortField] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(getPageSize)
  const [sortDirection, setSortDirection] = useState<string>('asc')
  const [routingRules, setRoutingRules] = useState<IRoutingRule[]>([])
  const [count, setCount] = useState<number>(0)
  const history = useHistory()
  const { tablesConfig } = useContext(AppConfigContext)
  const { openErrorSnack, toggleLoader } = useContext(FeedbackContext)
  const siteIds = filters[filterKey][Filter.sites] as string[]
  const active = filters[filterKey][Filter.active] as string[]
  const { t } = useTranslation()

  const getList = async () => {
    toggleLoader(true)

    const response = await RoutingRulesApi.getList({ siteIds, active, searchText }, 0, rowsPerPage)
    if (!isIError(response)) {
      setRoutingRules(response.routingRules)
      setCount(response.count)
    } else {
      openErrorSnack(response.error.message)
    }
    toggleLoader(false)
  }

  useEffect(() => {
    if (rowsPerPage !== getPageSize()) {
      savePageSize(rowsPerPage)
      scrollTop()
    }

    getList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, siteIds, active, rowsPerPage])

  const getListOnPageChange = async () => {
    toggleLoader(true)
    const response = await RoutingRulesApi.getList(
      { siteIds, active, searchText },
      paginationOffset,
      rowsPerPage,
      COLUMNS_MODEL_ROUTING_RULES.find((_column, index) => index === sortField)?.field,
      sortDirection === 'desc' ? -1 : 1,
    )
    if (!isIError(response)) {
      setRoutingRules(response.routingRules)
    } else {
      openErrorSnack(response.error.message)
    }
    toggleLoader(false)
  }

  useEffect(() => {
    if (!isInitialMount.current) {
      getListOnPageChange()
      scrollTop()
    } else {
      isInitialMount.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationOffset, sortField, sortDirection])

  const handleChangeRowsPerPage = (pageSize: number): void => {
    setPage(0)
    setPaginationOffset(0)
    setRowsPerPage(pageSize)
  }

  const handleOrderChange = (columnIndex: number): void => {
    setPaginationOffset(0)
    setPage(0)
    setSortField(columnIndex)
    setSortDirection((prevSortDirection) => (prevSortDirection === 'asc' ? 'desc' : 'asc'))
  }

  const handleNewRoutingRulesClick = () => {
    history.push(ROUTES_PATH.createRoutingRules)
  }

  const resetLocalFilters = (): void => {
    setIsSearchReset(true)
    resetFilters(filterKey)
  }

  const Filters = useMemo(
    (): JSX.Element => (
      <TopFiltersContainer>
        <FiltersContainer>
          <IconButton data-cy="initializeButton" onClick={resetLocalFilters}>
            <RefreshIcon />
          </IconButton>
          <SiteFilter
            dataCy="sitePicker"
            handleChange={(siteValues: string[]): void => {
              setFilter(filterKey, Filter.sites, siteValues)
            }}
            ids={siteIds}
            isUnselectAllowed={false}
          />
          <ActiveFilter
            handleChange={(activeValues: string[]): void => {
              setFilter(filterKey, Filter.active, activeValues)
            }}
            dataCy="activePicker"
            ids={active}
          />
        </FiltersContainer>
        <SearchContainer>
          <SearchInput
            defaultValue={searchText}
            key={isSearchReset ? 'search-reset' : ''}
            onSearch={(text: string): void => {
              setIsSearchReset(false)
              setFilter(filterKey, Filter.searchText, text)
            }}
          />
          <Button
            dataCy="newButton"
            className={styles.newButton}
            onPress={handleNewRoutingRulesClick}
          >
            {t('tablesEntries.new')}
          </Button>
        </SearchContainer>
      </TopFiltersContainer>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [siteIds, active, searchText, styles],
  )

  const displayRowsCount = useMemo(() => getDisplayRowsCount(routingRules), [routingRules])

  return (
    <>
      {Filters}
      <MaterialTable
        key={displayRowsCount}
        data={routingRules}
        columns={COLUMNS_MODEL_ROUTING_RULES.map(
          (col) =>
            ({
              ...col,
              hidden: tablesConfig[columnConfigName]?.includes(col.title),
            } as unknown as MaterialTable<object>),
        )}
        options={{
          toolbar: false,
          pageSize: displayRowsCount,
          actionsColumnIndex: -1,
        }}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        actions={[
          (): Action<IRoutingRule> => ({
            icon: ArrowForward,
            onClick: (): void => {
              /* unnecessary because of overriding but required on Action */
            },
            tooltip: t('tablesEntries.seeDetails'),
          }),
        ]}
        icons={MUITableIcons}
        components={{
          Header: (props): JSX.Element => (
            <MTableHeader
              {...props}
              onOrderChange={handleOrderChange}
              orderBy={sortField}
              orderDirection={sortDirection}
            />
          ),
          // eslint-disable-next-line react/prop-types, @typescript-eslint/no-unused-vars
          Pagination: ({ classes, ...props }): JSX.Element => (
            <Pagination
              {...props}
              columns={COLUMNS_MODEL_ROUTING_RULES}
              columnConfigName={columnConfigName}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              setPage={setPage}
              setPaginationOffset={setPaginationOffset}
            />
          ),
          Action: (props): JSX.Element => (
            // eslint-disable-next-line react/destructuring-assignment
            <Link to={`${ROUTES_PATH.detailRoutingRules}${props.data.id}`}>
              <IconButton title={t('tablesEntries.seeDetails')}>
                <ArrowForward className={styles.arrowIcon} />
              </IconButton>
            </Link>
          ),
        }}
      />
    </>
  )
}

export default (): JSX.Element => (
  <AppConfigProvider>
    <RoutingRulesTable />
  </AppConfigProvider>
)
