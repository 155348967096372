import { createStyles, makeStyles } from '@material-ui/core/styles'

export default makeStyles(() =>
  createStyles({
    listCard: {
      marginTop: '20px',
      marginBottom: '20px',
    },
  }),
)
