import React from 'react'
import { useTranslation } from 'react-i18next'

import { FilterKey } from 'constants/filters'
import { CancellationReasonType } from 'constants/constants'
import BaseCancellationsIndicator from './BaseCancellationsIndicator'

const containerId = 'stop-cancellations'
const filterKey = FilterKey.stopCancellations

const StopCancellationsIndicator = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <BaseCancellationsIndicator
      containerId={containerId}
      filterKey={filterKey}
      apiName="getStopCancellations"
      cancellationReasonType={CancellationReasonType.Stop}
      legendLabel={t('CancellationsScreen.numberOfStopCancellationsOverThePeriod')}
      title="CancellationsScreen.stopTitle"
    />
  )
}

export default StopCancellationsIndicator
