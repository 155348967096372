import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { ROUTES_PATH } from 'navigation/RoutesPath'

const ReloadCartDropoffScreen = (): JSX.Element => {
  const { push: navigateTo } = useHistory()

  useEffect(() => {
    navigateTo(ROUTES_PATH.cartDropoff)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <></>
}

export default ReloadCartDropoffScreen
