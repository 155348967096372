import { IError } from 'api/types'
import { ICustomerAddress, ICustomerFilters } from 'interfaces/ICustomer'
import { ICustomer } from 'interfaces/interfaces'
import Api from 'services/api'

const searchCustomers = async (
  queryParams: ICustomerFilters,
  sortBy?: string,
  sortDirection?: number,
): Promise<ICustomer[] | IError> => {
  try {
    const response = await Api.get(`customers/autocomplete`, {
      ...queryParams,
      sortBy,
      sortDirection,
    })
    return response
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const deleteAddress = async (
  id: string,
  address: ICustomerAddress,
): Promise<ICustomer | IError> => {
  try {
    const res = await Api.patch(`customers/${id}/delete-address`, { address })
    return res
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

export default {
  searchCustomers,
  deleteAddress,
}
