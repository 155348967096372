import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    tabIndicator: {
      backgroundColor: theme.color.primary,
    },
    alignFlexStart: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: '15px',
    },
    cardLine: {
      padding: theme.spacing(5),
      backgroundColor: theme.color.backgroundStrongGrey,
      marginBottom: 2,
    },
    cardLineLabel: {
      fontWeight: 'bold',
      paddingRight: theme.spacing(2),
    },
    tab: {
      margin: '0 8px',
      fontWeight: 'bold',
      padding: theme.spacing(3),
    },
    tabs: {
      backgroundColor: theme.color.backgroundStrongGrey,
      marginBottom: 2,
    },
    trackingContainer: {
      backgroundColor: theme.color.backgroundStrongGrey,
      overflowX: 'auto',
      padding: '10px',
    },
    tabPanel: {
      width: '100%',
      height: 376,
      overflowY: 'auto',
    },
    PODTabPanel: {
      height: 'unset',
    },
    tablePanel: {
      width: '100%',
      height: 328,
      overflowY: 'auto',
    },
    tableToolbar: {
      backgroundColor: theme.color.backgroundStrongGrey,
    },
    icon: {
      color: 'white',
      fontSize: 24,
      marginRight: 18,
    },
    addressContainer: {
      display: 'block',
      textAlign: 'center',
      width: '100px',
      minWidth: '100px',
    },
    addressLabel: {
      display: 'block',
      wordBreak: 'break-word',
    },
    visitList: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'center',
    },
    visitContent: {
      maxHeight: '250px',
      overflow: 'auto',
    },
    clientCodeIcon: {
      fontSize: 24,
      marginRight: 10,
    },
    stringValueLine: {
      marginTop: 20,
    },
    mapContainer: {
      height: 500,
      position: 'relative',
    },
    map: {
      height: '100%',
      zIndex: 1,
    },
    orderHistoryContainer: {
      width: '30vw',
      height: '100vh',
      zIndex: 99,
      padding: '15px',
      paddingTop: '15vh',
      position: 'absolute',
      right: '-30vw',
      top: 0,
      backgroundColor: theme.color.backgroundNavBar,
      overflowY: 'scroll',
      transition: '250ms',
    },
    orderHistoryContainerOpen: {
      right: 0,
    },
    orderHistoryCard: {
      width: '100%',
      padding: '10px',
      marginBottom: '10px',
    },
    orderHistorySubCard: {
      display: 'flex',
      flexDirection: 'column',
      padding: '8px',
      background: theme.color.tableRowEven,
      color: theme.color.secondary,
      border: '1px solid #D5D5D5',
      borderRadius: '5px',
      marginBottom: '10px',
    },
    spaceBetween: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }),
)
