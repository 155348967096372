import React, { useContext, useEffect, useRef, useState } from 'react'
import { IconButton } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import DateRangePicker from 'components/Inputs/DateRangePicker'
import {
  CarrierFilter,
  DeliveryTypeFilter,
  DriverFilter,
  RatingFilter,
  SiteFilter,
} from 'components/Inputs/ListFilter'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Filter, FilterKey } from 'constants/filters'
import { FiltersContext } from 'store/FiltersContext'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { DateType, RATING_GRADES } from 'constants/constants'
import AppConfigProvider from 'store/AppConfigContext'
import { COLUMNS_COMMENTS_REPORTING } from 'constants/table'
import { FeedbackContext } from 'store/FeedbackContext'
import StatsApi from 'api/stats'
import { isIError } from 'api/types'
import { ICommentStats } from 'interfaces/IComments'
import { getPageSize, savePageSize } from 'utils/localStorage'
import { scrollTop } from 'utils/functions'
import { FiltersContainer, TopFiltersContainer } from 'components/Layout'
import ExtendedDateFilters from 'components/Table/ExtendedDateFilters'
import CommentsTable from './CommentsTable'

const filterKey = FilterKey.customerCommentsReporting

function CommentsScreen(): JSX.Element {
  const isInitialMount = useRef(true)
  const { t } = useTranslation()
  const { setFilter, resetFilters, filters } = useContext(FiltersContext)
  const { openErrorSnack, toggleLoader } = useContext(FeedbackContext)

  const [data, setData] = useState<ICommentStats[]>([])
  const [count, setCount] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [paginationOffset, setPaginationOffset] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(getPageSize)

  const startDateFilter = filters[filterKey][Filter.startDate] as string
  const endDateFilter = filters[filterKey][Filter.endDate] as string
  const ratingGradesFilter = filters[filterKey][Filter.ratingGrade] as string[]
  const deliveryTypesFilter = filters[filterKey][Filter.deliveryTypes] as string[]
  const sitesFilter = filters[filterKey][Filter.sites] as string[]
  const carriersFilter = filters[filterKey][Filter.carriers] as string[]
  const driversFilter = filters[filterKey][Filter.drivers] as string[]

  const getStats = async (
    startDate?: string,
    endDate?: string,
    grades?: string[],
    deliveryTypes?: string[],
    warehouseIds?: string[],
    carrierIds?: string[],
    driverIds?: string[],
    offset?: number,
    limit?: number,
  ): Promise<void> => {
    toggleLoader(true)
    const res = await StatsApi.getCustomerCommentStats(
      startDate,
      endDate,
      grades,
      deliveryTypes,
      warehouseIds,
      carrierIds,
      driverIds,
      offset,
      limit,
    )
    if (isIError(res)) {
      openErrorSnack(res.error.message)
    } else {
      setData(res.items)
      setCount(res.count)
    }
    toggleLoader(false)
  }

  useEffect(() => {
    if (rowsPerPage !== getPageSize()) {
      savePageSize(rowsPerPage)
      scrollTop()
    }
    setPaginationOffset(() => 0)
    setPage(() => 0)

    getStats(
      startDateFilter,
      endDateFilter,
      ratingGradesFilter,
      deliveryTypesFilter,
      sitesFilter,
      carriersFilter,
      driversFilter,
      paginationOffset,
      rowsPerPage,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    startDateFilter,
    endDateFilter,
    ratingGradesFilter,
    deliveryTypesFilter,
    sitesFilter,
    carriersFilter,
    driversFilter,
    rowsPerPage,
  ])

  useEffect(() => {
    if (!isInitialMount.current) {
      getStats(
        startDateFilter,
        endDateFilter,
        ratingGradesFilter,
        deliveryTypesFilter,
        sitesFilter,
        carriersFilter,
        driversFilter,
        paginationOffset,
        rowsPerPage,
      )
      scrollTop()
    } else {
      isInitialMount.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationOffset])

  const resetLocalFilters = (): void => resetFilters(filterKey)

  const handleStartChange = (startValue: MaterialUiPickersDate): void => {
    setFilter(filterKey, Filter.startDate, moment(startValue).toISOString())
  }

  const handleEndChange = (endValue: MaterialUiPickersDate): void => {
    setFilter(filterKey, Filter.endDate, moment(endValue).toISOString())
  }

  const setEndDate = (value: string): void => {
    setFilter(filterKey, Filter.endDate, value)
  }

  const setStartDate = (value: string): void => {
    setFilter(filterKey, Filter.startDate, value)
  }

  return (
    <>
      <TopFiltersContainer>
        <FiltersContainer>
          <IconButton data-cy="initializeButton" onClick={resetLocalFilters}>
            <RefreshIcon />
          </IconButton>
          <DateRangePicker
            startDate={startDateFilter}
            endDate={endDateFilter}
            onChangeStart={handleStartChange}
            onChangeEnd={handleEndChange}
            hideShiftPicker
            dateType={DateType.Date}
            maxStartDate={moment().toISOString()}
            maxEndDate={moment().toISOString()}
          />
          <RatingFilter
            handleChange={(values: string[]): void => {
              setFilter(filterKey, Filter.ratingGrade, values)
            }}
            ids={ratingGradesFilter}
            dataCy="ratingPicker"
            data={RATING_GRADES}
          />
          <DeliveryTypeFilter
            handleChange={(values: string[]): void => {
              setFilter(filterKey, Filter.deliveryTypes, values)
            }}
            ids={deliveryTypesFilter}
            dataCy="deliveryPicker"
          />
          <SiteFilter
            handleChange={(siteValues: string[]): void => {
              setFilter(filterKey, Filter.sites, siteValues)
            }}
            ids={sitesFilter}
            placeholder={t('CustomerRatingReportingScreen.site')}
            dataCy="sitePicker"
            isUnselectAllowed={false}
          />
          <CarrierFilter
            handleChange={(carrierValues: string[]): void => {
              setFilter(filterKey, Filter.carriers, carrierValues)
              setFilter(filterKey, Filter.drivers, [])
            }}
            ids={carriersFilter}
            dataCy="carrierPicker"
            siteIds={sitesFilter}
            filterKey={filterKey}
          />
          <DriverFilter
            handleChange={(driverValues: string[]): void => {
              setFilter(filterKey, Filter.drivers, driverValues)
            }}
            ids={driversFilter}
            siteIds={sitesFilter}
            carrierIds={carriersFilter}
            filterKey={filterKey}
          />
        </FiltersContainer>
      </TopFiltersContainer>
      <ExtendedDateFilters
        last30weeks
        last30days
        setEndDate={setEndDate}
        setStartDate={setStartDate}
      />
      <CommentsTable
        columns={COLUMNS_COMMENTS_REPORTING}
        columnConfigName="commentList"
        filterKey={filterKey}
        count={count}
        data={data}
        page={page}
        setPage={setPage}
        paginationOffset={paginationOffset}
        setPaginationOffset={setPaginationOffset}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
    </>
  )
}

export default (): JSX.Element => (
  <AppConfigProvider>
    <CommentsScreen />
  </AppConfigProvider>
)
