import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import DateRangePicker from 'components/Inputs/DateRangePicker'
import { DateType } from 'constants/constants'
import { IUpdateTourVisits } from 'interfaces/Itours'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from './styles'

interface IProps {
  visit: IUpdateTourVisits
  phoneNumber: string
  label: string
  fullAddress: string
  handleChange: (visitId: string, name: string, value: MaterialUiPickersDate) => void
}

function ModalVisitCard({
  visit,
  label,
  fullAddress,
  phoneNumber,
  handleChange,
}: IProps): JSX.Element {
  const classes = useStyles()
  const { t } = useTranslation()
  const [formData, setFormData] = useState<{
    start: string
    end: string
  }>({
    start: '',
    end: '',
  })

  React.useEffect(() => {
    if (!visit || !visit.departureMinDate || !visit.departureMaxDate) return
    setFormData({
      start: visit.departureMinDate.toString(),
      end: visit.departureMaxDate.toString(),
    })
  }, [visit])

  return (
    <div className={classes.editContainer}>
      <div className={classes.editLabelContainer}>
        <span>{label ?? ''}</span>
      </div>
      <div className={classes.editAddressContainer}>
        <span className={classes.withMarginBottom}>{fullAddress}</span>
        <span className={classes.withMarginBottom}>{phoneNumber}</span>
        <span className={classes.withMarginBottom}>{t('tablesEntries.deliveryTime')}</span>
        <div className={classes.withMarginBottom}>
          <DateRangePicker
            dateType={DateType.DateTime}
            startDate={formData.start}
            endDate={formData.end}
            hideShiftPicker
            onChangeStart={(startValue: MaterialUiPickersDate) =>
              handleChange(visit.visitId, 'departureMinDate', startValue)}
            onChangeEnd={(endValue: MaterialUiPickersDate) =>
              handleChange(visit.visitId, 'departureMaxDate', endValue)}
          />
        </div>
      </div>
    </div>
  )
}

export default ModalVisitCard
