import React, { useEffect, useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import SettingsIcon from '@material-ui/icons/Settings'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import { ITableColumn } from 'interfaces/interfaces'

interface ISelectableColMenu {
  list: ITableColumn[]
  columnPreferences: string[]
  onChange?(entries: string[]): void
}

function SelectableColMenu({ list, columnPreferences, onChange }: ISelectableColMenu): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [preferencesList, setPreferencesList] = useState<string[]>([...columnPreferences])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => setAnchorEl(null)

  const toggleEntry = (entry: string) => {
    setPreferencesList((prev) => {
      const entries = [...prev]
      if (preferencesList.includes(entry)) {
        const index = entries.findIndex((string) => string === entry)
        entries.splice(index, 1)
      } else {
        entries.push(entry)
      }
      if (onChange) {
        onChange(entries)
      }
      return entries
    })
  }

  useEffect(() => {
    if (preferencesList.length !== columnPreferences.length) {
      setPreferencesList(columnPreferences)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnPreferences])

  const isChecked = (entry: ITableColumn): boolean => {
    if (entry.defaultHidden) {
      // in this case, the field is checked if it is available in the user preferences
      return preferencesList.includes(entry.title)
    }
    return !preferencesList.includes(entry.title)
  }

  return (
    <>
      <IconButton aria-controls="select-col-menu" aria-haspopup="true" onClick={handleClick}>
        <SettingsIcon />
      </IconButton>
      <Menu
        id="simple-menu-settings"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {list.map((entry) => (
          <MenuItem key={entry.title} onClick={() => toggleEntry(entry.title)}>
            <Checkbox checked={isChecked(entry)} />
            {entry.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default SelectableColMenu
