import React from 'react'
import styled from 'styled-components'
import { useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { StopStatus } from 'constants/constants'

interface LabelProps {
  background: string
}

const Container = styled.div(() => ({
  'align-items': 'center',
  display: 'flex',
  width: '100%',
}))

const Line = styled.div<LabelProps>(({ background, theme }) => ({
  height: '5px',
  width: '100%',
  flex: '1',
  background,
  minWidth: '115px',
  border: `1px solid ${theme.color.statusLineBorder}`,
}))

const Label = styled.p<LabelProps>(({ background, color }) => ({
  background,
  color,
  padding: '2px 5px 2px 5px',
  textAlign: 'center',
}))

interface IProps {
  status: StopStatus
}

const StatusLine = ({ status }: IProps): JSX.Element => {
  const theme = useTheme()
  const { t } = useTranslation()

  const getColor = (): { color: string; background: string } => {
    switch (status) {
      case StopStatus.Delivered:
        return { color: 'white', background: 'green' }
      case StopStatus.Dropped:
        return { color: 'white', background: 'red' }
      default:
        return { color: 'black', background: 'white' }
    }
  }

  const { color, background } = getColor()

  return (
    <Container>
      <Line background={background} theme={theme} />
      <Label background={background} color={color}>
        <div data-cy="stop-status">{t(`StopStatus.${StopStatus[status]?.toLowerCase()}`)}</div>
      </Label>
      <Line background={background} theme={theme} />
    </Container>
  )
}

export default StatusLine
