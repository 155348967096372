import React, { useContext, useMemo } from 'react'
import MaterialTable, { MTableHeader } from 'material-table'
import { AppConfigContext } from 'store/AppConfigContext'
import MUITableIcons from 'constants/MUITableIcons'
import Pagination from 'components/Table/Pagination/Pagination'
import { ICommentStats } from 'interfaces/IComments'
import { ITableColumn } from 'interfaces/interfaces'
import { dateDefaultFormat } from 'utils/dateFormat'
import {
  GetCarrierNameFromIdString,
  GetDriverNameFromIdString,
  getDisplayRowsCount,
} from 'utils/tableUtils'

interface ICommentsTableProps {
  columns: ITableColumn[]
  columnConfigName: string
  filterKey: string
  data: ICommentStats[]
  page: number
  setPage: (page: number) => void
  paginationOffset: number
  setPaginationOffset: (offset: number) => void
  rowsPerPage: number
  setRowsPerPage: (nbRows: number) => void
  detailsUrl?: string
  count?: number
}

export default function CommentsTable({
  columns,
  count,
  page,
  setPage,
  columnConfigName,
  setPaginationOffset,
  rowsPerPage,
  setRowsPerPage,
  ...rest
}: ICommentsTableProps): JSX.Element {
  const { tablesConfig } = useContext(AppConfigContext)

  const handleChangeRowsPerPage = (pageSize: number): void => {
    setPaginationOffset(0)
    setPage(0)
    setRowsPerPage(pageSize)
  }

  const displayRowsCount = useMemo(() => getDisplayRowsCount(rest.data), [rest.data])

  return (
    <MaterialTable
      key={displayRowsCount}
      columns={columns.map(
        (col) =>
          ({
            ...col,
            hidden: col.hidden || tablesConfig[columnConfigName]?.includes(col.title),
          } as unknown as MaterialTable<object>),
      )}
      data={rest.data.map((row: ICommentStats) => ({
        commentCreationDate: dateDefaultFormat(row.commentCreationDate),
        carrierId: GetCarrierNameFromIdString({ id: row.carrierId }),
        driverId: GetDriverNameFromIdString({ id: row.driverId }),
        customerName: row.customerName,
        phoneNumber: row.phoneNumber,
        endCustomerRating: row.endCustomerRating,
        endCustomerComment: row.endCustomerComment,
      }))}
      options={{
        showTitle: false,
        search: false,
        exportFileName: 'Customer Comments Statistics',
        pageSize: displayRowsCount,
        exportButton: true,
        exportDelimiter: ';',
      }}
      icons={MUITableIcons}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      components={{
        // eslint-disable-next-line react/prop-types, @typescript-eslint/no-unused-vars
        Pagination: ({ ...props }): JSX.Element => (
          <Pagination
            {...props}
            page={page}
            setPage={setPage}
            columns={columns.filter((col) => !col.hidden)}
            columnConfigName={columnConfigName}
            count={count}
            rowsPerPage={rowsPerPage}
            setPaginationOffset={setPaginationOffset}
          />
        ),
        Header: (props): JSX.Element => <MTableHeader {...props} />,
      }}
    />
  )
}
