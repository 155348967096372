import React, { useEffect, useState, useMemo, useContext } from 'react'
import MaterialTable, { MTableHeader, Action } from 'material-table'
import ArrowForward from '@material-ui/icons/ArrowForward'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import { ITableColumn, ITenantFilters } from 'interfaces/interfaces'
import { FiltersContext } from 'store/FiltersContext'
import { ITenant } from 'interfaces/ITenant'
import MUITableIcons from 'constants/MUITableIcons'
import { NUMBER_OF_TENANTS_BY_REQUEST } from 'constants/constants'
import { TenantsConsumer } from 'store/TenantsContext'
import SearchInput from 'components/Inputs/SearchInput'
import Button from 'components/Button/CustomButton'
import AppConfigProvider, { AppConfigContext } from 'store/AppConfigContext'
import Pagination from 'components/Table/Pagination'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import { FilterKey, Filter } from 'constants/filters'
import { getDisplayRowsCount } from 'utils/tableUtils'

import useStyles from './styles'

interface ICustomTableProps {
  columns: ITableColumn[]
  getTenantsList?: (
    filters?: ITenantFilters,
    offset?: number,
    sortField?: number,
    sortDirection?: string,
  ) => void
  tenants: ITenant[]
  count?: number
}

const columnConfigName = 'tenantList'
const filterKey = FilterKey.tenants

const CustomTable = ({
  columns,
  getTenantsList,
  tenants,
  count,
  ...rest
}: ICustomTableProps): JSX.Element => {
  const { setFilter, filters } = useContext(FiltersContext)

  const [paginationOffset, setPaginationOffset] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const searchText = filters[filterKey][Filter.searchText] as string
  const [isSearchReset, setIsSearchReset] = useState<boolean>(false)
  const [sortField, setSortField] = useState<number>(0)
  const [sortDirection, setSortDirection] = useState<string>('asc')
  const styles = useStyles()
  const history = useHistory()
  const { tablesConfig } = useContext(AppConfigContext)
  const { t } = useTranslation()

  useEffect(() => {
    if (getTenantsList) {
      getTenantsList({ searchText }, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText])

  useEffect(() => {
    if (getTenantsList) {
      getTenantsList({ searchText }, paginationOffset, sortField, sortDirection)
    }
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationOffset, sortField, sortDirection])

  const handleNewClick = (): void => {
    history.push(ROUTES_PATH.createTenant)
  }

  const Filters = useMemo(
    (): JSX.Element => (
      <div className={clsx(styles.filterContainer, styles.noFilter)}>
        <div className={styles.filterRightContainer}>
          <SearchInput
            defaultValue={searchText}
            key={isSearchReset ? 'search-reset' : ''}
            onSearch={(text: string): void => {
              setIsSearchReset(false)
              setFilter(filterKey, Filter.searchText, text)
            }}
          />
          <Button dataCy="newButton" className={styles.newButton} onPress={handleNewClick}>
            {t('tablesEntries.new')}
          </Button>
        </div>
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchText, styles],
  )

  const handleDetailsClick = ({ id }: ITenant): void => {
    history.push(`${ROUTES_PATH.tenantDetails}${id}`)
  }

  const handleOrderChange = (columnIndex: number): void => {
    setPage(0)
    setPaginationOffset(0)
    setSortField(columnIndex)
    setSortDirection((prevSortDirection) => (prevSortDirection === 'asc' ? 'desc' : 'asc'))
  }

  const displayRowsCount = useMemo(() => getDisplayRowsCount(tenants), [tenants])

  return (
    <>
      {Filters}
      <MaterialTable
        key={displayRowsCount}
        data={tenants}
        columns={columns.map(
          (col) =>
            ({
              ...col,
              hidden: tablesConfig[columnConfigName]?.includes(col.title),
            } as unknown as MaterialTable<object>),
        )}
        {...rest}
        options={{
          toolbar: false,
          pageSize: displayRowsCount,
          actionsColumnIndex: -1,
        }}
        actions={[
          (data: ITenant): Action<ITenant> => ({
            icon: ArrowForward,
            tooltip: t('tablesEntries.seeDetails'),
            onClick: (): void => handleDetailsClick(data),
          }),
        ]}
        icons={MUITableIcons}
        components={{
          // eslint-disable-next-line react/prop-types, @typescript-eslint/no-unused-vars
          Pagination: ({ classes, ...props }): JSX.Element => (
            <Pagination
              {...props}
              rowsPerPage={NUMBER_OF_TENANTS_BY_REQUEST}
              columnConfigName={columnConfigName}
              columns={columns}
              page={page}
              count={count}
              setPage={setPage}
              setPaginationOffset={setPaginationOffset}
            />
          ),
          Header: ({ ...props }): JSX.Element => (
            <MTableHeader
              {...props}
              onOrderChange={handleOrderChange}
              orderBy={sortField}
              orderDirection={sortDirection}
            />
          ),
        }}
      />
    </>
  )
}

export default (props: ICustomTableProps): JSX.Element => (
  <AppConfigProvider>
    <TenantsConsumer>
      {(ctx): JSX.Element => (
        <CustomTable count={ctx.count} getTenantsList={ctx.getTenantsList} {...props} />
      )}
    </TenantsConsumer>
  </AppConfigProvider>
)
