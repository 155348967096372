import React, { useState, useEffect } from 'react'

import OrdersTable from 'components/Table/OrdersTable'
import { COLUMNS_GEOCODING_ORDERS } from 'constants/table'
import { IOrder } from 'interfaces/IOrders'
import { OrdersConsumer } from 'store/OrdersContext'
import { LatLng } from 'interfaces/map'
import { FilterKey } from 'constants/filters'

import MapModal from 'screens/OrderCategory/List/MapModal'
import AppConfigProvider from 'store/AppConfigContext'

interface IOrdersProps {
  orders: IOrder[]
}

const GeocodingOrdersScreen = ({ orders }: IOrdersProps): JSX.Element => {
  const [isModalOpened, setModalOpened] = useState<boolean>(false)
  const [orderId, setOrderId] = useState<string | null>(null)
  const [deliveryAddresLabel, setDeliveryAddressLabel] = useState<string | null>(null)
  const [orderLocation, setOrderLocation] = useState<LatLng | null>(null)
  const [fullAddress, setFullAddress] = useState<string>('')
  const [warehouseId, setWarehouseId] = useState<string | undefined>(undefined)

  const onPressRowLocationButton = (rowData: IOrder): void => {
    setOrderId(rowData.orderId)
    setDeliveryAddressLabel(`${rowData.customer.firstName} ${rowData.customer.lastName}`)
    // default lat lng if none has been set
    setOrderLocation(rowData.location || { latitude: 48.85727, longitude: 2.38061 })
    setFullAddress(rowData.deliveryAddress || '')
    setWarehouseId(rowData.warehouse?.id)
    setModalOpened(true)
  }

  useEffect(() => {
    if (!isModalOpened) {
      setOrderLocation(null)
      setFullAddress('')
    }
  }, [isModalOpened])

  const onCloseModal = (): void => setModalOpened(false)

  return (
    <>
      <OrdersTable
        showExtendedDateFilter
        extendedDateFilter={{
          today: true,
          tomorrow: true,
          thisWeek: true,
          next7days: true,
        }}
        data={orders}
        columns={COLUMNS_GEOCODING_ORDERS}
        isForGeocoding
        onPressLocation={onPressRowLocationButton}
        columnConfigName="planningOrderList"
        filterKey={FilterKey.geocodingOrders}
      />
      <MapModal
        mapName="geocodingOrders"
        visible={isModalOpened}
        onClose={onCloseModal}
        currentLocation={{ lat: orderLocation?.latitude, lng: orderLocation?.longitude } as never}
        orderId={orderId}
        deliveryAddresLabel={deliveryAddresLabel as string}
        fullAddress={fullAddress}
        isCoordinateReadOnly
        warehouseId={warehouseId}
      />
    </>
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <AppConfigProvider>
    <OrdersConsumer>
      {(ctx): JSX.Element => <GeocodingOrdersScreen orders={ctx.orders} {...props} />}
    </OrdersConsumer>
  </AppConfigProvider>
)
