import React from 'react'
import { Tooltip } from '@devexpress/dx-react-chart-material-ui'

import useStyles from './styles'

const TooltipSheet = ({
  children,
  ...otherProps
}: JSX.IntrinsicAttributes & Tooltip.SheetProps): JSX.Element => {
  const classes = useStyles()
  return (
    <Tooltip.Sheet {...otherProps} className={classes.sheet}>
      {children}
    </Tooltip.Sheet>
  )
}

export default TooltipSheet
