import React, { ReactNode } from 'react'

import useStyles from './styles'

interface IFieldErrorProps {
  children: ReactNode
}

const FieldError = ({ children }: IFieldErrorProps): JSX.Element => {
  const classes = useStyles()

  return <span className={classes.error}>{children}</span>
}

export default FieldError
