import React from 'react'
import { Marker, Popup } from 'react-leaflet'
import L from 'leaflet'

import { IDriverEventLocation } from 'interfaces/IDriverEventLocation'
import { DRIVER_EVENT_MAP } from 'constants/constants'
import { dateDefaultFormat } from 'utils/dateFormat'

interface IProps {
  driverEventLocation: IDriverEventLocation
}

const GREEN_ICON = new L.Icon({
  iconUrl:
    'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|2ecc71&chf=a,s,ee00FFFF',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
})

const DriverEventMarker = ({ driverEventLocation }: IProps): JSX.Element => {
  const { location, concernedEvent, date } = driverEventLocation
  return (
    <Marker position={{ lat: location.latitude, lng: location.longitude }} icon={GREEN_ICON}>
      <Popup>
        <div>{DRIVER_EVENT_MAP.get(concernedEvent)}</div>
        <div>{dateDefaultFormat(date)}</div>
      </Popup>
    </Marker>
  )
}

export default DriverEventMarker
