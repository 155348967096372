import React, { useState, useEffect, ChangeEvent, useContext } from 'react'
import { FormControl, InputLabel, Select, MenuItem, IconButton } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import moment from 'moment'
import RefreshIcon from '@material-ui/icons/Refresh'
import CreatableSelect from 'react-select/creatable'
import { OptionTypeBase } from 'react-select'
import { useTheme } from '@material-ui/core/styles'
import { AuthContext } from 'store/AuthContext'
import { FiltersContext } from 'store/FiltersContext'
import { OrdersConsumer } from 'store/OrdersContext'
import { formatDeliveryTypes } from 'utils/functions'
import {
  DeliveryTypeFilter,
  PlanStatusFilter,
  SiteFilter,
  TransportTypeFilter,
} from 'components/Inputs/ListFilter'
import { IHourlyStats } from 'interfaces/IHourlyStats'
import {
  ORDER_CHART_VALUE_AXIS,
  OrderAxis,
  ORDER_CHART_VALUE_GROUP_BY,
  ICustomSelector,
  ORDER_PLAN_STATUSES,
} from 'constants/constants'
import ChartProgress from 'components/Chart/ChartProgress'
import { FilterKey, Filter } from 'constants/filters'
import { ControlComponent, getCreatableSelectStyle } from 'components/Inputs/CreatableSelect'
import { FiltersContainer, TopFiltersContainer } from 'components/Layout'
import { OrderTypeChart } from './OrderTypeChart'

import useStyles from './styles'

const ordersByType = 'orders-by-type'
const filterKey = FilterKey.orderIndicator

interface IOrderIndicatorProps {
  getOrderStats?: (
    date: string,
    deliveryTypes: string[],
    transportTypes?: string[],
    deliveryWarehouseIds?: string[],
    warehouseIds?: string[],
    planStatuses?: string[],
    zipCodes?: string[],
    groupBy?: number,
  ) => void
  orderTypesStats?: IHourlyStats[]
}

interface ISelectProps {
  config: ICustomSelector[]
  label: string
  type: string
  value: number
  onSelect: (value: number) => void
}

const CustomSelect = ({ label, value, type, config, onSelect }: ISelectProps): JSX.Element => {
  const classes = useStyles()

  const handleSelect = (event: ChangeEvent<{ value: unknown }>): void => {
    onSelect(event.target.value as number)
  }

  return (
    <FormControl variant="outlined">
      <InputLabel className={classes.customSelect}>{label}</InputLabel>
      <Select
        data-cy={`${type}Picker`}
        value={value}
        onChange={handleSelect}
        className={classes.customSelect}
        label={label}
      >
        <MenuItem value={-1} disabled>
          {label}
        </MenuItem>
        {config.map((conf) => (
          <MenuItem data-cy={`item-option-${conf.label}`} key={conf.id} value={conf.id}>
            {conf.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const OrderIndicator = ({ getOrderStats, orderTypesStats }: IOrderIndicatorProps): JSX.Element => {
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  const { setFilter, resetFilters, filters } = useContext(FiltersContext)
  const { user } = useContext(AuthContext)
  const date = filters[filterKey][Filter.date] as string
  const deliveryTypes = filters[filterKey][Filter.deliveryTypes] as string[]
  const transportTypes = filters[filterKey][Filter.transportTypes] as string[]
  const selectedAxis = filters[filterKey][Filter.axis] as number
  const deliveryWarehouseIds = filters[filterKey][Filter.deliveryWarehouses] as string[]
  const siteIds = filters[filterKey][Filter.sites] as string[]
  const planStatuses = filters[filterKey][Filter.planStatuses] as string[]
  const zipCodes = filters[filterKey][Filter.zipCodes] as OptionTypeBase[]
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [zipCodeInputText, setZipCodeInputText] = useState<string>('')
  const selectedGroupBy = filters[filterKey][Filter.groupBy] as number
  const [orders, setOrders] = useState<number[]>([])
  const [labelSources, setLabelSources] = useState<Map<number, string>>(new Map())

  const deliveryTypesArray = formatDeliveryTypes(user?.tenantConfig?.deliveryTypes)

  const deliveryTypesMap: Map<number, string> = new Map(
    deliveryTypesArray.map((deliveryType) => [
      deliveryType?.code || 0,
      deliveryType.label,
    ]),
  )

  const planStatusesMap: Map<number, string> = new Map(
    ORDER_PLAN_STATUSES.map((status) => [parseInt(status.id, 10), status.name]),
  )

  useEffect(() => {
    if (getOrderStats) {
      setIsLoading(true)
      getOrderStats(
        date,
        deliveryTypes.length > 0 ? deliveryTypes : deliveryTypesArray.map((type) => type.code?.toString() || '0'),
        transportTypes,
        deliveryWarehouseIds,
        siteIds,
        planStatuses.length > 0 ? planStatuses : ORDER_PLAN_STATUSES.map((status) => status.id),
        zipCodes.map(({ value }) => value),
        selectedGroupBy,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    date,
    deliveryTypes,
    transportTypes,
    deliveryWarehouseIds,
    siteIds,
    planStatuses,
    zipCodes,
    selectedGroupBy,
  ])

  useEffect(() => {
    setLabelSources(selectedGroupBy === 0 ? deliveryTypesMap : planStatusesMap)
    let values: number[]
    if (selectedGroupBy === 0) {
      values = deliveryTypes.length > 0
        ? deliveryTypes
          .filter((v) => v || '0')
          .map((v) => parseInt(v, 10))
        : deliveryTypesArray
          .filter((v) => v.code || '0')
          .map((v) => v.code as number)
    } else {
      values = planStatuses.length > 0
        ? planStatuses.map((v) => parseInt(v, 10))
        : ORDER_PLAN_STATUSES.map((v) => parseInt(v.id, 10))
    }
    setOrders(values)
    setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderTypesStats])

  const handleDateChange = (selectedDate: MaterialUiPickersDate): void => {
    if (moment(selectedDate).isValid()) {
      const newDate = moment(selectedDate).hours(0).minutes(0).toISOString()
      setFilter(filterKey, Filter.date, newDate)
    }
  }

  const resetLocalFilters = (): void => resetFilters(filterKey)

  const handleZipCodeChange = (zipCodeValues: OptionTypeBase): void => {
    if (zipCodes.length > 0) {
      setFilter(filterKey, Filter.zipCodes, zipCodeValues as OptionTypeBase[])
    }
  }

  const handleZipCodeKeyDown = (event: React.KeyboardEvent<HTMLElement>): void => {
    if (!zipCodeInputText) return
    // eslint-disable-next-line default-case
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setZipCodeInputText('')
        setFilter(
          filterKey,
          Filter.zipCodes,
          zipCodes.find((zipCode) => zipCode.value === zipCodeInputText)
            ? zipCodes
            : [...zipCodes, { label: zipCodeInputText, value: zipCodeInputText }],
        )
        event.preventDefault()
    }
  }

  let chartTitle = ''
  if (selectedGroupBy === 0) {
    chartTitle = selectedAxis === OrderAxis.Order
      ? t('OrderStatsScreen.orderCountByType')
      : t('OrderStatsScreen.parcelCountByType')
  } else {
    chartTitle = selectedAxis === OrderAxis.Order
      ? t('OrderStatsScreen.orderCountByStat')
      : t('OrderStatsScreen.parcelCountByStat')
  }
  return (
    <>
      <TopFiltersContainer>
        <FiltersContainer>
          <IconButton data-cy="initializeButton" onClick={resetLocalFilters}>
            <RefreshIcon />
          </IconButton>
          <KeyboardDatePicker
            label={t('executionScreen.date')}
            inputVariant="outlined"
            format="DD/MM/YYYY"
            value={date}
            onChange={handleDateChange}
            data-cy="startPicker"
            className={classes.datePicker}
          />
          <SiteFilter
            handleChange={(siteValues: string[]): void => {
              setFilter(filterKey, Filter.sites, siteValues)
            }}
            ids={siteIds}
            placeholder={t('OrderStatsScreen.site')}
            dataCy="sitePicker"
            isUnselectAllowed={false}
          />
          <CustomSelect
            config={ORDER_CHART_VALUE_AXIS}
            label={t('OrderStatsScreen.axis')}
            type="axis"
            value={selectedAxis}
            onSelect={(axis: number) => {
              setFilter(filterKey, Filter.axis, axis)
            }}
          />
          <CustomSelect
            config={ORDER_CHART_VALUE_GROUP_BY}
            label={t('OrderStatsScreen.groupBy')}
            type="groupBy"
            value={selectedGroupBy}
            onSelect={(groupBy: number) => {
              setFilter(filterKey, Filter.groupBy, groupBy)
            }}
          />
          <DeliveryTypeFilter
            dataCy="deliveryPicker"
            handleChange={(deliveryTypeValues: string[]): void => {
              setFilter(filterKey, Filter.deliveryTypes, deliveryTypeValues)
            }}
            ids={deliveryTypes}
          />
          <PlanStatusFilter
            handleChange={(planStatusValues: string[]): void => {
              setFilter(filterKey, Filter.planStatuses, planStatusValues)
            }}
            ids={planStatuses}
            placeholder={t('tablesEntries.status')}
            dataCy="statusPicker"
          />
          <TransportTypeFilter
            dataCy="transportPicker"
            handleChange={(transportTypeValues: string[]): void => {
              setFilter(filterKey, Filter.transportTypes, transportTypeValues)
            }}
            ids={transportTypes}
          />
          <SiteFilter
            handleChange={(deliveryWarehouseValues: string[]): void => {
              setFilter(filterKey, Filter.deliveryWarehouses, deliveryWarehouseValues)
            }}
            ids={deliveryWarehouseIds}
            placeholder={t('OrderStatsScreen.destinationSite')}
            dataCy="destinationSitePicker"
          />
          <CreatableSelect
            styles={getCreatableSelectStyle(
              'transparent',
              theme.color.label,
              theme.color.fieldBorder,
              theme.color.hover,
            )}
            className={classes.zipCode}
            components={{ DropdownIndicator: null, Control: ControlComponent }}
            inputValue={zipCodeInputText}
            onInputChange={setZipCodeInputText}
            isClearable
            isMulti
            menuIsOpen={false}
            onChange={handleZipCodeChange}
            onKeyDown={handleZipCodeKeyDown}
            placeholder={t('OrderStatsScreen.zipCode')}
            value={zipCodes}
            backgroundColor={theme.color.backgroundBody}
          />
        </FiltersContainer>
      </TopFiltersContainer>
      {isLoading && <ChartProgress count={24} />}
      {orderTypesStats && !isLoading && (
        <OrderTypeChart
          key={`order-type-${date}-${deliveryTypes.length}`}
          data={orderTypesStats}
          title={chartTitle}
          containerId={ordersByType}
          exportName={ordersByType}
          orders={orders}
          labelSources={labelSources}
          selectedAxis={selectedAxis}
        />
      )}
    </>
  )
}

export default (props: JSX.IntrinsicAttributes & IOrderIndicatorProps): JSX.Element => (
  <OrdersConsumer>
    {(ordersContext): JSX.Element => (
      <OrderIndicator
        getOrderStats={ordersContext.getOrderStats}
        orderTypesStats={ordersContext.orderTypesStats}
        {...props}
      />
    )}
  </OrdersConsumer>
)
