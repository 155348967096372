import React, { useEffect, useState, useMemo, useRef, useContext } from 'react'
import MaterialTable, { Action, MTableHeader } from 'material-table'
import ArrowForward from '@material-ui/icons/ArrowForward'
import { useHistory } from 'react-router-dom'
import RefreshIcon from '@material-ui/icons/Refresh'
import { IconButton } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import Button from 'components/Button/CustomButton'
import MUITableIcons from 'constants/MUITableIcons'
import { ICompany, ITableColumn, IUserFilters, IWarehouse } from 'interfaces'
import { IUser } from 'interfaces/IUser'
import { UsersConsumer } from 'store/UsersContext'
import { FiltersContext } from 'store/FiltersContext'
import {
  ActiveFilter,
  CompanyFilter,
  RolesFilter,
  SiteFilter,
  TenantFilter,
} from 'components/Inputs/ListFilter'
import SearchInput from 'components/Inputs/SearchInput'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import AppConfigProvider, { AppConfigContext } from 'store/AppConfigContext'
import Pagination from 'components/Table/Pagination'
import { scrollTop } from 'utils/functions'
import { getPageSize, savePageSize } from 'utils/localStorage'
import { FilterKey, Filter } from 'constants/filters'
import { getDisplayRowsCount } from 'utils/tableUtils'

import { AuthContext } from 'store/AuthContext'
import { USER_ROLES } from 'constants/constants'
import { ContentContext } from 'store/ContentContext'
import { FiltersContainer, SearchContainer, TopFiltersContainer } from 'components/Layout'
import useStyle from './styles'

interface ICustomTableProps {
  columns: ITableColumn[]
  users: IUser[]
  count?: number
  updateUsers?: (
    filters?: IUserFilters,
    offset?: number,
    rowsPerPage?: number,
    sortField?: number,
    sortDirection?: string,
    isAdmin?: boolean,
  ) => void
}

const columnConfigName = 'userList'
const filterKey = FilterKey.users

const CustomTable = ({
  columns,
  updateUsers,
  users,
  count,
  ...rest
}: ICustomTableProps): JSX.Element => {
  const isSitesFilterLoaded = useRef(false)
  const isCompanyFilterLoaded = useRef(false)
  const { setFilter, resetFilters, filters } = useContext(FiltersContext)
  const { user: loggedUser } = useContext(AuthContext)
  const { allCompanies, companies, sites } = useContext(ContentContext)
  const [paginationOffset, setPaginationOffset] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const active = filters[filterKey][Filter.active] as string[]
  const company = filters[filterKey][Filter.companies] as string[]
  const roles = filters[filterKey][Filter.roles] as string[]
  const tenants = filters[filterKey][Filter.tenant] as string[]
  const siteIds = filters[filterKey][Filter.sites] as string[]
  const searchText = filters[filterKey][Filter.searchText] as string
  const [isSearchReset, setIsSearchReset] = useState<boolean>(false)
  const [rowsPerPage, setRowsPerPage] = useState<number>(getPageSize)
  const [sortField, setSortField] = useState<number>(0)
  const [sortDirection, setSortDirection] = useState<string>('asc')
  const [availableCompanies, setAvailableCompanies] = useState<ICompany[]>([])
  const [availableSites, setAvailableSites] = useState<IWarehouse[]>([])
  const styles = useStyle()
  const history = useHistory()
  const { tablesConfig } = useContext(AppConfigContext)
  const { t } = useTranslation()
  const isAdmin = loggedUser?.roles.includes(USER_ROLES.superAdmin)

  useEffect(() => {
    if (!isAdmin) {
      return setAvailableCompanies(
        companies.filter((comp) => comp.tenantId === loggedUser?.tenantId),
      )
    }

    if (tenants.length === 0) {
      return setAvailableCompanies(allCompanies)
    }

    const filteredAvailableCompanies = allCompanies.filter(
      (comp) => comp.tenantId && tenants.includes(comp.tenantId),
    )
    if (company.length > 0) {
      const filteredAvailableCompaniesIds = filteredAvailableCompanies.map(
        (companyItem) => companyItem.id,
      )
      const filteredSelectedCompanies = company.filter((companyId) =>
        filteredAvailableCompaniesIds.includes(companyId),
      )
      setFilter(filterKey, Filter.companies, filteredSelectedCompanies)
    }
    return setAvailableCompanies(filteredAvailableCompanies)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenants, allCompanies, isAdmin, companies])

  useEffect(() => {
    if (!isAdmin || tenants.length === 0) {
      return setAvailableSites(sites)
    }
    const filteredAvailableSites = sites.filter(
      (site) => site.tenantId && tenants.includes(site.tenantId),
    )
    if (siteIds.length > 0) {
      const filteredAvailableSitesIds = filteredAvailableSites.map((site) => site.id)
      const filteredSelectedSites = siteIds.filter((siteId) =>
        filteredAvailableSitesIds.includes(siteId),
      )
      setFilter(filterKey, Filter.sites, filteredSelectedSites)
    }
    return setAvailableSites(filteredAvailableSites)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenants, isAdmin, sites])

  useEffect(() => {
    setFilter(filterKey, Filter.companies, [loggedUser?.companyId || ''])
    return () => {
      isCompanyFilterLoaded.current = false
      isSitesFilterLoaded.current = false
      setFilter(filterKey, Filter.companies, [])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleUpdateUsers = (withSortParams = false): void => {
    if (updateUsers) {
      updateUsers(
        { roles, company, active, searchText, tenants, siteIds },
        withSortParams ? paginationOffset : 0,
        rowsPerPage,
        withSortParams ? sortField : undefined,
        withSortParams ? sortDirection : undefined,
        isAdmin,
      )
      scrollTop()
    }
  }

  useEffect(() => {
    if (siteIds.length && !isSitesFilterLoaded.current) isSitesFilterLoaded.current = true
    if (company.length && !isCompanyFilterLoaded.current) isCompanyFilterLoaded.current = true
    if (!isSitesFilterLoaded.current || !isCompanyFilterLoaded.current) return
    if (rowsPerPage !== getPageSize()) {
      savePageSize(rowsPerPage)
    }
    handleUpdateUsers()
    resetPagination()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles, company, active, searchText, rowsPerPage, tenants, siteIds])

  useEffect(() => {
    if (!isSitesFilterLoaded.current || !isCompanyFilterLoaded.current) return
    handleUpdateUsers(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationOffset, sortField, sortDirection])

  const detailClicked = (user: IUser): void => {
    history.push({
      pathname: `${ROUTES_PATH.userDetails}${user.id}`,
      state: { user },
    })
  }

  const handleNewUserClick = (): void => {
    history.push({
      pathname: ROUTES_PATH.createUser,
    })
  }

  const handleChangeRowsPerPage = (pageSize: number): void => {
    setRowsPerPage(pageSize)
    resetPagination()
  }

  const resetPagination = (): void => {
    setPaginationOffset(0)
    setPage(0)
  }

  const resetLocalFilters = (): void => {
    setIsSearchReset(true)
    resetFilters(filterKey)
  }

  const Filters = useMemo(
    (): JSX.Element => (
      <TopFiltersContainer>
        <FiltersContainer>
          <IconButton data-cy="initializeButton" onClick={resetLocalFilters}>
            <RefreshIcon />
          </IconButton>
          {isAdmin && (
            <TenantFilter
              handleChange={(tenantValues: string[]): void => {
                setFilter(filterKey, Filter.tenant, tenantValues)
                setFilter(filterKey, Filter.companies, [])
              }}
              ids={tenants}
              sortBy="name"
              dataCy="tenantPicker"
            />
          )}
          <CompanyFilter
            data={availableCompanies}
            handleChange={(companyValues: string[]): void => {
              setFilter(filterKey, Filter.companies, companyValues)
            }}
            ids={company}
            sortBy="name"
            sortDirection={1}
            dataCy="companyPicker"
            filterKey={filterKey}
          />
          <SiteFilter
            dataCy="sitePicker"
            handleChange={(selectedSites: string[]): void => {
              setFilter(filterKey, Filter.sites, selectedSites)
            }}
            ids={siteIds}
            shouldUseUserSites={!loggedUser?.roles.includes(USER_ROLES.superAdmin)}
            placeholder={t('tablesEntries.site')}
            filterKey={filterKey}
            data={availableSites}
          />
          <RolesFilter
            handleChange={(roleValues: string[]): void => {
              setFilter(filterKey, Filter.roles, roleValues)
            }}
            ids={roles}
            dataCy="rolePicker"
          />
          <ActiveFilter
            handleChange={(activeValues: string[]): void => {
              setFilter(filterKey, Filter.active, activeValues)
            }}
            ids={active}
            dataCy="activePicker"
          />
        </FiltersContainer>
        <SearchContainer>
          <SearchInput
            defaultValue={searchText}
            key={isSearchReset ? 'search-reset' : ''}
            onSearch={(text: string): void => {
              setIsSearchReset(false)
              setFilter(filterKey, Filter.searchText, text)
            }}
          />
          <Button dataCy="newButton" className={styles.newButton} onPress={handleNewUserClick}>
            {t('tablesEntries.new')}
          </Button>
        </SearchContainer>
      </TopFiltersContainer>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [roles, company, availableCompanies, active, searchText, styles, tenants, siteIds],
  )

  const handleOrderChange = (columnIndex: number): void => {
    setPage(0)
    setPaginationOffset(0)
    setSortField(columnIndex)
    setSortDirection((prevSortDirection) => (prevSortDirection === 'asc' ? 'desc' : 'asc'))
  }

  const displayRowsCount = useMemo(() => getDisplayRowsCount(users), [users])

  return (
    <>
      {Filters}
      <MaterialTable
        key={displayRowsCount}
        data={users}
        columns={columns.map(
          (col) =>
            ({
              ...col,
              hidden: tablesConfig[columnConfigName]?.includes(col.title),
            } as unknown as MaterialTable<object>),
        )}
        {...rest}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        options={{
          toolbar: false,
          pageSize: displayRowsCount,
          actionsColumnIndex: -1,
        }}
        actions={[
          (data: IUser): Action<IUser> => ({
            icon: ArrowForward,
            tooltip: t('tablesEntries.seeDetails'),
            onClick: (): void => detailClicked(data),
          }),
        ]}
        icons={MUITableIcons}
        components={{
          // eslint-disable-next-line react/prop-types, @typescript-eslint/no-unused-vars
          Pagination: ({ classes, ...props }): JSX.Element => (
            <Pagination
              {...props}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              columns={columns}
              columnConfigName={columnConfigName}
              page={page}
              count={count}
              setPaginationOffset={setPaginationOffset}
            />
          ),
          Header: (props): JSX.Element => (
            <MTableHeader
              {...props}
              onOrderChange={handleOrderChange}
              orderBy={sortField}
              orderDirection={sortDirection}
            />
          ),
        }}
      />
    </>
  )
}

export default (props: ICustomTableProps): JSX.Element => (
  <AppConfigProvider>
    <UsersConsumer>
      {(ctx): JSX.Element => (
        <CustomTable count={ctx.count} updateUsers={ctx.updateUsers} {...props} />
      )}
    </UsersConsumer>
  </AppConfigProvider>
)
