import React from 'react'
import FolderIcon from '@material-ui/icons/Folder'
import FolderOpenIcon from '@material-ui/icons/FolderOpen'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined'
import ShareIcon from '@material-ui/icons/Share'
import DateRangeIcon from '@material-ui/icons/DateRange'
import DepartureBoardIcon from '@material-ui/icons/DepartureBoard'
import ShowChartIcon from '@material-ui/icons/ShowChart'
import PersonIcon from '@material-ui/icons/Person'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import CommuteIcon from '@material-ui/icons/Commute'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import StoreIcon from '@material-ui/icons/Store'
import EditLocationIcon from '@material-ui/icons/EditLocation'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import PeopleIcon from '@material-ui/icons/People'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import StarIcon from '@material-ui/icons/Star'
import VisibilityIcon from '@material-ui/icons/Visibility'
import MapIcon from '@material-ui/icons/Map'
import SettingsIcon from '@material-ui/icons/Settings'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import BallotIcon from '@material-ui/icons/Ballot'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import HomeIcon from '@material-ui/icons/Home'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import i18n from 'i18next'
import { Comment } from '@material-ui/icons'

import {
  OptimizationEngine,
  OptimizationItineraryCalculatorType,
  TypeOfSite,
  TypeOfTour,
} from 'interfaces/interfaces'
import LogisticShiftScreen from 'screens/SettingsCategory/Configurations/LogisticShiftScreen'
import { ITab } from 'interfaces/ITab'
import OrderHistoryIndicator from 'screens/ReportingCategory/OrderReporting/OrderHistoryIndicator/OrderHistoryIndicator'
import OrderIndicator from 'screens/ReportingCategory/OrderReporting/OrderIndicator'
import TransportVolumeIndicator from 'screens/ReportingCategory/ExecutionReporting/TransportVolumeIndicator'
import TourProgressIndicator from 'screens/ReportingCategory/ExecutionReporting/TourProgressIndicator'
import PunctualityIndicator from 'screens/ReportingCategory/ExecutionReporting/PunctualityIndicator'
// eslint-disable-next-line max-len
import PlanningMonitoringIndicator from 'screens/ReportingCategory/PlanningReporting/PlanningMonitoringIndicator/PlanningMonitoringIndicator'
import MobileAppIndicator from 'screens/ReportingCategory/ExecutionReporting/MobileAppIndicator'
import MarketShareIndicator from 'screens/ReportingCategory/ExecutionReporting/MarketShareIndicator'
import DeliveryTypesScreen from 'screens/SettingsCategory/Configurations/DeliveryTypesScreen'
import { NotificationType, SmsCommunicationService } from 'interfaces/INotifierConfiguration'
import DeliveryDistanceIndicator from 'screens/ReportingCategory/OrderReporting/DeliveryDistanceIndicator'
import DisputeReasonsScreen from 'screens/SettingsCategory/Configurations/DisputeReasonsScreen'
import CancellationReasonsScreen from 'screens/SettingsCategory/Configurations/CancellationReasonsScreens'
// eslint-disable-next-line max-len
import TourCancellationsIndicator from 'screens/ReportingCategory/ExecutionReporting/CancellationsIndicator/TourCancellationsIndicator'
// eslint-disable-next-line max-len
import StopCancellationsIndicator from 'screens/ReportingCategory/ExecutionReporting/CancellationsIndicator/StopCancellationsIndicator'
import TagsScreen from 'screens/SettingsCategory/Configurations/TagsScreen'
// eslint-disable-next-line max-len
import DeliveryScheduleChangesIndicator from 'screens/ReportingCategory/OrderReporting/DeliveryScheduleChangesIndicator/DeliveryScheduleChangesIndicator'
import CartDropoffConfigScreen from 'screens/SettingsCategory/SiteConfigurations/CartDropoff/CartDropoffConfigScreen'
import { IStep } from 'interfaces/IStep'
import { IOrderRequiredField } from 'interfaces/IOrders'

/**
 * Roles availables
 */
export const USER_ROLES = {
  pilot: 'PILOT',
  driver: 'DRIVER',
  carrier: 'CARRIER',
  superAdmin: 'SUPER_ADMIN',
  udcAdmin: 'UDCAdmin',
  udcExploitant: 'UDCExploitant',
  store: 'STORE',
  cashier: 'CASHIER',
}

export enum Role {
  Pilot = 'PILOT',
  Carrier = 'CARRIER',
  UdcAdmin = 'UDCAdmin',
  UdcExploitant = 'UDCExploitant',
  Driver = 'DRIVER',
  SuperAdmin = 'SUPER_ADMIN',
  Store = 'STORE',
  Cashier = 'CASHIER',
}

export const AUTHORIZED_ROLES = [Role.Pilot, Role.Carrier, Role.Store, Role.Cashier]

export const ROLES_WITH_ASSOCIATED_SITES = [
  Role.UdcAdmin,
  Role.UdcExploitant,
  Role.Pilot,
  Role.Carrier,
  Role.SuperAdmin,
  Role.Store,
  Role.Cashier,
]

export const PILOT_AND_STORE_USER_ROLES = [USER_ROLES.pilot, USER_ROLES.store]

export const USER_ROLES_WITH_ORDER_MODULE = [
  ...PILOT_AND_STORE_USER_ROLES,
  Role.SuperAdmin,
  Role.Cashier,
]

export const DELIVERY_ROLES = [
  { id: Role.Pilot, name: i18n.t('tablesEntries.rolesCategory.pilot') },
  { id: Role.Carrier, name: i18n.t('tablesEntries.rolesCategory.carrier') },
  { id: Role.Store, name: i18n.t('tablesEntries.rolesCategory.store') },
  { id: Role.Cashier, name: i18n.t('tablesEntries.rolesCategory.cashier') },
]

export const UDC_ROLES = [
  { id: Role.UdcAdmin, name: i18n.t('tablesEntries.rolesCategory.logisticsManager') },
  { id: Role.UdcExploitant, name: i18n.t('tablesEntries.rolesCategory.logisticsOperator') },
]

export const ROLES_USER_OPTIONS = [
  { id: USER_ROLES.pilot, name: i18n.t('UsersScreen.pilot') },
  { id: USER_ROLES.driver, name: i18n.t('UsersScreen.driver') },
  { id: USER_ROLES.carrier, name: i18n.t('UsersScreen.carrier') },
  { id: USER_ROLES.udcAdmin, name: i18n.t('UsersScreen.logisticsManager') },
  { id: USER_ROLES.udcExploitant, name: i18n.t('UsersScreen.logisticsOperator') },
  { id: USER_ROLES.superAdmin, name: i18n.t('UsersScreen.superAdmin') },
  { id: USER_ROLES.store, name: i18n.t('UsersScreen.store') },
  { id: USER_ROLES.cashier, name: i18n.t('UsersScreen.cashier') },
]

export const ROLES_USER_FILTER_OPTIONS = [
  { id: Role.Pilot, name: i18n.t('tablesEntries.rolesCategory.pilot') },
  { id: Role.Driver, name: i18n.t('tablesEntries.rolesCategory.driver') },
  { id: Role.Carrier, name: i18n.t('tablesEntries.rolesCategory.carrier') },
  { id: Role.UdcAdmin, name: i18n.t('tablesEntries.rolesCategory.logisticsManager') },
  { id: Role.UdcExploitant, name: i18n.t('tablesEntries.rolesCategory.logisticsOperator') },
  { id: Role.SuperAdmin, name: i18n.t('tablesEntries.rolesCategory.superAdmin') },
  { id: Role.Store, name: i18n.t('tablesEntries.rolesCategory.store') },
  { id: Role.Cashier, name: i18n.t('tablesEntries.rolesCategory.cashier') },
]

export const MENU_CATEGORIES = {
  order: i18n.t('menu.categories.order'),
  resources: i18n.t('menu.categories.resources'),
  network: i18n.t('menu.categories.network'),
  planning: i18n.t('menu.categories.planning'),
  cost: i18n.t('menu.categories.cost'),
  execution: i18n.t('menu.categories.execution'),
  reporting: i18n.t('menu.categories.reporting'),
  setting: i18n.t('menu.categories.setting'),
}

export const getCategoryIcon = (key: string, selected: boolean): JSX.Element => {
  switch (key) {
    case MENU_CATEGORIES.order:
      return <FolderIcon color={selected ? 'primary' : 'secondary'} />
    case MENU_CATEGORIES.resources:
      return <LocalShippingIcon color={selected ? 'primary' : 'secondary'} />
    case MENU_CATEGORIES.network:
      return <ShareIcon color={selected ? 'primary' : 'secondary'} />
    case MENU_CATEGORIES.planning:
      return <DateRangeIcon color={selected ? 'primary' : 'secondary'} />
    case MENU_CATEGORIES.cost:
      return <AccountBalanceWalletIcon color={selected ? 'primary' : 'secondary'} />
    case MENU_CATEGORIES.execution:
      return <DepartureBoardIcon color={selected ? 'primary' : 'secondary'} />
    case MENU_CATEGORIES.setting:
      return <SettingsIcon color={selected ? 'primary' : 'secondary'} />
    default:
      return <ShowChartIcon color={selected ? 'primary' : 'secondary'} />
  }
}

export const MENU_SUBCATEGORIES = {
  list: i18n.t('menu.subcategories.list'),
  orderDetails: i18n.t('menu.subcategories.orderDetails'),
  orderBooking: i18n.t('menu.subcategories.booking'),
  createOrderFromMenu: i18n.t('menu.subcategories.newOrder'),
  cartDropoff: i18n.t('menu.subcategories.cartDropoff'),
  visit: i18n.t('menu.subcategories.visit'),
  driver: i18n.t('menu.subcategories.drivers'),
  vehicle: i18n.t('menu.subcategories.vehicles'),
  team: i18n.t('menu.subcategories.team'),
  fleet: i18n.t('menu.subcategories.fleet'),
  vehicleCategory: i18n.t('menu.subcategories.vehicle_categories'),
  thirdParty: i18n.t('menu.subcategories.thirdParty'),
  site: i18n.t('menu.subcategories.sites'),
  address: i18n.t('menu.subcategories.addresses'),
  planification: i18n.t('menu.subcategories.planification'),
  multisitePlanning: i18n.t('menu.subcategories.multisitePlanning'),
  driverAssignment: i18n.t('menu.subcategories.driverAssignment'),
  toursMonitoring: i18n.t('menu.subcategories.tours'),
  mapMonitoring: i18n.t('menu.subcategories.map'),
  replay: i18n.t('menu.subcategories.replay'),
  reporting: i18n.t('menu.subcategories.reporting'),
  reportingOrders: i18n.t('menu.subcategories.orders'),
  reportingExecution: i18n.t('menu.subcategories.execution'),
  reportingPlanning: i18n.t('menu.subcategories.planning'),
  reportingSites: i18n.t('menu.subcategories.sites'),
  reportingSlots: i18n.t('menu.subcategories.slots'),
  reportingCustomerRating: i18n.t('menu.subcategories.customerRating'),
  reportingCustomerComments: i18n.t('menu.subcategories.customerComments'),
  carriers: i18n.t('menu.subcategories.carriers'),
  carrierDetail: i18n.t('menu.subcategories.carrierDetail'),
  carrierEdition: i18n.t('menu.subcategories.carrierEdition'),
  user: i18n.t('menu.subcategories.user'),
  userEdition: i18n.t('menu.subcategories.userEdition'),
  userDetails: i18n.t('menu.subcategories.userDetails'),
  tenants: i18n.t('menu.subcategories.tenants'),
  trailers: i18n.t('menu.subcategories.trailers'),
  featureFlag: i18n.t('menu.subcategories.featureFlag'),
  planTemplates: i18n.t('menu.subcategories.planTemplates'),
  configurations: i18n.t('menu.subcategories.configurations'),
  siteConfigurations: i18n.t('menu.subcategories.siteConfigurations'),
  planningOrders: i18n.t('menu.subcategories.planningOrders'),
  geocodingOrders: i18n.t('menu.subcategories.geocodingOrders'),
  webhooks: i18n.t('menu.subcategories.webhooks'),
  routingRules: i18n.t('menu.subcategories.routingRules'),
  tourList: i18n.t('menu.subcategories.tourList'),
  importHistory: i18n.t('menu.subcategories.importHistory'),
  sectors: i18n.t('menu.subcategories.sectors'),
  warehouseAdmin: i18n.t('menu.subcategories.warehouseAdmin'),
  notifierConfig: i18n.t('menu.subcategories.notifierConfig'),
  orders: i18n.t('menu.subcategories.orders'),
  warehouseTimeSlot: i18n.t('menu.subcategories.warehouseTimeSlot'),
  disputes: i18n.t('menu.subcategories.disputes'),
  apikeys: i18n.t('menu.subcategories.apikeys'),
}

export const getSubCategoryIcon = (key: string, selected: boolean): JSX.Element => {
  switch (key) {
    case MENU_SUBCATEGORIES.list:
      return <FolderOpenIcon color={selected ? 'primary' : 'secondary'} />
    case MENU_SUBCATEGORIES.visit:
      return <FolderOpenIcon color={selected ? 'primary' : 'secondary'} />
    case MENU_SUBCATEGORIES.driver:
      return <PersonIcon color={selected ? 'primary' : 'secondary'} />
    case MENU_SUBCATEGORIES.vehicle:
      return <LocalShippingIcon color={selected ? 'primary' : 'secondary'} />
    case MENU_SUBCATEGORIES.tourList:
      return <FolderOpenIcon color={selected ? 'primary' : 'secondary'} />
    case MENU_SUBCATEGORIES.team:
      return <SupervisorAccountIcon color={selected ? 'primary' : 'secondary'} />
    case MENU_SUBCATEGORIES.fleet:
      return <CommuteIcon color={selected ? 'primary' : 'secondary'} />
    case MENU_SUBCATEGORIES.vehicleCategory:
      return <LocalShippingOutlinedIcon color={selected ? 'primary' : 'secondary'} />
    case MENU_SUBCATEGORIES.thirdParty:
      return <AccountBoxIcon color={selected ? 'primary' : 'secondary'} />
    case MENU_SUBCATEGORIES.site:
      return <StoreIcon color={selected ? 'primary' : 'secondary'} />
    case MENU_SUBCATEGORIES.address:
      return <EditLocationIcon color={selected ? 'primary' : 'secondary'} />
    case MENU_SUBCATEGORIES.planification:
      return <DateRangeIcon color={selected ? 'primary' : 'secondary'} />
    case MENU_SUBCATEGORIES.multisitePlanning:
      return <DateRangeIcon color={selected ? 'primary' : 'secondary'} />
    case MENU_SUBCATEGORIES.driverAssignment:
      return <AssignmentIndIcon color={selected ? 'primary' : 'secondary'} />
    case MENU_SUBCATEGORIES.toursMonitoring:
      return <VisibilityIcon color={selected ? 'primary' : 'secondary'} />
    case MENU_SUBCATEGORIES.mapMonitoring:
      return <MapIcon color={selected ? 'primary' : 'secondary'} />
    case MENU_SUBCATEGORIES.replay:
      return <MapIcon color={selected ? 'primary' : 'secondary'} />
    case MENU_SUBCATEGORIES.carriers:
      return <LocalShippingIcon color={selected ? 'primary' : 'secondary'} />
    case MENU_SUBCATEGORIES.user:
      return <PeopleIcon color={selected ? 'primary' : 'secondary'} />
    case MENU_SUBCATEGORIES.tenants:
      return <PeopleIcon color={selected ? 'primary' : 'secondary'} />
    case MENU_SUBCATEGORIES.trailers:
      return <LocalShippingIcon color={selected ? 'primary' : 'secondary'} />
    case MENU_SUBCATEGORIES.reportingOrders:
      return <ShoppingCartIcon color={selected ? 'primary' : 'secondary'} />
    case MENU_SUBCATEGORIES.reportingCustomerRating:
      return <StarIcon color={selected ? 'primary' : 'secondary'} />
    case MENU_SUBCATEGORIES.reportingCustomerComments:
      return <Comment color={selected ? 'primary' : 'secondary'} />
    case MENU_SUBCATEGORIES.planTemplates:
      return <SettingsIcon color={selected ? 'primary' : 'secondary'} />
    case MENU_SUBCATEGORIES.geocodingOrders:
      return <LocationOnIcon color={selected ? 'primary' : 'secondary'} />
    case MENU_SUBCATEGORIES.configurations:
      return <SettingsIcon color={selected ? 'primary' : 'secondary'} />
    case MENU_SUBCATEGORIES.siteConfigurations:
      return <SettingsIcon color={selected ? 'primary' : 'secondary'} />
    case MENU_SUBCATEGORIES.sectors:
      return <StoreIcon color={selected ? 'primary' : 'secondary'} />
    case MENU_SUBCATEGORIES.warehouseAdmin:
      return <StoreIcon color={selected ? 'primary' : 'secondary'} />
    case MENU_SUBCATEGORIES.notifierConfig:
      return <SettingsIcon color={selected ? 'primary' : 'secondary'} />
    case MENU_SUBCATEGORIES.warehouseTimeSlot:
    case MENU_SUBCATEGORIES.reportingSlots:
      return <BallotIcon color={selected ? 'primary' : 'secondary'} />
    case MENU_SUBCATEGORIES.createOrderFromMenu:
      return <AddCircleIcon color={selected ? 'primary' : 'secondary'} />
    case MENU_SUBCATEGORIES.disputes:
      return <FolderOpenIcon color={selected ? 'primary' : 'secondary'} />
    case MENU_SUBCATEGORIES.cartDropoff:
      return <ShoppingCartIcon color={selected ? 'primary' : 'secondary'} />
    default:
      return <ShowChartIcon color={selected ? 'primary' : 'secondary'} />
  }
}

/**
 * Left navigation drawer width when opened
 */
export const DRAWER_WIDTH = 240

export const VEHICLE_CATEGORIES = [
  { id: '0', name: i18n.t('tablesEntries.vehicleCategory.van') },
  { id: '1', name: i18n.t('tablesEntries.vehicleCategory.pl') },
]

export const WAREHOUSE_TYPES = [
  { id: '0', name: i18n.t('tablesEntries.typeOfSite.warehouse') },
  { id: '1', name: i18n.t('tablesEntries.typeOfSite.hubspot') },
  { id: '2', name: i18n.t('tablesEntries.typeOfSite.store') },
  { id: '3', name: i18n.t('tablesEntries.typeOfSite.drive') },
]

export const WAREHOUSE_TOUR_TYPES = [
  { id: 'Global', name: i18n.t('tablesEntries.typeOfSiteTour.global') },
  { id: 'Sector', name: i18n.t('tablesEntries.typeOfSiteTour.sector') },
]

export const DELIVERY_TYPES_MAP = new Map([
  [0, i18n.t('tablesEntries.deliveryType.0')],
  [1, i18n.t('tablesEntries.deliveryType.1')],
  [2, i18n.t('tablesEntries.deliveryType.2')],
])

export const TOUR_STATUS = [
  { id: '0', name: i18n.t('tablesEntries.status.planified') },
  { id: '1', name: i18n.t('tablesEntries.status.preparing') },
  { id: '2', name: i18n.t('tablesEntries.status.prepared') },
  { id: '3', name: i18n.t('tablesEntries.status.loading') },
  { id: '4', name: i18n.t('tablesEntries.status.loaded') },
  { id: '5', name: i18n.t('tablesEntries.status.going') },
  { id: '6', name: i18n.t('tablesEntries.status.finished') },
  { id: '7', name: i18n.t('tablesEntries.status.dropped') },
  { id: '8', name: i18n.t('tablesEntries.status.canceled') },
  { id: '9', name: i18n.t('tablesEntries.status.refused') },
]

export const TOUR_STATUS_MAP = new Map([
  [0, i18n.t('tablesEntries.status.planified')],
  [1, i18n.t('tablesEntries.status.preparing')],
  [2, i18n.t('tablesEntries.status.prepared')],
  [3, i18n.t('tablesEntries.status.loading')],
  [4, i18n.t('tablesEntries.status.loaded')],
  [5, i18n.t('tablesEntries.status.going')],
  [6, i18n.t('tablesEntries.status.finished')],
  [7, i18n.t('tablesEntries.status.dropped')],
  [8, i18n.t('tablesEntries.status.canceled')],
  [9, i18n.t('tablesEntries.status.refused')],
])

export enum TOUR_STATUS_ENUM {
  PLANIFIED,
  PREPARING,
  PREPARED,
  LOADING,
  LOADED,
  GOING,
  FINISHED,
  DROPPED,
  CANCELED,
  REFUSED,
}

export const PLAN_TOUR_STATUS = [
  { id: '0', name: i18n.t('tablesEntries.planTourStatus.planned') },
  { id: '1', name: i18n.t('tablesEntries.planTourStatus.validated') },
  { id: '3', name: i18n.t('tablesEntries.planTourStatus.interfaced') },
]

export const PLAN_TOUR_STATUS_MAP = new Map([
  [0, i18n.t('tablesEntries.planTourStatus.planned')],
  [1, i18n.t('tablesEntries.planTourStatus.validated')],
  [3, i18n.t('tablesEntries.planTourStatus.interfaced')],
])

export enum PLAN_TOUR_STATUS_ENUM {
  PLANNED,
  VALIDATED,
  LOCKED,
  INTERFACED,
}

export const STOP_STATUS_MAP = new Map([
  [0, i18n.t('tablesEntries.status.planified')],
  [1, i18n.t('tablesEntries.status.loaded')],
  [2, i18n.t('tablesEntries.status.onSite')],
  [3, i18n.t('tablesEntries.status.delivered')],
  [4, i18n.t('tablesEntries.status.dropped')],
  [5, i18n.t('tablesEntries.status.deliverAgain')],
  [6, i18n.t('tablesEntries.status.canceled')],
])

export enum STOP_STATUS_ENUM {
  Planned,
  Loaded,
  OnSite,
  Delivered,
  Abandoned,
  Postponed,
  Canceled,
}

export const CARRIER_STATUS = [
  { id: '1', name: i18n.t('tablesEntries.status.active') },
  { id: '0', name: i18n.t('tablesEntries.status.notActive') },
]

export const SITE_SECTOR = [
  { id: 'true', name: i18n.t('tablesEntries.status.hasSector') },
  { id: 'false', name: i18n.t('tablesEntries.status.hasNoSector') },
]

export enum KlareoProduct {
  DeliveryPackage = 'deliveryPackage',
  UdcPackage = 'udcPackage',
}

export const KLAREO_PRODUCTS = [
  { id: KlareoProduct.DeliveryPackage, name: KlareoProduct.DeliveryPackage },
  { id: KlareoProduct.UdcPackage, name: KlareoProduct.UdcPackage },
]

export enum TenantLanguage {
  En = 'en',
  Fr = 'fr',
  De = 'de',
}

export const TENANT_LANGUAGE_OPTIONS = [
  { id: TenantLanguage.En, name: TenantLanguage.En },
  { id: TenantLanguage.Fr, name: TenantLanguage.Fr },
  { id: TenantLanguage.De, name: TenantLanguage.De },
]

export enum AVAILABLE_FILTERS {
  STATUS,
  VEHICLE_TYPES,
  DATE_RANGE,
  CARRIERS,
  ROLES,
  DELIVERY_TYPES,
  SITES,
}

export const SITE_TYPE_OPTIONS = [
  { value: TypeOfSite.Warehouse, label: i18n.t('SitesScreen.type.0') },
  { value: TypeOfSite.Hubspot, label: i18n.t('SitesScreen.type.1') },
  { value: TypeOfSite.Store, label: i18n.t('SitesScreen.type.2') },
  { value: TypeOfSite.Drive, label: i18n.t('SitesScreen.type.3') },
]

export const SITE_TOUR_TYPE_OPTIONS = [
  { value: '-', label: i18n.t('SitesScreen.tourType.null') },
  { value: TypeOfTour.Global, label: i18n.t('SitesScreen.tourType.Global') },
  { value: TypeOfTour.Sector, label: i18n.t('SitesScreen.tourType.Sector') },
]

export const OPTIMIZATION_ENGINE_OPTIONS = [
  {
    id: OptimizationEngine.Atoptima,
    name: OptimizationEngine.Atoptima,
  },
  {
    id: OptimizationEngine.Kvrp,
    name: OptimizationEngine.Kvrp,
  },
]

export const OPTIMIZATION_ITINERARY_CALCULATOR_OPTIONS = [
  {
    id: OptimizationItineraryCalculatorType.WORLD,
    name: i18n.t('SitesScreen.optimParam.optimizationItineraryCalculatorType.world'),
  },
  {
    id: OptimizationItineraryCalculatorType.EUROPE,
    name: i18n.t('SitesScreen.optimParam.optimizationItineraryCalculatorType.europe'),
  },
]

/**
 * Refresh rate of the monitoring screens, in ms
 */
export const AUTO_RELOAD_TIMER = 30000 // 30s

/**
 * Treshold to know if the driver is on time or late, in min
 */
export const DRIVER_ON_TIME = 15

export const ROWS_PER_PAGE_OPTIONS = [10, 12, 15, 20, 50]

export const MAX_NUMBER_OF_ITEMS_BY_REQUEST = 50

export enum DELAY_INDICATORS {
  lessThanAcceptableDelayCount = 'lessThanAcceptableDelayCount',
  greaterThanAcceptableDelayCount = 'greaterThanAcceptableDelayCount',
  betweenAcceptableDelayBoundsCount = 'betweenAcceptableDelayBoundsCount',
  totalCount = 'totalCount',
}

export const NUMBER_OF_TENANTS_BY_REQUEST = 13

export const DAYCODES_OF_WEEK = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA']

export const DAYS_OF_WEEK = [
  { code: DAYCODES_OF_WEEK[1], label: i18n.t('scheduler.monday') },
  { code: DAYCODES_OF_WEEK[2], label: i18n.t('scheduler.tuesday') },
  { code: DAYCODES_OF_WEEK[3], label: i18n.t('scheduler.wednesday') },
  { code: DAYCODES_OF_WEEK[4], label: i18n.t('scheduler.thursday') },
  { code: DAYCODES_OF_WEEK[5], label: i18n.t('scheduler.friday') },
  { code: DAYCODES_OF_WEEK[6], label: i18n.t('scheduler.saturday') },
  { code: DAYCODES_OF_WEEK[0], label: i18n.t('scheduler.sunday') },
]

export const MAX_DELAY = 60

export const DEFAULT_ACCEPTABLE_ADVANCE = 15
export const DEFAULT_ACCEPTABLE_DELAY = 15

export const DEFAULT_PLANNING_ZOOM_PINS = 13

export const TOUR_STATUS_CHART_COLORS = [
  '#DCDCDC',
  '#D3D3D3',
  '#C0C0C0',
  '#A9A9A9',
  '#808080',
  '#25262C',
  '#FF8A00',
  '#ff0000',
]

export const DAILY_TOUR_CHART_MAX_ARGUMENT_COUNT = 20

export const CONFIGURATIONS_MENU: ITab[] = [
  {
    label: i18n.t('ConfigurationsScreen.disputeReasonCodes'),
    component: <DisputeReasonsScreen />,
    isBetaMode: true,
    featureFlag: '/configuration/dispute-reasons',
    roles: [USER_ROLES.pilot],
  },
  {
    label: i18n.t('ConfigurationsScreen.shift'),
    component: <LogisticShiftScreen />,
    roles: [USER_ROLES.pilot],
  },
  {
    label: i18n.t('ConfigurationsScreen.deliveryTypes'),
    component: <DeliveryTypesScreen />,
    roles: [USER_ROLES.superAdmin],
  },
  {
    label: i18n.t('ConfigurationsScreen.cancellationReasons'),
    component: <CancellationReasonsScreen />,
    roles: [USER_ROLES.pilot],
  },
  {
    label: i18n.t('ConfigurationsScreen.tags'),
    component: <TagsScreen />,
    roles: [USER_ROLES.pilot, USER_ROLES.superAdmin],
  },
]

export const SITE_CONFIGURATIONS_MENU: ITab[] = [
  {
    label: i18n.t('menu.subcategories.cartDropoff'),
    component: <CartDropoffConfigScreen />,
    isBetaMode: true,
    featureFlag: '/order/cart-dropoff',
  },
]

export const ORDER_REPORTING_MENU: ITab[] = [
  {
    label: i18n.t('menu.subcategories.orders'),
    isBetaMode: true,
    featureFlag: '/reporting/orders',
    component: <OrderIndicator />,
  },
  { label: i18n.t('menu.subcategories.ordersHistory'), component: <OrderHistoryIndicator /> },
  {
    label: i18n.t('menu.subcategories.deliveryDistance'),
    component: <DeliveryDistanceIndicator />,
  },
  {
    label: i18n.t('menu.subcategories.deliveryScheduleChanges'),
    component: <DeliveryScheduleChangesIndicator />,
  },
]

export const EXECUTION_REPORTING_MENU: ITab[] = [
  {
    label: i18n.t('menu.subcategories.tourStats'),
    isBetaMode: true,
    featureFlag: '/reporting/tour-stats',
    component: <TourProgressIndicator />,
  },
  {
    label: i18n.t('menu.subcategories.respectedDeliveryTimeWindow'),
    isBetaMode: true,
    featureFlag: '/delay-indicators',
    component: <PunctualityIndicator />,
  },
  {
    label: i18n.t('menu.subcategories.transportVolume'),
    isBetaMode: true,
    featureFlag: '/volume-indicators',
    component: <TransportVolumeIndicator />,
  },
  { label: i18n.t('menu.subcategories.mobileAppMonitoring'), component: <MobileAppIndicator /> },
  { label: i18n.t('menu.subcategories.marketShare'), component: <MarketShareIndicator /> },
  {
    label: i18n.t('menu.subcategories.tourCancellations'),
    component: <TourCancellationsIndicator />,
  },
  {
    label: i18n.t('menu.subcategories.stopCancellations'),
    component: <StopCancellationsIndicator />,
  },
]

export const PLANNING_REPORTING_MENU: ITab[] = [
  {
    label: i18n.t('menu.subcategories.planningMonitoring'),
    featureFlag: '/reporting/planning/orders',
    component: <PlanningMonitoringIndicator />,
  },
]

export const DEFAULT_VEHICLE_TYPE_MAX_CLIENTS_COUNT = 100

export enum RuleType {
  Site = 'siteReference',
  ZipCode = 'zipCode',
  SiteArea = 'siteArea',
  Sector = 'sector',
}

enum PerimeterMode {
  Flight = 'flight',
  Car = 'car',
  Cycle = 'cycle',
  Pedestrian = 'pedestrian',
}

export const ROUTING_RULE_TYPES = [
  { value: RuleType.Site, label: i18n.t('RoutingRulesScreen.ruleTypes.site') },
  { value: RuleType.ZipCode, label: i18n.t('RoutingRulesScreen.ruleTypes.zipCode') },
  { value: RuleType.SiteArea, label: i18n.t('RoutingRulesScreen.ruleTypes.siteArea') },
  { value: RuleType.Sector, label: i18n.t('RoutingRulesScreen.ruleTypes.sector') },
]

export const PERIMETER_MODES = [
  { value: PerimeterMode.Flight, label: i18n.t('RoutingRulesScreen.perimeterModes.flight') },
  { value: PerimeterMode.Car, label: i18n.t('RoutingRulesScreen.perimeterModes.car') },
  { value: PerimeterMode.Cycle, label: i18n.t('RoutingRulesScreen.perimeterModes.cycle') },
  {
    value: PerimeterMode.Pedestrian,
    label: i18n.t('RoutingRulesScreen.perimeterModes.pedestrian'),
  },
]

export interface ICustomSelector {
  id: number
  label: string
}

export enum OrderAxis {
  Order,
  Bac,
}

export const ORDER_CHART_VALUE_AXIS = [
  { id: OrderAxis.Order, label: i18n.t('OrderStatsScreen.order') },
  { id: OrderAxis.Bac, label: i18n.t('OrderStatsScreen.parcel') },
]

export enum OrderGroupBy {
  DeliveryTypes,
  Status,
}

export const ORDER_CHART_VALUE_GROUP_BY = [
  { id: OrderGroupBy.DeliveryTypes, label: i18n.t('OrderStatsScreen.deliveryType') },
  { id: OrderGroupBy.Status, label: i18n.t('OrderStatsScreen.status') },
]

export const WEBHOOK_EVENTS = [
  { id: 'onPlanTourInterface', name: i18n.t('WebhooksScreen.events.onPlanTourInterface') },
  { id: 'onTourUpdated', name: i18n.t('WebhooksScreen.events.onTourUpdated') },
  { id: 'onTourFinished', name: i18n.t('WebhooksScreen.events.onTourFinished') },
  { id: 'onTourAbandoned', name: i18n.t('WebhooksScreen.events.onTourAbandoned') },
  { id: 'onTourDeleted', name: i18n.t('WebhooksScreen.events.onTourDeleted') },
  { id: 'onHandoutEnd', name: i18n.t('WebhooksScreen.events.onHandoutEnd') },
  { id: 'onOrderCreated', name: i18n.t('WebhooksScreen.events.onOrderCreated') },
  { id: 'onTourCreated', name: i18n.t('WebhooksScreen.events.onTourCreated') },
  { id: 'onTourCarrierRemoved', name: i18n.t('WebhooksScreen.events.onTourCarrierRemoved') },
  { id: 'onTourCarrierAssigned', name: i18n.t('WebhooksScreen.events.onTourCarrierAssigned') },
  { id: 'onStopAbandoned', name: i18n.t('WebhooksScreen.events.onStopAbandoned') },
  { id: 'onStopCanceled', name: i18n.t('WebhooksScreen.events.onStopCanceled') },
  { id: 'onCarrierCreated', name: i18n.t('WebhooksScreen.events.onCarrierCreated') },
  { id: 'onCarrierUpdated', name: i18n.t('WebhooksScreen.events.onCarrierUpdated') },
  { id: 'onOrderStatusUpdated', name: i18n.t('WebhooksScreen.events.onOrderStatusUpdated') },
  { id: 'onTourVisitsUpdated', name: i18n.t('WebhooksScreen.events.onTourVisitsUpdated') },
]

export const RATING_GRADES = [
  { id: '1', label: '1' },
  { id: '2', label: '2' },
  { id: '3', label: '3' },
  { id: '4', label: '4' },
  { id: '5', label: '5' },
]

export enum DateType {
  Date,
  Time,
  DateTime,
}

export enum OrderPlanStatus {
  Unplanned = 0,
  PartiallyPlanned = 1,
  Planned = 2,
  Validated = 3,
  Interfaced = 4,
  Canceled = 5,
  Anomaly = 6,
  ToBook = 7,
  Draft = 8,
}

export const ORDER_PLAN_STATUSES = [
  { id: OrderPlanStatus.Unplanned.toString(), name: i18n.t('OrdersScreen.planStatus.unplanned') },
  {
    id: OrderPlanStatus.PartiallyPlanned.toString(),
    name: i18n.t('OrdersScreen.planStatus.partiallyPlanned'),
  },
  { id: OrderPlanStatus.Planned.toString(), name: i18n.t('OrdersScreen.planStatus.planned') },
  { id: OrderPlanStatus.Validated.toString(), name: i18n.t('OrdersScreen.planStatus.validated') },
  { id: OrderPlanStatus.Interfaced.toString(), name: i18n.t('OrdersScreen.planStatus.interfaced') },
  { id: OrderPlanStatus.Canceled.toString(), name: i18n.t('OrdersScreen.planStatus.canceled') },
  { id: OrderPlanStatus.ToBook.toString(), name: i18n.t('OrdersScreen.planStatus.toBook') },
  { id: OrderPlanStatus.Anomaly.toString(), name: i18n.t('OrdersScreen.planStatus.anomaly') },
]

export const OPTIMIZATION_PARAMS = [
  'maximumOptimizationTime',
  'maxClientsPerTour',
  'speedMultiplier',
  'setupDuration',
  'visitDuration',
]

export const OPTIMIZATION_ROUTER_PARAMS = ['traffic', 'motorway', 'toll']

export enum UseType {
  Unused = '0',
  Used = '1',
}

export const MOBILE_APP_INDICATOR_TOUR_STATUS = [
  { id: UseType.Unused, name: i18n.t('MobileAppIndicatorScreen.status.unused') },
  { id: UseType.Used, name: i18n.t('MobileAppIndicatorScreen.status.used') },
]

export enum StopStatus {
  Planned,
  Loaded,
  OnSite,
  Delivered,
  Dropped,
  DeliverAgain,
  Canceled,
}

export enum AppInsightEvents {
  LaunchOptimization = 'Launch Optimization',
  ValidateOrInterfaceTour = 'Validate/Interface Tour',
  DeleteTour = 'Delete tour',
  DeletePlanTours = 'Delete all tours from plan',
  DeletePlan = 'Delete plan',
  DragAndDrop = 'Drag & drop',
}

export enum SentryEvents {
  LaunchOptimization = 'Launch Optimization',
  CreatePlanVisits = 'Create Plan Visits',
  DeletePlanVisits = 'Delete Plan Visits',
  LocalLogin = 'Local Login',
  Logout = 'Logout',
}

export enum TargetZoneSectorLevel {
  PostalCode = 'postalCode',
  City = 'city',
}

export enum DestinationType {
  Address = 'address',
  Warehouse = 'site',
}

export enum VehicleFillingRateCalculation {
  Quantity = 'quantity',
  Supports = 'supports',
}

export const DESTINATION_TYPE_OPTIONS = [
  {
    id: DestinationType.Address,
    name: i18n.t('OrdersScreen.deliveryAddressType.address'),
  },
  {
    id: DestinationType.Warehouse,
    name: i18n.t('OrdersScreen.deliveryAddressType.site'),
  },
]

export const VEHICLE_FILLING_RATE_CALCULATION_OPTIONS = [
  {
    id: VehicleFillingRateCalculation.Quantity,
    name: i18n.t('ExecutionScreen.vehicleFillingRateCalculation.quantity'),
  },
  {
    id: VehicleFillingRateCalculation.Supports,
    name: i18n.t('ExecutionScreen.vehicleFillingRateCalculation.supports'),
  },
]

export const CUSTOMER_NOTIFICATION_TYPE = [
  { id: NotificationType.Tracking, name: i18n.t('NotifierConfigurationsScreen.tracking') },
  { id: NotificationType.Booking, name: i18n.t('NotifierConfigurationsScreen.booking') },
  {
    id: NotificationType.DeliveryPlanningValidation,
    name: i18n.t('NotifierConfigurationsScreen.deliveryPlanningValidation'),
  },
  {
    id: NotificationType.StopDelivered,
    name: i18n.t('NotifierConfigurationsScreen.stopDelivered'),
  },
  { id: NotificationType.StopCanceled, name: i18n.t('NotifierConfigurationsScreen.stopCanceled') },
  {
    id: NotificationType.StopAbandoned,
    name: i18n.t('NotifierConfigurationsScreen.stopAbandoned'),
  },
  {
    id: NotificationType.StopPostponed,
    name: i18n.t('NotifierConfigurationsScreen.stopPostponed'),
  },
  {
    id: NotificationType.TourAbandoned,
    name: i18n.t('NotifierConfigurationsScreen.tourAbandoned'),
  },
]

export const SMS_COMMUNICATION_SERVICE = [
  { id: SmsCommunicationService.Twilio, name: i18n.t('NotifierConfigurationsScreen.twilio') },
  { id: SmsCommunicationService.Octopush, name: i18n.t('NotifierConfigurationsScreen.octopush') },
]

export enum TransportType {
  Delivery = 'delivery',
  Pickup = 'pickup',
}

export const TRANSPORT_TYPE_OPTIONS = [
  { id: TransportType.Delivery, name: i18n.t('tablesEntries.transportType.delivery') },
  { id: TransportType.Pickup, name: i18n.t('tablesEntries.transportType.pickup') },
]

export enum PinTooltipConfig {
  EstimatedServiceTime = 'estimatedServiceTime',
  ProductLabel = 'productLabel',
  ServiceInstructions = 'serviceInstructions',
}

export const PIN_TOOLTIP_CONFIG = [
  {
    id: PinTooltipConfig.EstimatedServiceTime,
    name: i18n.t('toolTipsEntries.estimatedServiceTime'),
  },
  { id: PinTooltipConfig.ProductLabel, name: i18n.t('toolTipsEntries.productLabel') },
  { id: PinTooltipConfig.ServiceInstructions, name: i18n.t('toolTipsEntries.serviceInstructions') },
]

export const READ_STATE = [
  { id: '1', name: i18n.t('tablesEntries.status.read') },
  { id: '0', name: i18n.t('tablesEntries.status.unread') },
]

export enum CancellationReasonType {
  Tour = 'tour',
  Stop = 'stop',
}

export const CANCELLATION_REASON_TYPES = [
  {
    value: CancellationReasonType.Stop,
    label: i18n.t('ConfigurationsScreen.cancellationReasonTypes.stop'),
  },
  {
    value: CancellationReasonType.Tour,
    label: i18n.t('ConfigurationsScreen.cancellationReasonTypes.tour'),
  },
]

export enum DriverEvent {
  StopAbandoned = 'stopAbandoned',
  StopDelivered = 'stopDelivered',
  TourAbandoned = 'tourAbandoned',
  TourFinished = 'tourFinished',
  VehicleReport = 'vehicleReport',
  TourStarted = 'tourStarted',
}

export const DRIVER_EVENT_MAP = new Map([
  [DriverEvent.StopAbandoned, i18n.t('OrdersScreen.driverEvents.stopAbandoned')],
  [DriverEvent.StopDelivered, i18n.t('OrdersScreen.driverEvents.stopDelivered')],
  [DriverEvent.TourAbandoned, i18n.t('OrdersScreen.driverEvents.tourAbandoned')],
  [DriverEvent.TourFinished, i18n.t('OrdersScreen.driverEvents.tourFinished')],
  [DriverEvent.VehicleReport, i18n.t('OrdersScreen.driverEvents.vehicleReport')],
  [DriverEvent.TourStarted, i18n.t('OrdersScreen.driverEvents.tourStarted')],
])

export enum SessionTokenExpiryDelay {
  SessionTokenExpiryDelay1Day = 'sessionTokenExpiryDelay1Day',
  SessionTokenExpiryDelay1Week = 'sessionTokenExpiryDelay1Week',
}

export const SESSION_TOKEN_EXPIRACY_DELAY = [
  {
    id: SessionTokenExpiryDelay.SessionTokenExpiryDelay1Day,
    name: i18n.t('TenantsScreen.sessionTokenExpiryDelay1Day'),
  },
  {
    default: true,
    id: SessionTokenExpiryDelay.SessionTokenExpiryDelay1Week,
    name: i18n.t('TenantsScreen.sessionTokenExpiryDelay1Week'),
  },
]

export const HIGHLIGHTED_ELEMENT_ZOOM = 14

export enum CustomerSearchField {
  Email = 'email',
  LastName = 'lastName',
  PhoneNumber = 'phoneNumber',
}

export enum OrderStepKey {
  Origin = 'origin',
  Destination = 'destination',
  Delivery = 'delivery',
  Order = 'order',
}

export const DEFAULT_ORDER_STEPS: IStep[] = [
  {
    key: OrderStepKey.Origin,
    title: i18n.t('OrdersScreen.customerInfo'),
    Icon: PersonIcon,
    isCompleted: false,
    isActive: true,
  },
  {
    key: OrderStepKey.Destination,
    title: i18n.t('OrdersScreen.addressInfo'),
    Icon: HomeIcon,
    isCompleted: false,
    isActive: false,
  },
  {
    key: OrderStepKey.Delivery,
    title: i18n.t('OrdersScreen.deliveryInfo'),
    Icon: AccessTimeIcon,
    isCompleted: false,
    isActive: false,
  },
  {
    key: OrderStepKey.Order,
    title: i18n.t('OrdersScreen.orderInfo'),
    Icon: ShoppingBasketIcon,
    isCompleted: false,
    isActive: false,
  },
]

export const ORDER_REQUIRED_FIELDS: Record<OrderStepKey, IOrderRequiredField[]> = {
  [OrderStepKey.Origin]: [
    {
      name: 'warehouseId',
      translationKey: 'OrdersScreen.warehouse',
      nameInCartDropoffConfig: 'originSiteId',
    },
    {
      name: 'customerEmail',
      translationKey: 'OrdersScreen.email',
    },
    {
      name: 'customerLastName',
      translationKey: 'OrdersScreen.customerLastName',
    },
    {
      name: 'customerFirstName',
      translationKey: 'OrdersScreen.customerFirstName',
    },
    {
      name: 'customerPhoneNumber',
      translationKey: 'OrdersScreen.phoneNumber',
    },
  ],
  [OrderStepKey.Destination]: [
    {
      name: 'deliveryAddressRoad',
      translationKey: 'OrdersScreen.address.road',
      destinationType: DestinationType.Address,
    },
    {
      name: 'deliveryAddressZipCode',
      translationKey: 'OrdersScreen.address.zipCode',
      destinationType: DestinationType.Address,
    },
    {
      name: 'deliveryAddressCity',
      translationKey: 'OrdersScreen.address.city',
      destinationType: DestinationType.Address,
    },
    {
      name: 'deliveryAddressCountry',
      translationKey: 'OrdersScreen.address.country',
      destinationType: DestinationType.Address,
      nameInCartDropoffConfig: 'country',
    },
    {
      name: 'deliverySiteId',
      translationKey: 'OrdersScreen.deliverySite',
      destinationType: DestinationType.Warehouse,
    },
  ],
  [OrderStepKey.Delivery]: [
    {
      name: 'deliveryDateTimeMinimum',
      translationKey: 'OrdersScreen.deliveryDateTimeMinimum',
    },
    {
      name: 'deliveryDateTimeMaximum',
      translationKey: 'OrdersScreen.deliveryDateTimeMaximum',
    },
  ],
  [OrderStepKey.Order]: [
    {
      name: 'deliveryType',
      translationKey: 'OrdersScreen.deliveryType',
      nameInCartDropoffConfig: 'deliveryType',
      isNumber: true,
    },
    {
      name: 'transportType',
      translationKey: 'OrdersScreen.transportType',
      nameInCartDropoffConfig: 'transportType',
    },
    {
      name: 'mainQuantity',
      translationKey: 'OrdersScreen.mainQuantity',
      isNumber: true,
    },
  ],
}

export const ORDER_HISTORY_ROWS = {
  DELIVERY_TYPE: 'deliveryType',
  TRANSPORT_TYPE: 'transportType',
  TAGS: 'tags',
  ESTIMATED_SERVICE_TIME: 'estimatedServiceTime',
  DELIVERY_ADDRESS: 'deliveryAddress',
  FLOOR: 'deliveryFloor',
  ELEVATOR: 'deliveryIsElevatorPresent',
  DOOR_CODE: 'deliveryDoorCode',
  ADDITIONAL_ADDRESS_INFORMATION: 'deliveryInstructions',
  ORDER_INSTRUCTIONS: 'orderInstructions',
  DELIVERY_TIME_MAX: 'deliveryDateTimeMaximum',
  DELIVERY_TIME_MIN: 'deliveryDateTimeMinimum',
  REQUESTED_CARRIER: 'carrierId',
  ENTREPRISE: 'enterpriseLabel',
  ORDER_AMOUNT: 'orderAmount',
  ESTIMATED_WEIGHT: 'estimatedWeight',
  MAIN_QUANTITY: 'mainQuantity',
}

export enum SetManualPassword {
  DEACTIVATED = 'deactivated',
  ALLUSERS = 'allUsers',
  DRIVERSONLY = 'driversOnly',
}

export const SET_MANUAL_PASSWORD = [
  {
    id: SetManualPassword.DEACTIVATED,
    name: i18n.t('TenantsScreen.setManualPassword.deactivated'),
  },
  {
    id: SetManualPassword.ALLUSERS,
    name: i18n.t('TenantsScreen.setManualPassword.allUsers'),
    default: true,
  },
  {
    id: SetManualPassword.DRIVERSONLY,
    name: i18n.t('TenantsScreen.setManualPassword.driversOnly'),
  },
]
