import React, { Dispatch, SetStateAction, useContext, useState } from 'react'
import { Grid, Tabs, Tab } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import clsx from 'clsx'

import CardLine, { CardLineProps } from 'components/CardLine/CardLine'
import CustomCard from 'components/CustomCard/CustomCard'
import { IVisit } from 'interfaces/IOrders'
import { useTranslation } from 'react-i18next'
import { dateDefaultFormat } from 'utils/dateFormat'
import {
  DisplayPhotosFromURLs,
  GetCarrierNameFromDirectId,
  GetDriverNameFromDirectId,
  GetOrderStatus,
  getTimeSlotFromVisit,
} from 'utils/tableUtils'
import TabPanel from 'components/TabPanel/TabPanel'
import { A11yPropsType } from 'interfaces/interfaces'
import SelectableColMenu from 'components/Table/SelectableColMenu/SelectableColMenu'
import { COLUMNS_MODEL_PRODUCTS_LIST } from 'constants/table'
import { AppConfigContext } from 'store/AppConfigContext'
import PODContent from './PODContent'
import ProductOrSupportTable from './ProductOrSupportTable'
import useStyles from './styles'
import ReturnablesTable from './ReturnablesTable'

type VisitDetailsCardProps = {
  selectedVisit?: IVisit
  a11yProps: (index: number) => A11yPropsType
  handleTabChange: (setter: Dispatch<SetStateAction<number>>, newValue: number) => void
  showReturnables?: boolean
}

export default ({
  selectedVisit,
  a11yProps,
  handleTabChange,
  showReturnables,
}: VisitDetailsCardProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [detailsTab, setDetailsTab] = useState<number>(0)
  const theme = useTheme()
  const { tablesConfig, updateTableConfig } = useContext(AppConfigContext)

  const infoLines: CardLineProps[] = [
    {
      label: t('OrdersScreen.lastStatus'),
      value: GetOrderStatus({
        status: selectedVisit?.stopStatus,
        cancellationReasonCode: selectedVisit?.cancellation?.reasonCode,
        cancellationSource: selectedVisit?.cancellation?.source,
      }),
    },
    {
      label: t('tablesEntries.details.bracket'),
      value: <div className={classes.alignFlexStart}>{getTimeSlotFromVisit(selectedVisit)}</div>,
    },
    {
      label: t('OrdersScreen.cancellationImages'),
      value: DisplayPhotosFromURLs({ photoURLs: selectedVisit?.cancellation?.photoUrls || [] }),
      hidden:
        !selectedVisit?.cancellation?.photoUrls
        || selectedVisit.cancellation.photoUrls.length === 0,
    },
    {
      label: t('OrdersScreen.planedDate'),
      value: dateDefaultFormat(selectedVisit?.planedArrival),
    },
    {
      label: t('OrdersScreen.deliveryDate'),
      value: dateDefaultFormat(selectedVisit?.realArrival || selectedVisit?.cancellation?.date),
    },
    {
      label: t('OrdersScreen.estimatedDate'),
      value: dateDefaultFormat(selectedVisit?.estimatedArrival),
    },
    {
      label: t('tablesEntries.carrier'),
      value: GetCarrierNameFromDirectId({ id: selectedVisit?.carrierId, displayPurpose: true }),
    },
    {
      label: t('tablesEntries.driver'),
      value: GetDriverNameFromDirectId({ id: selectedVisit?.driverId, displayPurpose: true }),
    },
    { label: t('OrdersScreen.tourNumber'), value: selectedVisit?.tourNumber },
  ]

  const productsColumnOptions = COLUMNS_MODEL_PRODUCTS_LIST

  return (
    <CustomCard
      dataCy="visitDetailsCard"
      cardTitle={t('OrdersScreen.detailsOnVisitNumber', { visitNumber: selectedVisit?.label })}
    >
      <Grid container direction="row" justify="center" alignItems="center" className={classes.tabs}>
        <Tabs
          TabIndicatorProps={{ style: { backgroundColor: theme.color.primary } }}
          value={detailsTab}
          onChange={(_, newTab) => handleTabChange(setDetailsTab, newTab)}
          aria-label="order tabs example"
        >
          <Tab
            data-cy={`${t('OrdersScreen.infos').toLowerCase()}Tabs`}
            className={classes.tab}
            label={t('OrdersScreen.infos')}
            {...a11yProps(0)}
          />
          <Tab
            data-cy={`${t('OrdersScreen.products').toLowerCase()}Tabs`}
            className={classes.tab}
            label={t('OrdersScreen.products')}
            {...a11yProps(1)}
          />
          {showReturnables && (
            <Tab
              data-cy={`${t('OrdersScreen.returnables').toLowerCase()}Tabs`}
              className={classes.tab}
              label={t('OrdersScreen.returnables')}
              {...a11yProps(2)}
            />
          )}
          <Tab
            data-cy={`${t('OrdersScreen.supports').toLowerCase()}Tabs`}
            className={classes.tab}
            label={t('OrdersScreen.supports')}
            {...a11yProps(showReturnables ? 3 : 2)}
          />
          <Tab
            data-cy={`${t('OrdersScreen.pod').toLowerCase()}Tabs`}
            className={classes.tab}
            label={t('OrdersScreen.pod')}
            {...a11yProps(showReturnables ? 4 : 3)}
          />
        </Tabs>
      </Grid>
      <TabPanel
        data-cy={`${t('OrdersScreen.infos').toLowerCase()}TabPanel`}
        value={detailsTab}
        index={0}
        className={classes.tabPanel}
      >
        {infoLines.map(({ label, value, hidden }) => (
          <CardLine key={label} label={label} value={value} hidden={hidden} />
        ))}
      </TabPanel>
      <TabPanel
        data-cy={`${t('OrdersScreen.products').toLowerCase()}TabPanel`}
        value={detailsTab}
        index={1}
        className={classes.tabPanel}
      >
        <div className={classes.tablePanel}>
          <ProductOrSupportTable
            dataCy={`${t('OrdersScreen.products').toLowerCase()}Table`}
            products={selectedVisit?.orderDetails || []}
          />
        </div>
        <div className={classes.tableToolbar}>
          <SelectableColMenu
            list={productsColumnOptions}
            columnPreferences={tablesConfig.productDetails || []}
            onChange={(entries) => updateTableConfig('productDetails', entries)}
          />
        </div>
      </TabPanel>
      {showReturnables && (
        <TabPanel
          data-cy={`${t('OrdersScreen.returnables').toLowerCase()}TabPanel`}
          value={detailsTab}
          index={2}
          className={classes.tabPanel}
        >
          <ReturnablesTable
            dataCy={`${t('OrdersScreen.returnables').toLowerCase()}Table`}
            returnables={selectedVisit?.returnables || []}
          />
        </TabPanel>
      )}
      <TabPanel
        data-cy={`${t('OrdersScreen.supports').toLowerCase()}TabPanel`}
        value={detailsTab}
        index={showReturnables ? 3 : 2}
        className={classes.tabPanel}
      >
        <ProductOrSupportTable
          dataCy={`${t('OrdersScreen.supports').toLowerCase()}Table`}
          supports={selectedVisit?.supports || []}
        />
      </TabPanel>
      <TabPanel
        data-cy={`${t('OrdersScreen.pod').toLowerCase()}TabPanel`}
        value={detailsTab}
        index={showReturnables ? 4 : 3}
        className={clsx(classes.tabPanel, classes.PODTabPanel)}
      >
        <PODContent
          driverComment={selectedVisit?.driverComment || ''}
          signatureUrl={selectedVisit?.signatureUrl || ''}
          imagesUrls={selectedVisit?.photoUrls || []}
          clientCode={selectedVisit?.clientCode || ''}
          clientCodeValidated={selectedVisit?.clientCodeValidated}
          location={
            selectedVisit?.destinationAddress?.latitude
            && selectedVisit?.destinationAddress?.longitude
              ? {
                latitude: selectedVisit.destinationAddress.latitude,
                longitude: selectedVisit.destinationAddress.longitude,
              }
              : undefined
          }
          driverEventLocations={selectedVisit?.driverEventLocations}
        />
      </TabPanel>
    </CustomCard>
  )
}
