import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField } from '@material-ui/core'

import useStyles from 'constants/cruStyles'
import Card from 'components/Card/Card'
import { IMapotempoConfig } from 'interfaces/interfaces'

interface ITextChange {
  target: {
    value: string
    name: string
    type?: string
    checked?: boolean
  }
}

interface IProps {
  mapotempoConfig?: Partial<IMapotempoConfig>
  onChange: (optimParam: IMapotempoConfig) => void
}

const WarehouseMapotempoCard = ({ mapotempoConfig, onChange }: IProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [config, setConfig] = useState<Partial<IMapotempoConfig>>({
    clientId: mapotempoConfig?.clientId,
    apiKey: mapotempoConfig?.apiKey,
    url: mapotempoConfig?.url,
    parcelDeliverableUnitId: mapotempoConfig?.parcelDeliverableUnitId,
    clientDeliverableUnitId: mapotempoConfig?.clientDeliverableUnitId,
  })

  const handleChange = (event: ITextChange): void => {
    const { target } = event
    const { name } = target
    const value = target.type === 'checkbox' ? target.checked : target.value

    onChange({ ...config, [name]: value } as IMapotempoConfig)
    setConfig({ ...config, [name]: value })
  }

  const isApiKeyFieldDisabled = config.clientId === '' || config.url === ''

  return (
    <Card title={t('SitesScreen.mapotempoConfigs').toUpperCase()}>
      <div className={classes.fieldsContainer}>
        <TextField
          label={t('SitesScreen.mapotempoConfig.clientId').toUpperCase()}
          type="text"
          onChange={handleChange}
          value={config.clientId || ''}
          name="clientId"
        />
        <TextField
          label={t('SitesScreen.mapotempoConfig.apiKey').toUpperCase()}
          type="text"
          onChange={handleChange}
          value={config.apiKey || ''}
          name="apiKey"
          disabled={isApiKeyFieldDisabled}
        />
        <TextField
          label={t('SitesScreen.mapotempoConfig.url').toUpperCase()}
          type="text"
          onChange={handleChange}
          value={config.url || ''}
          name="url"
        />
        <TextField
          label={t('SitesScreen.mapotempoConfig.parcelDeliverableUnitId').toUpperCase()}
          type="text"
          onChange={handleChange}
          value={config.parcelDeliverableUnitId || ''}
          name="parcelDeliverableUnitId"
        />
        <TextField
          label={t('SitesScreen.mapotempoConfig.clientDeliverableUnitId').toUpperCase()}
          type="text"
          onChange={handleChange}
          value={config.clientDeliverableUnitId || ''}
          name="clientDeliverableUnitId"
        />
      </div>
    </Card>
  )
}

export default WarehouseMapotempoCard
