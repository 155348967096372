import React, { useState } from 'react'
import moment from 'moment'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { useTranslation } from 'react-i18next'

import { IPlanTemplate } from 'interfaces/IPlanTemplate'
import { DeliveryTypeFilter, SiteFilter, TransportTypeFilter } from 'components/Inputs/ListFilter'
import useStyles from 'constants/cruStyles'
import Card from 'components/Card/Card'
import DateRangePicker from 'components/Inputs/DateRangePicker'
import { DateType } from 'constants/constants'
import useMainSite from 'hooks/useMainSite'

interface IProps {
  planTemplate?: Partial<IPlanTemplate>
  onPlanTemplateChange?: Function
  isReadOnly?: boolean
}

const PlanTemplateActivityCard = ({
  planTemplate,
  onPlanTemplateChange,
  isReadOnly,
}: IProps): JSX.Element => {
  const { t } = useTranslation()
  const [state, setState] = useState<Partial<IPlanTemplate>>({
    deliveryTypes: planTemplate?.deliveryTypes,
    transportTypes: planTemplate?.transportTypes,
    warehouseIds: planTemplate?.warehouseIds,
    secondaryWarehouseIds: planTemplate?.secondaryWarehouseIds,
    ordersBegin: planTemplate?.ordersBegin,
    ordersEnd: planTemplate?.ordersEnd,
  })
  const classes = useStyles()

  const isMainSiteMultisite = useMainSite(state.warehouseIds)

  const handleTimeChange = (time: MaterialUiPickersDate, name: string): void => {
    if (moment(time).isValid()) {
      setState({ ...state, [name]: moment(time).toISOString() })
      if (onPlanTemplateChange) {
        onPlanTemplateChange({ ...state, [name]: moment(time).toISOString() })
      }
    }
  }

  const handleMainSiteChange = (values: string[]): void => {
    setState({ ...state, warehouseIds: values, secondaryWarehouseIds: [] })
    if (onPlanTemplateChange) {
      onPlanTemplateChange({ ...state, warehouseIds: values, secondaryWarehouseIds: [] })
    }
  }

  const handleFilterChange = (values: string[], name: string): void => {
    setState({ ...state, [name]: values })
    if (onPlanTemplateChange) {
      onPlanTemplateChange({ ...state, [name]: values })
    }
  }

  return (
    <Card dataCy="activityCard" title={t('PlanTemplatesScreen.activity').toUpperCase()}>
      <div className={classes.fieldsContainer}>
        <div className={classes.filters}>
          <DeliveryTypeFilter
            dataCy="deliveryPicker"
            handleChange={(values: string[]): void => handleFilterChange(values, 'deliveryTypes')}
            ids={state.deliveryTypes || []}
            disabled={isReadOnly}
            shouldFillWidth
          />
        </div>
        <div className={classes.filters}>
          <TransportTypeFilter
            dataCy="transportPicker"
            handleChange={(values: string[]): void => handleFilterChange(values, 'transportTypes')}
            ids={state.transportTypes || []}
            disabled={isReadOnly}
            shouldFillWidth
          />
        </div>
        <div className={classes.filters}>
          <SiteFilter
            dataCy="sitePicker"
            placeholder={t('tablesEntries.mainSite')}
            handleChange={(values: string[]): void => handleMainSiteChange(values)}
            ids={state.warehouseIds || []}
            onlyOneValue
            disabled={isReadOnly}
            shouldFillWidth
            required
          />
        </div>
        {isMainSiteMultisite && (
          <div className={classes.filters}>
            <SiteFilter
              dataCy="secondaryWarehousesPicker"
              placeholder={t('PlanTemplatesScreen.secondarySites')}
              handleChange={(values: string[]): void =>
                handleFilterChange(values, 'secondaryWarehouseIds')}
              ids={state.secondaryWarehouseIds || []}
              disabled={isReadOnly}
              shouldFillWidth
              excludedIds={state.warehouseIds}
            />
          </div>
        )}
        <DateRangePicker
          startDate={state.ordersBegin}
          endDate={state.ordersEnd}
          dateType={DateType.Time}
          onChangeStart={(time: MaterialUiPickersDate): void => {
            handleTimeChange(time, 'ordersBegin')
          }}
          onChangeEnd={(time: MaterialUiPickersDate): void => {
            handleTimeChange(time, 'ordersEnd')
          }}
          hideShiftPicker
          isFullWidth
          startLabel={t('PlanTemplatesScreen.ordersBegin')}
          endLabel={t('PlanTemplatesScreen.ordersEnd')}
          startDataCy="orderStartPicker"
          endDataCy="orderEndPicker"
          isStartDisabled={isReadOnly}
          isEndDisabled={isReadOnly}
          isStartRequired
          isEndRequired
        />
      </div>
    </Card>
  )
}

export default PlanTemplateActivityCard
