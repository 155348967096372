import React, { useContext, useMemo } from 'react'
import { IconButton, Badge } from '@material-ui/core'
import NotificationsIcon from '@material-ui/icons/Notifications'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import { AlertsContext } from 'store/AlertsContext'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import clsx from 'clsx'
import useStyles from './styles'

const StyledBadge = styled(Badge)`
  color: white !important;
`

function NotificationButton(): JSX.Element {
  const { unreadAlertsCount } = useContext(AlertsContext)
  const history = useHistory()
  const classes = useStyles()

  const badgeNumber = useMemo(
    (): JSX.Element | null =>
      unreadAlertsCount ? (
        <span
          className={clsx(classes.alertCount, unreadAlertsCount > 9 && classes.countDoubleDigit)}
        >
          {unreadAlertsCount}
        </span>
      ) : null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [unreadAlertsCount, classes],
  )

  const handleClick = () => {
    history.push(ROUTES_PATH.alerts)
  }

  return (
    <>
      <IconButton
        onClick={handleClick}
        aria-label={`show ${unreadAlertsCount} new notifications`}
        data-cy="notificationButton"
      >
        <StyledBadge badgeContent={badgeNumber}>
          <NotificationsIcon />
        </StyledBadge>
      </IconButton>
    </>
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => <NotificationButton {...props} />
