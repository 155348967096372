import React, { useState, useEffect, useContext } from 'react'
import { IconButton, TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Chart, PieSeries, Legend, Title, Tooltip } from '@devexpress/dx-react-chart-material-ui'
import moment from 'moment'
import { EventTracker, Palette } from '@devexpress/dx-react-chart'
import { Skeleton } from '@material-ui/lab'
import { connectProps } from '@devexpress/dx-react-core'
import RefreshIcon from '@material-ui/icons/Refresh'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

import DateRangePicker from 'components/Inputs/DateRangePicker'
import { IUserData } from 'interfaces/interfaces'
import { IStopStat } from 'interfaces/IStopStat'
import { OrdersConsumer } from 'store/OrdersContext'
import { AuthConsumer } from 'store/AuthContext'
import { FiltersContext } from 'store/FiltersContext'
import { ContentContext } from 'store/ContentContext'
import Export from 'components/Chart/Export'
import {
  DELAY_INDICATORS,
  DEFAULT_ACCEPTABLE_DELAY,
  DEFAULT_ACCEPTABLE_ADVANCE,
} from 'constants/constants'
import { IChartData } from 'interfaces/IChartData'
import { CarrierFilter, SiteFilter } from 'components/Inputs/ListFilter'
import { FiltersContainer, TopFiltersContainer } from 'components/Layout'
import ExtendedDateFilters from 'components/Table/ExtendedDateFilters'
import useStyles from 'constants/cruStyles'
import { FilterKey, Filter } from 'constants/filters'
import StyledPaper from 'screens/ReportingCategory/components/StyledPaper'
import CustomLegend from 'screens/ReportingCategory/components/CustomLegend'
import {
  getChartTitle,
  setDateFactory,
  setSelectedDateFilterShortcutFactory,
} from 'utils/extendedDateFilter'
import { useDebouncedCallback } from 'use-debounce'

interface IProps {
  stopStats?: IStopStat
  user?: IUserData
  getStopStats?: (
    startDate: string,
    endDate: string,
    carrierIds?: string[],
    siteIds?: string[],
    toleratedAdvance?: number,
    toleratedDelay?: number,
  ) => void
}

const containerId = 'delay-reporting'
const filterKey = FilterKey.punctuality

const PunctualityIndicator = ({ stopStats, getStopStats }: IProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { setFilter, resetFilters, filters } = useContext(FiltersContext)
  const startDate = filters[filterKey][Filter.startDate] as string
  const endDate = filters[filterKey][Filter.endDate] as string
  const siteIds = filters[filterKey][Filter.sites] as string[]
  const carriers = filters[filterKey][Filter.carriers] as string[]
  const [chartData, setChartData] = useState<Array<IChartData>>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [acceptableAdvance, setAcceptableAdvance] = useState<number>()
  const [acceptableDelay, setAcceptableDelay] = useState<number>()
  const [selectedDateFilterShortcut, setSelectedDateFilterShortcut] = useState('')

  const { sites } = useContext(ContentContext)

  const fetchStats = () => {
    const { advance, delay } = getAdvanceDelay()
    if (getStopStats) {
      setIsLoading(true)
      getStopStats(startDate, endDate, carriers, siteIds, advance, delay)
    }
  }

  const getAdvanceDelay = () => {
    const advance = Number.isNaN(acceptableAdvance)
      ? getWarehouseAdvanceDelay().advance
      : acceptableAdvance
    const delay = Number.isNaN(acceptableDelay) ? getWarehouseAdvanceDelay().delay : acceptableDelay
    return { advance, delay }
  }

  const debouncedInputChange = useDebouncedCallback(() => {
    fetchStats()
  }, 600)

  useEffect(() => {
    fetchStats()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, carriers, siteIds])

  useEffect(() => {
    debouncedInputChange.callback()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptableAdvance, acceptableDelay])

  const getWarehouseAdvanceDelay = () => {
    let advance: number = DEFAULT_ACCEPTABLE_ADVANCE
    let delay: number = DEFAULT_ACCEPTABLE_DELAY
    if (sites.length > 0 && siteIds.length > 0) {
      const warehouse = sites.find((site) => siteIds.includes(site.id))
      if (warehouse) {
        advance = warehouse.optimParams?.toleratedAdvance ?? DEFAULT_ACCEPTABLE_ADVANCE
        delay = warehouse.optimParams?.toleratedDelay ?? DEFAULT_ACCEPTABLE_DELAY
      }
    }
    return { advance, delay }
  }
  useEffect(() => {
    const { advance, delay } = getWarehouseAdvanceDelay()
    setAcceptableAdvance(advance)
    setAcceptableDelay(delay)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sites, siteIds])

  const formatLabel = (key: string): string => {
    const { advance, delay } = getAdvanceDelay()
    switch (key) {
      case DELAY_INDICATORS.lessThanAcceptableDelayCount:
        return t('IndicatorsScreen.lessThanAcceptableDelayCount')
      case DELAY_INDICATORS.greaterThanAcceptableDelayCount:
        return t('IndicatorsScreen.greaterThanAcceptableDelayCount', {
          acceptableAdvance: advance,
          acceptableDelay: delay,
        })
      case DELAY_INDICATORS.betweenAcceptableDelayBoundsCount:
        return t('IndicatorsScreen.betweenAcceptableDelayBoundsCount', {
          acceptableAdvance: advance,
          acceptableDelay: delay,
        })
      default:
        return ''
    }
  }

  useEffect(() => {
    if (stopStats) {
      setIsLoading(false)
      let data: IChartData[] = []
      Object.entries(stopStats)
        .filter((item) => item[0] !== DELAY_INDICATORS.totalCount)
        .forEach((item) => {
          data = [...data, { label: formatLabel(item[0]), value: item[1] }]
        })
      setChartData(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stopStats])

  const EnhancedLegend = connectProps(CustomLegend, () => ({
    total: stopStats?.totalCount || 0,
    label: t('IndicatorsScreen.total'),
  }))

  const resetLocalFilters = (): void => resetFilters(filterKey)

  const { setEndDate, setStartDate } = setDateFactory(setFilter, filterKey)

  const { fnLast3months, fnLastMonth, fnLast7days, fnNext7days, fnThisMonth } = setSelectedDateFilterShortcutFactory(
    setSelectedDateFilterShortcut,
  )

  const chartTitle = getChartTitle(
    'IndicatorsScreen.respectedDeliveryTimeWindow',
    selectedDateFilterShortcut,
  )

  return (
    <>
      <TopFiltersContainer>
        <FiltersContainer>
          <IconButton data-cy="initializeButton" onClick={resetLocalFilters}>
            <RefreshIcon />
          </IconButton>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onChangeStart={(startValue: MaterialUiPickersDate): void => {
              setFilter(filterKey, Filter.startDate, moment(startValue).toISOString())
              setSelectedDateFilterShortcut('')
            }}
            onChangeEnd={(endValue: MaterialUiPickersDate): void => {
              setFilter(filterKey, Filter.endDate, moment(endValue).toISOString())
              setSelectedDateFilterShortcut('')
            }}
            hideShiftPicker
          />
          <SiteFilter
            handleChange={(siteValues: string[]): void => {
              setFilter(filterKey, Filter.sites, siteValues)
            }}
            ids={siteIds}
            placeholder={t('IndicatorsScreen.site')}
            dataCy="sitePicker"
            isUnselectAllowed={false}
          />
          <CarrierFilter
            dataCy="carrierPicker"
            handleChange={(carrierValues: string[]): void => {
              setFilter(filterKey, Filter.carriers, carrierValues)
            }}
            ids={carriers}
            siteIds={siteIds}
            filterKey={filterKey}
          />
          <TextField
            className={classes.inlineWithMargin}
            label={t('SitesScreen.optimParam.toleratedAdvance')}
            InputProps={{
              inputProps: { min: 0 },
            }}
            value={acceptableAdvance}
            type="number"
            onChange={(e) => setAcceptableAdvance(parseInt(e.target.value, 10))}
            variant="outlined"
          />
          <TextField
            label={t('SitesScreen.optimParam.toleratedDelay')}
            InputProps={{
              inputProps: { min: 0 },
            }}
            value={acceptableDelay}
            type="number"
            onChange={(e) => setAcceptableDelay(parseInt(e.target.value, 10))}
            variant="outlined"
          />
        </FiltersContainer>
      </TopFiltersContainer>
      <ExtendedDateFilters
        fnLast3months={fnLast3months}
        fnLastMonth={fnLastMonth}
        fnLast7days={fnLast7days}
        fnNext7days={fnNext7days}
        fnThisMonth={fnThisMonth}
        last3months
        lastMonth
        last7days
        next7days
        thisMonth
        setEndDate={setEndDate}
        setStartDate={setStartDate}
      />
      {isLoading && (
        <Skeleton
          variant="circle"
          animation="wave"
          width={400}
          height={400}
          style={{ margin: 'auto', marginTop: 60 }}
        />
      )}
      {chartData && !isLoading && (
        <StyledPaper id={containerId}>
          <Chart data-cy="punctualityChart" data={chartData}>
            <Palette scheme={['green', 'orange', 'red']} />
            <PieSeries valueField="value" argumentField="label" />
            <Title text={chartTitle} />
            <Legend position="bottom" rootComponent={EnhancedLegend} />
            <EventTracker />
            <Tooltip />
            {/* TODO: maybe use another fileName later instead of the containerId */}
            <Export dataCy="exportButton" containerId={containerId} fileName={containerId} />
          </Chart>
        </StyledPaper>
      )}
    </>
  )
}

export default (props: JSX.IntrinsicAttributes & IProps): JSX.Element => (
  <AuthConsumer>
    {(authContext): JSX.Element => (
      <OrdersConsumer>
        {(ordersContext): JSX.Element => (
          <PunctualityIndicator
            stopStats={ordersContext.stopStats}
            getStopStats={ordersContext.getStopStats}
            user={authContext.user}
            {...props}
          />
        )}
      </OrdersConsumer>
    )}
  </AuthConsumer>
)
