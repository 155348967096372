import React, { memo, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthContext } from 'store/AuthContext'
import { Typography } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import { IPasswordRules } from 'interfaces/interfaces'
import useStyles from './styles'

type PasswordComplexityCheckerProps = {
  password: string
}

const PasswordComplexityChecker = memo(
  ({ password }: PasswordComplexityCheckerProps): JSX.Element => {
    const { t } = useTranslation()
    const classes = useStyles()
    const { user } = useContext(AuthContext)

    const passwordRules = user?.tenantConfig?.passwordRules ?? ({} as IPasswordRules)

    const {
      charactersMinimum,
      upperCaseMinimum = 0,
      lowerCaseMinimum = 0,
      digitsMinimum = 0,
      symbolsMinimum = 0,
    } = passwordRules

    const isCharactersMinimumValid = password.length >= charactersMinimum
    const isUpperCaseMinimumValid = upperCaseMinimum
      ? new RegExp(`(?:.*[A-Z]){${upperCaseMinimum},}`).test(password)
      : true
    const isLowerCaseMinimumValid = lowerCaseMinimum
      ? new RegExp(`(?:.*[a-z]){${lowerCaseMinimum},}`).test(password)
      : true
    const isDigitsMinimumValid = digitsMinimum
      ? new RegExp(`(?:.*\\d){${digitsMinimum},}`).test(password)
      : true
    const isSymbolsMinimumValid = symbolsMinimum
      ? new RegExp(`(?:.*[^A-Za-z0-9]){${symbolsMinimum},}`).test(password)
      : true

    const renderValidationRule = (isValid: boolean, text: string) => (
      <div className={classes.validtionRulesWrapper}>
        {isValid ? <CheckIcon htmlColor="green" /> : <CloseIcon htmlColor="red" />}
        <Typography className={isValid ? classes.validText : classes.invalidText}>
          {text}
        </Typography>
      </div>
    )

    return (
      <div>
        {renderValidationRule(
          isCharactersMinimumValid,
          t('UsersScreen.passwordRules.charactersMinimum', { charactersMinimum }),
        )}
        {!!upperCaseMinimum
          && renderValidationRule(
            isUpperCaseMinimumValid,
            t('UsersScreen.passwordRules.upperCaseMinimum', { upperCaseMinimum }),
          )}
        {!!lowerCaseMinimum
          && renderValidationRule(
            isLowerCaseMinimumValid,
            t('UsersScreen.passwordRules.lowerCaseMinimum', { lowerCaseMinimum }),
          )}
        {!!digitsMinimum
          && renderValidationRule(
            isDigitsMinimumValid,
            t('UsersScreen.passwordRules.digitsMinimum', { digitsMinimum }),
          )}
        {!!symbolsMinimum
          && renderValidationRule(
            isSymbolsMinimumValid,
            t('UsersScreen.passwordRules.symbolsMinimum', { symbolsMinimum }),
          )}
      </div>
    )
  },
)

export default PasswordComplexityChecker
