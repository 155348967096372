import React from 'react'

interface IPinIcon {
  pinColor?: string
  children?: JSX.Element
  circleColor?: string
  circleSize?: number
  shouldDisplayStroke?: boolean
}

const ICON_WIDTH = 40
const ICON_HEIGHT = 40
const STROKE_WIDTH = 40

// eslint-disable-next-line max-len
const PATH = 'M256 0C161.896 0 85.333 76.563 85.333 170.667c0 28.25 7.063 56.26 20.49 81.104L246.667 506.5c1.875 3.396 5.448 5.5 9.333 5.5s7.458-2.104 9.333-5.5l140.896-254.813c13.375-24.76 20.438-52.771 20.438-81.021C426.667 76.563 350.104 0 256 0zm0 256c-47.052 0-85.333-38.281-85.333-85.333S208.948 85.334 256 85.334s85.333 38.281 85.333 85.333S303.052 256 256 256z'

const PinIcon = ({
  pinColor,
  circleColor = 'white',
  children,
  circleSize = 1,
  shouldDisplayStroke,
}: IPinIcon): JSX.Element => (
  <svg
    width={ICON_WIDTH}
    height={ICON_HEIGHT}
    viewBox={
      shouldDisplayStroke ? `-${STROKE_WIDTH / 2} -${STROKE_WIDTH / 2} 512 512` : '0 0 512 512'
    }
    role="img"
  >
    <path
      fill={pinColor}
      d={PATH}
      strokeWidth={shouldDisplayStroke ? STROKE_WIDTH : 0}
      stroke="orange"
    />
    <g>
      <circle cx={250} cy="180" r={130 * circleSize} fill={circleColor} />
      {children}
    </g>
  </svg>
)

export default PinIcon
