import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import VehiclesProvider, { VehiclesConsumer } from 'store/VehiclesContext'
import { ContentConsumer } from 'store/ContentContext'
import { IVehicle } from 'interfaces/IVehicle'
import { IIdParam } from 'interfaces/IIdParam'
import { ICarrier, IVehicleType, IWarehouse } from 'interfaces/interfaces'
import CustomCard from 'components/CustomCard/CustomCard'
import VehicleAvailabilityCard from 'screens/ResourcesCategory/Vehicles/VehicleAvailabilityCard'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import Button from 'components/Button/CustomButton'

import useStyles from 'constants/cruStyles'
import { IEvent } from 'interfaces/IEvent'

interface IProps {
  getDetails?: Function
  vehicleDetails: IVehicle
  carriers: ICarrier[]
  vehicleTypes: IVehicleType[]
  sites: IWarehouse[]
}

const VehicleDetailsScreen = ({
  getDetails,
  vehicleDetails,
  carriers,
  vehicleTypes,
  sites,
}: IProps): JSX.Element => {
  const { id } = useParams<IIdParam>()
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()

  useEffect(() => {
    if (id && getDetails) {
      getDetails(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const handleEditClick = (): void => {
    history.push(`${ROUTES_PATH.editVehicle}${id}`)
  }

  return (
    <>
      <div className={classes.detailHeaderContainer}>
        <h3 data-cy="vehicleDetailDescription">{`${vehicleDetails.name || ''}`}</h3>
        <Button dataCy="updateButton" onPress={handleEditClick}>
          {t('VehicleScreen.edit').toUpperCase()}
        </Button>
      </div>
      <div className={classes.detailsContent}>
        <div className={classes.detailsInnerContent}>
          <Grid item xs={10} className={classes.infoContainer}>
            <CustomCard
              dataCy="infoCard"
              cardTitle={t('VehicleScreen.profile').toUpperCase()}
              linePairs={[
                { label: t('VehicleScreen.name').toUpperCase(), value: vehicleDetails?.name || '' },
                {
                  label: t('VehicleScreen.licencePlate').toUpperCase(),
                  value: vehicleDetails?.licencePlate || '',
                },
                {
                  label: t('VehicleScreen.lastKnownKilometer').toUpperCase(),
                  value: vehicleDetails?.lastKnownKilometer?.toString() || '',
                },
                {
                  label: t('VehicleScreen.active').toUpperCase(),
                  value: vehicleDetails
                    ? vehicleDetails.active
                      ? t('VehicleScreen.yes')
                      : t('VehicleScreen.no')
                    : '',
                },
                {
                  label: t('VehicleScreen.carrier').toUpperCase(),
                  value:
                    carriers.length && vehicleDetails
                      ? carriers.find((carrier) => carrier.id === vehicleDetails.carrierId)?.name
                      : '',
                },
                {
                  label: t('VehicleScreen.site').toUpperCase(),
                  value:
                    sites.length && vehicleDetails
                      ? sites.find((site) => site.id === vehicleDetails.siteId)?.name
                      : '',
                },
                {
                  label: t('VehicleScreen.vehicleType').toUpperCase(),
                  value:
                    vehicleTypes.length && vehicleDetails
                      ? vehicleTypes.find(
                        (vehicleType) => vehicleType.code === vehicleDetails.vehicleType,
                      )?.label
                      : '',
                },
              ]}
            />
          </Grid>
        </div>
        {vehicleDetails.vehicleAvailabilityControl !== undefined && (
          <div className={classes.availabilityDetails}>
            <VehicleAvailabilityCard
              events={vehicleDetails.events as IEvent[]}
              shouldShowVehiclePlanning={vehicleDetails.vehicleAvailabilityControl}
              isReadOnly
            />
          </div>
        )}
      </div>
    </>
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <VehiclesProvider>
    <ContentConsumer>
      {(contentCtx): JSX.Element => (
        <VehiclesConsumer>
          {(vehiclesCtx): JSX.Element => (
            <VehicleDetailsScreen
              getDetails={vehiclesCtx.getDetails}
              vehicleDetails={vehiclesCtx.vehicleDetails}
              carriers={contentCtx.carriers}
              vehicleTypes={contentCtx.vehicleTypes}
              sites={contentCtx.sites}
              {...props}
            />
          )}
        </VehiclesConsumer>
      )}
    </ContentConsumer>
  </VehiclesProvider>
)
