import React, { useContext } from 'react'
import styled from 'styled-components'
import { Paper } from '@material-ui/core'
import { DraggableProvided, DraggableStateSnapshot, NotDraggingStyle } from 'react-beautiful-dnd'
import clsx from 'clsx'
import { Label } from 'components/Map/ToursMap/VisitsListItem'

import { IStopMarker } from 'interfaces/map'
import { dateToTimeFormat } from 'utils/dateFormat'
import { getStopMarkerDelaysColors } from 'utils/delaysColors'
import { DEFAULT_ACCEPTABLE_DELAY, DEFAULT_ACCEPTABLE_ADVANCE } from 'constants/constants'
import { ExecutionContext } from 'store/ExecutionContext'
import DelayCircle from './DelayCircle'
import useStyles from '../styles'

interface IStopSummary {
  stop: IStopMarker
  providedMarker?: DraggableProvided
  snapshotMarker?: DraggableStateSnapshot
  index: number
  toleratedAdvance?: number
  toleratedDelay?: number
}

enum Color {
  IsDraggingBackground = 'grey',
  DefaultBackground = 'white',
  IsDraggingText = 'white',
  DefaultText = 'black',
}

type TDraggableStyle = React.CSSProperties | NotDraggingStyle | undefined

export const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
`

export const TextName = styled.p`
  font-weight: bold;
  font-size: 14px;
  margin: 0 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: center;
`

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: TDraggableStyle,
): React.CSSProperties => ({
  background: isDragging ? Color.IsDraggingBackground : Color.DefaultBackground,
  color: isDragging ? Color.IsDraggingText : Color.DefaultText,
  ...draggableStyle,
})

const StopsListItem = ({
  stop,
  providedMarker,
  snapshotMarker,
  index,
  toleratedAdvance = DEFAULT_ACCEPTABLE_ADVANCE,
  toleratedDelay = DEFAULT_ACCEPTABLE_DELAY,
}: IStopSummary): JSX.Element => {
  const classes = useStyles()
  const { highlightedElement, setHighlightedElement } = useContext(ExecutionContext)
  const { deliveryDateTimeMinimum, deliveryDateTimeMaximum } = stop
  let timeSlot = ''
  if (deliveryDateTimeMinimum && deliveryDateTimeMaximum) {
    timeSlot = `${dateToTimeFormat(deliveryDateTimeMinimum)}-${dateToTimeFormat(
      deliveryDateTimeMaximum,
    )}`
  }

  const getDelayColor = (stopItem: IStopMarker): string | undefined => {
    const { fontColor } = getStopMarkerDelaysColors(
      stopItem.deliveryDateTimeMinimum,
      stopItem.deliveryDateTimeMaximum,
      toleratedAdvance,
      toleratedDelay,
      stopItem.realArrival,
    )
    return fontColor
  }

  const renderArrivalDate = () => {
    if (stop.cancellation?.date || stop.realArrival) {
      return (
        <div className={classes.delayIndicator}>
          <DelayCircle delayColor={getDelayColor(stop)} />
          <b>{dateToTimeFormat(stop.cancellation?.date || stop.realArrival)}</b>
        </div>
      )
    }

    if (stop.estimatedArrival) {
      return <i>{dateToTimeFormat(stop.estimatedArrival)}</i>
    }

    return <i>{dateToTimeFormat(stop.planedArrival)}</i>
  }

  return (
    <Paper
      data-cy="stopContainer"
      elevation={8}
      className={clsx(
        classes.info,
        classes.clickable,
        highlightedElement?.id === stop.stopId && classes.highlightedCard,
      )}
      ref={providedMarker?.innerRef}
      {...providedMarker?.draggableProps}
      {...providedMarker?.dragHandleProps}
      style={
        providedMarker
        && snapshotMarker
        && getItemStyle(snapshotMarker.isDragging, providedMarker.draggableProps.style)
      }
      onClick={(e) => {
        e.stopPropagation()
        setHighlightedElement({
          id: stop.stopId,
          latitude: stop.address.latitude,
          longitude: stop.address.longitude,
        })
      }}
      id={stop.stopId}
    >
      <ContentHeader>
        <Label>{index + 1}</Label>
        <TextName data-cy="stopTitle">{stop.address.label}</TextName>
      </ContentHeader>
      <div className={classes.stopAddress}>{stop.address.full}</div>
      <div className={classes.tourInfoFooter}>
        {renderArrivalDate()}
        {timeSlot && <b>{`[${timeSlot}]`}</b>}
      </div>
    </Paper>
  )
}

export default StopsListItem
