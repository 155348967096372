export enum ImportJobStatus {
  Todo = 'todo',
  Doing = 'doing',
  Success = 'success',
  HasErrors = 'has-errors',
  Failed = 'failed',
}

export enum ImportJobType {
  Order = 'order',
}

export enum ErrorType {
  Execution = 'execution',
  DataValidation = 'dataValidation',
}

export interface IImportJob {
  id: string
  status: ImportJobStatus
  dataType: ImportJobType
  nbOfActions: number
  nbOfActionsSuccess: number
  nbOfFailedActions: number
  errors: string
  originFileName: string
  uploadedFileUrl: string
  createdDate: string
  user: string
  userFirstName: string
  userLastName: string
}

export interface IJobImportError {
  id: string
  action: string
  validationErrors: string
  executionErrors: string
}
