import React from 'react'
import LinearProgress, { LinearProgressProps } from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

const LinearProgressWithLabel = (props: LinearProgressProps & { value: number }): JSX.Element => (
  <Box display="flex" alignItems="center">
    <Box width="100%" mr={1}>
      <LinearProgress variant="determinate" {...props} />
    </Box>
    <Box minWidth={35}>
      <Typography variant="body2" color="textSecondary">
        {`${Math.round(
          // eslint-disable-next-line react/destructuring-assignment
          props.value,
        )}%`}
      </Typography>
    </Box>
  </Box>
)

export default LinearProgressWithLabel
