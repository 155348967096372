import qs from 'qs'
import axios from 'axios'
import idx from 'idx'
import { v4 as uuidv4 } from 'uuid'
import * as Sentry from '@sentry/react'
import { Severity } from '@sentry/react'
import firebase from 'firebase/app'

const correlationId = () => ({ correlationId: uuidv4() })

const TIMEOUT = 120 * 1000

class Api {
  constructor(baseURL, headers = {}) {
    this.baseURL = baseURL
    this.api = axios.create({ baseURL, headers, timeout: TIMEOUT, withCredentials: true })
    this.headers = headers
    this.tokenData = {}
    this.api.interceptors.request.use(this.checkToken)
  }

  checkToken = async (config) => {
    const { currentUser } = firebase.auth()
    if (currentUser) {
      const res = await currentUser.getIdToken()
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${res}`,
      }
    } else {
      config.headers = {
        ...config.headers,
        Authorization: undefined,
      }
    }
    return config
  }

  setTenantIdHeader = (tenantId) => {
    this.headers = {
      ...this.headers,
      'x-tenantId': tenantId,
    }
  }

  setAccessToken = (token, expirationTime = null) => {
    this.headers = {
      ...this.headers,
      Authorization: `Bearer ${token}`,
    }
  }

  removeToken = () => {
    this.headers = {
      ...this.headers,
      Authorization: undefined,
    }
  }

  setUserCredentials = (name, password) => {
    this.headers = {
      ...this.headers,
    }
  }

  jsonToFormData = (json) => {
    const formData = new FormData()

    Object.keys(json).forEach((key) => {
      formData.append(key, json[key])
    })

    return formData
  }

  jsonToQuery = (json) => (json ? `?${qs.stringify(json, { arrayFormat: 'brackets' })}` : '')

  get = async (path = '', data = null, options = {}) => {
    const strQuery = this.jsonToQuery(data)
    const res = await this.api
      .get(`${path}${strQuery}`, {
        ...options,
        headers: { ...this.headers, ...options.headers, ...correlationId() },
      })
      .catch((error) => {
        this.handleRequestError(error, path, data, options)
      })
    return res.data
  }

  post = async (path = '', body = null, options = {}) => {
    const res = await this.api
      .post(path, body, {
        ...options,
        headers: { ...this.headers, ...options.headers, ...correlationId() },
      })
      .catch((error) => {
        this.handleRequestError(error, path, body, options)
      })
    return res.data
  }

  put = async (path = '', body = null, options = {}) => {
    const res = await this.api
      .put(path, body, {
        ...options,
        headers: { ...this.headers, ...options.headers, ...correlationId() },
      })
      .catch((error) => {
        this.handleRequestError(error, path, body, options)
      })
    return res.data
  }

  patch = async (path = '', body = null, options = {}) => {
    const res = await this.api
      .patch(path, body, {
        ...options,
        headers: { ...this.headers, ...options.headers, ...correlationId() },
      })
      .catch((error) => {
        this.handleRequestError(error, path, body, options)
      })
    return res.data
  }

  delete = async (path = '', body = null, options = {}) => {
    const res = await this.api
      .delete(path, {
        headers: { ...this.headers, ...options.headers, ...correlationId() },
        data: { ...body },
      })
      .catch((error) => {
        this.handleRequestError(error, path, body, options)
      })
    return res.data
  }

  handleRequestError = (error, path, body, options) => {
    const err = new Error()
    if (!error.response && !error.code) {
      err.name = 'NETWORK_ERROR'
      err.status = 'SERVER_ERROR'
      err.message = 'Network error'
    } else if (!error.response && error.code === 'ECONNABORTED') {
      err.name = 'TIMEOUT_ERROR'
      err.status = 'SERVER_ERROR'
      err.message = 'Timeout from server'
    } else {
      err.name = 'SERVER_ERROR'
      err.status = 'SERVER_ERROR'
      err.message =
        idx(error, (_) => _.response.data.message) ||
        `Server error, status: ${idx(error, (_) => _.response.status)}`
      err.code = idx(error, (_) => _.response.data.errorCode)
      err.statusCode = idx(error, (_) => _.response.status)
      err.data = idx(error, (_) => _.response.data)
      err.headers = idx(error, (_) => _.response.headers)
      err.type = idx(error, (_) => _.response.data.error_type)
      err.fieldErrors = idx(error, (_) => _.response.data.fieldErrors)
      err.req = {
        path: `${this.baseURL}${path}`,
        body,
        options,
      }
    }

    if (process.env.NODE_ENV === 'development') {
      console.log(
        //eslint-disable-line
        'SERVER_ERROR',
        'name',
        err.name,
        'status',
        err.status,
        'message',
        err.message,
        'statusCode',
        err.statusCode,
        'data',
        err.data,
        'headers',
        err.headers,
        'path',
        `${this.baseURL}${path}`,
        'body/query',
        body,
        'options',
        options,
      )
    }

    Sentry.captureEvent({
      message: err.message,
      level: Severity.Error,
      extra: {
        errorName: error?.name,
        errorCode: error?.code,
        errorMessage: error?.message,
        errorStack: error?.stack,
      },
      tags: {
        code: err.code || error.code,
        name: err.name,
        status: err.status,
        statusCode: err.statusCode,
        data: err.data,
        type: err.type,
        fieldErrors: err.fieldErrors,
        headers: err.headers,
      },
    })

    throw err
  }
}

export default Api
