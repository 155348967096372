export enum JobStatus {
  Canceled = 'canceled',
  Done = 'done',
  Failed = 'failed',
  InProgress = 'in progress',
  Planned = 'planned',
}

export enum JobType {
  VisitsGenerationJob = 'visitsGenerationJob',
  OptimizationJob = 'optimizationJob',
}

export enum ExtraMessageType {
  Error = 'error',
  Warning = 'warning',
}

export interface IVisitsGenerationProgress {
  totalNumberOfOrders: number
  numberOfTreatedOrders: number
  numberOfPlanVisitsCreated: number
  duration?: number
}

export interface IJob {
  jobId: string
  tenantId: string
  userId: string
  status: JobStatus
  dataType: JobType
  duration: number
  createdDate: Date
  processedDate: Date
  extra?: {
    messages?: {
      type: ExtraMessageType
      value: string
    }[]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    information?: Record<string, any>
  }
}
