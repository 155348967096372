import React, { ReactNode } from 'react'
import { StylesProvider } from '@material-ui/core/styles'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import * as Sentry from '@sentry/react'
import moment from 'moment'
import 'moment/locale/fr'

import AuthProvider from 'store/AuthContext'
import ContentProvider from 'store/ContentContext'
import ToursProvider from 'store/ToursContext'
import OrdersProvider from 'store/OrdersContext'
import ThemeProvider from 'store/ThemeContext'
import FeedbackProvider from 'store/FeedbackContext'
import FiltersProvider from 'store/FiltersContext'
import RootNavigator from 'navigation/RootNavigator'
import DefaultSiteProvider from 'store/DefaultSiteContext'
import AlertsProvider from 'store/AlertsContext'
import DisputeProvider from 'store/DisputeContext'
import TagsProvider from 'store/TagsContext'

const locale = navigator.language.includes('fr') ? 'fr' : 'en'
moment.locale(locale)

function App(): JSX.Element {
  return (
    <Providers>
      <RootNavigator />
    </Providers>
  )
}

interface IProviders {
  children: ReactNode
}

function Providers({ children }: IProviders): JSX.Element {
  return (
    <StylesProvider injectFirst>
      <ThemeProvider>
        <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
          <FeedbackProvider>
            <AuthProvider>
              <ContentProvider>
                <FiltersProvider>
                  <DefaultSiteProvider>
                    <AlertsProvider>
                      <ToursProvider>
                        <DisputeProvider>
                          <TagsProvider>
                            <OrdersProvider>{children}</OrdersProvider>
                          </TagsProvider>
                        </DisputeProvider>
                      </ToursProvider>
                    </AlertsProvider>
                  </DefaultSiteProvider>
                </FiltersProvider>
              </ContentProvider>
            </AuthProvider>
          </FeedbackProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </StylesProvider>
  )
}

export default Sentry.withProfiler(App)
