import React, { useContext, useEffect, useState } from 'react'
import { Paper, useTheme, Modal, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { ITourMapItem } from 'interfaces/map'
import useToggle from 'hooks/useToggle'
import { FeedbackContext } from 'store/FeedbackContext'
import PlansApi from 'api/plans'
import { isIError } from 'api/types'
import { findVehiclesWithModifiedAvailability, removePastDatesFromVehiclesAvailabilities } from 'utils/planningUtils'
import { PlanificationContext } from '../../PlanningStore'
import ButtonsRow from '../ButtonsRow'
import ListItem from '../OptimizeModal/ListItem'

interface IChangeVehicleModal {
  tour: ITourMapItem
  onClose(): void
}

const CustomPaper = styled(Paper)`
  position: absolute;
  min-width: 60%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 500;
  padding: 20px 50px;
`

const VehiclesListContainer = styled(Paper)`
  height: 500px;
  overflow: auto;
  background-color: ${(props) => props.theme.color.backgroundNavBar};
`

function ChangeVehicleModal({ tour, onClose }: IChangeVehicleModal): JSX.Element {
  const [isLoading, toggleIsLoading] = useToggle(false)
  const [selectedVehicle, setSelectedVehicle] = useState<string>(tour.vehicleId)
  const { openErrorSnack } = useContext(FeedbackContext)
  const { toggleBackdrop, vehiclesList, setVehiclesList, selectedPlan } = useContext(PlanificationContext)
  const { t } = useTranslation()
  const theme = useTheme()

  useEffect(() => {
    setVehiclesList(prev => removePastDatesFromVehiclesAvailabilities(prev, selectedPlan))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function handleSave() {
    toggleIsLoading()
    const res = await PlansApi.switchVehicle({
      tourId: tour.tourId,
      vehicleId: selectedVehicle,
      vehicleAvailability: findVehiclesWithModifiedAvailability([selectedVehicle], vehiclesList)[0],
    })
    if (res && !isIError(res)) {
      toggleBackdrop(t(`PlanningScreen.optimizeRunning`), false, true)
      onClose()
    } else {
      openErrorSnack(res.error.message)
    }
    toggleIsLoading()
  }

  function onPressRow(id: string): void {
    setSelectedVehicle(id)
  }

  return (
    <Modal open onClose={onClose} aria-label="cluster-creation-modal">
      <CustomPaper>
        <Typography variant="h4" style={{ marginBottom: 32 }}>
          {t('PlanningScreen.changeVehicle')}
        </Typography>
        <Typography style={{ marginBottom: 6 }}>
          {t('PlanningScreen.vehicleSelection')} :
        </Typography>
        <VehiclesListContainer theme={theme}>
          {!vehiclesList.length ? (
            <Typography>{t('PlanningScreen.optimizeModal.noVehicle')}</Typography>
          ) : (
            vehiclesList.map((veh) => (
              <ListItem
                key={veh.id}
                label={veh.name}
                selected={selectedVehicle === veh.id}
                startDate={veh.startDate}
                endDate={veh.endDate}
                onClick={(): void => onPressRow(veh.id)}
                siteId={veh.siteId}
                capacity={veh.capacity}
                id={veh.id}
              />
            ))
          )}
        </VehiclesListContainer>
        <ButtonsRow
          isLoading={isLoading}
          toggleModal={onClose}
          onClickCTA={handleSave}
          confirmMessage={t('PlanningScreen.validate')}
          style={{ marginTop: 42 }}
          isDisabled={vehiclesList.findIndex(v => new Date(v.startDate) > new Date(v.endDate)) !== -1}
        />
      </CustomPaper>
    </Modal>
  )
}

export default ChangeVehicleModal
