import React, { useContext, useMemo, useState } from 'react'
import { Paper } from '@material-ui/core'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import styled from 'styled-components'
import { IOutsourced } from 'interfaces/IOutsourced'
import { AuthContext } from 'store/AuthContext'
import { ContentContext } from 'store/ContentContext'
import COLORS from 'screens/PlanningCategory/PlanningScreen/components/ClusterColors'
import { sortPlanVisits } from 'utils/planningUtils'
import ListItem from '../ListItem'
import CustomHeader from './CustomHeader'

interface IOutsourcedColumn {
  outsourced: IOutsourced
  onDelete: () => void
  onSend: () => void
  onVisitDelete: (visitId: string) => void
}

const ListContainer = styled(Paper)`
  min-width: 260px;
  flex-direction: column;
  display: flex;
  background-color: slategrey;
  position: relative;
`

const StyledContainer = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  overflow-y: overlay;
  height: 100%;
  border-radius: 4px;
  background-color: ${COLORS[4]};
`

const OutsourcedColumn = ({
  outsourced,
  onDelete,
  onSend,
  onVisitDelete,
}: IOutsourcedColumn): JSX.Element => {
  const { carriers, tenants } = useContext(ContentContext)
  const selectedCarrier = useMemo(
    () => carriers.filter((carrier) => carrier.id === outsourced.carrierId)[0],
    [carriers, outsourced],
  )

  const { user } = useContext(AuthContext)
  const userTenant = tenants.find((tenant) => tenant.id === user?.tenantId)

  const tagsMap = new Map(userTenant?.tags?.map((tag) => [tag.code as string, tag.label as string]))
  const [showMoreForAll, setShowMoreForAll] = useState<boolean>(false)

  const handleToggleShowMoreForAll = (): void => {
    setShowMoreForAll(!showMoreForAll)
  }

  return (
    <ListContainer square>
      <CustomHeader
        showMoreForAll={showMoreForAll}
        handleToggleShowMoreForAll={handleToggleShowMoreForAll}
        carrierName={selectedCarrier.name}
        outsourced={outsourced}
        onDelete={onDelete}
        onSend={onSend}
      />
      <Droppable droppableId="outsourced-droppable">
        {(provided): JSX.Element => (
          <StyledContainer ref={provided.innerRef}>
            {sortPlanVisits(outsourced.visits || []).map((visit, index) => (
              <Draggable key={visit.stopId} index={index} draggableId={visit.stopId}>
                {(providedStop, snapshotStop): JSX.Element => (
                  <ListItem
                    tagsMap={tagsMap}
                    showMoreForAll={showMoreForAll}
                    color={COLORS[4]}
                    marker={visit}
                    providedMarker={providedStop}
                    snapshotMarker={snapshotStop}
                    isOutsourceMode
                    onDelete={() => onVisitDelete(visit.stopId)}
                  />
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </StyledContainer>
        )}
      </Droppable>
    </ListContainer>
  )
}

export default OutsourcedColumn
