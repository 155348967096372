import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Grid, Tooltip } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import useStyles from 'constants/cruStyles'
import OrdersApi from 'api/orders'
import { IError, isIError } from 'api/types'
import { IOrderDetailsParam } from 'interfaces/IIdParam'
import { A11yPropsType } from 'interfaces/interfaces'
import { FeedbackContext } from 'store/FeedbackContext'
import { CreatedOrder, IOrder, IOrderDetails, IOrderHistory, IVisit } from 'interfaces/IOrders'
import { handleTabChange } from 'utils/tabFeatureUtils'
import Button from 'components/Button/CustomButton'
import {
  ORDER_HISTORY_ROWS,
  OrderPlanStatus,
  Role,
  USER_ROLES_WITH_ORDER_MODULE,
} from 'constants/constants'
import { AuthContext } from 'store/AuthContext'
import NotifierConfigurationsProvider, {
  NotifierConfigurationsContext,
} from 'store/NotifierConfigurationsContext'
import { ContentContext } from 'store/ContentContext'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import { OrdersContext } from 'store/OrdersContext'
import AppConfigProvider from 'store/AppConfigContext'
import { OrderPdfGenerator, getDeliveryTypeLabelByCode } from 'utils/libs/pdf-generation'
import printJS from 'print-js'
import OrderCard from './components/OrderCard'
import ProductAndParcelCard from './components/ProductAndParcelCard'
import VisitDetailsCard from './components/VisitDetailsCard'
import VisitsCard from './components/VisitsCard'
import VisitTrackingCard from './components/VisitTrackingCard'
import OrderHistorySidebar from './components/OrderHistorySidebar'

const sortVisitByDate = (a: IVisit, b: IVisit): number => {
  if (!a.planedArrival || !b.planedArrival) return 0
  return a.planedArrival.getTime() - b.planedArrival.getTime()
}

const OrderDetailsScreen = (): JSX.Element => {
  const { id, orderNumber } = useParams<IOrderDetailsParam>()
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const [orderUpdates, setOrderUpdates] = useState<IOrderHistory[]>([])

  const { fetchOrderDetails, fetchExtendedOrderDetailsByOrderNumber, fetchOrderHistory } = useContext(OrdersContext)
  const { openErrorSnack, toggleLoader } = useContext(FeedbackContext)
  const { sendBookingSMS } = useContext(NotifierConfigurationsContext)
  const { sites } = useContext(ContentContext)
  const { user } = useContext(AuthContext)

  const [isOrderHistoryOpen, setIsOrderHistoryOpen] = useState<boolean>(false)
  const [orderDetails, setOrderDetails] = useState<IOrderDetails>()
  const [selectedVisit, setSelectedVisit] = useState<IVisit>()
  const [isSiteWithAutoCreatePlanVisits, setIsSiteWithAutoCreatePlanVisits] = useState<boolean>(false)
  const [isBookingEnabled, setIsBookingEnabled] = useState<boolean>(false)

  const canReserveAndSendSMS = user?.roles.some((role) =>
    USER_ROLES_WITH_ORDER_MODULE.includes(role as Role),
  )

  const handleOrderHistory = () => {
    if (!orderDetails?.orderId) return
    fetchOrderHistory(orderDetails.orderId, (orderHistoryArray) => {
      setOrderUpdates(orderHistoryArray)
    })
  }

  useEffect(() => {
    handleOrderHistory()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetails])

  const canOrderBeUnplanned = (status: OrderPlanStatus): boolean =>
    status === OrderPlanStatus.PartiallyPlanned
    || status === OrderPlanStatus.Planned
    || status === OrderPlanStatus.Validated

  const orderCannotBeBooked = (status: OrderPlanStatus): boolean =>
    status !== OrderPlanStatus.ToBook && status !== OrderPlanStatus.Unplanned

  const unplanOrder = async (orderId: string): Promise<IOrder | IError> => {
    toggleLoader(true)
    const res = await OrdersApi.unplan({ orderId })
    if (isIError(res)) {
      openErrorSnack(res.error.message)
    }
    toggleLoader(false)
    return res
  }

  function setDetailsAndSelectedVisit(response: IOrderDetails): void {
    setOrderDetails(response as IOrderDetails)
    setSelectedVisit([...(response as IOrderDetails).visits].sort(sortVisitByDate)[0])
  }

  async function fetchData(): Promise<void> {
    if (location.pathname.includes(ROUTES_PATH.orderDetailsByOrderNumber) && orderNumber) {
      return fetchExtendedOrderDetailsByOrderNumber(orderNumber, setDetailsAndSelectedVisit)
    }
    if (id) {
      return fetchOrderDetails(id, setDetailsAndSelectedVisit)
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const orderSite = useMemo(
    () => sites.find((site) => site.id === orderDetails?.warehouseId),
    [orderDetails, sites],
  )

  useEffect(() => {
    setIsSiteWithAutoCreatePlanVisits(orderSite?.autoCreatePlanVisits || false)
    setIsBookingEnabled(pilotCanBookSlot())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderSite])

  const a11yProps = (index: number): A11yPropsType => ({
    id: `simple-tab-${index}`,
    'aria-controls': `orders-tabpanel-${index}`,
  })

  const handlePrintClick = async (): Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (!orderDetails?.orderNumber) {
      return
    }

    const deliveryTypeLabel = getDeliveryTypeLabelByCode(
      orderDetails.deliveryType,
      user?.tenantConfig?.deliveryTypes,
    )
    const warehouseName = sites.find((site) => site.id === orderDetails?.warehouseId)?.name || ''
    try {
      const pdfFormatOrder: CreatedOrder = {
        ...orderDetails,
        deliveryTypeLabel,
        deliveryDateTimeMinimum: new Date(orderDetails.deliveryDateTimeMinimum).toUTCString(),
        deliveryDateTimeMaximum: new Date(orderDetails.deliveryDateTimeMaximum).toUTCString(),
        customer: {
          _id: '',
          email: orderDetails?.customerEmail,
          lastName: orderDetails?.customerLastName,
          firstName: orderDetails?.customerFirstName,
          phoneNumber: orderDetails?.customerPhoneNumber,
          orderNumber: Number.isNaN(orderDetails?.orderNumber)
            ? null
            : Number(orderDetails?.orderNumber),
          isNewCustomer: false,
          addresses: [],
        },
        mainQuantity: orderDetails?.mainQuantity ? Number(orderDetails?.mainQuantity) : -1,
        enterprise: orderDetails?.enterpriseLabel,
        geocodingSource: '',
      }
      const docUrl = OrderPdfGenerator.generateOrderPDF(warehouseName, pdfFormatOrder).documentURL
      printJS({ printable: docUrl })
    } catch (err) {
      openErrorSnack(t('OrdersScreen.errorGeneratingPdf'))
    }
  }

  const pilotCanBookSlot = () => {
    if (orderDetails && sites) {
      let adminCanReserveSlot = false
      if (orderSite) {
        adminCanReserveSlot = orderSite.appointmentEndCustomerPage?.canPilotReserveTimeSlots || false
      }
      return adminCanReserveSlot && !orderCannotBeBooked(orderDetails?.planStatus)
    }
    return false
  }

  const areOrderUpdatesEqual = (
    orderHistoryArray: IOrderHistory[],
    field: string,
    indexA: number,
    indexB: number,
  ) => {
    const currentRecord = orderHistoryArray[indexA][field]
    const nextRecord = orderHistoryArray[indexB][field]
    if (['string', 'number', 'boolean'].includes(typeof currentRecord)) {
      if (currentRecord !== nextRecord) {
        return true
      }
    } else if (Array.isArray(currentRecord)) {
      if (
        !currentRecord.some((e) => nextRecord.includes(e))
        && (currentRecord.length > 0 || nextRecord.length > 0)
      ) {
        return true
      }
    }
    return false
  }

  const hasInitialValueChanged = (field: string, withInitialValue = false) => {
    const cleanOrderHistoryArray = !withInitialValue
      ? orderUpdates.filter((e) => !checkIfUndefined(e[field]))
      : orderUpdates
    for (let i = 0; i < cleanOrderHistoryArray.length - 1; i += 1) {
      if (areOrderUpdatesEqual(cleanOrderHistoryArray, field, i, i + 1)) return true
    }
    return false
  }

  const checkIfUndefined = (value: string | number | undefined) => typeof value === 'undefined'

  const isOrderHistoryHidden = (): boolean => {
    let shouldHide = true
    if (!orderUpdates.length) return true
    const withInitialValueFields = [
      ORDER_HISTORY_ROWS.REQUESTED_CARRIER,
      ORDER_HISTORY_ROWS.ENTREPRISE,
      ORDER_HISTORY_ROWS.ORDER_AMOUNT,
      ORDER_HISTORY_ROWS.ESTIMATED_WEIGHT,
    ]
    Object.keys(ORDER_HISTORY_ROWS).forEach((key) => {
      if (hasInitialValueChanged(ORDER_HISTORY_ROWS[key], withInitialValueFields.includes(key))) {
        shouldHide = false
      }
    })
    return shouldHide
  }

  return (
    <>
      <div className={classes.orderDetailHeaderContainer}>
        <h3 data-cy="orderDetailDescription">
          {`${t('OrdersScreen.order')} ${orderDetails?.orderNumber || ''}`}
        </h3>

        <div style={{ display: 'flex', gap: '1rem' }}>
          <div>
            <Button
              disabled={!orderDetails?.orderNumber}
              dataCy="printOrderPdfButton"
              onPress={handlePrintClick}
            >
              {t('form.print')}
            </Button>
          </div>
          {canReserveAndSendSMS && (
            <>
              <Tooltip
                title={`${
                  isSiteWithAutoCreatePlanVisits
                    ? t('OrdersScreen.unplanDisabledSiteNotAllowed')
                    : orderDetails && canOrderBeUnplanned(orderDetails.planStatus)
                      ? t('OrdersScreen.unplanEnabled')
                      : t('OrdersScreen.unplanDisabledOrderStatus')
                }`}
                aria-label={t('OrdersScreen.unplanOrderButton')}
                arrow
              >
                <span className={classes.tooltipedContainers}>
                  <Button
                    disabled={
                      !(
                        orderDetails
                        && canOrderBeUnplanned(orderDetails.planStatus)
                        && !isSiteWithAutoCreatePlanVisits
                      )
                    }
                    dataCy="unplanOrderButton"
                    onPress={async () => {
                      if (orderDetails) {
                        await unplanOrder(orderDetails.orderId)
                        fetchData()
                      }
                    }}
                  >
                    {t('OrdersScreen.unplanOrderButton')}
                  </Button>
                </span>
              </Tooltip>
              {isBookingEnabled && (
                <Tooltip
                  title={`${
                    (orderDetails && orderCannotBeBooked(orderDetails?.planStatus)) || !orderDetails
                      ? t('OrdersScreen.bookOrderDisabled')
                      : t('OrdersScreen.bookOrderEnabled')
                  }`}
                  aria-label={t('OrdersScreen.reserveTimeslot')}
                  arrow
                >
                  <div className={classes.tooltipedContainers}>
                    <Button
                      dataCy="reservationButton"
                      onPress={async () => {
                        if (orderDetails) {
                          history.push(
                            `${ROUTES_PATH.orderDetails}${orderDetails?.orderId}/booking`,
                            { valid: true },
                          )
                        }
                      }}
                      disabled={
                        (orderDetails && orderCannotBeBooked(orderDetails?.planStatus))
                        || !orderDetails
                      }
                    >
                      {t('OrdersScreen.reserveTimeslot')}
                    </Button>
                  </div>
                </Tooltip>
              )}
              <Tooltip
                title={`${
                  orderDetails?.planStatus !== OrderPlanStatus.ToBook
                    ? t('OrdersScreen.sendSmsDisabled')
                    : t('OrdersScreen.sendSmsEnabled')
                }`}
                aria-label={t('OrdersScreen.sendSmsButton')}
                arrow
              >
                <div className={classes.tooltipedContainers}>
                  <Button
                    disabled={orderDetails?.planStatus !== OrderPlanStatus.ToBook || !orderDetails}
                    dataCy="sendSmsButton"
                    onPress={async () => {
                      if (orderDetails) {
                        await sendBookingSMS({
                          orderId: orderDetails.orderId,
                          orderNumber: orderDetails.orderNumber,
                          orderStatus: orderDetails.planStatus,
                          customerFirstName: orderDetails.customerFirstName,
                          customerLastName: orderDetails.customerLastName,
                          customerPhone: orderDetails.customerPhoneNumber,
                          tenantId: orderDetails.tenantId,
                          warehouseId: orderDetails.warehouseId,
                          dealerId: orderDetails.dealerId,
                          deliveryType: orderDetails.deliveryType,
                        })
                      }
                    }}
                  >
                    {t('OrdersScreen.sendSmsButton')}
                  </Button>
                </div>
              </Tooltip>
            </>
          )}
        </div>
      </div>
      <div>
        {orderUpdates.length > 0 && (
          <OrderHistorySidebar
            orderUpdates={orderUpdates}
            close={() => setIsOrderHistoryOpen(false)}
            open={isOrderHistoryOpen}
          />
        )}
      </div>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Grid container direction="column" spacing={4}>
            <Grid item xs>
              <OrderCard
                isOrderHistoryHidden={isOrderHistoryHidden()}
                openHistory={() => setIsOrderHistoryOpen(true)}
                orderDetails={orderDetails}
                setOrderDetails={setOrderDetails}
              />
            </Grid>
            <Grid item xs>
              {orderDetails && orderDetails.visits.length > 0 ? (
                <VisitsCard
                  visits={orderDetails.visits.sort(sortVisitByDate)}
                  selectedVisit={selectedVisit}
                  setSelectedVisit={setSelectedVisit}
                />
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Grid container direction="column" spacing={4}>
            {orderDetails && orderDetails.visits.length > 0 ? (
              <Grid item xs>
                <VisitTrackingCard
                  orderNumber={orderDetails.orderNumber || ''}
                  visits={orderDetails.visits.sort(sortVisitByDate)}
                />
              </Grid>
            ) : null}
            <Grid item xs>
              <ProductAndParcelCard
                a11yProps={a11yProps}
                supports={orderDetails?.parcels || []}
                products={orderDetails?.orderDetails || []}
                disputes={orderDetails?.disputes || []}
                shouldShowDisputes={
                  sites.find((site) => site.id === orderDetails?.warehouseId)?.planningMobile
                    ?.dispute ?? false
                }
              />
            </Grid>
            {orderDetails && orderDetails.visits.length > 0 ? (
              <Grid item xs>
                <VisitDetailsCard
                  selectedVisit={selectedVisit}
                  a11yProps={a11yProps}
                  handleTabChange={handleTabChange}
                  showReturnables={orderSite?.planningMobile?.returnables}
                />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <NotifierConfigurationsProvider>
    <AppConfigProvider>
      <OrderDetailsScreen {...props} />
    </AppConfigProvider>
  </NotifierConfigurationsProvider>
)
