import { Paper } from '@material-ui/core'
import styled from 'styled-components'

export const WIDTH_VALUE = 260
export const WIDTH = `${WIDTH_VALUE}px`

const StyledPaper = styled(Paper)`
  width: ${WIDTH};
  min-width: ${WIDTH};
  display: flex;
  flex-direction: column;
  background-color: slategrey;
`
export default StyledPaper
