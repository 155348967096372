import moment from 'moment'
import i18n from 'i18next'

import { TransportType } from 'constants/constants'
import { IPlan } from 'interfaces/IPlan'
import { IVehicleAvailability } from 'interfaces/IVehicleAvailability'
import { ISelectableMarker } from 'interfaces/map'
import { IModifiedVehicleAvailability } from 'interfaces/IModifiedVehicleAvailability'

const midnight = { hours: 0, minutes: 0 }
const lastMinute = { hours: 23, minutes: 59 }

export const getDateAddDays = (days: number): string => moment().add(days, 'days').toISOString()
export const getNextMidnight = (): string => moment().set(midnight).add(1, 'days').toISOString()
export const getNextLastMinute = (): string => moment().set(lastMinute).add(1, 'days').toISOString()

export function formatPlanLabel(startDate: string): string {
  return `${i18n.t('PlanningScreen.planCreation.planFor')} ${moment(startDate).format('DD/MM/yy')}`
}

export const calculateMainQuantity = (
  markers: ISelectableMarker[],
  transportType: TransportType,
): number => {
  // eslint-disable-next-line default-case
  switch (transportType) {
    case TransportType.Delivery:
      return markers
        .filter((marker) => marker.mainQuantity > 0)
        .reduce((accumulator, marker) => accumulator + marker.mainQuantity, 0)
    case TransportType.Pickup:
      return markers
        .filter((marker) => marker.mainQuantity < 0)
        .reduce((accumulator, marker) => accumulator + Math.abs(marker.mainQuantity), 0)
  }
}

export const calculateAmplitude = (startDate: string, endDate: string): string => {
  const start = moment(startDate)
  const end = moment(endDate)
  const ms = moment(end, 'DD/MM/YYYY HH:mm:ss').diff(moment(start, 'DD/MM/YYYY HH:mm:ss'))
  const d = moment.duration(ms)
  return Math.floor(d.asHours()) + moment.utc(ms).format(':mm')
}

// sorts planVisits by asc deliveryDateTimeMaximum then desc mainQuantity then alphabetically
export const sortPlanVisits = (planVisits: ISelectableMarker[]): ISelectableMarker[] =>
  planVisits
    .filter((visit) => visit.address.label)
    .sort(
      (v1, v2) =>
        new Date(v1.deliveryDateTimeMaximum).getTime()
          - new Date(v2.deliveryDateTimeMaximum).getTime()
        || v2.mainQuantity - v1.mainQuantity
        || v1.address.label.localeCompare(v2.address.label),
    )

export const removePastDatesFromVehiclesAvailabilities = (
  vehicles: IVehicleAvailability[],
  plan: IPlan | null,
): IVehicleAvailability[] => {
  const now = new Date()
  if (plan && now < new Date(plan.endDate)) {
    return vehicles.map(vehicle => {
      if (new Date(vehicle.startDate) < now && !vehicle.wasStartDateModifiedManually) {
        return { ...vehicle, startDate: now.toISOString() }
      }
      return vehicle
    })
  }
  return vehicles
}

export const findVehiclesWithModifiedAvailability = (
  vehicleIds: string[],
  vehicles: IVehicleAvailability[],
): IModifiedVehicleAvailability[] =>
  vehicles
    .filter(vehicle => vehicleIds.includes(vehicle.id))
    .filter(vehicle => vehicle.wasStartDateModifiedManually || vehicle.wasEndDateModifiedManually)
    .map(vehicle => ({
      vehicleId: vehicle.id,
      startDate: vehicle.startDate,
      endDate: vehicle.endDate,
      wasStartDateModifiedManually: vehicle.wasStartDateModifiedManually || false,
      wasEndDateModifiedManually: vehicle.wasEndDateModifiedManually || false,
    }))
