import Api from 'services/api'
import { IWarehouse, ISiteFilters } from 'interfaces/interfaces'
import { IError } from 'api/types'
import { ISiteReporting } from 'interfaces/ISiteReporting'

const parseSingleSite = (site: IWarehouse): IWarehouse => ({
  id: site.id,
  name: site.name,
  code: site.code,
  address: site.address,
  type: site.type,
  tourType: site.tourType,
  active: site.active,
  carriers: site.carriers,
  accountId: site.accountId,
  sectors: site.sectors,
  tenantName: site.tenantName,
  planningMobile: site.planningMobile,
  autoCreatePlanVisits: site.autoCreatePlanVisits,
  orderScreen: site.orderScreen,
})

const parseSites = (sites: IWarehouse[]): Array<IWarehouse> =>
  sites.map((site) => parseSingleSite(site))

const getList = async (
  filters?: ISiteFilters,
  offset?: number,
  rowsPerPage?: number,
  sortBy?: string,
  sortDirection?: number,
  isForAdmin = false,
): Promise<{ sites: Array<IWarehouse>; count: number } | IError> => {
  try {
    const response = await Api.get(isForAdmin ? 'warehouses/all' : 'warehouses', {
      siteIds: filters?.siteIds,
      types: filters?.type,
      ...(isForAdmin && { tenants: filters?.tenant }),
      ...((filters?.active?.length || 0) === 1 && {
        active: filters?.active && filters?.active[0],
      }),
      ...((filters?.searchText?.length || 0) >= 3 && { search: filters?.searchText }),
      ...((filters?.hasSector?.length || 0) === 1 && {
        hasSectors: filters?.hasSector && filters?.hasSector[0],
      }),
      offset,
      limit: rowsPerPage,
      sortBy: sortBy || 'name',
      sortDirection: sortDirection || 1,
    })
    return { sites: parseSites(response.items), count: response.count }
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const create = async (site: IWarehouse): Promise<IWarehouse | IError> => {
  try {
    const body = {
      ...site,
      address: {
        ...site.address,
        label: site.name,
      },
      carriers: site.carriers?.map((carrier) => ({ ...carrier, carrierId: carrier.id })),
      sectors: site.sectors?.map((sector) => sector.id),
    }
    delete body.carriersIds
    const response = await Api.post('warehouses', body)
    return response
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors, code: error.code } }
  }
}

const get = async (id: string, asAdmin = false): Promise<IWarehouse | IError> => {
  try {
    const response = await Api.get(
      asAdmin ? `warehouses/details/${id}/admin` : `warehouses/details/${id}`,
    )
    return response
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const update = async (
  id: string,
  site: IWarehouse,
  asAdmin = false,
): Promise<IWarehouse | IError> => {
  try {
    const body = {
      ...site,
      carriers: site.carriers?.map((carrier) => ({ ...carrier, carrierId: carrier.id })),
      address: {
        ...site.address,
        label: site.name,
      },
      sectors: site.sectors?.map((sector) => sector.id),
    }
    delete body.carriersIds
    const response = await Api.patch(asAdmin ? `warehouses/${id}/admin` : `warehouses/${id}`, body)
    return response
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors, code: error.code } }
  }
}

const getSiteReporting = async (
  startDate: string,
  endDate: string,
  warehouseIds?: string[],
  offset?: number,
  rowsPerPage?: number,
): Promise<{ items: Array<ISiteReporting>; count: number } | IError> => {
  try {
    const response = await Api.post('warehouses/reporting', {
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      warehouseIds,
      offset,
      limit: rowsPerPage,
    })
    return response
  } catch (error) {
    return { error: { message: error.message } }
  }
}

export default {
  getList,
  update,
  create,
  get,
  getSiteReporting,
}
