import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { IVisitsGenerationProgress } from 'interfaces/IJob'
import { isDefined } from 'utils/functions'
import LinearProgressWithLabel from './LinearProgressWithLabel'

const Container = styled.div`
  width: 100%;
`

interface IProps {
  progress: IVisitsGenerationProgress
}

const VisitsGenerationProgress = ({ progress }: IProps): JSX.Element => {
  const { t } = useTranslation()

  const value = progress.totalNumberOfOrders
    ? (progress.numberOfTreatedOrders / progress.totalNumberOfOrders) * 100
    : 0

  return (
    <Container>
      <LinearProgressWithLabel value={value} />
      <div>
        {t('PlanningScreen.visitsGeneration.totalNumberOfOrders')}: {progress.totalNumberOfOrders}
      </div>
      <div>
        {t('PlanningScreen.visitsGeneration.numberOfProcessedOrders')}:{' '}
        {progress.numberOfTreatedOrders}
      </div>
      <div>
        {t('PlanningScreen.visitsGeneration.numberOfVisitsCreated')}:{' '}
        {progress.numberOfPlanVisitsCreated}
      </div>
      <div>
        {t('PlanningScreen.visitsGeneration.duration')}:{' '}
        {isDefined(progress.duration) ? Math.ceil(progress.duration as number) : ''}
      </div>
    </Container>
  )
}

export default VisitsGenerationProgress
