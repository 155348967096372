import '@geoman-io/leaflet-geoman-free'
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'
import { LatLng } from 'leaflet'
import React, { useEffect, useRef, useState } from 'react'
import { MapContainerProps, Marker } from 'react-leaflet'
import styled from 'styled-components'
import { TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import { ISector, ITargetZoneSector } from 'interfaces/ISector'
import { LatLngNumber } from 'interfaces/map'
import Button from 'components/Button/CustomButton'
import { ITextChange } from 'interfaces/interfaces'
import COLORS from 'screens/PlanningCategory/PlanningScreen/components/ClusterColors'
import { TargetZoneSectorLevel } from 'constants/constants'
import Map from '../Map/Map'
import useStyles from '../styles'

interface ISectorMapProps {
  mapName: string
  warehouseId: string
  warehousePosition: { lat: number; lng: number }
  sectors: ISector[]
  createSector?: Function
  deleteSector?: Function
  updateSector?: (sectorId: string, sector: ISector) => Promise<void>
  createTargetZoneSector: (targetZoneSector: ITargetZoneSector) => Promise<void>
  refreshSites?: () => void
}

const StyledMap = styled.div`
  height: 75vh;
  flex: 1;
  position: relative;
`

const DEFAULT_COUNTRY = 'France'

function SectorMap({
  mapName,
  warehouseId,
  warehousePosition,
  sectors,
  createSector,
  deleteSector,
  updateSector,
  createTargetZoneSector,
  refreshSites,
  ...rest
}: MapContainerProps & ISectorMapProps): JSX.Element {
  const { t } = useTranslation()
  const sectorsRef = useRef(sectors)
  const classes = useStyles()
  const warehouseIdRef = useRef(warehouseId)
  const [postalCode, setPostalCode] = useState<string>()
  const [city, setCity] = useState<string>()
  const [country, setCountry] = useState<string>(DEFAULT_COUNTRY)

  useEffect(() => {
    if (sectors) {
      sectorsRef.current = sectors
    }
    if (refreshSites) {
      refreshSites()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectors])

  useEffect(() => {
    if (warehouseId) {
      warehouseIdRef.current = warehouseId
    }
  }, [warehouseId])

  async function handleSectorChange(
    layer: L.Polygon,
    remove?: boolean,
    sectorId?: string,
    layerIndex = 0,
  ): Promise<string> {
    const { options } = layer
    const { geometry } = layer.toGeoJSON()
    const coordinates: LatLngNumber[] = geometry.coordinates
      .flat(1)
      .map((entry) => [entry[1], entry[0]]) as LatLngNumber[]
    const prevSectors = [...sectorsRef.current]
    const sectorIndex = prevSectors.findIndex((sector) => sector.id === sectorId)
    if (remove && sectorIndex >= 0 && deleteSector) {
      const removedSectorId = prevSectors[sectorIndex].id
      await deleteSector(removedSectorId)
      return removedSectorId
    }
    if (sectorIndex >= 0 && updateSector) {
      const sector: ISector = {
        ...prevSectors[sectorIndex],
      }
      sector.sectorCoordinates[layerIndex] = {
        ...sector.sectorCoordinates[layerIndex],
        coordinates,
      }
      const updatedSector: ISector = { ...sector }
      const updatedSectorId = prevSectors[sectorIndex].id
      await updateSector(updatedSectorId, updatedSector)
      return updatedSectorId
    }
    if (createSector) {
      const newSector: ISector = {
        id: '',
        sectorCoordinates: [{ coordinates }],
        color: options.color,
        warehouseId: warehouseIdRef.current,
      }
      const createdSector = await createSector(newSector)
      return createdSector.id
    }
    return ''
  }

  const handlePostalCodeChange = ({ target: { value } }: ITextChange): void => setPostalCode(value)

  const handleCityChange = ({ target: { value } }: ITextChange): void => setCity(value)

  const handleCountryChange = ({ target: { value } }: ITextChange): void => setCountry(value)

  const handleCreateClick = async (): Promise<void> => {
    if (postalCode || city) {
      let targetZone: string[] = []
      if (postalCode) {
        targetZone = postalCode.split('\n').map((code) => `${code}, ${country}`)
      } else if (city) {
        targetZone.push(city)
      }
      const newTargetZoneSector: ITargetZoneSector = {
        targetZone,
        warehouseId: warehouseIdRef.current,
        color: COLORS[sectors.length % COLORS.length],
        level: postalCode ? TargetZoneSectorLevel.PostalCode : TargetZoneSectorLevel.City,
      }
      await createTargetZoneSector(newTargetZoneSector)
      setPostalCode('')
      setCity('')
      setCountry(DEFAULT_COUNTRY)
    }
  }

  return (
    <StyledMap data-cy="map">
      {warehouseId && (
        <>
          <Map
            mapName={mapName}
            className={classes.sectorsMap}
            {...rest}
            existingSectors={sectors}
            useToolbar
            onSectorChange={handleSectorChange}
            {...(warehousePosition.lat && warehousePosition.lng && { center: warehousePosition })}
          >
            {warehousePosition.lat && warehousePosition.lng && (
              <Marker position={warehousePosition as LatLng} />
            )}
          </Map>
          <div className={clsx(classes.addressContainer, classes.sectorsAddressContainer)}>
            <div className={classes.inputs}>
              <TextField
                data-cy="postalCode"
                label={t('SectorsScreen.postalCode').toUpperCase()}
                onChange={handlePostalCodeChange}
                type="text"
                name="postalCode"
                value={postalCode || ''}
                multiline
                rows={2}
                placeholder={t('SectorsScreen.postalCodePlaceholder')}
              />
              <TextField
                data-cy="city"
                label={t('SectorsScreen.city').toUpperCase()}
                onChange={handleCityChange}
                type="text"
                name="city"
                value={city || ''}
              />
              <TextField
                data-cy="country"
                label={t('SectorsScreen.country').toUpperCase()}
                onChange={handleCountryChange}
                type="text"
                name="country"
                value={country}
              />
              <Button
                dataCy="createButton"
                className={classes.geocodeBtn}
                onPress={handleCreateClick}
              >
                {t('SectorsScreen.create')}
              </Button>
            </div>
          </div>
        </>
      )}
    </StyledMap>
  )
}

export default SectorMap
