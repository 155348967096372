import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(0),
      margin: 0,
    },
    header: {
      backgroundColor: theme.color.backgroundPaperTitle,
      color: theme.color.secondary,
      fontWeight: 'bold',
    },
    content: {
      padding: 0,
      paddingBottom: '0 !important',
      backgroundColor: theme.palette.background.default,
    },
    bg: {
      background: theme.palette.background.default,
    },
    spaceBetween: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    cardIcon: {
      color: theme.color.colorPlan,
      background: theme.color.primary,
      '&:hover': {
        color: theme.color.colorPlan,
        background: theme.color.primary,
      },
    },
  }),
)
