import { createStyles, makeStyles } from '@material-ui/core/styles'

export default makeStyles(() =>
  createStyles({
    alertCount: {
      backgroundColor: 'red',
      width: '13px',
      height: '13px',
      borderRadius: '50%',
    },
    countDoubleDigit: {
      fontSize: '9px',
      paddingTop: ' 1px',
    },
  }),
)
