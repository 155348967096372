import React from 'react'
import PlaceIcon from '@material-ui/icons/Place'
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle'
import PinDropIcon from '@material-ui/icons/PinDrop'
import { useTranslation } from 'react-i18next'

import DisplayModeFabMenu from 'components/Map/DisplayModeFabMenu'

export enum ReplayScreenMode {
  DriverAndEventsLocations,
  DriverLocations,
  EventsLocations,
  __LENGTH,
}

interface IFabMenu {
  displayMode: ReplayScreenMode
  toggleDisplayMode: () => void
}

const displayModeIcons = {
  [ReplayScreenMode.DriverAndEventsLocations]: PlaceIcon,
  [ReplayScreenMode.DriverLocations]: PersonPinCircleIcon,
  [ReplayScreenMode.EventsLocations]: PinDropIcon,
}

const FabMenu = ({ displayMode, toggleDisplayMode }: IFabMenu): JSX.Element => {
  const { t } = useTranslation()

  const DisplayModeIcon = displayModeIcons[displayMode]

  // The first entry is displayed at bottom
  const actions = [
    {
      name: t('MapScreen.displayMode'),
      icon: <DisplayModeIcon />,
      action: toggleDisplayMode,
    },
  ]

  return <DisplayModeFabMenu actions={actions} />
}

export default FabMenu
