import { TextField } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { IIdParam } from 'interfaces/IIdParam'
import { ICostConfiguration } from 'interfaces/ICostConfiguration'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import CostConfigurationsProvider, {
  CostConfigurationsContext,
} from 'store/CostConfigurationsContext'
import Card from 'components/Card/Card'
import useStyles from 'constants/cruStyles'
import { useTranslation } from 'react-i18next'
import FormAction from 'components/Button/FormAction'
import { ITextChange } from 'interfaces/interfaces'
import { CarrierFilter } from 'components/Inputs/ListFilter'
import { DateType } from 'constants/constants'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import DateRangePicker from 'components/Inputs/DateRangePicker'
import moment from 'moment'

const CostConfigFormScreen = (): JSX.Element => {
  const {
    createCostConfiguration,
    updateCostConfiguration,
    getDetails,
    costConfigurationsDetails,
    updateSuccess,
    createSuccess,
  } = useContext(CostConfigurationsContext)

  const [costConfiguration, setCostConfiguration] = useState<Partial<ICostConfiguration>>({})

  const history = useHistory()
  const { id } = useParams<IIdParam>()
  const classes = useStyles()
  const { t } = useTranslation()

  useEffect(() => {
    if (id && getDetails) {
      getDetails(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (createSuccess || updateSuccess) {
      history.push(ROUTES_PATH.costConfigList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createSuccess, updateSuccess])

  useEffect(() => {
    if (costConfigurationsDetails && id) {
      setCostConfiguration({
        ...costConfigurationsDetails,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [costConfigurationsDetails])

  const handleCarriersChange = (carrierIds: string[]): void => {
    setCostConfiguration((prevCostConfiguration) => ({
      ...prevCostConfiguration,
      carrierIds,
    }))
  }

  const handleInputChange = (event: ITextChange): void => {
    const { target } = event
    const { name, value } = target
    if (name === 'label') {
      setCostConfiguration((prevCostConfiguration) => ({
        ...prevCostConfiguration,
        [name]: value,
      }))
    } else {
      setCostConfiguration((prevCostConfiguration) => ({
        ...prevCostConfiguration,
        [name]: value ? +value : null,
      }))
    }
  }

  const handleDateChange = (date: MaterialUiPickersDate, name: string): void => {
    setCostConfiguration((prevCostConfiguration) => ({
      ...prevCostConfiguration,
      [name as keyof ICostConfiguration]: moment(date).isValid()
        ? moment(date).toISOString()
        : null,
    }))
  }

  const handleSaveClick = async (): Promise<void> => {
    if (id) {
      if (updateCostConfiguration) {
        await updateCostConfiguration(id, costConfiguration as ICostConfiguration)
      }
    } else {
      if (createCostConfiguration) {
        await createCostConfiguration(costConfiguration as ICostConfiguration)
      }
    }
  }

  const handleCancelClick = (): void => {
    history.push(ROUTES_PATH.costConfigList)
  }

  return (
    <>
      <Card
        dataCy="configurationCard"
        title={t('CostConfigurationsScreen.configurations').toUpperCase()}
        contentClassName={classes.contentRef}
      >
        <div className={classes.fieldsContainer}>
          <div className={classes.filters}>
            <CarrierFilter
              data-cy="carriers"
              placeholder={t('CostConfigurationsScreen.carriers')}
              handleChange={(values: string[]): void => handleCarriersChange(values)}
              ids={costConfiguration.carrierIds || []}
              required
              shouldFillWidth
              onlyActives
              isUnselectAllowed={false}
            />
          </div>
          <DateRangePicker
            startDate={costConfiguration?.startDate || null}
            endDate={costConfiguration?.endDate || null}
            onChangeStart={(date): void => handleDateChange(date || null, 'startDate')}
            onChangeEnd={(date): void => handleDateChange(date || null, 'endDate')}
            hideShiftPicker
            dateType={DateType.DateTime}
            isStartClearable
            isFullWidth
            startLabel={t('CostConfigurationsScreen.startDate')}
            endLabel={t('CostConfigurationsScreen.endDate')}
            startDataCy="startDate"
            endDataCy="endDate"
          />
          <TextField
            data-cy="label"
            error={
              costConfiguration.label !== undefined
              && costConfiguration.label.toString().length === 0
            }
            label={t('CostConfigurationsScreen.label').toUpperCase()}
            onChange={handleInputChange}
            type="text"
            name="label"
            required
            value={costConfiguration.label !== undefined ? costConfiguration.label : ''}
          />
          <TextField
            data-cy="costPerTour"
            label={t('CostConfigurationsScreen.costPerTour').toUpperCase()}
            onChange={handleInputChange}
            type="number"
            name="costPerTour"
            value={costConfiguration.costPerTour !== undefined ? costConfiguration.costPerTour : ''}
          />
          <TextField
            data-cy="costPerStop"
            label={t('CostConfigurationsScreen.costPerStop').toUpperCase()}
            onChange={handleInputChange}
            type="number"
            name="costPerStop"
            value={costConfiguration.costPerStop !== undefined ? costConfiguration.costPerStop : ''}
          />
          <TextField
            data-cy="costPerKm"
            label={t('CostConfigurationsScreen.costPerKm').toUpperCase()}
            onChange={handleInputChange}
            type="number"
            name="costPerKm"
            value={costConfiguration.costPerKm !== undefined ? costConfiguration.costPerKm : ''}
          />
        </div>
      </Card>
      <FormAction onSaveClick={handleSaveClick} onCancelClick={handleCancelClick} />
    </>
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <CostConfigurationsProvider>
    <CostConfigFormScreen {...props} />
  </CostConfigurationsProvider>
)
