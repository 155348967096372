import { IError } from 'api/types'

const getNestedErrors = (errors: object): object =>
  Object.values(errors).map((error) => (Array.isArray(error) ? error : getNestedErrors(error)))

const getErrorList = (error: IError): string[] =>
  error.error.fieldErrors
    ? (getNestedErrors(error.error.fieldErrors) as string[]).flat(Infinity)
    : [error.error.message]

export { getErrorList }
