import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    arrow: {
      marginLeft: '35%',
      '&::after': {
        border: `1px solid ${theme.color.primary}`,
        background: theme.color.primary,
      },
    },
    sheet: {
      border: `1px solid ${theme.color.primary}`,
      background: theme.color.backgroundNavBar,
    },
    filterContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      rowGap: 8,
      flexWrap: 'wrap',
    },
    zipCode: {
      display: 'inline-block',
      maxWidth: 180,
      width: '100%',
      marginLeft: 8,
      verticalAlign: 'top',
    },
    distance: {
      marginLeft: 8,
      width: 140,
    },
    legendContainer: {
      textAlign: 'center',
      margin: 'auto',
      alignItems: 'center',
      flexFlow: 'column',
      display: 'flex',
      alignContent: 'center',
      width: '100%',
      justifyContent: 'center',
    },
    legend: {
      gridTemplateColumns: 'auto auto auto',
      margin: 'auto',
      display: 'grid',
    },
    totalLabel: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
  }),
)
