import Api from 'services/api'
import MdmApi from 'services/mdmApi'
import { IError } from 'api/types'
import {
  ICarrier,
  IDriver,
  IVehicle,
  IWarehouse,
  ICompany,
  IVehicleProfile,
  IVehicleType,
  IContent,
} from 'interfaces'
import { alphabeticSort } from 'utils/functions'
import { MAX_NUMBER_OF_ITEMS_BY_REQUEST } from 'constants/constants'
import { IPlanTemplate } from 'interfaces/IPlanTemplate'
import { ITenant } from 'interfaces/ITenant'
import { IUser } from 'interfaces/IUser'

async function getAllItems<T>(url: string, api = Api): Promise<Array<T>> {
  let items: T[] = []
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const promises: Promise<any>[] = []

  const res = await api.get(url, { limit: MAX_NUMBER_OF_ITEMS_BY_REQUEST })
  const { count } = res
  items = [...items, ...res.items]

  if (res.items.length < count) {
    const totalPage = Math.ceil(count / MAX_NUMBER_OF_ITEMS_BY_REQUEST)
    // eslint-disable-next-line no-plusplus
    for (let index = 1; index < totalPage; index++) {
      promises.push(
        api.get(url, {
          offset: index * MAX_NUMBER_OF_ITEMS_BY_REQUEST,
          limit: MAX_NUMBER_OF_ITEMS_BY_REQUEST,
        }),
      )
    }
    const restItems = (await Promise.all(promises)).map((item) => item.items)
    items = [...items, ...restItems].flat()
  }
  return items
}

const getWarehouses = async (): Promise<Array<IWarehouse> | IError> => {
  try {
    const res = await getAllItems<IContent>('/warehouses')
    return (res as Array<IWarehouse>).sort(alphabeticSort)
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getUsers = async (): Promise<Array<IUser> | IError> => {
  try {
    const res = await getAllItems<IUser>('/users')
    return res as Array<IUser>
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getCarriers = async (): Promise<Array<ICarrier> | IError> => {
  try {
    const res = await getAllItems<IContent>('/carriers')
    return (res as Array<ICarrier>).sort(alphabeticSort)
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getDrivers = async (): Promise<Array<IDriver> | IError> => {
  try {
    const res = await getAllItems<IContent>('/drivers')
    return (res as Array<IDriver>)
      .filter((driver) => driver.firstName && driver.lastName)
      .map((driver: IDriver) => ({
        ...driver,
        charge: 45,
        name: `${driver.firstName} ${driver.lastName}`,
      }))
      .sort(alphabeticSort)
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getVehicles = async (): Promise<Array<IVehicle> | IError> => {
  try {
    const res = await getAllItems<IContent>('/vehicles')
    return (res as Array<IVehicle>).sort(alphabeticSort)
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getCompanies = async (): Promise<Array<ICompany> | IError> => {
  try {
    const res = await getAllItems<IContent>('companies')
    return res.sort(alphabeticSort)
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getVehicleProfiles = async (): Promise<Array<IVehicleProfile> | IError> => {
  try {
    const res = await getAllItems<IContent>('/vehicle-profiles')
    return (
      res.map((item: IVehicleProfile) => ({
        id: item.id,
        name: item.label,
      })) as Array<IVehicleProfile>
    ).sort(alphabeticSort)
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getVehicleTypes = async (): Promise<Array<IVehicleType> | IError> => {
  try {
    const res = await getAllItems<IContent>('vehicle-types')
    return (
      res.map((item: IVehicleType) => ({
        ...item,
        name: item.label,
        active: item.active,
      })) as Array<IVehicleType>
    ).sort(alphabeticSort)
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getPlanTemplates = async (): Promise<Array<IPlanTemplate> | IError> => {
  try {
    const res = (await getAllItems<IContent>('plan-templates')) as unknown as Array<IPlanTemplate>
    return res
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getTenants = async (): Promise<Array<ITenant> | IError> => {
  try {
    const res = (await getAllItems<IContent>('tenants')) as unknown as Array<ITenant>
    return res
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getAllSites = async (): Promise<Array<IWarehouse> | IError> => {
  try {
    const res = (await getAllItems<IContent>('warehouses/all')) as unknown as Array<IWarehouse>
    return res
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getAllCompanies = async (): Promise<Array<ICompany> | IError> => {
  try {
    return (await getAllItems<IContent>('companies/all')) as unknown as Array<ICompany>
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getAllCarriers = async (): Promise<Array<ICarrier> | IError> => {
  try {
    const res = (await getAllItems<IContent>('carriers/all')) as unknown as Array<ICarrier>
    return res
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getDealers = async (): Promise<Array<IWarehouse> | IError> => {
  try {
    const res = (await getAllItems<IContent>('dealers', MdmApi)) as unknown as Array<IWarehouse>
    return res
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getAllDealers = async (): Promise<Array<IWarehouse> | IError> => {
  try {
    const res = (await getAllItems<IContent>('dealers/all', MdmApi)) as unknown as Array<IWarehouse>
    return res
  } catch (error) {
    return { error: { message: error.message } }
  }
}

export default {
  getCarriers,
  getAllCarriers,
  getDrivers,
  getVehicles,
  getWarehouses,
  getCompanies,
  getAllCompanies,
  getVehicleProfiles,
  getVehicleTypes,
  getPlanTemplates,
  getTenants,
  getAllSites,
  getDealers,
  getAllDealers,
  getUsers,
}
