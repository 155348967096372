import { IHighlightedElement } from 'interfaces/IHighlightedElement'
import React, { useState, createContext, ReactNode, Dispatch, SetStateAction } from 'react'

interface IExecutionContext {
  highlightedElement: IHighlightedElement | undefined
  setHighlightedElement: Dispatch<SetStateAction<IHighlightedElement | undefined>>
}

const ExecutionContext = createContext<IExecutionContext>({} as IExecutionContext)
const { Provider, Consumer } = ExecutionContext

interface IProps {
  children: ReactNode
}

const ExecutionProvider = ({ children }: IProps): JSX.Element => {
  const [highlightedElement, setHighlightedElement] = useState<IHighlightedElement>()

  return (
    <Provider
      value={{
        highlightedElement,
        setHighlightedElement,
      }}
    >
      {children}
    </Provider>
  )
}

export default ExecutionProvider

export { Consumer as ExecutionConsumer, ExecutionContext }
