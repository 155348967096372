import { useState, useEffect, useContext } from 'react'

import { isWarehouseMultisite } from 'utils/functions'
import { ContentContext } from 'store/ContentContext'

export const useMainSite = (siteIds?: string[]): boolean => {
  const [isMainSiteMultisite, setIsMainSiteMultisite] = useState<boolean>(false)
  const { sites } = useContext(ContentContext)

  useEffect(() => {
    if (siteIds && siteIds.length > 0) {
      const isMultisite = isWarehouseMultisite(siteIds[0], sites)
      setIsMainSiteMultisite(isMultisite)
    } else {
      setIsMainSiteMultisite(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteIds, sites])

  return isMainSiteMultisite
}

export default useMainSite
