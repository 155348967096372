import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import React, { useContext, useEffect, useState, Dispatch, SetStateAction } from 'react'
import Modal from '@material-ui/core/Modal'
import { TextField, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import PlansApi from 'api/plans'
import DateRangePicker from 'components/Inputs/DateRangePicker'
import { isIError } from 'api/types'
import {
  DeliveryTypeFilter,
  SiteFilter,
  CarrierFilter,
  VehicleCatFilter,
  TransportTypeFilter,
} from 'components/Inputs/ListFilter'
import ExpansionCard from 'components/Expansion/ExpansionCard'
import { IPlanTemplate } from 'interfaces/IPlanTemplate'
import { FeedbackContext } from 'store/FeedbackContext'
import { TransportType } from 'constants/constants'
import useMainSite from 'hooks/useMainSite'
import { formatPlanLabel, getNextLastMinute, getNextMidnight } from 'utils/planningUtils'
import { CustomPaper } from '../BasicComponents'
import ButtonsRow from '../ButtonsRow'
import { PlanificationContext } from '../../PlanningStore'
import PlanTemplateList from './PlanTemplateList'

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      overflow: 'auto',
      maxHeight: '100%',
    },
    table: {
      maxHeight: '326px',
      overflow: 'scroll',
    },
    activitySection: {
      display: 'flex',
      flexWrap: 'wrap',
      rowGap: '15px',
    },
  }),
)

interface ITextChange {
  target: { value: string }
}

interface IPlanCreationModal {
  isVisible: boolean
  isSpecificCreation: boolean
  toggleModal(): void
}

function PlanCreationModal({
  isVisible,
  toggleModal,
  isSpecificCreation,
}: IPlanCreationModal): JSX.Element {
  const { setSelectedPlan } = useContext(PlanificationContext)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [startDate, setStartDate] = useState<string | undefined>(
    isSpecificCreation ? getNextMidnight() : undefined,
  )
  const [endDate, setEndDate] = useState<string | undefined>(
    isSpecificCreation ? getNextLastMinute() : undefined,
  )
  const [planLabel, setPlanLabel] = useState<string>(
    isSpecificCreation && startDate ? formatPlanLabel(startDate) : '',
  )
  const [selectedWarehouse, setSelectedWarehouse] = useState<string[]>([])
  const [selectedSecondaryWarehouses, setSelectedSecondaryWarehouses] = useState<string[]>([])
  const [error, setError] = useState<string | null>(null)
  const [selectedDeliveryTypes, setSelectedDeliveryTypes] = useState<string[]>([])
  const [selectedTransportTypes, setSelectedTransportTypes] = useState<string[]>([])
  const [vehicleTypesIds, setVehicleTypesIds] = useState<string[]>([])
  const [carrierIds, setCarrierIds] = useState<string[]>([])
  const [vehicleDepartureMin, setVehicleDepartureMin] = useState<string | null>(null)
  const [vehicleReturnMax, setVehicleReturnMax] = useState<string | null>(null)
  const [ordersBegin, setOrdersBegin] = useState<string | null>(null)
  const [ordersEnd, setOrdersEnd] = useState<string | null>(null)
  const [errorCode, setErrorCode] = useState<number>()
  const { t } = useTranslation()
  const { openErrorSnack } = useContext(FeedbackContext)
  const classes = useStyles()

  useEffect(() => {
    if (error && !errorCode && selectedWarehouse && selectedWarehouse.length > 0) {
      setError(null)
    }
  }, [error, selectedWarehouse, errorCode])

  const isMainSiteMultisite = useMainSite(selectedWarehouse)

  async function onClickSave(): Promise<void> {
    if (selectedWarehouse && selectedWarehouse[0]) {
      setIsLoading(true)
      const res = await PlansApi.savePlan({
        label: planLabel,
        warehouseId: selectedWarehouse[0],
        secondaryWarehouseIds: selectedSecondaryWarehouses,
        begin: startDate || '',
        end: endDate || '',
        deliveryTypes: selectedDeliveryTypes,
        transportTypes: selectedTransportTypes as TransportType[],
        carrierIds,
        vehicleTypesIds,
        vehicleDepartureMin: vehicleDepartureMin || '',
        vehicleReturnMax: vehicleReturnMax || '',
        ordersBegin: ordersBegin || '',
        ordersEnd: ordersEnd || '',
      })
      setIsLoading(false)
      if (!isIError(res)) {
        setSelectedPlan(res)
        toggleModal()
      } else {
        openErrorSnack(res.error.message)
        if (res.error.code === 150) {
          setErrorCode(res.error.code)
          setError(t('PlanningScreen.planCreation.labelAlreadyExistsMessage'))
        }
      }
    } else {
      setError(t('PlanningScreen.planCreation.warehouseMissingMessage'))
    }
  }

  const onChangeText = (event: ITextChange): void =>
    setPlanLabel && setPlanLabel(event.target.value)

  function setSelectedItem(itemArray: string[], setter: (array: string[]) => void): void {
    if (itemArray && itemArray.length > 0) {
      setter([itemArray[itemArray.length - 1]])
    } else {
      setter([])
    }
  }

  function onChangeWarehouse(warehouseArray: string[]): void {
    setSelectedSecondaryWarehouses([])
    setSelectedItem(warehouseArray, setSelectedWarehouse)
  }

  const handleAddClick = (planTemplate: IPlanTemplate, date: string): void => {
    const begin = new Date(planTemplate.begin)
    const end = new Date(planTemplate.end)

    setPlanLabel(`${planTemplate.label} ${new Date(date).toLocaleDateString('fr')}`)
    const baseDate = moment(date).seconds(0).milliseconds(0)
    const startDateToSet = baseDate
      .clone()
      .hours(begin.getHours())
      .minutes(begin.getMinutes())
      .toISOString()
    setStartDate(startDateToSet)
    const endDateToSet = baseDate
      .clone()
      .add(planTemplate.dateOffset, 'days')
      .hours(end.getHours())
      .minutes(end.getMinutes())
      .toISOString()
    setEndDate(endDateToSet)

    let departureMinToSet
    if (planTemplate.vehicleDepartureMin) {
      const departureMin = new Date(planTemplate.vehicleDepartureMin)
      departureMinToSet = baseDate
        .clone()
        .hours(departureMin.getHours())
        .minutes(departureMin.getMinutes())
        .toISOString()
    } else {
      departureMinToSet = startDateToSet
    }
    setVehicleDepartureMin(departureMinToSet)

    let returnMaxToSet
    if (planTemplate.vehicleReturnMax) {
      const returnMax = new Date(planTemplate.vehicleReturnMax)
      returnMaxToSet = baseDate
        .clone()
        .add(planTemplate.dateOffset, 'days')
        .hours(returnMax.getHours())
        .minutes(returnMax.getMinutes())
        .toISOString()
    } else {
      returnMaxToSet = endDateToSet
    }
    setVehicleReturnMax(returnMaxToSet)

    let ordersBeginToSet
    if (planTemplate.ordersBegin) {
      const newOrdersBegin = new Date(planTemplate.ordersBegin)
      ordersBeginToSet = baseDate
        .clone()
        .hours(newOrdersBegin.getHours())
        .minutes(newOrdersBegin.getMinutes())
        .toISOString()
    } else {
      ordersBeginToSet = startDateToSet
    }
    setOrdersBegin(ordersBeginToSet)

    let ordersEndToSet
    if (planTemplate.ordersEnd) {
      const newOrdersEnd = new Date(planTemplate.ordersEnd)
      ordersEndToSet = baseDate
        .clone()
        .add(planTemplate.dateOffset, 'days')
        .hours(newOrdersEnd.getHours())
        .minutes(newOrdersEnd.getMinutes())
        .toISOString()
    } else {
      ordersEndToSet = endDateToSet
    }
    setOrdersEnd(ordersEndToSet)
    setSelectedDeliveryTypes(planTemplate.deliveryTypes.map(String))
    setSelectedTransportTypes(planTemplate.transportTypes)
    setSelectedWarehouse([planTemplate.warehouseId])
    setSelectedSecondaryWarehouses(planTemplate.secondaryWarehouseIds || [])
    setCarrierIds(planTemplate.carrierIds)
    setVehicleTypesIds(planTemplate.vehicleTypesIds)
  }

  const handleDateChange = (
    date: MaterialUiPickersDate,
    setter: Dispatch<SetStateAction<string | null>>,
  ): void => {
    if (moment(date).isValid()) {
      setter(moment(date).toISOString())
    }
  }

  return (
    <Modal
      open={isVisible}
      onClose={toggleModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <CustomPaper className={classes.paper}>
        {!isSpecificCreation && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography data-cy="modal-description">
                {t('PlanningScreen.planCreation.automatedPlanCreate')}
              </Typography>
            </Grid>
          </Grid>
        )}
        {!isSpecificCreation && (
          <ExpansionCard
            dataCy={t('PlanningScreen.planCreation.templates')}
            title={t('PlanningScreen.planCreation.templates')}
            isAlwaysExpanded
          >
            <Grid container spacing={3} className={classes.table}>
              <Grid item xs={12}>
                <PlanTemplateList onAddClick={handleAddClick} />
              </Grid>
            </Grid>
          </ExpansionCard>
        )}
        <ExpansionCard
          dataCy={t('PlanningScreen.planCreation.planDetails')}
          title={t('PlanningScreen.planCreation.planDetails')}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                data-cy="title-plan"
                value={planLabel}
                onChange={onChangeText}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <DateRangePicker
                onChangeStart={(date): void => setStartDate(moment(date).toISOString())}
                onChangeEnd={(date): void => setEndDate(moment(date).toISOString())}
                startDate={startDate}
                endDate={endDate}
                hideShiftPicker={!isSpecificCreation}
              />
            </Grid>
            <Grid data-cy={t('PlanningScreen.planCreation.activity')} item xs={12}>
              <Typography variant="h6" style={{ marginLeft: 8, marginBottom: 4 }}>
                {t('PlanningScreen.planCreation.activity')}
              </Typography>
              <div className={classes.activitySection}>
                <DeliveryTypeFilter
                  dataCy="deliveryPicker"
                  handleChange={(values: string[]): void => setSelectedDeliveryTypes(values)}
                  ids={selectedDeliveryTypes}
                />
                <TransportTypeFilter
                  dataCy="transportPicker"
                  handleChange={(values: string[]): void => setSelectedTransportTypes(values)}
                  ids={selectedTransportTypes}
                />
                <SiteFilter
                  onlyOneValue
                  handleChange={onChangeWarehouse}
                  ids={selectedWarehouse}
                  dataCy="sitePicker"
                  placeholder={t('tablesEntries.mainSite')}
                />
                {isMainSiteMultisite && (
                  <SiteFilter
                    dataCy="secondaryWarehousesPicker"
                    placeholder={t('PlanTemplatesScreen.secondarySites')}
                    handleChange={(values: string[]): void =>
                      setSelectedSecondaryWarehouses(values)}
                    ids={selectedSecondaryWarehouses}
                    excludedIds={selectedWarehouse}
                  />
                )}
                <DateRangePicker
                  onChangeStart={(date): void => handleDateChange(date, setOrdersBegin)}
                  onChangeEnd={(date): void => handleDateChange(date, setOrdersEnd)}
                  startDate={ordersBegin}
                  endDate={ordersEnd}
                  startLabel={t('PlanTemplatesScreen.ordersBegin')}
                  endLabel={t('PlanTemplatesScreen.ordersEnd')}
                  hideShiftPicker
                  startDataCy="orderStartPicker"
                  endDataCy="orderEndPicker"
                />
              </div>
            </Grid>
            <Grid data-cy={t('PlanningScreen.planCreation.vehicles')} item xs={12}>
              <Typography variant="h6" style={{ marginLeft: 8, marginBottom: 4 }}>
                {t('PlanningScreen.planCreation.vehicles')}
              </Typography>
              <DateRangePicker
                onChangeStart={(date): void => handleDateChange(date, setVehicleDepartureMin)}
                onChangeEnd={(date): void => handleDateChange(date, setVehicleReturnMax)}
                startDate={vehicleDepartureMin || null}
                endDate={vehicleReturnMax || null}
                startLabel={t('PlanTemplatesScreen.departureTime')}
                endLabel={t('PlanTemplatesScreen.returnTime')}
                hideShiftPicker
                startDataCy="departureTimePicker"
                endDataCy="returnTimePicker"
              />
              <CarrierFilter
                handleChange={(values: string[]): void => setCarrierIds(values)}
                ids={carrierIds}
                onlyActives
                dataCy="carrierPicker"
                filterIds={carrierIds}
                siteIds={
                  isMainSiteMultisite
                    ? selectedWarehouse.concat(...selectedSecondaryWarehouses)
                    : selectedWarehouse
                }
              />
              <VehicleCatFilter
                handleChange={(values: string[]): void => setVehicleTypesIds(values)}
                ids={vehicleTypesIds}
                onlyActives
                dataCy="vehicleTypePicker"
              />
            </Grid>
          </Grid>
        </ExpansionCard>
        <ButtonsRow
          isLoading={isLoading}
          toggleModal={toggleModal}
          error={error}
          onClickCTA={onClickSave}
        />
      </CustomPaper>
    </Modal>
  )
}

export default PlanCreationModal
