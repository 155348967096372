import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Grid, CardHeader, CardContent, Tabs, Tab } from '@material-ui/core'

import { ICarrierWarehouse, IWarehouse } from 'interfaces/interfaces'
import { IIdParam } from 'interfaces/IIdParam'
import { WAREHOUSE_TOUR_TYPES, WAREHOUSE_TYPES } from 'constants/constants'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import SitesProvider, { SitesConsumer } from 'store/SitesContext'
import CustomCard from 'components/CustomCard/CustomCard'
import CardLine from 'components/CardLine/CardLine'
import Button from 'components/Button/CustomButton'
import { formatDateInterval, intervalContainsDate } from 'utils/libs/date-range'
import useStyles from './styles'

interface IProps {
  getDetails?: Function
  siteDetails: IWarehouse
}

const SitesDetailsScreen = ({ getDetails, siteDetails }: IProps): JSX.Element => {
  const { id } = useParams<IIdParam>()
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()
  const [tabValue, setTabValue] = useState<number>(0)

  useEffect(() => {
    if (id && getDetails) {
      getDetails(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const tabChanged = (e: unknown, value: number): void => {
    setTabValue(value)
  }

  const GeneralPanel = (): JSX.Element => (
    <Grid container spacing={2}>
      <Grid data-cy="profilCard" item xs={6}>
        <CustomCard
          cardTitle={t('SitesScreen.profil')}
          linePairs={[
            { label: t('SitesScreen.label'), value: siteDetails ? siteDetails.name : '' },
            { label: t('SitesScreen.code'), value: siteDetails ? siteDetails.code : '' },
            {
              label: t('SitesScreen.function'),
              value: siteDetails
                ? WAREHOUSE_TYPES.find((warehouseType) => +warehouseType.id === siteDetails.type)
                  ?.name
                : '',
            },
            {
              label: t('SitesScreen.typeOfTour'),
              value: siteDetails
                ? WAREHOUSE_TOUR_TYPES.find((tourType) => tourType.id === siteDetails.tourType)
                  ?.name
                : '',
            },
            {
              label: t('SitesScreen.address'),
              value: siteDetails && siteDetails.address ? siteDetails.address.full : '',
            },
            {
              label: t('SitesScreen.phone'),
              value: siteDetails ? siteDetails.phone : '',
            },
            {
              label: t('SitesScreen.customerServicePhone'),
              value: siteDetails ? siteDetails.customerServicePhone : '',
            },
            {
              label: t('SitesScreen.accessInstructions'),
              value: siteDetails ? siteDetails.accessInstructions : '',
            },
            {
              label: t('SitesScreen.active'),
              value:
                siteDetails.active !== undefined
                  ? siteDetails.active
                    ? t('SitesScreen.yes')
                    : t('SitesScreen.no')
                  : '',
            },
            {
              label: t('SitesScreen.autoCreatePlanVisits'),
              value:
                siteDetails.autoCreatePlanVisits !== undefined
                  ? siteDetails.autoCreatePlanVisits
                    ? t('SitesScreen.yes')
                    : t('SitesScreen.no')
                  : '',
            },
          ]}
        />
      </Grid>
      <Grid data-cy="driverReferenceCard" item xs={6}>
        <CardHeader
          title={t('SitesScreen.carrierRef').toLocaleUpperCase()}
          className={classes.headerDetails}
          titleTypographyProps={{ variant: 'subtitle1' }}
        />
        <CardContent className={classes.contentDetails}>
          <Grid container>
            {siteDetails?.carriers
              && siteDetails?.carriers.map((carrier) => generateCardLine(carrier))}
          </Grid>
        </CardContent>
      </Grid>
    </Grid>
  )

  const handleEditClick = (): void => {
    history.push(`${ROUTES_PATH.editSite}${id}`)
  }

  return (
    <Grid container direction="column">
      <Grid item className={classes.detailsContainer}>
        <h3 data-cy="siteDetailDescription">{`${siteDetails ? siteDetails.name : ''}`}</h3>
        <Button dataCy="updateButton" onPress={handleEditClick}>
          {t('SitesScreen.edit')}
        </Button>
      </Grid>
      <Grid container>
        <Tabs
          value={tabValue}
          indicatorColor="primary"
          onChange={tabChanged}
          aria-label="detail sections"
          className={classes.tabs}
        >
          <Tab data-cy="generalButton" label={t('SitesScreen.general')} />
        </Tabs>
        <GeneralPanel />
      </Grid>
    </Grid>
  )
}

function generateCardLine(carrier: ICarrierWarehouse): JSX.Element {
  const { validityStartDate, validityEndDate } = carrier
  const value = formatDateInterval(validityStartDate, validityEndDate)
  const isActive = intervalContainsDate(new Date(), validityStartDate, validityEndDate)
  return <CardLine key={carrier.id} label={carrier.name} value={value} isDisabled={!isActive} />
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <SitesProvider>
    <SitesConsumer>
      {(sitesCtx): JSX.Element => (
        <SitesDetailsScreen
          getDetails={sitesCtx.getDetails}
          siteDetails={sitesCtx.siteDetails}
          {...props}
        />
      )}
    </SitesConsumer>
  </SitesProvider>
)
