import React, { useContext, useState } from 'react'
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import { AppBar } from '@material-ui/core/'
import CreatableSelect from 'react-select/creatable'
import { OptionTypeBase } from 'react-select'

import Toolbar from '@material-ui/core/Toolbar'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import DateRangePicker from 'components/Inputs/DateRangePicker'
import {
  DeliveryTypeFilter,
  PlanTourStatusFilter,
  SiteFilter,
  TransportTypeFilter,
} from 'components/Inputs/ListFilter'
import SearchInput from 'components/Inputs/SearchInput'

import { ControlComponent, getCreatableSelectStyle } from 'components/Inputs/CreatableSelect'
import { TENANTS_WITH_DELIVERY_MAX } from 'constants/env'
import { AuthContext } from 'store/AuthContext'
import { FiltersContainer, SearchContainer } from 'components/Layout'
import { PlanificationContext } from '../../PlanningStore'
import PlanMenu from './PlanMenu'
import OptimizationMenu from '../OptimizationMenu'

interface IToolBar {
  toggleCreationModal(isSpecific: boolean): void
  toggleFiltersModal(): void
  toggleOptimizeModal(): void
}

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      backgroundColor: theme.color.backgroundCustomToolbar,
    },
    emptySpace: {
      flexGrow: 1,
    },
    toolbar: {
      paddingTop: 8,
      paddingBottom: 8,
    },
    zipCode: {
      marginLeft: 8,
      paddingTop: 0,
      maxWidth: 180,
      width: '100%',
    },
  }),
)

function CustomToolbar({
  toggleCreationModal,
  toggleFiltersModal,
  toggleOptimizeModal,
}: IToolBar): JSX.Element {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    deliveryMaxEndDate,
    setDeliveryMaxEndDate,
    selectedPlan,
    selectedWarehouse,
    setSelectedWarehouse,
    selectedDeliveryType,
    setSelectedDeliveryType,
    selectedTransportTypes,
    setSelectedTransportTypes,
    setSearchText,
    setPlanTourStatus,
    planTourStatus,
    getPlanTours,
    isMapotempoActionInProgress,
    setZipCodes,
    zipCodes,
  } = useContext(PlanificationContext)
  const [zipCodeInputText, setZipCodeInputText] = useState<string>('')
  const theme = useTheme()
  const { user } = useContext(AuthContext)

  const handlePlanTourStatusFilter = (values: string[]) => {
    setPlanTourStatus(values)
    getPlanTours()
  }

  const handleZipCodeChange = (zipCodeValues: OptionTypeBase): void => {
    if (zipCodes.length > 0) {
      setZipCodes(zipCodeValues as OptionTypeBase[])
    }
  }

  const handleZipCodeKeyDown = (event: React.KeyboardEvent<HTMLElement>): void => {
    if (!zipCodeInputText) return
    // eslint-disable-next-line default-case
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setZipCodeInputText('')
        setZipCodes((prev) =>
          prev.find((zipCode) => zipCode.value === zipCodeInputText)
            ? prev
            : [...prev, { label: zipCodeInputText, value: zipCodeInputText }],
        )
        event.preventDefault()
    }
  }

  return (
    <AppBar position="sticky" elevation={1} className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <PlanMenu
          toggleCreationModal={toggleCreationModal}
          toggleFiltersModal={toggleFiltersModal}
        />
        <FiltersContainer>
          <DateRangePicker
            onChangeStart={(date): void => setStartDate(moment(date).toISOString())}
            onChangeEnd={(date): void => setEndDate(moment(date).toISOString())}
            startDate={startDate}
            endDate={endDate}
            isStartDisabled={isMapotempoActionInProgress}
            isEndDisabled={isMapotempoActionInProgress}
            hideShiftPicker
          />
          {TENANTS_WITH_DELIVERY_MAX
            && user?.tenantId
            && TENANTS_WITH_DELIVERY_MAX.includes(user?.tenantId) && (
              <DateRangePicker
                endDataCy="deliveryMaxEndDate"
                endLabel={t('PlanningScreen.deliveryMaxEndDate')}
                endDate={deliveryMaxEndDate}
                onChangeStart={(): void => {
                  /** */
                }}
                onChangeEnd={(value): void => {
                  setDeliveryMaxEndDate(moment(value).toISOString())
                }}
                hideStartPicker
                hideShiftPicker
              />
          )}
          <SiteFilter
            handleChange={(values: string[]): void => setSelectedWarehouse(values)}
            ids={selectedWarehouse}
            placeholder={t('tablesEntries.sites')}
            disabled={isMapotempoActionInProgress}
            dataCy="sitePicker"
            filterIds={
              selectedPlan
                ? [selectedPlan.warehouseId, ...selectedPlan.secondaryWarehouseIds]
                : undefined
            }
          />
          <DeliveryTypeFilter
            handleChange={(values: string[]): void => setSelectedDeliveryType(values)}
            ids={selectedDeliveryType}
            disabled={isMapotempoActionInProgress}
            dataCy="deliveryPicker"
          />
          <TransportTypeFilter
            handleChange={(values: string[]): void => setSelectedTransportTypes(values)}
            ids={selectedTransportTypes || []}
            disabled={isMapotempoActionInProgress}
            dataCy="transportPicker"
          />
          <CreatableSelect
            styles={getCreatableSelectStyle(
              theme.color.backgroundCustomToolbar,
              theme.color.label,
              theme.color.secondaryFieldBorder,
              theme.color.hover,
            )}
            className={classes.zipCode}
            components={{ DropdownIndicator: null, Control: ControlComponent }}
            inputValue={zipCodeInputText}
            onInputChange={setZipCodeInputText}
            isClearable
            isMulti
            menuIsOpen={false}
            onChange={handleZipCodeChange}
            onKeyDown={handleZipCodeKeyDown}
            placeholder={t('PlanningScreen.zipCode')}
            value={zipCodes}
            backgroundColor={theme.color.backgroundCustomToolbar}
            color={theme.color.label}
            data-cy="zipCode"
          />
        </FiltersContainer>
        <SearchContainer>
          <PlanTourStatusFilter
            handleChange={(values: string[]): void => handlePlanTourStatusFilter(values)}
            ids={planTourStatus}
            disabled={isMapotempoActionInProgress}
            dataCy="statusPicker"
          />
          <SearchInput isDisabled={isMapotempoActionInProgress} onSearch={setSearchText} />
        </SearchContainer>
        {selectedPlan && selectedPlan.isOptimizable && !selectedPlan.deletedOnThirdParty && (
          <OptimizationMenu toggleOptimizeModal={toggleOptimizeModal} />
        )}
      </Toolbar>
    </AppBar>
  )
}

export default CustomToolbar
