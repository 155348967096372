import React from 'react'
import { Grid, Tooltip } from '@material-ui/core'
import clsx from 'clsx'

import useStyles from './styles'

type CardDoubleLineProps = {
  onCardClick: () => void
  isSelected?: boolean
  firstLineLabel: string
  firstLineValue: string
  secondLineLabel: string
  secondLineValue: string
  Icon?: React.ReactNode
  iconTooltip?: string
}

export default ({
  onCardClick,
  isSelected,
  firstLineLabel,
  firstLineValue,
  secondLineLabel,
  secondLineValue,
  Icon,
  iconTooltip,
}: CardDoubleLineProps): JSX.Element => {
  const classes = useStyles()

  return (
    <Grid
      container
      direction="column"
      className={clsx(classes.cardLine, isSelected && classes.selected)}
      style={{ cursor: 'pointer' }}
      onClick={onCardClick}
      data-cy="container-row"
    >
      <Grid container data-cy="row">
        <Grid item xs={Icon ? 5 : 6} className={classes.cardLineLabel}>
          {firstLineLabel.toUpperCase()}
        </Grid>
        <Grid item xs={6}>
          {firstLineValue}
        </Grid>
        {Icon && iconTooltip && (
          <Grid item xs={1} className={classes.icon}>
            <Tooltip title={iconTooltip}>
              <div>{Icon}</div>
            </Tooltip>
          </Grid>
        )}
      </Grid>
      <Grid container className={classes.cardDoubleLine} data-cy="row">
        <Grid item xs={Icon ? 5 : 6} className={classes.cardLineLabel}>
          {secondLineLabel.toUpperCase()}
        </Grid>
        <Grid item xs={6}>
          {secondLineValue}
        </Grid>
      </Grid>
    </Grid>
  )
}
