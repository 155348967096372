import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormControlLabel, Switch, TextField } from '@material-ui/core'

import useStyles from 'constants/cruStyles'
import Card from 'components/Card/Card'
import { IOptimizationParam } from 'interfaces/IOptimizationParam'
import { IOptimizationRouterOptions } from 'interfaces/IOptimizationRouterOptions'
import SearchableSelect from 'components/Inputs/SearchableSelect'
import {
  OPTIMIZATION_ENGINE_OPTIONS,
  OPTIMIZATION_ITINERARY_CALCULATOR_OPTIONS,
} from 'constants/constants'
import { alphabeticSort } from 'utils/functions'
import { OptimizationEngine, OptimizationItineraryCalculatorType } from 'interfaces/interfaces'

interface ITextChange {
  target: {
    value: string
    name: string
    type?: string
    checked?: boolean
  }
}

interface IProps {
  averageLoadingTime?: Partial<number>
  optimParam?: Partial<IOptimizationParam>
  onChange: (optimParam: IOptimizationParam) => void
  onAverageLoadingTimeChange: (averageLoadingTime?: number) => void
}

const WarehouseOptimParamCard = ({
  averageLoadingTime,
  optimParam,
  onChange,
  onAverageLoadingTimeChange,
}: IProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [param, setParam] = useState<Partial<IOptimizationParam>>({
    isOptimizable: optimParam?.isOptimizable,
    daysBeforePlansExpiration: optimParam?.daysBeforePlansExpiration,
    maximumOptimizationTime: optimParam?.maximumOptimizationTime,
    speedMultiplier: optimParam?.speedMultiplier,
    setupDuration: optimParam?.setupDuration,
    visitDuration: optimParam?.visitDuration,
    maxDestinations: optimParam?.maxDestinations,
    multiTourPermission: optimParam?.multiTourPermission,
    nbToursPerVehicle: optimParam?.nbToursPerVehicle,
    delayBetweenTours: optimParam?.delayBetweenTours,
    optimizationEngine: optimParam?.optimizationEngine,
    optimizationMaxSplitSize: optimParam?.optimizationMaxSplitSize,
    optimizationStopSoftUpperBound: optimParam?.optimizationStopSoftUpperBound,
    optimizationVehicleSoftUpperBound: optimParam?.optimizationVehicleSoftUpperBound,
    optimizationCostWaitingTime: optimParam?.optimizationCostWaitingTime,
    optimizationItineraryCalculator: optimParam?.optimizationItineraryCalculator,
    isMultisite: optimParam?.isMultisite,
    loadingTimePerOrderMultisite: optimParam?.loadingTimePerOrderMultisite,
    toleratedAdvance: optimParam?.toleratedAdvance,
    toleratedDelay: optimParam?.toleratedDelay,
  })
  const [loadingTime, setLoadingTime] = useState<number | undefined>(
    averageLoadingTime ? Number(averageLoadingTime) : undefined,
  )
  const [routerOptions, setRouterOptions] = useState<IOptimizationRouterOptions>({
    traffic: optimParam?.routerOptions?.traffic,
    motorway: optimParam?.routerOptions?.motorway,
    toll: optimParam?.routerOptions?.toll,
    maxWalkDistance: optimParam?.routerOptions?.maxWalkDistance,
    strictRestriction: optimParam?.routerOptions?.strictRestriction,
  })

  const handleOptimParamInputChange = (event: ITextChange): void => {
    const { target } = event
    const { name } = target
    const value = target.type === 'checkbox' ? target.checked : target.value

    onChange({ ...param, [name]: value } as IOptimizationParam)
    setParam({ ...param, [name]: value })
  }

  const handleAverageLoadingTimeChange = (event: ITextChange): void => {
    const { target } = event
    const value = target.value ? Number(target.value) : undefined
    onAverageLoadingTimeChange(value)
    setLoadingTime(value)
  }

  const handleRouterOptionsInputChange = (event: ITextChange): void => {
    const { target } = event
    const { name } = target
    const value = target.type === 'checkbox' ? target.checked : target.value

    onChange({
      ...param,
      routerOptions: {
        ...routerOptions,
        [name]: value,
      },
    } as IOptimizationParam)
    setRouterOptions({ ...routerOptions, [name]: value })
  }

  const handleSelectChange = (
    event: React.ChangeEvent<{ value: unknown; name?: string | undefined }>,
  ): void => {
    const { target } = event
    const name = target.name as string
    const value = target.value as string

    onChange({ ...param, [name]: value } as IOptimizationParam)
    setParam({ ...param, [name]: value })
  }

  return (
    <Card title={t('SitesScreen.optimParams').toUpperCase()}>
      <div className={classes.fieldsContainer}>
        <div>
          <FormControlLabel
            className={classes.switchContainer}
            control={(
              <Switch
                color="primary"
                checked={param.isOptimizable || false}
                onChange={handleOptimParamInputChange}
                name="isOptimizable"
              />
            )}
            labelPlacement="start"
            label={t('SitesScreen.optimParam.isOptimizable').toUpperCase()}
          />
        </div>
        <div>
          <FormControlLabel
            className={classes.switchContainer}
            control={(
              <Switch
                color="primary"
                checked={param.isMultisite || false}
                onChange={handleOptimParamInputChange}
                name="isMultisite"
              />
            )}
            labelPlacement="start"
            label={t('SitesScreen.optimParam.isMultisite').toUpperCase()}
          />
        </div>
        <div>
          <FormControlLabel
            className={classes.switchContainer}
            control={(
              <Switch
                color="primary"
                checked={param.multiTourPermission || false}
                onChange={handleOptimParamInputChange}
                name="multiTourPermission"
              />
            )}
            labelPlacement="start"
            label={t('SitesScreen.optimParam.multiTourPermission').toUpperCase()}
          />
        </div>
        {param.multiTourPermission && (
          <TextField
            label={t('SitesScreen.optimParam.nbToursPerVehicle').toUpperCase()}
            type="number"
            onChange={handleOptimParamInputChange}
            value={param.nbToursPerVehicle || ''}
            name="nbToursPerVehicle"
          />
        )}
        <TextField
          label={t('SitesScreen.optimParam.delayBetweenTours').toUpperCase()}
          type="number"
          onChange={handleOptimParamInputChange}
          value={param.delayBetweenTours || ''}
          name="delayBetweenTours"
        />
        <TextField
          label={t('SitesScreen.optimParam.daysBeforePlansExpiration').toUpperCase()}
          type="number"
          onChange={handleOptimParamInputChange}
          value={param.daysBeforePlansExpiration || ''}
          name="daysBeforePlansExpiration"
        />
        <SearchableSelect
          dataCy="optimizationEngine"
          label={t('SitesScreen.optimParam.optimizationEngine')}
          name="optimizationEngine"
          selectedValue={
            param.optimizationEngine !== undefined && param.optimizationEngine !== null
              ? param.optimizationEngine
              : OptimizationEngine.Kvrp
          }
          options={OPTIMIZATION_ENGINE_OPTIONS.sort(alphabeticSort)}
          onChange={handleSelectChange}
        />
        <SearchableSelect
          dataCy="optimizationItineraryCalculator"
          label={t('SitesScreen.optimParam.optimizationItineraryCalculator')}
          name="optimizationItineraryCalculator"
          selectedValue={
            param.optimizationItineraryCalculator !== undefined
            && param.optimizationItineraryCalculator !== null
              ? param.optimizationItineraryCalculator
              : OptimizationItineraryCalculatorType.EUROPE
          }
          options={OPTIMIZATION_ITINERARY_CALCULATOR_OPTIONS.sort(alphabeticSort)}
          onChange={handleSelectChange}
        />
        <TextField
          label={t('SitesScreen.optimParam.maximumOptimizationTime').toUpperCase()}
          type="number"
          onChange={handleOptimParamInputChange}
          value={param.maximumOptimizationTime || ''}
          name="maximumOptimizationTime"
        />
        <TextField
          label={t('SitesScreen.optimParam.speedMultiplier').toUpperCase()}
          type="number"
          onChange={handleOptimParamInputChange}
          value={param.speedMultiplier || ''}
          name="speedMultiplier"
        />
        <TextField
          label={t('SitesScreen.optimParam.setupDuration').toUpperCase()}
          type="number"
          onChange={handleOptimParamInputChange}
          value={param.setupDuration || ''}
          name="setupDuration"
        />
        <TextField
          label={t('SitesScreen.optimParam.visitDuration').toUpperCase()}
          type="number"
          onChange={handleOptimParamInputChange}
          value={param.visitDuration || ''}
          name="visitDuration"
        />
        <TextField
          label={t('SitesScreen.optimParam.loadingTimePerOrderMultisite').toUpperCase()}
          type="number"
          onChange={handleOptimParamInputChange}
          value={param.loadingTimePerOrderMultisite || ''}
          name="loadingTimePerOrderMultisite"
        />
        <TextField
          label={t('SitesScreen.optimParam.maxDestinations').toUpperCase()}
          type="number"
          onChange={handleOptimParamInputChange}
          value={param.maxDestinations || ''}
          name="maxDestinations"
        />
        <TextField
          label={t('SitesScreen.optimParam.optimizationMaxSplitSize').toUpperCase()}
          type="number"
          onChange={handleOptimParamInputChange}
          value={param.optimizationMaxSplitSize || ''}
          name="optimizationMaxSplitSize"
        />
        <TextField
          label={t('SitesScreen.optimParam.optimizationStopSoftUpperBound').toUpperCase()}
          type="number"
          onChange={handleOptimParamInputChange}
          value={param.optimizationStopSoftUpperBound || ''}
          name="optimizationStopSoftUpperBound"
        />
        <TextField
          label={t('SitesScreen.optimParam.optimizationVehicleSoftUpperBound').toUpperCase()}
          type="number"
          onChange={handleOptimParamInputChange}
          value={param.optimizationVehicleSoftUpperBound || ''}
          name="optimizationVehicleSoftUpperBound"
        />
        <TextField
          label={t('SitesScreen.optimParam.optimizationCostWaitingTime').toUpperCase()}
          type="number"
          onChange={handleOptimParamInputChange}
          value={param.optimizationCostWaitingTime || ''}
          name="optimizationCostWaitingTime"
        />
        <div>
          <FormControlLabel
            className={classes.switchContainer}
            control={(
              <Switch
                color="primary"
                checked={routerOptions.traffic || false}
                onChange={handleRouterOptionsInputChange}
                name="traffic"
              />
            )}
            labelPlacement="start"
            label={t('SitesScreen.optimParam.routerOptions.traffic').toUpperCase()}
          />
        </div>
        <div>
          <FormControlLabel
            className={classes.switchContainer}
            control={(
              <Switch
                color="primary"
                checked={routerOptions.motorway || false}
                onChange={handleRouterOptionsInputChange}
                name="motorway"
              />
            )}
            labelPlacement="start"
            label={t('SitesScreen.optimParam.routerOptions.motorway').toUpperCase()}
          />
        </div>
        <div>
          <FormControlLabel
            className={classes.switchContainer}
            control={(
              <Switch
                color="primary"
                checked={routerOptions.toll || false}
                onChange={handleRouterOptionsInputChange}
                name="toll"
              />
            )}
            labelPlacement="start"
            label={t('SitesScreen.optimParam.routerOptions.toll').toUpperCase()}
          />
        </div>
        <TextField
          label={t('SitesScreen.optimParam.routerOptions.maxWalkDistance').toUpperCase()}
          type="number"
          onChange={handleRouterOptionsInputChange}
          value={routerOptions.maxWalkDistance || ''}
          name="maxWalkDistance"
        />
        <div>
          <FormControlLabel
            className={classes.switchContainer}
            control={(
              <Switch
                color="primary"
                checked={routerOptions.strictRestriction || false}
                onChange={handleRouterOptionsInputChange}
                name="strictRestriction"
              />
            )}
            labelPlacement="start"
            label={t('SitesScreen.optimParam.routerOptions.strictRestriction').toUpperCase()}
          />
        </div>
        <TextField
          label={t('SitesScreen.averageLoadingTime').toUpperCase()}
          type="number"
          onChange={handleAverageLoadingTimeChange}
          value={loadingTime || ''}
          name="averageLoadingTime"
        />
        <TextField
          label={t('SitesScreen.optimParam.toleratedAdvance').toUpperCase()}
          type="number"
          onChange={handleOptimParamInputChange}
          value={param.toleratedAdvance || ''}
          name="toleratedAdvance"
        />
        <TextField
          label={t('SitesScreen.optimParam.toleratedDelay').toUpperCase()}
          type="number"
          onChange={handleOptimParamInputChange}
          value={param.toleratedDelay || ''}
          name="toleratedDelay"
        />
      </div>
    </Card>
  )
}

export default WarehouseOptimParamCard
