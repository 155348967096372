import React from 'react'
import { useTranslation } from 'react-i18next'

import Card from 'components/Card/Card'
import useStyles from 'constants/cruStyles'
import { SiteFilter } from 'components/Inputs/ListFilter'

interface IProps {
  onSitesChange: (siteIds: string[]) => void
  onDefaultSiteChange: (siteId: string) => void
  sites?: string[]
  defaultSite?: string
}

const WarehouseCard = ({
  onSitesChange,
  onDefaultSiteChange,
  sites,
  defaultSite,
}: IProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Card
      dataCy="warehouseCard"
      title={t('UsersScreen.sites').toUpperCase()}
      contentClassName={classes.contentRef}
    >
      <div className={classes.filters}>
        <SiteFilter
          handleChange={onSitesChange}
          ids={sites || []}
          onlyActives
          shouldFillWidth
          required
          dataCy="sitePicker"
          shouldUseUserSites={false}
        />
      </div>
      <div className={classes.filters}>
        <SiteFilter
          placeholder={t('UsersScreen.defaultSite')}
          handleChange={(ids: string[]) => onDefaultSiteChange(ids[0])}
          ids={defaultSite ? [defaultSite] : []}
          shouldFillWidth
          onlyOneValue
          filterIds={sites}
          disabled={!sites?.length}
          required
          dataCy="sitePickerByDefault"
          shouldUseUserSites={false}
        />
      </div>
    </Card>
  )
}

export default WarehouseCard
