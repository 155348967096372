import React, { useState, createContext, ReactNode, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { AuthContext } from 'store/AuthContext'
import { isIError, IError } from 'api/types'
import { ILogisticShift } from 'interfaces/ILogisticShift'
import ShiftsApi from 'api/shifts'
import { getErrorList } from 'utils/errorUtils'
import { FeedbackContext } from 'store/FeedbackContext'

interface IShiftsContext {
  shifts: ILogisticShift[]
  error?: IError
  getLogisticShifts: () => void
  updateLogisticShifts: (logisticShifts: ILogisticShift[]) => void
}

const { Provider, Consumer } = createContext<IShiftsContext>({} as IShiftsContext)

interface IProps {
  children: ReactNode
}

const ShiftsProvider = ({ children }: IProps): JSX.Element => {
  const { getUserData } = useContext(AuthContext)
  const { openErrorSnack, openSuccessSnack, toggleLoader } = useContext(FeedbackContext)
  const [shifts, setShifts] = useState<ILogisticShift[]>([])
  const { t } = useTranslation()

  const getLogisticShifts = async (): Promise<void> => {
    toggleLoader(true)
    const response = await ShiftsApi.getLogisticShifts()
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      setShifts(response)
    }
    toggleLoader(false)
  }

  const updateLogisticShifts = async (logisticShifts: ILogisticShift[]): Promise<void> => {
    toggleLoader(true)
    const response = await ShiftsApi.updateLogisticShifts(logisticShifts)
    if (isIError(response)) {
      response.error.errorList = getErrorList(response)
      if (response.error.fieldErrors) {
        openErrorSnack(response.error.errorList.join('\n'))
      } else {
        openErrorSnack(response.error.message)
      }
    } else {
      getUserData(true)
      openSuccessSnack(t('ConfigurationsScreen.successfullySaved'))
    }
    toggleLoader(false)
  }

  return (
    <Provider
      value={{
        shifts,
        getLogisticShifts,
        updateLogisticShifts,
      }}
    >
      {children}
    </Provider>
  )
}

export default ShiftsProvider

export { Consumer as ShiftsConsumer }
