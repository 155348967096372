import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { FORM_ACTION_HEIGHT } from 'components/Button/styles'

import { HEADER_HEIGHT, MAIN_PADDING } from 'components/NavigationLayout/styles'
import { STEP_HEIGHT } from 'components/Stepper/styles'

const SCROLLBAR_WIDTH = 8

const CUSTOMER_LINE_HEIGHT = 34

const AUTOCOMPLETE_RESULTS_CONTAINER_GAP = 8

const NUMBER_OF_CUSTOMERS_TO_DISPLAY = 5

const CUSTOMER_LINE_BOX_SHADOW_OFFSET = 2

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      paddingLeft: '20px',
      paddingRight: '20px',
      gap: '15px',
    },
    dialog: {
      width: '600px',
    },
    dialogAddressesContainer: {
      marginLeft: '32px',
      marginRight: '32px',
    },
    autocompleteResultsContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: AUTOCOMPLETE_RESULTS_CONTAINER_GAP,
      paddingLeft: '20px',
      borderLeft: '1px solid grey',
      marginLeft: '20px',
      overflow: 'auto',
    },
    addressResultsContainer: {
      maxHeight: '300px',
    },
    customerResultsContainer: {
      maxHeight: NUMBER_OF_CUSTOMERS_TO_DISPLAY * CUSTOMER_LINE_HEIGHT
        + (NUMBER_OF_CUSTOMERS_TO_DISPLAY - 1) * AUTOCOMPLETE_RESULTS_CONTAINER_GAP
        + CUSTOMER_LINE_BOX_SHADOW_OFFSET,
      paddingBottom: CUSTOMER_LINE_BOX_SHADOW_OFFSET,
    },
    addressLine: {
      cursor: 'pointer',
      border: '1px solid grey',
      borderRadius: '8px',
      boxShadow: `2px 2px 2px 0px`,
      width: '100%',
      padding: '6px',
      display: 'flex',
      justifyContent: 'space-between',
      gap: '6px',
    },
    addressLineWithMargin: {
      marginBottom: '10px',
      cursor: 'pointer',
      border: '1px solid grey',
      borderRadius: '8px',
      boxShadow: `2px 2px 2px 0px`,
      width: '100%',
      padding: '6px',
      display: 'flex',
      justifyContent: 'space-between',
      gap: '6px',
      alignItems: 'center',
    },
    customerLine: {
      cursor: 'pointer',
      border: '1px solid grey',
      borderRadius: '8px',
      boxShadow: `${CUSTOMER_LINE_BOX_SHADOW_OFFSET}px ${CUSTOMER_LINE_BOX_SHADOW_OFFSET}px 2px 0px`,
      width: '100%',
      padding: '6px',
      display: 'flex',
      justifyContent: 'space-between',
      gap: '6px',
      height: CUSTOMER_LINE_HEIGHT,
    },
    autocompleteAddress: {
      width: 'auto',
      wordBreak: 'break-word',
    },
    resetButtonContainer: {
      marginLeft: 'auto',
      marginRight: '0',
    },
    accordion: {
      backgroundColor: theme.palette.background.default,
    },
    accordionPaper: {
      backgroundColor: theme.palette.background.default,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 18,
    },
    parcelCodesContainer: {
      maxHeight: '120px',
      overflow: 'auto',
      marginTop: 10,
    },
    parcelCode: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: '1px solid',
      minHeight: '35px',
    },
    checkbox: {
      width: 'max-content',
    },
    dateRangePickerContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: 8,
    },
    dateRangePicker: {
      flex: 1,
    },
    tagsContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    tagsList: {
      marginTop: '10px',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gap: '10px',
      maxHeight: '165px',
      overflow: 'auto',
    },
    tag: {
      height: '45px',
      padding: '10px',
      border: `1px solid ${theme.color.secondaryFieldBorder}`,
      background: 'transparent',
      color: theme.color.secondary,
      '&:hover': {
        background: 'transparent',
      },
    },
    selectedTag: {
      background: theme.color.primary,
      borderColor: theme.color.primary,
      color: 'white',
      '&:hover': {
        background: theme.color.primary,
      },
    },
    timeSlotsContainer: {
      width: '100%',
    },
    timeSlotsList: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    timeSlotsListItem: {
      width: 'auto',
    },
    stepper: {
      position: 'relative',
      margin: '0 auto',
    },
    stepsContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      marginBottom: MAIN_PADDING,
      height: STEP_HEIGHT,
      marginRight: SCROLLBAR_WIDTH,
    },
    orderForm: {
      height: `calc(100vh - ${
        4 * MAIN_PADDING + HEADER_HEIGHT + STEP_HEIGHT + FORM_ACTION_HEIGHT
      }px)`,
      overflowY: 'scroll',
    },
    cartDropoffFormAction: {
      paddingBottom: 0,
      marginTop: MAIN_PADDING,
      paddingRight: SCROLLBAR_WIDTH,
    },
    orderCard: {
      display: 'flex',
      position: 'relative',
      paddingLeft: 10,
      backgroundColor: theme.palette.background.default,
    },
  }),
)
