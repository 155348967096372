import React from 'react'
import Button from 'components/Button/CustomButton'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import { useTranslation } from 'react-i18next'

export interface IProps {
  readonly firstName: string
  readonly handleClose: () => void
  readonly lastName: string
  readonly open: boolean
  readonly onValidateForUpdate: () => void
}

export default function EmailForceUpdate({
  firstName,
  handleClose,
  lastName,
  open,
  onValidateForUpdate,
}: IProps): JSX.Element {
  const { t } = useTranslation()

  const prompt = t('ForceEmailUpdate.prompt').replace('$1', firstName).replace('$2', lastName)
  return (
    <Dialog open={open} onClose={handleClose} data-cy="emailForceUpdateDialog">
      <DialogTitle>{t('ForceEmailUpdate.warning')}</DialogTitle>
      <DialogContent>
        <DialogContentText data-cy="emailForceUpdatePrompt">{prompt}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onPress={onValidateForUpdate} dataCy="emailForceUpdateYes">
          {t('ForceEmailUpdate.yes')}
        </Button>
        <Button onPress={handleClose} dataCy="emailForceUpdateBackToEdit">
          {t('ForceEmailUpdate.backToEdit')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
