import React from 'react'

import { COLUMNS_MODEL_PLAN_LIST } from 'constants/table'
import { AVAILABLE_FILTERS } from 'constants/constants'
import PlansProvider, { PlansConsumer } from 'store/PlansContext'
import { IPlanTour } from 'interfaces/IPlan'
import Table from 'components/Table'
import { FilterKey } from 'constants/filters'

interface IProps {
  planTours: IPlanTour[]
}

const PlanTourList = ({ planTours }: IProps): JSX.Element => (
  <Table
    showExtendedDateFilter
    extendedDateFilter={{
      today: true,
      tomorrow: true,
      thisWeek: true,
      next7days: true,
    }}
    columns={COLUMNS_MODEL_PLAN_LIST}
    hideFilters={[AVAILABLE_FILTERS.DELIVERY_TYPES, AVAILABLE_FILTERS.VEHICLE_TYPES]}
    data={planTours}
    isForPlanning
    name="planTourList"
    filterKey={FilterKey.planTours}
  />
)

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <PlansProvider>
    <PlansConsumer>
      {(ctx): JSX.Element => <PlanTourList planTours={ctx.planTours} {...props} />}
    </PlansConsumer>
  </PlansProvider>
)
