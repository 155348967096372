import React, { useEffect, useState, useMemo, useContext } from 'react'
import { Collapse, ListItem } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'

import { IStopMarker, ITourMapItem, ITourStopsNumber } from 'interfaces/map'
import { ContentContext } from 'store/ContentContext'
import { VehicleFillingRateCalculation } from 'constants/constants'
import { getFinalStop } from 'utils/stopsUtils'
import useStyles from '../styles'
import TourSummary from './TourSummary'
import StopsListItem from './StopsListItem'

interface ITourItem {
  tour: ITourMapItem
  areDetailsShown: boolean
  isShowHideDetailsButtonClicked: boolean
  color: string
  numberOfStops: ITourStopsNumber
}

const TourItem = ({
  tour,
  areDetailsShown,
  isShowHideDetailsButtonClicked,
  color,
  numberOfStops,
}: ITourItem): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const { sites } = useContext(ContentContext)
  const classes = useStyles()

  const lastStop = useMemo(() => getFinalStop(tour.stops) as IStopMarker, [tour])

  useEffect(() => {
    if (areDetailsShown) {
      setIsExpanded(true)
    } else {
      setIsExpanded(false)
    }
  }, [isShowHideDetailsButtonClicked, areDetailsShown])

  const handleClick = (): void => setIsExpanded((prevState) => !prevState)

  const { tourNumber, driver, toleratedAdvance, toleratedDelay } = tour
  const { stopsNumber, realizedStopsNumber, filteredStops } = numberOfStops

  const vehicleAllocationCapacityCalculation = useMemo(
    (): VehicleFillingRateCalculation =>
      sites?.find((site) => tour?.warehouseId === site.id)?.executionScreen
        ?.vehicleFillingRateCalculation || VehicleFillingRateCalculation.Quantity,
    [sites, tour],
  )

  const vehicleAllocatedCapacityPercentage: number | null = useMemo(() => {
    if (!tour.vehicleCapacity) {
      return null
    }

    if (tour.stops.length === 0) {
      return 0
    }

    let quantity = 0
    if (vehicleAllocationCapacityCalculation === VehicleFillingRateCalculation.Quantity) {
      quantity = tour.stops.reduce((total, current) => total + Math.abs(current?.quantity || 0), 0)
    } else {
      quantity = tour.stops.reduce(
        (total, current) =>
          total
          + Math.abs(
            current.visitsToDeliver?.reduce(
              (parcelCount, visit) => parcelCount + (visit.supports?.length || 0),
              0,
            ) || 0,
          ),
        0,
      )
    }

    const percentage = Math.round((quantity / tour.vehicleCapacity) * 100)

    return percentage
  }, [tour, vehicleAllocationCapacityCalculation])

  return (
    <>
      <ListItem button onClick={handleClick} className={classes.toursColumn}>
        <TourSummary
          color={color}
          tourNumber={tourNumber}
          driverName={driver?.name}
          toleratedAdvance={toleratedAdvance}
          toleratedDelay={toleratedDelay}
          stopsNumber={stopsNumber}
          realizedStopsNumber={realizedStopsNumber}
          vehicleAllocatedCapacityPercentage={vehicleAllocatedCapacityPercentage}
          lastStop={lastStop}
        />
        {isExpanded ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit className={classes.tourCollapse}>
        {filteredStops.map((stop: IStopMarker, index: number) => (
          <StopsListItem
            key={stop.stopId}
            stop={stop}
            index={index}
            toleratedAdvance={tour.toleratedAdvance}
            toleratedDelay={tour.toleratedDelay}
          />
        ))}
      </Collapse>
    </>
  )
}

export default TourItem
