import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import AddIcon from '@material-ui/icons/Add'
import { Paper, IconButton } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import moment from 'moment'
import Alert from '@material-ui/lab/Alert'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { ContentConsumer } from 'store/ContentContext'
import { IPlanTemplate } from 'interfaces/IPlanTemplate'
import { getTimeFromDateString } from 'utils/dateFormat'
import { IWarehouse } from 'interfaces/interfaces'
import { DefaultSiteContext } from 'store/DefaultSiteContext'
import { AuthContext } from 'store/AuthContext'
import { formatDeliveryTypes } from 'utils/functions'
import { getTransportTypesName } from 'utils/tableUtils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      backgroundColor: theme.palette.background.default,
    },
  }),
)

const Row = styled.div`
  background-color: 'transparent';
  padding-right: 20px;
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  padding-bottom: 6px;
`

const Cell = styled.p`
  font-weight: 'normal';
  flex: 1;
`

interface IProps {
  planTemplates?: IPlanTemplate[]
  onAddClick: (planTemplate: IPlanTemplate, date: string) => void
  warehouses?: IWarehouse[]
}

const PlanTemplateList = ({ planTemplates, onAddClick, warehouses }: IProps): JSX.Element => {
  const [currentSetDate, setCurrentSetDate] = useState<string>()
  const [planTemplatesAvailable, setPlanTemplatesAvailable] = useState<IPlanTemplate[]>([])
  const [currentSetDateIndex, setCurrentSetDateIndex] = useState<number>()
  const [showError, setShowError] = useState<boolean>(false)
  const { t } = useTranslation()
  const classes = useStyles()
  const { defaultSiteId } = useContext(DefaultSiteContext)
  const { user } = useContext(AuthContext)
  const userDeliveryTypes = formatDeliveryTypes(user?.tenantConfig?.deliveryTypes)

  const handleDateChange = (date: MaterialUiPickersDate, index: number): void => {
    if (moment(date).isValid()) {
      setCurrentSetDateIndex(index)
      setCurrentSetDate(moment(date).toISOString())
    }
  }

  const handleAddClick = (planTemplate: IPlanTemplate): void => {
    if (currentSetDate) {
      onAddClick(planTemplate, currentSetDate)
      setShowError(false)
    } else {
      setShowError(true)
    }
  }

  useEffect(() => {
    setPlanTemplatesAvailable(
      planTemplates?.filter((planTemplate) => planTemplate.warehouseId === defaultSiteId) || [],
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSiteId])

  return (
    <Paper elevation={4} className={classes.content}>
      {showError && (
        <Alert severity="error">{t('PlanningScreen.planCreation.dateRequiredMessage')}</Alert>
      )}
      {planTemplatesAvailable
        && planTemplatesAvailable.map((planTemplate, index) => (
          <Row data-cy={planTemplate.label} key={planTemplate.id}>
            <Cell>{planTemplate.label}</Cell>
            <Cell>
              {warehouses?.find((warehouse) => warehouse.id === planTemplate.warehouseId)?.name}
            </Cell>
            <Cell>{getTimeFromDateString(planTemplate.begin as string)}</Cell>
            <Cell>{getTimeFromDateString(planTemplate.end as string)}</Cell>
            <Cell>
              {planTemplate.deliveryTypes
                .map(
                  (deliveryType) =>
                    userDeliveryTypes.find((type) => type.code === +deliveryType)?.label || '',
                )
                .join(', ')}
            </Cell>
            <Cell>{getTransportTypesName(planTemplate.transportTypes)}</Cell>
            <IconButton
              data-cy="addButton"
              onClick={(): void => {
                handleAddClick(planTemplate)
              }}
              disabled={index !== currentSetDateIndex}
            >
              <AddIcon color={index === currentSetDateIndex ? 'primary' : 'inherit'} />
            </IconButton>
            <KeyboardDatePicker
              label={t('PlanningScreen.planCreation.date')}
              inputVariant="outlined"
              format="DD/MM/YYYY"
              value={index === currentSetDateIndex ? currentSetDate : null}
              onChange={(date: MaterialUiPickersDate): void => {
                handleDateChange(date, index)
              }}
              data-cy="startPicker"
            />
          </Row>
        ))}
    </Paper>
  )
}

export default (props: IProps): JSX.Element => (
  <ContentConsumer>
    {(ctx): JSX.Element => (
      <PlanTemplateList planTemplates={ctx.planTemplates} warehouses={ctx.warehouses} {...props} />
    )}
  </ContentConsumer>
)
