import { CheckCircleOutline, Edit } from '@material-ui/icons'
import React from 'react'
import i18n from 'i18next'
import { Tooltip } from '@material-ui/core'

import { ClusterStatus } from 'interfaces/ICluster'

interface IClusterStatusProps {
  status: ClusterStatus
  isManualTour?: boolean
}

export default ({ status, isManualTour = false }: IClusterStatusProps): JSX.Element => (
  <Tooltip
    title={
      i18n.t(`PlanningScreen.cluster${status}`, {
        elementLocked: isManualTour
          ? i18n.t('PlanningScreen.manualTourLocked')
          : i18n.t('PlanningScreen.clusterLocked'),
      }) || ''
    }
  >
    {status === ClusterStatus.new ? (
      <Edit style={{ marginRight: 4 }} />
    ) : (
      <CheckCircleOutline
        style={{ color: status === ClusterStatus.closed ? 'chartreuse' : 'white', marginRight: 4 }}
      />
    )}
  </Tooltip>
)
