import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Collapse, List, ListItem, ListItemText } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import {
  IOrderTimeSlot,
  IOrderTimeSlotDetails,
  ISelectedTimeSlot,
} from 'interfaces/IWarehouseTimeSlot'
import { getTimeFromDateString } from 'utils/dateFormat'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

interface IProps {
  data: IOrderTimeSlot
  selectedTimeSlot?: ISelectedTimeSlot
  setSelectedTimeSlot?: Function
  customListClass?: string
  customListItemClass?: string
  isInitiallyOpen?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    slotsContainer: {
      marginLeft: '15px',
    },
    selectedTimeSlot: {
      backgroundColor: theme.color.green,
      '&:hover': {
        backgroundColor: theme.color.lightGreen,
      },
    },
    availableTimeSlot: {
      backgroundColor: theme.color.primary,
    },
    unavailableTimeSlot: {
      backgroundColor: theme.palette.secondary.dark,
    },
  }),
)

const TimeSlotDropdown = ({
  data,
  selectedTimeSlot,
  setSelectedTimeSlot,
  customListClass,
  customListItemClass,
  isInitiallyOpen = false,
}: IProps): JSX.Element => {
  const [open, setOpen] = useState(isInitiallyOpen)
  const { t } = useTranslation()
  const classes = useStyles()

  useEffect(() => {
    setOpen(isInitiallyOpen)
  }, [isInitiallyOpen])

  const handleClick = () => {
    setOpen((previousState) => !previousState)
  }

  const isSelectedTimeSlot = (timeSlotInfo: IOrderTimeSlotDetails) => {
    if (selectedTimeSlot) {
      if (
        selectedTimeSlot.beginTime === timeSlotInfo.beginTime
        && selectedTimeSlot.endTime === timeSlotInfo.endTime
      ) {
        return true
      }
    }
    return false
  }

  const onSlotSelected = (slotInfo: IOrderTimeSlotDetails) => {
    if (setSelectedTimeSlot) {
      if (selectedTimeSlot
        && new Date(slotInfo.beginTime).getTime() === new Date(selectedTimeSlot.beginTime).getTime()
        && new Date(slotInfo.endTime).getTime() === new Date(selectedTimeSlot.endTime).getTime()) {
        setSelectedTimeSlot(undefined)
      } else {
        setSelectedTimeSlot({
          beginTime: slotInfo.beginTime,
          endTime: slotInfo.endTime,
        })
      }
    }
  }

  const getButtonColor = (timeSlotInfo: IOrderTimeSlotDetails) =>
    isSelectedTimeSlot(timeSlotInfo)
      ? classes.selectedTimeSlot
      : timeSlotInfo.maxOrder <= timeSlotInfo.orderCount || !timeSlotInfo.active
        ? classes.unavailableTimeSlot
        : classes.availableTimeSlot

  return (
    <>
      <ListItem onClick={handleClick}>
        <ListItemText primary={`${data.day.charAt(0).toUpperCase()}${data.day.slice(1)}`} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <div className={classes.slotsContainer}>
          <List component="div" disablePadding className={customListClass}>
            {data.timeSlotDetails.length > 0
              && data.timeSlotDetails.map((timeSlotInfo) => (
                <ListItem
                  key={`${timeSlotInfo.beginTime}-${timeSlotInfo.endTime}`}
                  className={customListItemClass}
                >
                  <Button
                    className={getButtonColor(timeSlotInfo)}
                    onClick={() => {
                      onSlotSelected(timeSlotInfo)
                    }}
                  >
                    {`${getTimeFromDateString(timeSlotInfo.beginTime.toString())} 
                      - ${getTimeFromDateString(timeSlotInfo.endTime.toString())}`}
                  </Button>
                </ListItem>
              ))}
            {data.timeSlotDetails.length === 0 && (
              <ListItem key={`no-timeslot-${data.day}`}>{t('OrdersScreen.noTimeSlot')}</ListItem>
            )}
          </List>
        </div>
      </Collapse>
    </>
  )
}
export default TimeSlotDropdown
