import { createStyles, makeStyles } from '@material-ui/core/styles'

export default makeStyles(() =>
  createStyles({
    dialogMessage: {
      fontFamily: 'inherit',
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word',
    },
    editLabelContainer: {
      padding: '5px',
      background: '#D5D5D5',
      color: 'black',
      fontSize: '15px',
      fontWeight: 'bold',
      marginBottom: '10px',
    },
    editContainer: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100px',
      padding: '10px',
      margin: '27px 0px',
      border: '1px solid #D5D5D5',
    },
    editAddressContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      justifyContent: 'flex-start',
    },
    editHeader: {
      width: '30vw',
      display: 'flex',
      justifyContent: 'space-between',
    },
    withMarginBottom: {
      marginBottom: '12px',
    },
    finishTourContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
      justifyContent: 'space-between',
      padding: '1px',
    },
    dateRangePicker: {
      width: '100%',
    },
    finishTourActionsContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '10px',
      padding: '1px',
    },
    backdropContainer: {
      width: '100vw',
      height: '100vh',
    },
  }),
)
