import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip, Menu, MenuItem } from '@material-ui/core/'
import IconButton from '@material-ui/core/IconButton'
import FlashOnIcon from '@material-ui/icons/FlashOn'
import { useTheme } from '@material-ui/core/styles'
import * as Sentry from '@sentry/react'

import { isIError } from 'api/types'
import CreateVisitsModal from 'screens/PlanningCategory/PlanningScreen/components/CreateVisitsModal/CreateVisitsModal'
import { ContentContext } from 'store/ContentContext'
import { FeedbackContext } from 'store/FeedbackContext'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog'
import { PLAN_TOUR_STATUS_ENUM, SentryEvents } from 'constants/constants'
import { AuthContext } from 'store/AuthContext'
import { TENANTS_WITH_REPAIR_PLAN } from 'constants/env'
import { SpanStatus } from '@sentry/tracing'
import JobsApi from 'api/jobs'
import { getErrorList } from 'utils/errorUtils'
import { PlanificationContext } from '../PlanningStore'
import { createPlanVisits, deletePlanVisits, createSortedPlanVisits } from '../orders/orders'

interface IOptimizationMenu {
  toggleOptimizeModal(): void
}

function OptimizationMenu({ toggleOptimizeModal }: IOptimizationMenu): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const {
    selectedPlan,
    getPlansVisits,
    toggleBackdrop,
    isActionLoading,
    isVisitsLoading,
    setIsCreateVisitsDisabled,
    isDeleteVisitsDisabled,
    setIsDeleteVisitsDisabled,
    startDate,
    endDate,
    selectedDeliveryType,
    selectedTransportTypes,
    markers,
    isClusterMode,
    isMapotempoActionInProgress,
    tours,
    repairPlan,
    setClusters,
    setManualTours,
    setJobIds,
    isOutsourceMode,
  } = useContext(PlanificationContext)

  const theme = useTheme()
  const { t } = useTranslation()

  const { openErrorSnack, toggleLoader } = useContext(FeedbackContext)
  const { user, shouldDisplaySortVisits, shouldUseJobsForVisitsGeneration } = useContext(AuthContext)
  const { sites } = useContext(ContentContext)
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false)
  const [isRepairPlanConfirmationOpen, setIsRepairPlanConfirmationOpen] = useState(false)
  const [isPlanRepairDisabled, setIsPlanRepairDisabled] = useState(false)
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => setAnchorEl(null)

  function createVisits(): void {
    setIsCreateModalOpen(true)
    handleClose()
  }

  async function onCreateVisits(
    ordersBegin,
    ordersEnd,
    deliveryTypes,
    transportTypes,
    warehouseIds: string[],
    sortingParams,
  ): Promise<void> {
    if (shouldDisplaySortVisits && sortingParams.sortBy && !sortingParams.destinationSiteId) {
      openErrorSnack(t('PlanningScreen.destinationSiteRequired'))
    } else {
      const transaction = Sentry.startTransaction({
        name: SentryEvents.CreatePlanVisits,
        tags: {
          login: user?.login,
          tenant: user?.tenant,
          selectedPlan: selectedPlan?.label,
          selectedPlanId: selectedPlan?.id,
          warehouseCodes: warehouseIds
            ?.map((warehouseId) => sites.find((site) => site.id === warehouseId)?.code ?? 'unknown')
            .join(', '),
        },
      })
      Sentry.getCurrentHub().configureScope((scope) => scope.setSpan(transaction))

      toggleBackdrop(t(`PlanningScreen.visitCreationRunning`), true)
      toggleLoader(true)
      setIsCreateVisitsDisabled(true)

      let res
      if (shouldDisplaySortVisits && sortingParams.sortBy && sortingParams.destinationSiteId) {
        const destinationSite = sites.find((site) => site.id === sortingParams.destinationSiteId)

        if (destinationSite) {
          res = await createSortedPlanVisits({
            destinationSite,
            startDate: ordersBegin,
            endDate: ordersEnd,
            deliveryTypes,
            transportTypes,
            warehouseIds,
          })
        }
      } else {
        res = await createPlanVisits({
          startDate: ordersBegin,
          endDate: ordersEnd,
          deliveryTypes,
          transportTypes,
          warehouseIds,
        })
      }

      if (!isIError(res)) {
        transaction.setStatus(SpanStatus.Ok)
        getPlansVisits()
      } else {
        transaction.setStatus(SpanStatus.InternalError)
        openErrorSnack(res.error.message)
      }

      toggleLoader(false)
      toggleBackdrop()
      setIsCreateModalOpen(false)
      setIsCreateVisitsDisabled(false)
      transaction.finish()
    }
  }

  async function onCreateVisitsGenerationJob(
    ordersBegin,
    ordersEnd,
    deliveryTypes,
    transportTypes,
    warehouseIds: string[],
    // sortingParams,
  ): Promise<void> {
    toggleBackdrop(t(`PlanningScreen.visitCreationRunning`), true)
    toggleLoader(true)
    setIsCreateVisitsDisabled(true)
    setIsCreateModalOpen(false)
    const res = await JobsApi.createVisitsGenerationJob({
      planId: selectedPlan?.id,
      startDate: ordersBegin,
      endDate: ordersEnd,
      deliveryTypes,
      transportTypes,
      warehouseIds,
    })
    if (!isIError(res)) {
      setJobIds((prev) => [...prev, res])
    } else {
      res.error.errorList = getErrorList(res)
      if (res.error.fieldErrors) {
        openErrorSnack(res.error.errorList.join('\n'))
      } else {
        openErrorSnack(res.error.message)
      }
      toggleBackdrop()
      setIsCreateVisitsDisabled(false)
    }
    toggleLoader(false)
  }

  function optimize(): void {
    handleClose()
    toggleOptimizeModal()
  }

  function onClickDeleteVisits() {
    setIsDeleteConfirmationOpen(true)
  }

  function onClickRepairPlan() {
    setIsRepairPlanConfirmationOpen(true)
  }

  async function onConfirmDeleteVisits(confirm: boolean): Promise<void> {
    if (confirm) {
      const transaction = Sentry.startTransaction({
        name: SentryEvents.DeletePlanVisits,
        tags: {
          login: user?.login,
          tenant: user?.tenant,
          selectedPlan: selectedPlan?.label,
          selectedPlanId: selectedPlan?.id,
        },
      })
      Sentry.getCurrentHub().configureScope((scope) => scope.setSpan(transaction))

      toggleLoader(true)
      setIsDeleteVisitsDisabled(true)
      const res = await deletePlanVisits({
        startDate,
        endDate,
        deliveryTypes: selectedDeliveryType,
        transportTypes: selectedTransportTypes,
      })
      if (!isIError(res)) {
        transaction.setStatus(SpanStatus.Ok)
        setClusters([])
        setManualTours([])
        getPlansVisits()
      } else {
        transaction.setStatus(SpanStatus.InternalError)
        openErrorSnack(res.error.message)
      }
      toggleLoader(false)
      transaction.finish()
    }
    setIsDeleteConfirmationOpen(false)
    setIsDeleteVisitsDisabled(false)
    handleClose()
  }

  async function onConfirmRepairPlan(confirm: boolean): Promise<void> {
    if (!selectedPlan) {
      setIsRepairPlanConfirmationOpen(false)
      return
    }
    if (confirm) {
      toggleLoader(true)
      setIsPlanRepairDisabled(true)
      const res = await repairPlan(selectedPlan.id)
      if (!isIError(res)) {
        toggleBackdrop(t(`PlanningScreen.optimizeRunning`), false, true)
      } else {
        openErrorSnack(res.error.message)
      }
      toggleLoader(false)
    }
    setIsRepairPlanConfirmationOpen(false)
    setIsPlanRepairDisabled(false)
    handleClose()
  }

  return (
    <>
      <Tooltip
        title={
          t(selectedPlan ? 'PlanningScreen.optimize' : 'PlanningScreen.noPlanSelected') as string
        }
      >
        <span>
          <IconButton
            data-cy="optimizeButton"
            disabled={!selectedPlan || isActionLoading || isVisitsLoading}
            onClick={handleClick}
            aria-label="menu"
            style={{
              backgroundColor: theme.color.actionsBackgroundCustomToolbar,
              color: '#FFF',
            }}
          >
            <FlashOnIcon fontSize="large" />
          </IconButton>
        </span>
      </Tooltip>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          data-cy="item-option-refresh-visits"
          disabled={isMapotempoActionInProgress}
          onClick={createVisits}
        >
          {t('PlanningScreen.refreshVisits')}
        </MenuItem>
        <MenuItem
          data-cy="item-option-delete-visits"
          disabled={!markers.length || isMapotempoActionInProgress}
          onClick={onClickDeleteVisits}
        >
          {t('PlanningScreen.deleteVisits')}
        </MenuItem>
        {TENANTS_WITH_REPAIR_PLAN
          && user?.tenantId
          && TENANTS_WITH_REPAIR_PLAN.includes(user.tenantId) && (
            <MenuItem
              data-cy="item-option-repair-plan"
              disabled={
                tours.length === 0
                || tours.every((tour) => tour.status === PLAN_TOUR_STATUS_ENUM.INTERFACED)
                || isMapotempoActionInProgress
              }
              onClick={onClickRepairPlan}
            >
              {t('PlanningScreen.repairPlan')}
            </MenuItem>
        )}
        {!isOutsourceMode && (
          <MenuItem
            data-cy={isClusterMode ? 'item-option-optimize-clusters' : 'item-option-optimize-plan'}
            disabled={isMapotempoActionInProgress}
            onClick={optimize}
          >
            {t(isClusterMode ? 'PlanningScreen.optimizeClusters' : 'PlanningScreen.optimizePlan')}
          </MenuItem>
        )}
      </Menu>
      <ConfirmationDialog
        open={isDeleteConfirmationOpen}
        onClose={onConfirmDeleteVisits}
        message={t('PlanningScreen.confirmDeleteVisits')}
        confirmMessage={t('PlanningScreen.delete')}
        isConfirmDisabled={isDeleteVisitsDisabled}
      />
      <ConfirmationDialog
        open={isRepairPlanConfirmationOpen}
        onClose={onConfirmRepairPlan}
        message={t('PlanningScreen.confirmRepairPlan')}
        confirmMessage={t('PlanningScreen.repair')}
        isConfirmDisabled={isPlanRepairDisabled}
      />
      {isCreateModalOpen && (
        <CreateVisitsModal
          isVisible={isCreateModalOpen}
          toggleModal={() => setIsCreateModalOpen(!isCreateModalOpen)}
          createVisits={
            shouldUseJobsForVisitsGeneration ? onCreateVisitsGenerationJob : onCreateVisits
          }
          startDate={selectedPlan?.ordersBegin}
          endDate={selectedPlan?.ordersEnd}
          deliveryTypes={selectedPlan?.deliveryTypes.map(String)}
          transportTypes={selectedPlan?.transportTypes}
          warehouseId={selectedPlan?.warehouseId}
          secondaryWarehouseIds={selectedPlan?.secondaryWarehouseIds}
        />
      )}
    </>
  )
}

export default OptimizationMenu
