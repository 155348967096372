import { createStyles, makeStyles } from '@material-ui/core/styles'

export default makeStyles(() =>
  createStyles({
    filterRightContainer: {
      position: 'absolute',
      right: 0,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    filterContainer: {
      display: 'flex',
      flexDirection: 'row',
      position: 'relative',
    },
    filterLeftContainer: {
      marginBottom: 12,
    },
    fabFilterButton: {
      color: 'white',
      position: 'absolute',
      top: '95px',
      marginLeft: '50px',
      float: 'left',
      zIndex: 500,
    },
    fabFilterButtonWithoutMap: {
      marginLeft: '10px',
    },
    toursMapContainer: {
      display: 'flex',
      height: 'calc(100vh - 96px)',
      marginTop: '5px',
    },
    toursMapContainerWithFilters: {
      height: 'calc(100vh - 166px)',
    },
  }),
)
