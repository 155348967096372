import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { useParams, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import useStyles from 'constants/cruStyles'
import DriversProvider, { DriversConsumer } from 'store/DriversContext'
import { IIdParam } from 'interfaces/IIdParam'
import { ContentConsumer } from 'store/ContentContext'
import { IDriver } from 'interfaces/IDriver'
import { ICarrier } from 'interfaces/ICarrier'
import CustomCard from 'components/CustomCard/CustomCard'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import Button from 'components/Button/CustomButton'
import { IWarehouse } from 'interfaces/interfaces'
import { IEvent } from 'interfaces/IEvent'
import DriverAvailabilityCard from './DriverAvailabilityCard'

interface IProps {
  getDetails?: Function
  driverDetails: IDriver
  carriers: ICarrier[]
  sites: IWarehouse[]
}

const DriversDetailsScreen = ({
  getDetails,
  driverDetails,
  carriers,
  sites,
}: IProps): JSX.Element => {
  const { id } = useParams<IIdParam>()
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()

  useEffect(() => {
    if (id && getDetails) {
      getDetails(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const handleEditClick = (): void => {
    history.push(`${ROUTES_PATH.editDriver}${id}`)
  }

  return (
    <>
      <div className={classes.detailHeaderContainer}>
        <h3 data-cy="driverDetailDescription">
          {`${driverDetails.firstName || ''} ${driverDetails.lastName || ''}`}
        </h3>
        <Button dataCy="updateButton" onPress={handleEditClick}>
          {t('DriversScreen.edit')}
        </Button>
      </div>
      <div className={classes.detailsContent}>
        <div className={classes.detailsInnerContent}>
          <Grid item xs={10} className={classes.infoContainer}>
            <CustomCard
              dataCy="profilCard"
              cardTitle={t('DriversScreen.profile')}
              linePairs={[
                {
                  label: t('DriversScreen.firstName'),
                  value: driverDetails ? driverDetails.firstName : '',
                },
                {
                  label: t('DriversScreen.lastName'),
                  value: driverDetails ? driverDetails.lastName : '',
                },
                {
                  label: t('DriversScreen.phoneNumber'),
                  value: driverDetails ? driverDetails.phoneNumber : '',
                },
                {
                  label: t('DriversScreen.email'),
                  value: driverDetails ? driverDetails.login : '',
                },
                {
                  label: t('DriversScreen.active'),
                  value: driverDetails
                    ? driverDetails.active
                      ? t('DriversScreen.yes')
                      : t('DriversScreen.no')
                    : '',
                },
                {
                  label: t('DriversScreen.carrier'),
                  value:
                    carriers.length && driverDetails
                      ? carriers.find((carrier) => carrier.id === driverDetails.carrierId)?.name
                      : '',
                },
                {
                  label: t('DriversScreen.site'),
                  value:
                    sites.length && driverDetails
                      ? sites.find((site) => site.id === driverDetails.siteId)?.name
                      : '',
                },
              ]}
            />
          </Grid>
        </div>
        {driverDetails.driverAvailabilityControl !== undefined && (
          <div className={classes.availabilityDetails}>
            <DriverAvailabilityCard
              events={driverDetails.events as IEvent[]}
              shouldShowDriverPlanning={driverDetails.driverAvailabilityControl}
              isReadOnly
            />
          </div>
        )}
      </div>
    </>
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <DriversProvider>
    <ContentConsumer>
      {(contentCtx): JSX.Element => (
        <DriversConsumer>
          {(driversCtx): JSX.Element => (
            <DriversDetailsScreen
              getDetails={driversCtx.getDetails}
              driverDetails={driversCtx.driverDetails}
              carriers={contentCtx.carriers}
              sites={contentCtx.sites}
              {...props}
            />
          )}
        </DriversConsumer>
      )}
    </ContentConsumer>
  </DriversProvider>
)
