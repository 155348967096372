import React, { useContext } from 'react'

import { IError } from 'api/types'
import { COLUMNS_MODEL_USERS_LIST } from 'constants/table'
import { IUser } from 'interfaces/IUser'
import UsersProvider, { UsersConsumer } from 'store/UsersContext'
import UsersTable from 'components/Table/UsersTable'
import { AuthContext } from 'store/AuthContext'
import { USER_ROLES } from 'constants/constants'

interface IUsersProps {
  users: IUser[]
  error?: IError
}

const UsersScreen = ({ users }: IUsersProps): JSX.Element => {
  const { user: loggedUser } = useContext(AuthContext)
  const filteredColumnModelUsersList = COLUMNS_MODEL_USERS_LIST.filter(
    (item) => item.field !== 'tenant',
  )
  return (
    <UsersTable
      users={users}
      columns={
        loggedUser?.roles.includes(USER_ROLES.superAdmin)
          ? COLUMNS_MODEL_USERS_LIST
          : filteredColumnModelUsersList
      }
    />
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <UsersProvider>
    <UsersConsumer>
      {(ctx): JSX.Element => <UsersScreen users={ctx.users} {...props} />}
    </UsersConsumer>
  </UsersProvider>
)
