import React, { useMemo } from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import { ICluster } from 'interfaces/ICluster'
import { IVehicleAvailability } from 'interfaces/IVehicleAvailability'

const PercentageCard = styled.div<{ isOverLoaded: boolean }>`
  background-color: #fff;
  color: ${(props) => (props.isOverLoaded ? 'red' : 'green')};
  padding: 0 2px;
  border-radius: 4px;
`

const VehiculeListContainer = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

interface IVehiculeCapacityHeader {
  cluster: ICluster
  vehiclesList: IVehicleAvailability[]
}

const VehiculeCapacityHeader = ({
  cluster,
  vehiclesList,
}: IVehiculeCapacityHeader): JSX.Element => {
  const vehicleAllocatedCapacityPercentage: number | null = useMemo(() => {
    if (cluster.vehicleIds.length === 0) {
      return null
    }

    if (cluster.visits.length === 0) {
      return 0
    }

    const vehiclesCapacity = vehiclesList
      ?.filter((veh) => cluster.vehicleIds.includes(veh.id))
      .reduce((total, current) => total + current.capacity, 0) || 0

    const visitsOrdersQuantity = cluster.visits.reduce(
      (total, current) => total + Math.abs(current.mainQuantity),
      0,
    )

    const percentage = Math.round((visitsOrdersQuantity / vehiclesCapacity) * 100)

    return percentage
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cluster.vehicleIds, cluster.visits.length, vehiclesList])

  const overload = !!vehicleAllocatedCapacityPercentage && vehicleAllocatedCapacityPercentage > 100

  return (
    <Grid container>
      {cluster.vehicleIds.length > 0 && (
        <Grid container wrap="nowrap" justify="space-between">
          <Grid item style={{ maxWidth: '200px' }}>
            <VehiculeListContainer>
              {cluster.vehicleIds
                .map((id) => vehiclesList.find((vehicule) => vehicule.id === id)?.name)
                .sort((a = '', b = '') => a.localeCompare(b))
                .join(', ')}
            </VehiculeListContainer>
          </Grid>
          <Grid>
            <PercentageCard isOverLoaded={overload}>
              <b>{`${vehicleAllocatedCapacityPercentage ?? ''}%`}</b>
            </PercentageCard>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default VehiculeCapacityHeader
