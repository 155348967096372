import React, { useContext, useState } from 'react'
import { TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { IDriver } from 'interfaces/IDriver'
import { ContentConsumer } from 'store/ContentContext'
import { ICarrier, IWarehouse } from 'interfaces/interfaces'
import Switch from 'components/Inputs/ToggleSwitch'
import SearchableSelect from 'components/Inputs/SearchableSelect'
import useStyles from 'constants/cruStyles'
import Card from 'components/Card/Card'
import { AuthContext } from 'store/AuthContext'
import PasswordComplexityChecker from 'components/PasswordComplexityChecker/PasswordComplexityChecker'
import { SetManualPassword } from 'constants/constants'

interface ITextChange {
  target: {
    value: string
    name: string
    type?: string
    checked?: boolean
  }
}

interface IProps {
  driver?: IDriver
  onDriverChange: Function
  carrierOptions?: ICarrier[]
  siteOptions?: IWarehouse[]
}

const DriverInfoCard = ({
  driver,
  onDriverChange,
  carrierOptions,
  siteOptions,
}: IProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { user } = useContext(AuthContext)

  const [state, setState] = useState<IDriver>({
    id: driver?.id,
    firstName: driver?.firstName,
    lastName: driver?.lastName,
    login: driver?.login,
    password: driver?.password,
    active: driver?.active,
    carrier: driver?.carrier || '',
    phoneNumber: driver?.phoneNumber,
    siteId: driver?.siteId || '',
  })

  const handleInputChange = (event: ITextChange): void => {
    const { target } = event
    const { name } = target
    const value = target.type === 'checkbox' ? target.checked : target.value

    onDriverChange({ ...state, [name]: value })
    setState({ ...state, [name]: value })
  }

  const handleSelectChange = (
    event: React.ChangeEvent<{ value: unknown; name?: string }>,
  ): void => {
    const { target } = event
    const name = target.name as string
    const value = target.value as string

    onDriverChange({ ...state, [name]: value })
    setState({ ...state, [name]: value })
  }

  const shouldShowPasswordInput = (): boolean | undefined =>
    user?.tenantConfig?.setManualPassword
    && [SetManualPassword.ALLUSERS, SetManualPassword.DRIVERSONLY].includes(
      user.tenantConfig.setManualPassword,
    )

  return (
    <Card dataCy="profilCard" title={t('DriversScreen.profile').toUpperCase()}>
      <>
        <div className={classes.fieldsContainer}>
          <TextField
            data-cy="firstName"
            name="firstName"
            value={state.firstName || ''}
            required
            error={state.firstName === ''}
            label={t('DriversScreen.firstName').toUpperCase()}
            onChange={handleInputChange}
            type="text"
          />
          <TextField
            data-cy="lastName"
            name="lastName"
            type="text"
            error={state.lastName === ''}
            value={state.lastName || ''}
            required
            label={t('DriversScreen.lastName').toUpperCase()}
            onChange={handleInputChange}
          />
          <TextField
            data-cy="phoneNumber"
            label={t('DriversScreen.phoneNumber').toUpperCase()}
            onChange={handleInputChange}
            type="text"
            name="phoneNumber"
            value={state.phoneNumber || ''}
          />
          <TextField
            data-cy="email"
            error={state.login === ''}
            label={t('DriversScreen.email').toUpperCase()}
            onChange={handleInputChange}
            type="email"
            name="login"
            autoComplete="off"
            required
            value={state.login || ''}
          />
          {shouldShowPasswordInput() && (
            <>
              {/* used for prevent login field to autocomplete */}
              <input type="text" style={{ opacity: 0, position: 'absolute' }} />
              <TextField
                data-cy="password"
                error={state.password === ''}
                label={t('DriversScreen.password').toUpperCase()}
                onChange={handleInputChange}
                type="password"
                name="password"
                required
                autoComplete="new-password"
                value={state.password || ''}
              />
              <div>
                <PasswordComplexityChecker password={state.password || ''} />
              </div>
            </>
          )}
          <SearchableSelect
            dataCy="carrierSelect"
            label={t('DriversScreen.carrier')}
            name="carrier"
            required
            selectedValue={state.carrier}
            options={carrierOptions?.map((carrier) => ({ id: carrier.id, name: carrier.name }))}
            onChange={handleSelectChange}
          />
          <SearchableSelect
            dataCy="siteSelect"
            label={t('DriversScreen.site')}
            name="siteId"
            required
            selectedValue={state.siteId}
            options={siteOptions?.map((site) => ({ id: site.id, name: site.name }))}
            onChange={handleSelectChange}
          />
        </div>
        <Switch
          dataCy="active"
          onChange={handleInputChange}
          checked={state.active}
          name="active"
          label={t('DriversScreen.active').toUpperCase()}
        />
      </>
    </Card>
  )
}

export default (props: JSX.IntrinsicAttributes & IProps): JSX.Element => (
  <ContentConsumer>
    {(ctx): JSX.Element => (
      <DriverInfoCard
        onDriverChange={props.onDriverChange}
        carrierOptions={ctx.carriers}
        siteOptions={ctx.userSites}
        driver={props.driver}
      />
    )}
  </ContentConsumer>
)
