import React from 'react'
import { Axis } from '@devexpress/dx-react-chart'
import { ArgumentAxis } from '@devexpress/dx-react-chart-material-ui'

const VerticalLabel = ({ text, x, y, ...props }: Axis.LabelProps): JSX.Element => (
  <ArgumentAxis.Label
    {...props}
    text={text}
    x={0}
    y={0}
    transform={`translate(${x - 7} ${y + 25}) rotate(-60)`}
  />
)

export default VerticalLabel
