import { MENU_SUBCATEGORIES, PILOT_AND_STORE_USER_ROLES, USER_ROLES } from 'constants/constants'
import ApikeysScreen from 'screens/ApikeysScreen/ApikeysScreen'
import ApikeysFormScreen from 'screens/ApikeysScreen/components/ApikeysFormScreen'
import { ROUTES_PATH } from './RoutesPath'

/**
 * Apikeys configuration
 * Please refer to 'navigation/NavigationConfig' before changing this.
 */

const APIKEYS = [
  {
    title: MENU_SUBCATEGORIES.apikeys,
    path: ROUTES_PATH.apiKeysList,
    component: ApikeysScreen,
    isPrivate: true,
    roles: [USER_ROLES.superAdmin, ...PILOT_AND_STORE_USER_ROLES],
    isInMenu: false,
  },
  {
    title: MENU_SUBCATEGORIES.apikeys,
    path: ROUTES_PATH.createApiKey,
    component: ApikeysFormScreen,
    isPrivate: true,
    roles: [USER_ROLES.superAdmin, ...PILOT_AND_STORE_USER_ROLES],
    isInMenu: false,
  },
  {
    title: MENU_SUBCATEGORIES.apikeys,
    path: `${ROUTES_PATH.editApiKey}:id`,
    component: ApikeysFormScreen,
    isPrivate: true,
    roles: [USER_ROLES.superAdmin, ...PILOT_AND_STORE_USER_ROLES],
    isInMenu: false,
  },
]

export default APIKEYS
