import React, { useState, ReactNode, useContext } from 'react'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import useConstant from 'use-constant'
import { useTranslation } from 'react-i18next'

import { IUserData, ITourFilters, ITableColumn } from 'interfaces'
import { ITour, IUpdateTourStatus } from 'interfaces/Itours'
import { COLUMNS_MODEL, COLUMNS_MODEL_MONITORING } from 'constants/table'
import ToursApi from 'api/tours'
import { isIError } from 'api/types'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import { FeedbackContext } from 'store/FeedbackContext'
import { AuthConsumer } from './AuthContext'

interface ITourUpdateData {
  carrierId: string | null
  driverId: string | null
  vehiculeId: string | null
}

interface IToursContext {
  tours: ITour[]
  count: number
  updateTours: (
    filters?: ITourFilters,
    offset?: number,
    rowsPerPage?: number,
    sortField?: number,
    sortDirection?: string,
  ) => void
  updateTourById: (tour: { tourId: string }) => Promise<void>
  updateStopStatus: (stop: { stopId: string; status: number }) => Promise<void>
  updateTourStatus: (tourId: string, status: number) => Promise<void>
}

const ToursContext = React.createContext<IToursContext>({} as IToursContext)
const { Provider, Consumer } = ToursContext

interface IProps {
  children: ReactNode
  user?: IUserData
}

/**
 *
 * ToursContext will manage the tours list
 */

function ToursProvider({ children }: IProps): JSX.Element {
  const [tours, setTours] = useState<ITour[]>([])
  const [count, setCount] = useState<number>(0)
  const { openErrorSnack, openSuccessSnack, toggleLoader } = useContext(FeedbackContext)
  const { t } = useTranslation()

  const getTours = useConstant(() => AwesomeDebouncePromise(ToursApi.getTours, 500))

  async function updateTours(
    filters?: ITourFilters,
    offset?: number,
    rowsPerPage?: number,
    sortField?: number,
    sortDirection?: string,
  ): Promise<void> {
    let columnsModel: ITableColumn[] | undefined
    toggleLoader(true)

    if (window.location.pathname.includes(ROUTES_PATH.executionAffectation)) columnsModel = COLUMNS_MODEL
    else if (window.location.pathname.includes(ROUTES_PATH.executionTours)) columnsModel = COLUMNS_MODEL_MONITORING
    else columnsModel = undefined

    const res = await getTours(
      filters,
      offset,
      rowsPerPage,
      columnsModel && columnsModel.find((_column, index) => index === sortField)?.field,
      sortDirection === 'desc' ? -1 : 1,
    )
    if (isIError(res)) {
      openErrorSnack(res.error.message)
    } else {
      setTours(res.tours)
      setCount(res.count)
    }
    toggleLoader(false)
  }

  function updateSingleTour(tour: ITour): void {
    setTours((prev): ITour[] => {
      const toursArray = [...prev]
      const index = toursArray.findIndex((elem) => elem.tourId === tour.tourId)
      if (index !== -1) {
        toursArray[index] = tour
      }
      return toursArray
    })
  }

  async function updateTourById({ tourId, ...rest }: { tourId: string }): Promise<void> {
    const tourUpdate = { ...rest } as ITourUpdateData
    if (tourUpdate.carrierId === null) {
      tourUpdate.driverId = null
      tourUpdate.vehiculeId = null
    }
    toggleLoader(true)
    const res = await ToursApi.updateTour({ tourId, ...tourUpdate })
    if (isIError(res)) {
      openErrorSnack(res.error.message)
    } else {
      updateSingleTour(res)
      openSuccessSnack(t('TourMonitoringScreen.changeSaved'))
    }
    toggleLoader(false)
  }

  async function updateStopStatus({
    stopId,
    status,
    date,
  }: {
    stopId: string
    status: number
    date?: Date
  }): Promise<void> {
    toggleLoader(true)
    const res = await ToursApi.updateStopStatus(stopId, Number(status), date)
    if (isIError(res)) {
      openErrorSnack(res.error.message)
    } else {
      updateSingleTour(res)
      openSuccessSnack(t('TourMonitoringScreen.changeSaved'))
    }
    toggleLoader(false)
  }

  function updateResumedTour(tour: IUpdateTourStatus): void {
    setTours((prev): ITour[] => {
      const toursArray = [...prev]
      const index = toursArray.findIndex((elem) => elem.tourId === tour.tourId)
      if (index !== -1) {
        toursArray[index].status = tour.tourStatus
        toursArray[index].stops.forEach((stop) => {
          const destination = tour.destinations.find(
            (destinationItem) => destinationItem.stopId === stop.stopId,
          )
          if (destination) {
            const updatedStop = stop
            updatedStop.status = destination.status
          }
        })
      }
      return toursArray
    })
  }

  async function updateTourStatus(tourId: string, status: number): Promise<void> {
    toggleLoader(true)
    const res = await ToursApi.updateTourStatus(tourId, Number(status))
    if (isIError(res)) {
      openErrorSnack(res.error.message)
    } else {
      updateResumedTour(res)
    }
    toggleLoader(false)
  }

  return (
    <Provider
      value={{
        tours,
        count,
        updateTours,
        updateTourById,
        updateStopStatus,
        updateTourStatus,
      }}
    >
      {children}
    </Provider>
  )
}

export default (props: JSX.IntrinsicAttributes & IProps): JSX.Element => (
  <AuthConsumer>
    {(authCtx): JSX.Element => <ToursProvider user={authCtx.user} {...props} />}
  </AuthConsumer>
)
export { ToursContext, Consumer as ToursConsumer }
