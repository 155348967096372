import Api from 'services/api'
import { ICostConfiguration, IWarehouseCostReporting } from 'interfaces/ICostConfiguration'
import { IError } from 'api/types'

interface ICostConfigurationResponse {
  costConfigurations: ICostConfiguration[]
  count: number
}

const getList = async (
  offset?: number,
  rowsPerPage?: number,
  carriers?: string[],
): Promise<ICostConfigurationResponse | IError> => {
  try {
    const response = await Api.get('cost-configurations', {
      offset,
      limit: rowsPerPage,
      carrierIds: carriers,
    })
    return { costConfigurations: response.items, count: response.count }
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const get = async (id: string): Promise<ICostConfiguration | IError> => {
  try {
    const response = await Api.get(`cost-configurations/details/${id}`)
    return response
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const create = async (
  costConfiguration: ICostConfiguration,
): Promise<ICostConfiguration | IError> => {
  try {
    const response = await Api.post('cost-configurations', costConfiguration)
    return response
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

const update = async (
  id: string,
  costConfiguration: ICostConfiguration,
): Promise<ICostConfiguration | IError> => {
  try {
    const response = await Api.patch(`cost-configurations/${id}`, costConfiguration)
    return response
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

const getReporting = async (
  startDate: string,
  endDate: string,
  carriersToFilter: string[],
): Promise<IWarehouseCostReporting[] | IError> => {
  try {
    const response = await Api.get('cost-configurations/reporting', {
      minDate: new Date(startDate).toISOString(),
      maxDate: new Date(endDate).toISOString(),
      carrierIds: carriersToFilter,
    })
    return response
  } catch (error) {
    return { error: { message: error.message } }
  }
}

export default {
  getList,
  get,
  create,
  update,
  getReporting,
}
