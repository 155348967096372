import React from 'react'
import { Switch } from '@material-ui/core'
import { IWarehouseCartDropoffConfig } from 'interfaces/IWarehouseCartDropoffConfig'
import useStyles from './styles'

interface IProps {
  active: boolean
  onChange: (fieldName: string, value: unknown, propertyName?: string) => void
  name: string
  children?: JSX.Element
  disabled?: boolean
}
export const FieldConfig = ({ active, onChange, name, children, disabled = false }: IProps): JSX.Element => {
  const classes = useStyles()

  const handleActiveChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    onChange(name as keyof IWarehouseCartDropoffConfig, event.target.checked, 'active')
  }

  return (
    <div className={classes.fieldLine}>
      <div className={classes.childrenContainer}>{children}</div>
      <div className={classes.toggleContainer}>
        <Switch
          color="primary"
          checked={active}
          onChange={handleActiveChange}
          name={`active-${name}`}
          disabled={disabled}
        />
      </div>
    </div>
  )
}
