import React, { ReactNode, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IVehicle } from 'interfaces/IVehicle'
import { IVehicleFilters } from 'interfaces/interfaces'
import VehiclesApi from 'api/vehicles'
import { isIError } from 'api/types'
import { getErrorList } from 'utils/errorUtils'
import { COLUMNS_MODEL_VEHICLES_LIST, COLUMNS_MODEL_VEHICLE_REPORTS } from 'constants/table'
import { IVehicleReports } from 'interfaces/IVehicleReportsList'
import { FeedbackContext } from './FeedbackContext'

interface IVehiclesContext {
  vehicles: IVehicle[]
  count: number
  vehicleDetails: IVehicle
  createSuccess?: boolean
  updateSuccess?: boolean
  createVehicle: (vehicle: IVehicle) => void
  getList: (
    filters?: IVehicleFilters,
    offset?: number,
    rowsPerPage?: number,
    sortField?: number,
    sortDirection?: string,
  ) => void
  getDetails: (id: string) => void
  updateVehicle: (id: string, vehicle: IVehicle) => void
  vehicleReports: IVehicleReports[]
  countVehicleReports: number
  getReports: (
    id: string,
    offset?: number,
    rowsPerPage?: number,
    sortField?: number,
    sortDirection?: string,
  ) => void
}

interface IProps {
  children: ReactNode
}

const VehicleContext = React.createContext<IVehiclesContext>({} as IVehiclesContext)
const { Provider, Consumer } = VehicleContext

function VehiclesProvider({ children }: IProps): JSX.Element {
  const [vehicles, setVehicles] = useState<IVehicle[]>([])
  const [createSuccess, setCreateSuccess] = useState<boolean | undefined>()
  const [updateSuccess, setUpdateSuccess] = useState<boolean | undefined>()
  const [count, setCount] = useState<number>(0)
  const [vehicleDetails, setVehicleDetails] = useState<IVehicle>({})
  const [vehicleReports, setVehicleReports] = useState<IVehicleReports[]>([])
  const [countVehicleReports, setCountVehicleReports] = useState<number>(0)
  const { t } = useTranslation()
  const { openErrorSnack, openSuccessSnack, toggleLoader } = useContext(FeedbackContext)

  const getList = async (
    filters?: IVehicleFilters,
    offset?: number,
    rowsPerPage?: number,
    sortField?: number,
    sortDirection?: string,
  ): Promise<void> => {
    toggleLoader(true)
    const response = await VehiclesApi.getList(
      filters,
      offset,
      rowsPerPage,
      COLUMNS_MODEL_VEHICLES_LIST.find((_column, index) => index === sortField)?.field,
      sortDirection === 'desc' ? -1 : 1,
    )
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      setVehicles(response.vehicles)
      setCount(response.count)
    }
    toggleLoader(false)
  }

  const createVehicle = async (vehicle: IVehicle): Promise<void> => {
    toggleLoader(true)
    const response = await VehiclesApi.create(vehicle)
    if (isIError(response)) {
      response.error.errorList = getErrorList(response)
      if (response.error.fieldErrors) {
        openErrorSnack(response.error.errorList.join('\n'))
      } else {
        openErrorSnack(response.error.message)
      }
    } else {
      setCreateSuccess(true)
      openSuccessSnack(t('VehicleScreen.createdVehicle'))
    }
    toggleLoader(false)
  }

  const updateVehicle = async (id: string, vehicle: IVehicle): Promise<void> => {
    toggleLoader(true)
    const response = await VehiclesApi.update(id, vehicle)
    if (isIError(response)) {
      response.error.errorList = getErrorList(response)
      if (response.error.fieldErrors) {
        openErrorSnack(response.error.errorList.join('\n'))
      } else {
        openErrorSnack(response.error.message)
      }
    } else {
      setUpdateSuccess(true)
      openSuccessSnack(t('VehicleScreen.updatedVehicle'))
    }
    toggleLoader(false)
  }

  const getDetails = async (id: string): Promise<void> => {
    toggleLoader(true)
    const response = await VehiclesApi.get(id)
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      setVehicleDetails(response)
    }
    toggleLoader(false)
  }

  const getReports = async (
    id: string,
    offset?: number,
    rowsPerPage?: number,
    sortField?: number,
    sortDirection?: string,
  ): Promise<void> => {
    toggleLoader(true)
    const response = await VehiclesApi.getVehicleReports(
      id,
      offset,
      rowsPerPage,
      COLUMNS_MODEL_VEHICLE_REPORTS.find((_column, index) => index === sortField)?.field,
      sortDirection === 'desc' ? -1 : 1,
    )
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      setVehicleReports(response.items)
      setCountVehicleReports(response.count)
    }
    toggleLoader(false)
  }

  return (
    <Provider
      value={{
        vehicles,
        count,
        getList,
        createVehicle,
        createSuccess,
        vehicleDetails,
        getDetails,
        updateVehicle,
        updateSuccess,
        getReports,
        vehicleReports,
        countVehicleReports,
      }}
    >
      {children}
    </Provider>
  )
}

export default VehiclesProvider

export { Consumer as VehiclesConsumer, VehicleContext }
