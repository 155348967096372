import Api from 'services/api'
import { IAlert } from 'interfaces/IAlert'

import { IError } from 'api/types'
import { IAlertsFilters } from 'interfaces/interfaces'

const getAlerts = async (
  filters?: IAlertsFilters,
  offset?: number,
  rowsPerPage?: number,
  sortBy?: string,
  sortDirection?: number,
): Promise<{ alerts: Array<IAlert>; count: number } | IError> => {
  try {
    const res = await Api.post('alerts/search', {
      ...filters,
      offset,
      limit: rowsPerPage,
      sortBy: sortBy || 'createdDate',
      sortDirection: sortDirection || -1,
    })
    return { alerts: res.items, count: res.count }
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const updateAlertReadState = async (
  id: string,
  read: boolean,
): Promise<{ alert?: IAlert; error?: IError } | IError> => {
  try {
    const body = {
      read,
    }
    const res = await Api.patch(`alerts/${id}`, body)
    return res
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

export default {
  getAlerts,
  updateAlertReadState,
}
