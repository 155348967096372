import React, { useEffect, useState } from 'react'

import FormAction from 'components/Button/FormAction'
import ShiftsProvider, { ShiftsConsumer } from 'store/ShiftsContext'
import { ILogisticShift } from 'interfaces/ILogisticShift'
import LogisticShiftCard from './LogisticShiftCard'

interface IProps {
  shifts?: ILogisticShift[]
  getLogisticShifts: () => void
  updateLogisticShifts: (logisticShifts: ILogisticShift[]) => void
}

const LogisticShiftScreen = ({
  shifts,
  getLogisticShifts,
  updateLogisticShifts,
}: IProps): JSX.Element => {
  const [logisticShifts, setLogisticShifts] = useState<ILogisticShift[]>([])

  useEffect(() => {
    if (getLogisticShifts) {
      getLogisticShifts()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (shifts) {
      setLogisticShifts(shifts)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shifts])

  const handleCancelClick = (): void => {
    if (shifts) {
      setLogisticShifts(shifts)
    }
  }

  const handleSaveClick = (): void => {
    if (updateLogisticShifts) {
      updateLogisticShifts(logisticShifts)
    }
  }

  const handleLogisticShiftsChange = (changedLogisticShifts: ILogisticShift[]): void => {
    setLogisticShifts(changedLogisticShifts)
  }

  return (
    <>
      <LogisticShiftCard
        logisticShifts={logisticShifts}
        onLogisticShiftsChange={handleLogisticShiftsChange}
      />
      <FormAction onSaveClick={handleSaveClick} onCancelClick={handleCancelClick} />
    </>
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <ShiftsProvider>
    <ShiftsConsumer>
      {(shiftsContext): JSX.Element => (
        <LogisticShiftScreen
          shifts={shiftsContext.shifts}
          getLogisticShifts={shiftsContext.getLogisticShifts}
          updateLogisticShifts={shiftsContext.updateLogisticShifts}
          {...props}
        />
      )}
    </ShiftsConsumer>
  </ShiftsProvider>
)
