import React, { Suspense, useCallback, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { AuthContext } from 'store/AuthContext'
import { ISector } from 'interfaces/ISector'
import AppConfigProvider from 'store/AppConfigContext'
import CustomToolbar from './Toolbar/CustomToolbar'
import { PlanificationContext } from '../PlanningStore'
import { StyledContainer, StyledMapContainer } from './BasicComponents'
import Drawer from './Drawer'
import FabMenu from './FabMenu'
import DeselectButton from './DeselectButton'
import PlanCreationModal from './PlanModal/PlanCreationModal'
import FiltersModal from './FiltersModal/FiltersModal'
import OptimizeModal from './OptimizeModal/OptimizeModal'
import BackdropLoader from './BackdropLoader'
import PinFieldsMenu from './PinFieldsMenu'
import { CardSettingsMenu } from './CardSettingsMenu'

const MapWithSelect = React.lazy(() => import('./MapWithSelect'))

export enum screenMode {
  both,
  drawer,
  map,
  __LENGTH,
}

interface IProps {
  sectors: ISector[]
  toggleClusterMode?: () => void
  toggleOutsourceMode?: () => void
  path: string
}

const StyledDiv = styled.div`
  height: calc(100% + 32px);
  margin: -16px;
`

const Planning = ({
  sectors,
  toggleClusterMode,
  toggleOutsourceMode,
  path,
}: IProps): JSX.Element => {
  const [isPlanModalDisplayed, setPlanModalDisplayed] = useState<boolean>(false)
  const [isFiltersModalVisible, setFiltersModalVisible] = useState<boolean>(false)
  const [isOptimizeModalVisible, setOptimizeModalVisible] = useState<boolean>(false)
  const [displayMode, setDisplayMode] = useState<number>(screenMode.both)
  const [isSpecificCreation, setIsSpecificCreation] = useState<boolean>(true)
  const [maxOptimizationTime, setMaxOptimizationTime] = useState<number>(0)
  const [toggleCardSettingsModal, setToggleCardSettingsModal] = useState<boolean>(false)
  const {
    selectedPlan,
    isClusterMode,
    highlightedElement,
    setHighlightedElement,
    isMarkerClicked,
    setIsMarkerClicked,
  } = useContext(PlanificationContext)
  const history = useHistory()
  const { user, shouldDisplayPinToolTipFields } = useContext(AuthContext)

  useEffect(() => {
    if (highlightedElement) {
      const element = document.getElementById(highlightedElement.id)
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        })
      }
    }
  }, [highlightedElement])

  useEffect(() => {
    if (selectedPlan) {
      history.replace(`${path}?id=${selectedPlan?.id}`)
    } else {
      history.replace(path)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggleCreationModal = (): void => setPlanModalDisplayed((prev) => !prev)

  const toggleFiltersModal = (): void => setFiltersModalVisible((prev) => !prev)

  const toggleOptimizeModal = (): void => setOptimizeModalVisible((prev) => !prev)

  const [anchorElPinFieldsMenu, setAnchorElPinFieldsMenu] = useState<null | HTMLElement>(null)

  const [anchorElCardSettingsMenu, setAnchorElCardSettingsMenu] = useState<null | HTMLElement>(null)

  const handleClickPinConfigure = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorElPinFieldsMenu(event.currentTarget)
  }

  function toggleDisplayMode(): void {
    setDisplayMode((prev) => (prev === screenMode.__LENGTH - 1 ? 0 : prev + 1))
  }

  const getMapWidth = useCallback(
    () =>
      displayMode === screenMode.both ? '45%' : displayMode === screenMode.drawer ? '0%' : '100%',
    [displayMode],
  )

  const handleOpenModalCreation = (isSpecific: boolean): void => {
    setIsSpecificCreation(isSpecific)
    toggleCreationModal()
  }
  const handleClick = () => {
    if (!isMarkerClicked) {
      setHighlightedElement(undefined)
    }
    setIsMarkerClicked(false)
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleClick()
    }
  }

  const handleToglleCardSettingsModal = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorElCardSettingsMenu(event.currentTarget)
    setToggleCardSettingsModal(!toggleCardSettingsModal)
  }

  return (
    <StyledDiv role="button" tabIndex={0} onClick={handleClick} onKeyDown={handleKeyDown}>
      <CustomToolbar
        toggleCreationModal={handleOpenModalCreation}
        toggleFiltersModal={toggleFiltersModal}
        toggleOptimizeModal={toggleOptimizeModal}
      />
      <div>
        <StyledContainer>
          <StyledMapContainer data-cy="map" mapWidth={getMapWidth()}>
            <Suspense fallback={<div>Chargement...</div>}>
              <MapWithSelect
                mapWidth={getMapWidth()}
                isClusterMode={isClusterMode}
                sectors={sectors}
                pinsVisibilityZoom={user?.tenantConfig?.planningPinsZoom}
              />
            </Suspense>
          </StyledMapContainer>
          <Drawer setMaxOptimizationTime={setMaxOptimizationTime} />
          <FabMenu
            cardSettingsOpened={Boolean(anchorElCardSettingsMenu)}
            toggleClusterMode={toggleClusterMode}
            displayMode={displayMode}
            toggleDisplayMode={toggleDisplayMode}
            toggleToolTipConfigModal={handleClickPinConfigure}
            toggleOutsourceMode={toggleOutsourceMode}
            toggleCardSettingsModal={handleToglleCardSettingsModal}
          />
          {anchorElCardSettingsMenu && (
            <CardSettingsMenu
              anchorEl={anchorElCardSettingsMenu}
              setAnchorEl={setAnchorElCardSettingsMenu}
            />
          )}
          {shouldDisplayPinToolTipFields && (
            <PinFieldsMenu
              anchorEl={anchorElPinFieldsMenu}
              setAnchorEl={setAnchorElPinFieldsMenu}
            />
          )}
          <DeselectButton />
        </StyledContainer>
        {isPlanModalDisplayed && (
          <PlanCreationModal
            isSpecificCreation={isSpecificCreation}
            toggleModal={toggleCreationModal}
            isVisible={isPlanModalDisplayed}
          />
        )}
        {isFiltersModalVisible && (
          <FiltersModal toggleModal={toggleFiltersModal} isVisible={isFiltersModalVisible} />
        )}
        {isOptimizeModalVisible && (
          <OptimizeModal
            toggleModal={toggleOptimizeModal}
            isVisible={isOptimizeModalVisible}
            setMaxOptimizationTime={setMaxOptimizationTime}
          />
        )}
        <BackdropLoader maxOptimizationTime={maxOptimizationTime} />
      </div>
    </StyledDiv>
  )
}

export default (props: IProps): JSX.Element => (
  <AppConfigProvider>
    <Planning {...props} />
  </AppConfigProvider>
)
