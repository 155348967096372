import Api from 'services/api'

import { HERE_API_KEY } from 'constants/env'
import { IAutocompleteResult } from 'interfaces/IAutocompleteResult'
import { IGeoResult } from 'interfaces/IGeoResult'

import { IError } from '../types'

const MAP_RESOLUTION = 256
const HERE_MAP_VERSION = 2.1

const HERE_MAP_BASEURL = 'https://1.base.maps.ls.hereapi.com'
const HERE_SAT_BASEURL = 'https://2.aerial.maps.ls.hereapi.com'
const HERE_BASEPATH = `/maptile/${HERE_MAP_VERSION}/maptile/newest/`

const geocode = async (
  roadNumber?: string,
  street?: string,
  zipCode?: string,
  city?: string,
  country?: string,
  language?: string,
  warehouseId?: string,
): Promise<IGeoResult[] | IError> => {
  try {
    const res = await Api.post('address/geocode', {
      roadNumber,
      road: street,
      city,
      country,
      language,
      zipCode,
      warehouseId,
    })
    return res.items.map((item) => ({
      score: item.scoring.queryScore,
      position: { latitude: item.position.lat, longitude: item.position.lng },
      address: {
        label: item.address.label,
        roadNumber: item.address.houseNumber,
        road: item.address.street,
        zipCode: item.address.postalCode,
        city: item.address.city,
        country: item.address.countryName,
      },
    }))
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const computeMapUrls = (useSatellite: boolean): { tileUrlDark: string; tileUrlLight: string } => {
  const baseUrl = useSatellite ? HERE_SAT_BASEURL : HERE_MAP_BASEURL
  const dayMapFamily = useSatellite ? 'satellite.day' : 'normal.day'
  const nightMapFamily = useSatellite ? 'satellite.day' : 'reduced.night'

  return {
    tileUrlDark: `${baseUrl}${HERE_BASEPATH}${nightMapFamily}/{z}/{x}/{y}/${MAP_RESOLUTION}/png8?apiKey=${HERE_API_KEY}`,
    tileUrlLight: `${baseUrl}${HERE_BASEPATH}${dayMapFamily}/{z}/{x}/{y}/${MAP_RESOLUTION}/png8?apiKey=${HERE_API_KEY}`,
  }
}

const autocompleteAddress = async (
  address?: string,
  warehouseId?: string,
  language?: string,
): Promise<IAutocompleteResult[] | IError> => {
  try {
    const res = await Api.post('address/autocomplete', { address, warehouseId, language })
    return res.items.map((item) => ({
      title: item.title,
      address: {
        label: item.address.label,
        roadNumber: item.address.houseNumber,
        road: item.address.street,
        zipCode: item.address.postalCode,
        city: item.address.city,
        country: item.address.countryName,
      },
    }))
  } catch (error) {
    return { error: { message: error.message } }
  }
}

export default {
  geocode,
  autocompleteAddress,
  computeMapUrls,
}
