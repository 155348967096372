import { TextField, Theme } from '@material-ui/core'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useTheme } from '@material-ui/core/styles'

import {
  CarrierFilter,
  DealerFilter,
  DeliveryTypeFilter,
  NotificationTypeFilter,
  SiteFilter,
  SmsCommunicationServiceFilter,
  TenantFilter,
} from 'components/Inputs/ListFilter'
import { IIdParam } from 'interfaces/IIdParam'
import {
  INotifierConfiguration,
  NotificationType,
  SmsCommunicationService,
} from 'interfaces/INotifierConfiguration'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import NotifierConfigurationsProvider, {
  NotifierConfigurationsContext,
} from 'store/NotifierConfigurationsContext'
import Card from 'components/Card/Card'
import useStyles from 'constants/cruStyles'
import { useTranslation } from 'react-i18next'
import { Switch } from 'components/Inputs/ToggleSwitch.stories'
import FormAction from 'components/Button/FormAction'
import { FeedbackContext } from 'store/FeedbackContext'
import { isValidJson } from 'utils/functions'
import { IWarehouse } from 'interfaces/IWarehouse'
import { ContentContext } from 'store/ContentContext'
import { IDealer, ICarrier } from 'interfaces/interfaces'
import { AuthContext } from 'store/AuthContext'
import { USER_ROLES } from 'constants/constants'

const FieldError = styled.span<{ theme: Theme }>`
  color: ${(props) => props.theme.color.red};
`

const NotifierConfigFormScreen = (): JSX.Element => {
  const {
    createNotifierConfiguration,
    updateNotifierConfiguration,
    getDetails,
    notifierConfigurationsDetails,
    updateSuccess,
    createSuccess,
  } = useContext(NotifierConfigurationsContext)
  const isInitialMount = useRef(true)

  const [notifierConfiguration, setNotifierConfiguration] = useState<
    Partial<INotifierConfiguration>
  >({
    warehouseIds: [],
    dealerIds: [],
    carrierIds: [],
  })
  const [allowedSites, setAllowedSites] = useState<IWarehouse[]>([])
  const [allowedDealers, setAllowedDealers] = useState<IDealer[]>([])
  const [allowedCarriers, setAllowedCarriers] = useState<ICarrier[]>([])
  const [isValidSender, setIsValidSender] = useState<boolean>(true)
  const { user: currentUser } = useContext(AuthContext)

  const history = useHistory()
  const { id } = useParams<IIdParam>()
  const classes = useStyles()
  const { t } = useTranslation()
  const { openErrorSnack } = useContext(FeedbackContext)
  const { allSites, allDealers, allCarriers, sites, dealers, carriers } = useContext(ContentContext)
  const theme = useTheme()

  useEffect(() => {
    const notifierConfigurationTenantId = notifierConfiguration.tenantId || currentUser?.tenantId
    const userAllowedSites = currentUser?.roles.includes(USER_ROLES.superAdmin) ? allSites : sites

    setAllowedSites(
      userAllowedSites.filter((site) => site.tenantId === notifierConfigurationTenantId),
    )

    const userAllowedDealers = currentUser?.roles.includes(USER_ROLES.superAdmin)
      ? allDealers
      : dealers
    setAllowedDealers(
      userAllowedDealers.filter((dealer) => dealer.tenantId === notifierConfigurationTenantId),
    )

    if (!isInitialMount.current) {
      setNotifierConfiguration({
        ...notifierConfiguration,
        warehouseIds: [],
        dealerIds: [],
        carrierIds: [],
      })
    }
    if (
      userAllowedSites.length > 0
      && userAllowedDealers.length > 0
      && notifierConfiguration.tenantId
    ) {
      isInitialMount.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSites, allDealers, notifierConfiguration.tenantId, sites, dealers])

  useEffect(() => {
    if (currentUser?.tenantId && !currentUser?.roles.includes(USER_ROLES.superAdmin)) {
      setNotifierConfiguration({
        ...notifierConfiguration,
        tenantId: currentUser?.tenantId,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.tenantId])

  const getAllowedCarriers = (): ICarrier[] => {
    const carriersArray: ICarrier[] = []
    const userAllowedCarriers = currentUser?.roles.includes(USER_ROLES.superAdmin)
      ? allCarriers
      : carriers

    userAllowedCarriers.forEach((carrier) => {
      if (
        carrier.active
        && carrier.tenantId === notifierConfiguration.tenantId
        && carrier.warehouses.some((carrierWarehouse) =>
          notifierConfiguration?.warehouseIds?.includes(carrierWarehouse.id),
        )
      ) {
        carriersArray.push(carrier)
      }
    })
    return carriersArray
  }

  const isCarrierLinkedToWarehouse = (carrier: ICarrier, warehouses: string[]): boolean =>
    carrier.warehouses.some((warehouse) =>
      warehouses.some((currentWarehouse) => currentWarehouse === warehouse.id),
    )

  const getPersistingCarriers = (warehouseIds: string[]) => {
    const newCarriersArray: string[] = []
    notifierConfiguration.carrierIds?.forEach((carrierId) => {
      const carrierToCheck = allowedCarriers.find((e) => e.id === carrierId)
      if (carrierToCheck && warehouseIds) {
        if (isCarrierLinkedToWarehouse(carrierToCheck, warehouseIds)) {
          newCarriersArray.push(carrierToCheck.id)
        }
      }
    })
    handleFilterChange(newCarriersArray, 'carrierIds')
  }

  useEffect(() => {
    setAllowedCarriers(getAllowedCarriers())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifierConfiguration.warehouseIds, allCarriers, carriers])

  useEffect(() => {
    if (id && getDetails) {
      getDetails(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (createSuccess || updateSuccess) {
      history.push(ROUTES_PATH.notifierConfigList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createSuccess, updateSuccess])

  useEffect(() => {
    if (notifierConfigurationsDetails && id) {
      setNotifierConfiguration({
        ...notifierConfigurationsDetails,
        notificationParam: {
          ...notifierConfigurationsDetails.notificationParam,
          specific: notifierConfigurationsDetails.notificationParam?.specific
            ? JSON.stringify(notifierConfigurationsDetails.notificationParam?.specific)
            : undefined,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifierConfigurationsDetails])

  const handleFilterChange = (values: string[], name: string): void => {
    setNotifierConfiguration((prev) => ({
      ...prev,
      [name]: values,
    }))
  }

  const handleTenantChange = (values: string[]): void => {
    setNotifierConfiguration({
      ...notifierConfiguration,
      tenantId: values?.length ? values[0] : '',
    })
  }

  const isValidSenderString = (sender: string): boolean => {
    const regExp = /^(?=.*[a-zA-Z].*)([a-zA-Z0-9\s]{1,11})$/g
    const match = sender.match(regExp)
    return sender === '' ? true : match !== null
  }

  const handleSenderChange = (sender: string): void => {
    setIsValidSender(isValidSenderString(sender))
    setNotifierConfiguration({
      ...notifierConfiguration,
      notificationParam: {
        ...notifierConfiguration.notificationParam,
        sender,
      },
    })
  }

  const handleMessageChange = (message: string): void => {
    setNotifierConfiguration({
      ...notifierConfiguration,
      notificationParam: {
        ...notifierConfiguration.notificationParam,
        message,
      },
    })
  }

  const handleSpecificChange = (specific: string): void => {
    setNotifierConfiguration({
      ...notifierConfiguration,
      notificationParam: {
        ...notifierConfiguration.notificationParam,
        specific,
      },
    })
  }

  const handleSendSmsChange = (sendSms: boolean): void => {
    setNotifierConfiguration({
      ...notifierConfiguration,
      notificationParam: {
        ...notifierConfiguration.notificationParam,
        sendSms,
      },
    })
  }

  const handleNotificationTypeChange = (notificationType: NotificationType): void => {
    setNotifierConfiguration({
      ...notifierConfiguration,
      notificationType,
    })
  }

  const handleSmsCommunicationServiceChange = (
    smsCommunicationService: SmsCommunicationService,
  ): void => {
    setNotifierConfiguration({
      ...notifierConfiguration,
      smsCommunicationService: smsCommunicationService ?? null,
    })
  }

  const handleSaveClick = async (): Promise<void> => {
    if (
      notifierConfiguration.notificationParam?.specific
      && !isValidJson(notifierConfiguration.notificationParam?.specific as string)
    ) {
      openErrorSnack(t('NotifierConfigurationsScreen.incorrectJsonFormat'))
      return
    }
    if (id) {
      if (updateNotifierConfiguration) {
        await updateNotifierConfiguration(id, notifierConfiguration as INotifierConfiguration)
      }
    } else {
      if (createNotifierConfiguration) {
        await createNotifierConfiguration(notifierConfiguration as INotifierConfiguration)
      }
    }
  }

  const handleCancelClick = (): void => {
    history.push(ROUTES_PATH.notifierConfigList)
  }

  return (
    <>
      <Card
        dataCy="configurationCard"
        title={t('NotifierConfigurationsScreen.configurations').toUpperCase()}
        contentClassName={classes.contentRef}
      >
        <div className={classes.fieldsContainer}>
          {currentUser?.roles.includes(USER_ROLES.superAdmin) && (
            <div className={classes.filters}>
              <TenantFilter
                dataCy="tenantPicker"
                handleChange={(values: string[]) => handleTenantChange(values)}
                disabled={Boolean(id)}
                onlyOneValue
                shouldFillWidth
                ids={notifierConfiguration.tenantId ? [notifierConfiguration.tenantId] : []}
                required
                placeholder={t('tablesEntries.tenant')}
                isUnselectAllowed={false}
              />
            </div>
          )}

          <div className={classes.filters}>
            <SiteFilter
              handleChange={(values: string[]): void => {
                handleFilterChange(values, 'warehouseIds')
                getPersistingCarriers(values)
              }}
              ids={notifierConfiguration.warehouseIds || []}
              shouldFillWidth
              required
              dataCy="sitePicker"
              placeholder={t('tablesEntries.sites')}
              shouldUseUserSites={false}
              isUnselectAllowed={false}
              data={allowedSites}
              disabled={!notifierConfiguration.tenantId}
              defaultWhenCleared={allowedSites[0]?.id}
            />
          </div>
          <div className={classes.filters}>
            <CarrierFilter
              handleChange={(values: string[]): void => handleFilterChange(values, 'carrierIds')}
              ids={notifierConfiguration.carrierIds || []}
              shouldFillWidth
              dataCy="carrierPicker"
              placeholder={t('tablesEntries.carriers')}
              data={allowedCarriers}
              disabled={
                !notifierConfiguration.tenantId
                || !notifierConfiguration.warehouseIds
                || notifierConfiguration.warehouseIds.length === 0
              }
            />
          </div>
          <div className={classes.filters}>
            <DealerFilter
              handleChange={(values: string[]): void => handleFilterChange(values, 'dealerIds')}
              ids={notifierConfiguration.dealerIds || []}
              shouldFillWidth
              dataCy="dealerPicker"
              placeholder={t('tablesEntries.dealers')}
              data={allowedDealers}
              disabled={!notifierConfiguration.tenantId}
            />
          </div>
          <div className={classes.filters}>
            <DeliveryTypeFilter
              dataCy="deliveryPicker"
              handleChange={(values: string[]): void => handleFilterChange(values, 'deliveryTypes')}
              ids={notifierConfiguration.deliveryTypes?.map(String) || []}
              shouldFillWidth
              required
              isUnselectAllowed={false}
            />
          </div>
          <div className={classes.filters}>
            <NotificationTypeFilter
              data-cy="notificationType"
              placeholder={t('NotifierConfigurationsScreen.notificationType')}
              handleChange={(values: string[]): void =>
                handleNotificationTypeChange(values[0] as NotificationType)}
              ids={
                notifierConfiguration.notificationType
                  ? [notifierConfiguration.notificationType]
                  : []
              }
              required
              shouldFillWidth
            />
          </div>
          <div className={classes.filters}>
            <SmsCommunicationServiceFilter
              data-cy="smsCommunicationService"
              placeholder={t('NotifierConfigurationsScreen.smsCommunicationService')}
              handleChange={(values: string[]): void =>
                handleSmsCommunicationServiceChange(values[0] as SmsCommunicationService)}
              ids={
                notifierConfiguration.smsCommunicationService
                  ? [notifierConfiguration.smsCommunicationService]
                  : []
              }
              shouldFillWidth
            />
          </div>
          <TextField
            data-cy="sender"
            error={!isValidSender}
            label={t('NotifierConfigurationsScreen.sender').toUpperCase()}
            onChange={(event) => handleSenderChange(event.target.value)}
            type="text"
            name="sender"
            value={notifierConfiguration.notificationParam?.sender || ''}
          />
          {!isValidSender && (
            <FieldError theme={theme}>
              {t('NotifierConfigurationsScreen.validSenderInstructions')}
            </FieldError>
          )}
          <TextField
            data-cy="message"
            label={t('NotifierConfigurationsScreen.message').toUpperCase()}
            onChange={(event) => handleMessageChange(event.target.value)}
            type="text"
            name="message"
            value={notifierConfiguration.notificationParam?.message || ''}
          />
          <TextField
            data-cy="specific"
            label={t('NotifierConfigurationsScreen.specific').toUpperCase()}
            onChange={(event) => handleSpecificChange(event.target.value)}
            type="text"
            name="notificationParam.specific"
            value={notifierConfiguration.notificationParam?.specific || ''}
          />
        </div>
        <Switch
          dataCy="sendSms"
          name="notificationParam.sendSms"
          onChange={(event) => handleSendSmsChange(event.target.checked)}
          checked={notifierConfiguration.notificationParam?.sendSms || false}
          label={t('NotifierConfigurationsScreen.sendSms').toUpperCase()}
        />
      </Card>
      <FormAction onSaveClick={handleSaveClick} onCancelClick={handleCancelClick} />
    </>
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <NotifierConfigurationsProvider>
    <NotifierConfigFormScreen {...props} />
  </NotifierConfigurationsProvider>
)
