export interface IDeliveryType {
  id?: number
  code?: number
  label: string
  active: boolean
  errorCode?: boolean
  errorLabel?: boolean
  isPredefined?: boolean
  isNew?: boolean
}

export interface IDeliveryTypeItem {
  id: string
  name: string
}

export enum DefaultDeliveryTypeCode {
  CustomerSite,
  HubSpot,
  Mixed,
}
