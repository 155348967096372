interface RolePermissions {
  id: string
  hasRightToCreate: string[]
  rolesNotAllowed: string[]
}

export const getPossibleRolesToAdd = (
  currentUserRoles: string[],
  userRolesBeingEdited: string[],
  rolesRules: RolePermissions[],
): string[] => {
  const possibleRoles: string[] = []
  const rolesCurrentUserCanCreate = getRolesCurrentUserCanCreate(currentUserRoles, rolesRules)
  const rolesNotAllowed = getRolesNotAllowed(userRolesBeingEdited, rolesRules)

  rolesCurrentUserCanCreate.forEach((userRoleToAdd) => {
    if (
      !rolesNotAllowed.has(userRoleToAdd)
      && !possibleRoles.includes(userRoleToAdd)
      && !userRolesBeingEdited.includes(userRoleToAdd)
    ) {
      possibleRoles.push(userRoleToAdd)
    }
  })

  return possibleRoles
}

const getRolePermissions = (
  role: string,
  rolesRules: RolePermissions[],
): RolePermissions | undefined => rolesRules.find((roleRule) => roleRule.id === role)

const getRolesCurrentUserCanCreate = (
  currentUserRoles: string[],
  rolesRules: RolePermissions[],
): Set<string> => {
  const rolesCurrentUserCanCreate: Set<string> = new Set()
  currentUserRoles.forEach((currentUserRole) => {
    const currentUserRolePermissions = getRolePermissions(currentUserRole, rolesRules)

    if (currentUserRolePermissions) {
      currentUserRolePermissions.hasRightToCreate.forEach((role) => {
        rolesCurrentUserCanCreate.add(role)
      })
    }
  })
  return rolesCurrentUserCanCreate
}

const getRolesNotAllowed = (
  userRolesBeingEdited: string[],
  rolesRules: RolePermissions[],
): Set<string> => {
  const rolesNotAllowed: Set<string> = new Set()
  userRolesBeingEdited.forEach((userRoleBeingEdited) => {
    const userRoleBeingEditedPermissions = getRolePermissions(userRoleBeingEdited, rolesRules)
    if (userRoleBeingEditedPermissions) {
      userRoleBeingEditedPermissions.rolesNotAllowed.forEach((role) => {
        rolesNotAllowed.add(role)
      })
    }
  })
  return rolesNotAllowed
}
