import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { Grid } from '@material-ui/core'

import { DestinationType, OrderStepKey, TransportType } from 'constants/constants'
import { IOrderForm } from 'interfaces/IOrders'
import { DefaultSiteContext } from 'store/DefaultSiteContext'
import SitesProvider, { SitesConsumer } from 'store/SitesContext'
import { IWarehouseCartDropoffConfig } from 'interfaces/IWarehouseCartDropoffConfig'
import { convertToMinutes } from 'utils/libs/time-conversion'
import { FeedbackContext } from 'store/FeedbackContext'
import WarehouseTimeSlotsApi from 'api/warehouseTimeSlots'
import { isIError } from 'api/types'
import { IOrderTimeSlot, ISelectedTimeSlot } from 'interfaces/IWarehouseTimeSlot'
import { ICartDropoffDraft } from 'interfaces/ICartDropoffDraft'
import OrderForm from './OrderForm'
import OrderStepper from './OrderStepper'
import useStyles from './styles'

interface IProps {
  config: IWarehouseCartDropoffConfig | null
  getCartDropoffConfig: (warehouseId: string) => void
}

const CartDropoffScreen = ({ config, getCartDropoffConfig }: IProps): JSX.Element => {
  const classes = useStyles()
  const { defaultSiteId } = useContext(DefaultSiteContext)
  const { toggleLoader } = useContext(FeedbackContext)
  const [destinationType, setDestinationType] = useState<DestinationType>(DestinationType.Address)
  const [order, setOrder] = useState<Partial<IOrderForm>>({})
  const [orderTimeSlots, setOrderTimeSlots] = useState<IOrderTimeSlot[]>()
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<ISelectedTimeSlot>()
  const [activeStep, setActiveStep] = useState<OrderStepKey>(OrderStepKey.Origin)
  const [existingDraft, setExistingDraft] = useState<ICartDropoffDraft>()

  const removePastTimeSlots = (timeSlots: IOrderTimeSlot[]): IOrderTimeSlot[] => {
    const now = new Date()
    return timeSlots.map((slot) => ({
      ...slot,
      timeSlotDetails: slot.timeSlotDetails.filter(
        (slotDetail) => now <= new Date(slotDetail.endTime),
      ),
    }))
  }

  async function getTimeSlots() {
    if (order.warehouseId) {
      toggleLoader(true)
      const response = await WarehouseTimeSlotsApi.getTimeSlotsPerWarehouseOrSector(
        order.warehouseId,
        undefined,
        undefined,
        0,
        3,
        true,
      )
      if (!isIError(response)) {
        setOrderTimeSlots(removePastTimeSlots(response.timeSlots))
      }
      toggleLoader(false)
    }
  }

  useEffect(() => {
    getTimeSlots()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.warehouseId])

  const initializeOrder = (conf: IWarehouseCartDropoffConfig): void => {
    setOrder({
      deliveryType: conf.deliveryType?.defaultValue as number,
      warehouseId: conf.originSiteId?.defaultValue as string,
      deliveryAddressCountry: conf.country?.defaultValue as string,
      enterpriseLabel: conf.enterprise?.defaultValue as string,
      deliveryInstructions: conf.deliveryInstructions?.defaultValue as string,
      transportType: conf.transportType?.defaultValue as TransportType,
      estimatedServiceTime: convertToMinutes(
        conf.estimatedServiceTime?.defaultValue as number,
      ) as number,
      orderAmount: conf.value?.defaultValue as string,
      estimatedWeight: conf.estimatedWeight?.defaultValue as number,
      deliveryFloor: conf.floor?.defaultValue as number,
      deliveryIsElevatorPresent: conf.hasElevator?.defaultValue as boolean,
      deliveryDoorCode: conf.digicode?.defaultValue as string,
      orderInstructions: conf.orderInstructions?.defaultValue as string,
      numberOfArticles: conf.numberOfArticles?.defaultValue as number,
      orderNumber: conf.orderNumber?.defaultValue as string,
    })
    setDestinationType(conf.destinationType?.defaultValue as DestinationType)
  }

  useEffect(() => {
    if (defaultSiteId) getCartDropoffConfig(defaultSiteId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSiteId])

  useEffect(() => {
    if (config) initializeOrder(config)
  }, [config])

  const handleChange = (key: keyof IOrderForm, value: unknown): void => {
    setOrder({ ...order, [key]: value })
  }

  const handleTimeSlotSelect = (timeSlot: ISelectedTimeSlot) => {
    if (moment(timeSlot.beginTime).isValid() && moment(timeSlot.endTime).isValid()) {
      setOrder({
        ...order,
        deliveryDateTimeMinimum: moment(timeSlot.beginTime).toISOString(),
        deliveryDateTimeMaximum: moment(timeSlot.endTime).toISOString(),
      })
    }
  }

  useEffect(() => {
    if (selectedTimeSlot) {
      handleTimeSlotSelect(selectedTimeSlot)
    } else {
      setOrder({
        ...order,
        deliveryDateTimeMaximum: undefined,
        deliveryDateTimeMinimum: undefined,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTimeSlot])

  const resetDestinationCardData = () => {
    setDestinationType(existingDraft
      ? existingDraft.destinationType as DestinationType
      : config?.destinationType?.defaultValue as DestinationType,
    )
    setOrder(existingDraft ? {
      ...order,
      deliveryAddressRoadNumber: existingDraft.deliveryAddressNumber,
      deliveryAddressRoad: existingDraft.deliveryAddressRoad,
      deliveryAddressZipCode: existingDraft.deliveryAddressZipCode,
      deliveryAddressCity: existingDraft.deliveryAddressCity,
      deliveryAddressCountry: existingDraft.deliveryAddressCountry,
      enterpriseLabel: existingDraft.enterpriseLabel,
      deliveryInstructions: existingDraft.deliveryInstructions,
      deliverySiteId: existingDraft.deliverySiteId,
      deliveryFloor: existingDraft.deliveryFloor,
      deliveryIsElevatorPresent: existingDraft.deliveryIsElevatorPresent,
      deliveryDoorCode: existingDraft.deliveryDoorCode,
    } : {
      ...order,
      deliveryAddressFull: undefined,
      deliveryAddressRoadNumber: undefined,
      deliveryAddressRoad: undefined,
      deliveryAddressZipCode: undefined,
      deliveryAddressCity: undefined,
      deliveryAddressCountry: config?.country?.defaultValue as string,
      enterpriseLabel: config?.enterprise?.defaultValue as string,
      deliveryInstructions: config?.deliveryInstructions?.defaultValue as string,
      deliverySiteId: undefined,
      deliveryFloor: config?.floor?.defaultValue as number,
      deliveryIsElevatorPresent: config?.hasElevator?.defaultValue as boolean,
      deliveryDoorCode: config?.digicode?.defaultValue as string,
    })
  }

  const resetOriginCardData = () => {
    setOrder(existingDraft ? {
      ...order,
      warehouseId: existingDraft.departureSite,
      customerEmail: existingDraft.email,
      customerFirstName: existingDraft.firstName,
      customerLastName: existingDraft.lastName,
      customerPhoneNumber: existingDraft.phoneNumber,
      areTermsOfSaleAccepted: existingDraft.areTermsOfSaleAccepted,
    } : {
      ...order,
      warehouseId: config?.originSiteId?.defaultValue as string,
      customerEmail: undefined,
      customerFirstName: undefined,
      customerLastName: undefined,
      customerPhoneNumber: undefined,
      areTermsOfSaleAccepted: false,
    })
  }

  return (
    <>
      <Grid item xs={10} className={classes.stepper}>
        <OrderStepper
          activeStep={activeStep}
          order={order}
          setActiveStep={setActiveStep}
          config={config}
          destinationType={destinationType}
        />
      </Grid>
      <OrderForm
        isCartDropoff
        order={order}
        setOrder={setOrder}
        handleChange={handleChange}
        resetDestinationCardData={resetDestinationCardData}
        isOrderNumberValid
        destinationType={destinationType}
        setDestinationType={setDestinationType}
        config={config}
        orderTimeSlots={orderTimeSlots}
        selectedTimeSlot={selectedTimeSlot}
        setSelectedTimeSlot={setSelectedTimeSlot}
        onClick={(key: OrderStepKey) => { setActiveStep(key) }}
        resetOriginCardData={resetOriginCardData}
        existingDraft={existingDraft}
        setExistingDraft={setExistingDraft}
      />
    </>
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <SitesProvider>
    <SitesConsumer>
      {(ctx): JSX.Element => (
        <CartDropoffScreen
          config={ctx.cartDropoffConfig}
          getCartDropoffConfig={ctx.getCartDropoffConfig}
          {...props}
        />
      )}
    </SitesConsumer>
  </SitesProvider>
)
