import { makeStyles, createStyles } from '@material-ui/core/styles'

export default makeStyles(() =>
  createStyles({
    filterContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      rowGap: 8,
      flexWrap: 'wrap',
    },
    lastThirtyWeeks: {
      marginLeft: 8,
      cursor: 'pointer',
    },
    legendContainer: {
      textAlign: 'center',
      margin: 'auto',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      flexFlow: 'column',
      width: '100%',
      alignContent: 'center',
    },
    legend: {
      display: 'grid',
      gridTemplateColumns: 'auto auto auto',
      margin: 'auto',
    },
    totalLabel: {
      display: 'flex',
      width: '100%',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      marginTop: '25px',
    },
    totalNumberLabel: {
      marginTop: 0,
    },
    pieSkeleton: {
      margin: 'auto',
      marginTop: 60,
      width: 400,
      height: 400,
    },
    marginTop: {
      marginTop: '5px',
    },
  }),
)
