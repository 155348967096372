export enum MessageInfoType {
  tourNumber = 'tourNumber',
  orderId = 'orderId',
  vehicleId = 'vehicleId',
  importJobId = 'importJobId',
  carrierId = 'carrierId',
  orderNumber = 'orderNumber',
  deliveryDateTime = 'deliveryDateTime',
}

export enum AlertType {
  Manual = 'manual',
  OnTourAbandoned = 'onTourAbandoned',
  OnStopAbandoned = 'onStopAbandoned',
  OnImportFailed = 'onImportFailed',
  OnTourRefused = 'onTourRefused',
  OnLowVehicleRatingSubmitted = 'onLowVehicleRatingSubmitted',
  OnTourDelayed = 'onTourDelayed',
}

export interface IAlert {
  id: string
  type: AlertType
  tenantId: string
  read: boolean
  concernedWarehouseIds: string[]
  dealerId: string
  customMessage: string
  customTopic: string
  relatedObject?: Record<string, unknown>
  createdDate: Date
}
