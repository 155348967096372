import React, {
  useState,
  ReactNode,
  useEffect,
  useCallback,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react'
import { useTranslation } from 'react-i18next'
import * as Sentry from '@sentry/react'
import { Severity } from '@sentry/react'

import Cookies from 'js-cookie'
import { FirebaseAuth } from 'services/firebase'
import Api from 'services/api'
import UserApi from 'api/user'
import { IUserData } from 'interfaces'
import { isIError } from 'api/types'
import { IFeature } from 'interfaces/IFeature'
import { AUTHORIZED_ROLES, Role, SentryEvents } from 'constants/constants'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import {
  DISPLAY_PIN_TOOLTIP_FIELDS,
  DISPLAY_PLAN_TOUR_ESTIMATED_COST,
  DISPLAY_SORT_VISITS,
  TENANTS_WITH_ALERTS,
  DISPLAY_VISITS_WITHOUT_TOUR,
  AUTOMATIC_VISIT_FROM_ORDER,
  FILTER_PLAN_TOURS_BY_DELIVERY_TYPE,
  USERS_WITH_REAL_TIME_ALERTS,
  TENANTS_WITHOUT_OPTIMIZATION_JOB,
  TENANTS_WITHOUT_VISITS_GENERATION_JOB,
  TENANTS_WITH_REORGANIZE_UX,
} from 'constants/env'
import { FeedbackContext } from './FeedbackContext'

export const shouldUseLocalLogin = process.env.NODE_ENV !== 'production' || !window.location.host.includes('klareo.com')

interface IAuthContext {
  user: IUserData
  setUser: Dispatch<SetStateAction<IUserData>>
  isAuthLoading: boolean
  logoutUser: () => Promise<void | null>
  features: IFeature[]
  getUserData(forceUpdate?: boolean): Promise<void>
  setUnsubscribe(unsubscribeFunction: () => void): void
  shouldDisplayPinToolTipFields: boolean
  shouldDisplayTourEstimatedCost: boolean
  shouldDisplaySortVisits: boolean
  shouldDisplayNotificationBell: boolean
  shouldDisplayVisitsWithoutTour: boolean
  shouldDisplayAutomaticVisitToggle: boolean
  shouldFilterPlanToursByDeliveryType: boolean
  shouldGetAlertsInRealTime: boolean
  impersonateUser(email: string): Promise<void>
  isImpersonationLoading: boolean
  shouldUseJobsForOptimization: boolean
  shouldUseJobsForVisitsGeneration: boolean
  shouldUseReorganizeUX: boolean
}

const AuthContext = React.createContext<IAuthContext>({} as IAuthContext)
const { Provider, Consumer } = AuthContext

interface IProps {
  children: ReactNode
}

function AuthProvider({ children }: IProps): JSX.Element {
  const [user, setUser] = useState<IUserData>(null)
  const [features, setFeatures] = useState<IFeature[]>([])
  const [token, setToken] = useState<string | null>(null)
  const [isAuthLoading, setAuthLoading] = useState<boolean>(true)
  const [isImpersonationLoading, setImpersonationLoading] = useState<boolean>(false)
  const { openErrorSnack } = useContext(FeedbackContext)
  const [unsubscribe, setUnsubscribe] = useState<() => void>()
  const { t } = useTranslation()

  async function getUserToken(firebaseUser: firebase.default.User): Promise<void> {
    const userToken = await firebaseUser.getIdToken()
    if (userToken) {
      setToken(userToken)
    }
  }

  /* This will update the current user if already logged */
  useEffect(() => {
    FirebaseAuth().onAuthStateChanged((firebaseUser) => {
      const tokenFromCookie = Cookies.get('Authorization')
      if (firebaseUser) {
        getUserToken(firebaseUser)
      } else if (tokenFromCookie) {
        setToken(tokenFromCookie.split(' ')[1])
      } else {
        setToken(null)
        setAuthLoading(false)
      }
    })
  }, [])

  const getUserData = useCallback(
    async (forceUpdate?: boolean) => {
      if (token && (!user || forceUpdate)) {
        const res = await UserApi.validateUserToken({ token })
        if (!isIError(res)) {
          if (res?.roles.some((role) => AUTHORIZED_ROLES.includes(role as Role))) {
            const userFeatures = await UserApi.checkFeatures()
            if (!isIError(userFeatures)) {
              setFeatures(userFeatures)
            } else {
              openErrorSnack(userFeatures.error.message)
            }
            setUser(res)
          } else {
            openErrorSnack(t('loginScreen.accessDenied'))
            setToken(null)
            Api.removeToken()
            if (shouldUseLocalLogin) {
              logoutUser()
            } else {
              window.location.replace(ROUTES_PATH.login)
            }
          }
        } else {
          setToken(null)
          setAuthLoading(false)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token, user],
  )

  /* Check if auth is ready */
  useEffect(() => {
    if (token && user) {
      setAuthLoading(false)
    } else if (token && !user) {
      getUserData()
    } else if (!token && user) {
      setUser(null)
    }
  }, [token, user, getUserData])

  async function logoutUser(): Promise<void> {
    const login = user?.login
    setToken(null)
    // resetting user here to not wait for the useEffect, in case any actions are taken between
    setUser(null)
    if (unsubscribe) {
      unsubscribe()
    }
    await FirebaseAuth().signOut()

    Sentry.captureEvent({
      message: SentryEvents.Logout,
      level: Severity.Info,
      tags: {
        login,
      },
    })
  }

  async function impersonateUser(email: string): Promise<void> {
    setImpersonationLoading(true)
    const impersonatedUserToken = await UserApi.getCustomToken(email)
    if (!isIError(impersonatedUserToken)) {
      await logoutUser()
      await UserApi.loginWithCustomToken(impersonatedUserToken)
      window.location.reload()
    } else {
      openErrorSnack(impersonatedUserToken.error.message)
    }
    setImpersonationLoading(false)
  }

  const shouldDisplayPinToolTipFields = DISPLAY_PIN_TOOLTIP_FIELDS
    && user?.tenantId
    && Array.isArray(DISPLAY_PIN_TOOLTIP_FIELDS)
    && DISPLAY_PIN_TOOLTIP_FIELDS.includes(user?.tenantId)

  const shouldDisplayTourEstimatedCost = DISPLAY_PLAN_TOUR_ESTIMATED_COST
    && user?.tenantId
    && Array.isArray(DISPLAY_PLAN_TOUR_ESTIMATED_COST)
    && DISPLAY_PLAN_TOUR_ESTIMATED_COST.includes(user?.tenantId)

  const shouldDisplaySortVisits = DISPLAY_SORT_VISITS
    && user?.tenantId
    && Array.isArray(DISPLAY_SORT_VISITS)
    && DISPLAY_SORT_VISITS.includes(user?.tenantId)

  const shouldDisplayVisitsWithoutTour = DISPLAY_VISITS_WITHOUT_TOUR
    && user?.tenantId
    && Array.isArray(DISPLAY_VISITS_WITHOUT_TOUR)
    && DISPLAY_VISITS_WITHOUT_TOUR.includes(user?.tenantId)

  const shouldDisplayNotificationBell = TENANTS_WITH_ALERTS
    && user?.tenantId
    && (user.roles.includes(Role.Pilot) || user.roles.includes(Role.Store))
    && Array.isArray(TENANTS_WITH_ALERTS)
    && TENANTS_WITH_ALERTS.includes(user.tenantId)

  const shouldDisplayAutomaticVisitToggle = AUTOMATIC_VISIT_FROM_ORDER
    && user?.tenantId
    && Array.isArray(AUTOMATIC_VISIT_FROM_ORDER)
    && AUTOMATIC_VISIT_FROM_ORDER.includes(user.tenantId)

  const shouldFilterPlanToursByDeliveryType = FILTER_PLAN_TOURS_BY_DELIVERY_TYPE
    && user?.tenantId
    && Array.isArray(FILTER_PLAN_TOURS_BY_DELIVERY_TYPE)
    && FILTER_PLAN_TOURS_BY_DELIVERY_TYPE.includes(user?.tenantId)

  const shouldGetAlertsInRealTime = USERS_WITH_REAL_TIME_ALERTS
    && user?.id
    && (user.roles.includes(Role.Pilot) || user.roles.includes(Role.Store))
    && Array.isArray(USERS_WITH_REAL_TIME_ALERTS)
    && USERS_WITH_REAL_TIME_ALERTS.includes(user?.id)

  const shouldUseJobsForOptimization = !TENANTS_WITHOUT_OPTIMIZATION_JOB
    || (!!user?.tenantId
      && Array.isArray(TENANTS_WITHOUT_OPTIMIZATION_JOB)
      && !TENANTS_WITHOUT_OPTIMIZATION_JOB.includes(user?.tenantId))

  const shouldUseJobsForVisitsGeneration = !TENANTS_WITHOUT_VISITS_GENERATION_JOB
    || (!!user?.tenantId
      && Array.isArray(TENANTS_WITHOUT_VISITS_GENERATION_JOB)
      && !TENANTS_WITHOUT_VISITS_GENERATION_JOB.includes(user?.tenantId))

  const shouldUseReorganizeUX = TENANTS_WITH_REORGANIZE_UX
    && user?.tenantId
    && Array.isArray(TENANTS_WITH_REORGANIZE_UX)
    && TENANTS_WITH_REORGANIZE_UX.includes(user?.tenantId)

  return (
    <Provider
      value={{
        user,
        setUser,
        isAuthLoading,
        logoutUser,
        features,
        getUserData,
        setUnsubscribe,
        shouldDisplayPinToolTipFields,
        shouldDisplayTourEstimatedCost,
        shouldDisplaySortVisits,
        shouldDisplayNotificationBell,
        shouldDisplayVisitsWithoutTour,
        shouldDisplayAutomaticVisitToggle,
        shouldFilterPlanToursByDeliveryType,
        shouldGetAlertsInRealTime,
        impersonateUser,
        isImpersonationLoading,
        shouldUseJobsForOptimization,
        shouldUseJobsForVisitsGeneration,
        shouldUseReorganizeUX,
      }}
    >
      {children}
    </Provider>
  )
}

export { AuthContext }
export default AuthProvider
export { Consumer as AuthConsumer }
