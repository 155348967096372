import React from 'react'

interface ITruckIcon {
  color: string
}

const TruckIcon = ({ color }: ITruckIcon): JSX.Element => (
  <g transform="scale(0.6) translate(220,110)" fill={color}>
    <path d="M0,283.525c0,4.224,2.96,7.539,7.184,7.539h11.47c7.707-17,25.254-29.864,45.628-29.864 c20.375,0,37.918,12.864,45.629,29.864H259v-61H0V283.525z" />
    <path d="M64.282,277.32c-18.673,0-33.81,15.137-33.81,33.81c0,18.673,15.137,33.81,33.81,33.81 c18.664-0.021,33.789-15.146,33.81-33.81C98.092,292.458,82.955,277.32,64.282,277.32z" />
    <path d="M407.6,205.217l-32.2-14.153H275v100h19.488c7.712-17,25.254-29.864,45.628-29.864c20.374,0,37.921,12.864,45.628,29.864 h19.072c4.224,0,7.184-3.315,7.184-7.539v-71.034C412.106,209.408,410.38,206.554,407.6,205.217z" />
    <path d="M342.295,133.408c-4.018-7.705-12.041-12.482-20.73-12.344H275v54h88.915L342.295,133.408z" />
    <path d="M236.036,67.064H22.844C9.985,67.064,0,77.65,0,90.509v123.555h259V90.509c0-0.036,0.001-0.072,0.001-0.108 C259.104,77.616,248.822,67.167,236.036,67.064z" />
    <path d="M340.114,277.32c-18.673,0.001-33.81,15.138-33.809,33.811c0.001,18.673,15.138,33.81,33.811,33.809 c18.664-0.021,33.788-15.146,33.809-33.81c0,0,0-0.001,0-0.001C373.924,292.457,358.787,277.32,340.114,277.32z" />
  </g>
)

export default TruckIcon
