import Api from 'services/api'
import { ICarrier } from 'interfaces/ICarrier'

import { IError } from 'api/types'
import { ICarrierFilters } from 'interfaces/interfaces'

const parseSingleCarrier = (carrier: ICarrier): ICarrier => ({
  id: carrier.id,
  name: carrier.name,
  tenantId: carrier.tenantId,
  code: carrier.code,
  companyId: carrier.companyId,
  active: carrier.active,
  warehouses: carrier.warehouses,
  tags: carrier.tags,
})

const parseCarriers = (carriers: Array<ICarrier>): Array<ICarrier> =>
  carriers.map((elem) => parseSingleCarrier(elem))

const getCarriers = async (
  filters?: ICarrierFilters,
  offset?: number,
  rowsPerPage?: number,
  sortBy?: string,
  sortDirection?: number,
): Promise<{ carriers: Array<ICarrier>; count: number } | IError> => {
  try {
    const filtersToApply = {
      warehouses: filters?.warehouses,
      ...((filters?.active?.length || 0) === 1 && {
        active: filters?.active && filters?.active[0],
      }),
      ...((filters?.searchText?.length || 0) >= 3 && { search: filters?.searchText }),
    }
    const res = await Api.get('carriers', {
      ...filtersToApply,
      offset,
      limit: rowsPerPage,
      sortBy: sortBy || 'name',
      sortDirection: sortDirection || 1,
    })
    return { carriers: parseCarriers(res.items), count: res.count }
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getCarrierDetails = async (id: string): Promise<{ carrier?: ICarrier; error?: IError }> => {
  try {
    const res = await Api.get(`carriers/details/${id}`)
    return { carrier: parseSingleCarrier(res) }
  } catch (error) {
    return { error: { error } }
  }
}

const createCarrier = async (
  newCarrier: ICarrier,
): Promise<{ carrier?: ICarrier; error?: IError } | IError> => {
  try {
    const body = {
      ...newCarrier,
      warehouseIds: undefined,
      warehouses: newCarrier.warehouses?.map((warehouse) => ({
        ...warehouse,
        warehouseId: warehouse.id,
      })),
      tags: newCarrier.tags?.map((tag) => tag.code),
    }
    const res = await Api.post('carriers', body)
    return { carrier: parseSingleCarrier(res) }
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

const updateCarrier = async (
  idCarrier: string,
  newCarrier: ICarrier,
): Promise<{ carrier?: ICarrier; error?: IError } | IError> => {
  try {
    const body = {
      ...newCarrier,
      warehouseIds: undefined,
      warehouses: newCarrier.warehouses?.map((warehouse) => ({
        ...warehouse,
        warehouseId: warehouse.id,
      })),
      tags: newCarrier.tags?.map((tag) => tag.code),
    }
    const res = await Api.patch(`carriers/${idCarrier}`, body)
    return { carrier: parseSingleCarrier(res) }
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

export default {
  getCarriers,
  getCarrierDetails,
  createCarrier,
  updateCarrier,
}
