import React, { useContext } from 'react'

import { COLUMNS_MODEL_COST_CONFIGURATIONS_LIST } from 'constants/table'
import CostConfigurationsProvider, {
  CostConfigurationsContext,
} from 'store/CostConfigurationsContext'
import CostConfigurationsTable from 'components/Table/CostConfigurationsTable'

const CostConfigListScreen = (): JSX.Element => {
  const { costConfigurations, count } = useContext(CostConfigurationsContext)
  return (
    <CostConfigurationsTable
      costConfigurations={costConfigurations}
      count={count}
      columns={COLUMNS_MODEL_COST_CONFIGURATIONS_LIST}
    />
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <CostConfigurationsProvider>
    <CostConfigListScreen {...props} />
  </CostConfigurationsProvider>
)
