import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { TenantFilter } from 'components/Inputs/ListFilter'
import DeliveryTypesProvider, { DeliveryTypesConsumer } from 'store/DeliveryTypesContext'
import { IDeliveryType } from 'interfaces/IDeliveryType'
import FormAction from 'components/Button/FormAction'
import { AuthContext } from 'store/AuthContext'
import DeliveryTypesCard from './DeliveryTypesCard'

interface IProps {
  deliveryTypes: IDeliveryType[]
  getDeliveryTypes: (tenantId: string) => void
  updateDeliveryTypes: (tenantId: string, deliveryTypes: IDeliveryType[]) => void
}

const TenantContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`

const DeliveryTypesScreen = ({
  deliveryTypes,
  getDeliveryTypes,
  updateDeliveryTypes,
}: IProps): JSX.Element => {
  const { t } = useTranslation()
  const { user } = useContext(AuthContext)

  const [tenantId, setTenantId] = useState<string>(user?.tenantId || '')
  const [types, setTypes] = useState<IDeliveryType[]>([])

  useEffect(() => {
    if (tenantId) {
      getDeliveryTypes(tenantId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantId])

  useEffect(() => {
    setTypes(deliveryTypes)
  }, [deliveryTypes])

  const handleCancelClick = (): void => {
    setTypes(deliveryTypes)
  }

  const handleSaveClick = (): void => {
    if (tenantId) updateDeliveryTypes(tenantId, types)
  }

  const handleDeliveryTypesChange = (changedTypes: IDeliveryType[]): void => {
    setTypes(changedTypes)
  }

  return (
    <>
      <TenantContainer>
        <TenantFilter
          handleChange={(tenantValues: string[]): void => {
            setTenantId(tenantValues[0])
          }}
          placeholder={t('tablesEntries.tenant')}
          ids={[tenantId]}
          dataCy="tenantPicker"
          onlyOneValue
          shouldFillWidth={tenantId !== ''}
          required
          isUnselectAllowed={false}
        />
      </TenantContainer>
      {tenantId && (
        <>
          <DeliveryTypesCard
            deliveryTypes={types}
            onDeliveryTypesChange={handleDeliveryTypesChange}
          />
          <FormAction onSaveClick={handleSaveClick} onCancelClick={handleCancelClick} />
        </>
      )}
    </>
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <DeliveryTypesProvider>
    <DeliveryTypesConsumer>
      {(deliveryTypesContext): JSX.Element => (
        <DeliveryTypesScreen
          deliveryTypes={deliveryTypesContext.deliveryTypes}
          getDeliveryTypes={deliveryTypesContext.getDeliveryTypes}
          updateDeliveryTypes={deliveryTypesContext.updateDeliveryTypes}
          {...props}
        />
      )}
    </DeliveryTypesConsumer>
  </DeliveryTypesProvider>
)
