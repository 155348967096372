import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CircularProgress, Button } from '@material-ui/core'

import {
  PLAN_TOUR_STATUS,
  STOP_STATUS_ENUM,
  STOP_STATUS_MAP,
  StopStatus,
  TOUR_STATUS,
  TOUR_STATUS_ENUM,
  USER_ROLES,
} from 'constants/constants'
import { IPlanTour } from 'interfaces/IPlan'
import { ITour } from 'interfaces/Itours'
import { AuthContext } from 'store/AuthContext'
import { ContentContext } from 'store/ContentContext'
import { PlansContext } from 'store/PlansContext'
import { ToursContext } from 'store/ToursContext'
import { getTourStatus, getTourStatusLabel } from 'utils/tableUtils'
import TourRefusedAlert from 'components/TourRefusedAlert/TourRefusedAlert'
import ResumeAbandonedTourModal from 'components/ResumeAbandonedTourModal/ResumeAbandonedTourModal'

import FinishStopTourDialog from 'components/Dialog/FinishStopTourDialog'
import { FeedbackContext } from 'store/FeedbackContext'
import SearchableSelect from './SearchableSelect'
import useStyles from './styles'

/*
  This function is needed because we can't use hook as a direct child
*/
const ListSelectCarrier = ({ tour }: { tour: ITour }): JSX.Element => {
  const { carrierId } = tour
  const { updateTourById } = useContext(ToursContext)
  const { carriers } = useContext(ContentContext)

  const isHiddenCarrier = (id: string): boolean => {
    if (carriers) {
      const carrierDetails = carriers.find((x) => x.id === id)
      if (carrierDetails) {
        if (!carrierDetails.active) return true
        return !carrierDetails.warehouses.find((x) => x.id === tour.warehouseId)
      }
      return true
    }
    return true
  }
  return (
    <ListSelectComponent
      value={carrierId}
      valueName="carrierId"
      dict={carriers.map((carrier) => ({ ...carrier, hidden: isHiddenCarrier(carrier.id) }))}
      tourOrStopId={tour.tourId}
      updateById={updateTourById}
      selectedCarrier={carrierId}
      dataCy="row-carrierPicker"
      disabled={tour.status === TOUR_STATUS_ENUM.FINISHED}
    />
  )
}

function WrapperListSelectCarrier(tour: ITour): JSX.Element {
  return <ListSelectCarrier tour={tour} />
}

function ListSelectDriver({ tour }: { tour: ITour }): JSX.Element {
  const { updateTourById } = useContext(ToursContext)
  const { drivers } = useContext(ContentContext)

  return (
    <ListSelectComponent
      value={tour.driverId}
      valueName="driverId"
      dict={drivers}
      tourOrStopId={tour.tourId}
      updateById={updateTourById}
      selectedCarrier={tour.carrierId}
      dataBoundToCarrier
      dataBoundToWarehouse
      warehouseId={tour.warehouseId}
      onlyActives
      dataCy="row-driverPicker"
    />
  )
}

function WrapperListSelectDriver(tour: ITour): JSX.Element {
  return <ListSelectDriver tour={tour} />
}

function ListSelectVehicle({ tour }: { tour: ITour }): JSX.Element {
  const { updateTourById } = useContext(ToursContext)
  const { vehicles } = useContext(ContentContext)

  return (
    <ListSelectComponent
      value={tour.vehicleId}
      valueName="vehicleId"
      dict={vehicles}
      tourOrStopId={tour.tourId}
      updateById={updateTourById}
      selectedCarrier={tour.carrierId}
      dataBoundToCarrier
      onlyActives
      dataCy="row-vehiclePicker"
    />
  )
}

function WrapperListSelectVehicle(tour: ITour): JSX.Element {
  return <ListSelectVehicle tour={tour} />
}

const ListSelectStatus = ({ tour }: { tour: ITour }): JSX.Element => {
  const { t } = useTranslation()
  const [isResumeAbandonnedTourModalOpen, setIsResumeAbandonnedTourModalOpen] = useState<boolean>(false)
  const { updateTourById } = useContext(ToursContext)
  const classes = useStyles()
  const { status } = tour

  const statusesNotToChange = [TOUR_STATUS_ENUM.FINISHED, TOUR_STATUS_ENUM.REFUSED]

  const openResumeAbandonnedTourModal = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setIsResumeAbandonnedTourModalOpen(true)
  }

  const closeResumeAbandonnedTourModal = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setIsResumeAbandonnedTourModalOpen(false)
  }

  if (statusesNotToChange.includes(status as number)) {
    return <>{getTourStatus(tour)}</>
  }

  if (status === TOUR_STATUS_ENUM.DROPPED) {
    return (
      <>
        <ResumeAbandonedTourModal
          open={isResumeAbandonnedTourModalOpen}
          onClose={closeResumeAbandonnedTourModal}
          tour={tour}
        />
        <Button
          onClick={openResumeAbandonnedTourModal}
          title={t('tablesEntries.abandonedTourButtonTitle')}
          variant="outlined"
          className={classes.droppedButton}
        >
          {getTourStatusLabel(tour)}
        </Button>
      </>
    )
  }

  return (
    <div className={classes.tourStatusAndIconWrapper}>
      <ListSelectComponent
        value={status?.toString() || ''}
        valueName="status"
        dict={TOUR_STATUS.map((statusToFilter) => ({
          ...statusToFilter,
          disabled:
            statusToFilter.id !== TOUR_STATUS_ENUM.FINISHED.toString()
            && statusToFilter.id !== TOUR_STATUS_ENUM.DROPPED.toString(),
        }))}
        tourOrStopId={tour.tourId}
        updateById={updateTourById}
        isNullDisabled
        dataCy="row-statusPicker"
      />
      {tour.status === TOUR_STATUS_ENUM.REFUSED && <TourRefusedAlert tour={tour} />}
    </div>
  )
}

function WrapperListSelectStatus(tour: ITour): JSX.Element {
  return <ListSelectStatus tour={tour} />
}

const ListSelectPlanTourStatus = ({ planTour }: { planTour: IPlanTour }): JSX.Element => {
  const { status } = planTour
  const { updatePlanTourStatus } = useContext(PlansContext)
  const { user } = useContext(AuthContext)

  return user?.roles.includes(USER_ROLES.superAdmin) ? (
    <ListSelectComponent
      value={PLAN_TOUR_STATUS.find((fullStatus) => fullStatus.name === status)?.id || ''}
      valueName="status"
      dict={PLAN_TOUR_STATUS}
      tourOrStopId={planTour.planToursId}
      updateById={updatePlanTourStatus}
      isPlanTourChange
      isNullDisabled
      dataCy="row-statusPicker"
    />
  ) : (
    <>{planTour?.status}</>
  )
}

function WrapperListSelectPlanTourStatus(planTour: IPlanTour): JSX.Element {
  return <ListSelectPlanTourStatus planTour={planTour} />
}

interface IListSelectStopStatusProps {
  stopId: string
  status?: string | number
  isTourDropped?: boolean
}

const ListSelectStopStatus = ({
  status,
  stopId,
  isTourDropped,
}: IListSelectStopStatusProps): JSX.Element => {
  const { updateStopStatus } = useContext(ToursContext)

  const STOP_STATUS_ARRAY = Array.from(STOP_STATUS_MAP, (item) => ({ id: item[0], name: item[1] }))

  const possibleStopStatusChanges: Map<StopStatus, StopStatus[]> = new Map(
    isTourDropped
      ? [
        [
          StopStatus.Dropped,
          [
            StopStatus.Loaded,
            StopStatus.OnSite,
            StopStatus.Delivered,
            StopStatus.DeliverAgain,
            StopStatus.Canceled,
          ],
        ],
      ]
      : [
        [StopStatus.Planned, [StopStatus.Delivered, StopStatus.Dropped, StopStatus.DeliverAgain]],
        [StopStatus.Dropped, [StopStatus.Delivered, StopStatus.DeliverAgain]],
        [StopStatus.DeliverAgain, [StopStatus.Delivered, StopStatus.Dropped]],
      ],
  )

  const statusChangeOptions = STOP_STATUS_ARRAY.filter(
    (elem) =>
      possibleStopStatusChanges.get(Number(status))?.includes(elem.id) || elem.id === status,
  ).map((statusToFilter) => ({
    ...statusToFilter,
    id: statusToFilter.id.toString(),
    hidden: statusToFilter.id === status,
  }))

  return (
    <ListSelectComponent
      value={status?.toString() || ''}
      valueName="status"
      dict={statusChangeOptions}
      tourOrStopId={stopId}
      updateById={updateStopStatus}
      isStopChange
      isNullDisabled
      dataCy="row-statusPicker"
    />
  )
}

function WrapperListSelectStopStatus({
  status,
  stopId,
  isTourDropped,
}: IListSelectStopStatusProps): JSX.Element {
  return <ListSelectStopStatus status={status} stopId={stopId} isTourDropped={isTourDropped} />
}

interface IDictEntry {
  id: string
  name: string
  carrierId?: string
  charge?: number
  active?: boolean
  disabled?: boolean
  siteId?: string
  hidden?: boolean
}

interface ITourToUpdate {
  tourId: string
  [key: string]: string
}

interface IPlanTourToUpdate {
  planTourId: string
  status: string | null
}

interface IStopStatusToUpdate {
  stopId: string
  status: number
}

type IUpdateObject = ITourToUpdate & IPlanTourToUpdate & IStopStatusToUpdate

interface IListSelectProps {
  value: string
  dict: IDictEntry[]
  tourOrStopId: string
  updateById: (item: IUpdateObject) => Promise<void>
  valueName: string
  selectedCarrier?: string
  dataBoundToCarrier?: boolean
  onlyActives?: boolean
  dataCy?: string
  isPlanTourChange?: boolean
  isStopChange?: boolean
  isNullDisabled?: boolean
  warehouseId?: string
  dataBoundToWarehouse?: boolean
  disabled?: boolean
  stopTourId?: string
}

function ListSelectComponent({
  value,
  dict,
  tourOrStopId,
  updateById,
  valueName,
  selectedCarrier,
  dataBoundToCarrier,
  onlyActives,
  dataCy,
  isPlanTourChange,
  isStopChange,
  isNullDisabled,
  warehouseId,
  dataBoundToWarehouse,
  disabled,
}: IListSelectProps): JSX.Element | null {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isFinishTourDialogOpen, setIsFinishTourDialogOpen] = useState<boolean>(false)
  const { toggleLoader } = useContext(FeedbackContext)
  const { tours } = useContext(ToursContext)

  let entries = onlyActives ? [...dict.filter((item) => item.active)] : [...dict]

  const checkIfShouldOpenFinishTourDialog = (oldValue: string, newValue: string) => {
    if (isPlanTourChange) return false
    // eslint-disable-next-line max-len
    if (
      !isStopChange
      && parseInt(newValue, 10) === TOUR_STATUS_ENUM.FINISHED
      && [TOUR_STATUS_ENUM.PLANIFIED, TOUR_STATUS_ENUM.GOING].includes(parseInt(oldValue, 10))
    ) return true

    if (
      isStopChange
      && parseInt(newValue, 10) === STOP_STATUS_ENUM.Delivered
      && [STOP_STATUS_ENUM.Planned, STOP_STATUS_ENUM.Postponed].includes(parseInt(oldValue, 10))
    ) return true
    return false
  }

  async function handleChange(event: React.ChangeEvent<{ value: unknown }>): Promise<void> {
    setIsLoading(true)
    const newValue = event.target.value === -1 ? null : event.target.value
    const updateObject = {
      [isPlanTourChange ? 'planTourId' : isStopChange ? 'stopId' : 'tourId']: tourOrStopId,
      [valueName]: newValue,
    }
    if (checkIfShouldOpenFinishTourDialog(value, (newValue as string) || '')) {
      setIsFinishTourDialogOpen(true)
      setIsLoading(false)
      return
    }
    if (valueName === 'carrierId') {
      updateObject.driverId = null
    }
    await updateById(updateObject as IUpdateObject)
    setIsLoading(false)
  }

  const handleFinishTourDialogSubmit = async (id: string, tourEndDate: string): Promise<void> => {
    if (!id) return
    toggleLoader(true)
    const tourId = !isStopChange
      ? id
      : tours.find((tour) => tour.stops.findIndex((stop) => stop.stopId === id) > -1)?.tourId
    if (!tourId) return
    const updateObject = {
      [isStopChange ? 'stopId' : 'tourId']: tourOrStopId,
      status: isStopChange ? STOP_STATUS_ENUM.Delivered : TOUR_STATUS_ENUM.FINISHED.toString(),
      [isStopChange ? 'date' : 'tourEndDate']: isStopChange
        ? tourEndDate
          ? new Date(tourEndDate)
          : undefined
        : tourEndDate,
    }
    await updateById(updateObject as IUpdateObject)
    setIsFinishTourDialogOpen(false)
  }

  if (!dict || dict.length < 1) {
    return null
  }

  if (dataBoundToCarrier) {
    if (selectedCarrier) {
      entries = entries.filter((elem: IDictEntry) => elem.carrierId === selectedCarrier)
    } else {
      entries = []
    }
  }

  if (dataBoundToWarehouse) {
    entries = entries.filter((elem: IDictEntry) => !elem.siteId || elem.siteId === warehouseId)
  }

  function switchOpen(): void {
    setIsOpen((prev) => !prev)
  }
  return isLoading ? (
    <CircularProgress size={27} color="inherit" />
  ) : (
    <>
      <SearchableSelect
        label="-"
        selectedValue={value || -1}
        name={valueName}
        options={entries?.map((entry) => ({
          id: entry.id,
          name: `${entry.name} ${entry.charge && isOpen ? entry.charge : ''}`,
          disabled: entry.disabled,
          hidden: entry.hidden,
        }))}
        onChange={handleChange}
        isStandard
        isNullDisabled={isNullDisabled}
        onClose={switchOpen}
        onOpen={switchOpen}
        isInTable
        dataCy={dataCy}
        disabled={disabled}
      />
      {isFinishTourDialogOpen && (
        <FinishStopTourDialog
          close={() => setIsFinishTourDialogOpen(false)}
          open={isFinishTourDialogOpen}
          onClose={(val) => handleFinishTourDialogSubmit(tourOrStopId, val)}
          isTour={!isStopChange && !isPlanTourChange}
        />
      )}
    </>
  )
}

export {
  WrapperListSelectCarrier as ListSelectCarrier,
  WrapperListSelectDriver as ListSelectDriver,
  WrapperListSelectVehicle as ListSelectVehicle,
  WrapperListSelectStatus as ListSelectStatus,
  WrapperListSelectPlanTourStatus as ListSelectPlanTourStatus,
  WrapperListSelectStopStatus as ListSelectStopStatus,
}
