import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Grid, Tooltip } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useParams, useHistory } from 'react-router-dom'

import { IUserInfos } from 'interfaces/IUserInfos'
import { ICompany } from 'interfaces/interfaces'
import CustomCard from 'components/CustomCard/CustomCard'
import { ContentConsumer } from 'store/ContentContext'
import UsersProvider, { UsersConsumer } from 'store/UsersContext'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import { IIdParam } from 'interfaces/IIdParam'
import Button from 'components/Button/CustomButton'
import useStyles from 'constants/cruStyles'
import { checkRoleWithSite } from 'utils/functions'
import { getTranslatedRolesList, getUserSites, getUserDefaultSite } from 'utils/tableUtils'
import { AuthContext } from 'store/AuthContext'
import { AUTHORIZED_ROLES, Role, USER_ROLES } from 'constants/constants'

interface IProps {
  getDetails?: Function
  userDetails: IUserInfos
  companies: ICompany[]
}

const UsersDetailsScreen = ({ getDetails, userDetails, companies }: IProps): JSX.Element => {
  const { user: loggedUser, impersonateUser } = useContext(AuthContext)
  const { id } = useParams<IIdParam>()
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const [shouldShowSiteCard, setShouldShowSiteCard] = useState<boolean>(false)
  const [canEdit, setCanEdit] = useState<boolean>(true)
  useEffect(() => {
    if (id && getDetails) {
      getDetails(id, loggedUser?.roles.includes(USER_ROLES.superAdmin) ?? false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (userDetails?.tenant && loggedUser?.tenant) {
      setCanEdit(userDetails.tenant === loggedUser.tenant)
    }
    if (userDetails?.roles) {
      setShouldShowSiteCard(checkRoleWithSite(userDetails.roles))
    }
  }, [userDetails, loggedUser])

  const canImpersonate = useMemo(() => {
    if (userDetails?.roles) {
      return (
        userDetails.roles.some((role) => AUTHORIZED_ROLES.includes(role as Role))
        && userDetails.active
        && userDetails.email !== loggedUser?.login
      )
    }
    return false
  }, [userDetails, loggedUser])

  const handleEditClick = (): void => {
    history.push(`${ROUTES_PATH.editUser}${id}`)
  }

  const handleLoginAsClick = (): void => {
    if (userDetails?.email) {
      impersonateUser(userDetails?.email)
    }
  }

  return (
    <>
      <div className={classes.detailHeaderContainer}>
        <h3 data-cy="userDetailDescription">
          {`${userDetails.firstName || ''} ${userDetails.lastName || ''}`}
        </h3>
        <div style={{ display: 'flex', gap: '1rem' }}>
          <Button data-cy="updateButton" disabled={!canEdit} onPress={handleEditClick}>
            {t('UsersScreen.edit')}
          </Button>
          {loggedUser?.roles.includes(USER_ROLES.superAdmin) && (
            <Tooltip
              title={`${
                canImpersonate
                  ? t('UsersScreen.loginAsUserInfo')
                  : userDetails.email === loggedUser?.login
                    ? t('UsersScreen.alreadyLoggedIn')
                    : t('UsersScreen.userWithNoPlanningAccess')
              }`}
              aria-label={t('UsersScreen.loginAsUser')}
              arrow
            >
              <span>
                <Button
                  data-cy="loginAsUser"
                  disabled={!canImpersonate}
                  onPress={handleLoginAsClick}
                >
                  {t('UsersScreen.loginAsUser')}
                </Button>
              </span>
            </Tooltip>
          )}
        </div>
      </div>
      <div className={classes.detailsContent}>
        <div className={classes.detailsInnerContent}>
          <Grid item xs={10} className={classes.infoContainer}>
            <CustomCard
              dataCy="profilCard"
              cardTitle={t('UsersScreen.profile')}
              linePairs={[
                {
                  label: t('UsersScreen.firstName'),
                  value: userDetails ? userDetails.firstName : '',
                },
                {
                  label: t('UsersScreen.lastName'),
                  value: userDetails ? userDetails.lastName : '',
                },
                { label: t('UsersScreen.email'), value: userDetails ? userDetails.email : '' },
                {
                  label: t('UsersScreen.active'),
                  value: userDetails
                    ? userDetails.active
                      ? t('UsersScreen.yes')
                      : t('UsersScreen.no')
                    : '',
                },
                {
                  label: t('UsersScreen.company'),
                  value:
                    companies.length && userDetails
                      ? companies.find((company) => company.id === userDetails.companyId)?.name
                      : '',
                },
                {
                  label: t('UsersScreen.roles'),
                  value: userDetails?.roles ? getTranslatedRolesList(userDetails.roles) : '',
                },
                {
                  label: t('UsersScreen.sites'),
                  value: userDetails?.sites ? getUserSites(userDetails) : '',
                  hidden: !shouldShowSiteCard,
                },
                {
                  label: t('UsersScreen.defaultSite'),
                  value: getUserDefaultSite(userDetails),
                  hidden: !shouldShowSiteCard,
                },
              ]}
            />
          </Grid>
        </div>
      </div>
    </>
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <UsersProvider>
    <ContentConsumer>
      {(contentCtx): JSX.Element => (
        <UsersConsumer>
          {(usersCtx): JSX.Element => (
            <UsersDetailsScreen
              getDetails={usersCtx.getDetails}
              userDetails={usersCtx.userDetails}
              companies={contentCtx.companies}
              {...props}
            />
          )}
        </UsersConsumer>
      )}
    </ContentConsumer>
  </UsersProvider>
)
