import React from 'react'

function ForbiddenScreen(): JSX.Element {
  return (
    <>
      <div>
        <p>NOT ALLOWED</p>
      </div>
    </>
  )
}

export default ForbiddenScreen
