import React, { useEffect, useState } from 'react'
import useStyles from 'constants/cruStyles'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { FormControl, InputLabel, MenuItem, Select, Tooltip } from '@material-ui/core'
import { ContentConsumer } from 'store/ContentContext'
import { ICarrier } from 'interfaces/ICarrier'
import { InfoOutlined } from '@material-ui/icons'

export interface IProps {
  editMode: boolean
  value?: string[]
  handleChange: (value: string[], name: string) => void
  carriers?: ICarrier[]
  warehouseId?: string
}

const CarriersSelect = ({
  value,
  handleChange,
  carriers,
  editMode,
  warehouseId,
}: IProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [warehouseCarriers, setWarehouseCarriers] = useState<ICarrier[]>([])
  // we use previousWarehouseId to differentiate if the warehouse ID is undefined due to loading state from content
  // context, or if we manually emptied it
  const [previousWarehouseId, setPreviousWarehouseId] = useState<string | undefined>('')
  useEffect(() => {
    if (!carriers || carriers?.length === 0 || (!previousWarehouseId && !warehouseId)) return
    if (!carriers || carriers?.length === 0 || (previousWarehouseId && !warehouseId)) return handleChange([], 'carrierIds')
    setPreviousWarehouseId(warehouseId)
    const filteredWarehouseCarriers = carriers.filter((carrier) =>
      carrier.warehouses?.find((e) => e.id === warehouseId),
    )
    setWarehouseCarriers(filteredWarehouseCarriers)
    if (value && value.length > 0) {
      const cleanValues = value.filter((carrierId) =>
        filteredWarehouseCarriers.find((e) => e.id === carrierId),
      )
      handleChange(cleanValues, 'carrierIds')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carriers, warehouseId])

  return (
    <div
      className={clsx(
        classes.routingRuleFieldsContainer,
        classes.ruleDeliverySelect,
        classes.flexRow,
      )}
    >
      <FormControl disabled={!editMode}>
        <InputLabel
          className={editMode ? classes.customLabelPosition : ''}
          id="carriers-select-label"
        >
          {t('RoutingRulesScreen.carriers')}
        </InputLabel>
        <Select
          variant={editMode ? 'outlined' : undefined}
          IconComponent={!editMode ? () => <></> : undefined}
          labelId={editMode ? 'carriers-select-label' : undefined}
          multiple
          className={classes.routingRuleSelect}
          value={value && value.length > 0 ? value : []}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            if ((event.target.value as string[]).findIndex((e) => e === undefined) > -1) {
              handleChange([], 'carrierIds')
            } else {
              handleChange(event.target.value as string[], 'carrierIds')
            }
          }}
          label={t('RoutingRulesScreen.carriers')}
          name="carriersCondition"
        >
          <MenuItem>
            <em>{t('RoutingRulesScreen.carriers')}</em>
          </MenuItem>
          {warehouseCarriers
            && warehouseCarriers.length > 0
            && warehouseCarriers.map((option) => {
              if (option && option?.id && option?.code && option?.name) {
                return (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                )
              }
              return <></>
            })}
        </Select>
      </FormControl>
      <div className={classes.flex}>
        <Tooltip title={t('RoutingRulesScreen.carriersInfoMessage') || ''}>
          <InfoOutlined />
        </Tooltip>
      </div>
    </div>
  )
}

export default (props: JSX.IntrinsicAttributes & IProps): JSX.Element => (
  <ContentConsumer>
    {(ctx): JSX.Element => (
      <CarriersSelect
        warehouseId={props.warehouseId}
        carriers={ctx.carriers}
        value={props.value}
        editMode={props.editMode}
        handleChange={props.handleChange}
      />
    )}
  </ContentConsumer>
)
