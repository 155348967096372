import {
  MENU_SUBCATEGORIES,
  MENU_CATEGORIES,
  USER_ROLES,
  PILOT_AND_STORE_USER_ROLES,
} from 'constants/constants'
import OrderReportingScreen from 'screens/ReportingCategory/OrderReporting/OrderReportingScreen'
import ExecutionReportingScreen from 'screens/ReportingCategory/ExecutionReporting/ExecutionReportingScreen'
import PlanningReportingScreen from 'screens/ReportingCategory/PlanningReporting/PlanningReportingScreen'
import SiteReportingScreen from 'screens/ReportingCategory/SiteReporting/SiteReportingScreen'
import SlotsReportingScreen from 'screens/ReportingCategory/SlotsReporting/SlotsReportingScreen'
import CommentsScreen from 'screens/ReportingCategory/CustomerCommentsReporting/CommentsScreen'
import CustomerRatingReportingScreen from 'screens/ReportingCategory/CustomerRatingReporting/CustomerRatingReportingScreen'
import { ROUTES_PATH } from './RoutesPath'

const REPORTING_CATEGORY = [
  {
    title: MENU_SUBCATEGORIES.reportingOrders,
    path: ROUTES_PATH.reportingOrders,
    component: OrderReportingScreen,
    isPrivate: true,
    isInMenu: true,
    category: MENU_CATEGORIES.reporting,
    roles: [USER_ROLES.superAdmin, ...PILOT_AND_STORE_USER_ROLES],
  },
  {
    title: MENU_SUBCATEGORIES.reportingExecution,
    path: ROUTES_PATH.reportingExecution,
    component: ExecutionReportingScreen,
    isPrivate: true,
    isInMenu: true,
    category: MENU_CATEGORIES.reporting,
    roles: [USER_ROLES.superAdmin, ...PILOT_AND_STORE_USER_ROLES],
  },
  {
    title: MENU_SUBCATEGORIES.reportingPlanning,
    path: ROUTES_PATH.reportingPlanning,
    component: PlanningReportingScreen,
    isPrivate: true,
    isInMenu: true,
    category: MENU_CATEGORIES.reporting,
    roles: [USER_ROLES.superAdmin, ...PILOT_AND_STORE_USER_ROLES],
  },
  {
    title: MENU_SUBCATEGORIES.reportingSites,
    path: ROUTES_PATH.reportingSites,
    component: SiteReportingScreen,
    isPrivate: true,
    isInMenu: true,
    category: MENU_CATEGORIES.reporting,
    roles: [USER_ROLES.superAdmin, ...PILOT_AND_STORE_USER_ROLES],
  },
  {
    title: MENU_SUBCATEGORIES.reportingSlots,
    path: ROUTES_PATH.reportingSlots,
    component: SlotsReportingScreen,
    isPrivate: true,
    isInMenu: true,
    category: MENU_CATEGORIES.reporting,
    roles: [USER_ROLES.superAdmin, ...PILOT_AND_STORE_USER_ROLES],
  },
  {
    title: MENU_SUBCATEGORIES.reportingCustomerRating,
    path: ROUTES_PATH.reportingCustomerRating,
    component: CustomerRatingReportingScreen,
    isPrivate: true,
    isInMenu: true,
    category: MENU_CATEGORIES.reporting,
    roles: PILOT_AND_STORE_USER_ROLES,
  },
  {
    title: MENU_SUBCATEGORIES.reportingCustomerComments,
    path: ROUTES_PATH.reportingCustomerComments,
    component: CommentsScreen,
    isPrivate: true,
    isInMenu: true,
    category: MENU_CATEGORIES.reporting,
    roles: PILOT_AND_STORE_USER_ROLES,
  },
]

export default REPORTING_CATEGORY
