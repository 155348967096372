import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import useStyles from 'constants/cruStyles'
import SelectCard from 'components/CustomCard/SelectCard'
import { WEBHOOK_EVENTS } from 'constants/constants'
import { IOption } from 'interfaces/IOption'
import Card from 'components/Card/Card'

interface IProps {
  eventIds?: string[]
  onChange: (eventIds: string[]) => void
  isCreateMode: boolean
}

const EventCard = ({ eventIds, onChange, isCreateMode }: IProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [eventsList, setEventsList] = useState<IOption[]>([])
  const [selectedEventIds, setSelectedEventIds] = useState<string[]>([])

  useEffect(() => {
    setEventsList(WEBHOOK_EVENTS)
    if (isCreateMode) {
      const initialSelectedEventIds = WEBHOOK_EVENTS.map((event) => event.id)
      setSelectedEventIds(initialSelectedEventIds)
      onChange(initialSelectedEventIds)
    } else {
      setSelectedEventIds(eventIds || [])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleEventClick = (eventId: string): void => {
    let newSelectEventIds = [...selectedEventIds]
    const index = selectedEventIds.findIndex((selectedEventId) => selectedEventId === eventId)
    newSelectEventIds = index !== -1
      ? selectedEventIds.filter((id) => id !== eventId)
      : [...selectedEventIds, eventId]
    setSelectedEventIds(newSelectEventIds)
    onChange(newSelectEventIds)
  }

  const handleSelectAllEventsClick = (): void => {
    const newSelectEventIds = selectedEventIds.length === eventsList.length ? [] : eventsList.map((event) => event.id)
    setSelectedEventIds(newSelectEventIds)
    onChange(newSelectEventIds)
  }

  return (
    <Card
      title={t('WebhooksScreen.events.title').toUpperCase()}
      contentClassName={classes.contentRef}
    >
      <SelectCard
        options={eventsList}
        selectedOptions={selectedEventIds}
        isAllSelected={selectedEventIds.length === eventsList.length}
        onSelectAllClick={handleSelectAllEventsClick}
        onItemClick={(id: string) => handleEventClick(id)}
      />
    </Card>
  )
}

export default EventCard
