import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import VehicleTypesProvider, { VehicleTypesConsumer } from 'store/VehicleTypesContext'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import { IVehicleType } from 'interfaces/IVehicleType'
import { IIdParam } from 'interfaces/IIdParam'
import FormAction from 'components/Button/FormAction'
import { ContentConsumer } from 'store/ContentContext'
import VehicleTypeInfoCard from './VechicleTypeInfoCard'

interface IProps {
  createVehicleType?: Function
  createSuccess?: boolean
  updateSuccess?: boolean
  getDetails?: Function
  updateVehicleType?: Function
  vehicleTypeDetails?: IVehicleType
  refreshVehicleTypes?: () => void
}

const VehicleTypeFormScreen = ({
  createVehicleType,
  updateSuccess,
  updateVehicleType,
  createSuccess,
  getDetails,
  vehicleTypeDetails,
  refreshVehicleTypes,
}: IProps): JSX.Element => {
  const [vehicleType, setVehicleType] = useState<IVehicleType>({ active: true })
  const history = useHistory()
  const { id } = useParams<IIdParam>()

  const handleVehicleTypeChange = (vehicleTypeInfo: IVehicleType): void => {
    setVehicleType((prevVehicle) => ({
      ...prevVehicle,
      label: vehicleTypeInfo.label,
      code: vehicleTypeInfo.code,
      capacity: vehicleTypeInfo.capacity ? +vehicleTypeInfo.capacity : undefined,
      active: vehicleTypeInfo.active,
      vehicleProfileId: vehicleTypeInfo.vehicleProfileId,
      maxClientsPerTour: Number(vehicleTypeInfo.maxClientsPerTour),
    }))
  }

  const handleSaveClick = async (): Promise<void> => {
    if (id) {
      if (updateVehicleType) {
        await updateVehicleType(id, vehicleType)
      }
    } else {
      if (createVehicleType) {
        await createVehicleType(vehicleType)
      }
    }
  }

  useEffect(() => {
    if ((createSuccess || updateSuccess) && refreshVehicleTypes) {
      history.push(ROUTES_PATH.vehicleTypesList)
      refreshVehicleTypes()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createSuccess, updateSuccess])

  useEffect(() => {
    if (id && getDetails) {
      getDetails(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    setVehicleType({
      id: vehicleTypeDetails?.id,
      label: vehicleTypeDetails?.label,
      code: vehicleTypeDetails?.code,
      capacity: vehicleTypeDetails?.capacity,
      active: vehicleTypeDetails?.active,
      vehicleProfileId: vehicleTypeDetails?.vehicleProfile?.id,
      maxClientsPerTour: vehicleTypeDetails?.maxClientsPerTour,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleTypeDetails])

  const handleCancelClick = (): void => {
    history.push(ROUTES_PATH.vehicleTypesList)
  }

  return (
    <>
      {id && <h3 data-cy="vehicleTypeDetailDescription">{`${vehicleType.label || ''}`}</h3>}
      <VehicleTypeInfoCard
        key={vehicleType.id}
        vehicleType={vehicleType}
        onVehicleTypeChange={handleVehicleTypeChange}
      />
      <FormAction onCancelClick={handleCancelClick} onSaveClick={handleSaveClick} />
    </>
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <VehicleTypesProvider>
    <ContentConsumer>
      {(contentCtx): JSX.Element => (
        <VehicleTypesConsumer>
          {(vehicleTypeCtx): JSX.Element => (
            <VehicleTypeFormScreen
              updateSuccess={vehicleTypeCtx.updateSuccess}
              updateVehicleType={vehicleTypeCtx.updateVehicleType}
              getDetails={vehicleTypeCtx.getDetails}
              vehicleTypeDetails={vehicleTypeCtx.vehicleTypeDetails}
              createVehicleType={vehicleTypeCtx.createVehicleType}
              createSuccess={vehicleTypeCtx.createSuccess}
              refreshVehicleTypes={contentCtx.refreshVehicleTypes}
              {...props}
            />
          )}
        </VehicleTypesConsumer>
      )}
    </ContentConsumer>
  </VehicleTypesProvider>
)
