import React from 'react'
import i18n from 'i18next'

import {
  ListSelectCarrier,
  ListSelectDriver,
  ListSelectPlanTourStatus,
  ListSelectStatus,
  ListSelectVehicle,
} from 'components/Inputs/ListSelect'
import { ITableColumn } from 'interfaces'
import {
  formatAmplitude,
  formatPostalCodes,
  formatStartDate,
  formatSpecifics,
  getCarrierWarehouses,
  GetCarrierNameFromId,
  GetDriverNameFromId,
  GetDeliveryTypeLabelWrapped,
  formatCustomerName,
  getCarrierStatus,
  getUserRoles,
  getUserStatus,
  getUserCompanyName,
  formatLocationRelevance,
  getDriverStatus,
  getVehicleStatus,
  DisplayDriverField,
  DisplayVehicleField,
  getSiteStatus,
  getFullAddress,
  getSiteType,
  getSiteTourType,
  getSiteLocationRelevance,
  getVehicleTypeStatus,
  getVehicleProfileLabel,
  getVehicleTypeLabel,
  getDriverPhoneNumber,
  getPlanTemplateWarehouseFromId,
  getSiteNameFromId,
  formatBeginTime,
  formatEndTime,
  getThirdPartyActivation,
  getEventsLabel,
  getSitesLabelById,
  getSiteInOrderLabel,
  getPlanStatusLabel,
  getDriverName,
  getToOptimizeLabel,
  getUserSites,
  getUserDefaultSite,
  getSiteInPlanTourLabel,
  getJobStatusLabel,
  getJobDataTypeLabel,
  formatDate,
  getSiteSector,
  getRoutingRuleSiteNameFromId,
  getTenantNameFromId,
  getWarehousesLabelById,
  getSiteLabelById,
  getSectorsLabelById,
  getGeocodingSourceLabel,
  getNotificationTypeLabel,
  getDealersLabelById,
  getCarrierNamesFromIds,
  getTransportType,
  formatMainQuantityToLoad,
  getSiteLabelInTour,
  getConcernedWarehousesLabelById,
  getDealerLabelById,
  formatAlertCellStyle,
  DisplayVehicleImages,
  formatReportDate,
  formatVehicleReportRating,
  getDisputeReasonLabelFromCode,
  renderMediaLinks,
  getDisputeStatus,
  formatCreationDate,
  renderOrderLink,
  getTourStatus,
  formatAllowedIPs,
  formatApikeyUserName,
  getFirstDeliveredOrderDate,
  getFirstPlanifiedOrderDate,
  getLastPlanifiedOrderDate,
  getNumberOfMissedClientPromises,
  getLastStopDelay,
  addAlertToGeocoding,
  getLastDeliveredOrderDate,
  getEstimatedWeightLabel,
  addAlertToTourNumber,
} from 'utils/tableUtils'
import { IAlert } from 'interfaces/IAlert'
import DraftsIcon from '@material-ui/icons/Drafts'
import MarkunreadIcon from '@material-ui/icons/Markunread'

const cellStyle = {
  whiteSpace: 'nowrap',
}

/**
 * All usables columns, ordered alphabetically (please keep it that way)
 * How to use:
 * In your collumns array, either add directly the const like:
 * const MY_TABLE_NAME = [
 *  abandonedStopCount,
 *  ...restOfYourCols
 * ]
 *
 * Or, you can spread it like to override any entry:
 * const MY_TABLE_NAME = [
 *  { ...abandonedStopCount, render: yourRenderFunction },
 *  ...restOfYourCols
 * ]
 */

const abandonedStopCount = { title: i18n.t('tablesEntries.failures'), field: 'abandonedStopCount' }
const amplitude = {
  title: i18n.t('tablesEntries.amplitude'),
  field: 'amplitude',
  render: formatAmplitude,
}
const carrierId = {
  title: i18n.t('tablesEntries.carrier'),
  field: 'carrierId',
  disableClick: true,
  render: ListSelectCarrier,
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const deckCode = { title: i18n.t('tablesEntries.deck'), field: 'deckCode' }
const delayed = { title: i18n.t('tablesEntries.delayed'), field: 'delayed' }
// eslint-disable-next-line max-len
const deliveryType = {
  title: i18n.t('tablesEntries.deliveryType.title'),
  field: 'deliveryType',
  render: GetDeliveryTypeLabelWrapped,
}
const transportType = {
  title: i18n.t('tablesEntries.transportType.title'),
  field: 'transportType',
  render: getTransportType,
}
const departureDate = {
  title: i18n.t('tablesEntries.departure'),
  field: 'departureDate',
  render: formatStartDate,
  cellStyle,
}

const expectedDepartureDate = {
  title: i18n.t('tablesEntries.expectedDeparture'),
  field: 'departureDateTime',
  render: formatStartDate,
  cellStyle,
}

const firstDeliveredOrderDate = {
  title: i18n.t('tablesEntries.firstOrderDelivered'),
  field: 'stops',
  render: getFirstDeliveredOrderDate,
  cellStyle,
}

const lastDeliveredOrderDate = {
  title: i18n.t('tablesEntries.lastOrderDelivered'),
  field: 'stops',
  render: getLastDeliveredOrderDate,
  cellStyle,
}

const firstPlanifiedOrderDate = {
  title: i18n.t('tablesEntries.firstPlanifiedOrder'),
  field: 'stops',
  render: getFirstPlanifiedOrderDate,
  cellStyle,
}

const lastPlanifiedOrderDate = {
  title: i18n.t('tablesEntries.lastPlanifiedOrder'),
  field: 'stops',
  render: getLastPlanifiedOrderDate,
  cellStyle,
}

const lastStopDelay = {
  title: i18n.t('tablesEntries.delayed'),
  field: 'stops',
  render: getLastStopDelay,
  cellStyle,
}

const numberOfNonRespectedClientPromises = {
  title: i18n.t('tablesEntries.missedClientPromises'),
  field: 'stops',
  render: getNumberOfMissedClientPromises,
  cellStyle,
}

const driverId = {
  title: i18n.t('tablesEntries.driver'),
  field: 'driverId',
  disableClick: true,
  render: ListSelectDriver,
}

const postalCodes = {
  title: i18n.t('tablesEntries.postalCode'),
  field: 'postalCodes',
  render: formatPostalCodes,
  cellStyle,
}
const postponedStopCount = {
  title: i18n.t('tablesEntries.stopsNumberRedo'),
  field: 'postponedStopCount',
}
const quantity = { title: i18n.t('tablesEntries.quantity'), field: 'quantity' }
const quantityToDeliver = {
  title: i18n.t('tablesEntries.estimatedQuantityToDeliver'),
  field: 'mainQuantityToDeliver',
  sorting: false,
}
const quantityToLoad = {
  title: i18n.t('tablesEntries.estimatedQuantityToPickup'),
  field: 'mainQuantityToLoad',
  sorting: false,
  render: formatMainQuantityToLoad,
}
const mainQuantity = {
  title: i18n.t('tablesEntries.estimatedQuantity'),
  field: 'mainQuantity',
  sorting: false,
}

const specifics = {
  title: i18n.t('tablesEntries.specifics'),
  field: 'specifics',
  render: formatSpecifics,
  cellStyle,
}
const status = { title: i18n.t('tablesEntries.status.title'), field: 'status' }
const executionStatus = {
  ...status,
  render: getTourStatus,
  sorting: false,
}
const stopsNumber = { title: i18n.t('tablesEntries.stopsNumber'), field: 'stopsNumber' }
// const totalFloor = { title: i18n.t('tablesEntries.floor'), field: 'totalFloor' }
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const totalKilometers = {
  title: i18n.t('tablesEntries.totalKilometers'),
  field: 'totalKilometers',
}
const tourNumber = { title: i18n.t(`tablesEntries.tours`), field: 'tourNumber' }
const vehicleId = {
  title: i18n.t('tablesEntries.vehicle'),
  field: 'vehicleId',
  render: ListSelectVehicle,
  disableClick: true,
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const warehouseLabel = {
  title: i18n.t('tablesEntries.warehouses'),
  field: 'warehouseLabel',
  cellStyle,
}
const orderNumber = { title: i18n.t(`tablesEntries.orderNumber`), field: 'orderNumber' }
const warehouseLabelForOrders = {
  title: i18n.t('tablesEntries.warehouses'),
  field: 'warehouse.name',
}
const comment = {
  title: i18n.t('tablesEntries.comment'),
  field: 'endCustomerComment',
}
const ratingValue = {
  title: i18n.t('tablesEntries.ratingValue.title'),
  field: 'endCustomerRating',
}

const commentCreationDate = {
  title: i18n.t('tablesEntries.commentDateCreated'),
  field: 'commentCreationDate',
}

const customer = {
  title: i18n.t('tablesEntries.customer'),
  field: 'customer.name',
  render: formatCustomerName,
}
const commentCustomer = {
  title: i18n.t('tablesEntries.customer'),
  field: 'customerName',
}
const deliveryAddress = {
  title: i18n.t('tablesEntries.deliveryAddress'),
  field: 'deliveryAddress',
}
const name = { title: i18n.t('tablesEntries.name'), field: 'name' }
const tenantName = { title: i18n.t('tablesEntries.tenant'), field: 'tenantName' }
const tenant = { title: i18n.t('tablesEntries.tenant'), field: 'tenant' }
const code = { title: i18n.t('tablesEntries.code'), field: 'code' }
const priority = { title: i18n.t('tablesEntries.priority'), field: 'priority' }
const label = { title: i18n.t('tablesEntries.label'), field: 'label' }
const description = { title: i18n.t('tablesEntries.description'), field: 'description' }
const site = {
  title: i18n.t('tablesEntries.site'),
  field: 'warehouses',
  render: getCarrierWarehouses,
  sorting: false,
}
const sites = {
  title: i18n.t('tablesEntries.site'),
  field: 'siteIds',
  render: getSitesLabelById,
  sorting: false,
}
const warehouses = {
  title: i18n.t('tablesEntries.site'),
  field: 'warehouseIds',
  render: getWarehousesLabelById,
  sorting: false,
}
const sectors = {
  title: i18n.t('tablesEntries.sector'),
  field: 'sectorIds',
  render: getSectorsLabelById,
  sorting: false,
}
const active = { title: i18n.t('tablesEntries.active'), field: 'active', render: getCarrierStatus }
const firstName = { title: i18n.t('tablesEntries.firstName'), field: 'firstName' }
const lastName = { title: i18n.t('tablesEntries.lastName'), field: 'lastName' }
const email = { title: i18n.t('tablesEntries.email'), field: 'email' }
const companies = {
  title: i18n.t('tablesEntries.companies'),
  field: 'companies',
  render: getUserCompanyName,
}
const roles = { title: i18n.t('tablesEntries.roles'), field: 'roles', render: getUserRoles }
const activeUser = {
  title: i18n.t('tablesEntries.activeUser'),
  field: 'activeUser',
  render: getUserStatus,
}

const locationRelevance = {
  title: i18n.t('tablesEntries.locationRelevance'),
  field: 'locationRelevance',
  render: formatLocationRelevance,
}

const enterpriseLabel = {
  title: i18n.t('tablesEntries.enterpriseLabel'),
  field: 'enterpriseLabel',
}

const siteLocationRelevance = {
  title: i18n.t('tablesEntries.locationRelevance'),
  field: 'locationRelevance',
  render: getSiteLocationRelevance,
}
const login = { title: i18n.t('tablesEntries.login'), field: 'login' }
const carrierLabel = { title: i18n.t('tablesEntries.carrierLabel'), field: 'carrierLabel' }
const activeDriver = {
  title: i18n.t('tablesEntries.active'),
  field: 'active',
  render: getDriverStatus,
}
const licencePlate = { title: i18n.t('tablesEntries.licencePlate'), field: 'licencePlate' }
const carrier = {
  title: i18n.t('tablesEntries.carrier'),
  field: 'carrierId',
  render: GetCarrierNameFromId,
}
const activeVehicle = {
  title: i18n.t('tablesEntries.active'),
  field: 'active',
  render: getVehicleStatus,
}
const vehicleType = {
  title: i18n.t('tablesEntries.vehicleTypes'),
  field: 'vehicleType',
  render: getVehicleTypeLabel,
}
const activeSite = {
  title: i18n.t('tablesEntries.active'),
  field: 'active',
  render: getSiteStatus,
}
const siteWithSector = {
  title: i18n.t('tablesEntries.sector'),
  field: 'sectors',
  render: getSiteSector,
}
const siteAddress = {
  title: i18n.t('tablesEntries.address'),
  field: 'address',
  render: getFullAddress,
}
const siteType = { title: i18n.t('tablesEntries.type'), field: 'type', render: getSiteType }
const siteTourType = {
  title: i18n.t('tablesEntries.tourType'),
  field: 'tourType',
  render: getSiteTourType,
}
const activeVehicleType = {
  title: i18n.t('tablesEntries.active'),
  field: 'active',
  render: getVehicleTypeStatus,
}
const capacity = { title: i18n.t('tablesEntries.capacity'), field: 'capacity' }
const vehicleProfile = {
  title: i18n.t('tablesEntries.vehicleProfile'),
  field: 'vehicleProfile',
  render: getVehicleProfileLabel,
}
const driverPhoneNumber = {
  title: i18n.t('tablesEntries.phoneNumber'),
  field: 'phoneNumber',
  render: getDriverPhoneNumber,
}
const phoneNumber = { title: i18n.t('tablesEntries.phoneNumber'), field: 'phoneNumber' }
const planTemplateWarehouse = {
  title: i18n.t('tablesEntries.mainSite'),
  field: 'warehouseId',
  render: getPlanTemplateWarehouseFromId,
}
const begin = { title: i18n.t('tablesEntries.startTime'), field: 'begin', render: formatBeginTime }
const end = { title: i18n.t('tablesEntries.endTime'), field: 'end', render: formatEndTime }
const dateOffset = { title: i18n.t('tablesEntries.dateOffset'), field: 'dateOffset' }
const warehouse = {
  title: i18n.t('tablesEntries.siteAttachment'),
  field: 'siteId',
  render: getSiteNameFromId,
}
const defaultUserSite = {
  title: i18n.t('UsersScreen.defaultSite'),
  field: 'defaultSite',
  render: getUserDefaultSite,
}
const thirdPartyActivation = {
  title: i18n.t('tablesEntries.thirdPartyActivation'),
  field: 'thirdPartyActivation',
  render: getThirdPartyActivation,
}
const events = {
  title: i18n.t('tablesEntries.events'),
  field: 'events',
  render: getEventsLabel,
  sorting: false,
}
const url = { title: i18n.t('tablesEntries.url'), field: 'url', sorting: false }
const clientId = { title: i18n.t('tablesEntries.clientId'), field: 'clientId', sorting: false }
const deliverySite = {
  title: i18n.t('tablesEntries.deliverySite'),
  field: 'deliverySiteId',
  sorting: false,
  render: getSiteInOrderLabel,
}
const departureSite = {
  title: i18n.t('tablesEntries.departureSite'),
  field: 'departureWarehouse',
  sorting: false,
  render: getSiteInPlanTourLabel,
}

const tourDepartureSite = { ...departureSite, field: 'warehouseId', render: getSiteLabelInTour }

const planStatus = {
  title: i18n.t('tablesEntries.status.title'),
  field: 'planStatus',
  render: getPlanStatusLabel,
}
const toOptimize = {
  title: i18n.t('tablesEntries.toOptimize'),
  field: 'toOptimize',
  render: getToOptimizeLabel,
}

const jobStatus = {
  title: i18n.t('tablesEntries.status.title'),
  field: 'status',
  sorting: false,
  render: getJobStatusLabel,
}
const dataType = {
  title: i18n.t('tablesEntries.type'),
  field: 'dataType',
  sorting: false,
  render: getJobDataTypeLabel,
}
const nbOfActions = {
  title: i18n.t('tablesEntries.actionsCount'),
  field: 'nbOfActions',
  sorting: false,
  align: 'center',
}
const nbOfSuccessfullActions = {
  title: i18n.t('tablesEntries.successfullActionsCount'),
  field: 'nbOfActionsSuccess',
  sorting: false,
  align: 'center',
}
const nbOfFailedActions = {
  title: i18n.t('tablesEntries.failedActionsCount'),
  field: 'nbOfFailedActions',
  sorting: false,
  align: 'center',
}
const originFileName = {
  title: i18n.t('tablesEntries.fileName'),
  field: 'originFileName',
  sorting: false,
}
const importDate = {
  title: i18n.t('tablesEntries.importDate'),
  field: 'createdDate',
  sorting: false,
  render: formatDate,
}
const importedBy = { title: i18n.t('tablesEntries.importedBy'), field: 'user', sorting: false }
const importAction = { title: i18n.t('tablesEntries.action'), field: 'action', sorting: false }
const validationErrors = {
  title: i18n.t('tablesEntries.validationErrors'),
  field: 'validationErrors',
  sorting: false,
}
const executionErrors = {
  title: i18n.t('tablesEntries.executionErrors'),
  field: 'executionErrors',
  sorting: false,
}
const sitesRoutingRule = {
  title: i18n.t('tablesEntries.site'),
  field: 'condition.origin.siteId',
  render: getRoutingRuleSiteNameFromId,
  sorting: false,
}
const tenantNameNotifierConfig = {
  title: i18n.t('tablesEntries.tenant'),
  field: 'tenantId',
  render: getTenantNameFromId,
}
const ordersCount = {
  title: i18n.t('tablesEntries.orderCount'),
  field: 'ordersCount',
  sorting: false,
}
const deliveryOrderCount = {
  title: i18n.t('tablesEntries.deliveryOrderCount'),
  field: 'deliveryOrderCount',
  sorting: false,
}
const sentSMSCount = {
  title: i18n.t('tablesEntries.sentSMSCount'),
  field: 'sentSMSCount',
  sorting: false,
}
const geocodingSource = {
  title: i18n.t('tablesEntries.geocodingSource'),
  field: 'geocodingSource',
  sorting: false,
  render: getGeocodingSourceLabel,
}

const estimatedWeight = {
  title: i18n.t('tablesEntries.estimatedWeight'),
  field: 'estimatedWeight',
  render: getEstimatedWeightLabel,
  defaultHidden: true,
}

const notificationType = {
  title: i18n.t('tablesEntries.notificationType'),
  field: 'notificationType',
  sorting: false,
  render: getNotificationTypeLabel,
}

const dealer = {
  title: i18n.t('tablesEntries.dealer'),
  field: 'dealerId',
  render: getDealerLabelById,
  sorting: false,
}

const dealers = {
  title: i18n.t('tablesEntries.dealers'),
  field: 'dealerIds',
  render: getDealersLabelById,
  sorting: false,
}
const day = {
  title: i18n.t('tablesEntries.day'),
  field: 'day',
  sorting: false,
}
const slot = {
  title: i18n.t('tablesEntries.slot'),
  field: 'slot',
  sorting: false,
}
const availability = {
  title: i18n.t('tablesEntries.availability'),
  field: 'availability',
  sorting: false,
}

const carriers = {
  title: i18n.t('tablesEntries.carriers'),
  field: 'carrierIds',
  render: getCarrierNamesFromIds,
  sorting: false,
}

const orderLabel = {
  title: i18n.t('OrdersScreen.label'),
  field: 'label',
}

const orderEAN = {
  title: i18n.t('OrdersScreen.ean'),
  field: 'ean',
}

const orderDeclaredMissing = {
  title: i18n.t('OrdersScreen.declaredMissing'),
  field: 'missing',
}

const orderService = {
  title: i18n.t('OrdersScreen.service'),
  field: 'service',
}

const concernedWarehouses = {
  title: i18n.t('tablesEntries.concernedWarehouses'),
  field: 'concernedWarehouseIds',
  render: getConcernedWarehousesLabelById,
}

const createdDate = {
  title: i18n.t('tablesEntries.createdDate'),
  field: 'createdDate',
  sorting: false,
  render: formatDate,
}

const topic = {
  title: i18n.t('tablesEntries.topic'),
  field: 'topic',
  render: (alert: IAlert) => <>{alert.customTopic ?? i18n.t(`AlertsScreen.${alert.type}Topic`)}</>,
}

const read = {
  title: i18n.t('tablesEntries.state'),
  field: 'read',
  render: (alert: IAlert) => (alert.read ? <DraftsIcon /> : <MarkunreadIcon />),
}

const deliveryTime: ITableColumn = {
  title: i18n.t('tablesEntries.deliveryTime'),
  field: 'deliveryTime',
  sortField: 'deliveryDateTimeMinimum',
}

const driverName = {
  title: i18n.t('tablesEntries.name'),
  render: GetDriverNameFromId,
  field: 'driverName',
}

const reportComment = {
  title: i18n.t('tablesEntries.comment'),
  field: 'comment',
}
const reportRatingValue = {
  title: i18n.t('tablesEntries.ratingValue.title'),
  field: 'rating',
  render: formatVehicleReportRating,
}

const reportCreationDate = {
  title: i18n.t('tablesEntries.commentDateCreated'),
  field: 'createdOn',
  render: formatReportDate,
}

const vehicleImages = {
  title: i18n.t('tablesEntries.images'),
  field: 'images',
  render: DisplayVehicleImages,
}

const orderNumberWithLink = {
  title: i18n.t(`tablesEntries.orderNumber`),
  field: 'orderNumber',
  render: renderOrderLink,
}

export const COLUMNS_MODEL: ITableColumn[] = [
  tourNumber,
  tourDepartureSite,
  { ...carrierId, sorting: false },
  { ...driverId, sorting: false },
  // vehicleId,
  { ...deliveryType, sorting: false },
  { ...stopsNumber, sorting: false },
  // totalFloor,
  { ...postalCodes, sorting: false },
  { ...expectedDepartureDate },
  // amplitude,
  // specifics,
  executionStatus,
]

export const EXECUTION_STOP_DETAILS_COLUMNS_MODEL: ITableColumn[] = [
  {
    title: `${i18n.t('tablesEntries.details.stop')} - ${i18n.t('tablesEntries.details.floor')}`,
    field: 'floor',
    defaultHidden: true,
  },
  {
    title: `${i18n.t('tablesEntries.details.stop')} - ${i18n.t('tablesEntries.details.elevator')}`,
    field: 'hasElevator',
    defaultHidden: true,
  },
  {
    title: `${i18n.t('tablesEntries.details.stop')} - ${i18n.t('tablesEntries.details.tags')}`,
    field: 'tags',
    defaultHidden: true,
  },
]

export const COLUMNS_MODEL_CARRIER: ITableColumn[] = [
  { ...tourNumber },
  tourDepartureSite,
  { ...driverId, render: DisplayDriverField, sorting: false },
  { ...vehicleId, render: DisplayVehicleField, sorting: false },
  { ...deliveryType, sorting: false },
  { ...stopsNumber, sorting: false },
  { ...quantity, sorting: false },
  // totalFloor,
  { ...postalCodes, sorting: false },
  { ...departureDate },
  { ...amplitude, sorting: false },
  { ...specifics, sorting: false },
  { ...postponedStopCount, sorting: false },
  { ...delayed, sorting: false },
  executionStatus,
]

export const COLUMNS_MODEL_CARRIERS_LIST: ITableColumn[] = [name, code, site, active]

export const COLUMNS_MODEL_MONITORING: ITableColumn[] = [
  { ...tourNumber, render: addAlertToTourNumber },
  tourDepartureSite,
  { ...carrierId, render: GetCarrierNameFromId, sorting: false },
  { ...driverId, render: GetDriverNameFromId, sorting: false },
  { ...driverPhoneNumber, sorting: false },
  { ...deliveryType, title: i18n.t('tablesEntries.deliveryType.title'), sorting: false },
  { ...stopsNumber, sorting: false },
  { ...firstPlanifiedOrderDate, sorting: false },
  { ...firstDeliveredOrderDate, sorting: false },
  { ...lastPlanifiedOrderDate, sorting: false },
  { ...lastDeliveredOrderDate, sorting: false },
  { ...lastStopDelay, sorting: false },
  { ...numberOfNonRespectedClientPromises, sorting: false },
  { ...postponedStopCount, sorting: false },
  { ...abandonedStopCount, sorting: false },
  { ...status, render: ListSelectStatus, sorting: false },
]

export const COLUMNS_MODEL_PLAN_LIST: ITableColumn[] = [
  tourNumber,
  departureSite,
  { ...carrierId, render: GetCarrierNameFromId, sorting: false },
  { ...driverId, render: getDriverName, sorting: false },
  { ...driverPhoneNumber, sorting: false },
  { ...deliveryType, sorting: false },
  { ...stopsNumber, sorting: false },
  quantityToDeliver,
  quantityToLoad,
  { ...postalCodes, sorting: false },
  { ...departureDate, field: 'beginDateTime' },
  { ...toOptimize, sorting: false },
  { ...status, render: ListSelectPlanTourStatus, sorting: false },
]

export const COLUMNS_ORDERS: ITableColumn[] = [
  orderNumber,
  { ...warehouseLabelForOrders, sorting: false },
  { ...customer, sorting: false },
  { ...deliveryAddress, sorting: false },
  deliverySite,
  deliveryType,
  transportType,
  deliveryTime,
  mainQuantity,
  estimatedWeight,
  planStatus,
  locationRelevance,
  geocodingSource,
]

export const COLUMNS_PLANNING_ORDERS: ITableColumn[] = [
  orderNumber,
  { ...warehouseLabelForOrders, sorting: false },
  { ...customer, sorting: false },
  { ...deliveryAddress, sorting: false },
  { ...enterpriseLabel, sorting: false },
  deliverySite,
  deliveryType,
  transportType,
  mainQuantity,
  planStatus,
  locationRelevance,
]

export const COLUMNS_GEOCODING_ORDERS: ITableColumn[] = [
  orderNumber,
  { ...warehouseLabelForOrders, sorting: false },
  { ...customer, sorting: false },
  { ...deliveryAddress, sorting: false },
  deliveryType,
  mainQuantity,
  { ...locationRelevance, render: addAlertToGeocoding },
  geocodingSource,
]

export const COLUMNS_MODEL_DRIVERS_LIST: ITableColumn[] = [
  { ...firstName, sorting: false },
  { ...lastName, sorting: false },
  { ...phoneNumber, sorting: false },
  { ...login, sorting: false },
  { ...carrierLabel, sorting: false },
  { ...warehouse, title: i18n.t('tablesEntries.site'), sorting: false },
  { ...activeDriver, sorting: false },
]

export const COLUMNS_MODEL_USERS_LIST: ITableColumn[] = [
  firstName,
  lastName,
  email,
  tenant,
  companies,
  activeUser,
  roles,
  { ...sites, field: 'site', render: getUserSites },
  { ...defaultUserSite, sorting: false },
]

export const COLUMNS_MODEL_VEHICLES_LIST: ITableColumn[] = [
  name,
  { ...carrier, sorting: false },
  licencePlate,
  { ...vehicleType, sorting: false },
  { ...warehouse, sorting: false },
  activeVehicle,
  { ...thirdPartyActivation, sorting: false },
]

export const COLUMNS_MODEL_VEHICLE_REPORTS: ITableColumn[] = [
  { ...reportCreationDate },
  { ...driverName, sorting: false },
  { ...reportRatingValue, sorting: false },
  { ...reportComment, sorting: false },
  { ...vehicleImages, sorting: false },
]

export const COLUMNS_MODEL_ADMIN_WAREHOUSES: ITableColumn[] = [
  tenantName,
  { ...name, sorting: false },
]

export const COLUMS_MODEL_SITES_LIST: ITableColumn[] = [
  name,
  code,
  { ...siteType, sorting: false },
  { ...siteAddress, sorting: false },
  { ...siteLocationRelevance, sorting: false },
  activeSite,
  { ...siteTourType, sorting: false },
]

export const COLUMNS_MODEL_VEHICLE_TYPES_LIST: ITableColumn[] = [
  label,
  code,
  { ...vehicleProfile, sorting: false },
  { ...capacity, sorting: false },
  activeVehicleType,
]

export const COLUMS_MODEL_TENANTS_LIST: ITableColumn[] = [{ ...label, field: 'name' }]

export const COLUMNS_MODEL_TRAILERS_LIST: ITableColumn[] = [
  name,
  carrier,
  licencePlate,
  vehicleType,
  activeVehicle,
]

export const COLUMNS_MODEL_FEATURE_FLAGS: ITableColumn[] = [label, description]

export const COLUMNS_MODEL_PLAN_TEMPLATE: ITableColumn[] = [
  { ...label, title: i18n.t('tablesEntries.name') },
  planTemplateWarehouse,
  { ...begin, sorting: false },
  { ...end, sorting: false },
  { ...dateOffset, sorting: false },
]

export const COLUMNS_MODEL_WEBHOOKS: ITableColumn[] = [
  sites,
  events,
  carrier,
  dealer,
  url,
  clientId,
  active,
]

export const COLUMNS_MODEL_ROUTING_RULES: ITableColumn[] = [
  { ...label, sorting: false },
  { ...sitesRoutingRule, sorting: false },
  { ...priority, sorting: false },
  { ...active, title: i18n.t('tablesEntries.active'), sorting: false },
]

export const COLUMNS_MODEL_IMPORT_JOBS: ITableColumn[] = [
  jobStatus,
  dataType,
  nbOfActions,
  nbOfSuccessfullActions,
  nbOfFailedActions,
  originFileName,
  importDate,
  importedBy,
]

export const COLUMNS_MODEL_JOB_IMPORT_ERRORS: ITableColumn[] = [
  importAction,
  validationErrors,
  executionErrors,
]

export const COLUMS_MODEL_SECTORS_LIST: ITableColumn[] = [
  name,
  code,
  { ...siteType, sorting: false },
  { ...siteAddress, sorting: false },
  activeSite,
  { ...siteWithSector, sorting: false },
  { ...siteTourType, sorting: false },
]

export const COLUMNS_MODEL_NOTIFIER_CONFIGURATIONS: ITableColumn[] = [
  { ...notificationType, sorting: false },
  { ...warehouses, sorting: false },
  { ...carriers },
  { ...dealers, sorting: false },
]

export const COLUMNS_MODEL_NOTIFIER_CONFIGURATIONS_ADMIN: ITableColumn[] = [
  { ...notificationType, sorting: false },
  { ...tenantNameNotifierConfig, sorting: false },
  { ...warehouses, sorting: false },
  { ...carriers },
  { ...dealers, sorting: false },
]

export const COLUMNS_MODEL_WAREHOUSE_TIME_SLOTS: ITableColumn[] = [
  { ...label, sorting: false },
  { ...warehouses, sorting: false },
  { ...sectors, sorting: false },
  { ...active, title: i18n.t('tablesEntries.active'), sorting: false },
]

export const COLUMNS_SITE_REPORTING: ITableColumn[] = [
  { ...sites, field: 'siteId', render: getSiteLabelById, export: false },
  {
    title: i18n.t('tablesEntries.site'),
    field: 'siteName',
    sorting: false,
    hidden: true,
    export: true,
  },
  ordersCount,
  deliveryOrderCount,
  sentSMSCount,
]

export const COLUMNS_SLOTS_REPORTING: ITableColumn[] = [day, slot, availability]

export const COLUMNS_MODEL_COST_CONFIGURATIONS_LIST: ITableColumn[] = [
  { ...label, sorting: false },
  { ...carriers, sorting: false },
]

export const COLUMNS_COSTS_REPORTING: ITableColumn[] = [
  {
    title: i18n.t('tablesEntries.site'),
    field: 'warehouseName',
    sorting: false,
  },
  {
    title: i18n.t('tablesEntries.carrier'),
    field: 'carrierName',
    sorting: false,
  },
  {
    title: i18n.t('tablesEntries.carrierCost'),
    field: 'carrierCost',
    sorting: false,
    hidden: true,
    export: true,
  },
  {
    title: i18n.t('tablesEntries.carrierCost'),
    field: 'carrierCostFormatted',
    sorting: false,
    headerStyle: { textAlign: 'right' },
    export: false,
  },
]

export const COLUMNS_COMMENTS_REPORTING: ITableColumn[] = [
  { ...commentCreationDate, sorting: false },
  {
    ...carrierId,
    sorting: false,
    render: undefined,
  },
  {
    ...driverId,
    sorting: false,
    render: undefined,
  },
  { ...commentCustomer, sorting: false },
  { ...phoneNumber, sorting: false },
  { ...ratingValue, sorting: false },
  { ...comment, sorting: false },
]

export const COLUMNS_MODEL_PRODUCTS_LIST: ITableColumn[] = [
  quantity,
  orderLabel,
  orderEAN,
  orderService,
  orderDeclaredMissing,
]

const disputeReasonCode = {
  title: i18n.t('tablesEntries.disputeReasonCode'),
  field: 'reasonCode',
  sorting: true,
  render: getDisputeReasonLabelFromCode,
}

const disputeDriver = {
  title: i18n.t('tablesEntries.driver'),
  render: GetDriverNameFromId,
  sorting: false,
  field: 'driverId',
}

const disputeMediaLink = {
  title: i18n.t('tablesEntries.images'),
  field: 'mediaList',
  sorting: false,
  render: renderMediaLinks,
}

const disputeCreationDate = {
  title: i18n.t('tablesEntries.createdDate'),
  field: 'creationDate',
  sorting: true,
  render: formatCreationDate,
}

const disputeStatus = {
  title: i18n.t('tablesEntries.statusLabel'),
  field: 'status',
  sorting: false,
  render: getDisputeStatus,
}

const disputeNumber = {
  title: i18n.t('tablesEntries.disputeNumber'),
  sorting: false,
  field: 'disputeNumber',
}

export const COLUMNS_MODEL_DISPUTES_LIST: ITableColumn[] = [
  disputeCreationDate,
  disputeNumber,
  disputeReasonCode,
  orderNumberWithLink,
  carrier,
  disputeDriver,
  sites,
  disputeMediaLink,
  disputeStatus,
]

export const COLUMNS_MODEL_ALERTS_LIST: ITableColumn[] = [
  {
    ...createdDate,
    sorting: true,
    cellStyle: formatAlertCellStyle,
    render: formatDate,
  },
  { ...topic, sorting: false, cellStyle: formatAlertCellStyle },
  { ...concernedWarehouses, sorting: false, cellStyle: formatAlertCellStyle },
  { ...dealer, sorting: false, cellStyle: formatAlertCellStyle },
  { ...read, sorting: true, cellStyle: formatAlertCellStyle },
]

const apikeyComment = {
  title: i18n.t('tablesEntries.comment'),
  sorting: false,
  field: 'comment',
}

const apikeyUser = {
  title: i18n.t('tablesEntries.user'),
  sorting: false,
  field: 'userId',
  render: formatApikeyUserName,
}

const apikeyIPs = {
  title: i18n.t('tablesEntries.allowedIPs'),
  sorting: false,
  field: 'whitelistedIPs',
  render: formatAllowedIPs,
}

export const COLUMNS_MODEL_APIKEYS_LIST: ITableColumn[] = [
  { ...apikeyComment },
  { ...apikeyUser },
  { ...apikeyIPs },
]
