import React, { useContext } from 'react'

import { COLUMNS_MODEL_MONITORING } from 'constants/table'
import { ToursConsumer } from 'store/ToursContext'
import { ITour } from 'interfaces/Itours'
import Table from 'components/Table'
import { FilterKey } from 'constants/filters'
import { AVAILABLE_FILTERS, Role } from 'constants/constants'
import { AuthContext } from 'store/AuthContext'

interface IExecutionProps {
  tours: ITour[]
}

function TourMonitoringScreen({ tours }: IExecutionProps): JSX.Element {
  const { user } = useContext(AuthContext)
  const shouldHideCarrierFilters = user?.roles.length === 1 && user.roles[0] === Role.Carrier

  const getHiddenFilters = () => {
    const hiddenFilters: AVAILABLE_FILTERS[] = []
    if (shouldHideCarrierFilters) hiddenFilters.push(AVAILABLE_FILTERS.CARRIERS)
    hiddenFilters.push(AVAILABLE_FILTERS.VEHICLE_TYPES)

    return hiddenFilters
  }

  return (
    <Table
      showExtendedDateFilter
      extendedDateFilter={{
        today: true,
        tomorrow: true,
        thisWeek: true,
        next7days: true,
      }}
      columns={COLUMNS_MODEL_MONITORING}
      hasEditVisitsDialog
      data={tours}
      extendedDetails
      autoReload
      name="tourMonitoring"
      filterKey={FilterKey.tourMonitoring}
      hideFilters={getHiddenFilters()}
    />
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <ToursConsumer>
    {(ctx): JSX.Element => <TourMonitoringScreen tours={ctx.tours} {...props} />}
  </ToursConsumer>
)
