import React from 'react'
import HomeIcon from '@material-ui/icons/Home'
import useStyles from './styles'

interface IProps {
  label: string
}

const Address = ({ label }: IProps): JSX.Element => {
  const classes = useStyles()

  return (
    <div data-cy="item-address" className={classes.addressContainer}>
      <span className={classes.addressLabel}>{label}</span>
      <HomeIcon />
    </div>
  )
}

export default Address
