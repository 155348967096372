import React, { useState, ReactNode, useContext } from 'react'

import { IAddress } from 'interfaces/Itours'
import { IOrderFilters, IChartStat } from 'interfaces'
import { IError, isIError } from 'api/types'
import OrdersApi from 'api/orders'
import StatsApi from 'api/stats'
import { IStopStat } from 'interfaces/IStopStat'
// eslint-disable-next-line max-len
import {
  IOrder,
  IOrderDeliveryScheduleChangesStats,
  IOrderDetails,
  IOrderForDeliveryDistance,
  IOrderHistory,
  IOrderStats,
} from 'interfaces/IOrders'
import { ITourIndicator } from 'interfaces/ITourIndicator'
import { ITransportVolumeStat } from 'interfaces/ITransportVolumeStat'
import { COLUMNS_ORDERS, COLUMNS_GEOCODING_ORDERS } from 'constants/table'
import { IHourlyStats } from 'interfaces/IHourlyStats'
import { FeedbackContext } from 'store/FeedbackContext'
import { IAvgStatsTours } from 'interfaces/IAvgStatsTours'

interface IOrdersContext {
  orders: IOrder[]
  count: number
  updateOrders: () => void
  patchOrderPosition: (
    orderId: string,
    address: IAddress | Partial<IAddress>,
    shouldCacheAddressLabel?: boolean,
  ) => Promise<IOrder | IError>
  getStopStats: (startDate: string, endDate: string, carrierIds?: string[]) => void
  stopStats: IStopStat
  ordersStats: IOrderStats[]
  ordersDeliveryScheduleChangesStats: IOrderDeliveryScheduleChangesStats
  updateStats: (
    deliveryTypes?: string[],
    transportTypes?: string[],
    warehouseIds?: string[],
    planStatuses?: string[],
    deliveryWarehouseIds?: string[],
    zipCodes?: string[],
    minDay?: string,
    maxDay?: string,
  ) => void
  updateDeliveryScheduleChangedStats: (
    minDay?: string,
    maxDay?: string,
    warehouseIds?: string[],
    carrierIds?: string[],
  ) => void
  getTourStats: (startDate: string, endDate: string, carrierIds?: string[]) => void
  tourIndicator: ITourIndicator
  transportVolumeStat: ITransportVolumeStat
  avgStatsOfTours: IAvgStatsTours
  getTransportVolumeStats: (
    startDate: string,
    endDate: string,
    carrierIds?: string[],
    siteIds?: string[],
  ) => void
  getAvgStatsOfTours: (
    startDate: string,
    endDate: string,
    carrierIds?: string[],
    siteIds?: string[],
  ) => void
  getOrderStats: (
    date: string,
    deliveryTypes?: string[],
    transportTypes?: string[],
    deliveryWarehouseIds?: string[],
    warehouseIds?: string[],
    planStatuses?: string[],
    zipCodes?: string[],
    groupBy?: number,
  ) => void
  orderTypesStats: IHourlyStats[]
  planningOrderStats: IChartStat
  getOrderPlanningStats: (
    date: string,
    deliveryTypes?: string[],
    transportTypes?: string[],
    siteIds?: string[],
  ) => void
  deliveryDistanceReporting: IOrderForDeliveryDistance[]
  getDeliveryDistanceReporting: (
    deliveryTypes?: string[],
    transportTypes?: string[],
    warehouseIds?: string[],
    planStatuses?: string[],
    deliveryWarehouseIds?: string[],
    zipCodes?: string[],
    minDay?: string,
    maxDay?: string,
  ) => void
  fetchOrderDetails: (
    id: string,
    callbackFunction: (arg: IOrderDetails) => void,
    shouldGetPlanVisits?: boolean,
  ) => void
  fetchOrderHistory: (id: string, callbackFunction: (arg: IOrderHistory[]) => void) => void
  fetchExtendedOrderDetailsByOrderNumber: (
    orderNumber: string,
    callbackFunction: (arg: IOrderDetails) => void,
  ) => void
}

const OrdersContext = React.createContext<IOrdersContext>({} as IOrdersContext)
const { Provider, Consumer } = OrdersContext

interface IProps {
  children: ReactNode
}

/**
 *
 * OrdersContext will manage the orders list
 */

function OrdersProvider({ children }: IProps): JSX.Element {
  const [orders, setOrders] = useState<IOrder[]>([])
  const [ordersStats, setOrdersStats] = useState<IOrderStats[]>([])
  // eslint-disable-next-line max-len
  const [ordersDeliveryScheduleChangesStats, setOrdersDeliveryScheduleChangesStats] = useState<IOrderDeliveryScheduleChangesStats>({
    affectedOrders: 0,
    orderCount: 0,
  })
  const [count, setCount] = useState<number>(0)
  const [stopStats, setStopStats] = useState<IStopStat>({} as IStopStat)
  const [tourIndicator, setTourIndicator] = useState<ITourIndicator>({} as ITourIndicator)
  const [transportVolumeStat, setTransportVolumeStat] = useState<ITransportVolumeStat>(
    {} as ITransportVolumeStat,
  )
  const [avgStatsOfTours, setAvgStatsOfTours] = useState<IAvgStatsTours>({} as IAvgStatsTours)
  const [orderTypesStats, setOrderTypesStats] = useState<IHourlyStats[]>([])
  const [planningOrderStats, setPlanningOrderStats] = useState<IChartStat>({
    toursNumberOfOrdersByDeliveryTypes: [],
  } as IChartStat)
  const [deliveryDistanceReporting, setDeliveryDistanceReporting] = useState<
    IOrderForDeliveryDistance[]
  >([])
  const { openErrorSnack, toggleLoader } = useContext(FeedbackContext)

  async function updateOrders(
    filters?: IOrderFilters,
    offset?: number,
    rowsPerPage?: number,
    sortField?: number,
    sortDirection?: string,
    isForGeocoding?: boolean,
  ): Promise<void> {
    toggleLoader(true)
    const columnsToUse = isForGeocoding ? COLUMNS_GEOCODING_ORDERS : COLUMNS_ORDERS
    const res = await OrdersApi.getOrders(
      filters,
      offset,
      rowsPerPage,
      columnsToUse.find((_column, index) => index === sortField)?.sortField
        || columnsToUse.find((_column, index) => index === sortField)?.field,
      sortDirection === 'desc' ? -1 : 1,
    )
    if (isIError(res)) {
      openErrorSnack(res.error.message)
    } else {
      setOrders(res.orders)
      setCount(res.count)
    }
    toggleLoader(false)
  }

  async function updateDeliveryScheduleChangedStats(
    minDay?: string,
    maxDay?: string,
    warehouseIds?: string[],
    carrierIds?: string[],
  ): Promise<void> {
    toggleLoader(true)
    const res = await OrdersApi.getOrdersStatsWhereDeliveryScheduleChanged(
      minDay,
      maxDay,
      warehouseIds,
      carrierIds,
    )
    if (isIError(res)) {
      openErrorSnack(res.error.message)
    } else {
      setOrdersDeliveryScheduleChangesStats(res)
    }
    toggleLoader(false)
  }

  async function updateStats(
    deliveryTypes?: string[],
    transportTypes?: string[],
    warehouseIds?: string[],
    planStatuses?: string[],
    deliveryWarehouseIds?: string[],
    zipCodes?: string[],
    minDay?: string,
    maxDay?: string,
  ): Promise<void> {
    toggleLoader(true)
    const res = await OrdersApi.getOrdersStats(
      deliveryTypes,
      transportTypes,
      warehouseIds,
      planStatuses,
      deliveryWarehouseIds,
      zipCodes,
      minDay,
      maxDay,
    )
    if (isIError(res)) {
      openErrorSnack(res.error.message)
    } else {
      setOrdersStats(res)
    }
    toggleLoader(false)
  }

  async function patchOrderPosition(
    orderId: string,
    address: Partial<IAddress>,
    shouldCacheAddressLabel?: boolean,
  ): Promise<IOrder | IError> {
    toggleLoader(true)
    const res = await OrdersApi.patchOrderPosition(orderId, address, shouldCacheAddressLabel)
    if (!isIError(res)) {
      if (res) {
        setOrders((prev) => {
          const newOrders = [...prev]
          const index = newOrders.findIndex((elem) => elem.orderId === res?.orderId)
          newOrders[index] = res as IOrder
          return newOrders
        })
      }
    } else {
      openErrorSnack(res.error.message)
    }
    toggleLoader(false)
    return res
  }

  const getStopStats = async (
    startDate: string,
    endDate: string,
    carrierIds?: string[],
    siteIds?: string[],
    toleratedAdvance?: number,
    toleratedDelay?: number,
  ): Promise<void> => {
    toggleLoader(true)
    const response = await OrdersApi.getStopStats(
      startDate,
      endDate,
      carrierIds,
      siteIds,
      toleratedAdvance,
      toleratedDelay,
    )
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      setStopStats(response)
    }
    toggleLoader(false)
  }

  const getTourStats = async (
    startDate: string,
    endDate: string,
    carrierIds?: string[],
    siteIds?: string[],
  ): Promise<void> => {
    toggleLoader(true)
    const response = await OrdersApi.getTourStats(startDate, endDate, carrierIds, siteIds)
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      setTourIndicator(response)
    }
    toggleLoader(false)
  }

  const getTransportVolumeStats = async (
    startDate: string,
    endDate: string,
    carrierIds?: string[],
    siteIds?: string[],
  ): Promise<void> => {
    toggleLoader(true)
    const response = await OrdersApi.getTransportVolumeStats(
      startDate,
      endDate,
      carrierIds,
      siteIds,
    )
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      setTransportVolumeStat(response)
    }
    toggleLoader(false)
  }

  const getAvgStatsOfTours = async (
    startDate: string,
    endDate: string,
    carrierIds?: string[],
    siteIds?: string[],
  ): Promise<void> => {
    toggleLoader(true)
    const response = await OrdersApi.getAvgStatsOfTours(startDate, endDate, carrierIds, siteIds)
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      setAvgStatsOfTours(response)
    }
    toggleLoader(false)
  }

  const getOrderStats = async (
    date: string,
    deliveryTypes?: string[],
    transportTypes?: string[],
    deliveryWarehouseIds?: string[],
    warehouseIds?: string[],
    planStatuses?: string[],
    zipCodes?: string[],
    groupBy?: number,
  ): Promise<void> => {
    toggleLoader(true)
    const response = await OrdersApi.getOrderStats(
      date,
      deliveryTypes,
      transportTypes,
      deliveryWarehouseIds,
      warehouseIds,
      planStatuses,
      zipCodes,
      groupBy,
    )
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      setOrderTypesStats(response)
    }
    toggleLoader(false)
  }

  const getOrderPlanningStats = async (
    date: string,
    deliveryTypes?: string[],
    transportTypes?: string[],
    siteIds?: string[],
  ): Promise<void> => {
    toggleLoader(true)
    const response = await OrdersApi.getOrderPlanningStats(
      date,
      deliveryTypes,
      transportTypes,
      siteIds,
    )
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      setPlanningOrderStats(response)
    }
    toggleLoader(false)
  }

  async function getDeliveryDistanceReporting(
    deliveryTypes?: string[],
    transportTypes?: string[],
    warehouseIds?: string[],
    planStatuses?: string[],
    deliveryWarehouseIds?: string[],
    zipCodes?: string[],
    minDay?: string,
    maxDay?: string,
  ): Promise<void> {
    toggleLoader(true)
    const res = await StatsApi.getDeliveryDistanceReporting(
      deliveryTypes,
      transportTypes,
      warehouseIds,
      planStatuses,
      deliveryWarehouseIds,
      zipCodes,
      minDay,
      maxDay,
    )
    if (isIError(res)) {
      openErrorSnack(res.error.message)
    } else {
      setDeliveryDistanceReporting(res)
    }
    toggleLoader(false)
  }

  async function fetchOrderDetails(
    id: string,
    callbackFunction: (arg: IOrderDetails) => void,
    shouldGetPlanVisits = false,
  ): Promise<void> {
    toggleLoader(true)
    const response = await OrdersApi.getOrderDetails(id, shouldGetPlanVisits)
    if (response && isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      callbackFunction(response)
    }
    toggleLoader(false)
  }

  async function fetchOrderHistory(
    id: string,
    callbackFunction: (arg: IOrderHistory[]) => void,
  ): Promise<void> {
    toggleLoader(true)
    const response = await OrdersApi.getOrderHistory(id)
    if (response && isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      callbackFunction(response)
    }
    toggleLoader(false)
  }

  async function fetchExtendedOrderDetailsByOrderNumber(
    orderNumber: string,
    callbackFunction: (arg: IOrderDetails) => void,
  ): Promise<void> {
    toggleLoader(true)
    const response = await OrdersApi.getExtendedOrderDetailsByOrderNumber(orderNumber)
    if (response && isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      callbackFunction(response)
    }
    toggleLoader(false)
  }

  return (
    <Provider
      value={{
        orders,
        ordersStats,
        ordersDeliveryScheduleChangesStats,
        count,
        updateOrders,
        updateStats,
        updateDeliveryScheduleChangedStats,
        patchOrderPosition,
        getStopStats,
        stopStats,
        getTourStats,
        tourIndicator,
        transportVolumeStat,
        avgStatsOfTours,
        getTransportVolumeStats,
        getAvgStatsOfTours,
        getOrderStats,
        orderTypesStats,
        getOrderPlanningStats,
        planningOrderStats,
        deliveryDistanceReporting,
        getDeliveryDistanceReporting,
        fetchOrderDetails,
        fetchOrderHistory,
        fetchExtendedOrderDetailsByOrderNumber,
      }}
    >
      {children}
    </Provider>
  )
}

export default OrdersProvider

export { Consumer as OrdersConsumer, OrdersContext }
