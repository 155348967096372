import React from 'react'
import { Paper } from '@material-ui/core'
import { Theme, useTheme } from '@material-ui/core/styles'
import styled from 'styled-components'

const CustomPaper = styled(Paper)<{ theme: Theme }>`
  margin: 36px 0;
  border: ${(props) => `3px solid ${props.theme.palette.divider}`};
`

interface IProps {
  id: string
  children: React.ReactNode
}

const StyledPaper = ({ id, children }: IProps): JSX.Element => {
  const theme = useTheme()

  return (
    <CustomPaper id={id} elevation={0} theme={theme}>
      {children}
    </CustomPaper>
  )
}

export default StyledPaper
