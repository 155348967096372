import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import Button from 'components/Button/CustomButton'

interface IProps {
  open: boolean
  handleClose: () => void
  onValidate: () => void
}

const ExistingDraftDialog = ({ open, handleClose, onValidate }: IProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <DialogContentText>{t('ExistingDraft.text')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onPress={handleClose}>{t('ExistingDraft.no')}</Button>
        <Button onPress={onValidate}>{t('ExistingDraft.yes')}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ExistingDraftDialog
