import { IStop } from 'interfaces/Itours'
import { IPlanStop } from 'interfaces/IPlan'
import { IStopMarker } from 'interfaces/map'
import { IDelayResult, delayCalculation, stopDelayCalculation } from './delaysColors'

function calculateStopClientPromiseDelay(
  stop: IStop | IStopMarker,
  toleratedAdvance: number,
  toleratedDelay: number,
): IDelayResult {
  const date = stop.realArrival || stop.cancellation?.date || stop.estimatedArrival
  const res: IDelayResult = delayCalculation(
    date,
    stop.visitsToDeliver[0].departureMinDate,
    stop.visitsToDeliver[0].departureMaxDate,
    toleratedAdvance,
    toleratedDelay,
  )
  return res
}

function calculateStopDelay(stop: IStop | IStopMarker, toleratedDelay: number): IDelayResult {
  const res: IDelayResult = stopDelayCalculation(
    stop.realArrival ?? stop.estimatedArrival ?? stop.planedArrival,
    stop.planedArrival ?? stop.visitsToDeliver[0]?.departureMaxDate,
    toleratedDelay,
  )
  return res
}

const removeFirstAndLastStops = (
  stops: IStop[] | IPlanStop[] | IStopMarker[],
): (IStop | IPlanStop | IStopMarker)[] => {
  const res = [...stops]
  res.shift()
  res.pop()
  return res
}

const getFinalStop = (
  stops: IStop[] | IPlanStop[] | IStopMarker[],
): IStop | IPlanStop | IStopMarker | null => {
  const stopsWithoutFirstAndLast = removeFirstAndLastStops(stops) as IStop[]
  if (stopsWithoutFirstAndLast.length === 0) return null
  return stopsWithoutFirstAndLast.slice(-1)[0]
}

export {
  calculateStopClientPromiseDelay,
  calculateStopDelay,
  removeFirstAndLastStops,
  getFinalStop,
}
