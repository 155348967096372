import { useTheme } from '@material-ui/core/styles'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { Paper, TextField } from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Grid from '@material-ui/core/Grid'
import Modal from '@material-ui/core/Modal'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Switch from '@material-ui/core/Switch'
import * as Sentry from '@sentry/react'
import { Severity } from '@sentry/react'
import styled from 'styled-components'

import SitesApi from 'api/sites'
import PlansApi, { IOptimizePlan, IOptimizePlanWithManualTours } from 'api/plans'
import { OPTIMIZATION_PARAMS, OPTIMIZATION_ROUTER_PARAMS, SentryEvents } from 'constants/constants'
import { IOptimizationParam, VALUE_TYPE } from 'interfaces/IOptimizationParam'
import { IError, isIError } from 'api/types'
import { IVehicle } from 'interfaces/IVehicle'
import { PlanificationContext } from 'screens/PlanningCategory/PlanningScreen/PlanningStore'
import { FeedbackContext } from 'store/FeedbackContext'
import { ICluster } from 'interfaces/ICluster'
import { AuthContext } from 'store/AuthContext'
import { ContentContext } from 'store/ContentContext'
import { IJob } from 'interfaces/IJob'
import { findVehiclesWithModifiedAvailability, removePastDatesFromVehiclesAvailabilities } from 'utils/planningUtils'
import useStyles from './styles'
import ButtonsRow from '../ButtonsRow'
import ListItem from './ListItem'

interface IPlanCreationModal {
  isVisible: boolean
  toggleModal(): void
  setMaxOptimizationTime(value: number): void
}

const CustomPaper = styled(Paper)`
  position: absolute;
  min-width: 60%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 500;
  padding: 20px 50px;
`

const AccordionsContainer = styled(Paper)<{ bgColor: string }>`
  margin: 16px 0;
  width: 100%;
  max-height: 80vh;
  overflow: auto;
  background-color: ${(props) => props.bgColor};
`

const StyledSettingLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
function SettingLine({ label, value, onInputChange, dataCy }): JSX.Element {
  const [localValue, setLocalValue] = useState<boolean | number>(value)
  const { t } = useTranslation()

  async function onChange(lValue: boolean | number): Promise<void> {
    if (typeof localValue === 'boolean') {
      setLocalValue(lValue)
    }
    onInputChange(label, lValue)
  }

  function onChangeNumber(lValue: string, lLabel: string): void {
    if (lValue && VALUE_TYPE[lLabel] === 'float') {
      onChange(parseFloat(lValue.replace(/,/g, '.')))
    } else {
      onChange(parseInt(lValue, 10))
    }
  }

  return (
    <StyledSettingLine>
      <Typography>{t(`PlanningScreen.optimizeModal.${label}`)}</Typography>
      {(typeof localValue === 'number' || typeof localValue === 'undefined') && (
        <TextField
          onChange={(event): void => onChangeNumber(event.target.value, label)}
          id="filled-number"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          margin="dense"
          defaultValue={localValue}
          data-cy={dataCy}
        />
      )}
      {typeof localValue === 'boolean' && (
        <Switch
          checked={localValue}
          color="primary"
          onClick={(): Promise<void> => onChange(!localValue)}
          data-cy={dataCy}
        />
      )}
    </StyledSettingLine>
  )
}

export default ({
  isVisible,
  toggleModal,
  setMaxOptimizationTime,
}: IPlanCreationModal): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    selectedPlan,
    toggleBackdrop,
    markers,
    isClusterMode,
    clusters,
    setClusterOptimInError,
    isClusterOptimInError,
    setIsMapotempoActionInProgress,
    getOptimizeState,
    manualTours,
    isManualToursOptimInError,
    setManualToursOptimInError,
    isMultisite,
    setJobIds,
    vehiclesList,
    vehiclesListForGlobalOptim,
    setVehiclesListForGlobalOptim,
  } = useContext(PlanificationContext)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [openedPanel, setOpenedPanel] = useState<string | false>('settings')
  const [selectedVehiclesListIds, setSelectedVehiclesListIds] = useState<Set<string>>(new Set())
  const [optimizationParams, setOptimizationParams] = useState<IOptimizationParam>(
    {} as IOptimizationParam,
  )
  const [optimizeRemainingPlanVisits, setOptimizeRemainingPlanVisits] = useState<boolean>(false)
  const [error, setError] = useState<string | undefined>()
  const { openErrorSnack, toggleLoader } = useContext(FeedbackContext)
  const { user, shouldUseJobsForOptimization } = useContext(AuthContext)
  const { sites } = useContext(ContentContext)

  const theme = useTheme()

  const noClusters = !clusters.length
  const clusterWithoutVehicle = clusters.find((cluster) => !cluster.vehicleIds.length) !== undefined
  const areMTClusters = clusters.every((cluster) => cluster.visits.length === 0)

  const noVisits = !(markers.findIndex((marker) => marker.selected) !== -1
    ? markers.filter((marker) => !marker.tourId && marker.selected).map((visit) => visit.stopId)
      .length
    : markers.filter((marker) => !marker.tourId).map((visit) => visit.stopId).length)

  const shouldDisableClustersOptimize = isClusterMode && (noClusters || clusterWithoutVehicle || areMTClusters)

  const manualTourWithoutVehicle = manualTours.find((manualTour) => !manualTour.vehicleIds.length) !== undefined
  const manualTourWithoutVisit = manualTours.find((manualTour) => !manualTour.visits.length) !== undefined

  const shouldDisablePlanOptimize = !isClusterMode
    && (manualTours.length > 0 ? manualTourWithoutVehicle || manualTourWithoutVisit : noVisits)

  const manualToursVehicleIds = manualTours.reduce(
    (acc: string[], manualTour: ICluster) => [...acc, ...manualTour.vehicleIds],
    [],
  )

  const shouldDisableBecauseOfVehicleDateValidity = vehiclesListForGlobalOptim
    .findIndex(v => new Date(v.startDate) > new Date(v.endDate)) !== -1

  const errorMessage = (): string | undefined => {
    if (error) {
      return error
    }
    if (!isClusterMode) {
      if (manualTours.length > 0) {
        if (manualTourWithoutVehicle) {
          return t('PlanningScreen.optimizeModal.elementShouldHaveVehicle', {
            element: t('PlanningScreen.manualTour').toLowerCase(),
          })
        }
        if (manualTourWithoutVisit) {
          return t('PlanningScreen.optimizeModal.elementShouldHaveVisit', {
            element: t('PlanningScreen.manualTour').toLowerCase(),
          })
        }
      }
      return undefined
    }
    if (noClusters) return t('PlanningScreen.optimizeModal.noCluster')
    if (clusterWithoutVehicle) {
      return t('PlanningScreen.optimizeModal.elementShouldHaveVehicle', {
        element: t('PlanningScreen.cluster').toLowerCase(),
      })
    }
    if (areMTClusters) {
      return t('PlanningScreen.optimizeModal.emptyClustersMessage')
    }
  }

  const filteredClusters = clusters.filter((cluster) => cluster.visits.length !== 0)

  const getWarehouseOptimizationParam = async (warehouseId: string): Promise<void> => {
    toggleLoader(true)
    const response = await SitesApi.get(warehouseId)
    if (!isIError(response) && response.optimParams) {
      setOptimizationParams({
        maxClientsPerTour: undefined,
        ...response.optimParams,
      })
    } else if (isIError(response)) {
      openErrorSnack(response.error.message)
    }
    toggleLoader(false)
  }

  useEffect(() => {
    if (selectedPlan?.warehouseId) {
      getWarehouseOptimizationParam(selectedPlan?.warehouseId)
    }
    // If there are manualTours, make sure their vehicles are selected by default
    if (manualToursVehicleIds.length) {
      setSelectedVehiclesListIds(new Set(manualToursVehicleIds))
    } else {
      setSelectedVehiclesListIds(
        new Set(
          vehiclesListForGlobalOptim
            .filter((vehicle) => vehicle.isSelected)
            .map((vehicle) => vehicle.id as string),
        ),
      )
    }
    setVehiclesListForGlobalOptim(prev => removePastDatesFromVehiclesAvailabilities(prev, selectedPlan))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAccordionClick = (panel: string) =>
    (event: React.ChangeEvent<{}>, isExpanded: boolean): void => {
      setOpenedPanel(isExpanded ? panel : false)
    }

  const onVehicleClick = ({ id = '' }: IVehicle): void => {
    if (selectedVehiclesListIds.has(id)) {
      selectedVehiclesListIds.delete(id)
      setSelectedVehiclesListIds(new Set(selectedVehiclesListIds))
    } else {
      selectedVehiclesListIds.add(id)
      setSelectedVehiclesListIds(new Set(selectedVehiclesListIds))
    }
  }

  async function optimizeTour(): Promise<void> {
    if (!isClusterMode && selectedVehiclesListIds.size === 0) {
      setError(t('PlanningScreen.optimizeModal.errorNoVehicle'))
      return
    }
    if (!selectedPlan) {
      return
    }
    setIsLoading(true)
    const isOptimizing = await getOptimizeState()
    if (isOptimizing) {
      setIsLoading(false)
      return
    }
    setIsMapotempoActionInProgress(true)
    let res: IJob | IError
    if (isClusterMode) {
      if (isClusterOptimInError) setClusterOptimInError(false)
      const clustersVehicleIds = filteredClusters.map(cluster => cluster.vehicleIds).flat()
      res = await PlansApi.optimizeClusters(
        {
          planId: selectedPlan.id,
          clusters: filteredClusters,
          maxClientsPerTour: optimizationParams.maxClientsPerTour,
          optimParams: {
            ...optimizationParams,
          },
          vehiclesAvailabilities: findVehiclesWithModifiedAvailability(clustersVehicleIds, vehiclesList),
        },
        shouldUseJobsForOptimization,
      )
    } else {
      const isThereASelectedMarker = markers.findIndex((marker) => marker.selected) !== -1
      if (manualTours.length > 0) {
        if (isManualToursOptimInError) setManualToursOptimInError(false)
        const remainingPlanVisitIds = isThereASelectedMarker
          ? markers
            .filter(
              (marker) => !marker.tourId && marker.manualTourId === undefined && marker.selected,
            )
            .map((visit) => visit.stopId)
          : markers
            .filter((marker) => !marker.tourId && marker.manualTourId === undefined)
            .map((visit) => visit.stopId)

        const vehicleIds = optimizeRemainingPlanVisits
          ? Array.from(selectedVehiclesListIds)
          : manualToursVehicleIds

        const params: IOptimizePlanWithManualTours = {
          planId: selectedPlan.id,
          manualTours,
          planVisitIds: optimizeRemainingPlanVisits ? remainingPlanVisitIds : [],
          maxClientsPerTour: optimizationParams.maxClientsPerTour,
          optimParams: {
            ...optimizationParams,
          },
          vehicleIds,
          vehiclesAvailabilities: findVehiclesWithModifiedAvailability(
            vehicleIds,
            optimizeRemainingPlanVisits ? vehiclesListForGlobalOptim : vehiclesList,
          ),
        }
        res = isMultisite
          ? await PlansApi.optimizeMultisitePlanWithManualTours(
            params,
            shouldUseJobsForOptimization,
          )
          : await PlansApi.optimizePlanWithManualTours(params, shouldUseJobsForOptimization)
      } else {
        const planVisitIds = isThereASelectedMarker
          ? markers
            .filter((marker) => !marker.tourId && marker.selected)
            .map((visit) => visit.stopId)
          : markers.filter((marker) => !marker.tourId).map((visit) => visit.stopId)

        const vehicleIds = Array.from(selectedVehiclesListIds)

        const params: IOptimizePlan = {
          planId: selectedPlan.id,
          planVisitIds,
          maxClientsPerTour: optimizationParams.maxClientsPerTour,
          optimParams: {
            ...optimizationParams,
          },
          vehicleIds,
          vehiclesAvailabilities: findVehiclesWithModifiedAvailability(vehicleIds, vehiclesListForGlobalOptim),
        }

        res = isMultisite
          ? await PlansApi.optimizeMultisitePlan(params, shouldUseJobsForOptimization)
          : await PlansApi.optimizePlan(params, shouldUseJobsForOptimization)
      }
    }
    if (isIError(res)) {
      openErrorSnack(res.error.message)
      if (isClusterMode) setClusterOptimInError(true)
      else if (manualTours.length > 0) setManualToursOptimInError(true)
      // return // TODO manage optimize error
    } else {
      if (optimizationParams.maximumOptimizationTime) {
        setMaxOptimizationTime(optimizationParams.maximumOptimizationTime)
      } else setMaxOptimizationTime(0)
      if (res) {
        setJobIds((prev) => [...prev, (res as IJob).jobId])
      }
      toggleBackdrop(t(`PlanningScreen.optimizeRunning`), false, true)
    }
    setIsMapotempoActionInProgress(false)
    setIsLoading(false)
    toggleModal()
  }

  const handleCTAClick = (): void => {
    Sentry.captureEvent({
      message: SentryEvents.LaunchOptimization,
      level: Severity.Info,
      tags: {
        selectedPlan: selectedPlan?.label,
        selectedPlanId: selectedPlan?.id,
        warehouseCode: sites.find((site) => site.id === selectedPlan?.warehouseId)?.code,
        login: user?.login,
        tenant: user?.tenant,
        isMultisite,
        isClusterMode,
      },
    })

    setError(undefined)
    optimizeTour()
  }

  function onInputChange(label: string, value: boolean | number): void {
    setOptimizationParams((prev) => {
      const params = { ...prev }
      if (params.routerOptions && params.routerOptions[label] !== undefined) {
        params.routerOptions[label] = value
      } else {
        params[label] = value
      }
      return params
    })
  }

  const handleSelectAllVehiclesClick = (): void => {
    if (selectedVehiclesListIds.size === vehiclesListForGlobalOptim.length) {
      setSelectedVehiclesListIds(new Set(manualToursVehicleIds))
    } else {
      setSelectedVehiclesListIds(new Set(vehiclesListForGlobalOptim.map((vehicle) => vehicle.id as string)))
    }
  }

  return (
    <>
      <Modal
        open={isVisible}
        onClose={toggleModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <CustomPaper>
          <Typography variant="h5" data-cy="modal-description">
            {`${t(
              isClusterMode
                ? 'PlanningScreen.optimizeModal.optimisingClustersForPlan'
                : 'PlanningScreen.optimizeModal.optimisingPlan',
            )} ${selectedPlan?.label}`}
          </Typography>
          <Grid container>
            <AccordionsContainer bgColor={theme.palette.background.default}>
              {!isClusterMode
                && (manualTours.length === 0
                  || (manualTours.length > 0 && optimizeRemainingPlanVisits)) && (
                  <Accordion
                    expanded={openedPanel === 'vehicles'}
                    onChange={handleAccordionClick('vehicles')}
                    className={classes.accordion}
                    data-cy={t('PlanningScreen.optimizeModal.vehicles')}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography variant="h6">
                        {t('PlanningScreen.optimizeModal.vehicles')}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Paper
                        className={clsx(classes.paper, classes.listContainer, classes.vehiclesList)}
                      >
                        { vehiclesListForGlobalOptim.length <= 0 ? (
                          <Typography>{t('PlanningScreen.optimizeModal.noVehicle')}</Typography>
                        ) : (
                          <>
                            <ListItem
                              key="select-all"
                              divider
                              style={{ fontStyle: 'italic' }}
                              label={`${t('PlanningScreen.optimizeModal.selectAll')} (${
                                selectedVehiclesListIds.size
                              })`}
                              selected={selectedVehiclesListIds.size === vehiclesListForGlobalOptim.length}
                              onClick={handleSelectAllVehiclesClick}
                            />
                            {vehiclesListForGlobalOptim.map((vehicle) => (
                              <ListItem
                                key={vehicle.id}
                                label={vehicle.name}
                                selected={selectedVehiclesListIds.has(vehicle.id || '')}
                                startDate={vehicle.startDate}
                                endDate={vehicle.endDate}
                                onClick={(): void => onVehicleClick(vehicle)}
                                disabled={manualToursVehicleIds.includes(vehicle.id)}
                                siteId={vehicle.siteId}
                                capacity={vehicle.capacity}
                                id={vehicle.id}
                              />
                            ))}
                          </>
                        )}
                      </Paper>
                    </AccordionDetails>
                  </Accordion>
              )}
              <Accordion
                expanded={openedPanel === 'settings'}
                onChange={handleAccordionClick('settings')}
                className={classes.accordion}
                data-cy={t('PlanningScreen.optimizeModal.settings')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography variant="h6">{t('PlanningScreen.optimizeModal.settings')}</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
                  {!isClusterMode && manualTours.length > 0 && (
                    <SettingLine
                      dataCy="optimizeRemainingPlanVisits"
                      onInputChange={() => {
                        setOptimizeRemainingPlanVisits((prev) => !prev)
                      }}
                      label={t('optimizeRemainingPlanVisits')}
                      value={optimizeRemainingPlanVisits}
                    />
                  )}
                  {optimizationParams
                    && Object.entries(optimizationParams)
                      .filter(
                        (param) =>
                          param[0] !== 'routerOptions' && OPTIMIZATION_PARAMS.includes(param[0]),
                      )
                      .map((param) => (
                        <SettingLine
                          dataCy={param[0]}
                          onInputChange={onInputChange}
                          key={param[0]}
                          label={param[0]}
                          value={param[1]}
                        />
                      ))}
                  {/**  Disable this params for the moment */}
                  {/* {optimizationParams && optimizationParams.multiTourPermission && (
                    <SettingLine
                      dataCy="nbToursPerVehicle"
                      onInputChange={onInputChange}
                      key="nbToursPerVehicle"
                      label={t('nbToursPerVehicle')}
                      value={optimizationParams.nbToursPerVehicle}
                    />
                  )} */}
                  {optimizationParams
                    && optimizationParams.multiTourPermission
                    && Number(optimizationParams.nbToursPerVehicle) > 1 && (
                      <SettingLine
                        dataCy="delayBetweenTours"
                        onInputChange={onInputChange}
                        key="delayBetweenTours"
                        label={t('delayBetweenTours')}
                        value={optimizationParams.delayBetweenTours}
                      />
                  )}
                  {optimizationParams && optimizationParams.isMultisite && (
                    <SettingLine
                      dataCy="loadingTimePerOrderMultisite"
                      onInputChange={onInputChange}
                      key="loadingTimePerOrderMultisite"
                      label={t('loadingTimePerOrderMultisite')}
                      value={optimizationParams.loadingTimePerOrderMultisite}
                    />
                  )}
                  <Typography variant="subtitle1" style={{ margin: '16px 0 6px', fontWeight: 600 }}>
                    {t(`PlanningScreen.optimizeModal.routerOptions`)}
                  </Typography>
                  {optimizationParams
                    && optimizationParams.routerOptions
                    && Object.entries(optimizationParams.routerOptions)
                      .filter((option) => OPTIMIZATION_ROUTER_PARAMS.includes(option[0]))
                      .map((option) => (
                        <SettingLine
                          dataCy={option[0]}
                          onInputChange={onInputChange}
                          key={option[0]}
                          label={option[0]}
                          value={option[1]}
                        />
                      ))}
                </AccordionDetails>
              </Accordion>
            </AccordionsContainer>
            <ButtonsRow
              isLoading={isLoading}
              isDisabled={shouldDisablePlanOptimize || shouldDisableClustersOptimize || shouldDisableBecauseOfVehicleDateValidity}
              error={errorMessage()}
              toggleModal={toggleModal}
              onClickCTA={handleCTAClick}
              isOptimizePurpose
            />
          </Grid>
        </CustomPaper>
      </Modal>
    </>
  )
}
