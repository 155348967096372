import React, { useState } from 'react'
import { Grid, CardHeader, CardContent, Paper, Tabs, Tab, TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import useStyles from 'constants/cruStyles'
import { ITimeSlot, WeekDayName } from 'interfaces/IWarehouseTimeSlot'
import DateRangePicker from 'components/Inputs/DateRangePicker'
import { DateType } from 'constants/constants'
import { getSortedDays } from 'utils/functions'

interface IProps {
  timeSlot: ITimeSlot
}

const WarehouseTimeSlotDayDetails = ({ timeSlot }: IProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [currentTabDay, setCurrentTabDay] = useState<WeekDayName>(WeekDayName.Monday)
  const handleTabChange = (_event: React.ChangeEvent<{}>, newTabDay: WeekDayName): void => {
    setCurrentTabDay(newTabDay)
  }

  return (
    <Grid data-cy="timeSlotCard" item md={10} className={classes.infoContainer}>
      <Paper elevation={10}>
        <CardHeader
          title={t('SiteTimeSlotsScreen.timeSlots').toUpperCase()}
          titleTypographyProps={{
            variant: 'subtitle1',
            style: {
              fontWeight: 'bolder',
            },
          }}
          className={classes.header}
        />
        <CardContent className={classes.routingRulesCard}>
          <div className={classes.ruleTabsContainer}>
            <Tabs
              aria-label="rules tabs"
              value={currentTabDay}
              variant="scrollable"
              scrollButtons="auto"
              onChange={handleTabChange}
              className={classes.ruleTab}
            >
              {timeSlot
                && Object.keys(timeSlot).length > 0
                && getSortedDays()?.map((day) => (
                  <Tab
                    // eslint-disable-next-line react/no-array-index-key
                    key={`tab-${day}`}
                    label={t(`scheduler.${day}`)}
                    data-cy={t(`scheduler.${day}`)}
                    value={day}
                  />
                ))}
            </Tabs>
          </div>
          <div>
            {timeSlot
              && timeSlot[currentTabDay]?.map((timeSlotDay) => (
                <div className={classes.configurationItemsContainer}>
                  <DateRangePicker
                    startDate={timeSlotDay.beginTime.toString()}
                    endDate={timeSlotDay.endTime.toString()}
                    onChangeStart={(): void => {
                      // handleTimeChange(time, timeSlot.id, 'beginTime')
                    }}
                    onChangeEnd={(): void => {
                      // handleTimeChange(time, timeSlot.id, 'endTime')
                    }}
                    hideShiftPicker
                    dateType={DateType.Time}
                    isStartDisabled
                    isEndDisabled
                  />
                  <TextField
                    data-cy="availability"
                    className={classes.sideTextField}
                    label={t('SiteTimeSlotsScreen.maxOrder').toUpperCase()}
                    type="number"
                    name="availability"
                    disabled
                    value={timeSlotDay.availability !== undefined ? timeSlotDay.availability : ''}
                  />
                </div>
              ))}
          </div>
        </CardContent>
      </Paper>
    </Grid>
  )
}

export default WarehouseTimeSlotDayDetails
