import React from 'react'
import { useTranslation } from 'react-i18next'
import { FormControlLabel, Switch, TextField } from '@material-ui/core'
import { IWarehouseCartDropoffConfig } from 'interfaces/IWarehouseCartDropoffConfig'
import { IFieldChange } from 'interfaces/interfaces'
import { DestinationType } from 'constants/constants'
import { DestinationTypeFilter } from 'components/Inputs/ListFilter'
import { isDefined } from 'utils/functions'
import CountryAutocomplete, { ICountryValue } from 'components/Inputs/CountryAutocomplete'
import { IProps } from './OrderInfoConfig'
import { ConfigCard, FieldDetails } from './ConfigCard'
import useStyles from './styles'

export const AddressInfoConfig = ({ data, onChange }: IProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()

  const handleInputChange = (event: IFieldChange): void => {
    const { target } = event
    const { name } = target
    const value = target.type === 'checkbox' ? target.checked : target.value

    onChange(name as keyof IWarehouseCartDropoffConfig, value === '' ? undefined : value)
  }

  const handleCountryChange = (event: object, value: ICountryValue | null): void => {
    onChange('country', value?.label)
  }

  const handleSelectChange = (values: string[], name: string): void => {
    onChange(name as keyof IWarehouseCartDropoffConfig, values[0] || undefined)
  }

  const addressInfoFields: FieldDetails[] = [
    {
      name: 'destinationType',
      component: (
        <DestinationTypeFilter
          dataCy="destinationType"
          placeholder={t('CartDropoffConfigurationScreen.destinationType').toUpperCase()}
          ids={
            isDefined(data?.destinationType?.defaultValue)
              ? [data?.destinationType?.defaultValue as DestinationType]
              : []
          }
          handleChange={(values: string[]): void => handleSelectChange(values, 'destinationType')}
          onlyOneValue
          shouldFillWidth
          customFormClass={classes.fullWidth}
          removeMargin
          isUnselectAllowed={false}
        />
      ),
    },
    {
      name: 'country',
      component: (
        <CountryAutocomplete
          value={(data.country?.defaultValue as string) || ''}
          onChange={handleCountryChange}
          disableClearable
        />
      ),
      disabled: (data.country?.defaultValue as string || '').length === 0,
    },
    {
      name: 'additionInfo',
      label: t('CartDropoffConfigurationScreen.additionalAddressInfoSection').toUpperCase(),
      subSection: [
        {
          name: 'floor',
          component: (
            <TextField
              data-cy="floor"
              type="number"
              label={t('CartDropoffConfigurationScreen.floor').toUpperCase()}
              value={data.floor?.defaultValue ?? ''}
              name="floor"
              onChange={handleInputChange}
              fullWidth
            />
          ),
        },
        {
          name: 'hasElevator',
          component: (
            <FormControlLabel
              className={classes.switchContainer}
              control={(
                <Switch
                  color="primary"
                  checked={(data?.hasElevator?.defaultValue as boolean) ?? false}
                  name="hasElevator"
                  onChange={handleInputChange}
                />
              )}
              label={t('CartDropoffConfigurationScreen.hasElevator').toUpperCase()}
              labelPlacement="start"
            />
          ),
        },
        {
          name: 'digicode',
          component: (
            <TextField
              data-cy="digicode"
              type="text"
              label={t('CartDropoffConfigurationScreen.digicode').toUpperCase()}
              value={data.digicode?.defaultValue || ''}
              name="digicode"
              onChange={handleInputChange}
              fullWidth
            />
          ),
        },
        {
          name: 'deliveryInstructions',
          component: (
            <TextField
              data-cy="deliveryInstructions"
              type="text"
              label={t('CartDropoffConfigurationScreen.deliveryInstructions').toUpperCase()}
              value={data.deliveryInstructions?.defaultValue || ''}
              name="deliveryInstructions"
              onChange={handleInputChange}
              fullWidth
            />
          ),
        },
      ],
    },
    {
      name: 'enterprise',
      component: (
        <TextField
          data-cy="enterprise"
          type="text"
          label={t('CartDropoffConfigurationScreen.enterprise').toUpperCase()}
          value={data.enterprise?.defaultValue || ''}
          name="enterprise"
          onChange={handleInputChange}
          fullWidth
        />
      ),
    },
    {
      name: 'geocode',
      component: (
        <div className={classes.labelDiv}>
          {t('CartDropoffConfigurationScreen.geocode').toUpperCase()}
        </div>
      ),
    },
  ]

  return (
    <ConfigCard
      data={data}
      onChange={onChange}
      fields={addressInfoFields}
      sectionName={t('CartDropoffConfigurationScreen.cards.addressInfo')}
    />
  )
}
