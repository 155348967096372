import React, { useState, ReactNode, useContext } from 'react'
import moment from 'moment'

import { IWarehouse, ISiteFilters } from 'interfaces'
import { IError, isIError } from 'api/types'
import SitesApi from 'api/sites'
import SectorsApi from 'api/sectors'
import NotifierConfigurationsApi from 'api/notifierConfigurations'
import OptimizerAppsApi from 'api/optimizerApps/optimizerApps'
import { useTranslation } from 'react-i18next'
import { getErrorList } from 'utils/errorUtils'
import { COLUMS_MODEL_SITES_LIST, COLUMS_MODEL_SECTORS_LIST } from 'constants/table'
import { ISector, ITargetZoneSector } from 'interfaces/ISector'
import { ISiteReporting } from 'interfaces/ISiteReporting'
import { IOptimizerApp } from 'interfaces/IOptimizerApp'
import { IWarehouseCartDropoffConfig } from 'interfaces/IWarehouseCartDropoffConfig'
import CartDropoffApi from 'api/cartDropoff/cartDropoff'
import { FeedbackContext } from './FeedbackContext'

interface ISitesContext {
  getDetails: (id: string, asAdmin?: boolean) => void
  siteDetails: IWarehouse
  updateSuccess?: boolean
  createSuccess?: boolean
  updateSite: (id: string, site: IWarehouse, asAdmin?: boolean) => void
  createSite: (site: IWarehouse) => void
  sites: IWarehouse[]
  count: number
  getSitesList: (
    filters?: ISiteFilters,
    offset?: number,
    rowsPerPage?: number,
    sortField?: number,
    sortDirection?: string,
    isForPlanning?: boolean,
    isForAdmin?: boolean,
  ) => void
  sectors: ISector[]
  createSector: (sector: ISector) => Promise<IError | ISector>
  deleteSector: (sectorId: string) => void
  updateSector: (sectorId: string, sector: ISector) => Promise<void>
  createTargetZoneSector: (targetZoneSector: ITargetZoneSector) => Promise<void>
  getSiteReporting: (
    date: string,
    warehouseIds?: string[],
    offset?: number,
    rowsPerPage?: number,
  ) => Promise<void>
  siteReporting: ISiteReporting[]
  reportingCount: number
  optimizerApps: IOptimizerApp[]
  getOptimizerApps: () => Promise<void>
  cartDropoffConfig: IWarehouseCartDropoffConfig | null
  getCartDropoffConfig: (warehouseId: string) => Promise<void>
  updateCartDropoffConfig: (
    warehouseId: string,
    config: IWarehouseCartDropoffConfig,
  ) => Promise<void>
}

interface IProps {
  children: ReactNode
}

const SitesContext = React.createContext<ISitesContext>({} as ISitesContext)
const { Provider, Consumer } = SitesContext

const SitesProvider = ({ children }: IProps): JSX.Element => {
  const [siteDetails, setSitesDetails] = useState<IWarehouse>({} as IWarehouse)
  const [sectors, setSectors] = useState<ISector[]>([])
  const [updateSuccess, setUpdateSuccess] = useState<boolean | undefined>()
  const [createSuccess, setCreateSuccess] = useState<boolean | undefined>()
  const { t } = useTranslation()
  const [count, setCount] = useState<number>(0)
  const [reportingCount, setReportingCount] = useState<number>(0)
  const [sites, setSites] = useState<IWarehouse[]>([])
  const [siteReporting, setSiteReporting] = useState<ISiteReporting[]>([])
  const [optimizerApps, setOptimizerApps] = useState<IOptimizerApp[]>([])
  const [cartDropoffConfig, setCartDropoffConfig] = useState<IWarehouseCartDropoffConfig | null>(
    null,
  )
  const { openErrorSnack, openSuccessSnack, toggleLoader } = useContext(FeedbackContext)

  const getSitesList = async (
    filters?: ISiteFilters,
    offset?: number,
    rowsPerPage?: number,
    sortField?: number,
    sortDirection?: string,
    isForPlanning = false,
    isForAdmin = false,
  ): Promise<void> => {
    toggleLoader(true)
    const columnsModel = isForPlanning ? COLUMS_MODEL_SECTORS_LIST : COLUMS_MODEL_SITES_LIST
    const response = await SitesApi.getList(
      filters,
      offset,
      rowsPerPage,
      columnsModel.find((column, index) => index === sortField)?.field,
      sortDirection === 'desc' ? -1 : 1,
      isForAdmin,
    )
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      setSites(response.sites)
      setCount(response.count)
    }
    toggleLoader(false)
  }

  const getDetails = async (id: string, asAdmin = false): Promise<void> => {
    toggleLoader(true)
    const response = await SitesApi.get(id, asAdmin)
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      setSitesDetails(response as IWarehouse)
      if (response.sectors) {
        setSectors(response.sectors)
      }
    }
    toggleLoader(false)
  }

  const createSite = async (warehouse: IWarehouse): Promise<void> => {
    toggleLoader(true)
    const response = await SitesApi.create(warehouse)
    if (isIError(response)) {
      response.error.errorList = getErrorList(response)
      if (response.error.fieldErrors) {
        openErrorSnack(response.error.errorList.join('\n'))
      } else {
        openErrorSnack(response.error.message)
      }
    } else {
      setCreateSuccess(true)
      openSuccessSnack(t('SitesScreen.createdSite'))
    }
    toggleLoader(false)
  }

  const updateSite = async (id: string, warehouse: IWarehouse, asAdmin = false): Promise<void> => {
    toggleLoader(true)
    const response = await SitesApi.update(id, warehouse, asAdmin)
    if (isIError(response)) {
      response.error.errorList = getErrorList(response)
      if (response.error.fieldErrors) {
        openErrorSnack(response.error.errorList.join('\n'))
      } else {
        openErrorSnack(response.error.message)
      }
    } else {
      setUpdateSuccess(true)
      openSuccessSnack(t('SitesScreen.updatedSite'))
    }
    toggleLoader(false)
  }

  const createSector = async (sector: ISector): Promise<IError | ISector> => {
    toggleLoader(true)
    const response = await SectorsApi.create(sector)
    if (isIError(response)) {
      response.error.errorList = getErrorList(response)
      if (response.error.fieldErrors) {
        openErrorSnack(response.error.errorList.join('\n'))
      } else {
        openErrorSnack(response.error.message)
      }
    } else {
      setSectors((prevState) => [response, ...prevState])
      openSuccessSnack(t('SectorsScreen.sectorCreated'))
    }
    toggleLoader(false)
    return response
  }

  const deleteSector = async (sectorId: string): Promise<void> => {
    toggleLoader(true)
    const response = await SectorsApi.deleteSector(sectorId)
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      setSectors((prevState) => [...prevState.filter((sector) => sector.id !== sectorId)])
      openSuccessSnack(t('SectorsScreen.sectorDeleted'))
    }
    toggleLoader(false)
  }

  const updateSector = async (sectorId: string, sector: ISector): Promise<void> => {
    toggleLoader(true)
    const response = await SectorsApi.update(sectorId, sector)
    if (isIError(response)) {
      response.error.errorList = getErrorList(response)
      if (response.error.fieldErrors) {
        openErrorSnack(response.error.errorList.join('\n'))
      } else {
        openErrorSnack(response.error.message)
      }
    } else {
      setSectors((prevState) => [response, ...prevState.filter((elem) => elem.id !== sectorId)])
      openSuccessSnack(t('SectorsScreen.sectorUpdated'))
    }
    toggleLoader(false)
  }

  const createTargetZoneSector = async (targetZoneSector: ITargetZoneSector): Promise<void> => {
    toggleLoader(true)
    const response = await SectorsApi.createTargetZoneSector(targetZoneSector)
    if (isIError(response)) {
      response.error.errorList = getErrorList(response)
      if (response.error.fieldErrors) {
        openErrorSnack(response.error.errorList.join('\n'))
      } else {
        openErrorSnack(response.error.message)
      }
    } else {
      setSectors((prevState) => [response, ...prevState])
      openSuccessSnack(t('SectorsScreen.sectorCreated'))
    }
    toggleLoader(false)
  }

  const getSiteReporting = async (
    date: string,
    warehouseIds?: string[],
    offset?: number,
    rowsPerPage?: number,
  ): Promise<void> => {
    toggleLoader(true)
    const startDate = moment(date).startOf('month').toISOString()
    const endDate = moment(date).endOf('month').toISOString()
    const response = await SitesApi.getSiteReporting(
      startDate,
      endDate,
      warehouseIds,
      offset,
      rowsPerPage,
    )
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      const siteIds: string[] = []
      response.items.forEach((item) => {
        siteIds.push(item.siteId)
      })
      await completeSiteReporting(startDate, endDate, siteIds, response)
    }
    toggleLoader(false)
  }

  const completeSiteReporting = async (
    startDate: string,
    endDate: string,
    siteIds: string[],
    initialResponse: { items: ISiteReporting[]; count: number },
  ): Promise<void> => {
    const response = await NotifierConfigurationsApi.getNumberOfSMSSentPerSite(
      startDate,
      endDate,
      siteIds,
    )
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      setSiteReporting(
        initialResponse.items.map((item) => ({
          ...item,
          sentSMSCount: response.find((elem) => elem.siteId === item.siteId)?.sentSMSCount || 0,
        })),
      )
      setReportingCount(initialResponse.count)
    }
  }

  const getOptimizerApps = async (): Promise<void> => {
    const response = await OptimizerAppsApi.get()
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      setOptimizerApps(response)
    }
  }

  const getCartDropoffConfig = async (warehouseId: string): Promise<void> => {
    toggleLoader(true)
    const response = await CartDropoffApi.getCartDropoffConfig(warehouseId)
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      setCartDropoffConfig(response)
    }
    toggleLoader(false)
  }

  const updateCartDropoffConfig = async (
    warehouseId: string,
    config: IWarehouseCartDropoffConfig,
  ): Promise<void> => {
    toggleLoader(true)
    const response = await CartDropoffApi.updateCartDropoffConfig(warehouseId, config)
    if (isIError(response)) {
      response.error.errorList = getErrorList(response)
      if (response.error.fieldErrors) {
        openErrorSnack(response.error.errorList.join('\n'))
      } else {
        openErrorSnack(response.error.message)
      }
    } else {
      setCartDropoffConfig(response)
      openSuccessSnack(t('CartDropoffConfigurationScreen.updateSuccessful'))
    }
    toggleLoader(false)
  }

  return (
    <Provider
      value={{
        getDetails,
        siteDetails,
        updateSite,
        updateSuccess,
        createSite,
        createSuccess,
        sites,
        count,
        getSitesList,
        sectors,
        createSector,
        deleteSector,
        updateSector,
        createTargetZoneSector,
        getSiteReporting,
        siteReporting,
        reportingCount,
        optimizerApps,
        getOptimizerApps,
        cartDropoffConfig,
        getCartDropoffConfig,
        updateCartDropoffConfig,
      }}
    >
      {children}
    </Provider>
  )
}

export default SitesProvider

export { Consumer as SitesConsumer, SitesContext }
