import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import ImportJobsTable from 'components/Table/ImportJobsTable'
import ErrorModal from './ErrorModal'

const ImportJobsListScreen = (): JSX.Element => {
  const [isErrorsListModalOpened, setIsErrorsListModalOpened] = useState<boolean>(false)
  const [currentImportJobId, setCurrentImportJobId] = useState<string | undefined>()

  const query = new URLSearchParams(useLocation().search)
  const importJobIdToPreselect = query.get('importJobId')

  const handleErrorClick = (id: string): void => {
    setCurrentImportJobId(id)
    setIsErrorsListModalOpened(true)
  }

  useEffect(() => {
    if (importJobIdToPreselect) {
      handleErrorClick(importJobIdToPreselect)
    }
  }, [importJobIdToPreselect])

  return (
    <>
      <ImportJobsTable onErrorClick={handleErrorClick} />
      <ErrorModal
        key={currentImportJobId}
        id={currentImportJobId as string}
        isOpen={!!currentImportJobId && isErrorsListModalOpened}
        onClose={() => setIsErrorsListModalOpened(false)}
      />
    </>
  )
}

export default ImportJobsListScreen
