import React, { useContext, useEffect, useState } from 'react'
import FormAction from 'components/Button/FormAction'
import { IDisputeReasonCode } from 'interfaces/IDispute'
import { DisputeContext } from 'store/DisputeContext'
import DisputeReasonCodeCard from './DisputeReasonsCard'

const DisputeReasonsScreen = (): JSX.Element => {
  const [reasonCodes, setReasonCodes] = useState<IDisputeReasonCode[]>([])
  const { disputeReasonCodes, getDisputeReasonCodes, updateDisputeReasonCodes } = useContext(DisputeContext)
  useEffect(() => {
    getDisputeReasonCodes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setReasonCodes(disputeReasonCodes)
  }, [disputeReasonCodes])

  const handleCancelClick = (): void => {
    setReasonCodes(disputeReasonCodes)
  }

  const handleSaveClick = (): void => {
    updateDisputeReasonCodes(reasonCodes)
  }

  const handleDisputeReasonCodesChange = (changedReasonCodes: IDisputeReasonCode[]): void => {
    setReasonCodes(changedReasonCodes)
  }

  return (
    <>
      <DisputeReasonCodeCard
        disputeReasonCodes={reasonCodes}
        onDisputeReasonCodesChange={handleDisputeReasonCodesChange}
      />
      <FormAction onSaveClick={handleSaveClick} onCancelClick={handleCancelClick} />
    </>
  )
}

export default (): JSX.Element => <DisputeReasonsScreen />
