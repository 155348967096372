import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { FormControlLabel, Switch } from '@material-ui/core'
import { AppConfigContext } from 'store/AppConfigContext'

const SatViewSelectorContainer = styled.div`
  background: white;
  border: 1px solid #ccc;
  border-radius: 2px;
  color: black;
  float: right;
  margin: 10px 10px 0px 0px;
  padding: 2px 0px 2px 15px;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 800;
`

interface ISatViewSelectorProps {
  readonly mapName: string
}

function SatViewSelector({ mapName }: ISatViewSelectorProps): JSX.Element {
  const { t } = useTranslation()
  const { mapConfig, updateMapConfig } = useContext(AppConfigContext)
  const [activated, setActivated] = useState<boolean>(mapConfig?.includes(mapName))

  useEffect(() => {
    setActivated(mapConfig?.includes(mapName))
  }, [mapConfig]) // eslint-disable-line react-hooks/exhaustive-deps

  async function onChange() {
    await updateMapConfig(mapName, !activated)
  }

  return (
    <SatViewSelectorContainer>
      <FormControlLabel
        control={
          <Switch data-cy="satview-switch" checked={activated} onChange={onChange} size="small" />
        }
        label={t('MapScreen.satelliteView')}
      />
    </SatViewSelectorContainer>
  )
}

export default SatViewSelector
