import React from 'react'

import { COLUMNS_MODEL_TRAILERS_LIST } from 'constants/table'
import { ITrailer } from 'interfaces/ITrailer'
import TrailersTable from 'components/Table/TrailersTable'
import TrailersProvider, { TrailersConsumer } from 'store/TrailersContext'

interface IProps {
  trailers: ITrailer[]
}

const TrailersListScreen = ({ trailers }: IProps): JSX.Element => (
  <TrailersTable trailers={trailers} columns={COLUMNS_MODEL_TRAILERS_LIST} />
)

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <TrailersProvider>
    <TrailersConsumer>
      {(ctx): JSX.Element => <TrailersListScreen trailers={ctx.trailers} {...props} />}
    </TrailersConsumer>
  </TrailersProvider>
)
