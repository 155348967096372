import { ITextChange } from 'interfaces/interfaces'
import React, { useRef, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import { useTheme } from '@material-ui/core/styles'
import styled from 'styled-components'
import { useDebouncedCallback } from 'use-debounce'
import { useTranslation } from 'react-i18next'

interface ISearchInput {
  defaultValue?: string
  onSearch?: (string: string) => void
  isDisabled?: boolean
}

const MAX_WIDTH = 100

const CustomIconButton = styled(IconButton)`
  border-radius: 0;
`

function SearchInput({ defaultValue, onSearch, isDisabled }: ISearchInput): JSX.Element {
  const [width, setWidth] = useState<number>(defaultValue ? MAX_WIDTH : 0)
  const inputRef = useRef<HTMLInputElement>(null)
  const theme = useTheme()
  const { t } = useTranslation()

  const toggleSearchField = (): void => setWidth(width === MAX_WIDTH ? 0 : MAX_WIDTH)

  const onPressSearch = (): void => {
    if (inputRef && inputRef.current) {
      if (width === MAX_WIDTH) {
        inputRef.current.blur()
      } else {
        inputRef.current.focus()
      }
    }
    toggleSearchField()
  }

  const debouncedInputChange = useDebouncedCallback((searchedString: string) => {
    if (onSearch) onSearch(searchedString)
  }, 600)

  const onChangeText = (event: ITextChange): void =>
    debouncedInputChange.callback(event.target.value)

  return (
    <div style={{ minWidth: width === MAX_WIDTH ? 250 : 0, display: 'flex', direction: 'rtl' }}>
      <CustomIconButton
        data-cy="searchButton"
        onClick={onPressSearch}
        aria-label="Search"
        style={{
          borderRadius: '4px',
          backgroundColor: theme.color.backgroundCustomToolbar,
          color: theme.color.actionsCustomToolbar,
        }}
        disabled={isDisabled}
      >
        <SearchIcon />
      </CustomIconButton>
      <TextField
        inputProps={{
          style: {
            transition: 'padding 0.5s',
            padding: width === MAX_WIDTH ? undefined : 0,
          },
        }}
        data-cy="searchInput"
        type="text"
        onChange={onChangeText}
        defaultValue={defaultValue || ''}
        inputRef={inputRef}
        style={{
          transition: 'width 1s, opacity 0.5s',
          opacity: width === MAX_WIDTH ? 1 : 0,
          width: width === MAX_WIDTH ? '100%' : 0,
          direction: 'ltr',
        }}
        id="filled-basic"
        variant="filled"
        label={t('Filter.search')}
      />
    </div>
  )
}

export default SearchInput
