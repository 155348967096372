import moment from 'moment'

import { IError } from 'api/types'
import Api from 'services/api'
import { ICustomerRatingStats } from 'interfaces/ICustomerRatingStats'
import { ICommentStatsResult } from 'interfaces/IComments'
import { IOrderForDeliveryDistance } from 'interfaces/IOrders'
import { ICancellationStats } from 'interfaces/ICancellationStats'

const getCustomerRatingStats = async (
  startDate?: string,
  endDate?: string,
  deliveryTypes?: string[],
  warehouseIds?: string[],
  carrierIds?: string[],
  driverIds?: string[],
): Promise<ICustomerRatingStats | IError> => {
  try {
    const res = await Api.get('stats/customer-rating', {
      startDate: startDate
        ? moment(startDate).format('DD/MM/YYYY')
        : moment().add(1, 'days').subtract(30, 'weeks').format('DD/MM/YYYY'),
      endDate: endDate ? moment(endDate).format('DD/MM/YYYY') : moment().format('DD/MM/YYYY'),
      deliveryTypes,
      warehouseIds,
      carrierIds,
      driverIds,
      jetLag: new Date().getTimezoneOffset() / 60,
    })
    return res
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getCustomerCommentStats = async (
  startDate?: string,
  endDate?: string,
  grades?: string[],
  deliveryTypes?: string[],
  warehouseIds?: string[],
  carrierIds?: string[],
  driverIds?: string[],
  paginationOffset?: number,
  rowsPerPage?: number,
): Promise<ICommentStatsResult | IError> => {
  try {
    const res = await Api.get('stats/customer-comments', {
      startDate: startDate
        ? moment(startDate).format('DD/MM/YYYY')
        : moment().add(1, 'days').subtract(30, 'weeks').format('DD/MM/YYYY'),
      endDate: endDate ? moment(endDate).format('DD/MM/YYYY') : moment().format('DD/MM/YYYY'),
      grades,
      deliveryTypes,
      warehouseIds,
      carrierIds,
      driverIds,
      jetLag: new Date().getTimezoneOffset() / 60,
      offset: paginationOffset,
      limit: rowsPerPage,
    })
    return res
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getDeliveryDistanceReporting = async (
  deliveryTypes?: string[],
  transportTypes?: string[],
  warehouseIds?: string[],
  planStatuses?: string[],
  deliverySiteIds?: string[],
  zipCodes?: string[],
  minDay?: string,
  maxDay?: string,
): Promise<IOrderForDeliveryDistance[] | IError> => {
  try {
    const res = await Api.get('stats/delivery-distance', {
      minDay: minDay
        ? moment(minDay).format('DD/MM/YYYY')
        : moment().subtract(30, 'days').format('DD/MM/YYYY'),
      maxDay: maxDay ? moment(maxDay).format('DD/MM/YYYY') : undefined,
      deliveryTypes,
      transportTypes,
      zipCodes,
      warehouseIds,
      planStatuses,
      deliverySiteIds,
    })
    return res
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getTourCancellations = async (
  startDate: string,
  endDate: string,
  siteIds: string[],
  carrierIds?: string[],
  driverIds?: string[],
  cancellationReasonCodes?: string[],
): Promise<ICancellationStats | IError> => {
  try {
    const response = await Api.get('/stats/cancelled-tours-repartition-per-reason', {
      startDate: startDate ? new Date(startDate).toISOString() : undefined,
      endDate: endDate ? new Date(endDate).toISOString() : undefined,
      siteIds,
      carrierIds,
      driverIds,
      cancellationReasonCodes,
    })
    return response
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getStopCancellations = async (
  startDate: string,
  endDate: string,
  siteIds: string[],
  carrierIds?: string[],
  driverIds?: string[],
  cancellationReasonCodes?: string[],
): Promise<ICancellationStats | IError> => {
  try {
    const response = await Api.get('/stats/cancelled-stops-repartition-per-reason', {
      startDate: startDate ? new Date(startDate).toISOString() : undefined,
      endDate: endDate ? new Date(endDate).toISOString() : undefined,
      siteIds,
      carrierIds,
      driverIds,
      cancellationReasonCodes,
    })
    return response
  } catch (error) {
    return { error: { message: error.message } }
  }
}

export default {
  getCustomerRatingStats,
  getCustomerCommentStats,
  getDeliveryDistanceReporting,
  getTourCancellations,
  getStopCancellations,
}
