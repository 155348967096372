import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import moment from 'moment'
import {
  KeyboardDateTimePicker,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

import useStyles from 'components/NavigationLayout/styles'
import { DateType } from 'constants/constants'
import { getFormat, getMask } from 'utils/dateFormat'
import LogisticsShiftPicker from './LogisticsShiftPicker'

interface IDateRangeProps {
  startDate?: string | null
  endDate?: string | null
  maxStartDate?: string
  maxEndDate?: string
  hideShiftPicker?: boolean
  hideStartPicker?: boolean
  hideEndPicker?: boolean
  onChangeStart?: (date: MaterialUiPickersDate, id?: string) => void
  onChangeEnd?: (date: MaterialUiPickersDate, id?: string) => void
  onShiftChange?: (shift: string) => void
  dateType?: DateType
  startLabel?: string
  endLabel?: string
  id?: string
  isStartRequired?: boolean
  isEndRequired?: boolean
  isStartDisabled?: boolean
  isEndDisabled?: boolean
  isShiftPickerDisabled?: boolean
  shift?: string
  isFullWidth?: boolean
  startDataCy?: string
  endDataCy?: string
  isStartClearable?: boolean
  isEndClearable?: boolean
  customClass?: string
}

enum Type {
  Start,
  End,
}

interface IPickerProps {
  type: Type
}

export default ({
  startDate,
  endDate,
  onChangeStart,
  onChangeEnd,
  hideStartPicker = false,
  hideEndPicker = false,
  hideShiftPicker,
  maxStartDate,
  maxEndDate,
  dateType,
  startLabel,
  endLabel,
  id,
  isStartRequired,
  isEndRequired,
  isStartDisabled,
  isEndDisabled,
  isShiftPickerDisabled,
  onShiftChange,
  shift: initialShift,
  isFullWidth,
  startDataCy,
  endDataCy,
  isStartClearable,
  isEndClearable,
  customClass,
}: IDateRangeProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [shift, setShift] = useState<string | number>(initialShift || '')
  const [localStartDate, setLocalStartDate] = useState<string | null>(startDate || null)
  const [localEndDate, setLocalEndDate] = useState<string | null>(endDate || null)
  const [showDefaultStartValue, setShowDefaultStartValue] = useState<boolean>(false)
  const [showDefaultEndValue, setShowDefaultEndValue] = useState<boolean>(false)
  const [isStartOpen, setIsStartOpen] = useState<boolean>(false)
  const [isEndOpen, setIsEndOpen] = useState<boolean>(false)

  useEffect(() => {
    if (startDate !== localStartDate) {
      setLocalStartDate(startDate || null)
    }
    if (endDate !== localEndDate) {
      setLocalEndDate(endDate || null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate])

  const onShiftSelect = (selectedShift: string): void => {
    setShift(selectedShift)
    const splittedShift = selectedShift.split('-')
    const splittedBegin = splittedShift[0].trim().split(':')
    const splittedEnd = splittedShift[1].trim().split(':')
    const beginShiftHours = Number(splittedBegin[0])
    const endShiftHours = Number(splittedEnd[0])
    const beginShiftMinutes = Number(splittedBegin[1])
    const endShiftMinutes = Number(splittedEnd[1])
    const newStartDate = moment(localStartDate || startDate)
      .hours(beginShiftHours)
      .minutes(beginShiftMinutes)
    const newEndDate = moment(localEndDate || endDate)
      .hours(endShiftHours)
      .minutes(endShiftMinutes)
    setLocalStartDate(newStartDate.toISOString())
    setLocalEndDate(newEndDate.toISOString())
    if (onChangeStart) onChangeStart(newStartDate)
    if (onChangeEnd) onChangeEnd(newEndDate)
    if (onShiftChange) {
      onShiftChange(selectedShift)
    }
  }

  const handleStartChange = (date: MaterialUiPickersDate | null): void => {
    if (moment(date).isValid() || date === null) {
      setLocalStartDate(moment(date).seconds(0).milliseconds(0).toISOString())
      setShift('')
      if (onChangeStart) onChangeStart(moment(date).seconds(0).milliseconds(0), id)
    }
  }

  const handleEndChange = (date: MaterialUiPickersDate | null): void => {
    if (moment(date).isValid() || date === null) {
      setLocalEndDate(moment(date).seconds(59).milliseconds(999).toISOString())
      setShift('')
      if (onChangeEnd) onChangeEnd(moment(date).seconds(59).milliseconds(999), id)
    }
  }

  const handleStartOpen = (): void => {
    setShowDefaultStartValue(true)
    setIsStartOpen(true)
  }

  const handleStartClose = (): void => {
    setShowDefaultStartValue(false)
    setIsStartOpen(false)
  }

  const handleEndOpen = (): void => {
    setShowDefaultEndValue(true)
    setIsEndOpen(true)
  }

  const handleEndClose = (): void => {
    setShowDefaultEndValue(false)
    setIsEndOpen(false)
  }

  const pickerCommonProps = {
    id,
    inputVariant: 'outlined' as 'filled' | 'outlined' | 'standard' | undefined,
    format: getFormat(dateType),
    ampm: false,
    mask: getMask(dateType),
    className:
      customClass
      || clsx(classes.filtersMargin, isFullWidth ? classes.fullWidthMargin : classes.datePickerWidth),
    clearable: true,
  }

  const startPickerProps = {
    ...pickerCommonProps,
    label: startLabel || t('executionScreen.startDate'),
    required: isStartRequired,
    onChange: handleStartChange,
    'data-cy': startDataCy || 'startPicker',
    value:
      localStartDate || (showDefaultStartValue ? moment().hours(0).minutes(0).toISOString() : null),
    onOpen: handleStartOpen,
    open: isStartOpen,
    onClose: handleStartClose,
    disabled: isStartDisabled,
    clearable: isStartClearable !== undefined ? isStartClearable : false,
    maxDate: maxStartDate,
  }

  const endPickerProps = {
    ...pickerCommonProps,
    label: endLabel || t('executionScreen.endDate'),
    required: isEndRequired,
    onChange: handleEndChange,
    'data-cy': endDataCy || 'endPicker',
    value:
      localEndDate || (showDefaultEndValue ? moment().hours(23).minutes(59).toISOString() : null),
    onOpen: handleEndOpen,
    open: isEndOpen,
    onClose: handleEndClose,
    maxDate: maxEndDate,
    minDate: localStartDate || undefined,
    disabled: isEndDisabled,
    clearable: isEndClearable !== undefined ? isEndClearable : true,
  }

  const KeyboardPicker = (props): JSX.Element => {
    switch (dateType) {
      case DateType.Time:
        return <KeyboardTimePicker {...props} />
      case DateType.Date:
        return <KeyboardDatePicker {...props} />
      case DateType.DateTime:
        return <KeyboardDateTimePicker {...props} />
      default:
        return <KeyboardDateTimePicker {...props} />
    }
  }

  const Picker = ({ type }: IPickerProps): JSX.Element => {
    const props = type === Type.Start ? startPickerProps : endPickerProps
    return <KeyboardPicker {...props} />
  }

  return (
    <>
      {!hideStartPicker && <Picker type={Type.Start} />}
      {!hideEndPicker && <Picker type={Type.End} />}
      {!hideShiftPicker && (
        <LogisticsShiftPicker
          isDisabled={isShiftPickerDisabled}
          onShiftSelect={onShiftSelect}
          selectedShift={shift}
        />
      )}
    </>
  )
}
