import { useTranslation } from 'react-i18next'
import useStyles from 'constants/cruStyles'
import React, { useState } from 'react'
import { TextField } from '@material-ui/core'
import { ICarrier } from 'interfaces/ICarrier'
import ActiveSwitch from 'components/Inputs/ToggleSwitch'
import Card from 'components/Card/Card'
import { ITag } from 'interfaces/ITag'
import { Autocomplete } from '@material-ui/lab'

interface IProps {
  carrier?: ICarrier
  onProfileChange: Function
  tags: ITag[] | null
}

interface ITextChange {
  target: {
    value: string
    name: string
    type?: string
    checked?: boolean
  }
}

export default function ProfileCard({ carrier, onProfileChange, tags }: IProps): JSX.Element {
  const { t } = useTranslation()
  const classes = useStyles()
  const [state, setState] = useState<ICarrier>({
    name: carrier?.name,
    code: carrier?.code,
    active: carrier?.active,
    tags: carrier?.tags,
  })

  const handleInputChange = (event: ITextChange): void => {
    const { target } = event
    const { name } = target
    const value = target.type === 'checkbox' ? target.checked : target.value

    onProfileChange({ ...state, [name]: value })
    setState({ ...state, [name]: value })
  }

  const handleTagsChange = (event: object, value: ITag[]): void => {
    onProfileChange({ ...state, tags: value })
    setState({ ...state, tags: value })
  }

  return (
    <Card dataCy="profilCard" title={t('CarrierScreen.profile').toUpperCase()}>
      <>
        <div className={classes.fieldsContainer}>
          <TextField
            data-cy="label"
            error={state.name === ''}
            label={t('CarrierScreen.libelle').toUpperCase()}
            onChange={handleInputChange}
            type="text"
            name="name"
            required
            value={state.name || ''}
          />
          <TextField
            data-cy="code"
            error={state.code === ''}
            label={t('CarrierScreen.code').toUpperCase()}
            onChange={handleInputChange}
            type="text"
            name="code"
            required
            value={state.code || ''}
          />
          <Autocomplete
            multiple
            id="carrier-tags"
            autoComplete
            options={tags || []}
            getOptionLabel={(option) => option.label || ''}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={t('CarrierScreen.tags').toUpperCase()}
              />
            )}
            onChange={handleTagsChange}
            value={carrier?.tags || []}
            getOptionSelected={(option, value) => option.code === value.code}
          />
        </div>
        <ActiveSwitch
          dataCy="active"
          onChange={handleInputChange}
          name="active"
          checked={state.active}
          label={t('CarrierScreen.active').toUpperCase()}
        />
      </>
    </Card>
  )
}
