import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { IIdParam } from 'interfaces/IIdParam'
import WarehouseTimeSlotsApi from 'api/warehouseTimeSlots'
import { IOrderTimeSlot, ISelectedTimeSlot } from 'interfaces/IWarehouseTimeSlot'
import { FeedbackContext } from 'store/FeedbackContext'
import { isIError } from 'api/types'
import FormAction from 'components/Button/FormAction'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import OrdersApi from 'api/orders'
import { ContentContext } from 'store/ContentContext'
import { OrderPlanStatus } from 'constants/constants'
import { OrdersContext } from 'store/OrdersContext'
import { IOrderDetails, IAddressCoordinates } from 'interfaces/IOrders'
import TimeSlots from './components/TimeSlots'

interface IProps {
  isCreateOrderMode?: boolean
  warehouseId?: string
  coordinates?: IAddressCoordinates
  handleSave?: (selectedTimeSlot: ISelectedTimeSlot) => void
  handleClose?: () => void
}

interface ILocation {
  valid: {
    value: boolean
  }
}

const OrderBookingScreen = ({
  isCreateOrderMode,
  handleSave,
  handleClose,
  warehouseId,
  coordinates,
}: IProps): JSX.Element => {
  const { id } = useParams<IIdParam>()
  const { t } = useTranslation()
  const { push: navigateTo } = useHistory()
  const { openErrorSnack, toggleLoader, openSuccessSnack } = useContext(FeedbackContext)
  const { sites } = useContext(ContentContext)
  const location = useLocation()
  const valid = (location.state as ILocation) || undefined
  const { fetchOrderDetails } = useContext(OrdersContext)
  const [orderDetails, setOrderDetails] = useState<IOrderDetails>()
  const [orderTimeSlots, setOrderTimeSlots] = useState<IOrderTimeSlot[]>()
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<ISelectedTimeSlot>()
  const [pilotCanReserve, setPilotCanReserve] = useState<boolean>(true)

  useEffect(() => {
    if (!valid && !isCreateOrderMode) {
      // get order details
      fetchOrderDetails(id, (response) => setOrderDetails(response as IOrderDetails))
    } else {
      getTimeSlots()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (orderDetails && sites && !valid) {
      const orderSite = sites.find((site) => site.id === orderDetails.warehouseId)
      let adminCanReserveSlot = false
      if (orderSite) {
        adminCanReserveSlot = orderSite.appointmentEndCustomerPage?.canPilotReserveTimeSlots || false
      }

      if (
        adminCanReserveSlot
        && (orderDetails.planStatus === OrderPlanStatus.ToBook
          || orderDetails.planStatus === OrderPlanStatus.Unplanned)
      ) {
        getTimeSlots()
      } else {
        setPilotCanReserve(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetails, sites])

  async function getTimeSlots() {
    toggleLoader(true)
    let response
    if (isCreateOrderMode && warehouseId) {
      response = await WarehouseTimeSlotsApi.getTimeSlotsPerWarehouseOrSector(
        warehouseId,
        coordinates?.longitude,
        coordinates?.latitude,
      )
    } else {
      response = await WarehouseTimeSlotsApi.getTimeSlotsPerOrder(id)
    }
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      setOrderTimeSlots(response.timeSlots)
    }
    toggleLoader(false)
  }

  const canSave = (): boolean => !(orderTimeSlots && selectedTimeSlot)

  const handleSaveClick = async () => {
    if (isCreateOrderMode && handleSave && selectedTimeSlot) {
      handleSave(selectedTimeSlot)
    } else {
      toggleLoader(true)
      if (selectedTimeSlot) {
        const response = await OrdersApi.updateOrderAppointment(
          id,
          selectedTimeSlot?.beginTime,
          selectedTimeSlot?.endTime,
        )
        if (isIError(response)) {
          openErrorSnack(response.error.message)
        } else {
          setSelectedTimeSlot(undefined)
          setOrderTimeSlots([])
          openSuccessSnack(t('OrdersScreen.successfullyUpdated'))
          navigateTo(`${ROUTES_PATH.orderDetails}${id}`)
        }
      }
      toggleLoader(false)
    }
  }

  const handleCancel = () => {
    if (isCreateOrderMode && handleClose) {
      handleClose()
    } else {
      navigateTo(`${ROUTES_PATH.orderDetails}${id}`)
    }
  }

  if (pilotCanReserve) {
    return (
      <>
        <TimeSlots
          orderTimeSlots={orderTimeSlots}
          selectedTimeSlot={selectedTimeSlot}
          setSelectedTimeSlot={setSelectedTimeSlot}
        />
        <FormAction
          isSaveDisabled={canSave()}
          onSaveClick={handleSaveClick}
          onCancelClick={handleCancel}
        />
      </>
    )
  }
  return <h1>{t('OrdersScreen.booking.unauthorizedAccess')}</h1>
}
export default (props: JSX.IntrinsicAttributes & IProps): JSX.Element => (
  <OrderBookingScreen {...props} />
)
