import React, { useState, useEffect, useContext } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { CardContent, CardHeader, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'

import { IIdParam } from 'interfaces/IIdParam'
import { ICarrier } from 'interfaces/ICarrier'
import CarriersApi from 'api/carriers'
import { isIError } from 'api/types'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import { FeedbackContext } from 'store/FeedbackContext'
import { ICarrierWarehouse } from 'interfaces/interfaces'
import CustomCard from 'components/CustomCard/CustomCard'
import CardLine from 'components/CardLine/CardLine'
import Button from 'components/Button/CustomButton'
import useStyles from 'constants/cruStyles'
import { formatDateInterval, intervalContainsDate } from 'utils/libs/date-range'

export default (): JSX.Element => {
  const [carrier, setCarrier] = useState<ICarrier>()
  const [tabValue, setTabValue] = useState<number>(0)

  const history = useHistory()
  const { id } = useParams<IIdParam>()
  const { t } = useTranslation()
  const classes = useStyles()
  const { openErrorSnack, toggleLoader } = useContext(FeedbackContext)

  const getDetails = async (): Promise<void> => {
    toggleLoader(true)
    const res = await CarriersApi.getCarrierDetails(id)
    if (isIError(res)) {
      openErrorSnack(res.error.error.message)
    } else {
      setCarrier(res.carrier)
    }
    toggleLoader(false)
  }

  useEffect(() => {
    getDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const tabChanged = (e: unknown, value: number): void => {
    setTabValue(value)
  }

  const editClicked = (): void => {
    history.push({
      pathname: `${ROUTES_PATH.editCarrier}${id}`,
      state: carrier,
    })
  }

  const GeneralPanel = (): JSX.Element => (
    <Grid container spacing={2}>
      <Grid data-cy="profilCard" item xs={6}>
        <CustomCard
          cardTitle={t('CarrierScreen.profile').toUpperCase()}
          linePairs={[
            { label: t('CarrierScreen.libelle').toUpperCase(), value: carrier ? carrier.name : '' },
            { label: t('CarrierScreen.code').toUpperCase(), value: carrier ? carrier.code : '' },
            {
              label: t('CarrierScreen.tags').toUpperCase(),
              value: carrier?.tags?.map((tag) => tag.label)?.join(' - ') || '',
            },
            {
              label: t('CarrierScreen.active').toUpperCase(),
              value: carrier
                ? carrier.active
                  ? t('CarrierScreen.yes')
                  : t('CarrierScreen.no')
                : '',
            },
          ]}
        />
      </Grid>
      <Grid container data-cy="carrierReferenceCard" item xs={6} direction="column">
        <CardHeader
          title={t('CarrierScreen.reference').toUpperCase()}
          className={classes.headerDetails}
          titleTypographyProps={{ variant: 'subtitle1' }}
        />
        <CardContent className={classes.contentDetails}>
          <Grid item container direction="column">
            <Grid item>
              {carrier?.warehouses
                && carrier?.warehouses.map((warehouse) => generateCardLine(warehouse))}
            </Grid>
          </Grid>
        </CardContent>
      </Grid>
    </Grid>
  )

  return (
    <Grid container direction="column">
      <Grid item className={classes.detailHeaderContainer}>
        <h3 data-cy="carrierDetailDescription">{`${carrier ? carrier.name : ''}`}</h3>
        <Button dataCy="updateButton" onPress={editClicked}>
          {t('CarrierScreen.modify').toUpperCase()}
        </Button>
      </Grid>
      <Grid container>
        <Tabs
          value={tabValue}
          indicatorColor="primary"
          onChange={tabChanged}
          aria-label="detail sections"
          style={{ marginBottom: '10px' }}
        >
          <Tab data-cy="generalButton" label={t('CarrierScreen.general')} />
        </Tabs>
        <GeneralPanel />
      </Grid>
    </Grid>
  )
}

function generateCardLine(carrier: ICarrierWarehouse): JSX.Element {
  const { validityStartDate, validityEndDate } = carrier
  const value = formatDateInterval(validityStartDate, validityEndDate)
  const isActive = intervalContainsDate(new Date(), validityStartDate, validityEndDate)
  return <CardLine key={carrier.id} label={carrier.name} value={value} isDisabled={!isActive} />
}
