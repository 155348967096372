import React from 'react'
import { useTranslation } from 'react-i18next'

import CardDoubleLine from 'components/CardDoubleLine/CardDoubleLine'
import CustomCard from 'components/CustomCard/CustomCard'
import { dateDefaultFormat } from 'utils/dateFormat'
import { IOrderPlanVisit } from 'interfaces/IOrders'
import useStyles from '../../../OrderCategory/Details/components/styles'

type PlanVisitsCardProps = {
  planVisits: IOrderPlanVisit[]
  selectedPlanVisit?: IOrderPlanVisit
  setSelectedPlanVisit: (planVisit: IOrderPlanVisit) => void
}

export default ({
  planVisits,
  selectedPlanVisit,
  setSelectedPlanVisit,
}: PlanVisitsCardProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <CustomCard
      dataCy="visitsCard"
      cardTitle={t('OrdersScreen.planVisits')}
      contentClassName={classes.visitContent}
    >
      {planVisits.map((planVisit: IOrderPlanVisit) => (
        <CardDoubleLine
          key={planVisit.planVisitId}
          isSelected={planVisit.planVisitId === selectedPlanVisit?.planVisitId}
          onCardClick={(): void => setSelectedPlanVisit(planVisit)}
          firstLineLabel={t('OrdersScreen.planVisitNumber')}
          firstLineValue={planVisit.planVisitNumber}
          secondLineLabel={t('OrdersScreen.planedDate')}
          secondLineValue={dateDefaultFormat(planVisit.plannedDate)}
        />
      ))}
    </CustomCard>
  )
}
