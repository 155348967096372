import React, { useState, useEffect, useRef, useContext, useMemo } from 'react'
import MaterialTable, { MTableHeader } from 'material-table'
import { useParams } from 'react-router-dom'
import { VehiclesConsumer } from 'store/VehiclesContext'
import MUITableIcons from 'constants/MUITableIcons'
import { ITableColumn } from 'interfaces/interfaces'
import { scrollTop } from 'utils/functions'
import AppConfigProvider, { AppConfigContext } from 'store/AppConfigContext'
import Pagination from 'components/Table/Pagination'
import { getPageSize, savePageSize } from 'utils/localStorage'
import { getDisplayRowsCount } from 'utils/tableUtils'

import { IVehicleReports } from 'interfaces/IVehicleReportsList'
import { IIdParam } from 'interfaces/IIdParam'

interface ICustomTableProps {
  columns: ITableColumn[]
  reports: IVehicleReports[]
  count?: number
  getReports?: (
    id: string,
    offset?: number,
    rowsPerPage?: number,
    sortField?: number,
    sortDirection?: string,
  ) => void
}

const columnConfigName = 'vehicleReport'

function VehicleReportsTable({
  columns,
  reports,
  count,
  getReports,
  ...rest
}: ICustomTableProps): JSX.Element {
  const isInitialMount = useRef(true)
  const { id } = useParams<IIdParam>()

  const [paginationOffset, setPaginationOffset] = useState<number>(0)
  const [page, setPage] = useState<number>(0)

  const [sortDirection, setSortDirection] = useState<string>('desc')
  const [rowsPerPage, setRowsPerPage] = useState<number>(getPageSize)
  const [sortField, setSortField] = useState<number>(0)
  const { tablesConfig } = useContext(AppConfigContext)

  useEffect(() => {
    if (rowsPerPage !== getPageSize()) {
      savePageSize(rowsPerPage)
      scrollTop()
    }
    if (getReports) {
      getReports(id, 0, rowsPerPage, sortField, sortDirection)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage])

  useEffect(() => {
    if (getReports && !isInitialMount.current) {
      getReports(id, paginationOffset, rowsPerPage, sortField, sortDirection)
      scrollTop()
    } else {
      isInitialMount.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationOffset, sortDirection, sortField])

  const handleChangeRowsPerPage = (pageSize: number): void => {
    setPage(0)
    setRowsPerPage(pageSize)
    setPaginationOffset(0)
  }

  const handleOrderChange = (columnIndex: number): void => {
    setPaginationOffset(0)
    setPage(0)
    setSortDirection((prevSortDirection) => (prevSortDirection === 'asc' ? 'desc' : 'asc'))
    setSortField(columnIndex)
  }

  const displayRowsCount = useMemo(() => getDisplayRowsCount(reports), [reports])

  return (
    <>
      <MaterialTable
        key={displayRowsCount}
        data={reports}
        columns={columns.map(
          (col) =>
            ({
              ...col,
              hidden: tablesConfig[columnConfigName]?.includes(col.title),
            } as unknown as MaterialTable<object>),
        )}
        {...rest}
        options={{
          toolbar: false,
          pageSize: displayRowsCount,
          actionsColumnIndex: -1,
        }}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        icons={MUITableIcons}
        components={{
          Header: (props): JSX.Element => (
            <MTableHeader
              {...props}
              orderBy={sortField}
              onOrderChange={handleOrderChange}
              orderDirection={sortDirection}
            />
          ),
          // eslint-disable-next-line react/prop-types, @typescript-eslint/no-unused-vars
          Pagination: ({ classes, ...props }): JSX.Element => (
            <Pagination
              {...props}
              count={count}
              columnConfigName={columnConfigName}
              page={page}
              rowsPerPage={rowsPerPage}
              setPage={setPage}
              columns={columns}
              setPaginationOffset={setPaginationOffset}
            />
          ),
        }}
      />
    </>
  )
}

export default (props: JSX.IntrinsicAttributes & ICustomTableProps): JSX.Element => (
  <AppConfigProvider>
    <VehiclesConsumer>
      {(ctx): JSX.Element => (
        <VehicleReportsTable
          count={ctx.countVehicleReports}
          getReports={ctx.getReports}
          {...props}
        />
      )}
    </VehiclesConsumer>
  </AppConfigProvider>
)
