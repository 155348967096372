import React, { useEffect, useMemo, useContext, useState } from 'react'
import MaterialTable, { Action } from 'material-table'
import ArrowForward from '@material-ui/icons/ArrowForward'
import { useHistory } from 'react-router-dom'
import RefreshIcon from '@material-ui/icons/Refresh'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import { ITableColumn } from 'interfaces/interfaces'
import MUITableIcons from 'constants/MUITableIcons'
import Button from 'components/Button/CustomButton'
import AppConfigProvider, { AppConfigContext } from 'store/AppConfigContext'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import { ICostConfiguration } from 'interfaces/ICostConfiguration'
import { CostConfigurationsContext } from 'store/CostConfigurationsContext'
import { getPageSize, savePageSize } from 'utils/localStorage'
import { scrollTop } from 'utils/functions'
import { IconButton } from '@material-ui/core'
import { CarrierFilter } from 'components/Inputs/ListFilter'
import { Filter, FilterKey } from 'constants/filters'
import { FiltersContext } from 'store/FiltersContext'
import { getDisplayRowsCount } from 'utils/tableUtils'
import Pagination from './Pagination/Pagination'
import useStyles from './styles'

interface ICustomTableProps {
  columns: ITableColumn[]
  costConfigurations: ICostConfiguration[]
  count: number
}

const columnConfigName = 'costConfigurationsList'
const filterKey = FilterKey.costConfig

const CustomTable = ({
  columns,
  costConfigurations,
  count,
  ...rest
}: ICustomTableProps): JSX.Element => {
  const styles = useStyles()
  const history = useHistory()
  const { tablesConfig } = useContext(AppConfigContext)
  const { t } = useTranslation()
  const [paginationOffset, setPaginationOffset] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(getPageSize)
  const { getList } = useContext(CostConfigurationsContext)
  const { setFilter, resetFilters, filters } = useContext(FiltersContext)
  const carriers = filters[filterKey][Filter.carriers] as string[]

  useEffect(() => {
    if (rowsPerPage !== getPageSize()) {
      savePageSize(rowsPerPage)
      scrollTop()
    }
    if (getList) {
      getList(paginationOffset, rowsPerPage, carriers)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, carriers])

  useEffect(() => {
    if (getList) {
      getList(paginationOffset, rowsPerPage, carriers)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationOffset])

  const handleNewClick = (): void => {
    history.push(ROUTES_PATH.createCostConfig)
  }

  const handleDetailsClick = ({ id }: ICostConfiguration): void => {
    history.push(`${ROUTES_PATH.costConfigDetails}${id}`)
  }

  const resetLocalFilters = (): void => {
    resetFilters(filterKey)
  }

  const Filters = useMemo(
    (): JSX.Element => (
      <div className={clsx(styles.filterContainer)}>
        <div className={styles.filterLeftContainer}>
          <IconButton data-cy="initializeButton" onClick={resetLocalFilters}>
            <RefreshIcon />
          </IconButton>
          <CarrierFilter
            handleChange={(carrierValues: string[]): void => {
              setFilter(filterKey, Filter.carriers, carrierValues)
            }}
            ids={carriers}
            dataCy="carrierPicker"
          />
        </div>
        <div className={styles.filterRightContainer}>
          <Button dataCy="newButton" className={styles.newButton} onPress={handleNewClick}>
            {t('tablesEntries.new')}
          </Button>
        </div>
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [carriers, styles],
  )

  const handleChangeRowsPerPage = (pageSize: number): void => {
    setPage(0)
    setPaginationOffset(0)
    setRowsPerPage(pageSize)
  }

  const displayRowsCount = useMemo(
    () => getDisplayRowsCount(costConfigurations),
    [costConfigurations],
  )

  return (
    <>
      {Filters}
      <MaterialTable
        key={displayRowsCount}
        data={costConfigurations}
        columns={columns.map(
          (col) =>
            ({
              ...col,
              hidden: tablesConfig[columnConfigName]?.includes(col.title),
            } as unknown as MaterialTable<object>),
        )}
        {...rest}
        options={{
          toolbar: false,
          pageSize: displayRowsCount,
          actionsColumnIndex: -1,
        }}
        actions={[
          (data: ICostConfiguration): Action<ICostConfiguration> => ({
            icon: ArrowForward,
            tooltip: t('tablesEntries.seeDetails'),
            onClick: (): void => handleDetailsClick(data),
          }),
        ]}
        icons={MUITableIcons}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        components={{
          // eslint-disable-next-line react/prop-types, @typescript-eslint/no-unused-vars
          Pagination: ({ classes, ...props }): JSX.Element => (
            <Pagination
              {...props}
              columnConfigName={columnConfigName}
              columns={columns}
              count={count}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setPaginationOffset={setPaginationOffset}
            />
          ),
        }}
      />
    </>
  )
}

export default (props: ICustomTableProps): JSX.Element => (
  <AppConfigProvider>
    <CustomTable {...props} />
  </AppConfigProvider>
)
