import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import VehiclesProvider, { VehiclesConsumer } from 'store/VehiclesContext'
import { IVehicle } from 'interfaces/IVehicle'
import { IIdParam } from 'interfaces/IIdParam'

import useStyles from 'constants/cruStyles'
import VehicleReportsTable from 'components/Table/VehicleReportsTable'
import { COLUMNS_MODEL_VEHICLE_REPORTS } from 'constants/table'
import { IVehicleReports } from 'interfaces/IVehicleReportsList'

interface IProps {
  getDetails?: Function
  vehicleDetails: IVehicle
  reports: IVehicleReports[]
}

const VehicleReportsScreen = ({ vehicleDetails, getDetails, reports }: IProps): JSX.Element => {
  const { id } = useParams<IIdParam>()
  const classes = useStyles()

  useEffect(() => {
    if (id) {
      if (getDetails) getDetails(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <>
      <div className={classes.detailHeaderContainer}>
        <h3 data-cy="vehicleReportDescription">{`${vehicleDetails.name || ''}`}</h3>
      </div>
      <div className={classes.detailsContent}>
        <VehicleReportsTable columns={COLUMNS_MODEL_VEHICLE_REPORTS} reports={reports} />
      </div>
    </>
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <VehiclesProvider>
    <VehiclesConsumer>
      {(vehiclesCtx): JSX.Element => (
        <VehicleReportsScreen
          getDetails={vehiclesCtx.getDetails}
          vehicleDetails={vehiclesCtx.vehicleDetails}
          reports={vehiclesCtx.vehicleReports}
          {...props}
        />
      )}
    </VehiclesConsumer>
  </VehiclesProvider>
)
