import React from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import useStyles from 'constants/cruStyles'
import { TRANSPORT_TYPE_OPTIONS } from 'constants/constants'
import Select from './Select'

interface IProps {
  value?: string
  onChange: (value: string, name: string) => void
}

const TransportTypeSelect = ({ value, onChange }: IProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={clsx(classes.routingRuleFieldsContainer, classes.ruleDeliverySelect)}>
      <Select
        value={value}
        onChange={(newValue: string | number | null, name: string) => {
          onChange(newValue as string, name)
        }}
        options={TRANSPORT_TYPE_OPTIONS.map((option) => ({ value: option.id, label: option.name }))}
        label={t('RoutingRulesScreen.transport')}
        name="transportType"
        dataCy="transportPicker"
      />
    </div>
  )
}

export default TransportTypeSelect
