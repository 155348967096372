import React, { ReactNode, useContext } from 'react'
import { Route, Redirect, useLocation, useHistory } from 'react-router-dom'

import { isFeatureAvailable } from 'utils/isFeatureAvailable'
import { AuthContext, shouldUseLocalLogin } from 'store/AuthContext'
import { IUserData } from 'interfaces'
import { IFeature } from 'interfaces/IFeature'
import { checkUserRole } from 'utils/functions'
import { ROUTES_PATH } from './RoutesPath'

interface IProps {
  children: ReactNode
  path: string
  roles: string[] | undefined
  user?: IUserData
  isBetaMode?: boolean
  features?: IFeature[]
  exact?: boolean
  featureFlagName?: string
}

const checkFeatureAuthorization = (
  path: string,
  features?: IFeature[],
  isBetaMode?: boolean,
): boolean => {
  if (isBetaMode && features) {
    return isFeatureAvailable(features, { path })
  }
  return true
}

const RedirectToForbiddenScreen = (): JSX.Element => (
  <Redirect to={{ pathname: ROUTES_PATH.forbidden }} />
)

function PrivateRoute({
  children,
  roles,
  path,
  isBetaMode,
  exact,
  featureFlagName,
  ...rest
}: IProps): JSX.Element {
  const location = useLocation()
  const history = useHistory()
  const { user, features, isAuthLoading, isImpersonationLoading } = useContext(AuthContext)
  const queryParams = new URLSearchParams(location.search)

  const cleanURL = (urlToClean: string, params: URLSearchParams): string => {
    const url = new URL(urlToClean)
    params.delete('auth')
    url.search = params.toString()
    return url.toString()
  }

  if (user) {
    if (queryParams.has('auth')) {
      queryParams.delete('auth')
      location.search = queryParams.toString()
      history.replace({ search: queryParams.toString() })
    }
    return (
      <Route
        {...rest}
        exact={exact}
        render={() =>
          checkUserRole(user, roles)
          && checkFeatureAuthorization(featureFlagName || path, features, isBetaMode) ? (
              children
            ) : (
              <RedirectToForbiddenScreen />
            )}
      />
    )
  }

  const urlAuthQueryParam = queryParams.get('auth')

  if (urlAuthQueryParam) {
    if (!isAuthLoading) {
      window.location.replace(
        `${ROUTES_PATH.login}?auth=${urlAuthQueryParam}&redirect=${encodeURIComponent(
          cleanURL(window.location.href, queryParams),
        )}`,
      )
    }
    return <></>
  }
  if (!isAuthLoading) {
    if (shouldUseLocalLogin || isImpersonationLoading) {
      return <Redirect to={ROUTES_PATH.localLogin} />
    }
    window.location.assign(ROUTES_PATH.login)
    return <></>
  }
  return <Redirect to={{ pathname: ROUTES_PATH.localLogin, state: { from: location } }} />
}

export default PrivateRoute
