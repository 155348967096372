import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Grid,
  CardHeader,
  CardContent,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@material-ui/core'

import { IDataTransformer } from 'interfaces/interfaces'
import { AuthContext } from 'store/AuthContext'
import useStyles from 'constants/cruStyles'

interface IProps {
  onChange: (dataTransformer: IDataTransformer) => void
  name?: string
}

const DataTransformerCard = ({ name, onChange }: IProps): JSX.Element => {
  const { t } = useTranslation()
  const { user } = useContext(AuthContext)
  const dataTransformers = user?.tenantConfig.dataTransformers || []
  const classes = useStyles()

  const handleSelect = (event: React.ChangeEvent<{ value: unknown }>): void => {
    onChange(
      dataTransformers.find(
        (item) => item.name === (event.target.value as string),
      ) as IDataTransformer,
    )
  }

  return (
    <Grid item xs={10} className={classes.infoContainer}>
      <CardHeader
        title={t('WebhooksScreen.dataTransformer').toUpperCase()}
        className={classes.header}
        titleTypographyProps={{
          variant: 'subtitle1',
          style: {
            fontWeight: 'bolder',
          },
        }}
      />
      <CardContent className={classes.content}>
        <div className={classes.fieldsContainer}>
          <FormControl variant="outlined">
            <InputLabel>{t('WebhooksScreen.dataTransformer')}</InputLabel>
            <Select
              value={name || ''}
              onChange={handleSelect}
              label={t('WebhooksScreen.dataTransformer')}
              placeholder={t('WebhooksScreen.dataTransformer')}
              variant="outlined"
            >
              <MenuItem value={-1} disabled>
                {t('WebhooksScreen.dataTransformer')}
              </MenuItem>
              {dataTransformers.map((dataTransformerOption) => (
                <MenuItem key={dataTransformerOption.name} value={dataTransformerOption.name}>
                  {dataTransformerOption.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </CardContent>
    </Grid>
  )
}

export default DataTransformerCard
