import React, { useContext } from 'react'
import {
  ListItemIcon,
  Checkbox,
  ListItemText,
  IconButton,
  TableCell,
  TableRow,
  Paper,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { dateDefaultFormat } from 'utils/dateFormat'
import { getDeliveryTypesLabel, getTransportTypesName } from 'utils/tableUtils'
import { AuthContext } from 'store/AuthContext'
import { formatDeliveryTypes } from 'utils/functions'
import { TransportType } from 'constants/constants'

interface ICustomListItem {
  label?: string
  selected: boolean
  startDate: string
  endDate: string
  deliveryTypes: string[]
  transportTypes: TransportType[]
  onClick(): void
  onClickDelete(): Promise<void>
  dataCy: string
}

const CustomListItem = ({
  label,
  startDate,
  endDate,
  deliveryTypes,
  transportTypes,
  onClick,
  selected = false,
  onClickDelete,
  dataCy,
}: ICustomListItem): JSX.Element => {
  const { user } = useContext(AuthContext)
  const userDeliveryTypes = formatDeliveryTypes(user?.tenantConfig?.deliveryTypes)

  return (
    <TableRow component={Paper} onClick={onClick} data-cy={dataCy}>
      <TableCell>
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={selected}
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': label }}
          />
        </ListItemIcon>
      </TableCell>
      <TableCell>
        <ListItemText id={label} primary={label} />
      </TableCell>
      <TableCell>
        <ListItemText
          id={startDate}
          primary={`${dateDefaultFormat(new Date(startDate))} -> ${dateDefaultFormat(
            new Date(endDate),
          )}`}
        />
      </TableCell>
      <TableCell>
        <ListItemText
          id={deliveryTypes.toString()}
          primary={getDeliveryTypesLabel(deliveryTypes, userDeliveryTypes)}
        />
      </TableCell>
      <TableCell>
        <ListItemText
          id={transportTypes.toString()}
          primary={getTransportTypesName(transportTypes)}
        />
      </TableCell>
      <TableCell>
        <IconButton
          edge="end"
          aria-label="Delete plan"
          onClick={(e): void => {
            e.stopPropagation()
            onClickDelete()
          }}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

export default CustomListItem
