import React from 'react'
import ExploreTwoToneIcon from '@material-ui/icons/ExploreTwoTone'
import ExploreIcon from '@material-ui/icons/Explore'
import ExploreOffIcon from '@material-ui/icons/ExploreOff'
import MapIcon from '@material-ui/icons/Map'
import { useTranslation } from 'react-i18next'

import DisplayModeFabMenu from '../DisplayModeFabMenu'

export enum ScreenMode {
  MapAndListSummary,
  MapAndList,
  List,
  Map,
  __LENGTH,
}

interface IFabMenu {
  displayMode: ScreenMode
  toggleDisplayMode: () => void
}

const displayModeIcons = {
  [ScreenMode.Map]: MapIcon,
  [ScreenMode.MapAndListSummary]: ExploreTwoToneIcon,
  [ScreenMode.MapAndList]: ExploreIcon,
  [ScreenMode.List]: ExploreOffIcon,
}

const FabMenu = ({ displayMode, toggleDisplayMode }: IFabMenu): JSX.Element => {
  const { t } = useTranslation()

  const DisplayModeIcon = displayModeIcons[displayMode]

  // The first entry is displayed at bottom
  const actions = [
    {
      name: t('MapScreen.displayMode'),
      icon: <DisplayModeIcon />,
      action: toggleDisplayMode,
    },
  ]

  return <DisplayModeFabMenu actions={actions} />
}

export default FabMenu
