import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField } from '@material-ui/core'

import useStyles from 'constants/cruStyles'
import Card from 'components/Card/Card'
import { IExecutionTrackingScreenConfig } from 'interfaces/interfaces'

interface IInputChange {
  target: {
    value: string
    name: string
  }
}

interface IProps {
  executionTrackingScreen?: Partial<IExecutionTrackingScreenConfig>
  onChange: (configs: IExecutionTrackingScreenConfig) => void
}

const WarehouseExecutionTrackingScreenCard = ({
  executionTrackingScreen,
  onChange,
}: IProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [config, setConfig] = useState<Partial<IExecutionTrackingScreenConfig>>({
    delayAlertOnTourBeforeDeparture: executionTrackingScreen?.delayAlertOnTourBeforeDeparture,
  })

  const getInputValue = (event: IInputChange) => {
    const { target } = event
    return target.value ? Number(target.value) : undefined
  }

  const handleChange = (event: IInputChange): void => {
    const { target } = event
    const { name } = target

    const value = getInputValue(event)

    onChange({ ...config, [name]: value } as IExecutionTrackingScreenConfig)
    setConfig({ ...config, [name]: value })
  }

  return (
    <Card title={t('SitesScreen.executionTrackingScreen').toUpperCase()}>
      <div className={classes.fieldsContainer}>
        <div>
          <TextField
            label={t(
              'SitesScreen.executionTrackingScreenConfigs.delayAlertOnTourBeforeDeparture',
            ).toUpperCase()}
            type="number"
            InputProps={{
              inputProps: { min: 0 },
            }}
            onChange={handleChange}
            value={config.delayAlertOnTourBeforeDeparture}
            name="delayAlertOnTourBeforeDeparture"
            fullWidth
          />
        </div>
      </div>
    </Card>
  )
}

export default WarehouseExecutionTrackingScreenCard
