import React from 'react'
import { useTranslation } from 'react-i18next'

import CardDoubleLine from 'components/CardDoubleLine/CardDoubleLine'
import CustomCard from 'components/CustomCard/CustomCard'
import { dateDefaultFormat } from 'utils/dateFormat'
import { IVisit } from 'interfaces/IOrders'
import { TransportType } from 'constants/constants'
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import useStyles from './styles'

type VisitCardProps = {
  visits: IVisit[]
  selectedVisit?: IVisit
  setSelectedVisit: (visit: IVisit) => void
}

export default ({ visits, selectedVisit, setSelectedVisit }: VisitCardProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <CustomCard
      dataCy="visitsCard"
      cardTitle={t('OrdersScreen.visits')}
      contentClassName={classes.visitContent}
    >
      {visits.map((visit: IVisit) => (
        <CardDoubleLine
          key={visit.visitId}
          isSelected={visit.visitId === selectedVisit?.visitId}
          onCardClick={(): void => setSelectedVisit(visit)}
          firstLineLabel={t('OrdersScreen.visitNumber')}
          firstLineValue={visit.label}
          secondLineLabel={t('OrdersScreen.planedDate')}
          secondLineValue={dateDefaultFormat(visit.planedArrival)}
          Icon={
            visit.transportType === TransportType.Delivery ? (
              <LocalShippingIcon />
            ) : (
              <LocalShippingOutlinedIcon />
            )
          }
          iconTooltip={
            visit.transportType === TransportType.Delivery
              ? t('OrdersScreen.transportTypes.delivery')
              : t('OrdersScreen.transportTypes.pickup')
          }
        />
      ))}
    </CustomCard>
  )
}
