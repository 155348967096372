import i18n from 'i18next'

import { ROLES_WITH_ASSOCIATED_SITES } from 'constants/constants'
import { DefaultDeliveryTypeCode, IDeliveryType } from 'interfaces/IDeliveryType'
import {
  IApplicationVersion,
  ICarrier,
  ICarrierWarehouse,
  IUserData,
  IWarehouse,
} from 'interfaces/interfaces'
import { WeekDayName } from 'interfaces/IWarehouseTimeSlot'
import { IDailyStats } from 'interfaces/IDailyStats'
import { IDailyAvgStats } from 'interfaces/IAvgStatsTours'
import { CartDropoffConfigField } from 'interfaces/IWarehouseCartDropoffConfig'
import { intervalContainsDate } from './libs/date-range'

interface IContent {
  name: string
}

export const alphabeticSort = (a: IContent, b: IContent): number => a.name.localeCompare(b.name)

export const dateDescendingSort = (a: Date, b: Date): number => (a > b ? -1 : 1)

export const convertToCamelCase = (hyphenSeparated: string): string =>
  hyphenSeparated.replace(/-([a-z])/gi, (_match, group1) => group1.toUpperCase())

export const scrollTop = (): void =>
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })

export const checkRoleWithSite = (roles: string[] = []): boolean =>
  ROLES_WITH_ASSOCIATED_SITES.some((role) => roles?.includes(role))

export function isValidJson(stringJson: string): boolean {
  try {
    const parsed = JSON.parse(stringJson)
    if (parsed && typeof parsed === 'object') {
      return true
    }
  } catch {
    return false
  }
  return false
}

export const getSortedDays = (): string[] => [
  WeekDayName.Monday,
  WeekDayName.Tuesday,
  WeekDayName.Wednesday,
  WeekDayName.Thursday,
  WeekDayName.Friday,
  WeekDayName.Saturday,
  WeekDayName.Sunday,
]

/*
  Check if the user can see the screen if there's a role rule in route config
  If no roles are set in the route config, the page is accessible by all auth users
*/
export function checkUserRole(user: IUserData, roles: string[] | undefined): boolean {
  if (roles && roles.length > 0 && user && user.roles) {
    if (roles.filter((value) => user.roles.includes(value)).length > 0) {
      return true
    }
    return false
  }
  return true
}

// This function translates the labels of the pre-defined delivery types
export function formatDeliveryTypes(deliveryTypes?: IDeliveryType[]): IDeliveryType[] {
  return (
    deliveryTypes?.map((deliveryType) => {
      if (
        deliveryType.code === DefaultDeliveryTypeCode.CustomerSite
        || deliveryType.code === DefaultDeliveryTypeCode.HubSpot
        || deliveryType.code === DefaultDeliveryTypeCode.Mixed
      ) {
        return {
          ...deliveryType,
          label: i18n.t(`tablesEntries.deliveryType.${deliveryType.code}`),
        }
      }
      return deliveryType
    }) || []
  )
}

export const isDefined = (toCheck: unknown): boolean => toCheck !== null && toCheck !== undefined

export const isDefinedAndNotEmpty = (toCheck: unknown): boolean =>
  toCheck !== null && toCheck !== undefined && toCheck !== ''

const preserveZeroForCsvCell = (cellContent: string): string => `${cellContent}`

export const exportCsvFromArray = (rows: string[][], fileName: string): void => {
  const csvContent = rows
    .map((e) => e.map((cell) => preserveZeroForCsvCell(cell)).join(';'))
    .join('\n')
  const encodedUri = `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURI(csvContent)}`
  exportFile(encodedUri, fileName)
}

export const exportFile = (content: string, fileName?: string): void => {
  const anchor = document.createElement('a')
  document.body.appendChild(anchor)
  if (fileName) {
    anchor.download = fileName
  }
  anchor.target = '_blank'
  anchor.href = content
  anchor.click()
  anchor.remove()
}

export const isWarehouseMultisite = (warehouseId: string, warehouses: IWarehouse[]): boolean => {
  const warehouse = warehouses.find((elem) => elem.id === warehouseId)
  return warehouse?.optimParams?.isMultisite || false
}

export function sumTotalKms(totalKmStats: IDailyStats[]): string {
  let total = 0
  totalKmStats.forEach((dailyStats) => {
    Object.keys(dailyStats).forEach((key) => {
      if (key !== 'day') {
        total += parseFloat(dailyStats[key].toString())
      }
    })
  })
  return total.toFixed(2)
}

export function addMissingZeros(averages: IDailyAvgStats[]): IDailyAvgStats[] {
  const averagesWithZeros: IDailyAvgStats[] = []

  averages.forEach((dailyStats) => {
    const dailyAvgWithZeros: IDailyAvgStats = {} as IDailyAvgStats
    Object.keys(dailyStats).forEach((key) => {
      dailyAvgWithZeros[key] = dailyStats[key]
    })
    if (Object.keys(dailyStats).length === 1) {
      dailyAvgWithZeros.avgKmByVisit = 0
      dailyAvgWithZeros.avgNbVisitsByTour = 0
      dailyAvgWithZeros.avgTimeByVisit = 0
    }
    averagesWithZeros.push(dailyAvgWithZeros)
  })

  return averagesWithZeros
}

export function addFirstCarrierKey(averages: IDailyStats[], firstCarrierId: string): IDailyStats[] {
  // Adding the legend's first carrier key as a workaround to solve the bar chart library's problem
  // (displays the order of dates according to the carriers they have)

  const averagesWithZeros: IDailyStats[] = []

  averages.forEach((dailyStats) => {
    const dailyAvgWithZeros: IDailyStats = {} as IDailyStats
    Object.keys(dailyStats).forEach((key) => {
      dailyAvgWithZeros[key] = dailyStats[key]
    })
    if (!dailyStats[firstCarrierId]) {
      dailyAvgWithZeros[firstCarrierId] = 0
    }
    averagesWithZeros.push(dailyAvgWithZeros)
  })

  return averagesWithZeros
}

export function checkDomainAndRedirect(domainToReplace: string | undefined): void {
  const actualDomain = window.location.host
  if (domainToReplace && actualDomain !== domainToReplace) {
    const redirectPath = window.location.href.replace(actualDomain, domainToReplace)
    window.location.replace(redirectPath)
  }
}

export function getApplicationVersionValue(appVersion: IApplicationVersion): string {
  return `${appVersion.name} | ${appVersion.version}`
}

const isValidCarrierForSite = (carrierWarehouse: ICarrierWarehouse, siteId?: string): boolean =>
  carrierWarehouse.id === siteId
  && intervalContainsDate(
    new Date(),
    carrierWarehouse.validityStartDate,
    carrierWarehouse.validityEndDate,
  )

export const getValidCarriers = (carriers: ICarrier[], siteId?: string): ICarrier[] => {
  const carrierList = carriers || []
  const activeCarriers = carrierList.filter((carrier) => carrier.active)
  const filteredCarriersList = activeCarriers.filter((carrier) =>
    carrier.warehouses.find((element) => isValidCarrierForSite(element, siteId)),
  )
  return filteredCarriersList
}

export const shouldDisplayOrderFormField = (
  isCartDropoff: boolean,
  field?: CartDropoffConfigField,
): boolean => !isCartDropoff || (isCartDropoff && field?.active) || false
