import React from 'react'
import { COLUMNS_MODEL_PLAN_TEMPLATE } from 'constants/table'
import { IPlanTemplate } from 'interfaces/IPlanTemplate'
import PlanTemplateTable from 'components/Table/PlanTemplateTable'
import PlanTemplatesProvider, { PlanTemplatesConsumer } from 'store/PlanTemplatesContext'
import { FilterKey } from 'constants/filters'

interface IProps {
  planTemplates: IPlanTemplate[]
}

const PlanTemplatesListScreen = ({ planTemplates }: IProps): JSX.Element => (
  <PlanTemplateTable
    planTemplates={planTemplates}
    columns={COLUMNS_MODEL_PLAN_TEMPLATE}
    filterKey={FilterKey.templatePlans}
  />
)

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <PlanTemplatesProvider>
    <PlanTemplatesConsumer>
      {(ctx): JSX.Element => (
        <PlanTemplatesListScreen planTemplates={ctx.planTemplates} {...props} />
      )}
    </PlanTemplatesConsumer>
  </PlanTemplatesProvider>
)
