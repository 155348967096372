import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormControlLabel, Switch, TextField } from '@material-ui/core'

import useStyles from 'constants/cruStyles'
import Card from 'components/Card/Card'
import { IDeliveryInformationEndCustomerPageConfig } from 'interfaces/interfaces'

interface IInputChange {
  target: {
    value: string
    name: string
    type?: string
    checked?: boolean
  }
}

interface IProps {
  deliveryInformationEndCustomerPage?: Partial<IDeliveryInformationEndCustomerPageConfig>
  onChange: (configs: IDeliveryInformationEndCustomerPageConfig) => void
}

const WarehouseDeliveryInformationEndCustomerPageCard = ({
  deliveryInformationEndCustomerPage,
  onChange,
}: IProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [config, setConfig] = useState<Partial<IDeliveryInformationEndCustomerPageConfig>>({
    deliverySlotAmplitude: deliveryInformationEndCustomerPage?.deliverySlotAmplitude,
    message: deliveryInformationEndCustomerPage?.message,
    showElevator: deliveryInformationEndCustomerPage?.showElevator || false,
    showFloor: deliveryInformationEndCustomerPage?.showFloor || false,
    showInstructions: deliveryInformationEndCustomerPage?.showInstructions || false,
  })

  const getInputValue = (event: IInputChange) => {
    const { target } = event

    switch (target.type) {
      case 'checkbox':
        return target.checked
      case 'number':
        return target.value ? Number(target.value) : undefined
      default:
        return target.value
    }
  }

  const handleChange = (event: IInputChange): void => {
    const { target } = event
    const { name } = target

    const value = getInputValue(event)

    onChange({ ...config, [name]: value } as IDeliveryInformationEndCustomerPageConfig)
    setConfig({ ...config, [name]: value })
  }

  return (
    <Card title={t('SitesScreen.deliveryInformationEndCustomerPage').toUpperCase()}>
      <div className={classes.fieldsContainer}>
        <div>
          <TextField
            label={t(
              'SitesScreen.deliveryInformationEndCustomerPageConfigs.deliverySlotAmplitude',
            ).toUpperCase()}
            type="number"
            onChange={handleChange}
            value={config.deliverySlotAmplitude}
            name="deliverySlotAmplitude"
            fullWidth
          />
        </div>
        <div>
          <TextField
            label={t('SitesScreen.deliveryInformationEndCustomerPageConfigs.message').toUpperCase()}
            type="text"
            onChange={handleChange}
            value={config.message}
            name="message"
            fullWidth
          />
        </div>
        <div>
          <FormControlLabel
            className={classes.switchContainer}
            control={(
              <Switch
                color="primary"
                checked={config.showInstructions}
                onChange={handleChange}
                name="showInstructions"
              />
            )}
            labelPlacement="start"
            label={t(
              'SitesScreen.deliveryInformationEndCustomerPageConfigs.showInstructions',
            ).toUpperCase()}
          />
        </div>
        <div>
          <FormControlLabel
            className={classes.switchContainer}
            control={(
              <Switch
                color="primary"
                checked={config.showFloor}
                onChange={handleChange}
                name="showFloor"
              />
            )}
            labelPlacement="start"
            label={t(
              'SitesScreen.deliveryInformationEndCustomerPageConfigs.showFloor',
            ).toUpperCase()}
          />
        </div>
        <div>
          <FormControlLabel
            className={classes.switchContainer}
            control={(
              <Switch
                color="primary"
                checked={config.showElevator}
                onChange={handleChange}
                name="showElevator"
              />
            )}
            labelPlacement="start"
            label={t(
              'SitesScreen.deliveryInformationEndCustomerPageConfigs.showElevator',
            ).toUpperCase()}
          />
        </div>
      </div>
    </Card>
  )
}

export default WarehouseDeliveryInformationEndCustomerPageCard
