import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Circle, CircleMarker, FeatureGroup } from 'react-leaflet'
import { LatLng, LatLngBounds, latLngBounds, LatLngLiteral } from 'leaflet'
import lodash from 'lodash'

import { OrdersConsumer } from 'store/OrdersContext'
import { IOrderForDeliveryDistance } from 'interfaces/IOrders'
import { Filter, FilterKey } from 'constants/filters'
import OrdersFilters from 'screens/ReportingCategory/components/OrdersFilters'
import Map from 'components/Map'
import AppConfigProvider from 'store/AppConfigContext'
import { FiltersContext } from 'store/FiltersContext'
import { ContentContext } from 'store/ContentContext'
import LinesColors from 'components/Map/LinesColors'
import useStyles from './styles'
import WarehouseMarker from './WarehouseMarker'

interface IDeliveryDistanceIndicatorProps {
  deliveryDistanceReporting: IOrderForDeliveryDistance[]
  getDeliveryDistanceReporting: (
    deliveryTypes?: string[],
    transportTypes?: string[],
    warehouseIds?: string[],
    planStatuses?: string[],
    deliveryWarehouseIds?: string[],
    zipCodes?: string[],
    minDay?: string,
    maxDay?: string,
  ) => void
}

interface ISite {
  id: string
  name: string
  color: string
}

interface ISitePosition extends ISite {
  position: LatLngLiteral
}

interface INumberOfMarkersInCirlce extends ISite {
  numberOfMarkers: number
}

const filterKey = FilterKey.deliveryDistance

const MAX_NUMBER_OF_ORDERS_MARKERS = 10000

const DeliveryDistanceIndicator = ({
  deliveryDistanceReporting,
  getDeliveryDistanceReporting,
}: IDeliveryDistanceIndicatorProps): JSX.Element => {
  const [mapBounds, setMapBounds] = useState<LatLngBounds>(latLngBounds([]))
  const { t } = useTranslation()
  const classes = useStyles()
  const { filters } = useContext(FiltersContext)
  const { sites } = useContext(ContentContext)
  const distance = filters[filterKey][Filter.distance] as number
  const siteIds = filters[filterKey][Filter.sites] as string[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const featureGroupRef = useRef<any>(null)

  const sitePositions: ISitePosition[] = useMemo((): ISitePosition[] => {
    const res: ISitePosition[] = []
    const possibleColors: string[] = [...LinesColors]
    possibleColors.splice(3, 1) // removing blue color because it is used for the order markers
    siteIds.forEach((siteId, index) => {
      const site = sites.find((elem) => elem.id === siteId)
      if (site && site.address && site.address.latitude && site.address.longitude) {
        res.push({
          id: siteId,
          name: site.name,
          position: { lat: site.address.latitude, lng: site.address.longitude },
          color: possibleColors[index],
        })
      }
    })
    return res
  }, [siteIds, sites])

  const isMarkerInCircle = (center: LatLngLiteral, marker: LatLngLiteral): boolean => {
    const circleCenter = new LatLng(center.lat, center.lng)
    const circleRadius = distance // in km
    const distanceInKm = circleCenter.distanceTo(marker) / 1000
    return distanceInKm < circleRadius
  }

  const numberOfMarkersInCircles = useMemo((): INumberOfMarkersInCirlce[] => {
    const res: INumberOfMarkersInCirlce[] = []
    sitePositions.forEach((sitePosition) => {
      res.push({
        id: sitePosition.id,
        name: sitePosition.name,
        color: sitePosition.color,
        numberOfMarkers: 0,
      })
    })
    if (distance) {
      deliveryDistanceReporting
        .filter((elem) => elem.deliveryAddressLatitude && elem.deliveryAddressLongitude)
        .forEach((elem) => {
          sitePositions.forEach((sitePosition, index) => {
            if (
              isMarkerInCircle(sitePosition.position, {
                lat: elem.deliveryAddressLatitude,
                lng: elem.deliveryAddressLongitude,
              })
            ) {
              res[index].numberOfMarkers += 1
            }
          })
        })
    }
    return res
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distance, sitePositions, deliveryDistanceReporting])

  const markersToDisplay = useMemo(
    (): IOrderForDeliveryDistance[] =>
      lodash.sampleSize(
        deliveryDistanceReporting.filter(
          (elem) => elem.deliveryAddressLatitude && elem.deliveryAddressLongitude,
        ),
        MAX_NUMBER_OF_ORDERS_MARKERS,
      ),
    [deliveryDistanceReporting],
  )

  useEffect(() => {
    if (featureGroupRef && featureGroupRef.current) {
      setMapBounds(featureGroupRef.current.getBounds())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryDistanceReporting, sitePositions, distance])

  return (
    <>
      <OrdersFilters
        filterKey={filterKey}
        getStats={getDeliveryDistanceReporting}
        isForDistanceIndicator
      />
      <div className={classes.mapContainer}>
        <Map
          mapName="deliveryDistance"
          className={classes.map}
          bounds={mapBounds.isValid() ? mapBounds : undefined}
          shouldDisplayHeatmapLayer
          heatmapLayerPoints={markersToDisplay.map((elem) => [
            elem.deliveryAddressLatitude,
            elem.deliveryAddressLongitude,
            1,
          ])}
        >
          <FeatureGroup ref={featureGroupRef}>
            {sitePositions.map((sitePosition) => (
              <WarehouseMarker
                key={sitePosition.id}
                position={sitePosition.position}
                color={sitePosition.color}
              />
            ))}
            {markersToDisplay.map((elem) => (
              <CircleMarker
                key={elem.id}
                center={{ lat: elem.deliveryAddressLatitude, lng: elem.deliveryAddressLongitude }}
                stroke={false}
                interactive={false}
                radius={4}
                fillOpacity={1}
              />
            ))}
            {distance > 0
              && sitePositions.map((sitePosition) => (
                <Circle
                  key={sitePosition.id}
                  center={sitePosition.position}
                  pathOptions={{ color: sitePosition.color }}
                  radius={distance * 1000}
                  interactive={false}
                />
              ))}
          </FeatureGroup>
        </Map>
      </div>
      <div className={classes.legendContainer}>
        <span className={classes.legend}>
          {`${t('reporting.orders.total')}: ${deliveryDistanceReporting.length}`}
        </span>
        {distance > 0
          && numberOfMarkersInCircles.map((numberOfMarkersInCircle) => (
            <span
              key={numberOfMarkersInCircle.id}
              className={classes.legend}
              style={{ color: numberOfMarkersInCircle.color }}
            >
              {`${t('reporting.orders.insidePerimeter', {
                siteName: numberOfMarkersInCircle.name,
              })}: ${numberOfMarkersInCircle.numberOfMarkers}`}
            </span>
          ))}
      </div>
    </>
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <AppConfigProvider>
    <OrdersConsumer>
      {(ctx): JSX.Element => (
        <DeliveryDistanceIndicator
          deliveryDistanceReporting={ctx.deliveryDistanceReporting}
          getDeliveryDistanceReporting={ctx.getDeliveryDistanceReporting}
          {...props}
        />
      )}
    </OrdersConsumer>
  </AppConfigProvider>
)
