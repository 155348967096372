import React, { useState, ReactNode, useEffect, useContext } from 'react'

import {
  IUserData,
  IWarehouse,
  IDriver,
  ICarrier,
  IVehicle,
  ICompany,
  IVehicleProfile,
  IVehicleType,
  IDealer,
} from 'interfaces'
import { IPlanTemplate } from 'interfaces/IPlanTemplate'
import { ITenant } from 'interfaces/ITenant'
import ContentApi from 'api/content'
import { isIError } from 'api/types'
import { FeedbackContext } from 'store/FeedbackContext'
import { USER_ROLES } from 'constants/constants'
import { IUser } from 'interfaces/IUser'
import { AuthConsumer } from './AuthContext'

interface IContentContext {
  sites: IWarehouse[]
  warehouses: IWarehouse[]
  drivers: IDriver[]
  carriers: ICarrier[]
  allCarriers: ICarrier[]
  vehicles: IVehicle[]
  companies: ICompany[]
  allCompanies: ICompany[]
  vehicleProfiles: IVehicleProfile[]
  vehicleTypes: IVehicleType[]
  planTemplates: IPlanTemplate[]
  tenants: ITenant[]
  allSites: IWarehouse[]
  userSites: IWarehouse[]
  dealers: IDealer[]
  allDealers: IDealer[]
  refreshDrivers: () => void
  refreshCarriers: () => void
  refreshVehicles: () => void
  refreshVehicleTypes: () => void
  refreshSites: () => void
  refreshPlanTemplates: () => void
  userTenantId: string
  users: IUser[]
  refreshUsers: () => void
}

const ContentContext = React.createContext<IContentContext>({} as IContentContext)
const { Consumer } = ContentContext

interface IProps {
  children: ReactNode
  user?: IUserData
}

/**
 * This context will manage the data wich is not made to change often
 */

function ContentProvider({ children, user }: IProps): JSX.Element {
  const [sites, setSites] = useState<Array<IWarehouse>>([])
  const [drivers, setDrivers] = useState<Array<IDriver>>([])
  const [carriers, setCarriers] = useState<Array<ICarrier>>([])
  const [allCarriers, setAllCarriers] = useState<Array<ICarrier>>([])
  const [vehicles, setVehicles] = useState<Array<IVehicle>>([])
  const [companies, setCompanies] = useState<Array<ICompany>>([])
  const [allCompanies, setAllCompanies] = useState<Array<ICompany>>([])
  const [vehicleProfiles, setVehicleProfiles] = useState<Array<IVehicleProfile>>([])
  const [vehicleTypes, setVehicleTypes] = useState<Array<IVehicleType>>([])
  const [planTemplates, setPlanTemplates] = useState<Array<IPlanTemplate>>([])
  const [tenants, setTenants] = useState<Array<ITenant>>([])
  const [allSites, setAllSites] = useState<Array<IWarehouse>>([])
  const [userSites, setUserSites] = useState<Array<IWarehouse>>([])
  const [dealers, setDealers] = useState<Array<IDealer>>([])
  const [allDealers, setAllDealers] = useState<Array<IDealer>>([])
  const [userTenantId, setUserTenantId] = useState<string>('')
  const [users, setUsers] = useState<Array<IUser>>([])

  const { openErrorSnack, toggleLoader } = useContext(FeedbackContext)

  async function getAndSetData(
    apiCall: Function,
    dataSetter: Function,
    apiCallParam = '',
  ): Promise<void> {
    toggleLoader(true)
    const res = apiCallParam ? await apiCall(apiCallParam) : await apiCall()
    if (isIError(res)) {
      openErrorSnack(res.error.message)
    } else {
      dataSetter(res)
    }
    toggleLoader(false)
  }

  function getAllContent(userRoles: string[]): void {
    getAndSetData(ContentApi.getWarehouses, setSites)
    if (!(userRoles.length === 1 && userRoles.includes(USER_ROLES.cashier))) {
      getAndSetData(ContentApi.getCarriers, setCarriers)
      getAndSetData(ContentApi.getDrivers, setDrivers)
      getAndSetData(ContentApi.getVehicles, setVehicles)
      getAndSetData(ContentApi.getCompanies, setCompanies)
      getAndSetData(ContentApi.getVehicleProfiles, setVehicleProfiles)
      getAndSetData(ContentApi.getVehicleTypes, setVehicleTypes)
      getAndSetData(ContentApi.getPlanTemplates, setPlanTemplates)
    }
  }

  const refreshDrivers = (): void => {
    getAndSetData(ContentApi.getDrivers, setDrivers)
  }

  const refreshCarriers = (): void => {
    getAndSetData(ContentApi.getCarriers, setCarriers)
    getAndSetData(ContentApi.getCompanies, setCompanies)
  }

  const refreshVehicles = (): void => {
    getAndSetData(ContentApi.getVehicles, setVehicles)
  }

  const refreshVehicleTypes = (): void => {
    getAndSetData(ContentApi.getVehicleTypes, setVehicleTypes)
  }

  const refreshSites = (): void => {
    getAndSetData(ContentApi.getWarehouses, setSites)
  }

  const refreshPlanTemplates = (): void => {
    getAndSetData(ContentApi.getPlanTemplates, setPlanTemplates)
  }

  const refreshUsers = (): void => {
    getAndSetData(ContentApi.getUsers, setUsers)
  }

  const handleSetDealers = (dealersToSet: Array<IDealer>): void => {
    const dealersAllowedForTenant = dealersToSet.filter(
      (dealer) => dealer.tenantId === user?.tenantId,
    )
    setAllDealers(dealersToSet)
    setDealers(dealersAllowedForTenant)
  }

  useEffect(() => {
    if (user) setUserTenantId(user?.tenantId)
  }, [user])

  useEffect(() => {
    if (user && sites) {
      const filterIds = user.sites.map((site) => site.siteId)
      setUserSites(() => sites.filter((site) => filterIds.includes(site.id)))
    }
  }, [user, sites])

  useEffect(() => {
    if (user) {
      getAllContent(user.roles)
      if (user.roles.includes(USER_ROLES.superAdmin)) {
        getAndSetData(ContentApi.getTenants, setTenants)
        getAndSetData(ContentApi.getAllSites, setAllSites)
        getAndSetData(ContentApi.getAllCarriers, setAllCarriers)
        getAndSetData(ContentApi.getAllCompanies, setAllCompanies)
        getAndSetData(ContentApi.getAllDealers, handleSetDealers)
      } else if (!(user.roles.length === 1 && user.roles.includes(USER_ROLES.cashier))) {
        getAndSetData(ContentApi.getDealers, setDealers)
      }
    } else {
      setSites([])
      setDrivers([])
      setCarriers([])
      setVehicles([])
      setCompanies([])
      setVehicleProfiles([])
      setVehicleTypes([])
      setPlanTemplates([])
      setTenants([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id])

  return (
    <ContentContext.Provider
      value={{
        sites,
        warehouses: sites.filter((site) => site.type === 0),
        drivers,
        carriers,
        allCarriers,
        vehicles,
        companies,
        allCompanies,
        vehicleProfiles,
        vehicleTypes,
        refreshDrivers,
        refreshCarriers,
        planTemplates,
        refreshVehicles,
        refreshVehicleTypes,
        refreshSites,
        refreshPlanTemplates,
        tenants,
        allSites,
        userSites,
        dealers,
        allDealers,
        userTenantId,
        users,
        refreshUsers,
      }}
    >
      {children}
    </ContentContext.Provider>
  )
}

export default (props: JSX.IntrinsicAttributes & IProps): JSX.Element => (
  <AuthConsumer>
    {(ctx): JSX.Element => <ContentProvider user={ctx.user} {...props} />}
  </AuthConsumer>
)
export { ContentContext }
export { Consumer as ContentConsumer }
