import Api from 'services/api'
import { IError } from 'api/types'
import { IMarker, ITourMapItem } from 'interfaces/map'
import { IVisit } from 'interfaces/IOrders'

const getDeliveringDrivers = async (
  startDate: string,
  endDate: string,
): Promise<Array<IMarker> | IError> => {
  try {
    const res = await Api.get('drivers/delivering', { startDate, endDate })
    return res
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getSegments = async (
  startDate: string,
  endDate: string,
  status: string[],
): Promise<Array<ITourMapItem> | IError> => {
  try {
    const res = await Api.get('tours/segments', { startDate, endDate, status })
    return res
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getUnassignedVisits = async (
  startDate: string,
  endDate: string,
  siteIds: string[],
  deliveryTypeIds?: string[],
): Promise<Array<IVisit> | IError> => {
  try {
    const res = await Api.get('visits/unassigned', {
      startDate,
      endDate,
      siteIds,
      deliveryTypeIds,
    })
    return res
  } catch (error) {
    return { error: { message: error.message } }
  }
}

export default {
  getDeliveringDrivers,
  getUnassignedVisits,
  getSegments,
}
