import { Button, Paper, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { isIError } from 'api/types'
import React, { useCallback, useContext, useState } from 'react'
import FilesApi from 'api/files'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

import Modal from '@material-ui/core/Modal'
import { FileWithPath, useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { CustomPaper } from 'screens/PlanningCategory/PlanningScreen/components/BasicComponents'
import ButtonsRow from 'screens/PlanningCategory/PlanningScreen/components/ButtonsRow'
import { FeedbackContext } from 'store/FeedbackContext'
import { Link } from 'react-router-dom'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import { ImportJobType } from 'interfaces/IImportJob'
import { AuthContext } from 'store/AuthContext'
import { exportFile } from 'utils/functions'
import clsx from 'clsx'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      display: 'flex',
      width: '60%',
      height: '70%',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #000',
      boxShadow: theme.shadows[5],
      outline: 'none',
      top: '50%',
      left: '50%',
      transform: `translate(-50%, -50%)`,
    },
    filesContainer: {
      overflow: 'auto',
      backgroundColor: theme.palette.background.default,
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.color.scrollbarBackground,
      },
    },
    dropzone: {
      flex: 1,
      minHeight: '400px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '20px',
      margin: '20px',
      borderWidth: '4px',
      borderRadius: '8px',
      borderColor: theme.palette.primary.main,
      borderStyle: 'dashed',
      outline: 'none',
      transition: 'border .24s ease-in-out',
      cursor: 'pointer',
    },
    errorMessage: {
      position: 'absolute',
      bottom: '5%',
      left: '5%',
      zIndex: 1000,
    },
    historyLinkContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    orderTemplateButton: {
      borderColor: theme.color.primary,
      marginLeft: '16px',
    },
    historyLink: {
      fontSize: 18,
      color: theme.color.secondary,
      '&:hover': {
        color: theme.color.primary,
      },
    },
    disableLink: {
      pointerEvents: 'none',
    },
    filesListContainer: {
      maxHeight: '100px',
    },
  }),
)

interface IUploadModal {
  visible: boolean
  onClose: () => void
}

function UploadModal({ visible, onClose }: IUploadModal): JSX.Element {
  const classes = useStyles()
  const { t } = useTranslation()
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const { openErrorSnack } = useContext(FeedbackContext)
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([])

  const onDrop = useCallback(
    (acceptedFiles) => {
      setUploadedFiles([...uploadedFiles, ...acceptedFiles])
    },
    [uploadedFiles],
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  })
  const { user } = useContext(AuthContext)

  const handleUpload = async (): Promise<void> => {
    setIsUploading(true)
    if (uploadedFiles) {
      const res = await FilesApi.uploadFiles(uploadedFiles, ImportJobType.Order)
      setIsUploading(false)
      if (!isIError(res)) {
        handleClose()
      } else {
        openErrorSnack(res.error.message)
      }
    }
  }

  const handleTemplateDownload = async (): Promise<void> => {
    const templateDownload = user?.tenantConfig?.templates?.orderFileTemplateURL
    if (templateDownload) {
      exportFile(templateDownload)
    }
  }

  const handleClose = (): void => {
    if (!isUploading) {
      setUploadedFiles([])
      onClose()
    }
  }

  const files = uploadedFiles.map((file: FileWithPath) => (
    <li key={file.path}>
      {file.path} - {file.size} octets
    </li>
  ))

  const handleClearAllFiles = () => {
    setUploadedFiles([])
  }

  return (
    <Modal open={visible} onClose={handleClose} disableAutoFocus>
      <CustomPaper>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography>{t('OrdersScreen.importOrders')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.filesContainer}>
              <div {...getRootProps({ className: classes.dropzone })}>
                <input {...getInputProps()} />
                <p>{t('OrdersScreen.dropFilesHere')}</p>
              </div>
              {uploadedFiles.length > 0 && (
                <div className={classes.filesListContainer}>
                  <h4 style={{ marginLeft: 20 }}>{t('OrdersScreen.selectedFiles')}:</h4>
                  <ul>{files}</ul>
                </div>
              )}
            </Paper>
          </Grid>
          <Grid className={classes.historyLinkContainer} item xs={12}>
            <Link
              className={clsx(classes.historyLink, isUploading ? classes.disableLink : '')}
              to={ROUTES_PATH.importHistory}
            >
              {t('OrdersScreen.importHistory')}
            </Link>
          </Grid>
          <ButtonsRow
            confirmMessage={t('OrdersScreen.import')}
            isLoading={isUploading}
            toggleModal={handleClose}
            onClickCTA={handleUpload}
          >
            <Button
              variant="outlined"
              onClick={handleClearAllFiles}
              disabled={isUploading}
              className={classes.orderTemplateButton}
            >
              {t('OrdersScreen.clearUploads')}
            </Button>
            <Button
              variant="outlined"
              onClick={handleTemplateDownload}
              className={classes.orderTemplateButton}
            >
              {t('OrdersScreen.templateDownload')}
            </Button>
          </ButtonsRow>
        </Grid>
      </CustomPaper>
    </Modal>
  )
}

export default UploadModal
