import React, { useState, useEffect, useMemo, useRef, useContext } from 'react'
import MaterialTable, { Action, MTableHeader } from 'material-table'
import { useHistory } from 'react-router-dom'
import ArrowForward from '@material-ui/icons/ArrowForward'
import DescriptionIcon from '@material-ui/icons/Description'
import RefreshIcon from '@material-ui/icons/Refresh'
import { IconButton } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { FilterKey, Filter } from 'constants/filters'
import { VehiclesConsumer } from 'store/VehiclesContext'
import { IVehicle } from 'interfaces/IVehicle'
import MUITableIcons from 'constants/MUITableIcons'
import { IVehicleFilters, ITableColumn } from 'interfaces/interfaces'
import {
  ActiveFilter,
  CarrierFilter,
  VehicleCatFilter,
  SiteFilter,
} from 'components/Inputs/ListFilter'
import SearchInput from 'components/Inputs/SearchInput'
import Button from 'components/Button/CustomButton'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import { scrollTop } from 'utils/functions'
import AppConfigProvider, { AppConfigContext } from 'store/AppConfigContext'
import Pagination from 'components/Table/Pagination'
import { FiltersContext } from 'store/FiltersContext'
import { getPageSize, savePageSize } from 'utils/localStorage'
import { getDisplayRowsCount } from 'utils/tableUtils'

import { FiltersContainer, SearchContainer, TopFiltersContainer } from 'components/Layout'
import useStyles from './styles'

interface ICustomTableProps {
  columns: ITableColumn[]
  vehicles: IVehicle[]
  count?: number
  getList?: (
    filter?: IVehicleFilters,
    offset?: number,
    rowsPerPage?: number,
    sortField?: number,
    sortDirection?: string,
  ) => void
}

const columnConfigName = 'vehicleList'
const filterKey = FilterKey.vehicles

function VehiclesTable({
  columns,
  vehicles,
  count,
  getList,
  ...rest
}: ICustomTableProps): JSX.Element {
  const isInitialMount = useRef(true)

  const [paginationOffset, setPaginationOffset] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const { setFilter, resetFilters, filters } = useContext(FiltersContext)
  const active = filters[filterKey][Filter.active] as string[]
  const carriers = filters[filterKey][Filter.carriers] as string[]
  const searchText = filters[filterKey][Filter.searchText] as string
  const [isSearchReset, setIsSearchReset] = useState<boolean>(false)
  const vehicleTypeIds = filters[filterKey][Filter.vehicleTypes] as string[]
  const siteIds = filters[filterKey][Filter.sites] as string[]

  const [sortDirection, setSortDirection] = useState<string>('asc')
  const [rowsPerPage, setRowsPerPage] = useState<number>(getPageSize)
  const [sortField, setSortField] = useState<number>(0)
  const styles = useStyles()
  const history = useHistory()
  const { tablesConfig } = useContext(AppConfigContext)
  const { t } = useTranslation()

  useEffect(() => {
    if (rowsPerPage !== getPageSize()) {
      savePageSize(rowsPerPage)
      scrollTop()
    }
    if (getList) {
      getList({ active, carriers, vehicleTypeIds, searchText, siteIds }, 0, rowsPerPage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, carriers, searchText, rowsPerPage, vehicleTypeIds, siteIds])

  useEffect(() => {
    if (getList && !isInitialMount.current) {
      getList(
        { active, carriers, vehicleTypeIds, searchText, siteIds },
        paginationOffset,
        rowsPerPage,
        sortField,
        sortDirection,
      )
      scrollTop()
    } else {
      isInitialMount.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationOffset, sortDirection, sortField])

  const handleNewVehicleClick = (): void => {
    history.push(ROUTES_PATH.createVehicle)
  }

  const resetLocalFilters = (): void => {
    setIsSearchReset(true)
    resetFilters(filterKey)
  }

  const Filters = useMemo(
    (): JSX.Element => (
      <TopFiltersContainer>
        <FiltersContainer>
          <IconButton data-cy="initializeButton" onClick={resetLocalFilters}>
            <RefreshIcon />
          </IconButton>
          <ActiveFilter
            dataCy="activePicker"
            ids={active}
            handleChange={(activeValues: string[]): void => {
              setFilter(filterKey, Filter.active, activeValues)
            }}
          />
          <SiteFilter
            dataCy="sitePicker"
            handleChange={(siteValues: string[]): void => {
              setFilter(filterKey, Filter.sites, siteValues)
            }}
            ids={siteIds}
            isUnselectAllowed={false}
          />
          <CarrierFilter
            dataCy="carrierPicker"
            ids={carriers}
            handleChange={(carrierValues: string[]): void => {
              setFilter(filterKey, Filter.carriers, carrierValues)
            }}
            siteIds={siteIds}
            filterKey={filterKey}
          />
          <VehicleCatFilter
            dataCy="vehicleTypePicker"
            ids={vehicleTypeIds}
            handleChange={(vehicleTypeValues: string[]): void => {
              setFilter(filterKey, Filter.vehicleTypes, vehicleTypeValues)
            }}
          />
        </FiltersContainer>
        <SearchContainer>
          <SearchInput
            defaultValue={searchText}
            key={isSearchReset ? 'search-reset' : ''}
            onSearch={(text: string): void => {
              setIsSearchReset(false)
              setFilter(filterKey, Filter.searchText, text)
            }}
          />
          <Button dataCy="newButton" className={styles.newButton} onPress={handleNewVehicleClick}>
            {t('tablesEntries.new')}
          </Button>
        </SearchContainer>
      </TopFiltersContainer>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [active, carriers, searchText, vehicleTypeIds, siteIds, styles],
  )

  const handleDetailsClick = ({ id }: IVehicle): void => {
    history.push(`${ROUTES_PATH.vehicleDetails}${id}`)
  }

  const handleReportsClick = ({ id }: IVehicle): void => {
    history.push(`${ROUTES_PATH.vehicleReports}${id}`)
  }

  const handleChangeRowsPerPage = (pageSize: number): void => {
    setPage(0)
    setRowsPerPage(pageSize)
    setPaginationOffset(0)
  }

  const handleOrderChange = (columnIndex: number): void => {
    setPaginationOffset(0)
    setPage(0)
    setSortDirection((prevSortDirection) => (prevSortDirection === 'asc' ? 'desc' : 'asc'))
    setSortField(columnIndex)
  }

  const displayRowsCount = useMemo(() => getDisplayRowsCount(vehicles), [vehicles])
  const displayedColumns = columns.map(setColumnDisplay)

  return (
    <>
      {Filters}
      <MaterialTable
        key={displayRowsCount}
        data={vehicles}
        columns={displayedColumns}
        {...rest}
        options={{
          toolbar: false,
          pageSize: displayRowsCount,
          actionsColumnIndex: -1,
        }}
        actions={[
          (data: IVehicle): Action<IVehicle> => ({
            icon: ArrowForward,
            tooltip: t('tablesEntries.seeDetails'),
            onClick: (): void => handleDetailsClick(data),
          }),
          (data: IVehicle): Action<IVehicle> => ({
            icon: DescriptionIcon,
            tooltip: t('tablesEntries.seeReports'),
            onClick: (): void => handleReportsClick(data),
          }),
        ]}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        icons={MUITableIcons}
        components={{
          Header: (props): JSX.Element => (
            <MTableHeader
              {...props}
              orderBy={sortField}
              onOrderChange={handleOrderChange}
              orderDirection={sortDirection}
            />
          ),
          // eslint-disable-next-line react/prop-types, @typescript-eslint/no-unused-vars
          Pagination: ({ classes, ...props }): JSX.Element => (
            <Pagination
              {...props}
              count={count}
              columnConfigName={columnConfigName}
              page={page}
              rowsPerPage={rowsPerPage}
              setPage={setPage}
              columns={columns}
              setPaginationOffset={setPaginationOffset}
            />
          ),
        }}
      />
    </>
  )

  //  VehiclesTable helpers
  // *****************************************************************************

  function setColumnDisplay(col: ITableColumn) {
    const hidden = tablesConfig[columnConfigName]?.includes(col.title)
    return { ...col, hidden } as unknown as MaterialTable<object>
  }
}

export default (props: ICustomTableProps): JSX.Element => (
  <AppConfigProvider>
    <VehiclesConsumer>
      {(ctx): JSX.Element => <VehiclesTable count={ctx.count} getList={ctx.getList} {...props} />}
    </VehiclesConsumer>
  </AppConfigProvider>
)
