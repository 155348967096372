import { IRoutingRule } from 'interfaces/IRoutingRule'
import { IError } from 'api/types'
import Api from 'services/api'
import { RuleType } from 'constants/constants'
import { IRoutingRulesFilters } from 'interfaces/interfaces'

const formatRoutingRuleRequestBody = (routingRule: IRoutingRule) => ({
  label: routingRule.label,
  priority: routingRule.priority,
  active: routingRule.active,
  condition: {
    ...routingRule.condition,
    maximumordersApplicablebytimewindow: routingRule.condition?.maximumOrdersApplicableByTimeWindow,
    maximumOrdersApplicableByTimeWindow: undefined,
    carrierIds: routingRule.condition?.carrierIds,
    ...(routingRule?.condition?.origin?.type
      ? {
        origin: {
          ...routingRule?.condition?.origin,
          zipCodes:
              routingRule?.condition?.origin?.type !== RuleType.ZipCode
                ? undefined
                : routingRule.condition?.origin?.zipCodes,
          siteId:
              routingRule?.condition?.origin?.type === RuleType.Site
              || routingRule?.condition?.origin?.type === RuleType.SiteArea
                ? routingRule?.condition?.origin?.siteId
                : undefined,
          perimeter:
              routingRule?.condition?.origin?.type !== RuleType.SiteArea
                ? undefined
                : routingRule?.condition?.origin?.perimeter,
          perimeterMode:
              routingRule?.condition?.origin?.type !== RuleType.SiteArea
                ? undefined
                : routingRule?.condition?.origin.perimeterMode,
          sectorIds:
              routingRule?.condition?.origin?.type !== RuleType.Sector
                ? undefined
                : routingRule.condition?.origin?.sectorIds,
        },
      }
      : { origin: undefined }),
    ...(routingRule?.condition?.destination?.type
      ? {
        destination: {
          ...routingRule?.condition?.destination,
          zipCodes:
              routingRule?.condition?.destination?.type !== RuleType.ZipCode
                ? undefined
                : routingRule?.condition?.destination.zipCodes,
          siteId:
              routingRule?.condition?.destination?.type === RuleType.Site
              || routingRule?.condition?.destination?.type === RuleType.SiteArea
                ? routingRule?.condition?.destination?.siteId
                : undefined,
          perimeter:
              routingRule?.condition?.destination?.type !== RuleType.SiteArea
                ? undefined
                : routingRule?.condition?.destination?.perimeter,
          perimeterMode:
              routingRule?.condition?.destination?.type !== RuleType.SiteArea
                ? undefined
                : routingRule?.condition?.destination.perimeterMode,
          sectorIds:
              routingRule?.condition?.destination?.type !== RuleType.Sector
                ? undefined
                : routingRule?.condition?.destination.sectorIds,
        },
      }
      : { destination: undefined }),
  },
  routingSegments:
    routingRule.routingSegments
    && routingRule.routingSegments.map((routingSegment) => ({
      ...routingSegment,
      ...(routingSegment.origin?.type
        ? {
          origin: {
            ...routingSegment?.origin,
            siteId: routingSegment?.origin?.siteId,
          },
        }
        : { origin: undefined }),
      ...(routingSegment.destination?.type
        ? {
          destination: {
            ...routingSegment?.destination,
            siteId: routingSegment?.destination?.siteId,
          },
        }
        : { destination: undefined }),
    })),
})

const getList = async (
  filters?: IRoutingRulesFilters,
  offset?: number,
  rowsPerPage?: number,
  sortBy?: string,
  sortDirection?: number,
): Promise<{ routingRules: Array<IRoutingRule>; count: number } | IError> => {
  try {
    const response = await Api.get('routing-rules', {
      siteIds: filters?.siteIds,
      ...((filters?.searchText?.length || 0) >= 3 && { search: filters?.searchText }),
      ...((filters?.active?.length || 0) === 1 && {
        active: filters?.active && filters?.active[0],
      }),
      offset,
      limit: rowsPerPage,
      sortBy: sortBy || 'label',
      sortDirection: sortDirection || 1,
    })
    return { routingRules: response.items, count: response.count }
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const create = async (routingRules: IRoutingRule): Promise<IRoutingRule | IError> => {
  try {
    const response = await Api.post('routing-rules', formatRoutingRuleRequestBody(routingRules))
    return response
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

const update = async (id: string, routingRules: IRoutingRule): Promise<IRoutingRule | IError> => {
  try {
    const response = await Api.patch(
      `routing-rules/${id}`,
      formatRoutingRuleRequestBody(routingRules),
    )
    return response
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

const get = async (id: string): Promise<IRoutingRule | IError> => {
  try {
    const response = await Api.get(`routing-rules/details/${id}`)
    return {
      ...response,
      condition: {
        ...response.condition,
        maximumOrdersApplicableByTimeWindow: response.condition.maximumordersApplicablebytimewindow,
      },
    }
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const deleteRule = async (id: string): Promise<void | IError> => {
  try {
    const response = await Api.delete(`routing-rules/${id}`)
    return response
  } catch (error) {
    return { error: { message: error.message } }
  }
}

export default {
  getList,
  create,
  update,
  get,
  deleteRule,
}
