import React, { useContext } from 'react'

import {
  COLUMNS_MODEL_NOTIFIER_CONFIGURATIONS,
  COLUMNS_MODEL_NOTIFIER_CONFIGURATIONS_ADMIN,
} from 'constants/table'
import NotifierConfigurationsProvider, {
  NotifierConfigurationsContext,
} from 'store/NotifierConfigurationsContext'
import NotifierConfigurationsTable from 'components/Table/NotifierConfigurationsTable'
import { AuthContext } from 'store/AuthContext'
import { USER_ROLES } from 'constants/constants'

const NotifierConfigListScreen = (): JSX.Element => {
  const { notifierConfigurations, count } = useContext(NotifierConfigurationsContext)
  const { user: currentUser } = useContext(AuthContext)

  return (
    <NotifierConfigurationsTable
      notifierConfigurations={notifierConfigurations}
      count={count}
      columns={
        currentUser?.roles.includes(USER_ROLES.superAdmin)
          ? COLUMNS_MODEL_NOTIFIER_CONFIGURATIONS_ADMIN
          : COLUMNS_MODEL_NOTIFIER_CONFIGURATIONS
      }
    />
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <NotifierConfigurationsProvider>
    <NotifierConfigListScreen {...props} />
  </NotifierConfigurationsProvider>
)
