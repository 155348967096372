import Api from 'services/api'
import { IError } from 'api/types'
import { IApikey } from 'interfaces/IApikey'
import { IApikeyFilters } from 'interfaces/interfaces'

const parseSingleApikey = (apikey: IApikey): IApikey => ({
  id: apikey.id,
  userId: apikey.userId,
  comment: apikey.comment,
  tenantId: apikey.tenantId,
  whitelistedIPs: apikey.whitelistedIPs,
})

const parseApikeys = (apikeys: IApikey[]): Array<IApikey> =>
  apikeys.map((apikey) => parseSingleApikey(apikey))

const getList = async (
  filters?: IApikeyFilters,
  offset?: number,
  rowsPerPage?: number,
  sortBy?: string,
  sortDirection?: number,
): Promise<{ apikeys: Array<IApikey>; count: number } | IError> => {
  try {
    const query = {
      tenantIds: filters?.tenantIds,
      offset,
      limit: rowsPerPage,
      sortBy: sortBy || 'firstName',
      sortDirection: sortDirection || 1,
    }

    const response = await Api.get('apikeys', query)
    return { apikeys: parseApikeys(response.items), count: response.count }
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const get = async (id: string): Promise<IApikey | IError> => {
  try {
    const response = await Api.get(`apikeys/${id}/details`)
    return response
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const create = async (apikey: IApikey): Promise<IApikey | IError> => {
  try {
    const response = await Api.post(`apikeys/with-token`, {
      ...apikey,
      whitelistedIPs: apikey.whitelistedIPs?.map((ip) => ip.trim()),
    })
    return response
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

const update = async (id: string, apikey: IApikey): Promise<IApikey | IError> => {
  try {
    const response = await Api.patch(`apikeys/${id}`, {
      ...apikey,
      whitelistedIPs: apikey.whitelistedIPs?.map((ip) => ip.trim()),
    })
    return response
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

const revoke = async (id: string): Promise<IApikey | IError> => {
  try {
    const response = await Api.delete(`apikeys/${id}`)
    return response
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

export default {
  getList,
  get,
  create,
  update,
  revoke,
}
