import React, { useState, useEffect } from 'react'

import TrailersProvider, { TrailersConsumer } from 'store/TrailersContext'
import { ITrailer } from 'interfaces/ITrailer'
import { IIdParam } from 'interfaces/IIdParam'
import { useHistory, useParams } from 'react-router-dom'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import FormAction from 'components/Button/FormAction'
import { ContentConsumer } from 'store/ContentContext'
import { IVehicleType } from 'interfaces/interfaces'
import VehicleCard from 'components/Card/VehicleCard'

interface IProps {
  createTrailer?: Function
  createSuccess?: boolean
  updateSuccess?: boolean
  getDetails?: Function
  updateTrailer?: Function
  trailerDetails?: ITrailer
  vehicleTypes?: IVehicleType[]
}

const TrailerFormScreen = ({
  createTrailer,
  updateSuccess,
  updateTrailer,
  createSuccess,
  getDetails,
  trailerDetails,
  vehicleTypes,
}: IProps): JSX.Element => {
  const [trailer, setTrailer] = useState<ITrailer>({ active: true })
  const history = useHistory()
  const { id } = useParams<IIdParam>()

  const handleTrailerChange = (trailerInfo: ITrailer): void => {
    setTrailer((prevTrailer) => ({
      ...prevTrailer,
      name: trailerInfo.name,
      licencePlate: trailerInfo.licencePlate,
      carrier: trailerInfo.carrier,
      vehicleTypeId: vehicleTypes?.find(
        (vehicleType) => vehicleType.code === trailerInfo.vehicleType,
      )?.id,
      active: trailerInfo.active,
    }))
  }

  const handleSaveClick = async (): Promise<void> => {
    if (id) {
      if (updateTrailer) {
        await updateTrailer(id, trailer)
      }
    } else {
      if (createTrailer) {
        await createTrailer(trailer)
      }
    }
  }

  useEffect(() => {
    if (createSuccess || updateSuccess) {
      history.push(ROUTES_PATH.trailersList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createSuccess, updateSuccess])

  useEffect(() => {
    if (id && getDetails) {
      getDetails(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    setTrailer({
      id: trailerDetails?.id,
      name: trailerDetails?.name,
      licencePlate: trailerDetails?.licencePlate,
      active: trailerDetails?.active,
      carrierId: trailerDetails?.carrierId,
      vehicleType: trailerDetails?.vehicleType,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trailerDetails])

  const handleCancelClick = (): void => {
    history.push(ROUTES_PATH.trailersList)
  }

  return (
    <>
      {id && <h3>{`${trailer.name || ''}`}</h3>}
      <VehicleCard
        key={trailer.id}
        vehicle={trailer}
        onVehicleChange={handleTrailerChange}
        hideSite
        hideLastKnownKilometer
      />
      <FormAction onSaveClick={handleSaveClick} onCancelClick={handleCancelClick} />
    </>
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <TrailersProvider>
    <ContentConsumer>
      {(contentCtx): JSX.Element => (
        <TrailersConsumer>
          {(trailerCtx): JSX.Element => (
            <TrailerFormScreen
              updateSuccess={trailerCtx.updateSuccess}
              updateTrailer={trailerCtx.updateTrailer}
              getDetails={trailerCtx.getDetails}
              trailerDetails={trailerCtx.trailerDetails}
              createTrailer={trailerCtx.createTrailer}
              createSuccess={trailerCtx.createSuccess}
              vehicleTypes={contentCtx.vehicleTypes}
              {...props}
            />
          )}
        </TrailersConsumer>
      )}
    </ContentConsumer>
  </TrailersProvider>
)
