import React, { useContext, useState, useRef, useEffect } from 'react'
import {
  Grid,
  CardHeader,
  CardContent,
  Typography,
  Paper,
  Button,
  IconButton,
  FormControlLabel,
  Checkbox,
  TextField,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import clsx from 'clsx'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import CheckIcon from '@material-ui/icons/Check'
import { Autocomplete } from '@material-ui/lab'

import { IInputChange } from 'interfaces/IInputChange'
import { ICondition, IPlace, WeekDay } from 'interfaces/IRoutingRule'
import useStyles from 'constants/cruStyles'
import { DateType, DAYCODES_OF_WEEK, DAYS_OF_WEEK } from 'constants/constants'
import { dateToTimeFormat, getDateStringFromTime } from 'utils/dateFormat'
import DateRangePicker from 'components/Inputs/DateRangePicker'
import { AuthContext } from 'store/AuthContext'
import { ITag } from 'interfaces/ITag'
import { TagsContext } from 'store/TagsContext'
import { isDefined } from 'utils/functions'
import RulePlaceForm from './RulePlaceForm'
import DeliveryTypeSelect from './DeliveryTypeSelect'
import { TextInput } from './Inputs'
import { RoutingRulesContext } from './RoutingStore'
import TransportTypeSelect from './TransportTypeSelect'
import CarriersSelect from './CarriersSelect'

interface IProps {
  condition?: Partial<ICondition>
  onConditionChange: (condition: Partial<ICondition>) => void
}

const DEFAULT_APPLICABLE_TIME_WINDOW = 60

const ConditionCard = ({ condition, onConditionChange }: IProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [currentOrderNumber, setCurrentOrderNumber] = useState<number>()
  const [currentEditingOrderIndex, setCurrentEditingOrderIndex] = useState<number>()
  const { editMode } = useContext(RoutingRulesContext)
  const { tags, getTags } = useContext(TagsContext)
  const { user } = useContext(AuthContext)
  const orderNumberRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (!isDefined(tags) && user?.tenantId && getTags) {
      getTags(user.tenantId)
    }
  }, [tags, user, getTags])

  const handleOriginChange = (place: IPlace): void => {
    onConditionChange({
      ...condition,
      origin: place,
    })
  }

  const handleDestinationChange = (place: IPlace): void => {
    onConditionChange({
      ...condition,
      destination: place,
    })
  }

  const handleConditionChange = (
    value: number | string | string[] | null | undefined,
    name: string,
  ): void => {
    onConditionChange({
      ...condition,
      [name]: value,
    })
  }

  const handleMaximumOrdersApplicableByTimeWindow = (
    value: number | undefined,
    name: string,
  ): void => {
    onConditionChange({
      ...condition,
      [name]: value,
      applicableTimeWindow:
        condition?.applicableTimeWindow !== undefined
          ? condition?.applicableTimeWindow
          : DEFAULT_APPLICABLE_TIME_WINDOW,
    })
  }

  const handleAddOrder = () => {
    if (currentOrderNumber) {
      onConditionChange({
        ...condition,
        specificOrderNumbers: [...(condition?.specificOrderNumbers || []), `${currentOrderNumber}`],
      })
      setCurrentOrderNumber(undefined)
    }
  }

  const handleDeleteOrder = (orderNumberIndex: number) => {
    onConditionChange({
      ...condition,
      specificOrderNumbers: condition?.specificOrderNumbers?.filter(
        (_orderNumber, index) => index !== orderNumberIndex,
      ),
    })
  }

  const handleEditOrder = () => {
    const specificOrderNumbers = [...(condition?.specificOrderNumbers || [])]
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    specificOrderNumbers[currentEditingOrderIndex!] = `${currentOrderNumber}`
    onConditionChange({
      ...condition,
      specificOrderNumbers,
    })
    setCurrentEditingOrderIndex(undefined)
    setCurrentOrderNumber(undefined)
  }

  const handleOrderNumberEdit = (index: number, orderNumber: string) => {
    setCurrentEditingOrderIndex(index)
    setCurrentOrderNumber(+orderNumber)
    if (orderNumberRef && orderNumberRef.current) {
      orderNumberRef.current.focus()
    }
  }

  const handleApplicationDaysChange = (dayCode: string): void => {
    const currentApplicationDays = [...(condition?.applicationDays || [])]
    const day = DAYCODES_OF_WEEK.findIndex((code) => code === dayCode)
    let newApplicationDays: WeekDay[] = []
    if (currentApplicationDays.includes(day)) {
      newApplicationDays = currentApplicationDays.filter((elem) => elem !== day)
    } else {
      newApplicationDays = [...currentApplicationDays, day]
    }
    onConditionChange({ ...condition, applicationDays: newApplicationDays })
  }

  const isDayChecked = (dayCode: string, applicationDays?: WeekDay[]): boolean => {
    if (applicationDays) {
      const day = DAYCODES_OF_WEEK.findIndex((code) => code === dayCode)
      return applicationDays.includes(day)
    }
    return false
  }

  const handleTagsChange = (event: object, value: ITag[]): void => {
    onConditionChange({ ...condition, tags: value.map((tag) => tag.code || '') })
  }

  const getTagsFromCodes = (codes: string[]): ITag[] =>
    tags?.filter((tag) => tag.code && codes.includes(tag.code)) || []

  return (
    <Grid
      data-cy="conditionCard"
      item
      xs={5}
      className={clsx(classes.infoContainer, classes.routingRuleCard)}
    >
      <Paper elevation={10}>
        <CardHeader
          title={t('RoutingRulesScreen.conditions')}
          titleTypographyProps={{
            variant: 'subtitle1',
            style: {
              fontWeight: 'bolder',
            },
          }}
          className={classes.header}
        />
        <CardContent className={classes.routingRulesCard}>
          <RulePlaceForm
            dataCy="originSection"
            dataCyType="originType"
            place={condition?.origin}
            title={t('RoutingRulesScreen.origin')}
            onChange={handleOriginChange}
            isOrigin
            isRequired
          />

          <RulePlaceForm
            dataCy="destinationSection"
            dataCyType="destinationType"
            place={condition?.destination}
            title={t('RoutingRulesScreen.destination')}
            onChange={handleDestinationChange}
            isRequired
          />

          <div className={classes.fieldGroupContainer}>
            <DeliveryTypeSelect
              value={condition?.deliveryType}
              onChange={(value: number | string | null, name: string) => {
                handleConditionChange(value, name)
              }}
              dataCy="deliveryPicker"
            />
          </div>

          <div className={classes.fieldGroupContainer}>
            <TransportTypeSelect
              value={condition?.transportType}
              onChange={(value: string, name: string) => {
                handleConditionChange(value, name)
              }}
            />
          </div>

          <div data-cy="carriersSection" className={clsx(classes.fieldGroupContainer)}>
            <CarriersSelect
              warehouseId={condition?.origin?.siteId}
              editMode={editMode}
              value={condition?.carrierIds}
              handleChange={(value: string[], name: string) => {
                handleConditionChange(value, name)
              }}
            />
          </div>

          <div
            data-cy="applicationDaysSection"
            className={clsx(classes.fieldGroupContainer, classes.applicationDaysContainer)}
          >
            <Typography>{t('RoutingRulesScreen.applicationDays')}</Typography>
            {DAYS_OF_WEEK.map((day) => (
              <FormControlLabel
                key={`fcl-${day.code}`}
                control={(
                  <Checkbox
                    key={day.code}
                    checked={isDayChecked(day.code, condition?.applicationDays)}
                    onChange={(): void => handleApplicationDaysChange(day.code)}
                    name={day.code}
                  />
                )}
                label={day.label}
                disabled={!editMode}
              />
            ))}
          </div>

          <div data-cy="dateSection" className={classes.fieldGroupContainer}>
            <Typography className={classes.routingRuleSectionLabel}>
              {t('RoutingRulesScreen.date')}
            </Typography>
            <div className={classes.configurationRow}>
              <DateRangePicker
                startDate={condition?.beginValidity || null}
                endDate={condition?.endValidity || null}
                onChangeStart={(date): void =>
                  handleConditionChange(moment(date).toISOString() || undefined, 'beginValidity')}
                onChangeEnd={(date): void =>
                  handleConditionChange(moment(date).toISOString() || undefined, 'endValidity')}
                hideShiftPicker
                dateType={DateType.Date}
                isStartDisabled={!editMode}
                isEndDisabled={!editMode}
                isStartClearable
                customClass={classes.singleField}
              />
            </div>
          </div>

          <div data-cy="hourSection" className={classes.fieldGroupContainer}>
            <Typography className={classes.routingRuleSectionLabel}>
              {t('RoutingRulesScreen.hour')}
            </Typography>
            <div className={classes.configurationRow}>
              <DateRangePicker
                startDate={
                  condition?.beginHour ? getDateStringFromTime(condition?.beginHour) : null
                }
                endDate={condition?.endHour ? getDateStringFromTime(condition?.endHour) : null}
                startLabel={t('RoutingRulesScreen.beginHour')}
                endLabel={t('RoutingRulesScreen.endHour')}
                onChangeStart={(time): void =>
                  handleConditionChange(
                    dateToTimeFormat(moment(time).toISOString()) || undefined,
                    'beginHour',
                  )}
                onChangeEnd={(time): void =>
                  handleConditionChange(
                    dateToTimeFormat(moment(time).toISOString()) || undefined,
                    'endHour',
                  )}
                hideShiftPicker
                dateType={DateType.Time}
                isStartDisabled={!editMode}
                isEndDisabled={!editMode}
                isStartClearable
                customClass={classes.singleField}
              />
            </div>
          </div>

          <div data-cy="quantitySection" className={classes.fieldGroupContainer}>
            <Typography className={classes.routingRuleSectionLabel}>
              {t('RoutingRulesScreen.quantity')}
            </Typography>
            <div className={classes.configurationRow}>
              <TextInput
                type="number"
                variant="outlined"
                name="minQuantity"
                className={classes.singleField}
                label={t('RoutingRulesScreen.min')}
                value={condition?.minQuantity !== undefined ? condition.minQuantity : ''}
                onChange={({ target: { name, value } }: IInputChange) =>
                  handleConditionChange(value ? Number(value) : undefined, name)}
                data-cy="minQuantity"
              />
              <TextInput
                type="number"
                name="maxQuantity"
                variant="outlined"
                className={classes.singleField}
                label={t('RoutingRulesScreen.max')}
                value={condition?.maxQuantity !== undefined ? condition.maxQuantity : ''}
                onChange={({ target: { name, value } }: IInputChange) =>
                  handleConditionChange(value ? Number(value) : undefined, name)}
                data-cy="maxQuantity"
              />
            </div>
          </div>

          <div data-cy="weightSection" className={classes.fieldGroupContainer}>
            <Typography className={classes.routingRuleSectionLabel}>
              {t('RoutingRulesScreen.weight')}
            </Typography>
            <div className={classes.configurationRow}>
              <TextInput
                type="number"
                variant="outlined"
                name="minWeight"
                className={classes.singleField}
                label={t('RoutingRulesScreen.minWeight')}
                value={condition?.minWeight === undefined ? '' : condition.minWeight}
                onChange={({ target: { name, value } }: IInputChange) =>
                  handleConditionChange(value ? Number(value) : undefined, name)}
                data-cy="minWeight"
                InputProps={{
                  inputProps: { min: 0 },
                }}
              />
              <TextInput
                type="number"
                name="maxWeight"
                variant="outlined"
                className={classes.singleField}
                label={t('RoutingRulesScreen.maxWeight')}
                value={condition?.maxWeight === undefined ? '' : condition.maxWeight}
                onChange={({ target: { name, value } }: IInputChange) =>
                  handleConditionChange(value ? Number(value) : undefined, name)}
                data-cy="maxWeight"
                InputProps={{
                  inputProps: { min: 0 },
                }}
              />
            </div>
            {condition?.minWeight !== undefined
              && condition?.maxWeight !== undefined
              && condition?.minWeight >= condition?.maxWeight && (
                <div style={{ color: 'red' }}>{t('RoutingRulesScreen.weightValidationFailed')}</div>
            )}
          </div>

          <div data-cy="orderSection" className={classes.fieldGroupContainer}>
            <Typography className={classes.routingRuleSectionLabel}>
              {t('RoutingRulesScreen.order')}
            </Typography>
            <Autocomplete
              multiple
              id="routing-rule-tags"
              autoComplete
              options={tags || []}
              getOptionLabel={(option) => option.label || ''}
              renderInput={(params) => (
                <TextField {...params} variant="standard" label={t('RoutingRulesScreen.tags')} />
              )}
              onChange={handleTagsChange}
              value={getTagsFromCodes(condition?.tags || [])}
              getOptionSelected={(option, value) => option.code === value.code}
              disabled={!editMode}
            />
            <div className={clsx(classes.configurationRow, classes.orderConditionField)}>
              <TextInput
                type="number"
                variant="outlined"
                name="minAmount"
                className={classes.singleField}
                label={t('RoutingRulesScreen.minAmount')}
                value={condition?.minAmount || ''}
                onChange={({ target: { name, value } }: IInputChange) =>
                  handleConditionChange(value, name)}
                data-cy="minAmount"
              />
              <TextInput
                type="number"
                name="maxAmount"
                variant="outlined"
                className={classes.singleField}
                label={t('RoutingRulesScreen.maxAmount')}
                value={condition?.maxAmount || ''}
                onChange={({ target: { name, value } }: IInputChange) =>
                  handleConditionChange(value, name)}
                data-cy="maxAmount"
              />
            </div>
            <div className={clsx(classes.configurationRow, classes.orderConditionField)}>
              <TextInput
                type="number"
                variant="outlined"
                name="maximumOrdersApplicable"
                className={classes.singleField}
                label={t('RoutingRulesScreen.maximumOrdersApplicable')}
                value={
                  condition?.maximumOrdersApplicable !== undefined
                    ? condition.maximumOrdersApplicable
                    : ''
                }
                onChange={({ target: { name, value } }: IInputChange) =>
                  handleConditionChange(value ? Number(value) : undefined, name)}
                data-cy="maximumOrdersApplicable"
              />
            </div>
            <div className={clsx(classes.configurationRow, classes.orderConditionField)}>
              <TextInput
                type="number"
                variant="outlined"
                name="maximumOrdersApplicableByTimeWindow"
                className={classes.singleField}
                label={t('RoutingRulesScreen.maximumOrdersApplicableByTimeWindow')}
                value={
                  condition?.maximumOrdersApplicableByTimeWindow !== undefined
                    ? condition.maximumOrdersApplicableByTimeWindow
                    : ''
                }
                onChange={({ target: { name, value } }: IInputChange) =>
                  handleMaximumOrdersApplicableByTimeWindow(value ? Number(value) : undefined, name)}
                data-cy="maximumOrdersApplicableByTimeWindow"
              />
              <TextInput
                type="number"
                name="applicableTimeWindow"
                variant="outlined"
                className={classes.singleField}
                label={t('RoutingRulesScreen.applicableTimeWindow')}
                value={
                  condition?.applicableTimeWindow !== undefined
                    ? condition.applicableTimeWindow
                    : ''
                }
                onChange={({ target: { name, value } }: IInputChange) =>
                  handleConditionChange(value ? Number(value) : undefined, name)}
                data-cy="applicableTimeWindow"
              />
            </div>
            {editMode && (
              <div className={clsx(classes.configurationRow, classes.orderConditionField)}>
                <TextInput
                  type="number"
                  variant="outlined"
                  className={classes.singleField}
                  label={
                    currentEditingOrderIndex !== undefined
                      ? t('RoutingRulesScreen.editOrder')
                      : t('RoutingRulesScreen.addOrder')
                  }
                  InputProps={{
                    endAdornment:
                      currentEditingOrderIndex !== undefined ? (
                        <IconButton onClick={handleEditOrder} disabled={!currentOrderNumber}>
                          <CheckIcon />
                        </IconButton>
                      ) : (
                        <IconButton onClick={handleAddOrder} disabled={!currentOrderNumber}>
                          <AddIcon />
                        </IconButton>
                      ),
                  }}
                  value={currentOrderNumber || ''}
                  onChange={({ target: { value } }: IInputChange) => setCurrentOrderNumber(+value)}
                  onKeyPress={(e): false | void =>
                    e.key === 'Enter'
                    && (currentEditingOrderIndex !== undefined ? handleEditOrder() : handleAddOrder())}
                  inputRef={orderNumberRef}
                />
              </div>
            )}
            {condition?.specificOrderNumbers && condition?.specificOrderNumbers.length > 0 && (
              <div className={classes.conditionOrderContainer}>
                <Typography className={classes.routingRuleSectionLabel}>
                  {t('RoutingRulesScreen.ordersLimitation')}
                </Typography>
                <div className={classes.condtionOrders}>
                  {condition?.specificOrderNumbers?.map((orderNumber, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div className={clsx(classes.fieldsWarehouse, classes.orderList)} key={index}>
                      <span>{orderNumber}</span>
                      {editMode && (
                        <div>
                          <Button onClick={() => handleOrderNumberEdit(index, orderNumber)}>
                            <EditIcon />
                          </Button>
                          <Button onClick={() => handleDeleteOrder(index)}>
                            <DeleteIcon />
                          </Button>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </CardContent>
      </Paper>
    </Grid>
  )
}

export default ConditionCard
