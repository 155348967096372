import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox, FormControlLabel, IconButton, TextField, Tooltip } from '@material-ui/core'
import ReplayIcon from '@material-ui/icons/Replay'
import clsx from 'clsx'

import Card from 'components/Card/Card'
import { IOrderForm } from 'interfaces/IOrders'
import SearchableSelect from 'components/Inputs/SearchableSelect'
import { ContentContext } from 'store/ContentContext'
import { AuthContext } from 'store/AuthContext'
import { ICustomer, IFieldChange } from 'interfaces/interfaces'
import { IWarehouseCartDropoffConfig } from 'interfaces/IWarehouseCartDropoffConfig'
import { CustomerSearchField } from 'constants/constants'
import { shouldDisplayOrderFormField } from 'utils/functions'
import useStyles from './styles'

interface IProps {
  order?: Partial<IOrderForm>
  onChange: (key: keyof IOrderForm, value: unknown) => void
  onCustomerAutocomplete: (field: CustomerSearchField) => void
  onResetCustomerChoices: () => void
  onConfirmCustomerChoice: (newCustomer: Partial<ICustomer>) => void
  customerChoices: ICustomer[]
  isCartDropoff?: boolean
  config?: IWarehouseCartDropoffConfig | null
  resetOriginCardData: () => void
}

interface ICustomerChoicesProps {
  customerChoices: ICustomer[]
  makeAChoice: (customerChoice: ICustomer) => void
  autocompleteField: CustomerSearchField
}

const CustomerChoices = ({
  customerChoices,
  makeAChoice,
  autocompleteField,
}: ICustomerChoicesProps): JSX.Element => {
  const classes = useStyles()

  const constructCustomerChoiceString = (customerChoice: ICustomer): string => {
    if (autocompleteField === CustomerSearchField.Email) {
      return `${customerChoice.email} - ${customerChoice.lastName} ${customerChoice.firstName} ${customerChoice.phoneNumber}`
    }
    if (autocompleteField === CustomerSearchField.LastName) {
      return `${customerChoice.lastName} ${customerChoice.firstName} - ${customerChoice.email} ${customerChoice.phoneNumber}`
    }
    if (autocompleteField === CustomerSearchField.PhoneNumber) {
      return `${customerChoice.phoneNumber} - ${customerChoice.email} ${customerChoice.lastName} ${customerChoice.firstName}`
    }
    return ''
  }

  return (
    <div className={clsx(classes.autocompleteResultsContainer, classes.customerResultsContainer)} data-cy="customerChoices">
      {customerChoices?.map((customerChoice, index) => (
        <div
          data-cy={`item-option-${customerChoice.lastName}`}
          key={`${customerChoice.email}`}
          role="button"
          tabIndex={index}
          className={classes.customerLine}
          onClick={(): void => makeAChoice(customerChoice)}
          onKeyPress={(e): false | void => e.keyCode === 13 && makeAChoice(customerChoice)}
        >
          <div className={classes.autocompleteAddress}>
            {constructCustomerChoiceString(customerChoice)}
          </div>
        </div>
      ))}
    </div>
  )
}

const OriginCard = ({
  order,
  onChange,
  onCustomerAutocomplete,
  onConfirmCustomerChoice,
  onResetCustomerChoices,
  customerChoices,
  isCartDropoff = false,
  config,
  resetOriginCardData,
}: IProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { sites } = useContext(ContentContext)
  const { user } = useContext(AuthContext)
  const [lastModifiedAutocompleteField, setLastModifiedAutocompleteField] = useState<CustomerSearchField>()
  const warehouseOptions = sites.filter((site) =>
    user?.sites.map((userSite) => userSite.siteId).includes(site.id),
  )

  const handleInputChange = (event: IFieldChange): void => {
    const { target } = event
    const { name, type } = target
    const value = type === 'checkbox' ? target.checked : target.value
    onChange(name as keyof IOrderForm, value)
  }

  const handleAutocompleteFieldChange = (
    event: React.ChangeEvent<{ value: string; name?: string }>,
    field: CustomerSearchField,
  ): void => {
    const { target } = event
    const { name, value } = target
    if (value?.length > 1) {
      setLastModifiedAutocompleteField(field)
      onCustomerAutocomplete(field)
    } else {
      onResetCustomerChoices()
    }
    onChange(name as keyof IOrderForm, value)
  }

  const makeAChoice = (customerChoice: ICustomer): void => {
    onConfirmCustomerChoice(customerChoice)
    onResetCustomerChoices()
  }

  return (
    <Card title={t('OrdersScreen.customerInfo').toUpperCase()} dataCy="originCard">
      <div className={classes.container}>
        {shouldDisplayOrderFormField(isCartDropoff, config?.originSiteId) && (
          <SearchableSelect
            dataCy="warehouseCode"
            label={t('OrdersScreen.warehouse')}
            name="warehouseId"
            selectedValue={order?.warehouseId || ''}
            options={warehouseOptions?.map((site) => ({
              id: site.id,
              name: site.name,
            }))}
            onChange={handleInputChange}
            required
          />
        )}
        <TextField
          data-cy="customerEmail"
          required
          type="email"
          label={t('OrdersScreen.email').toUpperCase()}
          value={order?.customerEmail || ''}
          name="customerEmail"
          onChange={(e) => {
            handleAutocompleteFieldChange(e, CustomerSearchField.Email)
          }}
          autoComplete="off"
        />
        {customerChoices?.length > 0
          && lastModifiedAutocompleteField === CustomerSearchField.Email && (
            <CustomerChoices
              customerChoices={customerChoices}
              makeAChoice={makeAChoice}
              autocompleteField={CustomerSearchField.Email}
            />
        )}
        <TextField
          data-cy="customerLastName"
          required
          type="text"
          label={t('OrdersScreen.customerLastName').toUpperCase()}
          value={order?.customerLastName || ''}
          name="customerLastName"
          onChange={(e) => {
            handleAutocompleteFieldChange(e, CustomerSearchField.LastName)
          }}
          autoComplete="off"
        />
        {customerChoices?.length > 0
          && lastModifiedAutocompleteField === CustomerSearchField.LastName && (
            <CustomerChoices
              customerChoices={customerChoices}
              makeAChoice={makeAChoice}
              autocompleteField={CustomerSearchField.LastName}
            />
        )}
        <TextField
          data-cy="customerFirstName"
          required
          type="text"
          label={t('OrdersScreen.customerFirstName').toUpperCase()}
          value={order?.customerFirstName || ''}
          name="customerFirstName"
          onChange={handleInputChange}
          autoComplete="off"
        />
        <TextField
          data-cy="customerPhoneNumber"
          required
          type="text"
          label={t('OrdersScreen.phoneNumber').toUpperCase()}
          value={order?.customerPhoneNumber || ''}
          name="customerPhoneNumber"
          onChange={(e) => {
            handleAutocompleteFieldChange(e, CustomerSearchField.PhoneNumber)
          }}
          autoComplete="off"
        />
        {customerChoices?.length > 0
          && lastModifiedAutocompleteField === CustomerSearchField.PhoneNumber && (
            <CustomerChoices
              customerChoices={customerChoices}
              makeAChoice={makeAChoice}
              autocompleteField={CustomerSearchField.PhoneNumber}
            />
        )}
        {isCartDropoff && shouldDisplayOrderFormField(isCartDropoff, config?.termsOfSale) && (
          <FormControlLabel
            className={classes.checkbox}
            control={(
              <Checkbox
                checked={order?.areTermsOfSaleAccepted || false}
                onChange={handleInputChange}
                name="areTermsOfSaleAccepted"
              />
            )}
            label={t('OrdersScreen.termsOfSaleAcceptance')}
          />
        )}
        <div className={classes.resetButtonContainer}>
          <Tooltip title={t('OrdersScreen.reset').toUpperCase()}>
            <IconButton data-cy="resetCustomerInfoButton" onClick={resetOriginCardData}>
              <ReplayIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </Card>
  )
}

export default OriginCard
