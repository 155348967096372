import { TextField } from '@material-ui/core'
import React, { useContext, useEffect, useRef, useState } from 'react'
import Card from 'components/Card/Card'
import useStyles from 'constants/cruStyles'
import { useTranslation } from 'react-i18next'
import { ContentContext } from 'store/ContentContext'
import { IWarehouseTimeSlotForm } from 'interfaces/IWarehouseTimeSlot'
import ActiveSwitch from 'components/Inputs/ToggleSwitch'
import { SectorSiteFilter, SiteFilter } from 'components/Inputs/ListFilter'
import { ISector } from 'interfaces/ISector'

interface ITextChange {
  target: {
    value: string
    name: string
    type?: string
    checked?: boolean
  }
}

interface IProps {
  warehouseTimeSlot: IWarehouseTimeSlotForm
  onWarehouseTimeSlotChange: (warehouseTimeSlot: IWarehouseTimeSlotForm) => void
}

const WarehouseTimeSlotConfigurationFormScreen = ({
  warehouseTimeSlot,
  onWarehouseTimeSlotChange,
}: IProps): JSX.Element => {
  const classes = useStyles()

  const isInitialMount = useRef(true)

  const { t } = useTranslation()

  const { sites } = useContext(ContentContext)

  const [allowedSectors, setAllowedSectors] = useState<ISector[]>([])

  useEffect(() => {
    const allSectors: ISector[] = []
    sites?.forEach((site) => {
      if (site?.sectors?.length) {
        allSectors.push(
          ...site.sectors.map((sector) => ({ ...sector, label: sector.label || '-' })),
        )
      }
    })
    const filteredSectors = allSectors.filter(
      (sector) =>
        warehouseTimeSlot.warehouseIds?.findIndex((item) => item === sector.warehouseId) !== -1,
    )
    setAllowedSectors(filteredSectors)
    if (!isInitialMount.current) {
      const sectors = filteredSectors.filter(
        (sector) => warehouseTimeSlot.sectorIds?.findIndex((item) => item === sector.id) !== -1,
      )
      handleSectorChange(sectors.map((sector) => sector.id))
    }
    if (warehouseTimeSlot.sectorIds) {
      isInitialMount.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouseTimeSlot.warehouseIds])

  const handleInputChange = (event: ITextChange): void => {
    const { target } = event
    const { name, value } = target
    if (name === 'label') {
      onWarehouseTimeSlotChange({ ...warehouseTimeSlot, [name]: value })
    } else {
      onWarehouseTimeSlotChange({ ...warehouseTimeSlot, [name]: value ? +value : undefined })
    }
  }

  const handleSwitchChange = (event: ITextChange): void => {
    const { target } = event
    const { name, type } = target
    const value = type === 'checkbox' ? target.checked : target.value
    onWarehouseTimeSlotChange({ ...warehouseTimeSlot, [name]: value })
  }

  const handleSiteChange = (values: string[]): void => {
    onWarehouseTimeSlotChange({ ...warehouseTimeSlot, warehouseIds: values })
  }

  const handleSectorChange = (values: string[]): void => {
    onWarehouseTimeSlotChange({ ...warehouseTimeSlot, sectorIds: values })
  }

  return (
    <Card
      dataCy="timeSlotConfigurationCard"
      title={t('SiteTimeSlotsScreen.configurations').toUpperCase()}
      contentClassName={classes.contentRef}
    >
      <div className={classes.fieldsContainer}>
        <div className={classes.filters}>
          <SiteFilter
            handleChange={(values: string[]): void => handleSiteChange(values)}
            ids={warehouseTimeSlot.warehouseIds || []}
            shouldFillWidth
            required
            dataCy="sitePicker"
            placeholder={t('tablesEntries.sites')}
            shouldUseUserSites={false}
            isUnselectAllowed={false}
            data={sites}
            defaultWhenCleared={sites[0]?.id}
          />
        </div>
        <div className={classes.filters}>
          <SectorSiteFilter
            dataCy="sectorPicker"
            placeholder={t('tablesEntries.sectors')}
            handleChange={(sectorValues: string[]): void => {
              handleSectorChange(sectorValues)
            }}
            ids={warehouseTimeSlot.sectorIds || []}
            shouldFillWidth
            data={allowedSectors}
            disabled={allowedSectors?.length === 0}
          />
        </div>
        <TextField
          data-cy="label"
          error={
            warehouseTimeSlot.label !== undefined && warehouseTimeSlot.label.toString().length === 0
          }
          label={t('SiteTimeSlotsScreen.label').toUpperCase()}
          onChange={handleInputChange}
          type="text"
          name="label"
          required
          value={warehouseTimeSlot.label !== undefined ? warehouseTimeSlot.label : ''}
        />
        <TextField
          data-cy="minDelayInHour"
          error={
            warehouseTimeSlot.minDelayInHour !== undefined
            && warehouseTimeSlot.minDelayInHour.toString().length === 0
          }
          label={t('SiteTimeSlotsScreen.minDelayInHour').toUpperCase()}
          onChange={handleInputChange}
          type="number"
          name="minDelayInHour"
          required
          value={
            warehouseTimeSlot.minDelayInHour !== undefined ? warehouseTimeSlot.minDelayInHour : ''
          }
        />
        <TextField
          data-cy="maxDelayInHour"
          error={
            warehouseTimeSlot.maxDelayInHour !== undefined
            && warehouseTimeSlot.maxDelayInHour.toString().length === 0
          }
          label={t('SiteTimeSlotsScreen.maxDelayInHour').toUpperCase()}
          onChange={handleInputChange}
          type="number"
          name="maxDelayInHour"
          required
          value={
            warehouseTimeSlot.maxDelayInHour !== undefined ? warehouseTimeSlot.maxDelayInHour : ''
          }
        />
      </div>
      <ActiveSwitch
        dataCy="active"
        onChange={handleSwitchChange}
        name="active"
        checked={warehouseTimeSlot.active}
        label={t('SiteTimeSlotsScreen.active').toUpperCase()}
      />
    </Card>
  )
}

export default WarehouseTimeSlotConfigurationFormScreen
