import Cookies from 'js-cookie'
import Api from 'services/api'
import { FirebaseAuth } from 'services/firebase'
import { IUser } from 'interfaces/IUser'
import { IError, isIError } from 'api/types'
import { IUserFilter, IUserData } from 'interfaces/interfaces'
import { IUserInfos } from 'interfaces/IUserInfos'
import { IUserCredential } from 'interfaces/IUserCrendential'
import { IFeature } from 'interfaces/IFeature'
import { IUserPreferences } from 'interfaces/IUserPreferences'
import { USER_ROLES } from 'constants/constants'

interface ILoginData {
  email: string
  password: string
}

async function loginUser({ email, password }: ILoginData): Promise<IUserCredential | IError> {
  try {
    const res = await FirebaseAuth().signInWithEmailAndPassword(email, password)
    const token = await res.user?.getIdToken()
    if (token) {
      Cookies.set('Authorization', `Bearer ${token}`, {
        // tous les sous-domaines du domaine défini ont accès au cookie
        domain: 'klareo.com',
        // tous les chemins qui commencent par '/' ont accès au cookie
        path: '/',
      })
      const user = await validateUserToken({ token })
      if (user && !isIError(user) && !user.roles.find((role) => role === USER_ROLES.driver)) {
        const userInfo = {
          id: user.id,
          lastLoginDate: new Date().toISOString(),
        }
        await update(user.id, userInfo)
      }
    }
    return { ...res }
  } catch (error) {
    return { error: { message: error.message } }
  }
}

interface ITokenData {
  token: string | undefined
}

async function validateUserToken({ token }: ITokenData): Promise<IUserData | IError> {
  try {
    Api.setAccessToken(token)
    const user = await Api.post('users/check')
    Api.setTenantIdHeader(user?.tenantId)
    return user
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const parseSingleUser = (user: IUser): IUser => ({
  id: user.id,
  firstName: user.firstName,
  lastName: user.lastName,
  email: user.login,
  companyId: user.companyId,
  roles: user.roles,
  active: user.active,
  sites: user.sites,
  tenant: user.tenant,
})

const parseUsers = (users: IUser[]): Array<IUser> => users.map((elem) => parseSingleUser(elem))

const getUsers = async (
  filters?: IUserFilter,
  offset?: number,
  rowsPerPage?: number,
  sortBy?: string,
  sortDirection?: number,
  isAdmin = false,
): Promise<{ users: Array<IUser>; count: number } | IError> => {
  try {
    const filtersToApply = {
      companyIds: filters?.company,
      roles: filters?.roles,
      tenantIds: filters?.tenants,
      siteIds: filters?.siteIds,
      ...((filters?.active?.length || 0) === 1 && {
        active: filters?.active && filters?.active[0],
      }),
      ...((filters?.searchText?.length || 0) >= 3 && { search: filters?.searchText }),
    }
    const res = await Api.get(isAdmin ? 'users/admin' : 'users', {
      ...filtersToApply,
      offset,
      limit: rowsPerPage,
      sortBy: sortBy || 'firstName',
      sortDirection: sortDirection || 1,
    })
    return { users: parseUsers(res.items), count: res.count }
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const create = async (user: IUserInfos): Promise<IUser | IError> => {
  try {
    const res = await Api.post('users', user)
    return res
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

const update = async (id: string, user: IUserInfos): Promise<IUser | IError> => {
  try {
    const response = await Api.patch(`users/${id}`, user)
    return response
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

const get = async (id: string, isAdmin = false): Promise<IUser | IError> => {
  try {
    const user = await Api.get(isAdmin ? `users/details/${id}/admin` : `users/details/${id}`)
    return parseSingleUser(user)
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const checkFeatures = async (): Promise<Array<IFeature> | IError> => {
  try {
    const features = await Api.get('users/features')
    return features.data
  } catch (error) {
    return { error: { message: error.message } }
  }
}

interface IConfigReturn {
  id: string
  preferences: IUserPreferences
}

const getUserConfig = async ({ userId }: { userId: string }): Promise<IConfigReturn | IError> => {
  try {
    const res = await Api.get('user-preferences', { userId })
    return {
      id: res.id,
      preferences: res.preferences ? JSON.parse(res.preferences) : {},
    }
  } catch (error) {
    return { error: { message: error.message } }
  }
}

interface IUserConfigCall {
  config: Partial<IUserPreferences>
  userId?: string
  configId?: string
}

const createUserConfig = async ({
  config,
  userId,
}: IUserConfigCall): Promise<IConfigReturn | IError> => {
  try {
    const preferences = JSON.stringify(config)
    const res = await Api.post('user-preferences', { preferences, userId })
    return res
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

const updateUserConfig = async ({
  config,
  configId,
  userId,
}: IUserConfigCall): Promise<{ status: boolean } | IError> => {
  try {
    const preferences = JSON.stringify(config)
    await Api.patch(`user-preferences/${configId}`, { preferences, userId })
    return { status: true }
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

const getCustomToken = async (email: string): Promise<string | IError> => {
  try {
    return await Api.get('users/generate-custom-token', { email })
  } catch (error) {
    return { error: { message: error.message } }
  }
}

async function loginWithCustomToken(customToken: string): Promise<IUserCredential | IError> {
  try {
    const res = await FirebaseAuth().signInWithCustomToken(customToken)
    const token = await res.user?.getIdToken()
    if (token) {
      Cookies.set('Authorization', `Bearer ${token}`, {
        // tous les sous-domaines du domaine défini ont accès au cookie
        domain: 'klareo.com',
        // tous les chemins qui commencent par '/' ont accès au cookie
        path: '/',
      })
    }
    return { ...res }
  } catch (error) {
    return { error: { message: error.message } }
  }
}

export default {
  loginUser,
  validateUserToken,
  getUsers,
  create,
  get,
  update,
  checkFeatures,
  getUserConfig,
  createUserConfig,
  updateUserConfig,
  getCustomToken,
  loginWithCustomToken,
}
