import React from 'react'
import { TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { IWarehouseCartDropoffConfig } from 'interfaces/IWarehouseCartDropoffConfig'
import { IFieldChange } from 'interfaces/interfaces'
import { DeliveryTypeFilter, TransportTypeFilter } from 'components/Inputs/ListFilter'
import { isDefined } from 'utils/functions'
import { ConfigCard, FieldDetails } from './ConfigCard'
import useStyles from './styles'

export interface IProps {
  data: IWarehouseCartDropoffConfig
  onChange: (fieldName: string, value: unknown, propertyName?: string) => void
}

export const OrderInfoConfig = ({ data, onChange }: IProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()

  const handleInputChange = (event: IFieldChange): void => {
    const { target } = event
    const { name } = target
    const value = target.type === 'checkbox' ? target.checked : target.value

    onChange(name as keyof IWarehouseCartDropoffConfig, value === '' ? undefined : value)
  }

  const handleSelectChange = (values: string[], name: string): void => {
    onChange(name as keyof IWarehouseCartDropoffConfig, values[0] || undefined)
  }

  const orderInfoFields: FieldDetails[] = [
    {
      name: 'orderNumber',
      component: (
        <TextField
          data-cy="orderNumber"
          type="text"
          label={t('CartDropoffConfigurationScreen.orderNumber').toUpperCase()}
          value={data.orderNumber?.defaultValue || ''}
          name="orderNumber"
          onChange={handleInputChange}
          fullWidth
        />
      ),
    },
    {
      name: 'value',
      component: (
        <TextField
          data-cy="value"
          type="number"
          label={t('CartDropoffConfigurationScreen.value').toUpperCase()}
          value={data.value?.defaultValue || ''}
          name="value"
          onChange={handleInputChange}
          fullWidth
        />
      ),
    },
    {
      name: 'deliveryType',
      component: (
        <DeliveryTypeFilter
          dataCy="deliveryType"
          handleChange={(values: string[]): void => handleSelectChange(values, 'deliveryType')}
          ids={
            isDefined(data.deliveryType?.defaultValue)
              ? [data.deliveryType?.defaultValue as string]
              : []
          }
          shouldFillWidth
          customFormClass={classes.fullWidth}
          removeMargin
          onlyOneValue
          placeholder={t('CartDropoffConfigurationScreen.deliveryType').toUpperCase()}
          isUnselectAllowed={false}
        />
      ),
    },
    {
      name: 'transportType',
      component: (
        <TransportTypeFilter
          dataCy="transportType"
          handleChange={(values: string[]): void => handleSelectChange(values, 'transportType')}
          ids={
            isDefined(data.transportType?.defaultValue)
              ? [data.transportType?.defaultValue as string]
              : []
          }
          shouldFillWidth
          customFormClass={classes.fullWidth}
          removeMargin
          onlyOneValue
          placeholder={t('CartDropoffConfigurationScreen.transportType').toUpperCase()}
          isUnselectAllowed={false}
        />
      ),
    },
    {
      name: 'scanTicket',
      component: (
        <div className={classes.labelDiv}>
          {t('CartDropoffConfigurationScreen.scanTicket').toUpperCase()}
        </div>
      ),
    },
    {
      name: 'numberOfArticles',
      component: (
        <TextField
          data-cy="value"
          type="number"
          label={t('CartDropoffConfigurationScreen.numberOfArticles').toUpperCase()}
          value={data.numberOfArticles?.defaultValue ?? ''}
          name="numberOfArticles"
          onChange={handleInputChange}
          fullWidth
        />
      ),
    },
    {
      name: 'estimatedWeight',
      component: (
        <TextField
          data-cy="value"
          type="number"
          label={t('CartDropoffConfigurationScreen.estimatedWeight').toUpperCase()}
          value={data.estimatedWeight?.defaultValue ?? ''}
          name="estimatedWeight"
          onChange={handleInputChange}
          fullWidth
        />
      ),
    },
    {
      name: 'parcelCodes',
      component: (
        <TextField
          data-cy="parcelCodes"
          type="text"
          label={t('CartDropoffConfigurationScreen.parcelCodes').toUpperCase()}
          value={data.parcelCodes?.defaultValue || ''}
          name="parcelCodes"
          onChange={handleInputChange}
          fullWidth
        />
      ),
    },
  ]

  return (
    <ConfigCard
      data={data}
      onChange={onChange}
      fields={orderInfoFields}
      sectionName={t('CartDropoffConfigurationScreen.cards.orderInfo')}
    />
  )
}
