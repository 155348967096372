import React from 'react'
import { Tooltip } from '@devexpress/dx-react-chart-material-ui'

import useStyles from './styles'

const TooltipArrow = (props: JSX.IntrinsicAttributes & Tooltip.ArrowProps): JSX.Element => {
  const classes = useStyles()
  return <Tooltip.Arrow {...props} className={classes.arrow} />
}

export default TooltipArrow
