import React from 'react'
import styled from 'styled-components'
import Fab from '@material-ui/core/Fab'
import { Paper } from '@material-ui/core'
import { AddCircle } from '@material-ui/icons'
import { SpeedDial } from '@material-ui/lab'
import { DroppableProvided } from 'react-beautiful-dnd'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100vh - 139px);
  width: 100%;
  position: relative;
`

const StyledFab = styled(Fab)`
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 500;
`

interface IMapContainer {
  mapWidth: string
}

const StyledMapContainer = styled.div<IMapContainer>`
  display: flex;
  transition-duration: 0.5s;
  min-width: ${(props): string => props.mapWidth};
`

const CustomPaper = styled(Paper)`
  position: absolute;
  min-width: 60%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 500;
  padding: 50px;
`

const StyledLoaderContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #00000080;
`

const AutomaticDroppable = styled.div`
  height: 100px;
  background-color: #00000030;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: createBox 0.25s;
`

interface IDropContainer {
  provided: DroppableProvided
}

const AutomaticDroppableContainer = ({ provided }: IDropContainer): JSX.Element => (
  <AutomaticDroppable ref={provided.innerRef}>
    <AddCircle />
    {provided.placeholder}
  </AutomaticDroppable>
)

const StyledSpeedDial = styled(SpeedDial)`
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 500;
`

const Header = styled(Paper)`
  padding: 10px;
  background-color: slategray;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: max-height 0.25s ease-in;
`

export {
  StyledContainer,
  StyledFab,
  StyledMapContainer,
  CustomPaper,
  StyledLoaderContainer,
  AutomaticDroppableContainer,
  StyledSpeedDial,
  Header,
}
