import Api from 'services/api'
import { IVehicle } from 'interfaces/IVehicle'
import { IError } from 'api/types'
import { IVehicleFilters } from 'interfaces/interfaces'
import { IAvailability } from 'interfaces/IAvailability'
import { IVehicleAvailability } from 'interfaces/IVehicleAvailability'
import { IVehicleReportsList } from 'interfaces/IVehicleReportsList'

const parseSingleVehicles = (vehicle: IVehicle): IVehicle => ({
  id: vehicle.id,
  name: vehicle.name,
  licencePlate: vehicle.licencePlate,
  lastKnownKilometer: vehicle.lastKnownKilometer,
  carrierId: vehicle.carrierId,
  vehicleType: vehicle.vehicleType,
  active: vehicle.active,
  siteId: vehicle.siteId,
  thirdPartyActivation: vehicle.thirdPartyActivation,
})

const parseVehicles = (vehicles: IVehicle[]): Array<IVehicle> =>
  vehicles.map((vehicle) => parseSingleVehicles(vehicle))

const getList = async (
  filters?: IVehicleFilters,
  offset?: number,
  rowsPerPage?: number,
  sortBy?: string,
  sortDirection?: number,
): Promise<{ vehicles: Array<IVehicle>; count: number } | IError> => {
  try {
    const filtersToApply = {
      ...filters,
      carrierIds: filters?.carriers,
      ...((filters?.active?.length || 0) === 1 && {
        active: filters?.active && filters?.active[0],
      }),
      ...((filters?.searchText?.length || 0) >= 3 && { search: filters?.searchText }),
    }
    const response = await Api.get('vehicles', {
      ...filtersToApply,
      offset,
      limit: rowsPerPage,
      sortBy: sortBy || 'name',
      sortDirection: sortDirection || 1,
    })
    return { vehicles: parseVehicles(response.items), count: response.count }
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const create = async (vehicle: IVehicle): Promise<IVehicle | IError> => {
  try {
    const response = await Api.post('vehicles', {
      ...vehicle,
      availability:
        vehicle.events
        && vehicle.events.map((event) => ({
          label: event.title,
          beginDate: event.startDate,
          endDate: event.endDate,
          recurrenceRule: event.rRule,
          exDate: event.exDate,
        })),
      events: undefined,
      id: undefined,
    })
    return response
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

const get = async (id: string): Promise<IVehicle | IError> => {
  try {
    const response = await Api.get(`vehicles/details/${id}`)
    return {
      ...response,
      events:
        response?.availability
        && response?.availability.map((availability: IAvailability) => ({
          id: availability.groupId,
          startDate: availability.beginDate,
          endDate: availability.endDate,
          title: availability.label,
          rRule: availability.recurrenceRule,
          exDate: availability.exDate,
        })),
    }
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const update = async (id: string, vehicle: IVehicle): Promise<IVehicle | IError> => {
  try {
    const response = await Api.patch(`vehicles/${id}`, {
      ...vehicle,
      availability:
        vehicle.events
        && vehicle.events.map((event) => ({
          label: event.title,
          beginDate: event.startDate,
          endDate: event.endDate,
          recurrenceRule: event.rRule,
          exDate: event.exDate,
        })),
      events: undefined,
    })
    return response
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

const getVehiclesAvailabilitiesByPlan = async (
  planId: string,
  isForGlobalOptim: boolean,
): Promise<IVehicleAvailability[] | IError> => {
  try {
    const response = await Api.get('vehicles/vehicles-availabilities-by-plan', {
      planId,
      isForGlobalOptim,
    })
    return response.availableVehicles
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getVehicleReports = async (
  vehicleId: string,
  offset?: number,
  rowsPerPage?: number,
  sortBy?: string,
  sortDirection?: number,
): Promise<IVehicleReportsList | IError> => {
  try {
    const response = await Api.get('vehicles/reports', {
      vehicleId,
      offset,
      limit: rowsPerPage,
      sortBy: sortBy || 'createdOn',
      sortDirection: sortDirection || -1,
    })
    return response
  } catch (error) {
    return { error: { message: error.message } }
  }
}

export default {
  getList,
  create,
  get,
  update,
  getVehiclesAvailabilitiesByPlan,
  getVehicleReports,
}
