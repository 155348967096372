import React, { useContext } from 'react'
import clsx from 'clsx'
import { AppBar, Toolbar, IconButton } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import { useLocation } from 'react-router-dom'

import NotificationButton from 'components/Notifications/NotificationButton'
import { SiteFilter } from 'components/Inputs/ListFilter'
import { DefaultSiteContext } from 'store/DefaultSiteContext'
import { AuthContext } from 'store/AuthContext'
import AccountButton from './AccountButton'
import Breadcrumbs from './Breadcrumbs'
import useStyles from './styles'

interface ICustomAppBar {
  opened: boolean
  toggleDrawer(): void
}

const CustomAppBar = ({ opened, toggleDrawer }: ICustomAppBar): JSX.Element => {
  const classes = useStyles()
  const location = useLocation()
  const { defaultSiteId, setDefaultSiteAndFilters } = useContext(DefaultSiteContext)
  const { shouldDisplayNotificationBell } = useContext(AuthContext)

  const handleDefaultSiteChange = (value: string[]) => {
    setDefaultSiteAndFilters(value.length > 0 ? value[0] : undefined)
  }

  return (
    <AppBar
      position="fixed"
      elevation={1}
      className={clsx(classes.appBar, {
        [classes.appBarShift]: opened,
      })}
    >
      <Toolbar>
        <IconButton
          aria-label="open drawer"
          onClick={toggleDrawer}
          edge="start"
          className={clsx(classes.menuButton, {
            [classes.hide]: opened,
          })}
        >
          <MenuIcon />
        </IconButton>
        <Breadcrumbs path={location.pathname} />
        <div className={classes.emptySpace} />
        <SiteFilter
          dataCy="defaultSitePicker"
          handleChange={handleDefaultSiteChange}
          ids={[defaultSiteId as string]}
          onlyOneValue
          isUnselectAllowed={false}
          isForTopBar
        />
        <div className={classes.emptySpace} />
        {shouldDisplayNotificationBell && <NotificationButton />}
        <AccountButton />
      </Toolbar>
    </AppBar>
  )
}

export default CustomAppBar
