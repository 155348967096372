import React, { useContext, useEffect, useMemo, useState } from 'react'
import { DisputeContext } from 'store/DisputeContext'
import CustomTable from 'components/Table/CustomTable'
import { Filter, FilterKey } from 'constants/filters'
import { COLUMNS_MODEL_DISPUTES_LIST } from 'constants/table'
import { FiltersContext } from 'store/FiltersContext'
import { DISPUTE_REASON_CODES_TYPES, IDisputeFilters } from 'interfaces/IDispute'
import {
  SiteFilter,
  DriverFilter,
  CarrierFilter,
  DisputeReasonCodeFilter,
  DisputeReasonTypeFilter,
} from 'components/Inputs/ListFilter'
import AppConfigProvider from 'store/AppConfigContext'
import DateRangePicker from 'components/Inputs/DateRangePicker'
import moment from 'moment'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { DateType } from 'constants/constants'
import { Table, TableBody, TableCell, TableHead, TableRow, useTheme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const DisputesListScreen = (): JSX.Element => {
  const { setFilter, filters } = useContext(FiltersContext)
  const { getDisputeReasonCodes, searchDisputes, disputesCount, disputes, disputeReasonCodes } = useContext(DisputeContext)
  const { t } = useTranslation()
  const filterKey = FilterKey.disputes
  const sites = filters[filterKey][Filter.sites] as string[]
  const driversFilter = filters[filterKey][Filter.drivers] as string[]
  const disputeReasonCodesFilter = filters[filterKey][Filter.disputeReasonCodes] as string[]
  const carriersFilter = filters[filterKey][Filter.carriers] as string[]
  const startDateFilter = filters[filterKey][Filter.startDate] as string
  const endDateFilter = filters[filterKey][Filter.endDate] as string
  const [reasonTypesSelected, setReasonTypesSelected] = useState(
    DISPUTE_REASON_CODES_TYPES?.map((type) => type.value as string),
  )
  const theme = useTheme()

  useEffect(() => {
    getDisputeReasonCodes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setFilter(
      filterKey,
      Filter.disputeReasonCodes,
      getSelectableReasonCodes().map((item) => item.code),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reasonTypesSelected, disputeReasonCodes])

  const getSelectableReasonCodes = () => {
    const reasonTypes = DISPUTE_REASON_CODES_TYPES.filter((item) =>
      reasonTypesSelected.includes(item.value),
    )
    const items = disputeReasonCodes?.filter((reasonCode) =>
      reasonTypes.some((reasonType) => reasonCode.types.includes(reasonType?.value)),
    )
    return items
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderDetailPanel = (rowData: object | any) => (
    <>
      <Table
        size="small"
        aria-label="a dense table"
        style={{ margin: 4, width: '-webkit-fill-available', boxShadow: '0 0 6px 2px grey' }}
      >
        <TableHead
          style={{
            border: 'none',
            borderBottom: '1px solid #A8A8A8',
          }}
        >
          <TableRow>
            <TableCell align="left">{t('tablesEntries.ean')}</TableCell>
            <TableCell align="left">{t('tablesEntries.quantity')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowData
            && disputes[rowData.tableData.id].eanList.map(
              (
                eanListItem: any, // eslint-disable-line
                index: number,
              ) => (
                <TableRow
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  style={{
                    backgroundColor:
                      index % 2 === 0 ? theme.color.tableRowEven : theme.color.subTableRowOdd,
                  }}
                >
                  <TableCell align="left">{eanListItem.ean}</TableCell>
                  <TableCell align="left">{eanListItem.quantity}</TableCell>
                </TableRow>
              ),
            )}
        </TableBody>
      </Table>
    </>
  )

  const handleStartChange = (startValue: MaterialUiPickersDate): void => {
    setFilter(filterKey, Filter.startDate, moment(startValue).toISOString())
  }

  const handleEndChange = (endValue: MaterialUiPickersDate): void => {
    setFilter(filterKey, Filter.endDate, moment(endValue).toISOString())
  }

  const filtersToDisplay = useMemo(
    (): JSX.Element => (
      <>
        <DateRangePicker
          startDate={startDateFilter}
          endDate={endDateFilter}
          onChangeStart={handleStartChange}
          onChangeEnd={handleEndChange}
          hideShiftPicker
          dateType={DateType.Date}
          maxStartDate={moment().toISOString()}
          maxEndDate={moment().toISOString()}
        />
        <SiteFilter
          dataCy="sitePicker"
          handleChange={(siteValues: string[]): void => {
            setFilter(filterKey, Filter.sites, siteValues)
          }}
          ids={sites}
          isUnselectAllowed={false}
        />
        <CarrierFilter
          handleChange={(carrierValues: string[]): void => {
            setFilter(filterKey, Filter.carriers, carrierValues)
            setFilter(filterKey, Filter.drivers, [])
          }}
          ids={carriersFilter}
          dataCy="carrierPicker"
          siteIds={sites}
          filterKey={filterKey}
        />
        <DriverFilter
          handleChange={(driverValues: string[]): void => {
            setFilter(filterKey, Filter.drivers, driverValues)
          }}
          ids={driversFilter}
          siteIds={sites}
          carrierIds={carriersFilter}
          filterKey={filterKey}
        />
        <DisputeReasonTypeFilter
          dataCy="disputeReasonTypesPicker"
          handleChange={(reasonTypes): void => {
            setReasonTypesSelected(reasonTypes)
          }}
          ids={reasonTypesSelected}
          data={DISPUTE_REASON_CODES_TYPES.map((item) => ({ ...item, id: `${item?.value}` }))}
        />
        <DisputeReasonCodeFilter
          dataCy="disputeReasonCodesPicker"
          handleChange={(reasonCodesFilter): void => {
            setFilter(filterKey, Filter.disputeReasonCodes, reasonCodesFilter)
          }}
          ids={disputeReasonCodesFilter}
          data={getSelectableReasonCodes().map((item) => ({ ...item, id: `${item?.code}` }))}
        />
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      disputeReasonCodesFilter,
      reasonTypesSelected,
      startDateFilter,
      endDateFilter,
      sites,
      driversFilter,
      carriersFilter,
    ],
  )

  return (
    <>
      <CustomTable
        showExtendedDateFilter
        extendedDateFilter={{
          last7days: true,
          today: true,
          yesterday: true,
        }}
        columns={COLUMNS_MODEL_DISPUTES_LIST}
        listData={disputes}
        filterKey={FilterKey.disputes}
        renderDetailPanel={renderDetailPanel}
        columnConfigName="disputesList"
        filterFieldsToDisplay={filtersToDisplay}
        defaultSortDirection="desc"
        count={disputesCount}
        updateList={(
          filtersToAdd?: IDisputeFilters,
          offset?: number,
          rowsPerPage?: number,
          sortField?: number,
          sortDirection?: string,
        ) => {
          searchDisputes(
            {
              ...filtersToAdd,
              carrierIds: carriersFilter,
              driverIds: driversFilter,
              disputeReasonCodes: disputeReasonCodesFilter,
              startDate: startDateFilter,
              siteIds: sites,
              endDate: endDateFilter,
            },
            offset,
            rowsPerPage,
            sortField,
            sortDirection,
          )
        }}
      />
    </>
  )
}

export default (): JSX.Element => (
  <AppConfigProvider>
    <DisputesListScreen />
  </AppConfigProvider>
)
