import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    switchBlock: {
      display: 'flex',
    },
    switchContainer: {
      margin: 'auto',
    },
    activitySwitch: {
      thumbOnColor: theme.color.green,
      trackOnColor: theme.color.red,
    },
    tourStatusAndIconWrapper: {
      minWidth: '100px',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: '4px',
    },
    droppedButton: {
      textTransform: 'none',
    },
  }),
)
