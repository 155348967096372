import React, { useContext } from 'react'
import { DraggableProvided, DraggableStateSnapshot, NotDraggingStyle } from 'react-beautiful-dnd'
import styled from 'styled-components'
import { useTheme } from '@material-ui/core/styles'
import { Paper } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import { IVisit } from 'interfaces/IOrders'
import { dateToTimeFormat } from 'utils/dateFormat'
import { ExecutionContext } from 'store/ExecutionContext'
import useStyles from '../styles'

type TDraggableStyle = React.CSSProperties | NotDraggingStyle | undefined

export const getItemStyle = (
  isDragging: boolean,
  draggableStyle: TDraggableStyle,
): React.CSSProperties => ({
  userSelect: 'none',
  padding: '6px',
  margin: '8px 0 8px 0',
  background: isDragging ? 'grey' : 'white',
  color: isDragging ? 'white' : 'black',
  ...draggableStyle,
})

interface IIndicatorProps {
  color?: string
  defaultColor: string
}

export const Indicator = styled.div<IIndicatorProps>`
  width: 8px;
  background-color: ${(props): string => props.color || props.defaultColor};
  border-radius: 4px;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 6px;
  padding-right: 6px;
  width: 220px;
  gap: 4px;
  flex: 1;
`
export const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const TextName = styled.p`
  font-weight: bold;
  font-size: 14px;
  margin: 0 6px;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Status = styled.div`
  font-weight: bold;
  font-size: 12px;
  margin: 0;
  padding: 0;
  height: fit-content;
  border-radius: 8px;
  flex-shrink: 0;
`

export const Address = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

export const Label = styled.div`
  width: 24px;
  line-height: 20px;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  border: 2px solid #000;
  flex-shrink: 0;
`

export const CardFooter = styled.div`
  display: flex;
  align-items: center;
  font-size: small;
  justify-content: space-between;
`

export const Bold = styled.span({
  fontWeight: 'bold',
})

interface IListItem {
  visit: IVisit
  providedMarker?: DraggableProvided
  snapshotMarker?: DraggableStateSnapshot
}

const VisitsListItem = ({ visit, providedMarker, snapshotMarker }: IListItem): JSX.Element => {
  const { t } = useTranslation()
  const theme = useTheme()
  const classes = useStyles()
  const { highlightedElement, setHighlightedElement } = useContext(ExecutionContext)
  const { arrivalMinDate, arrivalMaxDate } = visit
  let timeSlot = ''
  if (arrivalMinDate && arrivalMaxDate) {
    timeSlot = `${dateToTimeFormat(arrivalMinDate)}-${dateToTimeFormat(arrivalMaxDate)}`
  }

  const label = visit.destinationAddress.label || ''

  return (
    <Paper
      elevation={8}
      ref={providedMarker?.innerRef}
      {...providedMarker?.draggableProps}
      {...providedMarker?.dragHandleProps}
      style={getItemStyle(!!snapshotMarker?.isDragging, providedMarker?.draggableProps.style)}
      className={clsx(
        classes.clickable,
        highlightedElement?.id === visit.visitId && classes.highlightedCard,
      )}
      onClick={(e) => {
        e.stopPropagation()
        setHighlightedElement({
          id: visit.visitId,
          latitude: visit.destinationAddress.latitude,
          longitude: visit.destinationAddress.longitude,
        })
      }}
      id={visit.visitId}
    >
      <div style={{ display: 'flex', position: 'relative' }}>
        <Indicator defaultColor={theme.color.secondaryHover as string} />
        <ContentContainer>
          <ContentHeader>
            <Label>{label?.trim()?.slice(0, 2).toUpperCase() || '-'}</Label>
            <TextName>{label}</TextName>
            <Status>{`${t('PlanningScreen.quantityShortForm')}: ${visit.quantity || 0}`}</Status>
          </ContentHeader>
          <Address>{visit.destinationAddress.full || '-'}</Address>
          <CardFooter>
            {timeSlot !== '' && (
              <div>
                <Bold>[{timeSlot}]</Bold>
              </div>
            )}
            <Bold>
              {`${t('PlanningScreen.ordersCountShortForm')}: ${visit.orderIds?.length || 0}`}
            </Bold>
          </CardFooter>
        </ContentContainer>
      </div>
    </Paper>
  )
}

export default VisitsListItem
