import React, { useEffect, useState, useCallback, useContext } from 'react'
import Modal from '@material-ui/core/Modal'
import { Paper, Table, TableContainer, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

import PlansApi from 'api/plans'
import DateRangePicker from 'components/Inputs/DateRangePicker'
import { isIError } from 'api/types'
import { IPlan, IPlanTourDeleteResponseItem } from 'interfaces/IPlan'
import { FeedbackContext } from 'store/FeedbackContext'

import { ROUTES_PATH } from 'navigation/RoutesPath'
import { DefaultSiteContext } from 'store/DefaultSiteContext'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog'
import useStyles from '../OptimizeModal/styles'
import { CustomPaper } from '../BasicComponents'
import { PlanificationContext } from '../../PlanningStore'
import ListItem from './ListItem'
import ButtonsRow from '../ButtonsRow'
import DeletePlanModal from '../DeletePlanModal'

interface IPlanCreationModal {
  isVisible: boolean
  toggleModal(): void
}

function FiltersModal({ isVisible, toggleModal }: IPlanCreationModal): JSX.Element {
  const classes = useStyles()
  const { openErrorSnack, toggleLoader } = useContext(FeedbackContext)

  const {
    plansList,
    setPlansList,
    selectedPlan: ctxSelectedPlan,
    setSelectedPlan: ctxSetSelectedPlan,
    setIsPlanTourStatusChangeDone,
    isMultisite,
  } = useContext(PlanificationContext)
  const [startDate, setStartDate] = useState<string>(moment().hours(0).minutes(0).toISOString())
  const [endDate, setEndDate] = useState<string>(moment().hours(23).minutes(59).toISOString())
  const [selectedPlan, setSelectedPlan] = useState(ctxSelectedPlan)
  const [isDeleteTourFinishModalOpen, setIsDeleteTourFinishModalOpen] = useState<boolean>(false)
  const [deleteTourFinishMessage, setDeleteTourFinishMessage] = useState<string>('')

  const [planIdToDelete, setPlanIdToDelete] = useState<string>()
  const { t } = useTranslation()
  const history = useHistory()

  const { defaultSiteId } = useContext(DefaultSiteContext)

  const getPlans = useCallback(async (): Promise<void> => {
    toggleLoader(true)
    const res = await PlansApi.getPlans({
      startDate: startDate || undefined,
      endDate: endDate || undefined,
      warehouseId: defaultSiteId || undefined,
    })
    if (!isIError(res)) {
      setPlansList(res)
    } else {
      openErrorSnack(res.error.message)
    }
    toggleLoader(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, defaultSiteId])

  useEffect(() => {
    getPlans()
  }, [startDate, endDate, getPlans, defaultSiteId])

  const setNotDeletedModalOpen = (notDeletePlanTours: IPlanTourDeleteResponseItem[]) => {
    setDeleteTourFinishMessage(
      t('PlanningScreen.notDeletedToursMessage', {
        stringifiedTrips: notDeletePlanTours.map((item) => item.planTourNumber).join(', '),
      }),
    )
    setIsDeleteTourFinishModalOpen(true)
  }

  function onClickPlanItem(clickedPlan: IPlan): void {
    setSelectedPlan((prev: IPlan | null): IPlan | null => {
      if (prev && prev.id === clickedPlan.id) {
        return null
      }
      return clickedPlan
    })
    // TODO uncomment this when we want more than 1 plan selected
    // setPlansList((prev: IPlan[]): IPlan[] => {
    //   const plansArray = [...prev]
    //   const index = prev.findIndex(planvisit => planvisit.id === clickedPlanId)
    //   plansArray[index].selected = !plansArray[index].selected
    //   return plansArray
    // })
  }

  function onPressSave(): void {
    ctxSetSelectedPlan(selectedPlan)
    setIsPlanTourStatusChangeDone(true)
    const path = isMultisite ? ROUTES_PATH.multisitePlanning : ROUTES_PATH.planning
    history.push(`${path}?id=${selectedPlan?.id}`)
    toggleModal()
  }

  async function onPressDeleteItem(clickedPlan: IPlan): Promise<void> {
    /*
      If you are trying to understand why the plan is still here after delete,
      increment this counter by 1.
      numberOfTasksToUnderstandTheBug = 2

      SPOILER_ALERT
      The front is working for this, the back says that the task is done,
      But the plan is only deleted once mapotempo has done computing things.
    */
    setPlanIdToDelete(clickedPlan.id)
  }

  return (
    <>
      <Modal
        open={isVisible}
        onClose={toggleModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <CustomPaper>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography data-cy="modal-description">
                {t('PlanningScreen.planSelection')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <DateRangePicker
                onChangeStart={(date): void => setStartDate(moment(date).toISOString())}
                onChangeEnd={(date): void => setEndDate(moment(date).toISOString())}
                startDate={startDate}
                endDate={endDate}
              />
            </Grid>
            <Grid item xs={12}>
              <Paper className={clsx(classes.paper, classes.listContainer, classes.planList)}>
                <TableContainer>
                  <Table aria-label="simple table">
                    {plansList.map((plan) => (
                      <ListItem
                        key={plan.id}
                        label={plan.label}
                        deliveryTypes={plan.deliveryTypes.map(String)}
                        transportTypes={plan.transportTypes}
                        startDate={plan.startDate}
                        endDate={plan.endDate}
                        selected={selectedPlan?.id === plan.id}
                        onClick={(): void => onClickPlanItem(plan)}
                        onClickDelete={(): Promise<void> => onPressDeleteItem(plan)}
                        dataCy={plan.label}
                      />
                    ))}
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
            <ButtonsRow toggleModal={toggleModal} onClickCTA={onPressSave} />
          </Grid>
        </CustomPaper>
      </Modal>
      <DeletePlanModal
        planIdToDelete={planIdToDelete}
        setPlanIdToDelete={setPlanIdToDelete}
        onEndDelete={getPlans}
        setNotDeletedModalOpen={setNotDeletedModalOpen}
      />
      {isDeleteTourFinishModalOpen && (
        <ConfirmationDialog
          open={isDeleteTourFinishModalOpen}
          onClose={() => {
            setDeleteTourFinishMessage('')
            setIsDeleteTourFinishModalOpen(false)
          }}
          message={deleteTourFinishMessage}
          showCancelOption={false}
        />
      )}
    </>
  )
}

export default FiltersModal
