import React from 'react'
import styled from 'styled-components'
import { Fab, Tooltip } from '@material-ui/core'
import { Save } from '@material-ui/icons'

interface SaveClusterBtnProps {
  saveClusters: () => void
  isDisabled?: boolean
  title: string
}

const SaveButton = styled(Fab)`
  background-color: white;
  margin-top: 5px;
`

export default ({ saveClusters, isDisabled, title }: SaveClusterBtnProps): JSX.Element => (
  <Tooltip title={title} aria-label={title}>
    <SaveButton onClick={saveClusters} disabled={isDisabled}>
      <Save color="primary" fontSize="large" />
    </SaveButton>
  </Tooltip>
)
