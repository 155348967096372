import React from 'react'
import ReactDOM from 'react-dom'
import 'i18n/i18n'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import App from 'screens/AppContainer'
import './index.css'
import { APP_ENVIRONMENT, EXPECTED_DOMAIN } from 'constants/env'
import { checkDomainAndRedirect } from 'utils/functions'
import * as serviceWorker from './serviceWorker'

checkDomainAndRedirect(EXPECTED_DOMAIN)

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: APP_ENVIRONMENT || 'unknown',
  integrations: [
    new Integrations.BrowserTracing({
      startTransactionOnLocationChange: false,
      startTransactionOnPageLoad: false,
    }),
  ],
  tracesSampleRate: 1.0,
  debug: APP_ENVIRONMENT === 'local',
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
