import {
  USER_ROLES,
  MENU_CATEGORIES,
  MENU_SUBCATEGORIES,
  PILOT_AND_STORE_USER_ROLES,
} from 'constants/constants'
import ExecutionScreen from 'screens/ExecutionCategory/Execution'
import CarrierExecutionScreen from 'screens/ExecutionCategory/CarrierExecution'
import TourMonitoringScreen from 'screens/ExecutionCategory/TourMonitoring'
import MapMonitoringScreen from 'screens/ExecutionCategory/MapMonitoringScreen'
import ReplayScreen from 'screens/ExecutionCategory/Replay/ReplayScreen'
import DisputesListScreen from 'screens/DisputeCategory/List/DisputesListScreen'
import { ROUTES_PATH } from './RoutesPath'

/**
 * EXECUTION_CATEGORY configuration
 * Please refer to 'navigation/NavigationConfig' before changing this.
 */

const EXECUTION_CATEGORY = [
  {
    title: MENU_SUBCATEGORIES.driverAssignment,
    path: ROUTES_PATH.executionAffectation,
    component: ExecutionScreen,
    isPrivate: true,
    isInMenu: true,
    category: MENU_CATEGORIES.execution,
    roles: PILOT_AND_STORE_USER_ROLES,
  },
  {
    title: MENU_SUBCATEGORIES.driverAssignment,
    path: ROUTES_PATH.executionCarrierAffectation,
    component: CarrierExecutionScreen,
    isPrivate: true,
    isInMenu: true,
    category: MENU_CATEGORIES.execution,
    roles: [USER_ROLES.carrier],
  },
  {
    title: MENU_SUBCATEGORIES.toursMonitoring,
    path: ROUTES_PATH.executionTours,
    component: TourMonitoringScreen,
    isPrivate: true,
    isInMenu: true,
    category: MENU_CATEGORIES.execution,
    roles: [...PILOT_AND_STORE_USER_ROLES, USER_ROLES.carrier],
  },
  {
    title: MENU_SUBCATEGORIES.mapMonitoring,
    path: ROUTES_PATH.executionMap,
    component: MapMonitoringScreen,
    isPrivate: true,
    isInMenu: true,
    category: MENU_CATEGORIES.execution,
    roles: [...PILOT_AND_STORE_USER_ROLES],
  },
  {
    title: MENU_SUBCATEGORIES.replay,
    path: ROUTES_PATH.executionReplay,
    component: ReplayScreen,
    isPrivate: true,
    isInMenu: true,
    category: MENU_CATEGORIES.execution,
    roles: [...PILOT_AND_STORE_USER_ROLES, USER_ROLES.superAdmin],
  },
  {
    title: MENU_SUBCATEGORIES.disputes,
    path: ROUTES_PATH.disputesList,
    component: DisputesListScreen,
    isPrivate: true,
    isInMenu: true,
    description: "Accès à l'écran liste de litiges",
    category: MENU_CATEGORIES.execution,
    roles: [USER_ROLES.superAdmin, ...PILOT_AND_STORE_USER_ROLES],
    isBetaMode: true,
  },
]

export default EXECUTION_CATEGORY
