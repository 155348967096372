import React, { useContext, useEffect } from 'react'
import { MapContainer, TileLayer, MapContainerProps } from 'react-leaflet'
import L from 'leaflet'
import 'leaflet-geometryutil'
import 'leaflet-snap'
import 'leaflet.markercluster'
import AddressApi from 'api/address'
import { HeatmapLayer } from 'react-leaflet-heatmap-layer-v3'
import { AppConfigContext } from 'store/AppConfigContext'
import MapProvider, { MapContext, IMapContext } from 'store/MapContext'
import { ISector } from 'interfaces/ISector'
import { ThemeContext } from 'store/ThemeContext'
import SatViewSelector from '../SatViewSelector'

interface IProps {
  mapName: string
  children?: React.ReactNode
  width?: string
  useToolbar?: boolean
  onSectorChange?(
    layer: L.Polygon,
    remove?: boolean,
    sectorId?: string,
    layerIndex?: number,
  ): Promise<string>
  existingSectors?: ISector[]
  shouldDisplayHeatmapLayer?: boolean
  heatmapLayerPoints?: [number, number, number][]
}

type IMapProps = IProps & MapContainerProps

function Map({
  mapName,
  children,
  existingSectors,
  width,
  shouldDisplayHeatmapLayer,
  heatmapLayerPoints,
  ...mapProps
}: IMapProps): JSX.Element {
  const { setMap, map } = useContext<IMapContext>(MapContext)

  const { darkMode } = useContext(ThemeContext)
  const { mapConfig } = useContext(AppConfigContext)

  function onMapCreated(createdMap: L.Map): void {
    setMap(createdMap)
  }

  const { tileUrlLight, tileUrlDark } = AddressApi.computeMapUrls(mapConfig.includes(mapName))
  const lightMap = L.tileLayer(tileUrlLight)
  const darkMap = L.tileLayer(tileUrlDark)

  function turnLightMap() {
    darkMap.removeFrom(map as L.Map)
    lightMap.addTo(map as L.Map)
  }

  function turnDarkMap() {
    lightMap.removeFrom(map as L.Map)
    darkMap.addTo(map as L.Map)
  }

  useEffect(() => {
    if (darkMode === true && map) {
      turnDarkMap()
    }
    if (darkMode === false && map) {
      turnLightMap()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [darkMode, mapConfig])

  // Fun way to make componnentWillUnmount
  // @ts-ignore
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => (): void => setMap(null), [])

  // This is important!
  // this useEffect is made to tell the map width has changed.
  // The component is too dumb to do it himself. The timeout is a workaround.
  useEffect(() => {
    if (map) {
      setTimeout(() => {
        map.invalidateSize()
      }, 1000)
    }
  }, [width, map])

  useEffect(() => {
    if (map) {
      const markers = L.featureGroup()
      if (mapProps.center) L.marker(mapProps.center).addTo(markers)
      if (existingSectors) {
        existingSectors.forEach((sector) => {
          sector.sectorCoordinates.forEach((sectorCoordinate) => {
            sectorCoordinate.coordinates.forEach((coordinate) => {
              L.marker([coordinate[0], coordinate[1]]).addTo(markers)
            })
          })
        })
      }
      if (markers.getBounds().isValid()) map.fitBounds(markers.getBounds())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, existingSectors])

  useEffect(() => {
    if (map && mapProps.bounds) map.fitBounds(mapProps.bounds)
  }, [map, mapProps.bounds])

  return (
    <MapContainer
      preferCanvas
      attributionControl={false}
      whenCreated={onMapCreated}
      center={{ lat: 48.85727, lng: 2.38061 }} // default position
      zoom={15}
      {...mapProps}
    >
      <SatViewSelector mapName={mapName} />
      {shouldDisplayHeatmapLayer && heatmapLayerPoints && heatmapLayerPoints.length > 0 && (
        <HeatmapLayer
          fitBoundsOnLoad
          fitBoundsOnUpdate
          points={heatmapLayerPoints}
          longitudeExtractor={(m) => m[1]}
          latitudeExtractor={(m) => m[0]}
          intensityExtractor={(m) => parseFloat(m[2])}
        />
      )}
      <TileLayer url={darkMode ? tileUrlDark : tileUrlLight} />
      {children}
    </MapContainer>
  )
}

export default ({
  useToolbar,
  onSectorChange,
  existingSectors = [],
  ...rest
}: JSX.IntrinsicAttributes & IMapProps): JSX.Element => (
  <MapProvider
    useToolbar={useToolbar}
    onSectorChange={onSectorChange}
    existingSectors={existingSectors}
  >
    <Map {...rest} existingSectors={existingSectors} />
  </MapProvider>
)
