import React from 'react'
import { COLUMNS_MODEL_VEHICLES_LIST } from 'constants/table'
import { IVehicle } from 'interfaces/IVehicle'
import VehiclesTable from 'components/Table/VehiclesTable'
import VehiclesProvider, { VehiclesConsumer } from 'store/VehiclesContext'

interface IProps {
  vehicles: IVehicle[]
}

const VehiclesListScreen = ({ vehicles }: IProps): JSX.Element => (
  <VehiclesTable vehicles={vehicles} columns={COLUMNS_MODEL_VEHICLES_LIST} />
)

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <VehiclesProvider>
    <VehiclesConsumer>
      {(ctx): JSX.Element => <VehiclesListScreen vehicles={ctx.vehicles} {...props} />}
    </VehiclesConsumer>
  </VehiclesProvider>
)
