import React from 'react'
import { Dialog, Grid } from '@material-ui/core'
import Image from '@material-ui/icons/Image'
import clsx from 'clsx'
import useStyles from './styles'

type ImageLineProps = {
  link: string
  alt: string
  label?: string
  noTopMargin?: boolean
}

const ImageLine = ({ alt, noTopMargin, label, link }: ImageLineProps): JSX.Element => {
  const classes = useStyles()
  const [opened, setOpen] = React.useState(false)

  const openImage = (): void => setOpen(true)
  const closeImage = (): void => setOpen(false)

  const getStyle = () =>
    noTopMargin ? classes.imageIcon : clsx(classes.imageIcon, classes.imageLine)

  return (
    <>
      <Grid data-cy="image-line" container className={getStyle()} onClick={openImage}>
        <Image className={classes.icon} />
        {label && (
          <Grid item data-cy="label">
            {label}
          </Grid>
        )}
      </Grid>
      <Dialog onClose={closeImage} aria-labelledby="image-dialog" open={opened}>
        <img src={link} className={classes.image} alt={alt} />
      </Dialog>
    </>
  )
}

export default ImageLine
