import { Theme, createStyles, makeStyles } from "@material-ui/core"

export default makeStyles((theme: Theme) =>
  createStyles({
    text: {
      width: '100%',
    },
    buttonsContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      flex: 1,
      borderRadius: 4,
      justifyContent: 'center',
      color: 'white',
    },
    nowIcon: {
      backgroundColor: theme.color.lightGreen,
      marginRight: 5,
    },
    editIcon: {
      backgroundColor: theme.color.primary,
    },
    hide: {
      display: 'none',
    },
    paper: {
      textAlign: 'center',
      backgroundColor: theme.palette.background.default,
    },
    listContainer: {
      height: 600,
      overflow: 'auto',
    },
    accordion: {
      width: '100%',
      backgroundColor: theme.palette.background.default,
    },
    vehiclesList: {
      width: '100%',
    },
    planList: {
      height: '400px',
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.color.scrollbarBackground,
      },
    },
    displayFlex: {
      display: 'flex',
    },
    displayNone: {
      display: 'none',
    },
    dateText: {
      width: '116px',
    },
    error: {
      color: 'red',
    },
  }),
)
