import React, { useEffect, useState, useMemo, useRef, useContext } from 'react'
import MaterialTable, { MTableHeader, Action } from 'material-table'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { IApikeyFilters, ITableColumn } from 'interfaces/interfaces'
import MUITableIcons from 'constants/MUITableIcons'
import { ApikeysConsumer } from 'store/ApikeysContext'
import AppConfigProvider, { AppConfigContext } from 'store/AppConfigContext'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import { scrollTop } from 'utils/functions'
import { getPageSize, savePageSize } from 'utils/localStorage'
import Pagination from 'components/Table/Pagination'
import { getDisplayRowsCount } from 'utils/tableUtils'
import Button from 'components/Button/CustomButton'

import { IApikey } from 'interfaces/IApikey'
import { Edit } from '@material-ui/icons'
import { IUser } from 'interfaces/IUser'
import { AuthContext } from 'store/AuthContext'
import { USER_ROLES } from 'constants/constants'
import DeleteIcon from '@material-ui/icons/Delete'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog'
import useStyles from './styles'

interface ICustomTableProps {
  columns: ITableColumn[]
  updateApikeys?: (
    filters?: IApikeyFilters,
    offset?: number,
    rowsPerPage?: number,
    sortField?: number,
    sortDirection?: string,
  ) => void
  revokeApikey?: (id: string) => void
  apiKeys: IApikey[]
  users?: IUser[]
  getUsers?: Function
  count?: number
}

const columnConfigName = 'apikeyList'

const CustomTable = ({
  columns,
  updateApikeys,
  revokeApikey,
  apiKeys,
  count,
  users,
  getUsers,
  ...rest
}: ICustomTableProps): JSX.Element => {
  const isInitialMount = useRef(true)

  const [paginationOffset, setPaginationOffset] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [sortField, setSortField] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(getPageSize)
  const [sortDirection, setSortDirection] = useState<string>('asc')
  const [usersList, setUsersList] = useState<IUser[]>([])
  const [isRevokeApikeyDialogOpened, setIsRevokeApikeyDialogOpened] = useState<boolean>(false)
  const [apikeyIdToDelete, setApikeyIdToDelete] = useState<string | undefined>()

  const { user: loggedUser } = useContext(AuthContext)
  const styles = useStyles()
  const history = useHistory()
  const { tablesConfig } = useContext(AppConfigContext)
  const { t } = useTranslation()

  const isAdminUser = useMemo(() => loggedUser?.roles.includes(USER_ROLES.superAdmin), [loggedUser])

  const tenantId = useMemo(() => loggedUser?.tenantId, [loggedUser])

  useEffect(() => {
    if (loggedUser) {
      if (isAdminUser) {
        if (getUsers) {
          getUsers()
        }
      } else {
        setUsersList([
          {
            id: loggedUser.id,
            firstName: loggedUser?.firstName || '',
            lastName: loggedUser?.lastName || '',
            roles: loggedUser.roles,
            companyId: loggedUser.companyId,
            sites: loggedUser.sites,
            active: true,
            tenant: loggedUser.tenant,
          },
        ])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdminUser])

  useEffect(() => {
    if (users && users.length > 0) setUsersList(users)
  }, [users])

  useEffect(() => {
    if (loggedUser) {
      if (rowsPerPage !== getPageSize()) {
        savePageSize(rowsPerPage)
        scrollTop()
      }
      if (updateApikeys) {
        updateApikeys({ tenantIds: tenantId ? [tenantId] : undefined }, 0, rowsPerPage)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser, rowsPerPage])

  useEffect(() => {
    if (updateApikeys && !isInitialMount.current) {
      getApiKeysList()
    } else {
      isInitialMount.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationOffset, sortField, sortDirection])

  const getApiKeysList = (): void => {
    if (loggedUser && updateApikeys) {
      updateApikeys(
        { tenantIds: tenantId ? [tenantId] : undefined },
        paginationOffset,
        rowsPerPage,
        sortField,
        sortDirection,
      )
      scrollTop()
    }
  }

  const handleNewApikeyClick = (): void => {
    history.push(ROUTES_PATH.createApiKey)
  }

  const handleEditClick = ({ id }: IApikey): void => {
    history.push(`${ROUTES_PATH.editApiKey}${id}`)
  }

  const handleDeleteApikey = async (validate: boolean): Promise<void> => {
    setIsRevokeApikeyDialogOpened(false)
    if (validate && apikeyIdToDelete && revokeApikey) {
      await revokeApikey(apikeyIdToDelete)
      setApikeyIdToDelete(undefined)
      setIsRevokeApikeyDialogOpened(false)
      getApiKeysList()
    } else {
      setApikeyIdToDelete(undefined)
      setIsRevokeApikeyDialogOpened(false)
    }
  }

  const handleOrderChange = (columnIndex: number): void => {
    setPage(0)
    setPaginationOffset(0)
    setSortField(columnIndex)
    setSortDirection((prevSortDirection) => (prevSortDirection === 'asc' ? 'desc' : 'asc'))
  }

  const handleChangeRowsPerPage = (pageSize: number): void => {
    setPage(0)
    setPaginationOffset(0)
    setRowsPerPage(pageSize)
  }

  const displayRowsCount = useMemo(() => getDisplayRowsCount(apiKeys), [apiKeys])

  return (
    <>
      <div className={styles.newButtonRightContainer}>
        <Button dataCy="newButton" className={styles.newButton} onPress={handleNewApikeyClick}>
          {t('tablesEntries.new')}
        </Button>
      </div>
      <MaterialTable
        key={displayRowsCount}
        data={apiKeys.map((apikey) => {
          const user = usersList?.find((item) => item.id === apikey.userId)
          return { ...apikey, userName: `${user?.firstName} ${user?.lastName}` }
        })}
        columns={columns.map(
          (col) =>
            ({
              ...col,
              hidden: tablesConfig[columnConfigName]?.includes(col.title),
            } as unknown as MaterialTable<object>),
        )}
        {...rest}
        options={{
          toolbar: false,
          pageSize: displayRowsCount,
          actionsColumnIndex: -1,
        }}
        actions={[
          (data: IApikey): Action<IApikey> => ({
            icon: Edit,
            tooltip: t('tablesEntries.edit'),
            onClick: (): void => handleEditClick(data),
          }),
          (data: IApikey): Action<IApikey> => ({
            icon: DeleteIcon,
            tooltip: t('tablesEntries.delete'),
            onClick: (): void => {
              setApikeyIdToDelete(data.id)
              setIsRevokeApikeyDialogOpened(true)
            },
          }),
        ]}
        icons={MUITableIcons}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        components={{
          // eslint-disable-next-line react/prop-types, @typescript-eslint/no-unused-vars
          Pagination: ({ classes, ...props }): JSX.Element => (
            <Pagination
              {...props}
              columnConfigName={columnConfigName}
              columns={columns}
              count={count}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setPaginationOffset={setPaginationOffset}
            />
          ),
          Header: (props): JSX.Element => (
            <MTableHeader
              {...props}
              onOrderChange={handleOrderChange}
              orderBy={sortField}
              orderDirection={sortDirection}
            />
          ),
        }}
      />
      {isRevokeApikeyDialogOpened && (
        <ConfirmationDialog
          open={isRevokeApikeyDialogOpened}
          onClose={handleDeleteApikey}
          message={t('ApikeyScreen.deleteKeyConfirmationMessage')}
        />
      )}
    </>
  )
}

export default (props: ICustomTableProps): JSX.Element => (
  <AppConfigProvider>
    <ApikeysConsumer>
      {(ctx): JSX.Element => (
        <CustomTable
          count={ctx.count}
          updateApikeys={ctx.updateApikeys}
          revokeApikey={ctx.revokeApikey}
          {...props}
        />
      )}
    </ApikeysConsumer>
  </AppConfigProvider>
)
