import React, { useMemo } from 'react'
import {
  Chart,
  Legend,
  Title,
  ValueAxis,
  ArgumentAxis,
  BarSeries,
  Tooltip,
} from '@devexpress/dx-react-chart-material-ui'
import { Stack, Animation, EventTracker, ValueScale, DomainItems } from '@devexpress/dx-react-chart'
import { connectProps } from '@devexpress/dx-react-core'
import { useTranslation } from 'react-i18next'

import { IHourlyStats } from 'interfaces/IHourlyStats'
import { OrderAxis } from 'constants/constants'
import Export from 'components/Chart/Export'
import StyledPaper from 'screens/ReportingCategory/components/StyledPaper'
import useStyles from './styles'

interface IProps {
  data: IHourlyStats[]
  title: string
  containerId: string
  exportName: string
  orders: number[]
  labelSources: Map<number, string>
  selectedAxis: number
}

interface ITotal {
  orderCount: number
  bacCount: number
}

type ILengendProps = ITotal & { selectedAxis: number }

const CustomLegend = ({
  orderCount,
  bacCount,
  selectedAxis,
  ...props
}: Legend.RootProps & ILengendProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.legendContainer}>
      <Legend.Root {...props} className={classes.legend} />
      {selectedAxis === OrderAxis.Order && (
        <Legend.Label
          className={classes.totalLabel}
          text={`${t('OrderStatsScreen.orderCount')}: ${orderCount}`}
        />
      )}
      {selectedAxis === OrderAxis.Bac && (
        <Legend.Label
          className={classes.totalLabel}
          text={`${t('OrderStatsScreen.parcelCount')}: ${bacCount}`}
        />
      )}
    </div>
  )
}

export const OrderTypeChart = ({
  data,
  title,
  containerId,
  exportName,
  orders,
  labelSources,
  selectedAxis,
}: IProps): JSX.Element => {
  const getLabel = (type): string => labelSources.get(type) as string

  const getTotalCount = (statsData: IHourlyStats[]): ITotal => ({
    orderCount: statsData.reduce((countPerHour, stat): number => {
      let count = countPerHour
      count += stat.stats.reduce((countOrder, s): number => {
        let c = countOrder
        c += s.count
        return c
      }, 0)
      return count
    }, 0),
    bacCount: statsData.reduce((countPerHour, stat): number => {
      let count = countPerHour
      count += stat.stats.reduce((countBac, s): number => {
        let c = countBac
        c += s.mainQuantity
        return c
      }, 0)
      return count
    }, 0),
  })

  const memoizedTotal = useMemo(() => getTotalCount(data), [data])

  const EnhancedLegend = connectProps(CustomLegend, () => ({
    ...memoizedTotal,
    selectedAxis,
  }))

  const chartData = data.map((d) =>
    d.stats.reduce(
      (obj, dt) => {
        const o = obj
        const label = getLabel(dt.type)
        o[label] = selectedAxis === OrderAxis.Order ? dt.count : dt.mainQuantity
        return o
      },
      {
        hour: d.hour,
      },
    ),
  )

  return (
    <StyledPaper id={containerId}>
      <Chart data-cy="orderChart" data={chartData}>
        <Title text={title} />
        <ValueScale
          name="orders"
          modifyDomain={(domain: DomainItems): DomainItems => [0, domain[1] * 1.2]}
        />
        <ArgumentAxis />
        <ValueAxis scaleName="orders" showGrid showLabels showLine showTicks />

        {orders.map((order) => (
          <BarSeries
            key={order}
            name={`${getLabel(order)}`}
            valueField={`${getLabel(order)}`}
            argumentField="hour"
            scaleName="orders"
          />
        ))}

        <Animation />
        <Legend position="bottom" rootComponent={EnhancedLegend} />
        <Stack stacks={[{ series: orders.map((option) => getLabel(option)) }]} />
        <EventTracker />
        <Tooltip />
        <Export dataCy="exportButton" containerId={containerId} fileName={exportName} />
      </Chart>
    </StyledPaper>
  )
}
