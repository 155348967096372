import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Card from 'components/Card/Card'
import SearchableSelect from 'components/Inputs/SearchableSelect'
import {
  VEHICLE_FILLING_RATE_CALCULATION_OPTIONS,
  VehicleFillingRateCalculation,
} from 'constants/constants'
import useStyles from 'constants/cruStyles'
import { IExecutionScreenConfig } from 'interfaces/interfaces'

interface IProps {
  executionScreen?: IExecutionScreenConfig
  onChange: (executionScreen: IExecutionScreenConfig) => void
}

const WarehouseExecutionScreenCard = ({ executionScreen, onChange }: IProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [config, setConfig] = useState<Partial<IExecutionScreenConfig>>({
    vehicleFillingRateCalculation:
      executionScreen?.vehicleFillingRateCalculation || VehicleFillingRateCalculation.Quantity,
  })

  const handleSearchableSelectChange = (
    event: React.ChangeEvent<{ value: unknown; name?: string }>,
  ): void => {
    const { target } = event
    const name = target.name as string
    const value = target.value as string
    onChange({
      ...config,
      [name]: value,
    } as IExecutionScreenConfig)
    setConfig({ ...config, [name]: value })
  }

  return (
    <Card title={t('SitesScreen.executionScreen').toUpperCase()}>
      <div className={classes.fieldsContainer}>
        <SearchableSelect
          label={t('SitesScreen.executionScreenConfig.vehicleFillingRateCalculation')}
          name="vehicleFillingRateCalculation"
          selectedValue={config.vehicleFillingRateCalculation}
          options={VEHICLE_FILLING_RATE_CALCULATION_OPTIONS}
          onChange={handleSearchableSelectChange}
        />
      </div>
    </Card>
  )
}

export default WarehouseExecutionScreenCard
