import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    header: {
      backgroundColor: theme.color.backgroundPaperTitle,
      color: theme.color.secondary,
      fontWeight: 'bold',
      borderRadius: '4px 4px 0 0',
    },
    headerDetails: {
      backgroundColor: theme.color.backgroundPaperTitle,
      color: theme.color.secondary,
      fontWeight: 'bolder',
    },
    infoContainer: {
      position: 'relative',
      margin: '0 auto',
    },
    content: {
      paddingLeft: '10px',
      paddingBottom: '5px',
      backgroundColor: theme.palette.background.default,
      marginBottom: '20px',
      display: 'flex',
      position: 'relative',
    },
    contentDetails: {
      overflow: 'auto',
      maxHeight: '512px',
      padding: 0,
      paddingBottom: '0 !important',
      backgroundColor: theme.palette.background.default,
    },
    contentRef: {
      paddingLeft: '10px',
      paddingBottom: '5px',
      backgroundColor: theme.palette.background.default,
      marginBottom: '20px',
      display: 'flex',
      position: 'relative',
      flexDirection: 'column',
      alignItems: 'stretch',
    },
    fieldsContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      paddingLeft: '20px',
    },
    fieldsWarehouse: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: '1px solid',
    },
    switchContainer: {
      display: 'flex',
      marginTop: '-30px',
    },
    DropdownList: {
      display: 'block',
      backgroundColor: theme.palette.background.paper,
      position: 'absolute',
      marginTop: '35px',
      zIndex: 999,
    },
    modifyButton: {
      marginBottom: '10px',
    },
    warehouse: {
      fontSize: '18px',
    },
    activitySwitch: {
      thumbOnColor: 'green',
      trackOnColor: 'red',
    },
    btnSelect: {
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'flex-end',
    },
    detailsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '20px',
    },
    errorContainer: {
      marginTop: '75px',
    },
    panelContainer: {
      display: 'flex',
    },
    gridContainer: {
      marginLeft: '50px',
    },
    tabContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    tabs: {
      marginBottom: '10px',
    },
  }),
)
