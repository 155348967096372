import React, { useState, useEffect, useMemo, useRef, useContext } from 'react'
import MaterialTable, { Action, MTableHeader } from 'material-table'
import { useHistory } from 'react-router-dom'
import ArrowForward from '@material-ui/icons/ArrowForward'
import RefreshIcon from '@material-ui/icons/Refresh'
import { IconButton } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { TrailersConsumer } from 'store/TrailersContext'
import { FiltersContext } from 'store/FiltersContext'
import { ITrailer } from 'interfaces/ITrailer'
import MUITableIcons from 'constants/MUITableIcons'
import { ITrailerFilters, ITableColumn } from 'interfaces/interfaces'
import { ActiveFilter, CarrierFilter, VehicleCatFilter } from 'components/Inputs/ListFilter'
import SearchInput from 'components/Inputs/SearchInput'
import Button from 'components/Button/CustomButton'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import { scrollTop } from 'utils/functions'
import AppConfigProvider, { AppConfigContext } from 'store/AppConfigContext'
import Pagination from 'components/Table/Pagination'
import { getPageSize, savePageSize } from 'utils/localStorage'
import { FilterKey, Filter } from 'constants/filters'
import { getDisplayRowsCount } from 'utils/tableUtils'

import { FiltersContainer, SearchContainer, TopFiltersContainer } from 'components/Layout'
import useStyles from './styles'

interface ICustomTableProps {
  columns: ITableColumn[]
  trailers: ITrailer[]
  count?: number
  getList?: (
    filter?: ITrailerFilters,
    offset?: number,
    rowsPerPage?: number,
    sortField?: number,
    sortDirection?: string,
  ) => void
}

const columnConfigName = 'trailerList'
const filterKey = FilterKey.trailers

const TrailersTable = ({
  columns,
  trailers,
  count,
  getList,
  ...rest
}: ICustomTableProps): JSX.Element => {
  const isInitialMount = useRef(true)

  const [paginationOffset, setPaginationOffset] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const { setFilter, resetFilters, filters } = useContext(FiltersContext)
  const active = filters[filterKey][Filter.active] as string[]
  const carriers = filters[filterKey][Filter.carriers] as string[]
  const vehicleTypeIds = filters[filterKey][Filter.vehicleTypes] as string[]
  const searchText = filters[filterKey][Filter.searchText] as string
  const [isSearchReset, setIsSearchReset] = useState<boolean>(false)
  const [rowsPerPage, setRowsPerPage] = useState<number>(getPageSize)
  const [sortField, setSortField] = useState<number>(0)
  const [sortDirection, setSortDirection] = useState<string>('asc')
  const history = useHistory()
  const styles = useStyles()
  const { tablesConfig } = useContext(AppConfigContext)
  const { t } = useTranslation()

  useEffect(() => {
    if (rowsPerPage !== getPageSize()) {
      savePageSize(rowsPerPage)
      scrollTop()
    }
    if (getList) {
      getList({ active, carriers, searchText, vehicleTypeIds }, 0, rowsPerPage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, carriers, searchText, vehicleTypeIds, rowsPerPage])

  useEffect(() => {
    if (getList && !isInitialMount.current) {
      getList(
        { active, carriers, searchText, vehicleTypeIds },
        paginationOffset,
        rowsPerPage,
        sortField,
        sortDirection,
      )
      scrollTop()
    } else {
      isInitialMount.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationOffset, sortField, sortDirection])

  const handleNewClick = (): void => {
    history.push(ROUTES_PATH.createTrailer)
  }

  const resetLocalFilters = (): void => {
    setIsSearchReset(true)
    resetFilters(filterKey)
  }

  const Filters = useMemo(
    (): JSX.Element => (
      <TopFiltersContainer>
        <FiltersContainer>
          <IconButton onClick={resetLocalFilters}>
            <RefreshIcon />
          </IconButton>
          <ActiveFilter
            handleChange={(activeValues: string[]): void => {
              setFilter(filterKey, Filter.active, activeValues)
            }}
            ids={active}
          />
          <CarrierFilter
            handleChange={(carrierValues: string[]): void => {
              setFilter(filterKey, Filter.carriers, carrierValues)
            }}
            ids={carriers}
          />
          <VehicleCatFilter
            handleChange={(vehicleTypeValues: string[]): void => {
              setFilter(filterKey, Filter.vehicleTypes, vehicleTypeValues)
            }}
            ids={vehicleTypeIds}
          />
        </FiltersContainer>
        <SearchContainer>
          <SearchInput
            defaultValue={searchText}
            key={isSearchReset ? 'search-reset' : ''}
            onSearch={(text: string): void => {
              setIsSearchReset(false)
              setFilter(filterKey, Filter.searchText, text)
            }}
          />
          <Button className={styles.newButton} onPress={handleNewClick}>
            {t('tablesEntries.new')}
          </Button>
        </SearchContainer>
      </TopFiltersContainer>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [active, carriers, vehicleTypeIds, searchText, styles],
  )

  const handleDetailsClick = ({ id }: ITrailer): void => {
    history.push(`${ROUTES_PATH.trailerDetails}${id}`)
  }

  const handleChangeRowsPerPage = (pageSize: number): void => {
    setPage(0)
    setRowsPerPage(pageSize)
    setPaginationOffset(0)
  }

  const handleOrderChange = (columnIndex: number): void => {
    setPage(0)
    setPaginationOffset(0)
    setSortField(columnIndex)
    setSortDirection((prevSortDirection) => (prevSortDirection === 'asc' ? 'desc' : 'asc'))
  }

  const displayRowsCount = useMemo(() => getDisplayRowsCount(trailers), [trailers])

  return (
    <>
      {Filters}
      <MaterialTable
        key={displayRowsCount}
        data={trailers}
        columns={columns.map(
          (col) =>
            ({
              ...col,
              hidden: tablesConfig[columnConfigName]?.includes(col.title),
            } as unknown as MaterialTable<object>),
        )}
        {...rest}
        options={{
          toolbar: false,
          pageSize: displayRowsCount,
          actionsColumnIndex: -1,
        }}
        actions={[
          (data: ITrailer): Action<ITrailer> => ({
            icon: ArrowForward,
            tooltip: t('tablesEntries.seeDetails'),
            onClick: (): void => handleDetailsClick(data),
          }),
        ]}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        icons={MUITableIcons}
        components={{
          // eslint-disable-next-line react/prop-types, @typescript-eslint/no-unused-vars
          Pagination: ({ classes, ...props }): JSX.Element => (
            <Pagination
              {...props}
              columnConfigName={columnConfigName}
              rowsPerPage={rowsPerPage}
              columns={columns}
              count={count}
              page={page}
              setPage={setPage}
              setPaginationOffset={setPaginationOffset}
            />
          ),
          Header: (props): JSX.Element => (
            <MTableHeader
              {...props}
              onOrderChange={handleOrderChange}
              orderBy={sortField}
              orderDirection={sortDirection}
            />
          ),
        }}
      />
    </>
  )
}

export default (props: ICustomTableProps): JSX.Element => (
  <AppConfigProvider>
    <TrailersConsumer>
      {(ctx): JSX.Element => <TrailersTable count={ctx.count} getList={ctx.getList} {...props} />}
    </TrailersConsumer>
  </AppConfigProvider>
)
