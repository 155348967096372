import React, { ReactNode } from 'react'
import { Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import useStyles from '../styles'

interface IPopupControl {
  titleKey: string
  onPress: () => void
  children: ReactNode
  containerClassName?: string
  className?: string
  dataCy?: string
}

const PopupControl = ({
  titleKey,
  onPress,
  children,
  containerClassName,
  className,
  dataCy,
}: IPopupControl): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div
      className={`leaflet-top leaflet-left ${classes.popupControlContainer} ${containerClassName}`}
    >
      <div className="leaflet-control-zoom leaflet-bar leaflet-control">
        <Button
          className={`leaflet-control-zoom-in ${classes.popupControl} ${className}`}
          title={t(`MapScreen.popupControls.${titleKey}`)}
          onClick={onPress}
          data-cy={dataCy}
        >
          {children}
        </Button>
      </div>
    </div>
  )
}

export default PopupControl
