import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardContent, TextField } from '@material-ui/core'
import clsx from 'clsx'

import { ISector } from 'interfaces/ISector'
import useStyles from 'constants/cruStyles'
import { ContentConsumer } from 'store/ContentContext'
import Button from 'components/Button/CustomButton'
import { IDriver, IVehicle } from 'interfaces/interfaces'
import { SitesConsumer } from 'store/SitesContext'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog'
import UpdateSector from './UpdateSector'

interface IProps {
  sector: ISector
  deleteSector?: Function
  drivers?: IDriver[]
  vehicles?: IVehicle[]
}

const DisplaySector = ({ sector, vehicles, drivers, deleteSector }: IProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [isUpdating, setIsUpdating] = useState(false)
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false)

  const handleDeleteSectorConfirmation = async (validate: boolean): Promise<void> => {
    setIsDeleteConfirmationModalOpen(false)
    if (validate && deleteSector) {
      await deleteSector(sector.id)
    }
  }

  return isUpdating ? (
    <UpdateSector
      data-cy="update-info-container"
      sector={sector}
      vehicles={vehicles?.filter((vehicle) => vehicle.siteId === sector.warehouseId)}
      drivers={drivers}
      onClose={() => setIsUpdating(false)}
    />
  ) : (
    <>
      <CardContent data-cy="display-info-container">
        <div className={clsx(classes.rowFieldsContainer, classes.routingRuleCard)}>
          <TextField
            data-cy="label-container"
            className={classes.sectorInfoField}
            label={t('SectorsScreen.label').toUpperCase()}
            disabled
            type="text"
            name="label"
            value={sector.label || '-'}
          />
          <TextField
            data-cy="vehicle-container"
            className={classes.sectorInfoField}
            label={t('SectorsScreen.vehicles').toUpperCase()}
            disabled
            type="text"
            name="vehicle"
            value={
              vehicles && vehicles.length && sector.vehicleIds && sector.vehicleIds.length
                ? sector.vehicleIds
                  .map((vId) => vehicles.find((vehicle) => vId === vehicle.id)?.name)
                  .join(', ')
                : '-'
            }
            inputProps={{ className: classes.sectorInfoInput }}
          />
          <TextField
            data-cy="driver-container"
            className={classes.sectorInfoField}
            label={t('SectorsScreen.driver').toUpperCase()}
            disabled
            type="text"
            name="driver"
            value={
              drivers && drivers.length && sector.driverId
                ? drivers.find((driver) => driver.id === sector.driverId)?.name
                : '-'
            }
          />
          <TextField
            data-cy="deck-code-container"
            className={classes.sectorInfoField}
            label={t('SectorsScreen.deckCode').toUpperCase()}
            disabled
            type="text"
            name="deckCode"
            value={sector.deckCode || '-'}
          />
        </div>
        <div className={classes.rowFieldsContainer}>
          <div className={classes.detailActionButtonsContainer}>
            <Button
              dataCy="deleteButton"
              variant="outlined"
              onPress={() => setIsDeleteConfirmationModalOpen(true)}
            >
              {t('SectorsScreen.delete')}
            </Button>
            <Button dataCy="updateButton" onPress={() => setIsUpdating(true)}>
              {t('SectorsScreen.modify')}
            </Button>
          </div>
        </div>
      </CardContent>
      <ConfirmationDialog
        open={isDeleteConfirmationModalOpen}
        onClose={handleDeleteSectorConfirmation}
        message={t('SectorsScreen.deleteSectorConfirmationMessage')}
      />
    </>
  )
}

export default (props: JSX.IntrinsicAttributes & IProps): JSX.Element => (
  <SitesConsumer>
    {(sitesCtx): JSX.Element => (
      <ContentConsumer>
        {(contentCtx): JSX.Element => (
          <DisplaySector
            drivers={contentCtx.drivers}
            vehicles={contentCtx.vehicles}
            sector={props.sector}
            deleteSector={sitesCtx.deleteSector}
          />
        )}
      </ContentConsumer>
    )}
  </SitesConsumer>
)
