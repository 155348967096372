import React, { useState, useEffect, ReactNode } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { useLocation } from 'react-router-dom'

import { AuthConsumer } from 'store/AuthContext'
import { NAVIGATION_CONFIG, NO_LAYOUT_SCREEN, INavElem } from 'navigation/NavigationConfig'
import { IUserData, IMenuEntries } from 'interfaces'
import { IFeature } from 'interfaces/IFeature'
import { isFeatureAvailable } from 'utils/isFeatureAvailable'
import Drawer from './CustomDrawer'
import useStyles from './styles'
import CustomAppBar from './CustomAppBar'

interface INavMenuProps {
  user?: IUserData
  children: ReactNode
  features?: IFeature[]
}

const shouldShowInMenu = (menuItem: INavElem, features?: IFeature[]): boolean => {
  if (menuItem.isBetaMode && features) {
    return isFeatureAvailable(features, menuItem)
  }
  return true
}

/* This will get the screens the user can access, and display it in the menu bar  */
function getMenuEntries(features?: IFeature[], user?: IUserData): IMenuEntries[] {
  const entries: IMenuEntries[] = []
  NAVIGATION_CONFIG.forEach((elem) => {
    if (elem.isInMenu && elem.roles) {
      const result = elem.roles.filter((value) => user?.roles?.includes(value))
      if (result.length > 0 && shouldShowInMenu(elem, features)) {
        const index = entries.findIndex((e) => e.title === elem.category)
        if (index !== -1) {
          entries[index].data.push(elem)
        } else if (elem.category) {
          entries.push({ title: elem.category, data: [elem], id: 0 })
        }
      }
    }
  })
  return entries
}

function NavigationMenu({ user, features, children }: INavMenuProps): JSX.Element {
  const classes = useStyles()
  const [menuEntries, setMenuEntries] = useState<IMenuEntries[]>([])
  const location = useLocation()
  const [opened, setOpened] = useState(true)

  useEffect(() => {
    if (user) {
      setMenuEntries(getMenuEntries(features, user))
    }
  }, [user, features])

  if (NO_LAYOUT_SCREEN.includes(location.pathname)) {
    return <>{children}</>
  }

  function toggleDrawer(): void {
    setOpened((prev) => !prev)
  }

  return (
    <div className={classes.displayContent}>
      <CssBaseline />
      <CustomAppBar opened={opened} toggleDrawer={toggleDrawer} />
      <Drawer entries={menuEntries} opened={opened} toggleDrawer={toggleDrawer} />
      <main data-cy="main-container" className={classes.mainContainer}>
        {children}
      </main>
    </div>
  )
}

export default (props: JSX.IntrinsicAttributes & INavMenuProps): JSX.Element => (
  <AuthConsumer>
    {(ctx): JSX.Element => <NavigationMenu user={ctx.user} features={ctx.features} {...props} />}
  </AuthConsumer>
)
