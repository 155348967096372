import React from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '@material-ui/core'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import useStyles from './styles'

type LocationRelevanceWithAlertProps = {
  locationRelevance: string
}

const LocationRelevanceWithAlert = ({
  locationRelevance,
}: LocationRelevanceWithAlertProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()

  const getIcon = () => {
    if (locationRelevance === '-' || Number(locationRelevance) <= 5) {
      return <ErrorOutlineIcon />
    }

    return <InfoOutlinedIcon />
  }

  const getAlertMessage = () => {
    if (locationRelevance === '-' || Number(locationRelevance) === 0) {
      return t('tablesEntries.locationRelevanceAlertMessageNotFound')
    }

    if (Number(locationRelevance) <= 5) {
      return t('tablesEntries.locationRelevanceAlertMessageIncorrect')
    }

    return t('tablesEntries.locationRelevanceAlertMessageUnreliable')
  }

  if (Number(locationRelevance) >= 90) {
    return <>{locationRelevance}</>
  }

  return (
    <div>
      <Tooltip
        title={(
          <div>
            <p>{getAlertMessage()}</p>
          </div>
        )}
      >
        <div className={classes.locationRelevanceAndIconWrapper}>
          <div className={classes.locationRelevanceWrapper}>{locationRelevance}</div>
          {getIcon()}
        </div>
      </Tooltip>
    </div>
  )
}

export default LocationRelevanceWithAlert
