import React, { useContext, useEffect } from 'react'
import { TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Autocomplete } from '@material-ui/lab'
import { TagsContext } from 'store/TagsContext'
import { AuthContext } from 'store/AuthContext'
import { ContentContext } from 'store/ContentContext'
import { IWarehouseCartDropoffConfig } from 'interfaces/IWarehouseCartDropoffConfig'
import { IFieldChange } from 'interfaces/interfaces'
import { ITag } from 'interfaces/ITag'
import { ICarrier } from 'interfaces/ICarrier'
import { getValidCarriers, isDefined } from 'utils/functions'
import { ConfigCard, FieldDetails } from './ConfigCard'
import { IProps } from './OrderInfoConfig'

export const DeliveryInfoConfig = ({ data, onChange }: IProps): JSX.Element => {
  const { t } = useTranslation()
  const { tags, getTags } = useContext(TagsContext)
  const { user } = useContext(AuthContext)
  const { carriers } = useContext(ContentContext)

  useEffect(() => {
    if (!isDefined(tags) && user?.tenantId && getTags) {
      getTags(user.tenantId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags, user])

  const handleInputChange = (event: IFieldChange): void => {
    const { target } = event
    const { name } = target
    const value = target.type === 'checkbox' ? target.checked : target.value

    onChange(name as keyof IWarehouseCartDropoffConfig, value === '' ? undefined : value)
  }

  const handleTagsChange = (event: object, value: ITag[]): void => {
    onChange(
      'tags',
      value.map((tag) => tag.code || ''),
      'allowedValues',
    )
    onChange(
      'tags',
      value.length !== 0,
      'active',
    )
  }

  const handleCarriersChange = (event: object, value: ICarrier[]): void => {
    onChange(
      'requestedCarriers',
      value.map((carrier) => carrier.id || ''),
      'allowedValues',
    )
    onChange(
      'requestedCarriers',
      value.length !== 0,
      'active',
    )
  }

  const getTagsFromCodes = (codes: string[]): ITag[] =>
    tags?.filter((tag) => tag.code && codes.includes(tag.code)) || []

  const deliveryInfoFields: FieldDetails[] = [
    {
      name: 'orderInstructions',
      component: (
        <TextField
          data-cy="orderInstructions"
          type="text"
          label={t('CartDropoffConfigurationScreen.orderInstructions').toUpperCase()}
          value={data.orderInstructions?.defaultValue || ''}
          name="orderInstructions"
          onChange={handleInputChange}
          fullWidth
        />
      ),
    },
    {
      name: 'estimatedServiceTime',
      component: (
        <TextField
          data-cy="estimatedServiceTime"
          type="number"
          label={t('CartDropoffConfigurationScreen.estimatedServiceTime').toUpperCase()}
          value={data.estimatedServiceTime?.defaultValue ?? ''}
          name="estimatedServiceTime"
          onChange={handleInputChange}
          fullWidth
        />
      ),
    },
    {
      name: 'tags',
      component: (
        <Autocomplete
          multiple
          id="tags"
          autoComplete
          options={tags || []}
          getOptionLabel={(option) => option.label || ''}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={t('CartDropoffConfigurationScreen.tags').toUpperCase()}
            />
          )}
          onChange={handleTagsChange}
          value={getTagsFromCodes(data.tags?.allowedValues || [])}
          getOptionSelected={(option, value) => option.code === value.code}
          fullWidth
        />
      ),
      disabled: (data.tags?.allowedValues || []).length === 0,
    },
    {
      name: 'requestedCarriers',
      component: (
        <Autocomplete
          multiple
          id="requestedCarriers"
          autoComplete
          options={getValidCarriers(carriers, data.originSiteId?.defaultValue as string)}
          getOptionLabel={(option) => option.name || ''}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={t('CartDropoffConfigurationScreen.carriers').toUpperCase()}
            />
          )}
          onChange={handleCarriersChange}
          value={carriers.filter((carrier) =>
            (data.requestedCarriers?.allowedValues || []).includes(carrier.id),
          )}
          getOptionSelected={(option, value) => option.id === value.id}
          fullWidth
        />
      ),
      disabled: (data.requestedCarriers?.allowedValues || []).length === 0,
    },
  ]

  return (
    <ConfigCard
      data={data}
      onChange={onChange}
      fields={deliveryInfoFields}
      sectionName={t('CartDropoffConfigurationScreen.cards.deliveryInfo')}
    />
  )
}
