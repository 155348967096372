import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import SelectCard from 'components/CustomCard/SelectCard'
import Card from 'components/Card/Card'
import useStyles from 'constants/cruStyles'
import { KlareoProduct, KLAREO_PRODUCTS } from 'constants/constants'

interface IProps {
  products: KlareoProduct[]
  onProductsChange: (productIds: KlareoProduct[]) => void
}

const TenantProductsCard = ({ products, onProductsChange }: IProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [selectedProductIds, setSelectedProductIds] = useState<KlareoProduct[]>([])

  useEffect(() => {
    setSelectedProductIds(products || [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleProductClick = (productId: string): void => {
    let newSelectProductIds = [...selectedProductIds]
    const index = selectedProductIds.findIndex(
      (selectedProductId) => selectedProductId === productId,
    )
    newSelectProductIds = index !== -1
      ? selectedProductIds.filter((id) => id !== productId)
      : [...selectedProductIds, productId as KlareoProduct]
    setSelectedProductIds(newSelectProductIds)
    onProductsChange(newSelectProductIds)
  }

  const handleSelectAllProductsClick = (): void => {
    const newSelectProductIds = selectedProductIds.length === KLAREO_PRODUCTS.length
      ? []
      : KLAREO_PRODUCTS.map((product) => product.id)
    setSelectedProductIds(newSelectProductIds)
    onProductsChange(newSelectProductIds)
  }

  return (
    <Card
      dataCy="productsCard"
      title={t('TenantsScreen.products').toUpperCase()}
      contentClassName={classes.contentRef}
    >
      <SelectCard
        options={KLAREO_PRODUCTS}
        selectedOptions={selectedProductIds}
        isAllSelected={selectedProductIds.length === KLAREO_PRODUCTS.length}
        onSelectAllClick={handleSelectAllProductsClick}
        onItemClick={handleProductClick}
      />
    </Card>
  )
}

export default TenantProductsCard
