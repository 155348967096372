import React, { ChangeEvent, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { FormControl, FormControlLabel, InputLabel, Checkbox, TextField } from '@material-ui/core'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import { ContentContext } from 'store/ContentContext'
import {
  TOUR_STATUS,
  CARRIER_STATUS,
  WAREHOUSE_TYPES,
  ROLES_USER_FILTER_OPTIONS,
  PLAN_TOUR_STATUS,
  ORDER_PLAN_STATUSES,
  MOBILE_APP_INDICATOR_TOUR_STATUS,
  SITE_SECTOR,
  CUSTOMER_NOTIFICATION_TYPE,
  TRANSPORT_TYPE_OPTIONS,
  READ_STATE,
  SMS_COMMUNICATION_SERVICE,
  CancellationReasonType,
  DESTINATION_TYPE_OPTIONS,
} from 'constants/constants'
import useStyles from 'components/NavigationLayout/styles'
import { alphabeticSort, formatDeliveryTypes } from 'utils/functions'
import { AuthContext } from 'store/AuthContext'
import { FiltersContext } from 'store/FiltersContext'
import { DefaultSiteContext } from 'store/DefaultSiteContext'
import { IDeliveryTypeItem } from 'interfaces/IDeliveryType'
import { ICancellationReason } from 'interfaces/ICancellationReason'
import { Filter } from 'constants/filters'
import { IWarehouse } from 'interfaces/IWarehouse'

export interface IDictEntry {
  id: string
  name?: string
  label?: string
}

interface IFilterProps {
  handleChange: (value: string[]) => void
  dataKeyFromContext?: string
  data?: IDictEntry[]
  dataName?: string
  ids: string[]
  placeholder?: string
  disabled?: boolean
  onlyOneValue?: boolean
  isReadOnly?: boolean
  onlyActives?: boolean
  shouldFillWidth?: boolean
  isForTopBar?: boolean
  required?: boolean
  dataCy?: string
  filterIds?: string[]
  excludedIds?: string[]
  isUnselectAllowed?: boolean
  defaultWhenCleared?: string
  customClass?: string
  mainKey?: string
  startDate?: string
  endDate?: string
  siteIds?: string[]
  sortBy?: string
  sortDirection?: number
  customFormClass?: string
  removeMargin?: boolean
  carrierIds?: string[]
  filterKey?: string
}

interface IFilterFn {
  handleChange: (value: string[]) => void
  ids: string[]
  placeholder?: string
  disabled?: boolean
  onlyOneValue?: boolean
  isReadOnly?: boolean
  onlyActives?: boolean
  shouldFillWidth?: boolean
  isForTopBar?: boolean
  required?: boolean
  dataCy?: string
  filterIds?: string[]
  excludedIds?: string[]
  isUnselectAllowed?: boolean
  shouldUseUserSites?: boolean
  data?: IDictEntry[]
  defaultWhenCleared?: string
  customClass?: string
  type?: CancellationReasonType
  startDate?: string
  endDate?: string
  siteIds?: string[]
  sortBy?: string
  sortDirection?: number
  customFormClass?: string
  removeMargin?: boolean
  carrierIds?: string[]
  filterKey?: string
}

interface IActive {
  [key: string]: string | boolean | number | object
  active: boolean
}

interface ITextChange {
  target: {
    value: string
    name: string
    type?: string
    checked?: boolean
  }
}

export const WarehouseFilter = ({
  handleChange,
  ids,
  disabled,
  onlyOneValue,
  isReadOnly,
  shouldFillWidth,
  required,
  placeholder,
  dataCy,
  isUnselectAllowed,
  isForTopBar,
  shouldUseUserSites = true,
}: IFilterFn): JSX.Element => {
  const { user } = useContext(AuthContext)
  const userSites: string[] = user?.sites.map((site) => site.siteId) || []

  return (
    <FilterListSelect
      dataCy={dataCy}
      handleChange={handleChange}
      dataKeyFromContext="warehouses"
      ids={ids}
      disabled={disabled}
      onlyOneValue={onlyOneValue}
      isReadOnly={isReadOnly}
      shouldFillWidth={shouldFillWidth}
      required={required}
      placeholder={placeholder}
      isUnselectAllowed={isUnselectAllowed}
      filterIds={shouldUseUserSites ? userSites : undefined}
      isForTopBar={isForTopBar}
    />
  )
}

export const SiteFilter = ({
  handleChange,
  ids,
  placeholder,
  disabled,
  dataCy,
  onlyActives,
  shouldFillWidth,
  filterIds,
  excludedIds,
  onlyOneValue,
  required,
  isUnselectAllowed,
  isForTopBar,
  shouldUseUserSites = true,
  data,
  defaultWhenCleared,
  customFormClass,
  removeMargin,
  filterKey,
}: IFilterFn): JSX.Element => {
  const { user } = useContext(AuthContext)
  const { defaultSiteId } = useContext(DefaultSiteContext)
  const userSites: string[] = user?.sites.map((site) => site.siteId) || []
  let allFilterIds: string[] | undefined = filterIds
  if (shouldUseUserSites) {
    allFilterIds = filterIds
      ? userSites.filter((userSite) => filterIds.includes(userSite))
      : userSites
  }

  return (
    <FilterListSelect
      handleChange={handleChange}
      dataKeyFromContext="sites"
      placeholder={placeholder}
      ids={ids}
      disabled={disabled}
      dataCy={dataCy}
      onlyActives={onlyActives}
      shouldFillWidth={shouldFillWidth}
      filterIds={allFilterIds}
      excludedIds={excludedIds}
      onlyOneValue={onlyOneValue}
      required={required}
      isForTopBar={isForTopBar}
      isUnselectAllowed={isUnselectAllowed}
      data={data}
      defaultWhenCleared={defaultWhenCleared || defaultSiteId || ''}
      customFormClass={customFormClass}
      removeMargin={removeMargin}
      filterKey={filterKey}
    />
  )
}

export const SectorSiteFilter = ({
  handleChange,
  ids,
  placeholder,
  disabled,
  dataCy,
  onlyActives,
  shouldFillWidth,
  filterIds,
  onlyOneValue,
  required,
  isUnselectAllowed,
  isForTopBar,
  data,
  customClass,
}: IFilterFn): JSX.Element => (
  <FilterListSelect
    handleChange={handleChange}
    dataKeyFromContext="sectors"
    placeholder={placeholder}
    ids={ids}
    disabled={disabled}
    dataCy={dataCy}
    onlyActives={onlyActives}
    shouldFillWidth={shouldFillWidth}
    filterIds={filterIds}
    onlyOneValue={onlyOneValue}
    required={required}
    isForTopBar={isForTopBar}
    isUnselectAllowed={isUnselectAllowed}
    data={data}
    customClass={customClass}
  />
)

export const CompanyFilter = ({
  handleChange,
  data,
  ids,
  dataCy,
  sortBy,
  sortDirection,
  filterKey,
}: IFilterFn): JSX.Element => (
  <FilterListSelect
    data={data}
    dataCy={dataCy}
    handleChange={handleChange}
    dataKeyFromContext="companies"
    ids={ids}
    sortBy={sortBy}
    sortDirection={sortDirection}
    filterKey={filterKey}
  />
)

export const RolesFilter = ({ handleChange, ids, dataCy }: IFilterFn): JSX.Element => (
  <FilterListSelect
    handleChange={handleChange}
    dataName="rolesCategory.title"
    data={ROLES_USER_FILTER_OPTIONS.sort(alphabeticSort)}
    ids={ids}
    dataCy={dataCy}
  />
)

export const PlanStatusFilter = ({ handleChange, ids, dataCy }: IFilterFn): JSX.Element => (
  <FilterListSelect
    handleChange={handleChange}
    data={ORDER_PLAN_STATUSES}
    ids={ids}
    dataName="status.title"
    dataCy={dataCy}
  />
)

export const ActiveFilter = ({ handleChange, ids, dataCy }: IFilterFn): JSX.Element => (
  <FilterListSelect
    handleChange={handleChange}
    dataName="status.activeTitle"
    data={CARRIER_STATUS}
    ids={ids}
    onlyOneValue
    dataCy={dataCy}
  />
)

export const SectorFilter = ({ handleChange, ids, dataCy }: IFilterFn): JSX.Element => (
  <FilterListSelect
    handleChange={handleChange}
    dataName="status.sectorTitle"
    data={SITE_SECTOR}
    ids={ids}
    onlyOneValue
    dataCy={dataCy}
  />
)

export const TenantFilter = ({
  handleChange,
  ids,
  dataCy,
  placeholder,
  onlyOneValue,
  shouldFillWidth,
  required,
  isUnselectAllowed,
  disabled,
  sortBy,
  sortDirection,
}: IFilterFn): JSX.Element => (
  <FilterListSelect
    handleChange={handleChange}
    placeholder={placeholder}
    dataKeyFromContext="tenants"
    ids={ids}
    dataCy={dataCy}
    onlyOneValue={onlyOneValue}
    shouldFillWidth={shouldFillWidth}
    required={required}
    isUnselectAllowed={isUnselectAllowed}
    disabled={disabled}
    sortBy={sortBy}
    sortDirection={sortDirection}
  />
)

export const StatusFilter = ({ handleChange, ids, dataCy }: IFilterFn): JSX.Element => (
  <FilterListSelect
    handleChange={handleChange}
    dataName="status.title"
    data={TOUR_STATUS}
    ids={ids}
    dataCy={dataCy}
  />
)

export const VehicleCatFilter = ({
  handleChange,
  ids,
  onlyActives,
  isReadOnly,
  shouldFillWidth,
  dataCy,
  disabled,
}: IFilterFn): JSX.Element => (
  <FilterListSelect
    handleChange={handleChange}
    dataKeyFromContext="vehicleTypes"
    ids={ids}
    isReadOnly={isReadOnly}
    onlyActives={onlyActives}
    shouldFillWidth={shouldFillWidth}
    dataCy={dataCy}
    disabled={disabled}
  />
)

export const WarehouseTypeFilter = ({ handleChange, ids, dataCy }: IFilterFn): JSX.Element => (
  <FilterListSelect
    dataCy={dataCy}
    handleChange={handleChange}
    dataName="typeOfSite.title"
    data={WAREHOUSE_TYPES.sort(alphabeticSort)}
    ids={ids}
  />
)

export const PlanTourStatusFilter = ({
  handleChange,
  ids,
  disabled,
  dataCy,
}: IFilterFn): JSX.Element => (
  <FilterListSelect
    dataCy={dataCy}
    handleChange={handleChange}
    dataName="planTourStatus.title"
    data={PLAN_TOUR_STATUS}
    ids={ids}
    disabled={disabled}
  />
)

export const DeliveryTypeFilter = ({
  handleChange,
  ids,
  isReadOnly,
  shouldFillWidth,
  disabled,
  dataCy,
  isUnselectAllowed,
  required,
  customFormClass,
  removeMargin,
  onlyOneValue,
  placeholder,
}: IFilterFn): JSX.Element => {
  const { user } = useContext(AuthContext)
  const userDeliveryTypes: IDeliveryTypeItem[] = formatDeliveryTypes(
    user?.tenantConfig?.deliveryTypes,
  ).map((deliveryType) => ({ id: deliveryType.code?.toString() || '', name: deliveryType.label }))

  return (
    <FilterListSelect
      handleChange={handleChange}
      dataName="deliveryType.title"
      data={userDeliveryTypes.sort(alphabeticSort)}
      ids={ids}
      isReadOnly={isReadOnly}
      disabled={disabled}
      shouldFillWidth={shouldFillWidth}
      dataCy={dataCy}
      defaultWhenCleared={userDeliveryTypes.length > 0 ? userDeliveryTypes[0].id : ''}
      isUnselectAllowed={isUnselectAllowed}
      required={required}
      customFormClass={customFormClass}
      removeMargin={removeMargin}
      onlyOneValue={onlyOneValue}
      placeholder={placeholder}
    />
  )
}

export const TransportTypeFilter = ({
  handleChange,
  ids,
  dataCy,
  shouldFillWidth,
  disabled,
  required,
  customFormClass,
  removeMargin,
  onlyOneValue,
  placeholder,
  isUnselectAllowed,
}: IFilterFn): JSX.Element => (
  <FilterListSelect
    handleChange={handleChange}
    dataName="transportType.title"
    data={TRANSPORT_TYPE_OPTIONS}
    ids={ids}
    dataCy={dataCy}
    disabled={disabled}
    shouldFillWidth={shouldFillWidth}
    required={required}
    customFormClass={customFormClass}
    removeMargin={removeMargin}
    onlyOneValue={onlyOneValue}
    placeholder={placeholder}
    isUnselectAllowed={isUnselectAllowed}
  />
)

export const DestinationTypeFilter = ({
  handleChange,
  ids,
  isReadOnly,
  shouldFillWidth,
  disabled,
  dataCy,
  isUnselectAllowed,
  required,
  customFormClass,
  removeMargin,
  onlyOneValue,
  placeholder,
}: IFilterFn): JSX.Element => (
  <FilterListSelect
    handleChange={handleChange}
    dataName="destinationType.title"
    data={DESTINATION_TYPE_OPTIONS}
    ids={ids}
    isReadOnly={isReadOnly}
    disabled={disabled}
    shouldFillWidth={shouldFillWidth}
    dataCy={dataCy}
    defaultWhenCleared=""
    isUnselectAllowed={isUnselectAllowed}
    required={required}
    customFormClass={customFormClass}
    removeMargin={removeMargin}
    onlyOneValue={onlyOneValue}
    placeholder={placeholder}
  />
)

export const RatingFilter = ({
  handleChange,
  ids,
  data,
  onlyActives,
  isReadOnly,
  shouldFillWidth,
  dataCy,
  onlyOneValue,
  disabled,
  required,
  isUnselectAllowed,
}: IFilterFn): JSX.Element => (
  <FilterListSelect
    handleChange={handleChange}
    dataName="ratingValue.title"
    data={data}
    ids={ids}
    onlyActives={onlyActives}
    isReadOnly={isReadOnly}
    shouldFillWidth={shouldFillWidth}
    disabled={disabled}
    dataCy={dataCy}
    onlyOneValue={onlyOneValue}
    required={required}
    isUnselectAllowed={isUnselectAllowed}
  />
)

export const CarrierFilter = ({
  handleChange,
  ids,
  data,
  onlyActives,
  isReadOnly,
  shouldFillWidth,
  dataCy,
  onlyOneValue,
  disabled,
  required,
  isUnselectAllowed,
  filterIds,
  startDate,
  endDate,
  siteIds,
  filterKey,
}: IFilterFn): JSX.Element => (
  <FilterListSelect
    handleChange={handleChange}
    dataKeyFromContext="carriers"
    ids={ids}
    data={data}
    onlyActives={onlyActives}
    isReadOnly={isReadOnly}
    shouldFillWidth={shouldFillWidth}
    disabled={disabled}
    dataCy={dataCy}
    onlyOneValue={onlyOneValue}
    required={required}
    isUnselectAllowed={isUnselectAllowed}
    filterIds={filterIds}
    startDate={startDate}
    endDate={endDate}
    siteIds={siteIds}
    filterKey={filterKey}
  />
)

export const UseFilter = ({ handleChange, ids, placeholder }: IFilterFn): JSX.Element => (
  <FilterListSelect
    handleChange={handleChange}
    data={MOBILE_APP_INDICATOR_TOUR_STATUS}
    ids={ids}
    placeholder={placeholder}
  />
)

export const DriverFilter = ({
  handleChange,
  ids,
  onlyActives,
  isReadOnly,
  shouldFillWidth,
  dataCy,
  onlyOneValue,
  disabled,
  data,
  siteIds,
  carrierIds,
  filterKey,
}: IFilterFn): JSX.Element => (
  <FilterListSelect
    handleChange={handleChange}
    dataKeyFromContext="drivers"
    ids={ids}
    onlyActives={onlyActives}
    isReadOnly={isReadOnly}
    disabled={disabled}
    shouldFillWidth={shouldFillWidth}
    dataCy={dataCy}
    onlyOneValue={onlyOneValue}
    data={data}
    siteIds={siteIds}
    carrierIds={carrierIds}
    filterKey={filterKey}
  />
)

export const DisputeReasonTypeFilter = ({
  handleChange,
  ids,
  onlyActives,
  isReadOnly,
  shouldFillWidth,
  dataCy,
  onlyOneValue,
  disabled,
  data,
}: IFilterFn): JSX.Element => (
  <FilterListSelect
    handleChange={handleChange}
    dataKeyFromContext="disputeReasonTypes"
    ids={ids}
    onlyActives={onlyActives}
    isReadOnly={isReadOnly}
    disabled={disabled}
    shouldFillWidth={shouldFillWidth}
    dataCy={dataCy}
    onlyOneValue={onlyOneValue}
    data={data}
  />
)

export const DisputeReasonCodeFilter = ({
  handleChange,
  ids,
  onlyActives,
  isReadOnly,
  shouldFillWidth,
  dataCy,
  onlyOneValue,
  disabled,
  data,
}: IFilterFn): JSX.Element => (
  <FilterListSelect
    handleChange={handleChange}
    dataKeyFromContext="disputeReasonCodes"
    ids={ids}
    onlyActives={onlyActives}
    isReadOnly={isReadOnly}
    disabled={disabled}
    shouldFillWidth={shouldFillWidth}
    dataCy={dataCy}
    onlyOneValue={onlyOneValue}
    data={data}
    mainKey="code"
  />
)

export const NotificationTypeFilter = ({
  handleChange,
  ids,
  placeholder,
  required,
  shouldFillWidth,
}: IFilterFn): JSX.Element => (
  <FilterListSelect
    handleChange={handleChange}
    data={CUSTOMER_NOTIFICATION_TYPE}
    ids={ids}
    placeholder={placeholder}
    shouldFillWidth={shouldFillWidth}
    onlyOneValue
    required={required}
  />
)

export const SmsCommunicationServiceFilter = ({
  handleChange,
  ids,
  placeholder,
  required,
  shouldFillWidth,
}: IFilterFn): JSX.Element => (
  <FilterListSelect
    handleChange={handleChange}
    data={SMS_COMMUNICATION_SERVICE}
    ids={ids}
    placeholder={placeholder}
    shouldFillWidth={shouldFillWidth}
    isUnselectAllowed
    onlyOneValue
    required={required}
  />
)

export const DealerFilter = ({
  handleChange,
  ids,
  placeholder,
  disabled,
  dataCy,
  shouldFillWidth,
  onlyOneValue,
  required,
  isUnselectAllowed,
  data,
}: IFilterFn): JSX.Element => (
  <FilterListSelect
    handleChange={handleChange}
    dataKeyFromContext="dealers"
    placeholder={placeholder}
    ids={ids}
    disabled={disabled}
    dataCy={dataCy}
    shouldFillWidth={shouldFillWidth}
    onlyOneValue={onlyOneValue}
    required={required}
    isUnselectAllowed={isUnselectAllowed}
    data={data}
  />
)

export const ReadFilter = ({ handleChange, ids, dataCy }: IFilterFn): JSX.Element => (
  <FilterListSelect
    handleChange={handleChange}
    dataName="status.readStateTitle"
    data={READ_STATE}
    ids={ids}
    onlyOneValue
    dataCy={dataCy}
  />
)

export const TourNumberFilter = ({
  handleChange,
  ids,
  dataCy,
  onlyOneValue,
  data,
  placeholder,
}: IFilterFn): JSX.Element => (
  <FilterListSelect
    handleChange={handleChange}
    ids={ids}
    dataCy={dataCy}
    onlyOneValue={onlyOneValue}
    data={data}
    placeholder={placeholder}
  />
)

export const CancellationReasonsFilter = ({ handleChange, ids, type }: IFilterFn): JSX.Element => {
  const { t } = useTranslation()
  const { user } = useContext(AuthContext)
  const userCancellationReasons: ICancellationReason[] = user?.tenantConfig?.cancellationReasons || []
  const data = userCancellationReasons
    .filter((cancellationReason) => cancellationReason.active && cancellationReason.type === type)
    .map(formatCancellationReason)

  return (
    <FilterListSelect
      handleChange={handleChange}
      ids={ids}
      placeholder={t('CancellationsScreen.reasons')}
      data={data}
    />
  )
}

export const FilterListSelect = ({
  handleChange,
  dataKeyFromContext,
  data,
  dataName,
  ids,
  placeholder,
  disabled,
  onlyActives,
  onlyOneValue,
  isReadOnly,
  shouldFillWidth,
  isForTopBar,
  required,
  dataCy,
  filterIds,
  excludedIds,
  isUnselectAllowed = true,
  defaultWhenCleared,
  customClass,
  mainKey = 'id',
  startDate,
  endDate,
  siteIds,
  sortBy,
  sortDirection = 1,
  customFormClass,
  removeMargin,
  carrierIds,
  filterKey,
}: IFilterProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const contentContext = useContext(ContentContext)
  const { setFilter, filters } = useContext(FiltersContext)

  const [items, setItems] = useState<IDictEntry[]>([])
  const inputRef = useRef<HTMLInputElement>(null)

  const checkIsAllSelected = (
    currentFilterKey: string,
    typeKey: string,
    availableOptionsIds: string,
  ) => {
    const selected = filters[currentFilterKey][typeKey] as string[]
    let filteredSelected: string[] = []
    if (selected.length > 0) {
      filteredSelected = selected.filter((id) => availableOptionsIds.includes(id))
      setFilter(currentFilterKey, typeKey, filteredSelected)
    }
    setIsAllSelected(
      filteredSelected.length > 0 && filteredSelected.length === availableOptionsIds.length,
    )
  }

  const options = useMemo(() => {
    const dataToUse = data || (dataKeyFromContext && contentContext[dataKeyFromContext])
    let filteredOptions = filterIds
      ? dataToUse.filter((item) => filterIds.includes(item[mainKey]))
      : dataToUse
    if (excludedIds) {
      filteredOptions = filteredOptions.filter((option) => !excludedIds.includes(option[mainKey]))
    }

    const result = onlyActives
      ? filteredOptions.filter((item: IActive) => item.active)
      : filteredOptions

    if (Array.isArray(result) && sortBy && sortDirection) {
      result.sort((a, b) => a[sortBy].localeCompare(b[sortBy]) * sortDirection)
    }

    if (dataKeyFromContext === Filter.carriers && siteIds && siteIds.length > 0) {
      const filteredAvailableCarriers = result.filter((it) =>
        it.warehouses?.find(warehouseSiteDateFilterFactory(siteIds, startDate, endDate)),
      )

      if (filterKey) {
        const filteredAvailableCarriersIds = filteredAvailableCarriers.map(
          (carrierItem) => carrierItem.id,
        )
        checkIsAllSelected(filterKey, Filter.carriers, filteredAvailableCarriersIds)
      }

      return filteredAvailableCarriers
    }

    if (
      dataKeyFromContext === Filter.drivers
      && ((carrierIds && carrierIds.length > 0) || (siteIds && siteIds.length > 0))
    ) {
      let filteredAvailableDrivers = result

      if (siteIds && siteIds.length > 0) {
        filteredAvailableDrivers = filteredAvailableDrivers.filter((it) =>
          siteIds?.includes(it.siteId),
        )
      }

      if (carrierIds && carrierIds.length > 0) {
        filteredAvailableDrivers = filteredAvailableDrivers.filter((it) =>
          carrierIds?.includes(it.carrierId),
        )
      }

      if (filterKey) {
        const filteredAvailableDriversIds = filteredAvailableDrivers.map(
          (driverItem) => driverItem.id,
        )
        checkIsAllSelected(filterKey, Filter.drivers, filteredAvailableDriversIds)
      }

      return filteredAvailableDrivers
    }

    if (dataKeyFromContext === Filter.sites && filterKey) {
      const sites = filters[filterKey][Filter.sites] as string[]
      setIsAllSelected(sites.length > 0 && result.length === sites.length)
    }

    if (dataKeyFromContext === Filter.companies && filterKey) {
      const companies = filters[filterKey][Filter.companies] as string[]
      setIsAllSelected(companies.length > 0 && result.length === companies.length)
    }

    return result
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    dataKeyFromContext,
    filterIds,
    excludedIds,
    onlyActives,
    siteIds,
    carrierIds,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    contentContext[dataKeyFromContext || ''],
  ])

  useEffect(() => {
    setItems(options)
  }, [options])

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.focus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue])

  function handleSelect(event: ChangeEvent<{ value: unknown }>): void {
    // Checking on value and items undefined because of an issue with the select all check triggering an undefined select
    if (event.target.value !== undefined) {
      const itemArrayRaw = event.target.value as (string | undefined)[]
      if (!itemArrayRaw.includes(undefined)) {
        const itemArray = itemArrayRaw as string[]
        if (onlyOneValue) {
          if (itemArray && itemArray.length > 0) {
            handleChange([itemArray[itemArray.length - 1]])
          } else if (isUnselectAllowed) {
            handleChange([])
          }
          setIsOpen(false)
        } else {
          if (itemArray && itemArray.length > 0) {
            handleChange(itemArray)
          } else if (isUnselectAllowed) {
            handleChange([])
          }
        }
      }
    }
  }

  const handleSelectAll = (): void => {
    setIsAllSelected((prev) => {
      handleChange(
        !prev
          ? options.map((option) => option[mainKey])
          : isUnselectAllowed
            ? []
            : [defaultWhenCleared || options[0][mainKey]],
      )
      return !prev
    })
  }

  const handleInputChange = (event: ITextChange): void => {
    const { target } = event
    const { value } = target
    setSearchValue(value)
    setItems(
      options.filter((option) =>
        (option.name || option.label).toLowerCase().includes(value.toLowerCase()),
      ),
    )
  }

  const stopImmediatePropagation = (e: {
    stopPropagation: () => void
    preventDefault: () => void
  }): void => {
    e.stopPropagation()
    e.preventDefault()
  }

  const handleClose = (): void => {
    setIsOpen(false)
    setSearchValue('')
    setItems(options)
  }

  const label = placeholder || t(`tablesEntries.${dataKeyFromContext || dataName}`)

  return (
    <FormControl
      className={customFormClass || ''}
      variant={customClass && disabled ? undefined : 'outlined'}
    >
      <InputLabel
        required={required}
        style={isForTopBar ? { color: '#FFF' } : {}}
        className={customClass || removeMargin ? undefined : classes.filtersMargin}
      >
        {label}
      </InputLabel>
      <Select
        readOnly={isReadOnly}
        value={ids}
        onChange={handleSelect}
        className={
          customClass
          || clsx(
            removeMargin ? undefined : classes.filtersMargin,
            shouldFillWidth ? undefined : classes.filtersWidth,
            isForTopBar ? classes.filterWidthHeight : undefined,
          )
        }
        label={label}
        multiple
        disabled={disabled}
        open={isOpen}
        onClose={handleClose}
        onOpen={() => {
          setIsOpen(true)
        }}
        data-cy={dataCy}
        IconComponent={customClass && disabled ? () => <></> : undefined}
        style={isForTopBar ? { color: '#FFF' } : {}}
      >
        <MenuItem value={-1} disabled>
          {label}
        </MenuItem>
        <MenuItem onKeyDown={(e) => e.stopPropagation()} onClickCapture={stopImmediatePropagation}>
          <TextField
            inputRef={inputRef}
            placeholder={t('Filter.search')}
            value={searchValue}
            onChange={handleInputChange}
          />
        </MenuItem>
        {!onlyOneValue && (
          <MenuItem style={{ paddingRight: 0 }} divider data-cy="filter-select-all">
            <FormControlLabel
              style={{ width: '100%', marginRight: 0 }}
              control={<Checkbox checked={isAllSelected} onChange={handleSelectAll} />}
              label={t('Filter.selectAll')}
            />
          </MenuItem>
        )}
        {items.map((elem: IDictEntry) => (
          <MenuItem
            data-cy={`item-option-${elem.name || elem.label}`}
            key={elem[mainKey]}
            value={elem[mainKey]}
          >
            {elem.name || elem.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

// *****************************************************************************
// HELPERS
// *****************************************************************************

function warehouseSiteDateFilterFactory(
  siteIds: string[] | undefined,
  startDate: string | undefined,
  endDate: string | undefined,
) {
  const startTimestamp = dateStringToTimestamp(startDate)
  const endTimestamp = dateStringToTimestamp(endDate)

  return ({ id, validityStartDate, validityEndDate }: IWarehouse): boolean => {
    if (siteIds && !siteIds.includes(id)) {
      return false
    }

    if (validityEndDate && startTimestamp) {
      return new Date(validityEndDate).getTime() >= startTimestamp
    }

    if (validityStartDate && endTimestamp) {
      return new Date(validityStartDate).getTime() <= endTimestamp
    }

    return true
  }
}

function dateStringToTimestamp(dateStr: string | undefined): number | null {
  if (!dateStr) {
    return null
  }

  const ts = Date.parse(dateStr)
  return Number.isNaN(ts) ? null : ts
}

function formatCancellationReason(cancellationReason: ICancellationReason): IDictEntry {
  return {
    id: cancellationReason.code,
    label: cancellationReason.label,
  } as IDictEntry
}
