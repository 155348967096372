import Api from 'services/api'
import { ITenantFilters } from 'interfaces/interfaces'
import { ITenant } from 'interfaces/ITenant'
import { IError } from 'api/types'
import { NUMBER_OF_TENANTS_BY_REQUEST } from 'constants/constants'

const parseSingleTenant = (tenant: ITenant): ITenant => ({
  id: tenant.id,
  name: tenant.name,
})

const parseTenants = (tenants: ITenant[]): Array<ITenant> =>
  tenants.map((tenant) => parseSingleTenant(tenant))

const getList = async (
  filters?: ITenantFilters,
  offset?: number,
  sortBy?: string,
  sortDirection?: number,
): Promise<{ tenants: Array<ITenant>; count: number } | IError> => {
  try {
    const limit = NUMBER_OF_TENANTS_BY_REQUEST
    const filtersToApply = {
      ...((filters?.searchText?.length || 0) >= 3 && { search: filters?.searchText }),
    }
    const response = await Api.get('tenants', {
      ...filtersToApply,
      offset,
      limit,
      sortBy: sortBy || 'name',
      sortDirection: sortDirection || 1,
    })
    return { tenants: parseTenants(response.items), count: response.count }
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const get = async (id: string): Promise<ITenant | IError> => {
  try {
    const response = await Api.get(`tenants/details/${id}`)
    return response
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const create = async (tenant: ITenant): Promise<ITenant | IError> => {
  try {
    const body = {
      name: tenant.name,
      tenantConfig: {
        ...tenant,
        name: undefined,
      },
      klareoProducts: tenant.klareoProducts,
    }
    const response = await Api.post('tenants', body)
    return response
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

const update = async (id: string, tenant: ITenant): Promise<ITenant | IError> => {
  try {
    const body = {
      name: tenant.name,
      tenantConfig: {
        ...tenant,
        name: undefined,
      },
      klareoProducts: tenant.klareoProducts,
    }
    const response = await Api.patch(`tenants/${id}`, body)
    return response
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

const initialize = async (id: string): Promise<void | IError> => {
  try {
    await Api.post(`tenants/${id}/initialize`)
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

export default {
  getList,
  get,
  create,
  update,
  initialize,
}
