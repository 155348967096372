import React, { useEffect, useMemo, useRef } from 'react'
import { Marker, Popup } from 'react-leaflet'
import L from 'leaflet'
import ReactDOMServer from 'react-dom/server'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import moment from 'moment'

import { IMarker, ITourStopsNumber } from 'interfaces/map'
import { dateToTimeFormat } from 'utils/dateFormat'
import TruckIcon from 'assets/icons/truck'
import PinIcon from './PinIcon'

interface IDriverMarker {
  data: IMarker
  color: string
  isPopupOpen: boolean
  isPopupControlClicked: boolean
  numberOfStops: ITourStopsNumber
}

const ICON_WIDTH = 50
const ICON_HEIGHT = 50

const Icon = ({ color }: { color: string }): JSX.Element => (
  <PinIcon pinColor={color} circleSize={1.1}>
    <TruckIcon color={color} />
  </PinIcon>
)

const StyledPopup = styled(Popup)<{ borderColor: string }>`
  .leaflet-popup-content-wrapper {
    border: 2px solid ${(props) => props.borderColor};
    box-shadow: 0 0px 0px 4px ${(props) => props.borderColor}66;
    color: black;
    border-radius: 40px;
  }

  .leaflet-popup-content {
    margin: 5px 15px;
  }

  .leaflet-popup-tip-container {
    visibility: hidden;
  }

  .leaflet-popup-close-button {
    visibility: hidden;
  }
`
const DriverName = styled.div<{ color: string }>`
  color: ${(props) => props.color};
  font-size: large;
`

const DriverMarker = ({
  data,
  color,
  isPopupOpen,
  isPopupControlClicked,
  numberOfStops,
}: IDriverMarker): JSX.Element | null => {
  const { t } = useTranslation()
  const leafletRef = useRef<L.Marker>(null)

  useEffect(() => {
    if (isPopupOpen) {
      leafletRef.current?.openPopup()
    } else {
      leafletRef.current?.closePopup()
    }
  }, [isPopupControlClicked, isPopupOpen])

  const generateIcon = useMemo(
    () =>
      L.divIcon({
        className: 'custom icon',
        html: ReactDOMServer.renderToString(<Icon color={color} />),
        iconAnchor: [ICON_WIDTH / 2, ICON_HEIGHT],
        popupAnchor: [0, -ICON_HEIGHT],
      }),
    [color],
  )

  if (!data) {
    return null
  }
  const { location, driverName, lastLocationDate } = data
  const shouldDisplayMarker = location
    && location.latitude
    && location.longitude
    && moment().diff(moment(lastLocationDate), 'hour') <= 2
  if (shouldDisplayMarker) {
    const { stopsNumber, realizedStopsNumber } = numberOfStops
    return (
      <Marker
        position={{ lat: location.latitude, lng: location.longitude }}
        icon={generateIcon}
        ref={leafletRef}
      >
        <StyledPopup autoClose={false} borderColor={color} autoPan={false}>
          <div data-cy="driverInfoContainer">
            <DriverName data-cy="driverName" color={color}>
              {driverName}
            </DriverName>
            {stopsNumber && (
              <div data-cy="stopNumber">
                {t('MapScreen.numberOfStops', { realizedStopsNumber, stopsNumber })}
              </div>
            )}
            {lastLocationDate && (
              <div data-cy="lastLocationHour">{dateToTimeFormat(lastLocationDate)}</div>
            )}
          </div>
        </StyledPopup>
      </Marker>
    )
  }
  return null
}

export default DriverMarker
