import React, { useContext, useState } from 'react'

import { ITourMapItem } from 'interfaces/map'
import { MapContext } from 'store/MapContext'
import { Polyline } from 'react-leaflet'
import ResumeAbandonedTourModal from 'components/ResumeAbandonedTourModal/ResumeAbandonedTourModal'
import { TOUR_STATUS_ENUM } from 'constants/constants'

interface IPolyline {
  tour: ITourMapItem
  color: string
  noBorder?: boolean
  selected?: boolean
  allowResumeAbandonnedTours?: boolean
}

const OPACITY_UNSELECTED = 0

const CustomPolyline = ({
  tour,
  color,
  noBorder,
  selected = true,
  allowResumeAbandonnedTours,
}: IPolyline): JSX.Element => {
  const [isResumeAbandonnedTourModalOpen, setIsResumeAbandonnedTourModalOpen] = useState<boolean>(false)
  const { canvas } = useContext(MapContext)

  if (!tour.roadpath || tour.roadpath?.length === 0) {
    return <></>
  }

  const openResumeAbandonnedTourModal = () => {
    setIsResumeAbandonnedTourModalOpen(true)
  }

  const closeResumeAbandonnedTourModal = () => {
    setIsResumeAbandonnedTourModalOpen(false)
  }

  const eventHandlers = allowResumeAbandonnedTours && tour.status === TOUR_STATUS_ENUM.DROPPED
    ? {
      click: openResumeAbandonnedTourModal,
    }
    : undefined

  return (
    <>
      {!noBorder && (
        <Polyline
          positions={tour.roadpath}
          pathOptions={{ color: 'grey', weight: 6, opacity: selected ? 1 : OPACITY_UNSELECTED }}
          renderer={canvas}
          eventHandlers={eventHandlers}
        />
      )}
      <Polyline
        positions={tour.roadpath}
        pathOptions={{ color, weight: 3, opacity: selected ? 1 : OPACITY_UNSELECTED }}
        renderer={canvas}
        eventHandlers={eventHandlers}
      />
      {allowResumeAbandonnedTours && (
        <ResumeAbandonedTourModal
          open={isResumeAbandonnedTourModalOpen}
          onClose={closeResumeAbandonnedTourModal}
          tour={tour}
        />
      )}
    </>
  )
}

export default CustomPolyline
