import Api from 'services/api'
import { IError } from 'api/types'
import { IWarehouseTimeSlotFilters } from 'interfaces/interfaces'
import {
  IOrderTimeSlot,
  IOrderWarehouseTimeSlotStatus,
  ITimeSlot,
  IWarehouseTimeSlot,
  IWarehouseTimeSlotForm,
  IWarehouseTimeSlotReportingConfiguration,
  IWarehouseTimeSlotStatus,
} from 'interfaces/IWarehouseTimeSlot'

const parseSingleWarehouseTimeSlot = (
  warehouseTimeSlot: IWarehouseTimeSlot,
): IWarehouseTimeSlot => ({
  id: warehouseTimeSlot.id,
  tenantId: warehouseTimeSlot.tenantId,
  label: warehouseTimeSlot.label,
  timeSlot: warehouseTimeSlot.timeSlot as ITimeSlot,
  active: warehouseTimeSlot.active,
  maxDelayInHour: warehouseTimeSlot.maxDelayInHour,
  minDelayInHour: warehouseTimeSlot.minDelayInHour,
  warehouseIds: warehouseTimeSlot.warehouseIds,
  sectorIds: warehouseTimeSlot.sectorIds,
})

const parseOrderWarehouseTimeSlots = (
  warehouseTimeSlots: IOrderWarehouseTimeSlotStatus,
): IOrderWarehouseTimeSlotStatus => ({
  id: warehouseTimeSlots.id,
  timeSlots: warehouseTimeSlots.timeSlots as IOrderTimeSlot[],
  active: warehouseTimeSlots.active,
})

const parseWarehouseTimeSlots = (
  warehouseTimeSlots: IWarehouseTimeSlot[],
): Array<IWarehouseTimeSlot> =>
  warehouseTimeSlots.map((warehouseTimeSlot) => parseSingleWarehouseTimeSlot(warehouseTimeSlot))

const getList = async (
  filters?: IWarehouseTimeSlotFilters,
  offset?: number,
  rowsPerPage?: number,
): Promise<{ warehouseTimeSlots: Array<IWarehouseTimeSlot>; count: number } | IError> => {
  try {
    const filtersToApply = {
      ...filters,
      warehouseIds: filters?.warehouseIds,
      ...((filters?.active?.length || 0) === 1 && {
        active: filters?.active && filters?.active[0],
      }),
      ...((filters?.searchText?.length || 0) >= 3 && { search: filters?.searchText }),
    }
    const response = await Api.get('warehouse-time-slots', {
      ...filtersToApply,
      offset,
      limit: rowsPerPage,
    })
    return { warehouseTimeSlots: parseWarehouseTimeSlots(response.items), count: response.count }
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const create = async (
  warehouseTimeSlot: IWarehouseTimeSlotForm,
): Promise<IWarehouseTimeSlot | IError> => {
  try {
    const response = await Api.post('warehouse-time-slots', warehouseTimeSlot)
    return response
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

const update = async (
  id: string,
  warehouseTimeSlot: IWarehouseTimeSlotForm,
): Promise<IWarehouseTimeSlot | IError> => {
  try {
    const response = await Api.patch(`warehouse-time-slots/${id}`, warehouseTimeSlot)
    return response
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

const get = async (id: string): Promise<IWarehouseTimeSlot | IError> => {
  try {
    const response = await Api.get(`warehouse-time-slots/details/${id}`)
    return parseSingleWarehouseTimeSlot(response)
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getReportingConfigurations = async (): Promise<
  IWarehouseTimeSlotReportingConfiguration[] | IError
> => {
  try {
    return await Api.get('warehouse-time-slots/reporting/configurations')
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getReporting = async (
  warehouseId: string,
  sectorId?: string,
): Promise<IWarehouseTimeSlotStatus | IError> => {
  try {
    return await Api.get('warehouse-time-slots/status', {
      warehouseId,
      sectorId,
      shouldFilterUnavailableSlots: 0,
      beginDayIndex: 0,
      endDayIndex: 3,
    })
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getTimeSlotsPerOrder = async (
  orderId: string,
): Promise<IOrderWarehouseTimeSlotStatus | IError> => {
  try {
    const response = await Api.get(`warehouse-time-slots/order/${orderId}`)
    return parseOrderWarehouseTimeSlots(response)
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getTimeSlotsPerWarehouseOrSector = async (
  warehouseId: string,
  longitude?: number,
  latitude?: number,
  beginDayIndex?: number,
  endDayIndex?: number,
  isCartDropoff = false,
): Promise<IOrderWarehouseTimeSlotStatus | IError> => {
  try {
    const response = await Api.get('warehouse-time-slots/warehouse-or-sector', {
      warehouseId,
      longitude,
      latitude,
      beginDayIndex,
      endDayIndex,
      isCartDropoff,
    })
    return parseOrderWarehouseTimeSlots(response)
  } catch (error) {
    return { error: { message: error.message } }
  }
}

export default {
  getList,
  create,
  update,
  get,
  getReportingConfigurations,
  getReporting,
  getTimeSlotsPerOrder,
  getTimeSlotsPerWarehouseOrSector,
}
