import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    scoreContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    scoreLabel: {
      marginRight: '6px',
    },
    scoreInnerContainer: {
      height: '100%',
    },
    addressMap: {
      flex: 1,
    },
    mapContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    addressContainer: {
      paddingBottom: '8px',
      padding: '12px 20px',
      display: 'flex',
      transform: 'scale(0.5)',
      transition: 'transform .2s',
      position: 'absolute',
      bottom: '-94px',
      left: '-40px',
      zIndex: 1001,
      backgroundColor: theme.color.semiTransparentBackground,
      '&:hover, &:focus-within': {
        opacity: '0.8',
        transform: 'scale(1.0) translate(50px,-104px)',
      },
    },
    geocodeBtn: {
      marginTop: '8px',
      marginBottom: '8px',
      backgroundColor: 'transparent',
      border: `1px solid ${theme.color.primary}`,
      color: theme.color.secondary,
    },
    geocodeResultsContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      width: '100%',
      paddingLeft: '20px',
      borderLeft: '1px solid grey',
      marginLeft: '20px',
    },
    geocodeLine: {
      cursor: 'pointer',
      border: '1px solid grey',
      borderRadius: '8px',
      boxShadow: `2px 2px 2px 0px ${theme.palette.secondary.main}`,
      width: '100%',
      padding: '6px',
      display: 'flex',
      justifyContent: 'space-between',
      gap: '6px',
    },
    geocodeAddress: {
      width: '260px',
      wordBreak: 'break-word',
    },
    inputs: {
      display: 'flex',
      flexDirection: 'column',
      width: '200px',
      justifyContent: 'flex-end',
    },
    sectorsMap: {
      height: '100%',
      zIndex: 1,
    },
    sectorsAddressContainer: {
      width: '200px',
      bottom: '-40px',
      '&:hover, &:focus-within': {
        transform: 'scale(1.0) translate(50px,-50px)',
      },
    },
    popupControlContainer: {
      marginTop: '72px',
    },
    popupControl: {
      width: '30px',
      height: '30px',
      lineHeight: '30px',
      border: 'none',
      backgroundColor: 'white',
      color: 'black',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: 'unset',
      padding: '0px',
      '&:hover': {
        background: theme.color.hover,
      },
    },
    hideDriverPopupContainer: {
      marginTop: '102px',
    },
    hideDriverPopup: {
      borderRadius: '0px 0px 2px 2px',
    },
    showDriverPopup: {
      borderRadius: '2px 2px 0px 0px',
    },
    noWrap: {
      whiteSpace: 'nowrap',
    },
    toursColumn: {
      padding: '10px',
      borderTop: `1px solid ${theme.palette.divider}`,
      justifyContent: 'space-between',
    },
    tourInfoContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    tourNumberContainer: {
      width: '105px',
    },
    tourNumber: {
      fontSize: 'large',
    },
    info: {
      padding: '6px',
      background: 'white',
      color: 'black',
      display: 'flex',
      flexDirection: 'column',
      margin: '8px 4px',
      borderRadius: '4px',
    },
    highlightedCard: {
      outline: '5px solid orange',
    },
    stopLabel: {
      fontWeight: 'bold',
      fontSize: '14px',
      textAlign: 'center',
    },
    stopAddress: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    tourInfoFooter: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 'small',
      justifyContent: 'space-between',
    },
    delayIndicator: {
      display: 'flex',
      alignItems: 'center',
      gap: '2px',
    },
    showDetailsButton: {
      padding: '0px',
      minWidth: 'unset',
    },
    toursList: {
      overflow: 'auto',
    },
    scrollable: {
      '&::-webkit-scrollbar': {
        width: '8px',
        height: '12px',
      },
      '&::-webkit-scrollbar-track': {
        webkitBorderRadius: '8px',
        borderRadius: '8px',
        background: theme.color.scrollbarBackground,
      },
      '&::-webkit-scrollbar-thumb': {
        webkitBorderRadius: '8px',
        borderRadius: '8px',
        background: theme.color.orange,
        border: `1px solid ${theme.color.scrollbarBorder}`,
      },
      '&::-webkit-scrollbar-thumb:window-inactive': {
        background: theme.color.orange,
      },
    },
    tourCollapse: {
      minHeight: 'unset !important',
    },
    toursMap: {
      flex: 1,
    },
    hidden: {
      visibility: 'hidden',
    },
    stop: {
      marginBottom: '0px',
    },
    visitsColumnsContainer: {
      marginLeft: '76px',
    },
    rightContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '4px',
    },
    colorsLegendContainer: {
      marginTop: '145px',
    },
    colorLegendsPaper: {
      border: 'none',
      backgroundColor: 'white',
      color: 'black',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      minWidth: 'unset',
      padding: '8px',
      fontSize: '10px',
    },
    colorLegendsContent: {
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
    },
    clickable: {
      cursor: 'pointer',
    },
  }),
)
