import React, { useState, createContext, ReactNode, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { isIError } from 'api/types'
import { getErrorList } from 'utils/errorUtils'
import { FeedbackContext } from 'store/FeedbackContext'
import { IDeliveryType } from 'interfaces/IDeliveryType'
import DeliveryTypesApi from 'api/deliveryTypes'
import { AuthContext } from './AuthContext'

interface IDeliveryTypesContext {
  deliveryTypes: IDeliveryType[]
  getDeliveryTypes: (tenantId: string) => void
  updateDeliveryTypes: (tenantId: string, deliveryTypes: IDeliveryType[]) => void
}

const { Provider, Consumer } = createContext<IDeliveryTypesContext>({} as IDeliveryTypesContext)

interface IProps {
  children: ReactNode
}

const DeliveryTypesProvider = ({ children }: IProps): JSX.Element => {
  const { openErrorSnack, openSuccessSnack, toggleLoader } = useContext(FeedbackContext)
  const { getUserData } = useContext(AuthContext)
  const [deliveryTypes, setDeliveryTypes] = useState<IDeliveryType[]>([])
  const { t } = useTranslation()

  const getDeliveryTypes = async (tenantId: string): Promise<void> => {
    toggleLoader(true)
    const response = await DeliveryTypesApi.getDeliveryTypes(tenantId)
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      setDeliveryTypes(response)
    }
    toggleLoader(false)
  }

  const updateDeliveryTypes = async (tenantId: string, types: IDeliveryType[]): Promise<void> => {
    toggleLoader(true)
    const response = await DeliveryTypesApi.updateDeliveryTypes(tenantId, types)
    if (isIError(response)) {
      response.error.errorList = getErrorList(response)
      if (response.error.fieldErrors) {
        openErrorSnack(response.error.errorList.join('\n'))
      } else {
        openErrorSnack(response.error.message)
      }
    } else {
      setDeliveryTypes(response)
      getUserData(true)
      openSuccessSnack(t('ConfigurationsScreen.successfullySaved'))
    }
    toggleLoader(false)
  }

  return (
    <Provider
      value={{
        deliveryTypes,
        getDeliveryTypes,
        updateDeliveryTypes,
      }}
    >
      {children}
    </Provider>
  )
}

export default DeliveryTypesProvider

export { Consumer as DeliveryTypesConsumer }
