import React from 'react'
import { useTranslation } from 'react-i18next'
import DeleteIcon from '@material-ui/icons/Delete'
import { FormControl, Button, TextField, InputLabel, Select, MenuItem } from '@material-ui/core'
import clsx from 'clsx'

import useStyles from 'constants/cruStyles'
import Card from 'components/Card/Card'
import { ITextChange } from 'interfaces/interfaces'
import { ICancellationReason } from 'interfaces/ICancellationReason'
import { CANCELLATION_REASON_TYPES } from 'constants/constants'
import FieldError from 'components/FieldError/FieldError'

interface IProps {
  cancellationReasons: ICancellationReason[]
  onCancellationReasonsChange: (cancellationReasons: ICancellationReason[]) => void
}

const CancellationReasonsCard = ({
  cancellationReasons,
  onCancellationReasonsChange,
}: IProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()

  const codeOrLabelAlreadyExistsForType = (
    property: 'code' | 'label',
    value: string,
    cancellationReasonId?: number,
    type?: string,
  ): boolean =>
    cancellationReasons
      .filter((cancellationReason) => cancellationReason.active && cancellationReason.type === type)
      .findIndex(
        (cancellationReason) =>
          cancellationReason[property].toLowerCase() === value.toLowerCase()
          && cancellationReason.id !== cancellationReasonId,
      ) !== -1

  const handleAdd = (): void => {
    const newId = Math.random()
    const errorCode = codeOrLabelAlreadyExistsForType('code', '', newId)
    const errorLabel = codeOrLabelAlreadyExistsForType('label', '', newId)
    onCancellationReasonsChange([
      ...cancellationReasons,
      {
        id: newId,
        type: undefined,
        code: '',
        label: '',
        active: true,
        errorCode,
        errorLabel,
        isNew: true,
      },
    ])
  }

  const handleDelete = (cancellationReasonId?: number, isNew?: boolean): void => {
    onCancellationReasonsChange(
      isNew
        ? cancellationReasons.filter(
          (cancellationReason) => cancellationReason.id !== cancellationReasonId,
        )
        : cancellationReasons.map((cancellationReason) => {
          if (cancellationReason.id === cancellationReasonId) {
            return {
              ...cancellationReason,
              active: false,
            }
          }
          return cancellationReason
        }),
    )
  }

  const handleInputChange = (
    event: ITextChange,
    cancellationReasonId?: number,
    type?: string,
  ): void => {
    const { target } = event
    const { name, value } = target

    onCancellationReasonsChange(
      cancellationReasons.map((cancellationReason) => {
        if (cancellationReason.id === cancellationReasonId) {
          return {
            ...cancellationReason,
            [name]: value,
            errorCode:
              name === 'code'
                ? codeOrLabelAlreadyExistsForType('code', value, cancellationReasonId, type)
                : cancellationReason.errorCode,
            errorLabel:
              name === 'label'
                ? codeOrLabelAlreadyExistsForType('label', value, cancellationReasonId, type)
                : cancellationReason.errorLabel,
          }
        }
        return cancellationReason
      }),
    )
  }

  const handleSelectChange = (
    event: React.ChangeEvent<{ value: unknown; name?: string }>,
    cancellationReasonId?: number,
  ): void => {
    const { target } = event
    const name = target.name as string
    const value = target.value as string

    onCancellationReasonsChange(
      cancellationReasons.map((cancellationReason) => {
        if (cancellationReason.id === cancellationReasonId) {
          return {
            ...cancellationReason,
            [name]: value,
            errorCode: codeOrLabelAlreadyExistsForType(
              'code',
              cancellationReason.code,
              cancellationReasonId,
              value,
            ),
            errorLabel: codeOrLabelAlreadyExistsForType(
              'label',
              cancellationReason.label,
              cancellationReasonId,
              value,
            ),
          }
        }
        return cancellationReason
      }),
    )
  }

  const typeSelectLabel = t('ConfigurationsScreen.type').toUpperCase()

  return (
    <Card
      dataCy="cancellationReasonsCard"
      title={t('ConfigurationsScreen.cancellationReasons').toUpperCase()}
      contentClassName={classes.contentRef}
    >
      <>
        <div className={classes.btnSelect}>
          <FormControl variant="outlined">
            <Button data-cy="addButton" className={classes.modifyButton} onClick={handleAdd}>
              {t('ConfigurationsScreen.add').toUpperCase()}
            </Button>
          </FormControl>
        </div>
        <div className={classes.fieldsContainer}>
          {cancellationReasons
            .filter((cancellationReason) => cancellationReason.active)
            .map((cancellationReason) => (
              <div
                data-cy="cancellationReasonItems"
                className={classes.configurationRow}
                key={cancellationReason.id}
              >
                <div className={classes.configurationItemsContainer}>
                  <FormControl variant="standard" error={!cancellationReason.type}>
                    <InputLabel required>{typeSelectLabel}</InputLabel>
                    <Select
                      name="type"
                      value={cancellationReason.type || ''}
                      onChange={(event) => handleSelectChange(event, cancellationReason.id)}
                      className={classes.filtersWidth}
                      label={typeSelectLabel}
                      data-cy="typeSelect"
                    >
                      <MenuItem data-cy={typeSelectLabel} value={-1} disabled>
                        {typeSelectLabel}
                      </MenuItem>
                      {CANCELLATION_REASON_TYPES.map((item) => (
                        <MenuItem data-cy={item.label} key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <div className={clsx(classes.sideTextField, classes.configurationTextField)}>
                    <TextField
                      data-cy="code"
                      error={cancellationReason.code === '' || cancellationReason.errorCode}
                      label={t('ConfigurationsScreen.code').toUpperCase()}
                      onChange={(event) =>
                        handleInputChange(event, cancellationReason.id, cancellationReason.type)}
                      name="code"
                      required
                      value={cancellationReason.code}
                    />
                    {cancellationReason.errorCode && (
                      <FieldError>{t('ConfigurationsScreen.codeAlreadyExistsForType')}</FieldError>
                    )}
                  </div>
                  <div className={clsx(classes.sideTextField, classes.configurationTextField)}>
                    <TextField
                      data-cy="label"
                      error={cancellationReason.label === '' || cancellationReason.errorLabel}
                      label={t('ConfigurationsScreen.label').toUpperCase()}
                      onChange={(event) =>
                        handleInputChange(event, cancellationReason.id, cancellationReason.type)}
                      name="label"
                      required
                      value={cancellationReason.label}
                    />
                    {cancellationReason.errorLabel && (
                      <FieldError>{t('ConfigurationsScreen.labelAlreadyExistsForType')}</FieldError>
                    )}
                  </div>
                </div>
                <Button
                  data-cy="deleteButton"
                  onClick={(): void => {
                    handleDelete(cancellationReason.id, cancellationReason.isNew)
                  }}
                >
                  <DeleteIcon />
                </Button>
              </div>
            ))}
        </div>
      </>
    </Card>
  )
}

export default CancellationReasonsCard
