import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import VehicleTypesProvider, { VehicleTypesConsumer } from 'store/VehicleTypesContext'
import CustomCard from 'components/CustomCard/CustomCard'
import { IVehicleType } from 'interfaces/IVehicleType'
import { IIdParam } from 'interfaces/IIdParam'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import Button from 'components/Button/CustomButton'

import useStyles from 'constants/cruStyles'

interface IProps {
  getDetails?: Function
  vehicleTypeDetails: IVehicleType
}

const VehicleTypeDetailsScreen = ({ getDetails, vehicleTypeDetails }: IProps): JSX.Element => {
  const { id } = useParams<IIdParam>()
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()

  useEffect(() => {
    if (id && getDetails) {
      getDetails(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const handleEditClick = (): void => {
    history.push(`${ROUTES_PATH.editVehicleType}${id}`)
  }

  return (
    <>
      <div className={classes.detailHeaderContainer}>
        <h3 data-cy="vehicleTypeDetailDescription">{`${vehicleTypeDetails.label || ''}`}</h3>
        <Button dataCy="updateButton" onPress={handleEditClick}>
          {t('VehicleTypesScreen.edit').toUpperCase()}
        </Button>
      </div>
      <div className={classes.detailsContent}>
        <div className={classes.detailsInnerContent}>
          <Grid data-cy="infoCard" item xs={10} className={classes.infoContainer}>
            <CustomCard
              cardTitle={t('VehicleTypesScreen.info').toUpperCase()}
              linePairs={[
                {
                  label: t('VehicleTypesScreen.label').toUpperCase(),
                  value: vehicleTypeDetails ? vehicleTypeDetails.label : '',
                },
                {
                  label: t('VehicleTypesScreen.code').toUpperCase(),
                  value: vehicleTypeDetails ? vehicleTypeDetails.code : '',
                },
                {
                  label: t('VehicleTypesScreen.active').toUpperCase(),
                  value: vehicleTypeDetails
                    ? vehicleTypeDetails.active
                      ? t('VehicleTypesScreen.yes')
                      : t('VehicleTypesScreen.no')
                    : '',
                },
                {
                  label: t('VehicleTypesScreen.profil').toUpperCase(),
                  value: vehicleTypeDetails ? vehicleTypeDetails.vehicleProfile?.label : '',
                },
                {
                  label: t('VehicleTypesScreen.capacity').toUpperCase(),
                  value: vehicleTypeDetails?.capacity ? `${vehicleTypeDetails.capacity}` : '',
                },
                {
                  label: t('VehicleTypesScreen.maxClientsPerTour').toUpperCase(),
                  value: vehicleTypeDetails?.maxClientsPerTour
                    ? `${vehicleTypeDetails.maxClientsPerTour}`
                    : '',
                },
              ]}
            />
          </Grid>
        </div>
      </div>
    </>
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <VehicleTypesProvider>
    <VehicleTypesConsumer>
      {(vehicleTypesCtx): JSX.Element => (
        <VehicleTypeDetailsScreen
          getDetails={vehicleTypesCtx.getDetails}
          vehicleTypeDetails={vehicleTypesCtx.vehicleTypeDetails}
          {...props}
        />
      )}
    </VehicleTypesConsumer>
  </VehicleTypesProvider>
)
