import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import Button from 'components/Button/CustomButton'
import FormAction from 'components/Button/FormAction'
import { CreatedOrder } from 'interfaces/IOrders'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import useStyles from './styles'

interface IOrderFormActionProps {
  isCartDropoff: boolean
  handleSaveAndPrintClick: () => Promise<void>
  handleSaveClick: () => Promise<CreatedOrder | void>
  isSaveDisabled?: boolean
  onSaveDraftClick: () => Promise<void>
}

const OrderFormAction = ({
  isCartDropoff,
  handleSaveAndPrintClick,
  handleSaveClick,
  isSaveDisabled,
  onSaveDraftClick,
}: IOrderFormActionProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { push: navigateTo } = useHistory()

  return isCartDropoff ? (
    <FormAction
      additionalButtonLabel={t('form.saveAndPrint')}
      onAdditionAction={handleSaveAndPrintClick}
      onSaveClick={handleSaveClick}
      onCancelClick={() => navigateTo(ROUTES_PATH.reloadCartDropoff)}
      additionalClass={classes.cartDropoffFormAction}
    >
      <Button dataCy="saveDraftButton" onPress={onSaveDraftClick}>
        {t('form.saveDraft')}
      </Button>
    </FormAction>
  ) : (
    <FormAction
      additionalButtonLabel={t('form.saveAndPrint')}
      onAdditionAction={handleSaveAndPrintClick}
      isSaveDisabled={isSaveDisabled}
      onSaveClick={handleSaveClick}
      onCancelClick={() => navigateTo(ROUTES_PATH.ordersList)}
    />
  )
}

export default OrderFormAction
