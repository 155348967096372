import { ICluster } from 'interfaces/ICluster'
import { IVehicleAvailability } from 'interfaces/IVehicleAvailability'

const computeAvailableVehicles = (
  vehiclesList: IVehicleAvailability[],
  elements: ICluster[],
  elementId: number,
): IVehicleAvailability[] => {
  const takenVehicleIds = new Set(
    [
      elements
        .filter((elem) => elem.clusterId !== elementId)
        .map((element) => element.vehicleIds)
        .flat(),
    ].flat(),
  )
  return vehiclesList?.filter((veh) => !takenVehicleIds.has(veh.id)) || []
}

export { computeAvailableVehicles }
