import React, { useEffect, useMemo, useContext, useState } from 'react'
import MaterialTable, { Action } from 'material-table'
import ArrowForward from '@material-ui/icons/ArrowForward'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { ITableColumn } from 'interfaces/interfaces'
import MUITableIcons from 'constants/MUITableIcons'
import Button from 'components/Button/CustomButton'
import AppConfigProvider, { AppConfigContext } from 'store/AppConfigContext'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import { INotifierConfiguration } from 'interfaces/INotifierConfiguration'
import { NotifierConfigurationsContext } from 'store/NotifierConfigurationsContext'
import { getPageSize, savePageSize } from 'utils/localStorage'
import { scrollTop } from 'utils/functions'
import { TenantFilter } from 'components/Inputs/ListFilter'
import { Filter, FilterKey } from 'constants/filters'
import { FiltersContext } from 'store/FiltersContext'
import { getDisplayRowsCount } from 'utils/tableUtils'
import { FiltersContainer, SearchContainer, TopFiltersContainer } from 'components/Layout'
import { AuthContext } from 'store/AuthContext'
import { USER_ROLES } from 'constants/constants'
import { isIntersecting } from 'utils/libs/array'
import useStyles from './styles'
import Pagination from './Pagination/Pagination'

interface ICustomTableProps {
  columns: ITableColumn[]
  notifierConfigurations: INotifierConfiguration[]
  count: number
}

const columnConfigName = 'notifierConfigurationsList'
const filterKey = FilterKey.notifierConfigurations

const CustomTable = ({
  columns,
  notifierConfigurations,
  count,
  ...rest
}: ICustomTableProps): JSX.Element => {
  const styles = useStyles()
  const history = useHistory()
  const { tablesConfig } = useContext(AppConfigContext)
  const { t } = useTranslation()
  const [paginationOffset, setPaginationOffset] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(getPageSize)
  const { getList } = useContext(NotifierConfigurationsContext)
  const { setFilter, filters } = useContext(FiltersContext)
  const tenant = filters[filterKey][Filter.tenant] as string[]
  const { user: currentUser } = useContext(AuthContext)

  useEffect(() => {
    if (rowsPerPage !== getPageSize()) {
      savePageSize(rowsPerPage)
      scrollTop()
    }
    if (getList) {
      let tenantsToFilter: string[] = []
      if (currentUser?.roles.includes(USER_ROLES.superAdmin)) {
        tenantsToFilter = tenant
      } else if (currentUser?.tenantId) {
        tenantsToFilter = [currentUser?.tenantId]
      }
      getList({ tenant: tenantsToFilter }, paginationOffset, rowsPerPage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, tenant])

  useEffect(() => {
    if (getList) {
      getList({ tenant }, paginationOffset, rowsPerPage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationOffset])

  const handleNewClick = (): void => {
    history.push(ROUTES_PATH.createNotifierConfig)
  }

  const handleDetailsClick = ({ id }: INotifierConfiguration): void => {
    history.push(`${ROUTES_PATH.notifierConfigDetails}${id}`)
  }

  const Filters = useMemo(
    (): JSX.Element =>
      isIntersecting(currentUser?.roles ?? [], [USER_ROLES.superAdmin, USER_ROLES.pilot]) ? (
        <TopFiltersContainer>
          <FiltersContainer>
            {currentUser?.roles.includes(USER_ROLES.superAdmin) && (
              <TenantFilter
                dataCy="tenantPicker"
                handleChange={(tenantValues: string[]): void => {
                  setFilter(filterKey, Filter.tenant, tenantValues)
                }}
                ids={tenant}
                placeholder={t('tablesEntries.tenant')}
              />
            )}
          </FiltersContainer>
          <SearchContainer>
            <Button dataCy="newButton" className={styles.newButton} onPress={handleNewClick}>
              {t('tablesEntries.new')}
            </Button>
          </SearchContainer>
        </TopFiltersContainer>
      ) : (
        <div />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tenant, styles, currentUser],
  )

  const handleChangeRowsPerPage = (pageSize: number): void => {
    setPage(0)
    setPaginationOffset(0)
    setRowsPerPage(pageSize)
  }

  const displayRowsCount = useMemo(
    () => getDisplayRowsCount(notifierConfigurations),
    [notifierConfigurations],
  )

  return (
    <>
      {Filters}
      <MaterialTable
        key={displayRowsCount}
        data={notifierConfigurations}
        columns={columns.map(
          (col) =>
            ({
              ...col,
              hidden: tablesConfig[columnConfigName]?.includes(col.title),
            } as unknown as MaterialTable<object>),
        )}
        {...rest}
        options={{
          toolbar: false,
          pageSize: displayRowsCount,
          actionsColumnIndex: -1,
        }}
        actions={[
          (data: INotifierConfiguration): Action<INotifierConfiguration> => ({
            icon: ArrowForward,
            tooltip: t('tablesEntries.seeDetails'),
            onClick: (): void => handleDetailsClick(data),
          }),
        ]}
        icons={MUITableIcons}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        components={{
          // eslint-disable-next-line react/prop-types, @typescript-eslint/no-unused-vars
          Pagination: ({ classes, ...props }): JSX.Element => (
            <Pagination
              {...props}
              columnConfigName={columnConfigName}
              columns={columns}
              count={count}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setPaginationOffset={setPaginationOffset}
            />
          ),
        }}
      />
    </>
  )
}

export default (props: ICustomTableProps): JSX.Element => (
  <AppConfigProvider>
    <CustomTable {...props} />
  </AppConfigProvider>
)
