import React from 'react'

interface ITabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
  className?: string
}

const TabPanel = ({ children, value, index, ...props }: ITabPanelProps): JSX.Element => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`vertical-tabpanel-${index}`}
    aria-labelledby={`vertical-tab-${index}`}
    {...props}
  >
    {value === index && children}
  </div>
)

export default TabPanel
