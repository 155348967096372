import Api from 'services/api'
import { ITrailer } from 'interfaces/ITrailer'
import { IError } from 'api/types'
import { ITrailerFilters } from 'interfaces/interfaces'

const parseSingleTrailer = (trailer: ITrailer): ITrailer => ({
  id: trailer.id,
  name: trailer.name,
  licencePlate: trailer.licencePlate,
  carrierId: trailer.carrierId,
  vehicleType: trailer.vehicleType,
  active: trailer.active,
})

const parseTrailers = (trailers: ITrailer[]): Array<ITrailer> =>
  trailers.map((trailer) => parseSingleTrailer(trailer))

const getList = async (
  filters?: ITrailerFilters,
  offset?: number,
  rowsPerPage?: number,
  sortBy?: string,
  sortDirection?: number,
): Promise<{ trailers: Array<ITrailer>; count: number } | IError> => {
  try {
    const filtersToApply = {
      ...filters,
      carrierIds: filters?.carriers,
      carriers: undefined,
      ...((filters?.active?.length || 0) === 1 && {
        active: filters?.active && filters?.active[0],
      }),
      ...((filters?.searchText?.length || 0) >= 3 && { search: filters?.searchText }),
    }
    const response = await Api.get('trailers', {
      ...filtersToApply,
      offset,
      limit: rowsPerPage,
      sortBy: sortBy || 'name',
      sortDirection: sortDirection || 1,
    })
    return { trailers: parseTrailers(response.items), count: response.count }
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const create = async (trailer: ITrailer): Promise<ITrailer | IError> => {
  try {
    const response = await Api.post('trailers', trailer)
    return response
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

const get = async (id: string): Promise<ITrailer | IError> => {
  try {
    const response = await Api.get(`trailers/details/${id}`)
    return response
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const update = async (id: string, trailer: ITrailer): Promise<ITrailer | IError> => {
  try {
    const response = await Api.patch(`trailers/${id}`, trailer)
    return response
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

export default {
  getList,
  create,
  get,
  update,
}
