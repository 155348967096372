export default [
  '#053000',
  '#45164a',
  '#3b3927',
  '#87803d',
  '#2232bf',
  '#26251c',
  '#0e1dab',
  '#c119d4',
  '#6b6850',
  '#661e6e',
]
