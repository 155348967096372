import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField } from '@material-ui/core'

import { ICompany } from 'interfaces/interfaces'
import { IUserInfos } from 'interfaces/IUserInfos'
import { ContentConsumer } from 'store/ContentContext'
import Switch from 'components/Inputs/ToggleSwitch'
import SearchableSelect from 'components/Inputs/SearchableSelect'
import useStyles from 'constants/cruStyles'
import Card from 'components/Card/Card'
import { AuthContext } from 'store/AuthContext'
import PasswordComplexityChecker from 'components/PasswordComplexityChecker/PasswordComplexityChecker'
import { SetManualPassword } from 'constants/constants'

interface IProps {
  infos?: IUserInfos
  onInfosChange: Function
  companyOptions?: ICompany[]
}

interface ITextChange {
  target: {
    value: string
    name: string
    type: string
    checked?: boolean
  }
}

function InfosCard({ infos, onInfosChange, companyOptions }: IProps): JSX.Element {
  const { t } = useTranslation()
  const classes = useStyles()
  const { user } = useContext(AuthContext)

  const [state, setState] = useState<Partial<IUserInfos>>({
    firstName: infos?.firstName,
    lastName: infos?.lastName,
    email: infos?.email,
    active: infos?.active || false,
    companyId: infos?.companyId || '',
    password: infos?.password,
  })

  const handleInputChange = (event: ITextChange): void => {
    const { target } = event
    const { name } = target
    const value = target.type === 'checkbox' ? target.checked : target.value

    onInfosChange({ ...state, [name]: value })
    setState({ ...state, [name]: value })
  }

  const handleCompanySelect = (event: React.ChangeEvent<{ value: unknown }>): void => {
    onInfosChange({ ...state, companyId: event.target.value as string })
    setState({ ...state, companyId: event.target.value as string })
  }

  const shouldShowPasswordInput = (): boolean =>
    user?.tenantConfig.setManualPassword === SetManualPassword.ALLUSERS

  return (
    <Card title={t('UsersScreen.profile').toUpperCase()} dataCy="profilCard">
      <>
        <div className={classes.fieldsContainer}>
          <TextField
            data-cy="firstName"
            error={state.firstName === ''}
            label={t('UsersScreen.firstName').toUpperCase()}
            onChange={handleInputChange}
            type="text"
            required
            name="firstName"
            value={state.firstName || ''}
          />
          <TextField
            data-cy="lastName"
            error={state.lastName === ''}
            label={t('UsersScreen.lastName').toUpperCase()}
            onChange={handleInputChange}
            type="text"
            required
            name="lastName"
            value={state.lastName || ''}
          />
          <TextField
            data-cy="email"
            error={state.email === ''}
            label={t('UsersScreen.email').toUpperCase()}
            onChange={handleInputChange}
            type="email"
            name="email"
            required
            value={state.email || ''}
          />
          {shouldShowPasswordInput() && (
            <>
              {/* used to prevent browsre autocomplete */}
              <input type="text" style={{ opacity: 0, position: 'absolute' }} />
              <TextField
                data-cy="password"
                error={state.password === ''}
                label={t('UsersScreen.password').toUpperCase()}
                onChange={handleInputChange}
                type="password"
                name="password"
                autoComplete="new-password"
                required
                value={state.password || ''}
              />
              <div>
                <PasswordComplexityChecker password={state.password || ''} />
              </div>
            </>
          )}
          <SearchableSelect
            dataCy="companySelect"
            label={t('UsersScreen.company')}
            selectedValue={state.companyId}
            name="company"
            options={companyOptions?.map((company) => ({ id: company.id, name: company.name }))}
            onChange={handleCompanySelect}
            required
          />
        </div>
        <Switch
          dataCy="active"
          name="active"
          onChange={handleInputChange}
          checked={state.active}
          label={t('UsersScreen.active').toUpperCase()}
        />
      </>
    </Card>
  )
}

export default (props: JSX.IntrinsicAttributes & IProps): JSX.Element => (
  <ContentConsumer>
    {(ctx): JSX.Element => (
      <InfosCard
        onInfosChange={props.onInfosChange}
        companyOptions={ctx.companies}
        infos={props.infos}
      />
    )}
  </ContentConsumer>
)
