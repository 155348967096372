import Api from 'services/api'
import { IVehicleTypeFilters } from 'interfaces/interfaces'
import { IVehicleType } from 'interfaces/IVehicleType'
import { IError } from 'api/types'

const parseSingleVehicleType = (vehicleType: IVehicleType): IVehicleType => ({
  id: vehicleType.id,
  label: vehicleType.label,
  code: vehicleType.code,
  active: vehicleType.active,
  vehicleProfile: vehicleType.vehicleProfile,
  capacity: vehicleType.capacity,
})

const parseVehicleTypes = (vehicleTypes: IVehicleType[]): Array<IVehicleType> =>
  vehicleTypes.map((vehicleType) => parseSingleVehicleType(vehicleType))

const getList = async (
  filters?: IVehicleTypeFilters,
  offset?: number,
  rowsPerPage?: number,
  sortBy?: string,
  sortDirection?: number,
): Promise<{ vehicleTypes: Array<IVehicleType>; count: number } | IError> => {
  try {
    const filtersToApply = {
      vehicleProfileIds: filters?.vehicleProfileIds,
      ...((filters?.active?.length || 0) === 1 && {
        active: filters?.active && filters?.active[0],
      }),
      ...((filters?.searchText?.length || 0) >= 3 && { search: filters?.searchText }),
    }
    const response = await Api.get('vehicle-types', {
      ...filtersToApply,
      offset,
      limit: rowsPerPage,
      sortBy: sortBy || 'label',
      sortDirection: sortDirection || 1,
    })
    return { vehicleTypes: parseVehicleTypes(response.items), count: response.count }
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const get = async (id: string): Promise<IVehicleType | IError> => {
  try {
    const response = await Api.get(`vehicle-types/details/${id}`)
    return response
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const create = async (vehicleType: IVehicleType): Promise<IVehicleType | IError> => {
  try {
    const response = await Api.post('vehicle-types', vehicleType)
    return response
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

const update = async (id: string, vehicleType: IVehicleType): Promise<IVehicleType | IError> => {
  try {
    const response = await Api.patch(`vehicle-types/${id}`, vehicleType)
    return response
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

export default {
  getList,
  get,
  create,
  update,
}
