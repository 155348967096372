import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Paper, Typography } from '@material-ui/core'
import Modal from '@material-ui/core/Modal'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { DeliveryTypeFilter, SiteFilter, TransportTypeFilter } from 'components/Inputs/ListFilter'
import ButtonsRow from 'screens/PlanningCategory/PlanningScreen/components/ButtonsRow'
import styled from 'styled-components'
import DateRangePicker from 'components/Inputs/DateRangePicker'
import { ISortingPrams } from 'interfaces/interfaces'
import { AuthContext } from 'store/AuthContext'
import { PlanificationContext } from 'screens/PlanningCategory/PlanningScreen/PlanningStore'
import SortingSelectors from './SortingSelectors'

interface ICreateVisitsModal {
  isVisible: boolean
  toggleModal(): void
  createVisits: (
    ordersBegin: string,
    ordersEnd: string,
    deliveryTypes: string[],
    transportTypes: string[],
    warehouseIds: string[],
    sortingParams: ISortingPrams,
  ) => void
  startDate?: string
  endDate?: string
  deliveryTypes?: string[]
  transportTypes?: string[]
  warehouseId?: string
  secondaryWarehouseIds?: string[]
}

const CustomPaper = styled(Paper)`
  position: absolute;
  min-width: 60%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 500;
  padding: 20px 50px;
`

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 15px;
`

export default ({
  isVisible,
  toggleModal,
  createVisits,
  startDate = '',
  endDate = '',
  deliveryTypes = [],
  transportTypes = [],
  warehouseId = '',
  secondaryWarehouseIds = [],
}: ICreateVisitsModal): JSX.Element => {
  const { t } = useTranslation()

  const { shouldDisplaySortVisits } = useContext(AuthContext)

  const [warehouseIds, setWarehouseIds] = useState<string[]>([
    warehouseId,
    ...secondaryWarehouseIds,
  ])
  const [ordersBegin, setOrdersBegin] = useState<string>(startDate)
  const [ordersEnd, setOrdersEnd] = useState<string>(endDate)
  const [newDeliveryTypes, setDeliveryTypes] = useState<string[]>(deliveryTypes)
  const [newTransportTypes, setNewTransportTypes] = useState<string[]>(transportTypes)
  const { isCreateVisitsDisabled } = useContext(PlanificationContext)
  const [sortingParams, setSortingParams] = useState<ISortingPrams>({
    sortBy: '',
    destinationSiteId: '',
  })

  const handleSortingPramsChange = (name: string, value: string) => {
    if (name === 'sortBy' && value === '') {
      setSortingParams({
        sortBy: '',
        destinationSiteId: '',
      })
    } else {
      setSortingParams({
        ...sortingParams,
        [name]: value,
      })
    }
  }

  return (
    <Modal
      open={isVisible}
      onClose={toggleModal}
      aria-label="visits-creation-modal"
      aria-describedby="simple-modal-description"
    >
      <CustomPaper>
        <Typography data-cy="modal-description" style={{ marginBottom: 32 }}>
          {t('PlanningScreen.refreshVisits')}
        </Typography>
        <Container>
          <SiteFilter dataCy="sitePicker" handleChange={setWarehouseIds} ids={warehouseIds} />
          <DateRangePicker
            startLabel={t('PlanTemplatesScreen.ordersBegin')}
            endLabel={t('PlanTemplatesScreen.ordersEnd')}
            isStartRequired
            isEndRequired
            startDate={ordersBegin || moment().hours(0).minutes(0).toISOString()}
            endDate={ordersEnd || moment().hours(23).minutes(59).toISOString()}
            onChangeStart={(time: MaterialUiPickersDate): void =>
              setOrdersBegin(moment(time).toISOString())}
            onChangeEnd={(time: MaterialUiPickersDate): void =>
              setOrdersEnd(moment(time).toISOString())}
            startDataCy="orderStartPicker"
            endDataCy="orderEndPicker"
          />
          <DeliveryTypeFilter
            handleChange={setDeliveryTypes}
            ids={newDeliveryTypes || []}
            dataCy="deliveryPicker"
          />
          <TransportTypeFilter
            handleChange={setNewTransportTypes}
            ids={newTransportTypes || []}
            dataCy="transportPicker"
          />
        </Container>
        {shouldDisplaySortVisits && (
          <Container>
            <SortingSelectors sortingPrams={sortingParams} onChange={handleSortingPramsChange} />
          </Container>
        )}
        <ButtonsRow
          toggleModal={toggleModal}
          onClickCTA={() =>
            createVisits(
              ordersBegin,
              ordersEnd,
              newDeliveryTypes,
              newTransportTypes,
              warehouseIds,
              sortingParams,
            )}
          confirmMessage={t('PlanningScreen.refreshVisits')}
          style={{ marginTop: 42 }}
          isLoading={isCreateVisitsDisabled}
        />
      </CustomPaper>
    </Modal>
  )
}
