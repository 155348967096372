import React from 'react'
import { COLUMNS_MODEL_APIKEYS_LIST } from 'constants/table'
import { IError } from 'api/types'
import { IApikey } from 'interfaces/IApikey'
import ApikeysTable from 'components/Table/ApikeysTable'
import ApikeysProvider, { ApikeysConsumer } from 'store/ApikeysContext'
import { IUser } from 'interfaces/IUser'
import { ContentConsumer } from 'store/ContentContext'

interface IApikeysProps {
  apikeys: IApikey[]
  users: IUser[]
  error?: IError
  getUsers: Function
}

const ApikeysScreen = ({ apikeys, users, getUsers }: IApikeysProps): JSX.Element => (
  <ApikeysTable
    apiKeys={apikeys}
    columns={COLUMNS_MODEL_APIKEYS_LIST}
    users={users}
    getUsers={getUsers}
  />
)

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <ContentConsumer>
    {(contentCtx): JSX.Element => (
      <ApikeysProvider>
        <ApikeysConsumer>
          {(ctx): JSX.Element => (
            <ApikeysScreen
              apikeys={ctx.apikeys}
              users={contentCtx.users}
              getUsers={contentCtx.refreshUsers}
              {...props}
            />
          )}
        </ApikeysConsumer>
      </ApikeysProvider>
    )}
  </ContentConsumer>
)
