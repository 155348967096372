import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { IStop, ITour, IUpdateTourVisits } from 'interfaces/Itours'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from './styles'
import ModalVisitCard from './ModalVisitCard'

interface Props {
  open: boolean
  onClose: (tourId: string, visits: IUpdateTourVisits[]) => void
  tour: ITour | undefined
}

function EditVisitsDialog({ open, onClose, tour }: Props): JSX.Element {
  const { t } = useTranslation()
  const classes = useStyles()
  const [allVisits, setAllVisits] = useState<IUpdateTourVisits[]>([])

  useEffect(() => {
    if (tour && tour.stops.length > 0) {
      const visits: IUpdateTourVisits[] = []
      tour.stops.forEach((stop, index) => {
        if (index !== 0 && index !== tour.stops.length - 1) {
          const stopVisits = stop.visitsToDeliver.map((visit) => ({
            visitId: visit?.visitId || '',
            departureMinDate: visit.departureMinDate,
            departureMaxDate: visit.departureMaxDate,
          }))
          visits.push(...stopVisits)
        }
      })
      setAllVisits(visits)
    }
  }, [tour])
  const renderVisits = () => {
    if (!tour) return <></>
    return tour.stops.map((stop: IStop, index: number) => {
      if (index === 0 || index === tour.stops.length - 1) return <></>
      return renderStop(stop)
    })
  }

  const handleAllVisitsChange = (
    visitId: string,
    name: string,
    value: MaterialUiPickersDate,
  ): void => {
    if (!value) return
    const index = allVisits.findIndex((e) => e.visitId === visitId)
    if (index < 0) return
    setAllVisits((prevState) => {
      const cloned: IUpdateTourVisits[] = [...prevState]
      const currentVisit = cloned[index]
      cloned[index] = { ...currentVisit, [name]: value.toString() }
      return [...cloned]
    })
  }

  const renderStop = (stop: IStop) =>
    stop.visitsToDeliver.map((visit) => {
      const visitInAllVisits = allVisits.find((e) => e.visitId === visit.visitId)
      if (!visitInAllVisits) return <></>
      return (
        <ModalVisitCard
          handleChange={handleAllVisitsChange}
          visit={visitInAllVisits}
          label={visit.label}
          fullAddress={visit.destinationAddress.full}
          phoneNumber={visit?.phoneNumber ?? ''}
        />
      )
    })

  if (!tour || !tour.tourNumber) return <></>
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="edit-visits-dialog"
      open={open}
    >
      <DialogContent>
        <div className={classes.editHeader}>
          <span>{tour.tourNumber}</span>
          <span>{tour.departureAddress.full}</span>
        </div>
        <div>{renderVisits()}</div>
      </DialogContent>
      <DialogActions>
        <Button
          data-cy="cancelButton"
          onClick={() => {
            onClose('', [])
          }}
          color="primary"
        >
          {t('confirmation.cancel')}
        </Button>
        <Button
          data-cy="okButton"
          onClick={() => {
            onClose(tour.tourId, allVisits)
          }}
          color="primary"
        >
          {t('confirmation.updateAndSend')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditVisitsDialog
