import React, { useState, useEffect, useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { IDataTransformer } from 'interfaces/interfaces'
import { IIdParam } from 'interfaces/IIdParam'
import FormAction from 'components/Button/FormAction'
import WebhooksApi from 'api/webhooks'
import { isIError } from 'api/types'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import { IWebhook } from 'interfaces/IWebhook'
import { FeedbackContext } from 'store/FeedbackContext'
import { AuthContext } from 'store/AuthContext'
import { isValidJson } from 'utils/functions'
import InformationCard from './InformationCard'
import SiteCard from './SiteCard'
import EventCard from './EventCard'
import DataTransformerCard from './DataTransformerCard'

const WebhookForm = (): JSX.Element => {
  const { user } = useContext(AuthContext)
  const dataTransformers = user?.tenantConfig.dataTransformers || []
  const [webhook, setWebhook] = useState<IWebhook>({ active: true } as IWebhook)
  const { id } = useParams<IIdParam>()
  const history = useHistory()
  const { openSuccessSnack, openErrorSnack } = useContext(FeedbackContext)
  const { t } = useTranslation()

  const getDetails = async () => {
    const response = await WebhooksApi.get(id)
    if (!isIError(response)) {
      setWebhook({
        ...response,
        extra: response.extra ? JSON.stringify(response.extra) : undefined,
        dataTransformer:
          dataTransformers.find(({ name }) => name === response?.dataTransformer?.name) || null,
      })
    }
  }

  useEffect(() => {
    if (id) {
      getDetails()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const handleSaveClick = async (): Promise<void> => {
    if (webhook.extra && !isValidJson(webhook.extra as string)) {
      openErrorSnack(t('WebhooksScreen.incorrectJsonFormat'))
      return
    }
    if (id) {
      const response = await WebhooksApi.update(id, webhook)
      if (!isIError(response)) {
        openSuccessSnack(t('WebhooksScreen.successfullyUpdated'))
        history.push(ROUTES_PATH.webhooksList)
      }
    } else {
      const response = await WebhooksApi.create(webhook)
      if (!isIError(response)) {
        openSuccessSnack(t('WebhooksScreen.successfullyCreated'))
        history.push(ROUTES_PATH.webhooksList)
      }
    }
  }

  const handleCancelClick = async (): Promise<void> => {
    history.push(ROUTES_PATH.webhooksList)
  }

  const handleWebhookChange = (changedWebhook: IWebhook): void => {
    setWebhook((prevWebhook) => ({
      ...prevWebhook,
      ...changedWebhook,
    }))
  }

  const handleSelectListChange = (selected: string[], fieldName: string): void => {
    setWebhook((prevWebhook) => ({
      ...prevWebhook,
      [fieldName]: selected,
    }))
  }

  const handleDataTransformerChange = (dataTransformer: IDataTransformer): void => {
    setWebhook((prevWebhook) => ({
      ...prevWebhook,
      dataTransformer,
    }))
  }

  return (
    <Grid container>
      <InformationCard
        key={`${webhook.id}-info`}
        webhook={webhook}
        onChange={handleWebhookChange}
      />
      <DataTransformerCard
        key={`${webhook.id}-transformer`}
        name={webhook?.dataTransformer?.name}
        onChange={handleDataTransformerChange}
      />
      <SiteCard
        key={`${webhook.id}-site`}
        siteIds={webhook.siteIds}
        isCreateMode={!id}
        onChange={(value: string[]) => {
          handleSelectListChange(value, 'siteIds')
        }}
      />
      <EventCard
        key={`${webhook.id}-event`}
        eventIds={webhook.events}
        isCreateMode={!id}
        onChange={(value: string[]) => {
          handleSelectListChange(value, 'events')
        }}
      />
      <FormAction onSaveClick={handleSaveClick} onCancelClick={handleCancelClick} />
    </Grid>
  )
}

export default WebhookForm
