import { ISelectableMarker } from 'interfaces/map'

export enum ClusterStatus {
  new,
  modification,
  closed,
}

export interface ICluster {
  clusterId: number
  vehicleIds: string[]
  visits: ISelectableMarker[]
  color: string
  status: ClusterStatus
  driverId?: string
  deckCode?: string
}
