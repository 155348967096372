export interface IError {
  error: {
    message: string
    code?: number
    fieldErrors?: object
    errorList?: string[]
  }
}

export function isIError(possibleErrorObject: IError | unknown): possibleErrorObject is IError {
  return !!possibleErrorObject && (possibleErrorObject as IError).error !== undefined
}
