import React, { useContext, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { createStyles, IconButton, makeStyles, Paper, Theme, Typography } from '@material-ui/core'
import Modal from '@material-ui/core/Modal'
import styled from 'styled-components'
import DraftsIcon from '@material-ui/icons/Drafts'
import MarkunreadIcon from '@material-ui/icons/Markunread'

import { AlertType, IAlert, MessageInfoType } from 'interfaces/IAlert'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import { AlertsContext } from 'store/AlertsContext'
import { addDays } from 'utils/dateFormat'
import { ContentContext } from 'store/ContentContext'
import { formatDateInterval } from 'utils/libs/date-range'

interface IAlertModal {
  toggleModal(): void
  alert: IAlert | null
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topic: {
      marginBottom: 15,
      marginLeft: -12,
      display: 'inline-flex',
      alignItems: 'center',
    },
    dealer: {
      marginBottom: 5,
    },
    message: {
      fontSize: 15,
      marginBottom: 10,
      fontFamily: 'inherit',
    },
    link: {
      color: theme.color.secondary,
      '&:hover': {
        color: theme.color.primary,
      },
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  }),
)

const CustomPaper = styled(Paper)`
  position: absolute;
  min-width: 30%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 500;
  padding: 20px 50px;
`

export default ({ toggleModal, alert }: IAlertModal): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [infoToFillMessage, setInfoToFillMessage] = useState({})
  const { updateReadState } = useContext(AlertsContext)
  const { dealers, carriers } = useContext(ContentContext)
  const dealerName = dealers.find((dealer) => dealer.id === alert?.dealerId)?.label

  useEffect(() => {
    const relatedObject = Object.entries(alert?.relatedObject || {})
    relatedObject.forEach((info) => {
      let routeToGo = ''
      let queryParam: string | undefined
      let keyToUse = info[0]
      let valueToUse = info[1]

      switch (keyToUse) {
        case MessageInfoType.tourNumber:
          queryParam = `?searchTerm=${valueToUse}`
          if (alert?.type === AlertType.OnTourRefused) {
            routeToGo = ROUTES_PATH.executionAffectation
            break
          }
          routeToGo = ROUTES_PATH.executionTours
          break
        case MessageInfoType.vehicleId:
          routeToGo = `${ROUTES_PATH.vehicleReports}${valueToUse}`
          break
        case MessageInfoType.importJobId:
          routeToGo = ROUTES_PATH.importHistory
          queryParam = `?importJobId=${valueToUse}`
          break
        case MessageInfoType.carrierId:
          keyToUse = 'carrierName'
          valueToUse = carriers.find((carrier) => carrier.id === valueToUse)?.name || '-'
          break
        case MessageInfoType.orderNumber:
          routeToGo = ROUTES_PATH.geocodingOrders
          queryParam = `?orderNumber=${valueToUse}`
          break
        case MessageInfoType.deliveryDateTime:
          keyToUse = 'deliveryWindow'
          valueToUse = formatDateInterval(
            (valueToUse as { min: string; max: string })?.min,
            (valueToUse as { min: string; max: string })?.max,
            true,
          )
          break
        default:
          break
      }

      if (routeToGo) {
        // If we have a route, we need to render a link in the message for this key
        setInfoToFillMessage((prevInfoToFillMessage) => ({
          ...prevInfoToFillMessage,
          [`${keyToUse}Value`]: valueToUse,
          [`${keyToUse}Link`]: (
            <Link
              className={classes.link}
              to={{
                pathname: routeToGo,
                search: queryParam,
                state: queryParam
                  ? {
                    startDateFilter:
                        alert?.relatedObject?.startDateFilter ?? addDays(new Date(), -30),
                    endDateFilter: alert?.relatedObject?.endDateFilter ?? addDays(new Date(), +1),
                    siteId: alert?.relatedObject?.siteId,
                  }
                  : undefined,
              }}
            />
          ),
        }))
      } else {
        // If we don't have a route
        // We just add the value for the key which might be used for interpolation in the message
        setInfoToFillMessage((prevInfoToFillMessage) => ({
          ...prevInfoToFillMessage,
          [keyToUse]: valueToUse,
        }))
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert])

  const toggleRead = () => {
    if (alert) {
      updateReadState(alert.id, !alert.read)
    }
  }

  return (
    <Modal
      open={!!alert}
      onClose={toggleModal}
      aria-label="alert-modal"
      aria-describedby="simple-modal-description"
    >
      <CustomPaper>
        <Typography data-cy="modal-description" className={classes.topic}>
          <IconButton onClick={toggleRead}>
            {alert?.read ? <DraftsIcon /> : <MarkunreadIcon />}
          </IconButton>

          <span>{alert?.customTopic ?? t(`AlertsScreen.${alert?.type}Topic`)}</span>
        </Typography>
        {dealerName && (
          <Typography data-cy="modal-description" className={classes.dealer}>
            {t(`AlertsScreen.dealer`)}: {dealerName}
          </Typography>
        )}

        <Typography>
          {alert?.customMessage ?? (
            <pre className={classes.message}>
              <Trans
                i18nKey={`AlertsScreen.${alert?.type}Message`}
                values={infoToFillMessage}
                components={infoToFillMessage}
              />
            </pre>
          )}
        </Typography>
      </CustomPaper>
    </Modal>
  )
}
