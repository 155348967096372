import React, { useContext, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'

import { IIdParam } from 'interfaces/IIdParam'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import CustomCard from 'components/CustomCard/CustomCard'
import Button from 'components/Button/CustomButton'
import useStyles from 'constants/cruStyles'
import { getSectorsLabelById, getWarehousesLabelById } from 'utils/tableUtils'
import WarehouseTimeSlotsProvider, {
  WarehouseTimeSlotsContext,
} from 'store/WarehouseTimeSlotsContext'
import { ITimeSlot, IWarehouseTimeSlot } from 'interfaces/IWarehouseTimeSlot'
import WarehouseTimeSlotDayDetails from './WarehouseTimeSlotDayDetailsScreen'

const WarehouseTimeSlotDetailsScreen = (): JSX.Element => {
  const { id } = useParams<IIdParam>()
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const { getDetails, warehouseTimeSlotDetails } = useContext(WarehouseTimeSlotsContext)

  useEffect(() => {
    if (id && getDetails) {
      getDetails(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const handleEditClick = (): void => {
    history.push(`${ROUTES_PATH.editWarehouseTimeSlot}${id}`)
  }

  return (
    <>
      <div className={classes.detailHeaderContainer}>
        <h3 data-cy="warehouseTimeSlotsDetails">{warehouseTimeSlotDetails.label}</h3>
        <Button data-cy="updateButton" onPress={handleEditClick}>
          {t('SiteTimeSlotsScreen.edit')}
        </Button>
      </div>
      <div className={classes.detailsContent}>
        <div className={classes.detailsInnerContent}>
          <Grid item className={classes.infoContainer}>
            <CustomCard
              dataCy="warehouseTimeSlotCard"
              cardTitle={t('SiteTimeSlotsScreen.warehouseTimeSlot').toUpperCase()}
              linePairs={[
                {
                  label: t('SiteTimeSlotsScreen.sites'),
                  value: getWarehousesLabelById(warehouseTimeSlotDetails as IWarehouseTimeSlot),
                },
                {
                  label: t('SiteTimeSlotsScreen.sectors'),
                  value: getSectorsLabelById(warehouseTimeSlotDetails as IWarehouseTimeSlot),
                },
                {
                  label: t('SiteTimeSlotsScreen.label'),
                  value: warehouseTimeSlotDetails.label ? `${warehouseTimeSlotDetails.label}` : '',
                },
                {
                  label: t('SiteTimeSlotsScreen.minDelayInHour'),
                  value: warehouseTimeSlotDetails.minDelayInHour
                    ? `${warehouseTimeSlotDetails.minDelayInHour}`
                    : '',
                },
                {
                  label: t('SiteTimeSlotsScreen.maxDelayInHour'),
                  value: warehouseTimeSlotDetails.maxDelayInHour
                    ? `${warehouseTimeSlotDetails.maxDelayInHour}`
                    : '',
                },
                {
                  label: t('SiteTimeSlotsScreen.active'),
                  value: warehouseTimeSlotDetails
                    ? warehouseTimeSlotDetails.active
                      ? t('UsersScreen.yes')
                      : t('UsersScreen.no')
                    : '',
                },
              ]}
            />
          </Grid>
        </div>
        <div className={classes.availabilityDetails}>
          <WarehouseTimeSlotDayDetails timeSlot={warehouseTimeSlotDetails.timeSlot as ITimeSlot} />
        </div>
      </div>
    </>
  )
}
export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <WarehouseTimeSlotsProvider>
    <WarehouseTimeSlotDetailsScreen {...props} />
  </WarehouseTimeSlotsProvider>
)
