import { createStyles, makeStyles } from '@material-ui/core/styles'

export const FORM_ACTION_HEIGHT = 56

export default makeStyles(() =>
  createStyles({
    actionContainer: {
      position: 'relative',
      paddingBottom: '70px',
      margin: '0 auto',
      display: 'flex',
      justifyContent: 'space-between',
    },
    styledButton: {
      alignSelf: 'auto',
      height: FORM_ACTION_HEIGHT,
    },
    additionalButton: {
      height: FORM_ACTION_HEIGHT,
      marginRight: 12,
    },
  }),
)
