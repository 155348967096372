import { Filter } from 'constants/filters'
import i18next from 'i18next'

interface setSelectedDateFilterShortcutFactoryReturn {
  fnLast3months(): void
  fnLastMonth(): void
  fnLast30days(): void
  fnLast7days(): void
  fnNext7days(): void
  fnThisMonth(): void
}

interface setDateFactoryReturn {
  setEndDate(value: string): void
  setStartDate(value: string): void
}

export function setSelectedDateFilterShortcutFactory(
  fn: (value: string) => void,
): setSelectedDateFilterShortcutFactoryReturn {
  return {
    fnLast3months() {
      fn('last3months')
    },
    fnLastMonth() {
      fn('lastMonth')
    },
    fnLast30days() {
      fn('last30days')
    },
    fnLast7days() {
      fn('last7days')
    },
    fnNext7days() {
      fn('next7days')
    },
    fnThisMonth() {
      fn('thisMonth')
    },
  }
}

export function setDateFactory(
  fn: (filterKey: string, filterName: string, value: string) => void,
  filterKey: string,
): setDateFactoryReturn {
  return {
    setEndDate(value) {
      fn(filterKey, Filter.endDate, value)
    },
    setStartDate(value) {
      fn(filterKey, Filter.startDate, value)
    },
  }
}

export function getChartTitle(chartName: string, currentDateFilterShortcut: string): string {
  let title = i18next.t(chartName)
  if (currentDateFilterShortcut) {
    const trad = i18next.t(`ExtendedDateLabel.${currentDateFilterShortcut}`)
    title = `${title} ${trad}`
  }
  return title
}
