import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IconButton,
  TextField,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Paper,
} from '@material-ui/core'
import ReplayIcon from '@material-ui/icons/Replay'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import clsx from 'clsx'

import Card from 'components/Card/Card'
import Switch from 'components/Inputs/ToggleSwitch'
import { IOrderForm } from 'interfaces/IOrders'
import SearchableSelect from 'components/Inputs/SearchableSelect'
import { DESTINATION_TYPE_OPTIONS, DestinationType } from 'constants/constants'
import { ContentContext } from 'store/ContentContext'
import { isDefined, shouldDisplayOrderFormField } from 'utils/functions'
import { IAutocompleteResult } from 'interfaces/IAutocompleteResult'
import CountryAutocomplete, { ICountryValue } from 'components/Inputs/CountryAutocomplete'
import { IWarehouseCartDropoffConfig } from 'interfaces/IWarehouseCartDropoffConfig'
import useStyles from './styles'

interface IProps {
  order?: Partial<IOrderForm>
  onChange: (key: keyof IOrderForm, value: unknown) => void
  destinationType: DestinationType
  onDestinationTypeChange: (destinationType: DestinationType) => void
  onAddressAutocomplete: () => void
  onResetAddressChoices: () => void
  onConfirmAddressChoice: (newAddress: Partial<IOrderForm>) => void
  addressChoices: IAutocompleteResult[]
  resetDestinationCardData: () => void
  isCartDropoff?: boolean
  config?: IWarehouseCartDropoffConfig | null
}

const DestinationCard = ({
  order,
  onChange,
  onDestinationTypeChange,
  destinationType,
  onAddressAutocomplete,
  onResetAddressChoices,
  onConfirmAddressChoice,
  addressChoices,
  resetDestinationCardData,
  isCartDropoff = false,
  config,
}: IProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { sites } = useContext(ContentContext)
  const [isExpanded, setIsExpanded] = useState(false)

  const handleInputChange = (event: React.ChangeEvent<{ value: unknown; name?: string }>): void => {
    const { target } = event
    const { name, value } = target
    onChange(name as keyof IOrderForm, value)
  }

  const handleCountryChange = (event: object, value: ICountryValue | null): void => {
    onChange('deliveryAddressCountry', value?.label || '')
  }

  const handleSwitchChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ): void => {
    const { target } = event
    const { name } = target
    onChange(name as keyof IOrderForm, checked)
  }

  const handleAddressChange = (
    event: React.ChangeEvent<{ value: string; name?: string }>,
  ): void => {
    const { target } = event
    const { name, value } = target
    if (value?.length > 7) {
      onAddressAutocomplete()
    } else {
      onResetAddressChoices()
    }
    onChange(name as keyof IOrderForm, value)
  }

  const makeAChoice = (addressChoice: IAutocompleteResult): void => {
    confirmAddressCompletion(addressChoice)
    onResetAddressChoices()
  }

  const confirmAddressCompletion = (addressChoice: IAutocompleteResult): void => {
    let formattedAddressLabel = addressChoice.address.label
    const addressLabelParts = formattedAddressLabel?.split(',')
    if (addressLabelParts) {
      const lastPart = addressLabelParts[addressLabelParts.length - 1]
      if (lastPart.trim() === addressChoice.address.country) {
        addressLabelParts.pop()
        formattedAddressLabel = addressLabelParts.join()
      }
    }
    onConfirmAddressChoice({
      deliveryAddressFull: formattedAddressLabel,
      deliveryAddressRoadNumber: addressChoice.address.roadNumber,
      deliveryAddressRoad: addressChoice.address.road,
      deliveryAddressZipCode: addressChoice.address.zipCode,
      deliveryAddressCity: addressChoice.address.city,
      deliveryAddressCountry: addressChoice.address.country,
    })
  }

  const AddressChoices = (): JSX.Element => (
    <div className={clsx(classes.autocompleteResultsContainer, classes.addressResultsContainer)}>
      {addressChoices.map((addressChoice, index) => (
        <div
          key={`${addressChoice.title}`}
          role="button"
          tabIndex={index}
          className={classes.addressLine}
          onClick={(): void => makeAChoice(addressChoice)}
          onKeyPress={(e): false | void => e.keyCode === 13 && makeAChoice(addressChoice)}
        >
          <div className={classes.autocompleteAddress}>{addressChoice.address.label}</div>
        </div>
      ))}
    </div>
  )

  const onChangeAccordion = () => {
    setIsExpanded((prev) => !prev)
  }

  const onWheel = (e: React.WheelEvent<HTMLDivElement>): void => {
    (e.target as HTMLInputElement).blur()
  }

  return (
    <Card title={t('OrdersScreen.addressInfo').toUpperCase()} dataCy="destinationCard">
      <div className={classes.container}>
        {shouldDisplayOrderFormField(isCartDropoff, config?.destinationType) && (
          <SearchableSelect
            dataCy="deliveryAddressType"
            label={t('OrdersScreen.deliveryAddressType.title')}
            name="deliveryAddressType"
            selectedValue={destinationType || ''}
            options={DESTINATION_TYPE_OPTIONS}
            onChange={(event) => onDestinationTypeChange(event.target.value as DestinationType)}
            required
            isSearchDisabled
          />
        )}
        {destinationType === DestinationType.Address && (
          <>
            <TextField
              data-cy="deliveryAddressRoad"
              required
              type="text"
              label={t('OrdersScreen.address.road').toUpperCase()}
              value={order?.deliveryAddressRoad || ''}
              name="deliveryAddressRoad"
              onChange={handleAddressChange}
              autoComplete="off"
            />
            {addressChoices.length > 0 && <AddressChoices />}
            <TextField
              data-cy="deliveryAddressRoadNumber"
              type="text"
              label={t('OrdersScreen.address.roadNumber').toUpperCase()}
              value={order?.deliveryAddressRoadNumber || ''}
              name="deliveryAddressRoadNumber"
              onChange={handleInputChange}
            />
            <TextField
              data-cy="deliveryAddressZipCode"
              required
              type="text"
              label={t('OrdersScreen.address.zipCode').toUpperCase()}
              value={order?.deliveryAddressZipCode || ''}
              name="deliveryAddressZipCode"
              onChange={handleInputChange}
            />
            <TextField
              data-cy="deliveryAddressCity"
              required
              type="text"
              label={t('OrdersScreen.address.city').toUpperCase()}
              value={order?.deliveryAddressCity || ''}
              name="deliveryAddressCity"
              onChange={handleInputChange}
            />
            {shouldDisplayOrderFormField(isCartDropoff, config?.country) && (
              <CountryAutocomplete
                dataCy="deliveryAddressCountry"
                required
                value={order?.deliveryAddressCountry || ''}
                onChange={handleCountryChange}
              />
            )}
            {(shouldDisplayOrderFormField(isCartDropoff, config?.floor)
              || shouldDisplayOrderFormField(isCartDropoff, config?.hasElevator)
              || shouldDisplayOrderFormField(isCartDropoff, config?.digicode)
              || shouldDisplayOrderFormField(isCartDropoff, config?.deliveryInstructions)) && (
              <Accordion
                expanded={isExpanded}
                onChange={onChangeAccordion}
                className={classes.accordion}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} data-cy="additionalInformationAccordion">
                  <Typography>{t('OrdersScreen.additionalInformation').toUpperCase()}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Paper elevation={0} className={classes.accordionPaper}>
                    {shouldDisplayOrderFormField(isCartDropoff, config?.floor) && (
                      <TextField
                        type="number"
                        fullWidth
                        label={t('OrdersScreen.deliveryFloor').toUpperCase()}
                        value={
                          isDefined(order?.deliveryFloor) ? order?.deliveryFloor?.toString() : ''
                        }
                        name="deliveryFloor"
                        onChange={handleInputChange}
                        onWheel={onWheel}
                      />
                    )}
                    {shouldDisplayOrderFormField(isCartDropoff, config?.hasElevator) && (
                      <Switch
                        checked={order?.deliveryIsElevatorPresent || false}
                        label={t('OrdersScreen.deliveryIsElevatorPresent').toUpperCase()}
                        justifyLeft
                        name="deliveryIsElevatorPresent"
                        onChange={handleSwitchChange}
                      />
                    )}
                    {shouldDisplayOrderFormField(isCartDropoff, config?.digicode) && (
                      <TextField
                        type="text"
                        fullWidth
                        label={t('OrdersScreen.deliveryDoorCode').toUpperCase()}
                        value={order?.deliveryDoorCode || ''}
                        name="deliveryDoorCode"
                        onChange={handleInputChange}
                      />
                    )}
                    {shouldDisplayOrderFormField(isCartDropoff, config?.deliveryInstructions) && (
                      <TextField
                        data-cy="deliveryInstructions"
                        type="text"
                        fullWidth
                        label={t('OrdersScreen.deliveryInstructions').toUpperCase()}
                        value={order?.deliveryInstructions || ''}
                        name="deliveryInstructions"
                        onChange={handleInputChange}
                      />
                    )}
                  </Paper>
                </AccordionDetails>
              </Accordion>
            )}
          </>
        )}
        {destinationType === DestinationType.Warehouse && (
          <>
            <SearchableSelect
              dataCy="deliverySite"
              label={t('OrdersScreen.deliverySite')}
              name="deliverySiteId"
              selectedValue={order?.deliverySiteId}
              options={sites}
              onChange={handleInputChange}
              required
            />
            {shouldDisplayOrderFormField(isCartDropoff, config?.deliveryInstructions) && (
              <TextField
                data-cy="deliveryInstructions"
                type="text"
                label={t('OrdersScreen.deliveryInstructions').toUpperCase()}
                value={order?.deliveryInstructions || ''}
                name="deliveryInstructions"
                onChange={handleInputChange}
              />
            )}
          </>
        )}
        {shouldDisplayOrderFormField(isCartDropoff, config?.enterprise) && (
          <TextField
            data-cy="enterprise"
            type="text"
            label={t('OrdersScreen.enterprise').toUpperCase()}
            value={order?.enterpriseLabel || ''}
            name="enterpriseLabel"
            onChange={handleInputChange}
          />
        )}
        <div className={classes.resetButtonContainer}>
          <Tooltip title={t('OrdersScreen.reset').toUpperCase()}>
            <IconButton data-cy="resetButton" onClick={resetDestinationCardData}>
              <ReplayIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </Card>
  )
}

export default DestinationCard
