import React, { useContext, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import TenantsProvider, { TenantsConsumer } from 'store/TenantsContext'
import CustomCard from 'components/CustomCard/CustomCard'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import { ITenant } from 'interfaces/ITenant'
import { IIdParam } from 'interfaces/IIdParam'
import Button from 'components/Button/CustomButton'
import TenantsApi from 'api/tenants'
import { isIError } from 'api/types'
import { FeedbackContext } from 'store/FeedbackContext'
import MultiLevelCard from 'components/MultiLevelCard/MultiLevelCard'

import useStyles from 'constants/cruStyles'
import { KlareoProduct } from 'constants/constants'

interface IProps {
  getDetails?: Function
  tenantDetails: ITenant
}

const TenantDetailsScreen = ({ getDetails, tenantDetails }: IProps): JSX.Element => {
  const { id } = useParams<IIdParam>()
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const { openErrorSnack, openSuccessSnack } = useContext(FeedbackContext)

  useEffect(() => {
    if (id && getDetails) {
      getDetails(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const handleEditClick = (): void => {
    history.push(`${ROUTES_PATH.editTenant}${id}`)
  }

  const handleInitUserClick = async (): Promise<void> => {
    const response = await TenantsApi.initialize(id)
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    } else {
      openSuccessSnack(t('TenantsScreen.successfullyInitialized'))
      if (getDetails) getDetails(id)
    }
  }

  return (
    <>
      <div className={classes.detailHeaderContainer}>
        <h3 data-cy="tenantDetailDescription">{`${tenantDetails.name || ''}`}</h3>
        <div className={classes.detailsActionButtonContainer}>
          <Button
            dataCy="initButton"
            disabled={tenantDetails.isInitialized}
            onPress={handleInitUserClick}
          >
            {t('TenantsScreen.initialize').toUpperCase()}
          </Button>
          <Button dataCy="updateButton" onPress={handleEditClick}>
            {t('TenantsScreen.edit').toUpperCase()}
          </Button>
        </div>
      </div>
      <Grid item xs={10} className={classes.tenantDetailsContainer}>
        <CustomCard
          dataCy="generalCard"
          cardTitle={t('TenantsScreen.general')}
          linePairs={[
            { label: t('TenantsScreen.name'), value: tenantDetails.name },
            { label: t('TenantsScreen.normalizedName'), value: tenantDetails.normalizedName },
            { label: t('TenantsScreen.language'), value: tenantDetails.language },
          ]}
        />
      </Grid>
      <Grid item xs={10} className={classes.tenantDetailsContainer}>
        <CustomCard dataCy="authenticationCard" cardTitle={t('TenantsScreen.authentication')}>
          <MultiLevelCard
            items={[
              {
                title: t('TenantsScreen.passwordRules').toUpperCase(),
                linePairs: [
                  {
                    label: t('TenantsScreen.upperCaseMinimum').toUpperCase(),
                    value: tenantDetails.passwordRules?.upperCaseMinimum?.toString(),
                  },
                  {
                    label: t('TenantsScreen.lowerCaseMinimum').toUpperCase(),
                    value: tenantDetails.passwordRules?.lowerCaseMinimum?.toString(),
                  },
                  {
                    label: t('TenantsScreen.digitsMinimum').toUpperCase(),
                    value: tenantDetails.passwordRules?.digitsMinimum?.toString(),
                  },
                  {
                    label: t('TenantsScreen.symbolsMinimum').toUpperCase(),
                    value: tenantDetails.passwordRules?.symbolsMinimum?.toString(),
                  },
                  {
                    label: t('TenantsScreen.charactersMinimum').toUpperCase(),
                    value: tenantDetails.passwordRules?.charactersMinimum?.toString(),
                  },
                ],
              },
              {
                title: t('TenantsScreen.setManualPassword.title').toUpperCase(),
                notWithinSection: true,
                titleValue:
                  tenantDetails.setManualPassword
                  && t(`TenantsScreen.setManualPassword.${tenantDetails.setManualPassword}`),
              },
              {
                title: t('TenantsScreen.sessionTokenExpiryDelay').toUpperCase(),
                notWithinSection: true,
                titleValue:
                  tenantDetails.sessionTokenExpiryDelay
                  && t(`TenantsScreen.${tenantDetails.sessionTokenExpiryDelay}`),
              },
            ]}
          />
        </CustomCard>
      </Grid>
      <Grid item xs={10} className={classes.tenantDetailsContainer}>
        <CustomCard
          dataCy="productsCard"
          cardTitle={t('TenantsScreen.products')}
          linePairs={[
            {
              label: KlareoProduct.DeliveryPackage,
              value: tenantDetails.klareoProducts
                ? tenantDetails.klareoProducts.includes(KlareoProduct.DeliveryPackage)
                  ? t('TenantsScreen.yes')
                  : t('TenantsScreen.no')
                : '',
            },
            {
              label: KlareoProduct.UdcPackage,
              value: tenantDetails.klareoProducts
                ? tenantDetails.klareoProducts.includes(KlareoProduct.UdcPackage)
                  ? t('TenantsScreen.yes')
                  : t('TenantsScreen.no')
                : '',
            },
          ]}
        />
      </Grid>
      <Grid item xs={10} className={classes.tenantDetailsContainer}>
        <CustomCard
          dataCy="planningCard"
          cardTitle={t('TenantsScreen.planning')}
          linePairs={[
            {
              label: t('TenantsScreen.carrierCanChangeDriver'),
              value:
                tenantDetails.carrierCanChangeDriver !== undefined
                  ? tenantDetails.carrierCanChangeDriver
                    ? t('TenantsScreen.yes')
                    : t('TenantsScreen.no')
                  : '',
            },
            {
              label: t('TenantsScreen.deliveryDuration'),
              value: `${tenantDetails.deliveryDuration ?? ''}`,
            },
            {
              label: t('TenantsScreen.planningPinsZoom'),
              value: `${tenantDetails.planningPinsZoom ?? ''}`,
            },
          ]}
        />
      </Grid>
      <Grid item xs={10} className={classes.tenantDetailsContainer}>
        <CustomCard dataCy="relayCard" cardTitle={t('TenantsScreen.relay')} />
      </Grid>
      <Grid item xs={10} className={classes.tenantDetailsContainer}>
        <CustomCard dataCy="relayMobileCard" cardTitle={t('TenantsScreen.relayMobile')} />
      </Grid>
    </>
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <TenantsProvider>
    <TenantsConsumer>
      {(TenantsCtx): JSX.Element => (
        <TenantDetailsScreen
          getDetails={TenantsCtx.getDetails}
          tenantDetails={TenantsCtx.tenantDetails}
          {...props}
        />
      )}
    </TenantsConsumer>
  </TenantsProvider>
)
