import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { TenantFilter } from 'components/Inputs/ListFilter'
import FormAction from 'components/Button/FormAction'
import { AuthContext } from 'store/AuthContext'
import { ITag } from 'interfaces/ITag'
import { TagsConsumer } from 'store/TagsContext'
import TagsCard from './TagsCard'

interface IProps {
  tags: ITag[] | null
  getTags: (tenantId: string) => void
  updateTags: (tenantId: string, tags: ITag[]) => void
}

const TenantContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`

const TagsScreen = ({ tags, getTags, updateTags }: IProps): JSX.Element => {
  const { t } = useTranslation()
  const { user } = useContext(AuthContext)

  const [tenantId, setTenantId] = useState<string>(user?.tenantId || '')
  const [tagsList, setTagsList] = useState<ITag[]>([])

  useEffect(() => {
    if (tenantId) {
      getTags(tenantId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantId])

  useEffect(() => {
    if (tags) setTagsList(tags)
  }, [tags])

  const handleCancelClick = (): void => {
    if (tags) setTagsList(tags)
  }

  const handleSaveClick = (): void => {
    if (tenantId && tagsList.findIndex((tag) => tag.errorCode) === -1) {
      updateTags(tenantId, tagsList)
    }
  }

  const handleTagsChange = (changedTags: ITag[]): void => {
    setTagsList(changedTags)
  }

  return (
    <>
      <TenantContainer>
        <TenantFilter
          handleChange={(tenantValues: string[]): void => {
            setTenantId(tenantValues[0])
          }}
          placeholder={t('tablesEntries.tenant')}
          ids={[tenantId]}
          dataCy="tenantPicker"
          onlyOneValue
          shouldFillWidth={tenantId !== ''}
          required
          isUnselectAllowed={false}
        />
      </TenantContainer>
      {tenantId && (
        <>
          <TagsCard tags={tagsList} onTagsChange={handleTagsChange} />
          <FormAction onSaveClick={handleSaveClick} onCancelClick={handleCancelClick} />
        </>
      )}
    </>
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <TagsConsumer>
    {(tagsContext): JSX.Element => (
      <TagsScreen
        tags={tagsContext.tags}
        getTags={tagsContext.getTags}
        updateTags={tagsContext.updateTags}
        {...props}
      />
    )}
  </TagsConsumer>
)
