import React, { useMemo } from 'react'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { useTranslation } from 'react-i18next'
import { IReturnable } from 'interfaces/IOrders'
import { useStyles, StyledTableCell, StyledTableRow } from './ProductOrSupportTable'

export default ({
  returnables,
  dataCy,
}: {
  returnables: Array<IReturnable>
  dataCy: string
}): JSX.Element => {
  const { t } = useTranslation()

  const classes = useStyles()

  const eanList = useMemo(
    () => returnables.flatMap((returnable) => returnable.eanList),
    [returnables],
  )

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table data-cy={dataCy} stickyHeader className={classes.table} aria-label="Returnables table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHead} align="center">
              {t('tablesEntries.quantity')}
            </TableCell>
            <TableCell className={classes.tableHead} align="center">
              {t('OrdersScreen.ean')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {eanList.map((eanListItem) => (
            <StyledTableRow key={eanListItem.ean}>
              <StyledTableCell align="center">
                <div className="left">{eanListItem.quantity}</div>
              </StyledTableCell>
              <StyledTableCell align="center">
                <div className="left">{eanListItem.ean}</div>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
