import React from 'react'

interface IWarehouseIcon {
  color?: string
}

const WarehouseIcon = ({ color = 'white' }: IWarehouseIcon): JSX.Element => (
  <svg
    enableBackground="new 0 0 24 24"
    height="230"
    viewBox="0 0 24 24"
    width="230"
    x="27%"
    y="6%"
    fill={color}
  >
    <path d="m23.676 7.882-11.25-7.75c-.256-.176-.594-.176-.851 0l-11.25 7.75c-.204.14-.325.371-.325.618v14.5c0 .552.448 1 1 1h2v-12c0-.552.448-1 1-1h16c.552 0 1 .448 1 1v12h2c.552 0 1-.448 1-1v-14.5c0-.247-.121-.478-.324-.618z" />
    <path d="m9.25 19v.75c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-.75h-1.75c-.276 0-.5.224-.5.5v4c0 .276.224.5.5.5h5c.276 0 .5-.224.5-.5v-4c0-.276-.224-.5-.5-.5z" />
    <path d="m16.25 19v.75c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-.75h-1.75c-.276 0-.5.224-.5.5v4c0 .276.224.5.5.5h5c.276 0 .5-.224.5-.5v-4c0-.276-.224-.5-.5-.5z" />
    <path d="m12.75 13v.75c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-.75h-1.75c-.276 0-.5.224-.5.5v4c0 .276.224.5.5.5h5c.276 0 .5-.224.5-.5v-4c0-.276-.224-.5-.5-.5z" />
  </svg>
)

export default WarehouseIcon
