import React, { useState } from 'react'
import JsPDF from 'jspdf'
import { withStyles } from '@material-ui/core/styles'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { Plugin, Template, TemplatePlaceholder } from '@devexpress/dx-react-core'
import domtoimage from 'dom-to-image'
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import i18n from 'i18next'

const ITEM_HEIGHT = 48
const paperProps = {
  style: {
    maxHeight: ITEM_HEIGHT * 4.5,
    width: 220,
  },
}
const iconButton = 'exportIconButton'

interface INodeId {
  id: string
}

interface INodeOffset {
  offsetWidth: number
  offsetHeight: number
}

type INode = Node & INodeId & INodeOffset

const filter = (node: Node): boolean => (node as INode).id !== iconButton

const exportToImage = async (
  chart: Node,
  fileName: string,
  format: string,
  exportFunc: Function,
): Promise<void> => {
  try {
    const dataUrl = await exportFunc(chart, { filter })
    const link = document.createElement('a')
    document.body.appendChild(link)
    link.download = `${fileName}-${new Date().toISOString()}.${format}`
    link.href = dataUrl
    link.click()
    link.remove()
  } catch (err) {
    /** TODO: handle error */
  }
}

const exportToJpeg = (chart: Node, fileName: string): Promise<void> =>
  exportToImage(chart, fileName, 'jpeg', domtoimage.toJpeg)

const exportToPng = (chart: Node, fileName: string): Promise<void> =>
  exportToImage(chart, fileName, 'png', domtoimage.toPng)

const exportToPdf = async (chart: Node, fileName: string): Promise<void> => {
  const width = (chart as INode).offsetWidth
  const height = (chart as INode).offsetHeight
  try {
    const dataUrl = await domtoimage.toJpeg(chart, { filter })
    const doc = new JsPDF({
      orientation: 'landscape',
      unit: 'px',
      format: [width, height],
    })
    const pdfWidth = doc.internal.pageSize.getWidth()
    const pdfHeight = doc.internal.pageSize.getHeight()
    doc.addImage(dataUrl, 'JPEG', 0, 0, pdfWidth, pdfHeight)
    doc.save(`${fileName}-${new Date().toISOString()}.pdf`)
  } catch (err) {
    /** TODO: handle error */
  }
}

const options = [
  { key: 'JPEG', action: exportToJpeg, text: i18n.t('Export.jpg') },
  { key: 'PNG', action: exportToPng, text: i18n.t('Export.png') },
  { key: 'PDF', action: exportToPdf, text: i18n.t('Export.pdf') },
]

const styles = {
  button: {
    width: '50px',
    height: '50px',
  },
}

interface IProps {
  containerId: string
  fileName: string
  dataCy?: string
}

interface IOption {
  action: Function
}

const Export = ({ containerId, fileName, dataCy }: IProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const handleExport = ({ action }: IOption) =>
    (): void => {
      const chart = document.querySelector(`#${containerId}`)
      handleClose()
      action(chart, fileName)
    }

  const open = Boolean(anchorEl)
  return (
    <Plugin name="Export">
      <Template name="top">
        <TemplatePlaceholder />
        <IconButton data-cy={dataCy} id={iconButton} onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="menu"
          data-cy={`${dataCy}-menu`}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={paperProps}
        >
          {options.map((option) => (
            <MenuItem
              data-cy={`item-option-${option.key}`}
              key={option.key}
              onClick={handleExport(option)}
            >
              {option.text}
            </MenuItem>
          ))}
        </Menu>
      </Template>
    </Plugin>
  )
}

export default withStyles(styles)(Export)
