import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Skeleton } from '@material-ui/lab'

interface IProps {
  count: number
}

const useStyles = makeStyles(() =>
  createStyles({
    loaderContainer: {
      height: 400,
      marginTop: 50,
      position: 'relative',
    },
    progressBar: {
      marginRight: 5,
      position: 'absolute',
      bottom: 0,
    },
  }),
)

const ChartProgress = ({ count }: IProps): JSX.Element => {
  const classes = useStyles()

  const getRandomInt = (min: number, max: number) =>
    Math.floor(Math.random() * (Math.floor(max) - Math.ceil(min) + 1)) + Math.ceil(min)

  return (
    <div className={classes.loaderContainer}>
      {[...Array(count)].map((_n, index) => (
        <Skeleton
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          variant="rect"
          height={getRandomInt(0, 400)}
          width={45}
          className={classes.progressBar}
          style={{ left: index * 50 }}
        />
      ))}
    </div>
  )
}

export default ChartProgress
