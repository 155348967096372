import React from 'react'

import { IOrderPlanVisit } from 'interfaces/IOrders'
import useStyles from 'screens/OrderCategory/Details/components/styles'
import Address from 'screens/OrderCategory/Details/components/Address'
import TrackingCard from 'screens/OrderCategory/Details/components/TrackingCard'
import PlanVisit from './PlanVisit'

type PlanVisitTrackingCardProps = {
  planVisits: IOrderPlanVisit[]
  orderNumber: string
}
export default ({ planVisits, orderNumber }: PlanVisitTrackingCardProps): JSX.Element => {
  const classes = useStyles()

  return (
    <TrackingCard orderNumber={orderNumber}>
      <div className={classes.visitList}>
        <Address label={planVisits[0].loadingAddressLabel || ''} />
        {planVisits.map((planVisit) => (
          <PlanVisit
            key={planVisit.planVisitId}
            address={planVisit.deliveryAddressLabel as string}
          />
        ))}
      </div>
    </TrackingCard>
  )
}
