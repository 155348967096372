import { Theme } from '@material-ui/core'
import styled from 'styled-components'

const Header = styled.div<{ theme: Theme }>`
  padding: 10px;
  background-color: ${(props) => props.theme.color.backgroundNavBar};
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 44px;
`
export default Header
