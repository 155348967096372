import React, { useContext, useEffect, useState } from 'react'
import { OptionTypeBase } from 'react-select'
import { useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import moment from 'moment'
import { IconButton, TextField } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import CreatableSelect from 'react-select/creatable'
import { FiltersContainer, TopFiltersContainer } from 'components/Layout'
import { Filter, FilterKey } from 'constants/filters'
import { FiltersContext } from 'store/FiltersContext'
import DateRangePicker from 'components/Inputs/DateRangePicker'
import {
  DeliveryTypeFilter,
  PlanStatusFilter,
  SiteFilter,
  TransportTypeFilter,
} from 'components/Inputs/ListFilter'
import { ControlComponent, getCreatableSelectStyle } from 'components/Inputs/CreatableSelect'
import ExtendedDateFilters from 'components/Table/ExtendedDateFilters'
import { ITextChange } from 'interfaces/interfaces'
import { setDateFactory, setSelectedDateFilterShortcutFactory } from 'utils/extendedDateFilter'
import useStyles from './styles'

interface IOrdersFiltersProps {
  filterKey: FilterKey
  getStats: (
    deliveryTypes?: string[],
    transportTypes?: string[],
    warehouseIds?: string[],
    planStatuses?: string[],
    deliveryWarehouseIds?: string[],
    zipCodes?: string[],
    minDay?: string,
    maxDay?: string,
  ) => void
  isForDistanceIndicator?: boolean
  selectedDateFilterShortcut?: string
  setSelectedDateFilterShortcut?: (value: string) => void
}

const OrdersFilters = ({
  filterKey,
  getStats,
  selectedDateFilterShortcut,
  setSelectedDateFilterShortcut = () => {
    /* */
  },
  isForDistanceIndicator = false,
}: IOrdersFiltersProps): JSX.Element => {
  const { setFilter, resetFilters, filters } = useContext(FiltersContext)
  const deliveryTypes = filters[filterKey][Filter.deliveryTypes] as string[]
  const transportTypes = filters[filterKey][Filter.transportTypes] as string[]
  const zipCodes = filters[filterKey][Filter.zipCodes] as OptionTypeBase[]
  const siteIds = filters[filterKey][Filter.sites] as string[]
  const planStatuses = filters[filterKey][Filter.planStatuses] as string[]
  const deliveryWarehouseIds = filters[filterKey][Filter.deliveryWarehouses] as string[]
  const startDate = filters[filterKey][Filter.startDate] as string
  const endDate = filters[filterKey][Filter.endDate] as string
  const distance = filters[filterKey][Filter.distance] as number
  const classes = useStyles()
  const [zipCodeInputText, setZipCodeInputText] = useState<string>('')
  const theme = useTheme()
  const { t } = useTranslation()

  useEffect(() => {
    getStats(
      deliveryTypes,
      transportTypes,
      siteIds,
      planStatuses,
      deliveryWarehouseIds,
      zipCodes.map(({ value }) => value),
      startDate,
      endDate,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    deliveryTypes,
    transportTypes,
    siteIds,
    planStatuses,
    deliveryWarehouseIds,
    zipCodes,
    startDate,
    endDate,
  ])

  const resetLocalFilters = (): void => resetFilters(filterKey)

  const handleZipCodeChange = (zipCodeValues: OptionTypeBase): void => {
    if (zipCodes.length > 0) {
      setFilter(filterKey, Filter.zipCodes, zipCodeValues as OptionTypeBase[])
    }
  }

  const handleZipCodeKeyDown = (event: React.KeyboardEvent<HTMLElement>): void => {
    if (!zipCodeInputText) return
    // eslint-disable-next-line default-case
    switch (event.key) {
      case 'Tab':
      case 'Enter':
        setZipCodeInputText('')
        setFilter(
          filterKey,
          Filter.zipCodes,
          zipCodes.find((zipCode) => zipCode.value === zipCodeInputText)
            ? zipCodes
            : [...zipCodes, { label: zipCodeInputText, value: zipCodeInputText }],
        )
        event.preventDefault()
    }
  }

  const handleStartChange = (startValue: MaterialUiPickersDate): void => {
    setFilter(filterKey, Filter.startDate, moment(startValue).toISOString())
    if (selectedDateFilterShortcut) {
      setSelectedDateFilterShortcut('')
    }
  }

  const handleEndChange = (endValue: MaterialUiPickersDate): void => {
    setFilter(filterKey, Filter.endDate, moment(endValue).toISOString())
    if (selectedDateFilterShortcut) {
      setSelectedDateFilterShortcut('')
    }
  }

  const onChangeDistance = (event: ITextChange): void => {
    const wantedDistance = +event.target.value
    const distanceValue = wantedDistance < 0 ? 0 : wantedDistance
    setFilter(filterKey, Filter.distance, distanceValue)
  }

  const { setEndDate, setStartDate } = setDateFactory(setFilter, filterKey)

  // eslint-disable-next-line max-len
  const { fnLast3months, fnLastMonth, fnLast30days, fnLast7days, fnNext7days, fnThisMonth } = setSelectedDateFilterShortcutFactory(
    setSelectedDateFilterShortcut,
  )

  return (
    <>
      <TopFiltersContainer>
        <FiltersContainer>
          <IconButton data-cy="initializeButton" onClick={resetLocalFilters}>
            <RefreshIcon />
          </IconButton>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onChangeStart={handleStartChange}
            onChangeEnd={handleEndChange}
            hideShiftPicker
          />
          <DeliveryTypeFilter
            handleChange={(values: string[]): void => {
              setFilter(filterKey, Filter.deliveryTypes, values)
            }}
            ids={deliveryTypes}
            dataCy="deliveryPicker"
          />
          <TransportTypeFilter
            handleChange={(values: string[]): void => {
              setFilter(filterKey, Filter.transportTypes, values)
            }}
            ids={transportTypes}
            dataCy="transportPicker"
          />
          <SiteFilter
            handleChange={(siteValues: string[]): void => {
              setFilter(filterKey, Filter.sites, siteValues)
            }}
            ids={siteIds}
            placeholder={t('reporting.orders.site')}
            dataCy="sitePicker"
            isUnselectAllowed={false}
          />
          <SiteFilter
            handleChange={(deliveryWarehouseValues: string[]): void => {
              setFilter(filterKey, Filter.deliveryWarehouses, deliveryWarehouseValues)
            }}
            ids={deliveryWarehouseIds}
            placeholder={t('reporting.orders.destinationSite')}
            dataCy="destinationSitePicker"
          />
          <CreatableSelect
            value={zipCodes}
            onChange={handleZipCodeChange}
            inputValue={zipCodeInputText}
            onKeyDown={handleZipCodeKeyDown}
            styles={getCreatableSelectStyle(
              'transparent',
              theme.color.label,
              theme.color.fieldBorder,
              theme.color.hover,
            )}
            className={classes.zipCode}
            components={{ DropdownIndicator: null, Control: ControlComponent }}
            onInputChange={setZipCodeInputText}
            isMulti
            isClearable
            menuIsOpen={false}
            backgroundColor={theme.color.backgroundBody}
            placeholder={t('OrderStatsScreen.zipCode')}
          />
          <PlanStatusFilter
            handleChange={(planStatusValues: string[]): void => {
              setFilter(filterKey, Filter.planStatuses, planStatusValues)
            }}
            ids={planStatuses}
            placeholder={t('tablesEntries.status')}
            dataCy="statusPicker"
          />
          {isForDistanceIndicator && (
            <TextField
              label={t('reporting.orders.distance')}
              onChange={onChangeDistance}
              type="number"
              name="distance"
              value={distance}
              variant="outlined"
              className={classes.distance}
              data-cy="distance"
            />
          )}
        </FiltersContainer>
      </TopFiltersContainer>
      <ExtendedDateFilters
        fnLast3months={fnLast3months}
        fnLastMonth={fnLastMonth}
        fnLast30days={fnLast30days}
        fnLast7days={fnLast7days}
        fnNext7days={fnNext7days}
        fnThisMonth={fnThisMonth}
        last3months
        lastMonth
        last30days
        last7days
        next7days
        thisMonth
        setEndDate={setEndDate}
        setStartDate={setStartDate}
      />
    </>
  )
}

export default OrdersFilters
