import { Grid } from '@material-ui/core'
import React, { useEffect, useState, useContext } from 'react'
import SitesProvider, { SitesConsumer } from 'store/SitesContext'
import { IWarehouse, ICarrier } from 'interfaces/interfaces'
import { useParams, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import SiteProfilCard from 'components/Sites/SiteProfilCard'
import SiteAddressCard from 'components/Sites/SiteAddressCard'
import { IAddress } from 'interfaces/Itours'
import { ContentConsumer } from 'store/ContentContext'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import { IIdParam } from 'interfaces/IIdParam'
import FormAction from 'components/Button/FormAction'
import { AuthContext } from 'store/AuthContext'
import SiteCarriersValidityCard from 'components/Card/SiteCarriersValidityCard'
import AppConfigProvider from 'store/AppConfigContext'

interface IProps {
  getDetails?: Function
  siteDetails: IWarehouse
  carriers: ICarrier[]
  updateSite: Function
  updateSuccess?: boolean
  createSuccess?: boolean
  createSite: Function
  refreshSites?: () => void
  refreshCarriers?: () => void
}

const SitesFormScreen = ({
  getDetails,
  siteDetails,
  carriers,
  updateSite,
  createSite,
  updateSuccess,
  createSuccess,
  refreshSites,
  refreshCarriers,
}: IProps): JSX.Element => {
  const { getUserData } = useContext(AuthContext)
  const { t } = useTranslation()
  const { id } = useParams<IIdParam>()
  const [site, setSite] = useState<Partial<IWarehouse>>({ active: true })
  const [carrierOptions, setCarrierOptions] = useState<ICarrier[]>(carriers)
  const history = useHistory()

  useEffect(() => {
    if (id && getDetails) {
      getDetails(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    setCarrierOptions(carriers)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carriers])

  useEffect(() => {
    if (siteDetails && id) {
      setSite({
        id: siteDetails?.id,
        name: siteDetails?.name,
        code: siteDetails?.code,
        type: siteDetails?.type,
        tourType: siteDetails?.tourType,
        active: siteDetails?.active,
        address: siteDetails.address,
        carriers: siteDetails.carriers,
        phone: siteDetails.phone,
        customerServicePhone: siteDetails.customerServicePhone,
        accessInstructions: siteDetails.accessInstructions,
        autoCreatePlanVisits: siteDetails.autoCreatePlanVisits,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteDetails])

  const handleProfilChange = (siteInfo: IWarehouse): void => {
    setSite((prevSite) => ({
      ...prevSite,
      name: siteInfo.name,
      code: siteInfo.code?.trim(),
      active: siteInfo.active,
      type: siteInfo.type,
      tourType: siteInfo.tourType,
      phone: siteInfo.phone,
      customerServicePhone: siteInfo.customerServicePhone,
      accessInstructions: siteInfo.accessInstructions,
      autoCreatePlanVisits: siteInfo.autoCreatePlanVisits,
    }))
  }

  const handleAddressChange = (siteAddress: IAddress): void => {
    setSite((prevSite) => ({
      ...prevSite,
      address: siteAddress,
    }))
  }

  const handleCarriersChange = (siteCarriers: ICarrier[]): void => {
    setSite((prevSite) => ({
      ...prevSite,
      carriers: siteCarriers,
    }))
  }

  const handleSaveClick = async (): Promise<void> => {
    if (id) {
      if (updateSite) {
        await updateSite(id, site)
      }
    } else {
      if (createSite) {
        await createSite(site)
      }
    }
  }

  useEffect(() => {
    if ((updateSuccess || createSuccess) && refreshSites) {
      history.push(ROUTES_PATH.sitesList)
      if (createSuccess) {
        getUserData(true)
      }
      refreshSites()
      if (refreshCarriers) {
        refreshCarriers()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSuccess, createSuccess])

  const handleCancelClick = (): void => {
    history.push(ROUTES_PATH.sitesList)
  }

  return (
    <Grid container justify="center">
      {id && (
        <Grid item xs={10}>
          <h3 data-cy="siteDetailDescription">{`${site.name || ''}`}</h3>
        </Grid>
      )}
      <SiteProfilCard key={`${site.id}-profil`} site={site} onProfilChange={handleProfilChange} />
      <SiteAddressCard
        mapName="siteForm"
        key={`${site.id}-address`}
        address={site.address}
        onAddressChange={handleAddressChange}
        isCreateMode={!!id}
        warehouseId={site.id}
      />
      <SiteCarriersValidityCard
        type="carrier"
        key={`${site.id}-carriers-${carrierOptions.length}`}
        validities={site.carriers || []}
        options={carrierOptions}
        onValidityChange={handleCarriersChange}
        cardTitle={t('SitesScreen.carrierRefValidity').toUpperCase()}
      />
      <FormAction onSaveClick={handleSaveClick} onCancelClick={handleCancelClick} />
    </Grid>
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <AppConfigProvider>
    <SitesProvider>
      <ContentConsumer>
        {(contentCtx): JSX.Element => (
          <SitesConsumer>
            {(sitesCtx): JSX.Element => (
              <SitesFormScreen
                getDetails={sitesCtx.getDetails}
                carriers={contentCtx.carriers}
                siteDetails={sitesCtx.siteDetails}
                updateSite={sitesCtx.updateSite}
                createSite={sitesCtx.createSite}
                updateSuccess={sitesCtx.updateSuccess}
                createSuccess={sitesCtx.createSuccess}
                refreshSites={contentCtx.refreshSites}
                refreshCarriers={contentCtx.refreshCarriers}
                {...props}
              />
            )}
          </SitesConsumer>
        )}
      </ContentConsumer>
    </SitesProvider>
  </AppConfigProvider>
)
