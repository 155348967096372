import React, { useContext } from 'react'
import { DeleteOutline, Send } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { FormControlLabel, Grid, IconButton, Switch } from '@material-ui/core'
import styled from 'styled-components'
import COLORS from 'screens/PlanningCategory/PlanningScreen/components/ClusterColors'
import { AppConfigContext } from 'store/AppConfigContext'
import { TransportType } from 'constants/constants'
import { IOutsourced } from 'interfaces/IOutsourced'
import { calculateMainQuantity } from 'utils/planningUtils'

const HeaderContainer = styled(Grid)`
  padding: 10px;
  background-color: ${COLORS[4]};
  color: white;
  cursor: default;
`

const CustomIconButton = styled(IconButton)`
  padding: 1px;
  color: #fff;
`

interface IOutsourcedHeader {
  showMoreForAll: boolean
  handleToggleShowMoreForAll: () => void
  carrierName: string
  outsourced: IOutsourced
  onDelete: () => void
  onSend: () => void
}

const ClusterHeader = ({
  showMoreForAll,
  handleToggleShowMoreForAll,
  carrierName,
  outsourced,
  onDelete,
  onSend,
}: IOutsourcedHeader): JSX.Element => {
  const { t } = useTranslation()
  const { toolTipsConfig } = useContext(AppConfigContext)
  const ordersCount = outsourced?.visits?.reduce(
    (total, current) => total + (current.ordersCount || 0),
    0,
  )
  const totalMainQuantityToDeliver = calculateMainQuantity(
    outsourced?.visits || [],
    TransportType.Delivery,
  )
  const totalMainQuantityToLoad = calculateMainQuantity(
    outsourced?.visits || [],
    TransportType.Pickup,
  )

  return (
    <HeaderContainer container direction="column">
      <Grid
        item
        container
        direction="row"
        wrap="nowrap"
        justify="space-between"
        style={{ height: '52px' }}
      >
        <Grid item container direction="column">
          <Grid item>
            <strong>{carrierName}</strong>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item>
              {`${t('PlanningScreen.visitsCountShortForm')}: ${outsourced?.visits?.length}`}
            </Grid>
            <Grid item>
              {`${t(
                'PlanningScreen.quantityShortForm',
              )}: ${totalMainQuantityToDeliver}-${totalMainQuantityToLoad}`}
            </Grid>
            <Grid item>{`${t('PlanningScreen.ordersCountShortForm')}: ${ordersCount}`}</Grid>
          </Grid>
        </Grid>
        <Grid item container direction="column" alignContent="flex-end" xs>
          <Grid item>
            <CustomIconButton onClick={onSend}>
              <Send />
            </CustomIconButton>
          </Grid>
          <Grid item>
            <CustomIconButton onClick={onDelete}>
              <DeleteOutline />
            </CustomIconButton>
          </Grid>
        </Grid>
      </Grid>
      {toolTipsConfig.cardSettings?.length > 0 && (
        <Grid item>
          <FormControlLabel
            control={<Switch checked={showMoreForAll} onChange={handleToggleShowMoreForAll} />}
            label={`+ ${t('CardSettingsMenu.info')}`}
          />
        </Grid>
      )}
    </HeaderContainer>
  )
}

export default ClusterHeader
