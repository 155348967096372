import { createStyles, makeStyles } from '@material-ui/core/styles'

export default makeStyles(() =>
  createStyles({
    commandContainer: {
      display: 'flex',
      position: 'relative',
      flexDirection: 'row',
    },
  }),
)
