import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { DRAWER_WIDTH } from 'constants/constants'

export const MAIN_PADDING = 16
export const HEADER_HEIGHT = 64

export default makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
      },
      mainContainer: {
        height: 'fit-content',
        minHeight: '100vh',
        flexGrow: 1,
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
        backgroundColor: theme.color.backgroundBody,
        padding: MAIN_PADDING,
        paddingTop: 80,
      },
      appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: theme.color.backgroundNavBarShift,
        height: HEADER_HEIGHT,
        /* backgroundColor: theme.palette.background.paper, */
      },
      appBarShift: {
        marginLeft: DRAWER_WIDTH,
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        backgroundColor: theme.color.backgroundNavBarShift,
      },
      menuButton: {
        marginRight: 36,
        color: '#FFF',
      },
      hide: {
        display: 'none',
      },
      drawer: {
        width: DRAWER_WIDTH,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        backgroundColor: theme.color.backgroundNavBar,
      },
      drawerOpen: {
        width: DRAWER_WIDTH,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        backgroundColor: theme.color.backgroundNavBar,
      },
      drawerClose: {
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9) + 1,
        },
        backgroundColor: theme.color.backgroundNavBar,
      },
      toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
      },
      filtersMargin: {
        marginLeft: 8,
      },
      datePickerWidth: {
        width: 215,
      },
      shift: {
        width: 150,
      },
      filtersWidth: {
        width: 170,
      },
      filterWidthHeight: {
        width: 300,
        height: 40,
        '& .MuiSelect-icon': {
          color: '#FFF',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#FFF',
        },
      },
      darkModeButtonLabel: {
        marginLeft: '0!important',
      },
      listItem: {
        color: theme.color.secondary,
        fontWeight: 700,
      },
      listItemSelected: {
        color: theme.color.primary,
        fontWeight: 700,
      },
      currentApp: {
        cursor: 'default',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: 16,
        color: theme.color.primary,
        paddingLeft: 16,
        paddingRight: 16,
        marginBottom: 4,
        marginTop: 4,
      },
      boxListSubItem: {
        color: theme.color.secondary,
        boxShadow: `inset -5px 0px 0px 0px transparent`,
      },
      boxListSubItemSelected: {
        color: theme.color.primary,
        boxShadow: `inset -5px 0px 0px 0px ${theme.color.primary}`,
      },
      listSubItem: {
        color: theme.color.secondary,
      },
      listSubItemIconOpen: {
        paddingLeft: 25,
        paddingRight: 25,
      },
      listSubItemIconClose: {
        paddingLeft: 0,
        paddingRight: 0,
      },
      collapse: {
        backgroundColor: theme.color.backgroundNavBar,
      },
      fullWidthMargin: {
        marginBottom: 8,
        marginTop: 8,
      },
      logo: {
        height: 46,
        width: 'auto',
        paddingLeft: 9,
        position: 'fixed',
        bottom: 22,
        objectFit: 'contain',
      },
      logoFull: {
        height: 50,
        width: 'auto',
        paddingLeft: 13,
        position: 'fixed',
        bottom: 20,
        objectFit: 'contain',
      },
      defaultDate: {
        display: 'none',
      },
      rightSideBar: {
        display: 'flex',
        flexDirection: 'column',
      },
      externalLink: {
        color: theme.color.secondary,
        textDecoration: 'none',
        fontWeight: 'bold',
        fontSize: 16,
        padding: '10px 16px 10px 16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 4,
        '&:hover': {
          background: theme.color.backgroundStrongGrey,
        },
      },
      externalLinkIcon: {
        fontSize: '16px',
        color: 'gray',
      },
      emptySpace: {
        flexGrow: 1,
      },
      displayContent: {
        height: '100vh',
        display: 'flex',
      },
    }),
  { index: 1 },
)
