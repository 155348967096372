export enum FilterKey {
  drivers = 'DriversTableFilters',
  carriers = 'CarriersTableFilters',
  sites = 'SitesTableFilters',
  tenants = 'TenantsTableFilters',
  trailers = 'TrailersTableFilters',
  users = 'UsersTableFilters',
  vehicles = 'VehiclesTableFilters',
  vehicleTypes = 'VehicleTypesTableFilters',
  mapMonitoring = 'MapMonitoringFilters',
  orders = 'OrdersTableFilters',
  planningOrders = 'PlanningOrdersTableFilters',
  geocodingOrders = 'GeocodingOrdersTableFilters',
  carrierExecution = 'CarrierExecutionTableFilters',
  execution = 'ExecutionTableFilters',
  tourMonitoring = 'TourMonitoringTableFilters',
  planTours = 'PlanToursTableFilters',
  routingRules = 'RoutingRulesTableFilters',
  marketShare = 'MarketShareIndicatorFilters',
  tourCancellations = 'TourCancellationsIndicatorFilters',
  stopCancellations = 'StopCancellationsIndicatorFilters',
  mobileApp = 'MobileAppIndicatorFilters',
  punctuality = 'PunctualityIndicatorFilters',
  tourProgress = 'TourProgressIndicatorFilters',
  transportVolume = 'TransportVolumeIndicatorFilters',
  orderHistory = 'OrderHistoryIndicatorFilters',
  deliveryScheduleChanges = 'DeliveryScheduleChangesFilters',
  orderIndicator = 'OrderIndicatorFilters',
  planningMonitoring = 'PlanningMonitoringIndicatorFilters',
  sectors = 'SectorsTableFilters',
  adminWarehouses = 'AdminWarehousesTableFilters',
  templatePlans = 'TemplatePlansTableFilters',
  warehouseTimeSlots = 'WarehouseTimeSlotsTableFilters',
  siteReporting = 'SiteReportingFilters',
  notifierConfigurations = 'NotifierConfigurationsTableFilter',
  costReporting = 'CostReportingFilters',
  costConfig = 'CostConfigFilters',
  customerRatingReporting = 'CustomerRatingReporting',
  customerCommentsReporting = 'CustomerCommentsReporting',
  deliveryDistance = 'DeliveryDistanceIndicatorFilters',
  alerts = 'alertsFilters',
  disputes = 'disputesFilters',
  replay = 'replayFilters',
  apikeys = 'apikeysFilter',
}

export enum Filter {
  carriers = 'carriers',
  searchText = 'searchText',
  active = 'active',
  warehouses = 'warehouses',
  types = 'types',
  vehicleTypes = 'vehicleTypes',
  companies = 'companies',
  roles = 'roles',
  sites = 'sites',
  startDate = 'startDate',
  endDate = 'endDate',
  deliveryMaxEndDate = 'deliveryMaxEndDate',
  deliveryTypes = 'deliveryTypes',
  transportTypes = 'transportTypes',
  deliverySites = 'deliverySites',
  planStatuses = 'planStatuses',
  relevance = 'relevance',
  status = 'status',
  drivers = 'drivers',
  uses = 'uses',
  date = 'date',
  axis = 'axis',
  deliveryWarehouses = 'deliveryWarehouse',
  shift = 'shift',
  zipCodes = 'zipCodes',
  hasSector = 'hasSector',
  tenant = 'tenant',
  ratingGrade = 'ratingGrade',
  lastThirtyWeeks = 'lastThirtyWeeks',
  distance = 'distance',
  dealers = 'dealers',
  read = 'read',
  tourNumbers = 'tourNumbers',
  disputeReasonCodes = 'disputeReasonCodes',
  disputeReasonTypes = 'disputeReasonTypes',
  cancellationReasons = 'cancellationReasons',
  groupBy = 'groupBy',
}
