import { useTheme, Theme } from '@material-ui/core/styles'
import React, { useContext, useState } from 'react'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { CircularProgress, Grid, IconButton, Menu, MenuItem, Paper } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

import { ISelectableMarker } from 'interfaces/map'

import { TransportType } from 'constants/constants'
import { AppConfigContext } from 'store/AppConfigContext'
import { AuthContext } from 'store/AuthContext'
import { calculateMainQuantity, sortPlanVisits } from 'utils/planningUtils'
import { ContentContext } from 'store/ContentContext'
import ListItem from './ListItem'
import { PlanificationContext } from '../PlanningStore'
import { StyledLoaderContainer } from './BasicComponents'

interface ISelectedColumn {
  markers: ISelectableMarker[]
  toursCount: number
  isClusterMode: boolean
  createManualTour: () => void
  totalDistance: number
  estimatedCost: number
  isOutsourceMode: boolean
}

function filterItemsToDisplay(
  markers: ISelectableMarker[],
  isClusterMode: boolean,
  isOutsourceMode: boolean,
): ISelectableMarker[] {
  if (isOutsourceMode) {
    return markers.filter((marker) => !marker.tourId && !marker.isOutsourced)
  }
  if (isClusterMode) {
    return markers.filter((marker) => !marker.tourId && marker.clusterId === undefined)
  }
  if (markers.findIndex((e) => e.selected) === -1) {
    return markers.filter((marker) => !marker.tourId && marker.manualTourId === undefined)
  }
  return markers.filter((marker) => marker.selected)
}

const HeaderContainer = styled(Grid)<{ theme: Theme }>`
  background-color: ${(props) => props.theme.color.headerPaper};
  padding: 10px;
`

const ListContainer = styled(Paper)<{ theme: Theme }>`
  min-width: 260px;
  flex-direction: column;
  display: flex;
  background-color: ${(props) => props.theme.color.container};
  position: relative;
`

const StyledContainer = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  overflow-y: overlay;
  height: 100%;
  max-height: calc(100% - 40px);
`

const StyledSubContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const ToursInfoHeader = styled.div`
  padding: 10px;
  background-color: ${(props) => props.theme.color.backgroundNavBar};
`

const ToursInfoContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
`

const ToursInfo = styled.div`
  flex: 1;
`

const SelectedColumn = React.forwardRef(
  (
    {
      markers,
      toursCount,
      isClusterMode,
      createManualTour,
      totalDistance,
      estimatedCost,
      isOutsourceMode,
    }: ISelectedColumn,
    ref: React.Ref<HTMLElement>,
  ): JSX.Element => {
    const { isVisitsLoading, isMapotempoActionInProgress, selectedPlan, isMultisite } = useContext(PlanificationContext)
    const { t } = useTranslation()
    const theme = useTheme()
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const [showMoreForAll, setShowMoreForAll] = useState<boolean>(false)
    const { toolTipsConfig } = useContext(AppConfigContext)
    const { shouldDisplayTourEstimatedCost, user } = useContext(AuthContext)
    const { tenants } = useContext(ContentContext)

    const isMenuOpen = Boolean(anchorEl)

    const userTenant = tenants.find((tenant) => tenant.id === user?.tenantId)
    const tagsMap = new Map(userTenant?.tags?.map((tag) => [tag.code as string, tag.label as string]))

    const items = filterItemsToDisplay(markers, isClusterMode, isOutsourceMode)
    const numberSelected = items.filter((item) =>
      isClusterMode ? !item.tourId && !item.clusterId : item.selected,
    ).length
    const quantityToDeliver = calculateMainQuantity(items, TransportType.Delivery)
    const quantityToLoad = calculateMainQuantity(items, TransportType.Pickup)
    const ordersCount = items.reduce((total, current) => total + (current.ordersCount || 0), 0)
    const toursStops = markers.filter(
      (marker) => marker.tourId && marker.mainQuantity !== undefined,
    )
    const totalTourQuantityToDeliver = calculateMainQuantity(toursStops, TransportType.Delivery)
    const totalTourQuantityToLoad = calculateMainQuantity(toursStops, TransportType.Pickup)
    const totalTourCmdCount = toursStops.reduce(
      (total, current) => total + (current.ordersCount || 0),
      0,
    )
    const stopCount = toursStops.length

    const handleMenuClick = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
      setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = (): void => {
      setAnchorEl(null)
    }

    const handleCreateManualTourClick = (): void => {
      createManualTour()
      handleMenuClose()
    }

    const handleToggleShowMoreForAll = (): void => {
      setShowMoreForAll(!showMoreForAll)
    }

    return (
      <ListContainer ref={ref} square theme={theme}>
        {isVisitsLoading && (
          <StyledLoaderContainer>
            <CircularProgress color="secondary" />
          </StyledLoaderContainer>
        )}
        {!isClusterMode && !isOutsourceMode && (
          <ToursInfoHeader theme={theme}>
            <ToursInfoContainer>
              <ToursInfo data-cy="toursInfo">
                <b data-cy={`${t('PlanningScreen.tours')}`}>
                  {`${t('PlanningScreen.tours')}: ${toursCount}`}
                </b>
                <StyledSubContainer>
                  <div data-cy={`${t('PlanningScreen.visitsCountShortForm')}`}>
                    {`${t('PlanningScreen.visitsCountShortForm')}: ${stopCount}`}
                  </div>
                  <div data-cy={`${t('PlanningScreen.quantityShortForm')}`}>
                    {`${t(
                      'PlanningScreen.quantityShortForm',
                    )}: ${totalTourQuantityToDeliver}-${totalTourQuantityToLoad}`}
                  </div>
                  <div data-cy={`${t('PlanningScreen.ordersCountShortForm')}`}>
                    {`${t('PlanningScreen.ordersCountShortForm')}: ${
                      isMultisite ? totalTourCmdCount / 2 : totalTourCmdCount
                    }`}
                  </div>
                </StyledSubContainer>
                <div data-cy="distance">
                  {`${t('PlanningScreen.distance')}: ${totalDistance}km`}
                </div>
                {shouldDisplayTourEstimatedCost && estimatedCost > 0 && (
                  <div data-cy="cost">
                    {t('PlanningScreen.estimatedCost', {
                      value: estimatedCost.toLocaleString('fr-FR', {
                        style: 'currency',
                        currency: 'EUR',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                        compactDisplay: 'long',
                      }),
                    })}
                  </div>
                )}
              </ToursInfo>
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleMenuClick}
                style={{ padding: 0 }}
                data-cy="moreButton"
              >
                <MoreVertIcon />
              </IconButton>
            </ToursInfoContainer>
          </ToursInfoHeader>
        )}
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={isMenuOpen}
          onClose={handleMenuClose}
          id="selected-column-menu"
        >
          <MenuItem
            data-cy="create-manual-tour-option"
            disabled={isMapotempoActionInProgress}
            onClick={handleCreateManualTourClick}
          >
            {t('PlanningScreen.createManualTour')}
          </MenuItem>
        </Menu>
        <HeaderContainer theme={theme} container direction="column" data-cy="header">
          <Grid item container direction="column" style={{ height: '52px' }}>
            <Grid item>
              <b data-cy={t('PlanningScreen.toPlan')}>
                {t('PlanningScreen.toPlan')}: {numberSelected > 0 ? numberSelected : items.length}
              </b>
            </Grid>
            <Grid item container justify="space-between" data-cy="header">
              <Grid item data-cy={`${t('PlanningScreen.quantityShortForm')}`}>
                {`${t('PlanningScreen.quantityShortForm')}: ${quantityToDeliver}-${quantityToLoad}`}
              </Grid>
              <Grid item data-cy={`${t('PlanningScreen.ordersCountShortForm')}`}>
                {`${t('PlanningScreen.ordersCountShortForm')}: ${ordersCount}`}
              </Grid>
            </Grid>
          </Grid>
          {toolTipsConfig.cardSettings?.length > 0 && (
            <Grid item data-cy="header">
              <FormControlLabel
                control={<Switch checked={showMoreForAll} onChange={handleToggleShowMoreForAll} />}
                label={`+ ${t('CardSettingsMenu.info')}`}
              />
            </Grid>
          )}
        </HeaderContainer>
        <Droppable
          droppableId="SelectedStops"
          isDropDisabled={isMapotempoActionInProgress || selectedPlan?.deletedOnThirdParty}
        >
          {(provided): JSX.Element => (
            <StyledContainer ref={provided.innerRef} data-cy="visit-container">
              {sortPlanVisits(items).map((marker, index) => (
                <Draggable
                  key={marker.stopId}
                  index={index}
                  draggableId={marker.stopId}
                  isDragDisabled={isMapotempoActionInProgress || selectedPlan?.deletedOnThirdParty}
                >
                  {(providedMarker, snapshotMarker): JSX.Element => (
                    <ListItem
                      tagsMap={tagsMap}
                      showMoreForAll={showMoreForAll}
                      marker={marker}
                      providedMarker={providedMarker}
                      snapshotMarker={snapshotMarker}
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </StyledContainer>
          )}
        </Droppable>
      </ListContainer>
    )
  },
)

export default SelectedColumn
