// #region GENERAL CONFIG
export const FULL_URL = process.env.REACT_APP_FULL_URL
export const MDM_URL = process.env.REACT_APP_MDM_API_URL
export const CUSTOMER_NOTIFIER_URL = process.env.REACT_APP_NOTIFIER_API_URL
export const RESERVATION_URL = process.env.REACT_APP_RESERVE_APPOINTMENT_URL || ''
export const HERE_API_KEY = process.env.REACT_APP_HERE_API_KEY
export const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}
export const APP_INSIGHT_CONNECTION_STRING = process.env.REACT_APP_APP_INSIGHT_CONNECTION_STRING
export const APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT
export const EXPECTED_DOMAIN = process.env.REACT_APP_EXPECTED_DOMAIN
// #endregion GENERAL CONFIG

// #region TENANT FLAGS
export const TENANTS_WITH_DELIVERY_MAX = process.env.REACT_APP_TENANTS_WITH_DELIVERY_MAX
export const TENANTS_WITH_REPAIR_PLAN = process.env.REACT_APP_TENANTS_WITH_REPAIR_PLAN
export const TENANTS_WITHOUT_OPTIMIZATION_JOB = JSON.parse(
  process.env.REACT_APP_TENANTS_WITHOUT_OPTIMIZATION_JOB || null,
)
export const TENANTS_WITH_REORGANIZE_UX = JSON.parse(
  process.env.REACT_APP_TENANTS_WITH_REORGANIZE_UX || null,
)
export const TENANTS_WITHOUT_VISITS_GENERATION_JOB = JSON.parse(
  process.env.REACT_APP_TENANTS_WITHOUT_VISITS_GENERATION_JOB || null,
)
export const TENANTS_WITH_ALERTS = JSON.parse(process.env.REACT_APP_TENANTS_WITH_ALERTS || null)
// #endregion TENANT FLAGS

// #region USER FLAGS
export const USERS_WITH_REAL_TIME_ALERTS = JSON.parse(
  process.env.REACT_APP_USERS_WITH_REAL_TIME_ALERTS || null,
)
// #endregion USER FLAGS

// #region DISPLAY FLAGS

export const DISPLAY_PIN_TOOLTIP_FIELDS = JSON.parse(
  process.env.REACT_APP_DISPLAY_PIN_TOOLTIP_FIELDS || null,
)
export const DISPLAY_PLAN_TOUR_ESTIMATED_COST = JSON.parse(
  process.env.REACT_APP_DISPLAY_PLAN_TOUR_ESTIMATED_COST || null,
)
export const DISPLAY_SORT_VISITS = JSON.parse(process.env.REACT_APP_DISPLAY_SORT_VISITS || null)
export const DISPLAY_VISITS_WITHOUT_TOUR = JSON.parse(
  process.env.REACT_APP_DISPLAY_VISITS_WITHOUT_TOUR || null,
)

// #endregion DISPLAY FLAGS

// #region GENERAL FLAGS
export const AUTOMATIC_VISIT_FROM_ORDER = JSON.parse(
  process.env.REACT_APP_AUTOMATIC_VISIT_FROM_ORDER || null,
)
export const FILTER_PLAN_TOURS_BY_DELIVERY_TYPE = JSON.parse(
  process.env.REACT_APP_FILTER_PLAN_TOURS_BY_DELIVERY_TYPE || null,
)
// #endregion GENERAL FLAGS
