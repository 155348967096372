import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useTheme } from '@material-ui/core/styles'

import { COLUMNS_MODEL_ALERTS_LIST } from 'constants/table'
import { Filter, FilterKey } from 'constants/filters'
import CustomTable from 'components/Table/CustomTable'
import { AlertsContext } from 'store/AlertsContext'
import DateRangePicker from 'components/Inputs/DateRangePicker'
import { DealerFilter, ReadFilter, SiteFilter } from 'components/Inputs/ListFilter'
import { FiltersContext } from 'store/FiltersContext'
import { IAlert } from 'interfaces/IAlert'
import { IAlertsFilters } from 'interfaces/interfaces'
import { AuthContext } from 'store/AuthContext'
import AlertModal from './components/AlertModal'

export const AlertsListScreen = (): JSX.Element => {
  const filterKey = FilterKey.alerts
  const {
    getAlertsList,
    getUnreadAlerts,
    allAlerts,
    setAllAlerts,
    alertsCount,
    updateReadState,
    updateReadStateSuccess,
    setUpdateReadStateSuccess,
  } = useContext(AlertsContext)
  const { setFilter, filters } = useContext(FiltersContext)
  const { user } = useContext(AuthContext)
  const [openAlert, setOpenAlert] = useState<IAlert | null>(null)

  const startDate = filters[filterKey][Filter.startDate] as string
  const endDate = filters[filterKey][Filter.endDate] as string
  const siteIds = filters[filterKey][Filter.sites] as string[]
  const dealerIds = filters[filterKey][Filter.dealers] as string[]
  const read = filters[filterKey][Filter.read] as string[]

  const { t } = useTranslation()
  const theme = useTheme()

  useEffect(() => {
    if (updateReadStateSuccess && openAlert) {
      setAllAlerts((prevAllAlerts) =>
        prevAllAlerts.map((alert) => {
          if (alert.id === openAlert.id) {
            return { ...alert, read: !alert.read }
          }
          return alert
        }),
      )
      setOpenAlert((prevOpenAlert) => {
        if (prevOpenAlert) {
          return { ...prevOpenAlert, read: !prevOpenAlert?.read }
        }
        return null
      })
      setUpdateReadStateSuccess(false)
      getUnreadAlerts()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateReadStateSuccess])

  const filtersToDisplay = useMemo(
    (): JSX.Element => (
      <>
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          onChangeStart={(startValue): void => {
            setFilter(filterKey, Filter.startDate, moment(startValue).toISOString())
          }}
          onChangeEnd={(endValue): void => {
            setFilter(filterKey, Filter.endDate, moment(endValue).toISOString())
          }}
          hideShiftPicker
        />
        <SiteFilter
          dataCy="sitePicker"
          handleChange={(siteValues: string[]): void => {
            setFilter(filterKey, Filter.sites, siteValues)
          }}
          ids={siteIds}
          isUnselectAllowed={false}
        />
        <DealerFilter
          handleChange={(values: string[]): void => setFilter(filterKey, Filter.dealers, values)}
          ids={dealerIds || []}
          dataCy="dealerPicker"
          placeholder={t('tablesEntries.dealers')}
        />
        <ReadFilter
          handleChange={(readValues: string[]): void => {
            setFilter(filterKey, Filter.read, readValues)
          }}
          ids={read}
          dataCy="activePicker"
        />
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [startDate, endDate, siteIds, dealerIds, read, theme],
  )

  return (
    <>
      <CustomTable
        columns={COLUMNS_MODEL_ALERTS_LIST}
        updateList={(
          filtersToAdd?: IAlertsFilters,
          offset?: number,
          rowsPerPage?: number,
          sortField?: number,
          sortDirection?: string,
        ) =>
          getAlertsList(
            {
              ...filtersToAdd,
              startDate,
              endDate,
              dealerIds,
              read,
              concernedRoles: [...(user?.roles || [])],
              concernedWarehouseIds: siteIds,
            },
            offset,
            rowsPerPage,
            sortField,
            sortDirection,
          )}
        listData={allAlerts}
        count={alertsCount}
        columnConfigName="alertsList"
        filterKey={FilterKey.alerts}
        filterFieldsToDisplay={filtersToDisplay}
        handleRowClick={(event, alert?: object): void => {
          const alertToOpen = alert as IAlert
          setOpenAlert(alertToOpen)
          if (!alertToOpen.read) {
            updateReadState(alertToOpen.id, true)
          }
        }}
        defaultSortDirection="desc"
      />
      <AlertModal
        toggleModal={() => {
          setOpenAlert(null)
        }}
        alert={openAlert}
      />
    </>
  )
}
