import React from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '@material-ui/core'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import useStyles from './styles'

type IProps = {
  startDate: string
  endDate: string
}

const DeliveryTimeSlotModifiedAlert = ({ startDate, endDate }: IProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <div>
      <Tooltip
        title={(
          <div>
            <pre className={classes.message}>
              {t('tablesEntries.deliveryTimeSlotModifiedAlert', {
                startDate,
                endDate,
              })}
            </pre>
          </div>
        )}
      >
        <ErrorOutlineIcon />
      </Tooltip>
    </div>
  )
}

export default DeliveryTimeSlotModifiedAlert
