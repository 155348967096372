import React, { CSSProperties, useContext, MouseEvent, useState } from 'react'
import { ListItem, ListItemIcon, Checkbox, ListItemText, Radio, Tooltip, Fade } from '@material-ui/core'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import moment from 'moment'
import { AlarmOn, Edit } from '@material-ui/icons'

import { dateDefaultFormat } from 'utils/dateFormat'
import { ContentContext } from 'store/ContentContext'
import { isDefined } from 'utils/functions'
import useToggle from 'hooks/useToggle'
import { IVehicleAvailability } from 'interfaces/IVehicleAvailability'
import { PlanificationContext } from '../../PlanningStore'
import VehicleAvailabilityDatePicker from './VehicleAvailabilityDatePicker'
import useStyles from './styles'

interface ICustomListItem {
  label?: string
  selected?: boolean
  onClick(): void
  style?: CSSProperties
  divider?: boolean
  startDate?: string
  endDate?: string
  isSingleValue?: boolean
  disabled?: boolean
  siteId?: string
  capacity?: number
  id?: string
}

const CustomListItem = ({
  label,
  onClick,
  startDate,
  endDate,
  selected = true,
  style,
  divider = false,
  isSingleValue = false,
  disabled = false,
  siteId,
  capacity,
  id,
}: ICustomListItem): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { sites } = useContext(ContentContext)
  const { isMultisite, setVehiclesList, setVehiclesListForGlobalOptim } = useContext(PlanificationContext)
  const [isHoveringStartDate, setIsHoveringStartDate] = useState<boolean>(false)
  const [isHoveringEndDate, setIsHoveringEndDate] = useState<boolean>(false)
  const [isStartDatePickerOpen, toggleIsStartDatePickerOpen] = useToggle(false)
  const [isEndDatePickerOpen, toggleIsEndDatePickerOpen] = useToggle(false)

  const setVehicleStartDateToNow = (vehicles: IVehicleAvailability[]): IVehicleAvailability[] => vehicles.map(vehicle => {
    if (vehicle.id === id) {
      return { ...vehicle, startDate: new Date().toISOString(), wasStartDateModifiedManually: true }
    }
    return vehicle
  })

  const editVehicleDate = (
    vehicles: IVehicleAvailability[],
    field: 'startDate' | 'endDate',
    newValue: MaterialUiPickersDate,
  ): IVehicleAvailability[] => vehicles.map(vehicle => {
    if (vehicle.id === id) {
      return {
        ...vehicle,
        [field]: moment(newValue).toISOString(),
        wasStartDateModifiedManually: field === 'startDate',
        wasEndDateModifiedManually: field === 'endDate',
      }
    }
    return vehicle
  })

  const handleNowClick = (e: MouseEvent) => {
    e.stopPropagation()
    setVehiclesList(prev => setVehicleStartDateToNow(prev))
    setVehiclesListForGlobalOptim(prev => setVehicleStartDateToNow(prev))
    setIsHoveringStartDate(false)
  }

  const handleStartDateEditClick = (e: MouseEvent) => {
    e.stopPropagation()
    toggleIsStartDatePickerOpen()
  }

  const handleEndDateEditClick = (e: MouseEvent) => {
    e.stopPropagation()
    toggleIsEndDatePickerOpen()
  }

  const shouldDisplayNowButton = (start: string): boolean => new Date() < new Date(start)

  const handleStartDatePickerChange = (newValue: MaterialUiPickersDate) => {
    setVehiclesList(prev => editVehicleDate(prev, 'startDate', newValue))
    setVehiclesListForGlobalOptim(prev => editVehicleDate(prev, 'startDate', newValue))
  }

  const handleEndDatePickerChange = (newValue: MaterialUiPickersDate) => {
    setVehiclesList(prev => editVehicleDate(prev, 'endDate', newValue))
    setVehiclesListForGlobalOptim(prev => editVehicleDate(prev, 'endDate', newValue))
  }

  const onMouseEnterStartDate = () => {
    setIsHoveringStartDate(true)
  }

  const onMouseLeaveStartDate = () => {
    setIsHoveringStartDate(false)
  }

  const onMouseEnterEndDate = () => {
    setIsHoveringEndDate(true)
  }

  const onMouseLeaveEndDate = () => {
    setIsHoveringEndDate(false)
  }

  return (
    <ListItem
      data-cy={label}
      role={undefined}
      dense
      button
      onClick={onClick}
      divider={divider}
      style={style}
      disabled={disabled}
    >
      <ListItemIcon>
        {isSingleValue ? (
          <Radio
            edge="start"
            checked={selected}
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': label }}
          />
        ) : (
          <Checkbox
            edge="start"
            checked={selected}
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': label }}
            data-cy="checkbox"
          />
        )}
      </ListItemIcon>
      <ListItemText id={label} primary={label} className={classes.text} />
      {isMultisite && siteId && (
        <ListItemText
          id={siteId}
          primary={sites.find((site) => site.id === siteId)?.name || ''}
          className={classes.text}
        />
      )}
      {startDate && (
        <div className={classes.text} onMouseEnter={onMouseEnterStartDate} onMouseLeave={onMouseLeaveStartDate}>
          <ListItemText
            id={startDate}
            primary={dateDefaultFormat(startDate)}
            className={clsx(classes.dateText, isHoveringStartDate ? classes.displayNone : classes.displayFlex)}
          />
          <div
            className={clsx(
              classes.dateText,
              classes.buttonsContainer,
              isHoveringStartDate ? classes.displayFlex : classes.displayNone,
            )}
          >
            {shouldDisplayNowButton(startDate) && (
              <Tooltip
                id="now-button"
                title={`${t('PlanningScreen.optimizeModal.makeVehicleAvailableImmediately')}`}
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 0 }}
              >
                <ListItemIcon className={clsx(classes.icon, classes.nowIcon)} onClick={handleNowClick}>
                  <AlarmOn />
                </ListItemIcon>
              </Tooltip>
            )}
            <Tooltip
              id="edit-button"
              title={`${t('PlanningScreen.optimizeModal.changeDate')}`}
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 0 }}
            >
              <ListItemIcon className={clsx(classes.icon, classes.editIcon)} onClick={handleStartDateEditClick}>
                <Edit />
              </ListItemIcon>
            </Tooltip>
          </div>
          {endDate && new Date(startDate) > new Date(endDate) && (
            <span className={classes.error}>{t('PlanningScreen.optimizeModal.dateValidation')}</span>
          )}
        </div>
      )}
      {endDate && (
        <div className={classes.text} onMouseEnter={onMouseEnterEndDate} onMouseLeave={onMouseLeaveEndDate}>
          <ListItemText
            id={endDate}
            primary={dateDefaultFormat(endDate)}
            className={clsx(classes.dateText, isHoveringEndDate ? classes.displayNone : classes.displayFlex)}
          />
          <div
            className={clsx(
              classes.dateText,
              classes.buttonsContainer,
              isHoveringEndDate ? classes.displayFlex : classes.displayNone,
            )}
          >
            <Tooltip
              id="edit-button"
              title={`${t('PlanningScreen.optimizeModal.changeDate')}`}
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 0 }}
            >
              <ListItemIcon className={clsx(classes.icon, classes.editIcon)} onClick={handleEndDateEditClick}>
                <Edit />
              </ListItemIcon>
            </Tooltip>
          </div>
        </div>
      )}
      {isDefined(capacity) && (
        <ListItemText id={capacity?.toString()} primary={capacity} className={classes.text} />
      )}
      {startDate && endDate && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div onClick={(e) => { e.stopPropagation() }}>
          <VehicleAvailabilityDatePicker
            startDate={startDate}
            endDate={endDate}
            value={startDate}
            open={isStartDatePickerOpen}
            onClose={toggleIsStartDatePickerOpen}
            onChange={handleStartDatePickerChange}
          />
          <VehicleAvailabilityDatePicker
            startDate={startDate}
            endDate={endDate}
            value={endDate}
            open={isEndDatePickerOpen}
            onClose={toggleIsEndDatePickerOpen}
            onChange={handleEndDatePickerChange}
          />
        </div>
      )}
    </ListItem>
  )
}

export default CustomListItem
