import React from 'react'
import { Legend } from '@devexpress/dx-react-chart-material-ui'

import useStyles from './styles'

const CustomLegend = ({
  total,
  label,
  ...props
}: Legend.RootProps & { total: number; label: string }): JSX.Element => {
  const classes = useStyles()

  return (
    <div data-cy="legend" className={classes.legendContainer}>
      <Legend.Root {...props} className={classes.legend} />
      <Legend.Label className={classes.totalLabel} text={`${label}: ${total}`} />
    </div>
  )
}

export default CustomLegend
