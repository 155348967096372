import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField } from '@material-ui/core'

import { IWarehouse } from 'interfaces/interfaces'
import { SITE_TOUR_TYPE_OPTIONS, SITE_TYPE_OPTIONS } from 'constants/constants'
import ActiveSwitch from 'components/Inputs/ToggleSwitch'
import SearchableSelect from 'components/Inputs/SearchableSelect'
import useStyles from 'constants/cruStyles'
import { alphabeticSort } from 'utils/functions'
import Card from 'components/Card/Card'

interface ITextChange {
  target: {
    value: string
    name: string
    type?: string
    checked?: boolean
  }
}

interface IProps {
  site: Partial<IWarehouse>
  onProfilChange: Function
}

const SiteProfilCard = ({ site, onProfilChange }: IProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [state, setState] = useState<Partial<IWarehouse>>({
    name: site?.name,
    code: site?.code,
    type: site?.type,
    tourType: site?.tourType,
    active: site?.active,
    phone: site?.phone,
    customerServicePhone: site?.customerServicePhone,
    accessInstructions: site?.accessInstructions,
    autoCreatePlanVisits: site?.autoCreatePlanVisits,
  })

  const handleInputChange = (event: ITextChange): void => {
    const { target } = event
    const { name } = target
    const value = target.type === 'checkbox' ? target.checked : target.value

    onProfilChange({ ...state, [name]: value })
    setState({ ...state, [name]: value })
  }

  const handleSelectChange = (
    event: React.ChangeEvent<{ value: unknown; name?: string | undefined }>,
  ): void => {
    const { target } = event
    const name = target.name as string
    const value = target.value as string

    onProfilChange({ ...state, [name]: value !== '-' ? value : null })
    setState({ ...state, [name]: value !== '-' ? value : null })
  }

  return (
    <Card dataCy="profilCard" title={t('SitesScreen.profil').toUpperCase()}>
      <>
        <div className={classes.fieldsContainer}>
          <TextField
            data-cy="label"
            label={t('SitesScreen.label').toUpperCase()}
            error={state.name === ''}
            type="text"
            onChange={handleInputChange}
            required
            value={state.name || ''}
            name="name"
          />
          <TextField
            data-cy="code"
            error={state.code === ''}
            label={t('SitesScreen.code').toUpperCase()}
            onChange={handleInputChange}
            type="text"
            required
            name="code"
            value={state.code || ''}
          />
          <TextField
            data-cy="phoneNumber"
            label={t('SitesScreen.phone').toUpperCase()}
            onChange={handleInputChange}
            type="text"
            name="phone"
            value={state.phone || ''}
          />
          <TextField
            data-cy="customerServicePhone"
            label={t('SitesScreen.customerServicePhone').toUpperCase()}
            onChange={handleInputChange}
            type="text"
            name="customerServicePhone"
            value={state.customerServicePhone || ''}
          />
          <TextField
            data-cy="accessInstructions"
            label={t('SitesScreen.accessInstructions').toUpperCase()}
            onChange={handleInputChange}
            type="text"
            name="accessInstructions"
            value={state.accessInstructions || ''}
            multiline
          />
          <SearchableSelect
            dataCy="typeSelect"
            label={t('SitesScreen.function')}
            name="type"
            required
            selectedValue={state.type !== undefined ? state.type : ''}
            options={SITE_TYPE_OPTIONS?.map((siteType) => ({
              id: siteType.value,
              name: siteType.label,
            })).sort(alphabeticSort)}
            onChange={handleSelectChange}
          />
          <SearchableSelect
            dataCy="tourTypeSelect"
            label={t('SitesScreen.typeOfTour')}
            name="tourType"
            selectedValue={
              state.tourType !== undefined && state.tourType !== null ? state.tourType : '-'
            }
            options={SITE_TOUR_TYPE_OPTIONS?.map((siteTourType) => ({
              id: siteTourType.value,
              name: siteTourType.label,
            })).sort(alphabeticSort)}
            onChange={handleSelectChange}
          />
          <ActiveSwitch
            dataCy="autoCreatePlanVisits"
            onChange={handleInputChange}
            name="autoCreatePlanVisits"
            checked={state.autoCreatePlanVisits}
            label={t('SitesScreen.autoCreatePlanVisits').toUpperCase()}
            justifyLeft
          />
        </div>
        <ActiveSwitch
          dataCy="active"
          onChange={handleInputChange}
          name="active"
          checked={state.active}
          label={t('SitesScreen.active').toUpperCase()}
        />
      </>
    </Card>
  )
}

export default SiteProfilCard
