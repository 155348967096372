import React, { useState } from 'react'

import OrdersTable from 'components/Table/OrdersTable'
import { COLUMNS_ORDERS } from 'constants/table'
import { IOrder } from 'interfaces/IOrders'
import UploadModal from 'screens/OrderCategory/List/UploadModal'
import { OrdersConsumer } from 'store/OrdersContext'
import { FilterKey } from 'constants/filters'
import { ROUTES_PATH } from 'navigation/RoutesPath'

interface IOrdersProps {
  orders: IOrder[]
}

const OrdersScreen = ({ orders }: IOrdersProps): JSX.Element => {
  const [isUploadModalOpened, setUploadModalOpened] = useState<boolean>(false)

  const onPressImport = (): void => {
    setUploadModalOpened(true)
  }

  return (
    <>
      <OrdersTable
        showExtendedDateFilter
        extendedDateFilter={{
          last7days: true,
          today: true,
          tomorrow: true,
          thisWeek: true,
          next7days: true,
        }}
        detailsUrl={ROUTES_PATH.orderDetails}
        columns={COLUMNS_ORDERS}
        data={orders}
        onPressImport={onPressImport}
        columnConfigName="orderList"
        filterKey={FilterKey.orders}
        canAddOrders
      />
      <UploadModal visible={isUploadModalOpened} onClose={() => setUploadModalOpened(false)} />
    </>
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <OrdersConsumer>
    {(ctx): JSX.Element => <OrdersScreen orders={ctx.orders} {...props} />}
  </OrdersConsumer>
)
