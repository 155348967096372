import { ICreateVisits } from 'api/plans/plans'
import { IError } from 'api/types'
import {
  ErrorType,
  IImportJob,
  IJobImportError,
  ImportJobStatus,
  ImportJobType,
} from 'interfaces/IImportJob'
import { IJob } from 'interfaces/IJob'
import Api from 'services/api'

interface IOrderJobImportFilter {
  offset?: number
  rowsPerPage?: number
  statuses?: ImportJobStatus[]
}

const getOrdersJobImportHistory = async (
  filters?: IOrderJobImportFilter,
): Promise<{ jobs: Array<IImportJob>; count: number } | IError> => {
  try {
    const response = await Api.get('/import-jobs', {
      offset: filters?.offset,
      limit: filters?.rowsPerPage,
      sortBy: 'createdDate',
      sortDirection: -1,
      dataTypes: [ImportJobType.Order],
      statuses: filters?.statuses,
    })
    return {
      jobs: response.items.map((item: IImportJob) => ({
        ...item,
        nbOfFailedActions: item.nbOfActions - item.nbOfActionsSuccess || 0,
        user: `${item.userFirstName || ''} ${item.userLastName || ''}`,
      })),
      count: response.count,
    }
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getDownloadUrl = async (jobId: string): Promise<string | IError> => {
  try {
    const response = await Api.get(`/import-jobs/${jobId}/download-url`)
    return response.downloadUrl
  } catch (error) {
    return { error: { message: error.message } }
  }
}

interface IJobImportErrorFilter {
  offset?: number
  rowsPerPage?: number
}

const getErrors = async (
  importJobId: string,
  filters?: IJobImportErrorFilter,
): Promise<{ errors: Array<IJobImportError>; count: number } | IError> => {
  try {
    const response = await Api.get(`/import-actions`, {
      importJobId,
      offset: filters?.offset,
      limit: filters?.rowsPerPage,
    })
    return {
      errors: response.items.map((item) => ({
        action: item.actionType,
        validationErrors: item.actionErrors
          .filter((error) => error.errorType === ErrorType.DataValidation)
          .map(({ errorMessage }) => errorMessage)
          .join(', '),
        executionErrors: item.actionErrors
          .filter((error) => error.errorType === ErrorType.Execution)
          .map(({ errorMessage }) => JSON.parse(errorMessage).originalMessage)
          .join(', '),
      })),
      count: response.count,
    }
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const getJobStatus = async (jobId: string): Promise<IJob | IError> => {
  try {
    const response = await Api.get(`/jobs/${jobId}/status`)
    return response
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const createVisitsGenerationJob = async ({
  planId,
  startDate,
  endDate,
  deliveryTypes,
  transportTypes,
  warehouseIds,
}: ICreateVisits): Promise<string | IError> => {
  try {
    const res = await Api.post('jobs/visits-generation', {
      planId,
      startDate,
      endDate,
      deliveryTypes,
      transportTypes,
      warehouseIds,
    })
    return res
  } catch (error) {
    return { error: { message: error.message, fieldErrors: error.fieldErrors } }
  }
}

export default {
  getOrdersJobImportHistory,
  getDownloadUrl,
  getErrors,
  getJobStatus,
  createVisitsGenerationJob,
}
