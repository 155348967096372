import React, { useEffect, useMemo, useState } from 'react'
import { Grid, Tooltip } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Check from '@material-ui/icons/Check'
import AccessTime from '@material-ui/icons/AccessTime'
import Close from '@material-ui/icons/Close'
import { Marker } from 'react-leaflet'
import { LatLngBounds, latLngBounds } from 'leaflet'

import ImageLine from 'components/ImageLine/ImageLine'
import { isDefined } from 'utils/functions'
import Map from 'components/Map'
import { LatLng } from 'interfaces/map'
import { IDriverEventLocation } from 'interfaces/IDriverEventLocation'
import DriverEventMarker from 'components/Map/DriverEventMarker'
import useStyles from './styles'

type PODContentProps = {
  signatureUrl: string
  driverComment: string
  imagesUrls: string[]
  clientCode: string
  clientCodeValidated?: boolean
  location?: LatLng
  driverEventLocations?: IDriverEventLocation[]
}

export default ({
  signatureUrl,
  driverComment,
  imagesUrls,
  clientCode,
  clientCodeValidated,
  location,
  driverEventLocations,
}: PODContentProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [mapBounds, setMapBounds] = useState<LatLngBounds>(latLngBounds([]))

  useEffect(() => {
    const markers = L.featureGroup()
    if (location) {
      L.marker([location.latitude, location.longitude]).addTo(markers)
    }
    driverEventLocations?.forEach((driverEventLocation) => {
      L.marker([
        driverEventLocation.location.latitude,
        driverEventLocation.location.longitude,
      ]).addTo(markers)
    })
    setMapBounds(markers.getBounds())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const codeClientIconToDisplay = useMemo(
    () => {
      if (!isDefined(clientCodeValidated)) {
        return (
          <Tooltip title={`${t('OrdersScreen.clientCodePending')}`}>
            <AccessTime className={classes.clientCodeIcon} />
          </Tooltip>
        )
      }
      if (clientCodeValidated) {
        return (
          <Tooltip title={`${t('OrdersScreen.clientCodeMatching')}`}>
            <Check className={classes.clientCodeIcon} htmlColor="green" />
          </Tooltip>
        )
      }

      return (
        <Tooltip title={`${t('OrdersScreen.clientCodeNotMatching')}`}>
          <Close className={classes.clientCodeIcon} htmlColor="red" />
        </Tooltip>
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clientCodeValidated, classes],
  )

  return (
    <>
      <Grid container className={classes.cardLine} direction="column" data-cy="row">
        <Grid item className={classes.cardLineLabel}>
          {t('tablesEntries.details.signature').toUpperCase()}
        </Grid>
        {signatureUrl && (
          <ImageLine link={signatureUrl} label="signature" alt="proof of delivery" />
        )}
      </Grid>
      <Grid container className={classes.cardLine} direction="column" data-cy="row">
        <Grid item className={classes.cardLineLabel}>
          {t('tablesEntries.images').toUpperCase()}
        </Grid>
        {imagesUrls.map((imageLink, index) => (
          <ImageLine link={imageLink} label={`image${index + 1}`} alt="proof of delivery" />
        ))}
      </Grid>
      <Grid container className={classes.cardLine} direction="column" data-cy="row">
        <Grid item className={classes.cardLineLabel}>
          {t('tablesEntries.driverComment').toUpperCase()}
        </Grid>
        {driverComment && (
          <Grid container className={classes.stringValueLine}>
            {driverComment}
          </Grid>
        )}
      </Grid>
      <Grid container className={classes.cardLine} direction="column" data-cy="row">
        <Grid item className={classes.cardLineLabel}>
          {t('tablesEntries.details.clientCode').toUpperCase()}
        </Grid>
        {clientCode && (
          <Grid container className={classes.stringValueLine}>
            {codeClientIconToDisplay}
            <Grid item>{clientCode}</Grid>
          </Grid>
        )}
      </Grid>
      <div className={classes.mapContainer}>
        <Map
          mapName="pod"
          className={classes.map}
          bounds={mapBounds.isValid() ? mapBounds : undefined}
        >
          {location && <Marker position={{ lat: location.latitude, lng: location.longitude }} />}
          {driverEventLocations?.map((driverEventLocation, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <DriverEventMarker key={index} driverEventLocation={driverEventLocation} />
          ))}
        </Map>
      </div>
    </>
  )
}
