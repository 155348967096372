import React from 'react'
import { Card, CardContent, CardHeader } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { IOrderTimeSlot, ISelectedTimeSlot } from 'interfaces/IWarehouseTimeSlot'
import TimeSlotDropdown from './TimeSlotDropdown'
import useStyles from './styles'

interface IProps {
  orderTimeSlots?: IOrderTimeSlot[]
  selectedTimeSlot?: ISelectedTimeSlot
  setSelectedTimeSlot?: React.Dispatch<React.SetStateAction<ISelectedTimeSlot | undefined>>
  customClass?: string
  customListClass?: string
  customListItemClass?: string
  areDropdownsInitiallyOpen?: boolean
}

const TimeSlots = ({
  orderTimeSlots,
  selectedTimeSlot,
  setSelectedTimeSlot,
  customClass,
  customListClass,
  customListItemClass,
  areDropdownsInitiallyOpen = false,
}: IProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Card className={customClass || classes.mainContainer}>
      <CardHeader
        title={t('OrdersScreen.booking.title')}
        className={classes.header}
        titleTypographyProps={{
          variant: 'subtitle1',
          style: {
            fontWeight: 'bolder',
          },
        }}
      />
      <CardContent className={classes.content}>
        {orderTimeSlots
          ? orderTimeSlots?.map((timeslot) => (
            <TimeSlotDropdown
              key={timeslot.day}
              data={timeslot}
              selectedTimeSlot={selectedTimeSlot}
              setSelectedTimeSlot={setSelectedTimeSlot}
              customListClass={customListClass}
              customListItemClass={customListItemClass}
              isInitiallyOpen={areDropdownsInitiallyOpen}
            />
          ))
          : ''}
      </CardContent>
    </Card>
  )
}

export default TimeSlots
