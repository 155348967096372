import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

interface IProps {
  open: boolean
  onSave: (startDate: string) => void
  onClose: () => void
  defaultValue: string
}

const DateSelectModal = ({ onSave, open, onClose, defaultValue }: IProps): JSX.Element => {
  const [startDate, setStartDate] = useState<string>(defaultValue)
  const { t } = useTranslation()

  const handleSave = () => {
    if (moment(startDate).isValid()) {
      onSave(startDate)
    }
  }

  return (
    <Dialog
      aria-describedby="simple-modal-description"
      onClose={onClose}
      aria-labelledby="date-dialog"
      open={open}
    >
      <DialogTitle data-cy="modal-description" id="date-dialog">
        {t('PlanningScreen.editDepartureDate')}
      </DialogTitle>
      <DialogContent>
        <KeyboardDateTimePicker
          label={t('executionScreen.startDate')}
          inputVariant="outlined"
          format="DD/MM/YYYY HH:mm"
          ampm={false}
          value={startDate}
          onChange={(date: MaterialUiPickersDate): void => setStartDate(moment(date).toISOString())}
          data-cy="startPicker"
        />
      </DialogContent>
      <DialogActions>
        <Button data-cy="saveButton" onClick={handleSave} color="primary">
          {t('PlanningScreen.validate')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DateSelectModal
