import Api from 'services/api'
import { IError } from 'api/types'
import { IOptimizerApp } from 'interfaces/IOptimizerApp'

const get = async (): Promise<IOptimizerApp[] | IError> => {
  try {
    const response = await Api.get(`optimizer-app`)
    return response
  } catch (error) {
    return { error: { message: error.message } }
  }
}

export default {
  get,
}
