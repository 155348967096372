import React, { useState, useContext } from 'react'
import { Typography, Menu, MenuItem, Button } from '@material-ui/core'
import AccountCircle from '@material-ui/icons/AccountCircle'
import { useTranslation } from 'react-i18next'

import { AuthContext } from 'store/AuthContext'
import { FiltersContext } from 'store/FiltersContext'
import { ThemeContext } from 'store/ThemeContext'
import { useHistory } from 'react-router-dom'
import { ROUTES_PATH } from 'navigation/RoutesPath'
import { Role } from 'constants/constants'

const AccountButton = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { user, logoutUser } = useContext(AuthContext)
  const { resetAllFilters } = useContext(FiltersContext)
  const { t } = useTranslation()
  const { toggleDarkMode, darkMode } = useContext(ThemeContext)
  const { push: navigateTo } = useHistory()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const onClickLogout = (): void => {
    if (logoutUser) {
      logoutUser()
      resetAllFilters()
    }
    handleClose()
  }

  const onClickChangeTheme = (): void => {
    toggleDarkMode()
  }

  const onClickNavigateToApiKeysPage = (): void => {
    navigateTo(ROUTES_PATH.apiKeysList)
    handleClose()
  }

  return (
    <>
      <Button
        style={{ display: 'flex', alignItems: 'center', color: '#FFF' }}
        onClick={handleClick}
        data-cy="accountButton"
      >
        <AccountCircle style={{ marginRight: 8 }} />
        <Typography>{user?.firstName}</Typography>
      </Button>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        variant="selectedMenu"
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
      >
        <MenuItem onClick={onClickChangeTheme} data-cy="theme-change">
          {darkMode ? t('menu.lightMode') : t('menu.darkMode')}
        </MenuItem>
        {user?.roles.some((role) =>
          [Role.SuperAdmin, Role.Pilot, Role.Store].includes(role as Role),
        ) ? (
          <MenuItem onClick={onClickNavigateToApiKeysPage} data-cy="api-keys">
            {t('menu.apiKeys')}
          </MenuItem>
          ) : null}
        <MenuItem onClick={onClickLogout} data-cy="logout">
          {`${t('menu.logout')} (${user?.login})`}
        </MenuItem>
      </Menu>
    </>
  )
}

export default AccountButton
