import React from 'react'
import { Card } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import { IStep } from 'interfaces/IStep'
import useStyles from './styles'

interface IProps {
  step: IStep
  onClick: () => void
}

const Step = ({ step, onClick }: IProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { title, Icon, isActive, isCompleted } = step

  const statusClass = isActive
    ? classes.activeStatus
    : isCompleted
      ? classes.completedStatus
      : classes.notCompletedStatus

  return (
    <Card
      onClick={onClick}
      className={clsx(
        classes.container,
        isActive ? classes.active : isCompleted ? classes.completed : classes.notCompleted,
      )}
    >
      <span className={classes.title}>{title}</span>
      <div className={classes.statusContainer}>
        <Icon className={clsx(classes.icon, statusClass)} />
        <div className={clsx(classes.status, statusClass)}>
          {isCompleted ? t('Stepper.completed') : t('Stepper.toBeCompleted')}
        </div>
      </div>
    </Card>
  )
}

export default Step
