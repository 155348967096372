import React, { useContext } from 'react'

import { ORDER_REPORTING_MENU } from 'constants/constants'
import { AuthContext } from 'store/AuthContext'
import VerticalTab from 'components/Tab/VerticalTab'
import { shouldShowTab } from 'utils/tabFeatureUtils'

const OrderReportingScreen = (): JSX.Element => {
  const { features } = useContext(AuthContext)
  return (
    <VerticalTab
      tabs={ORDER_REPORTING_MENU.filter((orderTab) => shouldShowTab(orderTab, features))}
    />
  )
}

export default OrderReportingScreen
