import Api from 'services/api'
import { ILogisticShift } from 'interfaces/ILogisticShift'
import { IError } from 'api/types'
import { getDateStringFromTime, getTimeFromDateString } from 'utils/dateFormat'

const getLogisticShifts = async (): Promise<ILogisticShift[] | IError> => {
  try {
    const response = await Api.get('/tenants/logisticsShift')
    return response.map((shift) => ({
      id: Math.random(),
      begin: getDateStringFromTime(shift.begin),
      end: getDateStringFromTime(shift.end),
    }))
  } catch (error) {
    return { error: { message: error.message } }
  }
}

const updateLogisticShifts = async (
  logisticShifts: ILogisticShift[],
): Promise<ILogisticShift[] | IError> => {
  try {
    const response = await Api.patch('/tenants/logisticsShift', {
      logisticsShift: logisticShifts.map((shift) => ({
        id: undefined,
        begin: getTimeFromDateString(shift.begin),
        end: getTimeFromDateString(shift.end),
      })),
    })
    return response
  } catch (error) {
    return { error: { message: error.message } }
  }
}

export default {
  getLogisticShifts,
  updateLogisticShifts,
}
