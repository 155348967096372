import { MENU_SUBCATEGORIES, MENU_CATEGORIES, USER_ROLES } from 'constants/constants'
import UsersScreen from 'screens/SettingsCategory/Users/List/UsersScreen'
import UserFormScreen from 'screens/SettingsCategory/Users/Edition/UserFormScreen'
import UsersDetailsScreen from 'screens/SettingsCategory/Users/Details/UsersDetailsScreen'
import TenantsListScreen from 'screens/SettingsCategory/Tenants/TenantsListScreen'
import TenantDetailsScreen from 'screens/SettingsCategory/Tenants/TenantDetailsScreen'
import TenantFormScreen from 'screens/SettingsCategory/Tenants/TenantFormScreen'
import FeatureFlagsScreen from 'screens/SettingsCategory/FeatureFlags'
import WebhooksTable from 'screens/SettingsCategory/Webhooks/WebhooksTable'
import ConfigurationsScreen from 'screens/SettingsCategory/Configurations/ConfigurationsScreen'
import WebhookForm from 'screens/SettingsCategory/Webhooks/WebhookForm'
import WarehousesAdminListScreen from 'screens/SettingsCategory/WarehouseAdmin/WarehousesAdminListScreen'
import WarehouseAdminDetailsScreen from 'screens/SettingsCategory/WarehouseAdmin/WarehouseAdminDetailsScreen'
import WarehouseAdminFormScreen from 'screens/SettingsCategory/WarehouseAdmin/WarehouseAdminFormScreen'
import NotifierConfigListScreen from 'screens/SettingsCategory/NotifierConfig/NotifierConfigListScreen'
import NotifierConfigDetailsScreen from 'screens/SettingsCategory/NotifierConfig/NotifierConfigDetailsScreen'
import NotifierConfigFormScreen from 'screens/SettingsCategory/NotifierConfig/NotifierConfigFormScreen'
import WarehouseTimeSlotListScreen from 'screens/SettingsCategory/WarehouseTimeSlot/WarehouseTimeSlotListScreen'
import WarehouseTimeSlotDetailsScreen from 'screens/SettingsCategory/WarehouseTimeSlot/WarehouseTimeSlotDetailsScreen'
import WarehouseTimeSlotScreen from 'screens/SettingsCategory/WarehouseTimeSlot/WarehouseTimeSlotScreen'
import SiteConfigurationsScreen from 'screens/SettingsCategory/SiteConfigurations/SiteConfigurationsScreen'
import { ROUTES_PATH } from './RoutesPath'

const SETTING_CATEGORY = [
  {
    title: MENU_SUBCATEGORIES.user,
    path: ROUTES_PATH.usersList,
    component: UsersScreen,
    isPrivate: true,
    isInMenu: true,
    category: MENU_CATEGORIES.setting,
    roles: [USER_ROLES.superAdmin, USER_ROLES.pilot, USER_ROLES.udcAdmin],
  },
  {
    title: MENU_SUBCATEGORIES.userEdition,
    path: `${ROUTES_PATH.editUser}:id`,
    component: UserFormScreen,
    isPrivate: true,
    isInMenu: false,
    category: MENU_CATEGORIES.setting,
    roles: [USER_ROLES.superAdmin, USER_ROLES.pilot, USER_ROLES.udcAdmin],
  },
  {
    title: MENU_SUBCATEGORIES.userDetails,
    path: `${ROUTES_PATH.userDetails}:id`,
    component: UsersDetailsScreen,
    isPrivate: true,
    isInMenu: false,
    category: MENU_CATEGORIES.setting,
    roles: [USER_ROLES.superAdmin, USER_ROLES.pilot, USER_ROLES.udcAdmin],
  },
  {
    title: MENU_SUBCATEGORIES.userEdition,
    path: ROUTES_PATH.createUser,
    component: UserFormScreen,
    isPrivate: true,
    isInMenu: false,
    category: MENU_CATEGORIES.setting,
    roles: [USER_ROLES.superAdmin, USER_ROLES.pilot, USER_ROLES.udcAdmin],
  },
  {
    title: MENU_SUBCATEGORIES.tenants,
    path: ROUTES_PATH.tenantsList,
    component: TenantsListScreen,
    isPrivate: true,
    isInMenu: true,
    category: MENU_CATEGORIES.setting,
    roles: [USER_ROLES.superAdmin],
  },
  {
    title: MENU_SUBCATEGORIES.tenants,
    path: `${ROUTES_PATH.tenantDetails}:id`,
    component: TenantDetailsScreen,
    isPrivate: true,
    isInMenu: false,
    category: MENU_CATEGORIES.setting,
    roles: [USER_ROLES.superAdmin],
  },
  {
    title: MENU_SUBCATEGORIES.tenants,
    path: `${ROUTES_PATH.editTenant}:id`,
    component: TenantFormScreen,
    isPrivate: true,
    isInMenu: false,
    category: MENU_CATEGORIES.setting,
    roles: [USER_ROLES.superAdmin],
  },
  {
    title: MENU_SUBCATEGORIES.tenants,
    path: ROUTES_PATH.createTenant,
    component: TenantFormScreen,
    isPrivate: true,
    isInMenu: false,
    category: MENU_CATEGORIES.setting,
    roles: [USER_ROLES.superAdmin],
  },
  {
    title: MENU_SUBCATEGORIES.featureFlag,
    path: ROUTES_PATH.manageFeature,
    component: FeatureFlagsScreen,
    isPrivate: true,
    isInMenu: true,
    isBetaMode: true,
    description: 'Liste des features disponibles en mode beta ( Menu Settings/Feature flag )',
    category: MENU_CATEGORIES.setting,
    roles: [USER_ROLES.superAdmin, USER_ROLES.pilot],
  },
  {
    title: MENU_SUBCATEGORIES.configurations,
    path: ROUTES_PATH.configurations,
    component: ConfigurationsScreen,
    isPrivate: true,
    isInMenu: true,
    category: MENU_CATEGORIES.setting,
    roles: [USER_ROLES.pilot, USER_ROLES.superAdmin],
  },
  {
    title: MENU_SUBCATEGORIES.siteConfigurations,
    path: ROUTES_PATH.siteConfigurations,
    component: SiteConfigurationsScreen,
    isPrivate: true,
    isInMenu: true,
    category: MENU_CATEGORIES.setting,
    roles: [USER_ROLES.pilot, USER_ROLES.superAdmin, USER_ROLES.store],
  },
  {
    title: MENU_SUBCATEGORIES.webhooks,
    path: ROUTES_PATH.webhooksList,
    component: WebhooksTable,
    isPrivate: true,
    isInMenu: true,
    description: 'Webhooks',
    category: MENU_CATEGORIES.setting,
    roles: [USER_ROLES.superAdmin],
  },
  {
    title: MENU_SUBCATEGORIES.webhooks,
    path: ROUTES_PATH.createWebhook,
    component: WebhookForm,
    isPrivate: true,
    isInMenu: false,
    hideInFeaturesList: true,
    category: MENU_CATEGORIES.setting,
    roles: [USER_ROLES.superAdmin],
  },
  {
    title: MENU_SUBCATEGORIES.webhooks,
    path: `${ROUTES_PATH.editWebhook}:id`,
    component: WebhookForm,
    isPrivate: true,
    isInMenu: false,
    hideInFeaturesList: true,
    category: MENU_CATEGORIES.setting,
    roles: [USER_ROLES.superAdmin],
  },
  {
    title: MENU_SUBCATEGORIES.warehouseAdmin,
    path: ROUTES_PATH.warehouseAdminList,
    component: WarehousesAdminListScreen,
    isPrivate: true,
    isInMenu: true,
    category: MENU_CATEGORIES.setting,
    roles: [USER_ROLES.superAdmin],
  },
  {
    title: MENU_SUBCATEGORIES.warehouseAdmin,
    path: `${ROUTES_PATH.warehouseAdminDetails}:id`,
    component: WarehouseAdminDetailsScreen,
    isPrivate: true,
    isInMenu: false,
    category: MENU_CATEGORIES.setting,
    roles: [USER_ROLES.superAdmin],
  },
  {
    title: MENU_SUBCATEGORIES.warehouseAdmin,
    path: `${ROUTES_PATH.editWarehouseAdmin}:id`,
    component: WarehouseAdminFormScreen,
    isPrivate: true,
    isInMenu: false,
    category: MENU_CATEGORIES.setting,
    roles: [USER_ROLES.superAdmin],
  },
  {
    title: MENU_SUBCATEGORIES.notifierConfig,
    path: ROUTES_PATH.notifierConfigList,
    component: NotifierConfigListScreen,
    isPrivate: true,
    isInMenu: true,
    category: MENU_CATEGORIES.setting,
    roles: [USER_ROLES.superAdmin, USER_ROLES.pilot],
  },
  {
    title: MENU_SUBCATEGORIES.notifierConfig,
    path: `${ROUTES_PATH.notifierConfigDetails}:id`,
    component: NotifierConfigDetailsScreen,
    isPrivate: true,
    isInMenu: false,
    category: MENU_CATEGORIES.setting,
    roles: [USER_ROLES.superAdmin, USER_ROLES.pilot],
  },
  {
    title: MENU_SUBCATEGORIES.notifierConfig,
    path: `${ROUTES_PATH.editNotifierConfig}:id`,
    component: NotifierConfigFormScreen,
    isPrivate: true,
    isInMenu: false,
    category: MENU_CATEGORIES.setting,
    roles: [USER_ROLES.superAdmin, USER_ROLES.pilot],
  },
  {
    title: MENU_SUBCATEGORIES.notifierConfig,
    path: ROUTES_PATH.createNotifierConfig,
    component: NotifierConfigFormScreen,
    isPrivate: true,
    isInMenu: false,
    category: MENU_CATEGORIES.setting,
    roles: [USER_ROLES.superAdmin, USER_ROLES.pilot],
  },
  {
    title: MENU_SUBCATEGORIES.warehouseTimeSlot,
    path: ROUTES_PATH.warehouseTimeSlotList,
    component: WarehouseTimeSlotListScreen,
    isPrivate: true,
    isInMenu: true,
    isBetaMode: true,
    description:
      'Accès aux écrans CRU créneaux horaires des sites (Menu Paramètres/ Sites créneaux)',
    category: MENU_CATEGORIES.setting,
    roles: [USER_ROLES.superAdmin, USER_ROLES.pilot],
  },
  {
    title: MENU_SUBCATEGORIES.warehouseTimeSlot,
    path: `${ROUTES_PATH.warehouseTimeSlotDetails}:id`,
    component: WarehouseTimeSlotDetailsScreen,
    isPrivate: true,
    isInMenu: false,
    isBetaMode: true,
    description: 'Accès aux détails des créneaux horaires',
    category: MENU_CATEGORIES.setting,
    roles: [USER_ROLES.superAdmin, USER_ROLES.pilot],
  },
  {
    title: MENU_SUBCATEGORIES.warehouseTimeSlot,
    path: `${ROUTES_PATH.editWarehouseTimeSlot}:id`,
    component: WarehouseTimeSlotScreen,
    isPrivate: true,
    isInMenu: false,
    isBetaMode: true,
    description: 'Accès à la modification de créneaux horaires',
    category: MENU_CATEGORIES.setting,
    roles: [USER_ROLES.superAdmin, USER_ROLES.pilot],
  },
  {
    title: MENU_SUBCATEGORIES.warehouseTimeSlot,
    path: ROUTES_PATH.createWarehouseTimeSlot,
    component: WarehouseTimeSlotScreen,
    isPrivate: true,
    isInMenu: false,
    isBetaMode: true,
    description: 'Accès à la création de créneaux horaires des sites',
    category: MENU_CATEGORIES.setting,
    roles: [USER_ROLES.superAdmin, USER_ROLES.pilot],
  },
]

export default SETTING_CATEGORY
