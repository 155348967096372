import React, { useState, useContext } from 'react'
import { LatLng } from 'leaflet'
import { useTranslation } from 'react-i18next'
import { Divider, TextField, Typography } from '@material-ui/core'
import { Marker } from 'react-leaflet'
import { useDebouncedCallback } from 'use-debounce'

import { IGeoResult } from 'interfaces/IGeoResult'
import { IUserData } from 'interfaces/interfaces'
import AddressApi from 'api/address'
import { isIError } from 'api/types'
import Button from 'components/Button/CustomButton'
import Map from 'components/Map'
import ScoreContainer from 'components/Map/ScoreContainer'
import { AuthConsumer } from 'store/AuthContext'
import { IAddress } from 'interfaces/Itours'
import { FeedbackContext } from 'store/FeedbackContext'

import useStyles from './styles'

interface AddressMapProps {
  address?: IAddress
  onAddressChange?: Function
  isCreateMode: boolean
  center?: Partial<LatLng>
  fullAddress?: string
  isCoordinateReadOnly?: boolean
  warehouseId?: string
  mapName: string
}

interface ITextChange {
  target: {
    value: string
    name: string
    type?: string
    checked?: boolean
  }
}

interface IDragEnd {
  target: {
    getLatLng(): LatLng
  }
}

const MapWithAddressForm = ({
  mapName,
  address,
  onAddressChange,
  isCreateMode,
  center,
  fullAddress = '',
  user,
  isCoordinateReadOnly = false,
  warehouseId,
}: AddressMapProps & { user: IUserData }): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { openErrorSnack, toggleLoader } = useContext(FeedbackContext)
  const [score, setScore] = useState<number | null>(null)
  const [currentAddress, setCurrentAddress] = useState<Partial<IAddress>>({
    roadNumber: address?.roadNumber,
    road: address?.road,
    zipCode: address?.zipCode,
    city: address?.city,
    latitude: address?.latitude,
    longitude: address?.longitude,
    relevance: address?.relevance,
    country: address?.country,
  })
  const [markerLocation, setMarkerLocation] = useState<Partial<LatLng> | undefined>(
    center
      || (address?.latitude && address?.longitude
        ? { lat: address.latitude, lng: address.longitude }
        : undefined),
  )
  const [geoChoices, setGeoChoices] = useState<IGeoResult[]>([])

  const onMarkerDragEnd = (event: IDragEnd): void => {
    const newLatLng = event.target.getLatLng()
    const updatedAddress = {
      ...currentAddress,
      latitude: newLatLng.lat,
      longitude: newLatLng.lng,
      relevance: 1,
    }
    setScore(null)
    setMarkerLocation(newLatLng)
    setCurrentAddress(updatedAddress)
    if (onAddressChange) onAddressChange(updatedAddress)
  }

  const debouncedAddressChange = useDebouncedCallback((newAddress) => {
    if (onAddressChange) onAddressChange(newAddress)
  }, 600)

  const handleInputChange = ({ target }: ITextChange): void => {
    const { name, type, checked, value } = target
    const newAddress = { ...currentAddress, [name]: type === 'checkbox' ? checked : value }
    setCurrentAddress(newAddress)
    debouncedAddressChange.callback(newAddress)
  }

  const confirmGeocode = (geocodeResult: IGeoResult): void => {
    const { latitude, longitude } = geocodeResult.position
    const newAddress = { ...geocodeResult.address, latitude, longitude, relevance: 1 }
    setScore(geocodeResult.score)
    setCurrentAddress(newAddress)
    setMarkerLocation({ lat: latitude, lng: longitude })
    if (onAddressChange) onAddressChange(newAddress)
  }

  const handleGeocodeClick = async (): Promise<void> => {
    setGeoChoices([])
    toggleLoader(true)

    const res = await AddressApi.geocode(
      currentAddress.roadNumber,
      currentAddress.road,
      currentAddress.zipCode,
      currentAddress.city,
      currentAddress.country,
      user?.tenantConfig.language,
      warehouseId,
    )

    if (isIError(res)) {
      openErrorSnack(res.error.message)
      setScore(null)
      setGeoChoices([])
      setCurrentAddress((prevAddress) => ({
        ...prevAddress,
        latitude: undefined,
        longitude: undefined,
        relevance: undefined,
      }))
      if (onAddressChange) {
        onAddressChange({
          ...currentAddress,
          latitude: undefined,
          longitude: undefined,
          relevance: undefined,
        })
      }
    } else if (res.length === 1) {
      confirmGeocode(res[0])
      setGeoChoices([])
    } else {
      setGeoChoices(res)
    }
    toggleLoader(false)
  }

  const makeAChoice = (geocodeChoice: IGeoResult): void => {
    confirmGeocode(geocodeChoice)
    setGeoChoices([])
  }

  const GeoChoices = (): JSX.Element => (
    <div className={classes.geocodeResultsContainer}>
      <Typography variant="subtitle1">
        {useTranslation().t('MapComponent.chooseLocation')}
      </Typography>
      {geoChoices.map((geoChoice) => (
        <div
          key={`${geoChoice.position.latitude}-${geoChoice.position.longitude}`}
          role="button"
          tabIndex={0}
          className={classes.geocodeLine}
          onClick={(): void => makeAChoice(geoChoice)}
          onKeyPress={(e): false | void => e.keyCode === 13 && makeAChoice(geoChoice)}
        >
          <div className={classes.geocodeAddress}>{geoChoice.address.label}</div>
          <ScoreContainer score={geoChoice.score} noLabel />
        </div>
      ))}
    </div>
  )

  const severalChoices = geoChoices.length >= 2

  return (
    <>
      <div
        className={classes.addressContainer}
        style={{
          width: severalChoices ? 'unset' : '200px',
          ...(severalChoices && { opacity: '0.8', transform: 'scale(1.0) translate(50px,-104px)' }),
          paddingTop: fullAddress ? '8px' : '4px',
        }}
      >
        <div className={classes.inputs}>
          {fullAddress && (
            <>
              <div>{fullAddress}</div>
              <Divider style={{ marginTop: '8px', backgroundColor: 'grey' }} />
            </>
          )}
          <TextField
            data-cy="roadNumber"
            error={currentAddress.roadNumber === ''}
            label={t('SitesScreen.number').toUpperCase()}
            onChange={handleInputChange}
            type="text"
            required
            name="roadNumber"
            value={currentAddress.roadNumber || ''}
          />
          <TextField
            data-cy="road"
            error={currentAddress.road === ''}
            label={t('SitesScreen.street').toUpperCase()}
            onChange={handleInputChange}
            type="text"
            required
            name="road"
            value={currentAddress.road || ''}
          />
          <TextField
            data-cy="zipCode"
            error={currentAddress.zipCode === ''}
            label={t('SitesScreen.zipCode').toUpperCase()}
            onChange={handleInputChange}
            type="text"
            name="zipCode"
            value={currentAddress.zipCode || ''}
          />
          <TextField
            data-cy="city"
            error={currentAddress.city === ''}
            label={t('SitesScreen.city').toUpperCase()}
            onChange={handleInputChange}
            type="text"
            required
            name="city"
            value={currentAddress.city || ''}
          />
          <TextField
            data-cy="country"
            label={t('SitesScreen.country').toUpperCase()}
            onChange={handleInputChange}
            type="text"
            name="country"
            value={currentAddress.country || ''}
          />
          <Button
            dataCy="geocodeButton"
            className={classes.geocodeBtn}
            onPress={handleGeocodeClick}
          >
            {t('SitesScreen.geocode')}
          </Button>
          <TextField
            data-cy="latitude"
            label={t('SitesScreen.lat').toUpperCase()}
            disabled={isCoordinateReadOnly}
            onChange={handleInputChange}
            type="number"
            name="latitude"
            value={currentAddress.latitude || ''}
          />
          <TextField
            data-cy="longitude"
            label={t('SitesScreen.long').toUpperCase()}
            disabled={isCoordinateReadOnly}
            onChange={handleInputChange}
            type="number"
            name="longitude"
            value={currentAddress.longitude || ''}
          />
          {isCreateMode && (
            <TextField
              data-cy="relevance"
              label={t('SitesScreen.locationRelevance').toUpperCase()}
              onChange={handleInputChange}
              type="number"
              name="relevance"
              value={currentAddress.relevance ? currentAddress.relevance * 100 : ''}
            />
          )}
          <ScoreContainer score={score} />
        </div>
        {geoChoices.length >= 2 && <GeoChoices />}
      </div>
      <div className={classes.mapContainer}>
        {/*
          // @ts-ignore */}
        <Map
          mapName={mapName}
          key={`${currentAddress.latitude}-${currentAddress.longitude}`}
          className={classes.addressMap}
          {...(markerLocation && { center: markerLocation })}
        >
          {markerLocation && (
            <Marker
              draggable
              position={markerLocation as LatLng}
              eventHandlers={{
                dragend: onMarkerDragEnd,
              }}
            />
          )}
        </Map>
      </div>
    </>
  )
}

export default (props: JSX.IntrinsicAttributes & AddressMapProps): JSX.Element => (
  <AuthConsumer>
    {(authContext): JSX.Element => <MapWithAddressForm user={authContext.user} {...props} />}
  </AuthConsumer>
)
