import React, {
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from 'react'
import useInterval from '@use-it/interval'
import { useTranslation } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce'
import { OptionTypeBase } from 'react-select'

import VehiclesApi from 'api/vehicles'
import { IError, isIError } from 'api/types'
import PlansApi from 'api/plans'
import JobsApi from 'api/jobs'
import { ISelectableMarker, ITourMapItem } from 'interfaces/map'
import { ICluster } from 'interfaces/ICluster'
import { IPlan, IPlanTourDeleteResponse, IPlanVisit } from 'interfaces/IPlan'
import { FeedbackContext } from 'store/FeedbackContext'
import { useExpirableState } from 'utils/useExpirableState'
import COLORS from 'components/Map/LinesColors'
import { AppInsightEvents } from 'constants/constants'
import appInsight from 'services/appInsight'
import {
  ExtraMessageType,
  IJob,
  JobStatus,
  JobType,
  IVisitsGenerationProgress,
} from 'interfaces/IJob'
import CLUSTER_COLORS from 'screens/PlanningCategory/PlanningScreen/components/ClusterColors'

import { IVehicleAvailability } from 'interfaces/IVehicleAvailability'
import { IProduct } from 'interfaces/IOrders'
import { ContentContext } from 'store/ContentContext'
import { AuthContext } from 'store/AuthContext'
import { IOutsourced } from 'interfaces/IOutsourced'
import { IHighlightedElement } from 'interfaces/IHighlightedElement'
import { getNextMidnight, getNextLastMinute, removePastDatesFromVehiclesAvailabilities } from 'utils/planningUtils'

interface IPlanifContext {
  markers: ISelectableMarker[]
  setMarkers: Dispatch<SetStateAction<ISelectableMarker[]>>
  tours: ITourMapItem[]
  setTours: Dispatch<SetStateAction<ITourMapItem[]>>
  selectedTours: string[]
  setSelectedTours: Dispatch<SetStateAction<string[]>>
  plansList: IPlan[]
  setPlansList: Dispatch<SetStateAction<IPlan[]>>
  selectedPlan: IPlan | null
  setSelectedPlan: Dispatch<SetStateAction<IPlan | null>>
  startDate: string
  setStartDate: Dispatch<SetStateAction<string>>
  endDate: string
  setEndDate: Dispatch<SetStateAction<string>>
  deliveryMaxEndDate: string
  setDeliveryMaxEndDate: Dispatch<SetStateAction<string>>
  isActionLoading: boolean
  isVisitsLoading: boolean
  selectedWarehouse: string[]
  setSelectedWarehouse(selection: string[]): void
  selectedDeliveryType: string[]
  setSelectedDeliveryType(selection: string[]): void
  selectedTransportTypes: string[]
  setSelectedTransportTypes(selection: string[]): void
  searchText: string
  setSearchText: Dispatch<SetStateAction<string>>
  getPlansVisits(): Promise<void>
  getPlanTours(planTourId?: string): Promise<void>
  deselectAll(): void
  hiddenTours: string[]
  toggleTourVisibility(id: string): void
  toggleAllToursVisibility(): void
  hideAllTours(shouldHide: boolean): void
  showSelectedTours(toursIds: string[]): void
  backdropMessage: string
  toggleBackdrop(
    message?: string,
    skipActionLoading?: boolean,
    isOptimizingByPilote?: boolean,
  ): void
  planTourStatus: string[]
  setPlanTourStatus(selection: string[]): void
  validateAllTours(): Promise<void>
  invalidateAllTours(): Promise<void>
  interfaceAllTours(): Promise<void>
  deletePlanVisits(): Promise<void>
  deletePlanTours(shouldDeleteTour: boolean): Promise<IPlanTourDeleteResponse | undefined>
  clusters: ICluster[]
  setClusters: Dispatch<SetStateAction<ICluster[]>>
  isClusterMode: boolean
  setClusterMode: Dispatch<SetStateAction<boolean>>
  isOutsourceMode: boolean
  outsourced: IOutsourced | null
  setOutsourced: Dispatch<SetStateAction<IOutsourced | null>>
  setIsOutsourceMode: Dispatch<SetStateAction<boolean>>
  isPlanValidateDisabled: boolean
  isPlanInvalidateDisabled: boolean
  isPlanInterfaceDisabled: boolean
  isCreateVisitsDisabled: boolean
  isPlanTourDeleteDisabled: boolean
  setIsCreateVisitsDisabled: Dispatch<SetStateAction<boolean>>
  setIsDeleteVisitsDisabled: Dispatch<SetStateAction<boolean>>
  isDeleteVisitsDisabled: boolean
  isClusterOptimInError: boolean
  setClusterOptimInError: (isInError: boolean) => void
  areClustersHidden: boolean
  setClustersVisibility: Dispatch<SetStateAction<boolean>>
  isMapotempoActionInProgress: boolean
  setIsMapotempoActionInProgress: Dispatch<SetStateAction<boolean>>
  getOptimizeState: () => Promise<boolean>
  setZipCodes: Dispatch<SetStateAction<OptionTypeBase[]>>
  zipCodes: OptionTypeBase[]
  isProcessing: boolean
  setIsProcessing: Dispatch<SetStateAction<boolean>>
  isPlanTourStatusChangeDone: boolean
  setIsPlanTourStatusChangeDone: Dispatch<SetStateAction<boolean>>
  deletePlanTour: (
    id: string,
    shouldDeleteTour: boolean,
  ) => Promise<IPlanTourDeleteResponse | undefined>
  manualTours: ICluster[]
  setManualTours: Dispatch<SetStateAction<ICluster[]>>
  isManualToursOptimInError: boolean
  setManualToursOptimInError: (isInError: boolean) => void
  isMultisite: boolean
  hasRecoveredClusters: boolean
  setHasRecoveredClusters: Dispatch<SetStateAction<boolean>>
  vehiclesList: IVehicleAvailability[]
  setVehiclesList: Dispatch<SetStateAction<IVehicleAvailability[]>>
  vehiclesListForGlobalOptim: IVehicleAvailability[]
  setVehiclesListForGlobalOptim: Dispatch<SetStateAction<IVehicleAvailability[]>>
  getOrderDetailsByPlanVisitId: (planVisitId: string) => Promise<IProduct[]>
  repairPlan: (id: string) => Promise<void | IError>
  isOptimizingByPilot: boolean
  setJobIds: Dispatch<SetStateAction<string[]>>
  visitsGenerationProgress: IVisitsGenerationProgress | null
  areOutsourcedHidden: boolean
  setOutsourcedVisibility: Dispatch<SetStateAction<boolean>>
  highlightedElement: IHighlightedElement | undefined
  setHighlightedElement: Dispatch<SetStateAction<IHighlightedElement | undefined>>
  isMarkerClicked: boolean
  setIsMarkerClicked: Dispatch<SetStateAction<boolean>>
}

interface ISetMarkersOptions {
  removeVisits?: boolean
  removePlanMarkers?: boolean
}

const PlanificationContext = React.createContext<IPlanifContext>({} as IPlanifContext)
const { Provider, Consumer } = PlanificationContext

interface IProps {
  children: ReactNode
  isMultisite?: boolean
}

function PlanificationProvider({ children, isMultisite = false }: IProps): JSX.Element {
  const { openErrorSnack, toggleLoader } = useContext(FeedbackContext)
  const [startDate, setStartDate] = useState<string>('')
  const [endDate, setEndDate] = useState<string>('')
  const [deliveryMaxEndDate, setDeliveryMaxEndDate] = useState<string>('')
  const [markers, setMarkers] = useState<ISelectableMarker[]>([])
  const [tours, setTours] = useState<ITourMapItem[]>([])
  const [selectedTours, setSelectedTours] = useState<string[]>([])
  const [plansList, setPlansList] = useState<IPlan[]>([])
  const [selectedPlan, setSelectedPlan] = useExpirableState<IPlan | null>(
    isMultisite ? 'SelectedMultisitePlan' : 'SelectedPlan',
    null,
    isMultisite ? 'MultisitePlanIdExpiration' : 'PlanIdExpiration',
  )
  const [isVisitsLoading, setVisitsLoading] = useState<boolean>(false)
  const [isActionLoading, setActionLoading] = useState<boolean>(false)
  const [selectedWarehouse, setSelectedWarehouse] = useState<string[]>([])
  const [selectedDeliveryType, setSelectedDeliveryType] = useState<string[]>([])
  const [selectedTransportTypes, setSelectedTransportTypes] = useState<string[]>([])
  const [searchText, setSearchText] = useState<string>('')
  const [hiddenTours, setHiddenTours] = useState<string[]>([])
  const [backdropMessage, setBackdropMessage] = useState<string>('')
  const [planTourStatus, setPlanTourStatus] = useState<string[]>(['0', '1'])
  const [isClusterMode, setClusterMode] = useState<boolean>(false)
  const [isOutsourceMode, setIsOutsourceMode] = useState<boolean>(false)
  const [isClusterOptimInError, setClusterOptimInError] = useState<boolean>(false)
  const [clusters, setClusters] = useState<ICluster[]>([])
  const [outsourced, setOutsourced] = useState<IOutsourced | null>(null)
  const [isPlanValidateDisabled, setIsPlanValidateDisabled] = useState<boolean>(false)
  const [isPlanInvalidateDisabled, setIsPlanInvalidateDisabled] = useState<boolean>(false)
  const [isPlanInterfaceDisabled, setIsPlanInterfaceDisabled] = useState<boolean>(false)
  const [isCreateVisitsDisabled, setIsCreateVisitsDisabled] = useState<boolean>(false)
  const [isDeleteVisitsDisabled, setIsDeleteVisitsDisabled] = useState<boolean>(false)
  const [isPlanTourDeleteDisabled, setIsPlanTourDeleteDisabled] = useState<boolean>(false)
  const [areClustersHidden, setClustersVisibility] = useState<boolean>(false)
  const [areOutsourcedHidden, setOutsourcedVisibility] = useState<boolean>(false)
  const [isMapotempoActionInProgress, setIsMapotempoActionInProgress] = useState<boolean>(false)
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const [isPlanTourStatusChangeDone, setIsPlanTourStatusChangeDone] = useState<boolean>(true)
  const [zipCodes, setZipCodes] = useState<OptionTypeBase[]>([])
  const [manualTours, setManualTours] = useState<ICluster[]>([])
  const [isManualToursOptimInError, setManualToursOptimInError] = useState<boolean>(false)
  const [hasRecoveredClusters, setHasRecoveredClusters] = useState<boolean>(false)
  const [vehiclesList, setVehiclesList] = useState<IVehicleAvailability[]>([])
  const [vehiclesListForGlobalOptim, setVehiclesListForGlobalOptim] = useState<IVehicleAvailability[]>([])
  const [isOptimizingByPilot, setIsOptimizingByPilot] = useState<boolean>(false)
  const [jobIds, setJobIds] = useState<string[]>([])
  const [visitsGenerationProgress, setVisitsGenerationProgress] = useState<IVisitsGenerationProgress | null>(null)
  const [highlightedElement, setHighlightedElement] = useState<IHighlightedElement>()
  const [isMarkerClicked, setIsMarkerClicked] = useState<boolean>(false)
  const manualToursRef = useRef(manualTours)
  const clustersRef = useRef(clusters)
  const outsourcedRef = useRef(outsourced)
  const isClusterModeRef = useRef(isClusterMode)
  const { sites } = useContext(ContentContext)
  const { shouldFilterPlanToursByDeliveryType, user } = useContext(AuthContext)
  const sitesRef = useRef(sites)
  const isOutsourceModeRef = useRef(isOutsourceMode)

  const getOngoingPlanJobs = async (): Promise<void> => {
    if (selectedPlan) {
      toggleLoader(true)
      const response = await PlansApi.getOngoingPlanJobs(selectedPlan.id)
      if (!isIError(response)) {
        const userOngoingVisitGenerationJobs = response.filter(
          (job) => job.dataType === JobType.VisitsGenerationJob && job.userId === user?.id,
        ) // should be 1 normally
        const optimizationJobs = response.filter((job) => job.dataType === JobType.OptimizationJob) // should be 1 normally
        const ids = [...optimizationJobs, ...userOngoingVisitGenerationJobs].map((job) => job.jobId)
        setJobIds(ids)
      } else {
        openErrorSnack(response.error.message)
      }
      toggleLoader(false)
    }
  }

  useEffect(() => {
    setJobIds([])
    getOngoingPlanJobs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlan])

  useEffect(() => {
    sitesRef.current = sites
  }, [sites])

  useEffect(() => {
    manualToursRef.current = manualTours
  }, [manualTours])

  useEffect(() => {
    clustersRef.current = clusters
  }, [clusters])

  useEffect(() => {
    outsourcedRef.current = outsourced
  }, [outsourced])

  const handleSetMarkers = () => {
    // @ts-ignore
    setMarkers((prevMarkers) =>
      prevMarkers
        .filter((marker) => !marker.isNotCompatibleWithFilters)
        .map((marker) => {
          if (marker.clusterId || marker.isOutsourced) {
            return { ...marker, color: undefined }
          }
          return marker
        }),
    )
  }

  useEffect(() => {
    isClusterModeRef.current = isClusterMode
    if (isClusterMode) {
      const planVisitsInClusters = clusters.reduce(
        (acc: ISelectableMarker[], cluster: ICluster) => [...acc, ...cluster.visits],
        [],
      )
      setMarkers((prevMarkers) => {
        const previousMarkers = [...prevMarkers]
        const markersToAdd: ISelectableMarker[] = []
        planVisitsInClusters.forEach((planVisit) => {
          const correspondingPlanVisitInMarkersIndex = previousMarkers.findIndex(
            (elem) => elem.stopId === planVisit.stopId,
          )
          if (correspondingPlanVisitInMarkersIndex === -1) {
            markersToAdd.push({ ...planVisit, isNotCompatibleWithFilters: true })
          } else {
            previousMarkers[correspondingPlanVisitInMarkersIndex].color = planVisit.color
          }
        })
        return [...previousMarkers, ...markersToAdd]
      })
    } else {
      handleSetMarkers()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClusterMode])

  useEffect(() => {
    isOutsourceModeRef.current = isOutsourceMode
    if (isOutsourceMode) {
      setMarkers((prevMarkers) => {
        const previousMarkers = [...prevMarkers]
        const markersToAdd: ISelectableMarker[] = []
        outsourced?.visits?.forEach((planVisit) => {
          const correspondingPlanVisitInMarkersIndex = previousMarkers.findIndex(
            (elem) => elem.stopId === planVisit.stopId,
          )
          if (correspondingPlanVisitInMarkersIndex === -1) {
            markersToAdd.push({ ...planVisit, isNotCompatibleWithFilters: true })
          } else {
            previousMarkers[correspondingPlanVisitInMarkersIndex].color = planVisit.color
          }
        })
        return [...previousMarkers, ...markersToAdd]
      })
    } else {
      handleSetMarkers()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOutsourceMode])

  const getCurrentPlan = async (): Promise<void> => {
    if (selectedPlan) {
      const currentPlanId = selectedPlan.id
      const response = await PlansApi.getPlan(currentPlanId as string)
      if (!isIError(response)) {
        if (selectedPlan?.deletedOnThirdParty !== response.deletedOnThirdParty) {
          setSelectedPlan(response)
        }
      }
    }
  }

  useEffect(() => {
    getCurrentPlan()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateVehiclesList = () => {
    if (selectedPlan?.id) {
      VehiclesApi.getVehiclesAvailabilitiesByPlan(selectedPlan.id, false).then((res) => {
        if (!isIError(res)) {
          setVehiclesList(removePastDatesFromVehiclesAvailabilities(res, selectedPlan))
        } else {
          setVehiclesList([])
        }
      })
    }
  }

  const updateVehiclesListForGlobalOptim = () => {
    if (selectedPlan?.id) {
      VehiclesApi.getVehiclesAvailabilitiesByPlan(selectedPlan.id, true).then((res) => {
        if (!isIError(res)) {
          setVehiclesListForGlobalOptim(removePastDatesFromVehiclesAvailabilities(res, selectedPlan))
        } else {
          setVehiclesListForGlobalOptim([])
        }
      })
    }
  }

  useEffect(() => {
    updateVehiclesList()
    updateVehiclesListForGlobalOptim()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlan, tours])

  const handleOptimizationJobStateChange = async (job: IJob): Promise<void> => {
    if (
      job.status === JobStatus.Done
      || job.status === JobStatus.Failed
      || job.status === JobStatus.Canceled
    ) {
      const errorMessages: string[] = []
      job.extra?.messages?.forEach((message) => {
        if (message.type === ExtraMessageType.Error) {
          errorMessages.push(message.value)
        }
      })
      if (errorMessages.length > 0) {
        openErrorSnack(errorMessages.join('\n'))
      }
      removeJob(job)
    }
  }

  const handleVisitsGenerationJobStateChange = async (job: IJob): Promise<void> => {
    if ([JobStatus.Done, JobStatus.Failed, JobStatus.Canceled].includes(job.status)) {
      if (job.status === JobStatus.Done) {
        getPlansVisits()
      }
      if (job.status === JobStatus.Failed || job.status === JobStatus.Canceled) {
        const messages: string[] = []
        job.extra?.messages?.forEach((message) => {
          messages.push(message.value)
        })
        if (messages.length > 0) {
          openErrorSnack(messages.join('\n'))
        }
      }
      toggleBackdrop()
      setIsCreateVisitsDisabled(false)
      setVisitsGenerationProgress(null)
      removeJob(job)
    } else {
      toggleBackdrop(t(`PlanningScreen.visitCreationRunning`), true)
      setIsCreateVisitsDisabled(true)
      const information = (job.extra?.information || {}) as IVisitsGenerationProgress
      const progress: IVisitsGenerationProgress = { ...information, duration: job.duration }
      setVisitsGenerationProgress(progress)
    }
  }

  const getJobStatus = async (jobId: string): Promise<void> => {
    const response = await JobsApi.getJobStatus(jobId)
    if (!isIError(response)) {
      if (response.dataType === JobType.OptimizationJob) {
        handleOptimizationJobStateChange(response)
      }
      if (response.dataType === JobType.VisitsGenerationJob) {
        handleVisitsGenerationJobStateChange(response)
      }
    }
  }

  const getJobsState = async (): Promise<void> => {
    const promises = jobIds.map((jobId) => getJobStatus(jobId))
    await Promise.all(promises)
  }

  const removeJob = (job: IJob): void => {
    setJobIds((prev) => prev.filter((id) => id !== job.jobId))
  }

  const { t } = useTranslation()

  function toggleBackdrop(
    message = '',
    skipActionLoading = false,
    isOptimizationByPilote = false,
  ): void {
    if (!skipActionLoading) {
      setActionLoading(message.length > 0)
    }
    if (isOptimizationByPilote) {
      setIsOptimizingByPilot(true)
    }
    setBackdropMessage(message)
  }

  function setMarkersWithoutDuplicates(
    newMarkers: ISelectableMarker[],
    options: ISetMarkersOptions = {},
  ): void {
    const { removeVisits, removePlanMarkers } = options
    setMarkers((prev) => {
      // This is needed because tour markers and visits marker are in the same array
      let previousArray = prev
      if (removeVisits) {
        previousArray = prev.filter((marker) => marker.tourId)
      } else if (removePlanMarkers) {
        previousArray = prev.filter((marker) => !marker.tourId)
      }
      const markersArray = [...previousArray, ...newMarkers]
      return markersArray.reduce((acc: ISelectableMarker[], current: ISelectableMarker) => {
        const itemIndex = acc.findIndex((item: ISelectableMarker) => item.stopId === current.stopId)
        if (itemIndex === -1) {
          return acc.concat([current])
        }
        acc.splice(itemIndex, 1, acc[itemIndex])
        return acc
      }, [])
    })
  }

  function extractMarkers(toursArray: ITourMapItem[]): void {
    let newMarkers: ISelectableMarker[] = []
    const siteLabels = sitesRef.current.map((site) => site.name)

    toursArray.forEach((tour: ITourMapItem, index) => {
      if (!tour.color) {
        // eslint-disable-next-line no-param-reassign
        tour.color = COLORS[index % COLORS.length]
      }
      const stops = tour.stops.map((currentStop, stopIndex) => {
        const stop: ISelectableMarker = {
          ...currentStop,
          color: tour.color,
          tourId: tour.tourId,
          tourNumber: tour.tourNumber,
          tourIndex: index,
          order: stopIndex,
          isWarehouse: stopIndex === 0 || tour.stops.length - 1 === stopIndex,
          loadingWarehouseId: currentStop.loadingWarehouseIds[0],
          estimatedServiceTime: currentStop.estimatedServiceTime,
          isSite: !!(currentStop.address.label && siteLabels.includes(currentStop.address.label)),
        }
        return stop
      })
      newMarkers = [...newMarkers, ...stops]
    })
    setMarkersWithoutDuplicates(newMarkers, { removePlanMarkers: true })
  }

  async function _getPlanTours(): Promise<void> {
    if (selectedPlan) {
      toggleLoader(true)
      const res = await PlansApi.getPlanTours({
        deliveryTypes: shouldFilterPlanToursByDeliveryType ? selectedDeliveryType : undefined,
        planId: selectedPlan.id,
        searchText: searchText.length < 3 ? undefined : searchText,
        status: planTourStatus,
      })
      if (res && !isIError(res)) {
        setTours((prev) => {
          const newTours = res.map((newTour) => {
            const oldTour = prev.find((lTour) => lTour.tourId === newTour.tourId)
            return { ...newTour, color: oldTour?.color || '' }
          })
          extractMarkers(newTours)
          return newTours
        })
      } else {
        openErrorSnack(res.error.message)
        setSelectedPlan(null)
      }
      toggleLoader(false)
    } else {
      openErrorSnack(t('PlanningScreen.planNeeded'))
    }
    toggleBackdrop()
  }

  const getPlanTours = useDebouncedCallback(_getPlanTours, 800, { trailing: true })

  useEffect(() => {
    setMarkers([])
    setTours([])
    getPlanTours.callback()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlan])

  useEffect(() => {
    getPlanTours.callback()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText])

  useEffect(() => {
    if (shouldFilterPlanToursByDeliveryType) {
      getPlanTours.callback()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDeliveryType])

  const formatVisitToSelectableMarker = (
    visit: IPlanVisit,
    siteLabels: string[],
  ): ISelectableMarker => {
    const newMarker = {
      ...visit,
      tags: visit.tags,
      selected: false,
      stopId: visit.planVisitId,
      planVisitsToDeliver: [visit.planVisitId],
      tourIndex: undefined,
      tourId: undefined,
      order: 0,
      isWarehouse: false,
      address: {
        road: visit.deliveryAddress.road,
        roadNumber: visit.deliveryAddress.roadNumber,
        city: visit.deliveryAddress.city,
        country: visit.deliveryAddress.country,
        hasElevator: visit.deliveryAddress.hasElevator,
        floor: visit.deliveryAddress.floor,
        zipCode: visit.deliveryAddress.zipCode,
        full: visit.deliveryAddress.full,
        label: visit.deliveryAddress.label || visit.deliveryAddress.full,
        latitude: visit.deliveryAddress.latitude,
        longitude: visit.deliveryAddress.longitude,
        customerFullName: visit.deliveryAddress.customerFullName,
      },
      estimatedServiceTime: visit.estimatedServiceTime,
      isSite: !!(visit.deliveryAddress.label && siteLabels.includes(visit.deliveryAddress.label)),
    }
    return newMarker as unknown as ISelectableMarker
  }

  const updateMarkersArray = (markersArray: ISelectableMarker[]): ISelectableMarker[] => {
    const newMarkers = [...markersArray]
    const planVisitsInManualTours = manualToursRef.current.reduce(
      (acc: ISelectableMarker[], manualTour: ICluster) => [...acc, ...manualTour.visits],
      [],
    )
    const planVisitsInClusters = clustersRef.current.reduce(
      (acc: ISelectableMarker[], cluster: ICluster) => [...acc, ...cluster.visits],
      [],
    )
    const markersToAdd: ISelectableMarker[] = []
    planVisitsInManualTours.forEach((planVisit) => {
      const correspondingPlanVisitInMarkersIndex = markersArray.findIndex(
        (elem) => elem.stopId === planVisit.stopId,
      )
      if (correspondingPlanVisitInMarkersIndex === -1) {
        markersToAdd.push({ ...planVisit, isNotCompatibleWithFilters: true })
      } else {
        newMarkers[correspondingPlanVisitInMarkersIndex].selected = planVisit.selected
        newMarkers[correspondingPlanVisitInMarkersIndex].manualTourId = planVisit.manualTourId
        newMarkers[correspondingPlanVisitInMarkersIndex].color = planVisit.color
        newMarkers[correspondingPlanVisitInMarkersIndex].order = planVisit.order
      }
    })
    planVisitsInClusters.forEach((planVisit) => {
      const correspondingPlanVisitInMarkersIndex = markersArray.findIndex(
        (elem) => elem.stopId === planVisit.stopId,
      )
      if (correspondingPlanVisitInMarkersIndex === -1) {
        if (isClusterModeRef.current) {
          markersToAdd.push({ ...planVisit, isNotCompatibleWithFilters: true })
        }
      } else {
        newMarkers[correspondingPlanVisitInMarkersIndex].selected = planVisit.selected
        newMarkers[correspondingPlanVisitInMarkersIndex].clusterId = planVisit.clusterId
        if (isClusterModeRef.current) {
          newMarkers[correspondingPlanVisitInMarkersIndex].color = planVisit.color
        }
        newMarkers[correspondingPlanVisitInMarkersIndex].order = planVisit.order
      }
    })
    outsourcedRef?.current?.visits?.forEach((planVisit) => {
      const correspondingPlanVisitInMarkersIndex = markersArray.findIndex(
        (elem) => elem.stopId === planVisit.stopId,
      )
      if (correspondingPlanVisitInMarkersIndex === -1) {
        if (isOutsourceModeRef.current) {
          markersToAdd.push({ ...planVisit, isNotCompatibleWithFilters: true })
        }
      } else {
        newMarkers[correspondingPlanVisitInMarkersIndex].selected = planVisit.selected
        newMarkers[correspondingPlanVisitInMarkersIndex].isOutsourced = true
        newMarkers[correspondingPlanVisitInMarkersIndex].order = planVisit.order
        if (isOutsourceModeRef.current) {
          const colorIndex = 4
          newMarkers[correspondingPlanVisitInMarkersIndex].color = CLUSTER_COLORS[colorIndex]
        }
      }
    })
    return [...newMarkers, ...markersToAdd]
  }

  const getPlansVisits = useCallback(async (): Promise<void> => {
    setVisitsLoading(true)
    if (startDate && endDate && selectedPlan) {
      const res = await PlansApi.getPlansVisits({
        startDate,
        endDate,
        deliveryMaxEndDate,
        deliveryType: selectedDeliveryType,
        transportTypes: selectedTransportTypes,
        warehouseIds: selectedWarehouse,
        zipCodes: zipCodes.map((zipCode) => zipCode.value),
        toOptimize: true,
      })
      if (!isIError(res)) {
        const siteLabels = sitesRef.current.map((site) => site.name)
        const markersArray: ISelectableMarker[] = res.map((marker) =>
          formatVisitToSelectableMarker(marker, siteLabels),
        )
        const newMarkers = updateMarkersArray(markersArray)
        setMarkersWithoutDuplicates(newMarkers, { removeVisits: true })
        setVisitsLoading(false)
      } else {
        openErrorSnack(res.error.message)
        setVisitsLoading(false)
      }
    } else {
      setVisitsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    startDate,
    endDate,
    deliveryMaxEndDate,
    selectedDeliveryType,
    selectedTransportTypes,
    selectedWarehouse,
    zipCodes,
  ])

  useEffect(() => {
    getPlansVisits()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    startDate,
    endDate,
    deliveryMaxEndDate,
    selectedDeliveryType,
    selectedTransportTypes,
    selectedWarehouse,
    zipCodes,
  ])

  function onOptimizationEnded(): void {
    if (isClusterMode) {
      if (isClusterOptimInError) {
        return
      }
      setClusterMode(false)
      setClusters([])
    } else {
      if (isManualToursOptimInError) {
        return
      }
      setManualTours([])
    }
    getPlansVisits()
    getPlanTours.callback()
    setIsPlanTourStatusChangeDone(true)
    setIsOptimizingByPilot(false)
  }

  async function getOptimizeState(): Promise<boolean> {
    if (selectedPlan) {
      const response = await PlansApi.isOptimizeRunning({ planId: selectedPlan.id })
      if (!isIError(response)) {
        if (!response && isActionLoading) {
          setActionLoading(false)
          onOptimizationEnded()
        }
        if (response) {
          toggleBackdrop(t(`PlanningScreen.optimizeRunning`))
        }
        return response
      }
      toggleBackdrop()
      openErrorSnack(response.error.message)
      if (response?.error?.code === 404) {
        setSelectedPlan(null)
      }
    }
    return false
  }

  const checkProcessingPlans = async (): Promise<boolean | null> => {
    let isStillProcessing = true
    while (isStillProcessing) {
      // eslint-disable-next-line no-await-in-loop
      const response = await PlansApi.getProcessingPlans({
        planIds: tours.map((tour) => tour.tourId),
      })
      if (!isIError(response)) {
        isStillProcessing = Boolean(response.filter((plan) => plan.isProcessing).length)
      } else {
        openErrorSnack(response.error.message)
        return null
      }
    }
    return isStillProcessing
  }

  const deletePlanTour = async (
    id: string,
    shouldDeleteTour: boolean,
  ): Promise<IPlanTourDeleteResponse | undefined> => {
    toggleLoader(true)
    const isStillProcessing = await checkProcessingPlans()
    if (isStillProcessing === false) {
      const response = await PlansApi.deletePlanTours([id], shouldDeleteTour)
      if (!isIError(response)) {
        setActionLoading(true)
      } else {
        openErrorSnack(response.error.message)
      }
      setIsMapotempoActionInProgress(false)
      return response as unknown as IPlanTourDeleteResponse
    }
    return undefined
  }

  const uselessFunc = (): void => {
    /* Do nothing here */
  }

  useInterval(isActionLoading ? getOptimizeState : uselessFunc, 5000)

  useInterval(jobIds.length > 0 ? getJobsState : uselessFunc, 5000)

  useInterval(!isClusterMode && !isOutsourceMode ? getOptimizeState : uselessFunc, 10000)

  useEffect(() => {
    if (selectedPlan) {
      getOptimizeState()
      setStartDate(selectedPlan.startDate)
      setEndDate(selectedPlan.endDate)
      setSelectedWarehouse([selectedPlan.warehouseId, ...selectedPlan.secondaryWarehouseIds])
      setSelectedDeliveryType(selectedPlan.deliveryTypes.map((type) => type.toString()))
      setSelectedTransportTypes(selectedPlan.transportTypes)
    } else {
      setStartDate(getNextMidnight())
      setEndDate(getNextLastMinute())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlan])

  function deselectAll(): void {
    if (!isClusterMode && !isOutsourceMode) {
      setSelectedTours([])
      setHiddenTours([])
    }
    setMarkers((prev) => prev.map((marker) => ({ ...marker, selected: false })))
  }

  function toggleTourVisibility(id: string): void {
    setHiddenTours((prev) =>
      prev.includes(id) ? [...prev.filter((tourId) => tourId !== id)] : [...prev, id],
    )
  }

  function showSelectedTours(toursIds: string[]): void {
    setHiddenTours(
      tours
        .filter((tour) => !toursIds.includes(tour.tourId) && tour.tourId)
        .map((tour) => tour.tourId),
    )
  }

  function toggleAllToursVisibility(): void {
    setHiddenTours((prev) => {
      if (prev.length > 0) {
        setSelectedTours([])
      }
      return prev.length > 0 ? [] : tours.map((tour) => tour.tourId)
    })
  }

  function hideAllTours(shouldHide: boolean): void {
    setHiddenTours(shouldHide ? tours.map((tour) => tour.tourId) : [])
  }

  async function validateAllTours(): Promise<void> {
    toggleBackdrop(t('PlanningScreen.validateAllTours'), true)
    const toursToValidate = tours.filter((tour) => tour.status === 0)
    const toursIdToSend = toursToValidate.map((tour) => tour.tourId)
    setIsPlanValidateDisabled(true)
    const res = await PlansApi.validatePlanTours(toursIdToSend)
    if (isIError(res)) {
      openErrorSnack(res.error.message)
    }
    getPlanTours.callback()
    toggleBackdrop()
    setIsPlanValidateDisabled(false)
    setIsPlanTourStatusChangeDone(true)
  }

  async function invalidateAllTours(): Promise<void> {
    toggleBackdrop(t('PlanningScreen.invalidateAllTours'), true)
    const toursToInvalidate = tours.filter((tour) => tour.status !== 0)
    const toursIdToSend = toursToInvalidate.map((tour) => tour.tourId)
    setIsPlanInvalidateDisabled(true)
    const res = await PlansApi.invalidatePlanTours(toursIdToSend)
    if (isIError(res)) {
      openErrorSnack(res.error.message)
    }
    getPlanTours.callback()
    toggleBackdrop()
    setIsPlanInvalidateDisabled(false)
    setIsPlanTourStatusChangeDone(true)
  }

  async function interfaceAllTours(): Promise<void> {
    toggleBackdrop(t('PlanningScreen.interfaceAllTours'), true)
    const toursToValidate = tours.filter((tour) => tour.status === 1)
    const toursIdToSend = toursToValidate.map((tour) => tour.tourId)
    toursIdToSend.map(() =>
      appInsight.trackEvent({ name: AppInsightEvents.ValidateOrInterfaceTour }),
    )
    setIsPlanInterfaceDisabled(true)
    await PlansApi.interfacePlanTours(toursIdToSend)
    getPlanTours.callback()
    toggleBackdrop()
    setIsPlanInterfaceDisabled(false)
    setIsPlanTourStatusChangeDone(true)
  }

  const deletePlanTours = async (
    shouldDeleteTour: boolean,
  ): Promise<IPlanTourDeleteResponse | undefined> => {
    toggleLoader(true)
    setIsPlanTourDeleteDisabled(true)
    const isStillProcessing = await checkProcessingPlans()
    if (isStillProcessing) {
      return
    }

    const response = await PlansApi.deletePlanToursByPlanId(
      selectedPlan?.id as string,
      shouldDeleteTour,
    )
    toggleBackdrop(t('PlanningScreen.deleteTours'))
    if (isIError(response)) {
      openErrorSnack(response.error.message)
    }
    setIsPlanTourDeleteDisabled(false)
    return response as unknown as IPlanTourDeleteResponse
  }

  async function deletePlanVisits(): Promise<void> {
    toggleBackdrop(t('PlanningScreen.ongoingDeleteVisits'), true)
    setIsDeleteVisitsDisabled(true)
    const res = await PlansApi.deletePlanVisits({
      startDate,
      endDate,
      deliveryTypes: selectedDeliveryType,
      transportTypes: selectedTransportTypes,
    })
    if (isIError(res)) {
      openErrorSnack(res.error.message)
    }
    await getPlansVisits()
    toggleBackdrop()
    setIsDeleteVisitsDisabled(false)
  }

  async function getOrderDetailsByPlanVisitId(planVisitId: string): Promise<IProduct[]> {
    const response = await PlansApi.getOrderDetailsByPlanVisitId(planVisitId)
    if (!isIError(response)) {
      return response
    }
    return []
  }

  async function repairPlan(planId: string): Promise<void> {
    const res = await PlansApi.repairPlan(planId)
    if (isIError(res)) {
      openErrorSnack(res.error.message)
    }
  }

  return (
    <Provider
      value={{
        markers,
        setMarkers,
        tours,
        setTours,
        selectedTours,
        setSelectedTours,
        plansList,
        setPlansList,
        selectedPlan,
        setSelectedPlan,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        deliveryMaxEndDate,
        setDeliveryMaxEndDate,
        isVisitsLoading,
        isActionLoading,
        selectedWarehouse,
        setSelectedWarehouse,
        selectedDeliveryType,
        setSelectedDeliveryType,
        selectedTransportTypes,
        setSelectedTransportTypes,
        searchText,
        setSearchText,
        getPlansVisits,
        getPlanTours: getPlanTours.callback,
        deselectAll,
        hiddenTours,
        toggleTourVisibility,
        toggleAllToursVisibility,
        hideAllTours,
        showSelectedTours,
        backdropMessage,
        toggleBackdrop,
        planTourStatus,
        setPlanTourStatus,
        validateAllTours,
        invalidateAllTours,
        interfaceAllTours,
        deletePlanVisits,
        clusters,
        setClusters,
        isClusterMode,
        setClusterMode,
        isOutsourceMode,
        setIsOutsourceMode,
        outsourced,
        setOutsourced,
        isPlanValidateDisabled,
        isPlanInvalidateDisabled,
        isPlanInterfaceDisabled,
        isCreateVisitsDisabled,
        setIsCreateVisitsDisabled,
        isDeleteVisitsDisabled,
        isPlanTourDeleteDisabled,
        isClusterOptimInError,
        setClusterOptimInError,
        areClustersHidden,
        setClustersVisibility,
        setIsDeleteVisitsDisabled,
        isMapotempoActionInProgress,
        setIsMapotempoActionInProgress,
        getOptimizeState,
        setZipCodes,
        zipCodes,
        deletePlanTours,
        setIsProcessing,
        isProcessing,
        setIsPlanTourStatusChangeDone,
        isPlanTourStatusChangeDone,
        deletePlanTour,
        manualTours,
        setManualTours,
        isManualToursOptimInError,
        setManualToursOptimInError,
        isMultisite,
        hasRecoveredClusters,
        setHasRecoveredClusters,
        vehiclesList,
        setVehiclesList,
        vehiclesListForGlobalOptim,
        setVehiclesListForGlobalOptim,
        getOrderDetailsByPlanVisitId,
        repairPlan,
        isOptimizingByPilot,
        setJobIds,
        visitsGenerationProgress,
        areOutsourcedHidden,
        setOutsourcedVisibility,
        highlightedElement,
        setHighlightedElement,
        isMarkerClicked,
        setIsMarkerClicked,
      }}
    >
      {children}
    </Provider>
  )
}

export { PlanificationContext }
export default PlanificationProvider
export { Consumer as PlanificationConsumer }
