import React from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import {
  addDays,
  endOfDay,
  endOfMonth,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths,
  subWeeks,
} from 'date-fns'
import { useTranslation } from 'react-i18next'
import useStyles from './styles'

interface IExtendedDateFiltersProps {
  fnLast30days?: () => void
  fnLast30weeks?: () => void
  fnLast3months?: () => void
  fnLast7days?: () => void
  fnLastMonth?: () => void
  fnNext7days?: () => void
  fnThisMonth?: () => void
  last30weeks?: boolean
  last30days?: boolean
  last3months?: boolean
  last7days?: boolean
  lastMonth?: boolean
  lastWeek?: boolean
  next7days?: boolean
  thisMonth?: boolean
  thisWeek?: boolean
  today?: boolean
  tomorrow?: boolean
  yesterday?: boolean
  setEndDate: (value: string) => void
  setStartDate: (value: string) => void
}

const ExtendedDateFilters = ({
  fnLast30days,
  fnLast30weeks,
  fnLast3months,
  fnLast7days,
  fnLastMonth,
  fnNext7days,
  fnThisMonth,
  last30weeks = false,
  last30days = false,
  last3months = false,
  last7days = false,
  lastMonth = false,
  lastWeek = false,
  next7days = false,
  thisMonth = false,
  thisWeek = false,
  today = false,
  tomorrow = false,
  yesterday = false,
  setEndDate,
  setStartDate,
}: IExtendedDateFiltersProps): JSX.Element => {
  const { t } = useTranslation()
  const styles = useStyles()

  const dateTransformer = {
    last30weeks() {
      const td = new Date()
      setEndDate(endOfWeek(subWeeks(td, 1), { weekStartsOn: 1 }).toISOString())
      setStartDate(endOfWeek(subWeeks(td, 30), { weekStartsOn: 1 }).toISOString())
      if (fnLast30weeks && typeof fnLast30weeks === 'function') {
        fnLast30weeks()
      }
    },
    last3months() {
      const td = new Date()
      setEndDate(endOfMonth(subMonths(td, 1)).toISOString())
      setStartDate(startOfMonth(subMonths(td, 3)).toISOString())
      if (fnLast3months && typeof fnLast3months === 'function') {
        fnLast3months()
      }
    },
    lastMonth() {
      const td = new Date()
      setEndDate(endOfMonth(subMonths(td, 1)).toISOString())
      setStartDate(startOfMonth(subMonths(td, 1)).toISOString())
      if (fnLastMonth && typeof fnLastMonth === 'function') {
        fnLastMonth()
      }
    },
    last30days() {
      const td = new Date()
      setEndDate(endOfDay(subDays(td, 1)).toISOString())
      setStartDate(endOfDay(subDays(td, 30)).toISOString())
      if (fnLast30days && typeof fnLast30days === 'function') {
        fnLast30days()
      }
    },
    last7days() {
      const td = new Date()
      setEndDate(endOfDay(subDays(td, 1)).toISOString())
      setStartDate(startOfDay(subDays(td, 7)).toISOString())
      if (fnLast7days && typeof fnLast7days === 'function') {
        fnLast7days()
      }
    },
    yesterday() {
      const td = new Date()
      setEndDate(endOfDay(subDays(td, 1)).toISOString())
      setStartDate(startOfDay(subDays(td, 1)).toISOString())
    },
    thisMonth() {
      const td = new Date()
      setEndDate(endOfMonth(td).toISOString())
      setStartDate(startOfMonth(td).toISOString())
      if (fnThisMonth && typeof fnThisMonth === 'function') {
        fnThisMonth()
      }
    },
    lastWeek() {
      const td = new Date()
      setEndDate(endOfWeek(subWeeks(td, 1), { weekStartsOn: 1 }).toISOString())
      setStartDate(startOfWeek(subWeeks(td, 1), { weekStartsOn: 1 }).toISOString())
    },
    today() {
      const td = new Date()
      setEndDate(endOfDay(td).toISOString())
      setStartDate(startOfDay(td).toISOString())
    },
    tomorrow() {
      const td = new Date()
      setEndDate(endOfDay(addDays(td, 1)).toISOString())
      setStartDate(startOfDay(addDays(td, 1)).toISOString())
    },
    thisWeek() {
      const td = new Date()
      setEndDate(endOfWeek(td, { weekStartsOn: 1 }).toISOString())
      setStartDate(startOfWeek(td, { weekStartsOn: 1 }).toISOString())
    },
    next7days() {
      const td = new Date()
      setEndDate(endOfDay(addDays(td, 7)).toISOString())
      setStartDate(startOfDay(addDays(td, 1)).toISOString())
      if (fnNext7days && typeof fnNext7days === 'function') {
        fnNext7days()
      }
    },
  }

  function extendedDateFilterButtonFactory(dateFilterName: string) {
    return (
      <Grid item>
        <Button
          data-cy={dateFilterName}
          color="primary"
          onClick={dateTransformer[dateFilterName]}
          size="medium"
          variant="outlined"
        >
          {t(`ExtendedDateFilter.${dateFilterName}`)}
        </Button>
      </Grid>
    )
  }

  return (
    <Grid
      className={styles.extendedDateFilter}
      container
      data-cy="extendedDateFilterShortcuts"
      direction="row"
      spacing={1}
    >
      {last30weeks && extendedDateFilterButtonFactory('last30weeks')}
      {last3months && extendedDateFilterButtonFactory('last3months')}
      {lastMonth && extendedDateFilterButtonFactory('lastMonth')}
      {last30days && extendedDateFilterButtonFactory('last30days')}
      {last7days && extendedDateFilterButtonFactory('last7days')}
      {lastWeek && extendedDateFilterButtonFactory('lastWeek')}
      {yesterday && extendedDateFilterButtonFactory('yesterday')}
      {today && extendedDateFilterButtonFactory('today')}
      {tomorrow && extendedDateFilterButtonFactory('tomorrow')}
      {thisWeek && extendedDateFilterButtonFactory('thisWeek')}
      {next7days && extendedDateFilterButtonFactory('next7days')}
      {thisMonth && extendedDateFilterButtonFactory('thisMonth')}
    </Grid>
  )
}

export default ExtendedDateFilters
