import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Help } from '@material-ui/icons'

import useStyles from '../styles'

interface IIndicatorProps {
  color: string
}

export const Indicator = styled.div<IIndicatorProps>`
  width: 12px;
  height: 12px;
  border: 1px solid black;
  background-color: ${(props): string => props.color};
  border-radius: 50%;
`

const ColorsLegend = (): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [isHover, setIsHover] = useState<boolean>(false)

  const onMouseEnter = () => {
    setIsHover(true)
  }

  const onMouseLeave = () => {
    setIsHover(false)
  }

  const colorLegends = [
    {
      color: 'white',
      description: t(`MapScreen.popupControls.colorsLegend.white`),
    },
    {
      color: 'black',
      description: t(`MapScreen.popupControls.colorsLegend.black`),
    },
    {
      color: 'green',
      description: t(`MapScreen.popupControls.colorsLegend.green`),
    },
    {
      color: 'orange',
      description: t(`MapScreen.popupControls.colorsLegend.orange`),
    },
    {
      color: 'red',
      description: t(`MapScreen.popupControls.colorsLegend.red`),
    },
  ]

  return (
    <div
      className={`leaflet-top leaflet-left ${classes.popupControlContainer} ${classes.colorsLegendContainer}`}
    >
      <div
        className="leaflet-control-zoom leaflet-bar leaflet-control leaflet-control-zoom-in"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {isHover ? (
          <div className={classes.colorLegendsPaper}>
            {colorLegends.map((legend) => (
              <div className={classes.colorLegendsContent} key={legend.color}>
                <Indicator color={legend.color} />
                <p>{legend.description}</p>
              </div>
            ))}
          </div>
        ) : (
          <div className={classes.popupControl}>
            <Help />
          </div>
        )}
      </div>
    </div>
  )
}

export default ColorsLegend
