import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import TrailersProvider, { TrailersConsumer } from 'store/TrailersContext'
import { ContentConsumer } from 'store/ContentContext'
import { ITrailer } from 'interfaces/ITrailer'
import { IIdParam } from 'interfaces/IIdParam'
import { ICarrier, IVehicleType } from 'interfaces/interfaces'
import CustomCard from 'components/CustomCard/CustomCard'
import Button from 'components/Button/CustomButton'
import { ROUTES_PATH } from 'navigation/RoutesPath'

import useStyles from 'constants/cruStyles'

interface IProps {
  getDetails?: Function
  trailerDetails: ITrailer
  carriers: ICarrier[]
  vehicleTypes: IVehicleType[]
}

const TrailerDetailsScreen = ({
  getDetails,
  trailerDetails,
  carriers,
  vehicleTypes,
}: IProps): JSX.Element => {
  const { id } = useParams<IIdParam>()
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()

  useEffect(() => {
    if (id && getDetails) {
      getDetails(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const handleEditClick = (): void => {
    history.push(`${ROUTES_PATH.editTrailer}${id}`)
  }

  return (
    <>
      <div className={classes.detailHeaderContainer}>
        <Button onPress={handleEditClick}>{t('TrailersScreen.edit').toUpperCase()}</Button>
        <h3>{`${trailerDetails.name || ''}`}</h3>
      </div>
      <div className={classes.detailsContent}>
        <div className={classes.detailsInnerContent}>
          <Grid item xs={10} className={classes.infoContainer}>
            <CustomCard
              cardTitle={t('TrailersScreen.profile').toUpperCase()}
              linePairs={[
                {
                  label: t('TrailersScreen.name').toUpperCase(),
                  value: trailerDetails ? trailerDetails.name : '',
                },
                {
                  label: t('TrailersScreen.licencePlate').toUpperCase(),
                  value: trailerDetails ? trailerDetails.licencePlate : '',
                },
                {
                  label: t('TrailersScreen.active').toUpperCase(),
                  value: trailerDetails
                    ? trailerDetails.active
                      ? t('TrailersScreen.yes')
                      : t('TrailersScreen.no')
                    : '',
                },
                {
                  label: t('TrailersScreen.carrier').toUpperCase(),
                  value:
                    carriers.length && trailerDetails
                      ? carriers.find((carrier) => carrier.id === trailerDetails.carrierId)?.name
                      : '',
                },
                {
                  label: t('TrailersScreen.vehicleType').toUpperCase(),
                  value:
                    vehicleTypes.length && trailerDetails
                      ? vehicleTypes.find(
                        (vehicleType) => vehicleType.code === trailerDetails.vehicleType,
                      )?.label
                      : '',
                },
              ]}
            />
          </Grid>
        </div>
      </div>
    </>
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <TrailersProvider>
    <ContentConsumer>
      {(contentCtx): JSX.Element => (
        <TrailersConsumer>
          {(trailersCtx): JSX.Element => (
            <TrailerDetailsScreen
              getDetails={trailersCtx.getDetails}
              trailerDetails={trailersCtx.trailerDetails}
              carriers={contentCtx.carriers}
              vehicleTypes={contentCtx.vehicleTypes}
              {...props}
            />
          )}
        </TrailersConsumer>
      )}
    </ContentConsumer>
  </TrailersProvider>
)
