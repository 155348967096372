import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    flex: {
      display: 'flex',
      flex: 1,
    },
    border: {
      borderRight: '1px solid #3f3f46',
    },
    list: {
      maxHeight: '360px',
      overflow: 'auto',
    },
    listItem: {
      display: 'block',
      borderRadius: '0px',
      width: '150px',
      height: '50px',
    },
    selectedListItem: {
      borderLeft: `4px solid ${theme.color.primary}`,
    },
    flexGrow: {
      flexGrow: 1,
    },
    noSectors: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '150px',
      fontWeight: 'bold',
      fontSize: '18px',
      opacity: 0.5,
    },
  }),
)
