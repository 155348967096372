import React, { useEffect } from 'react'
import { useMap } from 'react-leaflet'

import { HIGHLIGHTED_ELEMENT_ZOOM } from 'constants/constants'
import { IHighlightedElement } from 'interfaces/IHighlightedElement'

interface IProps {
  highlightedElement?: IHighlightedElement
}

const ChangeMapView = ({ highlightedElement }: IProps): JSX.Element => {
  const map = useMap()

  useEffect(() => {
    if (map && highlightedElement) {
      map.setView(
        [highlightedElement.latitude, highlightedElement.longitude],
        HIGHLIGHTED_ELEMENT_ZOOM,
      )
    }
  }, [highlightedElement, map])

  return <></>
}

export default ChangeMapView
