import React from 'react'
import { Box } from '@material-ui/core'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
  className: string
}

export default (props: TabPanelProps): JSX.Element => {
  const { children, value, index, className, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={className}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}
