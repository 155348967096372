import React from 'react'
import { isSameDay } from 'date-fns'
import { KeyboardDateTimePicker, KeyboardTimePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

import { getFormat } from 'utils/dateFormat'
import { DateType } from 'constants/constants'
import useStyles from './styles'

interface IVehicleAvailabilityDatePickerProps {
  startDate: string
  endDate: string
  value: string
  open: boolean
  onClose: () => void
  onChange: (date: MaterialUiPickersDate) => void
}

const VehicleAvailabilityDatePicker = ({
  startDate,
  endDate,
  value,
  open,
  onClose,
  onChange,
}: IVehicleAvailabilityDatePickerProps): JSX.Element => {
  const classes = useStyles()

  const pickerCommonProps = {
    open,
    onClose,
    value,
    onChange,
    className: classes.hide,
    ampm: false,
  }

  const timePickerProps = {
    ...pickerCommonProps,
    format: getFormat(DateType.Time),
  }

  const dateTimePickerProps = {
    ...pickerCommonProps,
    format: getFormat(DateType.DateTime),
  }

  return (
    isSameDay(new Date(startDate), new Date(endDate)) ? (
      <KeyboardTimePicker
        {...timePickerProps}
      />
    ) : (
      <KeyboardDateTimePicker
        {...dateTimePickerProps}
      />
    )
  )
}

export default VehicleAvailabilityDatePicker
