import React, { useContext, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core/'
import { useTranslation } from 'react-i18next'
import CircularProgress from '@material-ui/core/CircularProgress'

import PlansApi from 'api/plans'
import { FeedbackContext } from 'store/FeedbackContext'
import { isIError } from 'api/types'
import { IPlanDeleteResponse, IPlanTourDeleteResponseItem } from 'interfaces/IPlan'

interface IDeletePlanModal {
  planIdToDelete: string | undefined
  setPlanIdToDelete(id: string): void
  onEndDelete?(): void
  setNotDeletedModalOpen?(notDeletedPlanTours: IPlanTourDeleteResponseItem[]): void
}

function DeletePlanModal({
  planIdToDelete,
  setPlanIdToDelete,
  onEndDelete,
  setNotDeletedModalOpen,
}: IDeletePlanModal): JSX.Element {
  const { t } = useTranslation()
  const { openErrorSnack, toggleLoader } = useContext(FeedbackContext)
  const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState<boolean>(false)

  const confirmDelete = async (): Promise<void> => {
    if (planIdToDelete) {
      toggleLoader(true)
      setIsConfirmButtonDisabled(true)
      const res = await PlansApi.deletePlan(planIdToDelete)
      if (!isIError(res)) {
        if (onEndDelete) {
          const { isPlanDeleted, notDeletePlanTours } = res as unknown as IPlanDeleteResponse
          if (isPlanDeleted) {
            onEndDelete()
          } else {
            if (setNotDeletedModalOpen) setNotDeletedModalOpen(notDeletePlanTours)
          }
        }
      } else {
        openErrorSnack(res.error.message)
      }
      toggleLoader(false)
      setPlanIdToDelete('')
      setIsConfirmButtonDisabled(false)
    }
  }

  function cancelDelete(): void {
    setPlanIdToDelete('')
  }

  return (
    <>
      <Dialog
        open={!!planIdToDelete}
        aria-labelledby="are-you-sure"
        aria-describedby="modal-to-confirm-plan-deletion"
      >
        <DialogContent>
          <DialogContentText id="modal-description">
            {t('PlanningScreen.deleteConfirmationText')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button data-cy="cancelButton" onClick={cancelDelete} color="secondary">
            {t('PlanningScreen.cancel')}
          </Button>
          <Button
            data-cy="deleteButton"
            onClick={confirmDelete}
            color="primary"
            autoFocus
            disabled={isConfirmButtonDisabled}
          >
            {t('PlanningScreen.delete')}
            {isConfirmButtonDisabled && (
              <CircularProgress color="secondary" size={24} style={{ marginLeft: 8 }} />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DeletePlanModal
