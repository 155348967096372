import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid, Tabs, Tab, useTheme } from '@material-ui/core'
import CustomCard from 'components/CustomCard/CustomCard'
import { IProduct, ISupport } from 'interfaces/IOrders'
import { handleTabChange } from 'utils/tabFeatureUtils'
import { A11yPropsType } from 'interfaces/interfaces'
import TabPanel from 'components/TabPanel/TabPanel'
import SelectableColMenu from 'components/Table/SelectableColMenu/SelectableColMenu'
import { AppConfigContext } from 'store/AppConfigContext'
import { COLUMNS_MODEL_PRODUCTS_LIST } from 'constants/table'
import { IDispute } from 'interfaces/IDispute'
import ProductOrSupportTable from './ProductOrSupportTable'
import useStyles from './styles'

type ProductAndParcelCardProps = {
  products: IProduct[]
  supports: ISupport[]
  disputes: IDispute[]
  a11yProps: (index: number) => A11yPropsType
  shouldShowDisputes: Boolean
}

export default ({
  products,
  supports,
  disputes,
  a11yProps,
  shouldShowDisputes,
}: ProductAndParcelCardProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const { tablesConfig, updateTableConfig } = useContext(AppConfigContext)
  const [listTab, setListTab] = useState<number>(0)

  const productsColumnOptions = COLUMNS_MODEL_PRODUCTS_LIST

  return (
    <CustomCard dataCy="productAndParcelCard" cardTitle={t('OrdersScreen.orderDetails')}>
      <Grid container direction="row" justify="center" alignItems="center" className={classes.tabs}>
        <Tabs
          TabIndicatorProps={{ style: { backgroundColor: theme.color.primary } }}
          value={listTab}
          onChange={(_, newTab) => handleTabChange(setListTab, newTab)}
          aria-label="products and parcels tabs"
        >
          <Tab
            data-cy={`${t('OrdersScreen.products').toLowerCase()}Tabs`}
            className={classes.tab}
            label={t('OrdersScreen.products')}
            {...a11yProps(0)}
          />
          <Tab
            data-cy={`${t('OrdersScreen.support').toLowerCase()}Tabs`}
            className={classes.tab}
            label={t('OrdersScreen.support')}
            {...a11yProps(1)}
          />
          {disputes && shouldShowDisputes && (
            <Tab
              data-cy={`${t('OrdersScreen.disputes').toLowerCase()}Tabs`}
              className={classes.tab}
              label={t('OrdersScreen.disputes')}
              {...a11yProps(2)}
            />
          )}
        </Tabs>
      </Grid>
      <TabPanel
        data-cy={`${t('OrdersScreen.products').toLowerCase()}TabPanel`}
        value={listTab}
        index={0}
        className={classes.tabPanel}
      >
        <div className={classes.tablePanel}>
          <ProductOrSupportTable products={products} />
        </div>
        <div className={classes.tableToolbar}>
          <SelectableColMenu
            list={productsColumnOptions}
            columnPreferences={tablesConfig.productDetails || []}
            onChange={(entries) => updateTableConfig('productDetails', entries)}
          />
        </div>
      </TabPanel>
      <TabPanel
        data-cy={`${t('OrdersScreen.support').toLowerCase()}TabPanel`}
        value={listTab}
        index={1}
        className={classes.tabPanel}
      >
        <ProductOrSupportTable supports={supports} />
      </TabPanel>
      {shouldShowDisputes && (
        <TabPanel
          data-cy={`${t('OrdersScreen.disputes').toLowerCase()}TabPanel`}
          value={listTab}
          index={2}
          className={classes.tabPanel}
        >
          <ProductOrSupportTable disputes={disputes} />
        </TabPanel>
      )}
    </CustomCard>
  )
}
