import React, { useContext, useState } from 'react'
import { IconButton, Menu, MenuItem, TablePagination } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import { ITableColumn } from 'interfaces/interfaces'
import { ROWS_PER_PAGE_OPTIONS } from 'constants/constants'
import { AppConfigContext } from 'store/AppConfigContext'
import SelectableColMenu from 'components/Table/SelectableColMenu'
import { ITableSelectionAction } from 'interfaces/ITableSelectionAction'

interface IProps {
  columns: ITableColumn[]
  columnConfigName: string
  page: number
  count: number
  rowsPerPage: number
  setPage: Function
  setPaginationOffset: Function
  selectionActions?: ITableSelectionAction[]
  showActionButton?: boolean
}

const Pagination = ({
  columns,
  columnConfigName,
  page,
  count,
  rowsPerPage,
  setPage,
  setPaginationOffset,
  selectionActions,
  showActionButton,
  ...rest
}: IProps): JSX.Element => {
  const { updateTableConfig, tablesConfig } = useContext(AppConfigContext)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handleSelectionMenuClick = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleSelectionMenuClose = (): void => {
    setAnchorEl(null)
  }

  const isSelectionMenuOpen = Boolean(anchorEl)

  return (
    <td style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div>
        <SelectableColMenu
          list={columns}
          columnPreferences={tablesConfig[columnConfigName] || []}
          onChange={(entries) => updateTableConfig(columnConfigName, entries)}
        />
        {selectionActions && showActionButton && (
          <>
            <IconButton data-cy="moreButton" onClick={handleSelectionMenuClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="menu"
              data-cy="tableFooter-menu"
              anchorEl={anchorEl}
              open={isSelectionMenuOpen}
              onClose={handleSelectionMenuClose}
            >
              {selectionActions.map((selectionAction) => (
                <MenuItem
                  data-cy={`item-option-${selectionAction.label}`}
                  key={selectionAction.label}
                  onClick={() => selectionAction.action()}
                >
                  {selectionAction.label}
                </MenuItem>
              ))}
            </Menu>
          </>
        )}
      </div>
      <TablePagination
        {...rest}
        component="span"
        page={page}
        count={count}
        rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
        rowsPerPage={rowsPerPage}
        onChangePage={(
          _event: React.MouseEvent<HTMLButtonElement> | null,
          pageNumber: number,
        ): void => {
          setPage(pageNumber)
          setPaginationOffset(pageNumber * rowsPerPage)
        }}
      />
    </td>
  )
}

export default Pagination
