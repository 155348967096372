import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import moment from 'moment'

import { IPlanTemplate } from 'interfaces/IPlanTemplate'
import { CarrierFilter, VehicleCatFilter } from 'components/Inputs/ListFilter'
import useStyles from 'constants/cruStyles'
import Card from 'components/Card/Card'
import DateRangePicker from 'components/Inputs/DateRangePicker'
import { DateType } from 'constants/constants'

interface IProps {
  planTemplate?: Partial<IPlanTemplate>
  onPlanTemplateChange?: Function
  isReadOnly?: boolean
}

const PlanTemplateVehicleCard = ({
  planTemplate,
  onPlanTemplateChange,
  isReadOnly,
}: IProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [state, setState] = useState<Partial<IPlanTemplate>>({
    carrierIds: planTemplate?.carrierIds,
    vehicleTypesIds: planTemplate?.vehicleTypesIds,
    vehicleDepartureMin: planTemplate?.vehicleDepartureMin,
    vehicleReturnMax: planTemplate?.vehicleReturnMax,
  })

  const handleFilterChange = (values: string[], name: string): void => {
    setState({ ...state, [name]: values })
    if (onPlanTemplateChange) {
      onPlanTemplateChange({ ...state, [name]: values })
    }
  }

  const handleTimeChange = (time: MaterialUiPickersDate, name: string): void => {
    if (moment(time).isValid()) {
      setState({ ...state, [name]: moment(time).toISOString() })
      if (onPlanTemplateChange) {
        onPlanTemplateChange({ ...state, [name]: moment(time).toISOString() })
      }
    }
  }

  return (
    <Card dataCy="vehicleCard" title={t('PlanTemplatesScreen.vehicles').toUpperCase()}>
      <div className={classes.fieldsContainer}>
        <div className={classes.fieldsContainer}>
          <DateRangePicker
            startDate={state.vehicleDepartureMin}
            endDate={state.vehicleReturnMax}
            dateType={DateType.Time}
            onChangeStart={(time: MaterialUiPickersDate): void => {
              handleTimeChange(time, 'vehicleDepartureMin')
            }}
            onChangeEnd={(time: MaterialUiPickersDate): void => {
              handleTimeChange(time, 'vehicleReturnMax')
            }}
            hideShiftPicker
            isFullWidth
            startLabel={t('PlanTemplatesScreen.departureTime')}
            endLabel={t('PlanTemplatesScreen.returnTime')}
            startDataCy="departureTimePicker"
            endDataCy="returnTimePicker"
            isStartDisabled={isReadOnly}
            isEndDisabled={isReadOnly}
            isStartRequired
            isEndRequired
          />
          <div className={classes.filters}>
            <CarrierFilter
              handleChange={(values: string[]): void => handleFilterChange(values, 'carrierIds')}
              ids={state.carrierIds || []}
              disabled={isReadOnly}
              shouldFillWidth
              dataCy="carrierPicker"
            />
          </div>
          <div className={classes.filters}>
            <VehicleCatFilter
              handleChange={(values: string[]): void =>
                handleFilterChange(values, 'vehicleTypesIds')}
              ids={state.vehicleTypesIds || []}
              disabled={isReadOnly}
              shouldFillWidth
              dataCy="vehicleTypePicker"
            />
          </div>
        </div>
      </div>
    </Card>
  )
}

export default PlanTemplateVehicleCard
