import React from 'react'
import { StopStatus } from 'constants/constants'
import Address from './Address'
import StatusLine from './StatusLine'

interface IProps {
  status: StopStatus
  address: string
}

const Visit = ({ status, address }: IProps): JSX.Element => (
  <>
    <StatusLine status={status} />
    <Address label={address} />
  </>
)

export default Visit
