import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, GridSize } from '@material-ui/core'
import clsx from 'clsx'

import Button from 'components/Button/CustomButton'
import useStyles from './styles'

interface IProps {
  onCancelClick: (() => void) | (() => Promise<void>)
  onSaveClick: (() => void) | (() => Promise<void>)
  onAdditionAction?: (() => void) | (() => Promise<void>)
  additionalButtonLabel?: string
  isSaveDisabled?: boolean
  gridSize?: GridSize
  additionalClass?: string
  children?: ReactNode
}

export default function FormAction({
  onCancelClick,
  onSaveClick,
  onAdditionAction,
  additionalButtonLabel,
  isSaveDisabled = false,
  gridSize,
  additionalClass,
  children,
}: IProps): JSX.Element {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid item xs={gridSize || 10} className={clsx(classes.actionContainer, additionalClass)}>
      <Button dataCy="cancelButton" onPress={onCancelClick} variant="outlined">
        {t('form.cancel')}
      </Button>
      {children}
      <div>
        {additionalButtonLabel && onAdditionAction && (
          <Button
            className={classes.additionalButton}
            dataCy="additionalButton"
            onPress={onAdditionAction}
          >
            {additionalButtonLabel}
          </Button>
        )}
        <Button dataCy="saveButton" onPress={onSaveClick} disabled={isSaveDisabled}>
          {t('form.save')}
        </Button>
      </div>
    </Grid>
  )
}
