import React from 'react'
import { useTranslation } from 'react-i18next'
import DeleteIcon from '@material-ui/icons/Delete'
import { FormControl, Button } from '@material-ui/core'
import moment from 'moment'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

import useStyles from 'constants/cruStyles'
import { ILogisticShift } from 'interfaces/ILogisticShift'
import Card from 'components/Card/Card'
import DateRangePicker from 'components/Inputs/DateRangePicker'
import { DateType } from 'constants/constants'

interface IProps {
  logisticShifts: ILogisticShift[]
  onLogisticShiftsChange: (logisticShifts: ILogisticShift[]) => void
}

const LogisticShiftCard = ({ logisticShifts, onLogisticShiftsChange }: IProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleAddClick = (): void => {
    onLogisticShiftsChange([
      ...logisticShifts,
      {
        id: Math.random(),
        begin: moment().hours(0).minutes(0).toISOString(),
        end: moment().hours(23).minutes(59).toISOString(),
      },
    ])
  }

  const handleShiftDelete = (shiftId: number | undefined): void => {
    onLogisticShiftsChange(logisticShifts.filter((shift) => shift.id !== shiftId))
  }

  const handleTimeChange = (
    time: MaterialUiPickersDate,
    shiftId: number | undefined,
    name: string,
  ): void => {
    if (moment(time).isValid()) {
      onLogisticShiftsChange(
        logisticShifts.map((shift) => {
          if (shift.id === shiftId) {
            return {
              ...shift,
              [name]: moment(time).toISOString(),
            }
          }
          return shift
        }),
      )
    }
  }

  return (
    <Card
      dataCy="shiftCard"
      title={t('ConfigurationsScreen.shift').toUpperCase()}
      contentClassName={classes.contentRef}
    >
      <>
        <div className={classes.btnSelect}>
          <FormControl variant="outlined">
            <Button data-cy="addButton" className={classes.modifyButton} onClick={handleAddClick}>
              {t('ConfigurationsScreen.add').toUpperCase()}
            </Button>
          </FormControl>
        </div>
        <div className={classes.fieldsContainer}>
          {logisticShifts.map((shift) => (
            <div data-cy="shiftItems" className={classes.configurationRow} key={shift.id}>
              <div className={classes.configurationItemsContainer}>
                <DateRangePicker
                  startDate={shift.begin}
                  endDate={shift.end}
                  onChangeStart={(time: MaterialUiPickersDate): void => {
                    handleTimeChange(time, shift.id, 'begin')
                  }}
                  onChangeEnd={(time: MaterialUiPickersDate): void => {
                    handleTimeChange(time, shift.id, 'end')
                  }}
                  hideShiftPicker
                  dateType={DateType.Time}
                />
              </div>
              <Button
                data-cy="deleteButton"
                onClick={(): void => {
                  handleShiftDelete(shift.id)
                }}
              >
                <DeleteIcon />
              </Button>
            </div>
          ))}
        </div>
      </>
    </Card>
  )
}

export default LogisticShiftCard
