import React, { useContext, useMemo } from 'react'
import styled from 'styled-components'

import { ITourMapItem, IVehicleColumn } from 'interfaces/map'
import { PlanificationContext } from '../../PlanningStore'
import VehicleHeader from './VehicleHeader'
import TourColumn from '../TourColumn/TourColumn'
import ListContainer from '../Container'

interface IProps {
  vehicleColumn: IVehicleColumn
  loadingTours: string[]
}

const VehicleColumnContainer = styled(ListContainer)`
  min-width: unset;
`
const TourColumnsContainer = styled.div`
  display: flex;
  flex: 1;
  flexdirection: row;
  overflow: hidden;
`

const VehicleColumn = ({ vehicleColumn, loadingTours }: IProps): JSX.Element => {
  const { selectedTours } = useContext(PlanificationContext)

  const vehicleSelectedPlanTours = useMemo((): ITourMapItem[] => {
    const { planTours } = vehicleColumn
    if (selectedTours.length > 0) {
      return planTours.filter((planTour) => selectedTours.includes(planTour.tourId))
    }
    return planTours
  }, [vehicleColumn, selectedTours])

  return (
    <>
      {vehicleSelectedPlanTours.length > 0 && (
        <VehicleColumnContainer elevation={3} square>
          <VehicleHeader vehicleColumn={vehicleColumn} />
          <TourColumnsContainer>
            {vehicleSelectedPlanTours.map((planTour) => (
              <TourColumn
                key={planTour.tourId}
                tour={planTour}
                isLoading={loadingTours.includes(planTour.tourId)}
              />
            ))}
          </TourColumnsContainer>
        </VehicleColumnContainer>
      )}
    </>
  )
}

export default VehicleColumn
