import React, { useState } from 'react'
import SettingsIcon from '@material-ui/icons/Settings'
import { SpeedDial, SpeedDialAction } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import useStyles from './styles'

interface IProps {
  actions: {
    name: string
    icon: JSX.Element
    action: () => void
  }[]
}

const StyledSpeedDial = styled(SpeedDial)`
  position: absolute;
  bottom: 26px;
  margin-left: 10px;
  z-index: 500;
`

const StyledSettingsIcon = styled(SettingsIcon)`
  color: white;
`

const DisplayModeFabMenu = ({ actions }: IProps): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false)
  const { t } = useTranslation()
  const classes = useStyles()

  const handleOpen = (): void => setOpen(true)

  const handleClose = (): void => setOpen(false)

  return (
    <StyledSpeedDial
      ariaLabel={t('PlanningScreen.displayOptionsButton')}
      data-cy="displayOptionsButton"
      icon={<StyledSettingsIcon fontSize="large" />}
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
    >
      {actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          tooltipPlacement="right"
          tooltipOpen
          onClick={action.action}
          classes={{ staticTooltip: classes.noWrap }}
          data-cy={action.name}
        />
      ))}
    </StyledSpeedDial>
  )
}

export default DisplayModeFabMenu
