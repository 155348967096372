import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import useStyles from 'constants/cruStyles'
import { alphabeticSort, formatDeliveryTypes } from 'utils/functions'
import { AuthContext } from 'store/AuthContext'
import Select from './Select'

interface IProps {
  isForRule?: boolean
  value?: number
  onChange: (value: number | null, name: string) => void
  dataCy?: string
}

const DeliveryTypeSelect = ({ isForRule, value, onChange, dataCy }: IProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { user } = useContext(AuthContext)
  const userDeliveryTypes = formatDeliveryTypes(user?.tenantConfig?.deliveryTypes).map(
    (deliveryType) => ({
      value: deliveryType.code ?? '',
      label: deliveryType.label,
      name: deliveryType.label,
    }),
  )

  return (
    <div
      className={clsx(
        classes.routingRuleFieldsContainer,
        isForRule ? classes.ruleDeliverySelect : undefined,
      )}
    >
      <Select
        value={value}
        onChange={(newValue: number | string | null, name: string) => {
          onChange(newValue as number, name)
        }}
        options={userDeliveryTypes.sort(alphabeticSort)}
        label={t('RoutingRulesScreen.delivery')}
        name="deliveryType"
        dataCy={dataCy}
      />
    </div>
  )
}

export default DeliveryTypeSelect
