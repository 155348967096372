const convertDateToRecurrenceRuleDateFormat = (date: Date): string => {
  try {
    const isoDate = date.toISOString().replace(/-/g, '').replace(/:/g, '').replace(/\./g, '')
    return `${isoDate.substring(0, isoDate.length - 4)}Z`
  } catch (error) {
    return ''
  }
}

const addUntilDate = (currentRule: string, untilDate: string): string => {
  let newRule = 'RRULE:'
  const rules = currentRule.split('RRULE:')[1]
  rules.split(';').forEach((rule) => {
    if (!rule.includes('UNTIL')) {
      newRule = `${newRule}${rule};`
    }
  })
  return `${newRule}UNTIL=${untilDate}`
}

const updateDaysOfWeek = (currentRule: string, days: string): string => {
  let newRule = 'RRULE:'
  const rules = currentRule.split('RRULE:')[1]
  rules.split(';').forEach((rule) => {
    if (!rule.includes('BYDAY')) {
      newRule = `${newRule}${rule};`
    }
  })
  return `${newRule}${days.length > 0 ? 'BYDAY=' : ''}${days}`.replace(/;$/, '')
}

const getDefaultRule = (currentRule: string): string => {
  let newRule = 'RRULE:'
  const rules = currentRule.split('RRULE:')[1]
  rules.split(';').forEach((rule) => {
    if (!rule.includes('COUNT')) {
      newRule = `${newRule}${rule};`
    }
  })
  if (!currentRule.includes('UNTIL')) {
    newRule = `${newRule}UNTIL=${convertDateToRecurrenceRuleDateFormat(new Date())}`
  }
  return newRule.replace(/;$/, '')
}

export { convertDateToRecurrenceRuleDateFormat, addUntilDate, getDefaultRule, updateDaysOfWeek }
