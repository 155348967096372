import React, { ReactNode, useEffect, useContext, useState, Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import useInterval from '@use-it/interval'

import { IAlertsFilters, IUserData } from 'interfaces'
import AlertsApi from 'api/alerts/alerts'
import { isIError } from 'api/types'
import { FeedbackContext } from 'store/FeedbackContext'
import { IAlert } from 'interfaces/IAlert'
import { COLUMNS_MODEL_ALERTS_LIST } from 'constants/table'
import { AuthContext } from './AuthContext'

interface IAlertsContext {
  allAlerts: IAlert[]
  setAllAlerts: Dispatch<SetStateAction<IAlert[]>>
  alertsCount: number
  unreadAlertsCount: number
  getAlertsList: (
    filters: IAlertsFilters,
    offset?: number,
    rowsPerPage?: number,
    sortField?: number,
    sortDirection?: string,
  ) => Promise<void>
  updateReadState: (id: string, read: boolean) => Promise<void>
  updateReadStateSuccess: boolean
  setUpdateReadStateSuccess: Dispatch<SetStateAction<boolean>>
  getUnreadAlerts: () => Promise<void>
}

const POLLING_DELAY = 120000

const AlertsContext = React.createContext<IAlertsContext>({} as IAlertsContext)
const { Provider, Consumer } = AlertsContext

interface IProps {
  children: ReactNode
  user?: IUserData
}

function AlertsProvider({ children }: IProps): JSX.Element {
  const { t } = useTranslation()
  const { user, shouldGetAlertsInRealTime, shouldDisplayNotificationBell } = useContext(AuthContext)
  const { openErrorSnack, toggleLoader, openInfoSnack } = useContext(FeedbackContext)
  const [allAlerts, setAllAlerts] = useState<IAlert[]>([])
  const [alertsCount, setAlertsCount] = useState<number>(0)
  const [unreadAlertsCount, setUnreadAlertsCount] = useState<number>(0)
  const [updateReadStateSuccess, setUpdateReadStateSuccess] = useState<boolean>(false)

  async function getAlertsList(
    filters: IAlertsFilters,
    offset?: number,
    rowsPerPage?: number,
    sortField?: number,
    sortDirection?: string,
  ): Promise<void> {
    toggleLoader(true)

    const res = await AlertsApi.getAlerts(
      filters,
      offset,
      rowsPerPage,
      COLUMNS_MODEL_ALERTS_LIST.find((_column, index) => index === sortField)?.field,
      sortDirection === 'desc' ? -1 : 1,
    )
    if (isIError(res)) {
      openErrorSnack(res.error.message)
    } else {
      setAllAlerts(res.alerts)
      setAlertsCount(res.count)
    }
    toggleLoader(false)
  }

  async function getUnreadAlerts(isLoaderDisabled?: boolean, isPolling?: boolean): Promise<void> {
    if (!isLoaderDisabled) {
      toggleLoader(true)
    }

    const res = await AlertsApi.getAlerts({
      read: ['0'],
      concernedRoles: [...(user?.roles || [])],
      concernedWarehouseIds: user?.sites.map((site) => site.siteId),
    })
    if (isIError(res)) {
      openErrorSnack(res.error.message)
    } else {
      setUnreadAlertsCount(res.count)
      if (isPolling && res.count > unreadAlertsCount) {
        openInfoSnack(t('AlertsScreen.newAlert'))
      }
    }

    if (!isLoaderDisabled) {
      toggleLoader(false)
    }
  }

  async function updateReadState(id: string, read: boolean): Promise<void> {
    toggleLoader(true)
    const res = await AlertsApi.updateAlertReadState(id, read)
    if (isIError(res)) {
      openErrorSnack(res.error.message)
    } else {
      setUpdateReadStateSuccess(true)
    }
    toggleLoader(false)
  }

  useEffect(() => {
    if (user && shouldDisplayNotificationBell) {
      getUnreadAlerts(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, shouldDisplayNotificationBell])

  useInterval(
    () => getUnreadAlerts(true, true),
    shouldDisplayNotificationBell && shouldGetAlertsInRealTime ? POLLING_DELAY : null,
  )

  return (
    <Provider
      value={{
        allAlerts,
        setAllAlerts,
        alertsCount,
        unreadAlertsCount,
        getAlertsList,
        updateReadState,
        updateReadStateSuccess,
        setUpdateReadStateSuccess,
        getUnreadAlerts,
      }}
    >
      {children}
    </Provider>
  )
}

export { AlertsContext }
export default AlertsProvider

export { Consumer as AlertsConsumer }
