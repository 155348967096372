import React, { ReactNode, useContext, useEffect, useState } from 'react'
import { OptionTypeBase } from 'react-select'
import { endOfDay, startOfDay } from 'date-fns'
import moment from 'moment'
import { FilterKey, Filter } from 'constants/filters'
import { AuthContext } from './AuthContext'

export type IFilter = string | string[] | number | number[] | boolean | OptionTypeBase[] | undefined

export interface IFilters {
  [key: string]: {
    [filterName: string]: IFilter
  }
}

const DEFAULT_FILTERS = {
  [FilterKey.drivers]: {
    [Filter.searchText]: '',
    [Filter.active]: [],
    [Filter.sites]: [],
    [Filter.carriers]: [],
  },
  [FilterKey.carriers]: {
    [Filter.searchText]: '',
    [Filter.active]: [],
    [Filter.sites]: [],
  },
  [FilterKey.sites]: {
    [Filter.searchText]: '',
    [Filter.active]: [],
    [Filter.types]: [],
  },
  [FilterKey.tenants]: {
    [Filter.searchText]: '',
  },
  [FilterKey.trailers]: {
    [Filter.searchText]: '',
    [Filter.active]: [],
    [Filter.carriers]: [],
    [Filter.vehicleTypes]: [],
  },
  [FilterKey.users]: {
    [Filter.searchText]: '',
    [Filter.active]: [],
    [Filter.roles]: [],
    [Filter.companies]: [],
    [Filter.tenant]: [],
    [Filter.sites]: [],
  },
  [FilterKey.vehicles]: {
    [Filter.searchText]: '',
    [Filter.active]: [],
    [Filter.vehicleTypes]: [],
    [Filter.sites]: [],
    [Filter.carriers]: [],
  },
  [FilterKey.vehicleTypes]: {
    [Filter.searchText]: '',
    [Filter.active]: [],
  },
  [FilterKey.mapMonitoring]: {
    [Filter.startDate]: moment().hours(0).minutes(0).seconds(0)
      .milliseconds(0)
      .toISOString(),
    [Filter.endDate]: moment().hours(23).minutes(59).seconds(59)
      .milliseconds(999)
      .toISOString(),
    [Filter.searchText]: '',
    [Filter.sites]: [],
    [Filter.deliveryTypes]: [],
    [Filter.carriers]: [],
    [Filter.status]: ['5'],
  },
  [FilterKey.orders]: {
    [Filter.startDate]: startOfDay(new Date()).toISOString(),
    [Filter.endDate]: endOfDay(new Date()).toISOString(),
    [Filter.shift]: undefined,
    [Filter.searchText]: '',
    [Filter.sites]: [],
    [Filter.deliveryTypes]: [],
    [Filter.transportTypes]: [],
    [Filter.deliverySites]: [],
    [Filter.planStatuses]: [],
    [Filter.relevance]: undefined,
    [Filter.zipCodes]: [],
  },
  [FilterKey.planningOrders]: {
    [Filter.startDate]: moment()
      .subtract(15, 'days')
      .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0)
      .toISOString(),
    [Filter.endDate]: undefined,
    [Filter.shift]: undefined,
    [Filter.searchText]: '',
    [Filter.sites]: [],
    [Filter.deliveryTypes]: [],
    [Filter.transportTypes]: [],
    [Filter.deliverySites]: [],
    [Filter.planStatuses]: [],
    [Filter.relevance]: undefined,
    [Filter.zipCodes]: [],
  },
  [FilterKey.geocodingOrders]: {
    [Filter.startDate]: moment().hours(0).minutes(0).seconds(0)
      .milliseconds(0)
      .toISOString(),
    [Filter.endDate]: moment().hours(23).minutes(59).seconds(59)
      .milliseconds(999)
      .toISOString(),
    [Filter.searchText]: '',
    [Filter.sites]: [],
    [Filter.deliveryTypes]: [],
    [Filter.vehicleTypes]: [],
    [Filter.planStatuses]: [],
    [Filter.relevance]: 90,
  },
  [FilterKey.carrierExecution]: {
    [Filter.startDate]: moment().hours(0).minutes(0).seconds(0)
      .milliseconds(0)
      .toISOString(),
    [Filter.endDate]: moment().hours(23).minutes(59).seconds(59)
      .milliseconds(999)
      .toISOString(),
    [Filter.shift]: undefined,
    [Filter.carriers]: [],
    [Filter.sites]: [],
    [Filter.vehicleTypes]: [],
    [Filter.deliveryTypes]: [],
    [Filter.searchText]: '',
    [Filter.status]: ['0', '1', '2', '3', '4', '5', '6', '9'],
  },
  [FilterKey.execution]: {
    [Filter.startDate]: moment().hours(0).minutes(0).seconds(0)
      .milliseconds(0)
      .toISOString(),
    [Filter.endDate]: moment().hours(23).minutes(59).seconds(59)
      .milliseconds(999)
      .toISOString(),
    [Filter.shift]: undefined,
    [Filter.carriers]: [],
    [Filter.sites]: [],
    [Filter.vehicleTypes]: [],
    [Filter.deliveryTypes]: [],
    [Filter.searchText]: '',
    [Filter.status]: ['0', '1', '2', '3', '4', '5', '6', '9'],
  },
  [FilterKey.tourMonitoring]: {
    [Filter.startDate]: moment().hours(0).minutes(0).seconds(0)
      .milliseconds(0)
      .toISOString(),
    [Filter.endDate]: moment().hours(23).minutes(59).seconds(59)
      .milliseconds(999)
      .toISOString(),
    [Filter.shift]: undefined,
    [Filter.carriers]: [],
    [Filter.sites]: [],
    [Filter.vehicleTypes]: [],
    [Filter.deliveryTypes]: [],
    [Filter.searchText]: '',
    [Filter.status]: ['0', '1', '2', '3', '4', '5', '6', '9'],
  },
  [FilterKey.planTours]: {
    [Filter.startDate]: moment().hours(0).minutes(0).seconds(0)
      .milliseconds(0)
      .toISOString(),
    [Filter.endDate]: moment().hours(23).minutes(59).seconds(59)
      .milliseconds(999)
      .toISOString(),
    [Filter.shift]: undefined,
    [Filter.carriers]: [],
    [Filter.sites]: [],
    [Filter.deliverySites]: [],
    [Filter.deliveryTypes]: [],
    [Filter.searchText]: '',
    [Filter.status]: [],
  },
  [FilterKey.routingRules]: {
    [Filter.searchText]: '',
    [Filter.sites]: [],
    [Filter.active]: [],
  },
  [FilterKey.marketShare]: {
    [Filter.startDate]: moment().hours(0).minutes(0).seconds(0)
      .milliseconds(0)
      .toISOString(),
    [Filter.endDate]: moment().hours(23).minutes(59).seconds(59)
      .milliseconds(999)
      .toISOString(),
    [Filter.sites]: [],
    [Filter.deliveryTypes]: ['0', '1', '2'],
  },
  [FilterKey.tourCancellations]: {
    [Filter.startDate]: moment().hours(0).minutes(0).seconds(0)
      .milliseconds(0)
      .toISOString(),
    [Filter.endDate]: moment().hours(23).minutes(59).seconds(59)
      .milliseconds(999)
      .toISOString(),
    [Filter.sites]: [],
    [Filter.carriers]: [],
    [Filter.drivers]: [],
    [Filter.cancellationReasons]: [],
  },
  [FilterKey.stopCancellations]: {
    [Filter.startDate]: moment().hours(0).minutes(0).seconds(0)
      .milliseconds(0)
      .toISOString(),
    [Filter.endDate]: moment().hours(23).minutes(59).seconds(59)
      .milliseconds(999)
      .toISOString(),
    [Filter.sites]: [],
    [Filter.carriers]: [],
    [Filter.drivers]: [],
    [Filter.cancellationReasons]: [],
  },
  [FilterKey.mobileApp]: {
    [Filter.startDate]: moment().hours(0).minutes(0).seconds(0)
      .milliseconds(0)
      .toISOString(),
    [Filter.endDate]: moment().hours(23).minutes(59).seconds(59)
      .milliseconds(999)
      .toISOString(),
    [Filter.sites]: [],
    [Filter.carriers]: [],
    [Filter.drivers]: [],
    [Filter.uses]: [],
  },
  [FilterKey.punctuality]: {
    [Filter.startDate]: moment().hours(0).minutes(0).seconds(0)
      .milliseconds(0)
      .toISOString(),
    [Filter.endDate]: moment().hours(23).minutes(59).seconds(59)
      .milliseconds(999)
      .toISOString(),
    [Filter.sites]: [],
    [Filter.carriers]: [],
  },
  [FilterKey.tourProgress]: {
    [Filter.startDate]: moment().hours(0).minutes(0).seconds(0)
      .milliseconds(0)
      .toISOString(),
    [Filter.endDate]: moment().hours(23).minutes(59).seconds(59)
      .milliseconds(999)
      .toISOString(),
    [Filter.sites]: [],
    [Filter.carriers]: [],
  },
  [FilterKey.transportVolume]: {
    [Filter.startDate]: moment().hours(0).minutes(0).seconds(0)
      .milliseconds(0)
      .toISOString(),
    [Filter.endDate]: moment().hours(23).minutes(59).seconds(59)
      .milliseconds(999)
      .toISOString(),
    [Filter.sites]: [],
    [Filter.carriers]: [],
  },
  [FilterKey.orderHistory]: {
    [Filter.deliveryTypes]: [],
    [Filter.transportTypes]: [],
    [Filter.zipCodes]: [],
    [Filter.sites]: [],
    [Filter.planStatuses]: [],
    [Filter.deliveryWarehouses]: [],
    [Filter.startDate]: moment().subtract(30, 'days').startOf('days').toISOString(),
    [Filter.endDate]: moment().endOf('days').toISOString(),
  },
  [FilterKey.orderIndicator]: {
    [Filter.deliveryTypes]: ['0', '1', '2'],
    [Filter.transportTypes]: [],
    [Filter.date]: moment().add(1, 'day').hours(0).minutes(0)
      .toISOString(),
    [Filter.deliveryWarehouses]: [],
    [Filter.axis]: 0,
    [Filter.groupBy]: 0,
    [Filter.sites]: [],
    [Filter.planStatuses]: [],
    [Filter.zipCodes]: [],
  },
  [FilterKey.planningMonitoring]: {
    [Filter.deliveryTypes]: ['0', '1', '2'],
    [Filter.transportTypes]: [],
    [Filter.date]: moment().add(1, 'day').hours(0).minutes(0)
      .toISOString(),
    [Filter.sites]: [],
  },
  [FilterKey.sectors]: {
    [Filter.hasSector]: [],
    [Filter.active]: [],
    [Filter.types]: [],
    [Filter.sites]: [],
    [Filter.searchText]: '',
  },
  [FilterKey.adminWarehouses]: {
    [Filter.types]: [],
    [Filter.tenant]: [],
    [Filter.searchText]: '',
  },
  [FilterKey.templatePlans]: {
    [Filter.sites]: [],
  },
  [FilterKey.warehouseTimeSlots]: {
    [Filter.active]: [],
    [Filter.sites]: [],
    [Filter.tenant]: [],
  },
  [FilterKey.siteReporting]: {
    [Filter.date]: moment().subtract(1, 'month').toISOString(),
    [Filter.sites]: [],
  },
  [FilterKey.notifierConfigurations]: {
    [Filter.tenant]: [],
  },
  [FilterKey.costReporting]: {
    [Filter.startDate]: moment().subtract(1, 'month').startOf('month').toISOString(),
    [Filter.endDate]: moment().subtract(1, 'month').endOf('month').toISOString(),
    [Filter.carriers]: [],
  },
  [FilterKey.deliveryScheduleChanges]: {
    [Filter.startDate]: moment().subtract(1, 'month').startOf('month').toISOString(),
    [Filter.endDate]: moment().subtract(1, 'month').endOf('month').toISOString(),
    [Filter.carriers]: [],
    [Filter.sites]: [],
  },
  [FilterKey.costConfig]: {
    [Filter.carriers]: [],
  },
  [FilterKey.customerRatingReporting]: {
    [Filter.startDate]: undefined,
    [Filter.endDate]: undefined,
    [Filter.deliveryTypes]: [],
    [Filter.sites]: [],
    [Filter.carriers]: [],
    [Filter.drivers]: [],
  },
  [FilterKey.customerCommentsReporting]: {
    [Filter.startDate]: undefined,
    [Filter.endDate]: undefined,
    [Filter.ratingGrade]: [],
    [Filter.deliveryTypes]: [],
    [Filter.sites]: [],
    [Filter.carriers]: [],
    [Filter.drivers]: [],
  },
  [FilterKey.deliveryDistance]: {
    [Filter.deliveryTypes]: [],
    [Filter.transportTypes]: [],
    [Filter.zipCodes]: [],
    [Filter.sites]: [],
    [Filter.planStatuses]: [],
    [Filter.deliveryWarehouses]: [],
    [Filter.startDate]: moment().subtract(30, 'days').startOf('days').toISOString(),
    [Filter.endDate]: moment().endOf('days').toISOString(),
    [Filter.distance]: 0,
  },
  [FilterKey.alerts]: {
    [Filter.startDate]: undefined,
    [Filter.endDate]: undefined,
    [Filter.sites]: [],
    [Filter.dealers]: [],
    [Filter.read]: [],
  },
  [FilterKey.disputes]: {
    [Filter.startDate]: moment()
      .subtract(15, 'days')
      .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0)
      .toISOString(),
    [Filter.endDate]: undefined,
    [Filter.carriers]: [],
    [Filter.drivers]: [],
    [Filter.sites]: [],
    [Filter.disputeReasonCodes]: [],
  },
  [FilterKey.replay]: {
    [Filter.startDate]: moment().hours(0).minutes(0).seconds(0)
      .milliseconds(0)
      .toISOString(),
    [Filter.endDate]: moment().hours(23).minutes(59).seconds(59)
      .milliseconds(999)
      .toISOString(),
    [Filter.sites]: [],
    [Filter.tourNumbers]: [],
  },
  [FilterKey.apikeys]: {
    [Filter.tenant]: [],
  },
}

interface IFiltersContext {
  filters: IFilters
  setFilter: (key: string, filterName: string, filterValue: IFilter) => void
  resetFilters: (key: string) => void
  setDefaultSitesFilter: (filterValue: IFilter) => void
  resetAllFilters: () => void
}

const FiltersContext = React.createContext<IFiltersContext>({} as IFiltersContext)
const { Provider, Consumer } = FiltersContext

interface IProps {
  children: ReactNode
}

const FiltersProvider = ({ children }: IProps): JSX.Element => {
  const [filters, setFilters] = useState<IFilters>(DEFAULT_FILTERS)
  const { isAuthLoading, user } = useContext(AuthContext)

  useEffect(() => {
    if (!isAuthLoading && user) {
      setDefaultTenantsFilter([user.tenantId])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isAuthLoading])

  const setFilter = (key: string, filterName: string, filterValue: IFilter): void => {
    setFilters((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        [filterName]: filterValue,
      },
    }))
  }

  const setDefaultSitesFilter = (filterValue: IFilter): void => {
    setFilters((prev) => {
      const updatedFilters = prev
      // When we change the filter in the topbar, we change the filter value for all the warehouse filters in the application
      Object.keys(prev).forEach((key) => {
        Object.keys(prev[key]).forEach((subkey) => {
          if (subkey === Filter.sites) {
            updatedFilters[key][subkey] = filterValue
          }
        })
      })
      return { ...updatedFilters }
    })
  }

  const setDefaultTenantsFilter = (filterValue: IFilter): void => {
    setFilters((prev) => {
      const updatedFilters = prev
      Object.keys(prev).forEach((key) => {
        Object.keys(prev[key]).forEach((subkey) => {
          if (subkey === Filter.tenant) {
            updatedFilters[key][subkey] = filterValue
          }
        })
      })
      return { ...updatedFilters }
    })
  }

  const resetFilters = (key: string): void => {
    setFilters((prev) => ({
      ...prev,
      [key]: DEFAULT_FILTERS[key],
    }))
  }

  const resetAllFilters = (): void => setFilters(DEFAULT_FILTERS)

  return (
    <Provider value={{ filters, setFilter, resetFilters, resetAllFilters, setDefaultSitesFilter }}>
      {children}
    </Provider>
  )
}

export { FiltersContext, Consumer as FiltersConsumer }
export default FiltersProvider
