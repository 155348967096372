import React, { useContext } from 'react'
import { TextField, TextFieldProps } from '@material-ui/core'

import { RoutingRulesContext } from './RoutingStore'

type ITextInput = TextFieldProps

function TextInput({ value, disabled, variant, ...rest }: ITextInput): JSX.Element {
  const { editMode } = useContext(RoutingRulesContext)

  return (
    <TextField
      value={value}
      {...rest}
      disabled={disabled || !editMode}
      variant={!editMode ? undefined : variant}
    />
  )
}

export { TextInput }
