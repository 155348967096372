import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import useStyles from './styles'

interface IProps {
  colors: IColors
}

interface IColors {
  background: string
  fontColor: string
}

const Container = styled.div`
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  background-color: ${(props: IProps): string => props.colors.background};
  color: ${(props: IProps): string => props.colors.fontColor};
  border-radius: 4px;
`

interface IScoreContainerProps {
  score: number | null
  noLabel?: boolean
}

export default ({ score, noLabel = false }: IScoreContainerProps): JSX.Element | null => {
  const { t } = useTranslation()
  const classes = useStyles()

  const getColors = (): IColors => {
    if (score === null) {
      return {
        background: '',
        fontColor: '',
      }
    }
    if (score >= 0.97) {
      return {
        background: 'MediumSeaGreen',
        fontColor: 'green',
      }
    }
    if (score >= 0.91) {
      return {
        background: 'NavajoWhite',
        fontColor: 'orange',
      }
    }
    return {
      background: 'red',
      fontColor: 'white',
    }
  }

  return score !== null ? (
    <div data-cy="score" className={classes.scoreContainer}>
      {!noLabel && (
        <div data-cy="label" className={classes.scoreLabel}>
          {t('SitesScreen.accuracy')}
        </div>
      )}
      <Container data-cy="percentage" className={classes.scoreInnerContainer} colors={getColors()}>
        {+(score * 100).toFixed(2)}%
      </Container>
    </div>
  ) : null
}
