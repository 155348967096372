import React, { useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ICostDetailedReport,
  ICostReporting,
  IWarehouseCostReporting,
} from 'interfaces/ICostConfiguration'
import { TableRow, TableCell, IconButton } from '@material-ui/core'
import clsx from 'clsx'
import MUITableIcons from 'constants/MUITableIcons'
import { COLUMNS_COSTS_REPORTING } from 'constants/table'
import MaterialTable from 'material-table'
import { Filter, FilterKey } from 'constants/filters'
import { FiltersContext } from 'store/FiltersContext'
import DateRangePicker from 'components/Inputs/DateRangePicker'
import RefreshIcon from '@material-ui/icons/Refresh'
import moment from 'moment'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { DateType } from 'constants/constants'
import CostConfigurationsProvider, {
  CostConfigurationsContext,
} from 'store/CostConfigurationsContext'
import { getCarrierNames, getSitesLabel, getDisplayRowsCount } from 'utils/tableUtils'
import { ContentContext } from 'store/ContentContext'
import { CarrierFilter } from 'components/Inputs/ListFilter'
import { FiltersContainer, TopFiltersContainer } from 'components/Layout'
import { dateDescendingSort, exportCsvFromArray } from 'utils/functions'
import GetApp from '@material-ui/icons/GetApp'
import { dateDefaultFormat } from 'utils/dateFormat'
import ExtendedDateFilters from 'components/Table/ExtendedDateFilters'
import useStyles from './styles'

const costsReportingTitle = 'costs-reporting'
const filterKey = FilterKey.costReporting

const CostsReportingScreen = (): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { setFilter, resetFilters, filters } = useContext(FiltersContext)
  const startDate = filters[filterKey][Filter.startDate] as string
  const endDate = filters[filterKey][Filter.endDate] as string
  const carriersToFilter = filters[filterKey][Filter.carriers] as string[]
  const { getReporting, costsReporting } = useContext(CostConfigurationsContext)
  const { allSites, sites, carriers } = useContext(ContentContext)

  const csvReportHeaders = [
    t('CostsReportingScreen.warehouse'),
    t('CostsReportingScreen.carrier'),
    t('CostsReportingScreen.costType'),
    t('CostsReportingScreen.configLabel'),
    t('CostsReportingScreen.date'),
    t('CostsReportingScreen.tourNumber'),
    t('CostsReportingScreen.stopCount'),
    t('CostsReportingScreen.cost'),
  ]

  useEffect(() => {
    getReporting(startDate, endDate, carriersToFilter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, carriersToFilter])

  const constructTableData = (
    costReportingPerWarehouse: IWarehouseCostReporting[],
  ): ICostReporting[] => {
    const result: ICostReporting[] = []
    costReportingPerWarehouse.forEach((warehouseCost, warehouseCostIndex) => {
      warehouseCost.carriersCost.forEach((carrierCost, carrierCostIndex) => {
        const totalCarrierCost = carrierCost.costDetails?.reduce(
          (accumulator, costDetail) => accumulator + costDetail.cost,
          0,
        )
        const row: ICostReporting = {
          warehouseId: warehouseCost.warehouseId,
          warehouseName: getSitesLabel([warehouseCost.warehouseId], allSites, sites),
          carrierName: getCarrierNames([carrierCost.carrierId], carriers),
          carrierCostFormatted: totalCarrierCost?.toLocaleString('fr-FR'),
          carrierCost: totalCarrierCost,
          costDetails: carrierCost.costDetails,
          nbOfCarriers: carrierCostIndex === 0 ? warehouseCost.carriersCost.length : undefined,
          lastCarrierInWarehouse:
            warehouseCostIndex !== costReportingPerWarehouse.length - 1
            && carrierCostIndex === warehouseCost.carriersCost.length - 1,
        }
        result.push(row)
      })
    })
    return result
  }

  const resetLocalFilters = (): void => {
    resetFilters(filterKey)
  }

  const handleExportCostDetails = (
    warehouseName: string,
    carrierName: string,
    costDetails: ICostDetailedReport[],
  ): void => {
    const rows: string[][] = getRowsFromCostDetails(warehouseName, carrierName, costDetails)

    rows.unshift(csvReportHeaders)
    const csvTitle = `${warehouseName} - ${carrierName}`
    exportCsvFromArray(rows, csvTitle)
  }

  const handleExportWarehouseCostDetails = (
    warehouseName: string,
    warehouseCosts: IWarehouseCostReporting,
  ): void => {
    const rows: string[][] = []
    warehouseCosts.carriersCost.forEach((carrierCost) => {
      rows.push(
        ...getRowsFromCostDetails(
          warehouseName,
          getCarrierNames([carrierCost.carrierId], carriers),
          carrierCost.costDetails,
        ),
      )
    })

    rows.unshift(csvReportHeaders)
    exportCsvFromArray(rows, warehouseName)
  }

  const getRowsFromCostDetails = (
    warehouseName: string,
    carrierName: string,
    costDetails: ICostDetailedReport[],
  ): string[][] =>
    costDetails
      .sort((a, b) => dateDescendingSort(new Date(a.date), new Date(b.date)))
      .map((costDetail) => [
        warehouseName,
        carrierName,
        t(`CostsReportingScreen.${costDetail.costType}`),
        costDetail.configLabel,
        dateDefaultFormat(new Date(costDetail.date)),
        costDetail.tourNumber,
        costDetail.stopCount.toString(),
        costDetail.cost.toString(),
      ])

  const displayRowsCount = useMemo(() => getDisplayRowsCount(costsReporting), [costsReporting])

  const setEndDate = (value: string): void => {
    setFilter(filterKey, Filter.endDate, value)
  }

  const setStartDate = (value: string): void => {
    setFilter(filterKey, Filter.startDate, value)
  }

  return (
    <>
      <TopFiltersContainer>
        <FiltersContainer>
          <IconButton data-cy="initializeButton" onClick={resetLocalFilters}>
            <RefreshIcon />
          </IconButton>
          <CarrierFilter
            handleChange={(carrierValues: string[]): void => {
              setFilter(filterKey, Filter.carriers, carrierValues)
            }}
            ids={carriersToFilter}
            dataCy="carrierPicker"
          />
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onChangeStart={(startValue: MaterialUiPickersDate): void => {
              setFilter(filterKey, Filter.startDate, moment(startValue).toISOString())
            }}
            onChangeEnd={(endValue: MaterialUiPickersDate): void => {
              setFilter(filterKey, Filter.endDate, moment(endValue).toISOString())
            }}
            hideShiftPicker
            dateType={DateType.DateTime}
          />
        </FiltersContainer>
      </TopFiltersContainer>
      <ExtendedDateFilters
        last3months
        lastMonth
        last7days
        next7days
        thisMonth
        setEndDate={setEndDate}
        setStartDate={setStartDate}
      />
      <MaterialTable
        key={displayRowsCount}
        columns={COLUMNS_COSTS_REPORTING.map(
          (col) =>
            ({
              ...col,
            } as unknown as MaterialTable<object>),
        )}
        data={constructTableData(costsReporting)}
        options={{
          paging: false,
          exportButton: true,
          exportFileName: costsReportingTitle,
          showTitle: false,
          search: false,
          pageSize: displayRowsCount,
        }}
        localization={{
          toolbar: {
            // @ts-ignore
            exportCSVName: t('tablesEntries.exportCsv'),
            exportPDFName: t('tablesEntries.exportPdf'),
          },
        }}
        icons={MUITableIcons}
        components={{
          Row: (props) => {
            const {
              data: {
                warehouseId,
                warehouseName,
                carrierName,
                carrierCostFormatted,
                nbOfCarriers,
                lastCarrierInWarehouse,
                costDetails,
              },
            } = props
            return (
              <TableRow className={clsx(lastCarrierInWarehouse && classes.border)}>
                {nbOfCarriers && (
                  <TableCell rowSpan={nbOfCarriers} className={classes.warehouseTableCell}>
                    <IconButton
                      onClick={(): void =>
                        handleExportWarehouseCostDetails(
                          warehouseName,
                          costsReporting.find(
                            (costReport) => costReport.warehouseId === warehouseId,
                          ) as IWarehouseCostReporting,
                        )}
                      aria-label="export"
                    >
                      <GetApp />
                    </IconButton>
                    {warehouseName}
                  </TableCell>
                )}
                <TableCell>{carrierName}</TableCell>
                <TableCell align="right">{carrierCostFormatted}</TableCell>
                <TableCell>
                  <IconButton
                    onClick={(): void =>
                      handleExportCostDetails(warehouseName, carrierName, costDetails)}
                    aria-label="export"
                  >
                    <GetApp />
                  </IconButton>
                </TableCell>
              </TableRow>
            )
          },
        }}
      />
    </>
  )
}

export default (props: JSX.IntrinsicAttributes): JSX.Element => (
  <CostConfigurationsProvider>
    <CostsReportingScreen {...props} />
  </CostConfigurationsProvider>
)
